import React, {useCallback} from 'react';
import {IdeascaleSelectWithPagination, OptionType} from '@ideascale/ui';
import {GroupBase, OptionsOrGroups} from 'react-select';
import {useLocalizer} from '../../hooks/useLocalizer';
import {SearchInputGroup} from '../../shared/SearchInputGroup';
import {PageParameters} from '../../models/types/PageParameters';
import {PagedResponseContent} from '@ideascale/commons';
import {StageGroup} from '../../models/StageGroup';
import styles from './SearchField.module.scss';

type CustomSearchFieldProps = {
    fetchIdeasCustomFields(pageParameters: PageParameters, campaignIds?: number, includeArchivedCampaigns?: boolean): Promise<PagedResponseContent<StageGroup>>;
    values: OptionType[];
    onChange: (options: OptionType[] | null, mode: 'IDEA' | 'MEMBER') => void;
}

export const CustomSearchField = (props: CustomSearchFieldProps) => {
    const {fetchIdeasCustomFields, values, onChange} = props;
    const localizer = useLocalizer();

    const loadCustomFields = useCallback(async (term: string, prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>, additionalParams: any) => {
        try {
            const {page} = additionalParams;
            const customFieldData = await fetchIdeasCustomFields({page, term, limit: 10});
            const tags: OptionType[] = customFieldData.content.map((fieldItem) => ({
                value: fieldItem.id,
                label: fieldItem.label,
                labelAsJsx: <span className={`label customize-label text-truncate`}>{fieldItem.label}</span>
            }));
            return {
                options: tags,
                hasMore: customFieldData.hasMore,
                additional: {
                    page: page + 1,
                }
            };
        } catch(e: any) {
            return {
                options: []
            }
        }

    }, [fetchIdeasCustomFields]);


    return (
        <div data-test-element-id="custom-field-search" className={`form-group ${styles.customFieldSearch}`}>
            <label className="fw-bold" htmlFor="custom-field-search">{localizer.msg('search.idea.custom-field-label')}</label>
            <SearchInputGroup groupText={''}>
                <IdeascaleSelectWithPagination
                    ariaLabel={localizer.msg('search.idea.select-custom-field-label')}
                    inputId="custom-field-search"
                    isMulti={true}
                    loadOptions={loadCustomFields}
                    placeholder={localizer.msg('search.idea.custom-field-placeholder')}
                    value={values}
                    onChange={(options: any) => onChange(options, 'IDEA')}
                    additional={{page: 0}}/>
            </SearchInputGroup>
        </div>
    );
};