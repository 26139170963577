export class InviteFriendResponse {
    static readonly EMPTY = new InviteFriendResponse('', []);

    from: string;
    whiteListedDomains: string[];

    constructor(from: string, whiteListedDomains: string[]) {
        this.from = from;
        this.whiteListedDomains = whiteListedDomains;
    }
}