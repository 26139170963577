import {Command} from './Command';
import {CommunityHomeEditor} from './Editors/CommunityHomeEditor';
import {CommunityOperationType} from 'models/edit-mode/CommunityOperationType';
import {Operation} from 'models/edit-mode/Operation';
import {ChangeCommunityNameOperation} from 'models/edit-mode/ChangeCommunityNameOperation';

export class UpdateCommunityNameCommand implements Command {
    private readonly communityHomeEditor: CommunityHomeEditor;
    private readonly oldCommunityName: string;
    private readonly newCommunityName: string;
    private readonly type: CommunityOperationType;

    constructor(communityHomeEditor: CommunityHomeEditor, newCommunityName: string) {
        this.communityHomeEditor = communityHomeEditor;
        this.oldCommunityName = this.communityHomeEditor.communityName;
        this.newCommunityName = newCommunityName;
        this.type = CommunityOperationType.CHANGE_COMMUNITY_NAME;
    }

    execute(): void {
        this.communityHomeEditor.communityName = this.newCommunityName;
    }

    getType(): CommunityOperationType {
        return this.type;
    }

    getOperation(): Operation {
        return new ChangeCommunityNameOperation(this.type, this.communityHomeEditor.communityName);
    }

    undo(): void {
        this.communityHomeEditor.communityName = this.oldCommunityName;
    }

}