import {FieldAttributeParameters, PrivateMessageRequestParams} from '@ideascale/commons';
import {CampaignPerformanceParameters} from './CampaignPerformanceParameters';

export class DigestInternalShareRequestParams extends PrivateMessageRequestParams {
    constructor(
        ids: number[],
        subject: string,
        body: string,
        public campaignId?: number,
        public campaignPerformanceParameters?: CampaignPerformanceParameters,
        public attributeApiParameters?: FieldAttributeParameters[]
    ) {
        super(ids, subject, body, attributeApiParameters);
    }
}