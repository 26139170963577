import {useCallback} from 'react';
import {AlertEvent, AlertType, buildAlertEventData, eventDispatcher, isHtmlInject, isJavascriptInject} from '@ideascale/commons';
import {useLocalizer} from './useLocalizer';

export const useHtmlOrJavascriptInjectionAlert = () => {
    const localizer = useLocalizer();

    const isHtmlOrJavascriptInjection = useCallback((value: string) => {
        if (isHtmlInject(value)) {
            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.warn, localizer.msg('ideas.alerts.html-warning')));
            return true;
        } else if (isJavascriptInject(value)) {
            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.warn, localizer.msg('ideas.alerts.javascript-warning')));
            return true;
        }
        return false;
    }, [localizer]);

    return {isHtmlOrJavascriptInjection};
};