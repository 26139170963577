import React, {Fragment, useEffect, useRef, useState} from 'react';
import {UncontrolledTooltip} from 'reactstrap';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import {Checkbox, Icon} from '@ideascale/ui';
import {AlertType, Localizer, useApiErrorResponseHandler, uuid} from '@ideascale/commons';
import {RatingEdit} from 'shared/RatingEdit';
import {AssessmentRating} from 'models/stage-activity/AssessmentRating';
import {AssessmentRatingHint} from 'models/stage-activity/AssessmentRatingHint';
import {AssessmentRatingParameters} from 'models/types/AssessmentRatingParameters';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';

type AssessmentFieldProps = {
    localizer: Localizer;
    assessmentRating: AssessmentRating;
    hints: AssessmentRatingHint[];
    notesRequired: boolean;
    onSaveAssessmentActivity: (questionId: number, parameters: AssessmentRatingParameters) => Promise<void>;
}

export const AssessmentForm = (props: AssessmentFieldProps) => {
    const {localizer, assessmentRating, hints, notesRequired, onSaveAssessmentActivity} = props;
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [loading, setLoading] = useState(false);
    const [showNoteField, setShowNoteField] = useState(notesRequired && assessmentRating.note.length < 1);
    const [notesError, setNotesError] = useState('');
    const [rankError, setRankError] = useState('');
    const [ratingState, setRatingState] = useState(-1);
    const [noteState, setNoteState] = useState('');
    const notesElement = useRef<HTMLTextAreaElement>(null);
    const savedRating = assessmentRating.rate;
    const savedNotes = assessmentRating.note;
    const notApplicableHint = hints.find(hint => hint.rating === 0);

    const validateNotes = (notes: string, rating: number) => {
        setNotesError('');
        if (rating === 0) {
            return true;
        }
        if (notesRequired && notes.length < 1) {
            notesElement.current?.focus();
            setNotesError(localizer.msg('stage.assessment.validation.note'));
            return false;
        }
        return true;
    };

    const validateRank = (rank: number) => {
        setRankError('');
        if (rank < 0) {
            setRankError(localizer.msg('stage.assessment.validation.rank'));
            return false;
        }
        return true;
    };

    const onChangeRating = async (newVal: number) => {
        if (newVal !== ratingState) {
            setRatingState(newVal);
            if (validateRank(newVal)) {
                if (!notesRequired || validateNotes(noteState, newVal)) {
                    await submitAnswer({rating: newVal});
                }
            }
        }
    };

    const onSaveNotes = async () => {
        const newNotes = notesElement.current?.value || '';
        setNoteState(newNotes);
        if (validateNotes(newNotes, ratingState)) {
            if (validateRank(ratingState)) {
                await submitAnswer({note: newNotes});
            }
        }
    };

    const submitAnswer = async (parameters: Partial<AssessmentRatingParameters>) => {
        setLoading(true);
        try {
            await onSaveAssessmentActivity(assessmentRating.questionId, {
                rating: isNumber(parameters.rating) ? parameters.rating : ratingState,
                note: isString(parameters.note) ? parameters.note : noteState
            });
            setShowNoteField(false);
            setNotesError('');
            setRankError('');
        } catch (error: any) {
            handleErrorResponse(error, {
                setFormError: () => {
                    setNotesError(error?.data?.validationErrors?.note ?? localizer.msg('stage.assessment.validation.invalid-not'));
                },
                fallbackMessage: {type: AlertType.error, message: localizer.msg('stage.assessment.errors.save-failed')}
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setRatingState(savedRating);
    }, [savedRating]);

    useEffect(() => {
        setNoteState(savedNotes);
    }, [savedNotes]);

    const textAreaId = uuid();

    return (
        <div className={`${loading ? 'pointer-events-none opacity-75' : ''}`}>
            <strong>{assessmentRating.question}</strong>
            <div className="row mt-2">
                <div className="col-8 position-relative">
                    <RatingEdit
                        value={ratingState}
                        inputId={`${assessmentRating.questionId}`}
                        hints={hints}
                        onChange={(newVal) => onChangeRating(newVal)}
                    />
                    {
                        ratingState > 0 &&
                        <div className="question-completed">
                            <Icon className="active" iconSpritePath={svgIconsPath} name="check" width={15}
                                  height={15}
                                  fill="#384EC1"/>
                        </div>
                    }
                </div>
                <div id={`assessment-tooltip-container-${assessmentRating.questionId}`} className="col-4 text-end"
                     aria-live="polite">
                    <UncontrolledTooltip placement="top"
                                         target={`rating-tooltip-${assessmentRating.questionId}-${0}`}
                                         container={`assessment-tooltip-container-${assessmentRating.questionId}`}>
                        {notApplicableHint?.hint || localizer.msg('stage.assessment.na')}
                    </UncontrolledTooltip>
                    <div id={`rating-tooltip-${assessmentRating.questionId}-${0}`}>
                        <Checkbox className="mt-1" label="NA" checked={ratingState === 0}
                                  inputId={`na-checkbox-${assessmentRating.questionId}`}
                                  title={localizer.msg('stage.assessment.na')}
                                  onChange={() => onChangeRating(0)}/>
                    </div>
                </div>
            </div>

            {
                rankError &&
                <div
                    className="padding-left-0 py-2 font-size-sm required-field invalid-feedback d-block">
                    {rankError}
                </div>
            }

            <div>
                {
                    noteState.length === 0 && ratingState !== 0 &&
                    <button className="btn btn-link p-0 text-decoration-none shadow-none" type="button"
                            onClick={() => setShowNoteField(true)}>
                        {localizer.msg('stage.assessment.add-note')}{notesRequired &&
                        <span className="font-size-lg" aria-hidden={true}>*</span>}
                    </button>
                }
                {
                    showNoteField && ratingState !== 0 &&
                    <Fragment>
                        <div className="form-group">
                            <label className="sr-only"
                                   htmlFor={textAreaId}>{localizer.msg('stage.assessment.add-note')}</label>
                            <textarea id={textAreaId} className="form-control w-100"
                                      defaultValue={noteState} ref={notesElement} aria-required="true"/>
                            {
                                notesError &&
                                <div
                                    className="padding-left-0 py-2 required-field invalid-feedback d-block font-size-sm">
                                    {notesError}
                                </div>
                            }
                        </div>
                        <div className={`form-group ${!showNoteField ? 'd-none' : ''}`}>
                            <button className="btn btn-primary" type="button"
                                    onClick={() => void onSaveNotes()}>
                                {localizer.msg('common.actions.save')}
                            </button>
                        </div>
                    </Fragment>
                }
            </div>
            {
                !showNoteField && savedNotes.length > 0 && ratingState > 0 &&
                <div>
                    <label className="fw-bold">{localizer.msg('stage.assessment.note-heading')}</label>
                    <div>
                        <span>{savedNotes}</span>
                        <button className="btn btn-link p-0 pb-1 ms-1 text-decoration-none shadow-none"
                                title={localizer.msg('stage.assessment.edit-note')}
                                onClick={() => setShowNoteField(true)}>
                            <Icon iconSpritePath={svgIconsPath} name="pencil" width={14} height={14}/>
                            <span className="sr-only">{localizer.msg('stage.assessment.edit-note')}</span>
                        </button>
                    </div>
                </div>
            }
        </div>
    );
};