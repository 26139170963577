import {useRoutesMatch} from '@ideascale/commons';
import {ROUTES} from 'shared/Routes';

const PROFILE_ROUTES = [ROUTES.PROFILE.PROFILE_PATH, ROUTES.PROFILE.MESSAGES_PATH, ROUTES.PROFILE.NOTIFICATIONS_PATH, ROUTES.PROFILE.MY_IDEAS_PATH];
const HOME_ROUTES = ['/', ROUTES.HOME, ROUTES.ALL_ACTIVE_IDEAS, ROUTES.ALL_EXPIRED_IDEAS];
type ProfileRouteType = { memberId: number }

export const useRouteUtils = () => {
    const profileRoutesMatch = useRoutesMatch<ProfileRouteType>(PROFILE_ROUTES);
    const homeRouteMatch = useRoutesMatch(HOME_ROUTES);
    const campaignRouteMatch = useRoutesMatch(ROUTES.CAMPAIGN.BASE_WITH_WILDCARD);
    const aboutCampaignRouteMatch = useRoutesMatch(ROUTES.CAMPAIGN.ABOUT_PATH);
    const landingPageRouteMatch = useRoutesMatch(ROUTES.LANDING);
    const landingPageActionRouteMatch = useRoutesMatch(ROUTES.LANDING_PAGE_ACTION);

    return {
        profileRoutesMatch,
        homeRouteMatch,
        campaignRouteMatch,
        landingPageRouteMatch,
        aboutCampaignRouteMatch,
        landingPageActionRouteMatch,
    };
};