import React, {Fragment, useCallback, useMemo} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import range from 'lodash/range';
import {Button, Icon, Skeleton} from '@ideascale/ui';
import {
    ClassificationLabel,
    ClassificationUtil,
    eventDispatcher,
    KEYBOARD_KEYS,
    Localizer,
    ServiceError,
    SimpleDropdown,
    TimeAgo,
    useApiErrorResponseHandler,
    useToggle,
} from '@ideascale/commons';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useAppContext} from 'contexts/AppContext';
import {DraftIdea} from 'models/DraftIdea';
import {IDEA_EVENTS, QUERY_KEYS} from 'constants/AppConstants';
import './DraftIdeas.scss';

type DraftIdeasProps = {
    localizer: Localizer;
    fetchDraftIdeas: () => Promise<DraftIdea[]>;
    deleteDraftIdea: (ideaId: number) => Promise<void>;
    onClickDraft: (ideaId: number, submitterId: number) => void;
}

export const DraftIdeas = (props: DraftIdeasProps) => {
    const {localizer, fetchDraftIdeas, deleteDraftIdea, onClickDraft} = props;
    const {authentication, communityConfig} = useAppContext();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const queryClient = useQueryClient();
    const [showDrafts, toggleDrafts] = useToggle(false);
    const {
        data: draftIdeas,
        isLoading,
    } = useQuery(QUERY_KEYS.DRAFT_IDEAS, () => fetchDraftIdeas(),
        {
            onError: (e: Error | ServiceError) => {
                handleErrorResponse(e);
            }
        });
    const onDeleteDraft = useMutation((id: number) => deleteDraftIdea(id), {
        onSuccess: async (data, id) => {
            queryClient.setQueryData<DraftIdea[] | undefined>(QUERY_KEYS.DRAFT_IDEAS, (oldDraftIdeas) => {
                if (oldDraftIdeas) {
                    eventDispatcher.dispatch(IDEA_EVENTS.DRAFT_IDEA_DELETED, id);
                    return oldDraftIdeas.filter(idea => idea.id !== id);
                }
                return [];
            });
        },
        onError: (e: Error) => {
            handleErrorResponse(e);
        }
    });

    const renderLoader = useMemo(() => {
        return (
            <Fragment>
                {range(0, 3).map(index =>
                    <li key={index}>
                        <div className="w-75">
                            <Skeleton/>
                            <Skeleton width="120px"/>
                        </div>
                    </li>
                )}
            </Fragment>
        );
    }, []);

    const renderIdeas = useCallback(() => {
        return draftIdeas && draftIdeas.map(idea =>
            <div key={idea.id} className="draft-item d-flex justify-content-between align-items-start list-unstyled">
                <li className="flex-grow-1 pe-2 text-break text-start"
                    role="button"
                    aria-label={localizer.msg('idea.draft-item', {
                        title: idea.title
                    })}
                    onClick={() => onClickDraft(idea.id, idea.submitterId)}
                    onKeyUp={(event) => {
                        if (event.key === KEYBOARD_KEYS.ENTER) {
                            onClickDraft(idea.id, idea.submitterId);
                        }
                    }}
                    tabIndex={0}>
                    <strong className="d-block">
                        {
                            communityConfig.classificationEnabled && idea.classificationSummary &&
                            <ClassificationLabel
                                classification={ClassificationUtil.getClassificationAttribute(idea.classificationSummary)}
                                extraClasses="me-1"/>

                        }
                        {idea.title}
                    </strong>
                    <span className="font-size-sm">
                        <TimeAgo localizer={localizer} dateISOString={idea.createdAt} prefixText={false}/>
                    </span>
                </li>
                {
                    idea.submitterId === authentication.actor.id &&
                    <Button color="default"
                            type="button"
                            disabled={onDeleteDraft.isLoading && isLoading}
                            onClick={(e) => {
                                e.stopPropagation();
                                !onDeleteDraft.isLoading && !isLoading && onDeleteDraft.mutate(idea.id);
                            }}>
                        <Icon iconSpritePath={svgIconsPath} name="trash-can-open"/>
                        <span className="sr-only">Delete</span>
                    </Button>
                }
            </div>
        );
    }, [authentication.actor.id, communityConfig.classificationEnabled, draftIdeas, isLoading, localizer, onClickDraft, onDeleteDraft]);

    return (
        <Fragment>
            <SimpleDropdown open={showDrafts}
                            toggle={toggleDrafts}
                            dropdownClassName="draft-list"
                            toggleContent={
                                <Fragment>
                                    {localizer.msg('idea.draft-with-count', {count: (draftIdeas?.length || 0)})}
                                    <Icon iconSpritePath={svgIconsPath} name="triangle-down" width={14} height={14}/>
                                </Fragment>

                            }
                            toggleClassName="btn-draft px-0"
                            menuClassName="draft-ideas py-2"
                            align="right"
                            slide="right"
            >
                {
                    isLoading
                        ?
                        renderLoader
                        :
                        draftIdeas && draftIdeas.length > 0
                            ?
                            renderIdeas()
                            :
                            <li>{localizer.msg('idea.no-draft')}</li>
                }
            </SimpleDropdown>
        </Fragment>
    );
};
