import React from 'react';
import {Actor, Localizer, Member, MemberLink, TimeAgo} from '@ideascale/commons';
import {KudosAction} from 'shared/KudosAction';
import {LastEditedBy} from './LastEditedBy';
import {KudoActivitySummary} from 'models/KudoActivitySummary';
import {CommentSummary} from 'models/comments/CommentSummary';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import styles from './Comment.module.scss';

type CommentMetaProps = {
    elementId: string;
    comment: CommentSummary;
    localizer: Localizer;
    actor: Actor;
    createdAt: string;
    fetchKudoGivers?: (pageParameters: PageParameters, commentId: number) => Promise<PagedResponseContent<Member>>;
    onGiveKudo?: (commentId: number) => Promise<KudoActivitySummary>;
}

export const CommentMeta = (props: CommentMetaProps) => {
    const {elementId, localizer, comment, actor, createdAt, onGiveKudo, fetchKudoGivers} = props;

    return (
        <div className={styles.commentMeta}>
            <MemberLink id={comment.author?.id} identityHidden={comment.author?.identityHidden}>
                <strong
                    className="me-2">
                    {actor.id === comment.author?.id ? localizer.msg('common.you') : comment.author.name}
                </strong>
            </MemberLink>
            {
                actor.id !== comment.author.id && comment.author.username &&
                <span className="text-muted me-2">(@{comment.author.username})</span>
            }
            {
                onGiveKudo && <KudosAction
                    elementId={elementId}
                    className="me-2"
                    id={comment.id}
                    kudosCount={comment.kudoCount}
                    kudoGiven={comment.kudoGivenByActor}
                    canGiveKudos={actor.id !== comment.author.id}
                    giveKudos={() => onGiveKudo(comment.id)}
                    fetchKudoGivers={fetchKudoGivers}/>
            }
            <TimeAgo localizer={localizer} dateISOString={createdAt} prefixText={false}/>
            {
                comment?.lastEditedBy
                    ? (
                        <>
                            {' '}(<LastEditedBy localizer={localizer} actor={actor} editedBy={comment.lastEditedBy}
                                                time={comment.updatedAt}/>)
                        </>
                    )
                    : null
            }
        </div>
    );
};
