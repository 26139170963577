import React, {useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {PopoverBody, UncontrolledPopover} from 'reactstrap';
import {DateTime} from 'luxon';
import {Icon} from '@ideascale/ui';
import iconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {ClassificationLabel, DateTimeUtil, StringUtil, useApplicableClassifications} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {HtmlRenderer} from 'components/shared/HtmlRenderer';
import {SidebarCampaign} from 'models/SidebarCampaign';
import {CampaignSummary} from 'models/CampaignSummary';
import {useAppContext} from 'contexts/AppContext';
import {appLinks} from 'services/AppLinks';
import logo from 'assets/img/logo/logo.png';

const CAMPAIGN_SUMMARY_SHOW_DELAY = 500;

type CampaignLinkProps = {
    campaign: SidebarCampaign,
    fetchSidebarCampaignSummary(id: number): Promise<CampaignSummary>,
    campaignStatus?: 'Active' | 'Archive'
}

export const CampaignLink = ({campaign, fetchSidebarCampaignSummary, campaignStatus = 'Active'}: CampaignLinkProps) => {
    const localizer = useLocalizer();
    const {communityConfig} = useAppContext();
    const [campaignSummary, setCampaignSummary] = useState<CampaignSummary>(CampaignSummary.EMPTY);
    const summaryResponseTimeout = useRef<NodeJS.Timeout>();
    const {getClassificationAttribute} = useApplicableClassifications('', []);

    const campaignLinkRef = useRef<HTMLAnchorElement>(null);

    const onCampaignFocused = (campaign: SidebarCampaign) => {
        campaignLinkRef.current?.setAttribute('aria-describedby', `campaign-${campaign.id}-tooltip`);
        summaryResponseTimeout.current = setTimeout(async () => {
            const campaignSummaryResponse = await fetchSidebarCampaignSummary(campaign.id);
            setCampaignSummary(campaignSummaryResponse);
        }, CAMPAIGN_SUMMARY_SHOW_DELAY);
    };

    const onCampaignLeft = () => {
        campaignLinkRef.current?.removeAttribute('aria-describedby');
        if (summaryResponseTimeout.current) {
            clearTimeout(summaryResponseTimeout.current);
        }
    };

    return <div className="campaign-link-container"
                id={`campaign-${campaign.id}`}
                onMouseEnter={() => onCampaignFocused(campaign)}
                onMouseLeave={onCampaignLeft}
                onFocus={() => onCampaignFocused(campaign)}
                onBlur={onCampaignLeft}>
        <div className="mx-4 pt-1">
            {communityConfig.classificationEnabled && campaign.classificationSummary &&
                <ClassificationLabel classification={getClassificationAttribute(campaign.classificationSummary)}
                                     extraClasses="cursor-pointer"/>
            }
        </div>
        <Link className="d-flex align-items-center pt-1 pb-1 ps-4 pe-4 d-block w-100 text-decoration-none"
              title={campaign.name}
              ref={campaignLinkRef}
              to={campaign.defaultStage
                  ? appLinks.ideas(String(campaign.id), campaign.defaultStage.key)
                  : appLinks.campaign(String(campaign.id))}>
            <img className="campaign-logo campaign-logo-sm me-2" src={campaign.image ? campaign.image : logo}
                 width="20" height="20" alt={campaign.altText}/>
            <span className="w-100 d-flex flex-column">
                <span className="campaign-name">
                    {StringUtil.textTruncate(campaign.name, campaign.privateCampaign ? 22 : 30)}
                </span>
                {
                    campaign.expiryDate &&
                    <span className="font-size-sm font-italic">
                        {localizer.msg(campaignStatus === 'Archive' ? 'sidebar.campaign.expired-on' : 'sidebar.campaign.expires-on')} {DateTimeUtil.convertToShortDate(DateTime.fromISO(campaign.expiryDate))}
                    </span>
                }
            </span>
            {
                campaign.privateCampaign
                    ? (
                        <span className="private-campaign"
                              title={localizer.msg('sidebar.campaign.private-campaign')}>
                            <Icon className="ms-2 active"
                                  name="lock-closed-rounded"
                                  iconSpritePath={iconPath}
                                  width={14}
                                  height={14}/>
                        </span>
                    )
                    : null
            }
        </Link>
        <UncontrolledPopover className="me-n2 campaign-summary-container"
                             id={`campaign-${campaign.id}-tooltip`}
                             trigger="hover focus"
                             delay={{show: CAMPAIGN_SUMMARY_SHOW_DELAY, hide: 0}}
                             placement="left"
                             target={`campaign-${campaign.id}`}>
            <PopoverBody className="p-0">
                {
                    campaignSummary.featuredImageUrl
                        ? (
                            <div className="feature-img mb-0">
                                <img src={campaignSummary.featuredImageUrl}
                                     alt={campaignSummary.featuredImageAltText}
                                     width="300"
                                     height={300}
                                     className="img-fluid"/>
                            </div>
                        )
                        : null
                }
                <div className="content p-3">
                    {
                        campaignSummary.description &&
                        <HtmlRenderer content={campaignSummary.description}
                                      className="campaign-popover-description overflow-auto mb-2"/>
                    }
                    <div className="campaign-stats d-flex justify-content-between">
                        <div className="ideas-posted d-flex align-items-center">
                            <Icon name="lightbulb" iconSpritePath={iconPath}
                                  fill={'#767676'}
                                  width={26} height={26}
                                  className="me-1 ms-n2"/>
                            <span aria-hidden="true">{campaignSummary.ideaCount}</span>
                            <span
                                className="sr-only">{localizer.msg('campaign.stages.ideas', {count: campaignSummary.ideaCount})}</span>
                        </div>
                        <div className="ideas-comment mt-1">
                            <Icon name="message-solid-left"
                                  iconSpritePath={iconPath}
                                  fill={'#767676'}
                                  width={20} height={20} className="me-2"/>
                            <span aria-hidden="true">{campaignSummary.commentCount}</span>
                            <span
                                className="sr-only">{localizer.msg('profile.about-me.comments', {count: campaignSummary.commentCount})}</span>
                        </div>
                    </div>
                </div>
            </PopoverBody>
        </UncontrolledPopover>
    </div>;
};