import {Command} from './Command';
import {CampaignHomeEditor} from './Editors/CampaignHomeEditor';
import {Operation} from 'models/edit-mode/Operation';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';
import {TosConfigField} from 'models/edit-mode/TosConfigField';
import {CampaignEditableFieldType} from 'models/edit-mode/CampaignEditableFieldType';
import {ChangeCampaignTosOperation} from 'models/edit-mode/ChangeCampaignTosOperation';

export class UpdateCampaignTosCommand implements Command {
    private readonly campaignHomeEditor: CampaignHomeEditor;
    private readonly oldCampaignTos: TosConfigField;
    private readonly newCampaignTos: TosConfigField;
    private readonly name: CampaignOperationType;
    private readonly campaignId: number;

    constructor(campaignHomeEditor: CampaignHomeEditor, tosContent: string, tosEnabled: boolean, campaignId: number) {
        this.campaignHomeEditor = campaignHomeEditor;
        this.oldCampaignTos = this.campaignHomeEditor.tos;
        this.newCampaignTos = {
            name: CampaignEditableFieldType.CAMPAIGN_TOS,
            value: tosContent,
            tosEnabled: tosEnabled
        };
        this.campaignId = campaignId;
        this.name = CampaignOperationType.CHANGE_CAMPAIGN_TOS;
    }

    execute(): void {
        this.campaignHomeEditor.tos = this.newCampaignTos;
    }

    undo(): void {
        this.campaignHomeEditor.tos = this.oldCampaignTos;
    }

    getType(): CampaignOperationType {
        return this.name;
    }

    getOperation(): Operation {
        return new ChangeCampaignTosOperation(
            this.name,
            this.campaignHomeEditor.tos.value,
            this.campaignHomeEditor.tos.tosEnabled,
            this.campaignId);
    }
}