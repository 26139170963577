import React, {useEffect, useState} from 'react';
import {Localizer} from '@ideascale/commons';
import {CollapseExpand, ParagraphSkeleton} from '@ideascale/ui';
import iconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {SidebarSocialSubscription} from 'models/SidebarSocialSubscription';


type SocialSubscribeProps = {
    localizer: Localizer
    fetchSocialSubscription: () => Promise<SidebarSocialSubscription>
}

export const SocialSubscribe = (props: SocialSubscribeProps) => {
    const {localizer, fetchSocialSubscription} = props;
    const [loading, setLoading] = useState(true);
    const [subscriptionData, setSubscriptionData] = useState(SidebarSocialSubscription.Empty);

    useEffect(() => {
        fetchSocialSubscription().then(data => {
            setLoading(false);
            setSubscriptionData(data);
        });
    }, [fetchSocialSubscription]);

    return (
        subscriptionData.followOnTwitterUrl ? (
            <CollapseExpand containerClass="social-subscribe panel-light"
                            headerTitle={localizer.msg('sidebar.subscribe.header-title')}
                            svgIconSprite={iconPath}
                            defaultOpen={true}>
                {loading ? (<ParagraphSkeleton rows={4}/>) : (
                    <ul className="list-unstyled">
                        {
                            subscriptionData.followOnTwitterUrl &&
                            <li className="mb-1">
                                <a className="text-decoration-none" href={subscriptionData.followOnTwitterUrl}>
                                    {localizer.msg('sidebar.subscribe.twitter')}
                                </a>
                            </li>
                        }
                    </ul>
                )}
            </CollapseExpand>
        ) : null
    );
};
