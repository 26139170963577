import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Button, Icon} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {
    AlertEvent,
    AlertType,
    buildAlertEventData,
    ErrorReason,
    eventDispatcher,
    LandingPageMode,
    useApiErrorResponseHandler,
    useToggle,
    useUrlQuery,
} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {useNavigate, useParams} from 'react-router-dom';
import {useAppContext} from 'contexts/AppContext';
import {useLandingPageContext} from 'contexts/LandingPageContext';
import {useLandingPageService} from 'hooks/useService';
import {appLinks} from 'services/AppLinks';
import {LandingPageSettings} from 'components/topbar/LandingPageSettings';
import {CreateNewVersionModal} from 'components/landing-page/CreateNewVersionModal';
import {TopbarCommonEditModeActions} from 'components/topbar/TopbarCommonEditModeActions';
import {UnsavedContentConfirmationModal} from 'components/landing-page/UnsavedContentConfirmationModal';
import {LandingPageEditConfirmationModal} from 'components/landing-page/LandingPageEditConfirmationModal';
import {VersionPayload} from 'models/landing-page/VersionPayload';
import {LandingPageData} from 'models/landing-page/LandingPageData';
import {LandingPageVersion} from 'models/types/LandingPageVersion';
import {LandingPageResponse} from 'models/landing-page/LandingPageResponse';
import {LandingPagePermissions} from 'models/landing-page/LandingPagePermissions';
import {LandingPagePreviewParams} from 'models/types/LandingPagePreviewParams';
import {LandingPageVersionResponse} from 'models/landing-page/LandingPageVersionResponse';
import {ROUTES} from 'shared/Routes';


export const LandingPageTopbarEditModeContainer = () => {
    const navigate = useNavigate();
    const query = useUrlQuery();
    const localizer = useLocalizer();
    const {
        landingPage,
        setLoading,
        loading,
        setLandingPage,
        setTranslationMode,
        translationMode,
        selectedVersionData,
        setSelectedVersionData,
        componentEdited,
        handleComponentEdit,
    } = useLandingPageContext();
    const {permissions, id, translatedLanguageId, publishedVersion, contentModified} = landingPage;
    const {editModeEnabled, setLandingPagePublished} = useAppContext();
    const landingPageService = useLandingPageService();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const params = useParams<LandingPagePreviewParams>();
    const [showEditConfirmationModal, toggleEditConfirmationModal] = useToggle(false);
    const [showCreateNewVersionModal, toggleCreateNewVersionModal] = useToggle(false);
    const [showUnsavedConfirmationModal, toggleUnsavedConfirmationModal] = useToggle(false);
    const [versions, setVersions] = useState<LandingPageVersion[]>([]);
    const [landingPageTemplateData, setLandingPageTemplateData] = useState<LandingPageResponse>(LandingPageResponse.EMPTY);

    const fetchLandingPageTemplatesData = useCallback(async () => {
        if (landingPageService !== null) {
            return await landingPageService.fetchLandingPageList();
        }
        return LandingPageResponse.EMPTY;
    }, [landingPageService]);

    const fetchVersions = useCallback(async () => {
        if (landingPageService !== null) {
            return await landingPageService.fetchPageVersions({
                edit: permissions.edit,
                translation: permissions.translation
            }, id);
        }
        return LandingPageVersionResponse.EMPTY;
    }, [id, landingPageService, permissions.edit, permissions.translation]);

    const fetchPageByIdAndVersion = useCallback(async (permissions: LandingPagePermissions, pageId: number, version: number) => {
        if (landingPageService !== null) {
            try {
                return landingPageService.fetchPageByIdAndVersion(permissions, pageId, version);
            } catch (e: any) {
                handleErrorResponse(e);
            }
        }
        return LandingPageData.EMPTY;
    }, [handleErrorResponse, landingPageService]);

    const fetchPageById = useCallback(async (pageId: number) => {
        if (landingPageService !== null) {
            try {
                return landingPageService.fetchPageById(pageId);
            } catch (e: any) {
                handleErrorResponse(e);
            }
        }
        return LandingPageData.EMPTY;
    }, [handleErrorResponse, landingPageService]);

    const cancelEditing = useCallback(async () => {
        if (landingPageService !== null) {
            try {
                setLoading(true);
                await landingPageService.cancelEditing(id);
                let data: LandingPageData;
                if (selectedVersionData) {
                    data = await fetchPageByIdAndVersion(permissions, id, selectedVersionData.version);
                } else {
                    data = await fetchPageById(id);
                }
                handleComponentEdit(false);
                setLandingPage(data);
            } catch (e: any) {
                handleErrorResponse(e);
            } finally {
                setLoading(false);
            }
        }
    }, [fetchPageById, fetchPageByIdAndVersion, handleComponentEdit, handleErrorResponse, id, landingPageService, permissions, selectedVersionData, setLandingPage, setLoading]);

    const onCancelEditMode = useCallback(() => {
        const newUrlQuery = new URLSearchParams(query);
        newUrlQuery.delete(ROUTES.SEARCH_QUERY.EDIT_MODE);
        navigate(appLinks.landing(newUrlQuery));
    }, [navigate, query]);

    const onLanguageOrVersionChanged = useCallback((languageId: number, version: number) => {
        if (landingPageService !== null) {
            try {
                setLoading(true);
                landingPageService.fetchPageByLanguageAndVersion(id, languageId, version).then(data => {
                    setLandingPage(data);
                    setTranslationMode(true);
                    setLoading(false);
                });
            } catch (e: any) {
                handleErrorResponse(e);
            }
        }
    }, [handleErrorResponse, id, landingPageService, setLandingPage, setLoading, setTranslationMode]);

    const onVersionChange = useCallback(async (versionData: LandingPageVersion) => {
        setLoading(true);
        if (translationMode) {
            onLanguageOrVersionChanged(translatedLanguageId, versionData.version);
            setSelectedVersionData(versionData);
        } else {
            void fetchPageByIdAndVersion(permissions, id, versionData.version).then(data => {
                setLandingPage(data);
                setSelectedVersionData(versionData);
                setLoading(false);
            });
        }
    }, [fetchPageByIdAndVersion, id, onLanguageOrVersionChanged, permissions, setLandingPage, setLoading, setSelectedVersionData, translatedLanguageId, translationMode]);

    const onVersionEdit = useCallback(async (pageId: number, version: number, newVersion: VersionPayload) => {
        if (landingPageService !== null) {
            return landingPageService.editVersion(pageId, version, newVersion);
        }
        return LandingPageVersion.EMPTY;
    }, [landingPageService]);

    const onVersionDelete = useCallback(async (pageId: number, version: number) => {
        if (landingPageService !== null) {
            try {
                setLoading(true);
                await landingPageService.deleteVersion(pageId, version);
            } catch (e: any) {
                handleErrorResponse(e);
            } finally {
                setLoading(false);
            }
        }
    }, [handleErrorResponse, landingPageService, setLoading]);

    const startEditing = useCallback(async (pageId: number, version?: number, onSuccess?: () => void) => {
        if (landingPageService !== null) {
            try {
                setLoading(true);
                let data;
                if (version !== undefined) {
                    data = await landingPageService.startEditing(pageId, version);
                } else {
                    data = await landingPageService.startEditing(pageId);
                }
                onSuccess?.();
                setLandingPage(data);
            } catch (e: any) {
                handleErrorResponse(e, {
                    errorHandler: (error: any) => {
                        if (error.data?.reason === ErrorReason.PAGE_DRAFT_ALREADY_EXISTS) {
                            toggleEditConfirmationModal(true);
                        } else if (error.data?.reason === ErrorReason.PAGE_VERSIONS_COUNT_EXCEED) {
                            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(
                                AlertType.warn,
                                localizer.msg(`frontend-shared.errors.${error.data?.reason?.toLowerCase()}`)
                            ));
                            if (params.mode === LandingPageMode.EDIT && params.pageId !== undefined) {
                                navigate(appLinks.landingPageAction(LandingPageMode.SELECT, String(+params.pageId), editModeEnabled));
                            }
                        } else {
                            if (error?.message) {
                                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.error, error.message));
                            }
                        }
                    }
                });
            } finally {
                setLoading(false);
            }
        }
    }, [editModeEnabled, handleErrorResponse, landingPageService, localizer, navigate, params.mode, params.pageId, setLandingPage, setLoading, toggleEditConfirmationModal]);

    const onContinue = useCallback(async () => {
        await startEditing(id, undefined, () => {
            navigate(appLinks.landingPageAction(LandingPageMode.EDIT, String(landingPage.id), editModeEnabled));
        });
        return Promise.resolve(true);
    }, [editModeEnabled, id, landingPage.id, navigate, startEditing]);

    const onStartFresh = useCallback(async () => {
        if (landingPageService !== null && selectedVersionData) {
            await landingPageService.cancelEditing(id);
            void startEditing(id, selectedVersionData.version, () => {
                navigate(appLinks.landingPageAction(LandingPageMode.EDIT, String(landingPage.id), editModeEnabled));
            });
        }
        return Promise.resolve(true);
    }, [landingPageService, selectedVersionData, id, startEditing, navigate, landingPage.id, editModeEnabled]);

    const onPagePublish = useCallback(async (version?: number) => {
        if (landingPageService !== null) {
            try {
                setLoading(true);
                let data;
                if (version !== undefined) {
                    data = await landingPageService.publishLandingPage(id, version);
                } else {
                    data = await landingPageService.publishLandingPage(id);
                }
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(
                    AlertType.success,
                    localizer.msg('landing-page.action-topbar.publish-successful'))
                );
                setLandingPagePublished(true);
                setLandingPage(data);
                navigate(ROUTES.LANDING);
            } catch (e: any) {
                handleErrorResponse(e);
            } finally {
                setLoading(false);
            }
        }
    }, [landingPageService, setLoading, id, localizer, setLandingPagePublished, setLandingPage, navigate, handleErrorResponse]);

    const getVersions = useCallback(async () => {
        if (landingPageService !== null) {
            try {
                const data = await fetchVersions();
                setVersions(data.versions);
            } catch (error) {
                handleErrorResponse(error);
            }
        }
    }, [fetchVersions, handleErrorResponse, landingPageService]);

    const getLandingPageTemplates = useCallback(async () => {
        if (landingPageService !== null) {
            try {
                setLoading(true);
                const data = await fetchLandingPageTemplatesData();
                setLandingPageTemplateData(data);
            } catch (error) {
                handleErrorResponse(error);
            } finally {
                setLoading(false);
            }
        }
    }, [fetchLandingPageTemplatesData, handleErrorResponse, landingPageService, setLoading]);

    const onStartTranslation = useCallback((languageId: number, version: number) => {
        onLanguageOrVersionChanged(languageId, version);
        handleComponentEdit(false);
    }, [handleComponentEdit, onLanguageOrVersionChanged]);

    const onLanguageChange = useCallback((languageId: number, version: number) => {
        onLanguageOrVersionChanged(languageId, version);
    }, [onLanguageOrVersionChanged]);

    const onFinishTranslation = useCallback(() => {
        if (selectedVersionData) {
            setLoading(true);
            void fetchPageByIdAndVersion(permissions, id, selectedVersionData.version).then((data) => {
                setLandingPage(data);
                setLoading(false);
                setTranslationMode(false);
                handleComponentEdit(false);
            });
        }
    }, [fetchPageByIdAndVersion, handleComponentEdit, id, permissions, selectedVersionData, setLandingPage, setLoading, setTranslationMode]);

    const onDoneEditing = useCallback(async (landingPageData: LandingPageData) => {
        setLandingPage(landingPageData);
        setVersions(prev => [landingPageData.currentVersion, ...prev]);
        toggleCreateNewVersionModal();
        setSelectedVersionData(landingPageData.currentVersion);
        handleComponentEdit(false);
        navigate(appLinks.landingPageAction(LandingPageMode.SELECT, String(id), editModeEnabled));
    }, [editModeEnabled, handleComponentEdit, id, navigate, setLandingPage, setSelectedVersionData, toggleCreateNewVersionModal]);

    const onDoneEditingAndPublish = useCallback(async (landingPageData: LandingPageData) => {
        if (landingPageService) {
            try {
                setLoading(true);
                setVersions(prev => [landingPageData.currentVersion, ...prev]);
                toggleCreateNewVersionModal();
                setLandingPage(landingPageData);
                await onPagePublish(landingPageData.currentVersion.version);
                setSelectedVersionData(landingPageData.currentVersion);
                handleComponentEdit(false);
                onCancelEditMode();
            } catch (error: any) {
                handleErrorResponse(error);
            } finally {
                setLoading(false);
            }
        }
    }, [handleComponentEdit, handleErrorResponse, landingPageService, onCancelEditMode, onPagePublish, setLandingPage, setLoading, setSelectedVersionData, toggleCreateNewVersionModal]);

    const onSavePage = useCallback(async (pageVersion: VersionPayload, publish: boolean) => {
        if (landingPageService) {
            return landingPageService.doneEditing(id, {pageVersion, publish});
        }
        return LandingPageData.EMPTY;
    }, [id, landingPageService]);

    const onPreview = useCallback(() => {
        navigate(appLinks.landingPageAction(LandingPageMode.PREVIEW, String(id), editModeEnabled));
        if (params.pageId !== undefined) {
            void startEditing(+params.pageId);
        }
    }, [editModeEnabled, id, navigate, params.pageId, startEditing]);

    const onClosePreview = useCallback(() => {
        navigate(appLinks.landingPageAction(LandingPageMode.EDIT, String(id), editModeEnabled));
    }, [editModeEnabled, id, navigate]);

    const onDiscardUnsavedChanges = useCallback(() => {
        navigate(appLinks.landingPageAction(LandingPageMode.SELECT, String(id), editModeEnabled));
        toggleUnsavedConfirmationModal(false);
        void cancelEditing();
    }, [cancelEditing, editModeEnabled, id, navigate, toggleUnsavedConfirmationModal]);

    const onGoBack = useCallback(() => {
        if (componentEdited || contentModified) {
            toggleUnsavedConfirmationModal(true);
        } else {
            onDiscardUnsavedChanges();
        }
    }, [componentEdited, contentModified, onDiscardUnsavedChanges, toggleUnsavedConfirmationModal]);

    useEffect(() => {
        const pageId = params?.pageId ? +params.pageId : undefined;
        if (selectedVersionData === undefined && pageId !== undefined) {
            if ([LandingPageMode.EDIT, LandingPageMode.PREVIEW].includes(params?.mode!)) {
                void startEditing(pageId);
            } else if ([LandingPageMode.SELECT].includes(params?.mode!)) {
                setLoading(true);
                fetchPageById(pageId).then((data) => {
                    setLandingPage(data);
                    setSelectedVersionData(data.currentVersion);
                    setLoading(false);
                });
            }
        }
    }, [fetchPageById, params?.mode, params.pageId, selectedVersionData, setLandingPage, setLoading, setSelectedVersionData, startEditing]);

    useEffect(() => {
        if (selectedVersionData === undefined) {
            if (publishedVersion && publishedVersion !== LandingPageVersion.EMPTY) {
                setSelectedVersionData(publishedVersion);
            } else {
                setSelectedVersionData(versions[versions.length - 1]);
            }
        }
    }, [publishedVersion, selectedVersionData, setSelectedVersionData, versions]);

    useEffect(() => {
        if (permissions.edit) {
            void getLandingPageTemplates();
        }
    }, [getLandingPageTemplates, permissions.edit]);

    useEffect(() => {
        if (landingPage.id !== LandingPageData.EMPTY.id) {
            void getVersions();
        }
    }, [getVersions, landingPage.id]);

    return (
        <Fragment>
            {
                params.mode === LandingPageMode.EDIT
                    ?
                    <Fragment>
                        <Button color="cancel" className="edit-mode-element px-0 btn-back" onClick={onGoBack}
                                disabled={loading} title={localizer.msg('common.back')}>
                            <Icon className="position-relative pos-top-n1 me-2" width={20} height={20}
                                  iconSpritePath={svgIconsPath} name="arrow-left"/>
                            {localizer.msg('common.back')}
                        </Button>
                        <div>
                            <Button className="edit-mode-element btn-back" color="secondary" onClick={onPreview}
                                    disabled={loading} title={localizer.msg('landing-page.action-topbar.preview')}
                                    data-test-element-id="btn-preview">
                                <Icon className="active position-relative pos-top-n1 me-2" width={11} height={11}
                                      iconSpritePath={svgIconsPath} name="play"/>
                                {localizer.msg('landing-page.action-topbar.preview')}
                            </Button>
                            <Button className="edit-mode-element ms-3" color="primary"
                                    disabled={loading || !componentEdited || (contentModified !== undefined && !contentModified)}
                                    onClick={toggleCreateNewVersionModal} title={localizer.msg('common.done-editing')}
                                    data-test-element-id="btn-done-edit">
                                <Icon className="me-1 fill-white" width={24} iconSpritePath={svgIconsPath}
                                      name="check"/>
                                {localizer.msg('common.done-editing')}
                            </Button>
                        </div>
                    </Fragment>
                    :
                    <Fragment>
                        <LandingPageSettings mode={params.mode}
                                             versions={versions}
                                             localizer={localizer}
                                             setVersions={setVersions}
                                             onVersionEdit={onVersionEdit}
                                             onStartEditing={startEditing}
                                             onVersionDelete={onVersionDelete}
                                             onVersionChange={onVersionChange}
                                             onLanguageChange={onLanguageChange}
                                             fetchLandingPageById={fetchPageById}
                                             onStartTranslation={onStartTranslation}
                                             landingPageTemplates={landingPageTemplateData}/>
                        <div>
                            {
                                params.mode !== LandingPageMode.PREVIEW
                                    ?
                                    translationMode
                                        ?
                                        <Fragment>
                                            <Button className="edit-mode-element me-3" color="secondary"
                                                    onClick={onCancelEditMode} disabled={loading}
                                                    title={localizer.msg('common.cancel')}>
                                                <Icon className="active position-relative pos-top-n1 me-2" width={11}
                                                      iconSpritePath={svgIconsPath} name="cross"/>
                                                {localizer.msg('common.cancel')}
                                            </Button>
                                            <Button className="edit-mode-element" color="primary"
                                                    onClick={onFinishTranslation}
                                                    disabled={loading || !componentEdited || (contentModified !== undefined && !contentModified)}
                                                    title={localizer.msg('common.finish')}>
                                                <Icon className="me-1 active" name="check" width={15} height={15}
                                                      iconSpritePath={svgIconsPath}/>
                                                {localizer.msg('common.finish')}
                                            </Button>
                                        </Fragment>
                                        :
                                        <TopbarCommonEditModeActions localizer={localizer} onCancel={onCancelEditMode}
                                                                     cancelDisabled={loading}
                                                                     showPublishButton={permissions.edit}
                                                                     publishDisabled={(selectedVersionData?.version === publishedVersion?.version) || loading}
                                                                     onPublish={() => onPagePublish(selectedVersionData?.version)}/>
                                    :
                                    <Button color="secondary" className="edit-mode-element" onClick={onClosePreview}
                                            disabled={loading}
                                            title={localizer.msg('landing-page.action-topbar.close-preview')}>
                                        {localizer.msg('landing-page.action-topbar.close-preview')}
                                    </Button>
                            }
                        </div>
                    </Fragment>
            }
            {
                showEditConfirmationModal &&
                <LandingPageEditConfirmationModal localizer={localizer}
                                                  open={showEditConfirmationModal}
                                                  toggle={toggleEditConfirmationModal}
                                                  onContinue={onContinue}
                                                  onStartFresh={onStartFresh}/>
            }
            {
                showCreateNewVersionModal &&
                <CreateNewVersionModal localizer={localizer}
                                       onDoneEditing={onDoneEditing}
                                       onDoneEditingAndPublish={onDoneEditingAndPublish}
                                       onSave={onSavePage}
                                       open={showCreateNewVersionModal}
                                       toggle={toggleCreateNewVersionModal}/>
            }
            {
                showUnsavedConfirmationModal &&
                <UnsavedContentConfirmationModal localizer={localizer}
                                                 onDiscardUnsavedChanges={onDiscardUnsavedChanges}
                                                 open={showUnsavedConfirmationModal}
                                                 toggle={toggleUnsavedConfirmationModal}/>
            }
        </Fragment>
    );
};