import {Component} from 'models/landing-page/Component';
import {UiAttribute} from 'models/landing-page/UiAttribute';
import {InstructionListItem} from 'models/landing-page/InstructionListItem';
import {LandingPageComponentType} from 'models/enums/landing-page/LandingPageComponentType';
import {GeneralText} from 'models/landing-page/GeneralText';

export class InstructionListComponentData extends Component {
    public static EMPTY = new InstructionListComponentData(
        0,
        0,
        LandingPageComponentType.INSTRUCTIONS,
        false,
        UiAttribute.EMPTY,
        [],
        [],
        '',
        ''
    );

    constructor(
        public id: number,
        public languageId: number,
        public type: LandingPageComponentType.INSTRUCTIONS,
        public visible: boolean,
        public attribute: UiAttribute,
        public texts: GeneralText[],
        public items: InstructionListItem[],
        public title?: string,
        public subtitle?: string,
    ) {
        super(id, languageId, type, visible, attribute, title, subtitle);
    }
}