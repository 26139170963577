import React, {Fragment, useMemo} from 'react';
import {Icon} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {Localizer} from '@ideascale/commons';
import styles from './RatingView.module.scss';

type RatingViewProps = {
    localizer: Localizer;
    rating: number;
    zeroStarsAsText: boolean;
}

const iconCommonProps = {width: 18, height: 18, fill: '#333', iconSpritePath: svgIconPath};

const isHalfStarPrinted = (counter: number, avgRating: number) => {
    const fractionalNumber = getFractionalNumberOfAvgNumber(counter, avgRating);
    return (fractionalNumber >= 0.50 && fractionalNumber <= 0.99);
};

const getFractionalNumberOfAvgNumber = (counter: number, avgNumber: number) => {
    let fractionalNumber: number = 0.0;
    if (avgNumber > 0.0 && counter - avgNumber <= 0.50) {
        let integerPart: number = parseInt(String(avgNumber));
        fractionalNumber = avgNumber - integerPart;
    }
    return fractionalNumber;
};

export const RatingView = (props: RatingViewProps) => {
    const {localizer, rating, zeroStarsAsText} = props;

    const Stars = useMemo(() => {
        let stars: JSX.Element[] = [];
        for (let count = 1; count <= 5; count++) {
            if (count <= rating) {
                stars.push(<Icon name="star-solid" {...iconCommonProps} key={count}/>);
            } else if (isHalfStarPrinted(count, rating)) {
                stars.push(<Icon name="star-half" {...iconCommonProps} key={count}/>);
            } else {
                stars.push(<Icon name="star-outlined" {...iconCommonProps} key={count}/>);
            }
        }
        return stars;
    }, [rating]);

    return <Fragment>
        <div
            className="sr-only">{localizer.msg('stage.assessment.rating', {rating: (rating === 0 && zeroStarsAsText) ? 'NA' : rating})}</div>
        {
            (rating === 0 && zeroStarsAsText)
                ? <span className={styles.labelNa} aria-hidden="true">NA</span>
                : Stars
        }
    </Fragment>;
};