import {useCallback} from 'react';
import {CancelToken} from 'axios';
import {
    Campaign,
    CampaignsHolder,
    CampaignStage,
    EmailIdeaContent,
    EmailRequest,
    IdeaLabelsHolder,
    Member,
    TagParameters, TagSearchParameters,
    UnfurledUrl,
    useApiErrorResponseHandler
} from '@ideascale/commons';
import {useCommunityService} from './useService';
import {useLocalizer} from './useLocalizer';
import {AssignOwnersParams} from 'models/types/AssignOwnersParams';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {ChangeCampaignParams} from 'models/types/ChangeCampaignParams';
import {PageParameters} from 'models/types/PageParameters';
import {GenericResponse} from 'models/GenericResponse';
import {IdeaModerateActionResponse} from 'models/IdeaModerateActionResponse';
import {IdeaMoreActionResponse} from 'models/IdeaMoreActionResponse';
import {IdeaListViewMode} from 'models/enums/IdeaListViewMode';
import {IdeaSubmissionRequest} from 'models/IdeaSubmissionRequest';
import {ModifiedIdeaDetail} from 'models/ModifiedIdeaDetail';
import {KudoActivitySummary} from 'models/KudoActivitySummary';
import {LabelActionParams} from 'models/types/LabelActionParams';
import {IdeaReportingResponse} from 'models/types/IdeaReportingResponse';
import {VoteType} from 'models/enums/VoteType';
import {VoteParameters} from 'models/VoteParameters';
import {IdeaDetail} from 'models/IdeaDetail';
import {RevisionHistory} from 'models/RevisionHistory';
import {OriginalIdeaData} from 'models/OriginalIdeaData';
import {AttachmentParameters} from 'models/AttachmentParameters';
import {FileAttachmentsHolder} from 'models/FileAttachmentsHolder';
import {IdeaLabelActionResponse} from 'models/IdeaLabelActionResponse';
import {IdeaTeamMember} from 'models/IdeaTeamMember';
import {IdeaOwnerAssignmentResponse} from 'models/IdeaOwnerAssignmentResponse';

export const useIdeaService = () => {
    const communityService = useCommunityService();
    const localizer = useLocalizer();
    const {handleErrorResponse, throwApiErrorResponse} = useApiErrorResponseHandler({localizer});

    const changeStage = useCallback(async (ideaId: number, stageId: number) => {
        if (communityService !== null) {
            return await communityService.changeStage(ideaId, stageId);
        }
        return IdeaDetail.EMPTY;
    }, [communityService]);

    const deleteIdea = useCallback(async (ideaId: number) => {
        if (communityService !== null) {
            return await communityService.deleteIdea(ideaId);
        }
        return null;
    }, [communityService]);

    const assignOwners = useCallback(async ({data, ideaId}: AssignOwnersParams) => {
        if (communityService !== null) {
            return communityService.assignIdeaOwners(data, ideaId);
        }
        return IdeaOwnerAssignmentResponse.EMPTY;
    }, [communityService]);

    const fetchDropdownCampaigns = useCallback(async (params: PageParameters) => {
        if (communityService !== null) {
            return await communityService.fetchChangeCampaigns(params);
        }
        return PagedResponseContent.nullObject<CampaignsHolder<Campaign>>();
    }, [communityService]);

    const fetchCampaignStages = useCallback(async (ideaId: number, campaignId: number): Promise<CampaignStage[]> => {
        if (communityService !== null) {
            return await communityService.fetchCampaignStages(ideaId, campaignId);
        }
        return [];
    }, [communityService]);

    const changeCampaign = useCallback(async ({
                                                  ideaId,
                                                  campaignId,
                                                  stageId,
                                                  isDifferentStage,
                                                  tosAccepted
                                              }: ChangeCampaignParams) => {
        if (communityService !== null) {
            try {
                return await communityService.changeCampaign(ideaId, campaignId, stageId, isDifferentStage, tosAccepted);
            } catch (error: any) {
                throw error;
            }
        }
    }, [communityService]);

    const fetchMembers = useCallback(async (data: PageParameters, ideaId: number) => {
        if (communityService !== null) {
            return communityService.fetchMembers(data, ideaId);
        }
        return PagedResponseContent.nullObject<Member>();
    }, [communityService]);

    const fetchAssignedOwners = useCallback(async (ideaId: number) => {
        if (communityService !== null) {
            return communityService.fetchIdeaAssignedOwners(ideaId);
        }
        return [];
    }, [communityService]);

    const fetchIdeaEmailRecipients = useCallback(async (ideaId: number, parameters: PageParameters) => {
        if (communityService !== null) {
            return communityService.fetchIdeaEmailRecipients(ideaId, parameters);
        }
        return PagedResponseContent.nullObject<Member>();
    }, [communityService]);

    const emailIdeaByRecipientEmails = useCallback(async (requestParams: EmailRequest, ideaId: number) => {
        if (communityService !== null) {
            return communityService.emailIdeaByRecipientEmails(requestParams, ideaId);
        }
        return {invalidEmails: []};
    }, [communityService]);

    const emailIdeaByRecipientIds = useCallback(async (requestParams: EmailRequest, ideaId: number) => {
        if (communityService !== null) {
            return communityService.emailIdeaByRecipientIds(requestParams, ideaId);
        }
    }, [communityService]);

    const emailIdeaAuthor = useCallback(async (data: EmailRequest, ideaId: number) => {
        if (communityService !== null) {
            return communityService.emailIdeaAuthor(data, ideaId);
        }
    }, [communityService]);

    const fetchAuthorEmails = useCallback(async (ideaId: number) => {
        if (communityService !== null) {
            return communityService.fetchIdeaAuthorEmails(ideaId);
        }
        return [];
    }, [communityService]);

    const banMember = useCallback(async (memberId: number) => {
        if (communityService !== null) {
            return communityService.banMember(memberId);
        }
        return GenericResponse.EMPTY;
    }, [communityService]);

    const toggleIdeaFollowed = useCallback(async (ideaId: number, followed: boolean): Promise<GenericResponse> => {
        if (communityService !== null) {
            if (followed) {
                return await communityService.unfollowIdea(ideaId);
            } else {
                return await communityService.followIdea(ideaId);
            }
        }
        return GenericResponse.EMPTY;
    }, [communityService]);

    const toggleAuthorFollowed = useCallback(async (memberId: number, followed: boolean): Promise<GenericResponse> => {
        if (communityService !== null) {
            if (followed) {
                return await communityService.unfollowMember(memberId);
            } else {
                return await communityService.followMember(memberId);
            }
        }
        return GenericResponse.EMPTY;
    }, [communityService]);

    const toggleCommentingEnabled = useCallback(async (ideaId: number, enabled: boolean): Promise<void> => {
        if (communityService !== null) {
            if (enabled) {
                return communityService.disableCommenting(ideaId);
            } else {
                return communityService.enableCommenting(ideaId);
            }
        }
    }, [communityService]);

    const fetchModerateActions = useCallback(async (ideaId: number): Promise<IdeaModerateActionResponse> => {
        if (communityService !== null) {
            return await communityService.fetchIdeaModerateActions(ideaId);
        }
        return IdeaModerateActionResponse.EMPTY;
    }, [communityService]);

    const fetchMoreActions = useCallback(async (ideaId: number): Promise<IdeaMoreActionResponse> => {
        if (communityService !== null) {
            return await communityService.fetchIdeaMoreActions(ideaId);
        }
        return IdeaMoreActionResponse.EMPTY;
    }, [communityService]);

    const fetchIdeaLabels = useCallback(async (ideaId: number): Promise<IdeaLabelActionResponse> => {
        if (communityService !== null) {
            return await communityService.fetchIdeaLabels(ideaId);
        }
        return IdeaLabelActionResponse.EMPTY;
    }, [communityService]);

    const togglePinIdeas = useCallback(async (ideaId: number, pinned: boolean): Promise<void> => {
        if (communityService !== null) {
            if (pinned) {
                return await communityService.pinIdea(ideaId);
            } else {
                return await communityService.unpinIdea(ideaId);
            }
        }
    }, [communityService]);

    const changeViewMode = useCallback(async (viewMode: IdeaListViewMode) => {
        if (communityService !== null) {
            try {
                await communityService.changeIdeaListViewMode(viewMode);
            } catch (e: any) {
                handleErrorResponse(e);
            }
        }
    }, [communityService, handleErrorResponse]);

    const modifyIdea = useCallback(async (ideaId: number, submissionRequest: IdeaSubmissionRequest) => {
        if (communityService !== null) {
            return communityService.modifyIdea(ideaId, submissionRequest);
        }
        return ModifiedIdeaDetail.EMPTY;
    }, [communityService]);

    const giveKudo = useCallback(async (id: number) => {
        try {
            if (communityService !== null) {
                return await communityService.giveKudoOnIdea(id);
            }
        } catch (e: any) {
            handleErrorResponse(e);
        }
        return KudoActivitySummary.EMPTY;
    }, [communityService, handleErrorResponse]);

    const updateLabels = useCallback(async ({ideaId, labelId}: LabelActionParams): Promise<IdeaLabelsHolder> => {
        if (communityService !== null) {
            return await communityService.updateLabels(ideaId, labelId);
        }
        return IdeaLabelsHolder.EMPTY;
    }, [communityService]);

    const reportAbuse = useCallback(async (ideaId: number): Promise<IdeaReportingResponse> => {
        if (communityService !== null) {
            return await communityService.reportAbuse(ideaId);
        }
        return IdeaReportingResponse.EMPTY;
    }, [communityService]);

    const reportDuplicate = useCallback(async (ideaId: number): Promise<IdeaReportingResponse> => {
        if (communityService !== null) {
            return await communityService.reportDuplicate(ideaId);
        }
        return IdeaReportingResponse.EMPTY;
    }, [communityService]);

    const toggleVote = useCallback((ideaId: number, voteType: VoteType, voteParameters: VoteParameters) => {
        try {
            if ((voteType === VoteType.UP && voteParameters.previousVote > 0) || (voteType === VoteType.DOWN && voteParameters.previousVote < 0)) {
                return communityService?.retractVote(ideaId);
            } else {
                return voteType === VoteType.UP ? communityService?.upVote(ideaId, voteParameters) : communityService?.downVote(ideaId, voteParameters);
            }
        } catch (e: any) {
            handleErrorResponse(e);
        }
    }, [communityService, handleErrorResponse]);

    const fetchIdeaDetails = useCallback(async (ideaId: number, cancelToken?: CancelToken) => {
        if (communityService !== null && ideaId) {
            try {
                return await communityService.fetchIdeaDetails(ideaId, cancelToken);
            } catch (e: any) {
                throw e;
            }
        }
        return IdeaDetail.EMPTY;
    }, [communityService]);

    const fetchRevisionHistory = useCallback(async (ideaId: number, pageParameters: PageParameters): Promise<PagedResponseContent<RevisionHistory>> => {
        if (communityService !== null) {
            return await communityService.fetchRevisionHistory(ideaId, pageParameters);
        }
        return PagedResponseContent.nullObject();
    }, [communityService]);

    const fetchOriginalIdea = useCallback(async (ideaId: number) => {
        if (communityService !== null) {
            return await communityService.fetchOriginalIdea(ideaId);
        }
        return OriginalIdeaData.EMPTY;
    }, [communityService]);

    const fetchFollowers = useCallback(async (pageParameters: PageParameters, ideaId: number): Promise<PagedResponseContent<Member>> => {
        if (communityService !== null) {
            try {
                return await communityService.fetchIdeaFollowers(pageParameters, ideaId);
            } catch (e: any) {
                throwApiErrorResponse(e);
            }
        }
        return PagedResponseContent.nullObject();
    }, [communityService, throwApiErrorResponse]);

    const fetchOwners = useCallback(async (pageParameters: PageParameters, ideaId: number): Promise<PagedResponseContent<Member>> => {
        if (communityService !== null) {
            try {
                return await communityService.fetchIdeaOwners(pageParameters, ideaId);
            } catch (e: any) {
                throwApiErrorResponse(e);
            }
        }
        return PagedResponseContent.nullObject();
    }, [communityService, throwApiErrorResponse]);

    const fetchKudoGivers = useCallback(async (pageParameters: PageParameters, ideaId: number): Promise<PagedResponseContent<Member>> => {
        if (communityService !== null) {
            try {
                return await communityService.getIdeaKudoGivers(pageParameters, ideaId);
            } catch (e: any) {
                throwApiErrorResponse(e);
            }
        }
        return PagedResponseContent.nullObject();
    }, [communityService, throwApiErrorResponse]);

    const deleteIdeaAttachment = useCallback(async (ideaId: number, fileId: number) => {
        if (communityService !== null) {
            await communityService.deleteIdeaAttachment(ideaId, fileId);
        }
    }, [communityService]);

    const deleteIdeaFieldAttachment = useCallback(async (ideaId: number, fieldId: number, fileName: string) => {
        if (communityService !== null) {
            await communityService.deleteIdeaFieldAttachment(ideaId, fieldId, fileName);
        }
    }, [communityService]);

    const deleteIdeaStageAttachment = useCallback(async (ideaId: number, fieldId: number, fileName: string) => {
        if (communityService !== null) {
            await communityService.deleteIdeaStageAttachment(ideaId, fieldId, fileName);
        }
    }, [communityService]);

    const deleteCommentAttachment = useCallback(async (commentId: number, fileId: number) => {
        if (communityService !== null) {
            await communityService.deleteCommentAttachment(commentId, fileId);
        }
    }, [communityService]);

    const deleteStageCommentAttachment = useCallback(async (fileId: number) => {
        if (communityService !== null) {
            await communityService.deleteStageCommentAttachment(fileId);
        }
    }, [communityService]);

    const saveIdeaAttachments = useCallback(async (ideaId: number, attachmentParams: AttachmentParameters) => {
        if (communityService !== null) {
            return await communityService.saveIdeaAttachments(ideaId, attachmentParams);
        }
        return FileAttachmentsHolder.EMPTY;
    }, [communityService]);

    const unFurlUrl = useCallback(async (url: string) => {
        if (communityService !== null) {
            return await communityService.fetchUnfurledUrl(url);
        }
        return UnfurledUrl.EMPTY;
    }, [communityService]);

    const fetchSuggestedTags = useCallback(async (tagParams: TagParameters) => {
        if (communityService !== null) {
            return await communityService.fetchSuggestedTags(tagParams);
        }
        return [];
    }, [communityService]);

    const fetchTagsByTerm = useCallback(async (pageParams: TagSearchParameters) => {
        if (communityService !== null) {
            return await communityService.fetchTagsByTerm(pageParams);
        }
        return PagedResponseContent.nullObject<string>();
    }, [communityService]);

    const fetchModeratorTagsByTerm = useCallback(async (pageParams: PageParameters) => {
        if (communityService !== null) {
            return await communityService.fetchModeratorTagsByTerm(pageParams);
        }
        return PagedResponseContent.nullObject<string>();
    }, [communityService]);

    const saveTags = useCallback(async (ideaId: number, tags: string[]) => {
        if (communityService !== null) {
            return await communityService.saveTags(ideaId, tags);
        }
        return [];
    }, [communityService]);

    const saveModeratorTags = useCallback(async (ideaId: number, tags: string[]) => {
        if (communityService !== null) {
            return await communityService.saveModeratorTags(ideaId, tags);
        }
        return [];
    }, [communityService]);

    const fetchEmailIdeaContent = useCallback(async (ideaId: number): Promise<EmailIdeaContent> => {
        if (communityService !== null) {
            return await communityService.fetchEmailIdeaContent(ideaId);
        }
        return EmailIdeaContent.EMPTY;
    }, [communityService]);

    const getAuthorEmailIdeaContent = useCallback(async (ideaId: number): Promise<EmailIdeaContent> => {
        if (communityService !== null) {
            return await communityService.getAuthorEmailIdeaContent(ideaId);
        }
        return EmailIdeaContent.EMPTY;
    }, [communityService]);

    const getIdeaTeamMembers = useCallback(async (ideaId: number): Promise<IdeaTeamMember[]> => {
            if (communityService !== null) {
                return await communityService.fetchIdeaTeamMemberInfo(ideaId);
            }
            return [];
        },
        [communityService],
    );

    return {
        changeStage,
        fetchDropdownCampaigns,
        fetchCampaignStages,
        changeCampaign,
        deleteIdea,
        assignOwners,
        fetchMembers,
        fetchAssignedOwners,
        fetchRevisionHistory,
        fetchOriginalIdea,
        fetchIdeaEmailRecipients,
        emailIdeaByRecipientEmails: emailIdeaByRecipientEmails,
        emailIdeaByRecipientIds: emailIdeaByRecipientIds,
        emailIdeaAuthor,
        fetchAuthorEmails,
        banMember,
        toggleIdeaFollowed,
        toggleAuthorFollowed,
        toggleCommentingEnabled,
        fetchModerateActions,
        fetchMoreActions,
        fetchIdeaLabels,
        togglePinIdeas,
        changeViewMode,
        modifyIdea,
        giveKudo,
        updateLabels,
        reportAbuse,
        reportDuplicate,
        toggleVote,
        fetchIdeaDetails,
        fetchFollowers,
        deleteIdeaAttachment,
        deleteIdeaFieldAttachment,
        deleteIdeaStageAttachment,
        deleteCommentAttachment,
        deleteStageCommentAttachment,
        saveIdeaAttachments,
        fetchOwners,
        fetchKudoGivers,
        unFurlUrl,
        fetchSuggestedTags,
        saveTags,
        saveModeratorTags,
        fetchTagsByTerm,
        fetchModeratorTagsByTerm,
        fetchEmailIdeaContent,
        getAuthorEmailIdeaContent,
        getIdeaTeamMembers
    };
};
