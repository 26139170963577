export enum PermissionType {
    ALLOWED = 'ALLOWED',
    LICENSE_LOCKED = 'LICENSE_LOCKED',
}

export interface ResourcePermission {
    standardFilePermission: PermissionType;
    fileLibraryPermission: PermissionType;
    fileUploadPermission: PermissionType;
}
