import React, {Fragment} from 'react';
import {ActivityStreamData} from 'models/ActivityStreamData';
import {Localizer} from '@ideascale/commons';
import {ActionType} from 'models/enums/ActionType';
import {ActivityLink} from '../ActivityLink';

type IdeaMergeActivityProps = {
    localizer: Localizer;
    action: ActionType.IDEA_MERGE;
    activity: ActivityStreamData;
}

export const IdeaMergeActivity = (props: IdeaMergeActivityProps) => {
    const {localizer, activity} = props;

    if (activity.idea.id) {
        return (
            <Fragment>
                {localizer.msg('profile.activity-stream.idea.merge.action')}
                {' - '}
                <ActivityLink title={activity.idea.title} ideaId={activity.idea.id}/>
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                {activity.modifyType === 'COMMENT_MODIFY'
                    ? <i>({localizer.msg('profile.activity-stream.idea.merge.comment-removed')})</i>
                    : <i>({localizer.msg('profile.activity-stream.idea.merge.idea-removed')})</i>
                }
            </Fragment>
        );
    }
};