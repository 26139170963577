import React from 'react';
import {Skeleton} from '@ideascale/ui';

type MemberSkeletonProps = {
    avatarHeight: string;
    avatarWidth: string;
}

export const MemberSkeleton = (props: MemberSkeletonProps) => {
    const {avatarHeight, avatarWidth} = props;

    return (
        <div className="d-flex w-100 flex-grow-1 flex-shrink-1 align-items-center">
            <Skeleton cssClass="me-2" circle height={avatarHeight} width={avatarWidth}/>
            <div className="flex-grow-1 flex-shrink-1">
                <Skeleton width="100%"/>
                <Skeleton width="60%"/>
            </div>
        </div>
    );
};