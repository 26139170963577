import React, {createRef, Fragment, RefObject, useCallback, useEffect, useRef, useState} from 'react';
import {Controller, SubmitHandler, useFieldArray, useForm} from 'react-hook-form';
import {ActionButton, Button, Modal, NewRichTextEditor, RichTextEditorHandler, Skeleton} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {isHtmlInject, Localizer, useApiErrorResponseHandler, useHandleFormSubmit} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useFileUploadService} from 'hooks/useFileUploadService';
import {TranslationConfig} from 'models/TranslationConfig';
import {TranslationFieldType} from 'models/enums/TranslationFieldType';
import {TranslatedContentItem} from 'models/types/TranslatedContentItem';
import {TranslationContentType} from 'models/enums/landing-page/TranslationContentType';
import {TranslationContentRequest} from 'models/landing-page/TranslationContentRequest';
import {TranslationContentResponse} from 'models/landing-page/TranslationContentResponse';

type TextTranslationModalProps = {
    open: boolean;
    toggle: () => void;
    localizer: Localizer;
    translationConfigs: TranslationConfig[];
    getTranslatedContent: (parameters: TranslationContentRequest) => Promise<TranslationContentResponse[]>;
    saveTranslatedContent: (parameters: TranslationContentRequest) => Promise<TranslationContentResponse[]>;
    onTextTranslated: (response: TranslationContentResponse) => void;
    translationLanguageId: number;
    maxCharacterLimit?: number;
    onComponentEdited: () => void;
}

type TranslationInputs = {
    name: string,
    translations: {
        originalTranslation: string,
        translation: string
    }[]
}

export const TranslationModal = (props: TextTranslationModalProps) => {
    const {
        open,
        toggle,
        localizer,
        getTranslatedContent,
        saveTranslatedContent,
        onTextTranslated,
        translationConfigs,
        translationLanguageId,
        maxCharacterLimit,
        onComponentEdited
    } = props;
    const [translationFieldData, setTranslationFieldData] = useState<TranslationContentResponse[]>([]);
    const {communityConfig: {offensiveEmojis, maxFileSizeLimit}} = useAppContext();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const {tempImageUpload} = useFileUploadService();
    const {register, formState: {errors, isSubmitting}, handleSubmit, control, setError} = useForm<TranslationInputs>();
    const onFormSubmit = useHandleFormSubmit(handleSubmit);
    const {fields, append} = useFieldArray({control, name: 'translations'});
    const richTextEditorNodes = useRef<Map<number, RefObject<RichTextEditorHandler>>>(new Map());
    const [configUpdated, setConfigUpdated] = useState(false);

    const submitConfig: SubmitHandler<TranslationInputs> = async (formData) => {
        try {
            const requestItems: TranslatedContentItem[] = formData.translations.map((formField, index) => {
                return {
                    sourceObjectId: translationFieldData[index].sourceObjectId,
                    contentType: translationFieldData[index].contentType,
                    targetLanguageId: translationFieldData[index].targetLanguageId,
                    translation: formField.translation
                };
            });
            const requestBody = new TranslationContentRequest(requestItems);
            const translatedAdminContentResponses = await saveTranslatedContent(requestBody);
            onComponentEdited();
            translatedAdminContentResponses.forEach(response => {
                onTextTranslated(response);
            });
            toggle();
        } catch (error: any) {
            handleErrorResponse(error, {
                setFormError: () => {
                    if (error.data && 'validationErrors' in error.data) {
                        Object.keys(error.data['validationErrors']).forEach(fieldName => {
                            const matches = /(\[)(\d+)(])/.exec(fieldName);
                            if (matches && matches[2]) {
                                const index = +matches[2];
                                if (index < translationFieldData.length) {
                                    const localizerErrorKey = `errors.${error.data?.validationErrors?.[fieldName]?.toLowerCase()}`;
                                    setError(`translations.${index}.translation` as const, {message: localizer.msg(localizerErrorKey)});
                                }
                            }
                        });
                    }
                }
            });
        }
        return;
    };

    const fetchTranslatedTexts = useCallback(async (translationConfigs: TranslationConfig[]) => {
        try {
            const translationItemConfigs = translationConfigs.map(config => {
                return {
                    sourceObjectId: config.id,
                    contentType: config.contentType,
                    targetLanguageId: translationLanguageId,
                } as TranslatedContentItem;
            });
            const requestBody = new TranslationContentRequest(translationItemConfigs);
            const translationData = await getTranslatedContent(requestBody);
            setTranslationFieldData(translationData);
            translationData.forEach((data) => {
                append({originalTranslation: data.originalContent, translation: data.translation});
            });
        } catch (error: any) {
            handleErrorResponse(error);
        }
    }, [append, getTranslatedContent, handleErrorResponse, translationLanguageId]);

    const renderSkeleton = () => {
        return (
            <Fragment>
                {
                    translationConfigs.map((config, index) => (
                        <Fragment key={index}>
                            <label className="control-label">
                                {localizer.msg('landing-page.components.translation.original-title')}
                            </label>
                            <Skeleton cssClass="rounded" width="100%"
                                      height={config.fieldType === TranslationFieldType.TEXT ? '40px' : '180px'}/>
                            <label className="control-label">
                                {localizer.msg('landing-page.components.translation.original-title')}
                            </label>
                            <Skeleton cssClass="rounded mt-2" width="100%"
                                      height={config.fieldType === TranslationFieldType.TEXT ? '40px' : '180px'}/>
                        </Fragment>
                    ))
                }
            </Fragment>
        );
    };

    const focusRichText = (translationConfig: TranslationConfig) => {
        const richText = richTextEditorNodes.current.get(translationConfig.id);
        richText?.current?.focus();
    };

    useEffect(() => {
        void fetchTranslatedTexts(translationConfigs);
    }, [fetchTranslatedTexts, translationConfigs]);

    useEffect(() => {
        translationConfigs.filter(config => {
            return config.fieldType === TranslationFieldType.RICH_TEXT;
        }).forEach(config => {
            richTextEditorNodes.current?.set(config.id, createRef<RichTextEditorHandler>());
        });
    }, [translationConfigs]);

    return (
        <Modal isOpen={open} toggle={toggle} modalHeaderId="translation-modal-header"
               title={localizer.msg('landing-page.components.translation.translation-modal-heading')}>
            <form id={`translation-form`} onSubmit={onFormSubmit(submitConfig, isSubmitting || !configUpdated)}
                  aria-labelledby="translation-modal-header">
                {
                    translationFieldData.length > 0 ?
                        (
                            fields.map((field, index) => {
                                if (translationConfigs[index].fieldType === TranslationFieldType.TEXT) {
                                    return (
                                        <Fragment key={field.id}>
                                            <div className="form-group">
                                                <label htmlFor={`translations.${index}.originalTranslation`}
                                                       className="control-label">
                                                    {
                                                        translationFieldData[index].contentType === TranslationContentType.PAGE_COMPONENT_TITLE &&
                                                        localizer.msg('landing-page.components.translation.original-title')
                                                    }
                                                    {
                                                        translationFieldData[index].contentType === TranslationContentType.PAGE_COMPONENT_SUBTITLE &&
                                                        localizer.msg('landing-page.components.translation.original-subtitle')
                                                    }
                                                </label>
                                                <input type="text" id={`translations.${index}.originalTranslation`}
                                                       className="form-control"
                                                       defaultValue={translationFieldData[index].originalContent}
                                                       disabled={true}
                                                       {...register(`translations.${index}.originalTranslation` as const)}/>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor={`translations.${index}.translation`}
                                                       className="control-label">
                                                    {localizer.msg('landing-page.components.common.translation')}
                                                </label>
                                                <input type="text" id={`translations.${index}.translation`}
                                                       className="form-control"
                                                       defaultValue={translationFieldData[index].translation}
                                                       {...register(`translations.${index}.translation` as const, {
                                                           onChange: () => setConfigUpdated(prevState => !prevState ? true : prevState),
                                                           validate: value => !isHtmlInject(value) || localizer.msg('common.errors.html'),
                                                       })}/>
                                                {
                                                    errors.translations?.[index]?.translation && (
                                                        <p className="invalid-feedback d-block" data-role="field-error"
                                                           aria-live="polite">
                                                            {errors.translations?.[index]?.translation?.message}
                                                        </p>
                                                    )
                                                }
                                            </div>
                                        </Fragment>
                                    );
                                } else {
                                    return (
                                        <Fragment key={field.id}>
                                            <div className="form-group">
                                                <label htmlFor={`translations.${index}.originalTranslation`}
                                                       className="control-label">
                                                    {localizer.msg('landing-page.components.translation.original-content')}
                                                </label>
                                                {
                                                    <NewRichTextEditor
                                                        id={`translations.${index}.originalTranslation`}
                                                        toolbar="default"
                                                        defaultValue={translationFieldData[index].originalContent}
                                                        className="form-control"
                                                        svgIconPath={svgIconPath}
                                                        readonly={true}
                                                    />
                                                }
                                            </div>

                                            {
                                                translationConfigs[index].toolbar &&
                                                <Fragment>
                                                    <label className="control-label"
                                                           onClick={() => focusRichText(translationConfigs[index])}
                                                           htmlFor={`translations.${index}.translation`}>
                                                        {localizer.msg('landing-page.components.common.translation')}
                                                    </label>
                                                    <Controller
                                                        name={`translations.${index}.translation`}
                                                        control={control}
                                                        defaultValue={translationFieldData[index].translation}
                                                        rules={{
                                                            validate: () => {
                                                                if (
                                                                    maxCharacterLimit &&
                                                                    richTextEditorNodes.current.get(translationConfigs[index].id)?.current?.isCharacterLimitExceeded()
                                                                ) {
                                                                    return localizer.msg(
                                                                        'landing-page.components.edit.general-text.error.character-limit',
                                                                        {characterCount: maxCharacterLimit}
                                                                    );
                                                                }
                                                            },
                                                        }}
                                                        render={({field: {onChange}}) =>
                                                            <NewRichTextEditor
                                                                id="translation"
                                                                toolbar={translationConfigs[index].toolbar!}
                                                                className="form-control"
                                                                defaultValue={translationFieldData[index].translation}
                                                                svgIconPath={svgIconPath}
                                                                onChange={(value, delta, source, editor) => {
                                                                    setConfigUpdated(prevState => !prevState ? true : prevState);
                                                                    onChange(value, delta, source, editor);
                                                                }}
                                                                ref={richTextEditorNodes.current.get(translationConfigs[index].id)}
                                                                enableEmojiPicker={false}
                                                                characterLeftLabel={maxCharacterLimit ? localizer.msg('frontend-shared.fields.text-area.text-length-count') : ''}
                                                                maxCharacterLimit={maxCharacterLimit}
                                                                offensiveEmojis={offensiveEmojis}
                                                                enableAtMention={false}
                                                                uploadImage={tempImageUpload}
                                                                maxFileSize={maxFileSizeLimit}
                                                            />
                                                        }
                                                    />
                                                    {
                                                        errors.translations?.[index]?.translation && (
                                                            <p className="invalid-feedback d-block"
                                                               data-role="field-error" aria-live="polite">
                                                                {errors.translations?.[index]?.translation?.message}
                                                            </p>
                                                        )
                                                    }
                                                </Fragment>
                                            }
                                        </Fragment>
                                    );
                                }
                            })
                        ) : renderSkeleton()
                }
                <div className="form-group mt-5 text-end">
                    <Button className="btn btn-cancel me-3" color="cancel" type="button" onClick={toggle}>
                        {localizer.msg('common.actions.cancel')}
                    </Button>
                    <ActionButton type="submit" form={`translation-form`} loading={isSubmitting}
                                  disabled={!configUpdated}>
                        {localizer.msg('landing-page.components.common.confirm-verify')}
                    </ActionButton>
                </div>
            </form>
        </Modal>
    );
};

