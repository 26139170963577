import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {Controller, FieldValues, SubmitHandler, useForm, useWatch} from 'react-hook-form';
import {HtmlConverter, NewRichTextEditor, RichTextEditorHandler, Switch} from '@ideascale/ui';
import {
    AiAssistance,
    AiAssistanceFieldNames,
    AiContentResponse,
    HtmlUtils,
    Localizer,
    useHandleFormSubmit
} from '@ideascale/commons';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useAppContext} from 'contexts/AppContext';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useFileUploadService} from 'hooks/useFileUploadService';
import {useEditModeFormErrorHandler} from 'hooks/useEditModeFormErrorHandler';
import {ConfigurationActionBar} from 'components/shared/ConfigurationActionBar';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';

type CommonProps = {
    label: string;
    fieldId: string;
    onSubmit: SubmitHandler<FieldValues>;
    localizer: Localizer;
    toggleModal: () => void;
    defaultValue?: string;
    toolbarType: 'standard' | 'default' | 'textOnly' | 'minimal' | 'titleText';
    textRequired?: boolean;
    operationType: CampaignOperationType;
    aiAssistanceEnabled?: boolean;
    fetchAiAssistedText?: (prompt: string, fieldName: AiAssistanceFieldNames, chatId?: number) => Promise<AiContentResponse>;
    aiFieldName?: AiAssistanceFieldNames;
}

type WithCheckbox = CommonProps & {
    switchFieldId: string;
    switchLabel: string;
    defaultSwitchState: boolean;
}

type WithOutCheckbox = CommonProps & {
    switchFieldId?: never;
    switchLabel?: never;
    defaultSwitchState?: never;
}

type CommonRichTextEditFormProps =
    | WithCheckbox
    | WithOutCheckbox;

const MAX_CONTENT_LIMIT = 65000;

export const CampaignRichTextEditForm = (props: CommonRichTextEditFormProps) => {
    const {
        label,
        fieldId,
        onSubmit,
        localizer,
        toggleModal,
        defaultValue = '',
        switchFieldId,
        switchLabel,
        defaultSwitchState,
        toolbarType,
        textRequired,
        operationType,
        aiAssistanceEnabled = false,
        fetchAiAssistedText,
        aiFieldName
    } = props;
    const {communityConfig: {maxFileSizeLimit}, authentication: {actor}} = useAppContext();
    const {register, handleSubmit, control, getValues, clearErrors, formState: {errors, isDirty}, setError} = useForm({
        defaultValues: switchFieldId
            ? {
                [switchFieldId]: defaultSwitchState,
                [fieldId]: defaultValue
            }
            : {
                [fieldId]: defaultValue
            }
    });
    const onFormSubmit = useHandleFormSubmit(handleSubmit);
    const {campaignHomeEditor, validationErrors} = useEditModeContext();
    const {tempImageUpload} = useFileUploadService();
    const checkboxValue = useWatch({control, name: switchFieldId!});
    const {validationErrorHandler} = useEditModeFormErrorHandler({
        localizer,
        validationErrors: validationErrors.findError(operationType)
    });
    const richTextEditorNode = useRef<RichTextEditorHandler>(null);

    const renderAiTitle = useMemo(() => {
        switch (operationType) {
            case CampaignOperationType.CHANGE_CAMPAIGN_BRIEF:
                return localizer.msg('ai-assistance.campaign-brief-label');
            case CampaignOperationType.CHANGE_CAMPAIGN_DESCRIPTION:
                return localizer.msg('ai-assistance.campaign-statement-label');
            default:
                return '';
        }
    }, [localizer, operationType]);

    const onAiResponseUsed = useCallback((content: string) => {
        const newContent = HtmlUtils.markdownToHtml(content);
        const oldContent = richTextEditorNode.current?.getHtmlContent();
        richTextEditorNode.current?.setHtml(oldContent + newContent);
        richTextEditorNode.current?.setCursorAtEnd();
    }, []);

    const handleOnSubmit = (data: FieldValues) => {
        data[fieldId] = HtmlConverter.toServerHtmlFormat(data[fieldId]);
        onSubmit(data);
    };

    useEffect(() => {
        if (switchFieldId) {
            clearErrors();
        }
    }, [switchFieldId, checkboxValue, clearErrors]);

    useEffect(() => {
        if (!switchFieldId) {
            richTextEditorNode.current?.setCursorAtEnd();
        }
    }, [switchFieldId]);

    useEffect(() => {
        validationErrorHandler(setError);
    }, [setError, validationErrorHandler]);

    return (
        <form onSubmit={onFormSubmit(handleOnSubmit, !isDirty)}>
            {
                switchFieldId &&
                <div className="mb-3">
                    <Switch offText="OFF" onText="ON"
                            defaultChecked={defaultSwitchState}
                            inputId={switchFieldId}
                            label={<strong>{switchLabel}</strong>}
                            {...register(switchFieldId)}/>
                </div>
            }

            <div className={`form-group w-100 ${errors[fieldId] ? 'has-error' : ''}`}>
                <div className="d-flex">
                    <label className="fw-bold" htmlFor={fieldId}
                           onClick={() => richTextEditorNode.current?.setCursorAtEnd()}>{label}</label>
                    {
                        aiAssistanceEnabled && fetchAiAssistedText &&
                        <AiAssistance className="ms-auto"
                                      type="text"
                                      fieldName={aiFieldName}
                                      localizer={localizer}
                                      title={renderAiTitle}
                                      userAvatar={actor.avatar}
                                      getInitialPrompt={() => campaignHomeEditor.name}
                                      fetchAiAssistedText={fetchAiAssistedText}
                                      onAiResponseUsed={onAiResponseUsed}/>
                    }
                </div>
                <Controller
                    name={fieldId}
                    control={control}
                    defaultValue={HtmlConverter.toRenderHtmlFormat(defaultValue)}
                    rules={
                        {
                            validate: (value) => {
                                const htmlContent = richTextEditorNode.current?.getHtmlContent();
                                if (switchFieldId) {
                                    if (textRequired && getValues(switchFieldId) && String(value).length === 0) {
                                        return localizer.msg('common.errors.required');
                                    }
                                }
                                if (htmlContent && htmlContent.length > MAX_CONTENT_LIMIT) {
                                    return localizer.msg('common.errors.too-many-characters');
                                }
                                return true;
                            }
                        }
                    }
                    render={({field: {onChange}}) =>
                        <NewRichTextEditor
                            id={fieldId}
                            toolbar={toolbarType}
                            className={'form-control'}
                            svgIconPath={svgIconsPath}
                            defaultValue={HtmlConverter.toRenderHtmlFormat(defaultValue)}
                            onChange={onChange}
                            enableEmojiPicker={false}
                            enableAtMention={false}
                            uploadImage={tempImageUpload}
                            maxFileSize={maxFileSizeLimit}
                            ref={richTextEditorNode}
                        />
                    }
                />
                {
                    errors[fieldId] &&
                    <div className="invalid-feedback d-block">
                        {errors[fieldId].message}
                    </div>
                }
            </div>

            <ConfigurationActionBar updateBtnType="submit" localizer={localizer} onCancel={toggleModal}
                                    updateDisabled={!isDirty}/>
        </form>
    );
};