import React, {useMemo} from 'react';
import {
    AiAssistanceFieldNames,
    AiContentResponse,
    AiImageResponse,
    ClassificationLabel,
    useApplicableClassifications,
    useToggle
} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {CampaignDescriptionEditForm} from './edit-mode/CampaignDescriptionEditForm';
import {CampaignSubtitleEditForm} from './edit-mode/CampaignSubtitleEditForm';
import {CampaignFeaturedImageEdit} from './edit-mode/CampaignFeaturedImageEdit';
import {EditActionWrapper} from './shared/EditActionWrapper';
import {HtmlRenderer} from './shared/HtmlRenderer';
import {CampaignDetailsData} from 'models/CampaignDetailsData';
import {CampaignEditableFieldType} from 'models/edit-mode/CampaignEditableFieldType';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';
import {HomeConfigHolder} from 'models/edit-mode/HomeConfigHolder';
import defaultImage from 'assets/img/default-image.jpg';
import styles from './CampaignDetails.module.scss';

type CampaignDetailsProps = {
    campaignData: CampaignDetailsData;
    fetchAiAssistedText: (prompt: string, fieldName: AiAssistanceFieldNames, chatId?: number) => Promise<AiContentResponse>;
    fetchAiAssistedImage: (prompt: string, size?: string, numberOfImages?: number) => Promise<AiImageResponse>;
}

export const CampaignDetails = (props: CampaignDetailsProps) => {
    const {campaignData, fetchAiAssistedText, fetchAiAssistedImage} = props;
    const localizer = useLocalizer();
    const {getClassificationAttribute} = useApplicableClassifications('', []);
    const {communityConfig, editModeEnabled} = useAppContext();
    const {homeConfig, campaignHomeEditor, validationErrors} = useEditModeContext();
    const [editSubTitleModal, toggleEditSubTitleModal] = useToggle(false);
    const [editDescriptionModal, toggleEditDescriptionModal] = useToggle(false);
    const [editFeatureImageModal, toggleEditFeatureImageModal] = useToggle(false);

    const featuredImage = useMemo(() => {
        if (editModeEnabled && homeConfig !== HomeConfigHolder.EMPTY && homeConfig.isOperationAllowed(CampaignEditableFieldType.CAMPAIGN_FEATURE_IMAGE)) {
            const featureField = homeConfig.getBannerField(CampaignEditableFieldType.CAMPAIGN_FEATURE_IMAGE);
            return {
                imageUrl: featureField.url,
                altText: featureField.altText
            };
        }
        return {
            imageUrl: campaignData.featuredImageUrl,
            altText: campaignData.featuredImageAltText
        };
    }, [campaignData.featuredImageAltText, campaignData.featuredImageUrl, editModeEnabled, homeConfig]);

    return (
        <div className="row">
            {
                editModeEnabled && homeConfig.isOperationAllowed(CampaignEditableFieldType.CAMPAIGN_FEATURE_IMAGE)
                    ? <div className={`col-sm col-md-5 ${styles.featureImage}`} data-test-element-id="campaign-featured-image">
                        <EditActionWrapper
                            editAllowed={homeConfig.isOperationAllowed(CampaignEditableFieldType.CAMPAIGN_FEATURE_IMAGE)}
                            configurationChildren={
                                <CampaignFeaturedImageEdit maxFileSizeLimit={communityConfig.maxFileSizeLimit}
                                                           toggle={toggleEditFeatureImageModal}
                                                           fetchAiAssistedImage={fetchAiAssistedImage}/>
                            }
                            hasError={validationErrors.hasError(CampaignOperationType.CHANGE_CAMPAIGN_FEATURED_IMAGE)}
                            modalOpen={editFeatureImageModal}
                            modalTitle={localizer.msg('edit-mode.campaign.edit-featured-image')}
                            modalToggle={toggleEditFeatureImageModal}
                            editBtnTitle={localizer.msg('edit-mode.campaign.edit-featured-image')}
                            editBtnTestElementId="btn-edit-campaign-featured-image">
                            {
                                <img src={featuredImage.imageUrl || defaultImage}
                                     className="img-fluid float-start rounded"
                                     alt={featuredImage.altText || ''}/>
                            }
                        </EditActionWrapper>
                    </div>
                    : featuredImage.imageUrl &&
                    <div
                        className={`col-sm col-md-5 ${styles.featureImage} ${editModeEnabled ? 'opacity-50' : ''}`} data-test-element-id="campaign-featured-image">
                        <img src={featuredImage.imageUrl} className="img-fluid float-start rounded"
                             alt={featuredImage.altText}/>
                    </div>
            }
            <div className={`col-sm ${featuredImage.imageUrl || editModeEnabled ? 'col-md-7' : 'col-md-12'}`} data-test-element-id="campaign-details">
                {communityConfig.classificationEnabled && campaignData.classificationSummary &&
                    <ClassificationLabel
                        classification={getClassificationAttribute(campaignData.classificationSummary)}/>
                }
                <h2 className={`h4 ${editModeEnabled ? 'text-muted' : ''}`}>
                    {campaignHomeEditor.name || (campaignData.name ?? '')}
                </h2>
                {
                    editModeEnabled && homeConfig.isOperationAllowed(CampaignEditableFieldType.CAMPAIGN_SUBTITLE)
                        ? <EditActionWrapper
                            className="d-flex"
                            editAllowed={homeConfig.isOperationAllowed(CampaignEditableFieldType.CAMPAIGN_SUBTITLE)}
                            configurationChildren={
                                <CampaignSubtitleEditForm toggleModal={toggleEditSubTitleModal}
                                                          localizer={localizer}
                                                          fetchAiAssistedSubtitle={fetchAiAssistedText}/>
                            }
                            hasError={validationErrors.hasError(CampaignOperationType.CHANGE_CAMPAIGN_SUBTITLE)}
                            modalOpen={editSubTitleModal}
                            modalTitle={localizer.msg('edit-mode.campaign.edit-subtitle')}
                            modalToggle={toggleEditSubTitleModal}
                            editBtnTitle={localizer.msg('edit-mode.campaign.edit-subtitle')}
                            editBtnTestElementId="btn-edit-campaign-subtitle">
                            <div className={`text-muted mb-2 overflow-hidden ${!campaignHomeEditor.subtitle ? 'pe-5' : ''}`}
                                 data-test-element-id="campaign-subtitle">
                                {campaignHomeEditor.subtitle || localizer.msg('edit-mode.campaign.add-subtitle')}
                            </div>
                        </EditActionWrapper>
                        : <span className={editModeEnabled ? 'opacity-50' : ''}
                                data-test-element-id="campaign-subtitle">{campaignData?.subtitle}</span>
                }
                {
                    editModeEnabled && homeConfig.isOperationAllowed(CampaignEditableFieldType.CAMPAIGN_DESCRIPTION)
                        ? <EditActionWrapper
                            editAllowed={homeConfig.isOperationAllowed(CampaignEditableFieldType.CAMPAIGN_DESCRIPTION)}
                            className="d-flex mt-3"
                            configurationChildren={<CampaignDescriptionEditForm localizer={localizer}
                                                                                toggleModal={toggleEditDescriptionModal}
                                                                                fetchAiAssistedDescription={fetchAiAssistedText}/>}
                            hasError={validationErrors.hasError(CampaignOperationType.CHANGE_CAMPAIGN_DESCRIPTION)}
                            modalTitle={localizer.msg('edit-mode.campaign.edit-statements')}
                            modalOpen={editDescriptionModal}
                            modalToggle={toggleEditDescriptionModal}
                            editBtnTitle={localizer.msg('edit-mode.campaign.edit-statements')}
                            editBtnTestElementId="btn-edit-campaign-statements">
                            {
                                campaignHomeEditor.description
                                    ? <HtmlRenderer className="w-100" content={campaignHomeEditor.description}
                                                    elementId="campaign-details"/>
                                    : <div className="text-muted" data-test-element-id="campaign-details">
                                        {localizer.msg('edit-mode.campaign.add-statements')}</div>
                            }
                        </EditActionWrapper>
                        : !!campaignData.description &&
                        <HtmlRenderer className={`mt-2 ${editModeEnabled ? 'opacity-50' : ''}`}
                                      content={campaignData.description}
                                      elementId="campaign-details"/>
                }
            </div>
        </div>
    );
};