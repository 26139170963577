import {Type, TypeHelpOptions} from 'class-transformer';
import {DraftActionItem} from './DraftActionItem';
import {ModeratorActionItem} from './ModeratorActionItem';
import {StageActionItem} from './StageActionItem';
import {InnovationAssistantActionItem} from './InnovationAssistantActionItem';
import {ProfileActionItem} from './ProfileActionItem';
import {ActionItemGroupType} from './ActionItemGroupType';
import {ActionItem} from './ActionItem';

export class GroupedActionItem {
    group: ActionItemGroupType;
    groupTitle: string;
    seeAllLink: boolean;

    @Type((type?: TypeHelpOptions) => {
            switch (type?.object.group) {
                case ActionItemGroupType.DRAFT:
                    return DraftActionItem;
                case ActionItemGroupType.PROFILE:
                    return ProfileActionItem;
                case ActionItemGroupType.STAGE:
                    return StageActionItem;
                case ActionItemGroupType.MODERATOR:
                    return ModeratorActionItem;
                case ActionItemGroupType.INNOVATION_ASSISTANT:
                    return InnovationAssistantActionItem;
                default:
                    return ActionItem;
            }
        }
    )
    actionItems: Array<DraftActionItem | ModeratorActionItem | InnovationAssistantActionItem | ProfileActionItem | StageActionItem>;
}