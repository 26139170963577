type Options = {
    id: number,
    name: string,
    translatedName: string,
    order: number,
    defaultOption: boolean
}
type OptionGroups = {
    id: number,
    name: string,
    options: Options[]
}

export class CustomFieldData {
    public static EMPTY = new CustomFieldData(0, '', []);

    constructor(
        public id: number,
        public label: string,
        public optionGroups: OptionGroups[],
    ) {
    }
}