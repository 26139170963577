import React, {Fragment} from 'react';
import {useParams} from 'react-router-dom';
import {Icon} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {ClassificationFieldAttribute, useToggle} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {IdeateStageSummary} from 'models/IdeateStageSummary';
import {IdeaDetailsRouteMatchParams} from 'models/types/IdeaDetailsRouteMatchParams';
import {VoteParameters} from 'models/VoteParameters';
import {VoteType} from 'models/enums/VoteType';
import './IdeateStageCallToAction.scss';

type IdeateStageCallToActionProps = {
    ideaStageSummary: IdeateStageSummary;
    onVote: (voteParameters: VoteParameters, voteType: VoteType) => Promise<boolean>;
    netVotesEnabled: boolean;
    voteDownEnabled: boolean;
    classification?: ClassificationFieldAttribute[]
}

export const IdeateStageCallToAction = (props: IdeateStageCallToActionProps) => {
    const {authentication} = useAppContext();

    const {
        ideaStageSummary: {
            stage,
            voteValue,
            netVoteCount,
            ideaVotingAllowed,
            showVotingButton,
            participationAllowed,
            viewAllowed,
            downVoteCount,
            upVoteCount,
        },
        onVote,
        netVotesEnabled,
        voteDownEnabled,
        classification
    } = props;
    const localizer = useLocalizer();
    const {communityConfig} = useAppContext();
    const [processing, setProcessing] = useToggle(false);
    const params = useParams<IdeaDetailsRouteMatchParams>();

    const upVoted = () => {
        return voteValue > 0;
    };

    const downVoted = () => {
        return voteValue < 0;
    };

    const canUpVote = () => {
        return ideaVotingAllowed;
    };

    const canDownVote = () => {
        return ideaVotingAllowed && voteDownEnabled;
    };

    const canTakeAction = () => {
        return (participationAllowed && showVotingButton) || authentication.actor.isAnonymous() || authentication.isJoinCommunityAvailable();
    };

    const onClickVote = async (voteType: VoteType) => {
        if (canTakeAction()) {
            setProcessing(true);
            const voted = await onVote(
                {
                    voteValue: voteType === VoteType.UP ? 1 : -1,
                    previousVote: voteValue,
                    previousTotalVoteDown: downVoteCount,
                    previousTotalVoteUp: upVoteCount
                }, voteType);

            if (voted) {
                setProcessing(false);
            }
        }
    };

    return (
        <div
            className={`stage-action stage-action-ideate ${communityConfig.classificationEnabled && params.ideaId && classification ? 'mt-5' : ''}`}
            aria-label={canUpVote() || canDownVote() ? localizer.msg('stage.ideate.vote-for-this-idea') : undefined}
            data-fn-key={stage?.name}
            role={canUpVote() || canDownVote() ? 'menu' : undefined}>
            {participationAllowed || viewAllowed
                ?
                <>
                    {
                        canUpVote() &&
                        <button className={`btn btn-idea-vote ${upVoted() ? 'active' : ''}`}
                                type={'button'}
                                onClick={() => onClickVote(VoteType.UP)} disabled={!canTakeAction() || processing}
                                title={localizer.msg('stage.ideate.upvote-button-title')}
                                role="menuitemradio"
                                aria-checked={upVoted()}>
                            <Icon name="arrow-up" width={18} height={18} iconSpritePath={svgIconsPath}/>
                        </button>
                    }

                    <p className="stage-data text-center text-truncate px-1 mb-0"
                         title={netVotesEnabled ? localizer.msg('stage.common.net_vote', {count: netVoteCount}) : localizer.msg('stage.ideate.net-votes-hidden')}>
                        {
                            netVotesEnabled
                                ? <Fragment><span className="sr-only">Number of votes </span>{netVoteCount}</Fragment>
                                : localizer.msg('stage.ideate.net-votes-hidden')
                        }
                    </p>

                    {
                        canDownVote() &&
                        <button className={`btn btn-idea-vote ${downVoted() ? 'active' : ''}`} type={'button'}
                                onClick={() => onClickVote(VoteType.DOWN)}
                                disabled={!canTakeAction() || processing}
                                title={localizer.msg('stage.ideate.downvote-button-title')}
                                role="menuitemradio"
                                aria-checked={downVoted()}>
                            <Icon name="arrow-down" width={18} height={18} iconSpritePath={svgIconsPath}/>
                        </button>
                    }
                </>
                :
                <>
                    <div className="stage-data text-center text-truncate px-1"
                         title={localizer.msg('stage.ideate.awaits')}>
                        {localizer.msg('stage.ideate.awaits')}
                    </div>
                </>
            }
        </div>
    );
};