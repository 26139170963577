import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useAppContext} from 'contexts/AppContext';
import {ActionItemHeader} from 'components/topbar/action-items/ActionItemHeader';
import {GroupedActionItem} from 'models/topbar-action-item/GroupedActionItem';
import {StageActionItem} from 'models/topbar-action-item/StageActionItem';
import {IdeaListMode} from 'models/enums/IdeaListMode';
import {MyIdeaListMode} from 'models/enums/MyIdeaListMode';
import {appLinks} from 'services/AppLinks';

type StageActionItemsProps = {
    groupedActionItem: GroupedActionItem;
}

export const StageActionItems = (props: StageActionItemsProps) => {
    const {groupedActionItem} = props;
    const navigate = useNavigate();
    const {authentication: {actor}} = useAppContext();

    const onSeeAllClick = () => {
        navigate(appLinks.myIdeas(String(actor.id), MyIdeaListMode.MY_ACTION_ITEMS, 'all', IdeaListMode.RECENT));
    };

    return (
        <div className="action-item-container">
            <ActionItemHeader id="stage-actions-heading"
                              groupTitle={groupedActionItem.groupTitle}
                              seeAllLink={groupedActionItem.seeAllLink}
                              onSeeAllClick={onSeeAllClick}/>
            <div className="divider"/>
            <ul className="action-item-list-container list-unstyled mx-3 mt-3" aria-labelledby="stage-actions-heading">
                {
                    (groupedActionItem.actionItems as StageActionItem[]).map((actionItem: StageActionItem) => (
                        <li key={actionItem.stageId}>
                            <Link className="btn btn-link p-0 w-auto bg-transparent d-inline text-sm"
                                  to={appLinks.myIdeas(String(actor.id), MyIdeaListMode.MY_ACTION_ITEMS, actionItem.stageKey, IdeaListMode.RECENT)}
                                  aria-label={`${!!actionItem.count && actionItem.count} ${actionItem.name}`}>
                                <span>{actionItem.name} : {actionItem.stageLabel} {actionItem.count && `[ ${actionItem.count} ]`}</span>
                            </Link>

                        </li>
                    ))
                }
            </ul>
        </div>
    );
};