import React, {useCallback, useEffect, useRef, useState} from 'react';
import {CollapseExpand, Icon, ParagraphSkeleton, Scrollbar, ScrollbarInstance} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {InfiniteScrollLoadMoreButton, Localizer, Member, useApiErrorResponseHandler, useIntersectionObserver} from '@ideascale/commons';
import {useProfilePageParams} from 'hooks/useProfilePageParams';
import {MemberSummary} from 'components/MemberSummary';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {GenericResponse} from 'models/GenericResponse';

const PAGE_SIZE = 30;

type FollowedMembersProps = {
    localizer: Localizer;
    expanded: boolean;
    fetchFollowedMembers: (pageParameters: PageParameters) => Promise<PagedResponseContent<Member>>;
    unfollowMember: (memberId: number) => Promise<GenericResponse>;
    followMember: (memberId: number) => Promise<GenericResponse>;
    actionable: boolean;
}

class MemberSubscription extends Member {
    public followed: boolean;
}

export const FollowedMembers = (props: FollowedMembersProps) => {
    const {localizer, expanded, fetchFollowedMembers, unfollowMember, followMember, actionable} = props;
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const currentProfileId = useProfilePageParams();
    const scrollbarContainer = useRef<ScrollbarInstance>(null);
    const loadMoreButtonRef = useRef<HTMLButtonElement>(null);
    const currentPage = useRef(0);
    const [memberData, setMemberData] = useState<MemberSubscription[]>([]);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);

    const loadMembers = useCallback(async () => {
        fetchFollowedMembers({page: currentPage.current, limit: PAGE_SIZE}).then(data => {
            let hasMore = data.hasMore;

            setHasMore(hasMore);
            setLoading(false);
            let newData = data.content.map(member => ({...member, followed: true}));
            if (currentPage.current === 0) {
                setMemberData(newData);
            } else {
                setMemberData(prevData => [...prevData, ...newData]);

            }
            if (hasMore) {
                currentPage.current = currentPage.current + 1;
            }
        }).catch(e => {
            setLoading(false);
            handleErrorResponse(e);
        });
    }, [fetchFollowedMembers, handleErrorResponse]);

    const toggleFollowed = async (memberId: number) => {
        let newMemberData = [...memberData];
        let member = newMemberData.find(member => member.id === memberId);
        if (member) {
            if (member.followed) {
                try {
                    await unfollowMember(memberId);
                    member.followed = false;
                    setMemberData(newMemberData);
                } catch (error: any) {
                    handleErrorResponse(error);
                }
            } else {
                try {
                    await followMember(memberId);
                    member.followed = true;
                    setMemberData(newMemberData);
                } catch (error: any) {
                    handleErrorResponse(error);
                }
            }
        }
    };

    const onExpanded = () => {
        if (hasMore && currentPage.current === 0) {
            loadMembers().then();
        }
    };

    useIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: loadMembers,
        enabled: hasMore,
        options: {
            root: scrollbarContainer.current?.container
        }
    });

    useEffect(() => {
        currentPage.current = 0;
        setHasMore(true);
    }, [currentProfileId]);

    useEffect(() => {
        if (expanded && hasMore && currentPage.current === 0) {
            loadMembers().then();
        }
    }, [expanded, hasMore, loadMembers]);

    return (
        <CollapseExpand containerClass={'followed-members mb-3 panel-light'}
                        headerTitle={localizer.msg('profile.followed-sections.member.heading')}
                        svgIconSprite={svgIconPath}
                        defaultOpen={expanded} onEntering={onExpanded}>
            {
                loading ? (
                    <ParagraphSkeleton rows={6}/>
                ) : (
                    memberData.length > 0 ? (
                        <Scrollbar
                            className="follow-section"
                            autoHeight
                            autoHeightMax={275}
                            ref={scrollbarContainer}>
                            <ul className="list-unstyled pe-2" aria-label={localizer.msg('profile.followed-sections.member.heading')}>
                                {
                                    memberData.map((member) => (
                                        <li key={member.id}
                                            className="py-1 d-flex align-items-center text-truncate px-1">
                                            {
                                                actionable ? (
                                                    <button className="btn btn-link p-0 mb-1"
                                                            aria-label={member.followed ? localizer.msg('profile.followed-sections.member.unfollow') : localizer.msg('profile.followed-sections.member.follow')}
                                                            onClick={() => toggleFollowed(member.id)} role="switch" aria-checked={member.followed}>
                                                        <Icon iconSpritePath={svgIconPath}
                                                              className="subscribe-icon active"
                                                              name={member.followed ? 'star-solid' : 'star-outlined'}
                                                              width={20}
                                                              height={20}/>
                                                    </button>
                                                ) : (
                                                    <Icon iconSpritePath={svgIconPath}
                                                          className="subscribe-icon active"
                                                          name="star-solid"
                                                          width={20}
                                                          height={20}/>
                                                )
                                            }
                                            <MemberSummary id={member.id} avatarUrl={member.avatar} name={member.name}
                                                           username={member.username}
                                                           identityHidden={member.identityHidden}/>
                                        </li>
                                    ))
                                }
                            </ul>
                            <InfiniteScrollLoadMoreButton hasMore={hasMore}
                                                          localizer={localizer}
                                                          loading={loading}
                                                          onCLick={loadMembers}
                                                          loadMoreButtonRef={loadMoreButtonRef}/>
                        </Scrollbar>
                    ) : (
                        <span
                            className="text-muted d-block mt-2">
                            {localizer.msg('profile.followed-sections.member.empty')}
                        </span>
                    )
                )
            }
        </CollapseExpand>
    );
};