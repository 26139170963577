import React, {useEffect, useMemo, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import {scroller} from 'react-scroll';
import {InfoTip, Scrollbar, ScrollbarInstance} from '@ideascale/ui';
import {InfiniteScrollLoadMoreButton, useIntersectionObserver} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useIdeasContext} from 'contexts/IdeasContext';
import {useSearchContext} from 'contexts/SearchContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useIdeaService} from 'hooks/useIdeaService';
import {useMessageService} from 'hooks/useMessageService';
import {useSearchIdeasInfiniteScroll} from 'hooks/useSearchIdeasInfiniteScroll';
import {IdeaList} from 'components/idea/list/IdeaList';
import {IdeaListSkeleton} from 'components/idea/list/IdeaListSkeleton';
import {CommonUtil} from 'utils/CommonUtil';
import {QUERY_KEYS} from 'constants/AppConstants';
import {IdeaSummary} from 'models/IdeaSummary';

const IDEA_LIST_CONTAINER_ID = 'idea-search-list';

export const IdeaSearchResultContainer = () => {
    const localizer = useLocalizer();
    const location = useLocation();
    const {communityConfig: {ideaListViewMode}} = useAppContext();
    const {term, getSearchParams} = useSearchContext();
    const {updateIdeaListData, setIdeaListFilterQueryKey} = useIdeasContext();
    const {ideaListData} = useIdeasContext();
    const scrollbarContainer = useRef<ScrollbarInstance>(null);
    const loadMoreButtonRef = useRef<HTMLButtonElement>(null);
    const {
        fetchMembers,
        fetchAssignedOwners,
        emailIdeaAuthor,
        fetchAuthorEmails,
        banMember,
        toggleIdeaFollowed,
        toggleAuthorFollowed,
        toggleCommentingEnabled,
        fetchModerateActions,
        fetchMoreActions,
        fetchIdeaLabels,
        togglePinIdeas,
        getAuthorEmailIdeaContent
    } = useIdeaService();

    const {fetchRecipients} = useMessageService();

    useEffect(() => {
        setIdeaListFilterQueryKey([QUERY_KEYS.IDEA_LIST, getSearchParams]);
    }, [getSearchParams, setIdeaListFilterQueryKey]);

    const ideaListInfiniteScrollData = useSearchIdeasInfiniteScroll([QUERY_KEYS.IDEA_LIST, getSearchParams]);

    useEffect(() => {
        updateIdeaListData({
            pagedIdeas: ideaListInfiniteScrollData.data,
            totalIdeas: ideaListInfiniteScrollData.data?.pages[0]?.totalIdeas || 0,
            isLoading: ideaListInfiniteScrollData.isLoading,
            refetch: ideaListInfiniteScrollData.refetch,
            hasNextPage: ideaListInfiniteScrollData.hasNextPage || false,
            hasPreviousPage: ideaListInfiniteScrollData.hasPreviousPage || false,
            isFetchingPreviousPage: ideaListInfiniteScrollData.isFetchingPreviousPage,
            isFetchingNextPage: ideaListInfiniteScrollData.isFetchingNextPage,
            fetchNextPage: ideaListInfiniteScrollData.fetchNextPage,
            fetchPreviousPage: ideaListInfiniteScrollData.fetchPreviousPage
        });
    }, [ideaListInfiniteScrollData.data, ideaListInfiniteScrollData.fetchNextPage, ideaListInfiniteScrollData.fetchPreviousPage, ideaListInfiniteScrollData.hasNextPage, ideaListInfiniteScrollData.hasPreviousPage, ideaListInfiniteScrollData.isFetchingNextPage, ideaListInfiniteScrollData.isFetchingPreviousPage, ideaListInfiniteScrollData.isLoading, ideaListInfiniteScrollData.refetch, updateIdeaListData]);

    const {
        pagedIdeas,
        isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
        totalIdeas
    } = ideaListData;

    useIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: fetchNextPage,
        enabled: hasNextPage || false,
        options: {
            rootMargin: '350px 0px 0px',
            root: scrollbarContainer.current?.container
        }
    });

    const ideaList = useMemo(() => {
        let ideas: IdeaSummary[] | undefined = [];
        if (pagedIdeas) {
            pagedIdeas.pages.forEach((page: any) => {
                if (page?.data as IdeaSummary[]) {
                    ideas?.push(...page?.data as IdeaSummary[]);
                }
            });
        }
        return ideas;
    }, [pagedIdeas]);

    useEffect(() => {
        if (location.hash) {
            const scrollTargetIdea = location.hash.replace('#', '').toString();
            const ideaId = (scrollTargetIdea.split('-') || []).length === 2 ? scrollTargetIdea.split('-')[1] : -1;
            if (!!scrollTargetIdea && ideaId > -1) {
                CommonUtil.wait(500).then(() => {
                    scroller.scrollTo('idea-search-list', {
                        duration: 500,
                        delay: 0,
                        smooth: true,
                        offset: -60
                    });
                });
            }
        }
    }, [location.hash]);

    return (
        <section className="mt-4">
            {
                !isLoading && (
                    <header className="d-flex align-items-center mb-2">
                        <h2 className="font-size-md fw-bold mb-0 h3"
                            data-test-element-id="search-idea-count">
                            {
                                term
                                    ? (
                                        totalIdeas === 0
                                            ? localizer.msg('search.idea.no-results-with-term', {term: term})
                                            : localizer.msg('search.idea.results', {count: totalIdeas, term: term})
                                    )
                                    : (
                                        totalIdeas === 0
                                            ? localizer.msg('search.idea.no-results')
                                            : localizer.msg('search.idea.result-without-term', {count: totalIdeas})
                                    )
                            }
                        </h2>
                        <span className="position-relative pos-top-n1">
                             <InfoTip id="ideas-result-infoTip"
                                      placement="top"
                                      instructions={localizer.msg('search.search-info-instruction')}
                                      content={localizer.msg('search.idea-search-info')}/>
                        </span>
                    </header>
                )
            }
            {
                isLoading
                    ? <IdeaListSkeleton currentViewMode={ideaListViewMode} items={4}/>
                    : null
            }
            {
                ideaList?.length
                    ? (
                        <Scrollbar
                            className="idea-search-list pe-2"
                            ref={scrollbarContainer}
                            autoHeight
                            autoHeightMax={550}
                            autoHeightMin={(ideaList?.length > 0 && ideaList?.length < 3) ? 360 : 'auto'}>
                            <div id={IDEA_LIST_CONTAINER_ID}>
                                {
                                    ideaList && ideaList.length > 0 && (
                                        <IdeaList containerId={IDEA_LIST_CONTAINER_ID}
                                                  localizer={localizer}
                                                  ideas={ideaList}
                                                  isSearchResult={true}
                                                  listViewMode={ideaListViewMode}
                                                  fetchModerateActions={fetchModerateActions}
                                                  fetchMoreActions={fetchMoreActions}
                                                  fetchIdeaLabels={fetchIdeaLabels}
                                                  toggleIdeaFollowed={toggleIdeaFollowed}
                                                  toggleAuthorFollowed={toggleAuthorFollowed}
                                                  toggleCommentingEnabled={toggleCommentingEnabled}
                                                  fetchMembers={fetchMembers}
                                                  fetchAssignedOwners={fetchAssignedOwners}
                                                  fetchRecipients={fetchRecipients}
                                                  emailIdeaAuthor={emailIdeaAuthor}
                                                  fetchAuthorEmails={fetchAuthorEmails}
                                                  banMember={banMember}
                                                  togglePinIdeas={togglePinIdeas}
                                                  getAuthorEmailIdeaContent={getAuthorEmailIdeaContent}
                                        />
                                    )
                                }
                                <InfiniteScrollLoadMoreButton hasMore={hasNextPage}
                                                              skeletonComponent={<IdeaListSkeleton
                                                                  currentViewMode={ideaListViewMode}/>}
                                                              loading={isFetchingNextPage}
                                                              localizer={localizer}
                                                              onCLick={fetchNextPage}
                                                              loadMoreButtonRef={loadMoreButtonRef}/>
                            </div>
                        </Scrollbar>)
                    : null
            }
        </section>
    );
};