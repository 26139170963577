import React, {memo, useCallback, useMemo} from 'react';
import {GroupBase, OptionsOrGroups} from 'react-select';
import {useLocation, useNavigate} from 'react-router-dom';
import {GroupOptionType, IdeascaleSelect, IdeascaleSelectWithPagination, OptionType} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {Campaign, CampaignSelect, CampaignsHolder, Localizer} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useLeaderboardContext} from 'contexts/LeaderboardContext';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {PageParameters} from 'models/types/PageParameters';
import {MemberGroup} from 'models/types/MemberGroup';
import {TimePeriod} from 'models/enums/TimePeriod';
import styles from './LeaderboardFilter.module.scss';

const CAMPAIGN_SELECT_ID = 'campaign-select-field';
const GROUP_SELECT_ID = 'group-select-field';
const TIME_PERIOD_ID = 'time-period-field';

type LeaderboardFilterProps = {
    localizer: Localizer;
    fetchCampaigns: (params: PageParameters) => Promise<PagedResponseContent<CampaignsHolder<Campaign>>>;
    fetchGroups: (params: PageParameters) => Promise<PagedResponseContent<MemberGroup>>;
}

export const LeaderboardFilter = memo((props: LeaderboardFilterProps) => {
    const {localizer, fetchCampaigns, fetchGroups} = props;
    const {campaign, setCampaign, group, setGroup, timePeriod, setTimePeriod} = useLeaderboardContext();
    const navigate = useNavigate();
    const location = useLocation();
    const {communityConfig} = useAppContext();

    const DEFAULT_GROUP = useMemo(() => ({
        value: undefined,
        label: localizer.msg('leaderboard.groups.all')
    }), [localizer]);
    const DEFAULT_CAMPAIGN = useMemo(() => ({
        value: undefined,
        label: localizer.msg('leaderboard.campaign.all')
    }), [localizer]);
    const TIME_PERIOD_OPTIONS: GroupOptionType[] = useMemo(() => [
        {
            label: '',
            options: [
                {value: undefined, label: localizer.msg('leaderboard.time-period.all-time')}
            ]
        },
        {
            label: localizer.msg('leaderboard.time-period.group.weekly'),
            options: [4, 8, 12].map(unit => ({
                value: {mode: TimePeriod.WEEKLY, unit},
                label: localizer.msg('leaderboard.time-period.weekly', {count: unit})
            }))
        },
        {
            label: localizer.msg('leaderboard.time-period.group.monthly'),
            options: [6, 12].map(unit => ({
                value: {mode: TimePeriod.MONTHLY, unit},
                label: localizer.msg('leaderboard.time-period.monthly', {count: unit})
            }))
        },
        {
            label: localizer.msg('leaderboard.time-period.group.quarterly'),
            options: [4, 6, 8].map(unit => ({
                value: {mode: TimePeriod.QUARTERLY, unit},
                label: localizer.msg('leaderboard.time-period.quarterly', {count: unit})
            }))
        }
    ], [localizer]);

    const loadGroups = useCallback(async (term: string, prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>, additionalParams: any) => {
        try {
            const {page} = additionalParams;
            const {content, hasMore} = await fetchGroups({term, page});
            const options: OptionType[] = content.map(group => ({value: group.id, label: group.name}));

            if (page === 0) {
                options.unshift(DEFAULT_GROUP);
            }

            return {
                options,
                hasMore,
                additional: {
                    page: page + 1
                }
            };
        } catch (e: any) {
            return {
                options: []
            };
        }
    }, [DEFAULT_GROUP, fetchGroups]);

    return (
        <div className={`align-items-center justify-content-md-end mb-3 ${styles.leaderboardFilter}`}>
            <div className={`${communityConfig.classificationEnabled ? 'classification-item' : ''}`}
                 data-test-element-id="campaign-filter">
                <label htmlFor={CAMPAIGN_SELECT_ID} className="fw-bold float-start">
                    {localizer.msg('leaderboard.filter.campaign')}
                </label>
                <CampaignSelect
                    localizer={localizer}
                    ariaLabel={localizer.msg('leaderboard.filter.select-campaign')}
                    svgIconsPath={svgIconsPath}
                    classificationEnabled={communityConfig.classificationEnabled}
                    className={`campaign-search-dropdown ${communityConfig.classificationEnabled ? 'classification' : ''}`}
                    isMulti={false}
                    inputId={CAMPAIGN_SELECT_ID}
                    value={campaign || DEFAULT_CAMPAIGN}
                    onSelect={(value) => {
                        if (location.search) {
                            navigate({search: ''}, {replace: true});
                        }
                        setCampaign(value as OptionType);
                    }}
                    fetchCampaigns={fetchCampaigns}
                    extraOptionsStart={{
                        options: [DEFAULT_CAMPAIGN], label: ''
                    }}
                />
            </div>
            <div data-test-element-id="group-filter">
                <label htmlFor={GROUP_SELECT_ID} className="fw-bold float-start">
                    {localizer.msg('leaderboard.filter.groups')}
                </label>
                <IdeascaleSelectWithPagination
                    ariaLabel={localizer.msg('leaderboard.filter.select-group')}
                    inputId={GROUP_SELECT_ID}
                    value={group || DEFAULT_GROUP}
                    loadOptions={loadGroups}
                    additional={{page: 0}}
                    onChange={(value: any) => {
                        if (value) {
                            setGroup(value);
                        }
                    }}
                />
            </div>
            <div data-test-element-id="time-period-filter">
                <label htmlFor={TIME_PERIOD_ID} className="fw-bold float-start">
                    {localizer.msg('leaderboard.filter.time-period')}
                </label>
                <IdeascaleSelect
                    ariaLabel={localizer.msg('leaderboard.filter.select-time-period')}
                    value={timePeriod || TIME_PERIOD_OPTIONS[0].options[0]}
                    inputId={TIME_PERIOD_ID}
                    onChange={(option: any) => setTimePeriod(option)}
                    options={TIME_PERIOD_OPTIONS}
                />
            </div>
        </div>
    );
});