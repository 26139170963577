import React, {Fragment, useEffect, useState} from 'react';
import {FieldValues, useForm} from 'react-hook-form';
import {useMutation, useQueryClient} from 'react-query';
import {QueryObserverResult} from 'react-query/types/core/types';
import {
    AlertEvent,
    AlertType,
    buildAlertEventData,
    eventDispatcher,
    HookFormProvider,
    Localizer,
    ServiceError,
    useApiErrorResponseHandler,
    useHandleFormSubmit
} from '@ideascale/commons';
import {ActionButton, Modal, ParagraphSkeleton} from '@ideascale/ui';
import {usePrepareCustomFields} from 'hooks/usePrepareCustomFields';
import {QUERY_KEYS} from 'constants/AppConstants';
import {ProfileFieldData} from 'models/ProfileFieldData';
import {CustomFieldRequestValue, ProfileFieldUpdateRequest} from 'models/types/ProfileFieldUpdateRequest';
import {ProfileSidebarResponse} from 'models/ProfileSidebarResponse';
import {ProfileFieldModalStatus} from 'models/ProfileFieldModalStatus';

type EditProfileQuestionsProps = {
    isOpen: boolean;
    toggle: () => void;
    localizer: Localizer;
    fetchProfileFields: (memberId: number) => Promise<ProfileFieldData[]>;
    updateProfileFields: (updateRequest: ProfileFieldUpdateRequest, memberId: number) => Promise<ProfileSidebarResponse>;
    verified: boolean;
    closable?: boolean;
    memberId: number;
    editMode?: 'pendingVerifiableFields' | 'incompleteRequiredFields' | undefined;
    reFetchProfileFieldModalStatus?: () => Promise<QueryObserverResult<ProfileFieldModalStatus, any>>;
}

const FORM_ID = 'edit-profile-questions-form';

export const EditProfileQuestions = (props: EditProfileQuestionsProps) => {
    const {
        isOpen,
        toggle,
        localizer,
        fetchProfileFields,
        updateProfileFields,
        verified = false,
        closable = true,
        memberId,
        editMode,
        reFetchProfileFieldModalStatus
    } = props;
    const queryClient = useQueryClient();
    const {renderCustomField, getFieldValues, prepareFieldTypeMapping} = usePrepareCustomFields('EDIT');
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [loading, setLoading] = useState(true);
    const [profileFields, setProfileFields] = useState<ProfileFieldData[]>([]);
    const methods = useForm();
    const {handleSubmit, setError} = methods;
    const onFormSubmit = useHandleFormSubmit(handleSubmit);
    const {mutate, isLoading} = useMutation(
        ({request}: { request: ProfileFieldUpdateRequest }) => updateProfileFields(request, memberId),
        {
            onSuccess: async (profileSidebarData) => {
                const queryKey = [QUERY_KEYS.PROFILE_SIDEBAR, memberId];
                queryClient.setQueryData(queryKey, profileSidebarData);
                if (editMode) {
                    if (editMode === 'pendingVerifiableFields') {
                        await reFetchProfileFieldModalStatus?.();
                    } else if (editMode === 'incompleteRequiredFields') {
                        await reFetchProfileFieldModalStatus?.();
                    }
                }
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, localizer.msg('profile.profile-details.profile-fields-answered')));
                toggle();
            },
            onError: (error: ServiceError) => {
                handleErrorResponse(error, {
                    setFormError: setError
                });
            }
        });

    const onSubmit = async (data: FieldValues) => {
        const fieldValues: CustomFieldRequestValue[] = getFieldValues(data);

        try {
            mutate({request: {verified, fieldValues}});
        } catch (error: any) {
            handleErrorResponse(error, {
                setFormError: setError
            });
        }
    };

    useEffect(() => {
        if (isOpen) {
            setLoading(true);
            fetchProfileFields(memberId)
                .then(profileFields => {
                    prepareFieldTypeMapping(profileFields);
                    setProfileFields(profileFields);
                })
                .catch(error => {
                    handleErrorResponse(error);
                })
                .finally(() => setLoading(false));
        }
    }, [fetchProfileFields, handleErrorResponse, isOpen, memberId, prepareFieldTypeMapping]);

    return (
        <Modal className="profile-question-modal modal-lg" isOpen={isOpen} toggle={closable ? toggle : undefined}
               autoFocus={false}
               title={localizer.msg('profile.profile-details.edit.heading')}
               modalBodyClassName="my-2"
               modalHeaderId="edit-profile-questions"
               aria-labelledby="edit-profile-questions">
            <HookFormProvider {...methods}>
                <form id={FORM_ID} onSubmit={onFormSubmit(onSubmit, isLoading || profileFields.length < 1)}>
                    {
                        loading
                            ? <Fragment>
                                <div className="my-3"><ParagraphSkeleton rows={3}/></div>
                                <div className="my-3"><ParagraphSkeleton rows={3}/></div>
                                <div className="my-3"><ParagraphSkeleton rows={3}/></div>
                            </Fragment>
                            : profileFields.length > 0 ? profileFields.map((field, index) => renderCustomField(field, index))
                                : <div className="form-group text-muted">
                                    {localizer.msg('profile.profile-details.no-fields')}
                                </div>
                    }
                    <div className="d-flex flex-row justify-content-end">
                        {
                            closable &&
                            <button className="btn btn-cancel" onClick={() => {
                                toggle();
                                reFetchProfileFieldModalStatus?.().then();
                            }}>
                                {localizer.msg('common.cancel')}
                            </button>
                        }
                        <ActionButton type="submit" form={FORM_ID} loading={isLoading}
                                      disabled={profileFields.length < 1}>
                            {localizer.msg('common.submit')}
                        </ActionButton>
                    </div>
                </form>
            </HookFormProvider>
        </Modal>
    );
};