import React, {Fragment, useEffect, useState} from 'react';
import {range} from 'lodash';
import {Skeleton} from '@ideascale/ui';
import {Localizer, PageTheme} from '@ideascale/commons';
import {DisplayHeader} from 'components/landing-page/DisplayHeader';
import {ActivitySummaryItem} from 'components/landing-page/ActivitySummaryItem';
import {Component} from 'models/landing-page/Component';
import {ActivitySummaryType} from 'models/enums/landing-page/ActivitySummaryType';
import {ActivitySummaryRequest} from 'models/landing-page/ActivitySummaryRequest';
import {ActivitySummaryResponse} from 'models/landing-page/ActivitySummaryResponse';
import 'components/landing-page/ActivitySummaryComponent.scss';

type ActivitySummaryComponentProps = {
    config: Component;
    theme: PageTheme;
    localizer: Localizer;
    getActivitySummary: (timePeriodGroup: string, timePeriodUnit: number, parameters: ActivitySummaryRequest) => Promise<ActivitySummaryResponse[]>
}

const renderActivitySummarySkeletons = (amount: number = 6) => {
    return (
        <div className="row justify-content-center">
            {
                range(0, amount).map((item, index) => (
                    <Skeleton key={index} cssClass="m-3 rounded" width="250px" height="150px"/>
                ))
            }
        </div>
    );
};

export const ActivitySummaryListComponent = (props: ActivitySummaryComponentProps) => {
    const {config, theme, localizer, getActivitySummary} = props;

    const [loading, setLoading] = useState(false);
    const [activitySummaries, setActivitySummaries] = useState<ActivitySummaryResponse[]>([]);

    useEffect(() => {
        setLoading(true);

        const parameters: ActivitySummaryRequest = {
            activityTypes: [
                ActivitySummaryType.LOGIN,
                ActivitySummaryType.ENGAGED_USER,
                ActivitySummaryType.IDEA_VIEW,
                ActivitySummaryType.IDEA,
                ActivitySummaryType.COMMENT,
                ActivitySummaryType.VOTE,
            ]
        };

        getActivitySummary('weekly', 4, parameters).then(data => {
            setActivitySummaries(data);
            setLoading(false);
        });
    }, [getActivitySummary]);

    return (
        <Fragment>
            <DisplayHeader theme={theme} title={config.title} subtitle={config.subtitle}/>
            {
                loading ?
                    renderActivitySummarySkeletons(6)
                    :
                    <div className="row justify-content-center activity-summaries mx-0">
                        {
                            activitySummaries.map(activitySummary => (
                                <ActivitySummaryItem key={activitySummary.activityType} theme={theme}
                                                     localizer={localizer}
                                                     activitySummary={activitySummary}/>
                            ))
                        }
                    </div>
            }
        </Fragment>
    );
};