import React, {useCallback, useEffect, useMemo} from 'react';
import {FieldValues, useForm, useWatch} from 'react-hook-form';
import {
    AiAssistance,
    AiAssistanceFieldNames,
    AiContentResponse,
    isHtmlInject,
    Localizer,
    useHandleFormSubmit
} from '@ideascale/commons';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useAppContext} from 'contexts/AppContext';
import {useRouteUtils} from 'hooks/useRouteUtils';
import {useEditModeFormErrorHandler} from 'hooks/useEditModeFormErrorHandler';
import {UpdateCampaignNameCommand} from 'commands/edit-mode/UpdateCampaignNameCommand';
import {ConfigurationActionBar} from 'components/shared/ConfigurationActionBar';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';

const CAMPAIGN_NAME = 'name';
const CAMPAIGN_NAME_MAX_LENGTH = 128;

type CampaignNameEditFormProps = {
    toggleModal: () => void;
    localizer: Localizer;
    fetchAiAssistedName: (prompt: string, fieldName: AiAssistanceFieldNames, chatId?: number) => Promise<AiContentResponse>;
}
export const CampaignNameEditForm = (props: CampaignNameEditFormProps) => {
    const {toggleModal, localizer, fetchAiAssistedName} = props;
    const {campaignHomeEditor, commandExecutor, validationErrors} = useEditModeContext();
    const {communityConfig: {aiTextAssistEnabled}, authentication: {actor}} = useAppContext();
    const {register, handleSubmit, setFocus, control, setValue, formState: {errors, isDirty}, setError} = useForm({
        defaultValues: {
            [CAMPAIGN_NAME]: campaignHomeEditor.name
        }
    });
    const onFormSubmit = useHandleFormSubmit(handleSubmit);
    const {campaignRouteMatch} = useRouteUtils();
    const {validationErrorHandler} = useEditModeFormErrorHandler({
        localizer,
        validationErrors: validationErrors.findError(CampaignOperationType.CHANGE_CAMPAIGN_NAME)
    });
    const campaignNameValue: string = useWatch({
        name: CAMPAIGN_NAME,
        control
    });

    const onSubmit = (data: FieldValues) => {
        const campaignId = (campaignRouteMatch?.params as any)?.campaignId;
        const command = new UpdateCampaignNameCommand(campaignHomeEditor, data[CAMPAIGN_NAME].trim(), campaignId);
        commandExecutor.execute(command);
        validationErrors.clearError(command.getType());
        toggleModal();
    };
    const validate = (value: any) => {
        if (isHtmlInject(value)) {
            return localizer.msg('common.errors.html');
        } else if (!value.trim()) {
            return localizer.msg('common.errors.required');
        }
        return true;
    };

    const remainingLength = useMemo(() => {
        return CAMPAIGN_NAME_MAX_LENGTH - (campaignNameValue?.length ?? 0);
    }, [campaignNameValue]);

    const onAiResponseUsed = useCallback((content: string) => {
        setValue(CAMPAIGN_NAME, content, {shouldDirty: true, shouldTouch: true, shouldValidate: true});
    }, [setValue]);

    useEffect(() => {
        setFocus(CAMPAIGN_NAME);
    }, [setFocus]);

    useEffect(() => {
        validationErrorHandler(setError);
    }, [setError, validationErrorHandler]);

    return (
        <form onSubmit={onFormSubmit(onSubmit, !isDirty)}>
            <div className={`form-group w-100 ${errors[CAMPAIGN_NAME] ? 'has-error' : ''}`}>
                <div className="d-flex">
                    <label className="fw-bold" htmlFor={CAMPAIGN_NAME}>
                        {localizer.msg('edit-mode.campaign.name')}
                    </label>
                    {
                        aiTextAssistEnabled &&
                        <AiAssistance className="ms-auto"
                                      type="text"
                                      fieldName={AiAssistanceFieldNames.CAMPAIGN_NAME}
                                      localizer={localizer}
                                      title={localizer.msg('ai-assistance.campaign-name-label')}
                                      userAvatar={actor.avatar}
                                      getInitialPrompt={() => campaignNameValue}
                                      fetchAiAssistedText={fetchAiAssistedName}
                                      onAiResponseUsed={onAiResponseUsed}/>
                    }
                </div>
                <input className="form-control form-control-lg" type="text" id={CAMPAIGN_NAME}
                       maxLength={CAMPAIGN_NAME_MAX_LENGTH}
                       {...register(CAMPAIGN_NAME, {
                           required: localizer.msg('common.errors.required'),
                           maxLength: {
                               value: CAMPAIGN_NAME_MAX_LENGTH,
                               message: localizer.msg('frontend-shared.fields.errors.character-limit', {characterCount: CAMPAIGN_NAME_MAX_LENGTH}),
                           },
                           validate
                       })}
                />
                <div className="text-muted mt-1">
                    {localizer.msg('idea.form.field.feedback.text-length', {count: remainingLength})}
                </div>
                {
                    errors[CAMPAIGN_NAME] &&
                    <div className="invalid-feedback d-block" aria-live="assertive">
                        {
                            errors[CAMPAIGN_NAME].message
                        }
                    </div>
                }
            </div>
            <ConfigurationActionBar updateBtnType="submit" localizer={localizer} onCancel={toggleModal}
                                    updateDisabled={!isDirty}/>
        </form>
    );
};