import React from 'react';
import {useParams} from 'react-router-dom';
import {Icon} from '@ideascale/ui';
import {ClassificationFieldAttribute} from '@ideascale/commons';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useAppContext} from 'contexts/AppContext';
import {StageFunctionName} from 'models/enums/StageFunctionName';
import {IdeaDetailsRouteMatchParams} from 'models/types/IdeaDetailsRouteMatchParams';
import './StageCallToAction.scss';

type StageCallToActionProps = {
    stageFnKey: StageFunctionName;
    label: string;
    stageIconName: string;
    onClick: () => void;
    disabled?: boolean;
    title?: string;
    className?: string;
    classification?: ClassificationFieldAttribute[]
}

export const StageCallToAction = (props: StageCallToActionProps) => {
    const {
        stageFnKey,
        label,
        stageIconName,
        onClick,
        disabled = false,
        title = label,
        className = '',
        classification
    } = props;
    const {communityConfig} = useAppContext();
    const params = useParams<IdeaDetailsRouteMatchParams>();
    return (
        <button
            className={`btn btn-stage-action d-flex text-start ${className} ${communityConfig.classificationEnabled && params.ideaId && classification ? 'mt-5 mb-1' : ''}`}
            data-fn-key={stageFnKey} onClick={onClick}
            disabled={disabled}>
            {
                stageIconName &&
                <span className="btn-icon d-inline-block"><Icon className="position-relative"
                                                                iconSpritePath={svgIconsPath}
                                                                name={stageIconName}/></span>
            }
            <span className="btn-label text-center align-self-center text-truncate" title={title}>
                {label}
            </span>
        </button>
    );
};