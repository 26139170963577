import {useCallback} from 'react';
import {FileUploadResponse, UploadedResponse, UploadProgressCallback} from '@ideascale/commons';
import {useAppService} from './useService';

export const useFileUploadService = () => {
    const appService = useAppService();

    const uploadFile = useCallback(async (data: FormData, onUploadProgress: UploadProgressCallback) => {
        if (appService !== null) {
            return await appService.uploadFile(data, onUploadProgress);
        }
        return FileUploadResponse.EMPTY;
    }, [appService]);

    const uploadImage = useCallback(async (data: FormData, onUploadProgress: UploadProgressCallback) => {
        if (appService !== null) {
            return await appService.uploadImage(data, onUploadProgress);
        }
        return FileUploadResponse.EMPTY;
    }, [appService]);

    const tempImageUpload = useCallback(async (data: FormData, onUploadProgress: UploadProgressCallback) => {
        if (appService !== null) {
            return await appService.tempImageUpload(data, onUploadProgress);
        }
        return UploadedResponse.EMPTY;
    }, [appService]);

    return {
        uploadFile,
        uploadImage,
        tempImageUpload,
    };
};