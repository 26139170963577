import React, {Fragment, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import {NavItem} from 'reactstrap';
import {BodyTagAttributes, eventDispatcher, useRoutesMatch} from '@ideascale/commons';
import {PAGE_IDS, SCROLL_EVENTS} from 'constants/AppConstants';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useProfilePageParams} from 'hooks/useProfilePageParams';
import {useUpdateCurrentCampaign} from 'hooks/useUpdateCurrentCampaign';
import {PageLayoutContainer} from 'containers/PageLayoutContainer';
import {ProfileSidebarContainer} from 'containers/sidebar/ProfileSidebarContainer';
import {ProfileMainContainer} from 'containers/ProfileMainContainer';
import {IdeaSubmissionPreviewContainer} from 'containers/IdeaSubmissionPreviewContainer';
import {PageHeader} from 'components/PageHeader';
import {PageNavbar} from 'components/PageNavbar';
import {appLinks} from 'services/AppLinks';
import {ROUTES} from 'shared/Routes';
import './ProfilePage.scss';

export const ProfilePage = () => {
    const localizer = useLocalizer();
    const {authentication: {actor}, communityConfig} = useAppContext();
    const currentProfileId = useProfilePageParams();
    const isMyIdeasPage = useRoutesMatch(ROUTES.MY_IDEAS);
    useUpdateCurrentCampaign();

    useEffect(() => {
        eventDispatcher.dispatch(SCROLL_EVENTS.SCROLL_TOP);
    }, [currentProfileId]);

    return (
        <Fragment>
            <BodyTagAttributes data-test-element-id={PAGE_IDS.PROFILE_PAGE}/>
            <IdeaSubmissionPreviewContainer displayAsModal/>
            <PageHeader logo={communityConfig.logo} logoAltText={communityConfig.altText}
                        hideLogo={communityConfig.hideCommunityLogo}
                        logoBgTransparent={!communityConfig.logoFrameEnabled}
                        bannerImage={communityConfig.bannerImageUrl}
                        bannerAltText={communityConfig.bannerImageAltText}
                        bannerGradientEnabled={communityConfig.bannerGradientEnabled}
                        textColor={communityConfig.bannerTitleInBlack ? 'dark' : 'light'}
                        heading={communityConfig.name}
                        headingMeta=""/>
            <PageNavbar navbarAriaLabel={localizer.msg('profile.nav-label')}>
                <NavItem className="nav-item px-2 px-md-3">
                    <NavLink className={({isActive}) => `${isActive ? 'active' : ''} nav-link px-0 nav-link`}
                             to={appLinks.activity(currentProfileId)} data-test-element-id="profile-activity">
                        {localizer.msg('profile.tabs.activity')}
                    </NavLink>
                </NavItem>
                {currentProfileId === actor.id &&
                    <Fragment>
                        <NavItem className="nav-item px-2 px-md-3">
                            <NavLink className={({isActive}) => `${isActive ? 'active' : ''} nav-link px-0 nav-link`}
                                     to={appLinks.notifications(currentProfileId)} data-test-element-id="profile-notifications">
                                {localizer.msg('profile.tabs.notifications')}
                            </NavLink>
                        </NavItem>
                        <NavItem className="nav-item px-2 px-md-3">
                            <NavLink className={`nav-link px-0 nav-link ${isMyIdeasPage ? 'active' : ''}`}
                                     to={appLinks.myIdeas(String(currentProfileId))} data-test-element-id="profile-my-ideas">
                                {localizer.msg('profile.tabs.my-ideas')}
                            </NavLink>
                        </NavItem>
                        {
                            communityConfig.privateMessageEnabled &&
                            <NavItem className="nav-item px-2 px-md-3">
                                <NavLink className={({isActive}) => `${isActive ? 'active' : ''} nav-link px-0 nav-link`}
                                         to={appLinks.messages(currentProfileId)} data-test-element-id="profile-messages">
                                    {localizer.msg('profile.tabs.messages')}
                                </NavLink>
                            </NavItem>
                        }
                    </Fragment>
                }
            </PageNavbar>

            <PageLayoutContainer
                className="profile-container"
                mainContent={<ProfileMainContainer/>}
                sidebar={<ProfileSidebarContainer/>}
            />
        </Fragment>
    );
};