import React from 'react';
import range from 'lodash/range';
import {Localizer} from '@ideascale/commons';
import {ParagraphSkeleton, Skeleton} from '@ideascale/ui';
import 'components/landing-page/IdeaCardViewList.scss';

type IdeaCardViewSkeletonProps = {
    localizer: Localizer;
}

export const IdeaCardViewSkeleton = (props: IdeaCardViewSkeletonProps) => {
    const {localizer} = props;

    return (
        <div className="row trending-ideas w-100 rounded-top">
            <div className="col-12 col-lg-6">
                <div className="trending-idea h-100 card panel">
                    <Skeleton width="100%" height="300px"/>
                    <div className="card-body panel-body">
                        <ParagraphSkeleton rows={4}/>
                        <button className="btn btn-secondary mt-auto" disabled>
                            {localizer.msg('landing-page.components.common.read-more')}
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-6">
                {range(0, 3).map(index => (
                    <div className="trending-idea d-flex flex-row card panel align-items-center side-idea" key={index}>
                        <div className="col-5">
                            <Skeleton width="100%" height="150px"/>
                        </div>
                        <div className="card-body panel-body col-7">
                            <ParagraphSkeleton rows={4}/>
                            <button className="btn btn-secondary mt-auto" disabled>
                                {localizer.msg('landing-page.components.common.read-more')}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}