import React from 'react';
import {Link} from 'react-router-dom';
import {Localizer} from '@ideascale/commons';
import {ActivityStreamData} from 'models/ActivityStreamData';
import {ActionType} from 'models/enums/ActionType';
import {appLinks} from 'services/AppLinks';

type StageActionActivityProps = {
    localizer: Localizer;
    activity: ActivityStreamData;
    action: ActionType.MEMBER_ASSESSMENT_COMPLETE | ActionType.MEMBER_REVIEW_COMPLETE | ActionType.MEMBER_ESTIMATE_ROI
        | ActionType.MEMBER_FUND_PLEDGE | ActionType.MEMBER_REFINEMENT_COMPLETE;
}

export const StageActionActivity = (props: StageActionActivityProps) => {
    const {localizer, activity, action} = props;

    const renderActivityLabel = () => {
        switch (action) {
            case ActionType.MEMBER_FUND_PLEDGE:
                return localizer.msg('profile.activity-stream.fund.complete');
            case ActionType.MEMBER_ASSESSMENT_COMPLETE:
                return localizer.msg('profile.activity-stream.assessment.complete')
            case ActionType.MEMBER_REVIEW_COMPLETE:
                return localizer.msg('profile.activity-stream.reviewscale.complete');
            case ActionType.MEMBER_ESTIMATE_ROI:
                return localizer.msg('profile.activity-stream.estimate.complete');
            case ActionType.MEMBER_REFINEMENT_COMPLETE:
                return localizer.msg('profile.activity-stream.refine.complete');
        }
    };

    return (
        <span>
            {renderActivityLabel()}{' - '}
            {activity.idea.id
                ? <Link to={appLinks.ideaDetails(activity.idea.id)}>{activity.idea.title}</Link>
                : activity.idea.title
            }
        </span>
    );
};