export class NotificationIcon {
    private static readonly IS_SVG_PROTOCOL = 'ideascale-ui-svg:';

    constructor(
        public url: string,
        public alt: string,
        public title: string
    ) {
    }

    isSvg() {
        return this.url.startsWith(NotificationIcon.IS_SVG_PROTOCOL);
    }

    isTagSvg() {
        return this.isSvg() && this.getSvgName() === 'tag';
    }

    isDigest() {
        return this.isSvg() && this.getSvgName() === 'digest';
    }

    getSvgName() {
        return this.url.replace(NotificationIcon.IS_SVG_PROTOCOL, '');
    }

    getUrl() {
        return this.isSvg() ? this.getSvgName() : this.url;
    }
}