import {animateScroll, Events, scroller} from 'react-scroll';

export class Scroller {
    static scrollTo(targetId: string, options?: { duration?: number, offset?: number, delay?: number, flashing?: boolean, containerId?: string }) {
        const defaultOptions: any = {
            duration: options?.duration || 200,
            delay: options?.delay || 0,
            smooth: true,
            offset: options?.offset || -50,
            containerId: options?.containerId
        };

        if (options?.flashing) {
            const targetEl = document.getElementById(targetId);
            if (targetEl) {
                const goToElement = new Promise<void>((resolve) => {
                    Events.scrollEvent.register('end', () => {
                        resolve();
                        Events.scrollEvent.remove('end');
                    });
                    scroller.scrollTo(targetId, defaultOptions);
                });

                goToElement.then(() => {
                    if (targetEl) {
                        targetEl.classList.add('flash');
                        setTimeout(() => targetEl.classList.remove('flash'), 1200);
                    }
                });
            }
        } else {
            scroller.scrollTo(targetId, defaultOptions);
        }
    }

    static scrollToTop() {
        animateScroll.scrollToTop({
            duration: 200,
            smooth: true,
            delay: 0
        });
    }
}