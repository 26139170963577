import {Member} from '@ideascale/commons';

export class CampaignTeamData {
    static readonly EMPTY = new CampaignTeamData([], [], [], false, false, false);

    constructor(
        public sponsors: Member[],
        public moderators: Member[],
        public globalModerators: Member[],
        public campaignModeratorsSectionEnabled: boolean,
        public globalModeratorsSectionEnabled: boolean,
        public canSeeCampaignTeam: boolean,
    ) {
    }
}