import React, {useCallback} from 'react';
import {PrivateMessageRequestParams} from '@ideascale/commons';
import {useMessageService} from 'hooks/useMessageService';
import {useCommunityService} from 'hooks/useService';
import {useClassificationService} from 'hooks/useClassificationService';
import {Messages} from 'components/profile/messages/Messages';
import {PageParameters} from 'models/types/PageParameters';
import {ProfileInboxData} from 'models/ProfileInboxData';
import {PagedResponseContent} from 'models/PagedResponseContent';

export const MessageContainer = () => {
    const communityService = useCommunityService();
    const {fetchRecipients, onMemberSendMessages} = useMessageService();
    const {fetchClassifications} = useClassificationService();

    const fetchInboxMessages = useCallback(async (pageParams: PageParameters): Promise<PagedResponseContent<ProfileInboxData>> => {
        if (communityService !== null) {
            return await communityService.fetchInboxMessages(pageParams);
        }
        return PagedResponseContent.nullObject<ProfileInboxData>();
    }, [communityService]);

    const fetchSentMessages = useCallback(async (pageParams: PageParameters): Promise<PagedResponseContent<ProfileInboxData>> => {
        if (communityService !== null) {
            return await communityService.fetchSentMessages(pageParams);
        }
        return PagedResponseContent.nullObject<ProfileInboxData>();
    }, [communityService]);

    const fetchCountUnread = useCallback(async (): Promise<number> => {
        if (communityService !== null) {
            return await communityService.fetchCountUnread();
        }
        return 0;
    }, [communityService]);

    return (
        <Messages fetchCountUnread={fetchCountUnread}
                  fetchSentMessages={fetchSentMessages}
                  fetchRecipients={fetchRecipients}
                  fetchClassifications={fetchClassifications}
                  onSendMessage={(requestParams: PrivateMessageRequestParams) => onMemberSendMessages(requestParams)}
                  fetchInboxMessages={fetchInboxMessages}/>
    );
};

