import React, {useCallback, useEffect, useRef, useState} from 'react';
import Slider from 'react-slick';
import {useAppContext} from 'contexts/AppContext';
import {Avatar, Button, Icon, ParagraphSkeleton} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {MemberLink, StringUtil, useApiErrorResponseHandler} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {KudoImage} from 'shared/KudoImage';
import {KudosData} from 'models/KudosData';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const NAME_SIZE = 20;

type kudosProps = {
    fetchKudos: () => Promise<KudosData>;
}

export const Kudos = (props: kudosProps) => {
    const {fetchKudos} = props;
    const localizer = useLocalizer();
    const {editModeEnabled} = useAppContext();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [kudos, setKudos] = useState<KudosData>(KudosData.EMPTY);
    const [loading, setLoading] = useState(true);
    const [slidesToShow, setSlidesToShow] = useState(4);
    const [isPaused, setIsPaused] = useState(false);
    const sliderWrapperRef = useRef<HTMLDivElement>(null);
    const sliderRef = useRef<Slider | null>(null);

    const pauseSlides = () => {
        sliderRef.current?.slickPause();
    };

    const playSlides = () => {
        sliderRef.current?.slickPlay();
    };

    const togglePlay = useCallback(() => {
        if (sliderRef.current) {
            if (isPaused) {
                setIsPaused(false);
                playSlides();
            } else {
                setIsPaused(true);
                pauseSlides();
            }
        }
    }, [isPaused]);

    const updateKudosSlideLinksTabIndex = () => {
        if (sliderWrapperRef.current && !editModeEnabled) {
            const allSlickSlides = sliderWrapperRef.current.querySelectorAll('.slick-slide:not(.slick-active)');
            allSlickSlides.forEach(slide => {
                const kudosItem = slide.querySelectorAll('a');
                kudosItem.forEach(link => {
                    link.setAttribute('tabIndex', '-1');
                });
            });

            const allActiveSlickSlides = sliderWrapperRef.current.querySelectorAll('.slick-slide.slick-active');
            allActiveSlickSlides.forEach(slide => {
                const kudosItem = slide.querySelectorAll('a');
                kudosItem.forEach(link => {
                    link.setAttribute('tabIndex', '0');
                });
            });
        }
    };

    const settings = {
        autoplay: true,
        vertical: true,
        accessibility: true,
        arrows: false,
        infinite: true,
        slidesToShow: slidesToShow,
        pauseOnHover: true,
        className: (kudos.kudoActivities.length < 1 ? 'empty-kudos' : ''),
        onInit: () => updateKudosSlideLinksTabIndex(),
        onReInit: () => updateKudosSlideLinksTabIndex()
    };

    useEffect(() => {
        fetchKudos().then(data => {
            if (data.kudoActivities.length >= 4) {
                setSlidesToShow(4);
            } else {
                setSlidesToShow(data.kudoActivities.length);
            }
            setKudos(data);
            setLoading(false);
        }).catch(error => {
            handleErrorResponse(error);
            setLoading(false);
        });

    }, [fetchKudos, handleErrorResponse]);

    return (
        <div className="kudos-ticker">
            <ul className="kudos-stats list-unstyled d-flex justify-content-center align-items-center mb-2 py-3 border-bottom-1"
                aria-label={localizer.msg('sidebar.kudos.summary')}>
                <li className="kudos-count d-flex flex-column align-items-center px-3 border-end" title={localizer.msg('sidebar.kudos.received-title')}>
                    <span className="sr-only">
                        {localizer.msg('sidebar.kudos.received-sr-label', {count: kudos.receivedCount})}
                    </span>
                    <div className="number fw-bold" aria-hidden="true">{kudos.receivedCount}</div>
                    <div className="text-center" aria-hidden="true">{localizer.msg('sidebar.kudos.received-stat')}</div>
                </li>
                <li className="kudos-count d-flex flex-column align-items-center px-3 border-end" title={localizer.msg('sidebar.kudos.given-title')}>
                    <span
                        className="sr-only">
                        {localizer.msg('sidebar.kudos.given-sr-label', {count: kudos.givenCount})}
                    </span>
                    <div className="number fw-bold" aria-hidden="true">{kudos.givenCount}</div>
                    <div className="text-center" aria-hidden="true">{localizer.msg('sidebar.kudos.given')}</div>
                </li>
                <li className="kudos-count d-flex flex-column align-items-center px-3" title={localizer.msg('sidebar.kudos.balance-title')}>
                    <span className="sr-only">
                        {localizer.msg('sidebar.kudos.balance-sr-label', {count: kudos.kudoBalance})}
                    </span>
                    <div className="number fw-bold" aria-hidden="true">{kudos.kudoBalance}</div>
                    <div className="text-center" aria-hidden="true">{localizer.msg('sidebar.kudos.balance')}</div>
                </li>
            </ul>

            <div ref={sliderWrapperRef} className="kudos-list">
                <Slider {...settings} ref={sliderRef}>
                    {loading ?
                        <ParagraphSkeleton rows={5}/> :
                        (kudos.kudoActivities.length > 0 ?
                                kudos.kudoActivities.map((item, index) => {
                                    return (
                                        <div className="kudos d-inline-flex align-items-center fw-normal"
                                             key={index}>
                                            <Avatar size="sm" className="me-2" src={item.receiver.avatar}
                                                    alt={item.receiver.username ? localizer.msg('common.user-avatar', {username: item.receiver.username}) : ''}/>
                                            <div className="kudos-status">
                                                <span className="receiver">
                                                    <MemberLink className="text-decoration-none fw-bold"
                                                                onFocus={pauseSlides}
                                                                onBlur={playSlides}
                                                                id={item.receiver.id}
                                                                identityHidden={item.receiver.identityHidden}
                                                                title={item.receiver.name}>
                                                        {StringUtil.textTruncate(item.receiver.name, NAME_SIZE)}
                                                    </MemberLink>{' '}
                                                    {localizer.msg('sidebar.kudos.received')}
                                                    <KudoImage className="d-inline ms-1 me-1"
                                                               localizer={localizer} kudoType={item.kudoType}/>
                                                    {localizer.msg('sidebar.kudos.from')} {''}
                                                    <MemberLink className="text-decoration-none fw-bold"
                                                                onFocus={pauseSlides}
                                                                onBlur={playSlides}
                                                                id={item.giver.id}
                                                                identityHidden={item.giver.identityHidden}
                                                                title={item.giver.name}>
                                                        {StringUtil.textTruncate(item.giver.name, NAME_SIZE)}
                                                    </MemberLink>
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })
                                :
                                <div className="p-2 text-truncate" title={localizer.msg('sidebar.kudos.no-kudos')}>
                                    {localizer.msg('sidebar.kudos.no-kudos')}
                                </div>
                        )
                    }
                </Slider>
            </div>
            {
                kudos.kudoActivities.length > slidesToShow &&
                <div className="d-flex justify-content-center align-items-center mb-3">
                    <div className="slider-controls d-inline-flex gap-2 py-1 px-2">
                        <Button color="default"
                                className="p-0 line-height-0"
                                title={localizer.msg('sidebar.kudos.previous')}
                                onClick={() => sliderRef.current?.slickPrev()}>
                            <Icon iconSpritePath={svgIconPath} name="arrow-up-outlined-circle" height={24} width={24}/>
                        </Button>
                        <Button color="default"
                                className="p-0 line-height-0"
                                title={localizer.msg(isPaused ? 'sidebar.kudos.play' : 'sidebar.kudos.pause')}
                                onClick={togglePlay}>
                            <span
                                className="sr-only">{localizer.msg(isPaused ? 'sidebar.kudos.play' : 'sidebar.kudos.pause')}</span>
                            {
                                isPaused
                                    ? <Icon iconSpritePath={svgIconPath} name="play-outlined-circle" height={24}
                                            width={24}/>
                                    : <Icon iconSpritePath={svgIconPath} name="pause-outlined-circle" height={24}
                                            width={24}/>
                            }
                        </Button>
                        <Button color="default"
                                className="p-0 line-height-0"
                                title={localizer.msg('sidebar.kudos.next')}
                                onClick={() => sliderRef.current?.slickNext()}>
                            <Icon iconSpritePath={svgIconPath} name="arrow-down-outlined-circle" height={24}
                                  width={24}/>
                        </Button>
                    </div>
                </div>
            }
        </div>
    );
};