import React, {useCallback, useState} from 'react';
import {FieldValues} from 'react-hook-form';
import {HtmlConverter} from '@ideascale/ui';
import {
    CheckboxField,
    CurrencyField,
    CustomField,
    DateField,
    FieldType,
    MultipleChoiceField,
    SingleChoiceField,
    TextAreaField,
    TextField,
    US_ZIPCODE_MAXLENGTH
} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from './useLocalizer';
import {useFileUploadService} from './useFileUploadService';
import {ProfileFieldData} from 'models/ProfileFieldData';
import {CustomFieldRequestValue} from 'models/types/ProfileFieldUpdateRequest';

export const usePrepareCustomFields = (mode: 'EDIT' | 'VIEW' = 'VIEW') => {
    const localizer = useLocalizer();
    const {communityConfig: {dateTimeFormat, maxFileSizeLimit}, authentication} = useAppContext();
    const [fieldTypeMap, setFieldTypeMap] = useState<Map<string, { id: number, type: FieldType }>>(new Map());
    const {tempImageUpload} = useFileUploadService();

    const renderCustomField = useCallback((field: ProfileFieldData, index?: number) => {
        const focus = index === 0;
        switch (field.type) {
            case FieldType.HYPERLINK:
            case FieldType.GLOBAL_ZIPCODE:
            case FieldType.TEXTINPUT:
            case FieldType.INTEGER:
                return <TextField formField={field} key={field.key} autoFocus={focus} localizer={localizer}/>;
            case FieldType.ZIPCODE:
                return <TextField formField={field} key={field.key} maxLength={US_ZIPCODE_MAXLENGTH} autoFocus={focus}
                                  localizer={localizer}/>;
            case FieldType.TEXT_AREA:
                return <TextAreaField formField={field} localizer={localizer} key={field.key} autoFocus={focus}
                                      tempImageUpload={tempImageUpload} maxFileSize={maxFileSizeLimit}
                                      toolbarType={authentication.isJoinCommunityAvailable() ? 'minimal' : 'standard'}/>;
            case FieldType.CURRENCY:
                return <CurrencyField formField={field} key={field.key} autoFocus={focus} localizer={localizer}/>;
            case FieldType.CHECKBOX:
                return <CheckboxField formField={field} key={field.key} autoFocus={focus} localizer={localizer}/>;
            case FieldType.SINGLE_CHOICE:
                return <SingleChoiceField localizer={localizer} formField={field} key={field.key} autoFocus={focus}
                                          defaultValue={mode === 'EDIT' ? CustomField.getSingleChoiceSavedValue(field) : CustomField.getSingleChoiceDefaultValue(field)}/>;
            case FieldType.MULTIPLE_CHOICE:
                return <fieldset aria-label={field.label}>
                    <MultipleChoiceField formField={field} key={field.key} autoFocus={focus} localizer={localizer}
                                         defaultValues={mode === 'EDIT' ? CustomField.getMultipleChoiceSavedValues(field) : CustomField.getMultipleChoiceDefaultValues(field)}/>
                </fieldset>;
            case FieldType.DATE:
                return <DateField
                    localizer={localizer}
                    key={field.key}
                    formField={field}
                    dateFormat={dateTimeFormat.datePattern}
                    autoFocus={focus}/>;
            default:
                return null;
        }
    }, [authentication, dateTimeFormat.datePattern, localizer, maxFileSizeLimit, mode, tempImageUpload]);

    const prepareFieldTypeMapping = useCallback((profileFields: ProfileFieldData[]) => {
        const fieldTypes = profileFields.reduce((prevKeyMap, field) => {
            prevKeyMap.set(field.key, {id: field.id, type: field.type});
            return prevKeyMap;
        }, new Map());
        setFieldTypeMap(fieldTypes);
        return fieldTypes;
    }, []);

    const getFieldTypeMap = useCallback(() => {
        return fieldTypeMap;
    }, [fieldTypeMap]);

    const getFieldValues = useCallback((fieldInputs: FieldValues) => {
        let fieldValues: CustomFieldRequestValue[] = [];
        Object.keys(fieldInputs).forEach(fieldKey => {
            const field = fieldTypeMap.get(fieldKey);
            if (field) {
                let value = fieldInputs[fieldKey];
                if (field.type === FieldType.MULTIPLE_CHOICE) {
                    if (Array.isArray(value)) {
                        value = (value || []).join();
                    } else {
                        value = String(value || '');
                    }
                }
                if (field.type === FieldType.TEXT_AREA) {
                    value = HtmlConverter.toServerHtmlFormat(value);
                }
                fieldValues.push({key: fieldKey, value: value, fieldId: field.id});
            }
        });
        return fieldValues;
    }, [fieldTypeMap]);

    return {renderCustomField, getFieldTypeMap, prepareFieldTypeMapping, getFieldValues};
};