import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {flushSync} from 'react-dom';
import {Controller, useForm} from 'react-hook-form';
import {Button} from 'reactstrap';
import {
    ActionButton,
    Avatar,
    Checkbox,
    HtmlConverter,
    Icon,
    NewRichTextEditor,
    RichTextClassificationConfig,
    RichTextEditorHandler
} from '@ideascale/ui';
import {
    Actor,
    AttachmentAttributeParameters,
    AttachmentType,
    ClassificationAttachmentAttribute,
    ClassificationAttachmentAttributesData,
    ClassificationAttribute,
    ClassificationsHolder,
    ClassificationTreeMenu,
    ClassificationUtil,
    ClassifiedAttachment,
    EMBEDDED_FILE_NAME_PREFIX,
    FileAttachmentDetail,
    HookFormProvider,
    Localizer,
    RenderFormat,
    useApiErrorResponseHandler,
    useAtMentionConfiguration,
    useAttachmentClassificationErrorHandler,
    useFileUploadIntermediary,
    useHandleFormSubmit
} from '@ideascale/commons';
import {useCommentService} from 'hooks/useCommentService';
import {useFileUploadService} from 'hooks/useFileUploadService';
import {useIdeaAttachment} from 'hooks/useIdeaAttachment';
import {useAppContext} from 'contexts/AppContext';
import {CreateFileAttachment} from 'components/idea-details-tabs/CreateFileAttachment';
import {StageCommentSummary} from 'models/comments/StageCommentSummary';
import {StageCommentPermissions} from 'models/comments/StageCommentPermissions';
import {StageCommentType} from 'models/comments/StageCommentType';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import styles from './CommentForm.module.scss';

const STAGE_COMMENT_SUBMIT_FORM_ID = 'stage-comment-submit-form';

type StageCommentFormProps = {
    id: string;
    localizer: Localizer;
    actor: Actor;
    ideaId: number;
    parentId: number;
    sourceId: number;
    stageCommentType: StageCommentType;
    commentPermissions: StageCommentPermissions;
    addNewComment: (comment: StageCommentSummary) => void;
    onCancel?: () => void;
    atMention?: string;
    classificationsMenuData?: ClassificationsHolder | {};
    richTextClassificationConfig?: RichTextClassificationConfig;
}

type StageCommentFormFields = {
    sourceId: number;
    typeId: StageCommentType | undefined;
    ideaId: number;
    parentId: number;
    text: string;
    annotated: boolean;
    attachments: FileAttachmentDetail[];
    renderFormat: RenderFormat;
    attributeApiParameters?: Array<AttachmentAttributeParameters>;
}

export const StageCommentForm = (props: StageCommentFormProps) => {
    const {
        id,
        localizer,
        actor,
        ideaId,
        addNewComment,
        commentPermissions,
        parentId,
        sourceId,
        stageCommentType,
        onCancel,
        atMention,
        classificationsMenuData,
        richTextClassificationConfig,
    } = props;
    const {
        communityConfig: {
            atMentionEnabled,
            maxFileSizeLimit,
            offensiveEmojis,
            classificationEnabled
        },
        currentCampaign
    } = useAppContext();
    const {fetchAtMentionMembers, createStageComment} = useCommentService();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const {tempImageUpload, uploadFile} = useFileUploadService();
    const {onUploadFile, fileUploading} = useFileUploadIntermediary(uploadFile);
    const {updateIdeaDetailsCommentAttachments} = useIdeaAttachment();
    const richTextEditorNode = useRef<RichTextEditorHandler>(null);
    const [clearAllAttachments, setClearAllAttachments] = useState<boolean>(false);
    const [selectedTextClassification, setSelectedTextClassification] = useState<ClassificationAttribute>();
    const [selectedAttachmentClassification, setSelectedAttachmentClassification] = useState<ClassificationAttribute>();
    const [appliedAttachmentClassifications, setAppliedAttachmentClassifications] = useState<ClassifiedAttachment>();
    const [appliedAttachmentClassificationsData, setAppliedAttachmentClassificationsData] = useState<ClassificationAttachmentAttributesData>({});

    const defaultFormValues = {
        sourceId: 0,
        typeId: StageCommentType.UNDEFINED,
        parentId: 0,
        ideaId: 0,
        text: '',
        annotated: false,
        attachments: [],
    };
    const methods = useForm<StageCommentFormFields>({
        defaultValues: defaultFormValues,
        mode: 'onSubmit',
        reValidateMode: 'onSubmit'
    });

    const {
        register,
        handleSubmit,
        control,
        reset,
        setError,
        clearErrors,
        formState: {errors, isSubmitting}
    } = methods;
    const onSubmit = useHandleFormSubmit(handleSubmit);

    const {onClassificationError} = useAttachmentClassificationErrorHandler({
        fieldKey: 'attachments',
        setError,
        localizer
    });

    const fetchAtMentionData = useAtMentionConfiguration(
        atMentionEnabled,
        fetchAtMentionMembers,
        {
            campaignId: currentCampaign?.id ?? 0,
            ideaId,
        }
    );

    const onFormSubmit = async (formInputs: StageCommentFormFields) => {
        try {
            const response = await createStageComment({
                ...formInputs,
                text: HtmlConverter.toServerHtmlFormat(formInputs.text),
                ideaId: ideaId,
                parentId: parentId,
                sourceId: sourceId,
                typeId: stageCommentType,
                renderFormat: RenderFormat.HTML,
                attachments: formInputs.attachments.map(attachment => ({
                    filename: attachment.filename,
                    altText: attachment.altText.trim() || attachment.filename,
                })),
                attributeApiParameters: classificationEnabled ?
                    [
                        ClassificationUtil.buildAttachmentClassificationRequest(
                            'filename',
                            formInputs.attachments.map(attachment => attachment.filename),
                            appliedAttachmentClassificationsData['attachments'])
                    ] : undefined
            });
            if (response) {
                addNewComment && addNewComment(response);
                flushSync(() => setClearAllAttachments(true));
                reset(defaultFormValues);
                richTextEditorNode.current?.setTextWithBlur('');
                setSelectedAttachmentClassification(undefined);
                setAppliedAttachmentClassifications(undefined);
                setSelectedTextClassification(undefined);

                if (response.annotated && response?.attachments?.length > 0) {
                    await updateIdeaDetailsCommentAttachments(ideaId, response.attachments, (response.attributes?.filter(item => item.name === 'filename') as ClassificationAttachmentAttribute[]));
                }
            }
        } catch (error: any) {
            handleErrorResponse(error, {setFormError: setError});
        } finally {
            setClearAllAttachments(false);
        }
    };

    useEffect(() => {
        if (errors.text) {
            setTimeout(() => {
                richTextEditorNode.current?.focus();
            }, 300);
        }
    }, [errors]);

    useEffect(() => {
        if (atMention !== undefined) {
            richTextEditorNode.current?.setText(atMention);
            richTextEditorNode.current?.setCursorAtEnd();
        }
    }, [atMention]);

    const updateAppliedAttachmentClassifications = useCallback((key: string, value: ClassifiedAttachment) => {
        setAppliedAttachmentClassificationsData(prev => {
            return {...prev, [key]: value};
        });
        setAppliedAttachmentClassifications(value);
    }, []);

    return (
        <div id={id} className={`${styles.commentForm} d-flex ${parentId === ideaId ? 'border-top-1' : ''}`}>
            <Avatar className="me-2 me-lg-4" src={actor.avatar}
                    alt={actor.username ? localizer.msg('common.user-avatar', {username: actor.username}) : ''}
                    size="md"/>
            <div className="flex-grow-1 min-width">
                <HookFormProvider {...methods}>
                    <form id={`${STAGE_COMMENT_SUBMIT_FORM_ID}-${parentId}`}
                          onSubmit={onSubmit(onFormSubmit, isSubmitting || fileUploading)}>
                        <div className={`form-group stage-comment-text ${errors.text ? 'has-error' : ''}`}>
                            <Controller
                                name="text"
                                control={control}
                                rules={{
                                    required: localizer.msg('comments.comment-form.idea-text-required'),
                                    ...(classificationEnabled && {validate: () => !richTextEditorNode.current?.hasInvalidClassifications() || localizer.msg('frontend-shared.classification.invalid-classifications-msg')})
                                }}
                                render={({field: {onChange}}) =>
                                    <Fragment>
                                        {
                                            classificationEnabled
                                                ? (
                                                    <ClassificationTreeMenu
                                                        localizer={localizer}
                                                        id={`stage-comment-classification-menu-${id}`}
                                                        classifications={classificationsMenuData || {}}
                                                        appliedClassifications={selectedTextClassification}
                                                        onApplyClassifications={value => {
                                                            setSelectedTextClassification(value);
                                                            richTextEditorNode.current?.insertClassification(ClassificationUtil.getLabel(value));
                                                        }}/>
                                                )
                                                : null
                                        }
                                        <NewRichTextEditor
                                            toolbar={'standard'}
                                            className={'form-control'}
                                            placeholder={atMentionEnabled ? localizer.msg('idea.comment.form.placeholder-with-at-mention') : localizer.msg('idea.comment.form.placeholder')}
                                            svgIconPath={svgIconsPath}
                                            onChange={value => {
                                                clearErrors('text');
                                                onChange(value);
                                            }}
                                            ref={richTextEditorNode}
                                            enableEmojiPicker={true}
                                            offensiveEmojis={offensiveEmojis}
                                            enableAtMention={atMentionEnabled}
                                            fetchMentionUsers={fetchAtMentionData}
                                            uploadImage={tempImageUpload}
                                            maxFileSize={maxFileSizeLimit}
                                            classificationConfig={richTextClassificationConfig}
                                            defaultFileNamePrefix={EMBEDDED_FILE_NAME_PREFIX.COMMENT}
                                        />
                                    </Fragment>
                                }
                            />
                            {
                                errors.text && (
                                    <p className="invalid-feedback d-block" data-role="field-error" aria-live="polite">
                                        {errors.text.message}
                                    </p>
                                )
                            }
                        </div>
                        {
                            commentPermissions?.attachmentAllowed && (
                                <div
                                    className={`form-group ${errors.attachments && !Array.isArray(errors.attachments) ? 'has-error' : ''}`}>
                                    <label
                                        htmlFor={`${id}-attachments`} className="fw-bold me-1">
                                        <span>
                                           {localizer.msg('comments.comment-form.attachment-label')}
                                        </span>
                                    </label>
                                    <Fragment>
                                        {
                                            classificationEnabled && (
                                                <ClassificationTreeMenu
                                                    localizer={localizer}
                                                    id={`stage-comment-attachments-classification-menu-${id}`}
                                                    classifications={classificationsMenuData || {}}
                                                    appliedClassifications={selectedAttachmentClassification}
                                                    onApplyClassifications={value => {
                                                        clearErrors('attachments');
                                                        setSelectedAttachmentClassification(value);
                                                    }}/>
                                            )
                                        }
                                        <CreateFileAttachment
                                            localizer={localizer}
                                            inputId="attachments"
                                            maxFileSizeInMb={maxFileSizeLimit}
                                            uploadFile={onUploadFile}
                                            classificationsMenuData={classificationsMenuData}
                                            multiple={true}
                                            clearAll={clearAllAttachments}
                                            classificationEnabled={classificationEnabled}
                                            selectedClassification={selectedAttachmentClassification}
                                            updateAppliedAttachmentClassifications={updateAppliedAttachmentClassifications}
                                            appliedClassifications={appliedAttachmentClassifications}
                                            onClassificationError={onClassificationError}
                                            attachmentType={AttachmentType.STAGE_COMMENT}/>
                                        {
                                            errors?.attachments && (errors?.attachments as any)?.type === 'classificationRequired' && (
                                                <p className="invalid-feedback d-block" data-role="field-error"
                                                   aria-live="polite">
                                                    {(errors.attachments as any)?.message}
                                                </p>
                                            )
                                        }
                                    </Fragment>
                                </div>
                            )
                        }
                        {
                            commentPermissions?.annotationAllowed && (
                                <div className="form-group">
                                    <Checkbox className="fw-bold" inputId={`${id}-annotate-stage-comment-field`}
                                              label={
                                                  <Fragment>
                                                      <Icon className="position-relative pos-top-n1"
                                                            iconSpritePath={svgIconsPath} width={12}
                                                            height={12} name="arrow-up-to-line"/>
                                                      <span
                                                          className="ms-2">{localizer.msg('comments.comment-form.idea-comment-annotate')}</span>
                                                  </Fragment>
                                              }
                                              {...register('annotated')}/>
                                </div>
                            )
                        }
                        <div className="form-group mb-0 d-flex justify-content-end">
                            {
                                parentId !== ideaId && onCancel &&
                                <Button color="cancel" disabled={isSubmitting || fileUploading} className="me-2"
                                        onClick={onCancel}>
                                    {localizer.msg('common.cancel')}
                                </Button>
                            }
                            <ActionButton form={`${STAGE_COMMENT_SUBMIT_FORM_ID}-${parentId}`} type="submit"
                                          loading={isSubmitting || fileUploading}>
                                {localizer.msg('comments.comment-form.submit-comment')}
                            </ActionButton>
                        </div>
                    </form>
                </HookFormProvider>
            </div>
        </div>
    );
};
