import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {AiImageResponse, isHtmlInject, isJavascriptInject, useApiErrorResponseHandler} from '@ideascale/commons';
import {ActionButton, Button} from '@ideascale/ui';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useEditModeFileOperationService} from 'hooks/useEditModeFileOperationService';
import {useRouteUtils} from 'hooks/useRouteUtils';
import {useTabPermission} from 'hooks/useTabPermission';
import {useEditModeFormErrorHandler} from 'hooks/useEditModeFormErrorHandler';
import {FileLibrary} from './advanced-settings/FileLibrary';
import {FileUpload} from './advanced-settings/FileUpload';
import {AiFileUpload} from './advanced-settings/AiFileUpload';
import {TabItem} from '../share/TabItem';
import {TabPanel} from '../share/TabPanel';
import {LicenseUpgradeInfo} from 'components/edit-mode/LicenseUpgradeInfo';
import {ErrorMessage} from './advanced-settings/ErrorMessage';
import {UpdateCampaignFeaturedImage} from 'commands/edit-mode/UpdateCampaignFeaturedImage';
import {DEFAULT_STALE_TIME, QUERY_KEYS} from 'constants/AppConstants';
import {BannerField, BannerFieldBuilder} from 'models/edit-mode/BannerField';
import {ImageType} from 'models/enums/ImageType';
import {CampaignEditableFieldType} from 'models/edit-mode/CampaignEditableFieldType';
import {ImageFile} from 'models/edit-mode/ImageFile';
import {UploadFileRequest} from 'models/edit-mode/UploadFileRequest';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';
import {UploadedImage} from 'models/types/UploadedImage';
import {ImageUploadSource} from 'models/enums/ImageUploadSource';

const CAMPAIGN_FEATURED_IMAGE_RATIO = {w: 690, h: 518};
const FEATURED_IMAGE_AI_SIZE = '512x512';

export type CampaignFeaturedImageEditProps = {
    maxFileSizeLimit: number;
    toggle: () => void;
    fetchAiAssistedImage: (prompt: string, size?: string, numberOfImages?: number) => Promise<AiImageResponse>;
}

export const CampaignFeaturedImageEdit = (props: CampaignFeaturedImageEditProps) => {
    const {
        maxFileSizeLimit,
        toggle,
        fetchAiAssistedImage
    } = props;
    const localizer = useLocalizer();
    const {homeConfig, campaignHomeEditor, commandExecutor, validationErrors} = useEditModeContext();
    const {communityConfig: {aiImageAssistEnabled}, authentication: {actor}} = useAppContext();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const {
        fetchCampaignFeaturedGalleryImages,
        fetchCampaignLibraryImages,
        campaignUploadLibraryFiles
    } = useEditModeFileOperationService();
    const [selectedGalleryImage, setSelectedGalleryImage] = useState<ImageFile | null>(null);
    const [selectedLibraryImage, setSelectedLibraryImage] = useState<ImageFile | null>(null);
    const [featuredImage, setFeaturedImage] = useState<BannerField>(BannerField.EMPTY);
    const [checkedBannerGradient, setCheckedBannerGradient] = useState<boolean>(false);
    const [checkedBlackPageTitle, setCheckedBlackPageTitle] = useState<boolean>(false);
    const [uploadedImageType, setUploadedImageType] = useState<ImageUploadSource | undefined>(undefined);
    const [defaultActiveTab, setDefaultActiveTab] = useState<number>(1);
    const {campaignRouteMatch} = useRouteUtils();
    const {tabPermission} = useTabPermission(featuredImage);
    const [fileValidationErrors, setFileValidationErrors] = useState<Record<string, string>>({});
    const [fileUploading, setFileUploadingStatus] = useState(false);
    const {fieldError} = useEditModeFormErrorHandler({
        localizer,
        validationErrors: validationErrors.findError(CampaignOperationType.CHANGE_CAMPAIGN_FEATURED_IMAGE)
    });

    const {data: featuredImages = []} = useQuery([QUERY_KEYS.CAMPAIGN_FEATURED_GALLERY_IMAGES], () => fetchCampaignFeaturedGalleryImages(), {
        staleTime: DEFAULT_STALE_TIME,
        cacheTime: 0,
        onError: (error: any) => {
            handleErrorResponse(error);
        }
    });

    const {data: libraryImages = []} = useQuery([QUERY_KEYS.CAMPAIGN_LIBRARY_IMAGES], () => fetchCampaignLibraryImages(), {
        staleTime: DEFAULT_STALE_TIME,
        cacheTime: 0,
        onError: (error: any) => {
            handleErrorResponse(error);
        }
    });

    const updateSelectedImage = useCallback((image: ImageFile, imageType: ImageType) => {
        if (imageType === ImageType.STANDARD) {
            setSelectedGalleryImage(image);
            setSelectedLibraryImage(null);
        } else {
            setSelectedGalleryImage(null);
            setSelectedLibraryImage(image);
        }

        const newImage = new BannerFieldBuilder()
            .name(CampaignEditableFieldType.CAMPAIGN_FEATURE_IMAGE)
            .fileId(image.fileId)
            .url(image.url)
            .imageType(imageType)
            .gradientEnabled(checkedBannerGradient)
            .blackPageTitleEnabled(checkedBlackPageTitle)
            .build();
        setFeaturedImage(prev => ({
            ...newImage,
            standardFilePermission: prev.standardFilePermission,
            fileLibraryPermission: prev.fileLibraryPermission,
            fileUploadPermission: prev.fileUploadPermission
        }));
        setUploadedImageType(undefined);
    }, [checkedBannerGradient, checkedBlackPageTitle]);

    const onGalleryImageSelected = useCallback((image: ImageFile) => {
        updateSelectedImage(image, ImageType.STANDARD);
    }, [updateSelectedImage]);

    const onLibraryImageSelected = useCallback((image: ImageFile) => {
        updateSelectedImage(image, ImageType.CUSTOM);
    }, [updateSelectedImage]);

    const updateCampaignBanner = useCallback(() => {
        const campaignId = (campaignRouteMatch?.params as any)?.campaignId;
        if (featuredImage !== homeConfig.getBannerField(CampaignEditableFieldType.CAMPAIGN_FEATURE_IMAGE) && campaignId) {
            const command = new UpdateCampaignFeaturedImage(campaignHomeEditor, featuredImage, +campaignId);
            commandExecutor.execute(command);
            validationErrors.clearError(command.getType());
        }
    }, [campaignRouteMatch?.params, featuredImage, homeConfig, campaignHomeEditor, commandExecutor, validationErrors]);

    const onImageUploaded = useCallback((image: UploadedImage, type: ImageUploadSource) => {
        const newImage = new BannerFieldBuilder()
            .name(CampaignEditableFieldType.CAMPAIGN_FEATURE_IMAGE)
            .fileId(image.fileId)
            .filename(image.filename)
            .url(image.url)
            .gradientEnabled(checkedBannerGradient)
            .blackPageTitleEnabled(checkedBlackPageTitle)
            .imageType(ImageType.UPLOADED)
            .altText(image.altText || '')
            .build();

        setFeaturedImage(prev => ({
            ...newImage,
            standardFilePermission: prev.standardFilePermission,
            fileLibraryPermission: prev.fileLibraryPermission,
            fileUploadPermission: prev.fileUploadPermission
        }));
        setUploadedImageType(type);
        setSelectedGalleryImage(null);
        setSelectedLibraryImage(null);
    }, [checkedBannerGradient, checkedBlackPageTitle]);

    const onClick = useCallback(async () => {
        setFileValidationErrors({});
        try {
            if (featuredImage.fileId <= 0 && featuredImage.filename?.length) {
                const uploadFileRequest = new UploadFileRequest([]);
                uploadFileRequest.files.push({filename: featuredImage.filename, altText: featuredImage.altText ?? ''});

                const response = await campaignUploadLibraryFiles(uploadFileRequest);
                if (response) {
                    const uploadedFile = response.find(uploadedResponse => uploadedResponse.filename === featuredImage.filename)?.file
                        || {id: 0, url: ''};
                    featuredImage.fileId = uploadedFile.id;
                    featuredImage.url = uploadedFile.url;
                    featuredImage.imageType = ImageType.CUSTOM;
                }
                updateCampaignBanner();
            }
            toggle();
        } catch (e: any) {
            if (e?.data?.validationErrors) {
                setFileValidationErrors(e?.data?.validationErrors);
            } else {
                handleErrorResponse(e);
            }
        }
        updateCampaignBanner();
    }, [featuredImage, handleErrorResponse, toggle, updateCampaignBanner, campaignUploadLibraryFiles]);

    const campaignBannerImageTabItems = useMemo(() => {
        const tabs = [
            {
                value: 1,
                icon: 'image-layered',
                text: localizer.msg('edit-mode.image-setting.tabs.featured-image-gallery'),
                disabled: tabPermission.standardFileDisabled,
                content: (
                    <Fragment>
                        {
                            !tabPermission.standardFileDisabled
                                ? (
                                    <FileLibrary
                                        localizer={localizer}
                                        images={featuredImages}
                                        previewMode="xs-small"
                                        selectedImage={selectedGalleryImage}
                                        onSelectedImage={onGalleryImageSelected}
                                        ariaLabel={localizer.msg('edit-mode.image-setting.tabs.featured-image-gallery')}
                                    />

                                )
                                : null
                        }
                    </Fragment>
                ),
                additionalTabInfo: (
                    <LicenseUpgradeInfo
                        className="edit-mode-element"
                        triggerButtonId="campaign-featured-license-popover-trigger-1"
                        showIconOnly={true}
                        localizer={localizer}
                    />
                )
            },
            {
                value: 2,
                icon: 'plus-solid-box-rounded-layered',
                text: localizer.msg('edit-mode.image-setting.tabs.file-library'),
                disabled: tabPermission.libraryDisabled,
                content: (
                    <Fragment>
                        {
                            !tabPermission.libraryDisabled
                                ? (
                                    <FileLibrary
                                        localizer={localizer}
                                        images={libraryImages}
                                        previewMode="xs-small"
                                        selectedImage={selectedLibraryImage}
                                        onSelectedImage={onLibraryImageSelected}
                                        ariaLabel={localizer.msg('edit-mode.image-setting.tabs.file-library')}
                                    />
                                )
                                : null
                        }
                    </Fragment>
                ),
                additionalTabInfo: (
                    <LicenseUpgradeInfo
                        className="edit-mode-element"
                        triggerButtonId="campaign-featured-license-popover-trigger-2"
                        showIconOnly={true}
                        localizer={localizer}
                    />
                )
            },
            {
                value: 3,
                icon: 'arrow-up-from-line',
                text: localizer.msg('edit-mode.image-setting.tabs.upload'),
                disabled: tabPermission.uploadDisabled,
                content: (
                    <Fragment>
                        {
                            !tabPermission.uploadDisabled
                                ? (
                                    <FileUpload
                                        previewMode="medium"
                                        localizer={localizer}
                                        maxFileSizeLimit={maxFileSizeLimit}
                                        cropWidth={CAMPAIGN_FEATURED_IMAGE_RATIO.w}
                                        cropHeight={CAMPAIGN_FEATURED_IMAGE_RATIO.h}
                                        uploadedType={uploadedImageType}
                                        errorReason={fileValidationErrors[`files[0].altText`]}
                                        onUploadedSuccessfulImage={onImageUploaded}
                                        updateFileUploadingStatus={setFileUploadingStatus}
                                    />
                                )
                                : null
                        }
                    </Fragment>
                ),
                additionalTabInfo: (
                    <LicenseUpgradeInfo
                        className="edit-mode-element"
                        triggerButtonId="campaign-featured-license-popover-trigger-3"
                        showIconOnly={true}
                        localizer={localizer}
                    />
                )
            },

        ] as TabItem[];

        if (aiImageAssistEnabled) {
            tabs.push({
                value: 4,
                icon: 'sparkles',
                text: localizer.msg('ai-assistance.image-upload-label'),
                disabled: false,
                content: (
                    <AiFileUpload localizer={localizer}
                                  avatar={actor.avatar}
                                  imageSize={FEATURED_IMAGE_AI_SIZE}
                                  initialPrompt={campaignHomeEditor.name}
                                  maxFileSizeLimit={maxFileSizeLimit}
                                  cropWidth={CAMPAIGN_FEATURED_IMAGE_RATIO.w}
                                  cropHeight={CAMPAIGN_FEATURED_IMAGE_RATIO.h}
                                  uploadedType={uploadedImageType}
                                  altTextErrorReason={fileValidationErrors[`files[0].altText`]}
                                  onUploaded={onImageUploaded}
                                  fetchAiAssistedImage={fetchAiAssistedImage}/>
                ),
                additionalTabInfo: (
                    <LicenseUpgradeInfo
                        className="edit-mode-element"
                        triggerButtonId="campaign-logo-license-popover-trigger-1"
                        showIconOnly={true}
                        localizer={localizer}
                    />
                )
            });
        }

        return tabs;
    }, [actor.avatar, aiImageAssistEnabled, campaignHomeEditor.name, featuredImages, fetchAiAssistedImage, fileValidationErrors, libraryImages, localizer, maxFileSizeLimit, onGalleryImageSelected, onImageUploaded, onLibraryImageSelected, uploadedImageType, selectedGalleryImage, selectedLibraryImage, tabPermission.libraryDisabled, tabPermission.standardFileDisabled, tabPermission.uploadDisabled]);

    useEffect(() => {
        const defaultFeaturedImage = homeConfig.getBannerField(CampaignEditableFieldType.CAMPAIGN_FEATURE_IMAGE);
        defaultFeaturedImage.url = defaultFeaturedImage.url ?? '';

        if (defaultFeaturedImage?.imageType === ImageType.STANDARD) {
            setSelectedGalleryImage(defaultFeaturedImage);
            setDefaultActiveTab(1);
        } else if (defaultFeaturedImage?.imageType === ImageType.CUSTOM) {
            setSelectedLibraryImage(defaultFeaturedImage);
            setDefaultActiveTab(2);
        }

        setCheckedBannerGradient(defaultFeaturedImage?.gradientEnabled ?? false);
        setCheckedBlackPageTitle(defaultFeaturedImage?.blackPageTitleEnabled ?? false);
        setFeaturedImage(defaultFeaturedImage);
    }, [homeConfig]);

    const isFormDirty = () => {
        return featuredImage !== homeConfig.getBannerField(CampaignEditableFieldType.CAMPAIGN_FEATURE_IMAGE);
    };

    const hasAllTabsDisabled = useMemo(() => {
        return tabPermission.standardFileDisabled && tabPermission.libraryDisabled && tabPermission.uploadDisabled;
    }, [tabPermission.libraryDisabled, tabPermission.standardFileDisabled, tabPermission.uploadDisabled]);

    const hasErrorInAltText = () => {
        const hasInjectedAltText = featuredImage.altText ? (isHtmlInject(featuredImage.altText) || isJavascriptInject(featuredImage.altText)) : false;
        const hasFileValidationErrors = Object.keys(fileValidationErrors).length > 0;
        return hasInjectedAltText || hasFileValidationErrors;
    };

    return (
        <div>
            <div className="d-flex align-items-center">
                <h5 className={`my-3 fw-semibold ${validationErrors.findError(CampaignOperationType.CHANGE_CAMPAIGN_FEATURED_IMAGE) ? 'has-error' : ''}`}>{localizer.msg('edit-mode.campaign.featured-image-title')}</h5>
                {
                    hasAllTabsDisabled && (
                        <LicenseUpgradeInfo
                            triggerButtonId="campaign-featured-title-license-popover"
                            className="ms-2"
                            localizer={localizer}
                        />
                    )
                }
            </div>
            <ErrorMessage message={fieldError('fileId')?.message}/>
            {
                !hasAllTabsDisabled && (
                    <TabPanel defaultActiveTab={defaultActiveTab} tabItems={campaignBannerImageTabItems}/>
                )
            }
            <div className="action-bar mt-4 mb-3 p-0 text-end">
                <Button className="btn btn-cancel" color="cancel" aria-label="Close modal" onClick={toggle}
                        data-test-element-id="cancel">
                    {localizer.msg('common.actions.cancel')}
                </Button>
                <ActionButton
                    onClick={onClick}
                    className="btn btn-primary btn-lg"
                    type="button"
                    disabled={!isFormDirty() || hasErrorInAltText()}
                    loading={fileUploading}
                    data-test-element-id="update"
                >
                    {localizer.msg('common.update')}
                </ActionButton>
            </div>
        </div>
    );
};