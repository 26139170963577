import React, {useCallback} from 'react';
import {GroupBase, OptionsOrGroups} from 'react-select';
import {Icon, IdeascaleSelectWithPagination, OptionType} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useLocalizer} from 'hooks/useLocalizer';
import {SearchInputGroup} from 'shared/SearchInputGroup';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';

type TagSearchFieldProps = {
    defaultValue?: OptionType[];
    fetchTags(pageParameters: PageParameters): Promise<PagedResponseContent<string>>;
    setTags: (tags: OptionType[]) => void;
}

export const TagSearchField = (props: TagSearchFieldProps) => {
    const {fetchTags, setTags, defaultValue = []} = props;
    const localizer = useLocalizer();

    const loadTags = useCallback(async (term: string = '', prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>, additionalParams: any) => {
        try {
            const {page} = additionalParams;
            const tagData = await fetchTags({page, term, limit: 10});
            const tags: OptionType[] = tagData.content.map((tagItem) => ({
                value: tagItem,
                label: tagItem,
                labelAsJsx: <span className="label customize-label">{tagItem}</span>
            }));
            return {
                options: tags,
                hasMore: tagData.hasMore,
                additional: {
                    page: page + 1,
                }
            };
        } catch (e: any) {
            return {
                options: []
            };
        }
    }, [fetchTags]);

    return (
        <div data-test-element-id="tags-search" className="form-group">
            <label className="fw-bold"
                   htmlFor="search-tags">{localizer.msg('search.input-label.tags')}</label>
            <SearchInputGroup
                groupText={<Icon iconSpritePath={svgIconsPath} name="tag" fill="#777" width={18} height={18}/>}>
                <IdeascaleSelectWithPagination
                    ariaLabel={localizer.msg('sidebar.tags.select-tags')}
                    isMulti={true}
                    loadOptions={loadTags}
                    inputId="search-tags"
                    placeholder="Select Tags"
                    value={defaultValue}
                    onChange={(option: any) => setTags(option)}
                    additional={{
                        page: 0
                    }}/>
            </SearchInputGroup>
        </div>
    );
};
