type FileInfo = {
    filename: string;
    altText: string
}

export class UploadFileRequest {
    static EMPTY = new UploadFileRequest([]);

    constructor(
        public files: FileInfo[],
        public publicFolder?: boolean
    ) {
    }
}