import React from 'react';
import {Link} from 'react-router-dom';
import {Trans} from 'react-i18next';
import {MemberLink} from '@ideascale/commons';
import {NotificationType} from 'models/enums/NotificationType';

type StageActionNotificationProps = {
    type: NotificationType.IDEA_REFINE_ANSWER_MODIFIED | NotificationType.IDEA_REFINE_ANSWER_SUBMITTED |
        NotificationType.IDEA_FUND_TARGET_MODIFIED | NotificationType.IDEA_FUND_TARGET_SUBMITTED |
        NotificationType.ASSESSMENT_REVIEW_MODIFIED | NotificationType.ASSESSMENT_REVIEW_SUBMITTED |
        NotificationType.REVIEWSCALE_REVIEW_MODIFIED | NotificationType.REVIEWSCALE_REVIEW_SUBMITTED |
        NotificationType.ESTIMATE_ROI_SUBMITTED;
    memberId: number;
    identityHidden: boolean;
    memberLinkText: string;
    ideaLink: string;
    ideaLinkText: string;
}

export const StageActionNotification = (props: StageActionNotificationProps) => {
    const {type, memberId, identityHidden, memberLinkText, ideaLink, ideaLinkText} = props;

    const renderText = () => {
        switch (type) {
            case NotificationType.IDEA_REFINE_ANSWER_MODIFIED:
                return 'notification.idea.refine.modified';
            case NotificationType.IDEA_REFINE_ANSWER_SUBMITTED:
                return 'notification.idea.refine.submitted';
            case NotificationType.IDEA_FUND_TARGET_MODIFIED:
                return 'notification.idea.fund.modified';
            case NotificationType.IDEA_FUND_TARGET_SUBMITTED:
                return 'notification.idea.fund.submitted';
            case NotificationType.ASSESSMENT_REVIEW_MODIFIED:
                return 'notification.idea.assessment.modified';
            case NotificationType.ASSESSMENT_REVIEW_SUBMITTED:
                return 'notification.idea.assessment.submitted';
            case NotificationType.REVIEWSCALE_REVIEW_MODIFIED:
                return 'notification.idea.reviewscale.modified';
            case NotificationType.REVIEWSCALE_REVIEW_SUBMITTED:
                return 'notification.idea.reviewscale.submitted';
            case NotificationType.ESTIMATE_ROI_SUBMITTED:
                return 'notification.idea.estimate.submitted';
        }
    };

    return (
        <Trans i18nKey={renderText()} values={{
            member: memberLinkText,
            idea: ideaLinkText
        }}>
            <MemberLink id={memberId} identityHidden={identityHidden}/>
            <Link to={ideaLink}/>
        </Trans>
    );
};