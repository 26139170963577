import {Operation} from './Operation';
import {CommunityOperationType} from './CommunityOperationType';

export class ChangeIdeaSortModeOperation implements Operation {
    constructor(public type: CommunityOperationType, public sortMode: string) {
    }

    getType(): CommunityOperationType {
        return this.type;
    }
}