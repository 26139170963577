import React, {Fragment, useCallback, useMemo} from 'react';
import isEmpty from 'lodash/isEmpty';
import {Icon} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {
    AiAssistanceFieldNames,
    AiContentResponse,
    AiImageResponse,
    ClassificationLabel,
    ClassificationSummary,
    useApplicableClassifications,
    useToggle
} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {EditActionWrapper} from './shared/EditActionWrapper';
import {CampaignLogo} from './edit-mode/CampaignLogo';
import {CampaignBannerEdit} from 'components/edit-mode/CampaignBannerEdit';
import {CampaignNameEditForm} from 'components/edit-mode/CampaignNameEditForm';
import {CampaignEditableFieldType} from 'models/edit-mode/CampaignEditableFieldType';
import {HomeConfigHolder} from 'models/edit-mode/HomeConfigHolder';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';
import {IDEASCALE_LOGO} from 'constants/AppConstants';
import './PageHeader.scss';

type PageHeaderType = {
    logo: string;
    bannerImage: string;
    bannerAltText: string;
    logoAltText: string;
    heading: string;
    headingMeta: string;
    textColor?: 'light' | 'dark';
    logoBgTransparent?: boolean;
    headingTag?: React.ElementType;
    pageTitleInBlackEnabled?: boolean;
    bannerGradientEnabled?: boolean;
    classificationSummary?: ClassificationSummary,
    hideLogo: boolean;
    fetchAiAssistedCampaignLogo?: (prompt: string, size?: string, numberOfImages?: number) => Promise<AiImageResponse>;
    fetchAiAssistedCampaignName?: (prompt: string, fieldName: AiAssistanceFieldNames, chatId?: number) => Promise<AiContentResponse>;
}

export const PageHeader = (props: PageHeaderType) => {
    const {
        logo,
        bannerImage,
        bannerAltText,
        logoAltText,
        heading,
        headingMeta,
        logoBgTransparent = true,
        headingTag = 'h1',
        textColor = 'light',
        pageTitleInBlackEnabled,
        bannerGradientEnabled,
        classificationSummary,
        hideLogo,
        fetchAiAssistedCampaignName,
        fetchAiAssistedCampaignLogo
    } = props;

    const HeadingTag = headingTag;
    const {communityConfig, editModeEnabled} = useAppContext();
    const {homeConfig, campaignHomeEditor, validationErrors} = useEditModeContext();
    const {getClassificationAttribute} = useApplicableClassifications('', []);
    const [mediaModalOpen, toggleMediaModalOpen] = useToggle(false);
    const [logoModalOpen, toggleLogoModalOpen] = useToggle(false);
    const [headingModalOpen, toggleHeadingModalOpen] = useToggle(false);
    const localizer = useLocalizer();

    const fetchAiAssistedLogo = useCallback(async (prompt: string, size?: string, numberOfImages?: number) => {
        if (fetchAiAssistedCampaignLogo) {
            return fetchAiAssistedCampaignLogo(prompt, size, numberOfImages);
        }
        return AiImageResponse.EMPTY;
    }, [fetchAiAssistedCampaignLogo]);

    const fetchAiAssistedName = useCallback(async (prompt: string, fieldName: AiAssistanceFieldNames, chatId?: number) => {
        if (fetchAiAssistedCampaignName) {
            return fetchAiAssistedCampaignName(prompt, fieldName, chatId);
        }
        return AiContentResponse.EMPTY;
    }, [fetchAiAssistedCampaignName]);

    const bannerData = useMemo(() => {
        if (editModeEnabled && homeConfig.isOperationAllowed(CampaignEditableFieldType.CAMPAIGN_BANNER_IMAGE) && homeConfig !== HomeConfigHolder.EMPTY) {
            const bannerField = homeConfig.getBannerField(CampaignEditableFieldType.CAMPAIGN_BANNER_IMAGE);
            return {
                url: !isEmpty(bannerField.url) ? bannerField.url : bannerImage,
                altText: !isEmpty(bannerField.altText) ? bannerField.altText : bannerAltText,
                gradientEnabled: bannerField.gradientEnabled ?? bannerGradientEnabled,
                pageTitleInBlack: bannerField.blackPageTitleEnabled ?? pageTitleInBlackEnabled
            };
        } else {
            return {
                url: bannerImage,
                altText: bannerAltText,
                gradientEnabled: bannerGradientEnabled,
                pageTitleInBlack: pageTitleInBlackEnabled
            };
        }
    }, [bannerAltText, bannerGradientEnabled, bannerImage, editModeEnabled, homeConfig, pageTitleInBlackEnabled]);

    const campaignLogoData = useMemo(() => {
        if (editModeEnabled && homeConfig !== HomeConfigHolder.EMPTY && homeConfig.isOperationAllowed(CampaignEditableFieldType.CAMPAIGN_LOGO)) {
            const logoField = homeConfig.getLogoField(CampaignEditableFieldType.CAMPAIGN_LOGO);
            return {
                url: !isEmpty(logoField.url) ? logoField.url : (!isEmpty(communityConfig.logo) ? communityConfig.logo : IDEASCALE_LOGO),
                altText: logoField.altText ?? logoAltText,
                logoBgTransparent: !logoField.logoFrameEnabled,
                hideLogo: !logoField.logoOnBannerEnabled
            };
        } else {
            return {
                url: logo,
                altText: logoAltText,
                logoBgTransparent: logoBgTransparent,
                hideLogo: hideLogo
            };
        }
    }, [communityConfig.logo, editModeEnabled, hideLogo, homeConfig, logo, logoAltText, logoBgTransparent]);

    return (
        <header
            className={`page-header ${bannerData.gradientEnabled ? 'has-banner-gradient' : ''} ${textColor === 'dark' ? 'text-in-dark' : 'text-in-light'}`}>
            <div className="container h-100 bg-light rounded-bottom">
                {
                    <EditActionWrapper
                        editAllowed={homeConfig.isOperationAllowed(CampaignEditableFieldType.CAMPAIGN_BANNER_IMAGE)}
                        className="banner-image-as-bg position-absolute banner-overlay"
                        modalTitle={localizer.msg('edit-mode.campaign.banner-image.edit-banner-image')}
                        hasError={validationErrors.hasError(CampaignOperationType.CHANGE_CAMPAIGN_BANNER)}
                        configurationChildren={
                            <CampaignBannerEdit maxFileSizeLimit={communityConfig.maxFileSizeLimit}
                                                toggle={toggleMediaModalOpen}/>
                        }
                        modalOpen={mediaModalOpen}
                        modalToggle={toggleMediaModalOpen}
                        editBtnTestElementId="btn-edit-campaign-banner"
                        editBtnTitle={localizer.msg('edit-mode.campaign.banner-image.edit-banner-image')}>
                        <img src={bannerData.url}
                             className={`w-100 rounded-bottom ${editModeEnabled && !homeConfig.isOperationAllowed(CampaignEditableFieldType.CAMPAIGN_BANNER_IMAGE) ? 'opacity-50' : ''}`}
                             height={150}
                             alt={bannerData.altText}/>
                        {
                            bannerData.gradientEnabled &&
                            <div className="position-absolute top-0 left-0 opacity-25 bg-black w-100 h-100"/>
                        }
                    </EditActionWrapper>
                }
                <div
                    className={`d-flex align-items-end pb-3 h-100 position-relative z-index-1 ${editModeEnabled ? 'me-4' : ''}`}>
                    <div className="d-flex align-items-center w-100">
                        {
                            (editModeEnabled || !hideLogo) &&
                            <EditActionWrapper
                                editAllowed={homeConfig.isOperationAllowed(CampaignEditableFieldType.CAMPAIGN_LOGO)}
                                className={`${(campaignLogoData.logoBgTransparent || campaignLogoData.hideLogo) ? 'bg-transparent' : ''} header-logo d-inline-flex align-items-center justify-content-center me-3 min-width-auto position-relative`}
                                hasError={validationErrors.hasError(CampaignOperationType.CHANGE_CAMPAIGN_LOGO)}
                                configurationChildren={<CampaignLogo localizer={localizer}
                                                                     maxFileSizeLimit={communityConfig.maxFileSizeLimit}
                                                                     toggle={toggleLogoModalOpen}
                                                                     fetchAiAssistedImage={fetchAiAssistedLogo}/>}
                                modalTitle={localizer.msg('edit-mode.campaign.edit-logo')}
                                modalOpen={logoModalOpen}
                                modalToggle={toggleLogoModalOpen}
                                editBtnTestElementId="btn-edit-campaign-logo"
                                editBtnTitle={localizer.msg('edit-mode.campaign.edit-logo')}>
                                {
                                    campaignLogoData.hideLogo
                                        ? editModeEnabled && homeConfig.isOperationAllowed(CampaignEditableFieldType.CAMPAIGN_LOGO)
                                            ? <Fragment>
                                                <div className="logo-overlay"></div>
                                                <Icon name="eye-open-slash" className="logo-hidden-icon"
                                                      iconSpritePath={svgIconsPath} fill={'#fff'} width={20}
                                                      height={20}/>
                                                <img src={campaignLogoData.url}
                                                     className="rounded"
                                                     width={74}
                                                     alt={campaignLogoData.altText}/>
                                            </Fragment>
                                            : null
                                        : <img src={campaignLogoData.url}
                                               className={`rounded ${editModeEnabled && !homeConfig.isOperationAllowed(CampaignEditableFieldType.CAMPAIGN_LOGO) ? 'opacity-50' : ''}`}
                                               width={74}
                                               alt={campaignLogoData.altText}/>
                                }
                            </EditActionWrapper>

                        }
                        <div
                            className={`header-summary min-width ${bannerData.pageTitleInBlack ? 'page-title-black' : ''}`}>
                            {
                                communityConfig.classificationEnabled && headingMeta === ''
                                    ? ''
                                    : <ClassificationLabel
                                        classification={getClassificationAttribute(classificationSummary)}
                                        extraClasses="me-2 align-bottom d-inline"/>
                            }
                            <EditActionWrapper
                                editAllowed={homeConfig.isOperationAllowed(CampaignEditableFieldType.CAMPAIGN_NAME)}
                                className="d-block"
                                hasError={validationErrors.hasError(CampaignOperationType.CHANGE_CAMPAIGN_NAME)}
                                configurationChildren={
                                    <CampaignNameEditForm toggleModal={toggleHeadingModalOpen}
                                                          localizer={localizer}
                                                          fetchAiAssistedName={fetchAiAssistedName}/>
                                }
                                modalOpen={headingModalOpen}
                                modalTitle={localizer.msg('edit-mode.campaign.edit-name')}
                                modalToggle={toggleHeadingModalOpen}
                                editBtnTitle={localizer.msg('edit-mode.campaign.edit-name')}
                                editBtnTestElementId="btn-edit-campaign-name">
                                <HeadingTag title={editModeEnabled ? campaignHomeEditor.name : heading}
                                            className="mb-0 page-heading text-truncate">
                                    {
                                        editModeEnabled && homeConfig.isOperationAllowed(CampaignEditableFieldType.CAMPAIGN_NAME)
                                            ? campaignHomeEditor.name
                                            : heading
                                    }
                                </HeadingTag>
                            </EditActionWrapper>
                            <strong className="header-meta text-break">{headingMeta}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};