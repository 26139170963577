import React, {Fragment} from 'react';
import range from 'lodash/range';
import {Skeleton} from '@ideascale/ui';

export const LeaderboardSkeleton = () => {
    return <Fragment>
        {
            range(0, 5).map(item =>
                <li className="border-bottom-1 py-2 px-4" key={item}>
                    <div className="d-flex align-items-center">
                        <Skeleton cssClass="header-logo me-3" width="10px" height="14px"/>
                        <Skeleton cssClass="header-logo me-3" circle width="40px" height="40px"/>
                        <div className="flex-grow-1 me-3">
                            <div className="my-auto">
                                <Skeleton width="100%"/>
                            </div>
                        </div>
                        <div>
                            <Skeleton cssClass="header-logo" width="30px" height="30px"/>
                        </div>
                    </div>
                </li>
            )
        }
    </Fragment>;
};