import React, {Fragment, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button} from '@ideascale/ui';
import {CLASSIFICATION_NAME, ClassificationLabel, ClassificationUtil, Localizer,} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useToggleIdeaSubmissionModal} from 'hooks/useToggleIdeaSubmissionModal';
import {appLinks} from 'services/AppLinks';
import {ActionItemHeader} from 'components/topbar/action-items/ActionItemHeader';
import {GroupedActionItem} from 'models/topbar-action-item/GroupedActionItem';
import {DraftActionItem} from 'models/topbar-action-item/DraftActionItem';
import {MyIdeaListMode} from 'models/enums/MyIdeaListMode';
import {IdeaListMode} from 'models/enums/IdeaListMode';

type DraftActionItemsProps = {
    groupedActionItem: GroupedActionItem;
    localizer: Localizer;
}

export const DraftActionItems = (props: DraftActionItemsProps) => {
    const {groupedActionItem, localizer} = props;
    const {authentication: {actor}} = useAppContext();
    const [, toggleUseFormModal] = useToggleIdeaSubmissionModal();
    const navigate = useNavigate();
    const {communityConfig} = useAppContext();

    const onSeeAllClicked = useCallback(() => {
        navigate(appLinks.myIdeas(String(actor.id), MyIdeaListMode.MY_DRAFTS, 'all', IdeaListMode.RECENT));
    }, [actor.id, navigate]);

    return (
        <Fragment>
            <div className="action-item-container">
                <ActionItemHeader
                    id="draft-actions-heading"
                    groupTitle={groupedActionItem.groupTitle}
                    seeAllLink={groupedActionItem.seeAllLink}
                    onSeeAllClick={onSeeAllClicked}/>
                <div className="divider"/>
                <ul className="action-item-list-container list-unstyled mx-3 mt-3"
                    aria-labelledby="draft-actions-heading">
                    {
                        (groupedActionItem.actionItems as DraftActionItem[]).map((actionItem: DraftActionItem) => (
                            <li key={actionItem.draftId}>
                                {communityConfig.classificationEnabled && actionItem.attributes &&
                                    <div>
                                        <ClassificationLabel
                                            classification={ClassificationUtil.getAttributeByName(CLASSIFICATION_NAME.EFFECTIVE_CLASSIFICATION, actionItem.attributes)}
                                            extraClasses="me-2 align-bottom"/>
                                    </div>
                                }
                                <Button color="link" className="p-0 font-size-sm text-decoration-none w-100 text-truncate text-start"
                                              onClick={() => toggleUseFormModal(actionItem.draftId, actionItem.submitterId)}
                                              aria-label={localizer.msg('profile.my-ideas.filters.draft-label', {title: actionItem.name})}>
                                    {actionItem.name}
                                </Button>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </Fragment>
    );
};