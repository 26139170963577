import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {Icon, ParagraphSkeleton, Scrollbar, ScrollbarInstance} from '@ideascale/ui';
import {
    ClassificationLabel,
    InfiniteScrollLoadMoreButton,
    PrivateMessageRequestParams,
    useApiErrorResponseHandler,
    useApplicableClassifications,
    useIntersectionObserver,
    useToggle
} from '@ideascale/commons';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {appLinks} from 'services/AppLinks';
import {useLocalizer} from 'hooks/useLocalizer';
import {useDigestShare} from 'hooks/useDigestShare';
import {useSocialShareServices} from 'hooks/useSocialShareServices';
import {ShareModal} from './share/ShareModal';
import {TimeUtil} from 'utils/TimeUtil';
import {useAppContext} from 'contexts/AppContext';
import {useDigestService} from 'hooks/useDigestService';
import {CampaignPerformances} from './notification/CampaignPerformances';
import {PageParameters} from 'models/types/PageParameters';
import {NotificationType} from 'models/enums/NotificationType';
import {SharingMedia} from 'models/SharingMedia';
import {DigestEmailRequestParams} from 'models/DigestEmailRequestParams';
import {CampaignInfo, CampaignPerformancesData} from 'models/CampaignPerformancesData';
import {DigestShareData} from 'models/DigestShareData';
import {DigestResponseContent} from 'models/DigestResponseContent';
import styles from './DigestContent.module.scss';


type DigestContentProps = {
    fetchMonthlyModeratorDigest: (pageParameters: PageParameters, year: string, month: string) => Promise<DigestResponseContent>;
    fetchWeeklyModeratorDigest: (pageParameters: PageParameters, year: string, weekOfYear: string) => Promise<DigestResponseContent>;
    fetchCampaignPerformanceMonthlyModeratorDigest: (campaignId: number, memberId: number, year: string, month: string) => Promise<DigestShareData>;
    fetchCampaignPerformanceWeeklyModeratorDigest: (campaignId: number, memberId: number, year: string, weekOfYear: string) => Promise<DigestShareData>;
    digestNotificationData: { year: string, notificationType: NotificationType, month?: string, week?: string, campaignId?: number, memberId?: number };
}

export const DigestContent = (props: DigestContentProps) => {
    const {
        fetchMonthlyModeratorDigest,
        fetchWeeklyModeratorDigest,
        fetchCampaignPerformanceMonthlyModeratorDigest,
        fetchCampaignPerformanceWeeklyModeratorDigest,
        digestNotificationData: {year, notificationType, month, week, campaignId, memberId}
    } = props;
    const {communityConfig} = useAppContext();
    const {getSocialDigestShareURLs} = useSocialShareServices();
    const {authentication} = useAppContext();
    const {digestShareMessageSend, digestEmailSendViaRecipientEmails, digestEmailSendViaRecipientIds, getDigestEmailRecipients} = useDigestShare();
    const localizer = useLocalizer();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [digestContentData, setDigestContentData] = useState<DigestResponseContent>(DigestResponseContent.EMPTY);
    const [digestShareContentData, setDigestShareContentData] = useState<DigestShareData>(DigestShareData.EMPTY);
    const [loading, setLoading] = useState(true);
    const [shareDigestOpen, toggleShareDigestModal] = useToggle(false);
    const [socialSharingMedia, setSocialSharingMedia] = useState<SharingMedia[]>([]);
    const {fetchCampaignPerformanceSharingMedia} = useDigestService();
    const [shareCampaignId, setShareCampaignId] = useState(0);
    const [campaignInfo, setCampaignInfo] = useState<CampaignInfo>();
    const currentPage = useRef(0);
    const scrollContainer = useRef<ScrollbarInstance>(null);
    const loadMoreButtonRef = useRef<HTMLButtonElement>(null);
    const {getClassificationAttribute} = useApplicableClassifications('', []);

    const shareableText = notificationType === NotificationType.MONTHLY_MODERATOR_DIGEST ?
        communityConfig.url + process.env.PUBLIC_URL + '/campaign-performance/' + shareCampaignId + '/member/' + authentication.actor.id + '/monthly/' + year + '/' + month :
        communityConfig.url + process.env.PUBLIC_URL + '/campaign-performance/' + shareCampaignId + '/member/' + authentication.actor.id + '/weekly/' + year + '/' + week;

    const shareableLink = notificationType === NotificationType.SHARED_WEEKLY_CAMPAIGN_PERFORMANCE || notificationType === NotificationType.WEEKLY_MODERATOR_DIGEST ?
        appLinks.campaignPerformance(shareCampaignId, authentication.actor.id, 'weekly', year, week!) :
        appLinks.campaignPerformance(shareCampaignId, authentication.actor.id, 'monthly', year, month!);

    const internalMessageAdditionalURL = `<a class="d-block cursor-pointer" href="${communityConfig.url + process.env.PUBLIC_URL}${notificationType === NotificationType.SHARED_WEEKLY_CAMPAIGN_PERFORMANCE || notificationType === NotificationType.WEEKLY_MODERATOR_DIGEST ?
        appLinks.campaignPerformance(shareCampaignId, authentication.actor.id, 'weekly', year, week!) :
        appLinks.campaignPerformance(shareCampaignId, authentication.actor.id, 'monthly', year, month!)}">${(campaignInfo?.name ?? '') + ' ' + localizer.msg('notification.moderator-digest.campaign-performance')}</a>`;

    const loadModeratorDigest = useCallback(async () => {
        try {
            const response = notificationType === NotificationType.MONTHLY_MODERATOR_DIGEST ?
                await fetchMonthlyModeratorDigest({page: currentPage.current}, year, month!) :
                await fetchWeeklyModeratorDigest({page: currentPage.current}, year, week!);

            if (currentPage.current === 0) {
                setDigestContentData(prevState => ({
                    ...prevState,
                    hasMore: response.hasMore,
                    nextPageNo: response.nextPageNo,
                    fromDate: response.fromDate,
                    toDate: response.toDate,
                    campaignPerformances: [...response.campaignPerformances]
                }));
            } else {
                setDigestContentData(prevState => ({
                    ...prevState,
                    hasMore: response.hasMore,
                    nextPageNo: response.nextPageNo,
                    fromDate: response.fromDate,
                    toDate: response.toDate,
                    campaignPerformances: [...prevState.campaignPerformances, ...response.campaignPerformances]
                }));
            }
            currentPage.current = response.nextPageNo;
        } catch (e: any) {
            handleErrorResponse(e);
        }
    }, [fetchMonthlyModeratorDigest, fetchWeeklyModeratorDigest, handleErrorResponse, month, notificationType, week, year]);

    const toggleCampaignPerformanceSharingMedia = (shareCampaignId: number, campaign: CampaignInfo) => {
        toggleShareDigestModal();
        setShareCampaignId(shareCampaignId);
        setCampaignInfo(campaign);
        fetchCampaignPerformanceSharingMedia(shareCampaignId).then(data => {
            setSocialSharingMedia(data);
        });
    };

    const campaignHeader = (performanceData: CampaignPerformancesData) => {
        return (
            <div className={`campaign-header d-flex align-items-center mb-2 pb-3 ${styles.campaignHeader}`}>
                <div className="campaign-name d-flex align-items-center">
                    <img className="rounded me-2 d-inline-block"
                         src={performanceData.campaign.image} alt={performanceData.campaign.altText} width={30}
                         height={30}/>
                    <h5 className="d-inline mt-2 fw-bold"
                        title={performanceData.campaign.name}>
                        {communityConfig.classificationEnabled && performanceData.campaign.classificationSummary &&
                            <ClassificationLabel
                                extraClasses="me-2"
                                classification={getClassificationAttribute(performanceData.campaign.classificationSummary)}/>
                        }
                        {performanceData.campaign.name}
                    </h5>
                </div>
                {notificationType === NotificationType.MONTHLY_MODERATOR_DIGEST || notificationType === NotificationType.WEEKLY_MODERATOR_DIGEST
                    ?
                    <div className="sharing-options ms-auto">
                        <button className="btn btn-cancel p-1"
                                onClick={() => toggleCampaignPerformanceSharingMedia(performanceData.campaign.id, performanceData.campaign)}>
                            <Icon name="arrow-up-from-tray" iconSpritePath={svgIconsPath} fill="#666"
                                  width={18}
                                  height={18}/>
                        </button>
                    </div> : ''
                }
            </div>
        );
    };

    const campaignPerformanceTime = () => {
        return (
            <div className={`${styles.campaignPerformance} mt-3`}>
                <h5 className="text-primary fw-bold mb-0">
                    {localizer.msg('notification.moderator-digest.campaign-performance')}
                </h5>
                <p>
                    {notificationType === NotificationType.MONTHLY_MODERATOR_DIGEST || notificationType === NotificationType.SHARED_MONTHLY_CAMPAIGN_PERFORMANCE
                        ?
                        localizer.msg('notification.moderator-digest.performance-monthly', {
                            performMonth: TimeUtil.getMonthNameFromNumber(+month!),
                            performYear: year
                        }) :
                        ''
                    }
                    {notificationType === NotificationType.SHARED_WEEKLY_CAMPAIGN_PERFORMANCE
                        ? <>{localizer.msg('notification.moderator-digest.performance-weekly', {
                            weekOfYear: week!
                        })}{localizer.msg('notification.moderator-digest.week')} {' '}
                            ({TimeUtil.getFormattedDateTime(digestShareContentData.fromDate, 'dd LLLL yyyy')} {' - '}
                            {TimeUtil.getFormattedDateTime(digestShareContentData.toDate, 'dd LLLL yyyy')}) </>
                        : ''
                    }
                    {notificationType === NotificationType.WEEKLY_MODERATOR_DIGEST
                        ? <>{localizer.msg('notification.moderator-digest.performance-weekly', {
                            weekOfYear: week!
                        })}{localizer.msg('notification.moderator-digest.week')} {' '}
                            ({TimeUtil.getFormattedDateTime(digestContentData.fromDate, 'dd LLLL yyyy')} {' - '}
                            {TimeUtil.getFormattedDateTime(digestContentData.toDate, 'dd LLLL yyyy')}) </>
                        : ''
                    }
                </p>
            </div>
        );
    };

    const modalTitle = () => {
        return (
            <div className={styles.topBar}>
                <h3 className="mb-4 mt-n4">
                    {localizer.msg('notification.moderator-digest.moderators')} {' '}
                    {notificationType === NotificationType.MONTHLY_MODERATOR_DIGEST || notificationType === NotificationType.SHARED_MONTHLY_CAMPAIGN_PERFORMANCE
                        ?
                        localizer.msg('notification.moderator-digest.monthly-digest') : ''
                    }
                    {notificationType === NotificationType.WEEKLY_MODERATOR_DIGEST || notificationType === NotificationType.SHARED_WEEKLY_CAMPAIGN_PERFORMANCE
                        ?
                        localizer.msg('notification.moderator-digest.weekly-digest') : ''
                    }
                </h3>
            </div>
        );
    };

    useIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: loadModeratorDigest,
        enabled: digestContentData.hasMore,
        options: {
            root: scrollContainer.current?.container
        }
    });

    useEffect(() => {
        if ((notificationType === NotificationType.MONTHLY_MODERATOR_DIGEST && month) ||
            (notificationType === NotificationType.WEEKLY_MODERATOR_DIGEST && week)) {
            loadModeratorDigest().then(() => {
                setLoading(false);
            });
        } else if (notificationType === NotificationType.SHARED_MONTHLY_CAMPAIGN_PERFORMANCE && month && memberId) {
            fetchCampaignPerformanceMonthlyModeratorDigest(campaignId!, memberId, year, month)
                .then(campaignData => {
                    setDigestShareContentData(campaignData);
                    setLoading(false);
                }).catch(error => {
                handleErrorResponse(error);
            });
        } else if (notificationType === NotificationType.SHARED_WEEKLY_CAMPAIGN_PERFORMANCE && week && campaignId && memberId) {
            fetchCampaignPerformanceWeeklyModeratorDigest(campaignId, memberId, year, week)
                .then(campaignData => {
                    setDigestShareContentData(campaignData);
                    setLoading(false);
                }).catch(error => {
                handleErrorResponse(error);
            });
        }
    }, [campaignId, loadModeratorDigest, fetchCampaignPerformanceMonthlyModeratorDigest, fetchCampaignPerformanceWeeklyModeratorDigest, memberId, month, notificationType, week, year, handleErrorResponse]);

    return (
        <div className={styles.digestContent}>
            {modalTitle()}
            <Scrollbar
                className={styles.campaignList}
                autoHeight
                autoHeightMax={800}
                id="campaign-list"
                ref={scrollContainer}>
                {loading
                    ?
                    <ParagraphSkeleton rows={10}/> :
                    notificationType === NotificationType.MONTHLY_MODERATOR_DIGEST || notificationType === NotificationType.WEEKLY_MODERATOR_DIGEST
                        ?
                        <Fragment>
                            {
                                digestContentData.campaignPerformances.length > 0 ?
                                    digestContentData.campaignPerformances.map((campaign, index) => {
                                        return (
                                            <div className={`campaign p-3 mt-3 ${styles.campaign}`} key={index}>
                                                {campaignHeader(campaign)}
                                                {campaignPerformanceTime()}
                                                <CampaignPerformances campaignPerformanceData={campaign}/>
                                            </div>
                                        );
                                    }) : localizer.msg('notification.moderator-digest.no-campaign-performance')
                            }
                            <InfiniteScrollLoadMoreButton hasMore={digestContentData.hasMore}
                                                          localizer={localizer}
                                                          loading={loading}
                                                          onCLick={loadModeratorDigest}
                                                          skeletonComponent={<ParagraphSkeleton rows={10}/>}
                                                          loadMoreButtonRef={loadMoreButtonRef}/>
                        </Fragment> :
                        <Fragment>
                            {digestShareContentData.campaignPerformances.length > 0
                                ?
                                <div className={`campaign p-3 mt-3 ${styles.campaign}`}>
                                    {campaignHeader(digestShareContentData.campaignPerformances[0])}
                                    {campaignPerformanceTime()}
                                    <CampaignPerformances
                                        campaignPerformanceData={digestShareContentData.campaignPerformances[0]}/>
                                </div> :
                                null
                            }
                        </Fragment>
                }
            </Scrollbar>
            <ShareModal
                shareableText={shareableText}
                shareableLink={shareableLink}
                emailShareModalTitle={localizer.msg('notification.moderator-digest.email-campaign-performance')}
                emailSubject={localizer.msg('notification.moderator-digest.email-subject-text', {
                    memberName: authentication.actor.name,
                    communityName: communityConfig.name,
                    campaignName: campaignInfo?.name ?? ''
                })}
                emailInvitationText={localizer.msg('notification.moderator-digest.email-invitation')}
                open={shareDigestOpen}
                toggle={toggleShareDigestModal}
                socialShareURLs={getSocialDigestShareURLs(socialSharingMedia, shareCampaignId, campaignInfo?.name ?? '', authentication.actor.id, year, month, week, notificationType)}
                internalShareButtonTitle={localizer.msg('notification.moderator-digest.copy-campaign-performance')}
                title={localizer.msg('notification.moderator-digest.share-campaign-performance')}
                emailIdeaAllowed={true}
                shareContentTitle={campaignInfo?.name ?? ''}
                internalMessageAdditionalURL={internalMessageAdditionalURL}
                onSendMessage={(requestParams: PrivateMessageRequestParams) => digestShareMessageSend(requestParams, shareCampaignId, year, month, week)}
                emailIdeaSendByRecipientIds={(requestParams: DigestEmailRequestParams) => digestEmailSendViaRecipientIds(requestParams, shareCampaignId, year, month, week)}
                emailIdeaSendByRecipientEmails={(requestParams: DigestEmailRequestParams) => digestEmailSendViaRecipientEmails(requestParams, shareCampaignId, year, month, week)}
                getEmailRecipients={(parameters: PageParameters) => getDigestEmailRecipients(parameters, shareCampaignId)}
                sharable={true}
                moderatorDigest={true}
                classificationSummary={campaignInfo?.classificationSummary}
                campaignId={campaignInfo?.id}
                privateCampaign={!!campaignInfo?.privateCampaign}
            />
        </div>
    );
};