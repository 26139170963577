import {Command} from './Command';
import {CampaignHomeEditor} from './Editors/CampaignHomeEditor';
import {Operation} from 'models/edit-mode/Operation';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';
import {CampaignEditableFieldType} from 'models/edit-mode/CampaignEditableFieldType';
import {CampaignTeamVisibilityConfigField} from 'models/edit-mode/CampaignTeamVisibilityConfigField';
import {ChangeCampaignTeamVisibilityOperation} from 'models/edit-mode/ChangeCampaignTeamVisibilityOperation';

export class UpdateCampaignModeratorTeamVisibilityCommand implements Command {
    private readonly campaignHomeEditor: CampaignHomeEditor;
    private readonly oldCampaignModeratorTeamVisibility: CampaignTeamVisibilityConfigField;
    private readonly newCampaignModeratorTeamVisibility: CampaignTeamVisibilityConfigField;
    private readonly name: CampaignOperationType;
    private readonly campaignId: number;

    constructor(campaignHomeEditor: CampaignHomeEditor, value: boolean, campaignId: number) {
        this.campaignHomeEditor = campaignHomeEditor;
        this.oldCampaignModeratorTeamVisibility = this.campaignHomeEditor.campaignModeratorTeamVisibility;
        this.newCampaignModeratorTeamVisibility = {
            name: CampaignEditableFieldType.CAMPAIGN_MODERATORS_TEAM,
            value
        };
        this.campaignId = campaignId;
        this.name = CampaignOperationType.SHOW_CAMPAIGN_MODERATORS_TEAM;
    }

    execute(): void {
        this.campaignHomeEditor.campaignModeratorTeamVisibility = this.newCampaignModeratorTeamVisibility;
    }

    undo(): void {
        this.campaignHomeEditor.campaignModeratorTeamVisibility = this.oldCampaignModeratorTeamVisibility;
    }

    getType(): CampaignOperationType {
        return this.name;
    }

    getOperation(): Operation {
        return new ChangeCampaignTeamVisibilityOperation(
            this.name,
            this.campaignHomeEditor.campaignModeratorTeamVisibility.value,
            this.campaignId);
    }
}