import React from 'react';
import {ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle} from '@ideascale/ui';
import {Backdrop, Localizer, useToggle} from '@ideascale/commons';
import {useIdeaListMode} from 'hooks/useIdeaListMode';
import {useEditModeContext} from 'contexts/EditModeContext';
import {IdeaListFilterConfigurationForm} from 'components/edit-mode/IdeaListFilterConfigurationForm';
import {IdeaListViewConfigurationForm} from 'components/edit-mode/IdeaListViewConfigurationForm';
import {EditActionWrapper} from '../../shared/EditActionWrapper';
import {IdeaListOrder} from './IdeaListOrder';
import {IdeaListViewModes} from './IdeaListViewModes';
import {SidebarToggleButton} from '../../shared/SidebarToggleButton';
import {IdeaListViewMode} from 'models/enums/IdeaListViewMode';
import {IdeaListMode} from 'models/enums/IdeaListMode';
import {IdeaFilter} from 'models/enums/IdeaFilter';
import {OrderBy} from 'models/enums/OrderBy';
import {CommunityOperationType} from 'models/edit-mode/CommunityOperationType';
import './IdeaListNavigation.scss';

type IdeaListNavigationProps = {
    localizer: Localizer;
    ideaMode: IdeaListMode;
    ideaModeEditAllowed: boolean;
    onChangeMode: (mode: IdeaListMode) => void;
    orderBy: OrderBy;
    toggleOrder: () => void;
    ideaFilter?: IdeaFilter;
    onChangeFilter?: (filter: IdeaFilter) => void;
    listViewMode?: IdeaListViewMode | null;
    listViewModeEditAllowed?: boolean;
    onClickViewMode?: (viewMode: IdeaListViewMode) => void;
}

export const IdeaListNavigation = (props: IdeaListNavigationProps) => {
    const {
        localizer,
        ideaMode,
        listViewMode,
        orderBy,
        toggleOrder,
        onClickViewMode,
        onChangeMode,
        ideaModeEditAllowed,
        listViewModeEditAllowed
    } = props;

    const {getIdeaListModes} = useIdeaListMode();
    const {validationErrors} = useEditModeContext();

    const [dropdownOpen, toggleDropdownOpen] = useToggle(false);
    const [filterModalOpen, toggleFilterModalOpen] = useToggle(false);
    const [viewModeModalOpen, toggleViewModeModalOpen] = useToggle(false);

    return (
        <div
            className={`idea-list-mode d-flex justify-content-between align-items-center mb-3 ${ideaModeEditAllowed ? 'edit-mode-element' : ''}`}
            role="region" aria-label={localizer.msg('ideas.filters-region-label')}>
            <div className="idea-sort-by">
                <EditActionWrapper editAllowed={ideaModeEditAllowed}
                                   modalTitle={localizer.msg('edit-mode.change-default-filter')}
                                   modalOpen={filterModalOpen}
                                   modalToggle={toggleFilterModalOpen}
                                   configurationChildren={<IdeaListFilterConfigurationForm
                                       toggleModal={toggleFilterModalOpen}/>}
                                   hasError={validationErrors.hasError(CommunityOperationType.CHANGE_IDEA_SORT_MODE)}
                                   editBtnTitle={localizer.msg('edit-mode.change-default-filter')}
                                   editBtnTestElementId="btn-change-default-filter">
                    <div className="btn-group tour-lucido_sort_by_dropdown"
                         role="group"
                         aria-label="Button group with nested dropdown">
                        <ButtonDropdown drop="down"
                                        key="down"
                                        isOpen={dropdownOpen}
                                        toggle={toggleDropdownOpen}>
                            <Backdrop clicked={toggleDropdownOpen}/>

                            <DropdownToggle tag="button" className="btn btn-gray rounded-start border-end-0"
                                            aria-label={localizer.msg('profile.tabs.sort-by-label', {ideaMode})}
                                            title={localizer.msg('profile.tabs.sort-by')} caret
                                            data-test-element-id="idea-sort-by">
                                {localizer.msg(`ideas.mode.${ideaMode.toLowerCase()}`)} &nbsp;&nbsp;
                            </DropdownToggle>

                            <DropdownMenu data-test-element-id="idea-sort-options">
                                {
                                    getIdeaListModes().map(mode =>
                                        <DropdownItem key={mode}
                                                      aria-label={localizer.msg('common.sort-by', {option: mode})}
                                                      className={`${mode.toLowerCase() === ideaMode.toLowerCase() ? 'active' : ''}`}
                                                      data-test-element-id={mode.toLowerCase()}
                                                      onClick={() => onChangeMode(mode)}>
                                            {localizer.msg(`ideas.mode.${mode}`)}
                                        </DropdownItem>
                                    )
                                }
                            </DropdownMenu>
                        </ButtonDropdown>
                        <IdeaListOrder ideaMode={ideaMode} orderBy={orderBy} toggleOrder={toggleOrder}/>
                    </div>
                </EditActionWrapper>
            </div>
            {
                listViewMode && onClickViewMode &&
                <EditActionWrapper editAllowed={!!listViewModeEditAllowed}
                                   modalTitle={localizer.msg('edit-mode.change-default-view')}
                                   modalOpen={viewModeModalOpen}
                                   modalToggle={toggleViewModeModalOpen}
                                   configurationChildren={<IdeaListViewConfigurationForm
                                       toggleModal={toggleViewModeModalOpen}/>}
                                   hasError={validationErrors.hasError(CommunityOperationType.CHANGE_IDEA_VIEW_MODE)}
                                   editBtnTitle={localizer.msg('edit-mode.change-default-view')}
                                   editBtnTestElementId="btn-change-default-view">
                    <IdeaListViewModes listViewMode={listViewMode} onClickViewMode={onClickViewMode}
                                       localizer={localizer}/>
                </EditActionWrapper>
            }
            <SidebarToggleButton/>
        </div>
    );
};