import React, {Fragment} from 'react';
import {Localizer} from '@ideascale/commons';
import {ActivityLink} from '../ActivityLink';
import {ActionType} from 'models/enums/ActionType';
import {ActivityStreamData} from 'models/ActivityStreamData';

type StageChangeActivityProps = {
    localizer: Localizer;
    action: ActionType.IDEA_APPROVE | ActionType.IDEA_STATUS_CHANGE;
    activity: ActivityStreamData;
}

export const StageChangeActivity = (props: StageChangeActivityProps) => {
    const {localizer, activity} = props;

    return (
        <Fragment>
            {activity.stageName
                ? localizer.msg('profile.activity-stream.stage-change.stage', {stageName: activity.stageName})
                : localizer.msg('profile.activity-stream.stage-change.unknown')
            }
            {' - '}
            <ActivityLink title={activity.idea.title} ideaId={activity.idea.id}/>
        </Fragment>
    );
};