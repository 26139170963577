import {CustomField, FieldType, FileAttachment, Member, RenderFormat} from '@ideascale/commons';

export class RefineStageField extends CustomField {
    id?: number;
    hint: string;
    renderFormat: RenderFormat;
    revisionHistoryCount: number;
    attachments: FileAttachment[];
    displayValue: string;
    lastEditedAt: string | null;
    lastEditedBy: Member | null;
    fieldId?: number;

    static isPersisted(field: RefineStageField) {
        return field.id !== undefined;
    }

    static isLocalizable(field: RefineStageField) {
        return (field.type === FieldType.TEXT_AREA || field.type === FieldType.TEXTINPUT);
    }
}