import React, {useCallback, useMemo} from 'react';
import {useToggle} from '@ideascale/commons';
import {isEmpty} from 'lodash';
import {useEditModeContext} from 'contexts/EditModeContext';
import {CommunityNameEditForm} from './CommunityNameEditForm';
import {CommunitySubtitleEditForm} from './CommunitySubtitleEditForm';
import {HtmlRenderer} from 'components/shared/HtmlRenderer';
import {EditActionWrapper} from 'components/shared/EditActionWrapper';
import {useLocalizer} from 'hooks/useLocalizer';
import {useAppContext} from 'contexts/AppContext';
import {CommunityTileImage} from './CommunityTileImage';
import {CommunityEditableFieldType} from 'models/edit-mode/CommunityEditableFieldType';
import {CommunityOperationType} from 'models/edit-mode/CommunityOperationType';
import {ImageField} from 'models/edit-mode/ImageField';
import './CommunityTileEdit.scss';

export const CommunityTileEdit = () => {
    const localizer = useLocalizer();
    const {
        communityConfig: {
            maxFileSizeLimit,
            bannerImageUrl,
            bannerImageAltText,
            name,
            subtitle,
            communityTileImageUrl,
            communityTileImageAltText,
        }
    } = useAppContext();
    const {homeConfig, validationErrors} = useEditModeContext();

    const [tileModalOpen, toggleTileModalOpen] = useToggle(false);
    const [communityNameModalOpen, toggleCommunityNameModalOpen] = useToggle(false);
    const [communitySubTitleModalOpen, toggleCommunitySubTitleModalOpen] = useToggle(false);

    const hasModifiedTileImage = useCallback((tileImage: ImageField) => {
        return isEmpty(tileImage.url) || tileImage.defaultImage;
    }, []);

    const tileImageData = useMemo(() => {
        const tileImage = homeConfig.getImageField(CommunityEditableFieldType.COMMUNITY_TILE_IMAGE);
        if (hasModifiedTileImage(tileImage)) {
            const communityBanner = homeConfig.getBannerField(CommunityEditableFieldType.BANNER_IMAGE);
            if (isEmpty(communityBanner.url)) {
                if (isEmpty(communityTileImageUrl)) {
                    return {url: bannerImageUrl, altText: bannerImageAltText};
                }
                return {url: communityTileImageUrl, altText: communityTileImageAltText};
            }
            return {url: communityBanner.url, altText: communityBanner.altText};
        } else {
            return {url: tileImage.url, altText: tileImage.altText};
        }
    }, [bannerImageAltText, bannerImageUrl, communityTileImageAltText, communityTileImageUrl, hasModifiedTileImage, homeConfig]);

    return (
        <div
            className="edit-mode-community-tile d-flex justify-content-center position-relative align-items-center rounded">
            <EditActionWrapper
                className="position-absolute w-100 h-100 z-index-0"
                editAllowed={homeConfig.isOperationAllowed(CommunityEditableFieldType.COMMUNITY_TILE_IMAGE)}
                configurationChildren={<CommunityTileImage localizer={localizer} maxFileSizeLimit={maxFileSizeLimit}
                                                           toggle={toggleTileModalOpen}/>}
                hasError={validationErrors.hasError(CommunityOperationType.CHANGE_COMMUNITY_TILE)}
                modalTitle={localizer.msg('edit-mode.community.edit-tile-image')}
                modalOpen={tileModalOpen}
                modalToggle={toggleTileModalOpen}
                editBtnTitle={localizer.msg('edit-mode.community.edit-tile-image')}
                editBtnTestElementId="btn-edit-community-tile">
                <div className="slider-bg-overlay w-100 h-100 rounded"/>
                <img
                    className={`w-100 h-100 slider-image rounded ${homeConfig.isOperationAllowed(CommunityEditableFieldType.COMMUNITY_TILE_IMAGE) ? '' : 'opacity-50'}`}
                    src={tileImageData.url}
                    alt={tileImageData.altText || ''}/>
            </EditActionWrapper>

            <div className="w-100 mx-5 z-index-1">
                <EditActionWrapper
                    className={`my-1 fw-bold h1 d-block text-truncate text-center w-75 mx-auto ${homeConfig.isOperationAllowed(CommunityEditableFieldType.COMMUNITY_TITLE) ? '' : 'opacity-50'}`}
                    editAllowed={homeConfig.isOperationAllowed(CommunityEditableFieldType.COMMUNITY_TITLE)}
                    configurationChildren={<CommunityNameEditForm toggleModal={toggleCommunityNameModalOpen}/>}
                    hasError={validationErrors.hasError(CommunityOperationType.CHANGE_COMMUNITY_NAME)}
                    modalTitle={localizer.msg('edit-mode.community.edit-name')}
                    modalOpen={communityNameModalOpen}
                    modalToggle={toggleCommunityNameModalOpen}
                    editBtnTitle={localizer.msg('edit-mode.community.edit-name')}
                    editBtnTestElementId="btn-edit-community-name">
                    {homeConfig.getConfigField(CommunityEditableFieldType.COMMUNITY_TITLE).value || name}
                </EditActionWrapper>

                <EditActionWrapper
                    className={`mx-4 my-3 ${homeConfig.isOperationAllowed(CommunityEditableFieldType.COMMUNITY_SUBTITLE) ? '' : 'opacity-50'}`}
                    editAllowed={homeConfig.isOperationAllowed(CommunityEditableFieldType.COMMUNITY_SUBTITLE)}
                    configurationChildren={<CommunitySubtitleEditForm toggleModal={toggleCommunitySubTitleModalOpen}/>}
                    hasError={validationErrors.hasError(CommunityOperationType.CHANGE_COMMUNITY_SUBTITLE)}
                    modalTitle={localizer.msg('edit-mode.community.edit-subtitle')}
                    modalOpen={communitySubTitleModalOpen}
                    modalToggle={toggleCommunitySubTitleModalOpen}
                    editBtnTitle={localizer.msg('edit-mode.community.edit-subtitle')}
                    editBtnTestElementId="btn-edit-community-subtitle">
                    <div className="py-2 mb-0 font-size-lg text-truncate community-sub-title">
                        {
                            homeConfig.getConfigField(CommunityEditableFieldType.COMMUNITY_SUBTITLE).value ||
                            !homeConfig.isOperationAllowed(CommunityEditableFieldType.COMMUNITY_SUBTITLE)
                                ? <HtmlRenderer
                                    content={homeConfig.getConfigField(CommunityEditableFieldType.COMMUNITY_SUBTITLE).value || subtitle}/>
                                : `${localizer.msg('edit-mode.community.add-subtitle')}`
                        }
                    </div>
                </EditActionWrapper>
            </div>
        </div>
    );
};