import React, {useCallback} from 'react';
import {useLocalizer} from 'hooks/useLocalizer';
import {GroupBase, OptionsOrGroups} from 'react-select';
import {Icon, IdeascaleSelectWithPagination, OptionType} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {SearchInputGroup} from 'shared/SearchInputGroup';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';

type TagSearchFieldProps = {
    fetchModeratorTags(pageParameters: PageParameters): Promise<PagedResponseContent<string>>;
    setModeratorTags: (tags: OptionType[]) => void;
    defaultValue?: OptionType[];
}

export const ModeratorTagSearchField = (props: TagSearchFieldProps) => {
    const {fetchModeratorTags, setModeratorTags, defaultValue} = props;
    const localizer = useLocalizer();

    const loadTags = useCallback(async (term: string, prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>, additionalParams: any) => {
        try {
            const {page} = additionalParams;
            const tagData = await fetchModeratorTags({page, term, limit: 10});
            const tags: OptionType[] = tagData.content.map((tagItem) => ({
                value: tagItem,
                label: tagItem,
                labelAsJsx: <span className="label customize-label">{tagItem}</span>
            }));
            return {
                options: tags,
                hasMore: tagData.hasMore,
                additional: {
                    page: page + 1,
                }
            };
        } catch(e: any) {
            return {
                options: []
            }
        }
    }, [fetchModeratorTags]);

    return (
        <div data-test-element-id="moderator-tags" className="form-group">
            <label className="fw-bold" htmlFor="moderator-tags">{localizer.msg('search.moderator-tags-label')}</label>
            <SearchInputGroup
                groupText={<Icon iconSpritePath={svgIconsPath} name="tag" fill="#777" width={18} height={18}/>}>
                <IdeascaleSelectWithPagination
                    ariaLabel={localizer.msg('search.moderator-tags-label')}
                    isMulti={true}
                    inputId="moderator-tags"
                    loadOptions={loadTags}
                    placeholder="Select Moderator Tags"
                    value={defaultValue}
                    onChange={(option: any) => setModeratorTags(option as OptionType[])}
                    additional={{
                        page: 0
                    }}/>
            </SearchInputGroup>
        </div>
    );
};
