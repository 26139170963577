import React, {useEffect, useState} from 'react';
import {ParagraphSkeleton} from '@ideascale/ui';
import {WorkFlowChart} from './WorkFlowChart';
import {IdeaStagesData} from 'models/IdeaStagesData';
import './IdeaStagesWorkflow.scss';

type IdeaStagesWorkflowProps = {
    fetchIdeaStages: (campaignId: number) => Promise<Array<IdeaStagesData>>;
    ideaId: number;
    fetch: boolean;
    setFetchStageWorkflow: (nextValue?: boolean) => void;
}

export const IdeaStagesWorkflow = (props: IdeaStagesWorkflowProps) => {
    const {fetchIdeaStages, ideaId, fetch, setFetchStageWorkflow} = props;
    const [loading, setLoading] = useState(true);
    const [workflow, setWorkflow] = useState<Array<IdeaStagesData>>([]);

    useEffect(() => {
        if (fetch) {
            fetchIdeaStages(ideaId).then(data => {
                setWorkflow(data);
                setLoading(false);
                setFetchStageWorkflow(false);
            });
        }
    }, [fetchIdeaStages, ideaId, fetch, setFetchStageWorkflow]);

    const getWorkflowChartItems = () => {
        return workflow.map(item => ({
            id: item.id,
            current: item.current,
            completed: false,
            visited: item.visited,
            heading: item.label,
            hoverHeading: true,
        }));
    };

    return loading
        ? <ParagraphSkeleton rows={6}/>
        : <div className="idea-stages-workflow-chart">
            <WorkFlowChart workflowItems={getWorkflowChartItems()}/>
        </div>;
};