import React from 'react';
import {Icon, Scrollbar} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {FieldType, HtmlUtils, hyperlinkRendererResolver, useFieldBadge, useToggle} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {EditProfileQuestions} from 'components/profile/sidebar/EditProfileQuestions';
import {HtmlRenderer} from '../../shared/HtmlRenderer';
import {ProfileFieldData} from 'models/ProfileFieldData';
import {ProfileResponse} from 'models/types/ProfileResponse';
import {ProfileFieldUpdateRequest} from 'models/types/ProfileFieldUpdateRequest';
import {ProfileSidebarResponse} from 'models/ProfileSidebarResponse';

type ProfileDetailsProps = {
    currentProfileId: number;
    profileResponses: ProfileResponse[];
    fetchProfileFields: (memberId: number) => Promise<ProfileFieldData[]>;
    updateProfileFields: (updateRequest: ProfileFieldUpdateRequest, memberId: number) => Promise<ProfileSidebarResponse>;
    canEditProfile: boolean;
}

export const ProfileDetails = (props: ProfileDetailsProps) => {
    const localizer = useLocalizer();
    const {
        profileResponses,
        currentProfileId,
        fetchProfileFields,
        updateProfileFields,
        canEditProfile
    } = props;
    const [modalIsOpen, toggleModal] = useToggle(false);
    const renderBadge = useFieldBadge(localizer);

    const renderProfileResponseValue = (response: ProfileResponse) => {
        switch (response.type) {
            case FieldType.TEXT_AREA:
                return <HtmlRenderer content={response.value}/>;
            case FieldType.DATE:
                return localizer.date(response.value);
            case FieldType.YOUTUBE:
            case FieldType.SCREENR:
            case FieldType.HAPYAK:
            case FieldType.HYPERLINK:
                return <HtmlRenderer content={hyperlinkRendererResolver.resolver(response.value, response.value)}/>;
            default:
                return HtmlUtils.htmlToReact(response.value);
        }
    };

    return (
        <>
            {canEditProfile &&
            <button type="button" className="btn btn-link p-0 btn-edit float-end"
                    title={localizer.msg('profile.profile-details.edit-profile-details')}
                    onClick={() => toggleModal(true)}>
                <Icon iconSpritePath={svgIconPath} name="pencil"/>
            </button>
            }

            {profileResponses.length > 0
                ? <Scrollbar className="profile-bio-content" autoHeight autoHeightMax={500}>
                    <ul className="profile-questions list-unstyled m-0 px-4 me-2">
                        {
                            profileResponses.map((response, index) =>
                                <li className="py-3" key={response.title + index} data-field-type={response.type}>
                                    <p className="fw-bold m-0 mb-1">
                                        {response.title}
                                        {renderBadge(response)}
                                    </p>
                                    <div>{renderProfileResponseValue(response)}</div>
                                </li>
                            )
                        }
                    </ul>
                </Scrollbar>
                : <div className="text-muted px-4">{localizer.msg('profile.profile-details.no-fields')}</div>
            }

            {
                canEditProfile && modalIsOpen &&
                <EditProfileQuestions
                    isOpen={modalIsOpen}
                    toggle={() => toggleModal(false)}
                    localizer={localizer}
                    updateProfileFields={updateProfileFields}
                    fetchProfileFields={fetchProfileFields}
                    verified={false}
                    memberId={currentProfileId}
                />
            }
        </>
    );
};
