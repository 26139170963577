import React from 'react';
import {Checkbox} from '@ideascale/ui';
import {Localizer} from '@ideascale/commons';

type ArchiveCampaignSearchFieldProps = {
    localizer: Localizer;
    isChecked: boolean;
    setIsChecked: (value: boolean) => void;
}

export const ArchiveCampaignSearchField = (props: ArchiveCampaignSearchFieldProps) => {
    const {localizer, isChecked, setIsChecked} = props;

    return (
        <div className="form-group align-self-end">
            <Checkbox
                inputId="archive-campaign"
                className="fw-bold"
                label={localizer.msg('search.input-label.archived-campaigns')}
                name="required"
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
            />
        </div>
    );
};