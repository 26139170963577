import {useCallback} from 'react';
import {ClassificationsHolder, EffectiveClassificationRequest, useApiErrorResponseHandler} from '@ideascale/commons';
import {useCommunityService} from './useService';
import {useLocalizer} from './useLocalizer';

export const useClassificationService = () => {
    const communityService = useCommunityService();
    const localizer = useLocalizer();
    const {throwApiErrorResponse} = useApiErrorResponseHandler({localizer});

    const fetchClassifications = useCallback((campaignId?: number) => {
        if (communityService !== null) {
            try {
                return communityService.fetchClassifications(campaignId);
            } catch (e: any) {
                throwApiErrorResponse(e);
            }
        }
        return Promise.resolve(ClassificationsHolder.EMPTY);
    }, [communityService, throwApiErrorResponse]);

    const fetchEffectiveClassifications = useCallback((effectiveClassificationRequest: EffectiveClassificationRequest[]) => {
        if (communityService !== null) {
            try {
                return communityService.fetchEffectiveClassifications(effectiveClassificationRequest);
            } catch (e: any) {
                throwApiErrorResponse(e);
            }
        }
        return Promise.resolve([]);
    }, [communityService, throwApiErrorResponse]);


    return {
        fetchClassifications,
        fetchEffectiveClassifications
    };
};