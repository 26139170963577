import React, {useCallback} from 'react';
import {GroupBase, OptionsOrGroups} from 'react-select';
import {Icon, IdeascaleSelectWithPagination, OptionType} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {LabelData, PagedResponseContent} from '@ideascale/commons';
import {SearchInputGroup} from '../../shared/SearchInputGroup';
import {useLocalizer} from '../../hooks/useLocalizer';
import styles from '../../containers/SearchFilterContainer.module.scss';
import {PageParameters} from '../../models/types/PageParameters';


type LabelSearchFieldProps = {
    defaultValue?: OptionType[];
    setLabels: (labels: OptionType[]) => void;
    fetchLabels(pageParameters: PageParameters): Promise<PagedResponseContent<LabelData>>;
    initialValue?: OptionType[] | OptionType;
    landingPageSearch?: boolean;
    isMulti?: boolean;
}

export const LabelSearchField = (props: LabelSearchFieldProps) => {
    const {defaultValue, setLabels, fetchLabels, landingPageSearch = false, isMulti = true, initialValue} = props;
    const localizer = useLocalizer();

    const loadLabels = useCallback(async (term: string, prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>, additionalParams: any) => {
        try {
            const {page} = additionalParams;
            const labelData = await fetchLabels({page, term, limit: 10});
            let labels: OptionType[] = labelData.content.map((labelItem) => ({
                value: landingPageSearch ? labelItem : labelItem.id,
                label: labelItem.name,
                labelAsJsx: <div className="label-item"><span
                    className={`label customize-label text-truncate label-field-${labelItem.colorKey}`}>{labelItem.name}</span>
                </div>
            }));
            if (landingPageSearch && labels.length > 0 && page === 0) {
                labels = [
                    {
                        value: "",
                        label: localizer.msg('landing-page.components.idea.all-labels'),
                        labelAsJsx: <div className="label-item"><span
                            className={`label customize-label text-truncate label-field-in-reserve`}>{localizer.msg('landing-page.components.idea.all-labels')}</span>
                        </div>
                    } as OptionType, ...labels];
            }
            return {
                options: labels,
                hasMore: labelData.hasMore,
                additional: {
                    page: page + 1,
                }
            };
        } catch (e: any) {
            return {
                options: []
            }
        }
    }, [fetchLabels, landingPageSearch, localizer]);

    return (
        <div data-test-element-id="label-search" className={`form-group label-search-field ${styles.labelSearchField}`}>
            {
                !landingPageSearch &&
                <label className="fw-bold"
                       htmlFor="label-search">{localizer.msg('search.input-label.labels')}</label>
            }
            <SearchInputGroup
                groupText={<Icon iconSpritePath={svgIconsPath} fill="#666" name="flag-waving"/>}>
                <IdeascaleSelectWithPagination
                    ariaLabel={localizer.msg('search.input-label.select-labels')}
                    isMulti={isMulti}
                    inputId={'label-search'}
                    loadOptions={loadLabels}
                    placeholder="All Labels"
                    defaultValue={initialValue}
                    value={defaultValue}
                    onChange={(values: any) => setLabels(values)}
                    additional={{
                        page: 0
                    }}/>
            </SearchInputGroup>
        </div>
    );
};
