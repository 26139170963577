import React, {Fragment, useCallback} from 'react';
import {Localizer, PageTheme} from '@ideascale/commons';
import {ConfigWrapper} from 'components/landing-page/ConfigWrapper';
import {HtmlRenderer} from 'components/shared/HtmlRenderer';
import {InstructionListItem as InstructionListItemModel} from 'models/landing-page/InstructionListItem';

type InstructionListItemProps = {
    itemConfig: InstructionListItemModel;
    index: number;
    theme: PageTheme;
    localizer: Localizer;
    showConfig: boolean;
    defaultImage?: string;
    showTranslationConfig: boolean,
    onToggleVisibilityClicked: (item: InstructionListItemModel) => Promise<void>;
    onEditClicked: (item: InstructionListItemModel) => void;
    onTranslationClicked: (item: InstructionListItemModel) => void;
    onDeleteClicked: (item: InstructionListItemModel) => void;
}

export const InstructionListItem = (props: InstructionListItemProps) => {
    const {
        itemConfig,
        index,
        showConfig,
        theme,
        defaultImage,
        onDeleteClicked,
        onEditClicked,
        onToggleVisibilityClicked,
        onTranslationClicked,
        showTranslationConfig,
        localizer
    } = props;

    const onEdit = useCallback(() => {
        onEditClicked(itemConfig);
    }, [itemConfig, onEditClicked]);

    const onTranslate = useCallback(() => {
        onTranslationClicked(itemConfig);
    }, [itemConfig, onTranslationClicked]);

    const onDelete = useCallback(() => {
        onDeleteClicked(itemConfig);
    }, [itemConfig, onDeleteClicked]);

    const onToggleVisibility = useCallback(async () => {
        onToggleVisibilityClicked(itemConfig).then();
    }, [itemConfig, onToggleVisibilityClicked]);

    const renderContent = () => {
        switch (theme) {
            case PageTheme.BERNERS_LEE:
                return (
                    <li aria-labelledby={`instruction-item-${index}`}>
                        <ConfigWrapper className="instruction-list-item h-100"
                                       showTranslationConfig={showTranslationConfig}
                                       visible={itemConfig.visible} localizer={localizer}
                                       showConfig={showConfig} onEditClicked={onEdit}
                                       onTranslationClicked={onTranslate}
                                       onDeleteClicked={onDelete}
                                       toggleVisibility={onToggleVisibility}
                                       accessibleName={localizer.msg('landing-page.instruction')}>
                            <div className="row d-flex align-items-center">
                                <div className="col-12 col-md-6">
                                    <div className="highlight-shadow text-center text-lg-left">
                                        <img className="instruction-list-item-image media-item rounded img-fluid"
                                             src={defaultImage ? defaultImage : itemConfig.url}
                                             alt={itemConfig.altText}/>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mt-5 mt-md-0 position-relative">
                                    <div className="instruction-list-item mt-3 w-100">
                                        <div className="d-flex align-items-center">
                                            <div className="py-1 px-3 me-3 d-none d-md-block">
                                                <div
                                                    className="item-order d-flex align-items-center justify-content-center">
                                                    {index + 1}
                                                </div>
                                            </div>
                                            <div className="item-content me-sm-3 flex-grow-1 flex-shrink-1">
                                                <h3 className="h4 instruction-list-item-header text-wrap text-break"
                                                    id={`instruction-item-${index}`}>
                                                    <strong>{itemConfig.title}</strong>
                                                </h3>
                                                <HtmlRenderer className="font-size-lg instruction-list-item-description"
                                                              content={itemConfig.description}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ConfigWrapper>
                    </li>
                );
            case PageTheme.CURIE:
                return (
                    <li key={itemConfig.id} className="col-md-4" aria-labelledby={`instruction-item-${index}`}>
                        <ConfigWrapper className="border-1 instruction-list-item h-100"
                                       onDeleteClicked={onDelete}
                                       localizer={localizer}
                                       visible={itemConfig.visible}
                                       showTranslationConfig={showTranslationConfig}
                                       onTranslationClicked={onTranslate}
                                       showConfig={showConfig}
                                       componentClass="p-3" onEditClicked={onEdit}
                                       toggleVisibility={onToggleVisibility}
                                       accessibleName={localizer.msg('landing-page.instruction')}>
                            <div className="d-flex justify-content-center instruction-list-item-image ">
                                <img className="instruction-list-item-image img-fluid rounded-top"
                                     alt={itemConfig.altText} src={defaultImage ? defaultImage : itemConfig.url}/>
                            </div>
                            <h3 className="h4 mt-5 text-center instruction-list-item-header text-wrap text-break" id={`instruction-item-${index}`}>
                                {itemConfig.title}
                            </h3>
                            <HtmlRenderer className="font-size-lg instruction-list-item-description"
                                          content={itemConfig.description}/>
                        </ConfigWrapper>
                    </li>
                );
            case PageTheme.ARMSTRONG:
                return (
                    <li className="col-md-4" aria-labelledby={`instruction-item-${index}`}>
                        <ConfigWrapper className="instruction-list-item h-100"
                                       onDeleteClicked={onDelete}
                                       localizer={localizer}
                                       visible={itemConfig.visible}
                                       showTranslationConfig={showTranslationConfig}
                                       onTranslationClicked={onTranslate}
                                       showConfig={showConfig} onEditClicked={onEdit}
                                       toggleVisibility={onToggleVisibility}
                                       accessibleName={localizer.msg('landing-page.instruction')}>
                            <div className="mx-auto rounded instruction-list-item-image">
                                <img className="img-fluid rounded-top" alt={itemConfig.altText}
                                     src={defaultImage ? defaultImage : itemConfig.url}/>
                            </div>
                            <h3 className="h4 mt-5 text-center instruction-list-item-header text-wrap text-break" id={`instruction-item-${index}`}>
                                {itemConfig.title}
                            </h3>
                            <HtmlRenderer className="font-size-lg instruction-list-item-description"
                                          content={itemConfig.description}/>
                        </ConfigWrapper>
                    </li>
                );
            case PageTheme.EASTMAN:
            default:
                return (
                    <div className="col-md-4">
                        <ConfigWrapper className="instruction-list-item h-100"
                                       onDeleteClicked={onDelete}
                                       localizer={localizer}
                                       visible={itemConfig.visible}
                                       showTranslationConfig={showTranslationConfig}
                                       onTranslationClicked={onTranslate}
                                       showConfig={showConfig} onEditClicked={onEdit}
                                       toggleVisibility={onToggleVisibility}
                                       accessibleName={localizer.msg('landing-page.instruction')}>
                            <div className="mx-auto rounded instruction-list-item-image">
                                <img className="img-fluid rounded-top" alt={itemConfig.altText}
                                     src={defaultImage ? defaultImage : itemConfig.url}/>
                            </div>
                            <h3 className="h4 mt-5 text-center instruction-list-item-header text-wrap text-break">
                                {itemConfig.title}
                            </h3>
                            <HtmlRenderer className="font-size-lg instruction-list-item-description"
                                          content={itemConfig.description}/>
                        </ConfigWrapper>
                    </div>
                );
        }
    };

    return (
        <Fragment>
            {
                renderContent()
            }
        </Fragment>
    );
};