import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {CollapseExpand, Icon, ParagraphSkeleton, Scrollbar, ScrollbarInstance} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {Campaign, InfiniteScrollLoadMoreButton, Localizer, StringUtil, useApiErrorResponseHandler} from '@ideascale/commons';
import {useProfilePageParams} from 'hooks/useProfilePageParams';
import {FollowedGroupedCampaigns} from 'components/FollowedGroupedCampaigns';
import {CampaignSubscribeResponse} from 'models/CampaignSubscribeResponse';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {CampaignGroup} from 'models/CampaignGroup';

const PAGE_SIZE = 10;

type FollowedCampaignsProps = {
    localizer: Localizer;
    expanded: boolean;
    fetchCampaignGroups: (pageParameters: PageParameters) => Promise<PagedResponseContent<CampaignGroup>>;
    fetchCampaignsByGroup: (pageParameters: PageParameters, groupId: number) => Promise<PagedResponseContent<Campaign>>;
    unsubscribeCampaign: (campaignId: number) => Promise<CampaignSubscribeResponse>;
    subscribeCampaign: (campaignId: number) => Promise<CampaignSubscribeResponse>;
    actionable: boolean;
}

export const FollowedCampaigns = (props: FollowedCampaignsProps) => {
    const {
        localizer,
        expanded,
        fetchCampaignGroups,
        fetchCampaignsByGroup,
        unsubscribeCampaign,
        subscribeCampaign,
        actionable
    } = props;
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const scrollbarContainer = useRef<ScrollbarInstance>(null);
    const loadMoreButtonRef = useRef<HTMLButtonElement>(null);
    const [campaignGroupData, setCampaignGroupData] = useState<CampaignGroup[]>([]);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const currentProfileId = useProfilePageParams();
    const currentPage = useRef(0);

    useEffect(() => {
        currentPage.current = 0;
        setHasMore(true);
    }, [currentProfileId]);

    const loadCampaignGroups = useCallback(async () => {
        fetchCampaignGroups({page: currentPage.current, limit: PAGE_SIZE}).then(data => {
            let hasMore = data.hasMore;
            setHasMore(hasMore);
            setLoading(false);
            if (currentPage.current === 0) {
                setCampaignGroupData(data.content);
            } else {
                setCampaignGroupData(prevData => [...prevData, ...data.content]);
            }
            if (hasMore) {
                currentPage.current = currentPage.current + 1;
            }
        }).catch(e => {
            setLoading(false);
            handleErrorResponse(e);
        });
    }, [fetchCampaignGroups, handleErrorResponse]);

    useEffect(() => {
        if (expanded && hasMore && currentPage.current === 0) {
            loadCampaignGroups().then();
        }
    }, [expanded, hasMore, loadCampaignGroups]);

    const onExpanded = () => {
        if (hasMore && currentPage.current === 0) {
            loadCampaignGroups().then();
        }
    };

    return (
        <CollapseExpand containerClass={'followed-campaigns mb-3 panel-light'}
                        headerTitle={localizer.msg('profile.followed-sections.campaign.heading')}
                        svgIconSprite={svgIconPath}
                        defaultOpen={expanded} onEntering={onExpanded}>
            {
                loading ? (
                    <ParagraphSkeleton rows={6}/>
                ) : (
                    campaignGroupData.length > 0 ? (
                        <Scrollbar className="follow-section"
                                   autoHeight
                                   autoHeightMax={275}
                                   ref={scrollbarContainer}>
                            <div className="pe-2" aria-label={localizer.msg('profile.followed-sections.campaign.heading')}>
                                {
                                    campaignGroupData.map(group => (
                                            <Fragment key={group.id}>
                                                <h3 className="fw-bold d-flex align-items-center mt-2 mb-1 px-1 h6" title={group.name}>
                                                    <Icon iconSpritePath={svgIconPath} name="loudspeaker" fill="#666666"
                                                          className="me-2" height={20}
                                                          width={20}/>
                                                    {StringUtil.textTruncate(group.name, 25)}
                                                </h3>
                                                <FollowedGroupedCampaigns localizer={localizer} groupId={group.id}
                                                                          fetchCampaignsByGroup={fetchCampaignsByGroup}
                                                                          unsubscribeCampaign={unsubscribeCampaign}
                                                                          subscribeCampaign={subscribeCampaign}
                                                                          scrollRef={scrollbarContainer}
                                                                          actionable={actionable}/>
                                            </Fragment>
                                        )
                                    )
                                }
                            </div>
                            <InfiniteScrollLoadMoreButton hasMore={hasMore}
                                                          localizer={localizer}
                                                          loading={loading}
                                                          onCLick={loadCampaignGroups}
                                                          loadMoreButtonRef={loadMoreButtonRef}/>
                        </Scrollbar>
                    ) : (
                        <span className="text-muted d-block mt-2">
                            {localizer.msg('profile.followed-sections.campaign.empty')}
                        </span>
                    )
                )
            }
        </CollapseExpand>
    );
};