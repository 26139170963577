import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {
    ClassificationsHolder,
    ComposeMessageForm,
    Member,
    PagedResponseContent,
    PrivateMessageRequestParams
} from '@ideascale/commons';
import {appLinks} from 'services/AppLinks';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useProfilePageParams} from 'hooks/useProfilePageParams';
import {useFileUploadService} from 'hooks/useFileUploadService';
import {PageParameters} from 'models/types/PageParameters';

type composeProps = {
    fetchRecipients(pageParameters: PageParameters): Promise<PagedResponseContent<Member>>;
    onSendMessage(requestParams: PrivateMessageRequestParams): Promise<void>;
    fetchClassifications: () => Promise<ClassificationsHolder>
}

export const Compose = (props: composeProps) => {
    const {fetchRecipients, onSendMessage, fetchClassifications} = props;
    const {
        communityConfig: {
            classificationEnabled,
            maxFileSizeLimit,
            offensiveEmojis,
            privateMessageMaxRecipients
        }
    } = useAppContext();
    const {tempImageUpload} = useFileUploadService();
    const localizer = useLocalizer();
    const currentProfileId = useProfilePageParams();
    const navigate = useNavigate();

    const onCancel = useCallback(() => {
        navigate(appLinks.messagesInbox(currentProfileId));
    }, [currentProfileId, navigate]);

    return (
        <div className="p-3 col-md-11 compose-box-container">
            {
                classificationEnabled &&
                <div className="alert alert-info">
                    <b>{localizer.msg('common.please-note')}:</b> {localizer.msg('idea.actions.email.classification-notice')}
                </div>
            }
            <ComposeMessageForm
                className="mt-2 message-form message-create-form"
                formId="compose-message"
                fetchClassifications={fetchClassifications}
                onSendMessage={onSendMessage}
                fetchRecipients={fetchRecipients}
                localizer={localizer}
                onCancel={onCancel}
                showCancelButton={true}
                maxFileSizeLimit={maxFileSizeLimit}
                tempImageUpload={tempImageUpload}
                offensiveEmojis={offensiveEmojis}
                classificationEnabled={classificationEnabled}
                privateMessageMaxRecipients={privateMessageMaxRecipients}
            />
        </div>
    );
};
