import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {CollapseExpand, Icon, ParagraphSkeleton, Scrollbar, ScrollbarInstance, Tag} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {eventDispatcher, InfiniteScrollLoadMoreButton, Localizer, PagedResponseContent, PageParameters, useApiErrorResponseHandler} from '@ideascale/commons';
import {useProfilePageParams} from 'hooks/useProfilePageParams';
import {appLinks} from 'services/AppLinks';
import {GenericResponse} from 'models/GenericResponse';
import {SCROLL_EVENTS} from 'constants/AppConstants';

const PAGE_SIZE = 30;

type FollowedTagsProps = {
    localizer: Localizer;
    expanded: boolean;
    fetchFollowedTags: (pageParameters: PageParameters) => Promise<PagedResponseContent<string>>;
    unfollowTag: (tagName: string) => Promise<GenericResponse>;
    followTag: (tagName: string) => Promise<GenericResponse>;
    actionable: boolean;
}

class TagSubscription {
    public name: string;
    public followed: boolean;
}

export const FollowedTags = (props: FollowedTagsProps) => {
    const {localizer, expanded, fetchFollowedTags, unfollowTag, followTag, actionable} = props;
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const currentProfileId = useProfilePageParams();
    const scrollbarContainer = useRef<ScrollbarInstance>(null);
    const loadMoreButtonRef = useRef<HTMLButtonElement>(null);
    const currentPage = useRef(0);
    const [tagData, setTagData] = useState<TagSubscription[]>([]);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);

    const loadTags = useCallback(async () => {
        fetchFollowedTags({page: currentPage.current, limit: PAGE_SIZE}).then(data => {
            let hasMore = data.hasMore;
            setHasMore(hasMore);
            setLoading(false);
            let newData = data.content.map(tag => ({name: tag, followed: true}));
            if (currentPage.current === 0) {
                setTagData(newData);
            } else {
                setTagData(prevState => [...prevState, ...newData]);
            }
            if (hasMore) {
                currentPage.current = currentPage.current + 1;
            }
        }).catch(e => {
            setLoading(false);
            handleErrorResponse(e);
        });
    }, [fetchFollowedTags, handleErrorResponse]);

    const toggleFollowed = async (tagName: string) => {
        let newTagData = [...tagData];
        let tag = newTagData.find(tag => tag.name === tagName);
        if (tag) {
            if (tag.followed) {
                try {
                    await unfollowTag(tagName);
                    tag.followed = false;
                    setTagData(newTagData);
                } catch (error: any) {
                    handleErrorResponse(error);
                }
            } else {
                try {
                    await followTag(tagName);
                    tag.followed = true;
                    setTagData(newTagData);
                } catch (error: any) {
                    handleErrorResponse(error);
                }
            }
        }
    };

    const onExpanded = () => {
        if (hasMore && currentPage.current === 0) {
            loadTags().then();
        }
    };

    useEffect(() => {
        currentPage.current = 0;
        setHasMore(true);
    }, [currentProfileId]);

    useEffect(() => {
        if (expanded && hasMore && currentPage.current === 0) {
            loadTags().then();
        }
    }, [expanded, hasMore, loadTags]);

    return (
        <CollapseExpand containerClass="followed-tags mb-3 panel-light"
                        headerTitle={localizer.msg('profile.followed-sections.tag.heading')}
                        svgIconSprite={svgIconPath}
                        defaultOpen={expanded} onEntering={onExpanded}>
            {
                loading ? (
                    <ParagraphSkeleton rows={6}/>
                ) : (
                    tagData.length > 0 ? (
                        <Scrollbar
                            className="follow-section"
                            autoHeight
                            autoHeightMax={275}
                            ref={scrollbarContainer}>
                            <ul className="list-unstyled pe-2">
                                {
                                    tagData.map((tag, index) => (
                                        <li className="d-flex align-items-center py-1 px-1" key={index} aria-label={localizer.msg('profile.followed-sections.tag.heading')}>
                                            {
                                                actionable ? (
                                                    <button className="btn btn-link p-0"
                                                            aria-label={tag.followed ? localizer.msg('profile.followed-sections.tag.unfollow') : localizer.msg('profile.followed-sections.tag.follow')}
                                                            onClick={() => toggleFollowed(tag.name)} role="switch" aria-checked={tag.followed}>
                                                        <Icon className="active"
                                                              iconSpritePath={svgIconPath}
                                                              name={tag.followed ? 'star-solid' : 'star-outlined'} width={18}
                                                              height={18}/>
                                                    </button>
                                                ) : (
                                                    <Icon className="active"
                                                          iconSpritePath={svgIconPath} name="star-solid" width={18}
                                                          height={18}/>
                                                )
                                            }
                                            <Link to={appLinks.tag(tag.name)}
                                                  title={tag.name}
                                                  onClick={() => eventDispatcher.dispatch(SCROLL_EVENTS.SCROLL_TOP)}>
                                                <Tag label={tag.name} className="ms-1 mb-0"/>
                                            </Link>
                                        </li>
                                    ))
                                }
                                <InfiniteScrollLoadMoreButton hasMore={hasMore}
                                                              loading={loading}
                                                              onCLick={loadTags}
                                                              localizer={localizer}
                                                              loadMoreButtonRef={loadMoreButtonRef}/>
                            </ul>
                        </Scrollbar>
                    ) : (
                        <span className="text-muted d-block mt-2">
                            {localizer.msg('profile.followed-sections.tag.empty')}
                        </span>
                    )
                )
            }
        </CollapseExpand>
    );
};