import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {OriginalIdeaData} from 'models/OriginalIdeaData';
import {Localizer, ParagraphSkeleton, useApiErrorResponseHandler} from '@ideascale/commons';
import {CollapseExpand} from '@ideascale/ui';
import {HtmlRenderer} from 'components/shared/HtmlRenderer';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';

type OriginalIdeaProps = {
    localizer: Localizer;
    ideaId: number;
    translatedIdeaShowAllowed: boolean;
    fetchOriginalIdea: (ideaId: number) => Promise<OriginalIdeaData>;
}

export const OriginalIdea = (props: OriginalIdeaProps) => {
    const {localizer, ideaId, translatedIdeaShowAllowed, fetchOriginalIdea} = props;
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [originalIdea, setOriginalIdea] = useState<OriginalIdeaData>();
    const [isDateFetching, setIsDateFetching] = useState<boolean>(true);

    const loadOriginalIdea = useCallback(async () => {
        if (!originalIdea) {
            try {
                setIsDateFetching(true);
                const response = await fetchOriginalIdea(ideaId);
                setOriginalIdea(response);
            } catch (error: any) {
                handleErrorResponse(error);
            } finally {
                setIsDateFetching(false);
            }
        }
    }, [fetchOriginalIdea, handleErrorResponse, ideaId, originalIdea]);

    useEffect(() => {
        loadOriginalIdea().then();
    }, [loadOriginalIdea]);

    return (
        <>
            {
                isDateFetching
                    ? <ParagraphSkeleton rows={5}/>
                    : (
                        <div className="my-2">
                            <h3 className="sr-only">Translation</h3>
                            {translatedIdeaShowAllowed &&
                                <CollapseExpand
                                    containerClass="panel-default"
                                    headerTitle={translatedIdeaShowAllowed
                                        ? `${localizer.msg('original-idea.original-language')} - ${originalIdea?.language.name}`
                                        : localizer.msg('original-idea.view.translated')
                                    }
                                    isOpen={true}
                                    svgIconSprite={svgIconsPath}>
                                    {
                                        originalIdea &&
                                        <Fragment>
                                            <div>
                                                <strong>{localizer.msg('original-idea.title')}</strong>{' '}{originalIdea.title}
                                            </div>
                                            <div>
                                                <strong>{localizer.msg('original-idea.description')}</strong>
                                                <HtmlRenderer content={originalIdea.description}/>
                                            </div>
                                            {originalIdea.fieldValues.map((fieldValue, index) =>
                                                <div key={fieldValue.id || index}>
                                                    <strong>{fieldValue.name}</strong>
                                                    <HtmlRenderer content={fieldValue.value}/>
                                                </div>
                                            )}
                                        </Fragment>
                                    }
                                </CollapseExpand>
                            }
                        </div>
                    )
            }
        </>
    );
};