import React, {Fragment, useEffect, useState} from 'react';
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import {
    BodyTagAttributes,
    emptyFunction,
    eventDispatcher,
    Member,
    PageTitle,
    useApiErrorResponseHandler,
    useRoutesMatch
} from '@ideascale/commons';
import {TimeUtil} from 'utils/TimeUtil';
import {useAppContext} from 'contexts/AppContext';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useIdeasContext} from 'contexts/IdeasContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useCampaignModuleService} from 'hooks/useCampaignModuleService';
import {useIdeasPageRouteMatch} from 'hooks/useIdeasPageRouteMatch';
import {useIdeaSubmissionActions} from 'hooks/useIdeaSubmissionActions';
import {PAGE_IDS, SCROLL_EVENTS, SHOW_LOGIN_PROMPT, STAGE_KEYS} from 'constants/AppConstants';
import {appLinks} from 'services/AppLinks';
import {ROUTES} from 'shared/Routes';
import {PageLayoutContainer} from 'containers/PageLayoutContainer';
import {CampaignHeaderContainer} from 'containers/CampaignHeaderContainer';
import {AboutCampaignContainer} from 'containers/AboutCampaignContainer';
import {CampaignTeamContainer} from 'containers/CampaignTeamContainer';
import {IdeaSubmissionPreviewContainer} from 'containers/IdeaSubmissionPreviewContainer';
import {IdeaListWithContextProviderContainer} from 'containers/IdeaListWithContextProviderContainer';
import {CampaignWorkflowContainer} from 'containers/CampaignWorkflowContainer';
import {CampaignSidebarContainer} from 'containers/sidebar/CampaignSidebarContainer';
import {IdeaSubmissionEntry} from 'components/IdeaSubmissionEntry';
import {CampaignSidebarEdit} from 'components/edit-mode/CampaignSidebarEdit';
import {SubmissionEndNotice} from 'components/SubmissionEndNotice';
import {CampaignDetailsData} from 'models/CampaignDetailsData';
import {CommunityEditableFieldType} from 'models/edit-mode/CommunityEditableFieldType';
import {CampaignTab} from 'models/enums/CampaignTab';

export const CampaignPage = () => {
    const {
        authentication,
        setCurrentCampaign,
        setCurrentStage,
        communityConfig: {name: communityName, defaultCampaignTab},
        currentCampaign,
        editModeEnabled
    } = useAppContext();
    const {homeConfig, campaignHomeEditor} = useEditModeContext();
    const {actor} = authentication;
    const {fetchCampaignSummary, fetchCampaignDetails} = useCampaignModuleService();
    const localizer = useLocalizer();
    const navigate = useNavigate();
    const {clearIdeaListCachedData} = useIdeasContext();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const {
        routeParams,
        urlTag,
        urlModeratorTag,
        urlLabel,
        urlOrder,
        urlQuery
    } = useIdeasPageRouteMatch(ROUTES.CAMPAIGN.IDEAS_PATH);
    const {onSubmitIdeaButtonClick} = useIdeaSubmissionActions();
    const [campaignData, setCampaignData] = useState<CampaignDetailsData>(CampaignDetailsData.EMPTY);
    const [loading, setLoading] = useState(true);
    const campaignBaseRouteMatched = useRoutesMatch(ROUTES.CAMPAIGN.BASE);

    useEffect(() => {
        if (routeParams.campaignId && +routeParams.campaignId > 0 && currentCampaign?.id !== +routeParams.campaignId && isEmpty(campaignBaseRouteMatched)) {
            (async () => {
                try {
                    const campaign = await fetchCampaignSummary(+routeParams.campaignId!);
                    setCurrentCampaign(campaign);
                    if (!routeParams?.stageKey) {
                        setCurrentStage(null);
                    }
                    if (campaign && routeParams?.stageKey === STAGE_KEYS.UNSPECIFIED) {
                        if (campaign.defaultStage) {
                            navigate({
                                pathname: appLinks.ideas(String(campaign.id), campaign.defaultStage.key),
                                search: urlQuery.toString()
                            });
                        } else {
                            navigate({
                                pathname: appLinks.ideas(String(campaign.id), STAGE_KEYS.ALL_STAGES),
                                search: urlQuery.toString()
                            });
                        }
                    }
                    clearIdeaListCachedData();
                } catch (error: any) {
                    handleErrorResponse(error);
                }
            })();
        }
    }, [clearIdeaListCachedData, currentCampaign?.id, fetchCampaignSummary, handleErrorResponse, navigate, routeParams.campaignId, routeParams?.stageKey, setCurrentCampaign, setCurrentStage, urlQuery, campaignBaseRouteMatched]);

    useEffect(() => {
        eventDispatcher.dispatch(SCROLL_EVENTS.SCROLL_TOP);
    }, [campaignData.id]);

    useEffect(() => {
        if (routeParams.campaignId && isEmpty(campaignBaseRouteMatched)) {
            fetchCampaignDetails(+routeParams.campaignId!).then(data => {
                setCampaignData(data);
                setLoading(false);
                if (data.tosAcceptPending) {
                    navigate(appLinks.campaignTos(routeParams.campaignId!), {replace: true});
                }
            }).catch(handleErrorResponse);
        }
    }, [fetchCampaignDetails, handleErrorResponse, routeParams.campaignId, campaignBaseRouteMatched, navigate]);

    useEffect(() => {
        if (!isEmpty(campaignBaseRouteMatched)) {
            switch (defaultCampaignTab) {
                case CampaignTab.ABOUT:
                    return navigate(appLinks.aboutCampaign(routeParams.campaignId!), {replace: true});
                case CampaignTab.TEAM:
                    return navigate(appLinks.campaignTeam(routeParams.campaignId!), {replace: true});
                case CampaignTab.WORKFLOW:
                    return navigate(appLinks.campaignWorkflow(routeParams.campaignId!), {replace: true});
                case CampaignTab.IDEAS:
                default:
                    return navigate(appLinks.ideas(routeParams.campaignId), {replace: true});
            }
        }
    }, [campaignBaseRouteMatched, defaultCampaignTab, routeParams.campaignId, navigate]);

    return (
        <Fragment>
            <BodyTagAttributes data-test-element-id={PAGE_IDS.CAMPAIGN_HOME}/>
            <PageTitle
                title={`${communityName} - by IdeaScale | ${editModeEnabled ? campaignHomeEditor.name : campaignData.name}`}/>
            <CampaignHeaderContainer/>
            <IdeaSubmissionPreviewContainer displayAsModal={false}/>
            <PageLayoutContainer
                mainContent={
                    <Fragment>
                        {
                            currentCampaign?.ideaSubmissionEndDate && TimeUtil.calculateTimeLeft(currentCampaign?.ideaSubmissionEndDate).days < 31 &&
                            <SubmissionEndNotice localizer={localizer}
                                                 date={currentCampaign?.ideaSubmissionEndDate}/>
                        }
                        {
                            currentCampaign?.ideaSubmissionAllowed &&
                            <IdeaSubmissionEntry
                                localizer={localizer}
                                actionMember={new Member(actor.id, actor.name, actor.username, actor.avatar, '')}
                                onClickAvatar={authentication.actor.isAnonymous() ? () => eventDispatcher.dispatch(SHOW_LOGIN_PROMPT) : emptyFunction}
                                onClick={onSubmitIdeaButtonClick}/>
                        }
                        <Routes>
                            <Route path={ROUTES.CAMPAIGN.IDEAS_WITH_WILDCARD}
                                   element={<IdeaListWithContextProviderContainer
                                       campaignId={routeParams?.campaignId || 'active'}
                                       stageKey={routeParams?.stageKey || STAGE_KEYS.UNSPECIFIED}
                                       urlLabel={urlLabel}
                                       urlModeratorTag={urlModeratorTag}
                                       urlTag={urlTag}
                                       urlOrder={urlOrder}/>}/>
                            <Route path={ROUTES.CAMPAIGN.ABOUT}
                                   element={
                                       routeParams?.campaignId &&
                                       <AboutCampaignContainer loading={loading}
                                                               campaignId={+(routeParams?.campaignId)}
                                                               campaignData={campaignData}/>
                                   }/>
                            <Route path={ROUTES.CAMPAIGN.TEAM}
                                   element={<CampaignTeamContainer/>}/>
                            <Route path={ROUTES.CAMPAIGN.WORKFLOW}
                                   element={
                                       routeParams?.campaignId &&
                                       <CampaignWorkflowContainer campaignId={+(routeParams?.campaignId)}/>
                                   }/>
                            {
                                isEmpty(campaignBaseRouteMatched) &&
                                <Route path="*" element={<Navigate to={ROUTES.HOME} replace={true}/>}/>
                            }
                        </Routes>
                    </Fragment>
                }
                sidebar={<section
                    className={`idea-sidebar ${homeConfig.isOperationAllowed(CommunityEditableFieldType.SIDEBAR) ? 'edit-mode-element' : ''}`}>
                    {
                        homeConfig.isOperationAllowed(CommunityEditableFieldType.SIDEBAR)
                            ? <CampaignSidebarEdit/>
                            : <CampaignSidebarContainer/>
                    }
                </section>}
            />
        </Fragment>
    );
};