import {Command} from './Command';
import {CampaignHomeEditor} from './Editors/CampaignHomeEditor';
import {Operation} from 'models/edit-mode/Operation';
import {LogoField} from 'models/edit-mode/LogoField';
import {ChangeCampaignLogoOperation} from 'models/edit-mode/ChangeCampaignLogoOperation';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';

export class UpdateCampaignLogoCommand implements Command {
    private readonly campaignHomeEditor: CampaignHomeEditor;
    private readonly oldCampaignLogo: LogoField;
    private readonly newCampaignLogo: LogoField;
    private readonly name: CampaignOperationType;
    private readonly campaignId;

    constructor(campaignHomeEditor: CampaignHomeEditor, newLogoField: LogoField, campaignId: number) {
        this.campaignHomeEditor = campaignHomeEditor;
        this.newCampaignLogo = newLogoField;
        this.oldCampaignLogo = this.campaignHomeEditor.campaignLogo;
        this.campaignId = campaignId;
        this.name = CampaignOperationType.CHANGE_CAMPAIGN_LOGO;
    }

    execute(): void {
        this.campaignHomeEditor.campaignLogo = this.newCampaignLogo;
    }

    getType(): CampaignOperationType {
        return this.name;
    }

    getOperation(): Operation {
        const campaignLogo = this.campaignHomeEditor.campaignLogo;
        return new ChangeCampaignLogoOperation(
            CampaignOperationType.CHANGE_CAMPAIGN_LOGO,
            campaignLogo.fileId,
            campaignLogo.logoFrameEnabled,
            campaignLogo.logoOnBannerEnabled,
            this.campaignId
        );
    }

    undo(): void {
        this.campaignHomeEditor.campaignLogo = this.oldCampaignLogo;
    }
}