import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {CollapseExpand, ParagraphSkeleton, Scrollbar, ScrollbarInstance} from '@ideascale/ui';
import iconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {
    AlertEvent,
    AlertType,
    buildAlertEventData,
    eventDispatcher,
    InfiniteScrollLoadMoreButton,
    Member,
    MemberLink,
    useIntersectionObserver
} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {PageParameters} from 'models/types/PageParameters';
import {AdminModeratorData} from 'models/AdminModeratorData';

type ListModuleTypeProps = {
    inputId: string;
    headerTitle: string;
    fetchMemberList: (data: PageParameters) => Promise<AdminModeratorData>;
    manageLink: string;
    manageText: string;
};

const PAGE_SIZE = 10;

export const ListModule = (props: ListModuleTypeProps) => {
    const {inputId, headerTitle, fetchMemberList, manageLink, manageText} = props;
    const localizer = useLocalizer();
    const scrollbarContainer = useRef<ScrollbarInstance>(null);
    const loadMoreButtonRef = useRef<HTMLButtonElement>(null);
    const currentPage = useRef(0);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [canManage, setCanManage] = useState(false);
    const [memberData, setMemberData] = useState<Member[]>([]);

    const loadMembers = useCallback(async () => {
        try {
            const response = await fetchMemberList({page: currentPage.current, limit: PAGE_SIZE});
            if (response.powerUsers.hasMore) {
                currentPage.current = currentPage.current + 1;
            }
            setHasMore(response.powerUsers.hasMore);
            setMemberData(prevData => [...prevData, ...response.powerUsers.content]);
            setCanManage(response.managePowerUsers);
        } catch (e: any) {
            const {message} = (e as Error) || {};
            if (message) {
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.error, message));
            }
        }
    }, [fetchMemberList]);

    useIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: loadMembers,
        enabled: hasMore,
        options: {
            root: scrollbarContainer.current?.container
        }
    });

    useEffect(() => {
        if (currentPage.current === 0 && hasMore) {
            loadMembers().then(() => setLoading(false));
        }
    }, [hasMore, loadMembers]);

    return (
        <CollapseExpand containerClass={`${headerTitle.toLowerCase()} panel-light`}
                        headerTitle={headerTitle}
                        svgIconSprite={iconPath}
                        defaultOpen={true}>
            <Fragment>
                {
                    loading ? (
                        <ParagraphSkeleton rows={4}/>
                    ) : (
                        <Fragment>
                            <div id={inputId} className="list-module mb-2 me-n3">
                                {
                                    memberData.length > 0 ?
                                        <Scrollbar
                                            autoHeight
                                            autoHeightMin={38}
                                            autoHeightMax={300}
                                            ref={scrollbarContainer}>
                                            <ul className="list-unstyled">
                                                {
                                                    memberData.map((member, index) => (
                                                        <li key={index} className="d-flex align-items-center pe-3">
                                                            <img
                                                                className="me-2 float-start avatar avatar-sm text-truncate"
                                                                src={member.avatar}
                                                                alt={member.username ? localizer.msg('common.user-avatar', {username: member.username}) : ''}
                                                            />
                                                            <MemberLink id={member.id}
                                                                        identityHidden={member.identityHidden}>
                                                                {member.name}
                                                            </MemberLink>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                            <InfiniteScrollLoadMoreButton hasMore={hasMore}
                                                                          localizer={localizer}
                                                                          loading={loading}
                                                                          onCLick={loadMembers}
                                                                          loadMoreButtonRef={loadMoreButtonRef}/>
                                        </Scrollbar> :
                                        <div className="p-2">{localizer.msg('common.no-member-found')}</div>
                                }
                            </div>
                            {
                                canManage &&
                                <div>
                                    <a className="ps-2" href={manageLink}>
                                        {manageText}
                                    </a>
                                </div>
                            }
                        </Fragment>
                    )
                }
            </Fragment>
        </CollapseExpand>
    );
};

