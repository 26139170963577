import {useCallback, useEffect, useState} from 'react';
import {DropResult} from 'react-beautiful-dnd';
import {reorderList} from '@ideascale/commons';
import {SidebarItem} from 'models/sidebar/SidebarItem';

export const useSidebarEditHelper = (homeConfigSidebarItems: SidebarItem[], createCommand: (newItems: SidebarItem[]) => void) => {
    const [sidebarItems, setSidebarItems] = useState<SidebarItem[]>([]);
    const [dataFetching, setDataFetching] = useState<boolean>(true);

    const updateSidebarItems = useCallback((newSidebarItems: SidebarItem[]) => {
        setSidebarItems(newSidebarItems);
        createCommand(newSidebarItems);
    }, [createCommand]);


    const onVisibilityButtonClick = useCallback((sidebarItem: SidebarItem) => {
        const newItems = sidebarItems.map(item => item.key === sidebarItem.key ? sidebarItem : item);
        updateSidebarItems(newItems);
    }, [sidebarItems, updateSidebarItems]);

    const onDragEnd = useCallback((result: DropResult) => {
        if (!result.destination) {
            return;
        }
        let newItems = reorderList(
            sidebarItems,
            result.source.index,
            result.destination.index,
            'position'
        );
        updateSidebarItems(newItems);
    }, [sidebarItems, updateSidebarItems]);

    useEffect(() => {
        setSidebarItems(homeConfigSidebarItems);
        setDataFetching(false);
    }, [homeConfigSidebarItems]);

    return {
        sidebarItems,
        dataFetching,
        onVisibilityButtonClick,
        onDragEnd
    };
};