import React, {Fragment} from 'react';
import {BodyTagAttributes, PageTitle} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useUpdateCurrentCampaign} from 'hooks/useUpdateCurrentCampaign';
import {useLocalizer} from 'hooks/useLocalizer';
import {PageLayoutContainer} from 'containers/PageLayoutContainer';
import {SearchFilterContainer} from 'containers/SearchFilterContainer';
import {SearchResultContainer} from 'containers/SearchResultContainer';
import {IdeaSubmissionPreviewContainer} from 'containers/IdeaSubmissionPreviewContainer';
import {PAGE_IDS} from 'constants/AppConstants';

export const SearchPage = () => {
    const {communityConfig} = useAppContext();
    const localizer = useLocalizer();
    useUpdateCurrentCampaign();

    return (
        <Fragment>
            <BodyTagAttributes data-test-element-id={PAGE_IDS.ADVANCED_SEARCH}/>
            <PageTitle
                title={`${communityConfig.name} - by IdeaScale`}/>
            <IdeaSubmissionPreviewContainer displayAsModal/>
            <header>
                <h1 className="sr-only">{localizer.msg('search.page-title')}</h1>
                <div className="container mt-3">
                    <div className="panel panel-default card mx-sm-n3">
                        <SearchFilterContainer/>
                    </div>
                </div>
            </header>
            <PageLayoutContainer
                mainContent={
                    <SearchResultContainer/>
                }
            />
        </Fragment>
    );
};