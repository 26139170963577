import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {ParagraphSkeleton, Scrollbar, ScrollbarInstance} from '@ideascale/ui';
import {InfiniteScrollLoadMoreButton, useApiErrorResponseHandler, useIntersectionObserver} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {LinkedTag} from 'components/shared/LinkedTag';
import {appLinks} from 'services/AppLinks';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {PageParameters} from 'models/types/PageParameters';
import {TagData} from 'models/TagData';
import './TagsList.scss';

type tagsModuleData = {
    fetchTags: (pageParameters: PageParameters, campaignId?: number, stageId?: number) => Promise<PagedResponseContent<TagData>>;
    fetchModeratorTags: (pageParameters: PageParameters, campaignId?: number, stageId?: number) => Promise<PagedResponseContent<TagData>>;
    title: string | JSX.Element;
}

const TAGS_LIST = 'tags-list';
const MODERATOR_TAGS_LIST = 'moderator-tags-list';

export const TagsList = (props: tagsModuleData) => {
    const {fetchTags, title, fetchModeratorTags} = props;
    const localizer = useLocalizer();
    const {currentCampaign} = useAppContext();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const tagScrollbarContainer = useRef<ScrollbarInstance>(null);
    const tagLoadMoreButtonRef = useRef<HTMLButtonElement>(null);
    const moderatorTagScrollbarContainer = useRef<ScrollbarInstance>(null);
    const moderatorTagLoadMoreButtonRef = useRef<HTMLButtonElement>(null);
    const tagsCurrentPage = useRef(0);
    const moderatorTagsCurrentPage = useRef(0);
    const [tags, setTags] = useState<TagData[]>([]);
    const [moderatorTags, setModeratorTags] = useState<TagData[]>([]);
    const [loadingTags, setLoadingTags] = useState(false);
    const [loadingModeratorTags, setLoadingModeratorTags] = useState(false);
    const [initialLoadingTags, setInitialLoadingTags] = useState(true);
    const [initialLoadingModeratorTags, setInitialLoadingModeratorTags] = useState(true);
    const [hasMoreTags, setHasMoreTags] = useState(true);
    const [hasMoreModeratorTags, setHasMoreModeratorTags] = useState(true);


    const loadTags = useCallback(async () => {
        try {
            setLoadingTags(true);
            const pagedResponse = await fetchTags({page: tagsCurrentPage.current}, currentCampaign?.id);
            if (pagedResponse.hasMore) {
                tagsCurrentPage.current = tagsCurrentPage.current + 1;
            }
            setHasMoreTags(pagedResponse.hasMore);
            setTags(prevData => [...prevData, ...pagedResponse.content]);
        } catch (error: any) {
            handleErrorResponse(error);
        } finally {
            setLoadingTags(false);
        }
    }, [currentCampaign?.id, fetchTags, handleErrorResponse]);


    const loadModeratorTags = useCallback(async () => {
        try {
            setLoadingModeratorTags(true);
            const pagedResponse = await fetchModeratorTags({page: moderatorTagsCurrentPage.current}, currentCampaign?.id);
            if (pagedResponse.hasMore) {
                moderatorTagsCurrentPage.current = moderatorTagsCurrentPage.current + 1;
            }
            setHasMoreModeratorTags(pagedResponse.hasMore);
            setModeratorTags(prevData => [...prevData, ...pagedResponse.content]);
        } catch (error: any) {
            handleErrorResponse(error);
        } finally {
            setLoadingModeratorTags(false);
        }
    }, [currentCampaign?.id, fetchModeratorTags, handleErrorResponse]);

    useIntersectionObserver({
        target: tagLoadMoreButtonRef,
        onIntersect: loadTags,
        enabled: hasMoreTags,
        options: {
            root: tagScrollbarContainer.current?.container
        }
    });

    useIntersectionObserver({
        target: moderatorTagLoadMoreButtonRef,
        onIntersect: loadModeratorTags,
        enabled: hasMoreModeratorTags,
        options: {
            root: moderatorTagScrollbarContainer.current?.container
        }
    });

    const renderTags = () => {
        return tags.length > 0 ?
            (
                <Scrollbar
                    id={TAGS_LIST}
                    autoHeight
                    autoHeightMax={380}
                    ref={tagScrollbarContainer}>
                    <ul className="list-inline pe-3 pt-3">
                        {
                            tags.map((item, index) => {
                                return (
                                    <li className="list-inline-item ms-2" key={index}>
                                        <LinkedTag item={item}
                                                   link={appLinks.tag(item.label, currentCampaign?.id.toString())}/>
                                    </li>
                                );
                            })
                        }
                    </ul>
                    <InfiniteScrollLoadMoreButton hasMore={hasMoreTags}
                                                  localizer={localizer}
                                                  loading={loadingTags}
                                                  skeletonComponent={<ParagraphSkeleton rows={1}/>}
                                                  onCLick={loadTags}
                                                  loadMoreButtonRef={tagLoadMoreButtonRef}/>
                </Scrollbar>
            ) : (
                <div className="p-2">{localizer.msg('sidebar.tags.no-tags')}</div>
            );
    };

    useEffect(() => {
        loadTags().then(() => setInitialLoadingTags(false));
    }, [loadTags]);

    useEffect(() => {
        loadModeratorTags().then(() => setInitialLoadingModeratorTags(false));
    }, [loadModeratorTags]);

    return (
        <Fragment>
            <Fragment>
                <div className="mb-2 overflow-hidden">
                    <h2 className="h4">{title}</h2>
                </div>
                {
                    initialLoadingTags ? <ParagraphSkeleton rows={6}/> : renderTags()
                }
            </Fragment>

            <div className="mb-3 overflow-hidden"/>
            {
                initialLoadingModeratorTags ? <ParagraphSkeleton rows={6}/> :
                    moderatorTags.length > 0 &&
                    <Fragment>
                        <div className="mb-2 overflow-hidden">
                            <h2 className="h4">{localizer.msg('sidebar.tags.moderator-tags')}</h2>
                        </div>
                        <Scrollbar className="pe-2"
                                   id={MODERATOR_TAGS_LIST}
                                   autoHeight
                                   autoHeightMax={380}
                                   ref={moderatorTagScrollbarContainer}>
                            <ul className="list-inline pe-3 pt-3">
                                {
                                    moderatorTags.map((item, index) => {
                                        return (
                                            <li className="list-inline-item ms-2" key={index}>
                                                <LinkedTag item={item}
                                                           link={appLinks.moderatorTag(item.label, currentCampaign?.id.toString())}/>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                            <InfiniteScrollLoadMoreButton hasMore={hasMoreModeratorTags}
                                                          loading={loadingModeratorTags}
                                                          localizer={localizer}
                                                          skeletonComponent={<ParagraphSkeleton rows={1}/>}
                                                          onCLick={loadModeratorTags}
                                                          loadMoreButtonRef={moderatorTagLoadMoreButtonRef}/>
                        </Scrollbar>
                    </Fragment>
            }
        </Fragment>
    );
};