import {EmailRequest, FieldAttributeParameters} from '@ideascale/commons';
import {CampaignPerformanceParameters} from './CampaignPerformanceParameters';

export class DigestEmailRequestParams extends EmailRequest {
    static builder(): DigestEmailRequestParamsBuilder {
        const EMPTY = new DigestEmailRequestParams('', '', [], []);
        return new DigestEmailRequestParamsBuilder(EMPTY);
    }

    constructor(
        subject: string,
        message: string,
        recipientIds?: string[],
        recipientEmails?: string[],
        public campaignPerformanceParameters?: CampaignPerformanceParameters,
        attributeApiParameters?: FieldAttributeParameters[]
    ) {
        super(subject, message, recipientIds, recipientEmails, attributeApiParameters);
    }
}

export class DigestEmailRequestParamsBuilder {
    constructor(private emailRequestParams: DigestEmailRequestParams) {
    }

    subject(subject: string): this {
        this.emailRequestParams.subject = subject;
        return this;
    }

    message(message: string): this {
        this.emailRequestParams.message = message;
        return this;
    }

    recipientEmails(recipientEmails?: string[]): this {
        this.emailRequestParams.recipientEmails = recipientEmails;
        return this;
    }

    recipientIds(recipientIds?: string[]): this {
        this.emailRequestParams.recipientIds = recipientIds;
        return this;
    }

    campaignPerformanceParameters(campaignPerformanceParameters?: CampaignPerformanceParameters): this {
        this.emailRequestParams.campaignPerformanceParameters = campaignPerformanceParameters;
        return this;
    }

    attributeApiParameters(attributeApiParameters?: FieldAttributeParameters[]): this {
        this.emailRequestParams.attributeApiParameters = attributeApiParameters;
        return this;
    }

    build(): DigestEmailRequestParams {
        return this.emailRequestParams;
    }
}