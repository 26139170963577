import {useCallback} from 'react';
import {QualifierLinkedIdeas} from 'models/QualifierLinkedIdeas';
import {LinkedIdea} from 'models/LinkedIdea';
import {LinkIdeaQualifier} from '@ideascale/commons';

export const useQualifierLinkedIdeasAction = () => {
    const addIdeaToQualifiedLinkedIdeas = useCallback((qualifierIdeas: QualifierLinkedIdeas[], idea: LinkedIdea, qualifier: LinkIdeaQualifier) => {
        const newQualifierIdeas = [...qualifierIdeas.map(value => ({linkQualifier: value.linkQualifier, ideas: [...value.ideas]}))];
        const findIndex = newQualifierIdeas.findIndex((qualifiedIdeas) => qualifiedIdeas.linkQualifier.id === qualifier.id);
        if (findIndex > -1) {
            const ideaIndex = newQualifierIdeas[findIndex].ideas.findIndex(prevIdea => prevIdea.id === idea.id);
            if (ideaIndex < 0) {
                newQualifierIdeas[findIndex].ideas = [...newQualifierIdeas[findIndex].ideas, idea];
            }
        } else {
            const findNextQualifierIndex = newQualifierIdeas.findIndex((qualifiedIdeas) => qualifiedIdeas.linkQualifier.id === qualifier.id + 1);
            if (findNextQualifierIndex > -1) {
                newQualifierIdeas.splice(findNextQualifierIndex, 0, {linkQualifier: qualifier, ideas: [idea]});
            } else {
                newQualifierIdeas.push({linkQualifier: qualifier, ideas: [idea]});
            }
        }
        return newQualifierIdeas;
    }, []);

    const removeIdeaFromQualifiedLinkedIdeas = useCallback((qualifierIdeas: QualifierLinkedIdeas[], linkedIdea: LinkedIdea, qualifierId: number) => {
        return qualifierIdeas
            .map(qualifierLinkedIdeas => {
                if (qualifierLinkedIdeas.linkQualifier.id === qualifierId) {
                    return {
                        ...qualifierLinkedIdeas,
                        ideas: qualifierLinkedIdeas.ideas.filter(idea => idea.id !== linkedIdea.id)
                    };
                } else {
                    return qualifierLinkedIdeas;
                }
            });
    }, []);

    return {addIdeaToQualifiedLinkedIdeas, removeIdeaFromQualifiedLinkedIdeas};
};