import React, {useCallback, useEffect, useRef, useState} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import {useParams} from 'react-router-dom';
import {useQuery} from 'react-query';
import {Icon, ParagraphSkeleton, RichTextClassificationConfig} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {
    ClassifiableContributionType,
    ClassificationAttachmentAttribute,
    ClassificationsHolder,
    CommentType,
    eventDispatcher,
    FileAttachmentDetail,
    SUPER_COMMENT_EVENTS,
    useApiErrorResponseHandler
} from '@ideascale/commons';
import {DEFAULT_STALE_TIME, EFFECTIVE_CLASSIFICATION, ID_PREFIXES, QUERY_KEYS} from 'constants/AppConstants';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useCommentService} from 'hooks/useCommentService';
import {useCommentUtils} from 'hooks/useCommentUtils';
import {useClassificationService} from 'hooks/useClassificationService';
import {useIdeaUpdater} from 'hooks/useIdeaUpdater';
import {useFileUploadService} from 'hooks/useFileUploadService';
import {CommonUtil} from 'utils/CommonUtil';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {CommentLevel} from 'models/comments/CommentLevel';
import {StageCommentSummary} from 'models/comments/StageCommentSummary';
import {StageComment} from 'components/idea-details-tabs/StageComment';
import {StageCommentOption} from 'models/comments/StageCommentOption';
import {StageCommentPermissions} from 'models/comments/StageCommentPermissions';
import {StageCommentForm} from 'components/idea-details-tabs/StageCommentForm';
import {AssessmentCommentOption} from 'models/comments/AssessmentCommentOption';
import {BuildTeamCommentOption} from 'models/comments/BuildTeamCommentOption';
import {ReviewscaleCommentOption} from 'models/comments/ReviewscaleCommentOption';
import {StageCommentType} from 'models/comments/StageCommentType';
import {CommentRouteMatchParams} from 'models/types/CommentRouteMatchParams';

type StageCommentsContainerProps = {
    ideaId: number;
    commentOption: StageCommentOption;
    className?: string;
    classificationsMenuData?: ClassificationsHolder | {};
    richTextClassificationConfig?: RichTextClassificationConfig;
}

export const StageCommentsContainer = (props: StageCommentsContainerProps) => {
    const {ideaId, commentOption, className, classificationsMenuData, richTextClassificationConfig} = props;

    const localizer = useLocalizer();
    const params = useParams<CommentRouteMatchParams>();
    const {authentication: {actor}, communityConfig: {classificationEnabled}} = useAppContext();
    const {
        fetchStageComments,
        deleteStageComment,
        createSuperComment,
        deleteStageCommentAttachment,
        fetchStageCommentPermissions,
        addStageCommentAttachments,
        unfurlUrl
    } = useCommentService();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const {uploadFile} = useFileUploadService();
    const {fetchEffectiveClassifications} = useClassificationService();
    const {updateIdeaEffectiveClassificationAttribute} = useIdeaUpdater();
    const {findCommentIdById, scrollToComment} = useCommentUtils();
    const [stageCommentsList, setStageCommentsList] = useState<StageCommentSummary[]>([]);
    const [hasMoreComments, setHasMoreComments] = useState<boolean>(false);
    const [isCommentLoading, setIsCommentLoading] = useState<boolean>(true);
    const pageRef = useRef(0);
    const commentHighlighted = useRef(false);

    const {data: commentPermissions = StageCommentPermissions.EMPTY} = useQuery([QUERY_KEYS.STAGE_COMMENT_PERMISSION, ideaId], () => fetchStageCommentPermissions(ideaId), {
        staleTime: DEFAULT_STALE_TIME
    });

    const getSourceId = useCallback(() => {
        switch (commentOption.commentType) {
            case CommentType.ASSESSMENT:
                return (commentOption as AssessmentCommentOption).assessmentId;
            case CommentType.BUILD_TEAM:
                return (commentOption as BuildTeamCommentOption).groupId;
            case CommentType.REVIEWSCALE:
                return (commentOption as ReviewscaleCommentOption).reviewscaleId;
            default:
                return 0;
        }
    }, [commentOption]);

    const getStageCommentType = useCallback(() => {
        switch (commentOption.commentType) {
            case CommentType.ASSESSMENT:
                return StageCommentType.ASSESSMENT_COMMENT;
            case CommentType.BUILD_TEAM:
                return StageCommentType.BUILD_TEAM_COMMENT;
            case CommentType.REVIEWSCALE:
                return StageCommentType.REVIEWSCALE_COMMENT;
            default:
                return StageCommentType.UNDEFINED;
        }
    }, [commentOption.commentType]);

    const fetchPaginatedComments = useCallback(async (stageId: number) => {
        try {
            const commentResponse: PagedResponseContent<StageCommentSummary> = await fetchStageComments(ideaId, stageId, {page: pageRef.current});
            setStageCommentsList(prev => [...prev, ...commentResponse.content]);
            setHasMoreComments(commentResponse.hasMore);
            pageRef.current = commentResponse.hasMore ? pageRef.current + 1 : pageRef.current;
        } catch (error: any) {
            handleErrorResponse(error);
        } finally {
            setIsCommentLoading(false);
        }
    }, [fetchStageComments, handleErrorResponse, ideaId]);

    const onHasMoreCommentsClick = () => {
        if (hasMoreComments) {
            fetchPaginatedComments(commentOption.stageId).then();
        }
    };

    useEffect(() => {
        if (commentOption.stageId !== 0) {
            pageRef.current = 0;
            setStageCommentsList([]);
            fetchPaginatedComments(commentOption.stageId).then();
        }
    }, [fetchPaginatedComments, commentOption.stageId]);

    const findComment = useCallback((commentSummaries: StageCommentSummary[], commentId: number): StageCommentSummary | undefined => {
        let findItem: StageCommentSummary | undefined;
        commentSummaries.every((item) => {
            if (item.id === commentId) {
                findItem = item;
                return false;
            }
            if (item.replies) {
                findItem = findComment(item.replies, commentId);
                return !findItem;
            }
            return !findItem;
        });
        return findItem;
    }, []);

    const updateCommentEffectiveClassificationAttribute = useCallback(async (commentId: number, parentId: number | undefined, updateState: React.Dispatch<React.SetStateAction<StageCommentSummary[]>>) => {
        try {
            const requestBody = [
                {id: ideaId, type: ClassifiableContributionType.IDEA},
                {id: commentId, type: ClassifiableContributionType.STAGE_COMMENT}
            ];

            if (parentId && commentId !== parentId) {
                requestBody.push({id: parentId, type: ClassifiableContributionType.STAGE_COMMENT});
            }

            const response = await fetchEffectiveClassifications(requestBody);
            const ideaEffectiveClassification = response?.find(item => item.id === ideaId);
            updateIdeaEffectiveClassificationAttribute(ideaId, ideaEffectiveClassification);

            updateState(prev => {
                const oldComments = cloneDeep(prev);
                const commentFound = findComment(oldComments, commentId);
                if (commentFound) {
                    const classificationSummary = response.find(item => item.id === commentFound.id);
                    if (classificationSummary) {
                        commentFound.attributes = [...commentFound?.attributes?.filter(attr => attr.name !== EFFECTIVE_CLASSIFICATION) ?? [], {
                            name: EFFECTIVE_CLASSIFICATION,
                            value: classificationSummary.classificationSummary
                        }];
                    }
                }
                return oldComments;
            });
        } catch (error: any) {
            handleErrorResponse(error);
        }
    }, [fetchEffectiveClassifications, findComment, handleErrorResponse, ideaId, updateIdeaEffectiveClassificationAttribute]);

    const updateEffectiveClassification = useCallback(async (commentId: number, parentId?: number) => {
        if (classificationEnabled) {
            await updateCommentEffectiveClassificationAttribute(commentId, parentId, setStageCommentsList);
        }
    }, [classificationEnabled, updateCommentEffectiveClassificationAttribute]);

    const onAddNewComment = useCallback(async (comment: StageCommentSummary) => {
        setStageCommentsList(prev => {
            return [...prev, comment];
        });

        if (comment.annotated) {
            eventDispatcher.dispatch(SUPER_COMMENT_EVENTS.SUPER_COMMENTS_RELOAD);
        }

        scrollToComment(`${ID_PREFIXES.COMMENT}-${comment.id}`);

        await updateEffectiveClassification(comment.id);
    }, [scrollToComment, updateEffectiveClassification]);

    const updateCommentsInList = (prev: StageCommentSummary[], commentId: number, replyComment: StageCommentSummary) => {
        const oldComments = cloneDeep(prev);
        oldComments.every((comment, index, argsArray) => {
            let findComment = argsArray.find(item => item.id === commentId);
            if (findComment) {
                findComment.replies = [...findComment.replies || [], replyComment];
                return false;
            }

            findComment = (comment.replies || []).find(item => item.id === commentId);
            if (findComment) {
                findComment.replies = [...findComment.replies || [], replyComment];
                return false;
            }
            return true;
        });
        return oldComments;
    };

    const onReplyComment = useCallback(async (commentId: number, replyComment: StageCommentSummary) => {
        setStageCommentsList(prev => {
            return updateCommentsInList(prev, commentId, replyComment);
        });

        if (replyComment.annotated) {
            eventDispatcher.dispatch(SUPER_COMMENT_EVENTS.SUPER_COMMENTS_RELOAD);
        }
        CommonUtil.flashElement(`${ID_PREFIXES.COMMENT}-${replyComment.id}`);

        await updateEffectiveClassification(replyComment.id, commentId);
    }, [updateEffectiveClassification]);

    const deleteAttachmentFromList = (prevComments: StageCommentSummary[], commentId: number, attachmentId: number, _commentLevel: CommentLevel) => {
        const oldAttachments = cloneDeep(prevComments);
        oldAttachments.every(stageComment => {
            if (stageComment.id === commentId && stageComment.attachments) {
                stageComment.attachments = stageComment.attachments.filter(attachment => attachment.id !== attachmentId);
                return false;
            }

            if (stageComment.replies?.length > 0) {
                stageComment.replies.every((comment2) => {
                    if (comment2.id === commentId) {
                        comment2.attachments = comment2.attachments.filter(attachment => attachment.id !== attachmentId);
                        return false;
                    }
                    return true;
                });
            }
            return true;
        });
        return oldAttachments;
    };

    const deleteAttachment = useCallback((commentId: number, attachmentId: number, commentLevel: CommentLevel) => {
        setStageCommentsList(prev => {
            return deleteAttachmentFromList(prev, commentId, attachmentId, commentLevel);
        });
    }, []);

    const onDeleteAttachment = useCallback(async (commentId: number, attachmentId: number, commentLevel: CommentLevel, parentId?: number) => {
        await deleteStageCommentAttachment(attachmentId);
        deleteAttachment(commentId, attachmentId, commentLevel);
        updateEffectiveClassification(commentId, parentId).then();
    }, [deleteAttachment, deleteStageCommentAttachment, updateEffectiveClassification]);

    const updateAttachmentsInLists = (prev: StageCommentSummary[], commentId: number, attachments: FileAttachmentDetail[], commentLevel: CommentLevel, attributes?: ClassificationAttachmentAttribute[]) => {
        const oldAttachments = cloneDeep(prev);
        oldAttachments.every(stageComment => {
            if (commentId === stageComment.id) {
                stageComment.attachments = attachments;
                stageComment.attributes = attributes;
                return false;
            }

            if (stageComment.replies?.length > 0) {
                stageComment.replies.every((comment2) => {
                    if (comment2.id === commentId) {
                        comment2.attachments = attachments;
                        comment2.attributes = attributes;
                        return false;
                    }
                    return true;
                });
            }
            return true;
        });
        return oldAttachments;
    };

    const updateCommentListAttachments = useCallback((commentId: number, attachments: FileAttachmentDetail[], commentLevel: CommentLevel, attributes?: ClassificationAttachmentAttribute[]) => {
        setStageCommentsList(prev => {
            return updateAttachmentsInLists(prev, commentId, attachments, commentLevel, attributes);
        });
    }, []);

    const onAddAttachments = useCallback((commentId: number, attachments: FileAttachmentDetail[], commentLevel: CommentLevel, attributes?: ClassificationAttachmentAttribute[], parentId?: number) => {
        updateCommentListAttachments(commentId, attachments, commentLevel, attributes);
        updateEffectiveClassification(commentId, parentId).then();
    }, [updateCommentListAttachments, updateEffectiveClassification]);

    const deleteCommentFromList = (prev: StageCommentSummary[], commentId: number, _commentLevel: CommentLevel) => {
        const oldComments = cloneDeep(prev);
        oldComments.every((comment, index) => {
            if (comment.id === commentId) {
                oldComments.splice(index, 1);
                return false;
            }

            if (comment.replies?.length > 0) {
                comment.replies.every((comment2, index) => {
                    if (comment2.id === commentId && comment.replies?.length > 0) {
                        comment.replies.splice(index, 1);
                        return false;
                    }
                    return true;
                });
            }
            return true;
        });
        return oldComments;
    };

    const onDeleteComment = useCallback(async (comment: StageCommentSummary, commentLevel: CommentLevel, parentId?: number) => {
        await deleteStageComment(comment.id);
        if (comment.annotated) {
            eventDispatcher.dispatch(SUPER_COMMENT_EVENTS.SUPER_COMMENTS_RELOAD);
        }

        setStageCommentsList(prev => {
            return deleteCommentFromList(prev, comment.id, commentLevel);
        });

        updateEffectiveClassification(comment.id, parentId).then();
    }, [deleteStageComment, updateEffectiveClassification]);

    const updateCommentListAfterAnnotated = (prev: StageCommentSummary[], commentId: number, commentLevel: CommentLevel) => {
        const oldComments = cloneDeep(prev);
        oldComments.every((comment) => {
            if (comment.id === commentId) {
                comment.annotated = true;
                return false;
            }

            if (commentLevel === CommentLevel.Level2 && comment.replies.length > 0) {
                comment.replies.every((comment2) => {
                    if (comment2.id === commentId && comment.replies?.length > 0) {
                        comment2.annotated = true;
                        return false;
                    }
                    return true;
                });
            }
            return true;
        });
        return oldComments;
    };

    const onAnnotateComment = useCallback(async (commentId: number, commentLevel: CommentLevel) => {
        const response = await createSuperComment(commentId, commentOption.commentType);
        if (response) {
            eventDispatcher.dispatch(SUPER_COMMENT_EVENTS.SUPER_COMMENTS_RELOAD);

            setStageCommentsList(prev => {
                return updateCommentListAfterAnnotated(prev, commentId, commentLevel);
            });
        }
    }, [commentOption.commentType, createSuperComment]);

    const updateUnAnnotateComment = (prev: StageCommentSummary[], commentId: number) => {
        const oldComments = cloneDeep(prev);
        oldComments.every((comment, index, argsArray) => {
            let findComment = argsArray.find(item => item.id === commentId);
            if (findComment) {
                findComment.annotated = false;
                return false;
            }

            if (comment.replies) {
                findComment = comment.replies.find(item => item.id === commentId);
                if (findComment) {
                    findComment.annotated = false;
                    return false;
                }
            }
            return true;
        });

        return oldComments;
    };

    useEffect(() => {
        eventDispatcher.addListener(SUPER_COMMENT_EVENTS.SUPER_COMMENT_REMOVE, (eventData: { commentId: number, commentType: CommentType }) => {
            const {commentId} = eventData;
            setStageCommentsList(prev => {
                return updateUnAnnotateComment(prev, commentId);
            });
        });

        return () => {
            eventDispatcher.removeListener(SUPER_COMMENT_EVENTS.SUPER_COMMENT_REMOVE);
        };
    }, [fetchStageComments]);

    useEffect(() => {
        if (params.commentId && !commentHighlighted.current) {
            const findCommentId = findCommentIdById(stageCommentsList, +params.commentId);
            if (findCommentId) {
                scrollToComment(`${ID_PREFIXES.COMMENT}-${findCommentId}`);
                commentHighlighted.current = true;
            }
        }
    }, [stageCommentsList, params.commentId, findCommentIdById, scrollToComment]);

    return (
        <section className={className}>
            <ul className="list-unstyled comment-list">
                {
                    isCommentLoading
                        ? <ParagraphSkeleton rows={7}/>
                        : (
                            stageCommentsList.map(comment => (
                                <li key={comment.id}>
                                    <StageComment
                                        idPrefix={ID_PREFIXES.COMMENT}
                                        commentLevel={CommentLevel.Level1}
                                        localizer={localizer}
                                        comment={comment}
                                        deleteAttachment={(commentId, attachmentId, commentLevel, parentId) => onDeleteAttachment(commentId, attachmentId, commentLevel, parentId)}
                                        onReplyComment={onReplyComment}
                                        ideaId={ideaId}
                                        onAddFileAttachments={(commentId, attachments, commentLevel, attributes, parentId) => onAddAttachments(commentId, attachments, commentLevel, attributes, parentId)}
                                        deleteComment={onDeleteComment}
                                        annotateComment={onAnnotateComment}
                                        uploadFile={uploadFile}
                                        addAttachments={addStageCommentAttachments}
                                        commentPermissions={commentPermissions}
                                        stageCommentType={getStageCommentType()}
                                        sourceId={getSourceId()}
                                        unfurlUrl={unfurlUrl}
                                        richTextClassificationConfig={richTextClassificationConfig}
                                        classificationsMenuData={classificationsMenuData}
                                    />
                                </li>
                            ))
                        )
                }
                {
                    hasMoreComments && (
                        <li>
                            <div className="mx-4 mb-2">
                                <button
                                    className="btn btn-link text-decoration-none d-flex hide-replies px-3 align-items-center mt-3"
                                    onClick={onHasMoreCommentsClick}
                                    disabled={isCommentLoading}>
                                    <span>{localizer.msg('common.load-more')}</span>
                                    <Icon iconSpritePath={svgIconsPath} width={12} height={12}
                                          name="chevron-down"
                                          className="ms-2"/>
                                </button>
                            </div>
                        </li>
                    )
                }
            </ul>

            {
                commentOption.commentingEnabled &&
                <StageCommentForm
                    id={`${ID_PREFIXES.COMMENT_FORM}-${commentOption.commentType}`}
                    localizer={localizer}
                    actor={actor}
                    ideaId={ideaId}
                    parentId={ideaId}
                    sourceId={getSourceId()}
                    commentPermissions={commentPermissions}
                    addNewComment={onAddNewComment}
                    stageCommentType={getStageCommentType()}
                    classificationsMenuData={classificationsMenuData}
                    richTextClassificationConfig={richTextClassificationConfig}/>
            }
        </section>
    );
};