import React, {Fragment} from 'react';
import {Trans} from 'react-i18next';
import {Localizer} from '@ideascale/commons';
import {ActionType} from 'models/enums/ActionType';
import {ActivityStreamData} from 'models/ActivityStreamData';
import {ActivityLink} from '../ActivityLink';

type LabelActivityProps = {
    localizer: Localizer;
    action: ActionType.CONTRIBUTION_LABEL_ADDED | ActionType.CONTRIBUTION_LABEL_REMOVED;
    activity: ActivityStreamData;
}

const Label = (props: { name: string, colorKey: string, isPrivate: boolean }) => {
    if (props.name && props.colorKey) {
        return <span className={`label label-field-${props.colorKey}`}>{props.name}</span>;
    } else if (props.isPrivate) {
        return <>(Unauthorized/private label)</>;
    } else {
        return <i>(Label removed)</i>;
    }
};

export const LabelActivity = (props: LabelActivityProps) => {
    const {localizer, action, activity} = props;

    const isComment = !!activity.commentId;

    if (activity.label) {
        return (
            <Fragment>
                <Trans
                    i18nKey={`profile.activity-stream.label.${action === ActionType.CONTRIBUTION_LABEL_ADDED ? 'added' : 'removed'}`}
                    values={{item: isComment ? localizer.msg('profile.activity-stream.label.comment') : localizer.msg('profile.activity-stream.label.idea')}}
                >
                    <Label {...activity.label}/>
                </Trans>
                {' - '}
                <ActivityLink ideaId={activity.idea.id} title={activity.idea.title}/>
            </Fragment>
        );
    } else {
        return null;
    }
};