import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Localizer, useApiErrorResponseHandler, useHandleFormSubmit} from '@ideascale/commons';
import {HtmlRenderer} from 'components/shared/HtmlRenderer';
import {Media} from 'models/landing-page/Media';
import {MediaType} from 'models/enums/landing-page/MediaType';
import {useForm} from 'react-hook-form';
import {ActionButton} from '@ideascale/ui';

const YOUTUBE_PATTERN = RegExp('^((?:https?:)?//)?((?:www|m)\\.)?(?:youtube\\.com|youtu.be)(\\/(?:[\\w\\-]+\\?v=|embed/|v/)?)([\\w\\-]+)(\\S+)?$', 'i');
const VIMEO_PATTERN = RegExp('^(https?://)?((?:www|player)\\.)?vimeo.com/(?:channels\\/(?:\\w+\\/)?|groups\\/([^\\/]*)\\/videos\\/|video\\/)?(\\d+)', 'i');
const VIDYARD_PATTERN = RegExp('^(?:(https?)://)?(?:www\\.)?(video|share)\\.vidyard\\.com\\/watch.([a-zA-Z0-9_-]+)', 'i');
const KALTURA_PATTERN = RegExp('^(?:(https?)://)?(?:www\\.)?videos\\.kaltura\\.com\\/media\\/.*\\/([a-zA-Z0-9_-]+)', 'i');
const FLICKR_PATTERN = RegExp('^(?:https://)?(?:www\\.)?flickr\\.com/photos/([^/]+/\\d+)?', 'i');
const BRIGHTCOVE_PATTERN = RegExp('((http|https)?://)?(link.brightcove.com|bcove.me|players.brightcove.net)/([a-z0-9_-]+)([^a-z0-9_-].*)?', 'i');
const QUMUCLOUD_PATTERN = RegExp('((http|https)?://)?(?:www\\.)?((\\w+).qumucloud.com)\\/([a-z0-9_-]+)([^a-z0-9_-].*)?', 'i');

type VideoConfigFields = {
    mediaType: MediaType.VIDEO,
    videoUrl: string,
};

type MediaConfigVideoTabProps = {
    localizer: Localizer;
    config: Media;
    onConfigUpdated: () => void;
    updateConfig: (config: Media) => Promise<void>;
    configUpdated: boolean;
    onComponentEdited: () => void;
    toggle: () => void;
}

export const MediaConfigVideoTab = (props: MediaConfigVideoTabProps) => {
    const {localizer, config, onConfigUpdated, updateConfig, onComponentEdited, toggle, configUpdated} = props;
    const {formState: {errors, isSubmitting}, register, handleSubmit, setFocus} = useForm<VideoConfigFields>({
        defaultValues: {
            videoUrl: config.type === MediaType.VIDEO ? config.url : '',
            mediaType: MediaType.VIDEO
        }
    });
    const onFormSubmit = useHandleFormSubmit(handleSubmit);
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [videoTag, setVideoTag] = useState(config.type === MediaType.VIDEO ? `<a href="${config.url}"/>` || '' : '');
    const isValidVideoUrl = (url: string) => {
        return YOUTUBE_PATTERN.test(url)
            || VIMEO_PATTERN.test(url)
            || VIDYARD_PATTERN.test(url)
            || KALTURA_PATTERN.test(url)
            || FLICKR_PATTERN.test(url)
            || BRIGHTCOVE_PATTERN.test(url)
            || QUMUCLOUD_PATTERN.test(url);
    };

    const handleVideoUrlChange = useCallback((url: string) => {
        onConfigUpdated();
        if (isValidVideoUrl(url)) {
            const tagString = `<a href="${url}"/>`;
            setVideoTag(tagString);
        }
    }, [onConfigUpdated]);

    const submitConfig = async (data: VideoConfigFields) => {
        const newConfig = {...config};
        newConfig.type = MediaType.VIDEO;
        newConfig.url = data.videoUrl;

        try {
            await updateConfig(newConfig);
            onComponentEdited();
            toggle();
        } catch (e) {
            handleErrorResponse(e);
        }
    };

    useEffect(() => {
        setFocus('videoUrl');
    }, [setFocus]);

    return (
        <form id={`media-form-video-${config.id}`}
              onSubmit={onFormSubmit(submitConfig, isSubmitting || !configUpdated)}>
            <div className={`form-group ${errors.videoUrl ? 'has-error' : ''}`}>
                <label className="fw-bold" htmlFor="video-url">
                    {localizer.msg('landing-page.components.media.video-link-help')}
                    <span className="font-size-lg" aria-hidden={true}>*</span>
                </label>
                <input className="form-control" type="text" id="video-url"
                       aria-required={true}
                       autoFocus
                       placeholder={localizer.msg('landing-page.components.media.config-modal.video-link-placeholder')}
                       {...register('videoUrl', {
                           onChange: ((e) => handleVideoUrlChange(e.target.value)),
                           validate: (value) => isValidVideoUrl(value) || localizer.msg('landing-page.components.media.video-error')
                       })}
                />
                {
                    errors.videoUrl &&
                    <div className="invalid-feedback d-block">
                        {errors.videoUrl.message}
                    </div>
                }
            </div>
            {
                videoTag &&
                <Fragment>
                    <div className="fw-bold mb-2">{localizer.msg('landing-page.components.media.preview')}</div>
                    <div className="w-100 video-container">
                        {<HtmlRenderer content={videoTag} includeCookieConsent={false}/>}
                    </div>
                </Fragment>
            }
            <div className="form-group mt-5 text-end">
                <button className="btn btn-cancel me-3" type="button" onClick={toggle}>
                    {localizer.msg('common.actions.cancel')}
                </button>
                <ActionButton type="submit" form={`media-form-video-${config.id}`} disabled={!configUpdated}
                              loading={isSubmitting}>
                    {localizer.msg('common.actions.submit')}
                </ActionButton>
            </div>
        </form>
    );
};
