import React from 'react';
import {Trans} from 'react-i18next';
import {ActionType} from 'models/enums/ActionType';
import {ActivityStreamData} from 'models/ActivityStreamData';
import {Localizer} from '@ideascale/commons';
import {ActivityLink} from '../ActivityLink';

type LinkActivityProps = {
    localizer: Localizer;
    action: ActionType.LINKED_IDEA | ActionType.UNLINKED_IDEA
    activity: ActivityStreamData;
}

export const LinkActivity = (props: LinkActivityProps) => {
    const {action, activity} = props;

    const isDeleted = !activity.linkQualifier;

    const getTextKey = () => {
        if (action === ActionType.LINKED_IDEA) {
            return `profile.activity-stream.idea.linked${isDeleted ? '-deleted' : ''}`;
        } else {
            return 'profile.activity-stream.idea.unlinked';
        }
    };

    return (
        <Trans i18nKey={getTextKey()} values={{link: activity.linkQualifier}}>
            <ActivityLink title={activity.linkedIdea?.title || ''} ideaId={activity.linkedIdea?.id}/>
            <ActivityLink title={activity.idea.title} ideaId={activity.idea.id}/>
        </Trans>
    );
};