import React, {Fragment} from 'react';
import {Localizer} from '@ideascale/commons';
import {ReviewFactorRankForm} from './ReviewFactorRankForm';
import {KeyDefinitionPanel} from '../KeyDefinitionPanel';
import {ReviewscaleStageRankActivity} from 'models/stage-activity/ReviewscaleStageRankActivity';
import {FactorResponse} from 'models/stage-activity/FactorResponse';
import {findReviewScaleResponseTypeShortForm, ReviewScaleResponseType} from 'models/enums/ReviewScaleResponseType';
import {FactorType} from 'models/enums/FactorType';
import './ReviewFactorRankForm.module.scss';
import {StageActionResponse} from 'models/StageActionResponse';
import {StageActivity} from 'models/stage-activity/StageActivity';
import {BaseIdeaStageSummary} from 'models/BaseIdeaStageSummary';

type ReviewScaleRankProps = {
    localizer: Localizer;
    rankActivity: ReviewscaleStageRankActivity;
    loadActivity: (scrollToActivityHeader?: boolean) => void;
    onSaveFactorReview: (factorType: FactorType, responseType: ReviewScaleResponseType | undefined, factorId: number, note: string) => Promise<StageActionResponse<StageActivity, BaseIdeaStageSummary>>;
}

export const ReviewScaleRank = (props: ReviewScaleRankProps) => {
    const {localizer, rankActivity, onSaveFactorReview, loadActivity} = props;

    const getFactorResponse = (responses: FactorResponse[], factorId: number) => {
        return responses.find(response => response.factorId === factorId);
    };

    const showFinish = () => {
        const isAnsweredAllValueFactors = rankActivity?.valueFactorActivity ? rankActivity.valueFactorActivity.factors.length === rankActivity.valueFactorActivity.responses.length : true;
        const isAnsweredAllCostFactors = rankActivity?.costFactorActivity ? rankActivity.costFactorActivity.factors.length === rankActivity.costFactorActivity.responses.length : true;
        const isAnsweredAllConstraint = rankActivity?.constraintFactorActivity ? rankActivity.constraintFactorActivity.factors.length === rankActivity.constraintFactorActivity.responses.length : true;
        return isAnsweredAllValueFactors && isAnsweredAllCostFactors && isAnsweredAllConstraint;
    };

    return (
        <div className="reviewscale" id="reviewscale">
            {
                rankActivity?.valueFactorActivity &&
                <Fragment>
                    <p>{localizer.msg('stage.reviewscale.review-value-factor-default-description')}</p>
                    <div className="item card panel panel-default panel-custom mb-4" data-test-element-id="value-factor">
                        <div className="card-header panel-heading">
                            {localizer.msg('stage.reviewscale.review-value-factors')}
                            <hr/>
                        </div>
                        <div className="card-body panel-body">
                            <ul className="list-unstyled questions">
                                {
                                    rankActivity.valueFactorActivity.factors.map(factor => <li key={factor.id} className="mb-4">
                                            <ReviewFactorRankForm localizer={localizer}
                                                                  factor={factor}
                                                                  factorType={FactorType.VALUE}
                                                                  onChangeRankOrNote={onSaveFactorReview}
                                                                  factorResponse={getFactorResponse(rankActivity.valueFactorActivity.responses, factor.id)}/>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                    {
                        rankActivity.valueFactorActivity?.hints.length > 0 &&
                        <div className="row">
                            <div className="col-lg-6 col-sm-7">
                                <KeyDefinitionPanel heading={localizer.msg('stage.reviewscale.key')} hints={
                                    rankActivity.valueFactorActivity.hints.map(hint => ({
                                        key: findReviewScaleResponseTypeShortForm(hint.responseType),
                                        details: hint.hint
                                    }))
                                }/>
                            </div>
                        </div>
                    }
                </Fragment>
            }

            {
                rankActivity?.costFactorActivity &&
                <Fragment>
                    <p className="mt-5">{localizer.msg('stage.reviewscale.review-cost-factor-default-description')}</p>
                    <div className="item card panel panel-default panel-custom mb-4" data-test-element-id="cost-factor">
                        <div className="card-header panel-heading">
                            {localizer.msg('stage.reviewscale.review-cost-factors')}
                            <hr/>
                        </div>
                        <div className="card-body panel-body">
                            <ul className="list-unstyled questions">
                                {
                                    rankActivity.costFactorActivity.factors.map(factor => <li key={factor.id} className="mb-4">
                                            <ReviewFactorRankForm localizer={localizer}
                                                                  factor={factor}
                                                                  factorType={FactorType.COST}
                                                                  onChangeRankOrNote={onSaveFactorReview}
                                                                  factorResponse={getFactorResponse(rankActivity.costFactorActivity.responses, factor.id)}/>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                    {
                        rankActivity.costFactorActivity?.hints.length > 0 &&
                        <div className="row">
                            <div className="col-lg-6 col-sm-7">
                                <KeyDefinitionPanel heading={localizer.msg('stage.reviewscale.key')} hints={
                                    rankActivity.costFactorActivity.hints.map(hint => ({
                                        key: findReviewScaleResponseTypeShortForm(hint.responseType),
                                        details: hint.hint
                                    }))
                                }/>
                            </div>
                        </div>
                    }
                </Fragment>
            }

            {
                rankActivity?.constraintFactorActivity &&
                <Fragment>
                    <p className="mt-5">{localizer.msg('stage.reviewscale.review-constraint-factor-default-description')}</p>
                    <div className="item card panel panel-default panel-custom mb-4" data-test-element-id="constraint-factor">
                        <div className="card-header panel-heading">
                            {localizer.msg('stage.reviewscale.review-constraints')}
                            <hr/>
                        </div>
                        <div className="card-body panel-body">
                            <ul className="list-unstyled questions">
                                {
                                    rankActivity.constraintFactorActivity.factors.map(factor => <li key={factor.id} className="mb-4">
                                            <ReviewFactorRankForm localizer={localizer}
                                                                  factor={factor}
                                                                  factorType={FactorType.CONSTRAINT}
                                                                  onChangeRankOrNote={onSaveFactorReview}
                                                                  factorResponse={getFactorResponse(rankActivity.constraintFactorActivity.responses, factor.id)}/>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                    {
                        rankActivity.constraintFactorActivity?.hints.length > 0 &&
                        <div className="row">
                            <div className="col-lg-6 col-sm-7">
                                <KeyDefinitionPanel heading={localizer.msg('stage.reviewscale.key')} hints={
                                    rankActivity.constraintFactorActivity.hints.map(hint => ({
                                        key: String(hint.responseType),
                                        details: hint.hint
                                    }))
                                }/>
                            </div>
                        </div>
                    }
                </Fragment>
            }
            {
                showFinish() && <div className="row action-bar reviewscale-finish mt-2">
                    <div className="col-lg-12 text-end">
                        <button className="btn btn-primary" onClick={() => loadActivity(true)}>
                            {localizer.msg('stage.reviewscale.finish')}
                        </button>
                    </div>
                </div>
            }

        </div>
    );
};
