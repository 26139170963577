import {Type} from 'class-transformer';
import {
    Author,
    Campaign,
    ClassificationAttachmentAttribute,
    ClassificationFieldAttribute,
    FileAttachment,
    LabelData,
    Member,
    RenderFormat,
    SuperCommentData,
    SuperCommentResponse
} from '@ideascale/commons';
import {IdeaSummary} from './IdeaSummary';
import {QualifierLinkedIdeas} from './QualifierLinkedIdeas';
import {IdeateStageSummary} from './IdeateStageSummary';
import {BuildTeamStageSummary} from './BuildTeamStageSummary';
import {RefineStageSummary} from './RefineStageSummary';
import {EstimateStageSummary} from './EstimateStageSummary';
import {ReviewScaleStageSummary} from './ReviewScaleStageSummary';
import {FundStageSummary} from './FundStageSummary';
import {AssessmentStageSummary} from './AssessmentStageSummary';
import {ReserveStageSummary} from './ReserveStageSummary';
import {PendingStageSummary} from './PendingStageSummary';
import {BaseIdeaStageSummary} from './BaseIdeaStageSummary';
import {StageFunctionName} from './enums/StageFunctionName';
import {StageGroup} from './enums/StageGroup';
import {TranslationSummary} from './types/TranslationSummary';
import {IdeaFieldSection} from './IdeaFieldSection';
import {TicketData} from './TicketData';
import {IdeaModerateActionResponse} from './IdeaModerateActionResponse';
import {SharingMedia} from './SharingMedia';
import {IdeaTagHolder} from './IdeaTagHolder';
import {IdeaDetailsTab} from './enums/IdeaDetailsTab';
import {Stage} from './types/Stage';
import {CommentOptionsHolder} from './CommentsOptionHolder';

export type PermissionHolder = {
    printIdeaAllowed: boolean;
    editIdeaEnabled: boolean;
    assignOwnerAllowed: boolean;
    emailIdeaAllowed: boolean;
    emailAuthorAllowed: boolean;
    ideaAuthorFollowAllowed: boolean;
    banMemberAllowed: boolean;
    reviewTranslationUrl: string;
    reportAbuseAllowed: boolean;
    reportDuplicateAllowed: boolean;
    superCommentDeletionAllowed: boolean;
    superCommentUpdateAllowed: boolean;
    copyIdeaAllowed: boolean;
    allowedMediaList: SharingMedia[];
    labelHolder: {
        labels: LabelData[];
        stage: Stage;
    };
    moderationPermissionHolder: IdeaModerateActionResponse;
}

export class IdeaDetail extends IdeaSummary {
    public static readonly EMPTY = new IdeaDetail(0, 0, '', '', '', '', '', Campaign.EMPTY,
        [], new Author(0, '', '', '', ''), [], false, true, 0, 0, true, [], '', false, 0, true, true, true, '', false, 0, false, false,[], [], [], [],
        {translated: false, translationStatus: 0}, false,
        new IdeateStageSummary({
            id: 0,
            key: '',
            name: StageFunctionName.IDEATE,
            label: '',
            functionName: StageFunctionName.IDEATE,
            stageGroup: StageGroup.ACTIVE,
            description: ''
        }, true, true, 0, '', 0, true, true, true, 0, 0, 0),
        1, [], [], [], SuperCommentResponse.EMPTY, 0, [], IdeaDetailsTab.COMMENTS, 0, {
            printIdeaAllowed: false,
            editIdeaEnabled: false,
            assignOwnerAllowed: false,
            emailIdeaAllowed: false,
            emailAuthorAllowed: false,
            ideaAuthorFollowAllowed: false,
            banMemberAllowed: false,
            reviewTranslationUrl: '',
            reportAbuseAllowed: false,
            reportDuplicateAllowed: false,
            superCommentDeletionAllowed: false,
            superCommentUpdateAllowed: false,
            copyIdeaAllowed: false,
            allowedMediaList: [],
            labelHolder: {
                labels: [],
                stage: {
                    id: 0,
                    key: '',
                    name: '',
                    label: '',
                    description: '',
                    functionName: '',
                    stageGroup: StageGroup.COMPLETED
                }
            },
            moderationPermissionHolder: IdeaModerateActionResponse.EMPTY,
        }, IdeaTagHolder.EMPTY, {commentOptions: []}, false, '', Member.EMPTY, false, 0, '', false, false, false);

    owners: Author[];
    tickets: TicketData[];
    fieldSections: IdeaFieldSection[];
    @Type(() => QualifierLinkedIdeas)
    linkedIdeas: QualifierLinkedIdeas[];
    @Type(() => SuperCommentData)
    superCommentsHolder: SuperCommentResponse;
    revisionHistoryCount: number;
    defaultTab: IdeaDetailsTab;
    ideaViewCount: number;
    permissionHolder?: PermissionHolder;
    ideaTagHolder: IdeaTagHolder;
    @Type(() => CommentOptionsHolder)
    commentOptionsHolder: CommentOptionsHolder;
    seen: boolean;
    lastEditedAt: string;
    lastEditedBy: Member;
    followersCount: number;
    customDescriptionLabel: string;
    buildTeamViewAllowed: boolean;
    fileAttachmentAllowed: boolean;

    constructor(
        id: number,
        ideaNumber: number,
        title: string,
        description: string,
        summary: string,
        image: string,
        altText: string,
        campaign: Campaign,
        tags: string[],
        submitter: Member,
        coSubmitters: Member[],
        subscribed: boolean,
        moderate: boolean,
        commentCount: number,
        kudoCount: number,
        kudoGiven: boolean,
        labels: LabelData[],
        createdAt: string,
        publicIdea: boolean,
        ownerCount: number,
        followingIdeaAuthor: boolean,
        commentingEnabled: boolean,
        commentingAllowed: boolean,
        assignmentText: string,
        pinned: boolean,
        funnelId: number,
        labellingAllowed: boolean,
        followingEnabled: boolean,
        attachments: FileAttachment[],
        attributes: (ClassificationFieldAttribute | ClassificationAttachmentAttribute)[],
        customAttachments: FileAttachment[],
        commentAttachments: FileAttachment[],
        translation: TranslationSummary,
        draft: boolean,
        stageSummary: IdeateStageSummary | BuildTeamStageSummary | RefineStageSummary | EstimateStageSummary | ReviewScaleStageSummary | FundStageSummary | AssessmentStageSummary | ReserveStageSummary | PendingStageSummary | BaseIdeaStageSummary,
        renderFormat: RenderFormat,
        owners: Author[],
        fieldSections: IdeaFieldSection[],
        linkedIdeas: QualifierLinkedIdeas[],
        superCommentsHolder: SuperCommentResponse,
        revisionHistoryCount: number,
        tickets: TicketData[],
        defaultTab: IdeaDetailsTab,
        ideaViewCount: number,
        permissionHolder: PermissionHolder,
        ideaTagHolder: IdeaTagHolder,
        commentOptionsHolder: CommentOptionsHolder,
        seen: boolean,
        lastEditedAt: string,
        lastEditedBy: Member,
        memberActionAllowed: boolean,
        followersCount: number,
        customDescriptionLabel: string,
        buildTeamViewAllowed: boolean,
        fileAttachmentAllowed: false,
        sharable: boolean
    ) {
        super(id,
            ideaNumber,
            title,
            description,
            summary,
            image,
            altText,
            campaign,
            tags,
            submitter,
            coSubmitters,
            subscribed,
            moderate,
            commentCount,
            kudoCount,
            kudoGiven,
            labels,
            createdAt,
            publicIdea,
            ownerCount,
            followingIdeaAuthor,
            commentingEnabled,
            commentingAllowed,
            assignmentText,
            pinned,
            funnelId,
            labellingAllowed,
            followingEnabled,
            attachments,
            attributes,
            customAttachments,
            commentAttachments,
            stageSummary,
            renderFormat,
            translation,
            draft,
            memberActionAllowed,
            sharable);

        this.owners = owners;
        this.tickets = tickets;
        this.fieldSections = fieldSections;
        this.linkedIdeas = linkedIdeas;
        this.superCommentsHolder = superCommentsHolder;
        this.revisionHistoryCount = revisionHistoryCount;
        this.defaultTab = defaultTab;
        this.ideaViewCount = ideaViewCount;
        this.permissionHolder = permissionHolder;
        this.ideaTagHolder = ideaTagHolder;
        this.commentOptionsHolder = commentOptionsHolder;
        this.seen = seen;
        this.lastEditedAt = lastEditedAt;
        this.lastEditedBy = lastEditedBy;
        this.draft = draft;
        this.followersCount = followersCount;
        this.customDescriptionLabel = customDescriptionLabel;
        this.buildTeamViewAllowed = buildTeamViewAllowed;
        this.fileAttachmentAllowed = fileAttachmentAllowed;
    }
}