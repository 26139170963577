import {Operation} from './Operation';
import {CommunityOperationType} from './CommunityOperationType';
import {SidebarItem} from '../sidebar/SidebarItem';

export class ChangeCommunitySidebarOrderOperation implements Operation {
    constructor(public type: CommunityOperationType,
                public sidebarItems: SidebarItem[]) {
    }

    getType(): CommunityOperationType {
        return this.type;
    }
}