import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import range from 'lodash/range';
import {ParagraphSkeleton} from '@ideascale/ui';
import {AlertEvent, AlertType, buildAlertEventData, eventDispatcher, Member, useToggle} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {useIdeaUpdater} from 'hooks/useIdeaUpdater';
import {useCommunityService} from 'hooks/useService';
import {useIdeasContext} from 'contexts/IdeasContext';
import {useFileUploadService} from 'hooks/useFileUploadService';
import {useClassificationService} from 'hooks/useClassificationService';
import {useRefetchIdeaDetails} from 'hooks/useRefetchIdeaDetails';
import {useMessageService} from 'hooks/useMessageService';
import {useAppContext} from "contexts/AppContext";
import {useIdeaSubmissionService} from "hooks/useIdeaSubmissionService";
import {IdeateActivity} from 'components/stage-activities/IdeateActivity';
import {AssessmentActivity} from 'components/stage-activities/AssessmentActivity';
import {EstimateActivity} from 'components/stage-activities/EstimateActivity';
import {RefineActivity} from 'components/stage-activities/RefineActivity';
import {ReviewScaleActivity} from 'components/stage-activities/ReviewScaleActivity';
import {ActivityHeaderSummary} from 'components/ActivityHeaderSummary';
import {BasicActivity} from 'components/stage-activities/BasicActivity';
import {LabelChangeActivity} from 'components/stage-activities/LabelChangeActivity';
import {SubmittedActivity} from 'components/stage-activities/SubmittedActivity';
import {AuthorChangeStageActivity} from 'components/stage-activities/AuthorChangeStageActivity';
import {CampaignChangeStageActivity} from 'components/stage-activities/CampaignChangeStageActivity';
import {BuildTeamActivity} from 'components/stage-activities/BuildTeamActivity';
import {FundActivity} from 'components/stage-activities/FundActivity';
import {TicketActivity} from 'components/stage-activities/TicketActivity';
import {StageFunctionName} from 'models/enums/StageFunctionName';
import {StageActivitySummary} from 'models/StageActivitySummary';
import {IdeaActivities} from 'models/IdeaActivities';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {RevisionHistory} from 'models/RevisionHistory';
import {StageActivity} from 'models/stage-activity/StageActivity';
import {FundStageActivity} from 'models/FundStageActivity';
import {StageActionResponse} from 'models/StageActionResponse';
import {TeamRoleData} from 'models/TeamRoleData';
import {BuildTeamStageActivity} from 'models/BuildTeamStageActivity';
import {BuildTeamStageSummary} from 'models/BuildTeamStageSummary';
import {FundStageSummary} from 'models/FundStageSummary';
import {RefineStageActivity} from 'models/stage-activity/RefineStageActivity';
import {RefineStageSummary} from 'models/RefineStageSummary';
import {StageFieldValueParameters} from 'models/types/StageFieldValueParameters';
import {BaseIdeaStageSummary} from 'models/BaseIdeaStageSummary';
import {ReviewNotesResponse} from 'models/stage-activity/ReviewNotesResponse';
import {FactorType} from 'models/enums/FactorType';
import {ReviewScaleResponseType} from 'models/enums/ReviewScaleResponseType';
import {SaveEstimateParameters} from 'models/types/SaveEstimateParameters';
import {EstimateStageActivity} from 'models/stage-activity/EstimateStageActivity';
import {EstimateStageSummary} from 'models/EstimateStageSummary';
import {AssessmentStageRatingActivity} from 'models/stage-activity/AssessmentStageRatingActivity';
import {AssessmentRatingParameters} from 'models/types/AssessmentRatingParameters';
import {AssessmentStageActivity} from 'models/stage-activity/AssessmentStageActivity';
import {AssessmentStageSummary} from 'models/AssessmentStageSummary';

type IdeaActivityContainerProps = {
    ideaId: number;
    stageId: number;
    campaignId?: number;
}

export const IdeaActivityContainer = (props: IdeaActivityContainerProps) => {
    const {ideaId, stageId, campaignId} = props;
    const localizer = useLocalizer();
    const {communityConfig: {atMentionEnabled}} = useAppContext();
    const communityService = useCommunityService();
    const {fetchAtMentionsRefineCustomField} = useIdeaSubmissionService();
    const {tempImageUpload, uploadFile} = useFileUploadService();
    const [ideaActivitySummary, setIdeaActivitySummary] = useState<IdeaActivities>(IdeaActivities.Empty);
    const {ideaListFilterQueryKey} = useIdeasContext();
    const {updateIdeaDetails, updateIdea} = useIdeaUpdater();
    const {refetchIdeaDetails} = useRefetchIdeaDetails();
    const currentStageIdRef = useRef<number | null>(0);
    const {fetchRecipients} = useMessageService();
    const {fetchClassifications} = useClassificationService();
    const [loading, setLoading] = useToggle(true);

    const updateIdeaStageSummary = useCallback(<T extends BaseIdeaStageSummary>(stageSummary: T) => {
        updateIdeaDetails(ideaId, 'stageSummary', stageSummary);
        updateIdea(ideaListFilterQueryKey, ideaId, 'stageSummary', stageSummary);
    }, [ideaId, ideaListFilterQueryKey, updateIdea, updateIdeaDetails]);

    const fetchStageActivity = useCallback(async (stageId: number) => {
        if (communityService !== null) {
            return await communityService.fetchIdeaStageActivity(ideaId, stageId);
        }
        return StageActivity.EMPTY;
    }, [communityService, ideaId]);

    const fetchReviewNotes = useCallback(async (stageId: number) => {
        if (communityService !== null) {
            return await communityService.fetchReviewNotes(ideaId, stageId);
        }
        return ReviewNotesResponse.EMPTY;
    }, [communityService, ideaId]);

    const getReviewScaleEditRank = useCallback(async (stageId: number) => {
        if (communityService !== null) {
            return await communityService.getEditRank(ideaId, stageId);
        }
        return StageActivity.EMPTY;
    }, [communityService, ideaId]);

    const saveFactorReview = useCallback(async (stageId: number, factorType: FactorType, responseType: ReviewScaleResponseType | undefined, factorId: number, note: string) => {
        if (communityService !== null) {
            const response = await communityService.saveFactorReview(ideaId, stageId, factorType, responseType, factorId, note);
            if (response.stageChanged) {
                refetchIdeaDetails(ideaId);
            }
            return response;
        }
        return StageActionResponse.EMPTY;
    }, [communityService, ideaId, refetchIdeaDetails]);

    const fetchStageFieldRevisionHistory = useCallback(async (fieldId: number, pageParameters: PageParameters) => {
        if (communityService) {
            return await communityService.fetchStageFieldRevisionHistory(ideaId, fieldId, pageParameters);
        }
        return PagedResponseContent.nullObject<RevisionHistory>();
    }, [communityService, ideaId]);

    const saveRefineStageField = useCallback(async (fieldId: number, fieldParameters: StageFieldValueParameters) => {
        if (communityService) {
            const response = await communityService.saveRefineStageField(ideaId, fieldId, fieldParameters);
            if (response.stageChanged) {
                refetchIdeaDetails(ideaId);
            }
            return response;
        }
        return StageActionResponse.EMPTY as StageActionResponse<RefineStageActivity, RefineStageSummary>;
    }, [communityService, ideaId, refetchIdeaDetails]);

    const fetchFund = useCallback(async (ideaId: number, stageId: number): Promise<FundStageActivity> => {
        if (communityService !== null) {
            return await communityService.fetchFund(ideaId, stageId);
        }
        return FundStageActivity.EMPTY;
    }, [communityService]);

    const editFundPledge = useCallback(async (ideaId: number, stageId: number): Promise<FundStageActivity> => {
        if (communityService !== null) {
            return await communityService.editFundPledge(ideaId, stageId);
        }
        return FundStageActivity.EMPTY;
    }, [communityService]);

    const fundTarget = useCallback(async (ideaId: number, amount: number) => {
        if (communityService !== null) {
            return await communityService.modifyFundTarget(ideaId, amount);
        }
        return StageActionResponse.EMPTY as StageActionResponse<FundStageActivity, FundStageSummary>;
    }, [communityService]);

    const modifyFundPledge = useCallback(async (ideaId: number, amount: number) => {
        if (communityService !== null) {
            const response = await communityService.modifyFundPledge(ideaId, amount);
            if (response.stageChanged) {
                refetchIdeaDetails(ideaId);
            }
            return response;
        }
        return StageActionResponse.EMPTY as StageActionResponse<FundStageActivity, FundStageSummary>;
    }, [communityService, refetchIdeaDetails]);

    const fetchBuildTeamData = useCallback(async (ideaId: number, stageId: number): Promise<BuildTeamStageActivity> => {
        if (communityService !== null) {
            return await communityService.fetchBuildTeamData(ideaId, stageId);
        }
        return StageActivity.EMPTY as BuildTeamStageActivity;
    }, [communityService]);

    const fetchTeamRole = useCallback(async (): Promise<Array<TeamRoleData>> => {
        if (communityService !== null) {
            return await communityService.fetchTeamRole();
        }
        return [];
    }, [communityService]);

    const assignTeamRole = useCallback(async (ideaId: number, stageId: number, positionId: number, teamRoleId: number): Promise<StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>> => {
        if (communityService !== null) {
            try {
                const response = await communityService.assignTeamRole(ideaId, stageId, positionId, teamRoleId);
                if (response.stageChanged) {
                    refetchIdeaDetails(ideaId);
                }
                return response;
            } catch (e: any) {
                const {message} = (e as Error) || {};
                if (message) {
                    eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.error, message));
                }
            }
        }
        return StageActionResponse.EMPTY as StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>;
    }, [communityService, refetchIdeaDetails]);

    const joinBuildTeam = useCallback(async (ideaId: number, stageId: number, positionId: number) => {
        if (communityService !== null) {
            const response = await communityService.joinBuildTeam(ideaId, stageId, positionId);
            if (response.stageChanged || !response?.stageSummary) {
                refetchIdeaDetails(ideaId);
            }
            return response;
        }
        return StageActionResponse.EMPTY as StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>;
    }, [communityService, refetchIdeaDetails]);

    const assignMemberBuildTeam = useCallback(async (ideaId: number, stageId: number, positionId: number, memberId: number) => {
        if (communityService !== null) {
            const response = await communityService.assignMemberBuildTeam(ideaId, stageId, positionId, memberId);
            if (response.stageChanged) {
                refetchIdeaDetails(ideaId);
            }
            return response;

        }
        return StageActionResponse.EMPTY as StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>;
    }, [communityService, refetchIdeaDetails]);

    const unassignMemberBuildTeam = useCallback(async (ideaId: number, stageId: number, memberId: number) => {
        if (communityService !== null) {
            const response = await communityService.unassignMemberBuildTeam(ideaId, stageId, memberId);
            if (!response?.stageSummary) {
                refetchIdeaDetails(ideaId);
            }
            return response;
        }
        return StageActionResponse.EMPTY as StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>;
    }, [communityService, refetchIdeaDetails]);

    const promoteToOwnerBuildTeam = useCallback(async (ideaId: number, stageId: number, memberId: number) => {
        if (communityService !== null) {
            return await communityService.promoteToOwnerBuildTeam(ideaId, stageId, memberId);
        }
        return StageActionResponse.EMPTY as StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>;
    }, [communityService]);

    const addPositionBuildTeam = useCallback(async (ideaId: number, stageId: number, buildTeamGroupId: number) => {
        if (communityService !== null) {
            return await communityService.addPositionBuildTeam(ideaId, stageId, buildTeamGroupId);
        }
        return StageActionResponse.EMPTY as StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>;
    }, [communityService]);

    const removePositionBuildTeam = useCallback(async (ideaId: number, stageId: number, positionId: number) => {
        if (communityService !== null) {
            const response = await communityService.removePositionBuildTeam(ideaId, stageId, positionId);
            if (response.stageChanged) {
                refetchIdeaDetails(ideaId);
            }
            return response;
        }
        return StageActionResponse.EMPTY as StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>;
    }, [communityService, refetchIdeaDetails]);

    const fetchTeamMember = useCallback(async (ideaId: number, stageId: number, pageParameters: PageParameters): Promise<PagedResponseContent<Member>> => {
        if (communityService !== null) {
            return await communityService.fetchTeamMember(ideaId, stageId, pageParameters);
        }
        return PagedResponseContent.nullObject<Member>();
    }, [communityService]);

    const saveEstimate = useCallback(async (stageId: number, estimateData: SaveEstimateParameters) => {
        if (communityService !== null) {
            const response = await communityService.saveEstimate(ideaId, stageId, estimateData);
            if (response.stageChanged) {
                refetchIdeaDetails(ideaId);
            }
            return response;

        }
        return StageActionResponse.EMPTY as StageActionResponse<EstimateStageActivity, EstimateStageSummary>;
    }, [communityService, ideaId, refetchIdeaDetails]);

    const fetchAssessmentStageRankActivity = useCallback(async (stageId: number) => {
        if (communityService !== null) {
            return await communityService.fetchAssessmentStageRankActivity(ideaId, stageId);
        }
        return AssessmentStageRatingActivity.EMPTY;
    }, [communityService, ideaId]);

    const saveAssessmentRank = useCallback(async (assessmentId: number, questionId: number, parameters: AssessmentRatingParameters) => {
        if (communityService !== null) {
            const response = await communityService.saveAssessmentRank(ideaId, assessmentId, questionId, parameters);
            if (response.stageChanged) {
                refetchIdeaDetails(ideaId);
            }
            return response;
        }
        return StageActionResponse.EMPTY as StageActionResponse<AssessmentStageActivity, AssessmentStageSummary>;
    }, [communityService, ideaId, refetchIdeaDetails]);

    const isBasicNonInteractiveStage = () => {
        return ideaActivitySummary.basicStage || ideaActivitySummary.currentStage.name.toLowerCase() === StageFunctionName.RESERVE;
    };

    const renderHeaderTitle = (stageActivity: StageActivitySummary) => {
        if (ideaActivitySummary.basicStage) {
            return stageActivity.campaignName ? `: ${stageActivity.campaignName}` : '';
        } else {
            return stageActivity.campaignName ? `${stageActivity.campaignName} : ${stageActivity.newStage.label}` : stageActivity.newStage.label;
        }
    };

    const renderStageActivity = (stageActivity: StageActivitySummary, isCurrentStage: boolean) => {
        switch (stageActivity.newStage.name.toLowerCase()) {
            case StageFunctionName.ASSESSMENT:
                return <AssessmentActivity key={`idea-${ideaId}-stage-${stageId}-assessment`}
                                           stageId={stageActivity.newStage.id}
                                           fetchActivity={fetchStageActivity}
                                           localizer={localizer}
                                           isCurrentStage={isCurrentStage}
                                           stageLabel={stageActivity.newStage.label}
                                           stageDescription={stageActivity.newStage.description}
                                           fetchAssessmentStageRankActivity={fetchAssessmentStageRankActivity}
                                           saveAssessmentRank={saveAssessmentRank}
                                           updateIdeaStageSummary={updateIdeaStageSummary}
                                           headerSummary={<ActivityHeaderSummary localizer={localizer}
                                                                                 stageActivity={stageActivity}
                                                                                 author={ideaActivitySummary.ideaAuthor}/>}
                                           headerTitle={renderHeaderTitle(stageActivity)}/>;
            case StageFunctionName.BUILD_TEAM:
                return <BuildTeamActivity key={`idea-${ideaId}-stage-${stageId}-build-team`}
                                          stageId={stageActivity.newStage.id}
                                          ideaId={ideaId}
                                          updateIdeaStageSummary={updateIdeaStageSummary}
                                          fetchBuildTeamData={fetchBuildTeamData}
                                          fetchActivity={fetchStageActivity}
                                          fetchTeamRole={fetchTeamRole}
                                          assignTeamRole={assignTeamRole}
                                          fetchRecipients={fetchRecipients}
                                          joinBuildTeam={joinBuildTeam}
                                          assignMemberBuildTeam={assignMemberBuildTeam}
                                          fetchTeamMember={fetchTeamMember}
                                          unassignMemberBuildTeam={unassignMemberBuildTeam}
                                          promoteToOwnerBuildTeam={promoteToOwnerBuildTeam}
                                          addPositionBuildTeam={addPositionBuildTeam}
                                          removePositionBuildTeam={removePositionBuildTeam}
                                          localizer={localizer} isCurrentStage={isCurrentStage}
                                          fetchClassifications={fetchClassifications}
                                          headerSummary={
                                              <ActivityHeaderSummary localizer={localizer}
                                                                     stageActivity={stageActivity}
                                                                     author={ideaActivitySummary.ideaAuthor}/>
                                          }
                                          headerTitle={renderHeaderTitle(stageActivity)}/>;
            case StageFunctionName.ESTIMATE:
                return <EstimateActivity key={`idea-${ideaId}-stage-${stageId}-estimate`}
                                         stageId={stageActivity.newStage.id}
                                         fetchActivity={fetchStageActivity}
                                         localizer={localizer}
                                         isCurrentStage={isCurrentStage}
                                         headerSummary={<ActivityHeaderSummary localizer={localizer}
                                                                               stageActivity={stageActivity}
                                                                               author={ideaActivitySummary.ideaAuthor}/>}
                                         headerTitle={renderHeaderTitle(stageActivity)}
                                         saveEstimate={saveEstimate}
                                         updateIdeaStageSummary={updateIdeaStageSummary}/>;
            case StageFunctionName.FUND:
                return <FundActivity key={`idea-${ideaId}-stage-${stageId}-fund`}
                                     stageId={stageActivity.newStage.id}
                                     fetchActivity={fetchStageActivity}
                                     editFundPledge={editFundPledge}
                                     ideaId={ideaId} fetchFund={fetchFund} fundTarget={fundTarget}
                                     modifyFundPledge={modifyFundPledge} localizer={localizer}
                                     isCurrentStage={isCurrentStage}
                                     updateIdeaStageSummary={updateIdeaStageSummary}
                                     headerSummary={<ActivityHeaderSummary localizer={localizer}
                                                                           stageActivity={stageActivity}
                                                                           author={ideaActivitySummary.ideaAuthor}/>}
                                     headerTitle={renderHeaderTitle(stageActivity)}/>;
            case StageFunctionName.REFINE:
                return <RefineActivity key={`idea-${ideaId}-stage-${stageId}-refine`}
                                       stageId={stageActivity.newStage.id}
                                       fetchActivity={fetchStageActivity}
                                       localizer={localizer}
                                       isCurrentStage={isCurrentStage}
                                       headerSummary={<ActivityHeaderSummary localizer={localizer}
                                                                             stageActivity={stageActivity}
                                                                             author={ideaActivitySummary.ideaAuthor}/>}
                                       saveRefineStageField={saveRefineStageField}
                                       fetchRevisionHistory={fetchStageFieldRevisionHistory}
                                       updateIdeaStageSummary={updateIdeaStageSummary}
                                       uploadFile={uploadFile}
                                       headerTitle={renderHeaderTitle(stageActivity)}
                                       tempImageUpload={tempImageUpload}
                                       campaignId={campaignId}
                                       ideaId={ideaId}
                                       atMentionEnabled={atMentionEnabled}
                                       fetchAtMentionsRefineCustomField={fetchAtMentionsRefineCustomField}/>;
            case StageFunctionName.REVIEW_SCALE:
                return <ReviewScaleActivity key={`idea-${ideaId}-stage-${stageId}-review`}
                                            fetchActivity={fetchStageActivity}
                                            fetchNotes={fetchReviewNotes}
                                            getEditRank={getReviewScaleEditRank}
                                            stageId={stageActivity.newStage.id}
                                            updateIdeaStageSummary={updateIdeaStageSummary}
                                            localizer={localizer}
                                            saveFactorReview={saveFactorReview}
                                            isCurrentStage={isCurrentStage}
                                            headerSummary={<ActivityHeaderSummary localizer={localizer}
                                                                                  stageActivity={stageActivity}
                                                                                  author={ideaActivitySummary.ideaAuthor}/>}
                                            headerTitle={renderHeaderTitle(stageActivity)}/>;
            case StageFunctionName.IDEATE:
                return <IdeateActivity key={`idea-${ideaId}-stage-${stageId}-ideate`}
                                       stageId={stageActivity.newStage.id}
                                       fetchActivity={fetchStageActivity}
                                       localizer={localizer}
                                       isCurrentStage={isCurrentStage}
                                       headerSummary={<ActivityHeaderSummary localizer={localizer}
                                                                             stageActivity={stageActivity}
                                                                             author={ideaActivitySummary.ideaAuthor}/>}
                                       headerTitle={renderHeaderTitle(stageActivity)}/>;
            default:
                return <Fragment/>;
        }
    };

    const renderSkeleton = () => {
        return range(0, 3).map(index =>
            <div className="panel card panel-default mb-3" key={index}>
                <div className="card-body pb-2">
                    <ParagraphSkeleton rows={3}/>
                </div>
            </div>);
    };

    const loadIdeaActivities = useCallback(async (ideaId: number) => {
        if (communityService !== null) {
            try {
                const response = await communityService.fetchIdeaActivities(ideaId);
                setIdeaActivitySummary(response);
                setLoading(false);
            } catch (e: any) {
                const {message} = (e as Error) || {};
                if (message) {
                    eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.error, message));
                }
            }
        }
    }, [communityService, setLoading]);

    useEffect(() => {
        if (stageId !== currentStageIdRef.current) {
            currentStageIdRef.current = stageId;
            loadIdeaActivities(ideaId).then();
        }
        return () => {
            currentStageIdRef.current = null;
        };
    }, [ideaId, loadIdeaActivities, stageId]);

    return (
        <section className="activity-container rounded-bottom">
            {
                loading
                    ?
                    renderSkeleton()
                    :
                    <>
                        <>
                            {
                                isBasicNonInteractiveStage() ?
                                    <BasicActivity localizer={localizer}
                                                   activitySummary={ideaActivitySummary.currentStageActivity}
                                                   author={ideaActivitySummary.ideaAuthor}
                                                   headerTitle={ideaActivitySummary.currentStage.label ? ideaActivitySummary.currentStage.label : ideaActivitySummary.currentStageActivity.newStage.label}/>
                                    :
                                    renderStageActivity(ideaActivitySummary.currentStageActivity, true)
                            }
                            {
                                ideaActivitySummary.currentStageActivity.campaignChangeActivity &&
                                <CampaignChangeStageActivity localizer={localizer}
                                                             campaignChangeActivity={ideaActivitySummary.currentStageActivity.campaignChangeActivity}/>
                            }
                        </>
                        {
                            ideaActivitySummary.closedStageActivities.map((activitySummary, index) => (
                                <Fragment key={`${activitySummary.newStage.id}_${index}`}>
                                    {
                                        ideaActivitySummary.basicStage ?
                                            <BasicActivity localizer={localizer} activitySummary={activitySummary}
                                                           author={ideaActivitySummary.ideaAuthor}
                                                           headerTitle={renderHeaderTitle(activitySummary)}/>
                                            :
                                            renderStageActivity(activitySummary, false)
                                    }
                                    {
                                        activitySummary.campaignChangeActivity &&
                                        <CampaignChangeStageActivity localizer={localizer}
                                                                     campaignChangeActivity={activitySummary.campaignChangeActivity}/>
                                    }
                                </Fragment>
                            ))
                        }
                        {
                            ideaActivitySummary.labelActivities && ideaActivitySummary.labelActivities.length > 0 &&
                            <LabelChangeActivity localizer={localizer}
                                                 labelChangeActivity={ideaActivitySummary.labelActivities}/>
                        }

                        {
                            ideaActivitySummary.tickets.length > 0 &&
                            <TicketActivity localizer={localizer} ticketActivity={ideaActivitySummary.tickets}/>
                        }

                        {
                            ideaActivitySummary.authorChangeActivity &&
                            <AuthorChangeStageActivity localizer={localizer}
                                                       authorChangeActivity={ideaActivitySummary.authorChangeActivity}/>
                        }
                    </>

            }
            <SubmittedActivity localizer={localizer} ideaAuthor={ideaActivitySummary.ideaAuthor}
                               ideaSubmittedAt={ideaActivitySummary.ideaSubmittedAt}/>
        </section>
    );
};
