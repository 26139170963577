import React, {Fragment} from 'react';
import {Trans} from 'react-i18next';
import {Switch} from '@ideascale/ui';
import {IdeaFieldData, Localizer, useHookFormContext} from '@ideascale/commons';
import {helpLinks} from 'constants/AppConstants';

type HideAuthorFieldProps = {
    localizer: Localizer;
    ideaField: IdeaFieldData;
    helpSiteBaseUrl: string;
}

export const HideAuthorField = (props: HideAuthorFieldProps) => {
    const {localizer, ideaField, helpSiteBaseUrl} = props;
    const {register} = useHookFormContext();

    return (
        <Fragment>
            <div className="form-group pb-3 border-bottom-1" key={ideaField.key}>
                <Switch offText="OFF" onText="ON"
                        inputId={ideaField.key}
                        defaultChecked={Boolean(ideaField.value)}
                        label={
                            <Fragment>
                                <strong>{ideaField.label}</strong>
                            </Fragment>
                        }
                        {...register(ideaField.key)}/>
                <p className="text-muted mt-3">
                    <Trans i18nKey="idea.fields.hide-author-field.anonymous-warning">
                        <a className="fw-bold" href={helpLinks.anonymousBestPracticeUrl(helpSiteBaseUrl)}
                           title={localizer.msg('idea.fields.hide-author-field.anonymous-activity-help')}
                           target="_blank" rel="noopener noreferrer">Anonymity & Hidden Identities</a>
                    </Trans>
                </p>
            </div>
        </Fragment>
    );
};