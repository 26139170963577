import {BaseIdeaStageSummary} from './BaseIdeaStageSummary';
import {StageSummaryData} from './types/StageSummaryData';

export class ReviewScaleStageSummary extends BaseIdeaStageSummary {
    constructor(
        public stage: StageSummaryData,
        public viewAllowed: boolean,
        public participationAllowed: boolean,
        public percentage: number,
        public callToActionLabel: string,
        public reviewCompletedCount: number,
        public reviewMemberCount: number,
        public roiScore: number,
        public valueScoreTotal: number,
        public costScoreTotal: number,
    ) {
        super(stage, viewAllowed, participationAllowed, percentage, callToActionLabel);
    }

    getIconName(): string {
        return 'bar-graph-solid-box';
    }
}