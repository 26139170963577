import React from 'react';
import './KeyDefinitionPanel.scss';

export type hint = {
    key: string | React.ReactNode,
    details: string
}

type KeyDefinitionPanelProps = {
    heading: string;
    hints: hint[];
    panelBodyClass?: string;
}

export const KeyDefinitionPanel = (props: KeyDefinitionPanelProps) => {
    const {heading, hints, panelBodyClass = 'reviewscale-rating-dfn'} = props;
    return (

        <div className="card panel panel-custom panel-key mb-0">
            <div className="card-header panel-heading">
                {
                    heading
                }
                <hr/>
            </div>
            <div className={`panel-body card-body font-size-normal ${panelBodyClass}`}>
                <ul className="list-unstyled rating-definition">
                    {
                        hints.map((hint, index) => <li key={index}>
                            <dfn
                                className={`text-review-${(typeof hint.key === 'string') ? hint.key.toLowerCase() : index} text-truncate`}
                                title={hint.details}>
                                {
                                    (typeof hint.key === 'string') &&
                                    <span className={`dot me-2 review-${hint.key.toLowerCase()}`}/>
                                }
                                {
                                    hint.key
                                }
                            </dfn>
                            <span>
                                {
                                    hint.details
                                }
                            </span>
                        </li>)
                    }
                </ul>
            </div>
        </div>

    );
};