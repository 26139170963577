export enum ActivitySummaryType {
    SCORE = 'SCORE',
    IDEA = 'IDEA',
    COMMENT = 'COMMENT',
    VOTE = 'VOTE',
    ENGAGEMENT_ACTION = 'ENGAGEMENT_ACTION',
    IDEA_VELOCITY = 'IDEA_VELOCITY',
    MODERATOR_ACTIVITIES = 'MODERATOR_ACTIVITIES',
    MODERATOR_ACTIVITIES_PER_IDEA = 'MODERATOR_ACTIVITIES_PER_IDEA',
    IDEA_VIEW = 'IDEA_VIEW',
    LOGIN = 'LOGIN',
    ENGAGED_USER = 'ENGAGED_USER',
    SELECTED = 'SELECTED',
    IMPLEMENTED = 'IMPLEMENTED',
}