import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {ActionButton, Button} from '@ideascale/ui';
import {
    ErrorReason,
    isHtmlInject,
    isJavascriptInject,
    isKeyExists,
    Localizer,
    useApiErrorResponseHandler,
    useToggle
} from '@ideascale/commons';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useEditModeFileOperationService} from 'hooks/useEditModeFileOperationService';
import {useEditModeFormErrorHandler} from 'hooks/useEditModeFormErrorHandler';
import {FileUpload} from './advanced-settings/FileUpload';
import {ErrorMessage} from './advanced-settings/ErrorMessage';
import {LicenseUpgradeInfo} from './LicenseUpgradeInfo';
import {UpdateCommunityTileCommand} from 'commands/edit-mode/UpdateCommunityTileCommand';
import {InfoMessage} from './advanced-settings/InfoMessage';
import {IMAGE_TYPE_WITHOUT_GIF_REGEX} from 'constants/AppConstants';
import {ImageField, ImageFieldBuilder} from 'models/edit-mode/ImageField';
import {CommunityEditableFieldType} from 'models/edit-mode/CommunityEditableFieldType';
import {UploadFileRequest} from 'models/edit-mode/UploadFileRequest';
import {ImageType} from 'models/enums/ImageType';
import {PermissionType} from 'models/edit-mode/ResourcePermission';
import {CommunityOperationType} from 'models/edit-mode/CommunityOperationType';
import {ImageUploadSource} from 'models/enums/ImageUploadSource';

type CommunityTileImageProps = {
    localizer: Localizer;
    maxFileSizeLimit: number;
    toggle: () => void;
}

export const CommunityTileImage = (props: CommunityTileImageProps) => {
    const {localizer, maxFileSizeLimit, toggle} = props;
    const {homeConfig, communityHomeEditor, commandExecutor, validationErrors} = useEditModeContext();
    const [tileImage, setTileImage] = useState<ImageField>(ImageField.EMPTY);
    const [showAltText, setShowAltText] = useToggle(false);
    const [uploadedImageType, setUploadedImageType] = useState<ImageUploadSource | undefined>(undefined);
    const {communityUploadLibraryFiles} = useEditModeFileOperationService();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [fileValidationErrors, setFileValidationErrors] = useState<Record<string, string>>({});
    const [fileUploading, setFileUploadingStatus] = useState(false);
    const {fieldError} = useEditModeFormErrorHandler({
        localizer,
        validationErrors: validationErrors.findError(CommunityOperationType.CHANGE_COMMUNITY_TILE)
    });

    const updateTileImage = useCallback(() => {
        const command = new UpdateCommunityTileCommand(communityHomeEditor, {
            ...tileImage,
            defaultImage: tileImage.imageType === ImageType.UPLOADED && tileImage.fileId === 0
        });
        commandExecutor.execute(command);
        validationErrors.clearError(command.getType());
    }, [commandExecutor, communityHomeEditor, tileImage, validationErrors]);


    const onClick = useCallback(async () => {
        setFileValidationErrors({});
        try {
            if (tileImage.fileId <= 0 && tileImage.filename?.length) {
                const uploadFileRequest = new UploadFileRequest([]);
                uploadFileRequest.files.push({filename: tileImage.filename, altText: tileImage.altText ?? ''});

                const response = await communityUploadLibraryFiles(uploadFileRequest);
                const uploadedFile = response.find(uploadedResponse => uploadedResponse.filename === tileImage.filename)?.file || {
                    id: 0,
                    url: ''
                };
                tileImage.fileId = uploadedFile.id;
                tileImage.url = uploadedFile.url;
            }
            toggle();
        } catch (e: any) {
            if (e?.data?.validationErrors) {
                setFileValidationErrors(e?.data?.validationErrors);
            } else {
                handleErrorResponse(e);
            }
        }
        updateTileImage();
    }, [handleErrorResponse, tileImage, toggle, updateTileImage, communityUploadLibraryFiles]);

    const isFormDirty = () => {
        return tileImage !== homeConfig.getBannerField(CommunityEditableFieldType.COMMUNITY_TILE_IMAGE);
    };

    useEffect(() => {
        setTileImage(homeConfig.getImageField(CommunityEditableFieldType.COMMUNITY_TILE_IMAGE));
    }, [homeConfig]);

    const hasErrorInAltText = () => {
        const hasInjectedAltText = tileImage.altText ? (isHtmlInject(tileImage.altText) || isJavascriptInject(tileImage.altText)) : false;
        const hasFileValidationErrors = Object.keys(fileValidationErrors).length > 0;
        return hasInjectedAltText || hasFileValidationErrors;
    };

    return (
        <Fragment>
            <div className="d-flex align-items-center">
                <strong
                    className={`${validationErrors.findError(CommunityOperationType.CHANGE_COMMUNITY_TILE) ? 'has-error' : ''}`}>
                    {localizer.msg('edit-mode.community.tile-image')}
                </strong>
                {
                    homeConfig.getImageField(CommunityEditableFieldType.COMMUNITY_TILE_IMAGE).fileUploadPermission !== PermissionType.ALLOWED
                        ? (
                            <LicenseUpgradeInfo
                                triggerButtonId="campaign-logo-title-license-popover"
                                className="ms-2"
                                localizer={localizer}
                            />
                        )
                        : null
                }
            </div>
            <ErrorMessage message={fieldError('fileId')?.message}/>
            {
                (homeConfig.getImageField(CommunityEditableFieldType.COMMUNITY_TILE_IMAGE).fileUploadPermission === PermissionType.ALLOWED) && (fieldError('fileId')?.reason !== ErrorReason.FEATURE_IS_NOT_ACCESSIBLE)
                    ?
                    (
                        <Fragment>
                            <FileUpload previewMode="full"
                                        localizer={localizer}
                                        maxFileSizeLimit={maxFileSizeLimit}
                                        fileValidation={IMAGE_TYPE_WITHOUT_GIF_REGEX}
                                        imageUrl={!tileImage.defaultImage ? tileImage.url : undefined}
                                        uploadedType={uploadedImageType}
                                        cropWidth={850}
                                        cropHeight={165}
                                        showAltText={showAltText}
                                        errorReason={fileValidationErrors[`files[0].altText`]}
                                        onUploadedSuccessfulImage={(image, type) => {
                                            const newImage = new ImageFieldBuilder()
                                                .name(CommunityEditableFieldType.COMMUNITY_TILE_IMAGE)
                                                .fileId(image.fileId)
                                                .filename(image.filename)
                                                .url(image.url)
                                                .altText(image.altText ?? '')
                                                .defaultImage(false)
                                                .imageType(ImageType.UPLOADED)
                                                .build();

                                            setTileImage(prev => ({
                                                ...newImage,
                                                fileUploadPermission: prev.fileUploadPermission
                                            }));
                                            setShowAltText(true);
                                            setUploadedImageType(type);
                                        }}
                                        updateFileUploadingStatus={setFileUploadingStatus}
                            />
                            {(!isKeyExists(tileImage, 'fileId') || tileImage.url?.length === 0 || tileImage.defaultImage) &&
                                <InfoMessage localizer={localizer}/>}
                        </Fragment>
                    )
                    : null
            }

            <div className="action-bar mt-4 mb-3 p-0 text-end">
                <Button className="btn btn-cancel" color="cancel" aria-label="Close modal" onClick={toggle}
                        data-test-element-id="cancel">
                    {localizer.msg('common.actions.cancel')}
                </Button>
                <ActionButton
                    className="btn btn-primary btn-lg"
                    type="button"
                    loading={fileUploading}
                    disabled={!isFormDirty() || tileImage.defaultImage || (fieldError('fileId')?.reason === ErrorReason.FEATURE_IS_NOT_ACCESSIBLE) || hasErrorInAltText()}
                    onClick={onClick}
                    data-test-element-id="update"
                >
                    {localizer.msg('common.update')}
                </ActionButton>
            </div>
        </Fragment>

    );
};