import React, {ChangeEvent, FocusEvent, useEffect, useMemo, useRef} from 'react';
import {useWatch} from 'react-hook-form';
import isString from 'lodash/isString';
import {
    ClassificationAttribute,
    ClassificationConfig,
    ClassificationUtil,
    FieldExtensionsWithInputGroup,
    GenericFormField,
    IdeaFieldData,
    Localizer,
    useFormValidationRules,
    useHookFormContext
} from '@ideascale/commons';
import {useSelectionClassifications} from '../../../hooks/useSelectionClassifications';

export const IDEA_TITLE_VALUE_ON_BLUR = 'titleOnBlur';
export const IDEA_TITLE_VALUE_ON_CHANGE = 'titleOnChange';

type TitleFieldProps = {
    localizer: Localizer;
    ideaField: IdeaFieldData;
    autoFocus?: boolean;
    svgIcons?: string;
    classificationConfig?: ClassificationConfig;
    containerId?: string;
}

export const TitleField = (props: TitleFieldProps) => {
    const {localizer, ideaField, autoFocus, classificationConfig} = props;
    const {selectedClassificationsData, ...restClassificationConfig} = classificationConfig || {};
    const validationRules = useFormValidationRules(ideaField.validationRules || []);
    const {register, control, setValue, getValues} = useHookFormContext();
    const {onBlur, onChange, ref, ...restRegisterOptions} = register(ideaField.key, validationRules);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const {
        setSelectedClassifications
    } = useSelectionClassifications(classificationConfig?.selectedClassificationsData[ideaField.key]);

    const onChangeTitleField = async (e: ChangeEvent<HTMLInputElement>) => {
        await onChange(e);
        setValue(IDEA_TITLE_VALUE_ON_CHANGE, getValues(ideaField.key));
        const [newValue, classificationAttribute] = ClassificationUtil.getClassificationFromText(getValues(ideaField.key), classificationConfig);
        if (classificationAttribute) {
            setValue(ideaField.key, newValue);
            onChangeClassificationValue(classificationAttribute);
        }
    }
    const onBlurTitleField = async (e: FocusEvent) => {
        await onBlur(e);
        setValue(IDEA_TITLE_VALUE_ON_BLUR, getValues(ideaField.key));
    };

    const maxLengthRule = useMemo(() => {
        return (ideaField.validationRules || []).find((rule: any) => rule.type === 'maxLength');
    }, [ideaField.validationRules]);

    const titleValue: string | undefined = useWatch({
        name: ideaField.key,
        disabled: !maxLengthRule,
        defaultValue: ideaField.value,
        control
    });

    const remainingLength = useMemo(() => {
        return maxLengthRule
            ? maxLengthRule.value as number - (titleValue || '').length
            : Infinity;
    }, [maxLengthRule, titleValue]);

    useEffect(() => {
        if (autoFocus) {
            setTimeout(() => inputRef.current?.focus());
        }
    }, [autoFocus]);

    const onChangeClassificationValue = (value: ClassificationAttribute) => {
        setSelectedClassifications(value);
        classificationConfig?.updateAppliedClassifications && classificationConfig?.updateAppliedClassifications(ideaField.key, value);
    };

    return (
        <GenericFormField localizer={localizer}
                          aiAssistanceEnabled={false}
                          field={ideaField}
                          onApplyClassifications={onChangeClassificationValue}
                          appliedClassifications={classificationConfig?.selectedClassificationsData[ideaField.key]}
                          {...restClassificationConfig}>
            <FieldExtensionsWithInputGroup enabledClassification={classificationConfig?.enableClassification}
                             classificationAttribute={classificationConfig?.selectedClassificationsData[ideaField.key]}>
                <input className="form-control" id={ideaField.key} type="text"
                       maxLength={maxLengthRule ? Number(maxLengthRule.value) : undefined}
                       defaultValue={isString(ideaField.value) ? ideaField.value : ''}
                       onBlur={onBlurTitleField}
                       onChange={onChangeTitleField}
                       ref={(el) => {
                           ref(el);
                           inputRef.current = el;
                       }}
                       {...restRegisterOptions}
                />
            </FieldExtensionsWithInputGroup>
            <input type="hidden" {...register(IDEA_TITLE_VALUE_ON_BLUR)}/>
            {
                maxLengthRule &&
                <div className={`text-muted mt-2 ${remainingLength < 0 ? 'text-warning' : ''}`}>
                    {
                        remainingLength < 0
                            ? localizer.msg('idea.form.field.feedback.text-length-exceeded', {count: -remainingLength})
                            : localizer.msg('idea.form.field.feedback.text-length', {count: remainingLength})
                    }
                </div>
            }
        </GenericFormField>
    );
};