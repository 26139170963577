import {Button} from 'reactstrap';
import React, {Fragment, useCallback, useState} from 'react';
import {useForm, useWatch} from 'react-hook-form';
import {ActionButton} from '@ideascale/ui';
import {
    AttachmentAttributeParameters,
    AttachmentType,
    ClassificationAttachmentAttribute,
    ClassificationAttachmentAttributesData,
    ClassificationAttribute,
    ClassificationsHolder,
    ClassificationTreeMenu,
    ClassificationUtil,
    ClassifiedAttachment,
    FileAttachmentDetail,
    FileUploadResponse,
    HookFormProvider,
    Localizer,
    UploadProgressCallback,
    useApiErrorResponseHandler,
    useAttachmentClassificationErrorHandler,
    useFileUploadIntermediary,
    useHandleFormSubmit
} from '@ideascale/commons';
import {CreateFileAttachment} from 'components/idea-details-tabs/CreateFileAttachment';
import {AttachmentFormInputs} from 'models/comments/AttachmentFormInputs';
import {FileAttachmentsHolder} from 'models/FileAttachmentsHolder';

const ATTACHMENT_FORM_ID = 'ATTACHMENT_FORM_ID';

type AttachmentFormProps = {
    id: string
    commentId: number;
    localizer: Localizer;
    multiple?: boolean;
    addNotes?: boolean;
    maxFileSizeLimit: number;
    onAttachmentCancel: () => void;
    uploadFile: (data: FormData, onUploadProgress: UploadProgressCallback) => Promise<FileUploadResponse>;
    addAttachments: (commentId: number, formInputs: AttachmentFormInputs, attributeApiParameters?: Array<AttachmentAttributeParameters>) => Promise<FileAttachmentsHolder>;
    onAddAttachments: (commentId: number, attachments: FileAttachmentDetail[], attributes?: ClassificationAttachmentAttribute[]) => void;
    classificationEnabled?: boolean;
    classificationsMenuData?: ClassificationsHolder | {};
}

export const AttachmentForm = (props: AttachmentFormProps) => {
    const {
        id,
        localizer,
        commentId,
        uploadFile,
        addAttachments,
        multiple = false,
        addNotes = true,
        onAddAttachments,
        maxFileSizeLimit,
        onAttachmentCancel,
        classificationEnabled = false,
        classificationsMenuData
    } = props;
    const methods = useForm<{ attachments: FileAttachmentDetail[] }>({defaultValues: {attachments: []}});
    const {
        control,
        handleSubmit,
        setError,
        clearErrors,
        formState: {errors, isSubmitting},
    } = methods;
    const onSubmit = useHandleFormSubmit(handleSubmit);
    const attachments = useWatch({control, name: 'attachments'});
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const {onUploadFile, fileUploading} = useFileUploadIntermediary(uploadFile);
    const [selectedAttachmentClassification, setSelectedAttachmentClassification] = useState<ClassificationAttribute>();
    const [appliedAttachmentClassifications, setAppliedAttachmentClassifications] = useState<ClassifiedAttachment>({});
    const [appliedAttachmentClassificationsData, setAppliedAttachmentClassificationsData] = useState<ClassificationAttachmentAttributesData>({});
    const {onClassificationError} = useAttachmentClassificationErrorHandler({
        fieldKey: 'attachments',
        setError,
        localizer
    });

    const updateAppliedAttachmentClassifications = useCallback((key: string, value: ClassifiedAttachment) => {
        setAppliedAttachmentClassificationsData(prev => {
            return {...prev, [key]: value};
        });
        setAppliedAttachmentClassifications(value);
    }, []);

    const onFormSubmit = async (formInputs: { attachments: FileAttachmentDetail[] }) => {
        if (attachments.length > 0) {
            try {
                const attributeApiParameters = classificationEnabled ? [
                    ClassificationUtil.buildAttachmentClassificationRequest(
                        'filename',
                        [formInputs.attachments[0].filename],
                        appliedAttachmentClassificationsData['attachments']
                    )] : undefined;
                const response = await addAttachments(commentId, {
                    ...formInputs,
                    fileName: formInputs.attachments[0].filename,
                    note: formInputs.attachments[0].note,
                    altText: formInputs.attachments[0].altText.trim() || formInputs.attachments[0].filename
                }, attributeApiParameters);
                setSelectedAttachmentClassification(undefined);
                if ('attachments' in response && Array.isArray(response.attachments)) {
                    onAddAttachments(commentId, response.attachments, response?.attributes);
                }
            } catch (error: any) {
                handleErrorResponse(error);
            }
        }
    };

    return (
        <div id={id}>
            <HookFormProvider {...methods}>
                <form id={`${ATTACHMENT_FORM_ID}-${id}`}
                      onSubmit={onSubmit(onFormSubmit, isSubmitting || fileUploading || attachments.length === 0)}
                      className="mt-4">
                    <div
                        className={`form-group ${errors.attachments && !Array.isArray(errors.attachments) ? 'has-error' : ''}`}>
                        <Fragment>
                            {
                                classificationEnabled && (
                                    <ClassificationTreeMenu
                                        localizer={localizer}
                                        id={`attachment-classification-menu-${id}`}
                                        classifications={classificationsMenuData || {}}
                                        appliedClassifications={selectedAttachmentClassification}
                                        onApplyClassifications={value => {
                                            clearErrors('attachments');
                                            setSelectedAttachmentClassification(value);
                                        }}/>
                                )
                            }
                            <CreateFileAttachment
                                attachmentType={AttachmentType.IDEA}
                                localizer={localizer}
                                inputId="attachments"
                                maxFileSizeInMb={maxFileSizeLimit}
                                uploadFile={onUploadFile}
                                multiple={multiple}
                                includeAttachmentNote={addNotes}
                                classificationsMenuData={classificationsMenuData}
                                classificationEnabled={classificationEnabled}
                                selectedClassification={selectedAttachmentClassification}
                                updateAppliedAttachmentClassifications={updateAppliedAttachmentClassifications}
                                appliedClassifications={appliedAttachmentClassifications}
                                onClassificationError={onClassificationError}
                            />
                        </Fragment>
                        {
                            errors?.attachments && (errors?.attachments as any)?.type === 'classificationRequired' && (
                                <p className="invalid-feedback d-block" data-role="field-error" aria-live="polite">
                                    {(errors.attachments as any)?.message}
                                </p>
                            )
                        }
                        {
                            errors.attachments && (errors?.attachments as any)?.type === 'required' && (
                                <p className="invalid-feedback d-block">
                                    {localizer.msg('comments.comment-form.upload-file-empty')}
                                </p>
                            )
                        }
                    </div>
                    <div className="form-group mb-0 d-flex justify-content-end">
                        <Button color="cancel" disabled={isSubmitting || fileUploading} className="me-2"
                                onClick={onAttachmentCancel}>
                            {localizer.msg('common.cancel')}
                        </Button>
                        <ActionButton disabled={attachments.length === 0}
                                      form={`${ATTACHMENT_FORM_ID}-${id}`} type="submit"
                                      loading={isSubmitting || fileUploading}>
                            {localizer.msg('common.submit')}
                        </ActionButton>
                    </div>
                </form>
            </HookFormProvider>
        </div>
    );
};