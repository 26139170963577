import React from 'react';
import {Localizer, Member, MemberLink, StringUtil} from '@ideascale/commons';
import {Avatar} from '@ideascale/ui';
import './CampaignTeamMember.scss';

type CampaignMemberProps = {
    member: Member;
    localizer: Localizer;
}

export const CampaignTeamMember = (props: CampaignMemberProps) => {
    const {member, localizer} = props;

    return (
        <MemberLink id={member.id} identityHidden={member.identityHidden}
                    className="d-flex align-items-center team-member" title={member.name}>
            <div>
                <Avatar className="me-2" size={'md'} src={member.avatar}
                        alt={member.username ? localizer.msg('common.user-avatar', {username: member.username}) : ''}/>
            </div>
            <div className="text-truncate">
                <strong>{StringUtil.textTruncate(member.name, 25)}</strong>
                {
                    member.username &&
                    <span className="text-muted d-block text-truncate">
                        @{StringUtil.textTruncate(member.username, 25)}
                    </span>
                }
            </div>
        </MemberLink>
    );
};