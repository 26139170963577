export class GoogleAnalyticsDimensions {
    static readonly EMPTY = new GoogleAnalyticsDimensions('', '', '', '', '', '', '','');

    constructor(
        public trackingAction: string,
        public totalLogins: string,
        public totalIdeas: string,
        public userRole: string,
        public accountType: string,
        public layout: string,
        public loginOption: string,
        public trackingStatus: string,
    ) {
    }
}