import {CampaignPerformancesData} from './CampaignPerformancesData';

export class DigestShareData {
    static readonly EMPTY = new DigestShareData([], '', '');

    constructor(
        public campaignPerformances: CampaignPerformancesData[],
        public fromDate: string,
        public toDate: string
    ) {
    }
}