import React, {Fragment} from 'react';
import {
    ClassificationFieldAttribute,
    ClassificationLabel,
    ClassificationUtil,
    FieldType,
    HtmlRenderer,
    hyperlinkRendererResolver,
    RenderFormat,
    useFieldBadge,
    useHtmlToReactV2
} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useCookieConsentConfiguration} from 'hooks/useCookieConsentConfiguration';
import {useLocalizer} from 'hooks/useLocalizer';
import {IdeaFieldSection} from 'models/IdeaFieldSection';
import {FieldValue} from 'models/types/FieldValue';

const HTML_TO_REACT_CONFIG = {atMention: true};

type IdeaCustomFieldsType = {
    fieldSections: IdeaFieldSection[];
    className?: string;
    classification?: ClassificationFieldAttribute[]
}

export const IdeaCustomFields = (props: IdeaCustomFieldsType) => {
    const {className = 'mt-4', fieldSections, classification} = props;

    const localizer = useLocalizer();
    const cookieConsentConfig = useCookieConsentConfiguration();
    const {communityConfig} = useAppContext();
    const htmlToReact = useHtmlToReactV2();
    const renderFieldBadge = useFieldBadge(localizer);

    const renderFieldValue = (fieldValue: FieldValue) => {
        switch (fieldValue.fieldDisplayType) {
            case FieldType.TEXT_AREA:
                return <HtmlRenderer content={fieldValue.value} WrapperTag="span" renderFormat={RenderFormat.HTML}
                                     reactOptions={HTML_TO_REACT_CONFIG}
                                     cookieConsentConfig={cookieConsentConfig}/>;
            case FieldType.ATTACHMENT:
                return (fieldValue.attachments !== null) &&
                    (<Fragment>
                        <ul className="mb-2">
                            {fieldValue.attachments.map((attachment, index) => (
                                <li key={index}>
                                    <a className="d-block classic-link" href={attachment.url}>
                                        {communityConfig.classificationEnabled && classification &&
                                            <ClassificationLabel
                                                classification={ClassificationUtil.getAppliedFileClassificationsByFileName(fieldValue.fieldName, attachment.filename, classification)}
                                                extraClasses="me-1"/>
                                        }
                                        {attachment.filename}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </Fragment>);
            case FieldType.YOUTUBE:
            case FieldType.SCREENR:
            case FieldType.HAPYAK:
            case FieldType.HYPERLINK:
                return <HtmlRenderer content={hyperlinkRendererResolver.resolver(fieldValue.value, fieldValue.value)}
                                     WrapperTag="span" renderFormat={RenderFormat.HTML}
                                     reactOptions={HTML_TO_REACT_CONFIG} cookieConsentConfig={cookieConsentConfig}/>;
            default:
                return <Fragment>{htmlToReact(fieldValue.value)}</Fragment>;
        }
    };

    return (
        <div className={className}>
            {fieldSections?.map((section, index) => (
                <div key={index} className={`${section.title ? 'custom-field-section-content mb-2' : ''}`}>
                    {
                        section.title && <h2 className="font-size-md mb-2">{section.title}</h2>
                    }
                    <dl key={`${section.id}-${index}`} id={`custom-field-section-${section.id}`}>
                        {
                            section.ideaFieldValues.map((fieldValue, index) =>
                                <Fragment>
                                    <dt className="mb-1">
                                        {communityConfig.classificationEnabled && classification && fieldValue.fieldDisplayType !== FieldType.TEXT_AREA &&
                                            <ClassificationLabel
                                                classification={ClassificationUtil.getAttributeByName(fieldValue.fieldName, classification)}
                                                extraClasses="me-1"/>
                                        }
                                        {fieldValue.name} {renderFieldBadge(fieldValue)}</dt>
                                    <dd className="custom-fields" key={index}
                                        data-test-element-id={fieldValue.id}
                                        data-field-type={fieldValue.fieldDisplayType}>
                                        {renderFieldValue(fieldValue)}
                                    </dd>
                                </Fragment>
                            )
                        }
                    </dl>
                </div>
            ))}
        </div>
    );
};
