import React from 'react';
import {Button} from '@ideascale/ui';
import {ActionItemHeader} from './ActionItemHeader';
import {GroupedActionItem} from 'models/topbar-action-item/GroupedActionItem';
import {ProfileActionItem} from 'models/topbar-action-item/ProfileActionItem';
import {ProfileActionType} from 'models/topbar-action-item/ProfileActionType';

type ProfileActionItemsProps = {
    groupedActionItem: GroupedActionItem;
    toggleProfileQuestionsModal: () => void;
    toggleVerifiableFieldsOpen: () => void;
}

export const ProfileActionItems = (props: ProfileActionItemsProps) => {
    const {groupedActionItem, toggleProfileQuestionsModal, toggleVerifiableFieldsOpen} = props;
    const renderProfileActionItems = (item: ProfileActionItem) => {
        switch (item.type) {
            case ProfileActionType.PROFILE_QUESTION:
                return (
                    <li key={item.name}>
                        <Button color="link" className="p-0 font-size-sm text-decoration-none"
                                aria-label={`${!!item.count && item.count} ${item.name}`}
                                onClick={toggleProfileQuestionsModal}>
                            <span aria-hidden="true">{item.name} {item.count && `[ ${item.count} ]`}</span>
                        </Button>
                    </li>
                );
            case ProfileActionType.VERIFIABLE_FIELD:
                return (
                    <li key={item.name}>
                        <Button color="link" className="btn btn-link p-0 font-size-sm"
                                aria-label={`${!!item.count && item.count} ${item.name}`}
                                onClick={toggleVerifiableFieldsOpen}>
                            <span aria-hidden="true">{item.name} {item.count && `[ ${item.count} ]`}</span>
                        </Button>
                    </li>
                );
            case ProfileActionType.CHANGE_LANGUAGE:
            case ProfileActionType.CONFIGURE_TIMEZONE:
                return (
                    <li key={item.name}>
                        <a className="btn btn-link p-0 font-size-sm text-decoration-none" href={item.url}>
                            <span>{item.name}</span>
                        </a>
                    </li>
                );
        }
    };

    return (
        <div className="action-item-container">
            <ActionItemHeader id="profile-action-heading" groupTitle={groupedActionItem.groupTitle}
                              seeAllLink={groupedActionItem.seeAllLink}/>
            <div className="divider"/>
            <ul className="action-item-list-container list-unstyled mx-3 mt-3" aria-labelledby="profile-action-heading">
                {
                    (groupedActionItem.actionItems as ProfileActionItem[]).map((actionItem: ProfileActionItem) => (
                        renderProfileActionItems(actionItem)
                    ))
                }
            </ul>
        </div>
    );
};