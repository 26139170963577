import React from 'react';
import {ParagraphSkeleton, Skeleton} from '@ideascale/ui';
import {IdeaListSkeleton} from 'components/idea/list/IdeaListSkeleton';
import {IdeaListViewMode} from 'models/enums/IdeaListViewMode';


export const LandingPageSkeleton = () => {
    return (
        <div className="container landing-page-container">
            <div className="card panel mt-5">
                <div className="card-body panel-body">
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <br/>
                            <br/>
                            <ParagraphSkeleton rows={2}/>
                            <br/>
                            <br/>
                            <ParagraphSkeleton rows={4}/>
                            <br/>

                            <Skeleton height="40px" width="120px"/>
                        </div>

                        <div className="col-12 col-sm-6 ">
                            <Skeleton height="400px" width="100%"/>
                        </div>
                    </div>
                </div>
            </div>


            <div className="mt-5">
                <div className="d-flex flex-row justify-content-between slick-list">
                    <div className="slider-skeleton mx-2"/>
                </div>
            </div>

            <div className="mt-5">
                <IdeaListSkeleton currentViewMode={IdeaListViewMode.LIST_VIEW} items={4}/>
            </div>

        </div>
    )
}