import React, {Fragment, useCallback, useEffect, useState} from 'react';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {Icon} from '@ideascale/ui';
import {Scroller} from 'utils/Scroller';
import {useLocalizer} from 'hooks/useLocalizer';
import './ScrollToTopButton.scss';

const SCROLL_OFFSET = 300;

export const ScrollToTopButton = () => {
    const localizer = useLocalizer();
    const [visible, setVisible] = useState(false);

    const showOrHideScrollButton = useCallback(() => {
        if (window.pageYOffset > SCROLL_OFFSET) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }, []);

    const onScrollToTopClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        Scroller.scrollToTop();
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', showOrHideScrollButton);

        return () => {
            window.removeEventListener('scroll', showOrHideScrollButton);
        };
    }, [showOrHideScrollButton]);

    return (
        <Fragment>
            {
                visible &&
                <nav aria-label={localizer.msg('common.back-to-top')}>
                    <div className="position-fixed cursor-pointer scroll-position text-center"
                         onClick={onScrollToTopClick}>
                        <button className="btn btn-primary p-0 rounded-circle border-0"
                                title={localizer.msg('common.back-to-top')}>
                            <Icon name="arrow-up" fill="#fff" width={22} height={22} iconSpritePath={svgIconsPath}/>
                        </button>
                        <br/>
                        <span>{localizer.msg('common.back-to-top')}</span>
                    </div>
                </nav>
            }
        </Fragment>
    );
};