import React, {useCallback, useEffect, useState} from 'react';
import range from 'lodash/range';
import {HtmlConverter, Skeleton} from '@ideascale/ui';
import {useToggle} from '@ideascale/commons';
import {SidebarWidgetEditModal} from './SidebarWidgetEditModal';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useSidebarEditHelper} from 'hooks/useSidebarEditHelper';
import {SidebarDragAndDropElements} from 'components/edit-mode/SidebarDragAndDropElements';
import {ReorderCommunitySidebarCommand} from 'commands/edit-mode/ReorderCommunitySidebarCommand';
import {UpdateContactWidgetCommand} from 'commands/edit-mode/UpdateContactWidgetCommand';
import {UpdateCommunityWidgetCommand} from 'commands/edit-mode/UpdateCommunityWidgetCommand';
import {SidebarItemKey} from 'models/enums/SidebarItemKey';
import {SidebarItem} from 'models/sidebar/SidebarItem';
import {SidebarCustomWidgetInput} from 'models/edit-mode/SidebarCustomWidgetInput';
import {WidgetField} from 'models/edit-mode/WidgetField';
import {CommunityEditableFieldType} from 'models/edit-mode/CommunityEditableFieldType';
import 'components/edit-mode/CommunitySidebarEdit.scss';

export const CommunitySidebarEdit = () => {
    const localizer = useLocalizer();
    const [communitySummaryEditModalOpen, toggleCommunitySummaryEditModalOpen] = useToggle(false);
    const [contactEditModalOpen, toggleContactEditModalOpen] = useToggle(false);
    const [communitySummary, setCommunitySummary] = useState<WidgetField>(WidgetField.EMPTY);
    const [contactWidgetData, setContactWidgetData] = useState<WidgetField>(WidgetField.EMPTY);
    const {homeConfig, communityHomeEditor, commandExecutor, validationErrors} = useEditModeContext();

    const {
        sidebarItems,
        dataFetching,
        onVisibilityButtonClick,
        onDragEnd
    } = useSidebarEditHelper(
        homeConfig.sidebarItems,
        (newItems: SidebarItem[]) => {
            const command = new ReorderCommunitySidebarCommand(communityHomeEditor, newItems);
            commandExecutor.execute(command);
            validationErrors.clearError(command.getType());
        });

    const onEditButtonClicked = useCallback((sidebarItem: SidebarItem) => {
        if (sidebarItem.key === SidebarItemKey.COMMUNITY_SUMMARY) {
            toggleCommunitySummaryEditModalOpen(true);
        } else if (sidebarItem.key === SidebarItemKey.CONTACT) {
            toggleContactEditModalOpen(true);
        }
    }, [toggleCommunitySummaryEditModalOpen, toggleContactEditModalOpen]);

    const submitContactChanges = async (values: SidebarCustomWidgetInput) => {
        const command = new UpdateContactWidgetCommand(communityHomeEditor, {
            name: contactWidgetData.name,
            title: values.title,
            content: HtmlConverter.toServerHtmlFormat(values.content),
            key: contactWidgetData.key
        });
        commandExecutor.execute(command);
        validationErrors.clearError(command.getType());
        toggleContactEditModalOpen();
    };

    const submitCommunitySummary = async (values: SidebarCustomWidgetInput) => {
        const command = new UpdateCommunityWidgetCommand(communityHomeEditor, {
            name: communitySummary.name,
            title: values.title,
            content: HtmlConverter.toServerHtmlFormat(values.content),
            key: communitySummary.key
        });

        commandExecutor.execute(command);
        validationErrors.clearError(command.getType());
        toggleCommunitySummaryEditModalOpen();
    };

    useEffect(() => {
        setContactWidgetData(homeConfig.getWidgetField(CommunityEditableFieldType.CUSTOM_WIDGET));
        setCommunitySummary(homeConfig.getWidgetField(CommunityEditableFieldType.COMMUNITY_WIDGET));
    }, [homeConfig]);

    return (
        <>
            {
                dataFetching
                    ? (
                        <div className="sidebar-skeleton-wrapper">
                            {
                                range(1, 12).map(index => (
                                    <div key={index}
                                         className="mb-2 d-flex justify-content-center align-items-center"
                                         style={{height: '50px'}}>
                                        <Skeleton height={'25px'} width={'70%'} cssClass={'me-2'}/>
                                        <Skeleton height={'25px'} width={'10%'} cssClass={'me-2'}/>
                                        <Skeleton height={'25px'} width={'10%'}/>
                                    </div>
                                ))
                            }
                        </div>
                    )
                    : (
                        <div className="sidebar-edit-mode">

                            <SidebarDragAndDropElements sidebarItems={sidebarItems} onDragEnd={onDragEnd}
                                                        localizer={localizer}
                                                        onVisibilityButtonClick={onVisibilityButtonClick}
                                                        onEditButtonClick={onEditButtonClicked}/>
                            {
                                communitySummaryEditModalOpen &&
                                <SidebarWidgetEditModal
                                    isOpen={communitySummaryEditModalOpen}
                                    toggle={toggleCommunitySummaryEditModalOpen}
                                    widgetField={communitySummary}
                                    onSubmitWidgetChanges={submitCommunitySummary}
                                    placeholder={localizer.msg('sidebar.custom.community-tile')}
                                />
                            }
                            {
                                contactEditModalOpen &&
                                <SidebarWidgetEditModal
                                    isOpen={contactEditModalOpen}
                                    toggle={toggleContactEditModalOpen}
                                    widgetField={contactWidgetData}
                                    onSubmitWidgetChanges={submitContactChanges}
                                    placeholder={localizer.msg('sidebar.custom.community-tile')}
                                />
                            }
                        </div>
                    )
            }
        </>
    );
};
