import React, {Fragment, useCallback, useMemo, useState} from 'react';
import {PageTheme, useApiErrorResponseHandler, useToggle} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {useTextColor} from 'hooks/useTextColor';
import {useTranslation} from 'hooks/useTranslation';
import {useLandingPageService} from 'hooks/useService';
import {HtmlRenderer} from 'components/shared/HtmlRenderer';
import {ConfigWrapper} from 'components/landing-page/ConfigWrapper';
import {DisplayHeader} from 'components/landing-page/DisplayHeader';
import {TextComponentConfigModal} from 'components/landing-page/TextComponentConfigModal';
import {TranslationModal} from 'shared/TranslationModal';
import {TextComponentData} from 'models/landing-page/TextComponentData';
import {TranslationConfig} from 'models/TranslationConfig';
import {RichTextToolbarType} from 'models/enums/landing-page/RichTextToolbarType';
import {TranslationFieldType} from 'models/enums/TranslationFieldType';
import {TranslationContentType} from 'models/enums/landing-page/TranslationContentType';
import {TranslationContentResponse} from 'models/landing-page/TranslationContentResponse';

type TextContainerProps = {
    showConfig: boolean;
    data: TextComponentData;
    theme: PageTheme;
    showTranslationConfig: boolean,
    onComponentEdited: () => void,
    translationLanguageId?: number,
}

export const TextComponentContainer = (props: TextContainerProps) => {
    const {showConfig, data, theme, showTranslationConfig, onComponentEdited, translationLanguageId} = props;
    const localizer = useLocalizer();
    const {showErrorMessage} = useApiErrorResponseHandler({localizer});
    const [textConfig, setTextConfig] = useState(data);
    const [showTranslationModal, toggleTranslationModal] = useToggle(false);
    const [showEditModal, toggleEditModal] = useToggle(false);
    const landingPageService = useLandingPageService();
    const {getTextColorClassName} = useTextColor();
    const {getTranslatedContent, saveTranslatedContent} = useTranslation();

    const onEditClicked = useCallback(() => {
        toggleEditModal();
    }, [toggleEditModal]);

    const saveComponentConfig = useCallback(async (component: TextComponentData) => {
        if (landingPageService) {
            return landingPageService.saveTextComponentConfig(component);
        }
        return TextComponentData.EMPTY;
    }, [landingPageService]);

    const updateComponentConfig = useCallback(async (component: TextComponentData) => {
        try {
            const response = await saveComponentConfig(component);
            if (response) {
                setTextConfig(response);
            }
        } catch (e: any) {
            if (e.data && 'validationErrors' in e.data) {
                throw e.data;
            } else {
                showErrorMessage(e);
            }
        }
    }, [saveComponentConfig, showErrorMessage]);

    const onUpdateConfig = useCallback(async (config: TextComponentData) => {
        const newData = {...textConfig};
        newData.title = config.title;
        newData.text = config.text;
        newData.textColor = config.textColor;
        await updateComponentConfig(newData);
    }, [textConfig, updateComponentConfig]);

    const onToggleVisibility = useCallback(async () => {
        const newData: TextComponentData = {...textConfig};
        newData.visible = !newData.visible;
        await updateComponentConfig(newData);
        onComponentEdited();
    }, [onComponentEdited, textConfig, updateComponentConfig]);

    const onTranslationClicked = useCallback(() => {
        if (translationLanguageId !== undefined) {
            toggleTranslationModal();
        }
    }, [toggleTranslationModal, translationLanguageId]);

    const onTextTranslated = useCallback((response: TranslationContentResponse) => {
        setTextConfig(prev => {
            const newData = {...prev};
            if (response.contentType === TranslationContentType.PAGE_COMPONENT_TITLE) {
                newData.title = response.translation;
            } else {
                newData.text = response.translation;
            }
            return newData;
        });
    }, []);

    const translationConfigs = useMemo(() => {
        return [
            new TranslationConfig(
                TranslationContentType.PAGE_COMPONENT_TITLE,
                textConfig.id,
                TranslationFieldType.TEXT,
            ),
            new TranslationConfig(
                TranslationContentType.TEXT_COMPONENT_TEXT,
                textConfig.id,
                TranslationFieldType.RICH_TEXT,
                RichTextToolbarType.STANDARD
            )
        ];
    }, [textConfig.id]);

    return (
        <Fragment>
            <ConfigWrapper visible={textConfig.visible}
                           showConfig={showConfig} showTranslationConfig={showTranslationConfig}
                           onTranslationClicked={onTranslationClicked}
                           localizer={localizer}
                           toggleVisibility={onToggleVisibility}
                           onEditClicked={onEditClicked}
                           className={`${textConfig.attribute?.className}`}
                           accessibleName={localizer.msg('landing-page.text-component')}>
                <DisplayHeader className={`${getTextColorClassName(textConfig.textColor)}`} theme={theme}
                               title={textConfig.title}/>
                <HtmlRenderer className={`font-size-lg ${getTextColorClassName(textConfig.textColor)}`}
                              content={textConfig.text}/>
            </ConfigWrapper>
            {
                showEditModal &&
                <TextComponentConfigModal updateData={onUpdateConfig} localizer={localizer} configData={textConfig}
                                          onComponentEdited={onComponentEdited}
                                          toggle={toggleEditModal} open={showEditModal}/>
            }
            {
                showTranslationModal && translationLanguageId !== undefined &&
                <TranslationModal translationLanguageId={translationLanguageId} localizer={localizer}
                                  open={showTranslationModal}
                                  toggle={toggleTranslationModal}
                                  onTextTranslated={onTextTranslated}
                                  onComponentEdited={onComponentEdited}
                                  translationConfigs={translationConfigs}
                                  getTranslatedContent={getTranslatedContent}
                                  saveTranslatedContent={saveTranslatedContent}/>
            }
        </Fragment>
    );
};