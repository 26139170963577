import React from 'react';
import {Link} from 'react-router-dom';
import {Trans} from 'react-i18next';
import {NotificationData} from 'models/NotificationData';
import {appLinks} from 'services/AppLinks';

type IdeaAnnotatedNotificationProps = {
    notification: NotificationData;
}

// TODO: RAFAYAT create annotated comment url with commentType id. comment id not send from backend here.
export const IdeaAnnotatedNotification = (props: IdeaAnnotatedNotificationProps) => {
    const {notification} = props;

    return (
        <Trans i18nKey="notification.idea.idea-annotated" values={{
            idea: notification.viewAttributes.ideaTitle
        }}>
            <Link to={appLinks.ideaDetails(notification.ideaId)}/>
            <Link to={appLinks.ideaDetailsComment(notification.ideaId)}/>
        </Trans>
    );
};