import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {Controller, useWatch} from 'react-hook-form';
import {OptionType, TagAsyncSelect} from '@ideascale/ui';
import {
    GenericFormField,
    IDEA_DESCRIPTION_VALUE_ON_BLUR,
    IdeaFieldData,
    Localizer,
    stringToOptionType,
    TagSearchParameters,
    TagSuggestions,
    useFormValidationRules,
    useHookFormContext
} from '@ideascale/commons';
import {IDEA_TITLE_VALUE_ON_BLUR} from './TitleField';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';

type TagsFieldProps = {
    localizer: Localizer;
    ideaField: IdeaFieldData;
    fetchTags: (pageParams: TagSearchParameters) => Promise<PagedResponseContent<string>>;
    fetchSuggestedTags: (ideaTitle: string, ideaDescription: string, currentTags: string[]) => Promise<string[]>;
    campaignId?: number;
    autoFocus?: boolean;
}

export const TagsField = (props: TagsFieldProps) => {
    const {ideaField, localizer, fetchTags, campaignId, fetchSuggestedTags, autoFocus} = props;
    const validationRules = useFormValidationRules(ideaField.validationRules || []);
    const {control, getValues, setFocus, setValue,} = useHookFormContext();
    const [suggestedTags, setSuggestedTags] = useState<OptionType[]>([]);
    const [tags, setTags] = useState<OptionType[]>([]);

    const ideaDescription = useWatch({name: IDEA_DESCRIPTION_VALUE_ON_BLUR, control});
    const ideaTitle = useWatch({name: IDEA_TITLE_VALUE_ON_BLUR, control});

    const defaultOptions = useMemo(() => stringToOptionType(ideaField.tags || []), [ideaField.tags]);

    const fetchCampaignTags = useCallback((pageParams: PageParameters) => {
        return fetchTags({...pageParams, campaignId});
    }, [campaignId, fetchTags]);

    const loadSuggestedTags = useCallback(async (ideaTitle: string, ideaDescription: string) => {
        try {
            const tags = await fetchSuggestedTags(ideaTitle || '', ideaDescription || '', []);
            const tagOptions = stringToOptionType(tags) || [];
            setSuggestedTags(tagOptions);
        } catch (error: any) {
            setSuggestedTags([]);
        }
    }, [fetchSuggestedTags]);

    useEffect(() => {
        const title = ideaTitle || getValues('title');
        const description = ideaDescription || getValues('text');
        if (title || description) {
            loadSuggestedTags(title, description).then();
        }
    }, [getValues, ideaDescription, ideaTitle, loadSuggestedTags]);

    useEffect(() => {
        setValue(ideaField.key, tags);
    }, [ideaField.key, setValue, tags]);

    useEffect(() => {
        setTags(defaultOptions);
    }, [defaultOptions]);

    useEffect(() => {
        if (autoFocus) {
            setTimeout(() => setFocus(ideaField.key));
        }
    }, [autoFocus, ideaField.key, setFocus]);

    return <GenericFormField localizer={localizer}
                             field={ideaField}
                             aiAssistanceEnabled={false}>
        <div className="tag-list">
            <Controller
                control={control} name={ideaField.key}
                rules={validationRules}
                render={({field: {name, value, ref}}) =>
                    <Fragment>
                        <TagAsyncSelect
                            name={name}
                            ref={ref}
                            placeholder={localizer.msg('frontend-shared.tags.add-placeholder')}
                            inputId={name}
                            isCreatable={ideaField.tagCreationAllowed}
                            enterLabel={localizer.msg('frontend-shared.tags.enter')}
                            createLabel={localizer.msg('frontend-shared.tags.create')}
                            noTagsMessage={localizer.msg('frontend-shared.tags.no-match-found')}
                            fetchOptions={fetchCampaignTags}
                            value={value}
                            onChange={(tags: any) => {
                                setTags(tags || []);
                            }}
                        />
                    </Fragment>
                }
            />
            <TagSuggestions localizer={localizer} tags={tags} suggestedTags={suggestedTags} setTags={setTags}/>
        </div>
    </GenericFormField>;
};