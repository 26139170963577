import React, {useCallback, useState} from 'react';
import {LayoutUtil, useToggle} from '@ideascale/commons';
import {useCommunityService} from 'hooks/useService';
import {useLocalizer} from 'hooks/useLocalizer';
import {useAppContext} from 'contexts/AppContext';
import {PageParameters} from 'models/types/PageParameters';
import {NotificationType} from 'models/enums/NotificationType';
import {NotificationResponse} from 'models/NotificationResponse';
import {DigestModal} from 'components/DigestModal';
import {ProfileNotifications} from 'components/profile/notifications/ProfileNotifications';
import {ProfileFollowContainer} from './ProfileFollowContainer';

export const NotificationContainer = () => {
    const localizer = useLocalizer();
    const {authentication: {actor}} = useAppContext();
    const communityService = useCommunityService();
    const [showDigestNotificationModal, toggleDigestNotificationModal] = useToggle(false);
    const [digestNotificationData, setDigestNotificationData] = useState({
        year: '',
        notificationType: NotificationType.MONTHLY_MODERATOR_DIGEST,
        month: '',
        week: '',
        campaignId: 0,
        memberId: 0
    });
    const toggleDigestModal = (year: string, notificationType: NotificationType, month?: string, week?: string, campaignId?: number, memberId?: number) => {
        setDigestNotificationData({
            year: year,
            notificationType: notificationType,
            month: month || '',
            week: week || '',
            campaignId: campaignId || 0,
            memberId: memberId || 0
        });
        toggleDigestNotificationModal();
    };
    const fetchProfileNotifications = useCallback(async (groupId: number, pageParameters: PageParameters) => {
        if (communityService) {
            return await communityService.fetchProfileNotifications(groupId, pageParameters);
        }
        return NotificationResponse.EMPTY;
    }, [communityService]);

    const markNotificationsAsSeen = useCallback(async () => {
        if (communityService) {
            await communityService.markNotificationsAsSeen();
        }
    }, [communityService]);

    return (
        <>
            <div className="row ms-lg-0">
                <div className="col-sm-12 col-md-12 col-lg-8 px-lg-0 pe-lg-3">
                    <ProfileNotifications
                        getDataForDigestModal={toggleDigestModal}
                        localizer={localizer}
                        actorId={actor.id}
                        fetchProfileNotifications={fetchProfileNotifications}
                        markNotificationsAsSeen={markNotificationsAsSeen}
                    />
                </div>
                <div className="sidebar-wrapper col-sm-12 col-md-12 col-lg-4 px-lg-0"
                     data-test-element-id="profile-central-section">
                    <ProfileFollowContainer expanded={!LayoutUtil.isMobileLayout()}/>
                </div>
            </div>
            <DigestModal showDigestNotificationModal={showDigestNotificationModal}
                         digestNotificationData={digestNotificationData}
                         toggleDigestNotificationModal={toggleDigestNotificationModal}/>
        </>

    );
};