import {ClassificationSummary, Member} from '@ideascale/commons';

type Messages = {
    body: string,
    createdAt: string,
    sender: Member,
    recipients: Member[]
}

export class ConversationThreadData {
    static readonly EMPTY = new ConversationThreadData(0, '', []);

    constructor(
        public id: number,
        public subject: string,
        public messages: Messages[],
        public subjectClassification?: ClassificationSummary,
    ) {
    }
}