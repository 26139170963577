import {FeatureTourStep} from '@ideascale/commons';
import {create} from 'zustand';

type TourState = {
    onboardInProgress: boolean;
    setOnboardInProgress: (nextValue: boolean) => void;
    runTour: boolean;
    setRunTour: (nextValue: boolean) => void;
    currentStepName: FeatureTourStep;
    currentStepIndex: number;
    setTourStep: (nextStep: FeatureTourStep) => void;
    setTourStepIndex: (nextStepIndex: number) => void;
    incrementStepIndex: (offset: number) => void;
}

export const useFeatureTourStore = create<TourState>()(set => ({
    currentStepName: FeatureTourStep.LUCIDO_EXPLORE,
    currentStepIndex: 0,
    runTour: false,
    onboardInProgress: false,
    setTourStep: (nextStep) => set({currentStepName: nextStep}),
    setTourStepIndex: (nextStepIndex) => set({currentStepIndex: nextStepIndex}),
    incrementStepIndex: (offset: number) => set((state) => ({currentStepIndex: state.currentStepIndex + offset})),
    setRunTour: (nextValue) => set({runTour: nextValue}),
    setOnboardInProgress: (nextValue) => set({onboardInProgress: nextValue})
}));