import {Operation} from './Operation';
import {CommunityOperationType} from './CommunityOperationType';

export class ChangeCommunityNameOperation implements Operation {
    constructor(
        public type: CommunityOperationType,
        public name: string
    ) {
    }

    getType(): CommunityOperationType {
        return this.type;
    }
}