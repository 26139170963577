import React, {Fragment, useEffect, useState} from 'react';
import {PageTheme} from '@ideascale/commons';
import {StatisticItem} from 'components/landing-page/StatisticItem';
import {DisplayHeader} from 'components/landing-page/DisplayHeader';
import {StatisticsItem} from 'models/landing-page/StatisticsItem';
import {StatisticsType} from 'models/enums/landing-page/StatisticsType';
import {StatisticsResponse} from 'models/landing-page/StatisticsResponse';
import {CommunityStatisticsData} from 'models/landing-page/CommunityStatisticsData';
import {StatisticsRequest} from 'models/landing-page/StatisticsRequest';
import 'components/landing-page/CommunityStatisticsComponent.scss';

type CommunityStatisticsComponentProps = {
    config: CommunityStatisticsData;
    showConfig: boolean;
    getCommunityStats: (items: StatisticsRequest) => Promise<StatisticsResponse[]>
    toggleItemVisibility: (item: StatisticsItem) => void;
    theme: PageTheme;
}

export const CommunityStatisticsComponent = (props: CommunityStatisticsComponentProps) => {
    const {config, showConfig, getCommunityStats, toggleItemVisibility, theme} = props;
    const [statistics, setStatistics] = useState<StatisticsResponse[]>([]);

    const getStatisticValueFromData = (type: StatisticsType) => {
        const item = statistics.find(item => item.statisticsType === type)
        return item?.count || 0;
    }

    useEffect(() => {
        const parameters: StatisticsRequest = {
            statisticsTypes: [
                StatisticsType.CAMPAIGNS,
                StatisticsType.IDEAS,
                StatisticsType.COMMENTS,
                StatisticsType.MEMBERS,
                StatisticsType.IMPLEMENTED_IDEAS,
                StatisticsType.SELECTED_IDEAS
            ]
        }
        getCommunityStats(parameters).then(data => setStatistics(data));
    }, [getCommunityStats])

    return (
        <Fragment>
            <DisplayHeader theme={theme} title={config.title}/>
            <div className="d-flex align-items-center flex-wrap community-stat-items">
                {
                    config.statisticsList && config.statisticsList.map(item => (item.visible || showConfig) &&
                        <StatisticItem key={item.title} showConfig={showConfig}
                                       value={getStatisticValueFromData(item.type)}
                                       data={item}
                                       toggleVisibility={toggleItemVisibility}/>)
                }
            </div>
        </Fragment>
    )
}