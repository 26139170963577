import React from 'react';
import {FieldValues, useForm} from 'react-hook-form';
import {ActionButton} from '@ideascale/ui';
import {
    AlertEvent,
    AlertType,
    buildAlertEventData,
    eventDispatcher,
    HookFormProvider,
    Localizer,
    useHandleFormSubmit
} from '@ideascale/commons';
import {EstimateFormSection} from './EstimateFormSection';
import {BaseIdeaStageSummary} from 'models/BaseIdeaStageSummary';
import {SaveEstimateParameters} from 'models/types/SaveEstimateParameters';
import {StageActionResponse} from 'models/StageActionResponse';
import {EstimateStageActivity} from 'models/stage-activity/EstimateStageActivity';
import {EstimateStageSummary} from 'models/EstimateStageSummary';

const FIELD_NAMES_VALUE = {
    high: 'highValue',
    medium: 'mediumValue',
    low: 'lowValue',
};

const FIELD_NAMES_COST = {
    high: 'highCost',
    medium: 'mediumCost',
    low: 'lowCost'
};

type EstimateFormProps = {
    localizer: Localizer;
    stageId: number;
    fundUnit: string;
    setActivity: (activity: EstimateStageActivity) => void;
    updateIdeaStageSummary: <T extends BaseIdeaStageSummary>(stageSummary: T) => void;
    saveEstimate: (stageId: number, estimateData: SaveEstimateParameters) => Promise<StageActionResponse<EstimateStageActivity, EstimateStageSummary>>;
}

export const EstimateForm = (props: EstimateFormProps) => {
    const {localizer, stageId, fundUnit, setActivity, saveEstimate, updateIdeaStageSummary} = props;
    const methods = useForm({mode: 'onChange'});
    const onFormSubmit = useHandleFormSubmit(methods.handleSubmit);
    const onSubmit = async (data: FieldValues) => {
        try {
            const response = await saveEstimate(stageId, {
                highValue: data.highValue,
                mediumValue: data.mediumValue,
                lowValue: data.lowValue,
                highCost: data.highCost,
                mediumCost: data.mediumCost,
                lowCost: data.lowCost
            });
            if (response.stageActivity) {
                setActivity(response.stageActivity);
            }
            updateIdeaStageSummary(response.stageSummary);
        } catch (error: any) {
            const {message} = (error as Error) || {};
            if (message) {
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.error, message));
            }
        }
    };

    return (
        <HookFormProvider {...methods}>
            <form onSubmit={onFormSubmit(onSubmit, methods.formState.isSubmitting || !(methods.formState.isValid))}>
                <h4 className="fw-bold">{localizer.msg('stage.estimate.estimate-value')}</h4>
                <EstimateFormSection localizer={localizer} fundUnit={fundUnit} fieldName={FIELD_NAMES_VALUE}
                                     sectionName={'value'} stageId={stageId}/>
                <h4 className="fw-bold">{localizer.msg('stage.estimate.estimate-cost')}</h4>
                <EstimateFormSection localizer={localizer} fundUnit={fundUnit} fieldName={FIELD_NAMES_COST}
                                     sectionName={'cost'} stageId={stageId}/>
                <div className="col-lg-12 text-end mb-4">
                    <ActionButton loading={methods.formState.isSubmitting} disabled={!(methods.formState.isValid)}>
                        {localizer.msg('stage.estimate.estimate-submit')}
                    </ActionButton>
                </div>
            </form>
        </HookFormProvider>
    );
};