import React, {useEffect, useRef, useState} from 'react';
import {Controller, FieldValues, useForm} from 'react-hook-form';
import {
    ActionButton,
    HtmlConverter,
    IdeascaleSelect,
    Modal,
    NewRichTextEditor,
    RichTextEditorHandler
} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {Localizer, useApiErrorResponseHandler, useHandleFormSubmit} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useTextColor} from 'hooks/useTextColor';
import {useFileUploadService} from 'hooks/useFileUploadService';
import {TextType} from 'models/enums/landing-page/TextType';
import {GeneralText} from 'models/landing-page/GeneralText';
import {RichTextToolbarType} from 'models/enums/landing-page/RichTextToolbarType';

type GeneralTextConfigModalProps = {
    open: boolean;
    toggle: () => void;
    config: GeneralText
    updateConfig: (config: GeneralText) => Promise<void>;
    localizer: Localizer;
    toolbar: RichTextToolbarType;
    onComponentEdited: () => void;
    maxCharacterLimit?: number;
}

const FIELD_NAMES: Record<string, string> = {
    text: 'text',
    textColor: 'textColor',
};

export const GeneralTextConfigModal = (props: GeneralTextConfigModalProps) => {
    const {open, toggle, config, updateConfig, localizer, toolbar, maxCharacterLimit, onComponentEdited} = props;
    const richTextEditorNode = useRef<RichTextEditorHandler>(null);
    const {communityConfig: {offensiveEmojis, maxFileSizeLimit}} = useAppContext();
    const {tempImageUpload} = useFileUploadService();
    const {control, formState: {errors, isSubmitting}, handleSubmit} = useForm();
    const onFormSubmit = useHandleFormSubmit(handleSubmit);
    const {textColorOptions} = useTextColor();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [configUpdated, setConfigUpdated] = useState(false);

    const submitConfig = async (data: FieldValues) => {
        const newConfig = {...config};
        newConfig.text = HtmlConverter.toServerHtmlFormat(data.text);
        newConfig.textColor = data.textColor.value;
        try {
            await updateConfig(newConfig);
            onComponentEdited();
            toggle();
        } catch (e) {
            handleErrorResponse(e);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            richTextEditorNode.current?.focus();
        }, 300);
    }, []);

    return (
        <Modal isOpen={open} toggle={toggle} modalHeaderId="text-config-modal-header"
               title={localizer.msg(`landing-page.components.edit.${config.type === TextType.HEADING ? 'heading' : 'description'}`)}>
            <form id={`general-text-form-${config.id}`}
                  onSubmit={onFormSubmit(submitConfig, isSubmitting || !configUpdated)}
                  aria-labelledby="text-config-modal-header">
                <div className={`form-group ${errors[FIELD_NAMES.text] ? 'has-error' : ''}`}>
                    <label className="control-label fw-bold" htmlFor={FIELD_NAMES.text}
                           onClick={() => richTextEditorNode.current?.focus()}>
                        {localizer.msg('landing-page.components.common.text')}
                        <span className="font-size-lg" aria-hidden={true}>*</span>
                        <span className="sr-only">{localizer.msg('common.form.required')}</span>
                    </label>
                    <Controller
                        name={FIELD_NAMES.text}
                        control={control}
                        defaultValue={HtmlConverter.toRenderHtmlFormat(config.text)}
                        rules={{
                            required: localizer.msg('common.errors.required'),
                            onChange: () => setConfigUpdated(prevState => !prevState ? true : prevState),
                            validate: () => {
                                if (maxCharacterLimit && richTextEditorNode.current?.isCharacterLimitExceeded()) {
                                    return localizer.msg(
                                        'landing-page.components.edit.general-text.error.character-limit',
                                        {characterCount: maxCharacterLimit}
                                    );
                                }
                            }
                        }}
                        render={({field: {onChange}}) =>
                            <NewRichTextEditor
                                id={FIELD_NAMES.text}
                                toolbar={toolbar}
                                className={'form-control'}
                                placeholder={localizer.msg(`landing-page.components.edit.${config.type === TextType.HEADING ? 'heading' : 'description'}`)}
                                svgIconPath={svgIconPath}
                                defaultValue={HtmlConverter.toRenderHtmlFormat(config.text)}
                                onChange={(value, delta, source, editor) => {
                                    setConfigUpdated(prevState => !prevState ? true : prevState);
                                    onChange(value, delta, source, editor);
                                }}
                                characterLeftLabel={maxCharacterLimit ? localizer.msg('frontend-shared.fields.text-area.text-length-count') : ''}
                                maxCharacterLimit={maxCharacterLimit}
                                ref={richTextEditorNode}
                                enableEmojiPicker={false}
                                offensiveEmojis={offensiveEmojis}
                                enableAtMention={false}
                                uploadImage={tempImageUpload}
                                maxFileSize={maxFileSizeLimit}
                            />
                        }
                    />
                    {
                        errors[FIELD_NAMES.text] && (
                            <p className="invalid-feedback d-block" data-role="field-error"
                               aria-live="polite">
                                {errors[FIELD_NAMES.text].message}
                            </p>
                        )
                    }
                </div>
                <div className="form-group">
                    <label htmlFor={FIELD_NAMES.textColor} className="control-label fw-bold">
                        {localizer.msg('landing-page.components.common.text-color')}
                    </label>
                    <Controller
                        control={control} name={FIELD_NAMES.textColor}
                        defaultValue={textColorOptions.find(colorOption => colorOption.value === config.textColor)}
                        rules={{
                            required: localizer.msg('idea.form.field.errors.required')
                        }}
                        render={({field}) =>
                            <IdeascaleSelect
                                inputId={FIELD_NAMES.textColor}
                                name={FIELD_NAMES.textColor}
                                isMulti={false}
                                value={field.value}
                                options={textColorOptions}
                                onChange={(selectedOption: any) => {
                                    setConfigUpdated(prevState => !prevState ? true : prevState);
                                    field.onChange(selectedOption);
                                }}
                            />
                        }/>
                </div>
                <div className="form-group mt-5 text-end">
                    <button className="btn btn-cancel me-3" type="button" onClick={toggle}
                            data-test-element-id="btn-cancel">
                        {localizer.msg('common.actions.cancel')}
                    </button>
                    <ActionButton type="submit" form={`general-text-form-${config.id}`}
                                  loading={isSubmitting} disabled={!configUpdated} data-test-element-id="btn-submit">
                        {localizer.msg('common.actions.submit')}
                    </ActionButton>
                </div>
            </form>
        </Modal>
    );
};