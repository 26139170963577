import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {Icon, ParagraphSkeleton, Scrollbar, ScrollbarInstance, Button} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {
    AlertEvent,
    AlertType,
    buildAlertEventData,
    CLASSIFICATION_NAME,
    ClassificationLabel,
    ClassificationUtil,
    eventDispatcher,
    InfiniteScrollLoadMoreButton,
    isHtmlInject,
    isJavascriptInject,
    KEYBOARD_KEYS,
    LinkIdeaQualifier,
    LinkQualifier,
    Localizer,
    PagedResponseContent,
    StringUtil,
    useApiErrorResponseHandler,
    useIntersectionObserver,
    useToggle
} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {appLinks} from 'services/AppLinks';
import {AddLinkAction} from '../../shared/AddLinkAction';
import {LinkableIdea} from 'models/LinkableIdea';
import {PageParameters} from 'models/types/PageParameters';
import './LinkableIdeas.scss';

type LinkableIdeasProps = {
    localizer: Localizer;
    ideaId: number;
    ideaTitle: string;
    ideaDescription?: string;
    fetchLinkableIdeas: (ideaId: number, ideaTitle: string, ideaDescription: string, pageParameters: PageParameters) => Promise<PagedResponseContent<LinkableIdea>>;
    fetchLinkableIdeasByKeywords: (ideaId: number, pageParameters: PageParameters) => Promise<PagedResponseContent<LinkableIdea>>;
    linkQualifiers: LinkQualifier;
    onClickQualifier: (linkedIdea: LinkableIdea, qualifier: LinkIdeaQualifier) => void;
    showDefaultValue?: boolean;
    showIdeaSearchField?: boolean;
    className?: string;
    SearchContainerTag?: React.ElementType;
    ideasContainerIdPrefix?: string;
}

export const LinkableIdeas = (props: LinkableIdeasProps) => {
    const {
        localizer,
        ideaId,
        ideaTitle = '',
        ideaDescription = '',
        linkQualifiers,
        fetchLinkableIdeas,
        fetchLinkableIdeasByKeywords,
        onClickQualifier,
        showDefaultValue = false,
        showIdeaSearchField = false,
        className = '',
        SearchContainerTag = 'div',
        ideasContainerIdPrefix = ''
    } = props;
    const {communityConfig} = useAppContext();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const searchTerms = useRef<string>('');
    const searchInputRef = useRef<HTMLInputElement>(null);
    const scrollContainer = useRef<ScrollbarInstance>(null);
    const loadMoreButtonRef = useRef<HTMLButtonElement>(null);
    const fetchByKeywords = useRef<boolean>(false);

    const [loading, setLoading] = useToggle(false);
    const [hasMore, setHasMore] = useState(false);
    const [linkableIdeas, setLinkableIdeas] = useState<LinkableIdea[]>([]);

    const currentPage = useRef(0);

    const loadLinkableIdeas = useCallback(async () => {
        try {
            let pagedResponse: PagedResponseContent<LinkableIdea>;
            setLoading(true);
            if (fetchByKeywords.current) {
                const keywords = searchInputRef.current ? searchInputRef.current.value : '';
                pagedResponse = await fetchLinkableIdeasByKeywords(ideaId, {
                    term: keywords,
                    page: currentPage.current,
                });
            } else {
                pagedResponse = await fetchLinkableIdeas(ideaId, ideaTitle, ideaDescription, {
                    page: currentPage.current,
                    limit: 7
                });
            }
            setLoading(false);
            currentPage.current = currentPage.current + 1;
            setHasMore(pagedResponse.hasMore);
            setLinkableIdeas(prevIdeas => {
                return [...prevIdeas, ...pagedResponse.content];
            });
        } catch (error: any) {
            handleErrorResponse(error);
        }
    }, [fetchLinkableIdeas, fetchLinkableIdeasByKeywords, handleErrorResponse, ideaDescription, ideaId, ideaTitle, setLoading]);

    const searchLinkableIdeas = () => {
        const keywords = searchInputRef.current ? searchInputRef.current.value : '';
        if (isHtmlInject(keywords)) {
            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.warn, localizer.msg('ideas.alerts.html-warning')));
        } else if (isJavascriptInject(keywords)) {
            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.warn, localizer.msg('ideas.alerts.javascript-warning')));
        } else {
            fetchByKeywords.current = true;
            searchTerms.current = keywords;
            currentPage.current = 0;
            setLinkableIdeas([]);
            loadLinkableIdeas().then();
        }
    };

    useIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: loadLinkableIdeas,
        enabled: hasMore,
        options: {
            root: scrollContainer.current?.container
        }
    });

    useEffect(() => {
        searchTerms.current = '';
        if (ideaTitle || ideaDescription) {
            fetchByKeywords.current = false;
            searchTerms.current = ideaTitle + ' ' + StringUtil.removeMarkdown(ideaDescription);
            currentPage.current = 0;
            setLinkableIdeas([]);
            loadLinkableIdeas().then();

        }
    }, [ideaDescription, ideaTitle, loadLinkableIdeas]);

    return (
        <section className={`linkable-ideas ${className}`}>
            <SearchContainerTag className={`input-group z-index-4 ${!showIdeaSearchField ? 'd-none' : ''}`}>
                <Button type="button" color="outline-secondary"
                        data-test-element-id="search-idea"
                        className="btn btn-outline-secondary idea-links-search-btn shadow-none"
                        title={localizer.msg('search.label')}
                        onClick={searchLinkableIdeas}>
                    <Icon iconSpritePath={svgIconsPath} name="magnifying-glass-left"/>
                </Button>
                <label className="sr-only" htmlFor="linked-ideas-search">{localizer.msg('search.label')}</label>
                <input className="form-control rounded-bottom-end-0" data-test-element-id="search-input"
                       ref={searchInputRef}
                       id="linked-ideas-search"
                       onKeyPress={(e) => {
                           if (e.key === KEYBOARD_KEYS.ENTER) {
                               e.preventDefault();
                               searchLinkableIdeas();
                           }
                       }}
                       name="query"
                       type="text"
                       autoComplete="off"
                       defaultValue={showDefaultValue ? searchTerms.current : ''}
                       placeholder={localizer.msg('ideas.terms.linked.search-placeholder')}/>
            </SearchContainerTag>


            <Scrollbar
                tabIndex={-1}
                className={`border-1 ${showIdeaSearchField ? 'rounded-bottom' : 'rounded'} search-and-select-ideas pt-1 pb-1`}
                autoHeight
                ref={scrollContainer}
                autoHeightMin={300}
                autoHeightMax={500}>
                {
                    linkableIdeas.map(idea =>
                        <div className="d-flex py-1 align-items-center" key={idea.id}
                             data-test-element-id="linkable-idea">
                            <div className="flex-grow-1">
                                <Link className="idea-title-link" to={appLinks.ideaDetails(idea.id)}
                                      target={`ideascale-idea-${idea.id}`}>
                                    {communityConfig.classificationEnabled && idea.attributes &&
                                        <ClassificationLabel
                                            classification={ClassificationUtil.getAttributeByName(CLASSIFICATION_NAME.EFFECTIVE_CLASSIFICATION, idea.attributes)}
                                            extraClasses="align-middle me-1 align-bottom"/>
                                    }
                                    {idea.title}
                                </Link>
                            </div>
                            <div>
                                <AddLinkAction localizer={localizer} linkableIdea={idea}
                                               containerElementId={`${ideasContainerIdPrefix}-search-and-select-ideas`}
                                               onClickQualifier={onClickQualifier}
                                               linkQualifiers={linkQualifiers}/>
                            </div>
                        </div>
                    )
                }
                <InfiniteScrollLoadMoreButton hasMore={hasMore}
                                              localizer={localizer}
                                              loading={loading}
                                              onCLick={loadLinkableIdeas}
                                              skeletonComponent={
                                                  <div className="py-2">
                                                      <ParagraphSkeleton rows={10}/>
                                                  </div>
                                              }
                                              loadMoreButtonRef={loadMoreButtonRef}/>
                {
                    linkableIdeas.length === 0 &&
                    <span className="d-inline-block no-data-found">
                        {
                            searchTerms.current
                                ? localizer.msg('ideas.terms.linked.no-ideas-found-with-terms', {terms: searchTerms.current})
                                : localizer.msg('ideas.terms.linked.no-ideas-found')
                        }
                    </span>
                }
            </Scrollbar>
        </section>
    );
};
