import React from 'react';
import {Icon} from '@ideascale/ui';
import {Localizer} from '@ideascale/commons';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import './IdeaDetailsNav.scss';

type IdeaDetailsNavProps = {
    localizer: Localizer;
    currentIndex: number;
    totalItems: number;
    onClickPrev: () => void;
    onClickNext: () => void;
}

export const IdeaDetailsNav = (props: IdeaDetailsNavProps) => {
    const {localizer, currentIndex, totalItems, onClickPrev, onClickNext} = props;

    if (currentIndex === -1) {
        return null;
    }

    return (
        <nav className="idea-nav text-center clearfix border-bottom-1 px-2 fw-bold" aria-label={localizer.msg('ideas.navigation')}>
            <span className="sr-only">{localizer.msg('ideas.navigation')}</span>
            <span className="pager-index">{currentIndex + 1}</span> {localizer.msg('common.of')} <span
            className="pager-total">{totalItems}</span>
            {
                <button
                    className={`prev btn btn-link px-0 float-start text-gray shadow-none ${currentIndex < 1 ? 'invisible' : ''}`}
                    aria-hidden={currentIndex < 1}
                    type="button"
                    onClick={onClickPrev}>
                    <Icon className="position-relative pos-top-n1" fill="#666" iconSpritePath={svgIconsPath}
                          name="triangle-left"/>
                    <span className="d-none d-md-inline-block">{localizer.msg('ideas.prev-idea')}</span>
                </button>
            }
            {
                <button
                    className={`next btn btn-link px-0 float-end text-gray shadow-none ${totalItems === currentIndex + 1 ? 'invisible' : ''}`}
                    aria-hidden={totalItems === currentIndex + 1}
                    type="button"
                    onClick={onClickNext}>
                    <span className="d-none d-md-inline-block">{localizer.msg('ideas.next-idea')}</span>
                    <Icon className="position-relative pos-top-n1" fill="#666" iconSpritePath={svgIconsPath}
                          name="triangle-right"/>
                </button>
            }
        </nav>
    );
};
