import React, {Fragment, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {ParagraphSkeleton, Tag} from '@ideascale/ui';
import {eventDispatcher, Localizer, PagedResponseContent, PageTheme} from '@ideascale/commons';
import {DisplayHeader} from 'components/landing-page/DisplayHeader';
import {appLinks} from 'services/AppLinks';
import {Component} from 'models/landing-page/Component';
import {TagData} from 'models/TagData';
import {SCROLL_EVENTS} from 'constants/AppConstants';
import 'components/landing-page/CommunityTagsComponent.scss';

type CommunityTagsComponentProps = {
    localizer: Localizer;
    editMode?: boolean;
    data: Component;
    theme: PageTheme;
    getTags: () => Promise<PagedResponseContent<TagData>>;
}

export const CommunityTagsComponent = (props: CommunityTagsComponentProps) => {
    const {localizer, editMode = false, data, theme, getTags} = props;
    const [tags, setTags] = useState<string[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        getTags()
            .then(data => setTags(data.content.map(item => item.label)))
            .finally(() => setLoading(false));
    }, [getTags]);

    return (
        <Fragment>
            <DisplayHeader theme={theme} title={data.title}
                           subtitle={theme === PageTheme.EASTMAN ? data.subtitle : ''}/>
            <ul className={`list-inline ${theme === PageTheme.CURIE ? 'text-center' : ''}`}>
                {
                    loading
                        ? <ParagraphSkeleton rows={3}/>
                        : (
                            tags.length > 0
                                ? (
                                    tags.map((item, index) =>
                                        <li className="list-inline-item me-3" key={index}>
                                            <Link to={appLinks.tag(item)}
                                                  title={item}
                                                  {...(editMode ? {tabIndex: -1} : {})}
                                                  onClick={() => eventDispatcher.dispatch(SCROLL_EVENTS.SCROLL_TOP)}>
                                                <Tag label={item}/>
                                            </Link>
                                        </li>)
                                )
                                :
                                (
                                    <li className="panel card">
                                        <div className="card-body panel-body">
                                            <div className="alert alert-warning mb-0 text-center">
                                                {localizer.msg('landing-page.components.common.no-tags')}
                                            </div>
                                        </div>
                                    </li>
                                )
                        )
                }
            </ul>
        </Fragment>
    );
};