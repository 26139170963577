import {ImageryOperation} from './ImageryOperation';
import {CampaignOperation} from './CampaignOperation';
import {CampaignOperationType} from './CampaignOperationType';

export class ChangeCampaignLogoOperation extends ImageryOperation implements CampaignOperation {
    campaignId: number;

    constructor(
        type: CampaignOperationType,
        fileId: number,
        public logoFrameEnabled: boolean,
        public logoOnBannerEnabled: boolean,
        campaignId: number,
    ) {
        super(type, fileId);
        this.campaignId = campaignId;
    }
}