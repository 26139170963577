import React, {useCallback} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {Campaign, CampaignsHolder} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {useCommunityService} from 'hooks/useService';
import {LeaderboardContextProvider} from 'contexts/LeaderboardContext';
import {useAppContext} from 'contexts/AppContext';
import {AllLeaderboardsTab} from 'components/leaderboard/AllLeaderboardsTab';
import {LeaderboardTab} from 'components/leaderboard/LeaderboardTab';
import {LeaderboardFilter} from 'components/leaderboard/LeaderboardFilter';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {LeaderboardParameters} from 'models/types/LeaderboardParameters';
import {TimePeriod} from 'models/enums/TimePeriod';
import {LeaderboardResponse} from 'models/LeaderboardResponse';
import {MemberGroup} from 'models/types/MemberGroup';
import {ROUTES} from 'shared/Routes';

export const LeaderboardContainer = ({campaignContext}: { campaignContext?: Campaign }) => {
    const localizer = useLocalizer();
    const {authentication: {actor}} = useAppContext();
    const communityService = useCommunityService();

    const fetchCampaigns = useCallback(async (params: PageParameters) => {
        if (communityService) {
            return await communityService.fetchLeaderboardCampaigns(params);
        }
        return PagedResponseContent.nullObject<CampaignsHolder<Campaign>>();
    }, [communityService]);

    const fetchGroups = useCallback(async (params: PageParameters) => {
        if (communityService) {
            return await communityService.fetchLeaderboardGroups(params);
        }
        return PagedResponseContent.nullObject<MemberGroup>();
    }, [communityService]);

    const fetchLeaderboard = useCallback(async (parameters: LeaderboardParameters, timePeriod?: { mode: TimePeriod, unit: number }) => {
        if (communityService) {
            return await communityService.fetchLeaderboard(parameters, timePeriod);
        }
        return LeaderboardResponse.EMPTY();
    }, [communityService]);

    return (
        <LeaderboardContextProvider campaignContext={campaignContext}>
            <LeaderboardFilter localizer={localizer} fetchGroups={fetchGroups} fetchCampaigns={fetchCampaigns}/>
            <Routes>
                <Route path={ROUTES.LEADERBOARD.TYPE}
                       element={<LeaderboardTab localizer={localizer} fetchLeaderboard={fetchLeaderboard}
                                                actorId={actor.id}/>}/>
                <Route path="/"
                       element={<AllLeaderboardsTab localizer={localizer} fetchLeaderboard={fetchLeaderboard}/>}/>
                <Route path="*" element={<Navigate to={ROUTES.LEADERBOARD.BASE}/>}/>
            </Routes>
        </LeaderboardContextProvider>
    );
};