import {Operation} from './Operation';
import {CommunityOperationType} from './CommunityOperationType';

export class PublishCommunityHomeOperation implements Operation {
    private readonly type: CommunityOperationType;
    constructor() {
        this.type = CommunityOperationType.PUBLISH_COMMUNITY_HOME_CONFIG;
    }
    getType(): CommunityOperationType {
        return this.type;
    }
}