import {Type} from 'class-transformer';
import {ActivityStreamData} from './ActivityStreamData';

export class PagedResponseActivityStream {
    @Type(() => ActivityStreamData)
    content: ActivityStreamData[];
    hasMore: boolean;
    pageNo: number;
    pageSize: number;
    totalElements: number;
}