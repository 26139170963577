import React, {useCallback, useState} from 'react';
import {PageTheme, useApiErrorResponseHandler} from '@ideascale/commons';
import {useDefaultImage} from 'hooks/useDefaultImage';
import {useLandingPageService} from 'hooks/useService';
import {useLocalizer} from 'hooks/useLocalizer';
import {MediaContainer} from 'containers/landing-page/MediaContainer';
import {GeneralTextComponent} from 'components/landing-page/GeneralTextComponent';
import {ConfigWrapper} from 'components/landing-page/ConfigWrapper';
import {ButtonsComponent} from 'components/landing-page/ButtonsComponent';
import {Media} from 'models/landing-page/Media';
import {Button} from 'models/landing-page/Button';
import {HeroType} from 'models/enums/landing-page/HeroType';
import {TextType} from 'models/enums/landing-page/TextType';
import {GeneralText} from 'models/landing-page/GeneralText';
import {HeroComponentData} from 'models/landing-page/HeroComponentData';
import {RichTextToolbarType} from 'models/enums/landing-page/RichTextToolbarType';
import {TranslationContentType} from 'models/enums/landing-page/TranslationContentType';
import {TranslationContentResponse} from 'models/landing-page/TranslationContentResponse';
import 'containers/landing-page/HeroComponentContainer.scss';

type HeroComponentContainerType = {
    showConfig: boolean,
    data: HeroComponentData,
    theme: PageTheme,
    showTranslationConfig: boolean,
    onComponentEdited: () => void,
    translationLanguageId?: number,
}

const HERO_PRIMARY_HEADING_CHARACTER_LIMIT = 40;
const HERO_PRIMARY_PARAGRAPH_CHARACTER_LIMIT = 245;

export const HeroComponentContainer = (props: HeroComponentContainerType) => {
    const {showConfig, data, theme, showTranslationConfig, translationLanguageId, onComponentEdited} = props;
    const localizer = useLocalizer();
    const [heroConfig, setHeroConfig] = useState(data);
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const {getDefaultImages, containsDefaultImage} = useDefaultImage(theme);
    const landingPageService = useLandingPageService();

    const saveHeroComponentData = useCallback(async (component: HeroComponentData) => {
        if (landingPageService) {
            return landingPageService.saveHeroComponentConfig(component);
        }
        return HeroComponentData.EMPTY;
    }, [landingPageService]);

    const updateData = useCallback(async (component: HeroComponentData) => {
        try {
            const response = await saveHeroComponentData(component);
            if (response) {
                setHeroConfig(response);
            }
        } catch (error: any) {
            handleErrorResponse(error);
        }
    }, [handleErrorResponse, saveHeroComponentData]);

    const onToggleVisibility = useCallback(async () => {
        const newData = {...heroConfig};
        newData.visible = !newData.visible;
        await updateData(newData);
        onComponentEdited();
    }, [heroConfig, onComponentEdited, updateData]);

    const onToggleMediaVisibility = useCallback(async (data: Media) => {
        const newData = {...heroConfig};
        const newMedia = newData.medias.find(media => media.id === data.id);
        if (newMedia) {
            newMedia.visible = !data.visible;
            await updateData(newData);
            onComponentEdited();
        }
    }, [heroConfig, onComponentEdited, updateData]);

    const onToggleTextVisibility = useCallback(async (data: GeneralText) => {
        const newData = {...heroConfig};
        const newText = newData.texts?.find(text => text.id === data.id);
        if (newText) {
            newText.visible = !data.visible;
            await updateData(newData);
            onComponentEdited();
        }
    }, [heroConfig, onComponentEdited, updateData]);

    const onToggleButtonVisibility = useCallback(async (data: Button) => {
        const newData = {...heroConfig};
        const newButton = newData.buttons?.find(button => button.id === data.id);
        if (newButton) {
            newButton.visible = !data.visible;
            await updateData(newData);
            onComponentEdited();
        }
    }, [heroConfig, onComponentEdited, updateData]);

    const onUpdateText = useCallback(async (data: GeneralText) => {
        const newData = {...heroConfig};
        const newText = newData.texts?.find(text => text.id === data.id);
        if (newText) {
            newText.text = data.text;
            newText.textColor = data.textColor;
            await updateData(newData);
        }
    }, [heroConfig, updateData]);

    const onUpdateButton = useCallback(async (data: Button) => {
        const newData = {...heroConfig};
        const newButton = newData.buttons?.find(button => button.id === data.id);
        if (newButton) {
            newButton.text = data.text;
            await updateData(newData);
        }
    }, [heroConfig, updateData]);

    const onTextTranslated = useCallback((response: TranslationContentResponse) => {
        setHeroConfig(prev => {
            const newData = {...prev};
            if (response.contentType === TranslationContentType.PAGE_COMPONENT_TITLE) {
                newData.title = response.translation;
            } else if (response.contentType === TranslationContentType.PAGE_COMPONENT_SUBTITLE) {
                newData.subtitle = response.translation;
            } else if (response.contentType === TranslationContentType.COMPONENT_BUTTON_TEXT) {
                const buttonComponentToUpdate = newData.buttons?.find(button => button.id === response.sourceObjectId);
                if (buttonComponentToUpdate) {
                    buttonComponentToUpdate.text = response.translation;
                }
            } else {
                const textComponentToUpdate = newData.texts?.find(text => text.id === response.sourceObjectId);
                if (textComponentToUpdate) {
                    textComponentToUpdate.text = response.translation;
                }
            }
            return newData;
        });
    }, []);

    const updateMediaData = useCallback(async (data: Media) => {
        try {
            const newData = {...heroConfig};
            const newMedia = newData.medias.find(media => media.id === data.id);
            if (newMedia) {
                newMedia.type = data.type;
                newMedia.url = data.url;
                newMedia.type = data.type;
                newMedia.altText = data.altText;
                await updateData(newData);
            }
        } catch (error: any) {
            handleErrorResponse(error);
        }
    }, [handleErrorResponse, heroConfig, updateData]);

    const calculateMaxCharacterLimit = useCallback((textType: TextType) => {
        if (heroConfig.heroType === HeroType.PRIMARY) {
            if (textType === TextType.HEADING) {
                return HERO_PRIMARY_HEADING_CHARACTER_LIMIT;
            } else {
                return HERO_PRIMARY_PARAGRAPH_CHARACTER_LIMIT;
            }
        } else {
            return undefined;
        }
    }, [heroConfig.heroType]);

    const renderAceessibleName = useCallback(() => {
        switch (data.heroType) {
            case HeroType.PRIMARY:
                return localizer.msg('landing-page.hero-primary');
            case HeroType.SECONDARY:
                return localizer.msg('landing-page.hero-secondary');
            case HeroType.GENERAL:
            default:
                return localizer.msg('landing-page.hero-general');
        }
    }, [data.heroType, localizer]);

    const renderHeroSecondaryToolbar = useCallback((text: GeneralText) => {
        return text.type === TextType.HEADING
            ? RichTextToolbarType.TITLE_TEXT
            : theme === PageTheme.ARMSTRONG
                ? RichTextToolbarType.UNALIGNED_TEXT_ONLY
                : RichTextToolbarType.MINIMAL;
    }, [theme]);

    const renderHeroPrimary = () => {
        switch (theme) {
            case PageTheme.EASTMAN:
                return (
                    <div className={`${showTranslationConfig ? 'translation-mode' : ''}`}>
                        {
                            heroConfig.medias?.length > 0 &&
                            <MediaContainer theme={theme} onToggleVisibility={onToggleMediaVisibility}
                                            showConfig={showConfig} imageCropHeight={550} imageCropWidth={1140}
                                            data={heroConfig.medias[0]} updateMediaData={updateMediaData}
                                            allowVideo={false}
                                            mediaPreviewMode="full"
                                            onComponentEdited={onComponentEdited}
                                            defaultImage={containsDefaultImage(heroConfig.medias[0].url) ? getDefaultImages(heroConfig)[0] : undefined}/>
                        }
                        <div
                            className="col-12 d-flex flex-column align-items-center justify-content-center hero-primary-texts position-relative">
                            {
                                heroConfig.texts?.map(text => (
                                    <GeneralTextComponent data={text}
                                                          key={text.type}
                                                          toolbarType={text.type === TextType.HEADING ? RichTextToolbarType.TITLE_TEXT : RichTextToolbarType.MINIMAL}
                                                          className="text-center"
                                                          onComponentEdited={onComponentEdited}
                                                          maxCharacterLimit={calculateMaxCharacterLimit(text.type)}
                                                          showConfig={showConfig}
                                                          onUpdateText={onUpdateText}
                                                          onTextTranslated={onTextTranslated}
                                                          onToggleVisibility={onToggleTextVisibility}
                                                          showTranslationConfig={showTranslationConfig}
                                                          translationLanguageId={translationLanguageId}
                                                          accessibleName={localizer.msg('landing-page.components.common.title')}/>
                                ))
                            }
                            {
                                heroConfig.buttons?.length > 0 &&
                                <ButtonsComponent showConfig={showConfig}
                                                  className="mt-3"
                                                  buttons={heroConfig.buttons}
                                                  onUpdateButton={onUpdateButton}
                                                  onTextTranslated={onTextTranslated}
                                                  onComponentEdited={onComponentEdited}
                                                  toggleVisibility={onToggleButtonVisibility}
                                                  showTranslationConfig={showTranslationConfig}
                                                  translationLanguageId={translationLanguageId}/>
                            }
                        </div>
                    </div>
                );
            case PageTheme.CURIE:
            default:
                return (
                    <div
                        className={`${showTranslationConfig ? 'translation-mode' : ''}`}>
                        {
                            heroConfig.medias?.length > 0 &&
                            <MediaContainer theme={theme} onToggleVisibility={onToggleMediaVisibility}
                                            showConfig={showConfig} imageCropHeight={550} imageCropWidth={1140}
                                            data={heroConfig.medias[0]} updateMediaData={updateMediaData}
                                            allowVideo={false} onComponentEdited={onComponentEdited}
                                            mediaPreviewMode="full"
                                            defaultImage={containsDefaultImage(heroConfig.medias[0].url) ? getDefaultImages(heroConfig)[0] : undefined}/>
                        }
                        <div
                            className="col-12 col-lg-8 ps-4 ps-md-5 d-flex flex-column align-items-start justify-content-center hero-primary-texts position-relative">
                            {
                                heroConfig.texts?.map(text => (
                                    <GeneralTextComponent data={text}
                                                          key={text.type}
                                                          toolbarType={text.type === TextType.HEADING ? RichTextToolbarType.TITLE_TEXT : RichTextToolbarType.MINIMAL}
                                                          className="w-100"
                                                          showConfig={showConfig}
                                                          onUpdateText={onUpdateText}
                                                          onTextTranslated={onTextTranslated}
                                                          onComponentEdited={onComponentEdited}
                                                          onToggleVisibility={onToggleTextVisibility}
                                                          showTranslationConfig={showTranslationConfig}
                                                          translationLanguageId={translationLanguageId}
                                                          maxCharacterLimit={calculateMaxCharacterLimit(text.type)}/>
                                ))
                            }
                            {
                                heroConfig.buttons?.length > 0 &&
                                <ButtonsComponent showConfig={showConfig}
                                                  buttons={heroConfig.buttons}
                                                  onUpdateButton={onUpdateButton}
                                                  onTextTranslated={onTextTranslated}
                                                  onComponentEdited={onComponentEdited}
                                                  toggleVisibility={onToggleButtonVisibility}
                                                  showTranslationConfig={showTranslationConfig}
                                                  translationLanguageId={translationLanguageId}/>
                            }
                        </div>
                        <div className="col-lg-4 col-12"/>
                    </div>
                );
        }
    };

    const renderComponent = () => {
        switch (data.heroType) {
            case HeroType.PRIMARY:
                return renderHeroPrimary();
            case HeroType.SECONDARY:
                return (
                    <div className={`row ${showConfig || showTranslationConfig ? 'pt-3' : ''}`}>
                        <div className="col-12 col-md-2">
                            {
                                heroConfig.medias?.length > 0 &&
                                <MediaContainer theme={theme} onToggleVisibility={onToggleMediaVisibility}
                                                onComponentEdited={onComponentEdited}
                                                mediaPreviewMode="small"
                                                defaultImage={containsDefaultImage(heroConfig.medias[0].url) ? getDefaultImages(heroConfig)[0] : undefined}
                                                showConfig={showConfig} data={heroConfig.medias[0]} imageCropWidth={165}
                                                imageCropHeight={165} updateMediaData={updateMediaData}/>
                            }
                        </div>
                        <div
                            className={`col-12 col-md-8 hero-secondary-texts ${theme === PageTheme.EASTMAN ? 'py-5' : ''}`}>
                            {
                                heroConfig.texts?.map(text => <GeneralTextComponent data={text}
                                                                                    key={text.type}
                                                                                    showConfig={showConfig}
                                                                                    toolbarType={renderHeroSecondaryToolbar(text)}
                                                                                    onUpdateText={onUpdateText}
                                                                                    onComponentEdited={onComponentEdited}
                                                                                    onTextTranslated={onTextTranslated}
                                                                                    maxCharacterLimit={calculateMaxCharacterLimit(text.type)}
                                                                                    onToggleVisibility={onToggleTextVisibility}
                                                                                    translationLanguageId={translationLanguageId}
                                                                                    showTranslationConfig={showTranslationConfig}/>
                                )
                            }
                            {
                                heroConfig.buttons?.length > 0 &&
                                <ButtonsComponent className="justify-content-center mt-4" showConfig={showConfig}
                                                  buttons={heroConfig.buttons}
                                                  onUpdateButton={onUpdateButton}
                                                  onTextTranslated={onTextTranslated}
                                                  onComponentEdited={onComponentEdited}
                                                  toggleVisibility={onToggleButtonVisibility}
                                                  showTranslationConfig={showTranslationConfig}
                                                  translationLanguageId={translationLanguageId}/>
                            }
                        </div>
                        {
                            heroConfig.medias?.length > 1 &&
                            <div className="col-12 col-md-2">
                                <MediaContainer theme={theme}
                                                imageCropWidth={165}
                                                imageCropHeight={165}
                                                mediaPreviewMode="small"
                                                showConfig={showConfig}
                                                data={heroConfig.medias[1]}
                                                updateMediaData={updateMediaData}
                                                onComponentEdited={onComponentEdited}
                                                onToggleVisibility={onToggleMediaVisibility}
                                                defaultImage={containsDefaultImage(heroConfig.medias[1].url) ? getDefaultImages(heroConfig)[1] : undefined}/>
                            </div>
                        }
                    </div>
                );
            case HeroType.GENERAL:
            default:
                return (
                    <div className="row">
                        <div
                            className={`col-lg-6 d-flex flex-column justify-content-center hero-general-texts order-first`}>
                            {
                                heroConfig.texts?.map(text => (
                                    <GeneralTextComponent data={text}
                                                          key={text.type}
                                                          showConfig={showConfig}
                                                          toolbarType={text.type === TextType.HEADING ? RichTextToolbarType.TITLE_TEXT : RichTextToolbarType.MINIMAL}
                                                          onUpdateText={onUpdateText}
                                                          onComponentEdited={onComponentEdited}
                                                          maxCharacterLimit={calculateMaxCharacterLimit(text.type)}
                                                          onTextTranslated={onTextTranslated}
                                                          onToggleVisibility={onToggleTextVisibility}
                                                          translationLanguageId={translationLanguageId}
                                                          showTranslationConfig={showTranslationConfig}/>
                                ))
                            }
                            {
                                heroConfig.buttons?.length > 0 &&
                                <ButtonsComponent showConfig={showConfig}
                                                  buttons={heroConfig.buttons}
                                                  onUpdateButton={onUpdateButton}
                                                  onTextTranslated={onTextTranslated}
                                                  onComponentEdited={onComponentEdited}
                                                  toggleVisibility={onToggleButtonVisibility}
                                                  showTranslationConfig={showTranslationConfig}
                                                  translationLanguageId={translationLanguageId}/>
                            }
                        </div>
                        <div
                            className={`col-lg-6 order-last order-lg-0`}>
                            {
                                heroConfig.medias?.length > 0 &&
                                <MediaContainer theme={theme}
                                                imageCropWidth={555}
                                                imageCropHeight={430}
                                                showConfig={showConfig}
                                                mediaPreviewMode="medium"
                                                onComponentEdited={onComponentEdited}
                                                onToggleVisibility={onToggleMediaVisibility}
                                                data={heroConfig.medias[0]} updateMediaData={updateMediaData}
                                                defaultImage={containsDefaultImage(heroConfig.medias[0].url) ? getDefaultImages(heroConfig)[0] : undefined}/>
                            }
                        </div>
                    </div>
                );
        }
    };

    return (
        <ConfigWrapper showConfig={showConfig}
                       visible={heroConfig.visible}
                       localizer={localizer}
                       toggleVisibility={showTranslationConfig ? undefined : onToggleVisibility}
                       className={heroConfig.attribute?.className}
                       accessibleName={renderAceessibleName()}>
            {renderComponent()}
        </ConfigWrapper>
    );
};