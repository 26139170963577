export enum CommunityEditableFieldType {
    COMMUNITY_TITLE = 'COMMUNITY_TITLE',
    COMMUNITY_SUBTITLE = 'COMMUNITY_SUBTITLE',
    COMMUNITY_TILE_IMAGE = 'COMMUNITY_TILE_IMAGE',
    IDEA_LIST_VIEW_MODE = 'IDEA_LIST_VIEW_MODE',
    IDEA_LIST_MODE = 'IDEA_LIST_MODE',
    TOPBAR_LOGO = 'TOPBAR_LOGO',
    BANNER_IMAGE = 'BANNER_IMAGE',
    COMMUNITY_LOGO = 'COMMUNITY_LOGO',
    CUSTOM_FAVICON = 'CUSTOM_FAVICON',
    COMMUNITY_WIDGET = 'COMMUNITY_WIDGET',
    CUSTOM_WIDGET = 'CUSTOM_WIDGET',
    SIDEBAR = 'SIDEBAR',
    UNKNOWN = 'UNKNOWN',
}