import React from 'react';
import {DragDropContext, Droppable, DropResult, ResponderProvided} from 'react-beautiful-dnd';
import {Localizer} from '@ideascale/commons';
import {useEditModeContext} from 'contexts/EditModeContext';
import {SidebarEditableElement} from './SidebarEditableElement';
import {SidebarItem} from 'models/sidebar/SidebarItem';
import {SidebarItemKey} from 'models/enums/SidebarItemKey';
import {CommunityOperationType} from 'models/edit-mode/CommunityOperationType';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';

type SidebarDragAndDropElementsProps = {
    localizer: Localizer;
    onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
    sidebarItems: SidebarItem[];
    onEditButtonClick: (sidebarItem: SidebarItem) => void;
    onVisibilityButtonClick: (sidebarItem: SidebarItem) => void;
}

export const SidebarDragAndDropElements = (props: SidebarDragAndDropElementsProps) => {
    const {onDragEnd, sidebarItems, onEditButtonClick, onVisibilityButtonClick, localizer} = props;
    const {validationErrors} = useEditModeContext();

    const hasError = (sidebarIter: SidebarItem) => {
        const {key} = sidebarIter;
        const errorMap: Record<string, CommunityOperationType | CampaignOperationType> = {
            [SidebarItemKey.COMMUNITY_SUMMARY]: CommunityOperationType.CHANGE_COMMUNITY_WIDGET,
            [SidebarItemKey.CONTACT]: CommunityOperationType.CHANGE_CONTACT_WIDGET,
            [SidebarItemKey.CAMPAIGN_CUSTOM_WIDGET_ONE]: CampaignOperationType.CHANGE_CAMPAIGN_WIDGET_ONE,
            [SidebarItemKey.CAMPAIGN_CUSTOM_WIDGET_TWO]: CampaignOperationType.CHANGE_CAMPAIGN_WIDGET_TWO,
            [SidebarItemKey.CAMPAIGN_CUSTOM_WIDGET_THREE]: CampaignOperationType.CHANGE_CAMPAIGN_WIDGET_THREE
        };
        return validationErrors.hasError(errorMap[key]);
    };


    return (
        <DragDropContext onDragEnd={onDragEnd} dragHandleUsageInstructions={localizer.msg('frontend-shared.common.drag-drop-info')}>
            <Droppable droppableId="droppable">
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {
                            sidebarItems.map((item, index) => (
                                item && <SidebarEditableElement
                                    localizer={localizer}
                                    hasError={hasError(item)}
                                    key={index}
                                    sidebarItem={item}
                                    index={index}
                                    onVisibilityButtonClick={onVisibilityButtonClick}
                                    onEditButtonClick={onEditButtonClick}/>
                            ))
                        }
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};