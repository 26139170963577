import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {OptionType} from '@ideascale/ui';
import {LabelData, Localizer, PagedResponseContent} from '@ideascale/commons';
import {CustomFieldOption, SearchState, useSearchContext} from 'contexts/SearchContext';
import {useAppContext} from 'contexts/AppContext';
import {ROUTES} from 'shared/Routes';
import {DependantCustomSearchField} from './DependantCustomSearchField';
import {TimeFrameSearchField} from './TimeFrameSearchField';
import {LabelSearchField} from './LabelSearchField';
import {StageSearchField} from './StageSearchField';
import {TagSearchField} from './TagSearchField';
import {ModeratorTagSearchField} from './ModeratorTagSearchField';
import {CustomSearchField} from './CustomSearchField';
import {ArchiveCampaignSearchField} from './ArchiveCampaignSearchField';
import {FilterActionButtons} from './FilterActionButtons';
import {PageParameters} from 'models/types/PageParameters';
import {StagesHolder} from 'models/StagesHolder';
import {StageGroup} from 'models/StageGroup';
import {CustomFieldData} from 'models/CustomFieldData';
import {SearchCategory} from 'models/enums/SearchCategory';

type AllSearchFilterProps = {
    localizer: Localizer;
    fetchLabels(pageParameters: PageParameters): Promise<PagedResponseContent<LabelData>>;
    fetchTags(pageParameters: PageParameters): Promise<PagedResponseContent<string>>;
    fetchModeratorTags(pageParameters: PageParameters): Promise<PagedResponseContent<string>>;
    fetchStages(pageParameters: PageParameters): Promise<PagedResponseContent<StagesHolder<StageGroup>>>;
    fetchIdeasCustomFields(pageParameters: PageParameters, campaignIds?: number, includeArchivedCampaigns?: boolean): Promise<PagedResponseContent<StageGroup>>;
    fetchDependantCustomFields(fieldId: number): Promise<CustomFieldData>;
    onClearFilters: () => void;
    onApplyFilters: (params: Partial<SearchState>) => void;
}

export const AllSearchFilter = (props: AllSearchFilterProps) => {
    const {
        localizer,
        fetchLabels,
        fetchStages,
        fetchTags,
        fetchModeratorTags,
        fetchIdeasCustomFields,
        fetchDependantCustomFields,
        onClearFilters,
        onApplyFilters,
    } = props;

    const {
        fromDate,
        toDate,
        archiveCampaign: includeArchiveCampaign,
        labels,
        tags,
        moderatorTags,
        stages,
        ideaCustomFields: ideaCustomFieldsDefaultValue,
        setSearchCategory
    } = useSearchContext();
    const {authentication, communityConfig} = useAppContext();
    const navigate = useNavigate();
    const [dates, setDates] = useState<Date[]>([]);
    const [archiveCampaign, setIncludeArchivedCampaigns] = useState(true);
    const [selectedLabels, setSelectedLabels] = useState<OptionType[]>([]);
    const [selectedIdeaCustomFields, setSelectedIdeaCustomFields] = useState<CustomFieldOption[]>([]);
    const [selectedTags, setSelectedTags] = useState<OptionType[]>([]);
    const [selectedModeratorTags, setSelectedModeratorTags] = useState<OptionType[]>([]);
    const [selectedStages, setSelectedStages] = useState<OptionType[]>([]);

    const onChangeCustomIdeaField = (options: OptionType[] | null) => {
        if (options && options.length > 0) {
            const newValue: CustomFieldOption[] = [];
            options.forEach(option => {
                const found = selectedIdeaCustomFields.find(field => field.field.value === option.value);
                if (!found) {
                    newValue.push({field: option, values: []});
                } else {
                    newValue.push(found);
                }
            });
            setSelectedIdeaCustomFields(newValue);
        } else {
            setSelectedIdeaCustomFields([]);
        }
    };

    const onChangeIdeaDependantField = (fieldId: number, options: OptionType[] | null) => {
        const ideaField = selectedIdeaCustomFields.find(field => field.field.value === fieldId);
        if (ideaField) {
            const newIdeaFields = [...selectedIdeaCustomFields];
            const index = selectedIdeaCustomFields.findIndex(iField => iField.field.value === fieldId);
            if (options && options.length > 0) {
                newIdeaFields[index] = {...ideaField, values: options};
            } else {
                newIdeaFields[index] = {...ideaField, values: []};
            }
            setSelectedIdeaCustomFields(newIdeaFields);
        }
    };

    const updateSelectedValue = (values: OptionType[] | null, stateActionMethod: Dispatch<SetStateAction<any>>) => {
        if (values && values.length > 0) {
            stateActionMethod(values);
        } else {
            stateActionMethod([]);
        }
    };

    const onApplyFilter = useCallback(() => {
        let fromDate = null;
        let toDate = null;
        if (dates.length === 2) {
            fromDate = dates[0];
            toDate = dates[1];
        }
        onApplyFilters({
            fromDate,
            toDate,
            labels: selectedLabels,
            tags: selectedTags,
            moderatorTags: selectedModeratorTags,
            stages: selectedStages,
            ideaCustomFields: selectedIdeaCustomFields,
            archiveCampaign: archiveCampaign
        });
    }, [archiveCampaign, dates, onApplyFilters, selectedIdeaCustomFields, selectedLabels, selectedModeratorTags, selectedStages, selectedTags]);

    const clearLocalStateValues = useCallback(() => {
        setDates([]);
        setIncludeArchivedCampaigns(false);
        setSelectedLabels([]);
        setSelectedIdeaCustomFields([]);
        setSelectedTags([]);
        setSelectedModeratorTags([]);
        setSelectedStages([]);
    }, []);

    const onClearAllFiltersClick = useCallback(() => {
        clearLocalStateValues();
        onClearFilters();
    }, [clearLocalStateValues, onClearFilters]);

    const onCancel = useCallback(() => {
        onClearAllFiltersClick();
        setSearchCategory(SearchCategory.ALL);
        navigate(ROUTES.HOME);
    }, [navigate, onClearAllFiltersClick, setSearchCategory]);

    useEffect(() => {
        setDates(fromDate && toDate ? [fromDate, toDate] : []);
        setIncludeArchivedCampaigns(includeArchiveCampaign);
        setSelectedLabels(labels);
        setSelectedIdeaCustomFields(ideaCustomFieldsDefaultValue);
        setSelectedTags(tags);
        setSelectedModeratorTags(moderatorTags);
        setSelectedStages(stages);
    }, [fromDate, ideaCustomFieldsDefaultValue, includeArchiveCampaign, labels, moderatorTags, stages, tags, toDate]);

    return (
        <>
            <div className="row">
                <div className="col-12 col-lg-4">
                    <TimeFrameSearchField localizer={localizer} dates={dates} onChangeDate={setDates}/>
                </div>
                <div className="col-12 col-lg-4">
                    <LabelSearchField
                        fetchLabels={fetchLabels}
                        defaultValue={selectedLabels}
                        setLabels={(options) => updateSelectedValue(options, setSelectedLabels)}
                    />
                </div>
                <div className="col-12 col-lg-4">
                    <StageSearchField fetchStages={fetchStages}
                                      setStages={(options) => updateSelectedValue(options, setSelectedStages)}
                                      defaultValue={selectedStages}/>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-4">
                    <TagSearchField fetchTags={fetchTags} defaultValue={selectedTags} setTags={setSelectedTags}/>
                </div>
                {
                    communityConfig.moderatorTagEnabled && authentication.isModerator() && (
                        <div className="col-12 col-lg-4">
                            <ModeratorTagSearchField fetchModeratorTags={fetchModeratorTags}
                                                     defaultValue={selectedModeratorTags}
                                                     setModeratorTags={setSelectedModeratorTags}/>
                        </div>
                    )
                }
                <div className="col-12 col-lg-4">
                    <CustomSearchField
                        fetchIdeasCustomFields={fetchIdeasCustomFields}
                        values={selectedIdeaCustomFields.map(field => field.field)}
                        onChange={onChangeCustomIdeaField}
                    />
                </div>
            </div>
            <div className="row">
                {selectedIdeaCustomFields.map(item => {
                    return (
                        <div className="col-12 col-lg-4 mb-3" key={item.field.value}>
                            <DependantCustomSearchField
                                fieldId={item.field.value}
                                value={item.values}
                                mode="IDEA"
                                onChangeDependantField={onChangeIdeaDependantField}
                                fetchDependantCustomFields={fetchDependantCustomFields}
                            />
                        </div>
                    );
                })}
            </div>
            {
                !authentication.actor.isCommunityMember() && (
                    <div className="row mt-4">
                        <div className="col-12 col-lg-4">
                            <ArchiveCampaignSearchField
                                localizer={localizer}
                                isChecked={archiveCampaign}
                                setIsChecked={setIncludeArchivedCampaigns}
                            />
                        </div>
                    </div>
                )
            }
            <FilterActionButtons
                localizer={localizer}
                onClear={onClearAllFiltersClick}
                onApply={onApplyFilter}
                onCancel={onCancel}
            />
        </>
    );
};