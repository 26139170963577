import React, {Fragment, useCallback, useMemo} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {ButtonDropdown, DropdownMenu, DropdownToggle, Icon} from '@ideascale/ui';
import {
    AlertEvent,
    AlertType,
    Backdrop,
    buildAlertEventData,
    Campaign,
    ChangeCampaignModal,
    ClassificationAttachmentAttribute,
    ClassificationFieldAttribute,
    ClassificationTitle,
    CommentType,
    ConfirmationModal,
    EmailGroupType,
    EmailIdeaContent,
    EmailRequest,
    eventDispatcher,
    GroupEmailIdeaModal,
    HTML_TO_REACT_CONFIG,
    Member,
    PrivateMessageRequestParams,
    SuperCommentResponse,
    SuperComments,
    TagModal,
    TagParameters,
    TagSearchParameters,
    UnfurledUrl,
    UnfurledUrlList,
    useApiErrorResponseHandler,
    useToggle
} from '@ideascale/commons';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useIdeaDetailsContext} from 'contexts/IdeaDetailsContext';
import {useAppContext} from 'contexts/AppContext';
import {useFileUploadService} from 'hooks/useFileUploadService';
import {useIdeaActions} from 'hooks/useIdeaActions';
import {useSocialShareServices} from 'hooks/useSocialShareServices';
import {useIdeaShare} from 'hooks/useIdeaShare';
import {useClassificationService} from 'hooks/useClassificationService';
import {useCampaignChange} from 'hooks/useCampaignChange';
import {useIdeaLabelAction} from 'hooks/useIdeaLabelAction';
import {useCookieConsentConfiguration} from 'hooks/useCookieConsentConfiguration';
import {appLinks} from 'services/AppLinks';
import {SCROLL_EVENTS} from 'constants/AppConstants';
import {CopyIdeaFormContainer} from 'containers/CopyIdeaFormContainer';
import {StageSummary} from 'components/idea/StageSummary';
import {IdeaHeader} from 'components/idea/IdeaHeader';
import {StageAction} from 'components/idea/StageAction';
import {InfiniteUserPopover} from 'components/InfiniteUserPopover';
import {IdeaModerateActions} from 'components/idea/IdeaModerateActions';
import {IdeaMoreActions} from 'components/idea/IdeaMoreActions';
import {AssignOwnersModal} from 'components/action-modals/AssignOwnersModal';
import {EditIdeaFormContainer} from 'containers/EditIdeaFormContainer';
import {PersistentBanner} from 'components/idea/details/PersistentBanner';
import {IdeaContent} from 'components/idea/IdeaContent';
import {LinkIdeas} from 'components/idea/details/LinkIdeas';
import {IdeaLabelItems} from 'components/IdeaLabelItems';
import {IdeaStagesWorkflow} from 'components/IdeaStagesWorkflow';
import {IdeaRevisionHistory} from 'components/idea/IdeaRevisionHistory';
import {OriginalIdea} from 'components/idea/OriginalIdea';
import {Tickets} from 'components/Tickets';
import {AssignedOwnerGroup} from 'components/AssignedOwnerGroup';
import {AttachmentThumbnails} from 'components/idea/details/AttachmentThumbnails';
import {TeamMembersPopover} from 'components/TeamMembersPopover';
import {ShareModal} from 'components/share/ShareModal';
import {IdeaCustomFields} from '../IdeaCustomFields';
import {IdeaProps} from 'components/idea/list/Idea';
import {ROUTES} from 'shared/Routes';
import {IdeaDetail} from 'models/IdeaDetail';
import {RevisionHistory} from 'models/RevisionHistory';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {OriginalIdeaData} from 'models/OriginalIdeaData';
import {QualifierLinkedIdeas} from 'models/QualifierLinkedIdeas';
import {IdeaMoreActionResponse} from 'models/IdeaMoreActionResponse';
import {IdeaStagesData} from 'models/IdeaStagesData';
import {AssignOwnersParams} from 'models/types/AssignOwnersParams';
import {IdeaTeamMember} from 'models/IdeaTeamMember';
import './IdeaDetails.scss';

const MEMBER_PAGE_SIZE = 30;

type IdeaDetailsProps = IdeaProps & {
    content: IdeaDetail;
    fetchRevisionHistory: (ideaId: number, pageParameters: PageParameters) => Promise<PagedResponseContent<RevisionHistory>>;
    fetchOriginalIdea: (ideaId: number) => Promise<OriginalIdeaData>;
    fetchIdeaStages: (ideaId: number) => Promise<Array<IdeaStagesData>>;
    fetchFollowers: (pageParameters: PageParameters, ideaId: number) => Promise<PagedResponseContent<Member>>;
    fetchOwners: (pageParameters: PageParameters, ideaId: number) => Promise<PagedResponseContent<Member>>;
    fetchKudoGivers: (pageParameters: PageParameters, ideaId: number) => Promise<PagedResponseContent<Member>>;
    fetchSuperComments: (ideaId: number, pageNo: number) => Promise<SuperCommentResponse>;
    deleteSuperComment: (commentId: number, commentType: CommentType) => Promise<void>;
    reorderSuperComments: (ideaId: number, orderedIds: number[]) => Promise<void>;
    unFurlUrl: (url: string) => Promise<UnfurledUrl>;
    fetchSuggestedTags: (tagParams: TagParameters) => Promise<string[]>;
    fetchTagsByTerm: (pageParams: TagSearchParameters) => Promise<PagedResponseContent<string>>;
    fetchModeratorTagsByTerm: (pageParams: PageParameters) => Promise<PagedResponseContent<string>>;
    fetchRecipients: (pageParameters: PageParameters) => Promise<PagedResponseContent<Member>>;
    getIdeaTeamMembers: (ideaId: number) => Promise<IdeaTeamMember[]>;
    getAuthorEmailIdeaContent: (ideaId: number) => Promise<EmailIdeaContent>;
    totalIdeas: number;
    getCurrentIndex: () => number;
    navigateNextIdea: () => void;
};

export const IdeaDetails = (props: IdeaDetailsProps) => {
    const {
        localizer,
        content,
        fetchIdeaStages,
        fetchIdeaLabels,
        toggleIdeaFollowed,
        toggleAuthorFollowed,
        toggleCommentingEnabled,
        fetchAssignedOwners,
        fetchMembers,
        emailIdeaAuthor,
        fetchRevisionHistory,
        fetchOriginalIdea,
        fetchAuthorEmails,
        banMember,
        togglePinIdeas,
        fetchFollowers,
        fetchOwners,
        fetchKudoGivers,
        fetchSuperComments,
        deleteSuperComment,
        reorderSuperComments,
        unFurlUrl,
        fetchSuggestedTags,
        fetchTagsByTerm,
        fetchModeratorTagsByTerm,
        totalIdeas,
        getCurrentIndex,
        navigateNextIdea,
        getAuthorEmailIdeaContent,
        getIdeaTeamMembers,
    } = props;

    const navigate = useNavigate();
    const {
        currentCampaign,
        authentication,
        communityConfig: {
            translatedIdeaShowAllowed,
            linkQualifiers,
            stageTimelineEnabled,
            classificationEnabled,
            maxFileSizeLimit,
            url: communityUrl,
            name: communityName,
            offensiveEmojis,
            unfurlUrlEnabled
        }
    } = useAppContext();
    const {
        onToggleVote,
        giveKudo,
        reportAbuse,
        reportDuplicate,
        assignOwners,
        changeCampaign,
        deleteIdea,
        changeIdeaStage,
        editIdea,
        fetchLinkableIdeas,
        fetchLinkableIdeasByKeywords,
        saveQualifierLinkedIdeas,
        unlinkIdea,
        updateTags,
        updateModeratorTags,
    } = useIdeaDetailsContext();
    const {tempImageUpload} = useFileUploadService();
    const {fetchClassifications} = useClassificationService();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const {
        fetchDropdownCampaigns,
        fetchChangeCampaignStages,
        isChangeToDifferentFunnel
    } = useCampaignChange(undefined, content);
    const [labelDropdownOpen, toggleLabelDropdown] = useToggle(false);
    const [showShareModal, toggleShareModal] = useToggle(false);
    const [showCommentConfirmation, toggleShowCommentConfirmation] = useToggle(false);
    const [showBanConfirmation, toggleShowBanConfirmation] = useToggle(false);
    const [showAssignOwnerModal, toggleAssignOwnerModal] = useToggle(false);
    const [showChangeCampaignModal, toggleChangeCampaignModal] = useToggle(false);
    const [showEditIdeaModal, toggleEditIdeaModal] = useToggle(false);
    const [showCopyIdeaFormModal, toggleShowCopyIdeaFormModal] = useToggle(false);
    const [showEmailAuthorModal, toggleEmailAuthorModal] = useToggle(false);
    const [showReportAbuseConfirmation, toggleReportAbuseConfirmation] = useToggle(false);
    const [showReportDuplicateConfirmation, toggleReportDuplicateConfirmation] = useToggle(false);
    const [showTagModal, toggleTagModal] = useToggle(false);
    const [showModeratorTagModal, toggleModeratorTagModal] = useToggle(false);
    const [showLinkableIdeas, toggleLinkableIdeas] = useToggle(false);
    const [fetchStageWorkFlow, setFetchStageWorkFlow] = useToggle(true);
    const [showFollowersPopover, toggleFollowersPopover] = useToggle(false);
    const [showTeamMembersPopover, toggleTeamMembersPopover] = useToggle(false);
    const [showViewOriginalCard, toggleViewOriginalCard] = useToggle(false);
    const internalMessageAdditionalURL = `<a class= "d-block cursor-pointer" href=${communityUrl + process.env.PUBLIC_URL + appLinks.ideaDetails(content.id)}>${content.title}</a>`;
    const shareableText = communityUrl + process.env.PUBLIC_URL + appLinks.ideaDetails(content.id);
    const shareableLink = appLinks.ideaDetails(content.id);
    const permissions = content.permissionHolder;
    const moreActionData: IdeaMoreActionResponse = {
        printIdeaAllowed: permissions?.printIdeaAllowed || false,
        ideaAuthorFollowAllowed: permissions?.ideaAuthorFollowAllowed || false,
        emailIdeaAllowed: permissions?.emailIdeaAllowed || false,
        emailAuthorAllowed: permissions?.emailAuthorAllowed || false,
        assignOwnerAllowed: permissions?.assignOwnerAllowed || false,
        banMemberAllowed: permissions?.banMemberAllowed || false,
        reviewTranslationUrl: permissions?.reviewTranslationUrl || '',
        allowedMediaList: [],
        sharable: false
    };
    const {
        onStageActionClick,
        onToggleAuthorFollowAction,
        onToggleFollowAction,
        onToggleCommentEnabled,
        onBanMemberAction,
        onTogglePinToLandingPageAction
    } = useIdeaActions();
    const {getSocialIdeaShareURLs} = useSocialShareServices();
    const {
        ideaShareMessagesSend,
        getIdeaEmailRecipients,
        emailIdeaSendByRecipientIds,
        emailIdeaSendByRecipientEmails,
        getEmailIdeaContent
    } = useIdeaShare();
    const {
        onAddIdeaLabel,
        onRemoveIdeaLabel,
        updateIdeaDetailsLabels
    } = useIdeaLabelAction(content.labels);
    const cookieConsentConfig = useCookieConsentConfiguration();

    const onClickStageAction = useCallback(() => {
        onStageActionClick(content.stageSummary, content.id, true);
    }, [content.id, content.stageSummary, onStageActionClick]);

    const onFollowToggleClicked = useCallback(async (followed: boolean) => {
        await onToggleFollowAction(toggleIdeaFollowed, content.id, followed, true, content.followersCount);
    }, [content.followersCount, content.id, onToggleFollowAction, toggleIdeaFollowed]);

    const followAuthorAction = useCallback((followed: boolean) => {
        onToggleAuthorFollowAction(toggleAuthorFollowed, content.id, content.submitter.id, followed, true);
    }, [content.id, content.submitter.id, onToggleAuthorFollowAction, toggleAuthorFollowed]);

    const confirmToggleCommentEnabled = useCallback(async () => {
        await onToggleCommentEnabled(toggleCommentingEnabled, content.id, content.title, content.commentingEnabled, true, content.permissionHolder);
    }, [content.commentingEnabled, content.id, content.permissionHolder, content.title, onToggleCommentEnabled, toggleCommentingEnabled]);

    const confirmBanMember = useCallback(async () => {
        await onBanMemberAction(banMember, content.id, content.submitter.id, true, permissions);
    }, [banMember, content.id, content.submitter.id, onBanMemberAction, permissions]);

    const togglePinToLandingPage = useCallback(async (pinned: boolean) => {
        await onTogglePinToLandingPageAction(togglePinIdeas, content.id, pinned, true);
    }, [content.id, onTogglePinToLandingPageAction, togglePinIdeas]);

    const onSaveLinkableIdeas = useCallback((linkedIdeas: QualifierLinkedIdeas[]) => {
        saveQualifierLinkedIdeas(content.id, linkedIdeas).then();
        toggleLinkableIdeas();
    }, [content.id, saveQualifierLinkedIdeas, toggleLinkableIdeas]);

    const onUnlinkIdea = useCallback((linkedIdeaId: number, qualifierId: number) => {
        unlinkIdea(content.id, linkedIdeaId, qualifierId).then();
    }, [content.id, unlinkIdea]);

    const onIdeaDelete = useCallback(async (ideaId: number) => {
        deleteIdea(ideaId);
        if (getCurrentIndex() === -1 || getCurrentIndex() + 1 >= totalIdeas) {
            navigate(ROUTES.HOME);
        } else {
            navigateNextIdea();
        }
    }, [deleteIdea, getCurrentIndex, navigate, navigateNextIdea, totalIdeas]);

    const onAssignOwnerConfirmed = useCallback(async (assignOwnersParams: AssignOwnersParams) => {
        return await assignOwners(assignOwnersParams);
    }, [assignOwners]);

    const getAllIdeaAttachments = () => {
        return [...content.attachments, ...content.customAttachments, ...content.commentAttachments];
    };

    const unfurlingContent = useMemo(() => {
        let text = content.description;
        content.fieldSections.forEach(section => {
            section.ideaFieldValues.forEach(field => {
                text += ' ' + field.value;
            });
        });
        return text;
    }, [content.description, content.fieldSections]);

    const onFetchTagsByTerm = useCallback(async (pageParas: PageParameters) => {
        return await fetchTagsByTerm({...pageParas, campaignId: content.campaign?.id});
    }, [content.campaign?.id, fetchTagsByTerm]);

    const getAllIdeaAttributes = useCallback(() => {
        return content.attributes?.filter(attribute => 'fileAttributeValues' in attribute) as ClassificationAttachmentAttribute[];
    }, [content.attributes]);

    const onCampaignChange = useCallback(async (selectedCampaign: Campaign, stageId?: number, tosAccepted?: boolean) => {
        try {
            await changeCampaign({
                ideaId: content.id,
                campaignId: selectedCampaign.id,
                stageId: stageId,
                isDifferentStage: content.campaign?.funnelId !== selectedCampaign.funnelId,
                tosAccepted: tosAccepted
            });
            setFetchStageWorkFlow(true);
            eventDispatcher.dispatch(
                AlertEvent.ALERT,
                buildAlertEventData(
                    AlertType.success,
                    localizer.msg('idea.actions.move.campaign-changed-from',
                        {
                            from: content.campaign?.name || '',
                            to: selectedCampaign.name
                        }
                    )
                )
            );
        } catch (error: any) {
            handleErrorResponse(error);
        }
    }, [changeCampaign, content.campaign?.funnelId, content.campaign?.name, content.id, handleErrorResponse, localizer, setFetchStageWorkFlow]);

    const onLabelDropDownToggled = useCallback(async () => {
        toggleLabelDropdown();
        await updateIdeaDetailsLabels(content.id);
    }, [content.id, toggleLabelDropdown, updateIdeaDetailsLabels]);

    const onUpdateTags = useCallback((ideaIds: number[], tags: string[]) => {
        return updateTags(ideaIds[0], tags);
    }, [updateTags]);

    const onUpdateModeratorTags = useCallback((ideaIds: number[], tags: string[]) => {
        return updateModeratorTags(ideaIds[0], tags);
    }, [updateModeratorTags]);

    return (
        <article className="idea idea-details mb-0" data-idea-id={content.id}
                 aria-roledescription={localizer.msg('idea.idea-details')}>
            {classificationEnabled && content.attributes &&
                <ClassificationTitle attributes={content.attributes as ClassificationFieldAttribute[]}
                                     extraClasses="mb-3"
                                     localizer={localizer}/>
            }
            <PersistentBanner localizer={localizer}
                              ideaNumber={content.ideaNumber}
                              ideaTitle={content.title}
                              ideaCreatedAt={content.createdAt}
                              submitter={content.submitter}
                              labels={content.labels}
                              onClickStageAction={onClickStageAction}
                              onVote={(voteParameters, voteType) => onToggleVote(content.id, voteType, voteParameters)}
                              stageSummary={content.stageSummary}
                              ideaViewCount={content.ideaViewCount}
                              classification={content.attributes as ClassificationFieldAttribute[]}/>
            <IdeaHeader
                ideaNumber={content.ideaNumber}
                idPrefix={'idea-details'}
                campaign={content.campaign}
                ideaId={content.id}
                ideaTitle={content.title}
                ideaCreatedAt={content.createdAt}
                linkableIdeaTitle={false}
                submitter={content.submitter}
                coSubmitters={content.coSubmitters}
                kudosCount={content.kudoCount}
                kudoGiven={content.kudoGiven}
                giveKudo={giveKudo}
                labels={content.labels}
                showLabelsStackView={false}
                fetchKudoGivers={fetchKudoGivers}
                ideaViewCount={content.ideaViewCount}
                classification={content.attributes as ClassificationFieldAttribute[]}/>

            <div className="idea-main-details mb-3">
                {
                    content.ownerCount > 0 &&
                    <AssignedOwnerGroup ideaId={content.id} ownerCount={content.ownerCount} localizer={localizer}
                                        fetchOwners={fetchOwners} pageSize={MEMBER_PAGE_SIZE}/>
                }

                {content.revisionHistoryCount > 0 &&
                    <IdeaRevisionHistory ideaId={content.id} fetchIdeaRevisionHistory={fetchRevisionHistory}
                                         lastEditedAt={content.lastEditedAt} lastEditedBy={content.lastEditedBy}
                                         participationAllowed={content.stageSummary.participationAllowed}
                    />
                }

                <IdeaContent localizer={localizer}
                             ideaId={content.id}
                             customDescriptionLabel={content?.customDescriptionLabel}
                             listPage={false}
                             description={content.description}
                             classification={content.attributes as ClassificationAttachmentAttribute[]}
                             attachments={getAllIdeaAttachments()}/>
                {content.buildTeamViewAllowed &&
                    <div className="mb-4" id={`team-popover-container-${content.id}`}>
                        <button className="btn btn-link p-0"
                                id={`user-team-popover-${content.id}`}
                                onClick={toggleTeamMembersPopover}>{localizer.msg('idea-details.idea-team-members')}</button>
                        {showTeamMembersPopover &&
                            <TeamMembersPopover actorId={authentication.actor.id}
                                                selector={`user-team-popover-${content.id}`}
                                                header={''}
                                                placement="right"
                                                ideaId={content.id}
                                                getTeamData={getIdeaTeamMembers}
                                                trigger="legacy"
                                                showBuildTeamPopover={showTeamMembersPopover}
                                                toggleBuildTeamPopover={toggleTeamMembersPopover}
                                                containerId={`team-popover-container-${content.id}`}
                            />}
                    </div>
                }

                <section>
                    <ul className="list-unstyled ms-0 d-flex flex-wrap gap-2"
                        aria-label={localizer.msg('frontend-shared.tags.label')}>
                        {
                            (content.ideaTagHolder.editTagsAllowed || content.ideaTagHolder.addTagsAllowed) &&
                            <li>
                                <button type="button" className="tag modal-dialog-trigger" onClick={toggleTagModal}
                                        aria-label={content.ideaTagHolder.editTagsAllowed ? localizer.msg('frontend-shared.tags.edit-tags') : localizer.msg('frontend-shared.tags.add-placeholder')}
                                        data-test-element-id={content.ideaTagHolder.editTagsAllowed ? 'edit-tags' : 'add-tags'}>
                            <span className="tag-label">
                                + {content.ideaTagHolder.editTagsAllowed ? localizer.msg('frontend-shared.tags.edit-tags') : localizer.msg('frontend-shared.tags.add-placeholder')}...
                            </span>
                                </button>
                            </li>
                        }
                        {
                            content.ideaTagHolder.tags.length > 0 &&
                            content.ideaTagHolder.tags?.map(tag =>
                                <li key={tag}>
                                    <Link className="tag" to={appLinks.tag(tag, currentCampaign?.id.toString())}
                                          data-test-element-id="idea-tag">
                                        <span className="tag-label">{tag}</span>
                                    </Link>
                                </li>
                            )
                        }
                    </ul>
                </section>
                {content.ideaTagHolder.addModeratorTagsAllowed &&
                    <section>
                        <ul className="list-unstyled ms-0 d-flex flex-wrap gap-2"
                            aria-label={localizer.msg('frontend-shared.tags.moderator-tags')}>
                            {content.ideaTagHolder.addModeratorTagsAllowed &&
                                <li>
                                    <button type="button" className="tag modal-dialog-trigger"
                                            onClick={toggleModeratorTagModal}
                                            data-test-element-id="add-moderator-tags"
                                            aria-label={localizer.msg('frontend-shared.tags.add-moderator-tags')}>
                                        <span>+ {localizer.msg('frontend-shared.tags.add-moderator-tags')}...</span>
                                    </button>
                                </li>
                            }
                            {
                                content.ideaTagHolder.moderatorTags?.map(tag =>
                                    <li key={tag}>
                                        <Link className="tag" data-test-element-id="moderator-tag"
                                              to={appLinks.moderatorTag(tag, currentCampaign?.id.toString())}>
                                            <span className="tag-label">{tag}</span>
                                        </Link>
                                    </li>
                                )
                            }
                        </ul>
                    </section>
                }

                <IdeaCustomFields className="idea-meta" fieldSections={content.fieldSections}
                                  classification={content.attributes as ClassificationFieldAttribute[]}/>

                {
                    showViewOriginalCard &&
                    <OriginalIdea localizer={localizer} ideaId={content.id} fetchOriginalIdea={fetchOriginalIdea}
                                  translatedIdeaShowAllowed={translatedIdeaShowAllowed}/>

                }
                {
                    content.translation.translated && !showViewOriginalCard &&
                    <button className="btn btn-link p-0 me-2"
                            id={`view-original-${content.id}`}
                            onClick={() => toggleViewOriginalCard(true)}>{localizer.msg('original-idea.view.original')}
                    </button>

                }
            </div>

            {
                !authentication.actor.isCommunityMember() && authentication.isAuthenticated() &&
                <Fragment>
                    <button className="btn btn-light btn-link-ideas" onClick={toggleLinkableIdeas}>
                        {localizer.msg('idea-details.add')} <Icon
                        className="transform-rotate90 position-relative pos-top-n1"
                        iconSpritePath={svgIconsPath}
                        name="link"/> {localizer.msg('idea-details.linked-ideas')}
                    </button>
                    <LinkIdeas localizer={localizer}
                               ideaId={content.id}
                               ideaTitle={content.title}
                               ideaDescription={content.description}
                               linkQualifiers={linkQualifiers}
                               linkedIdeas={content.linkedIdeas}
                               fetchLinkableIdeas={fetchLinkableIdeas}
                               fetchLinkableIdeasByKeywords={fetchLinkableIdeasByKeywords}
                               onSave={onSaveLinkableIdeas}
                               onCancel={toggleLinkableIdeas}
                               unlinkIdea={onUnlinkIdea}
                               showLinkableIdeas={showLinkableIdeas}/>
                </Fragment>
            }

            {content.tickets.length > 0 &&
                <Tickets content={content.tickets}/>
            }

            {
                <section className="mb-3">
                    <SuperComments
                        localizer={localizer}
                        actorMemberId={authentication.actor.id}
                        ideaId={content.id}
                        unFurlUrl={unFurlUrl}
                        deletionAllowed={!!content?.permissionHolder?.superCommentDeletionAllowed}
                        updateAllowed={!!content?.permissionHolder?.superCommentUpdateAllowed}
                        superCommentResponse={content.superCommentsHolder}
                        fetchSuperComments={fetchSuperComments}
                        deleteComment={deleteSuperComment}
                        reorderComments={reorderSuperComments}
                        classificationEnabled={classificationEnabled}
                        cookieConsentConfig={cookieConsentConfig}
                        htmlToReactConfig={HTML_TO_REACT_CONFIG}
                        enableUnfurledUrl={unfurlUrlEnabled}
                    />
                </section>
            }

            <UnfurledUrlList content={unfurlingContent} unfurlUrl={unFurlUrl} localizer={localizer}
                             enabled={unfurlUrlEnabled}/>

            {stageTimelineEnabled &&
                <IdeaStagesWorkflow fetchIdeaStages={fetchIdeaStages} ideaId={content.id} fetch={fetchStageWorkFlow}
                                    setFetchStageWorkflow={setFetchStageWorkFlow}/>
            }

            <StageSummary ideaStageSummary={content.stageSummary} ideaId={content.id}
                          classification={content.attributes as ClassificationFieldAttribute[]}/>

            <div className="d-block d-sm-flex justify-content-between align-items-center flex-wrap ms-lg-n2">
                <StageAction ideaStageSummary={content.stageSummary}
                             toggleCopyIdeaFormModal={toggleShowCopyIdeaFormModal}
                             copyIdeaAllowed={permissions && permissions.copyIdeaAllowed}
                             onClickAction={onClickStageAction}
                             onVote={(voteParameters, voteType) => onToggleVote(content.id, voteType, voteParameters)}
                             classification={content.attributes as ClassificationFieldAttribute[]}/>
                <div className="idea-action-items pt-3 pt-sm-0 d-flex">
                    {
                        content.commentingAllowed && content.commentingEnabled &&
                        <Link className="ms-2 focus-box-shadow" data-test-element-id="btn-comments"
                              onClick={() => eventDispatcher.dispatch(SCROLL_EVENTS.DETAILS_TABS)}
                              to={appLinks.ideaDetailsComment(content.id)}
                              title={content.commentCount === 0 ?
                                  localizer.msg('common.leave-a-comment') :
                                  localizer.msg('common.go-to-comment')}>
                            <Icon name="message-solid-right" fill="#666"
                                  width={17}
                                  height={17}
                                  iconSpritePath={svgIconsPath}/>
                            {
                                content.commentCount > 0 &&
                                <span className="count ms-1">
                                    {content.commentCount > 999 ? '999+' : content.commentCount}
                                    <span className="sr-only">
                                        {
                                            localizer.msg('common.comment', {count: content.commentCount})
                                        }
                                    </span>
                                </span>
                            }
                        </Link>
                    }

                    {
                        content.followingEnabled &&
                        <div className="ms-2 d-flex align-items-center"
                             id="idea-follow-container"
                             onFocus={() => toggleFollowersPopover(true)}
                            onBlur={(event) => {
                                if (!event.currentTarget.contains(event.relatedTarget)) {
                                    toggleFollowersPopover(false);
                                }
                            }}
                             onMouseEnter={() => toggleFollowersPopover(true)}
                            onMouseLeave={() => toggleFollowersPopover(false)}
                        >
                            <button className="active btn d-flex align-items-center p-0 border-radius-0 line-height-0"
                                    id="idea-follow"
                                    onClick={() => onFollowToggleClicked(content.subscribed)}
                                    data-test-element-id={content.subscribed ? 'btn-unfollow' : 'btn-follow'}
                                    title={content.subscribed ? localizer.msg('idea.actions.unfollow-title') : localizer.msg('idea.actions.follow-title')}>
                                <Icon className={`position-relative pos-top-n1 ${content.subscribed ? 'active' : ''}`}
                                      name="star-solid"
                                      width={19}
                                      height={19}
                                      iconSpritePath={svgIconsPath}/>
                                {
                                    content.followersCount > 0 &&
                                    <span
                                        className={`count ms-1 fw-normal ${content.subscribed ? 'text-primary' : ''}`}>
                                        {content.followersCount > 999 ? '999+' : content.followersCount}
                                        <span className="sr-only">{localizer.msg('idea-details.followers')}</span>
                                    </span>
                                }
                            </button>
                            {
                                content.followersCount > 0 && showFollowersPopover &&
                                <InfiniteUserPopover id={content.id} actorId={authentication.actor.id}
                                                     container="idea-follow-container"
                                                     selector="idea-follow"
                                                     header={localizer.msg('idea-details.idea-followers')}
                                                     fetchUsers={fetchFollowers}
                                                     pageSize={MEMBER_PAGE_SIZE} isOpen={showFollowersPopover}
                                                     toggle={toggleFollowersPopover}/>
                            }
                        </div>
                    }

                    {
                        authentication.isAuthenticated() && !!authentication.actor.id && content.labellingAllowed &&
                        <ButtonDropdown className="drawer-able moderation-action-menu ms-2"
                                        drop="down"
                                        isOpen={labelDropdownOpen}
                                        toggle={onLabelDropDownToggled}>
                            <Backdrop/>

                            <DropdownToggle tag="button" className="btn btn-idea-moderator p-0"
                                            data-test-element-id="btn-set-label"
                                            title={localizer.msg('idea.actions.label-title')}>
                                <Icon className="pointer-events-none" name="flag-waving" width={17} height={17}
                                      fill="#666" iconSpritePath={svgIconsPath}/>
                            </DropdownToggle>

                            <div className="right-panel">
                                {
                                    labelDropdownOpen &&
                                    <DropdownMenu id={`idea-${content.id}-labels-menu`}
                                                  className="dropdown-menu px-3 py-2 modify-label" end>
                                        <IdeaLabelItems localizer={localizer} ideaId={content.id}
                                                        focusId={`idea-${content.id}-labels-menu`}
                                                        fetchIdeaLabels={fetchIdeaLabels}
                                                        onAddIdeaLabel={onAddIdeaLabel}
                                                        onRemoveIdeaLabel={onRemoveIdeaLabel}
                                                        toggleDropdown={onLabelDropDownToggled}
                                                        toggleReportAbuseConfirmation={toggleReportAbuseConfirmation}
                                                        toggleReportDuplicateConfirmation={toggleReportDuplicateConfirmation}
                                                        onChangeIdeaStage={changeIdeaStage}
                                                        stageSummary={content.stageSummary} isDetailsPage={true}/>
                                    </DropdownMenu>
                                }
                            </div>
                        </ButtonDropdown>
                    }

                    {
                        permissions && (permissions.moderationPermissionHolder.approveIdeaEnabled || permissions.moderationPermissionHolder.rejectIdeaEnabled)
                            ? <a href={appLinks.approveIdeaModeration(content.id, content.seen)} className="ms-2"
                                 title={localizer.msg('idea.actions.moderate-actions-title')}
                                 data-test-element-id="approve-idea-moderation">
                                <Icon name="gavel-solid" width={17} height={17} fill="#666"
                                      iconSpritePath={svgIconsPath}/>
                            </a>
                            : content.moderate &&
                            <IdeaModerateActions
                                toggleChangeCampaignModal={toggleChangeCampaignModal}
                                localizer={localizer}
                                selectedIdea={content}
                                toggleEditIdeaModal={toggleEditIdeaModal}
                                moderationPermissions={permissions?.moderationPermissionHolder}
                                toggleCommentEnabled={toggleShowCommentConfirmation}
                                togglePinToLandingPage={togglePinToLandingPage}
                                onIdeaStageChange={changeIdeaStage}
                                onIdeaDelete={onIdeaDelete}
                            />
                    }

                    {
                        permissions && permissions.editIdeaEnabled &&
                        <button className="ms-2 active btn p-0 border-radius-0" id="idea-edit"
                                data-test-element-id="btn-edit-idea"
                                title={localizer.msg('idea.actions.edit')} onClick={toggleEditIdeaModal}>
                            <Icon className="position-relative pos-top-n1" name="pencil"
                                  iconSpritePath={svgIconsPath} fill="#666" width={19} height={19}/>
                        </button>
                    }

                    {
                        permissions && permissions.assignOwnerAllowed &&
                        <button className="ms-2 active btn p-0 border-radius-0" id="idea-assign"
                                data-test-element-id="btn-idea-assign"
                                title={content.ownerCount > 0 ? localizer.msg('idea.actions.assign-owner.change') : localizer.msg('idea.actions.assign-owner.assign')}
                                onClick={toggleAssignOwnerModal}>
                            <Icon className="position-relative" name="clipboard-lines"
                                  fill="#666" width={19}
                                  height={19}
                                  iconSpritePath={svgIconsPath}/>
                        </button>
                    }

                    {
                        permissions && content.sharable && (permissions.emailIdeaAllowed || permissions.allowedMediaList.length > 0) &&
                        <button type="button" className="btn p-0 ms-2 border-radius-0" onClick={toggleShareModal}
                                data-test-element-id="btn-share-idea"
                                title={localizer.msg('idea.share-idea')}>
                            <Icon name="arrow-up-from-tray" fill="#666" width={17} height={17}
                                  iconSpritePath={svgIconsPath}/>
                        </button>
                    }

                    {
                        content.memberActionAllowed &&
                        <IdeaMoreActions
                            selectedIdea={content} localizer={localizer}
                            permissionData={moreActionData}
                            toggleShareModal={toggleShareModal}
                            toggleAssignOwnerModal={toggleAssignOwnerModal}
                            toggleEmailAuthorModal={toggleEmailAuthorModal}
                            toggleBanMemberModal={toggleShowBanConfirmation}
                            followAuthorAction={followAuthorAction}
                        />
                    }
                </div>
            </div>
            {classificationEnabled && content.attributes &&
                <ClassificationTitle attributes={content.attributes as ClassificationFieldAttribute[]}
                                     extraClasses="mt-4 mb-2" localizer={localizer}/>
            }
            {
                getAllIdeaAttachments().length > 0 &&
                <AttachmentThumbnails
                    classificationEnabled={classificationEnabled}
                    localizer={localizer}
                    ideaId={content.id}
                    attachments={getAllIdeaAttachments()}
                    attributes={getAllIdeaAttributes()}
                />
            }
            <ConfirmationModal localizer={localizer}
                               message={content.commentingEnabled ? localizer.msg('idea.actions.comment-disable-confirmation') : localizer.msg('idea.actions.comment-enable-confirmation')}
                               open={showCommentConfirmation} toggle={toggleShowCommentConfirmation}
                               onConfirm={confirmToggleCommentEnabled}/>
            <ConfirmationModal localizer={localizer} message={localizer.msg('idea.actions.ban-member-confirmation')}
                               open={showBanConfirmation} toggle={toggleShowBanConfirmation}
                               onConfirm={confirmBanMember}/>

            {
                showReportAbuseConfirmation &&
                <ConfirmationModal localizer={localizer} message={localizer.msg('idea.actions.abused-confirm')}
                                   open={showReportAbuseConfirmation} toggle={toggleReportAbuseConfirmation}
                                   onConfirm={() => reportAbuse(content.id)}/>
            }

            {
                showReportDuplicateConfirmation &&
                <ConfirmationModal
                    localizer={localizer} message={localizer.msg('idea.actions.duplicate-confirm')}
                    open={showReportDuplicateConfirmation} toggle={toggleReportDuplicateConfirmation}
                    onConfirm={() => reportDuplicate(content.id)}/>
            }

            {
                showAssignOwnerModal &&
                <AssignOwnersModal localizer={localizer} open={showAssignOwnerModal} toggle={toggleAssignOwnerModal}
                                   selectedIdea={content}
                                   fetchOwnersByName={fetchMembers}
                                   assignReassignOwners={onAssignOwnerConfirmed}
                                   fetchAssignedOwners={fetchAssignedOwners}/>
            }

            {
                showChangeCampaignModal &&
                <ChangeCampaignModal
                    open={showChangeCampaignModal}
                    toggle={toggleChangeCampaignModal}
                    localizer={localizer}
                    svgIconsPath={svgIconsPath}
                    heading={localizer.msg('idea.actions.move.heading')}
                    classificationEnabled={classificationEnabled}
                    defaultCampaign={content.campaign}
                    message={localizer.msg('idea.actions.move.campaign-warning')}
                    isDifferentFunnel={isChangeToDifferentFunnel}
                    fetchCampaignStages={fetchChangeCampaignStages}
                    fetchDropdownCampaigns={fetchDropdownCampaigns}
                    onSaveCampaignChange={onCampaignChange}
                    communityUrl={communityUrl}
                />
            }

            {
                showShareModal &&
                <ShareModal open={showShareModal}
                            toggle={toggleShareModal}
                            shareContentId={content.id}
                            shareContentTitle={content.title}
                            emailShareModalTitle={localizer.msg('idea.actions.email-idea-title')}
                            emailInvitationText={localizer.msg('idea.actions.email.invitation')}
                            socialShareURLs={getSocialIdeaShareURLs(content.permissionHolder?.allowedMediaList, content.title, content.id)}
                            internalShareButtonTitle={localizer.msg('idea.share.copy-title')}
                            shareableLink={shareableLink}
                            shareableText={shareableText}
                            internalMessageAdditionalURL={internalMessageAdditionalURL}
                            title={localizer.msg('idea.share-idea')}
                            emailIdeaAllowed={content.permissionHolder?.emailIdeaAllowed}
                            onSendMessage={(requestParams: PrivateMessageRequestParams) => ideaShareMessagesSend(requestParams, content.id)}
                            emailIdeaSendByRecipientIds={(requestParams: EmailRequest) => emailIdeaSendByRecipientIds(requestParams, content.id)}
                            emailIdeaSendByRecipientEmails={(requestParams: EmailRequest) => emailIdeaSendByRecipientEmails(requestParams, content.id)}
                            getEmailRecipients={(parameters: PageParameters) => getIdeaEmailRecipients(content.id, parameters)}
                            getEmailContent={() => getEmailIdeaContent(content.id)}
                            sharable={content.sharable}
                            campaignId={content.campaign?.id}
                            privateCampaign={content.campaign?.privateCampaign || false}
                />
            }

            {
                showEmailAuthorModal &&
                <GroupEmailIdeaModal open={showEmailAuthorModal}
                                     toggle={toggleEmailAuthorModal}
                                     localizer={localizer}
                                     svgIconsPath={svgIconsPath}
                                     maxFileSizeLimit={maxFileSizeLimit}
                                     groupType={EmailGroupType.IDEA_OWNERS}
                                     communityName={communityName}
                                     emailContentId={content.id}
                                     emailContentTitle={content.title}
                                     emailGroupMembers={emailIdeaAuthor}
                                     fetchGroupRecipients={fetchAuthorEmails}
                                     modalTitle={localizer.msg('idea.actions.email-idea-title')}
                                     getEmailContent={() => getAuthorEmailIdeaContent(content.id)}
                                     communityUrl={communityUrl}
                                     tempImageUpload={tempImageUpload}
                                     offensiveEmojis={offensiveEmojis}
                                     classificationEnabled={classificationEnabled}
                                     fetchClassifications={() => fetchClassifications(content.campaign?.id)}
                />
            }

            {
                showEditIdeaModal &&
                <EditIdeaFormContainer ideaId={content.id} open={showEditIdeaModal} toggle={toggleEditIdeaModal}
                                       editIdea={editIdea}/>
            }

            {
                showCopyIdeaFormModal &&
                <CopyIdeaFormContainer copyIdeaFormModalOpen={showCopyIdeaFormModal}
                                       toggleCopyIdeaFormModalOpen={toggleShowCopyIdeaFormModal}
                                       ideaId={content.id} isIdeaCopyMode={true}/>
            }

            {
                showTagModal &&
                <TagModal localizer={localizer}
                          open={showTagModal}
                          ideaIds={[content.id]}
                          toggleModal={toggleTagModal}
                          moderatorTags={false}
                          fetchSuggestedTags={fetchSuggestedTags}
                          creationAllowed={!content.ideaTagHolder.predefinedTagEnabled || content.ideaTagHolder.hybridTagEnabled}
                          updateTags={onUpdateTags}
                          existingTags={content.ideaTagHolder.tags}
                          allowEditTags={content.ideaTagHolder.editTagsAllowed}
                          fetchTags={onFetchTagsByTerm}/>
            }

            {
                showModeratorTagModal &&
                <TagModal localizer={localizer}
                          open={showModeratorTagModal}
                          ideaIds={[content.id]}
                          moderatorTags={true}
                          creationAllowed={!content.ideaTagHolder.predefinedModeratorTagEnabled}
                          toggleModal={toggleModeratorTagModal}
                          fetchTags={fetchModeratorTagsByTerm}
                          updateTags={onUpdateModeratorTags} existingTags={content.ideaTagHolder.moderatorTags}/>
            }
        </article>
    );
};