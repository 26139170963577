import {Command} from './Command';
import {CommunityHomeEditor} from './Editors/CommunityHomeEditor';
import {SidebarItem} from 'models/sidebar/SidebarItem';
import {CommunityOperationType} from 'models/edit-mode/CommunityOperationType';
import {ChangeCommunitySidebarOrderOperation} from 'models/edit-mode/ChangeCommunitySidebarOrderOperation';

export class ReorderCommunitySidebarCommand implements Command {
    private readonly communityHomeEditor: CommunityHomeEditor;
    private readonly oldSidebarItems: SidebarItem[];
    private readonly newSidebarItems: SidebarItem[];
    private readonly name: CommunityOperationType;

    constructor(communityHomeEditor: CommunityHomeEditor, newSidebarItems: SidebarItem[]) {
        this.communityHomeEditor = communityHomeEditor;
        this.oldSidebarItems = this.communityHomeEditor.sidebarItems;
        this.newSidebarItems = newSidebarItems;
        this.name = CommunityOperationType.CHANGE_COMMUNITY_SIDEBAR_ORDER;
    }

    getType() {
        return this.name;
    }

    execute() {
        this.communityHomeEditor.sidebarItems = this.newSidebarItems;
    }

    undo() {
        this.communityHomeEditor.sidebarItems = this.oldSidebarItems;
    }

    getOperation() {
        return new ChangeCommunitySidebarOrderOperation(this.name, this.communityHomeEditor.sidebarItems);
    }
}