import React, {Fragment} from 'react';
import {ClassificationLabel, useApplicableClassifications} from '@ideascale/commons';
import {CollapseExpand} from '@ideascale/ui';
import iconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useAppContext} from 'contexts/AppContext';
import {HtmlRenderer} from 'components/shared/HtmlRenderer';
import {useLocalizer} from 'hooks/useLocalizer';
import {TicketData} from 'models/TicketData';
import {TicketKey} from 'models/enums/TicketKey';
import trello from 'assets/img/trello.png';
import jira from 'assets/img/jira.png';
import youtrack from 'assets/img/youtrack.png';
import azure from 'assets/img/azure.png';
import './Tickets.scss';

type TicketsProps = {
    content: TicketData[];
}

const renderIcon = (ticket: TicketData) => {
    switch (ticket.connectorKey) {
        case TicketKey.YOUTRACK:
            return <img src={youtrack} alt={'youtrack'}/>;
        case TicketKey.JIRA:
            return <img src={jira} alt={'jira'}/>;
        case TicketKey.TRELLO:
            return <img src={trello} alt={'trello'}/>;
        case TicketKey.AZURE:
            return <img src={azure} alt={'azure'}/>;
        default:
            return '';
    }
};

export const Tickets = (props: TicketsProps) => {
    const {content} = props;
    const localizer = useLocalizer();
    const {communityConfig} = useAppContext();
    const {getClassificationAttribute} = useApplicableClassifications('', []);

    return (
        <Fragment>
            {
                content.map(item =>
                    <Fragment key={item.createdAt}>
                        <CollapseExpand
                            containerClass="tickets-collapse-expand-container mb-3 panel-default"
                            headerTitle={item.summary}
                            headerComponent={
                                <Fragment>
                                    {
                                        communityConfig.classificationEnabled && item.classificationSummary &&
                                        <ClassificationLabel
                                            classification={getClassificationAttribute(item.classificationSummary)}
                                            extraClasses="me-1 align-baseline"/>
                                    }
                                    {item.summary}
                                </Fragment>
                            }
                            svgIconSprite={iconPath}
                            headerIcon={renderIcon(item)}
                            headerTag={'h3'}
                            headerUrl={item.issueUrl}
                            defaultOpen={true}>
                            <div className="mb-3">
                                <HtmlRenderer content={item.description} className="text-wrap"/>
                            </div>
                            <div className="information">
                                {
                                    item.customFields.length > 0 &&
                                    <Fragment>
                                        {
                                            item.customFields.map(data => {
                                                return (
                                                    <Fragment>
                                                        {data.value === '' ?
                                                            '' :
                                                            <Fragment>
                                                                <b className="me-1 mb-2 d-inline-block">{data.name}:</b>
                                                                <span className="me-3">{data.value}</span>
                                                            </Fragment>

                                                        }
                                                    </Fragment>

                                                );
                                            })
                                        }
                                    </Fragment>
                                }
                                <Fragment>
                                    <b>{localizer.msg('common.last-updated')}</b>:
                                    {' '} {localizer.dateTime(item.updatedAt)}
                                </Fragment>
                            </div>
                        </CollapseExpand>
                    </Fragment>
                )
            }
        </Fragment>
    );
};