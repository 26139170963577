import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {ParagraphSkeleton, Scrollbar, ScrollbarInstance} from '@ideascale/ui';
import {
    CLASSIFICATION_NAME,
    ClassificationLabel,
    ClassificationUtil,
    InfiniteScrollLoadMoreButton,
    MemberLink,
    TimeAgo,
    useApiErrorResponseHandler,
    useIntersectionObserver
} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useIdeaLabels} from 'hooks/useIdeaLabels';
import {useProfilePageParams} from 'hooks/useProfilePageParams';
import {LabelsView} from 'shared/LabelsView';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {OwnedIdeasData} from 'models/OwnedIdeasData';
import {PageParameters} from 'models/types/PageParameters';
import {appLinks} from 'services/AppLinks';
import './OwnedIdeas.scss';

type OwnedIdeasProps = {
    fetchOwnedIdeas: (memberId: number, pageParams: PageParameters) => Promise<PagedResponseContent<OwnedIdeasData>>;
}

const PAGE_SIZE = 10;
const OWNED_IDEAS_LIST = 'owned-ideas-list';
const UNAUTHORIZED_PRIVATE = 'unauthorized/private';

export const OwnedIdeas = (props: OwnedIdeasProps) => {
    const {fetchOwnedIdeas} = props;
    const localizer = useLocalizer();
    const {communityConfig} = useAppContext();
    const currentProfileId = useProfilePageParams();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const scrollbarContainer = useRef<ScrollbarInstance>(null);
    const loadMoreButtonRef = useRef<HTMLButtonElement>(null);
    const currentPage = useRef(0);
    const {ideaLabelRouteChange} = useIdeaLabels();
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [ownedIdeas, setOwnedIdeas] = useState<OwnedIdeasData[]>([]);

    const loadIdeas = useCallback(async () => {
        try {
            if (currentProfileId > 0) {
                const pagedResponse = await fetchOwnedIdeas(currentProfileId, {
                    page: currentPage.current,
                    limit: PAGE_SIZE
                });
                if (pagedResponse.hasMore) {
                    currentPage.current = currentPage.current + 1;
                }
                setHasMore(pagedResponse.hasMore);
                if (pagedResponse.pageNo === 0) {
                    setOwnedIdeas(pagedResponse.content);
                } else {
                    setOwnedIdeas((prevData: OwnedIdeasData[]) => [...prevData, ...pagedResponse.content]);
                }
            }
        } catch (error: any) {
            handleErrorResponse(error);
        }
    }, [currentProfileId, fetchOwnedIdeas, handleErrorResponse]);

    useIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: loadIdeas,
        enabled: hasMore,
        options: {
            root: scrollbarContainer.current?.container
        }
    });

    useEffect(() => {
        loadIdeas().then(() => setLoading(false));
    }, [loadIdeas]);

    useEffect(() => {
        currentPage.current = 0;
    }, [currentProfileId]);


    return (
        <div className="mb-3">
            <div className="py-0 border-top-0">
                {
                    loading ?
                        <div className="p-2 pt-3">
                            <ParagraphSkeleton rows={6}/>
                        </div> :
                        <Fragment>
                            {ownedIdeas.length > 0 ?
                                <Scrollbar
                                    id={OWNED_IDEAS_LIST}
                                    className={`mb-2 ${ownedIdeas.length <= 1 ? 'one-idea' : ''}`}
                                    autoHeight
                                    autoHeightMin={123}
                                    autoHeightMax={450}
                                    ref={scrollbarContainer}>
                                    <ul className="me-2 list-unstyled" aria-label={localizer.msg('profile.owned-ideas')}>
                                        {
                                            ownedIdeas.map(idea => {
                                                return (
                                                    <li key={idea.id}>
                                                        {communityConfig.classificationEnabled && idea.campaign.classificationSummary &&
                                                            <ClassificationLabel
                                                                classification={ClassificationUtil.getClassificationAttribute(idea.campaign.classificationSummary)}
                                                            />
                                                        }
                                                        <h3 className="text-muted mb-0 font-size-sm text-truncate campaign-title"
                                                           title={idea.campaign.name} aria-label={localizer.msg('idea.fields.campaign.label')}>
                                                            {idea.campaign.name}
                                                        </h3>
                                                        {communityConfig.classificationEnabled && idea.attributes &&
                                                            <ClassificationLabel
                                                                classification={ClassificationUtil.getAttributeByName(CLASSIFICATION_NAME.EFFECTIVE_CLASSIFICATION, idea.attributes)}
                                                                extraClasses="me-1 mb-1 mt-1 align-middle"/>
                                                        }
                                                        {idea.title === UNAUTHORIZED_PRIVATE ? idea.title :
                                                            <Link to={appLinks.ideaDetails(idea.id)}
                                                                  title={idea.title}
                                                                  className="fw-bold me-1">
                                                                {idea.title}
                                                            </Link>
                                                        }
                                                        <LabelsView labels={idea.labels} onLabelClick={ideaLabelRouteChange}/>
                                                        <div className="mb-1">
                                                            <MemberLink
                                                                className="text-truncate fw-normal me-2 d-block"
                                                                id={idea.submitter.id}
                                                                identityHidden={idea.submitter.identityHidden}
                                                                title={idea.submitter.name}>
                                                                {idea.submitter.name}
                                                            </MemberLink>
                                                            <span className="text-muted d-block">
                                                                        <TimeAgo localizer={localizer}
                                                                                 prefixText={false}
                                                                                 dateISOString={idea.createdAt}/>
                                                                    </span>
                                                        </div>
                                                    </li>
                                                );
                                            })
                                        }
                                        <InfiniteScrollLoadMoreButton hasMore={hasMore}
                                                                      localizer={localizer}
                                                                      loading={loading}
                                                                      onCLick={loadIdeas}
                                                                      loadMoreButtonRef={loadMoreButtonRef}/>
                                    </ul>
                                </Scrollbar> :
                                <div className="text-gray mt-2 mb-2">
                                    {localizer.msg('profile.no-owned-ideas')}
                                </div>
                            }
                        </Fragment>
                }
            </div>
        </div>
    );
};