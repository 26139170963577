export enum ActionType {
    IDEA = 'IDEA',
    IDEA_REMOVE = 'IDEA_REMOVE',
    VOTE_POSITIVE = 'VOTE_POSITIVE',
    VOTE_NEGATIVE = 'VOTE_NEGATIVE',
    VOTE_POSITIVE_RETRACT = 'VOTE_POSITIVE_RETRACT',
    VOTE_NEGATIVE_RETRACT = 'VOTE_NEGATIVE_RETRACT',
    COMMENT = 'COMMENT',
    COMMENT_REMOVE = 'COMMENT_REMOVE',
    MODIFY = 'MODIFY',
    CONTRIBUTOR_ADDED = 'CONTRIBUTOR_ADDED',
    CONTRIBUTOR_REMOVED = 'CONTRIBUTOR_REMOVED',
    IDEA_CAMPAIGN_CHANGE = 'IDEA_CAMPAIGN_CHANGE',
    IDEA_MERGE = 'IDEA_MERGE',
    IDEA_KUDO = 'IDEA_KUDO',
    COMMENT_KUDO = 'COMMENT_KUDO',
    MEMBER_FOLLOW_IDEA = 'MEMBER_FOLLOW_IDEA',
    MEMBER_UN_FOLLOW_IDEA = 'MEMBER_UN_FOLLOW_IDEA',
    MEMBER_ASSESSMENT_COMPLETE = 'MEMBER_ASSESSMENT_COMPLETE',
    MEMBER_REVIEW_COMPLETE = 'MEMBER_REVIEW_COMPLETE',
    MEMBER_FUND_PLEDGE = 'MEMBER_FUND_PLEDGE',
    MEMBER_ESTIMATE_ROI = 'MEMBER_ESTIMATE_ROI',
    MEMBER_REFINEMENT_COMPLETE = 'MEMBER_REFINEMENT_COMPLETE',
    IDEA_STATUS_CHANGE = 'IDEA_STATUS_CHANGE',
    IDEA_APPROVE = 'IDEA_APPROVE',
    IDEA_RECYCLE_BIN = 'IDEA_RECYCLE_BIN',
    COMMENT_RECYCLE_BIN = 'COMMENT_RECYCLE_BIN',
    TAG_ADDED = 'TAG_ADDED',
    TAG_REMOVED = 'TAG_REMOVED',
    CONTRIBUTION_LABEL_ADDED = 'CONTRIBUTION_LABEL_ADDED',
    CONTRIBUTION_LABEL_REMOVED = 'CONTRIBUTION_LABEL_REMOVED',
    LINKED_IDEA = 'LINKED_IDEA',
    UNLINKED_IDEA = 'UNLINKED_IDEA',
}