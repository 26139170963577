import React, {Fragment} from 'react';
import {ClassificationLabel, Localizer, TimeAgo, useApplicableClassifications} from '@ideascale/commons';
import {TicketData} from 'models/TicketData';
import iconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {TicketKey} from 'models/enums/TicketKey';
import youtrack from 'assets/img/youtrack.png';
import jira from 'assets/img/jira.png';
import trello from 'assets/img/trello.png';
import azure from 'assets/img/azure.png';
import {ActivityCard} from '../idea/details/ActivityCard';
import './TicketActivity.scss';
import {useAppContext} from 'contexts/AppContext';

type TicketActivityProps = {
    localizer: Localizer;
    ticketActivity: TicketData[];
}

export const TicketActivity = (props: TicketActivityProps) => {
    const {localizer, ticketActivity} = props;
    const {communityConfig} = useAppContext();
    const {getClassificationAttribute} = useApplicableClassifications('', []);

    const renderName = (ticket: TicketData) => {
        switch (ticket.connectorKey) {
            case TicketKey.YOUTRACK:
                return 'Youtrack';
            case TicketKey.JIRA:
                return 'Jira';
            case TicketKey.TRELLO:
                return 'Trello';
            case TicketKey.AZURE:
                return 'Azure';
            default:
                return '';
        }
    };

    const renderIcon = (ticket: TicketData) => {
        switch (ticket.connectorKey) {
            case TicketKey.YOUTRACK:
                return <img src={youtrack} alt={'youtrack'}/>;
            case TicketKey.JIRA:
                return <img src={jira} alt={'jira'}/>;
            case TicketKey.TRELLO:
                return <img src={trello} alt={'trello'}/>;
            case TicketKey.AZURE:
                return <img src={azure} alt={'azure'}/>;
            default:
                return '';
        }
    };

    return (
        <div className="pb-3">
            {ticketActivity.map(item => {
                return (
                    <Fragment key={item.createdAt}>
                        <ActivityCard containerClass="campaign-stages ticket-activity"
                                      headerTitle={`${renderName(item)} ${localizer.msg('common.ticket-created')}`}
                                      svgIconSprite={iconPath}
                                      headerSummary={
                                          <>
                                              <p className="mb-0 mt-3 fw-normal">
                                                  {renderName(item)} {localizer.msg('common.ticket-created')} {' '}
                                                  <TimeAgo localizer={localizer} dateISOString={item.createdAt}/>
                                              </p>
                                          </>

                                      }
                                      defaultOpen={true}>
                            <div className="mb-3">
                                <div className="product-logo me-2 d-inline">
                                    {renderIcon(item)}
                                </div>
                                <a href={item.issueUrl}>
                                    {
                                        communityConfig.classificationEnabled && item.classificationSummary &&
                                        <ClassificationLabel
                                            classification={getClassificationAttribute(item.classificationSummary)}
                                            extraClasses="me-1 align-baseline"/>

                                    }
                                    {item.summary}
                                </a>
                            </div>
                        </ActivityCard>
                    </Fragment>
                );
            })}
        </div>
    );
};