import React, {Fragment} from 'react';
import {Avatar} from '@ideascale/ui';
import {Localizer, MemberLink, StringUtil} from '@ideascale/commons';
import {LeaderboardItem} from 'models/LeaderboardItem';
import {LeaderboardType} from 'models/enums/LeaderboardType';
import {useAppContext} from 'contexts/AppContext';
import styles from './LeaderboardList.module.scss';

type LeaderboardListProps = {
    localizer: Localizer;
    type: LeaderboardType;
    items: LeaderboardItem[];
    disabled: boolean;
}

export const LeaderboardList = (props: LeaderboardListProps) => {
    const {localizer, type, items, disabled} = props;
    const {authentication: {actor}} = useAppContext();
    const ItemName = localizer.msg(`leaderboard.type.${StringUtil.snakeCaseToKebabCase(type)}.item`);

    const isOwnItem = (memberId: number) => {
        return actor.id === memberId;
    };

    return (
        <Fragment>
            {
                disabled
                    ? <li
                        className="p-4 text-muted">{ItemName} {localizer.msg('common.are-disabled-for-this-campaign')}</li>
                    : items.length > 0
                        ? items.map((item, index) =>
                            <li className={`d-flex align-items-center border-bottom-1 py-2 px-4 ${styles.leaderboardItem} ${isOwnItem(item.leader.id) ? styles.ownProfile : ''}`}
                                key={`${item.leader.id}_${index}`}>
                                <div
                                    className={`${styles.rankCount} fw-bold ${isOwnItem(item.leader.id) ? 'text-primary' : 'text-gray'}`}>
                                    {item.rating}
                                </div>
                                <div className="avatar me-2">
                                    <MemberLink id={item.leader.id} identityHidden={item.leader.identityHidden}>
                                        <Avatar size="sm" src={item.leader.avatar}
                                                alt={item.leader.username ? localizer.msg('common.user-avatar', {username: item.leader.username}) : ''}/>
                                    </MemberLink>
                                </div>
                                <div className="fw-bold text-truncate" title={item.leader.name}>
                                    <MemberLink className={`${isOwnItem(item.leader.id) ? 'text-primary' : ''}`}
                                                id={item.leader.id} identityHidden={item.leader.identityHidden}
                                                title={item.leader.name}>
                                        {StringUtil.textTruncate(item.leader.name, 25)}
                                    </MemberLink>
                                </div>
                                <div className={`${styles.count} text-center ms-auto`}>
                                    <p className={`mb-0 fw-bold font-size-lg ${isOwnItem(item.leader.id) ? 'text-primary' : ''}`}>{item.count}</p>
                                    <p className={`mb-0 font-size-sm h6 ${isOwnItem(item.leader.id) ? 'text-primary' : ''}`}>
                                        {localizer.msg(`leaderboard.type.${StringUtil.snakeCaseToKebabCase(type)}.item`)}
                                    </p>
                                </div>
                            </li>
                        )
                        : <li className="text-muted ps-4 py-3">
                            {localizer.msg(`leaderboard.type.${StringUtil.snakeCaseToKebabCase(type)}.no-data`)}
                        </li>
            }
        </Fragment>
    );
};