import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {ClassificationLabel, ClassificationUtil, InfiniteScrollLoadMoreButton, useApiErrorResponseHandler, useIntersectionObserver} from '@ideascale/commons';
import {ParagraphSkeleton, Scrollbar, ScrollbarInstance} from '@ideascale/ui';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useProfilePageParams} from 'hooks/useProfilePageParams';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {PageParameters} from 'models/types/PageParameters';
import {CommunityMembershipsData} from 'models/CommunityMembershipsData';
import './CommunityMemberships.scss';

type CommunityMembershipsProps = {
    fetchCommunityMemberships: (memberId: number, pageParams: PageParameters) => Promise<PagedResponseContent<CommunityMembershipsData>>
}

const PAGE_SIZE = 5;
const COMMUNITY_MEMBERSHIPS = 'community-memberships';

export const CommunityMemberships = (props: CommunityMembershipsProps) => {
    const {fetchCommunityMemberships} = props;
    const {communityConfig: {classificationEnabled}} = useAppContext();
    const localizer = useLocalizer();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const scrollbarContainer = useRef<ScrollbarInstance>(null);
    const loadMoreButtonRef = useRef<HTMLButtonElement>(null);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [communityMemberships, setCommunityMemberships] = useState<CommunityMembershipsData[]>([]);
    const currentPage = useRef(0);
    const currentProfileId = useProfilePageParams();

    const loadCommunities = useCallback(async () => {
        try {
            if (currentProfileId > 0) {
                const pagedResponse = await fetchCommunityMemberships(currentProfileId, {page: currentPage.current, limit: PAGE_SIZE});
                if (pagedResponse.hasMore) {
                    currentPage.current = currentPage.current + 1;
                }
                setHasMore(pagedResponse.hasMore);
                if (pagedResponse.pageNo === 0) {
                    setCommunityMemberships(pagedResponse.content);
                } else {
                    setCommunityMemberships(prevData => [...prevData, ...pagedResponse.content]);
                }
            }
        } catch (error: any) {
            handleErrorResponse(error);
        }
    }, [currentProfileId, fetchCommunityMemberships, handleErrorResponse]);

    useIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: loadCommunities,
        enabled: hasMore || false,
        options: {
            root: scrollbarContainer.current?.container
        }
    });

    useEffect(() => {
        currentPage.current = 0;
    }, [currentProfileId]);

    useEffect(() => {
        loadCommunities().then(() => setLoading(false));
    }, [loadCommunities]);

    const fallBackImage = (event: React.SyntheticEvent<HTMLImageElement>) => {
        event.currentTarget.onerror = null;
        event.currentTarget.src = '/img/ideascale-icon.png';
    };

    return (
        <div className="mb-3">
            <div className="py-0 border-top-0">
                {
                    loading ?
                        <div className="p-2 pt-3">
                            <ParagraphSkeleton rows={6}/>
                        </div> :
                        <Fragment>
                            {communityMemberships.length > 0 ?
                                <Scrollbar id={COMMUNITY_MEMBERSHIPS}
                                           autoHeight
                                           autoHeightMin={200}
                                           autoHeightMax={200}
                                           ref={scrollbarContainer}>
                                    <ul className="list-unstyled m-0 pe-2" aria-label={localizer.msg('profile.community-memberships')}>
                                        {
                                            communityMemberships.map(community => {
                                                return (
                                                    <li key={community.memberId}
                                                        className="d-flex align-items-center py-2">
                                                        {community.communityLogoUrl &&
                                                            <img className="rounded me-2"
                                                                 src={community.communityLogoUrl}
                                                                 width={30}
                                                                 alt={community.communityLogoAltText}
                                                                 onError={fallBackImage}/>
                                                        }
                                                        <span className="name-wrapper">
                                                        {
                                                            classificationEnabled && community.classificationSummary &&
                                                            <span className="d-block">
                                                            <ClassificationLabel
                                                                classification={ClassificationUtil.getClassificationAttribute(community.classificationSummary)}
                                                                extraClasses="me-1"/>
                                                            </span>
                                                        }
                                                            <a className="fw-bold" href={community.communityUrl}>
                                                            {community.communityName}
                                                        </a>
                                                        </span>
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>
                                    <InfiniteScrollLoadMoreButton hasMore={hasMore}
                                                                  localizer={localizer}
                                                                  loading={loading}
                                                                  onCLick={loadCommunities}
                                                                  loadMoreButtonRef={loadMoreButtonRef}/>
                                </Scrollbar> :
                                <div className="text-gray mt-2 mb-2">
                                    {localizer.msg('profile.no-community-memberships')}
                                </div>
                            }
                        </Fragment>
                }
            </div>
        </div>
    );
};