import React, {Fragment, useCallback, useMemo, useRef, useState} from 'react';
import uniqBy from 'lodash/uniqBy';
import {Avatar, Icon, RichTextClassificationConfig} from '@ideascale/ui';
import {
    AttachmentAttributeParameters,
    ClassificationAttachmentAttribute,
    ClassificationLabel,
    ClassificationsHolder,
    ClassificationUtil,
    ConfirmationModal,
    FileAttachmentDetail,
    FileUploadResponse,
    LabelData,
    Localizer,
    TimeAgo,
    UnfurledUrl,
    UnfurledUrlList,
    UploadProgressCallback,
    useToggle
} from '@ideascale/commons';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useIdeaAttachment} from 'hooks/useIdeaAttachment';
import {useAppContext} from 'contexts/AppContext';
import {useCommentService} from 'hooks/useCommentService';
import {ReplyComment} from './ReplyComment';
import {CommentVote} from './CommentVote';
import {CommentActions} from './CommentActions';
import {PinnedBy} from './PinnedBy';
import {CommentMeta} from './CommentMeta';
import {LabelsView} from 'shared/LabelsView';
import {ReplyForm} from './ReplyForm';
import {AttachmentForm} from './AttachmentForm';
import {EditForm} from './EditForm';
import {CommentApproval} from './CommentApproval';
import ViewOriginalLanguageCard from 'components/ViewOriginalLanguageCard';
import {AttachmentPreview} from 'components/idea-details-tabs/AttachmentPreview';
import {HtmlRenderer} from '../shared/HtmlRenderer';
import {ID_PREFIXES} from 'constants/AppConstants';
import {Scroller} from 'utils/Scroller';
import {CommentSummary} from 'models/comments/CommentSummary';
import {CommentPermissions} from 'models/comments/CommentPermissions';
import {CommentLevel} from 'models/comments/CommentLevel';
import {KudoActivitySummary} from 'models/KudoActivitySummary';
import {AttachmentFormInputs} from 'models/comments/AttachmentFormInputs';
import {StageFunctionName} from 'models/enums/StageFunctionName';
import {TranslationStatus} from 'models/enums/TranslationStatus';
import {OriginalComment} from 'models/comments/OriginalComment';
import {FileAttachmentsHolder} from 'models/FileAttachmentsHolder';
import styles from './Comment.module.scss';

type CommentProps = {
    idPrefix: string;
    localizer: Localizer;
    ideaId: number;
    comment: CommentSummary;
    addLabel: (commentId: number, labelData: LabelData) => Promise<LabelData[]>;
    annotateComment: (commentId: number, commentLevel: CommentLevel) => void;
    removeLabel: (commentId: number, labelData: LabelData) => Promise<void>;
    upVote: (commentSummary: CommentSummary) => void;
    downVote: (commentSummary: CommentSummary) => void;
    approveComment: (commentSummary: CommentSummary) => void;
    rejectComment: (commentSummary: CommentSummary) => void;
    giveKudo: (commentId: number) => Promise<KudoActivitySummary>;
    reportAbuse: (comment: CommentSummary) => void;
    fetchOriginalComment: (commentId: number) => Promise<OriginalComment>;
    fetchEditComment: (commentId: number) => Promise<CommentSummary>;
    uploadFile: (data: FormData, onUploadProgress: UploadProgressCallback) => Promise<FileUploadResponse>;
    addAttachments: (commentId: number, formInputs: AttachmentFormInputs, attributeApiParameters?: Array<AttachmentAttributeParameters>) => Promise<FileAttachmentsHolder>;
    unfurlUrl: (url: string) => Promise<UnfurledUrl>;
    onReplyComment: (comment: CommentSummary, replyComment: CommentSummary, commentLevel: CommentLevel) => void;
    onAddFileAttachments: (comment: CommentSummary, attachments: FileAttachmentDetail[], commentLevel: CommentLevel, attributes?: ClassificationAttachmentAttribute[]) => void;
    deleteComment: (comment: CommentSummary, commentLevel: CommentLevel) => void;
    commentPermissions?: CommentPermissions;
    pinned?: boolean;
    className?: string;
    deleteAttachment?: (comment: CommentSummary, attachmentId: number, commentLevel: CommentLevel) => void;
    editComment?: (comment: CommentSummary, commentLevel: CommentLevel) => void;
    pinUnpinComment?: (commentId: number, pinned: boolean) => void;
    classificationsMenuData?: ClassificationsHolder | {};
    richTextClassificationConfig?: RichTextClassificationConfig;
}

export const Comment = (props: CommentProps) => {
    const {
        idPrefix,
        localizer,
        comment,
        pinned,
        ideaId,
        className = '',
        deleteAttachment,
        onReplyComment,
        commentPermissions,
        onAddFileAttachments,
        editComment,
        deleteComment,
        pinUnpinComment,
        annotateComment,
        addLabel,
        removeLabel,
        upVote,
        downVote,
        approveComment,
        rejectComment,
        giveKudo,
        reportAbuse,
        fetchOriginalComment,
        uploadFile,
        addAttachments,
        unfurlUrl,
        fetchEditComment,
        classificationsMenuData = {},
        richTextClassificationConfig
    } = props;
    const {author, text, createdAt, pinnedBy} = comment;
    const {
        authentication,
        communityConfig: {
            maxFileSizeLimit,
            translatedIdeaShowAllowed,
            contentTranslationEnabled,
            classificationEnabled,
            unfurlUrlEnabled
        }
    } = useAppContext();
    const {deleteCommentAttachment, fetchCommentLabelsById, getCommentKudoGivers} = useCommentService();
    const {updateIdeaDetailsCommentAttachments} = useIdeaAttachment();
    const [showReplies, toggleReplies] = useToggle(true);
    const [showReplyForm, toggleReplyForm] = useToggle(false);
    const [showEditForm, toggleEditForm] = useToggle(false);
    const [showAttachmentForm, toggleAttachmentForm] = useToggle(false);
    const [showDeleteConfirmation, toggleDeleteConfirmation] = useToggle(false);
    const [showRejectConfirmation, toggleRejectConfirmation] = useToggle(false);
    const [showReportAbuseConfirmation, toggleReportAbuseConfirmation] = useToggle(false);
    const [showDeleteAttachmentConfirmation, toggleDeleteAttachmentConfirmation] = useToggle(false);
    const [showOriginalLanguageCard, setShowOriginalLanguageCard] = useState(false);
    const deleteAttachmentId = useRef<number>(0);

    const onShowRepliesComments = () => {
        toggleReplies(true);
    };

    const scrollToForm = useCallback(() => {
        const timeOutId = setTimeout(() => {
            Scroller.scrollTo(`${idPrefix}-${ID_PREFIXES.REPLY_FORM}-${comment.id}`, {offset: -300, duration: 300});
            clearTimeout(timeOutId);
        }, 100);
    }, [comment.id, idPrefix]);

    const scrollToComment = useCallback(() => {
        const timeOutId = setTimeout(() => {
            Scroller.scrollTo(`${idPrefix}-${comment.id}`, {offset: -300, duration: 300});
            clearTimeout(timeOutId);
        }, 100);
    }, [comment.id, idPrefix]);

    const onReplyClick = useCallback(() => {
        toggleReplyForm(true);
        toggleAttachmentForm(false);
        scrollToForm();
    }, [scrollToForm, toggleAttachmentForm, toggleReplyForm]);

    const onAttachmentClick = useCallback(() => {
        toggleAttachmentForm(true);
        toggleReplyForm(false);
        scrollToForm();
    }, [scrollToForm, toggleAttachmentForm, toggleReplyForm]);

    const onSubmitReplyClick = useCallback((replyComment: CommentSummary) => {
        toggleReplyForm(false);
        onReplyComment(comment, replyComment, CommentLevel.Level1);
        toggleReplies(true);
    }, [comment, onReplyComment, toggleReplies, toggleReplyForm]);

    const getLastThreeReplier = useMemo(() => {
        const allReplier = comment.replies?.map(reply => reply.author);
        return uniqBy(allReplier, 'id').slice(-3).reverse();
    }, [comment.replies]);

    const getLastReply = useCallback(() => {
        return comment.replies[comment.replies.length - 1];
    }, [comment.replies]);

    const onDeleteAttachment = async (commentSummary: CommentSummary, attachmentId: number, commentLevel: CommentLevel) => {
        const deleteResponse = await deleteCommentAttachment(commentSummary.id, attachmentId);
        if (deleteResponse?.status === 'success') {
            deleteAttachment && deleteAttachment(commentSummary, attachmentId, commentLevel);
        }

        if (comment.annotated) {
            await updateIdeaDetailsCommentAttachments(ideaId, [], [], attachmentId);
        }
    };

    const onAddAttachments = useCallback(async (commentSummary: CommentSummary, attachments: FileAttachmentDetail[], commentLevel: CommentLevel, attributes?: ClassificationAttachmentAttribute[]) => {
        onAddFileAttachments && onAddFileAttachments(commentSummary, attachments, commentLevel, attributes);
        toggleAttachmentForm(false);

        const newAttachments = attachments.filter(item => !comment.attachments?.map(file => file.filename).includes(item.filename));
        if (comment.annotated && newAttachments?.length > 0) {
            await updateIdeaDetailsCommentAttachments(ideaId, newAttachments, (attributes?.filter(item => item.name === 'filename') as ClassificationAttachmentAttribute[]));
        }
    }, [comment.annotated, comment.attachments, ideaId, onAddFileAttachments, toggleAttachmentForm, updateIdeaDetailsCommentAttachments]);

    const onEditComment = useCallback((comment: CommentSummary, commentLevel: CommentLevel) => {
        editComment && editComment(comment, commentLevel);
        toggleEditForm(false);
    }, [editComment, toggleEditForm]);

    const onReplyCancel = () => {
        toggleReplyForm();
        scrollToComment();
    };

    const onEditCancel = () => {
        toggleEditForm();
        scrollToComment();
    };

    const onAttachmentCancel = () => {
        toggleAttachmentForm();
        scrollToComment();
    };

    const translationLabel = useMemo(() => {
        return new LabelData(comment.id, localizer.msg('original-idea.translated'), `translated-${comment.id}`, 'translated', true, false, false, false);
    }, [comment.id, localizer]);

    return (
        <article className={`${styles.comment} ${className}`} id={`${idPrefix}-${comment.id}`} tabIndex={-1}>
            <h1 className="sr-only">{localizer.msg('comments.comment-title')}</h1>
            <div className="d-flex">
                <div className="avatar-container me-2 me-lg-4 avatar-md">
                    {
                        !showEditForm && (
                            <Avatar src={author.avatar}
                                    alt={author.username ? localizer.msg('common.user-avatar', {username: author.username}) : ''}
                                    size="md"/>
                        )
                    }
                </div>

                <div className="flex-grow-1">
                    {
                        showEditForm && (
                            <EditForm id={`${idPrefix}-${ID_PREFIXES.REPLY_FORM}-${comment.id}`} comment={comment}
                                      parentId={comment.parentId}
                                      localizer={localizer} ideaId={ideaId}
                                      onEditComment={(editedComment) => onEditComment(editedComment, CommentLevel.Level1)}
                                      fetchEditComment={fetchEditComment}
                                      onEditCancel={onEditCancel}
                                      classificationsMenuData={classificationsMenuData}
                                      richTextClassificationConfig={richTextClassificationConfig}/>
                        )
                    }
                    {
                        !showEditForm && (
                            <>
                                {
                                    pinned && pinnedBy && <PinnedBy localizer={localizer} author={pinnedBy}/>
                                }
                                <CommentMeta elementId={`${idPrefix}-${comment.id}`} comment={comment}
                                             onGiveKudo={giveKudo} localizer={localizer}
                                             actor={authentication.actor} createdAt={createdAt}
                                             fetchKudoGivers={getCommentKudoGivers}/>
                                <div className={`${styles.commentLabels} mt-2`}>
                                    <LabelsView labels={comment.labels} showStackView={false}/>
                                </div>
                                {
                                    classificationEnabled && comment.attributes &&
                                    <ClassificationLabel
                                        classification={ClassificationUtil.getEffectiveAttribute(comment.attributes)}/>
                                }
                                <div className="comment-content mt-2">
                                    <div
                                        className={`${comment.stage.toLowerCase() === StageFunctionName.PENDING ? 'approval-pending' : ''}`}>
                                        <HtmlRenderer content={text} reactOptions={{atMention: true}}/>
                                    </div>
                                    {
                                        contentTranslationEnabled && (comment.translation.translated || authentication.isTranslationModerator()) && (
                                            <section className="d-flex align-items-center flex-wrap">
                                                {
                                                    comment.translation.translated && translatedIdeaShowAllowed &&
                                                    <Fragment>
                                                        <div>
                                                            <LabelsView labels={[translationLabel]} showStackView={false}/>
                                                        </div>
                                                        {
                                                            comment.translation.translationStatus === TranslationStatus.UNVERIFIED &&
                                                            (authentication.isTranslationModerator() || authentication.isModerator()) &&
                                                            <em className="text-warning me-2">({localizer.msg('original-idea.view.unverified')})</em>
                                                        }
                                                        {!showOriginalLanguageCard &&
                                                            <button className="btn btn-link p-0 me-2"
                                                                    id={`view-original-${comment.id}`}
                                                                    onClick={() => setShowOriginalLanguageCard(true)}>{localizer.msg('original-idea.view.original')}</button>}
                                                        {showOriginalLanguageCard &&
                                                            <ViewOriginalLanguageCard
                                                                id={`view-original-card-${comment.id}`}
                                                                commentId={comment.id}
                                                                localizer={localizer}
                                                                className="my-3"/>}
                                                    </Fragment>}
                                                {
                                                    comment.reviewTranslationUrl && authentication.isTranslationModerator() &&
                                                    <Fragment>
                                                        {
                                                            <a className="mx-1 btn btn-link px-0 shadow-none d-inline-block"
                                                               href={comment.reviewTranslationUrl}>
                                                                {localizer.msg('comments.actions.review-translation')}
                                                            </a>
                                                        }
                                                    </Fragment>
                                                }
                                            </section>
                                        )
                                    }
                                    <UnfurledUrlList content={text} unfurlUrl={unfurlUrl} localizer={localizer} enabled={unfurlUrlEnabled}/>
                                </div>
                                {
                                    (!comment.actions?.approvalAllowed || !comment.actions?.rejectionAllowed) && (
                                        <CommentVote
                                            upVoteCount={comment.upVoteCount}
                                            downVoteCount={comment.downVoteCount}
                                            onUpVoteCount={() => upVote(comment)}
                                            onDownVoteCount={() => downVote(comment)}
                                            upVoteEnabled={authentication.actor.id !== comment.author.id}
                                            downVoteEnabled={authentication.actor.id !== comment.author.id}
                                        />
                                    )
                                }
                            </>
                        )
                    }

                    <AttachmentPreview attachments={comment.attachments}
                                       localizer={localizer}
                                       onDeleteAttachment={(attachmentId) => {
                                           deleteAttachmentId.current = attachmentId;
                                           toggleDeleteAttachmentConfirmation(true);
                                       }}
                                       enabledClassification={classificationEnabled}
                                       fileAttributes={ClassificationUtil.getAppliedFileClassifications('filename', comment.attributes)}
                    />

                    {
                        (comment.actions?.approvalAllowed || comment.actions?.rejectionAllowed) && (
                            <CommentApproval
                                localizer={localizer}
                                approvalAllowed={comment.actions.approvalAllowed}
                                rejectionAllowed={comment.actions.rejectionAllowed}
                                onApproveClick={() => approveComment(comment)}
                                onRejectClick={() => toggleRejectConfirmation(true)}/>
                        )
                    }

                    {
                        !showReplies && comment.replies?.length > 0 && (
                            <div className="replies-summary d-flex align-items-center mt-3">
                                <div className="multiple-avatar">
                                    {
                                        getLastThreeReplier.map((commenter, index) => (
                                            <img key={index} className="avatar avatar-sm"
                                                 src={commenter.avatar}
                                                 alt={commenter.name}/>
                                        ))
                                    }
                                </div>
                                <button className="btn btn-link px-2"
                                        onClick={onShowRepliesComments}>{localizer.msg('comments.reply', {count: comment.replies?.length})}</button>
                                <span>
                                {`${localizer.msg('comments.last-reply')} `}<TimeAgo localizer={localizer}
                                                                                     dateISOString={getLastReply().updatedAt}/>
                            </span>
                            </div>
                        )
                    }
                    {
                        showReplies && comment.replies?.length > 0 && (
                            <>
                                <button
                                    className="btn btn-link text-decoration-none d-flex hide-replies px-0 align-items-center mt-3 shadow-none"
                                    onClick={() => toggleReplies(false)}>
                                    <span>{localizer.msg('comments.hide-replies')}</span>
                                    <Icon iconSpritePath={svgIconsPath} width={12} height={12}
                                          name="chevron-down" className="ms-1 fill-primary"/>
                                </button>
                                <ul className="list-unstyled child-comments">
                                    {comment?.replies?.map(reply => (<li key={reply.id}>
                                        <ReplyComment
                                            localizer={localizer} comment={reply} ideaId={ideaId}
                                            parentComment={comment}
                                            labelsPermitted={commentPermissions?.labelsApplicable || false}
                                            onReplyComment={onReplyComment}
                                            onAddFileAttachments={onAddAttachments}
                                            deleteAttachment={(commentSummary, attachmentId, commentLevel) => onDeleteAttachment(commentSummary, attachmentId, commentLevel)}
                                            editComment={(editedComment, commentLevel) => onEditComment(editedComment, commentLevel)}
                                            commentLevel={CommentLevel.Level2}
                                            deleteComment={deleteComment}
                                            annotateComment={(commentId, commentLevel) => annotateComment(commentId, commentLevel)}
                                            fetchCommentLabelsById={fetchCommentLabelsById}
                                            addLabel={addLabel}
                                            removeLabel={removeLabel}
                                            upVote={upVote}
                                            downVote={downVote}
                                            approveComment={approveComment}
                                            rejectComment={rejectComment}
                                            giveKudo={giveKudo}
                                            reportAbuse={reportAbuse}
                                            idPrefix={`${idPrefix}`}
                                            fetchKudoGivers={getCommentKudoGivers}
                                            fetchOriginalComment={fetchOriginalComment}
                                            uploadFile={uploadFile}
                                            addAttachments={addAttachments}
                                            unfurlUrl={unfurlUrl}
                                            fetchEditComment={fetchEditComment}
                                            classificationsMenuData={classificationsMenuData}
                                            richTextClassificationConfig={richTextClassificationConfig}
                                        />
                                    </li>))}
                                </ul>
                            </>
                        )
                    }
                    {
                        !showEditForm && comment.actions && (
                            <CommentActions
                                localizer={localizer}
                                fetchCommentLabelsById={fetchCommentLabelsById}
                                actionsPermitted={comment.actions}
                                labelsPermitted={commentPermissions?.labelsApplicable || false}
                                onReplyClick={onReplyClick}
                                onAttachmentClick={onAttachmentClick}
                                onEditClick={() => {
                                    toggleEditForm(true);
                                    scrollToForm();
                                }}
                                onDeleteClick={() => toggleDeleteConfirmation(true)}
                                pinned={comment.pinned}
                                annotated={comment.annotated}
                                onPinUnpinClick={(pinned) => pinUnpinComment && pinUnpinComment(comment.id, pinned)}
                                onAnnotateClick={() => annotateComment(comment.id, CommentLevel.Level1)}
                                commentId={comment.id}
                                onAddLabelClick={addLabel}
                                onRemoveLabelClick={removeLabel}
                                onReportAbuseClick={() => toggleReportAbuseConfirmation(true)}
                            />
                        )
                    }

                    {
                        showReplyForm && (
                            <ReplyForm id={`${idPrefix}-${ID_PREFIXES.REPLY_FORM}-${comment.id}`} localizer={localizer}
                                       actor={authentication.actor}
                                       onSubmitReply={onSubmitReplyClick}
                                       onReplyCancel={onReplyCancel}
                                       replyId={comment.id} ideaId={ideaId} parentId={comment.id}
                                       commentAuthor={comment.author}
                                       privateComment={comment.privateComment}
                                       classificationsMenuData={classificationsMenuData}
                                       richTextClassificationConfig={richTextClassificationConfig}
                            />
                        )
                    }

                    {
                        showAttachmentForm && (
                            <AttachmentForm id={`${idPrefix}-${ID_PREFIXES.REPLY_FORM}-${comment.id}`}
                                            localizer={localizer}
                                            maxFileSizeLimit={maxFileSizeLimit}
                                            commentId={comment.id}
                                            onAttachmentCancel={onAttachmentCancel}
                                            uploadFile={uploadFile}
                                            addAttachments={addAttachments}
                                            classificationsMenuData={classificationsMenuData}
                                            classificationEnabled={classificationEnabled}
                                            onAddAttachments={(commentId, attachments, attributes) => onAddAttachments(comment, attachments, CommentLevel.Level1, attributes)}/>
                        )
                    }

                    {
                        showDeleteConfirmation && (
                            <ConfirmationModal localizer={localizer} message={localizer.msg('comments.confirm-message')}
                                               open={showDeleteConfirmation} toggle={toggleDeleteConfirmation}
                                               onConfirm={() => deleteComment(comment, CommentLevel.Level1)}/>)
                    }

                    {
                        showRejectConfirmation && (
                            <ConfirmationModal localizer={localizer} message={localizer.msg('comments.confirm-message')}
                                               open={showRejectConfirmation} toggle={toggleRejectConfirmation}
                                               onConfirm={() => rejectComment(comment)}/>)
                    }

                    {
                        showReportAbuseConfirmation && (<ConfirmationModal localizer={localizer}
                                                                           message={localizer.msg('comments.comment-abused-confirm')}
                                                                           open={showReportAbuseConfirmation}
                                                                           toggle={toggleReportAbuseConfirmation}
                                                                           onConfirm={() => reportAbuse(comment)}/>)
                    }

                    {
                        showDeleteAttachmentConfirmation && (
                            <ConfirmationModal localizer={localizer} message={localizer.msg('comments.confirm-message')}
                                               open={showDeleteAttachmentConfirmation}
                                               toggle={toggleDeleteAttachmentConfirmation}
                                               onConfirm={() => {
                                                   onDeleteAttachment(comment, deleteAttachmentId.current, CommentLevel.Level1).then(() => deleteAttachmentId.current = 0);
                                               }}/>
                        )
                    }
                </div>
            </div>
        </article>
    );
};
