import React from 'react';
import {Localizer, PageTheme} from '@ideascale/commons';
import {Button, Icon, Modal} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {LandingPageSummary} from 'models/landing-page/LandingPageSummary';
import armstrong from 'assets/img/landing-page/common/armstrong.png';
import bernersLee from 'assets/img/landing-page/common/berners-lee.png';
import curie from 'assets/img/landing-page/common/curie.png';
import eastman from 'assets/img/landing-page/common/eastman.png';
import 'components/landing-page/LandingPageTemplateListModal.scss';

type LandingPageTemplateListModalProps = {
    localizer: Localizer;
    open: boolean;
    toggleModal: () => void;
    pages: LandingPageSummary[];
    activePageId: number;
    publishedPageId: number;
    onChangeTemplate: (pageId: number) => void;
}

export const LandingPageTemplateListModal = (props: LandingPageTemplateListModalProps) => {
    const {toggleModal, open, localizer, pages, activePageId, onChangeTemplate, publishedPageId} = props;

    const getThumbnailPath = (theme: PageTheme) => {
        switch (theme) {
            case PageTheme.ARMSTRONG:
                return armstrong;
            case PageTheme.BERNERS_LEE:
                return bernersLee;
            case PageTheme.EASTMAN:
                return eastman;
            case PageTheme.CURIE:
            default:
                return curie;
        }
    };

    return (
        <Modal className="landing-page-template-modal" isOpen={open} toggle={toggleModal} size="lg"
               title={localizer.msg('landing-page.template-selection-modal.title')}>
            <div className="row">
                {
                    pages?.map(page => {
                        const publishedTemplate = publishedPageId === page.id;
                        const activeTemplate = activePageId === page.id;
                        return (
                            <div
                                className={`col-md-6 col-sm-3 p-3 template-container ${publishedTemplate ? 'selected-template' : ''}`}
                                key={page.id}>
                                <div className={`template cursor-pointer ${activeTemplate ? 'active' : ''}`}
                                     onClick={() => onChangeTemplate(page.id)}>
                                    <div className="template-thumbnail">
                                        <img className="w-100" src={getThumbnailPath(page.theme)} alt={page.theme}/>
                                    </div>
                                    <div className="template-info d-flex align-items-center border-top-1 py-3 px-4">
                                        <div
                                            className={`template-name fw-bold d-flex align-items-baseline`}>
                                            {page.name}
                                            {
                                                publishedTemplate &&
                                                <span
                                                    className={`label pos-top-n1 single-label ${activeTemplate ? 'label-field-landing-page-active-template-published' : 'label-field-landing-page-template-published'} ms-2 edit-mode-element`}>
                                                        {localizer.msg('landing-page.action-topbar.published')}
                                                </span>
                                            }
                                        </div>
                                        <Button className="text-break ms-auto edit-btn px-2 py-1 font-size-sm"
                                                color="secondary"
                                                onClick={(event) => {
                                                    event?.stopPropagation();
                                                    onChangeTemplate(page.id);
                                                }}>
                                            <Icon className="active me-1 position-relative pos-top-n1"
                                                  name="pencil"
                                                  iconSpritePath={svgIconPath} width={14} height={14}/>
                                            {localizer.msg('landing-page.template-selection-modal.edit')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </Modal>
    );
};