import {ClassificationSummary} from '@ideascale/commons';

type Stages = {
    id: number,
    name: string,
    label: string,
    key: string,
    functionName: string,
    description: string,
    stageGroup: string,
    defaultStage: boolean
}

export class CampaignDetailsData {
    static readonly EMPTY = new CampaignDetailsData(0, '', '', '', '', '', '', [], false, false, {
        id: 0,
        name: '',
        locationExtensions: {
            countriesExtensions: [],
            fvey: {
                id: 0,
                name: ''
            },
            nf: {
                id: 0,
                name: ''
            }
        },
        sensitiveExtensions: []
    });

    constructor(
        public id: number,
        public name: string,
        public featuredImageUrl: string,
        public featuredImageAltText: string,
        public subtitle: string,
        public summary: string,
        public description: string,
        public stages: Stages[],
        public tosAcceptPending: boolean,
        public tosEnabled: boolean,
        public classificationSummary: ClassificationSummary,
    ) {
    }
}