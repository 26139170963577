import {CommunityHomeEditor} from './Editors/CommunityHomeEditor';
import {Command} from './Command';
import {CommunityOperationType} from 'models/edit-mode/CommunityOperationType';
import {BannerField} from 'models/edit-mode/BannerField';
import {Operation} from 'models/edit-mode/Operation';
import {ChangeCommunityBannerOperation} from 'models/edit-mode/ChangeCommunityBannerOperation';

export class UpdateBannerImageCommand implements Command {
    private readonly communityHomeEditor: CommunityHomeEditor;
    private readonly oldBannerImage: BannerField;
    private readonly newBannerImage: BannerField;
    private readonly name: CommunityOperationType;

    constructor(communityHomeEditor: CommunityHomeEditor, newBannerField: BannerField) {
        this.communityHomeEditor = communityHomeEditor;
        this.newBannerImage = newBannerField;
        this.oldBannerImage = this.communityHomeEditor.bannerImage;
        this.name = CommunityOperationType.CHANGE_COMMUNITY_BANNER;
    }

    execute(): void {
        this.communityHomeEditor.bannerImage = this.newBannerImage;
    }

    getType(): CommunityOperationType {
        return this.name;
    }

    getOperation(): Operation {
        return new ChangeCommunityBannerOperation(CommunityOperationType.CHANGE_COMMUNITY_BANNER, this.communityHomeEditor.bannerImage.fileId, this.communityHomeEditor.bannerImage.gradientEnabled, this.communityHomeEditor.bannerImage.blackPageTitleEnabled, this.communityHomeEditor.bannerImage.imageType);
    }

    undo(): void {
        this.communityHomeEditor.bannerImage = this.oldBannerImage;
    }
}