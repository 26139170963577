import React from 'react';
import range from 'lodash/range';
import {IdeaListViewMode} from 'models/enums/IdeaListViewMode';
import {IdeaSkeleton} from '../IdeaSkeleton';

type IdeaListSkeletonProps = {
    currentViewMode: IdeaListViewMode;
    items?: number;
}

export const IdeaListSkeleton = (props: IdeaListSkeletonProps) => {
    const {currentViewMode, items = 3} = props;

    return (
        <div className={`ideas ${currentViewMode.replace('_', '-').toLowerCase()} loading clearfix`}>
            {
                range(0, items).map(index => <IdeaSkeleton key={`${index}-skeleton`} viewMode={currentViewMode}/>)
            }
        </div>
    );
};