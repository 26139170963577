import React, {Fragment, useCallback, useState} from 'react';
import {FieldValues, useForm} from 'react-hook-form';
import {
    AlertType,
    AttachmentField,
    CheckboxField,
    CurrencyField,
    CustomField,
    DateField,
    FieldType,
    FileAttachmentDetail,
    FileUploadResponse,
    FormAttachment,
    HookFormProvider,
    HtmlUtils,
    hyperlinkRendererResolver,
    Localizer,
    Member,
    MemberLink,
    MemberSearchRequest,
    MultipleChoiceField,
    RenderFormat,
    SingleChoiceField,
    TextAreaField,
    TextField,
    TimeAgo,
    UploadedResponse,
    UploadProgressCallback,
    US_ZIPCODE_MAXLENGTH,
    useApiErrorResponseHandler,
    useFileUploadIntermediary,
    useHandleFormSubmit,
    useToggle
} from '@ideascale/commons';
import {ActionButton, HtmlConverter, Icon, InfoTip} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useAppContext} from 'contexts/AppContext';
import {RefineLanguageField} from 'components/stage-activities/refine/RefineLanguageField';
import {RefineRevisionHistory} from 'components/stage-activities/refine/RefineRevisionHistory';
import {HtmlRenderer} from 'components/shared/HtmlRenderer';
import {FIELD_VALUE_ID_PREFIX} from 'constants/AppConstants';
import {RefineStageField} from 'models/stage-activity/RefineStageField';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {RevisionHistory} from 'models/RevisionHistory';
import {StageActionResponse} from 'models/StageActionResponse';
import {RefineStageActivity} from 'models/stage-activity/RefineStageActivity';
import {RefineStageSummary} from 'models/RefineStageSummary';
import {StageFieldValueParameters} from 'models/types/StageFieldValueParameters';
import {BaseIdeaStageSummary} from 'models/BaseIdeaStageSummary';

type RefineFieldMode = 'EDIT' | 'VIEW';

type RefineFieldProps = {
    localizer: Localizer;
    index: number;
    canEdit: boolean;
    defaultMode: RefineFieldMode;
    field: RefineStageField;
    setActivity: (activity: RefineStageActivity) => void;
    uploadFile: (data: FormData, onUploadProgress: UploadProgressCallback) => Promise<FileUploadResponse>;
    fetchRevisionHistory: (fieldId: number, pageParameters: PageParameters) => Promise<PagedResponseContent<RevisionHistory>>;
    saveRefineStageField: (fieldId: number, fieldParameters: StageFieldValueParameters) => Promise<StageActionResponse<RefineStageActivity, RefineStageSummary>>;
    updateIdeaStageSummary: <T extends BaseIdeaStageSummary>(stageSummary: T) => void;
    tempImageUpload?: (data: FormData, onUploadProgress: UploadProgressCallback) => Promise<UploadedResponse>;
    ideaId?: number;
    campaignId?: number;
    stageId?: number;
    atMentionEnabled?: boolean;
    fetchAtMentionsRefineCustomField?: (parameters: MemberSearchRequest) => Promise<Member[]>;
}

export const RefineField = (props: RefineFieldProps) => {
    const {
        localizer,
        index,
        field,
        canEdit,
        defaultMode = 'VIEW',
        uploadFile,
        setActivity,
        fetchRevisionHistory,
        saveRefineStageField,
        updateIdeaStageSummary,
        tempImageUpload,
        ideaId,
        campaignId,
        stageId,
        atMentionEnabled,
        fetchAtMentionsRefineCustomField
    } = props;
    const {
        communityConfig: {
            dateTimeFormat,
            maxFileSizeLimit,
            multipleLanguageSupported,
            supportedLanguages,
            preferredLanguage,
        }, authentication: {actor}
    } = useAppContext();
    const {onUploadFile, fileUploading} = useFileUploadIntermediary(uploadFile);
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [mode, setMode] = useState<RefineFieldMode>(defaultMode);
    const [langId, setLangId] = useState<number | undefined>(RefineStageField.isLocalizable(field) ? preferredLanguage.id : undefined);
    const [showHistory, toggleHistory] = useToggle(false);
    const [focusOnRender, toggleFocusOnRender] = useToggle(false);
    const methods = useForm({
        defaultValues: {
            ...(field.type === FieldType.ATTACHMENT && {[field.key]: field.attachments})
        }
    });
    const {handleSubmit, setError, reset, watch} = methods;
    const onFormSubmit = useHandleFormSubmit(handleSubmit);
    const formId = `refine-custom-field-${field.key}`;
    const hasRevisionHistory = field.revisionHistoryCount > 0;

    const suggestAtMentionUsers = useCallback(async (searchTerm: string) => {
        const params = {
            term: searchTerm,
            campaignId: campaignId,
            ideaId: ideaId,
            stageId: stageId,
            privateIdea: field.privateField,
            customFieldId: field.fieldId
        };
        return await fetchAtMentionsRefineCustomField?.(params) ?? [];
    }, [campaignId, fetchAtMentionsRefineCustomField, field.fieldId, field.privateField, ideaId, stageId]);

    const renderFieldValue = (field: RefineStageField) => {
        if (!field.id) {
            return <p>{localizer.msg('stage.refine.no-response')}</p>;
        }
        switch (field.type) {
            case FieldType.TEXT_AREA:
                return field.renderFormat === RenderFormat.HTML
                    ? <p id={`${FIELD_VALUE_ID_PREFIX}-${field.id}`}><HtmlRenderer content={field.displayValue}/></p>
                    : <p id={`${FIELD_VALUE_ID_PREFIX}-${field.id}`}>{HtmlUtils.htmlToReact(field.displayValue)}</p>;
            case FieldType.TEXTINPUT:
                return <p>{HtmlUtils.htmlToReact(field.displayValue)}</p>;
            case FieldType.ATTACHMENT:
                return field.attachments.length > 0 &&
                    (
                        <ul aria-label="Already attached" className="mb-2">
                            {watch(field.key).map((attachment, index) => (
                                <li key={index}>
                                    <a className="d-block classic-link" href={attachment.url}>
                                        {attachment.filename}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    );
            case FieldType.SINGLE_CHOICE:
            case FieldType.MULTIPLE_CHOICE:
                return <MultiChoiceDisplay field={field}/>;
            case FieldType.YOUTUBE:
            case FieldType.SCREENR:
            case FieldType.HAPYAK:
            case FieldType.HYPERLINK:
                return <p>
                    <HtmlRenderer content={hyperlinkRendererResolver.resolver(field.displayValue, field.displayValue)}/>
                </p>;
            default:
                return <p>{field.displayValue}</p>;
        }
    };

    const renderField = () => {
        switch (field.type) {
            case FieldType.HYPERLINK:
            case FieldType.TEXTINPUT:
            case FieldType.INTEGER:
                return <TextField formField={field} autoFocus={focusOnRender} hideRequiredIndicator
                                  localizer={localizer}/>;
            case FieldType.ZIPCODE:
                return <TextField formField={field} autoFocus={focusOnRender} maxLength={US_ZIPCODE_MAXLENGTH}
                                  hideRequiredIndicator
                                  localizer={localizer}/>;
            case FieldType.TEXT_AREA:
                return <TextAreaField
                    formField={field}
                    autoFocus={focusOnRender}
                    localizer={localizer}
                    tempImageUpload={tempImageUpload}
                    hideRequiredIndicator
                    maxFileSize={maxFileSizeLimit}
                    atMentionEnabled={atMentionEnabled}
                    fetchAtMentionMembers={suggestAtMentionUsers}/>;
            case FieldType.CURRENCY:
                return <CurrencyField formField={field} autoFocus={focusOnRender} hideRequiredIndicator
                                      localizer={localizer}/>;
            case FieldType.CHECKBOX:
                return <CheckboxField formField={field} autoFocus={focusOnRender} hideRequiredIndicator
                                      localizer={localizer}/>;
            case FieldType.SINGLE_CHOICE:
                return <SingleChoiceField localizer={localizer} formField={field} autoFocus={focusOnRender}
                                          hideRequiredIndicator
                                          defaultValue={RefineStageField.isPersisted(field) ? CustomField.getSingleChoiceSavedValue(field) : CustomField.getSingleChoiceDefaultValue(field)}/>;
            case FieldType.MULTIPLE_CHOICE:
                return <fieldset aria-label={field.label}>
                    <MultipleChoiceField formField={field} autoFocus={focusOnRender} hideRequiredIndicator
                                         localizer={localizer}
                                         defaultValues={RefineStageField.isPersisted(field) ? CustomField.getMultipleChoiceSavedValues(field) : CustomField.getMultipleChoiceDefaultValues(field)}/>
                </fieldset>;
            case FieldType.DATE:
                return <DateField
                    localizer={localizer}
                    formField={field}
                    autoFocus={focusOnRender}
                    dateFormat={dateTimeFormat.datePattern}
                    hideRequiredIndicator
                />;
            case FieldType.ATTACHMENT:
                return <AttachmentField
                    localizer={localizer}
                    ideaField={field}
                    autoFocus={focusOnRender}
                    maxFileSizeLimit={maxFileSizeLimit}
                    uploadFile={onUploadFile}
                    hideRequiredIndicator
                    svgIconPath={svgIconsPath}
                />;
            default:
                return null;
        }
    };

    const onSubmitField = async (data: FieldValues) => {
        let fieldValue = data[field.key];

        let currentAttachments: FileAttachmentDetail[] = [];
        let formAttachments: FormAttachment[] = [];
        if (fieldValue && (field.type === FieldType.ATTACHMENT || field.type === FieldType.MULTIPLE_CHOICE)) {
            if (field.type === FieldType.MULTIPLE_CHOICE) {
                fieldValue = Array.isArray(fieldValue) ? fieldValue.join() : fieldValue;
            } else {
                currentAttachments = (fieldValue as FileAttachmentDetail[]).map(attachment => ({
                    ...attachment,
                    altText: attachment.altText.trim() || attachment.filename
                }));
                if (Array.isArray(fieldValue)) {
                    formAttachments = (fieldValue as FileAttachmentDetail[]).map(attachment => ({
                        altText: attachment.altText.trim() || attachment.filename,
                        filename: attachment.filename
                    }));
                    fieldValue = (fieldValue as FileAttachmentDetail[]).map(attachment => attachment.filename).join();
                } else {
                    formAttachments.push({
                        altText: fieldValue.altText.trim() || fieldValue.filename,
                        filename: fieldValue.filename
                    });
                    fieldValue = fieldValue.filename;
                }
            }
        }

        try {
            const response = await saveRefineStageField(CustomField.getFieldId(field.key), {
                value: field.type === FieldType.TEXT_AREA ? HtmlConverter.toServerHtmlFormat(fieldValue) : fieldValue,
                languageId: langId,
                renderFormat: RenderFormat.HTML,
                name: field.key,
                attachments: formAttachments
            });
            reset({...(field.type === FieldType.ATTACHMENT && {[field.key]: currentAttachments})});
            setMode('VIEW');
            if (response.stageActivity) {
                setActivity(response.stageActivity);
            }
            updateIdeaStageSummary(response.stageSummary);
        } catch (error: any) {
            handleErrorResponse(error, {
                setFormError: setError,
                fallbackMessage: {type: AlertType.error, message: localizer.msg('stage.refine.save-failed')}
            });
        }
    };

    const onCancel = async () => {
        reset({...(field.type === FieldType.ATTACHMENT && {[field.key]: field.attachments})});
        setMode('VIEW');
    };

    return (
        <li className="mb-4">
            {mode === 'VIEW'
                ? <Fragment>
                    <div className="d-flex align-items-center">
                        <p className="fw-bold font-size-normal h5">
                            {index + 1}. {field.label}
                        </p>
                        {
                            field.hint &&
                            <span className="ms-1 field-infotip h5">
                            <InfoTip id={`refine-field-view-${field.id}-${field.key}`} content={field.hint}/>
                        </span>
                        }
                        {field.privateField &&
                            <Fragment>
                                {' '}<span
                                className="label label-field-private">{localizer.msg('stage.refine.private')}</span>
                            </Fragment>
                        }
                    </div>
                    <span className="sr-only">{localizer.msg('common.answer')}</span>
                    {renderFieldValue(field)}
                    <div className="d-flex align-items-center">
                        {
                            field.lastEditedBy && field.lastEditedAt &&
                            <span>{localizer.msg('stage.refine.edited-by')}{' '}
                                {
                                    field.lastEditedBy.id
                                        ?
                                        <MemberLink id={field.lastEditedBy.id}
                                                    identityHidden={field.lastEditedBy.identityHidden}>
                                            <strong>{actor.id === field.lastEditedBy.id ? localizer.msg('common.you') : field.lastEditedBy.name}</strong>
                                        </MemberLink>
                                        : <span>{field.lastEditedBy.name}</span>
                                }
                                {' '}<TimeAgo localizer={localizer} dateISOString={field.lastEditedAt}/>
                        </span>
                        }
                        {canEdit &&
                            <button className="btn btn-link p-0 ms-1 shadow-none text-decoration-none"
                                    onClick={() => {
                                        setMode('EDIT');
                                        toggleFocusOnRender(true);
                                    }}>
                                {localizer.msg('stage.refine.edit')}
                            </button>
                        }
                        {hasRevisionHistory &&
                            <button className="btn btn-link-secondary p-0 ms-1 shadow-none text-decoration-none"
                                    onClick={toggleHistory}>
                                {showHistory ? localizer.msg('stage.refine.revision-history.hide') : localizer.msg('stage.refine.revision-history.show')}
                            </button>
                        }
                    </div>
                    {showHistory &&
                        <RefineRevisionHistory
                            localizer={localizer}
                            actorId={actor.id}
                            fieldId={RefineStageField.getFieldId(field.key)}
                            fetchRevisionHistory={fetchRevisionHistory}/>
                    }
                </Fragment>
                : <form id={formId}
                        onSubmit={onFormSubmit(onSubmitField, fileUploading || methods.formState.isSubmitting)}
                        aria-label={field.label}>
                    <HookFormProvider {...methods}>
                        {renderField()}
                        {
                            multipleLanguageSupported && supportedLanguages.length > 0 && RefineStageField.isLocalizable(field) &&
                            <RefineLanguageField localizer={localizer} fieldKey={field.key} setLangId={setLangId}
                                                 defaultLanguage={preferredLanguage}
                                                 supportedLanguages={supportedLanguages}/>
                        }
                    </HookFormProvider>
                    {
                        field.lastEditedBy && field.lastEditedAt &&
                        <span>
                        {localizer.msg('stage.refine.edited-by')}
                            {' '}
                            <MemberLink id={field.lastEditedBy.id} identityHidden={field.lastEditedBy.identityHidden}>
                                <strong>{actor.id === field.lastEditedBy.id ? localizer.msg('common.you') : field.lastEditedBy.name}</strong>
                        </MemberLink>
                            {' '}
                            <TimeAgo localizer={localizer} dateISOString={field.lastEditedAt}/>
                    </span>
                    }
                    <div className="text-end">
                        {defaultMode !== 'EDIT' &&
                            <button className="btn btn-cancel" type="button" onClick={onCancel}>
                                {localizer.msg('common.cancel')}
                            </button>
                        }
                        <ActionButton className="ms-2" form={formId} type="submit"
                                      loading={fileUploading || methods.formState.isSubmitting}>
                            {localizer.msg('common.save-answer')}
                        </ActionButton>
                    </div>
                </form>
            }
        </li>
    );
};

const MultiChoiceDisplay = (props: { field: RefineStageField }) => {
    const {field} = props;
    const selectedValues = new Set(Array.isArray(field.value) ? field.value : typeof field.value === 'number' ? [field.value] : []);
    return <div className="ps-4">
        {field.optionGroups && field.optionGroups.map(group => {
            if (group.options.some(opt => selectedValues.has(opt.id))) {
                return (
                    <Fragment>
                        {group.name}
                        <ul className="list-unstyled mb-3">
                            {group.options.map(option => {
                                if (selectedValues.has(option.id)) {
                                    return <li><Icon iconSpritePath={svgIconsPath}
                                                     name="check"/> {option.translatedName ? option.translatedName : option.name}
                                    </li>;
                                } else {
                                    return null;
                                }
                            })}
                        </ul>
                    </Fragment>
                );
            } else {
                return null;
            }
        })}
    </div>;
};
