import React from 'react';
import {Trans} from 'react-i18next';
import {Localizer, Member, TimeAgo} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {BasicActivityCard} from '../idea/details/BasicActivityCard';

type SubmittedActivityProps = {
    localizer: Localizer;
    ideaAuthor: Member;
    ideaSubmittedAt: string;
}

export const SubmittedActivity = (props: SubmittedActivityProps) => {
    const {localizer, ideaAuthor, ideaSubmittedAt} = props;
    const {authentication: {actor}} = useAppContext();

    return (
        <div className="pb-3">
            <BasicActivityCard headerTitle={localizer.msg('stage.common.idea-submitted-title')}
                               headerSummary={
                                   <>
                                       <Trans i18nKey="stage.common.idea-submission" values={{
                                           member: ideaAuthor.id === actor.id ?
                                               localizer.msg('stage.common.changed-self') : ideaAuthor.name
                                       }}>
                                           <strong>Idea Author</strong>
                                           <TimeAgo localizer={localizer} dateISOString={ideaSubmittedAt}/>
                                       </Trans>
                                   </>
                               }/>
        </div>
    );
};