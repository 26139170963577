import React, {Fragment, useCallback} from 'react';
import {ParagraphSkeleton} from '@ideascale/ui';
import {useCommunityService} from 'hooks/useService';
import {useAiAssistanceService} from 'hooks/useAiAssistanceService';
import {CampaignDetails} from 'components/CampaignDetails';
import {CampaignTos} from 'components/CampaignTos';
import {CampaignBrief} from 'components/CampaignBrief';
import {SidebarToggleButton} from 'components/shared/SidebarToggleButton';
import {CampaignDetailsData} from 'models/CampaignDetailsData';
import {TosData} from 'models/TosData';

type AboutCampaignContainerProps = {
    campaignId: number;
    campaignData: CampaignDetailsData;
    loading: boolean;
}

export const AboutCampaignContainer = (props: AboutCampaignContainerProps) => {
    const {campaignId, campaignData, loading} = props;
    const communityService = useCommunityService();
    const {fetchAiAssistedText, fetchAiAssistedImages} = useAiAssistanceService();

    const fetchCampaignTosContent = useCallback(async (campaignId: number): Promise<TosData> => {
        if (communityService !== null) {
            return await communityService.fetchCampaignTosContent(campaignId);
        }
        return TosData.EMPTY;
    }, [communityService]);

    return (
        <Fragment>
            <div className='mb-3 d-flex d-lg-none justify-content-end'><SidebarToggleButton/></div>
            {
                loading
                    ? <Fragment>
                        <div className="card panel p-3">
                            <ParagraphSkeleton rows={6}/>
                        </div>
                        <div className="spacer mb-3"/>
                        <div className="card panel p-3">
                            <ParagraphSkeleton rows={6}/>
                        </div>
                    </Fragment>
                    : <Fragment>
                        <div className="card panel mb-4">
                            <div className="card-body">
                                <CampaignDetails campaignData={campaignData}
                                                 fetchAiAssistedText={fetchAiAssistedText}
                                                 fetchAiAssistedImage={fetchAiAssistedImages}/>
                            </div>
                        </div>
                        <CampaignBrief campaignData={campaignData}
                                       fetchAiAssistedBrief={fetchAiAssistedText}/>
                        <CampaignTos tosEnabled={campaignData.tosEnabled}
                                     campaignId={campaignId}
                                     fetchCampaignTosContent={fetchCampaignTosContent}/>
                    </Fragment>
            }
        </Fragment>
    );
};