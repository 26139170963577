import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Icon} from '@ideascale/ui';
import {AlertEvent, AlertType, buildAlertEventData, eventDispatcher} from '@ideascale/commons';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';

type ShareableLinkProps = {
    internalShareButtonTitle?: string;
    shareableText: string;
    shareableLink: string
}

export const ShareableLink = (props: ShareableLinkProps) => {
    const {shareableText, internalShareButtonTitle, shareableLink} = props;

    const onCopy = () => {
        eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, 'Link has been copied'));
    };

    return (
        <Fragment>
            <p className="text-primary col-9 alert alert-info mb-0 break-all-words">
                <Link className="text-truncate fw-normal text-decoration-none d-block"
                      to={shareableLink} title={shareableText}>
                    {shareableText}
                </Link>
            </p>
            <CopyToClipboard onCopy={onCopy} text={shareableText}>
                <div className="col-3 d-flex align-items-center">
                    <button className="btn btn-cancel p-0" title={internalShareButtonTitle}>
                        <Icon name="copy"
                              iconSpritePath={svgIconPath} fill="#384EC1" width={18}
                              height={18}/>
                    </button>
                </div>
            </CopyToClipboard>
        </Fragment>
    );
};