import {Command} from './Command';
import {CampaignHomeEditor} from './Editors/CampaignHomeEditor';
import {SidebarItem} from 'models/sidebar/SidebarItem';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';
import {ChangeCampaignSidebarOrderOperation} from 'models/edit-mode/ChangeCampaignSidebarOrderOperation';

export class ReorderCampaignSidebarCommand implements Command {
    private readonly campaignHomeEditor: CampaignHomeEditor;
    private readonly oldSidebarItems: SidebarItem[];
    private readonly newSidebarItems: SidebarItem[];
    private readonly type: CampaignOperationType;
    private readonly campaignId: number;

    constructor(communityHomeEditor: CampaignHomeEditor, newSidebarItems: SidebarItem[], campaignId: number) {
        this.campaignHomeEditor = communityHomeEditor;
        this.oldSidebarItems = this.campaignHomeEditor.sidebarItems;
        this.newSidebarItems = newSidebarItems;
        this.campaignId = campaignId;
        this.type = CampaignOperationType.CHANGE_CAMPAIGN_SIDEBAR_ORDER;
    }

    getType() {
        return this.type;
    }

    execute() {
        this.campaignHomeEditor.sidebarItems = this.newSidebarItems;
    }

    undo() {
        this.campaignHomeEditor.sidebarItems = this.oldSidebarItems;
    }

    getOperation() {
        return new ChangeCampaignSidebarOrderOperation(this.type, this.campaignHomeEditor.sidebarItems, this.campaignId);
    }
}