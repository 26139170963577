import {Stage} from './types/Stage';

export class IdeaModerateActionResponse {
    static readonly EMPTY = new IdeaModerateActionResponse(false, false, false, false, false, false, false, false, false, false, [], [], []);

    constructor(
        public approveIdeaEnabled: boolean,
        public rejectIdeaEnabled: boolean,
        public campaignChangeEnabled: boolean,
        public commentingEnabled: boolean,
        public commentingToggleEnabled: boolean,
        public editIdeaEnabled: boolean,
        public mergeIdeaEnabled: boolean,
        public deleteIdeaEnabled: boolean,
        public stageChangeEnabled: boolean,
        public pinnedIdeaEnabled: boolean,
        public activeStages: Stage[],
        public archivesStages: Stage[],
        public rejectedStages: Stage[]
    ) {
    }
}