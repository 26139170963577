import React from 'react';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Checkbox} from '@ideascale/ui';
import {appLinks} from 'services/AppLinks';
import styles from './TermsOfServiceField.module.scss';
import {IdeaFormFieldKeys, Localizer, useHookFormContext} from '@ideascale/commons';

type TermsOfServiceFieldProps = {
    campaign: { id: number, name: string };
    localizer: Localizer
}

export const TermsOfServiceField = (props: TermsOfServiceFieldProps) => {
    const {campaign, localizer} = props;
    const {register} = useHookFormContext();

    return (
        <div className="form-group">
            <Checkbox
                label={
                    <span className={styles.tos}>
                        <Trans i18nKey="idea.form.terms-of-service" values={{campaignName: campaign.name}}>
                            <Link className="fw-bold" target="ideascale-campaign-tos"
                                  to={appLinks.campaignTos(String(campaign.id))}/>
                        </Trans>
                        <span className="font-size-lg" aria-hidden={true}>*</span>
                        <span className="sr-only">{localizer.msg('common.form.required')}</span>
                    </span>
                }
                inputId={IdeaFormFieldKeys.TERMS_OF_SERVICE}
                defaultChecked={false}
                {...register(IdeaFormFieldKeys.TERMS_OF_SERVICE, {
                    required: true
                })}
            />
        </div>
    );
};