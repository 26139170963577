import {
    ClassificationAttachmentAttribute,
    ClassificationFieldAttribute,
    FileAttachmentDetail,
    LabelData,
    Member,
    RenderFormat
} from '@ideascale/commons';
import {CommentStage} from './CommentStage';
import {TranslationSummary} from './TranslationSummary';
import {CommentActionsPermission} from './CommentActionsPermission';

export class CommentSummary {
    static EMPTY = new CommentSummary(0, 0, 0, '', '',
        CommentStage.ACTIVE, TranslationSummary.EMPTY, Member.EMPTY, 0, 0,
        0, 0, 0, false, false,
        false, false, [], [], CommentActionsPermission.EMPTY, Member.EMPTY,
        Member.EMPTY, [], false, '', Date.now().toString(), RenderFormat.HTML, false, '');

    constructor(
        public id: number,
        public ideaId: number,
        public parentId: number,
        public text: string,
        public originalContent: string,
        public stage: CommentStage,
        public translation: TranslationSummary,
        public author: Member,
        public actorVoteValue: number,
        public netVoteCount: number,
        public upVoteCount: number,
        public downVoteCount: number,
        public kudoCount: number,
        public privateComment: boolean,
        public pinned: boolean,
        public annotated: boolean,
        public kudoGivenByActor: boolean,
        public labels: LabelData[],
        public attachments: FileAttachmentDetail[],
        public actions: CommentActionsPermission,
        public pinnedBy: Member,
        public lastEditedBy: Member,
        public replies: CommentSummary[],
        public firstLevelComment: boolean,
        public createdAt: string,
        public updatedAt: string,
        public renderFormat: RenderFormat,
        public hideAuthor: boolean,
        public reviewTranslationUrl?: string,
        public attributes?: Array<ClassificationFieldAttribute | ClassificationAttachmentAttribute>
    ) {
    }
}