import React, {Dispatch, Fragment, SetStateAction, useRef} from 'react';
import {NavLink} from 'react-router-dom';
import {
    AlertEvent,
    AlertType,
    buildAlertEventData,
    ClassificationLabel,
    ClassificationUtil,
    eventDispatcher,
    HtmlFormatType,
    InfiniteScrollLoadMoreButton,
    StringUtil,
    TimeAgo,
    useApiErrorResponseHandler,
    useIntersectionObserver
} from '@ideascale/commons';
import {Icon, Scrollbar, ScrollbarInstance} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useProfilePageParams} from 'hooks/useProfilePageParams';
import {HtmlRenderer} from 'components/shared/HtmlRenderer';
import {appLinks} from 'services/AppLinks';
import {GenericResponse} from 'models/GenericResponse';
import {ProfileInboxData} from 'models/ProfileInboxData';

const MESSAGE_INBOX_LIST = 'message-inbox-list';

type messagesListProps = {
    loadMessages: () => void;
    messages: ProfileInboxData[];
    setMessages: Dispatch<SetStateAction<ProfileInboxData[]>>;
    hasMore: boolean;
    messageType: string;
    trashConversationThread: (conversationThreadId: number) => Promise<GenericResponse>
    onTrashCallback?: () => void;
}

export const MessagesList = (props: messagesListProps) => {
    const {loadMessages, messages, hasMore, trashConversationThread, setMessages, messageType, onTrashCallback} = props;
    const currentProfileId = useProfilePageParams();
    const localizer = useLocalizer();
    const {communityConfig: {classificationEnabled}} = useAppContext();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const scrollbarContainer = useRef<ScrollbarInstance>(null);
    const loadMoreButtonRef = useRef<HTMLButtonElement>(null);

    const trashThread = (e: React.MouseEvent, messageId: number) => {
        e.preventDefault();
        trashConversationThread(messageId).then(status => {
            if (status?.status === 'success') {
                setMessages(messages.filter(message => message.id !== messageId));
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, status.message));
                if(onTrashCallback) onTrashCallback();
            }
        }).catch((error) => {
            handleErrorResponse(error);
        });
    };

    useIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: loadMessages,
        enabled: hasMore,
        options: {
            root: scrollbarContainer.current?.container
        }
    });

    return (
        <Scrollbar id={MESSAGE_INBOX_LIST}
                   autoHeight
                   autoHeightMax={900}
                   ref={scrollbarContainer}>
            <nav className="mb-0"
                 aria-label={localizer.msg('topbar.messages.message-preview-list')}>
                {
                    messages.map(message => {
                        return (
                            <div key={message.id} className="message-preview m-1 mt-0 position-relative">
                                <NavLink key={message.id}
                                         className={({isActive}) => isActive ? 'active' : ''}
                                         to={
                                             messageType === 'sent' ? appLinks.messagesSentDetails(currentProfileId, message.id)
                                                 : appLinks.messagesInboxDetails(currentProfileId, message.id)
                                         }>
                                    <div className="message-list-content pb-3 p-2">
                                        <div className="d-flex justify-content-between">
                                            <div
                                                className="user-name text-gray">
                                                {message.unread &&
                                                    <Fragment>
                                                        <span
                                                            className="unread dot d-inline-block rounded-circle me-1"
                                                            aria-hidden/>
                                                        <span
                                                            className="sr-only">{localizer.msg('topbar.messages.unread')}</span>
                                                    </Fragment>
                                                }
                                                {
                                                    messageType === 'sent' ?
                                                        (
                                                            <ul className="list-unstyled recipients text-truncate">
                                                                {message.lastMessage.recipients.map(item => {
                                                                    return (
                                                                        <li className="list-inline-item d-inline"
                                                                            title={item.name}
                                                                            key={item.id}>
                                                                            {StringUtil.textTruncate(item.name, 20)}
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        ) : (
                                                            <span title={message.lastMessage.sender.name}>
                                                                {StringUtil.textTruncate(message.lastMessage.sender.name, 25)}
                                                            </span>
                                                        )
                                                }
                                            </div>
                                            <div className="time text-gray text-end ms-1">
                                                <TimeAgo localizer={localizer}
                                                         dateISOString={message.lastMessage.createdAt}/>
                                            </div>
                                        </div>
                                        <div className="message-subject text-truncate" title={message.subject}>
                                            {
                                                classificationEnabled && message.subjectClassification &&
                                                <ClassificationLabel
                                                    extraClasses="me-1"
                                                    classification={ClassificationUtil.getClassificationAttribute(message.subjectClassification)}/>
                                            }
                                            <span className="fw-bold">
                                               {message.subject}
                                            </span>
                                        </div>
                                        <div
                                            className="message-body mt-1 d-flex align-items-end justify-content-between">
                                            <div className="line-clamp">
                                                <HtmlRenderer content={message.lastMessage.body}
                                                              htmlFormatType={HtmlFormatType.SIMPLE}/>
                                            </div>
                                        </div>
                                    </div>
                                </NavLink>
                                <button
                                    className="btn btn-secondary btn-delete ms-2 mt-1 p-1 float-end d-flex align-items-center justify-content-center position-absolute"
                                    title={localizer.msg('common.delete-message')}
                                    onClick={(e) => trashThread(e, message.id)}>
                                    <Icon iconSpritePath={svgIconPath}
                                          name="trash-can-open"
                                          fill="#384EC1"
                                          height={15} width={15}/>
                                </button>
                            </div>
                        );
                    })

                }
            </nav>
            <InfiniteScrollLoadMoreButton hasMore={hasMore}
                                          localizer={localizer}
                                          loading={false}
                                          onCLick={loadMessages}
                                          loadMoreButtonRef={loadMoreButtonRef}/>
        </Scrollbar>
    );
};

