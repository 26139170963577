import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Controller, SubmitHandler, useFieldArray, useForm} from 'react-hook-form';
import {Button, Icon, IdeascaleSelect, OptionType, Skeleton} from '@ideascale/ui';
import {
    AlertEvent,
    AlertType,
    buildAlertEventData,
    eventDispatcher,
    PageTitle,
    useApiErrorResponseHandler,
    useHandleFormSubmit
} from '@ideascale/commons';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useCommunityService} from 'hooks/useService';
import {useUpdateCurrentCampaign} from 'hooks/useUpdateCurrentCampaign';
import {PageLayoutContainer} from 'containers/PageLayoutContainer';
import {PageHeader} from 'components/PageHeader';
import {InviteFriendResponse} from 'models/InviteFriendResponse';
import {InviteFriendRequest} from 'models/InviteFriendRequest';
import {VALID_EMAIL_PREFIX_REGEX, VALID_EMAIL_REGEX} from 'constants/AppConstants';
import 'pages/InviteFriendsPage.scss';

type EmailInputs = {
    name: string,
    emails: {
        prefix: string,
        domain?: OptionType,
    }[]
}

const EMAIL_FIELD_COUNT = 5;

export const InviteFriendsPage = () => {
    const {communityConfig} = useAppContext();
    const communityService = useCommunityService();
    const localizer = useLocalizer();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const {
        register,
        formState: {errors},
        handleSubmit,
        control,
        setError,
        resetField, clearErrors
    } = useForm<EmailInputs>({reValidateMode: 'onSubmit'});
    const onFormSubmit = useHandleFormSubmit(handleSubmit);
    const {fields, append} = useFieldArray({control, name: 'emails'});
    const [whiteListedDomainsConfig, setWhiteListedDomainsConfig] = useState<InviteFriendResponse>(InviteFriendResponse.EMPTY);
    const [loading, setLoading] = useState<boolean>(true);
    const [options, setOptions] = useState<OptionType[]>([]);
    const [submitting, setSubmitting] = useState<boolean>(false);
    useUpdateCurrentCampaign();

    const sendInvitation: SubmitHandler<EmailInputs> = async (formData) => {
        const payload: InviteFriendRequest = {
            emails: []
        };
        formData.emails.forEach((email) => {
            const {prefix, domain} = email;
            if (prefix) {
                if (domain) {
                    payload.emails.push(prefix + domain.value);
                } else {
                    payload.emails.push(prefix);
                }
            } else {
                payload.emails.push('');
            }
        });
        setSubmitting(true);
        try {
            if (payload.emails.length) {
                await communityService?.sendInvitations(payload);
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, localizer.msg('invite-friends.success')));
                fields.forEach((field, index) => {
                    resetField(`emails.${index}.prefix` as const);
                });
            }
        } catch (err: any) {
            if (err.data?.validationErrors) {
                const keys = Object.keys(err.data.validationErrors);
                keys.forEach((key) => {
                    const matched = key.match(/\[.+]/)?.[0];
                    const index = matched?.substring(1, matched.length - 1);
                    const errorMessage = err.data.validationErrors[`emails[${index}]`];
                    setError(`emails.${Number(index)}.prefix`, {message: errorMessage});
                });
            } else {
                handleErrorResponse(err);
            }
        } finally {
            setSubmitting(false);
        }
    };

    const fetchWhiteListedDomains = useCallback(async () => {
        if (communityService !== null) {
            try {
                const data = await communityService.fetchWhiteListedDomainsForInviteFriends();
                setWhiteListedDomainsConfig(data);
                setOptions(data.whiteListedDomains?.map((email) => ({value: email, label: email})));
            } catch (e: any) {
                handleErrorResponse(e);
            } finally {
                setLoading(false);
            }
        }
    }, [communityService, handleErrorResponse]);


    useEffect(() => {
        for (let i = 0; i < EMAIL_FIELD_COUNT; i++) {
            append({prefix: undefined,}, {focusIndex: 0});
        }
        void fetchWhiteListedDomains();
    }, [append, fetchWhiteListedDomains]);

    return (
        <Fragment>
            <PageTitle title={localizer.msg('invite-friends.page-title')}/>
            <PageHeader logo={communityConfig.logo} logoAltText={communityConfig.altText}
                        hideLogo={communityConfig.hideCommunityLogo}
                        logoBgTransparent={!communityConfig.logoFrameEnabled}
                        bannerImage={communityConfig.bannerImageUrl}
                        bannerAltText={communityConfig.bannerImageAltText}
                        bannerGradientEnabled={communityConfig.bannerGradientEnabled}
                        textColor={communityConfig.bannerTitleInBlack ? 'dark' : 'light'}
                        heading={communityConfig.name}
                        headingMeta=""/>
            <PageLayoutContainer
                mainContent={
                    <div className="d-flex justify-content-center mt-4">
                        <div className="invite-friends rounded">
                            {
                                communityConfig.inviteFriendEnabled ?
                                    <Fragment>
                                        <section className="mb-3">
                                            <h4>{localizer.msg('invite-friends.title')}</h4>
                                            <div className="d-flex align-items-center">
                                                <strong>{localizer.msg('invite-friends.from')}:</strong>
                                                {
                                                    loading ?
                                                        <Skeleton cssClass="rounded ms-1" height="21px" width="40%"/>
                                                        :
                                                        <span className="ms-1">  {whiteListedDomainsConfig?.from}</span>
                                                }
                                            </div>
                                            <div>
                                                <strong>{localizer.msg('invite-friends.to')}:</strong>
                                                <span className="ms-1">
                                                    {localizer.msg('invite-friends.description')}
                                                </span>
                                            </div>
                                            {
                                                whiteListedDomainsConfig?.whiteListedDomains?.length > 0 &&
                                                <div className="alert alert-info show mt-2 without-domain-info"
                                                     role="alert">
                                                    {localizer.msg('invite-friends.email-without-domain-notice')}
                                                </div>
                                            }
                                        </section>
                                        {
                                            loading ?
                                                <Fragment>
                                                    <Skeleton cssClass="mb-2 rounded" count={EMAIL_FIELD_COUNT}
                                                              height={'40px'}/>
                                                    <Skeleton cssClass="mb-2 rounded" height="35px" width="118.34px"/>
                                                </Fragment>
                                                :
                                                <form onSubmit={onFormSubmit(sendInvitation, submitting)}>
                                                    {
                                                        fields.map((field, index) => {
                                                            return (
                                                                <Fragment key={field.id}>
                                                                    <div
                                                                        className={`input-group mt-3 ${errors?.emails?.[index]?.prefix ? 'field-has-error' : ''}`}>
                                                                            <span
                                                                                className={`input-group-text input-group-prepend position-relative px-2`}>
                                                                                <Icon name="person-solid" fill="#666"
                                                                                      height={27}
                                                                                      width={18}
                                                                                      iconSpritePath={svgIconsPath}/>
                                                                            </span>
                                                                        <label className="sr-only"
                                                                               htmlFor={`emails.${index}.prefix`}>
                                                                            {localizer.msg('invite-friends.friend-email', {number: index})}
                                                                        </label>
                                                                        <input
                                                                            className={`invite-email-input form-control`}
                                                                            type="text"
                                                                            placeholder={
                                                                                localizer.msg(
                                                                                    whiteListedDomainsConfig?.whiteListedDomains?.length ?
                                                                                        'invite-friends.email-without-domain-placeholder'
                                                                                        :
                                                                                        'invite-friends.email-placeholder'
                                                                                )
                                                                            }
                                                                            {...register(`emails.${index}.prefix` as const, {
                                                                                pattern:
                                                                                    {
                                                                                        value: whiteListedDomainsConfig?.whiteListedDomains?.length ? VALID_EMAIL_PREFIX_REGEX : VALID_EMAIL_REGEX,
                                                                                        message: localizer.msg('invite-friends.email-invalid')
                                                                                    }
                                                                            })}
                                                                            id={`emails.${index}.prefix`}
                                                                            onChange={() => clearErrors(`emails.${index}.prefix`)}
                                                                        />
                                                                        {
                                                                            whiteListedDomainsConfig?.whiteListedDomains?.length > 0 &&
                                                                            <Controller
                                                                                name={`emails.${index}.domain`}
                                                                                control={control}
                                                                                defaultValue={options?.length ? options[0] : undefined}
                                                                                render={({field: {value, onChange}}) =>
                                                                                    <IdeascaleSelect options={options}
                                                                                                     ariaLabel={localizer.msg('invite-friends.select-email-domain')}
                                                                                                     value={value}
                                                                                                     onChange={onChange}
                                                                                    />
                                                                                }
                                                                            />
                                                                        }
                                                                    </div>
                                                                    {
                                                                        errors?.emails?.[index] ?
                                                                            <div
                                                                                className="invalid-feedback d-block mt-2">
                                                                                {errors?.emails?.[index]?.prefix?.message}
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </Fragment>
                                                            );
                                                        })
                                                    }
                                                    <div className="d-flex justify-content-between mt-3">
                                                        <Button disabled={submitting} color="primary">
                                                            {localizer.msg('invite-friends.send')}
                                                        </Button>
                                                    </div>
                                                </form>}
                                    </Fragment>
                                    :
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                        <div className="alert alert-warning mb-0 text-center d-flex align-items-start">
                                            <Icon className="me-1" name="exclamation-solid-triangle" height={18} width={18}
                                                  iconSpritePath={svgIconsPath}/>
                                            {localizer.msg('invite-friends.invitation-disabled-msg')}
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                }
            />
        </Fragment>
    );
};

