import React, {Fragment} from 'react';
import {useLocalizer} from 'hooks/useLocalizer';
import {ReviewScaleStageSummary} from 'models/ReviewScaleStageSummary';
import {StageSummaryHeading} from './StageSummaryHeading';
import {IdeaStageSummaryProps} from 'models/types/IdeaStageSummaryProps';

export const ReviewScaleSummary = (props: IdeaStageSummaryProps<ReviewScaleStageSummary>) => {
    const {ideaStageSummary: {roiScore, valueScoreTotal, costScoreTotal, participationAllowed, viewAllowed}} = props;
    const localizer = useLocalizer();

    const isParticipationOrViewAllowed = () => {
        return participationAllowed || viewAllowed;
    };

    return (
        <Fragment>
            <StageSummaryHeading
                className="mb-3"
                heading={isParticipationOrViewAllowed() ? localizer.msg('stage.reviewscale.impact-ratio') : localizer.msg('stage.reviewscale.heading')}/>
            <div className="stage-data d-flex align-items-center mt-n2 mb-2">
                {
                    isParticipationOrViewAllowed()
                        ?
                        <>
                            <strong>
                                {roiScore.toFixed(2)}
                            </strong>
                            <span>
                                <span
                                    className="impact-value">{localizer.msg('stage.reviewscale.value')}: {valueScoreTotal.toFixed(2)}</span>
                                <span className="mx-2">|</span>
                                <span
                                    className="impact-cost">{localizer.msg('stage.reviewscale.cost')}: {costScoreTotal.toFixed(2)}</span>
                            </span>
                        </>
                        :
                        <em>{localizer.msg('stage.reviewscale.awaits')}</em>
                }
            </div>
        </Fragment>
    );
};