import {Type, TypeHelpOptions} from 'class-transformer';
import {
    Campaign,
    ClassificationAttachmentAttribute,
    ClassificationFieldAttribute, FileAttachment,
    LabelData,
    Member,
    RenderFormat
} from '@ideascale/commons';
import {ClassTransformerUtil} from 'utils/ClassTransformerUtil';
import {IdeateStageSummary} from './IdeateStageSummary';
import {BuildTeamStageSummary} from './BuildTeamStageSummary';
import {BaseIdeaStageSummary} from './BaseIdeaStageSummary';
import {EstimateStageSummary} from './EstimateStageSummary';
import {AssessmentStageSummary} from './AssessmentStageSummary';
import {FundStageSummary} from './FundStageSummary';
import {RefineStageSummary} from './RefineStageSummary';
import {ReserveStageSummary} from './ReserveStageSummary';
import {ReviewScaleStageSummary} from './ReviewScaleStageSummary';
import {PendingStageSummary} from './PendingStageSummary';
import {TranslationSummary} from './types/TranslationSummary';

export class IdeaSummary {
    id: number;
    ideaNumber: number;
    title: string;
    description: string;
    summary: string;
    image: string;
    altText: string;
    @Type(() => Campaign)
    campaign?: Campaign;
    tags: string[];
    submitter: Member;
    coSubmitters: Member[];
    subscribed: boolean;
    moderate: boolean;
    commentCount: number;
    kudoCount: number;
    kudoGiven: boolean;
    labels: LabelData[];
    createdAt: string;
    publicIdea: boolean;
    ownerCount: number;
    followingIdeaAuthor: boolean;
    commentingEnabled: boolean;
    commentingAllowed: boolean;
    assignmentText: string;
    pinned: boolean;
    funnelId: number;
    labellingAllowed: boolean;
    followingEnabled: boolean;
    attachments: FileAttachment[];
    attributes: (ClassificationFieldAttribute | ClassificationAttachmentAttribute)[];
    customAttachments: FileAttachment[];
    commentAttachments: FileAttachment[];
    translation: TranslationSummary;
    draft: boolean;
    memberActionAllowed: boolean;

    @Type((type?: TypeHelpOptions) => {
        return ClassTransformerUtil.getIdeaStageType(type?.object.stageSummary.stage?.name.toLowerCase());
    })
    stageSummary: IdeateStageSummary | BuildTeamStageSummary | RefineStageSummary | EstimateStageSummary | ReviewScaleStageSummary | FundStageSummary | AssessmentStageSummary | ReserveStageSummary | PendingStageSummary | BaseIdeaStageSummary;
    renderFormat: RenderFormat;
    sharable: boolean;

    constructor(
        id: number,
        ideaNumber: number,
        title: string,
        description: string,
        summary: string,
        image: string,
        altText: string,
        campaign: Campaign,
        tags: string[],
        submitter: Member,
        coSubmitters: Member[],
        subscribed: boolean,
        moderate: boolean,
        commentCount: number,
        kudoCount: number,
        kudoGiven: boolean,
        labels: LabelData[],
        createdAt: string,
        publicIdea: boolean,
        ownerCount: number,
        followingIdeaAuthor: boolean,
        commentingEnabled: boolean,
        commentingAllowed: boolean,
        assignmentText: string,
        pinned: boolean,
        funnelId: number,
        labellingAllowed: boolean,
        followingEnabled: boolean,
        attachments: FileAttachment[],
        attributes: (ClassificationFieldAttribute | ClassificationAttachmentAttribute)[],
        customAttachments: FileAttachment[],
        commentAttachments: FileAttachment[],
        stageSummary: IdeateStageSummary | BuildTeamStageSummary | RefineStageSummary | EstimateStageSummary | ReviewScaleStageSummary | FundStageSummary | AssessmentStageSummary | ReserveStageSummary | PendingStageSummary | BaseIdeaStageSummary,
        renderFormat: RenderFormat,
        translation: TranslationSummary,
        draft: boolean,
        memberActionAllowed: boolean,
        sharable: boolean
    ) {
        this.id = id;
        this.ideaNumber = ideaNumber;
        this.title = title;
        this.description = description;
        this.summary = summary;
        this.image = image;
        this.altText = altText;
        this.campaign = campaign;
        this.tags = tags;
        this.submitter = submitter;
        this.coSubmitters = coSubmitters;
        this.subscribed = subscribed;
        this.moderate = moderate;
        this.commentCount = commentCount;
        this.kudoCount = kudoCount;
        this.kudoGiven = kudoGiven;
        this.labels = labels;
        this.createdAt = createdAt;
        this.publicIdea = publicIdea;
        this.ownerCount = ownerCount;
        this.followingIdeaAuthor = followingIdeaAuthor;
        this.commentingEnabled = commentingEnabled;
        this.commentingAllowed = commentingAllowed;
        this.assignmentText = assignmentText;
        this.pinned = pinned;
        this.funnelId = funnelId;
        this.labellingAllowed = labellingAllowed;
        this.followingEnabled = followingEnabled;
        this.attachments = attachments;
        this.attributes = attributes;
        this.customAttachments = customAttachments;
        this.commentAttachments = commentAttachments;
        this.stageSummary = stageSummary;
        this.renderFormat = renderFormat;
        this.translation = translation;
        this.draft = draft;
        this.memberActionAllowed = memberActionAllowed;
        this.sharable = sharable;
    }
}