import React, {useEffect, useMemo} from 'react';
import {FieldValues, useForm, useWatch} from 'react-hook-form';
import {isHtmlInject, useHandleFormSubmit} from '@ideascale/commons';
import {useEditModeContext} from 'contexts/EditModeContext';
import {ConfigurationActionBar} from 'components/shared/ConfigurationActionBar';
import {useLocalizer} from 'hooks/useLocalizer';
import {useEditModeFormErrorHandler} from 'hooks/useEditModeFormErrorHandler';
import {UpdateCommunityNameCommand} from 'commands/edit-mode/UpdateCommunityNameCommand';
import {CommunityOperationType} from 'models/edit-mode/CommunityOperationType';

const COMMUNITY_NAME = 'name';
const COMMUNITY_NAME_MAX_LENGTH = 128;

type CommunityNameEditFormProps = {
    toggleModal: () => void;
}

export const CommunityNameEditForm = (props: CommunityNameEditFormProps) => {
    const {toggleModal} = props;
    const localizer = useLocalizer();
    const {commandExecutor, communityHomeEditor, validationErrors} = useEditModeContext();
    const {register, handleSubmit, setFocus, setError, control, formState: {errors, isDirty}} = useForm({
        defaultValues: {
            [COMMUNITY_NAME]: communityHomeEditor.communityName
        }
    });
    const onFormSubmit = useHandleFormSubmit(handleSubmit);
    const {validationErrorHandler} = useEditModeFormErrorHandler({
        localizer,
        validationErrors: validationErrors.findError(CommunityOperationType.CHANGE_COMMUNITY_NAME)
    });
    const communityNameValue: string = useWatch({
        name: COMMUNITY_NAME,
        control
    });

    const onSubmit = (data: FieldValues) => {
        const command = new UpdateCommunityNameCommand(communityHomeEditor, data[COMMUNITY_NAME].trim());
        commandExecutor.execute(command);
        validationErrors.clearError(command.getType());
        toggleModal();
    };

    const remainingLength = useMemo(() => {
        return COMMUNITY_NAME_MAX_LENGTH - (communityNameValue?.length ?? 0);
    }, [communityNameValue]);

    useEffect(() => {
        setFocus(COMMUNITY_NAME);
    }, [setFocus]);

    useEffect(() => {
        validationErrorHandler(setError);
    }, [setError, validationErrorHandler]);

    return (
        <form onSubmit={onFormSubmit(onSubmit, !isDirty)}>
            <div className={`form-group w-100 ${errors[COMMUNITY_NAME] ? 'has-error' : ''}`}>
                <label className="fw-bold" htmlFor={COMMUNITY_NAME}>
                    {localizer.msg('edit-mode.community.name')}
                </label>
                <input className="form-control" type="text" id={COMMUNITY_NAME}
                       maxLength={COMMUNITY_NAME_MAX_LENGTH}
                       {...register(COMMUNITY_NAME, {
                           validate: (value: any) => {
                               if (!value.trim()) {
                                   return localizer.msg('common.errors.required');
                               } else if (isHtmlInject(value)) {
                                   return localizer.msg('common.errors.html');
                               }
                           }
                       })}
                />
                <div className="text-muted mt-1">
                    {localizer.msg('idea.form.field.feedback.text-length', {count: remainingLength})}
                </div>
                {
                    errors[COMMUNITY_NAME] &&
                    <div className="invalid-feedback d-block">
                        {errors[COMMUNITY_NAME].message}
                    </div>
                }
            </div>
            <ConfigurationActionBar updateBtnType="submit" localizer={localizer} onCancel={toggleModal}
                                    updateDisabled={!isDirty}/>
        </form>
    );
};