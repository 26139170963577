import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {Link,} from 'react-router-dom';
import {CollapseExpand, Icon, ParagraphSkeleton, Scrollbar, ScrollbarInstance} from '@ideascale/ui';
import iconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {InfiniteScrollLoadMoreButton, useApiErrorResponseHandler, useIntersectionObserver, useRoutesMatch, useUrlQuery} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useSidebarContext} from 'contexts/SidebarContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {appLinks} from 'services/AppLinks';
import {CampaignLink} from 'components/sidebar/common/CampaignLink';
import {CampaignGroup} from 'models/CampaignGroup';
import {PageParameters} from 'models/types/PageParameters';
import {CampaignSummary} from 'models/CampaignSummary';
import {SidebarCampaign} from 'models/SidebarCampaign';
import {DataFetchingType} from 'models/types/DataFetchingType';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {CampaignModuleContent} from 'models/CampaignModuleContent';
import {SidebarCampaignHolder} from 'models/SidebarCampaignHolder';
import {ROUTES} from 'shared/Routes';
import './CampaignsModule.scss';

type ConfigType = {
    allIdeas?: boolean;
    manageCampaign?: boolean;
    defaultExpand?: boolean;
}

type ActiveCampaignsModuleProps = {
    config?: ConfigType;
    headerTitle: string;
    dataFetchingType: DataFetchingType;
    fetchSidebarCampaignModule(pageParameters: PageParameters): Promise<CampaignModuleContent>;
    fetchCampaignGroups(pageParameters: PageParameters): Promise<PagedResponseContent<CampaignGroup>>;
    fetchCampaignsByGroup(groupId: number, pageParameters: PageParameters): Promise<PagedResponseContent<SidebarCampaign>>;
    fetchSidebarCampaignSummary(id: number): Promise<CampaignSummary>;
    addCampaigns: (group: CampaignGroup, campaigns: SidebarCampaign[], defaultExpand: boolean, page: number, hasMore: boolean) => void;
    updateCampaignGroupPaginationData: (page: number, hasMore: boolean) => void;
    updateCampaignGroupExpansion: (group?: CampaignGroup, expand?: boolean) => void;
}

const HOME_ROUTES = [ROUTES.HOME, '/'];

export const ActiveCampaignsModule = (props: ActiveCampaignsModuleProps) => {
    const {
        config = {allIdeas: true, manageCampaign: true, defaultExpand: true},
        headerTitle,
        dataFetchingType,
        fetchSidebarCampaignModule,
        fetchCampaignGroups,
        fetchCampaignsByGroup,
        fetchSidebarCampaignSummary,
        addCampaigns,
        updateCampaignGroupPaginationData,
        updateCampaignGroupExpansion
    } = props;

    const {
        activeCampaignsHolder: {campaignHolders, campaignGroupPagination, campaignModuleConfig, setCampaignModuleConfig}
    } = useSidebarContext();
    const {currentCampaign} = useAppContext();
    const localizer = useLocalizer();
    const urlQuery = useUrlQuery();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const activeCampaignRouteMatch = useRoutesMatch(ROUTES.ALL_ACTIVE_IDEAS);
    const homeRouteMatch = useRoutesMatch(HOME_ROUTES);
    const scrollContainer = useRef<ScrollbarInstance>(null);
    const loadMoreButtonRef = useRef<HTMLButtonElement>(null);
    const [isDataFetching, setIsDataFetching] = useState(false);
    const label = urlQuery.get(ROUTES.QUERY_PARAMS.LABEL) || '';

    const fetchCampaignModule = useCallback(async () => {
        try {
            setIsDataFetching(true);
            const campaignModuleResponse: CampaignModuleContent = await fetchSidebarCampaignModule({page: 0});
            setCampaignModuleConfig({
                manageCampaign: campaignModuleResponse.manageCampaign
            });
            updateCampaignGroupPaginationData(campaignModuleResponse.campaignGroups.pageNo + 1, campaignModuleResponse.campaignGroups.hasMore);

            campaignModuleResponse.campaignGroups.content.forEach(group => {
                const campaignHolderCampaigns: PagedResponseContent<SidebarCampaign> = campaignModuleResponse.campaignsHolder.campaigns as unknown as PagedResponseContent<SidebarCampaign>;
                const groupCampaigns: SidebarCampaign[] = campaignModuleResponse.campaignsHolder.group.id === group.id ? campaignHolderCampaigns.content : [];
                const campaignPageNo = campaignModuleResponse.campaignsHolder.group.id === group.id && campaignHolderCampaigns.hasMore ? campaignHolderCampaigns.pageNo + 1 : 0;
                group.groupExpanded = currentCampaign?.campaignGroupId === group.id && currentCampaign.active;
                addCampaigns(group, groupCampaigns, campaignModuleResponse.campaignsHolder.group.id === group.id, campaignPageNo, campaignHolderCampaigns.hasMore);
            });
        } catch (error: any) {
            handleErrorResponse(error);
        } finally {
            setIsDataFetching(false);
        }
    }, [addCampaigns, currentCampaign?.active, currentCampaign?.campaignGroupId, fetchSidebarCampaignModule, handleErrorResponse, setCampaignModuleConfig, updateCampaignGroupPaginationData]);

    const getCampaignHolder = (groupId: number) => {
        return campaignHolders.find(x => x.group.id === groupId);
    };

    const fetchCampaignsByCampaignGroup = async (groupId: number) => {
        try {
            const campaignHolder = getCampaignHolder(groupId);
            const currentPage = campaignHolder?.currentCampaignPage || 0;
            const response = await fetchCampaignsByGroup(groupId, {page: currentPage});
            if (campaignHolder) {
                addCampaigns(campaignHolder.group, response.content, false, response.hasMore ? currentPage + 1 : currentPage, response.hasMore);
            }
        } catch (error: any) {
            handleErrorResponse(error);
        }
    };

    const onCampaignGroupExpand = (group: CampaignGroup) => {
        if (getCampaignHolder(group.id) && getCampaignHolder(group.id)?.campaigns.length === 0) {
            fetchCampaignsByCampaignGroup(group.id).then();
        }
        updateCampaignGroupExpansion(group, false);
    };

    const onCampaignViewMore = (group: CampaignGroup) => {
        fetchCampaignsByCampaignGroup(group.id).then();
    };

    const loadMoreCampaignGroups = async () => {
        try {
            const pagedResponse = await fetchCampaignGroups({page: campaignGroupPagination.currentPage});
            pagedResponse.content.forEach(group => {
                addCampaigns(group, [], false, 0, false);
            });
            updateCampaignGroupPaginationData((pagedResponse.pageNo + 1), pagedResponse.hasMore);
        } catch (error: any) {
            handleErrorResponse(error);
        }
    };

    const onCampaignModuleExpand = () => {
        if (dataFetchingType === 'LAZY' && campaignHolders.length === 0) {
            fetchCampaignModule().then();
        }
    };

    useIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: loadMoreCampaignGroups,
        enabled: campaignGroupPagination.hasMore,
        options: {
            root: scrollContainer.current?.container
        }
    });

    useEffect(() => {
        if (dataFetchingType === 'EAGER' && campaignHolders.length === 0) {
            fetchCampaignModule().then();
        }
    }, [campaignHolders.length, dataFetchingType, fetchCampaignModule]);

    useEffect(() => {
        if (urlQuery.has('label')) {
            updateCampaignGroupExpansion();
        }
    }, [updateCampaignGroupExpansion, urlQuery]);

    return (
        <CollapseExpand
            headerTitle={headerTitle}
            defaultOpen={config.defaultExpand}
            containerClass={`campaign-list active-campaign-list panel-light ${(!campaignModuleConfig.manageCampaign) ? 'padding-bottom-0' : ''}`}
            svgIconSprite={iconPath}
            onEntering={onCampaignModuleExpand}
        >
            <div className="campaign-list-group">
                {
                    config.allIdeas
                        ? (
                            <div className="all-ideas d-flex justify-content-between align-items-center">
                                <Link
                                    className={`fw-normal text-dark text-decoration-none d-block w-100 ${(!currentCampaign && (label !== 'label-off-topic') && (activeCampaignRouteMatch || (homeRouteMatch))) ? 'current-campaign' : ''}`}
                                    to={appLinks.activeIdeas()}>
                                    {localizer.msg('sidebar.campaign.all-ideas')}
                                </Link>
                            </div>
                        )
                        : null
                }
                <Scrollbar
                    className="campaign-group-items-container"
                    autoHeight
                    ref={scrollContainer}
                    autoHeightMin={44}
                    autoHeightMax={350}>
                    {
                        isDataFetching ? (
                            <div className="m-3"><ParagraphSkeleton rows={6}/></div>
                        ) : (
                            campaignHolders.map((campaignHolder: SidebarCampaignHolder, index) => (
                                <CollapseExpand key={`${campaignHolder.group.id}-${index}`}
                                                containerClass="m-0 panel-light"
                                                headerTitle={campaignHolder.group.name}
                                                headerTitleAttr={campaignHolder.group.name}
                                                headerClass="text-truncate"
                                                svgIconSprite={iconPath}
                                                isOpen={campaignHolder.group.groupExpanded}
                                                collapseIcon={<Fragment>
                                                    <Icon name="minus" iconSpritePath={iconPath}
                                                          fill={'#767676'}
                                                          width={12}
                                                          height={12}/>
                                                    <span className="sr-only">collapse</span>
                                                </Fragment>}
                                                expandIcon={<Fragment>
                                                    <Icon name="plus" iconSpritePath={iconPath}
                                                          fill={'#767676'} width={12}
                                                          height={12}/>
                                                    <span className="sr-only">expand</span>
                                                </Fragment>}
                                                onEntering={() => onCampaignGroupExpand(campaignHolder.group)}
                                                onEntered={() => scrollContainer.current?.forceUpdate()}
                                                onExited={() => scrollContainer.current?.forceUpdate()}>
                                    <ul className="pt-0 m-0 list-unstyled">
                                        {
                                            campaignHolder.campaigns.map(campaign => (
                                                <li className={`py-1 ${currentCampaign?.id === campaign.id ? 'current-campaign' : ''}`}
                                                    key={campaign.id}>
                                                    <CampaignLink campaign={campaign}
                                                                  campaignStatus={'Active'}
                                                                  fetchSidebarCampaignSummary={fetchSidebarCampaignSummary}/>
                                                </li>
                                            ))
                                        }
                                        {
                                            campaignHolder.hasMoreCampaigns &&
                                            <li className="view-more-btn-wrapper">
                                                <button className="btn btn-link fw-normal btn-view-more"
                                                        onClick={() => onCampaignViewMore(campaignHolder.group)}>
                                                    {localizer.msg('sidebar.campaign.view-more')}
                                                </button>
                                            </li>
                                        }
                                    </ul>
                                </CollapseExpand>
                            ))
                        )
                    }
                    <InfiniteScrollLoadMoreButton hasMore={campaignGroupPagination.hasMore}
                                                  localizer={localizer}
                                                  loading={isDataFetching}
                                                  onCLick={loadMoreCampaignGroups}
                                                  skeletonComponent={
                                                      <div className="m-3">
                                                          <ParagraphSkeleton rows={10}/>
                                                      </div>
                                                  }
                                                  loadMoreButtonRef={loadMoreButtonRef}/>
                </Scrollbar>
                {
                    campaignModuleConfig.manageCampaign &&
                    <div className="go-to-admin ps-3 mt-3">
                        <a href={appLinks.manageCampaigns}>{localizer.msg('sidebar.campaign.manage-campaign')}</a>
                    </div>
                }
            </div>
        </CollapseExpand>
    );
};

