import React, {useEffect, useRef} from 'react';
import {Controller, FieldValues, useForm} from 'react-hook-form';
import {HtmlConverter, NewRichTextEditor, RichTextEditorHandler} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useHandleFormSubmit} from '@ideascale/commons';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useEditModeFormErrorHandler} from 'hooks/useEditModeFormErrorHandler';
import {UpdateCommunitySubtitleCommand} from 'commands/edit-mode/UpdateCommunitySubtitleCommand';
import {ConfigurationActionBar} from 'components/shared/ConfigurationActionBar';
import {CommunityOperationType} from 'models/edit-mode/CommunityOperationType';

const COMMUNITY_SUBTITLE = 'subtitle';
const MAX_CHARACTER_LIMIT = 240;

type CommunitySubtitleEditFormProps = {
    toggleModal: () => void;
}

export const CommunitySubtitleEditForm = (props: CommunitySubtitleEditFormProps) => {
    const {toggleModal} = props;
    const localizer = useLocalizer();
    const {commandExecutor, communityHomeEditor, validationErrors} = useEditModeContext();
    const {handleSubmit, control, formState: {errors, isDirty}, setError} = useForm({
        defaultValues: {
            [COMMUNITY_SUBTITLE]: communityHomeEditor.communitySubtitle
        }
    });
    const onFormSubmit = useHandleFormSubmit(handleSubmit);
    const {validationErrorHandler} = useEditModeFormErrorHandler({
        localizer,
        validationErrors: validationErrors.findError(CommunityOperationType.CHANGE_COMMUNITY_SUBTITLE)
    });
    const richTextEditorNode = useRef<RichTextEditorHandler>(null);

    const onSubmit = (data: FieldValues) => {
        const command = new UpdateCommunitySubtitleCommand(communityHomeEditor, HtmlConverter.toServerHtmlFormat(data[COMMUNITY_SUBTITLE].trim()));
        commandExecutor.execute(command);
        validationErrors.clearError(command.getType());
        toggleModal();
    };

    useEffect(() => {
        richTextEditorNode.current?.setCursorAtEnd();
    }, []);

    useEffect(() => {
        validationErrorHandler(setError);
    }, [setError, validationErrorHandler]);

    return (
        <form onSubmit={onFormSubmit(onSubmit, !isDirty)}>
            <div className={`form-group ${errors[COMMUNITY_SUBTITLE] ? 'has-error' : ''}`}>
                <label className="fw-bold" htmlFor="community-subtitle-input">
                    {localizer.msg('edit-mode.community.subtitle')}
                </label>
                <Controller
                    control={control}
                    name={COMMUNITY_SUBTITLE}
                    defaultValue={communityHomeEditor.communitySubtitle}
                    rules={{
                        validate: () => {
                            if (richTextEditorNode.current?.isCharacterLimitExceeded()) {
                                return localizer.msg('frontend-shared.fields.errors.character-limit',
                                    {characterCount: MAX_CHARACTER_LIMIT});
                            }
                        }
                    }}
                    render={({field}) =>
                        <NewRichTextEditor
                            id={'community-subtitle-input'}
                            className={'form-control h-auto'}
                            toolbar={'minimal'}
                            svgIconPath={svgIconsPath}
                            ref={richTextEditorNode}
                            onChange={field.onChange}
                            enableEmojiPicker={false}
                            enableAtMention={false}
                            maxCharacterLimit={MAX_CHARACTER_LIMIT}
                            characterLeftLabel={localizer.msg('frontend-shared.fields.text-area.text-length-count')}
                            defaultValue={communityHomeEditor.communitySubtitle}
                        />
                    }/>
                {
                    errors[COMMUNITY_SUBTITLE] &&
                    <div className="invalid-feedback d-block">
                        {errors[COMMUNITY_SUBTITLE].message}
                    </div>
                }
            </div>
            <ConfigurationActionBar updateBtnType="submit" localizer={localizer} onCancel={toggleModal}
                                    updateDisabled={!isDirty}/>
        </form>
    );
};