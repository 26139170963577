import {useMutation, useQueryClient} from 'react-query';
import cloneDeep from 'lodash/cloneDeep';
import {Link} from 'react-router-dom';
import {Trans} from 'react-i18next';
import {AlertEvent, AlertType, buildAlertEventData, eventDispatcher} from '@ideascale/commons';
import {useIdeaService} from './useIdeaService';
import {useIdeaUpdater} from './useIdeaUpdater';
import {PagedIdeas} from 'contexts/IdeasContext';
import {appLinks} from 'services/AppLinks';
import {QUERY_KEYS} from 'constants/AppConstants';
import {IdeaSummary} from 'models/IdeaSummary';
import {IdeateStageSummary} from 'models/IdeateStageSummary';
import {IdeaDetail} from 'models/IdeaDetail';
import {StageFunctionName} from 'models/enums/StageFunctionName';
import {StageSummaryData} from 'models/types/StageSummaryData';

type ChangeStageParams = {
    ideaId: number,
    stageId: number
}

export const useIdeaStageChange = (ideaListQueryKey: any = '') => {
    const queryClient = useQueryClient();
    const {changeStage} = useIdeaService();
    const {ideaDetailsQueryKey, replaceFullIdeaDetails} = useIdeaUpdater();

    const getIdeasRouteLink = (campaignId?: number, stage?: StageSummaryData) => {
        if (stage?.name.toLowerCase() === StageFunctionName.RECYCLE_BIN) {
            return <a href={appLinks.ideaPortfolioRecycleBinIdeas()}>recycleBin</a>;
        } else if (stage?.name.toLowerCase() === StageFunctionName.OFFTOPIC) {
            return <a href={appLinks.ideaPortfolioOffTopicIdeas()}>offTopic</a>;
        } else if (stage?.name.toLowerCase() === StageFunctionName.PENDING) {
            return <a href={appLinks.ideaPortfolioPendingApproval()}>pending</a>;
        }
        return <Link to={appLinks.ideas(String(campaignId), stage?.key)}
                     onClick={() => eventDispatcher.dispatch(AlertEvent.REMOVE_ALERT)}/>;
    };

    const ideaListStageChangeMutation = useMutation(({
                                                         ideaId,
                                                         stageId
                                                     }: ChangeStageParams) => changeStage(ideaId, stageId),
        {
            onSuccess: async (idea?: IdeaDetail) => {
                await queryClient.invalidateQueries(QUERY_KEYS.IDEA_LABELS);
                await queryClient.cancelQueries(ideaListQueryKey);
                let oldStage = undefined;
                queryClient.setQueryData(ideaListQueryKey, (previousIdeaList) => {
                    const newIdeaList = cloneDeep(previousIdeaList) as { pages: PagedIdeas[], pageParams: any };
                    const [queryKey, queryValue] = ideaListQueryKey;
                    const stageKey = queryValue?.['stageKey'];
                    const campaignId = queryValue?.['campaignId'];

                    const updatedPagedIdeas = newIdeaList.pages.map((page: PagedIdeas) => {
                        const findIndex = page.data.findIndex((ideaSummary: IdeaSummary) => ideaSummary.id === idea?.id);
                        if (findIndex > -1 && idea) {
                            oldStage = page.data[findIndex].stageSummary;

                            page.data[findIndex].stageSummary = idea.stageSummary;
                            page.data[findIndex].labels = idea.labels;

                            queryClient.invalidateQueries([queryKey, {
                                stageKey: idea.stageSummary?.stage?.key,
                                campaignId: idea.campaign?.id?.toString()
                            }]);

                            queryClient.invalidateQueries([queryKey, {
                                stageKey: oldStage.stage.key,
                                campaignId: idea.campaign?.id?.toString()
                            }]);

                            if (stageKey !== 'all' && +campaignId === idea.campaign?.id) {
                                page.data.splice(findIndex, 1);
                                queryClient.invalidateQueries([queryKey, {
                                    stageKey: 'all',
                                    campaignId: idea.campaign?.id?.toString()
                                }]);
                            }
                        }
                        return page;
                    });
                    return {...newIdeaList, pages: updatedPagedIdeas};
                });

                if (oldStage) {
                    eventDispatcher.dispatch(AlertEvent.ALERT,
                        buildAlertEventData(
                            AlertType.success,
                            <Trans i18nKey={'idea.state.changed'}
                                   values={{
                                       from: (oldStage as IdeateStageSummary).stage.label,
                                       to: idea?.stageSummary.stage.label,
                                   }}>
                                {getIdeasRouteLink(idea?.campaign?.id, idea?.stageSummary.stage)}
                            </Trans>,
                            false
                        )
                    );
                }
            },
            onSettled: async () => {
                await queryClient.invalidateQueries(QUERY_KEYS.CAMPAIGN_FUNNEL);
            }
        }
    );

    const ideaDetailsStageChangeMutation = useMutation(({
                                                            ideaId,
                                                            stageId
                                                        }: ChangeStageParams) => changeStage(ideaId, stageId),
        {
            onSuccess: async (ideaDetail: IdeaDetail | undefined, {ideaId}: ChangeStageParams) => {
                await queryClient.invalidateQueries(QUERY_KEYS.IDEA_LABELS);
                await queryClient.cancelQueries(ideaDetailsQueryKey(ideaId));
                let oldIdeaDetails = queryClient.getQueryData(ideaDetailsQueryKey(ideaId)) as IdeaDetail;
                await replaceFullIdeaDetails(ideaDetail as IdeaDetail);

                if (oldIdeaDetails) {
                    eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, <Trans
                        i18nKey={'idea.state.changed'}
                        values={{
                            from: (oldIdeaDetails.stageSummary as IdeateStageSummary).stage.label,
                            to: ideaDetail?.stageSummary.stage.label,
                        }}>
                        {getIdeasRouteLink(ideaDetail?.campaign?.id, ideaDetail?.stageSummary.stage)}
                    </Trans>, false));
                }
            },
            onSettled: async () => {
                await queryClient.invalidateQueries(QUERY_KEYS.CAMPAIGN_FUNNEL);
            }
        }
    );

    return {ideaListStageChangeMutation, ideaDetailsStageChangeMutation};
};
