import React, {Dispatch, Fragment, SetStateAction, useCallback, useEffect, useMemo, useState} from 'react';
import {isEmpty} from 'lodash';
import {ErrorReason, Localizer} from '@ideascale/commons';
import {useTabPermission} from 'hooks/useTabPermission';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useEditModeFormErrorHandler} from 'hooks/useEditModeFormErrorHandler';
import {FileLibrary} from './FileLibrary';
import {FileUpload} from './FileUpload';
import {TabItem} from '../../share/TabItem';
import {TabPanel} from '../../share/TabPanel';
import {BannerImagePreview} from './BannerImagePreview';
import {RequiredMsgInfo} from './RequiredMsgInfo';
import {LicenseUpgradeInfo} from 'components/edit-mode/LicenseUpgradeInfo';
import {ErrorMessage} from './ErrorMessage';
import {IMAGE_TYPE_WITHOUT_GIF_REGEX} from 'constants/AppConstants';
import {CommunityEditableFieldType} from 'models/edit-mode/CommunityEditableFieldType';
import {ImageType} from 'models/enums/ImageType';
import {BannerField, BannerFieldBuilder} from 'models/edit-mode/BannerField';
import {ImageFile} from 'models/edit-mode/ImageFile';
import {CommunityOperationType} from 'models/edit-mode/CommunityOperationType';
import {ImageUploadSource} from 'models/enums/ImageUploadSource';

const BANNER_IMAGE_RATIO = {w: 1140, h: 150};

export type BannerImageProps = {
    localizer: Localizer;
    maxFileSizeLimit: number;
    libraryImages: ImageFile[];
    galleryImages: ImageFile[];
    bannerImage: BannerField;
    setBannerImage: Dispatch<SetStateAction<BannerField>>;
    errorReason: string;
    updateFileUploadingStatus?: Dispatch<SetStateAction<Map<string, boolean>>>;
}

export const BannerImage = (props: BannerImageProps) => {
    const {
        localizer,
        maxFileSizeLimit,
        libraryImages,
        galleryImages,
        bannerImage,
        setBannerImage,
        errorReason,
        updateFileUploadingStatus
    } = props;
    const [selectedGalleryImage, setSelectedGalleryImage] = useState<ImageFile | null>(null);
    const [selectedLibraryImage, setSelectedLibraryImage] = useState<ImageFile | null>(null);
    const [checkedBannerGradient, setCheckedBannerGradient] = useState<boolean>(false);
    const [checkedBlackPageTitle, setCheckedBlackPageTitle] = useState<boolean>(false);
    const [uploadedImageType, setUploadedImageType] = useState<ImageUploadSource | undefined>(undefined);
    const [defaultActiveTab, setDefaultActiveTab] = useState<number>(1);
    const {tabPermission} = useTabPermission(bannerImage);
    const {validationErrors} = useEditModeContext();
    const [fileUploading, setFileUploadingStatus] = useState(false);
    const [fileTypeErrorMessage, setFileTypeErrorMessage] = useState<string | null>(null);
    const {fieldError} = useEditModeFormErrorHandler({
        localizer,
        validationErrors: validationErrors.findError(CommunityOperationType.CHANGE_COMMUNITY_BANNER)
    });

    useEffect(() => {
        if (bannerImage?.imageType === ImageType.STANDARD) {
            setSelectedGalleryImage(bannerImage);
            setDefaultActiveTab(1);
        } else if (bannerImage?.imageType === ImageType.CUSTOM) {
            setDefaultActiveTab(2);
            setSelectedLibraryImage(bannerImage);
        }
        setCheckedBannerGradient(bannerImage?.gradientEnabled);
        setCheckedBlackPageTitle(bannerImage?.blackPageTitleEnabled);
    }, [bannerImage]);

    const validateFileType = useCallback((url: string) => {
        setFileTypeErrorMessage(null);
        if (/\.(gif)$/.test(url)) {
            setFileTypeErrorMessage(localizer.msg(`frontend-shared.errors.${ErrorReason.ANIMATED_GIF_NOT_SUPPORTED.toLowerCase()}`));
            return false;
        }
        return true;
    }, [localizer]);

    const onGalleryImageSelected = useCallback((image: ImageFile) => {
        setSelectedLibraryImage(null);
        setSelectedGalleryImage(image);
        validateFileType(image.url);

        const newImage = new BannerFieldBuilder()
            .name(CommunityEditableFieldType.BANNER_IMAGE)
            .fileId(image.fileId)
            .url(image.url)
            .imageType(ImageType.STANDARD)
            .gradientEnabled(checkedBannerGradient)
            .blackPageTitleEnabled(checkedBlackPageTitle)
            .build();
        setBannerImage(prev => ({
            ...newImage,
            standardFilePermission: prev.standardFilePermission,
            fileLibraryPermission: prev.fileLibraryPermission,
            fileUploadPermission: prev.fileUploadPermission
        }));
        setUploadedImageType(undefined);
    }, [checkedBannerGradient, checkedBlackPageTitle, setBannerImage, validateFileType]);

    const onLibraryImageSelected = useCallback((image: ImageFile) => {
        setSelectedGalleryImage(null);
        setSelectedLibraryImage(image);
        validateFileType(image.url);

        const newImage = new BannerFieldBuilder()
            .name(CommunityEditableFieldType.BANNER_IMAGE)
            .fileId(image.fileId)
            .url(image.url)
            .imageType(ImageType.CUSTOM)
            .gradientEnabled(checkedBannerGradient)
            .blackPageTitleEnabled(checkedBlackPageTitle)
            .build();
        setBannerImage(prev => ({
            ...newImage,
            standardFilePermission: prev.standardFilePermission,
            fileLibraryPermission: prev.fileLibraryPermission,
            fileUploadPermission: prev.fileUploadPermission
        }));
        setUploadedImageType(undefined);
    }, [checkedBannerGradient, checkedBlackPageTitle, setBannerImage, validateFileType]);

    const bannerImageTabItems = useMemo(() => {
        return [
            {
                value: 1,
                icon: 'image-layered',
                text: localizer.msg('edit-mode.image-setting.tabs.banner-gallery'),
                disabled: tabPermission.standardFileDisabled,
                content: (
                    <Fragment>
                        {
                            !tabPermission.standardFileDisabled
                                ? (
                                    <FileLibrary
                                        localizer={localizer}
                                        images={galleryImages}
                                        previewMode="full"
                                        selectedImage={selectedGalleryImage}
                                        onSelectedImage={onGalleryImageSelected}
                                        disabled={tabPermission.standardFileDisabled}
                                        ariaLabel={localizer.msg('edit-mode.image-setting.tabs.banner-gallery')}
                                    />
                                )
                                : null
                        }
                    </Fragment>
                ),
                additionalTabInfo: (
                    <LicenseUpgradeInfo
                        className="edit-mode-element"
                        triggerButtonId="banner-license-popover-trigger-1"
                        showIconOnly={true}
                        localizer={localizer}
                    />
                )
            },
            {
                value: 2,
                icon: 'plus-solid-box-rounded-layered',
                text: localizer.msg('edit-mode.image-setting.tabs.file-library'),
                disabled: tabPermission.libraryDisabled,
                content: (
                    <Fragment>
                        {
                            !tabPermission.libraryDisabled
                                ? (
                                    <FileLibrary
                                        localizer={localizer}
                                        images={libraryImages}
                                        selectedImage={selectedLibraryImage}
                                        onSelectedImage={onLibraryImageSelected}
                                        disabled={tabPermission.libraryDisabled}
                                        ariaLabel={localizer.msg('edit-mode.image-setting.tabs.file-library')}
                                    />
                                )
                                : null
                        }
                    </Fragment>
                ),
                additionalTabInfo: (
                    <LicenseUpgradeInfo
                        className="edit-mode-element"
                        triggerButtonId="banner-license-popover-trigger-2"
                        showIconOnly={true}
                        localizer={localizer}
                    />
                )
            },
            {
                value: 3,
                icon: 'arrow-up-from-line',
                text: localizer.msg('edit-mode.image-setting.tabs.upload'),
                disabled: tabPermission.uploadDisabled,
                content: (
                    <Fragment>
                        {
                            !tabPermission.libraryDisabled
                                ? (
                                    <FileUpload
                                        previewMode="full"
                                        localizer={localizer}
                                        maxFileSizeLimit={maxFileSizeLimit}
                                        fileValidation={IMAGE_TYPE_WITHOUT_GIF_REGEX}
                                        cropWidth={BANNER_IMAGE_RATIO.w}
                                        cropHeight={BANNER_IMAGE_RATIO.h}
                                        uploadedType={uploadedImageType}
                                        errorReason={errorReason}
                                        onUploadedSuccessfulImage={(image, type) => {
                                            setFileTypeErrorMessage(null);
                                            const uploadedImage = new BannerFieldBuilder()
                                                .name(CommunityEditableFieldType.BANNER_IMAGE)
                                                .fileId(image.fileId)
                                                .filename(image.filename)
                                                .url(image.url)
                                                .altText(image.altText ?? '')
                                                .imageType(ImageType.UPLOADED)
                                                .gradientEnabled(checkedBannerGradient)
                                                .blackPageTitleEnabled(checkedBlackPageTitle)
                                                .build();

                                            setBannerImage(prev => ({
                                                ...uploadedImage,
                                                standardFilePermission: prev.standardFilePermission,
                                                fileLibraryPermission: prev.fileLibraryPermission,
                                                fileUploadPermission: prev.fileUploadPermission
                                            }));
                                            setUploadedImageType(type);
                                            setSelectedGalleryImage(null);
                                            setSelectedLibraryImage(null);
                                        }}
                                        updateFileUploadingStatus={setFileUploadingStatus}
                                    />
                                )
                                : null
                        }
                    </Fragment>
                ),
                additionalTabInfo: (
                    <LicenseUpgradeInfo
                        className="edit-mode-element"
                        triggerButtonId="banner-license-popover-trigger-3"
                        showIconOnly={true}
                        localizer={localizer}
                    />
                )
            }
        ] as TabItem[];
    }, [checkedBannerGradient, checkedBlackPageTitle, errorReason, galleryImages, libraryImages, localizer, maxFileSizeLimit, onGalleryImageSelected, onLibraryImageSelected, selectedGalleryImage, selectedLibraryImage, setBannerImage, tabPermission.libraryDisabled, tabPermission.standardFileDisabled, tabPermission.uploadDisabled, uploadedImageType]);

    const hasAllTabsDisabled = useMemo(() => {
        return tabPermission.standardFileDisabled && tabPermission.libraryDisabled && tabPermission.uploadDisabled;
    }, [tabPermission.libraryDisabled, tabPermission.standardFileDisabled, tabPermission.uploadDisabled]);

    useEffect(() => {
        updateFileUploadingStatus?.(prev => new Map(prev.set(CommunityOperationType.CHANGE_COMMUNITY_BANNER, fileUploading)));
    }, [fileUploading, updateFileUploadingStatus]);

    return (
        <Fragment>
            <div className="d-flex align-items-center">
                <h5 className={`my-3 fw-semibold ${validationErrors.findError(CommunityOperationType.CHANGE_COMMUNITY_BANNER) || !!fileTypeErrorMessage ? 'has-error' : ''}`}>{localizer.msg('edit-mode.advanced-settings.banner-image.banner-image-title')}</h5>
                {
                    hasAllTabsDisabled && (
                        <LicenseUpgradeInfo
                            triggerButtonId="banner-title-license-popover"
                            className="ms-2"
                            localizer={localizer}
                        />
                    )
                }
            </div>
            <ErrorMessage message={fieldError('fileId')?.message || fileTypeErrorMessage!}/>
            {
                (fieldError('fileId')?.reason !== ErrorReason.FEATURE_IS_NOT_ACCESSIBLE && !hasAllTabsDisabled)
                    ? (
                        <Fragment>
                            <TabPanel tabItems={bannerImageTabItems} defaultActiveTab={defaultActiveTab}/>
                            {
                                bannerImage?.url?.length > 0 && (
                                    <BannerImagePreview
                                        localizer={localizer}
                                        bannerImage={bannerImage}
                                        setBannerImage={setBannerImage}
                                        checkedBannerGradient={checkedBannerGradient}
                                        setCheckedBannerGradient={setCheckedBannerGradient}
                                        checkedBlackPageTitle={checkedBlackPageTitle}
                                        setCheckedBlackPageTitle={setCheckedBlackPageTitle}
                                    />
                                )
                            }
                            {
                                ((!bannerImage || bannerImage.fileId === 0) && isEmpty(bannerImage?.url)) &&
                                <RequiredMsgInfo localizer={localizer}/>
                            }
                        </Fragment>
                    )
                    : <div className="mt-4"></div>
            }
        </Fragment>
    );
};