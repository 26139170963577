export class CommonUtil {
    static flashElement(targetId: string) {
        const targetEl = document.getElementById(targetId);
        if (targetEl) {
            targetEl.classList.add('flash');
            setTimeout(() => targetEl.classList.remove('flash'), 1200);
        }
    }

    static wait(time: number = 0): Promise<boolean> {
        return new Promise((resolve) => setTimeout(() => resolve(true), time));
    }

    static systemThemePreferenceIsDark() {
        return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    }

    static setElementId(selector: string, id: string) {
        const targetElement = document.querySelector(selector);
        if (targetElement) {
            targetElement.setAttribute('id', id);
        }
    }

    static setStyleProperty(property: string, value: string) {
        document.documentElement.style.setProperty(property, value);
    }

    static formatNumberWithLeadingZero(number: number) {
        return number.toString().padStart(2, '0');
    }
}