import {useCallback, useEffect, useRef} from 'react';
import {useInfiniteQuery} from 'react-query';
import {useApiErrorResponseHandler} from '@ideascale/commons';
import {PagedIdeas} from 'contexts/IdeasContext';
import {useSearchService} from './useSearchService';
import {useLocalizer} from './useLocalizer';
import {DEFAULT_STALE_TIME} from 'constants/AppConstants';

export const useSearchIdeasInfiniteScroll = (ideaSearchListQueryKey: any) => {
    const {fetchSearchIdeas} = useSearchService();
    const localizer = useLocalizer();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const initialLoadRef = useRef(true);

    const fetchIdeas = useCallback(async ({pageParam = 0, queryKey}: any) => {
        try {
            const searchParams = queryKey[1];
            const pagedResponse = await fetchSearchIdeas({...searchParams, pageParameters: {page: pageParam, limit: 15}});
            return {
                data: pagedResponse.content,
                totalIdeas: pagedResponse.totalElements,
                allPages: Math.ceil(pagedResponse.totalElements / pagedResponse.pageSize),
                nextPage: pagedResponse.hasMore ? pagedResponse.pageNo + 1 : undefined,
                prevPage: pagedResponse.pageNo > 0 ? pagedResponse.pageNo - 1 : undefined
            } as PagedIdeas;
        } catch(e: any) {
            handleErrorResponse(e);
        }
    }, [fetchSearchIdeas, handleErrorResponse]);

    useEffect(() => {
        initialLoadRef.current = false;
    }, []);

    return useInfiniteQuery<PagedIdeas | undefined>(ideaSearchListQueryKey, fetchIdeas, {
        staleTime: DEFAULT_STALE_TIME,
        getNextPageParam: (lastPage) => lastPage?.nextPage,
        getPreviousPageParam: (firstPage) => firstPage?.prevPage,
        enabled: !initialLoadRef.current
    });
};