import React, {Dispatch, Fragment, SetStateAction, useCallback, useEffect, useState} from 'react';
import {Progress} from 'reactstrap';
import {CropperModal, FileInput, Icon} from '@ideascale/ui';
import {
    AlertEvent,
    AlertType,
    buildAlertEventData,
    eventDispatcher,
    Localizer,
    useFileUploadHandlers,
    useFileUploadIntermediary,
    useToggle
} from '@ideascale/commons';
import loadingSvg from '@ideascale/ui/dist/assets/loading.svg';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useFileUploadService} from 'hooks/useFileUploadService';
import {FileUploadPreview} from './FileUploadPreview';
import {IMAGE_TYPE_REGEX} from 'constants/AppConstants';
import {UploadedImage} from 'models/types/UploadedImage';
import {ImageUploadSource} from 'models/enums/ImageUploadSource';
import './FileUpload.scss';

const MAX_IMAGE_WIDTH = 100;
const MAX_IMAGE_HEIGHT = 100;

type FileUploadProps = {
    localizer: Localizer;
    previewMode?: 'full' | 'medium' | 'small';
    imageUrl?: string;
    maxFileSizeLimit: number;
    showAltText?: boolean;
    onUploadedSuccessfulImage?: (image: UploadedImage, type: ImageUploadSource) => void;
    uploadedType?: ImageUploadSource;
    cropWidth?: number;
    cropHeight?: number;
    fileValidation?: RegExp;
    errorReason?: string;
    updateFileUploadingStatus?: Dispatch<SetStateAction<boolean>>
}

export const FileUpload = (props: FileUploadProps) => {
    const {
        localizer,
        maxFileSizeLimit,
        previewMode = 'small',
        cropWidth = MAX_IMAGE_WIDTH,
        cropHeight = MAX_IMAGE_HEIGHT,
        onUploadedSuccessfulImage,
        showAltText = true,
        uploadedType,
        imageUrl,
        fileValidation = IMAGE_TYPE_REGEX,
        errorReason,
        updateFileUploadingStatus
    } = props;
    const [showCropper, toggleShowCropper] = useToggle(false);
    const [previewImage, setPreviewImage] = useState<string | null>(null);
    const [altText, setAltText] = useState<string>('');
    const [altTextError, setAltTextError] = useState<string | null>(null);
    const [uploadedImage, setUploadedImage] = useState<UploadedImage>(UploadedImage.EMPTY);
    const [imageFile, setImageFile] = useState<File>();
    const {uploadImage} = useFileUploadService();
    const {onUploadFile, fileUploading} = useFileUploadIntermediary(uploadImage);

    const {averagePercentageCompleted, onInputFileChange, handleFileUploadError} = useFileUploadHandlers({
        localizer,
        onUploadSuccess: (fileName, previewImageUrl) => {
            setPreviewImage(previewImageUrl);
            const uploadedImageFile = new UploadedImage(0, previewImageUrl, fileName, altText);
            setUploadedImage(uploadedImageFile);
            onUploadedSuccessfulImage?.(uploadedImageFile, ImageUploadSource.FILE);
        },
        uploadFile: onUploadFile,
        maxFileSizeInMb: maxFileSizeLimit,
    });

    const onPreviewRemoved = useCallback(() => {
        setPreviewImage(null);
        onUploadedSuccessfulImage?.(UploadedImage.EMPTY, ImageUploadSource.FILE);
    }, [onUploadedSuccessfulImage]);

    const onAltTextChanged = useCallback((text?: string) => {
        onUploadedSuccessfulImage?.({...uploadedImage, altText: text?.trim()}, ImageUploadSource.FILE);
    }, [onUploadedSuccessfulImage, uploadedImage]);

    useEffect(() => {
        imageUrl && setPreviewImage(imageUrl);
    }, [imageUrl]);

    useEffect(() => {
        if (errorReason) {
            setAltTextError(localizer.msg(`frontend-shared.errors.${errorReason.toLowerCase()}`));
        }
    }, [errorReason, localizer]);

    useEffect(() => {
        updateFileUploadingStatus?.(fileUploading);
    }, [fileUploading, updateFileUploadingStatus]);

    return (
        <Fragment>
            <div className="file-upload-wrapper">
                {
                    previewImage && uploadedType === ImageUploadSource.FILE && (
                        <FileUploadPreview localizer={localizer}
                                           previewMode={previewMode}
                                           showAltText={showAltText}
                                           previewImage={previewImage}
                                           altText={altText}
                                           setAltText={setAltText}
                                           altTextError={altTextError}
                                           setAltTextError={setAltTextError}
                                           onPreviewRemoved={onPreviewRemoved}
                                           onAltTextChanged={onAltTextChanged}/>
                    )
                }

                {
                    !previewImage && (
                        <div className="empty-image-upload-info text-center mb-4">
                            <Icon className="mb-3" iconSpritePath={svgIconPath} name="image" width={24}
                                  height={24}/>
                            <div className="fw-semibold mb-1">
                                {localizer.msg('edit-mode.upload-image.empty-text-msg-1')}
                            </div>
                        </div>
                    )
                }

                <div className="my-3">
                    <div className="form-group react-file-uploader">
                        <FileInput
                            labels={{
                                browseFile: localizer.msg('frontend-shared.form.attachment.browse-file'),
                                dropFile: localizer.msg('frontend-shared.form.attachment.drop-file-here'),
                                or: localizer.msg('frontend-shared.form.attachment.or'),
                                dragAndDrop: localizer.msg('frontend-shared.form.attachment.drag-drop'),
                                uploadInstruction: localizer.msg('frontend-shared.form.attachment.upload-warning')
                            }}
                            svgIconPath={svgIconPath}
                            onSuccess={(files) => {
                                if (!fileValidation.test(files[0].type)) {
                                    eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.warn, localizer.msg('common.form.attachment.upload.file-type-not-supported')));
                                } else {
                                    if (fileValidation.test(files[0].type)) {
                                        toggleShowCropper(true);
                                        setImageFile(files[0]);
                                    } else {
                                        onInputFileChange(files).then();
                                    }
                                }
                            }}
                            onError={(error) => handleFileUploadError(error)}
                            maxSizeInMB={maxFileSizeLimit}
                            multiple={false}
                        />
                        {
                            averagePercentageCompleted < 100 &&
                            <Progress value={averagePercentageCompleted} max={100} barClassName="progressbar"
                                      className="progressbar-track mt-1">
                                {localizer.msg('common.form.attachment.upload.uploading')}
                            </Progress>
                        }
                    </div>
                </div>
            </div>
            {
                showCropper &&
                <CropperModal
                    isOpen={showCropper}
                    toggle={toggleShowCropper}
                    title={localizer.msg('frontend-shared.common.adjust-image')}
                    saveButtonText={localizer.msg('frontend-shared.common.save-changes')}
                    closeButtonText={localizer.msg('frontend-shared.common.close')}
                    file={imageFile}
                    handleUpload={onInputFileChange}
                    svgIconPath={svgIconPath}
                    loadingSvg={loadingSvg}
                    autoCropArea={1}
                    cropHeight={cropHeight}
                    cropWidth={cropWidth}/>
            }
        </Fragment>
    );
};