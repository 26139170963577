import {LandingPageSummary} from 'models/landing-page/LandingPageSummary';

export class LandingPageResponse {
    public static EMPTY = new LandingPageResponse(0, false, []);

    public constructor(
        public activePageId: number,
        public alwaysPublic: boolean,
        public pages: LandingPageSummary[],
    ) {
    }
}