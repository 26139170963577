import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Trans} from 'react-i18next';
import {Localizer} from '@ideascale/commons';
import {ActivityLink} from 'components/profile/activity-stream/ActivityLink';
import {ActivityStreamData} from 'models/ActivityStreamData';
import {ActionType} from 'models/enums/ActionType';
import {appLinks} from 'services/AppLinks';

type ContributorActivityProps = {
    localizer: Localizer;
    action: ActionType.CONTRIBUTOR_ADDED | ActionType.CONTRIBUTOR_REMOVED;
    activity: ActivityStreamData;
}

export const ContributorActivity = (props: ContributorActivityProps) => {
    const {activity, action} = props;

    return (
        <Fragment>
            <Trans
                i18nKey={action === ActionType.CONTRIBUTOR_ADDED ? 'profile.activity-stream.contributor.added' : 'profile.activity-stream.contributor.removed'}
                values={{member: activity.member?.name}}>
                <Link to={appLinks.profile(activity.member?.id || 0)}>{activity.member?.name}</Link>
            </Trans>
            {' - '}
            <ActivityLink title={activity.idea.title} ideaId={activity.idea.id}/>
        </Fragment>
    );
};
