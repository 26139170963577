import React from 'react';
import {IdeaSummary} from 'models/IdeaSummary';
import {Localizer} from '@ideascale/commons';
import IdeaCard from 'components/landing-page/IdeaCard';
import {IdeaCardViewSkeleton} from 'components/landing-page/IdeaCardViewSkeleton';
import 'components/landing-page/IdeaCardViewList.scss';

type IdeaCardViewListProps = {
    ideas: IdeaSummary[];
    editMode?: boolean;
    localizer: Localizer;
    loading: boolean;
}

export const IdeaCardViewList = (props: IdeaCardViewListProps) => {
    const {ideas, editMode = false, localizer, loading} = props;

    return (
        loading
            ? (
                <IdeaCardViewSkeleton localizer={localizer}/>
            )
            : (
                <div className="row trending-ideas w-100 rounded-top">
                    <div className={`${ideas.length === 1 ? 'col-12' : 'col-12 col-lg-6'}`}>
                        <IdeaCard editMode={editMode} sideView={false} idea={ideas[0]} localizer={localizer}/>
                    </div>
                    {
                        ideas.length > 1 &&
                        <div className="col-12 col-lg-6">
                            {
                                ideas.slice(1, ideas.length).map((idea) => (
                                    <IdeaCard sideView={true}
                                              editMode={editMode}
                                              key={idea.id} idea={idea}
                                              localizer={localizer}/>
                                ))
                            }
                        </div>
                    }
                </div>
            )
    );
};
