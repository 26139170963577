import React from 'react';
import {Localizer, Member} from '@ideascale/commons';
import {BasicActivityCard} from '../idea/details/BasicActivityCard';
import {ActivityHeaderSummary} from '../ActivityHeaderSummary';
import {StageActivitySummary} from 'models/StageActivitySummary';

type BasicActivityProps = {
    localizer: Localizer;
    activitySummary: StageActivitySummary;
    author: Member;
    headerTitle?: string;
}
export const BasicActivity = (props: BasicActivityProps) => {
    const {localizer, activitySummary, author, headerTitle} = props;

    return (
        <div className="pb-3">
            <BasicActivityCard headerSummary={
                <ActivityHeaderSummary stageActivity={activitySummary} localizer={localizer} author={author}/>
            } headerTitle={headerTitle} />
        </div>
    );
};