import React, {useCallback, useEffect} from 'react';
import {Modal} from '@ideascale/ui';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useIdeaService} from 'hooks/useIdeaService';
import {IdeaSubmissionPreview} from 'components/idea-submission/IdeaSubmissionPreview';

type IdeaSubmissionPreviewContainerProps = {
    displayAsModal: boolean
}

export const IdeaSubmissionPreviewContainer = (props: IdeaSubmissionPreviewContainerProps) => {
    const {displayAsModal} = props;
    const localizer = useLocalizer();
    const {lastSubmittedIdea, setLastSubmittedIdea} = useAppContext();
    const {fetchOwners} = useIdeaService();

    const onClosePreview = useCallback(() => {
        setLastSubmittedIdea(null);
    }, [setLastSubmittedIdea]);

    useEffect(() => {
        return () => {
            setLastSubmittedIdea(null);
        };
    }, [setLastSubmittedIdea]);

    const renderPreview = useCallback((previewComponent: JSX.Element) => {
        if (displayAsModal) {
            return <Modal isOpen toggle={onClosePreview} className="modal-lg">
                <article className="mt-n4 idea shadow-none mb-0 px-0" data-test-element-id="idea-preview">
                    {previewComponent}
                </article>
            </Modal>;
        } else {
            return <article className="container mt-3">
                <div className="panel panel-default card mx-sm-n3" data-test-element-id="idea-preview">
                    <div className="panel-body card-body p-4 idea shadow-none mb-0">
                        {previewComponent}
                    </div>
                </div>
            </article>;
        }
    }, [displayAsModal, onClosePreview]);

    if (lastSubmittedIdea) {
        return renderPreview(
            <IdeaSubmissionPreview
                setLastSubmittedIdea={setLastSubmittedIdea} localizer={localizer}
                submittedIdea={lastSubmittedIdea} onClosePreview={onClosePreview}
                modalVersion={displayAsModal} fetchOwners={fetchOwners}
            />
        );
    } else {
        return null;
    }
};