import React from 'react';
import {Icon} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {StatisticsItem} from 'models/landing-page/StatisticsItem';

type StatisticItemProps = {
    showConfig: boolean;
    data: StatisticsItem;
    toggleVisibility: (item: StatisticsItem) => void;
    value: number;
}

export const StatisticItem = (props: StatisticItemProps) => {
    const {showConfig, data, toggleVisibility, value} = props;

    return (
        <div className={`community-stat-item position-relative me-0 me-sm-4`} title={`${value} ${data.title}`} aria-disabled={!data.visible}>
            <div className={`position-relative stat-card m-auto ${!data.visible ? 'component-hidden' : ''}`}>
                <div className="ps-3 pt-5">
                    <div className="fw-bold h4">
                        {value}
                    </div>
                    <span className="font-size-lg text-truncate">
                        {data.title}
                    </span>
                </div>
                {
                    showConfig &&
                    <button className="btn btn-gray pt-1 px-2 ms-2 item-config edit-mode-element" aria-disabled={false}
                            onClick={() => toggleVisibility({...data, visible: !data.visible})}>
                        <Icon iconSpritePath={svgIconPath} name={`${data.visible ? 'eye-open' : 'eye-open-slash'}`}
                              width={18}
                              height={18}/>
                        <span className="sr-only">Toggle component visibility</span>
                    </button>
                }
            </div>
        </div>
    );
};