import {AbstractService} from '../AbstractService';
import {EditModeService} from '../EditModeService';
import {serviceLinks} from '../ServiceLinks';
import {HomeConfigHolder} from 'models/edit-mode/HomeConfigHolder';
import {Operation} from 'models/edit-mode/Operation';
import {PublishCampaignHomeOperation} from 'models/edit-mode/PublishCampaignHomeOperation';
import {PublishCommunityHomeOperation} from 'models/edit-mode/PublishCommunityHomeOperation';
import {ImageFile} from 'models/edit-mode/ImageFile';
import {UploadFileRequest} from 'models/edit-mode/UploadFileRequest';
import {UploadedFileResponse} from 'models/edit-mode/UploadedFileResponse';

export class EditModeServiceImpl extends AbstractService implements EditModeService {
    async fetchCommunityConfig(): Promise<HomeConfigHolder> {
        return await this.getAndUnwrap(serviceLinks.communityHomeConfig(), {}, HomeConfigHolder);
    }

    async fetchCampaignConfig(campaignId: number): Promise<HomeConfigHolder> {
        return await this.getAndUnwrap(serviceLinks.campaignHomeConfig(campaignId), {}, HomeConfigHolder);
    }

    async saveCommunityOperations(operations: Operation[]) {
        return await this.postAndUnwrap(serviceLinks.executeCommunityHomeConfig(), {operations: operations});
    }

    async publishCommunityOperations(operations: Operation[]) {
        operations.push(new PublishCommunityHomeOperation());
        return this.saveCommunityOperations(operations);
    }

    async saveCampaignOperations(operations: Operation[], campaignId: number) {
        return await this.postAndUnwrap(serviceLinks.executeCampaignHomeConfig(campaignId), {operations: operations});
    }

    async publishCampaignOperations(campaignId: number, operations: Operation[]) {
        operations.push(new PublishCampaignHomeOperation(campaignId));
        return this.saveCampaignOperations(operations, campaignId);
    }

    async fetchCommunityLibraryImages(): Promise<ImageFile[]> {
        return await this.getAndUnwrap(serviceLinks.communityLibraryImages());
    }

    async fetchCampaignLibraryImages(): Promise<ImageFile[]> {
        return await this.getAndUnwrap(serviceLinks.campaignLibraryImages());
    }

    async fetchCommunityBannerImages(): Promise<ImageFile[]> {
        return await this.getAndUnwrap(serviceLinks.communityBannerImages());
    }

    async fetchCampaignBannerImages(): Promise<ImageFile[]> {
        return await this.getAndUnwrap(serviceLinks.campaignBannerImages());
    }

    async fetchCampaignFeaturedGalleryImages(): Promise<ImageFile[]> {
        return await this.getAndUnwrap(serviceLinks.campaignFeaturedGalleryImages());
    }

    async communityUploadLibraryFiles(uploadFileRequest: UploadFileRequest): Promise<UploadedFileResponse[]> {
        return await this.postAndUnwrap(serviceLinks.communityUploadLibraryFiles(), uploadFileRequest);
    }

    async campaignUploadLibraryFiles(uploadFileRequest: UploadFileRequest): Promise<UploadedFileResponse[]> {
        return await this.postAndUnwrap(serviceLinks.campaignUploadLibraryFiles(), uploadFileRequest);
    }
}