export class InstructionListItem {
    public static EMPTY = new InstructionListItem(
        0,
        '',
        '',
        '',
        '',
        false,
    );

    constructor(
        public id: number,
        public url: string,
        public altText: string,
        public title: string,
        public description: string,
        public visible: boolean,
    ) {
    }
}