import React from 'react';
import {Icon} from '@ideascale/ui';
import {Localizer} from '@ideascale/commons';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';

type BackToListProps = {
    localizer: Localizer;
    onClick: () => void;
}

export const BackToList = (props: BackToListProps) => {
    const {localizer, onClick} = props;
    return (
        <nav className="nav-back mb-2 pb-1" aria-label={localizer.msg('ideas.back-to-list')}>
            <button className="btn btn-link px-0 shadow-none" type="button" onClick={onClick}>
                <Icon className="position-relative pos-top-n1 active" iconSpritePath={svgIconsPath}
                      name="fast-rewind"/> {localizer.msg('ideas.back-to-list')}
            </button>
        </nav>
    );
};
