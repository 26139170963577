export enum TranslationContentType {
    PAGE_COMPONENT_TITLE = 'PAGE_COMPONENT_TITLE',
    PAGE_COMPONENT_SUBTITLE = 'PAGE_COMPONENT_SUBTITLE',
    COMPONENT_TEXT_TEXT = 'COMPONENT_TEXT_TEXT',
    COMPONENT_INSTRUCTION_TITLE = 'COMPONENT_INSTRUCTION_TITLE',
    COMPONENT_INSTRUCTION_DESCRIPTION = 'COMPONENT_INSTRUCTION_DESCRIPTION',
    COMPONENT_STATISTICS_TITLE = 'COMPONENT_STATISTICS_TITLE',
    COMPONENT_LEADER_TITLE = 'COMPONENT_LEADER_TITLE',
    COMPONENT_ACTIVITY_TITLE = 'COMPONENT_ACTIVITY_TITLE',
    COMPONENT_BUTTON_TEXT = 'COMPONENT_BUTTON_TEXT',
    TEXT_COMPONENT_TEXT = 'TEXT_COMPONENT_TEXT',
}