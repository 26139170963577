import React, {Fragment} from 'react';
import {Icon} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useLocalizer} from 'hooks/useLocalizer';

type SocialShareProps = {
    socialShareURLs: { facebook: string, twitter: string, linkedin: string };
}

export const SocialShare = (props: SocialShareProps) => {
    const {socialShareURLs} = props;
    const localizer = useLocalizer();

    return (
        <Fragment>
            {
                socialShareURLs.twitter !== '' &&
                <a className="me-4" target="_blank" rel="noreferrer" href={socialShareURLs && socialShareURLs.twitter}
                   title={localizer.msg('idea.actions.twitter-share')}>
                    <Icon name="twitter-solid-box" fill="#999" width={24} height={24}
                          iconSpritePath={svgIconPath}/>
                </a>
            }
            {
                socialShareURLs.facebook !== '' &&
                <a className="me-4" target="_blank" rel="noreferrer" href={socialShareURLs && socialShareURLs.facebook}
                   title={localizer.msg('idea.actions.facebook-share')}>
                    <Icon name="facebook-solid-box" fill="#999" width={24} height={24}
                          iconSpritePath={svgIconPath}/>
                </a>
            }
            {
                socialShareURLs.linkedin !== '' &&
                <a className="me-4" target="_blank" rel="noreferrer" href={socialShareURLs && socialShareURLs.linkedin}
                   title={localizer.msg('idea.actions.linkedin-share')}>
                    <Icon name="linkedin-solid-box" fill="#999" width={24} height={24}
                          iconSpritePath={svgIconPath}/>
                </a>
            }
        </Fragment>
    );
};