import React, {useCallback, useState} from 'react';
import {Link} from 'react-router-dom';
import {useQuery} from 'react-query';
import {PopoverBody, UncontrolledPopover} from 'reactstrap';
import {CampaignStage as Stage, eventDispatcher, thousandFormat, useRoutesMatch, useUrlQuery} from '@ideascale/commons';
import {ParagraphSkeleton} from '@ideascale/ui';
import {useAppContext} from 'contexts/AppContext';
import {useIdeaDetailsContext} from 'contexts/IdeaDetailsContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {ROUTES} from 'shared/Routes';
import {appLinks} from 'services/AppLinks';
import {QUERY_KEYS, SCROLL_EVENTS, STAGE_KEYS} from 'constants/AppConstants';
import {StageDetails} from 'models/StageDetails';
import {IdeaListRouteMatchParams} from 'models/types/IdeaListRouteMatchParams';

type stagesProps = {
    fetchSidebarStages: (campaignId: number, stageKey?: string, tag?: string, moderatorTag?: string, labelKey?: string) => Promise<StageDetails>;
}

const STAGE_STATUS_ACTIVE = 'ACTIVE';
const STAGE_STATUS_COMPLETED = 'COMPLETED';
const CAMPAIGN_ROUTES = [ROUTES.CAMPAIGN.IDEAS_PATH, ROUTES.CAMPAIGN.ABOUT_PATH, ROUTES.CAMPAIGN.TEAM_PATH, ROUTES.CAMPAIGN.WORKFLOW_PATH, ROUTES.CAMPAIGN_TAGS];

export const Stages = (props: stagesProps) => {
    const {ideaDetails} = useIdeaDetailsContext();
    const {fetchSidebarStages} = props;
    const localizer = useLocalizer();
    const {setCurrentStage, currentCampaign} = useAppContext();
    const urlQuery = useUrlQuery();
    const matchedRoute = useRoutesMatch<IdeaListRouteMatchParams>(CAMPAIGN_ROUTES);
    const urlTagString = urlQuery.get(ROUTES.QUERY_PARAMS.TAG);
    const urlLabelString = urlQuery.get(ROUTES.QUERY_PARAMS.LABEL);
    const [stageItem, setStageItem] = useState<StageDetails>(StageDetails.EMPTY);
    const [loading, setLoading] = useState(true);

    const getStageKey = useCallback(() => {
        return ideaDetails?.id ? ideaDetails.stageSummary?.stage?.key : matchedRoute?.params.stageKey;
    }, [ideaDetails?.id, ideaDetails.stageSummary.stage.key, matchedRoute?.params.stageKey]);

    useQuery([QUERY_KEYS.CAMPAIGN_FUNNEL, getStageKey()], () => fetchSidebarStages((matchedRoute?.params.campaignId ? +matchedRoute?.params.campaignId : ideaDetails?.campaign?.id) || 0, matchedRoute?.params.stageKey || '', urlTagString || '', '', urlLabelString || ''),
        {
            enabled: !!matchedRoute?.params.campaignId || (ideaDetails?.id > 0 && !!ideaDetails.campaign),
            onSuccess: (data) => {
                setStageItem(data);
                setLoading(false);
                let findCurrentStage;
                if (ideaDetails?.id) {
                    findCurrentStage = data.stages.find(data => data.stage.key === ideaDetails.stageSummary.stage.key);
                } else {
                    findCurrentStage = data.stages.find(data => data.stage.key === matchedRoute?.params.stageKey);
                }
                if (findCurrentStage) {
                    setCurrentStage(findCurrentStage);
                } else {
                    setCurrentStage(null);
                }
            }
        }
    );

    const campaignIdeasLink = (campaignId: number | undefined, stageKey?: string) => {
        if (campaignId) {
            return appLinks.ideas(String(campaignId), stageKey);
        } else {
            return '#';
        }
    };

    const isActiveStage = (stage: Stage) => {
        return stage.key === matchedRoute?.params.stageKey || stage.id === ideaDetails.stageSummary.stage.id;
    };

    return (
        <div id="stages">
            <div className="funnel-stages">
                {
                    loading
                        ? <ParagraphSkeleton rows={6}/>
                        : (
                            <>
                                <ul className="list-unstyled stage-list mb-4">
                                    <li className={`position-relative ${matchedRoute?.params.stageKey === STAGE_KEYS.ALL_STAGES ? 'active' : ''}`}>
                                        <Link to={campaignIdeasLink(currentCampaign?.id, STAGE_KEYS.ALL_STAGES)}
                                              className={`d-table w-100 text-white text-center text-decoration-none fw-bold`}
                                              onClick={() => eventDispatcher.dispatch(SCROLL_EVENTS.SCROLL_TOP)}>
                                            <div className="stage-name">
                                                {localizer.msg('campaign.stages.all-stages')}
                                            </div>
                                            <div className="separator"/>
                                            <div className="stage-idea-count text-truncate"
                                                 title={localizer.msg('campaign.stages.ideas', {
                                                     count: stageItem.countTotalIdeas
                                                 })}>
                                                {thousandFormat(stageItem.countTotalIdeas!)}
                                                <span className="sr-only">{localizer.msg('page.ideas')}</span>
                                            </div>
                                        </Link>
                                    </li>
                                    {
                                        stageItem.stages.filter((data) => data.stage.stageGroup === STAGE_STATUS_ACTIVE).map((data, index) => {
                                            return (
                                                <li className={`position-relative ${isActiveStage(data.stage) ? 'active' : ''}`}
                                                    key={index}
                                                    id={`stage-${data.stage.id}-tooltip-container`}
                                                    aria-live="polite"
                                                >
                                                    <Link to={campaignIdeasLink(currentCampaign?.id, data.stage.key)}
                                                          onClick={() => eventDispatcher.dispatch(SCROLL_EVENTS.SCROLL_TOP)}
                                                          id={`stage-${data.stage.id}`}
                                                          className={`d-table w-100 text-white text-center text-decoration-none fw-bold ${campaignIdeasLink(currentCampaign?.id, data.stage.key) !== data.stage.key ? '' : 'active'}`}>
                                                        <div className="stage-name">
                                                            {data.stage.label}
                                                        </div>
                                                        <div className="separator bg-white"/>
                                                        <div className="stage-idea-count text-truncate"
                                                             title={localizer.msg('campaign.stages.ideas', {
                                                                 count: data.ideaCount
                                                             })}>{thousandFormat(data.ideaCount!)}
                                                            <span className="sr-only">{localizer.msg('page.ideas')}</span>
                                                        </div>
                                                        <UncontrolledPopover
                                                            trigger="hover focus"
                                                            placement="left"
                                                            container={`stage-${data.stage.id}-tooltip-container`}
                                                            target={`stage-${data.stage.id}`}>
                                                            <PopoverBody>
                                                                <ul className="list-unstyled mb-0">
                                                                    <li>{data.stage.description}</li>
                                                                </ul>
                                                            </PopoverBody>
                                                        </UncontrolledPopover>
                                                    </Link>
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                                <ul className="list-unstyled stage-list archive-stage-list">
                                    {
                                        stageItem.stages.filter((data) => data.stage.stageGroup === STAGE_STATUS_COMPLETED).map((data, index) => {
                                            return (
                                                <li className={`position-relative ${isActiveStage(data.stage) ? 'active' : ''}`}
                                                    key={index}
                                                    id={`stage-${data.stage.id}-tooltip-container`}
                                                    aria-live="polite">
                                                    <Link to={campaignIdeasLink(currentCampaign?.id, data.stage.key)}
                                                          id={`stage-${data.stage.id}`}
                                                          className="d-table w-100 text-white text-center text-decoration-none fw-bold"
                                                          onClick={() => eventDispatcher.dispatch(SCROLL_EVENTS.SCROLL_TOP)}>
                                                        <div className="stage-name">
                                                            {data.stage.label}
                                                        </div>
                                                        <div className="separator bg-white"/>
                                                        <div className="stage-idea-count text-truncate"
                                                             title={localizer.msg('campaign.stages.ideas', {
                                                                 count: data.ideaCount
                                                             })}>{thousandFormat(data.ideaCount!)}
                                                            <span className="sr-only">{localizer.msg('page.ideas')}</span>
                                                        </div>
                                                        <UncontrolledPopover trigger="hover focus"
                                                                             placement="left"
                                                                             container={`stage-${data.stage.id}-tooltip-container`}
                                                                             target={`stage-${data.stage.id}`}>
                                                            <PopoverBody>
                                                                <ul className="list-unstyled mb-0">
                                                                    <li>{data.stage.description}</li>
                                                                </ul>
                                                            </PopoverBody>
                                                        </UncontrolledPopover>
                                                    </Link>
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                            </>
                        )
                }
            </div>
        </div>
    );
};
