import {TextColor} from 'models/enums/landing-page/TextColor';
import {OptionType} from '@ideascale/ui';
import {useLocalizer} from 'hooks/useLocalizer';

export const useTextColor = () => {
    const localizer = useLocalizer();

    const getTextColorClassName = (color: TextColor) => {
        if (color === TextColor.LIGHT) {
            return 'text-white';
        } else if (color === TextColor.DARK) {
            return 'text-dark';
        } else {
            return '';
        }
    };

    const textColorOptions: OptionType[] = [
        {
            value: TextColor.DEFAULT,
            label: localizer.msg('landing-page.components.edit.general-text.default')
        },
        {
            value: TextColor.DARK,
            label: localizer.msg('landing-page.components.edit.general-text.dark')
        },
        {
            value: TextColor.LIGHT,
            label: localizer.msg('landing-page.components.edit.general-text.light')
        },
    ];

    return {getTextColorClassName, textColorOptions};
};