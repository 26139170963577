import {CommunityHomeEditor} from './Editors/CommunityHomeEditor';
import {Command} from './Command';
import {CommunityOperationType} from 'models/edit-mode/CommunityOperationType';
import {Operation} from 'models/edit-mode/Operation';
import {LogoField} from 'models/edit-mode/LogoField';
import {ChangeCommunityLogoOperation} from 'models/edit-mode/ChangeCommunityLogoOperation';

export class UpdateCommunityLogoCommand implements Command {
    private readonly communityHomeEditor: CommunityHomeEditor;
    private readonly oldCommunityLogo: LogoField;
    private readonly newCommunityLogo: LogoField;
    private readonly name: CommunityOperationType;

    constructor(communityHomeEditor: CommunityHomeEditor, newLogoField: LogoField) {
        this.communityHomeEditor = communityHomeEditor;
        this.newCommunityLogo = newLogoField;
        this.oldCommunityLogo = this.communityHomeEditor.communityLogo;
        this.name = CommunityOperationType.CHANGE_COMMUNITY_LOGO;
    }

    execute(): void {
        this.communityHomeEditor.communityLogo = this.newCommunityLogo;
    }

    getType(): CommunityOperationType {
        return this.name;
    }

    getOperation(): Operation {
        return new ChangeCommunityLogoOperation(CommunityOperationType.CHANGE_COMMUNITY_LOGO, this.communityHomeEditor.communityLogo.fileId, this.communityHomeEditor.communityLogo.logoFrameEnabled, this.communityHomeEditor.communityLogo.logoOnBannerEnabled);
    }

    undo(): void {
        this.communityHomeEditor.communityLogo = this.oldCommunityLogo;
    }
}