import React, {Fragment} from 'react';
import {Localizer} from '@ideascale/commons';
import {ActivityLink} from '../ActivityLink';
import {ActionType} from 'models/enums/ActionType';
import {ActivityStreamData} from 'models/ActivityStreamData';

type TagActivityProps = {
    localizer: Localizer;
    action: ActionType.TAG_ADDED | ActionType.TAG_REMOVED;
    activity: ActivityStreamData;
}

export const TagActivity = (props: TagActivityProps) => {
    const {action, activity, localizer} = props;

    const tagRemoved = !activity.tag;

    return (
        <Fragment>
            {action === ActionType.TAG_ADDED
                ? localizer.msg('profile.activity-stream.tag.added')
                : localizer.msg('profile.activity-stream.tag.removed')
            }{' '}
            {tagRemoved
                ? <i>({localizer.msg('profile.activity-stream.tag.deleted')})</i>
                : <b>{activity.tag}</b>
            }{' - '}
            <ActivityLink title={activity.idea.title} ideaId={activity.idea.id}/>
        </Fragment>
    );
};