import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button} from '@ideascale/ui';
import {LinkIdeaQualifier, LinkQualifier, Localizer, PagedResponseContent} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useQualifierLinkedIdeasAction} from 'hooks/useQualifierLinkedIdeasAction';
import {LinkedIdeas} from 'shared/LinkedIdeas';
import {LinkableIdeas} from './LinkableIdeas';
import {LinkableIdea} from 'models/LinkableIdea';
import {PageParameters} from 'models/types/PageParameters';
import {QualifierLinkedIdeas} from 'models/QualifierLinkedIdeas';
import {LinkedIdea} from 'models/LinkedIdea';

type LinkIdeasProps = {
    localizer: Localizer;
    ideaId: number;
    ideaTitle: string;
    ideaDescription: string;
    linkQualifiers: LinkQualifier;
    linkedIdeas: QualifierLinkedIdeas[];
    fetchLinkableIdeas: (ideaId: number, ideaTitle: string, ideaDescription: string, pageParameters: PageParameters) => Promise<PagedResponseContent<LinkableIdea>>;
    fetchLinkableIdeasByKeywords: (ideaId: number, pageParameters: PageParameters) => Promise<PagedResponseContent<LinkableIdea>>;
    onSave: (qualifierIdeas: QualifierLinkedIdeas[]) => void;
    unlinkIdea: (linkedIdeaId: number, qualifierId: number) => void;
    onCancel: () => void;
    showLinkableIdeas?: boolean;
}

export const LinkIdeas = (props: LinkIdeasProps) => {
    const {
        localizer,
        fetchLinkableIdeas,
        fetchLinkableIdeasByKeywords,
        ideaId,
        ideaTitle,
        linkQualifiers,
        linkedIdeas,
        showLinkableIdeas = true,
        onCancel,
        onSave,
        unlinkIdea,
    } = props;

    const {authentication: {actor}} = useAppContext();
    const {addIdeaToQualifiedLinkedIdeas, removeIdeaFromQualifiedLinkedIdeas} = useQualifierLinkedIdeasAction();
    const [qualifierIdeas, setQualifierIdeas] = useState<QualifierLinkedIdeas[]>([]);
    const deletedLinkedIdeas = useRef<{ linkedIdea: LinkedIdea, qualifierId: number }[]>([]);

    const onClickQualifier = useCallback((idea: LinkableIdea, linkIdeaQualifier: LinkIdeaQualifier) => {
        setQualifierIdeas(prevIdeas => addIdeaToQualifiedLinkedIdeas(prevIdeas, {
            id: idea.id,
            title: idea.title,
            deletable: true,
            linkedBy: actor,
            labels: [],
            attributes: idea.attributes
        }, linkIdeaQualifier));
    }, [actor, addIdeaToQualifiedLinkedIdeas]);

    const onUnlinkIdea = useCallback((linkedIdea: LinkedIdea, qualifierId: number) => {
        setQualifierIdeas(prevState => removeIdeaFromQualifiedLinkedIdeas(prevState, linkedIdea, qualifierId));
        unlinkIdea(linkedIdea.id, qualifierId);
        if (!showLinkableIdeas) {
            deletedLinkedIdeas.current = [...deletedLinkedIdeas.current, {linkedIdea: linkedIdea, qualifierId: qualifierId}];
        }
    }, [removeIdeaFromQualifiedLinkedIdeas, showLinkableIdeas, unlinkIdea]);

    const onClickCancel = useCallback(() => {
        let propsLinkedIdeas: QualifierLinkedIdeas[] = [...linkedIdeas.map(value => ({
            linkQualifier: value.linkQualifier,
            ideas: [...value.ideas]
        }))];
        deletedLinkedIdeas.current.forEach(deleteIdea => {
            propsLinkedIdeas = removeIdeaFromQualifiedLinkedIdeas(propsLinkedIdeas, deleteIdea.linkedIdea, deleteIdea.qualifierId);
        });

        onCancel();
        setQualifierIdeas(propsLinkedIdeas);
    }, [linkedIdeas, onCancel, removeIdeaFromQualifiedLinkedIdeas]);

    const onSaveClick = () => {
        const allQualifiedIdeas = qualifierIdeas.flatMap(qualifierIdeas => qualifierIdeas.ideas);
        deletedLinkedIdeas.current = deletedLinkedIdeas.current.filter(deletedIdea => allQualifiedIdeas.findIndex(idea => idea.id === deletedIdea.linkedIdea.id) === -1);
        onSave(qualifierIdeas);
    };

    useEffect(() => {
        setQualifierIdeas(linkedIdeas);
    }, [linkedIdeas, setQualifierIdeas]);

    return (
        <div className="linked-idea-container mb-3">
            {
                showLinkableIdeas &&
                <>
                    <LinkableIdeas className="mt-3"
                                   localizer={localizer}
                                   ideaId={ideaId}
                                   ideaTitle={ideaTitle}
                                   fetchLinkableIdeas={fetchLinkableIdeas}
                                   fetchLinkableIdeasByKeywords={fetchLinkableIdeasByKeywords}
                                   linkQualifiers={linkQualifiers}
                                   onClickQualifier={onClickQualifier}
                                   showDefaultValue={true}
                                   SearchContainerTag="form"
                                   ideasContainerIdPrefix={'details'}
                                   showIdeaSearchField={true}/>
                    <div className="text-end mt-3">
                        <button className="btn btn-cancel me-2" data-test-element-id="cancel-link"
                                onClick={onClickCancel}>{localizer.msg('common.actions.cancel')}</button>
                        <Button color="primary" onClick={onSaveClick} data-test-element-id="save-link">{localizer.msg('common.actions.save')}</Button>
                    </div>
                </>
            }

            <div className="mt-3">
                <LinkedIdeas actorMemberId={actor.id} localizer={localizer} linkedIdeas={qualifierIdeas}
                             linkQualifiers={linkQualifiers}
                             unlinkIdea={onUnlinkIdea}/>
            </div>
        </div>
    );
};