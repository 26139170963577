import React, {memo, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {useQuery} from 'react-query';
import {Button, ParagraphSkeleton, Scrollbar, ScrollbarInstance} from '@ideascale/ui';
import {AlertType, eventDispatcher, LayoutUtil, useApiErrorResponseHandler} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {NotificationList} from './NotificationList';
import {NotificationResponse} from 'models/NotificationResponse';
import {NotificationType} from 'models/enums/NotificationType';
import {appLinks} from 'services/AppLinks';
import {NOTIFICATION_FILTERS, SCROLL_EVENTS} from 'constants/AppConstants';

type NotificationProps = {
    fetchTopbarNotifications: (groupId: number) => Promise<NotificationResponse>;
    getDataForDigestModal: (year: string, notificationType: NotificationType, month?: string, week?: string, campaignId?: number, memberId?: number) => void;
}

export const TopbarNotifications = memo((props: NotificationProps) => {
    const {fetchTopbarNotifications, getDataForDigestModal} = props;
    const localizer = useLocalizer();
    const {authentication: {actor}} = useAppContext();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [filter, setFilter] = useState<typeof NOTIFICATION_FILTERS[number]>(NOTIFICATION_FILTERS[0]);
    const scrollbarContainer = useRef<ScrollbarInstance>(null);

    const {
        data,
        isLoading
    } = useQuery(
        ['topbar-notifications', filter.groupId],
        ({queryKey}) => fetchTopbarNotifications(Number(queryKey[1])),
        {
            staleTime: 10000,
            refetchOnWindowFocus: true,
            onError: (error: any) => {
                handleErrorResponse(error, {
                    fallbackMessage: {
                        type: AlertType.error,
                        message: localizer.msg('notification.error.message')
                    }
                });
            }
        }
    );

    const onFilterChanged = (group: { groupId: number, labelLocalization: string }) => {
        setFilter(group);
    };

    const onClickView = () => {
        eventDispatcher.dispatch(SCROLL_EVENTS.SCROLL_TOP);
    };

    return (
        <div className="m-3 font-size-sm">
            <p className="font-size-md fw-bold h5">{localizer.msg('notification.header')}</p>
            <div className="mt-3" role="tablist" aria-label={localizer.msg('notification.group.filter')}>
                {NOTIFICATION_FILTERS.map(value =>
                    <Button color="transparent"
                            key={value.groupId}
                            id={String(value.groupId)}
                            role="tab"
                            aria-selected={filter.groupId === value.groupId ? 'true' : 'false'}
                            onClick={() => onFilterChanged(value)}
                            className={`rounded font-size-sm me-2 ${filter.groupId === value.groupId ? 'btn-secondary' : 'btn-cancel fw-normal'}`}>
                        {localizer.msg(value.labelLocalization)}
                    </Button>
                )}
            </div>
            <div className="py-3 px-1 me-n3" role="tabpanel" aria-labelledby={filter.groupId.toString()}>
                {isLoading ?
                    <ParagraphSkeleton rows={4}/>
                    : (data && data.notifications.content.length > 0 ?
                            <Scrollbar
                                autoHeight
                                autoHeightMax={LayoutUtil.isMobileLayout() ? '80vh' : '70vh'}
                                ref={scrollbarContainer}>
                                <ul className="list-unstyled notifications-list pe-3"
                                    aria-label={localizer.msg('notification.list')}>
                                    <NotificationList
                                        getDataForDigestModal={getDataForDigestModal}
                                        notifications={data.notifications.content}
                                        actorId={actor.id}
                                        localizer={localizer}/>
                                </ul>
                            </Scrollbar>
                            : <div className="fw-bold py-2">
                                {localizer.msg('notification.no-new')}
                            </div>
                    )
                }
            </div>
            <div className="d-flex justify-content-between notification-footer pb-2 pb-md-0">
                <Link to={appLinks.notifications(actor.id)} onClick={onClickView}
                      className="btn btn-light me-3 rounded font-size-sm flex-grow-1 flex-shrink-1 text-center position-relative">
                    {localizer.msg('notification.view.all')}
                    {
                        data && data.remainingUnseen > 0 &&
                        <span className="badge rounded-pill bg-danger font-size-sm position-absolute">
                                {data.remainingUnseen}
                            </span>
                    }
                </Link>
                <Link to={appLinks.notifications(actor.id)} onClick={onClickView}
                      className="btn btn-secondary rounded font-size-sm flex-grow-1 flex-shrink-1 text-center">
                    {localizer.msg('notification.view.subscriptions')}
                </Link>
            </div>
        </div>
    );
});