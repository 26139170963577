import React from 'react';
import {Link} from 'react-router-dom';
import {Trans} from 'react-i18next';
import {NotificationData} from 'models/NotificationData';
import {appLinks} from 'services/AppLinks';

type IdeaStageChangedNotificationProps = {
    actorId: number;
    notification: NotificationData;
}

export const IdeaStageChangedNotification = (props: IdeaStageChangedNotificationProps) => {
    const {actorId, notification} = props;

    const actorIsIdeaAuthor = notification.ideaAuthorId === actorId;

    return (
        <Trans i18nKey={`notification.idea.stage.${actorIsIdeaAuthor ? 'changed-self' : 'changed-others'}`} values={{
            idea: notification.viewAttributes.ideaTitle,
            stage: notification.viewAttributes.stageName
        }}>

            <Link to={appLinks.ideaDetails(notification.ideaId)} />
            <strong />
        </Trans>
    );
};