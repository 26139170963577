import React, {Fragment, useCallback, useRef, useState} from 'react';
import {Localizer, PageTheme} from '@ideascale/commons';
import {useDefaultImage} from 'hooks/useDefaultImage';
import {HtmlRenderer} from 'components/shared/HtmlRenderer';
import {ConfigWrapper} from 'components/landing-page/ConfigWrapper';
import {InstructionListItem} from 'models/landing-page/InstructionListItem';
import {InstructionListComponentData} from 'models/landing-page/InstructionListComponentData';
import {CommonUtil} from 'utils/CommonUtil';

type EastmanInstructionItemContainerProps = {
    theme: PageTheme;
    showConfig: boolean;
    config: InstructionListComponentData;
    toggleVisibility: (item: InstructionListItem) => Promise<void>;
    showTranslationConfig: boolean;
    onSelectItem: (item: InstructionListItem) => void;
    selectedItem: InstructionListItem;
    onEditClicked: (item: InstructionListItem) => void;
    onDeleteClicked: (item: InstructionListItem) => void;
    onTranslationClicked: (item: InstructionListItem) => void;
    localizer: Localizer;
    bodyClass?: string;
}

export const EastmanInstructionItemContainer = (props: EastmanInstructionItemContainerProps) => {
    const {
        showConfig,
        toggleVisibility,
        theme,
        config,
        showTranslationConfig,
        selectedItem,
        onSelectItem,
        onEditClicked,
        onDeleteClicked,
        onTranslationClicked,
        bodyClass,
        localizer
    } = props;

    const {getDefaultImages, containsDefaultImage, getDefaultImageIndex} = useDefaultImage(theme);
    const [mouseOverItemId, setMouseOverItemId] = useState(0);
    const selectedItemInfoContainerRef = useRef<HTMLDivElement>(null);

    const onItemMouseOver = useCallback((item: InstructionListItem) => {
        setMouseOverItemId(item.id);
    }, []);

    const onItemMouseOut = useCallback(() => {
        setMouseOverItemId(0);
    }, []);

    const scrollIntoItemView = () => {
        CommonUtil.wait(0).then(() => {
            selectedItemInfoContainerRef.current?.scrollIntoView({behavior: 'smooth', block: 'start'});
        });
    };

    const onItemClicked = useCallback((item: InstructionListItem) => {
        onSelectItem(item);
        scrollIntoItemView();
    }, [onSelectItem])

    const renderButton = useCallback((item: InstructionListItem, editMode?: boolean) => (
        <button
            onMouseOver={() => !editMode && onItemMouseOver(item)}
            onMouseOut={() => !editMode && onItemMouseOut()}
            className={`btn btn-eastman edit-mode-element w-100 fw-bold instruction-list-item-header text-wrap text-break instruction-list-item p-4 ${editMode ? '' : 'instruction-list-preview'} ${item.id === selectedItem.id ? 'selected-item' : ''} ${mouseOverItemId === item.id && !editMode ? 'show-outline' : ''}`}
            onClick={() => onItemClicked(item)}
            role="tab" aria-selected={item.id === selectedItem.id}
            id={`instruction-list-tab-${item.id}`}>
            {item.title}
        </button>
    ), [mouseOverItemId, onItemClicked, onItemMouseOut, onItemMouseOver, selectedItem.id]);

    return (
        <div className={`${bodyClass ? bodyClass : ''} row mb-5`}>
            <div className="instruction-list-items col-12 col-md-6" role="tablist" aria-orientation="vertical">
                {
                    config.items
                        ?.filter(item => (item.visible || showConfig))
                        .map((item, index) => (
                            <div className="list-unstyled" key={index}>
                                {
                                    showConfig || showTranslationConfig
                                        ? <ConfigWrapper showTranslationConfig={showTranslationConfig}
                                                         visible={item.visible}
                                                         showConfig={showConfig}
                                                         onEditClicked={() => onEditClicked(item)}
                                                         onTranslationClicked={() => onTranslationClicked(item)}
                                                         onDeleteClicked={() => onDeleteClicked(item)}
                                                         localizer={localizer}
                                                         showOutline={mouseOverItemId === item.id}
                                                         onItemMouseOver={() => onItemMouseOver(item)}
                                                         onItemMouseOut={onItemMouseOut}
                                                         toggleVisibility={() => toggleVisibility(item)}
                                                         accessibleName={localizer.msg('landing-page.instruction')}>
                                            {renderButton(item, true)}
                                        </ConfigWrapper>
                                        : renderButton(item)
                                }
                            </div>
                        ))
                }
            </div>
            <div className={`col-12 col-md-6 mt-5 mt-md-0 instruction-item-info`} role="tabpanel" tabIndex={0}
                 aria-labelledby={`instruction-list-tab-${selectedItem.id}`} ref={selectedItemInfoContainerRef}>
                {
                    selectedItem &&
                    <ConfigWrapper showTranslationConfig={showTranslationConfig}
                                   visible={selectedItem.visible}
                                   showConfig={showConfig}
                                   localizer={localizer}
                                   onItemMouseOver={() => onItemMouseOver(selectedItem)}
                                   onItemMouseOut={onItemMouseOut}
                                   showOutline={selectedItem.id === mouseOverItemId}
                                   onEditClicked={() => onEditClicked(selectedItem)}
                                   onTranslationClicked={() => onTranslationClicked(selectedItem)}
                                   onDeleteClicked={() => onDeleteClicked(selectedItem)}
                                   toggleVisibility={() => toggleVisibility(selectedItem)}>
                        <Fragment>
                            <div className="highlight-shadow image-container">
                                <img className="instruction-list-item-image media-item img-fluid rounded"
                                     src={containsDefaultImage(selectedItem.url)
                                         ? getDefaultImages(config)[getDefaultImageIndex(selectedItem.url)]
                                         : selectedItem.url}
                                     alt={selectedItem.altText}/>
                            </div>
                            <HtmlRenderer className="font-size-lg instruction-list-item-description text-gray mt-5"
                                          content={selectedItem.description}/>
                        </Fragment>
                    </ConfigWrapper>
                }
            </div>
        </div>
    );
};