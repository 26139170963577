import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useQuery} from 'react-query';
import {clone, isEmpty} from 'lodash';
import {isHtmlInject, isJavascriptInject, Localizer, useApiErrorResponseHandler} from '@ideascale/commons';
import {ActionButton, Button, Modal} from '@ideascale/ui';
import {TopBarLogo} from './TopBarLogo';
import {useEditModeFileOperationService} from 'hooks/useEditModeFileOperationService';
import {useEditModeContext} from 'contexts/EditModeContext';
import {DEFAULT_STALE_TIME, QUERY_KEYS} from 'constants/AppConstants';
import {BannerImage} from './BannerImage';
import {CommunityLogo} from './CommunityLogo';
import {CustomFavicon} from './CustomFavicon';
import {UpdateTopbarLogoCommand} from 'commands/edit-mode/UpdateTopbarLogoCommand';
import {UpdateBannerImageCommand} from 'commands/edit-mode/UpdateBannerImageCommand';
import {UpdateCommunityLogoCommand} from 'commands/edit-mode/UpdateCommunityLogoCommand';
import {UpdateCustomFaviconCommand} from 'commands/edit-mode/UpdateCustomFaviconCommand';
import {CommunityEditableFieldType} from 'models/edit-mode/CommunityEditableFieldType';
import {ImageField} from 'models/edit-mode/ImageField';
import {BannerField} from 'models/edit-mode/BannerField';
import {LogoField} from 'models/edit-mode/LogoField';
import {UploadFileRequest} from 'models/edit-mode/UploadFileRequest';
import {UploadedFileResponse} from 'models/edit-mode/UploadedFileResponse';
import {ImageType} from 'models/enums/ImageType';
import {TopbarLogoField} from 'models/edit-mode/TopbarLogoField';
import './AdvancedSettingsModal.scss';

type AdvancedSettingsModalProps = {
    isOpen: boolean
    toggle: () => void;
    localizer: Localizer;
    maxFileSizeLimit: number;
}

export const AdvancedSettingsModal = (props: AdvancedSettingsModalProps) => {
    const {localizer, isOpen, maxFileSizeLimit, toggle} = props;
    const {homeConfig, communityHomeEditor, commandExecutor, validationErrors} = useEditModeContext();
    const {
        fetchCommunityLibraryImages,
        fetchCommunityBannerImages,
        communityUploadLibraryFiles
    } = useEditModeFileOperationService();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [topbarLogo, setTopbarLogo] = useState<TopbarLogoField>(TopbarLogoField.EMPTY);
    const [bannerImage, setBannerImage] = useState<BannerField>(BannerField.EMPTY);
    const [communityLogo, setCommunityLogo] = useState<LogoField>(LogoField.EMPTY);
    const [customFavicon, setCustomFavicon] = useState<ImageField>(ImageField.EMPTY);
    const [fileValidationErrors, setFileValidationErrors] = useState<Record<string, string>>({});
    const [fileUploadingStatus, setFileUploadingStatus] = useState<Map<string, boolean>>(new Map());
    const trackCurrentUploadFiles = useRef<Record<string, number>>({});

    useEffect(() => {
        setTopbarLogo(homeConfig.getTopbarLogoField(CommunityEditableFieldType.TOPBAR_LOGO));
        setBannerImage(homeConfig.getBannerField(CommunityEditableFieldType.BANNER_IMAGE));
        setCommunityLogo(homeConfig.getLogoField(CommunityEditableFieldType.COMMUNITY_LOGO));
        setCustomFavicon(homeConfig.getImageField(CommunityEditableFieldType.CUSTOM_FAVICON));
    }, [homeConfig]);

    const {data: libraryImages = []} = useQuery([QUERY_KEYS.COMMUNITY_LIBRARY_IMAGES], () => fetchCommunityLibraryImages(), {
        staleTime: DEFAULT_STALE_TIME,
        cacheTime: 0,
        onError: (error: any) => {
            handleErrorResponse(error);
        }
    });

    const {data: galleryImages = []} = useQuery([QUERY_KEYS.COMMUNITY_GALLERY_IMAGES], () => fetchCommunityBannerImages(), {
        staleTime: DEFAULT_STALE_TIME,
        cacheTime: 0,
        onError: (error: any) => {
            handleErrorResponse(error);
        }
    });

    const getFileInfo = (filename: string | undefined, uploadedFileResponse: UploadedFileResponse[]) => {
        return uploadedFileResponse.find(file => file.filename === filename)?.file;
    }

    const updateImageOperations = () => {
        if (topbarLogo !== homeConfig.getTopbarLogoField(CommunityEditableFieldType.TOPBAR_LOGO)) {
            const topbarLogoData = clone(topbarLogo);
            if (topbarLogoData?.topbarLogoUrl !== undefined && topbarLogoData?.fileId === undefined) {
                topbarLogoData.fileId = 0;
            }
            if (topbarLogoData?.fileId === 0) {
                topbarLogoData.topbarLogoAltText = undefined;
            }
            const command = new UpdateTopbarLogoCommand(communityHomeEditor, topbarLogoData);
            commandExecutor.execute(command);
            validationErrors.clearError(command.getType());
        }

        if (bannerImage !== homeConfig.getBannerField(CommunityEditableFieldType.BANNER_IMAGE)) {
            const command = new UpdateBannerImageCommand(communityHomeEditor, bannerImage);
            commandExecutor.execute(command);
            validationErrors.clearError(command.getType());
        }

        if (communityLogo !== homeConfig.getLogoField(CommunityEditableFieldType.COMMUNITY_LOGO)) {
            const command = new UpdateCommunityLogoCommand(communityHomeEditor, communityLogo);
            commandExecutor.execute(command);
            validationErrors.clearError(command.getType());
        }

        if (customFavicon !== homeConfig.getImageField(CommunityEditableFieldType.CUSTOM_FAVICON)) {
            const command = new UpdateCustomFaviconCommand(communityHomeEditor, customFavicon);
            commandExecutor.execute(command);
            validationErrors.clearError(command.getType());
        }
        toggle();
    };

    const onClick = async () => {
        const uploadFileRequest = new UploadFileRequest([]);
        let index = 0;
        trackCurrentUploadFiles.current = {};
        setFileValidationErrors({});

        if (topbarLogo.fileId <= 0 && topbarLogo.filename?.length) {
            uploadFileRequest.publicFolder = true;
            uploadFileRequest.files.push({filename: topbarLogo.filename, altText: topbarLogo.topbarLogoAltText ?? ''});
            trackCurrentUploadFiles.current[CommunityEditableFieldType.TOPBAR_LOGO] = index;
            index = index + 1;
        }
        if (bannerImage.fileId <= 0 && bannerImage.filename?.length) {
            uploadFileRequest.files.push({filename: bannerImage.filename, altText: bannerImage.altText ?? ''});
            trackCurrentUploadFiles.current[CommunityEditableFieldType.BANNER_IMAGE] = index;
            index = index + 1;
        }
        if (communityLogo.fileId <= 0 && communityLogo.filename?.length) {
            uploadFileRequest.publicFolder = true;
            uploadFileRequest.files.push({filename: communityLogo.filename, altText: communityLogo.altText ?? ''});
            trackCurrentUploadFiles.current[CommunityEditableFieldType.COMMUNITY_LOGO] = index;
            index = index + 1;
        }
        if (customFavicon.fileId <= 0 && customFavicon.filename?.length) {
            uploadFileRequest.publicFolder = true;
            uploadFileRequest.files.push({filename: customFavicon.filename, altText: customFavicon.altText ?? ''});
            trackCurrentUploadFiles.current[CommunityEditableFieldType.CUSTOM_FAVICON] = index;
        }

        if (uploadFileRequest.files.length > 0) {
            try {
                const response = await communityUploadLibraryFiles(uploadFileRequest);

                if (response) {
                    const topbarFileInfo = getFileInfo(topbarLogo.filename, response)
                    if (topbarFileInfo) {
                        topbarLogo.fileId = topbarFileInfo.id;
                        topbarLogo.url = topbarFileInfo.url;
                        topbarLogo.imageType = ImageType.CUSTOM;
                    }

                    const bannerImageFileInfo = getFileInfo(bannerImage.filename, response);
                    if (bannerImageFileInfo) {
                        bannerImage.fileId = bannerImageFileInfo.id;
                        bannerImage.url = bannerImageFileInfo.url;
                        bannerImage.imageType = ImageType.CUSTOM;
                    }

                    const communityLogoFileInfo = getFileInfo(communityLogo.filename, response);
                    if (communityLogoFileInfo) {
                        communityLogo.fileId = communityLogoFileInfo.id;
                        communityLogo.url = communityLogoFileInfo.url;
                        communityLogo.imageType = ImageType.CUSTOM;
                    }

                    const customFaviconFileInfo = getFileInfo(customFavicon.filename, response);
                    if (customFaviconFileInfo) {
                        customFavicon.fileId = customFaviconFileInfo.id;
                        customFavicon.url = customFaviconFileInfo.url;
                        customFavicon.imageType = ImageType.CUSTOM;
                    }
                }
                updateImageOperations();
            } catch (e: any) {
                if (e?.data?.validationErrors) {
                    setFileValidationErrors(e?.data?.validationErrors);
                } else {
                    handleErrorResponse(e);
                }
            }
        } else {
            updateImageOperations();
        }
    };

    const isFormDirty = () => {
        return (topbarLogo !== homeConfig.getTopbarLogoField(CommunityEditableFieldType.TOPBAR_LOGO)) ||
            (bannerImage !== homeConfig.getBannerField(CommunityEditableFieldType.BANNER_IMAGE)) ||
            (communityLogo !== homeConfig.getLogoField(CommunityEditableFieldType.COMMUNITY_LOGO)) ||
            (customFavicon !== homeConfig.getImageField(CommunityEditableFieldType.CUSTOM_FAVICON));
    };

    const hasErrorInAltText = () => {
        const images = [topbarLogo, bannerImage, communityLogo, customFavicon];
        const hasInjectedAltText = images.some((image) => {
            return image.altText && (isHtmlInject(image.altText) || isJavascriptInject(image.altText));
        });
        const hasFileValidationErrors = Object.keys(fileValidationErrors).length > 0;
        return hasInjectedAltText || hasFileValidationErrors;
    };

    const hasErrorInTopbarLogo = useMemo(() => {
        const {fileId, topbarLogoAltText, topbarLogoUrl} = topbarLogo;
        return (fileId && (isHtmlInject(topbarLogoAltText || '') || isJavascriptInject(topbarLogoAltText || ''))) || isHtmlInject(topbarLogoUrl) || isJavascriptInject(topbarLogoUrl);
    }, [topbarLogo]);

    const getErrorReason = useCallback((type: CommunityEditableFieldType) => {
        const index = trackCurrentUploadFiles.current[type];
        return fileValidationErrors[`files[${index}].altText`];
    }, [fileValidationErrors]);

    const isFileUploading = useCallback(() => {
        return Array.from(fileUploadingStatus.values()).some(val => val);
    }, [fileUploadingStatus]);

    return (
        <Modal className="advanced-settings modal-lg" isOpen={isOpen}
               toggle={toggle}
               autoFocus={true}
               title={localizer.msg('edit-mode.advanced-settings.title')}
               modalHeaderId="advanced-settings-header"
               trapFocus={true}
               aria-labelledby="advanced-settings-header">
            {
                topbarLogo.name === CommunityEditableFieldType.TOPBAR_LOGO
                    ? (
                        <Fragment>
                            <TopBarLogo errorReason={getErrorReason(CommunityEditableFieldType.TOPBAR_LOGO)}
                                        topbarLogo={topbarLogo} setTopbarLogo={setTopbarLogo}
                                        libraryImages={libraryImages} localizer={localizer}
                                        maxFileSizeLimit={maxFileSizeLimit}
                                        updateFileUploadingStatus={setFileUploadingStatus}
                            />

                            <hr/>
                        </Fragment>
                    )
                    : null
            }
            {
                bannerImage.name === CommunityEditableFieldType.BANNER_IMAGE
                    ? (
                        <Fragment>
                            <BannerImage errorReason={getErrorReason(CommunityEditableFieldType.BANNER_IMAGE)}
                                         bannerImage={bannerImage} setBannerImage={setBannerImage}
                                         libraryImages={libraryImages} galleryImages={galleryImages} localizer={localizer}
                                         maxFileSizeLimit={maxFileSizeLimit}
                                         updateFileUploadingStatus={setFileUploadingStatus}/>

                            <hr/>
                        </Fragment>
                    )
                    : null
            }
            {
                communityLogo.name === CommunityEditableFieldType.COMMUNITY_LOGO
                    ? (
                        <Fragment>
                            <CommunityLogo errorReason={getErrorReason(CommunityEditableFieldType.COMMUNITY_LOGO)}
                                           communityLogo={communityLogo} setCommunityLogo={setCommunityLogo}
                                           libraryImages={libraryImages} localizer={localizer}
                                           maxFileSizeLimit={maxFileSizeLimit}
                                           updateFileUploadingStatus={setFileUploadingStatus}/>

                            <hr/>
                        </Fragment>
                    )
                    : null
            }
            {
                customFavicon.name === CommunityEditableFieldType.CUSTOM_FAVICON
                    ? (
                        <Fragment>
                            <CustomFavicon errorReason={getErrorReason(CommunityEditableFieldType.CUSTOM_FAVICON)}
                                           customFavicon={customFavicon} setCustomFavicon={setCustomFavicon}
                                           libraryImages={libraryImages} localizer={localizer}
                                           maxFileSizeLimit={maxFileSizeLimit}
                                           updateFileUploadingStatus={setFileUploadingStatus}/>
                        </Fragment>
                    )
                    : null
            }

            <div className="action-bar mt-4 mb-3 p-0 text-end">
                <Button className="btn btn-cancel" color="cancel" aria-label="Close modal" onClick={toggle}>
                    {localizer.msg('common.actions.cancel')}
                </Button>
                <ActionButton
                    className="btn btn-primary btn-lg"
                    type="button"
                    disabled={!isFormDirty() || isEmpty(bannerImage.url) || hasErrorInAltText() || isFileUploading() || /\.(gif)$/.test(bannerImage.url) || hasErrorInTopbarLogo}
                    loading={false}
                    onClick={onClick}
                >
                    {localizer.msg('common.update')}
                </ActionButton>
            </div>
        </Modal>
    );
};