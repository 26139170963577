import React from 'react';
import {Trans} from 'react-i18next';
import {Localizer, TimeAgo} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {BasicActivityCard} from '../idea/details/BasicActivityCard';
import {AuthorChangeActivity} from 'models/AuthorChangeActivity';

type AuthorChangeStageActivityProps = {
    localizer: Localizer;
    authorChangeActivity: AuthorChangeActivity;
}

export const AuthorChangeStageActivity = (props: AuthorChangeStageActivityProps) => {
    const {localizer, authorChangeActivity} = props;
    const {authentication: {actor}} = useAppContext();

    return (
        <div className="pb-3">
            <BasicActivityCard headerTitle={localizer.msg('stage.common.author-changed-title')}
                               headerSummary={
                                   <>
                                       <Trans i18nKey="stage.common.author-change" values={{
                                           initialMember: authorChangeActivity.oldMember.name,
                                           newMember: authorChangeActivity.newMember.name,
                                           member: authorChangeActivity.member.id === actor.id ? localizer.msg('stage.common.changed-self') : authorChangeActivity.member.name
                                       }}>
                                           <em>Initial Member</em>
                                           <em>New Member</em>
                                           <strong>Member</strong>
                                           <TimeAgo localizer={localizer} dateISOString={authorChangeActivity.createdAt}/>
                                       </Trans>
                                   </>
                               }/>
        </div>
    );
};