import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Scrollbar, ScrollbarInstance} from '@ideascale/ui';
import {InfiniteScrollLoadMoreButton, Localizer, useApiErrorResponseHandler, useIntersectionObserver} from '@ideascale/commons';
import {LeaderboardList} from 'components/leaderboard/LeaderboardList';
import {LeaderboardSkeleton} from 'components/leaderboard/LeaderboardSkeleton';
import {LeaderboardItem} from 'models/LeaderboardItem';
import {LeaderboardType} from 'models/enums/LeaderboardType';
import {LeaderboardParameters} from 'models/types/LeaderboardParameters';
import {TimePeriod} from 'models/enums/TimePeriod';
import {LeaderboardResponse} from 'models/LeaderboardResponse';
import styles from './LeaderboardPagination.module.scss';

type LeaderboardPaginationProps = {
    localizer: Localizer;
    type: LeaderboardType;
    fetchLeaderboard: (parameters: LeaderboardParameters, timePeriod?: { mode: TimePeriod, unit: number }) => Promise<LeaderboardResponse>;
    actorId: number;
    campaignId?: number;
    groupId?: number;
    timePeriod?: { mode: TimePeriod, unit: number };
}

const SHOW_SUMMARY = false;
const PAGE_SIZE = 25;
const LAST_ELEMENT_INDEX = PAGE_SIZE - 1;

export const LeaderboardPagination = (props: LeaderboardPaginationProps) => {
    const {localizer, type, fetchLeaderboard, actorId, campaignId, groupId, timePeriod} = props;
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const pageState = useRef(0);
    const lastItemInPage = useRef<LeaderboardItem | null>(null);
    const hasMore = useRef(false);
    const scrollbarContainer = useRef<ScrollbarInstance>(null);
    const loadMoreButtonRef = useRef<HTMLButtonElement>(null);
    const [enabled, setEnabled] = useState(true);
    const [leaderboardData, setLeaderboardData] = useState<LeaderboardItem[]>([]);
    const [initialLoading, setInitialLoading] = useState(true);

    const loadLeaderboard = useCallback(async (page: number, lastItem: LeaderboardItem | null) => {
        pageState.current = page + 1;
        const params: LeaderboardParameters = {
            leaderboardType: type,
            page: page,
            limit: PAGE_SIZE,
            campaignId: campaignId,
            groupId: groupId,
            rating: lastItem?.rating,
            count: lastItem?.count,
            summary: SHOW_SUMMARY
        };
        try {
            const response = await fetchLeaderboard(params, timePeriod);
            hasMore.current = response.hasMore;
            const lastElement = response.content[LAST_ELEMENT_INDEX];
            if (lastElement) {
                if (actorId === lastElement.leader.id) {
                    lastItemInPage.current = response.content[LAST_ELEMENT_INDEX - 1];
                } else {
                    lastItemInPage.current = lastElement;
                }
            }
            setEnabled(response.enabled);
            setLeaderboardData(prev => prev.concat(response.content));
            setInitialLoading(false);
        } catch(e: any) {
            pageState.current = page;
            handleErrorResponse(e);
        }
    }, [actorId, campaignId, fetchLeaderboard, groupId, handleErrorResponse, timePeriod, type]);

    useIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: () => loadLeaderboard(pageState.current, lastItemInPage.current),
        enabled: hasMore.current,
        options: {
            root: scrollbarContainer.current?.container
        }
    });

    useEffect(() => {
        pageState.current = 0;
        hasMore.current = false;
        lastItemInPage.current = null;
        setInitialLoading(true);
        setLeaderboardData([]);
    }, [campaignId, groupId, timePeriod, type]);

    useEffect(() => {
        if (pageState.current === 0) {
            void loadLeaderboard(pageState.current, lastItemInPage.current);
        }
    }, [loadLeaderboard]);

    return (
        <div className="card panel">
            <div className="card-body p-0 rounded-top">
                <Scrollbar
                    className={`${styles.leaderboardList}`}
                    autoHeight
                    autoHeightMin={55}
                    autoHeightMax={600}
                    ref={scrollbarContainer}>
                    <ul className="list-unstyled mb-0">
                        {
                            initialLoading
                                ? <LeaderboardSkeleton/>
                                : <LeaderboardList type={type} localizer={localizer} items={leaderboardData}
                                                   disabled={!enabled}/>
                        }
                    </ul>
                    <InfiniteScrollLoadMoreButton hasMore={hasMore.current}
                                                  localizer={localizer}
                                                  loading={false}
                                                  onCLick={() => loadLeaderboard(pageState.current, lastItemInPage.current)}
                                                  loadMoreButtonRef={loadMoreButtonRef}/>
                </Scrollbar>
            </div>
        </div>
    );
};