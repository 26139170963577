export class MemberBio {
    static EMPTY = new MemberBio(0, '');

    constructor(
        public memberId: number,
        public content: string,
    ) {

    }
}
