import {useAppContext} from 'contexts/AppContext';
import {useIdeaService} from './useIdeaService';
import {IdeaListViewMode} from 'models/enums/IdeaListViewMode';

export const useIdeaViewMode = (ideaListQueryKey: any = '') => {
    const {setIdeaListViewMode} = useAppContext();
    const {changeViewMode} = useIdeaService();

    const setViewMode = async (viewMode: IdeaListViewMode) => {
        await changeViewMode(viewMode);
        setIdeaListViewMode(viewMode);
    };

    return {setViewMode};
};
