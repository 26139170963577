import React, {useCallback, useEffect, useState} from 'react';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {ParagraphSkeleton, useApiErrorResponseHandler} from '@ideascale/commons';
import {EstimateReport} from './EstimateReport';
import {EstimateOverallReport} from './EstimateOverallReport';
import {ActivityCard} from '../idea/details/ActivityCard';
import {EstimateForm} from './EstimateForm';
import {BaseStageActivityProps} from 'models/types/BaseStageActivityProps';
import {EstimateStageActivity} from 'models/stage-activity/EstimateStageActivity';
import {BaseIdeaStageSummary} from 'models/BaseIdeaStageSummary';
import {SaveEstimateParameters} from 'models/types/SaveEstimateParameters';
import {StageActionResponse} from 'models/StageActionResponse';
import {EstimateStageSummary} from 'models/EstimateStageSummary';
import IconToolsLarge from 'assets/img/icon-tools-large.png';
import IconRightLarge from 'assets/img/icon-right-large.png';
import './EstimateActivity.scss';

type EstimateActivityProps = BaseStageActivityProps & {
    updateIdeaStageSummary: <T extends BaseIdeaStageSummary>(stageSummary: T) => void;
    saveEstimate: (stageId: number, estimateData: SaveEstimateParameters) => Promise<StageActionResponse<EstimateStageActivity, EstimateStageSummary>>;
}

export const EstimateActivity = (props: EstimateActivityProps) => {
    const {
        fetchActivity,
        stageId,
        isCurrentStage,
        headerSummary,
        headerTitle,
        localizer,
        saveEstimate,
        updateIdeaStageSummary
    } = props;
    const [loading, setLoading] = useState(true);
    const [estimateActivity, setEstimateActivity] = useState<EstimateStageActivity>();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});

    const renderActivityHeading = () => {
        if (isCurrentStage) {
            if (estimateActivity?.participationAllowed) {
                return localizer.msg('stage.estimate.estimate-participate');
            } else if (estimateActivity?.viewAllowed) {
                return localizer.msg('Estimates are underway... Here\'s the latest');
            } else {
                return (
                    <>
                        {localizer.msg('stage.estimate.estimate-no-view')}
                        <div className="text-center mt-3">
                            <img src={IconToolsLarge} alt="Hammer and wrench in a cross"/>
                        </div>
                    </>
                );
            }
        } else {
            return (
                <>
                    {localizer.msg('stage.estimate.estimate-completed')}
                    {
                        (!(estimateActivity?.participationAllowed || estimateActivity?.viewAllowed)) &&
                        <div className="text-center mt-3">
                            <img src={IconRightLarge} alt="Checked Icon"/>
                        </div>
                    }
                </>
            );
        }
    };

    const loadEstimateActivity = useCallback(async () => {
        try {
            const activity = await fetchActivity(stageId);
            setEstimateActivity(activity as EstimateStageActivity);
            setLoading(false);
        } catch(e: any) {
           handleErrorResponse(e);
        }
    }, [fetchActivity, handleErrorResponse, stageId]);

    useEffect(() => {
        if (isCurrentStage) {
            loadEstimateActivity().then();
        }
    }, [isCurrentStage, loadEstimateActivity]);

    return (
        <div className="pb-3">
            <ActivityCard containerClass="campaign-stages"
                          headerTitle={headerTitle}
                          headerSummary={
                              <p className="mb-0 mt-3 fw-normal">
                                  {headerSummary}
                              </p>
                          }
                          isCurrentStage={isCurrentStage}
                          svgIconSprite={svgIconPath}
                          defaultOpen={isCurrentStage}
                          onEntered={() => !estimateActivity && loadEstimateActivity().then()}>
                {
                    loading ? (
                        <ParagraphSkeleton rows={10}/>
                    ) : (
                        <div className="estimate-activity-details">
                            <div className="mb-5 font-size-sm">
                                {
                                    renderActivityHeading()
                                }
                            </div>
                            {
                                estimateActivity?.viewAllowed && (
                                    <>
                                        {
                                            estimateActivity.participationAllowed && (
                                                estimateActivity.memberEstimateStatistics ? (
                                                    <EstimateReport localizer={localizer} activity={estimateActivity}/>
                                                ) : (
                                                    isCurrentStage && (
                                                        <>
                                                            <EstimateForm localizer={localizer}
                                                                          stageId={stageId}
                                                                          fundUnit={estimateActivity.fundUnit}
                                                                          setActivity={setEstimateActivity}
                                                                          saveEstimate={saveEstimate}
                                                                          updateIdeaStageSummary={updateIdeaStageSummary}/>
                                                        </>
                                                    )
                                                )
                                            )
                                        }
                                        <>
                                            <EstimateOverallReport localizer={localizer} activity={estimateActivity}/>
                                        </>
                                    </>
                                )
                            }
                        </div>
                    )
                }
            </ActivityCard>
        </div>
    );
};