import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {CollapseExpand} from '@ideascale/ui';
import {
    CLASSIFICATION_NAME,
    ClassificationLabel,
    ClassificationUtil,
    LinkQualifier,
    Localizer
} from '@ideascale/commons';
import {LabelsView} from 'shared/LabelsView';
import {useIdeaLabels} from 'hooks/useIdeaLabels';
import {appLinks} from 'services/AppLinks';
import {QualifierLinkedIdeas} from 'models/QualifierLinkedIdeas';
import {LinkedIdea} from 'models/LinkedIdea';
import svgIconSprite from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import './LinkedIdeas.scss';
import {useAppContext} from 'contexts/AppContext';

type FormLinkedIdeasProps = {
    localizer: Localizer;
    actorMemberId: number;
    linkedIdeas: QualifierLinkedIdeas[];
    linkQualifiers: LinkQualifier;
    unlinkIdea?: (linkedIdea: LinkedIdea, qualifierId: number) => void;
}

export const LinkedIdeas = (props: FormLinkedIdeasProps) => {
    const {localizer, linkedIdeas, unlinkIdea, actorMemberId} = props;
    const {ideaLabelRouteChange} = useIdeaLabels();
    const {communityConfig} = useAppContext();
    const hasLinkedIdeas = () => {
        let totalIdeas = 0;
        linkedIdeas.forEach(qualifiedLinkData => {
            totalIdeas += qualifiedLinkData.ideas.length;
        });
        return totalIdeas > 0;
    };

    return (
        <Fragment>
            {
                hasLinkedIdeas() &&
                <CollapseExpand defaultOpen={true}
                                headerTitle={localizer.msg('ideas.terms.linked.long')}
                                headerIcon="link"
                                headerTag="h3"
                                svgIconSprite={svgIconSprite}
                                containerClass="linked-ideas-container panel-default">
                    <ul className="list-unstyled linked-ideas mb-n3">
                        {
                            linkedIdeas.map((qualifierIdeas, index) => {
                                return (qualifierIdeas.ideas
                                    && qualifierIdeas.ideas.length > 0
                                    && <li key={index} className="ideascale-list-wrapper" data-test-element-id="ideascale-list-wrapper">
                                        <h4 className="h6" data-test-element-id="link-qualifier">{qualifierIdeas.linkQualifier.name}</h4>
                                        <ul className="list-unstyled mb-3">
                                            {
                                                qualifierIdeas.ideas.map(idea => {
                                                    return (
                                                        <li key={idea.id}
                                                            className="d-flex text-break" data-test-element-id="linked-idea">
                                                            <div className="linked-idea-title-container">
                                                                {communityConfig.classificationEnabled && idea.attributes &&
                                                                <ClassificationLabel
                                                                    classification={ClassificationUtil.getAttributeByName(CLASSIFICATION_NAME.EFFECTIVE_CLASSIFICATION, idea.attributes)}
                                                                    extraClasses="align-middle me-1 align-bottom"/>
                                                                }
                                                                <Link className="me-1" to={appLinks.ideaDetails(idea.id)}
                                                                      data-test-element-id="idea-title">
                                                                    {idea.title}
                                                                </Link>
                                                                {
                                                                    idea.labels.length > 0 &&
                                                                    <span className="me-2">
                                                                        <LabelsView onLabelClick={ideaLabelRouteChange} labels={idea.labels}/>
                                                                    </span>
                                                                }

                                                                <span className="single-label text-muted" data-test-element-id="linked-by">
                                                                {localizer.msg('ideas.terms.linked.by')}{' '}
                                                                    {idea.linkedBy.id === actorMemberId ? localizer.msg('common.you') : idea.linkedBy.name}
                                                            </span>
                                                            </div>
                                                            {
                                                                unlinkIdea && idea.deletable &&
                                                                <button
                                                                    className="btn btn-cancel p-1 border-0 fw-normal ms-auto"
                                                                    title={localizer.msg('common.remove')}
                                                                    aria-label={localizer.msg('common.remove')}
                                                                    onClick={() => unlinkIdea(idea, qualifierIdeas.linkQualifier.id)}>
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            }
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </li>);
                            })
                        }
                    </ul>
                </CollapseExpand>
            }
        </Fragment>
    );
};