import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useQueryClient} from 'react-query';
import {eventDispatcher, useIntersectionObserver, useUrlQuery} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {IdeaListMode} from 'models/enums/IdeaListMode';
import {MyIdeaListMode} from 'models/enums/MyIdeaListMode';
import {OrderBy} from 'models/enums/OrderBy';
import {useLocalizer} from 'hooks/useLocalizer';
import {useIdeaService} from 'hooks/useIdeaService';
import {useIdeaViewMode} from 'hooks/useIdeaViewMode';
import {useMessageService} from 'hooks/useMessageService';
import {useIdeaListInfiniteScroll} from 'hooks/useIdeaListInfiniteScroll';
import {useIdeaListMode} from 'hooks/useIdeaListMode';
import {useIdeasPageRouteMatch} from 'hooks/useIdeasPageRouteMatch';
import {useIdeaListContext} from 'contexts/IdeaListContext';
import {useIdeasContext} from 'contexts/IdeasContext';
import {ROUTES} from 'shared/Routes';
import {appLinks} from 'services/AppLinks';
import {IDEA_EVENTS, QUERY_KEYS} from 'constants/AppConstants';
import {IdeaList} from 'components/idea/list/IdeaList';
import {MyIdeasListNavigation} from 'components/idea/list/MyIdeasListNavigation';
import {IdeaListSkeleton} from 'components/idea/list/IdeaListSkeleton';
import {IdeaListSelectedLabel} from 'components/idea/list/IdeaListSelectedLabel';

export const MyIdeasListContainer = () => {
    const {communityConfig: {ideaListViewMode, defaultIdeaListMode}} = useAppContext();
    const localizer = useLocalizer();
    const {setViewMode} = useIdeaViewMode();
    const {routeParams, urlLabel} = useIdeasPageRouteMatch(ROUTES.MY_IDEAS);
    const navigate = useNavigate();
    const urlQuery = useUrlQuery();
    const {orderBy, setOrderBy} = useIdeaListContext();
    const {ideaListData} = useIdeasContext();
    const queryClient = useQueryClient();
    const {getIdeaListModesWithoutRecommended} = useIdeaListMode();
    const [ideaMode, setIdeaMode] = useState<IdeaListMode>(defaultIdeaListMode);
    const [myIdeasMode, setMyIdeasMode] = useState<MyIdeaListMode>(MyIdeaListMode.MY_IDEAS);
    const {
        fetchMembers,
        fetchAssignedOwners,
        emailIdeaAuthor,
        fetchAuthorEmails,
        banMember,
        toggleIdeaFollowed,
        toggleAuthorFollowed,
        toggleCommentingEnabled,
        fetchModerateActions,
        fetchMoreActions,
        fetchIdeaLabels,
        togglePinIdeas,
        getAuthorEmailIdeaContent
    } = useIdeaService();
    const {updateIdeaListData, setIdeaListFilterQueryKey, clearIdeaListCachedData} = useIdeasContext();
    const {fetchRecipients} = useMessageService();
    const ideasModeInitialized = useRef(false);
    const myIdeasModeInitialized = useRef(false);

    const getIdeaListQueryKey = useMemo(() => {
        return [QUERY_KEYS.IDEA_LIST, {
            stageKey: routeParams?.stageKey,
            mode: ideaMode,
            myIdeasMode: myIdeasMode,
            order: orderBy,
            label: urlLabel,
        }];
    }, [ideaMode, myIdeasMode, orderBy, routeParams?.stageKey, urlLabel]);

    useEffect(() => {
        setIdeaListFilterQueryKey(getIdeaListQueryKey);
    }, [getIdeaListQueryKey, setIdeaListFilterQueryKey]);

    const ideaListInfiniteScrollData = useIdeaListInfiniteScroll(getIdeaListQueryKey, ideasModeInitialized.current && myIdeasModeInitialized.current);

    useEffect(() => {
        updateIdeaListData({
            pagedIdeas: ideaListInfiniteScrollData.data,
            totalIdeas: ideaListInfiniteScrollData.data?.pages[0]?.totalIdeas || 0,
            isLoading: ideaListInfiniteScrollData.isLoading,
            refetch: ideaListInfiniteScrollData.refetch,
            hasNextPage: ideaListInfiniteScrollData.hasNextPage || false,
            hasPreviousPage: ideaListInfiniteScrollData.hasPreviousPage || false,
            isFetchingPreviousPage: ideaListInfiniteScrollData.isFetchingPreviousPage,
            isFetchingNextPage: ideaListInfiniteScrollData.isFetchingNextPage,
            fetchNextPage: ideaListInfiniteScrollData.fetchNextPage,
            fetchPreviousPage: ideaListInfiniteScrollData.fetchPreviousPage,
        });
    }, [ideaListInfiniteScrollData.data, ideaListInfiniteScrollData.fetchNextPage, ideaListInfiniteScrollData.fetchPreviousPage, ideaListInfiniteScrollData.hasNextPage, ideaListInfiniteScrollData.hasPreviousPage, ideaListInfiniteScrollData.isFetchingNextPage, ideaListInfiniteScrollData.isFetchingPreviousPage, ideaListInfiniteScrollData.isLoading, ideaListInfiniteScrollData.refetch, updateIdeaListData]);

    const {
        pagedIdeas,
        totalIdeas,
        isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    } = ideaListData;

    const loadMoreButtonRef = useRef<HTMLButtonElement>(null);

    useIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: fetchNextPage,
        enabled: hasNextPage || false,
        options: {
            rootMargin: '400px 0px 0px'
        }
    });

    const onToggleOrder = () => {
        clearIdeaListCachedData();
        const order = orderBy === OrderBy.DESC ? OrderBy.ASC : OrderBy.DESC;
        setOrderBy(order);

        const newUrlQuery = new URLSearchParams(urlQuery);
        newUrlQuery.set(ROUTES.QUERY_PARAMS.ORDER, order);

        routeParams?.memberId &&
        navigate({
            pathname: appLinks.myIdeas(routeParams?.memberId, routeParams?.myIdeasMode, routeParams?.stageKey, routeParams?.mode),
            search: newUrlQuery.toString()
        });
    };

    const onChangeMode = useCallback((mode: IdeaListMode) => {
        setIdeaMode(mode);
        clearIdeaListCachedData();

        routeParams?.memberId &&
        navigate({
            pathname: appLinks.myIdeas(routeParams?.memberId, routeParams?.myIdeasMode, routeParams?.stageKey, mode),
            search: urlQuery.toString()
        });
    }, [clearIdeaListCachedData, navigate, routeParams?.memberId, routeParams?.myIdeasMode, routeParams?.stageKey, urlQuery]);

    const onChangeMyIdeaListMode = useCallback((mode: MyIdeaListMode) => {
        setMyIdeasMode(mode);
        routeParams?.memberId &&
        navigate({
            pathname: appLinks.myIdeas(routeParams?.memberId, mode, routeParams?.stageKey, routeParams?.mode),
            search: urlQuery.toString()
        });
    }, [navigate, routeParams?.memberId, routeParams?.mode, routeParams?.stageKey, urlQuery]);

    useEffect(() => {
        ideasModeInitialized.current = true;
        if (routeParams?.mode && routeParams?.mode !== 'unspecified' && getIdeaListModesWithoutRecommended().includes(routeParams?.mode)) {
            setIdeaMode(routeParams?.mode);
        } else {
            setIdeaMode(defaultIdeaListMode);
        }
    }, [defaultIdeaListMode, getIdeaListModesWithoutRecommended, routeParams?.mode]);

    useEffect(() => {
        myIdeasModeInitialized.current = true;
        if (routeParams?.myIdeasMode && myIdeasMode !== routeParams?.myIdeasMode) {
            setMyIdeasMode(routeParams?.myIdeasMode);
        }
    }, [myIdeasMode, routeParams?.myIdeasMode]);

    useEffect(() => {
        eventDispatcher.addListener(IDEA_EVENTS.DRAFT_IDEA_DELETED, () => {
            if (routeParams?.myIdeasMode === MyIdeaListMode.MY_DRAFTS) {
                queryClient.invalidateQueries(QUERY_KEYS.IDEA_LIST).then();
            }
        });
        return () => {
            eventDispatcher.removeListener(IDEA_EVENTS.DRAFT_IDEA_DELETED);
        };
    }, [routeParams?.myIdeasMode, queryClient]);

    return (
        <div className="idea-list">
            <MyIdeasListNavigation ideaMode={ideaMode} onChangeMode={onChangeMode} orderBy={orderBy}
                                   myIdeasMode={myIdeasMode}
                                   onChangeMyIdeaListMode={onChangeMyIdeaListMode}
                                   toggleOrder={onToggleOrder}
                                   listViewMode={ideaListViewMode} onClickViewMode={setViewMode}/>
            {
                isLoading
                    ? <IdeaListSkeleton currentViewMode={ideaListViewMode}/>
                    : <Fragment>
                        {
                            urlLabel && totalIdeas > 0 &&
                            <IdeaListSelectedLabel labeledIdea={pagedIdeas?.pages[0]?.data[0]} labelKey={urlLabel}
                                                   count={totalIdeas}/>
                        }

                        <IdeaList localizer={localizer}
                                  ideas={pagedIdeas?.pages.map(page => page?.data || []).flat(1)}
                                  listViewMode={ideaListViewMode}
                                  fetchModerateActions={fetchModerateActions}
                                  fetchMoreActions={fetchMoreActions}
                                  fetchIdeaLabels={fetchIdeaLabels}
                                  toggleIdeaFollowed={toggleIdeaFollowed}
                                  toggleAuthorFollowed={toggleAuthorFollowed}
                                  toggleCommentingEnabled={toggleCommentingEnabled}
                                  fetchMembers={fetchMembers}
                                  fetchAssignedOwners={fetchAssignedOwners}
                                  fetchRecipients={fetchRecipients}
                                  emailIdeaAuthor={emailIdeaAuthor}
                                  fetchAuthorEmails={fetchAuthorEmails}
                                  banMember={banMember}
                                  togglePinIdeas={togglePinIdeas}
                                  getAuthorEmailIdeaContent={getAuthorEmailIdeaContent}
                        />

                        {
                            isFetchingNextPage
                                ? <IdeaListSkeleton currentViewMode={ideaListViewMode}/>
                                :
                                hasNextPage &&
                                <div className="text-center">
                                    <button className="btn btn-gray" ref={loadMoreButtonRef}
                                            onClick={() => fetchNextPage()}>
                                        {localizer.msg('idea-list.load-next')}
                                    </button>
                                </div>
                        }
                    </Fragment>
            }
        </div>
    );
};