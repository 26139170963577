import React, {Fragment, useCallback, useMemo, useState} from 'react';
import {PageTheme, useApiErrorResponseHandler, useToggle} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {useLandingPageService} from 'hooks/useService';
import {useTranslation} from 'hooks/useTranslation';
import {ConfigWrapper} from 'components/landing-page/ConfigWrapper';
import {CommonConfigModal} from 'components/landing-page/CommonConfigModal';
import {CommunityLeadersComponent} from 'components/landing-page/CommunityLeadersComponent';
import {TranslationModal} from 'shared/TranslationModal';
import {Component} from 'models/landing-page/Component';
import {UiAttribute} from 'models/landing-page/UiAttribute';
import {LandingPageComponentType} from 'models/enums/landing-page/LandingPageComponentType';
import {TranslationConfig} from 'models/TranslationConfig';
import {TranslationContentType} from 'models/enums/landing-page/TranslationContentType';
import {TranslationFieldType} from 'models/enums/TranslationFieldType';
import {TranslationContentResponse} from 'models/landing-page/TranslationContentResponse';
import {LeaderListComponent} from 'models/landing-page/LeaderListComponent';

type CommunityLeadersContainerProps = {
    showConfig: boolean;
    data: LeaderListComponent;
    theme: PageTheme;
    showTranslationConfig: boolean,
    onComponentEdited: () => void;
    translationLanguageId?: number,
}

export const CommunityLeadersContainer = (props: CommunityLeadersContainerProps) => {
    const {showConfig, data, theme, showTranslationConfig, translationLanguageId, onComponentEdited} = props;
    const [showEditModal, toggleEditModal] = useToggle(false);
    const [showTranslationModal, toggleTranslationModal] = useToggle(false);
    const [leadersConfig, setLeadersConfig] = useState(data);
    const localizer = useLocalizer();
    const {showErrorMessage} = useApiErrorResponseHandler({localizer});
    const landingPageService = useLandingPageService();
    const {getTranslatedContent, saveTranslatedContent} = useTranslation();

    const onEditClicked = useCallback(() => {
        toggleEditModal();
    }, [toggleEditModal]);

    const saveCommunityLeadersData = useCallback(async (component: Component) => {
        if (landingPageService) {
            return landingPageService.saveCommonConfig(component);
        }
        return {
            id: 0,
            visible: false,
            type: LandingPageComponentType.STATISTICS,
            attribute: UiAttribute.EMPTY,
            title: ''
        };
    }, [landingPageService]);

    const getCommunityLeaderboardItems = useCallback(async () => {
        if (landingPageService) {
            return landingPageService.fetchCommunityLeaders();
        }
        return [];
    }, [landingPageService]);

    const onToggleVisibility = async () => {
        const newData = {...leadersConfig};
        newData.visible = !newData.visible;
        await updateCommunityLeadersData(newData);
        onComponentEdited();
    };

    const updateCommunityLeadersData = useCallback(async (component: Component) => {
        try {
            const response = await saveCommunityLeadersData(component);
            if (response) {
                setLeadersConfig(response as LeaderListComponent);
            }
        } catch (e: any) {
            if (e.data && 'validationErrors' in e.data) {
                throw e.data;
            } else {
                showErrorMessage(e);
            }
        }
    }, [saveCommunityLeadersData, showErrorMessage]);

    const onTranslationClicked = useCallback(() => {
        if (translationLanguageId !== undefined) {
            toggleTranslationModal();
        }
    }, [toggleTranslationModal, translationLanguageId]);

    const onTextTranslated = useCallback((response: TranslationContentResponse) => {
        setLeadersConfig(prev => {
            const newData = {...prev};
            if (response.contentType === TranslationContentType.PAGE_COMPONENT_TITLE) {
                newData.title = response.translation;
            } else {
                newData.subtitle = response.translation;
            }
            return newData;
        });
    }, []);

    const translationConfigs = useMemo(() => {
        return theme === PageTheme.CURIE ?
            [
                new TranslationConfig(
                    TranslationContentType.PAGE_COMPONENT_TITLE,
                    leadersConfig.id,
                    TranslationFieldType.TEXT,
                ),
                new TranslationConfig(
                    TranslationContentType.PAGE_COMPONENT_SUBTITLE,
                    leadersConfig.id,
                    TranslationFieldType.TEXT,
                ),
            ] :
            [
                new TranslationConfig(
                    TranslationContentType.PAGE_COMPONENT_TITLE,
                    leadersConfig.id,
                    TranslationFieldType.TEXT,
                ),
            ];
    }, [leadersConfig.id, theme]);

    return (
        <Fragment>
            <ConfigWrapper showTranslationConfig={showTranslationConfig} onTranslationClicked={onTranslationClicked}
                           visible={leadersConfig.visible}
                           toggleVisibility={onToggleVisibility}
                           showConfig={showConfig}
                           localizer={localizer}
                           onEditClicked={onEditClicked}
                           className={`${leadersConfig.attribute?.className} community-leaders-component`}
                           accessibleName={localizer.msg('landing-page.community-leaders')}>
                <CommunityLeadersComponent getLeaderboardItems={getCommunityLeaderboardItems} config={leadersConfig}
                                           theme={theme} editMode={showConfig || showTranslationConfig}/>
            </ConfigWrapper>
            {
                showEditModal &&
                <CommonConfigModal theme={theme} open={showEditModal} toggle={toggleEditModal}
                                   onComponentEdited={onComponentEdited}
                                   configData={leadersConfig} updateData={updateCommunityLeadersData}
                                   localizer={localizer} renderSubtitle={theme === PageTheme.CURIE}/>
            }
            {
                showTranslationModal && translationLanguageId !== undefined &&
                <TranslationModal translationLanguageId={translationLanguageId} localizer={localizer}
                                  open={showTranslationModal}
                                  toggle={toggleTranslationModal}
                                  onTextTranslated={onTextTranslated}
                                  onComponentEdited={onComponentEdited}
                                  translationConfigs={translationConfigs}
                                  getTranslatedContent={getTranslatedContent}
                                  saveTranslatedContent={saveTranslatedContent}/>
            }
        </Fragment>
    );
};