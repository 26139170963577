import React, {Fragment, useRef} from 'react';
import Slider from 'react-slick';
import {uniqueId} from 'lodash';
import {PopoverBody, UncontrolledPopover} from 'reactstrap';
import {Icon, SliderLeftArrow, SliderRightArrow} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {
    Attachment,
    ClassificationAttachmentAttribute,
    ClassificationLabel,
    ClassificationUtil,
    FileAttachment,
    FileAttribute,
    Localizer
} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import './IdeaContentAttachment.scss';

type IdeaContentAttachmentProps = {
    popoverContainerId: string;
    localizer: Localizer;
    attachments: FileAttachment[];
    ideaId: number;
    classification?: ClassificationAttachmentAttribute[]
}

export const IdeaContentAttachment = (props: IdeaContentAttachmentProps) => {
    const {localizer, attachments, ideaId, classification, popoverContainerId} = props;
    const {communityConfig: {classificationEnabled}} = useAppContext();
    const popoverIdRef = useRef(uniqueId(`preview-attachment-${ideaId}-`));

    const hasAttachments = () => {
        return attachments && attachments.length > 0;
    };

    const getFirstImageOfAttachments = () => {
        return attachments?.find(attachment => FileAttachment.isPreviewableImg(attachment.filename));
    };

    const getImageAttachments = () => {
        return attachments.filter(attachment => FileAttachment.isPreviewableImg(attachment.filename));
    };

    const hasImageAttachment = () => {
        return getFirstImageOfAttachments();
    };

    const getFirstAttachment = () => {
        return hasAttachments() ? attachments[0] : FileAttachment.EMPTY;
    };

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true,
        prevArrow: <SliderLeftArrow svgIconPath={svgIconsPath}/>,
        nextArrow: <SliderRightArrow svgIconPath={svgIconsPath}/>,
    };

    return (
        <>
            {
                hasImageAttachment()
                    ?
                    <Fragment>
                        <div className="idea-content-thumbnail-holder overflow-hidden">
                            <img className="idea-thumbnail" src={getFirstImageOfAttachments()?.url}
                                 alt={getFirstImageOfAttachments()?.altText || getFirstImageOfAttachments()?.filename}/>
                            <button className="preview-icon btn" id={popoverIdRef.current}
                                    aria-label="Preview idea attachment">
                                <Icon iconSpritePath={svgIconsPath} fill="#fff" name="magnifying-glass-plus"/>
                            </button>
                        </div>
                        <UncontrolledPopover className="attachment-preview-popover" trigger="legacy" placement="bottom"
                                             container={popoverContainerId}
                                             target={popoverIdRef.current}>
                            <PopoverBody className="p-3" aria-label={localizer.msg('idea.attachment-preview')}>
                                {
                                    getImageAttachments().length > 0
                                        ?
                                        <Slider {...settings}>
                                            {
                                                getImageAttachments().map((image, index) => (
                                                        <div key={index}>
                                                            {
                                                                classificationEnabled &&
                                                                <div className="text-center py-2 mt-4">
                                                                    <ClassificationLabel
                                                                        classification={ClassificationUtil.getAppliedFileAttribute(image.filename, (classification?.flatMap(item => item.fileAttributeValues) as FileAttribute[]))}/>
                                                                </div>
                                                            }
                                                            <img className="img-fluid" src={image.url}
                                                                 alt={image.altText || image.filename}/>
                                                        </div>
                                                    )
                                                )
                                            }
                                        </Slider>
                                        :
                                        <img className="img-fluid" src={getFirstImageOfAttachments()?.url}
                                             alt={getFirstImageOfAttachments()?.altText || getFirstImageOfAttachments()?.filename}/>
                                }
                            </PopoverBody>
                        </UncontrolledPopover>
                    </Fragment>
                    :
                    (FileAttachment.isVideo(getFirstAttachment().filename) || FileAttachment.isAudio(getFirstAttachment().filename)) &&
                    <div className="mb-2 text-center">
                        {
                            Attachment.isVideo(getFirstAttachment().filename) &&
                            <video controls={true} preload="metadata">
                                <source src={getFirstAttachment().url}/>
                                {localizer.msg('unsupported.video')}
                            </video>
                        }
                        {
                            FileAttachment.isAudio(getFirstAttachment().filename) &&
                            <audio src={getFirstAttachment().url} controls={true} preload="metadata">
                                {localizer.msg('unsupported.audio')}
                            </audio>
                        }
                    </div>
            }
        </>
    );
};