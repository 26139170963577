import React from 'react';
import {Link} from 'react-router-dom';
import {Trans} from 'react-i18next';
import {Localizer, MemberLink} from '@ideascale/commons';
import {KudoImage} from 'shared/KudoImage';
import {NotificationData} from 'models/NotificationData';
import {appLinks} from 'services/AppLinks';
import {KudoType} from 'models/enums/KudoType';

type IdeaKudoReceivedNotificationProps = {
    localizer: Localizer;
    actorId: number;
    notification: NotificationData;
}
export const IdeaKudoReceivedNotification = (props: IdeaKudoReceivedNotificationProps) => {
    const {localizer, actorId, notification} = props;
    const actorIsKudosSender = notification.sourceMemberId === actorId;
    const otherMemberId = actorIsKudosSender ? notification.receiverId : notification.sourceMemberId;
    const otherMemberName = actorIsKudosSender ? notification.viewAttributes.kudoReceiverName : notification.viewAttributes.sourceMemberName;
    return (
        <Trans i18nKey={`notification.kudos.idea.${actorIsKudosSender ? 'received' : 'given'}`} values={{
            member: otherMemberName,
            idea: notification.viewAttributes.ideaTitle
        }}>
            <MemberLink id={otherMemberId} identityHidden={notification.identityHidden}/>
            <KudoImage localizer={localizer} kudoType={KudoType.REGULAR} width={18} height={18}/>
            <Link to={appLinks.ideaDetails(notification.ideaId)}/>
        </Trans>
    );
};