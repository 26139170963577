import React, {Fragment} from 'react';
import {useLocalizer} from 'hooks/useLocalizer';
import {useTranslation} from 'hooks/useTranslation';
import {ButtonComponent} from 'components/landing-page/ButtonComponent';
import {Button as ButtonData} from 'models/landing-page/Button';
import {TranslationContentResponse} from 'models/landing-page/TranslationContentResponse';

type ButtonsContainerProps = {
    showConfig: boolean;
    buttons: ButtonData[];
    onUpdateButton: (data: ButtonData) => Promise<void>;
    onTextTranslated: (response: TranslationContentResponse) => void;
    toggleVisibility: (button: ButtonData) => Promise<void>;
    onComponentEdited: () => void;
    showTranslationConfig?: boolean;
    translationLanguageId?: number;
    className?: string;
}

export const ButtonsComponent = (props: ButtonsContainerProps) => {
    const {
        showConfig,
        buttons,
        onUpdateButton,
        showTranslationConfig,
        onTextTranslated,
        translationLanguageId,
        toggleVisibility,
        className,
        onComponentEdited,
    } = props;
    const {getTranslatedContent, saveTranslatedContent} = useTranslation();
    const localizer = useLocalizer();

    return (
        <Fragment>
            <div className={`btn-component d-flex ${className ? className : ''}`}>
                {
                    buttons.map(button => (
                        <ButtonComponent key={button.id} localizer={localizer}
                                         buttonConfig={button}
                                         showConfig={showConfig}
                                         toggleVisibility={toggleVisibility}
                                         updateButtonConfig={onUpdateButton}
                                         onTextTranslated={onTextTranslated}
                                         onComponentEdited={onComponentEdited}
                                         translationLanguageId={translationLanguageId}
                                         showTranslationConfig={showTranslationConfig}
                                         getTranslatedButtonText={getTranslatedContent}
                                         saveTranslatedButtonText={saveTranslatedContent}/>

                    ))
                }
            </div>
        </Fragment>
    );
};