import {plainToClass, Type} from 'class-transformer';
import {NotificationData} from './NotificationData';
import {PagedResponseContent} from './PagedResponseContent';

export class NotificationResponse {
    static EMPTY = new NotificationResponse(PagedResponseContent.nullObject(), 0, 0);

    @Type(() => NotificationData)
    notifications: PagedResponseContent<NotificationData>;

    remainingUnseen: number;

    totalUnseen: number;

    constructor(notifications: PagedResponseContent<NotificationData>, remainingUnseen: number, totalUnseen: number) {
        this.notifications = new PagedResponseContent<NotificationData>(
            plainToClass(NotificationData, notifications.content),
            notifications.hasMore,
            notifications.pageNo,
            notifications.pageSize,
            notifications.totalElements
        );
        this.remainingUnseen = remainingUnseen;
        this.totalUnseen = totalUnseen;
    }
}