import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Localizer} from '@ideascale/commons';
import {ROUTES} from 'shared/Routes';
import {useAppContext} from 'contexts/AppContext';
import {SearchState, useSearchContext} from 'contexts/SearchContext';
import {ArchiveCampaignSearchField} from './ArchiveCampaignSearchField';
import {TimeFrameSearchField} from './TimeFrameSearchField';
import {FilterActionButtons} from './FilterActionButtons';
import {SearchCategory} from 'models/enums/SearchCategory';

type CampaignsSearchFilterProps = {
    localizer: Localizer;
    onClearFilters: () => void;
    onApplyFilters: (params: Partial<SearchState>) => void;
}

export const CampaignsSearchFilter = (props: CampaignsSearchFilterProps) => {
    const {localizer, onClearFilters, onApplyFilters} = props;
    const {authentication: {actor}} = useAppContext();
    const {
        fromDate,
        toDate,
        archiveCampaign: archiveCampaignDefaultValue,
        privateCampaign: privateCampaignDefaultValue,
        setSearchCategory
    } = useSearchContext();
    const navigate = useNavigate();
    const [dates, setDates] = useState<Date[]>([]);
    const [archiveCampaign, setIncludeArchivedCampaigns] = useState(true);
    const [privateCampaign, setPrivateCampaign] = useState(false);

    const onApplySearchFilter = useCallback(() => {
        let fromDate = null;
        let toDate = null;
        if (dates.length === 2) {
            fromDate = dates[0];
            toDate = dates[1];
        }
        onApplyFilters({fromDate, toDate, archiveCampaign, privateCampaign});
    }, [archiveCampaign, dates, onApplyFilters, privateCampaign]);

    const clearLocalStateValues = useCallback(() => {
        setDates([]);
        setIncludeArchivedCampaigns(false);
        setPrivateCampaign(false);
    }, []);

    const onClearAllFiltersClick = useCallback(() => {
        clearLocalStateValues();
        onClearFilters();
    }, [clearLocalStateValues, onClearFilters]);

    const onCancel = useCallback(() => {
        onClearAllFiltersClick();
        setSearchCategory(SearchCategory.ALL);
        navigate(ROUTES.HOME);
    }, [navigate, onClearAllFiltersClick, setSearchCategory]);

    useEffect(() => {
        setDates(fromDate && toDate ? [fromDate, toDate] : []);
        setIncludeArchivedCampaigns(archiveCampaignDefaultValue);
        setPrivateCampaign(privateCampaignDefaultValue);
    }, [archiveCampaignDefaultValue, fromDate, privateCampaignDefaultValue, toDate]);

    return (
        <Fragment>
            <div className="row">
                <div className="col-12 col-lg-4">
                    <TimeFrameSearchField
                        localizer={localizer}
                        onChangeDate={setDates}
                        dates={dates}
                    />
                </div>
                {
                    !actor.isCommunityMember() && (
                        <>
                            <div className="col-12 col-lg-4 d-inline-flex">
                                <ArchiveCampaignSearchField
                                    localizer={localizer}
                                    isChecked={archiveCampaign}
                                    setIsChecked={setIncludeArchivedCampaigns}
                                />
                            </div>
                        </>
                    )
                }
            </div>
            <FilterActionButtons
                localizer={localizer}
                onCancel={onCancel}
                onClear={onClearAllFiltersClick}
                onApply={onApplySearchFilter}
            />
        </Fragment>
    );
};