import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Localizer, PagedResponseContent} from '@ideascale/commons';
import {OptionType} from '@ideascale/ui';
import {CustomFieldOption, SearchState, useSearchContext} from 'contexts/SearchContext';
import {ROUTES} from 'shared/Routes';
import {MemberProfileSearchField} from './MemberProfileSearchField';
import {DependantCustomSearchField} from './DependantCustomSearchField';
import {FilterActionButtons} from './FilterActionButtons';
import {TimeFrameSearchField} from './TimeFrameSearchField';
import {PageParameters} from 'models/types/PageParameters';
import {StageGroup} from 'models/StageGroup';
import {CustomFieldData} from 'models/CustomFieldData';
import {SearchCategory} from 'models/enums/SearchCategory';

type MemberSearchFilterProps = {
    fetchAdvanceSearchProfileFields(pageParameters: PageParameters): Promise<PagedResponseContent<StageGroup>>;
    fetchDependantProfileFields(fieldId: number): Promise<CustomFieldData>;
    localizer: Localizer;
    onClearFilters: () => void;
    onApplyFilters: (params: Partial<SearchState>) => void;
}

export const MemberSearchFilter = (props: MemberSearchFilterProps) => {
    const {
        localizer,
        onClearFilters,
        onApplyFilters,
        fetchAdvanceSearchProfileFields,
        fetchDependantProfileFields
    } = props;
    const {
        fromDate,
        toDate,
        memberCustomFields: memberCustomFieldsDefaultValue,
        setSearchCategory
    } = useSearchContext();
    const navigate = useNavigate();
    const [dates, setDates] = useState<Date[]>([]);
    const [memberCustomFields, setMemberCustomFields] = useState<CustomFieldOption[]>([]);

    const onChangeCustomMemberField = (options: OptionType[] | null) => {
        if (options && options.length > 0) {
            const newValue: CustomFieldOption[] = [];
            options.forEach(option => {
                const found = memberCustomFields.find(field => field.field.value === option.value);
                if (!found) {
                    newValue.push({field: option, values: []});
                } else {
                    newValue.push(found);
                }
            });
            setMemberCustomFields(newValue);
        } else {
            setMemberCustomFields([]);
        }
    };

    const onChangeMemberDependantField = (fieldId: number, options: OptionType[] | null) => {
        const memberField = memberCustomFields.find(field => field.field.value === fieldId);
        if (memberField) {
            const newMemberFields = [...memberCustomFields];
            const index = memberCustomFields.findIndex(iField => iField.field.value === fieldId);
            if (options && options.length > 0) {
                newMemberFields[index] = {...memberField, values: options};
            } else {
                newMemberFields[index] = {...memberField, values: []};
            }
            setMemberCustomFields(newMemberFields);
        }
    };

    const onApplySearchFilter = useCallback(() => {
        let fromDate = null;
        let toDate = null;
        if (dates.length === 2) {
            fromDate = dates[0];
            toDate = dates[1];
        }
        onApplyFilters({fromDate, toDate, memberCustomFields});
    }, [dates, memberCustomFields, onApplyFilters]);

    const clearLocalStateValues = useCallback(() => {
        setDates([]);
        setMemberCustomFields([]);
    }, []);

    const onClearAllFiltersClick = useCallback(() => {
        clearLocalStateValues();
        onClearFilters();
    }, [clearLocalStateValues, onClearFilters]);

    const onCancel = useCallback(() => {
        onClearAllFiltersClick();
        setSearchCategory(SearchCategory.ALL);
        navigate(ROUTES.HOME);
    }, [navigate, onClearAllFiltersClick, setSearchCategory]);

    useEffect(() => {
        setDates(fromDate && toDate ? [fromDate, toDate] : []);
        setMemberCustomFields(memberCustomFieldsDefaultValue);
    }, [fromDate, memberCustomFieldsDefaultValue, toDate]);

    return (
        <>
            <div className="row">
                <div className="col-12 col-lg-4">
                    <TimeFrameSearchField localizer={localizer} dates={dates} onChangeDate={setDates}/>
                </div>
                <div className="col-12 col-lg-4">
                    <MemberProfileSearchField
                        fetchAdvanceSearchProfileFields={fetchAdvanceSearchProfileFields}
                        values={memberCustomFields.map(field => field.field)}
                        onChange={onChangeCustomMemberField}
                    />
                </div>
                {memberCustomFields.map(item => {
                    return (
                        <div className="col-12 col-lg-4 mb-3" key={item.field.value}>
                            <DependantCustomSearchField
                                fieldId={item.field.value}
                                value={item.values}
                                mode="MEMBER"
                                onChangeDependantField={onChangeMemberDependantField}
                                fetchDependantCustomFields={fetchDependantProfileFields}
                            />
                        </div>
                    );
                })}
            </div>
            <FilterActionButtons
                localizer={localizer}
                onCancel={onCancel}
                onClear={onClearAllFiltersClick}
                onApply={onApplySearchFilter}
            />
        </>
    );
};