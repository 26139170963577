import React from 'react';
import {Link} from 'react-router-dom';
import {Localizer} from '@ideascale/commons';
import {AssessmentForm} from 'components/stage-activities/AssessmentForm';
import {KeyDefinitionPanel} from 'components/KeyDefinitionPanel';
import {RatingView} from 'components/stage-activities/RatingView';
import {AssessmentStageRatingActivity} from 'models/stage-activity/AssessmentStageRatingActivity';
import {AssessmentRatingParameters} from 'models/types/AssessmentRatingParameters';
import {appLinks} from 'services/AppLinks';

type AssessmentRatingActivityProps = {
    localizer: Localizer;
    stageLabel: string;
    stageDescription: string;
    activity: AssessmentStageRatingActivity;
    onSaveAssessmentActivity: (questionId: number, parameters: AssessmentRatingParameters) => Promise<void>;
    reloadAssessmentActivity: () => Promise<void>;
}

export const AssessmentRatingActivity = (props: AssessmentRatingActivityProps) => {
    const {
        localizer,
        activity,
        stageDescription,
        stageLabel,
        onSaveAssessmentActivity,
        reloadAssessmentActivity
    } = props;

    const allFieldsAnswered = activity.assessmentRatings.every(assessmentRating => assessmentRating.rate > -1);

    return (
        <div id="assessment" className="assessment">
            <div className="row">
                <div className="col-lg-6 col-12">
                    <strong>{stageLabel}</strong>
                    <p>{stageDescription}</p>
                </div>
                <div className="col-lg-6 col-12">
                    <div className="card panel panel-custom mb-3">
                        <div className="questions card-body panel-body">
                            <ul className="list-unstyled">
                                {activity.assessmentRatings.map((assessmentRating) =>
                                    <li className="question" id={`question-${assessmentRating.questionId}`}
                                        key={assessmentRating.questionId}>
                                        <AssessmentForm
                                            localizer={localizer}
                                            assessmentRating={assessmentRating}
                                            hints={activity.hints}
                                            notesRequired={activity.notesRequired}
                                            onSaveAssessmentActivity={onSaveAssessmentActivity}
                                        />
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                {
                    activity.nextUnassessedIdeaId &&
                    <div className="col-lg-12 text-end">
                        <Link className="btn btn-primary"
                              to={appLinks.ideaDetails(activity.nextUnassessedIdeaId)}>
                            {localizer.msg('pagination.next')}
                        </Link>
                    </div>
                }
                {
                    (!activity.nextUnassessedIdeaId && allFieldsAnswered) &&
                    <div className="col-lg-12 text-end">
                        <button className="btn btn-primary" onClick={reloadAssessmentActivity}>
                            {localizer.msg('stage.assessment.finish')}
                        </button>
                    </div>
                }
            </div>
            {
                activity.hints.length > 0 &&
                <div className="row">
                    <div className="col-12 col-lg-6 offset-lg-6 align-self-end">
                        <KeyDefinitionPanel
                            panelBodyClass="assessment-rating-dfn"
                            heading={localizer.msg('stage.assessment.key')}
                            hints={activity.hints.map(hint => ({
                                    details: hint.hint || '',
                                    key: <RatingView localizer={localizer} rating={hint.rating} zeroStarsAsText/>
                                })
                            )}/>
                    </div>
                </div>
            }
        </div>
    );
};