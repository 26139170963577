import React, {Fragment} from 'react';
import {Localizer} from '@ideascale/commons';
import {ActivityLink} from '../ActivityLink';
import {ActivityStreamData} from 'models/ActivityStreamData';
import {ActionType} from 'models/enums/ActionType';

type IdeaActivityProps = {
    localizer: Localizer;
    action: ActionType.IDEA | ActionType.IDEA_REMOVE | ActionType.MEMBER_FOLLOW_IDEA | ActionType.MEMBER_UN_FOLLOW_IDEA;
    activity: ActivityStreamData;
}

export const IdeaActivity = (props: IdeaActivityProps) => {
    const {localizer, activity, action} = props;

    const renderActivityLabel = () => {
        if (action === ActionType.IDEA) {
            return localizer.msg('profile.activity-stream.idea.posted');
        } else if (action === ActionType.IDEA_REMOVE) {
            return localizer.msg('profile.activity-stream.idea.removed');
        } else if (action === ActionType.MEMBER_FOLLOW_IDEA) {
            return localizer.msg('profile.activity-stream.idea.followed');
        } else if (action === ActionType.MEMBER_UN_FOLLOW_IDEA) {
            return localizer.msg('profile.activity-stream.idea.unfollowed');
        }
    };

    return (
        <Fragment>
            {renderActivityLabel()}{' - '}
            <ActivityLink title={activity.idea.title} ideaId={activity.idea.id}/>
        </Fragment>
    );
};