import {CancelToken} from 'axios';
import {Theme} from '@ideascale/ui';
import {
    AiAssistanceFieldNames,
    AiContentResponse,
    AiImageResponse,
    AttachmentAttributeParameters,
    Campaign,
    CampaignsHolder,
    CampaignStage,
    ChatConfig,
    ClassificationsHolder,
    CommentType,
    Cookies,
    EffectiveClassification,
    EffectiveClassificationRequest,
    EmailIdeaContent,
    EmailRequest,
    EmailResponse,
    FieldAttributeParameters,
    IdeaLabelsHolder,
    LabelData,
    Member,
    MemberDetailData,
    MemberSearchRequest,
    MessageData,
    PrivateMessageRequestParams,
    SuperCommentData,
    SuperCommentResponse,
    TagParameters,
    TagSearchParameters,
    TopbarClassification,
    UnfurledUrl,
} from '@ideascale/commons';
import {AbstractService} from '../AbstractService';
import {CommunityService} from '../CommunityService';
import {serviceLinks} from '../ServiceLinks';
import {PageParameters} from 'models/types/PageParameters';
import {TagData} from 'models/TagData';
import {CustomPageData} from 'models/CustomPageData';
import {Slider} from 'models/Slider';
import {CampaignSubscribeResponse} from 'models/CampaignSubscribeResponse';
import {GroupedActionItem} from 'models/topbar-action-item/GroupedActionItem';
import {NotificationResponse} from 'models/NotificationResponse';
import {DraftIdea} from 'models/DraftIdea';
import {LinkableIdea} from 'models/LinkableIdea';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {Language} from 'models/types/Language';
import {IdeaFormFields} from 'models/IdeaFormFields';
import {SidebarSocialSubscription} from 'models/SidebarSocialSubscription';
import {SidebarCampaign} from 'models/SidebarCampaign';
import {CampaignModuleContent} from 'models/CampaignModuleContent';
import {CampaignSummary} from 'models/CampaignSummary';
import {CampaignGroup} from 'models/CampaignGroup';
import {KudosData} from 'models/KudosData';
import {CampaignActivity} from 'models/CampaignActivity';
import {StageDetails} from 'models/StageDetails';
import {IdeaSummary} from 'models/IdeaSummary';
import {IdeaListMode} from 'models/enums/IdeaListMode';
import {OrderBy} from 'models/enums/OrderBy';
import {IdeaSearchParameters} from 'models/types/IdeaSearchParameters';
import {SidebarItem} from 'models/sidebar/SidebarItem';
import {IdeaSubmissionRequest} from 'models/IdeaSubmissionRequest';
import {ModifiedIdeaDetail} from 'models/ModifiedIdeaDetail';
import {PageResponseIdeaSummary} from 'models/PageResponseIdeaSummary';
import {CampaignDetailsData} from 'models/CampaignDetailsData';
import {CampaignTeamData} from 'models/CampaignTeamData';
import {OwnedIdeasData} from 'models/OwnedIdeasData';
import {CommunityMembershipsData} from 'models/CommunityMembershipsData';
import {ProfileSidebarResponse} from 'models/ProfileSidebarResponse';
import {FollowingResponse} from 'models/FollowingResponse';
import {ProfileFieldData} from 'models/ProfileFieldData';
import {CustomFieldRequestValue, ProfileFieldUpdateRequest} from 'models/types/ProfileFieldUpdateRequest';
import {IdeaModerateActionResponse} from 'models/IdeaModerateActionResponse';
import {IdeaMoreActionResponse} from 'models/IdeaMoreActionResponse';
import {CampaignHeaderData} from 'models/CampaignHeaderData';
import {FollowingData} from 'models/FollowingData';
import {GenericResponse} from 'models/GenericResponse';
import {IdeaOwnerAssignRequest} from 'models/types/IdeaOwnerAssignRequest';
import {VoteActionResponse} from 'models/VoteActionResponse';
import {VoteParameters} from 'models/VoteParameters';
import {KudoActivitySummary} from 'models/KudoActivitySummary';
import {IdeaReportingResponse} from 'models/types/IdeaReportingResponse';
import {IdeaListViewMode} from 'models/enums/IdeaListViewMode';
import {PagedResponseActivityStream} from 'models/PagedResponseActivityStream';
import {MyIdeaListMode} from 'models/enums/MyIdeaListMode';
import {IdeaDetail} from 'models/IdeaDetail';
import {ProfileInboxData} from 'models/ProfileInboxData';
import {ConversationThreadData} from 'models/ConversationThreadData';
import {FollowedIdea} from 'models/types/FollowedIdea';
import {RevisionHistory} from 'models/RevisionHistory';
import {OriginalIdeaData} from 'models/OriginalIdeaData';
import {FileAttachmentsHolder} from 'models/FileAttachmentsHolder';
import {AttachmentParameters} from 'models/AttachmentParameters';
import {QualifierLinkedIdeas} from 'models/QualifierLinkedIdeas';
import {LinkableIdeasRequest} from 'models/LinkableIdeasRequest';
import {IdeaActivities} from 'models/IdeaActivities';
import {ReviewscaleStageActivity} from 'models/stage-activity/ReviewscaleStageActivity';
import {FundStageActivity} from 'models/FundStageActivity';
import {BuildTeamStageActivity} from 'models/BuildTeamStageActivity';
import {StageActionResponse} from 'models/StageActionResponse';
import {TeamRoleData} from 'models/TeamRoleData';
import {BuildTeamStageSummary} from 'models/BuildTeamStageSummary';
import {RefineStageActivity} from 'models/stage-activity/RefineStageActivity';
import {RefineStageSummary} from 'models/RefineStageSummary';
import {StageActivity} from 'models/stage-activity/StageActivity';
import {FundStageSummary} from 'models/FundStageSummary';
import {StageFieldValueParameters} from 'models/types/StageFieldValueParameters';
import {TosData} from 'models/TosData';
import {ReviewNotesResponse} from 'models/stage-activity/ReviewNotesResponse';
import {ReviewscaleStageRankActivity} from 'models/stage-activity/ReviewscaleStageRankActivity';
import {FactorType} from 'models/enums/FactorType';
import {ReviewScaleResponseType} from 'models/enums/ReviewScaleResponseType';
import {BaseIdeaStageSummary} from 'models/BaseIdeaStageSummary';
import {SaveEstimateParameters} from 'models/types/SaveEstimateParameters';
import {EstimateStageActivity} from 'models/stage-activity/EstimateStageActivity';
import {EstimateStageSummary} from 'models/EstimateStageSummary';
import {IdeaLabelActionResponse} from 'models/IdeaLabelActionResponse';
import {CommentsParams} from 'models/comments/CommentsParams';
import {CommentSummary} from 'models/comments/CommentSummary';
import {CommentPermissions} from 'models/comments/CommentPermissions';
import {CommentFormInputs} from 'models/comments/CommentFormInputs';
import {CampaignWorkFlowData} from 'models/CampaignWorkFlowData';
import {IdeaStagesData} from 'models/IdeaStagesData';
import {AttachmentFormInputs} from 'models/comments/AttachmentFormInputs';
import {AssessmentStageRatingActivity} from 'models/stage-activity/AssessmentStageRatingActivity';
import {AssessmentRatingParameters} from 'models/types/AssessmentRatingParameters';
import {AssessmentStageSummary} from 'models/AssessmentStageSummary';
import {AssessmentStageActivity} from 'models/stage-activity/AssessmentStageActivity';
import {SidebarLeaderboardItem} from 'models/SidebarLeaderboardItem';
import {CommentLabelsResponse} from 'models/comments/CommentLabelsResponse';
import {StageCommentSummary} from 'models/comments/StageCommentSummary';
import {CommentVoteResponse} from 'models/comments/CommentVoteResponse';
import {CommentVoteRequest} from 'models/comments/CommentVoteRequest';
import {TimePeriod} from 'models/enums/TimePeriod';
import {LeaderboardParameters} from 'models/types/LeaderboardParameters';
import {MemberGroup} from 'models/types/MemberGroup';
import {FooterData} from 'models/FooterData';
import {StageCommentPermissions} from 'models/comments/StageCommentPermissions';
import {StageCommentFormInputs} from 'models/comments/StageCommentFormInputs';
import {MemberSearchParams} from 'models/types/MemberSearchParamaters';
import {SearchedMember} from 'models/SearchedMember';
import {CampaignSearchParameters} from 'models/types/CampaignSearchParameters';
import {CampaignSearchItem} from 'models/CampaignSearchItem';
import {StagesHolder} from 'models/StagesHolder';
import {StageGroup} from 'models/StageGroup';
import {SearchParameters} from 'models/SearchParameters';
import {CustomFieldData} from 'models/CustomFieldData';
import {AdminModeratorData} from 'models/AdminModeratorData';
import {TagStatsHolder} from 'models/TagStatsHolder';
import {SidebarCustomWidget} from 'models/SidebarCustomWidget';
import {LeaderboardResponse} from 'models/LeaderboardResponse';
import {IdeaTeamMember} from 'models/IdeaTeamMember';
import {MessageReplyData} from 'models/MessageReplyData';
import {OriginalComment} from 'models/comments/OriginalComment';
import {MemberProfileAndFieldValuesHolder} from 'models/MemberProfileAndFieldValuesHolder';
import {MemberProfileAndFieldRequestParameters} from 'models/MemberProfileAndFieldRequestParameters';
import {ReleasedFeatureHolder} from 'models/new-features/ReleasedFeatureHolder';
import {Feature} from 'models/new-features/Feature';
import {GoogleAnalyticsDimensions} from 'models/GoogleAnalyticsDimensions';
import {IdeaInternalShareRequestParams} from 'models/IdeaInternalShareRequestParams';
import {MemberBio} from 'models/MemberBio';
import {TourStep} from 'models/TourStep';
import {SharingMedia} from 'models/SharingMedia';
import {DigestEmailRequestParams} from 'models/DigestEmailRequestParams';
import {DigestShareData} from 'models/DigestShareData';
import {DigestInternalShareRequestParams} from 'models/DigestInternalShareRequestParams';
import {DigestResponseContent} from 'models/DigestResponseContent';
import {InviteFriendResponse} from 'models/InviteFriendResponse';
import {InviteFriendRequest} from 'models/InviteFriendRequest';
import {IdeaOwnerAssignmentResponse} from 'models/IdeaOwnerAssignmentResponse';
import {ProfileFieldModalStatus} from 'models/ProfileFieldModalStatus';
import {FollowerInfo} from 'models/types/FollowerInfo';
import {AI_IMAGES_NUMBER} from 'constants/AppConstants';

export class CommunityServiceImpl extends AbstractService implements CommunityService {
    async fetchTopBarCampaigns(pageParameters: PageParameters): Promise<PagedResponseContent<CampaignsHolder<Campaign>>> {
        return await this.getAsPostAndUnwrap<PagedResponseContent<CampaignsHolder<Campaign>>, PageParameters>(serviceLinks.topBarCampaigns(), {
            term: pageParameters.term || '',
            page: pageParameters.page,
            limit: pageParameters.limit || 10
        });
    }

    async fetchSliderData(data: PageParameters): Promise<Slider> {
        return await this.getAsPostAndUnwrap<Slider, PageParameters>(serviceLinks.slider(), data, undefined, Slider);
    }

    async subscribeCampaign(id: number): Promise<CampaignSubscribeResponse> {
        return await this.postAndUnwrap(serviceLinks.subscribeCampaign(id), undefined, undefined, CampaignSubscribeResponse);
    }

    async unsubscribeCampaign(id: number): Promise<CampaignSubscribeResponse> {
        return await this.postAndUnwrap(serviceLinks.unsubscribeCampaign(id), undefined, undefined, CampaignSubscribeResponse);
    }

    async followTag(name: string): Promise<FollowingResponse> {
        return await this.postAndUnwrap(serviceLinks.followTag(name), undefined, undefined, FollowingResponse);
    }

    async unfollowTag(name: string): Promise<FollowingResponse> {
        return await this.postAndUnwrap(serviceLinks.unfollowTag(name), undefined, undefined, FollowingResponse);
    }

    async fetchFollowingTag(name: string): Promise<FollowingData> {
        return await this.getAndUnwrap(serviceLinks.fetchFollowingTag(name));
    }

    async fetchCampaignFollows(campaignId: number): Promise<FollowingData> {
        return await this.getAndUnwrap(serviceLinks.fetchCampaignFollows(campaignId));
    }

    async fetchCampaignFollowers(campaignId: number, sort: string, pageParams: PageParameters): Promise<PagedResponseContent<FollowerInfo>> {
        return await this.postAndUnwrap(serviceLinks.fetchCampaignFollowers(campaignId), {
            term: pageParams.term,
            sortOrder: sort,
            pageParameters: {
                page: pageParams.page,
                limit: pageParams.limit || 10,
            }
        });
    }

    async fetchTopBarMessages(): Promise<MessageData> {
        return await this.getAndUnwrap<MessageData>(serviceLinks.topBarMessages(), undefined);
    }

    async changeIdeaListViewMode(viewMode: IdeaListViewMode): Promise<void> {
        return await this.postAndUnwrap(serviceLinks.ideaListViewMode(viewMode));
    }

    async fetchTopBarCustomPages(pageParameters: PageParameters): Promise<PagedResponseContent<CustomPageData>> {
        return await this.getAsPostAndUnwrap<PagedResponseContent<CustomPageData>, PageParameters>(serviceLinks.topBarCustomPages(), {
            page: pageParameters.page,
            limit: pageParameters.limit || 10
        });
    }

    async fetchTopBarLabels(pageParameters: PageParameters): Promise<PagedResponseContent<LabelData>> {
        return await this.getAsPostAndUnwrap<PagedResponseContent<LabelData>, PageParameters>(serviceLinks.topBarLabels(), {
            page: pageParameters.page,
            limit: 30
        });
    }

    async fetchTopbarActionItems(): Promise<GroupedActionItem[]> {
        return await this.getAndUnwrap<GroupedActionItem[]>(serviceLinks.topbarActionItems(), undefined, GroupedActionItem as any);
    }

    async fetchTopbarNotifications(groupId: number): Promise<NotificationResponse> {
        const {
            notifications,
            remainingUnseen,
            totalUnseen
        } = await this.getAndUnwrap<NotificationResponse>(serviceLinks.topbarNotifications(groupId));
        return new NotificationResponse(notifications, remainingUnseen, totalUnseen);
    }

    async fetchIdeas(campaignId: 'active' | 'expired' | string, stageKey: string, mode: IdeaListMode, order: OrderBy, ideaSearchParameters: IdeaSearchParameters, myIdeasMode: MyIdeaListMode): Promise<PagedResponseContent<IdeaSummary>> {
        const pageParameters = {
            page: ideaSearchParameters.pageParameters.page,
            limit: 15,
        };

        if (!myIdeasMode) {
            return await this.getAsPostAndUnwrap(serviceLinks.ideas(campaignId, stageKey, mode, order), {
                tag: ideaSearchParameters.tag,
                moderatorTag: ideaSearchParameters.moderatorTag,
                labelKey: ideaSearchParameters.labelKey,
                applicableIdeasOnly: ideaSearchParameters.applicableIdeasOnly,
                pageParameters
            }, undefined, PageResponseIdeaSummary);
        } else {
            return await this.getAsPostAndUnwrap(serviceLinks.myIdeas(myIdeasMode, mode, stageKey, order), {
                labelKey: ideaSearchParameters.labelKey,
                pageParameters
            }, undefined, PageResponseIdeaSummary);
        }
    }

    async fetchSidebarTags(campaignId?: number, stageId?: number, limit?: number): Promise<TagStatsHolder> {
        return await this.getAsPostAndUnwrap(serviceLinks.fetchSidebarTags(), {
            campaignId: campaignId,
            stageId: stageId,
            limit: limit || 30
        });
    }

    async fetchTags(pageParameters: PageParameters, campaignId?: number, stageId?: number, term?: string): Promise<PagedResponseContent<TagData>> {
        return await this.getAsPostAndUnwrap(serviceLinks.fetchTags(), {
            campaignId: campaignId,
            stageId: stageId,
            term: pageParameters.term,
            page: pageParameters.page,
            limit: pageParameters.limit || 100
        });
    }

    async fetchModeratorTags(pageParameters: PageParameters, campaignId?: number, stageId?: number): Promise<PagedResponseContent<TagData>> {
        return await this.getAsPostAndUnwrap(serviceLinks.fetchModeratorTags(), {
            campaignId: campaignId,
            stageId: stageId,
            page: pageParameters.page,
            limit: pageParameters.limit || 100
        });
    }

    async fetchSidebarSocialShare(): Promise<SharingMedia[]> {
        return await this.getAndUnwrap(serviceLinks.fetchSidebarSocialShare(), undefined);
    }

    async fetchActiveCampaignGroups(pageParameters: PageParameters): Promise<PagedResponseContent<CampaignGroup>> {
        return await this.getAsPostAndUnwrap<PagedResponseContent<CampaignGroup>, PageParameters>(serviceLinks.activeCampaignGroups(), {
            page: pageParameters.page,
            limit: pageParameters.limit || 10
        });
    }

    async fetchActiveCampaignsByGroup(groupId: number, pageParameters: PageParameters): Promise<PagedResponseContent<SidebarCampaign>> {
        return await this.getAsPostAndUnwrap<PagedResponseContent<SidebarCampaign>, PageParameters>(serviceLinks.activeCampaignsByGroup(groupId), {
            page: pageParameters.page,
            limit: pageParameters.limit || 10
        });
    }

    async fetchSidebarActiveCampaignModule(pageParameters: PageParameters): Promise<CampaignModuleContent> {
        return await this.getAndUnwrap<CampaignModuleContent>(serviceLinks.sidebarActiveCampaignModule(), undefined, CampaignModuleContent);
    }

    async fetchSidebarCampaignSummary(id: number): Promise<CampaignSummary> {
        return await this.getAndUnwrap<CampaignSummary>(serviceLinks.sidebarCampaignSummary(id), undefined, CampaignSummary);
    }

    async fetchArchivedCampaignGroups(pageParameters: PageParameters): Promise<PagedResponseContent<CampaignGroup>> {
        return await this.getAsPostAndUnwrap<PagedResponseContent<CampaignGroup>, PageParameters>(serviceLinks.archivedCampaignGroups(), {
            page: pageParameters.page,
            limit: pageParameters.limit || 10
        });
    }

    async fetchArchivedCampaignsByGroup(groupId: number, pageParameters: PageParameters): Promise<PagedResponseContent<SidebarCampaign>> {
        return await this.getAsPostAndUnwrap<PagedResponseContent<SidebarCampaign>, PageParameters>(serviceLinks.archivedCampaignsByGroup(groupId), {
            page: pageParameters.page,
            limit: pageParameters.limit || 10
        });
    }

    async fetchSidebarArchivedCampaignModule(pageParameters: PageParameters): Promise<CampaignModuleContent> {
        return await this.getAndUnwrap<CampaignModuleContent>(serviceLinks.sidebarArchivedCampaignModule(), undefined, CampaignModuleContent);
    }

    async fetchSocialSubscription(): Promise<SidebarSocialSubscription> {
        return await this.getAndUnwrap(serviceLinks.fetchSocialSubscription(), undefined, SidebarSocialSubscription);
    }

    async fetchSidebarAdmins(data: PageParameters): Promise<AdminModeratorData> {
        return await this.getAsPostAndUnwrap(serviceLinks.fetchSidebarAdmins(), data);
    }

    async fetchSidebarGlobalModerators(data: PageParameters): Promise<AdminModeratorData> {
        return await this.getAsPostAndUnwrap(serviceLinks.fetchSidebarGlobalModerators(), data);
    }

    async fetchSidebarCampaignModerators(data: PageParameters, campaignId: number): Promise<AdminModeratorData> {
        return await this.getAsPostAndUnwrap(serviceLinks.fetchSidebarCampaignModerators(campaignId), data);
    }

    async fetchKudos(): Promise<KudosData> {
        return await this.getAndUnwrap(serviceLinks.fetchKudos());
    }

    async fetchRecipientsByCampaign(parameters: PageParameters, campaignId: number): Promise<PagedResponseContent<Member>> {
        return await this.getAsPostAndUnwrap<PagedResponseContent<Member>, PageParameters>(serviceLinks.recipientsByCampaign(campaignId), {
            term: parameters.term || '',
            page: parameters.page,
            limit: parameters.limit || 10
        });
    }

    async fetchCampaignActivity(campaignId: number): Promise<CampaignActivity> {
        return await this.getAndUnwrap(serviceLinks.fetchCampaignActivity(campaignId), undefined, CampaignActivity);
    }

    async fetchCampaign(id: number): Promise<Campaign> {
        return await this.getAndUnwrap(serviceLinks.campaignSummary(id));
    }

    async fetchAllowedGroupedCampaigns(pageParameters: PageParameters): Promise<PagedResponseContent<CampaignsHolder<Campaign>>> {
        return this.getAsPostAndUnwrap(serviceLinks.allowedGroupedCampaigns(), {
            term: pageParameters.term || '',
            page: pageParameters.page,
            limit: pageParameters.limit || 10
        });
    }

    async fetchDrafts(): Promise<DraftIdea[]> {
        return await this.getAndUnwrap(serviceLinks.drafts());
    }

    async deleteDraft(ideaId: number): Promise<void> {
        return await this.deleteAndUnwrap(serviceLinks.deleteDraft(ideaId), undefined);
    }

    async fetchLinkableIdeas(ideaId: number, ideaTitle: string, ideaDescription: string, pageParameters: PageParameters): Promise<PagedResponseContent<LinkableIdea>> {
        return this.getAsPostAndUnwrap(serviceLinks.similarIdeaSearch(ideaId), {
            title: ideaTitle,
            description: ideaDescription,
            pageParameters
        });
    }

    async fetchLinkableIdeasByKeywords(ideaId: number, pageParameters: PageParameters): Promise<PagedResponseContent<LinkableIdea>> {
        return this.getAsPostAndUnwrap(serviceLinks.similarIdeaSearchByKeyword(ideaId), {
            term: pageParameters.term,
            pageParameters
        });
    }

    async saveLinkableIdeas(ideaId: number, linkedIdeas: QualifierLinkedIdeas[]): Promise<QualifierLinkedIdeas[]> {
        const requestBody: LinkableIdeasRequest[] = linkedIdeas.map(qualifierIdeas => ({
            linkQualifierId: qualifierIdeas.linkQualifier.id,
            ideaIds: qualifierIdeas.ideas.map(idea => idea.id)
        }));
        return this.postAndUnwrap(serviceLinks.linkIdeas(ideaId), requestBody);
    }

    async unlinkIdea(ideaId: number, linkedIdeaId: number, qualifierId: number): Promise<void> {
        return this.deleteAndUnwrap(serviceLinks.unlinkIdea(ideaId), {linkedIdeaId, linkQualifierId: qualifierId});
    }

    async fetchSuperComments(ideaId: number, pageParameters: PageParameters): Promise<SuperCommentResponse> {
        return await this.getAsPostAndUnwrap(serviceLinks.superComments(ideaId), {...pageParameters});
    }

    async deleteSuperComment(commentId: number, commentType: CommentType): Promise<void> {
        return await this.postAndUnwrap(serviceLinks.deleteSuperComment(commentId, commentType));
    }

    async reorderSuperComments(ideaId: number, orderedIds: number[]): Promise<void> {
        return await this.postAndUnwrap(serviceLinks.reorderSuperComments(ideaId), {superCommentIds: orderedIds});
    }

    async fetchAtMentionMembers(parameters: MemberSearchRequest): Promise<Member[]> {
        const {content} = await this.getAsPostAndUnwrap<PagedResponseContent<Member>, MemberSearchRequest>(serviceLinks.atMentionMembers(), parameters);
        return content;
    }

    async fetchIdeaFormLabels(ideaId: number): Promise<LabelData[]> {
        return await this.getAndUnwrap(serviceLinks.ideaFormLabels(ideaId));
    }

    async fetchIdeaFormFieldsByCampaignId(campaignId: number): Promise<IdeaFormFields> {
        let link = serviceLinks.emptyFormFields();
        if (campaignId) {
            link = serviceLinks.campaignFormFields(campaignId);
        }
        return await this.getAndUnwrap(link);
    }

    async fetchIdeaFormFieldsByIdeaId(ideaId: number): Promise<IdeaFormFields> {
        return await this.getAndUnwrap(serviceLinks.ideaFormFields(ideaId));
    }

    async fetchCopyIdeaFormFieldsByIdeaId(ideaId: number): Promise<IdeaFormFields> {
        return await this.getAndUnwrap(serviceLinks.copyIdeaFormFields(ideaId));
    }

    async createIdea(submissionRequest: IdeaSubmissionRequest): Promise<IdeaDetail> {
        return this.postAndUnwrap(serviceLinks.createIdea(), submissionRequest, undefined, IdeaDetail);
    }

    async modifyIdea(ideaId: number, submissionRequest: IdeaSubmissionRequest): Promise<ModifiedIdeaDetail> {
        return this.postAndUnwrap(serviceLinks.modifyIdea(ideaId), submissionRequest, undefined, ModifiedIdeaDetail);
    }

    async fetchIdeaDetails(ideaId: number, cancelToken?: CancelToken): Promise<IdeaDetail> {
        return this.getAndUnwrap(serviceLinks.ideaDetails(ideaId), {
            cancelToken
        }, IdeaDetail);
    }

    async fetchContributors(parameters: MemberSearchRequest): Promise<PagedResponseContent<MemberDetailData>> {
        return this.getAsPostAndUnwrap(serviceLinks.ideaContributorSearch(), parameters);
    }

    async fetchOnBehalfMembers(parameters: MemberSearchRequest): Promise<PagedResponseContent<MemberDetailData>> {
        return this.getAsPostAndUnwrap(serviceLinks.ideaOnBehalfOfSearch(), parameters);
    }

    async detectLanguage(text: string): Promise<Language> {
        return this.getAsPostAndUnwrap(serviceLinks.detectLanguage(), {
            text
        });
    }

    async fetchTagsByTerm(pageParams: TagSearchParameters): Promise<PagedResponseContent<string>> {
        return await this.getAsPostAndUnwrap(serviceLinks.searchTags(), pageParams);
    }

    async fetchModeratorTagsByTerm(pageParams: PageParameters): Promise<PagedResponseContent<string>> {
        return await this.getAsPostAndUnwrap(serviceLinks.searchModeratorTags(), pageParams);
    }

    async saveTags(ideaId: number, tagValues: string[]): Promise<string[]> {
        return this.postAndUnwrap(serviceLinks.saveTags(ideaId), {tagValues});
    }

    async saveModeratorTags(ideaId: number, tagValues: string[]): Promise<string[]> {
        return this.postAndUnwrap(serviceLinks.saveModeratorTags(ideaId), {tagValues});
    }

    async fetchSuggestedTags(tagParams: TagParameters): Promise<string[]> {
        return await this.getAsPostAndUnwrap(serviceLinks.suggestedTags(), tagParams);
    }

    async fetchSidebarStages(campaignId: number, stageKey?: string, tag?: string, moderatorTag?: string, labelKey?: string): Promise<StageDetails> {
        return await this.getAsPostAndUnwrap(serviceLinks.sidebarStages(campaignId), {
            campaignId: campaignId,
            stageKey: stageKey,
            tag: tag,
            moderatorTag: moderatorTag,
            labelKey: labelKey
        });
    }

    async fetchCommunitySidebarItems(): Promise<SidebarItem[]> {
        return await this.getAndUnwrap(serviceLinks.communitySidebar());
    }

    async fetchCampaignSidebarItems(campaignId: number): Promise<SidebarItem[]> {
        return await this.getAndUnwrap(serviceLinks.campaignSidebar(campaignId));
    }

    async fetchCampaignHeader(campaignId: number): Promise<CampaignHeaderData> {
        return await this.getAndUnwrap(serviceLinks.fetchCampaignHeader(campaignId));
    }

    async fetchRecipients(pageParameters: PageParameters): Promise<PagedResponseContent<Member>> {
        return await this.getAsPostAndUnwrap<PagedResponseContent<Member>, PageParameters>(serviceLinks.recipients(), {
            term: pageParameters.term,
            page: pageParameters.page,
            limit: pageParameters.limit || 10
        }, undefined);
    }

    async fetchCampaignDetails(campaignId: number): Promise<CampaignDetailsData> {
        return await this.getAndUnwrap(serviceLinks.fetchCampaignDetails(campaignId));
    }

    async fetchCampaignTeamMembers(campaignId: number): Promise<CampaignTeamData> {
        return await this.getAndUnwrap(serviceLinks.fetchCampaignTeamMembers(campaignId));
    }


    async fetchOwnedIdeas(memberId: number, pageParams: PageParameters): Promise<PagedResponseContent<OwnedIdeasData>> {
        return await this.getAsPostAndUnwrap(serviceLinks.fetchOwnedIdeas(memberId), pageParams);
    }

    async fetchCommunityMemberships(memberId: number, pageParams: PageParameters): Promise<PagedResponseContent<CommunityMembershipsData>> {
        return await this.getAsPostAndUnwrap(serviceLinks.fetchCommunityMemberships(memberId), pageParams);
    }

    async fetchProfileSidebar(memberId?: number): Promise<ProfileSidebarResponse> {
        return await this.getAndUnwrap(serviceLinks.sidebarProfile(memberId));
    }

    async updateProfileAboutMeSection(parameters: {
        id: number,
        avatar: string,
        firstName: string,
        lastName: string
    }): Promise<ProfileSidebarResponse> {
        return this.postAndUnwrap(serviceLinks.updateProfileAboutMeSection(), parameters);
    }

    async toggleMemberSubscription(memberId: number, follow: boolean): Promise<GenericResponse> {
        return await this.postAndUnwrap(serviceLinks.memberSubscription(memberId, follow));
    }

    async fetchProfileFields(memberId: number): Promise<ProfileFieldData[]> {
        return this.getAndUnwrap(serviceLinks.memberProfileFields(memberId));
    }

    async updateProfileFields(updateRequest: ProfileFieldUpdateRequest, memberId: number): Promise<ProfileSidebarResponse> {
        return this.postAndUnwrap(serviceLinks.updateMemberProfileFields(memberId), updateRequest);
    }

    async fetchProfileNotifications(groupId: number, pageParameters: PageParameters): Promise<NotificationResponse> {
        const {
            notifications,
            remainingUnseen,
            totalUnseen
        } = await this.getAsPostAndUnwrap<NotificationResponse, PageParameters>(serviceLinks.profileNotifications(groupId), pageParameters);
        return new NotificationResponse(notifications, remainingUnseen, totalUnseen);
    }

    async markNotificationsAsSeen(): Promise<void> {
        return await this.postAndUnwrap(serviceLinks.markNotificationsAsSeen());
    }

    async fetchIdeaModerateActions(ideaId: number): Promise<IdeaModerateActionResponse> {
        return await this.getAndUnwrap(serviceLinks.fetchIdeaModerateActions(ideaId), undefined, IdeaModerateActionResponse);
    }

    async fetchIdeaMoreActions(ideaId: number): Promise<IdeaMoreActionResponse> {
        return await this.getAndUnwrap(serviceLinks.fetchIdeaMoreActions(ideaId), undefined, IdeaMoreActionResponse);
    }

    async followIdea(ideaId: number): Promise<GenericResponse> {
        return await this.postAndUnwrap(serviceLinks.followIdea(ideaId), undefined, undefined, GenericResponse);
    }

    async unfollowIdea(ideaId: number): Promise<GenericResponse> {
        return await this.postAndUnwrap(serviceLinks.unfollowIdea(ideaId), undefined, undefined, GenericResponse);
    }

    async enableCommenting(ideaId: number): Promise<void> {
        return await this.postAndUnwrap(serviceLinks.enableCommenting(ideaId));
    }

    async disableCommenting(ideaId: number): Promise<void> {
        return await this.postAndUnwrap(serviceLinks.disableCommenting(ideaId));
    }

    async changeStage(campaignId: number, stageId: number): Promise<IdeaDetail> {
        return await this.postAndUnwrap(serviceLinks.changeStage(campaignId, stageId), undefined, undefined, IdeaDetail);
    }

    async fetchMembers(data: PageParameters, ideaId: number): Promise<PagedResponseContent<Member>> {
        return await this.getAsPostAndUnwrap<PagedResponseContent<Member>, PageParameters>(serviceLinks.fetchMembersToAssignOwner(ideaId), data);
    }

    async fetchIdeaAssignedOwners(ideaId: number): Promise<Member[]> {
        return await this.getAndUnwrap(serviceLinks.fetchIdeaAssignedOwners(ideaId));
    }

    async assignIdeaOwners(data: IdeaOwnerAssignRequest, ideaId: number): Promise<IdeaOwnerAssignmentResponse> {
        return await this.postAndUnwrap(serviceLinks.assignIdeaOwners(ideaId), data);
    }

    async deleteIdea(ideaId: number): Promise<void> {
        return await this.deleteAndUnwrap(serviceLinks.deleteIdea(ideaId));
    }

    async downVote(ideaId: number, voteParameters: VoteParameters): Promise<VoteActionResponse<BaseIdeaStageSummary>> {
        return await this.postAndUnwrap(serviceLinks.vote(ideaId), voteParameters, undefined, VoteActionResponse);
    }

    async upVote(ideaId: number, voteParameters: VoteParameters): Promise<VoteActionResponse<BaseIdeaStageSummary>> {
        return await this.postAndUnwrap(serviceLinks.vote(ideaId), voteParameters, undefined, VoteActionResponse);
    }

    async retractVote(ideaId: number): Promise<VoteActionResponse<BaseIdeaStageSummary>> {
        return await this.postAndUnwrap(serviceLinks.retractVote(ideaId), undefined, undefined, VoteActionResponse);
    }

    async fetchChangeCampaigns(pageParameters: PageParameters): Promise<PagedResponseContent<CampaignsHolder<Campaign>>> {
        return this.getAsPostAndUnwrap(serviceLinks.fetchChangeCampaigns(), {
            term: pageParameters.term || '',
            page: pageParameters.page,
            limit: pageParameters.limit || 10
        });
    }

    async fetchCampaignStages(ideaId: number, campaignId: number): Promise<CampaignStage[]> {
        return await this.getAndUnwrap(serviceLinks.campaignStages(ideaId, campaignId));
    }

    async changeCampaign(ideaId: number, campaignId: number, stageId?: number, isDifferentStage?: boolean, tosAccepted?: boolean): Promise<IdeaDetail> {
        return await this.postAndUnwrap(serviceLinks.changeCampaign(ideaId, campaignId, stageId, isDifferentStage), {campaignTosAccepted: tosAccepted}, undefined, IdeaDetail);
    }

    async giveKudoOnIdea(ideaId: number): Promise<KudoActivitySummary> {
        return await this.postAndUnwrap(serviceLinks.ideaKudo(ideaId));
    }

    async fetchIdeaEmailRecipients(ideaId: number, parameters: PageParameters): Promise<PagedResponseContent<Member>> {
        return await this.getAsPostAndUnwrap(serviceLinks.fetchIdeaEmailRecipients(ideaId), parameters);
    }

    async emailIdeaByRecipientEmails(requestParams: EmailRequest, ideaId: number): Promise<EmailResponse> {
        return await this.postAndUnwrap(serviceLinks.emailIdeaByRecipientEmails(ideaId), requestParams);
    }

    async emailIdeaByRecipientIds(requestParams: EmailRequest, ideaId: number): Promise<void> {
        return await this.postAndUnwrap(serviceLinks.emailIdeaByRecipientIds(ideaId), requestParams);
    }

    async sendDigestEmailViaRecipientEmails(requestParams: DigestEmailRequestParams, campaignId: number): Promise<void> {
        return await this.postAndUnwrap(serviceLinks.digestEmailByRecipientEmails(campaignId), requestParams);
    }

    async sendDigestEmailViaRecipientIds(requestParams: DigestEmailRequestParams, campaignId: number): Promise<void> {
        return await this.postAndUnwrap(serviceLinks.digestEmailByRecipientIds(campaignId), requestParams);
    }

    async emailIdeaAuthor(data: EmailRequest, ideaId: number): Promise<void> {
        return await this.postAndUnwrap(serviceLinks.emailIdeaAuthor(ideaId), data);
    }

    async fetchIdeaAuthorEmails(ideaId: number): Promise<string[]> {
        return await this.getAndUnwrap(serviceLinks.fetchIdeaAuthorEmails(ideaId));
    }

    async banMember(memberId: number): Promise<GenericResponse> {
        return await this.postAndUnwrap(serviceLinks.banMember(memberId));
    }

    async followMember(memberId: number): Promise<GenericResponse> {
        return await this.postAndUnwrap(serviceLinks.followMember(memberId));
    }

    async unfollowMember(memberId: number): Promise<GenericResponse> {
        return await this.postAndUnwrap(serviceLinks.unfollowMember(memberId));
    }

    async pinIdea(ideaId: number): Promise<void> {
        return await this.postAndUnwrap(serviceLinks.pinIdea(ideaId));
    }

    async unpinIdea(ideaId: number): Promise<void> {
        return await this.postAndUnwrap(serviceLinks.unpinIdea(ideaId));
    }

    async updateLabels(ideaId: number, labelIds: number[]): Promise<IdeaLabelsHolder> {
        return await this.postAndUnwrap(serviceLinks.updateLabels(ideaId), {labelIds});
    }

    async reportAbuse(ideaId: number): Promise<IdeaReportingResponse> {
        return await this.postAndUnwrap(serviceLinks.reportAbuse(ideaId));
    }

    async reportDuplicate(ideaId: number): Promise<IdeaReportingResponse> {
        return await this.postAndUnwrap(serviceLinks.reportDuplicate(ideaId));
    }

    async fetchActivityStream(memberId: number, type: string, pageParameters: PageParameters): Promise<PagedResponseActivityStream> {
        return await this.getAsPostAndUnwrap(serviceLinks.activityStream(memberId, type), pageParameters, undefined, PagedResponseActivityStream);
    }

    async fetchInboxMessages(pageParams: PageParameters): Promise<PagedResponseContent<ProfileInboxData>> {
        return await this.getAsPostAndUnwrap(serviceLinks.inboxMessages(), {
            term: pageParams.term || '',
            page: pageParams.page,
            limit: 20
        });
    }

    async fetchSentMessages(pageParams: PageParameters): Promise<PagedResponseContent<ProfileInboxData>> {
        return await this.getAsPostAndUnwrap(serviceLinks.sentMessages(), {
            term: pageParams.term || '',
            page: pageParams.page,
            limit: 20
        });
    }

    async onMemberSendMessages(requestParams: PrivateMessageRequestParams): Promise<void> {
        return await this.postAndUnwrap(serviceLinks.memberSendMessages(), requestParams);
    }

    async onDigestSendMessages(requestParams: DigestInternalShareRequestParams): Promise<void> {
        if (requestParams.campaignId && requestParams.campaignPerformanceParameters) {
            return await this.getAsPostAndUnwrap(serviceLinks.sendDigestMessages(requestParams.campaignId), requestParams);
        }
    }

    async onIdeaSendMessages(requestParams: IdeaInternalShareRequestParams): Promise<void> {
        if (requestParams.ideaId) {
            return await this.postAndUnwrap(serviceLinks.sendIdeaMessages(requestParams.ideaId), requestParams);
        }
    }

    async conversationThreadReply(
        recipientIds: number[],
        body: string,
        subject: string,
        subjectClassificationAttributes: FieldAttributeParameters[],
        conversationThreadId: number
    ): Promise<MessageReplyData> {
        return await this.postAndUnwrap(serviceLinks.conversationThreadReply(conversationThreadId), {
            recipientIds: recipientIds,
            subject: subject,
            body: body,
            attributeApiParameters: subjectClassificationAttributes,
        });
    }

    async trashConversationThread(conversationThreadId: number): Promise<GenericResponse> {
        return await this.postAndUnwrap(serviceLinks.trashConversationThread(conversationThreadId));
    }

    async fetchConversationThread(conversationThreadId: number): Promise<ConversationThreadData> {
        return await this.getAndUnwrap(serviceLinks.conversationThread(conversationThreadId));
    }

    async fetchCountUnread(): Promise<number> {
        return await this.getAndUnwrap(serviceLinks.countUnread());
    }

    async fetchFollowedMembers(pageParameters: PageParameters, memberId: number): Promise<PagedResponseContent<Member>> {
        return await this.getAsPostAndUnwrap<PagedResponseContent<Member>, PageParameters>(serviceLinks.fetchFollowedMembers(memberId), pageParameters);
    }

    async fetchFollowedIdeas(pageParameters: PageParameters, memberId: number): Promise<PagedResponseContent<FollowedIdea>> {
        return await this.getAsPostAndUnwrap<PagedResponseContent<FollowedIdea>, PageParameters>(serviceLinks.fetchFollowedIdeas(memberId), pageParameters);
    }

    async fetchFollowedTags(pageParameters: PageParameters, memberId: number): Promise<PagedResponseContent<string>> {
        return await this.getAsPostAndUnwrap<PagedResponseContent<string>, PageParameters>(serviceLinks.fetchFollowedTags(memberId), pageParameters);
    }

    async fetchFollowedCampaignGroups(pageParameters: PageParameters, memberId: number): Promise<PagedResponseContent<CampaignGroup>> {
        return await this.getAsPostAndUnwrap<PagedResponseContent<CampaignGroup>, PageParameters>(serviceLinks.fetchFollowedCampaignGroups(memberId), pageParameters);
    }

    async fetchFollowedCampaignByGroupId(pageParameters: PageParameters, memberId: number, groupId: number): Promise<PagedResponseContent<Campaign>> {
        return await this.getAsPostAndUnwrap<PagedResponseContent<Campaign>, PageParameters>(serviceLinks.fetchFollowedCampaignByGroupId(memberId, groupId), pageParameters);
    }

    async fetchRevisionHistory(ideaId: number, pageParameters: PageParameters): Promise<PagedResponseContent<RevisionHistory>> {
        return await this.getAsPostAndUnwrap(serviceLinks.fetchRevisionHistory(ideaId), pageParameters);
    }

    async fetchOriginalIdea(ideaId: number): Promise<OriginalIdeaData> {
        return await this.getAndUnwrap(serviceLinks.originalIdea(ideaId));
    }

    async fetchIdeaFollowers(pageParameters: PageParameters, ideaId: number): Promise<PagedResponseContent<Member>> {
        return await this.getAsPostAndUnwrap<PagedResponseContent<Member>, PageParameters>(serviceLinks.ideaFollowers(ideaId), pageParameters);
    }

    async fetchIdeaOwners(pageParameters: PageParameters, ideaId: number): Promise<PagedResponseContent<Member>> {
        return await this.getAsPostAndUnwrap<PagedResponseContent<Member>, PageParameters>(serviceLinks.ideaOwners(ideaId), pageParameters);
    }

    async fetchFund(ideaId: number, stageId: number): Promise<FundStageActivity> {
        return await this.getAndUnwrap(serviceLinks.ideaStageActivity(ideaId, stageId));
    }

    async modifyFundTarget(ideaId: number, amount: number): Promise<StageActionResponse<FundStageActivity, FundStageSummary>> {
        return await this.postAndUnwrap(serviceLinks.fundTarget(ideaId), {
            amount: amount,
        }, undefined, StageActionResponse);
    }

    async modifyFundPledge(ideaId: number, amount: number): Promise<StageActionResponse<FundStageActivity, FundStageSummary>> {
        return await this.postAndUnwrap(serviceLinks.modifyFundPledge(ideaId), {
            amount: amount,
        }, undefined, StageActionResponse);
    }

    async editFundPledge(ideaId: number, stageId: number): Promise<FundStageActivity> {
        return await this.getAndUnwrap(serviceLinks.editFundPledge(ideaId, stageId));
    }

    async fetchIdeaAttachments(ideaId: number): Promise<FileAttachmentsHolder> {
        return await this.getAndUnwrap(serviceLinks.ideaAttachments(ideaId), undefined, FileAttachmentsHolder);
    }

    async deleteIdeaAttachment(ideaId: number, fileId: number): Promise<void> {
        return await this.deleteAndUnwrap(serviceLinks.deleteIdeaAttachment(ideaId, fileId));
    }

    async deleteIdeaFieldAttachment(ideaId: number, fieldId: number, fileName: string): Promise<void> {
        return await this.postAndUnwrap(serviceLinks.deleteIdeaFieldAttachment(ideaId), {fieldId, fileName}, undefined);
    }

    async deleteIdeaStageAttachment(ideaId: number, fieldId: number, fileName: string): Promise<void> {
        return await this.postAndUnwrap(serviceLinks.deleteIdeaStageAttachment(ideaId), {fieldId, fileName}, undefined);
    }

    async saveIdeaAttachments(ideaId: number, attachmentParams: AttachmentParameters): Promise<FileAttachmentsHolder> {
        return await this.postAndUnwrap(serviceLinks.saveIdeaAttachments(ideaId), attachmentParams);
    }

    async fetchIdeaActivities(ideaId: number): Promise<IdeaActivities> {
        return await this.getAndUnwrap(serviceLinks.ideaActivities(ideaId));
    }

    async fetchIdeaStageActivity(ideaId: number, stageId: number): Promise<StageActivity> {
        return await this.getAndUnwrap(serviceLinks.ideaStageActivity(ideaId, stageId));
    }

    async fetchAssessmentStageRankActivity(ideaId: number, stageId: number): Promise<AssessmentStageRatingActivity> {
        return await this.getAndUnwrap(serviceLinks.assessmentEditRank(ideaId, stageId));
    }

    async saveAssessmentRank(ideaId: number, assessmentId: number, questionId: number, parameters: AssessmentRatingParameters): Promise<StageActionResponse<AssessmentStageActivity, AssessmentStageSummary>> {
        return this.postAndUnwrap(serviceLinks.saveAssessmentRank(ideaId, assessmentId, questionId), parameters, undefined, StageActionResponse);
    }

    async fetchReviewNotes(ideaId: number, stageId: number): Promise<ReviewNotesResponse> {
        return await this.getAndUnwrap(serviceLinks.reviewNotes(ideaId, stageId));
    }

    async getEditRank(ideaId: number, stageId: number): Promise<ReviewscaleStageRankActivity> {
        return await this.getAndUnwrap(serviceLinks.reviewScaleEditRank(ideaId, stageId));
    }

    async saveFactorReview(ideaId: number, stageId: number, factorType: FactorType, responseType: ReviewScaleResponseType | undefined, factorId: number, note: string): Promise<StageActionResponse<ReviewscaleStageActivity, BaseIdeaStageSummary>> {
        let requestUrl;
        switch (factorType) {
            case FactorType.CONSTRAINT:
                requestUrl = serviceLinks.rankConstraintFactor(ideaId, stageId);
                break;
            case FactorType.COST:
                requestUrl = serviceLinks.rankCostFactor(ideaId, stageId);
                break;
            case FactorType.VALUE:
                requestUrl = serviceLinks.rankValueFactor(ideaId, stageId);
                break;
            default:
                requestUrl = '';
                break;
        }
        return await this.postAndUnwrap(requestUrl, {responseType, factorId, note}, undefined, StageActionResponse);
    }

    async fetchStageFieldRevisionHistory(ideaId: number, fieldId: number, pageParameters: PageParameters): Promise<PagedResponseContent<RevisionHistory>> {
        return await this.getAsPostAndUnwrap(serviceLinks.stageFieldRevisionHistory(ideaId, fieldId), pageParameters);
    }

    async saveRefineStageField(ideaId: number, fieldId: number, fieldParameters: StageFieldValueParameters): Promise<StageActionResponse<RefineStageActivity, RefineStageSummary>> {
        return this.postAndUnwrap(serviceLinks.saveStageField(ideaId, fieldId), fieldParameters, undefined, StageActionResponse);
    }

    async fetchBuildTeamData(ideaId: number, stageId: number): Promise<BuildTeamStageActivity> {
        return await this.getAndUnwrap(serviceLinks.ideaStageActivity(ideaId, stageId));
    }

    async fetchTeamRole(): Promise<Array<TeamRoleData>> {
        return await this.getAndUnwrap(serviceLinks.teamRole());
    }

    async fetchTeamMember(ideaId: number, stageId: number, pageParameters: PageParameters): Promise<PagedResponseContent<Member>> {
        return await this.getAsPostAndUnwrap(serviceLinks.teamMember(), {
            ideaId: ideaId,
            stageId: stageId,
            term: pageParameters.term || '',
            pageParameters
        });
    }

    async assignTeamRole(ideaId: number, stageId: number, positionId: number, teamRoleId: number): Promise<StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>> {
        return await this.postAndUnwrap(serviceLinks.assignTeamRole(ideaId, stageId, positionId, teamRoleId), undefined, undefined, StageActionResponse);
    }

    async joinBuildTeam(ideaId: number, stageId: number, positionId: number): Promise<StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>> {
        return await this.postAndUnwrap(serviceLinks.joinBuildTeam(ideaId, stageId, positionId), undefined, undefined, StageActionResponse);
    }

    async assignMemberBuildTeam(ideaId: number, stageId: number, positionId: number, memberId: number): Promise<StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>> {
        return await this.postAndUnwrap(serviceLinks.assignMemberBuildTeam(ideaId, stageId, positionId, memberId), undefined, undefined, StageActionResponse);
    }

    async unassignMemberBuildTeam(ideaId: number, stageId: number, memberId: number): Promise<StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>> {
        return await this.postAndUnwrap(serviceLinks.unassignMemberBuildTeam(ideaId, stageId, memberId), undefined, undefined, StageActionResponse);
    }

    async promoteToOwnerBuildTeam(ideaId: number, stageId: number, memberId: number): Promise<StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>> {
        return await this.postAndUnwrap(serviceLinks.promoteToOwnerBuildTeam(ideaId, stageId, memberId), undefined, undefined, StageActionResponse);
    }

    async addPositionBuildTeam(ideaId: number, stageId: number, buildTeamGroupId: number): Promise<StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>> {
        return await this.postAndUnwrap(serviceLinks.addPositionBuildTeam(ideaId, stageId, buildTeamGroupId), undefined, undefined, StageActionResponse);
    }

    async removePositionBuildTeam(ideaId: number, stageId: number, positionId: number): Promise<StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>> {
        return await this.deleteAndUnwrap(serviceLinks.removePositionBuildTeam(ideaId, stageId, positionId), undefined, undefined, StageActionResponse);
    }

    async fetchCampaignTosContent(campaignId: number): Promise<TosData> {
        return await this.getAndUnwrap(serviceLinks.campaignTosContent(campaignId));
    }

    async campaignTosAccept(campaignId: number): Promise<GenericResponse> {
        return await this.postAndUnwrap(serviceLinks.campaignTosAccept(campaignId));
    }

    async saveEstimate(ideaId: number, stageId: number, estimateData: SaveEstimateParameters): Promise<StageActionResponse<EstimateStageActivity, EstimateStageSummary>> {
        return await this.postAndUnwrap(serviceLinks.saveEstimate(ideaId, stageId), estimateData, undefined, StageActionResponse);
    }

    async fetchUnfurledUrl(url: string): Promise<UnfurledUrl> {
        return this.getAsPostAndUnwrap(serviceLinks.unfurlUrl(), {url});
    }

    async fetchIdeaLabels(ideaId: number): Promise<IdeaLabelActionResponse> {
        return await this.getAndUnwrap(serviceLinks.ideaLabels(ideaId));
    }

    async fetchComments(ideaId: number, commentParams: CommentsParams): Promise<PagedResponseContent<CommentSummary>> {
        return await this.getAsPostAndUnwrap(serviceLinks.commentsList(ideaId), commentParams);
    }

    async fetchCommentsById(commentId: number, limit: number): Promise<PagedResponseContent<CommentSummary>> {
        return await this.getAndUnwrap(serviceLinks.commentsListById(commentId));
    }

    async fetchStageComments(ideaId: number, stageId: number, pageParams: PageParameters): Promise<PagedResponseContent<StageCommentSummary>> {
        return await this.getAsPostAndUnwrap(serviceLinks.stageCommentList(ideaId, stageId), pageParams);
    }

    async fetchPinnedComments(ideaId: number, commentParams: CommentsParams): Promise<CommentSummary[]> {
        return await this.getAsPostAndUnwrap(serviceLinks.pinnedComment(ideaId), {order: commentParams.order});
    }

    async fetchChildComments(commentId: number): Promise<CommentSummary[]> {
        return await this.getAndUnwrap(serviceLinks.childCommentsList(commentId));
    }

    async fetchCommentPermissions(parentId: number): Promise<CommentPermissions> {
        return await this.getAndUnwrap(serviceLinks.commentPermissions(parentId));
    }

    async fetchStageCommentPermissions(ideaId: number): Promise<StageCommentPermissions> {
        return await this.getAndUnwrap(serviceLinks.stageCommentPermissions(ideaId));
    }

    async fetchCommentLabels(ideaId: number): Promise<LabelData[]> {
        return await this.getAndUnwrap(serviceLinks.commentLabels(ideaId));
    }

    async fetchCommentLabelsById(commentId: number): Promise<CommentLabelsResponse> {
        return await this.getAndUnwrap(serviceLinks.commentLabelsById(commentId));
    }

    async commentCreation(formInputs: CommentFormInputs): Promise<CommentSummary> {
        return await this.postAndUnwrap(serviceLinks.commentCreate(), formInputs);
    }

    async createStageComment(formInputs: StageCommentFormInputs): Promise<StageCommentSummary> {
        return await this.postAndUnwrap(serviceLinks.createStageComment(), formInputs);
    }

    async commentModification(commentId: number, formInputs: CommentFormInputs): Promise<CommentSummary> {
        return await this.postAndUnwrap(serviceLinks.commentModify(commentId), formInputs);
    }

    async deleteCommentAttachment(commentId: number, fileId: number): Promise<GenericResponse> {
        return await this.deleteAndUnwrap(serviceLinks.deleteCommentAttachment(commentId, fileId));
    }

    async deleteStageCommentAttachment(fileId: number): Promise<GenericResponse> {
        return await this.deleteAndUnwrap(serviceLinks.deleteStageCommentAttachment(fileId));
    }

    async fetchCampaignWorkflow(campaignId: number): Promise<CampaignWorkFlowData> {
        return await this.getAndUnwrap(serviceLinks.campaignWorkFlow(campaignId));
    }

    async fetchIdeaStages(ideaId: number): Promise<Array<IdeaStagesData>> {
        return await this.getAndUnwrap(serviceLinks.ideaStages(ideaId));
    }

    async commentAttachments(commentId: number, formInputs: AttachmentFormInputs, attributeApiParameters?: Array<AttachmentAttributeParameters>): Promise<FileAttachmentsHolder> {
        return await this.postAndUnwrap(serviceLinks.commentAttachments(commentId), {
            attachments: [formInputs],
            attributeApiParameters
        });
    }

    async addStageCommentAttachments(commentId: number, formInputs: AttachmentFormInputs, attributeApiParameters?: Array<AttachmentAttributeParameters>): Promise<FileAttachmentsHolder> {
        return await this.postAndUnwrap(serviceLinks.addStageCommentAttachment(commentId), {
            attachments: [formInputs],
            attributeApiParameters
        });
    }

    async deleteComment(commentId: number): Promise<void> {
        return await this.deleteAndUnwrap(serviceLinks.deleteComment(commentId));
    }

    async deleteStageComment(commentId: number): Promise<void> {
        return await this.deleteAndUnwrap(serviceLinks.deleteStageComment(commentId));
    }

    async pinComment(commentId: number): Promise<void> {
        return await this.postAndUnwrap(serviceLinks.pinComment(commentId));
    }

    async unpinComment(commentId: number): Promise<void> {
        return await this.postAndUnwrap(serviceLinks.unpinComment(commentId));
    }

    async createSuperComment(commentId: number, commentTypeId: number): Promise<SuperCommentData> {
        return await this.postAndUnwrap(serviceLinks.createSuperComment(commentId, commentTypeId));
    }

    async fetchSidebarLeaderboardItem(campaignId?: number): Promise<Array<SidebarLeaderboardItem>> {
        return await this.getAndUnwrap(serviceLinks.sidebarLeaderboardItem(campaignId));
    }

    async addCommentsLabel(commentId: number, labelId: number): Promise<LabelData[]> {
        return await this.postAndUnwrap(serviceLinks.addCommentsLabel(commentId, labelId));
    }

    async removeCommentsLabel(commentId: number, labelId: number): Promise<void> {
        return await this.deleteAndUnwrap(serviceLinks.removeCommentsLabel(commentId, labelId));
    }

    async commentUpDownVote(commentId: number, voteRequest: CommentVoteRequest): Promise<CommentVoteResponse> {
        return await this.postAndUnwrap(serviceLinks.commentUpDownVote(commentId), voteRequest);
    }

    async commentRetractVote(commentId: number): Promise<CommentVoteResponse> {
        return await this.postAndUnwrap(serviceLinks.commentRetractVote(commentId));
    }

    async fetchLeaderboard(parameters: LeaderboardParameters, timePeriod?: {
        mode: TimePeriod,
        unit: number
    }): Promise<LeaderboardResponse> {
        return await this.getAsPostAndUnwrap(serviceLinks.leaderboard(timePeriod), parameters);
    }

    async fetchLeaderboardCampaigns(pageParameters: PageParameters): Promise<PagedResponseContent<CampaignsHolder<Campaign>>> {
        return await this.getAsPostAndUnwrap(serviceLinks.leaderboardCampaigns(), pageParameters);
    }

    async fetchLeaderboardGroups(pageParameters: PageParameters): Promise<PagedResponseContent<MemberGroup>> {
        return await this.getAsPostAndUnwrap(serviceLinks.leaderboardGroups(), pageParameters);
    }

    async commentAbuse(commentId: number): Promise<void> {
        return await this.postAndUnwrap(serviceLinks.commentAbuse(commentId));
    }

    async commentApprove(commentId: number): Promise<CommentSummary> {
        return await this.postAndUnwrap(serviceLinks.commentApprove(commentId));
    }

    async commentReject(commentId: number): Promise<CommentSummary> {
        return await this.postAndUnwrap(serviceLinks.commentReject(commentId));
    }

    async getCommentKudoGivers(pageParameters: PageParameters, commentId: number): Promise<PagedResponseContent<Member>> {
        return await this.getAsPostAndUnwrap(serviceLinks.getCommentKudoGivers(commentId), pageParameters);
    }

    async giveCommentKudo(commentId: number): Promise<KudoActivitySummary> {
        return await this.postAndUnwrap(serviceLinks.giveCommentKudo(commentId));
    }

    async getIdeaKudoGivers(pageParameters: PageParameters, ideaId: number): Promise<PagedResponseContent<Member>> {
        return await this.getAsPostAndUnwrap(serviceLinks.ideaKudoGivers(ideaId), pageParameters);
    }

    async fetchOriginalComment(commentId: number): Promise<OriginalComment> {
        return await this.getAndUnwrap(serviceLinks.getOriginalComment(commentId));
    }

    async fetchEditComment(commentId: number): Promise<CommentSummary> {
        return await this.getAndUnwrap(serviceLinks.getEditComment(commentId));
    }

    async fetchFooter(): Promise<FooterData> {
        return await this.getAndUnwrap(serviceLinks.footer());
    }

    async confirmCookieConsent(cookieCategories: Cookies[]): Promise<void> {
        return await this.postAndUnwrap(serviceLinks.confirmCookieConsent(), {cookieCategories});
    }

    async searchCampaigns(parameters: CampaignSearchParameters): Promise<PagedResponseContent<CampaignSearchItem>> {
        return await this.getAsPostAndUnwrap(serviceLinks.advancedSearchCampaigns(), parameters);
    }

    async searchMembers(searchParams: MemberSearchParams): Promise<PagedResponseContent<SearchedMember>> {
        return await this.getAsPostAndUnwrap(serviceLinks.searchMembers(), searchParams);
    }

    async searchLabels(pageParameters: PageParameters): Promise<PagedResponseContent<LabelData>> {
        return await this.getAsPostAndUnwrap(serviceLinks.searchLabels(), pageParameters);
    }

    async searchStages(pageParameters: PageParameters): Promise<PagedResponseContent<StagesHolder<StageGroup>>> {
        return await this.getAsPostAndUnwrap(serviceLinks.searchStages(), pageParameters);
    }

    async fetchSearchIdeas(searchParameters: SearchParameters): Promise<PagedResponseContent<IdeaSummary>> {
        return await this.getAsPostAndUnwrap(serviceLinks.advancedSearchIdeas(), searchParameters);
    }

    async fetchIdeasCustomFields(pageParameters: PageParameters, campaignIds?: number, includeArchivedCampaigns?: boolean): Promise<PagedResponseContent<StageGroup>> {
        return await this.getAsPostAndUnwrap(serviceLinks.searchIdeasCustomFields(), {
            campaignIds: campaignIds,
            includeArchivedCampaigns: includeArchivedCampaigns,
            term: pageParameters.term,
            page: pageParameters.page,
            limit: pageParameters.limit || 10
        });
    }

    async fetchDependantCustomFields(fieldId: number): Promise<CustomFieldData> {
        return await this.getAndUnwrap(serviceLinks.dependantCustomSearchFields(fieldId));
    }

    async sudoAdmin(): Promise<void> {
        return await this.getAsPostAndUnwrap(serviceLinks.sudoAdmin());
    }

    async deSudoAdmin(): Promise<void> {
        return await this.getAsPostAndUnwrap(serviceLinks.deSudoAdmin());
    }

    async fetchAdvanceSearchProfileFields(pageParameters: PageParameters): Promise<PagedResponseContent<StageGroup>> {
        return await this.getAsPostAndUnwrap(serviceLinks.advanceSearchProfileFields(), {
            term: pageParameters.term,
            page: pageParameters.page,
            limit: pageParameters.limit || 10
        });
    }

    async fetchDependantProfileFields(fieldId: number): Promise<CustomFieldData> {
        return await this.getAndUnwrap(serviceLinks.dependantProfileFields(fieldId));
    }

    async fetchMemberIncompleteProfileFields(): Promise<ProfileFieldData[]> {
        return await this.getAndUnwrap(serviceLinks.memberIncompleteProfileFields());
    }

    async fetchMemberPendingVerifiableProfileFields(): Promise<ProfileFieldData[]> {
        return await this.getAndUnwrap(serviceLinks.memberPendingVerifiableProfileFields());
    }

    async fetchMemberIncompleteVerifiableProfileFields(): Promise<ProfileFieldData[]> {
        return await this.getAndUnwrap(serviceLinks.memberIncompleteVerifiableProfileFields());
    }

    async fetchAdvancedSearchTags(pageParams: PageParameters): Promise<PagedResponseContent<string>> {
        return await this.getAsPostAndUnwrap(serviceLinks.advancedSearchTags(), pageParams);
    }

    async fetchAdvancedSearchModeratorTags(pageParams: PageParameters): Promise<PagedResponseContent<string>> {
        return await this.getAsPostAndUnwrap(serviceLinks.advancedSearchModeratorTags(), pageParams);
    }

    async fetchMemberBio(): Promise<MemberBio> {
        return await this.getAndUnwrap(serviceLinks.memberBio());
    }

    async SaveMemberBio(memberId: number, content: string): Promise<MemberBio> {
        return await this.postAndUnwrap(serviceLinks.saveMemberBio(), {
            memberId: memberId,
            content: content
        });
    }

    async fetchCampaignWidget(communityId: number, sidebarItemKey: string): Promise<SidebarCustomWidget> {
        return await this.getAsPostAndUnwrap(serviceLinks.campaignWidget(communityId), {sidebarItemKey});
    }

    async fetchCommunityWidget(): Promise<SidebarCustomWidget> {
        return await this.getAndUnwrap(serviceLinks.communityWidget());
    }

    async fetchSidebarContactWidget(): Promise<SidebarCustomWidget> {
        return await this.getAndUnwrap(serviceLinks.sidebarContactWidget());
    }

    async fetchEmailIdeaContent(ideaId: number): Promise<EmailIdeaContent> {
        return await this.getAndUnwrap(serviceLinks.emailIdeaContent(ideaId));
    }

    async getAuthorEmailIdeaContent(ideaId: number): Promise<EmailIdeaContent> {
        return await this.getAndUnwrap(serviceLinks.authorEmailIdeaContent(ideaId));
    }

    async enablePrivilege(memberId: number): Promise<void> {
        return await this.postAndUnwrap(serviceLinks.enablePrivilege(memberId));
    }

    async disablePrivilege(memberId: number): Promise<void> {
        return await this.postAndUnwrap(serviceLinks.disablePrivilege(memberId));
    }

    async fetchIdeaTeamMemberInfo(ideaId: number): Promise<IdeaTeamMember[]> {
        return await this.getAndUnwrap<IdeaTeamMember[]>(serviceLinks.ideaTeamMembers(ideaId));
    }

    async getFeatureTours(keys: string[]): Promise<TourStep[]> {
        return await this.postAndUnwrap(serviceLinks.getFeatureTour(), keys);
    }

    async dismissFeatureTour(key: string, stepId: number): Promise<any> {
        return await this.postAndUnwrap(serviceLinks.dismissFeatureTour(key, stepId));
    }

    async skipFeatureTour(key: string): Promise<void> {
        return await this.postAndUnwrap(serviceLinks.skipFeatureTour(key));
    }

    async fetchJoinCommunityFields(): Promise<ProfileFieldData[]> {
        return await this.getAndUnwrap(serviceLinks.fetchJoinCommunityFields());
    }

    async fetchJoinCommunityProfileFields(): Promise<MemberProfileAndFieldValuesHolder> {
        return await this.getAndUnwrap(serviceLinks.fetchJoinCommunityProfileFields());
    }

    async joinCommunity(fieldValues: CustomFieldRequestValue[]): Promise<GenericResponse> {
        return this.postAndUnwrap(serviceLinks.joinCommunity(), {fieldValues});
    }

    async saveJoinCommunityProfileFields(profileAndFieldRequestParams: MemberProfileAndFieldRequestParameters): Promise<GenericResponse> {
        return this.postAndUnwrap(serviceLinks.saveJoinCommunityProfileFields(), profileAndFieldRequestParams);
    }

    async fetchReleasedFeatures(page: number): Promise<ReleasedFeatureHolder> {
        return this.getAndUnwrap(serviceLinks.releasedFeatures(page));
    }

    async fetchUpcomingFeatures(): Promise<Feature[]> {
        return this.getAndUnwrap(serviceLinks.upcomingFeatures());
    }


    async fetchGoogleAnalyticsDimensions(): Promise<GoogleAnalyticsDimensions> {
        return this.getAndUnwrap(serviceLinks.googleAnalyticsDimensions());
    }

    async fetchChatConfig(): Promise<ChatConfig> {
        return this.getAndUnwrap(serviceLinks.chatConfig());
    }

    async fetchMonthlyModeratorDigest(pageParameters: PageParameters, year: string, month: string): Promise<DigestResponseContent> {
        return this.getAsPostAndUnwrap(serviceLinks.monthlyModeratorDigest(year, month), {...pageParameters});
    }

    async fetchWeeklyModeratorDigest(pageParameters: PageParameters, year: string, weekOfYear: string): Promise<DigestResponseContent> {
        return this.getAsPostAndUnwrap(serviceLinks.weeklyModeratorDigest(year, weekOfYear), {...pageParameters});
    }

    async fetchCampaignPerformanceMonthlyModeratorDigest(campaignId: number, memberId: number, year: string, month: string): Promise<DigestShareData> {
        return this.getAndUnwrap(serviceLinks.campaignPerformanceMonthlyModeratorDigest(campaignId, memberId, year, month));
    }

    async fetchCampaignPerformanceWeeklyModeratorDigest(campaignId: number, memberId: number, year: string, weekOfYear: string): Promise<DigestShareData> {
        return this.getAndUnwrap(serviceLinks.campaignPerformanceWeeklyModeratorDigest(campaignId, memberId, year, weekOfYear));
    }

    async fetchCampaignPerformanceSharingMedia(campaignId: number): Promise<SharingMedia[]> {
        return this.getAndUnwrap(serviceLinks.campaignPerformanceSharingMedia(campaignId));
    }

    async changeThemePreference(theme: Theme): Promise<void> {
        return this.postAndUnwrap(serviceLinks.changeThemePreference(theme));
    }

    async fetchWhiteListedDomainsForInviteFriends(): Promise<InviteFriendResponse> {
        return this.getAndUnwrap(serviceLinks.inviteFriends());
    }

    async sendInvitations(payload: InviteFriendRequest): Promise<void> {
        return this.postAndUnwrap(serviceLinks.inviteFriends(), payload);
    }

    async fetchTopbarClassification(): Promise<TopbarClassification> {
        return this.getAndUnwrap(serviceLinks.topbarClassification());
    }

    async fetchClassifications(campaignId?: number): Promise<ClassificationsHolder> {
        return this.getAndUnwrap(serviceLinks.classifications(campaignId));
    }

    async fetchEffectiveClassifications(requestData: EffectiveClassificationRequest[]): Promise<EffectiveClassification[]> {
        return this.postAndUnwrap(serviceLinks.effectiveClassifications(), requestData);
    }

    async fetchProfileFieldModalStatus(): Promise<ProfileFieldModalStatus> {
        return this.getAndUnwrap(serviceLinks.fetchProfileFieldModalStatus());
    }

    async fetchCommunityTosContent(): Promise<string> {
        return this.getAndUnwrap(serviceLinks.membershipTos());
    }

    async submitMembershipTos(): Promise<void> {
        return this.postAndUnwrap(serviceLinks.membershipTos());
    }

    async fetchAiText(prompt: string, fieldName: AiAssistanceFieldNames, chatId?: number): Promise<AiContentResponse> {
        return this.postAndUnwrap(serviceLinks.aiTextAssistance(), {
            chatId: chatId,
            prompt: prompt,
            fieldName: fieldName
        });
    }

    async fetchAiImage(prompt: string, size?: string, numberOfImages?: number): Promise<AiImageResponse> {
        return this.postAndUnwrap(serviceLinks.aiImageAssistance(), {
            prompt: prompt,
            size: size || '512',
            numberOfImages: numberOfImages || AI_IMAGES_NUMBER
        });
    }

    async fetchAiIdeaDescription(prompt: string, fieldName: AiAssistanceFieldNames, chatId?: number, campaignId?: number, chatContinue: boolean = false): Promise<AiContentResponse> {
        return this.postAndUnwrap(serviceLinks.aiIdeaDescription(), {
            chatId: chatId,
            prompt: prompt,
            fieldName: fieldName,
            campaignId: campaignId,
            chatContinue: chatContinue
        });
    }

    async fetchAtMentionsIdeaCustomField(parameters: MemberSearchRequest): Promise<Member[]> {
        const {content} = await this.getAsPostAndUnwrap<PagedResponseContent<Member>, MemberSearchRequest>(serviceLinks.atMentionIdeaCustomField(), parameters);
        return content;
    }

    async fetchAtMentionsRefineCustomField(parameters: MemberSearchRequest): Promise<Member[]> {
        const {content} = await this.getAsPostAndUnwrap<PagedResponseContent<Member>, MemberSearchRequest>(serviceLinks.atMentionRefineCustomField(), parameters);
        return content;
    }
}
