import React, {Fragment} from 'react';
import {Trans} from 'react-i18next';
import {useLocalizer} from 'hooks/useLocalizer';
import {StageSummaryHeading} from './StageSummaryHeading';
import {RefineStageSummary} from 'models/RefineStageSummary';
import {IdeaStageSummaryProps} from 'models/types/IdeaStageSummaryProps';

export const RefineSummary = (props: IdeaStageSummaryProps<RefineStageSummary>) => {
    const {ideaStageSummary: {refineCompleteFieldCount, refineFieldCount, participationAllowed, viewAllowed}} = props;
    const localizer = useLocalizer();

    return (
        <Fragment>
            <StageSummaryHeading className="mb-3" heading={localizer.msg('stage.refine.heading')}/>
            <div className="stage-data mt-n3 mb-2">
                {
                    participationAllowed || viewAllowed
                        ? <Trans i18nKey="stage.refine.questions-answered"
                                 values={{completed: refineCompleteFieldCount, total: refineFieldCount}}>
                            <strong/>
                        </Trans>
                        : <em>{localizer.msg('stage.refine.awaits')}</em>
                }
            </div>
        </Fragment>
    );
};