import {Operation} from './Operation';
import {CampaignOperationType} from './CampaignOperationType';

export class PublishCampaignHomeOperation implements Operation {
    private readonly type: CampaignOperationType
    constructor(private campaignId: number) {
        this.type = CampaignOperationType.PUBLISH_CAMPAIGN_HOME_CONFIG;
    }

    getType(): CampaignOperationType {
        return this.type;
    }
}