import React from 'react';
import {isKeyExists, Localizer} from '@ideascale/commons';
import {Icon} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {ImageFile} from 'models/edit-mode/ImageFile';
import './FileLibrary.scss';

type FileLibraryProps = {
    localizer: Localizer;
    images: ImageFile[];
    previewMode?: 'full' | 'medium' | 'small' | 'xs-small';
    selectedImage?: ImageFile | null;
    onSelectedImage: (image: ImageFile) => void;
    ariaLabel?: string;
    disabled?: boolean;
}

export const FileLibrary = (props: FileLibraryProps) => {
    const {
        images,
        previewMode = 'small',
        onSelectedImage,
        selectedImage,
        localizer,
        ariaLabel = '',
        disabled = false
    } = props;

    return (
        <div className="file-library-container" tabIndex={0}>
            {
                images?.length > 0
                    ? (
                        <div className="d-flex justify-content-start flex-wrap mx-n1" role="list"
                             aria-label={ariaLabel}>
                            {
                                images.map((image, index) => (
                                    <button
                                        key={index}
                                        role="checkbox"
                                        aria-checked={selectedImage?.fileId === image.fileId}
                                        className={`image-edit-container m-2 rounded ${selectedImage?.fileId === image.fileId ? 'active' : ''} ${previewMode === 'full' ? 'w-100' : previewMode}`}
                                        onClick={() => {
                                            if (selectedImage?.fileId === image.fileId) {
                                                onSelectedImage({...ImageFile.EMPTY});
                                            } else {
                                                onSelectedImage(image);
                                            }
                                        }}
                                        disabled={disabled}
                                    >
                                        <img
                                            className={`library-image rounded ${previewMode}`}
                                            src={image.url}
                                            alt={!isKeyExists(image, 'altText') ? `${localizer.msg('edit-mode.default-banner-alt-text', {index: (index + 1)})}` : image.altText?.length ? image.altText : localizer.msg('edit-mode.empty-alt-text')}
                                            height={120}
                                        />
                                        <div className="overlay d-none">
                                            <Icon className="check-mark" iconSpritePath={svgIconPath} name="check-solid-box-rounded"
                                                  width={24} height={24}/>
                                        </div>
                                    </button>
                                ))
                            }
                        </div>
                    )
                    : (
                        <div className="empty-image-upload-info text-center my-3">
                            <Icon
                                className="mb-3"
                                iconSpritePath={svgIconPath}
                                name="plus-solid-box-rounded-layered"
                                width={26}
                                height={26}
                            />
                            <div className="fw-semibold mb-1">
                                {localizer.msg('edit-mode.file-library.empty-text-msg-1')}
                            </div>
                            <div className="default-image-text-info">
                                {localizer.msg('edit-mode.file-library.empty-text-msg-2')}
                            </div>
                        </div>
                    )
            }
        </div>
    );
};