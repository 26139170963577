import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {FieldErrors, FieldValues, useForm, useWatch} from 'react-hook-form';
import {Link, useLocation} from 'react-router-dom';
import {useQuery, useQueryClient} from 'react-query';
import {Modal, Scrollbar, ScrollbarInstance} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {
    AdditionalInput,
    AiAssistanceFieldNames,
    AiContentResponse,
    AlertEvent,
    AlertType,
    AttachmentField,
    buildAlertEventData,
    Campaign,
    CampaignDropdownLabel,
    CampaignsHolder,
    CheckboxField,
    ClassificationAttachmentAttributesData,
    ClassificationAttribute,
    ClassificationAttributesData,
    ClassificationsHolder,
    ClassificationUtil,
    ClassifiedAttachment,
    CoSubmitterField,
    CurrencyField,
    CustomField,
    DateField,
    DescriptionField,
    EMBEDDED_FILE_NAME_PREFIX,
    ErrorReason,
    eventDispatcher,
    FEATURE_TOUR_EVENTS,
    FieldType,
    FileAttachmentDetail,
    FileUploadResponse,
    FormSection,
    FormSectionData,
    GlobalZipcodeField,
    HookFormProvider,
    IdeaFieldData,
    IdeaFormFieldKeys,
    IdeaFormMode,
    IdeaFormSkeleton,
    IdeaFormUtil,
    LabelData,
    LabelsField,
    LayoutUtil,
    Member,
    MemberDetailData,
    MemberSearchRequest,
    MultipleChoiceField,
    PageTitle,
    reloadPage,
    SingleChoiceField,
    stringToOptionType,
    StringUtil,
    SubmitOnBehalfField,
    TagSearchParameters,
    TextAreaField,
    TextField,
    UploadedResponse,
    UploadProgressCallback,
    US_ZIPCODE_MAXLENGTH,
    useApiErrorResponseHandler,
    useFileUploadIntermediary,
    useHandleFormSubmit,
    useInterval,
    useUrlQuery
} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useMoveIdeaToRecycle} from 'hooks/useMoveIdeaToRecycle';
import {useClassificationService} from 'hooks/useClassificationService';
import {COMMON_VALIDATION_ERROR, QUERY_KEYS} from 'constants/AppConstants';
import {ROUTES} from 'shared/Routes';
import {DraftIdeas} from 'components/DraftIdeas';
import {CampaignDropdown} from 'components/idea-submission/CampaignDropdown';
import {TitleField} from 'components/idea-submission/fields/TitleField';
import {TagsField} from 'components/idea-submission/fields/TagsField';
import {LinkIdeasField} from 'components/idea-submission/fields/LinkIdeasField';
import {ModeratorOrAdminOnlyField} from 'components/idea-submission/fields/ModeratorOrAdminOnlyField';
import {HideAuthorField} from 'components/idea-submission/fields/HideAuthorField';
import {TermsOfServiceField} from 'components/idea-submission/fields/TermsOfServiceField';
import {DEFAULT_LANGUAGE_OPTION, LanguageField} from 'components/idea-submission/fields/LanguageField';
import {LanguageHiddenField} from 'components/idea-submission/fields/LanguageHiddenField';
import {HtmlRenderer} from '../shared/HtmlRenderer';
import {CommonUtil} from '../../utils/CommonUtil';
import {FormButtons} from './FormButtons';
import {DraftIdea} from 'models/DraftIdea';
import {IdeaFormFields} from 'models/IdeaFormFields';
import {ModifiedIdeaDetail} from 'models/ModifiedIdeaDetail';
import {IdeaSubmissionRequest, IdeaSubmissionRequestBuilder} from 'models/IdeaSubmissionRequest';
import {LinkableIdea} from 'models/LinkableIdea';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {Language} from 'models/types/Language';
import {PageParameters} from 'models/types/PageParameters';
import {IdeaDetail} from 'models/IdeaDetail';
import {BasicStagesId} from 'models/enums/BasicStagesId';
import {appLinks} from 'services/AppLinks';
import './IdeaFormModal.scss';

const AUTO_SAVE_DRAFT_INTERVAL = 3 * 60 * 1000;
const MODAL_BODY_ID = 'modal-body-idea-form';
const SUBTITLE_LENGTH = LayoutUtil.isMobileLayout() ? 120 : 210;

type IdeaFormModalProps = {
    open: boolean;
    toggle: () => void;
    defaultMode: IdeaFormMode;
    fetchCampaigns: (params: PageParameters) => Promise<PagedResponseContent<CampaignsHolder<Campaign>>>;
    fetchFieldsByCampaignId: (campaignId: number) => Promise<IdeaFormFields>;
    fetchFieldsByIdeaId: (ideaId: number) => Promise<IdeaFormFields>;
    fetchCopyIdeaFormFieldsByIdeaId: (ideaId: number) => Promise<IdeaFormFields>;
    fetchAtMentionMembers: (parameters: MemberSearchRequest) => Promise<Member[]>
    fetchDrafts: () => Promise<DraftIdea[]>;
    deleteDraft: (ideaId: number) => Promise<void>;
    fetchContributors: (parameters: MemberSearchRequest) => Promise<PagedResponseContent<MemberDetailData>>;
    fetchOnBehalfMembers: (parameters: MemberSearchRequest) => Promise<PagedResponseContent<MemberDetailData>>;
    fetchLinkableIdeas: (ideaId: number, ideaTitle: string, ideaDescription: string, pageParameters: PageParameters) => Promise<PagedResponseContent<LinkableIdea>>;
    fetchLinkableIdeasByKeywords: (ideaId: number, pageParameters: PageParameters) => Promise<PagedResponseContent<LinkableIdea>>;
    fetchIdeaLabelsToAdd: (ideaId?: number) => Promise<LabelData[]>;
    detectLanguage: (text: string) => Promise<Language>;
    fetchInputTags: (pageParams: TagSearchParameters) => Promise<PagedResponseContent<string>>;
    fetchSuggestedTags: (ideaTitle: string, ideaDescription: string, currentTags: string[]) => Promise<string[]>;
    createIdea: (submissionRequest: IdeaSubmissionRequest) => Promise<IdeaDetail>;
    uploadFile: (data: FormData, onUploadProgress: UploadProgressCallback) => Promise<FileUploadResponse>;
    initialEditIdeaId?: number;
    initialDraftIdeaId?: number | null;
    moveToRecycleBtnPreview?: boolean;
    tempImageUpload?: (data: FormData, onUploadProgress: UploadProgressCallback) => Promise<UploadedResponse>;
    editIdea?: (ideaId: number, submissionRequest: IdeaSubmissionRequest) => Promise<ModifiedIdeaDetail>;
    aiAssistanceEnabled?: boolean;
    fetchAiAssistedDescription: (prompt: string, fieldName: AiAssistanceFieldNames, chatId?: number, campaignId?: number, chatContinue?: boolean) => Promise<AiContentResponse>;
    fetchAtMentionsIdeaCustomField?: (parameters: MemberSearchRequest) => Promise<Member[]>;
}

const FORM_ID = 'idea-submission-form';
export const DEFAULT_CAMPAIGN_ID = 0;
const DEFAULT_CAMPAIGN_OPTION = {
    label: '',
    value: DEFAULT_CAMPAIGN_ID
};

export const IdeaFormModal = (props: IdeaFormModalProps) => {
    const {
        open,
        toggle,
        initialEditIdeaId,
        initialDraftIdeaId = null,
        defaultMode = 'CREATE_IDEA',
        fetchCampaigns,
        createIdea,
        editIdea,
        fetchFieldsByCampaignId,
        fetchFieldsByIdeaId,
        fetchCopyIdeaFormFieldsByIdeaId,
        fetchAtMentionMembers,
        fetchDrafts,
        deleteDraft,
        fetchLinkableIdeas,
        fetchLinkableIdeasByKeywords,
        fetchContributors,
        fetchOnBehalfMembers,
        fetchIdeaLabelsToAdd,
        detectLanguage,
        fetchInputTags,
        fetchSuggestedTags,
        uploadFile,
        tempImageUpload,
        aiAssistanceEnabled = false,
        fetchAiAssistedDescription,
        fetchAtMentionsIdeaCustomField
    } = props;
    const localizer = useLocalizer();
    const query = useUrlQuery();
    const {handleErrorResponse, showErrorMessage} = useApiErrorResponseHandler({localizer});
    const location = useLocation();
    const {moveToRecycle} = useMoveIdeaToRecycle();
    const queryClient = useQueryClient();
    const {
        communityConfig: {
            dateTimeFormat,
            multipleLanguageSupported,
            maxFileSizeLimit,
            defaultPage,
            name: communityName,
            atMentionEnabled,
            offensiveEmojis,
            classificationEnabled,
            aiTextAssistEnabled,
            helpSiteBaseUrl
        },
        setLastSubmittedIdea,
        currentCampaign: initialCampaignContext,
        authentication: {actor}
    } = useAppContext();
    const {onUploadFile, fileUploading} = useFileUploadIntermediary(uploadFile);
    const [mode, setMode] = useState<IdeaFormMode>(defaultMode);
    const draftSubmitterId = useRef(query.get(ROUTES.QUERY_PARAMS.SUBMITTER_ID) !== null ? Number(query.get(ROUTES.QUERY_PARAMS.SUBMITTER_ID)) : null);
    const [ideaFormFields, setIdeaFormFields] = useState<IdeaFormFields>(IdeaFormFields.EMPTY);
    const [isLoadingForm, setIsLoadingForm] = useState(true);
    const [isSubmittingDraft, setIsSubmittingDraft] = useState(false);
    const draftId = useRef<number | null>(initialDraftIdeaId);
    const methods = useForm({shouldUnregister: true, reValidateMode: 'onSubmit'});
    const {control, getValues, setValue, reset, setError, handleSubmit, formState: {isSubmitting}} = methods;
    const onFormSubmit = useHandleFormSubmit(handleSubmit);
    const selectedCampaignOption: { label: string, value: number } = useWatch({
        name: IdeaFormFieldKeys.CAMPAIGN,
        control,
        defaultValue: initialCampaignContext ? {
            label: initialCampaignContext?.name,
            value: initialCampaignContext?.id
        } : DEFAULT_CAMPAIGN_OPTION
    });
    const hiddenAuthorField = useWatch({name: IdeaFormFieldKeys.HIDDEN_AUTHOR, control,});
    const selectedCampaignId = selectedCampaignOption.value;
    const shouldRenderLanguageField = (mode === 'CREATE_IDEA' || mode === 'EDIT_DRAFT' || mode === 'COPY_IDEA') && multipleLanguageSupported;
    const isCampaignDropdownDisabled = mode === 'EDIT_IDEA' || mode === 'COPY_IDEA';
    const existingDefaultAttachments = useMemo(() => IdeaFormUtil.getIdeaDefaultAttachments(ideaFormFields.formSections), [ideaFormFields.formSections]);
    const existingCustomAttachments = useMemo(() => IdeaFormUtil.getIdeaCustomAttachments(ideaFormFields.formSections), [ideaFormFields.formSections]);
    const {fetchClassifications} = useClassificationService();
    const [classificationsMenuData, setClassificationsMenuData] = useState<ClassificationsHolder | {}>({});
    const [appliedClassifications, setAppliedClassifications] = useState<ClassificationAttributesData>({});
    const [appliedAttachmentClassifications, setAppliedAttachmentClassifications] = useState<ClassificationAttachmentAttributesData>({});
    const lastSubmittedIdeaRef = useRef<IdeaDetail | null>(null);

    useQuery(['classification-menu', selectedCampaignId], () => fetchClassifications(selectedCampaignId), {
        enabled: classificationEnabled && (!['CREATE_IDEA', 'EDIT_DRAFT'].includes(mode) ? selectedCampaignId > 0 : true),
        onSuccess: (response: ClassificationsHolder) => {
            setClassificationsMenuData(response);
        },
        onError: (error: any) => {
            handleErrorResponse(error);
        }
    });

    const updateAppliedClassifications = useCallback((key: string, value: ClassificationAttribute) => {
        setAppliedClassifications(prev => {
            return {...prev, [key]: value};
        });
    }, []);

    const updateAppliedAttachmentClassifications = useCallback((key: string, value: ClassifiedAttachment) => {
        setAppliedAttachmentClassifications(prev => {
            return {...prev, [key]: value};
        });
    }, []);

    const resetClassificationSelection = useCallback(() => {
        if (classificationEnabled) {
            setAppliedClassifications({});
            setAppliedAttachmentClassifications({});
        }
    }, [classificationEnabled]);

    const resetFormFields = useCallback((ideaFieldData: IdeaFormFields, existingValues?: FieldValues, mode?: IdeaFormMode) => {
        const {campaign} = ideaFieldData;
        reset(createFieldValues(ideaFieldData, existingValues, mode));
        setValue(IdeaFormFieldKeys.CAMPAIGN, campaign ? {
            value: campaign.id,
            label: campaign.name,
            labelAsJsx: <CampaignDropdownLabel campaign={campaign} classificationEnabled={classificationEnabled}/>
        } : {label: `-- ${localizer.msg('idea.fields.campaign.please-select')} --`, value: DEFAULT_CAMPAIGN_ID});
        setValue(IdeaFormFieldKeys.LANGUAGE, existingValues?.languageId);
    }, [classificationEnabled, localizer, reset, setValue]);

    const onSelectDraft = useCallback(async (selectedDraftId: number, submitterId: number) => {
        try {
            setMode('EDIT_DRAFT');
            setIsLoadingForm(true);
            draftId.current = selectedDraftId;
            draftSubmitterId.current = submitterId;
            const data = await fetchFieldsByIdeaId(selectedDraftId);
            resetClassificationSelection();
            setIdeaFormFields(data);
            resetFormFields(data, {}, 'EDIT_DRAFT');
        } catch (error) {
            handleErrorResponse(error);
        } finally {
            setIsLoadingForm(false);
        }
    }, [fetchFieldsByIdeaId, handleErrorResponse, resetClassificationSelection, resetFormFields]);

    const onSelectCampaign = useCallback(async (campaignId: number) => {
        if (aiTextAssistEnabled) {
            eventDispatcher.dispatch(FEATURE_TOUR_EVENTS.PAUSE_TOUR);
        }
        try {
            const existingValues = getValues();
            setIsLoadingForm(true);
            const data = await fetchFieldsByCampaignId(campaignId);
            resetClassificationSelection();
            setIdeaFormFields(data);
            resetFormFields(data, existingValues);
        } catch (error: any) {
            handleErrorResponse(error);
        } finally {
            setIsLoadingForm(false);
        }
    }, [aiTextAssistEnabled, fetchFieldsByCampaignId, getValues, handleErrorResponse, resetClassificationSelection, resetFormFields]);

    const refetchIdeaList = useCallback(() => {
        const pathSplit = location.pathname.split('/');
        if (pathSplit.includes('ideas') || pathSplit.includes('home') || (location.pathname === '/' && defaultPage === 'home')) {
            queryClient.invalidateQueries(QUERY_KEYS.IDEA_LIST).then();
        }
    }, [defaultPage, location, queryClient]);

    const moveToRecycleBin = useCallback(() => {
        if (initialEditIdeaId) {
            moveToRecycle(initialEditIdeaId, BasicStagesId.RECYCLE_BIN).then();
            toggle();
            refetchIdeaList();
        }
    }, [initialEditIdeaId, moveToRecycle, refetchIdeaList, toggle]);

    const renderLanguageField = () => {
        if (shouldRenderLanguageField && mode !== 'COPY_IDEA') {
            return <LanguageField localizer={localizer} detectLanguage={detectLanguage}/>;
        } else if (shouldRenderLanguageField && mode === 'COPY_IDEA' && ideaFormFields.language) {
            return <LanguageHiddenField language={ideaFormFields.language}/>;
        } else {
            return <Fragment/>;
        }
    };

    const onSubmitError = (errors: FieldErrors) => {
        if (IdeaFormFieldKeys.TERMS_OF_SERVICE in errors) {
            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.warn, localizer.msg('idea.form.field.errors.campaign-tos-required')));
        } else if (COMMON_VALIDATION_ERROR in errors) {
            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.warn, errors[COMMON_VALIDATION_ERROR].message));
        }
    };

    const resetForSubmitAnotherIdea = useCallback(async () => {
        if (aiTextAssistEnabled) {
            eventDispatcher.dispatch(FEATURE_TOUR_EVENTS.PAUSE_TOUR);
        }
        setLastSubmittedIdea(null);
        setMode('CREATE_IDEA');
        setIsLoadingForm(true);
        const data = await fetchFieldsByCampaignId(selectedCampaignId || 0);
        resetClassificationSelection();
        setIdeaFormFields(data);
        draftId.current = 0;
        resetFormFields(data);
        setIsLoadingForm(false);
    }, [aiTextAssistEnabled, fetchFieldsByCampaignId, resetClassificationSelection, resetFormFields, selectedCampaignId, setLastSubmittedIdea]);

    const onSubmitSuccess = async (submittedData: FieldValues, submitAnother: boolean = false) => {
        const ideaSubmissionRequest = new IdeaSubmissionRequestBuilder(submittedData, draftId.current, ideaFormFields, ideaFormFields.copiedIdeaAuthorId, appliedClassifications, appliedAttachmentClassifications).build();
        try {
            if (mode === 'EDIT_IDEA' && initialEditIdeaId && editIdea) {
                const modifiedSummaryPreview = await editIdea(initialEditIdeaId, ideaSubmissionRequest);
                lastSubmittedIdeaRef.current = modifiedSummaryPreview as IdeaDetail;
                refetchIdeaList();
                toggle();
                queryClient.invalidateQueries([QUERY_KEYS.IDEA_ATTACHMENTS, initialEditIdeaId]).then();
            } else {
                const summaryPreview = await createIdea(ideaSubmissionRequest);
                lastSubmittedIdeaRef.current = summaryPreview;
                refetchIdeaList();
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, localizer.msg('idea.form.success.submit')));
                queryClient.invalidateQueries(QUERY_KEYS.DRAFT_IDEAS).then();
                if (submitAnother) {
                    await resetForSubmitAnotherIdea();
                } else {
                    setLastSubmittedIdea(summaryPreview);
                    toggle();
                }
            }
            await queryClient.invalidateQueries(QUERY_KEYS.CAMPAIGN_FUNNEL);
        } catch (error: any) {
            handleErrorResponse(error, {setFormError: setError});

            if ([ErrorReason.SUBMITTED_IDEA_NOT_ACCESSIBLE].includes(error.data?.reason)) {
                if (submitAnother) {
                    await resetForSubmitAnotherIdea();
                } else {
                    toggle();
                }
            }
        }
    };

    const onSubmitDraft = async () => {
        if (aiTextAssistEnabled) {
            eventDispatcher.dispatch(FEATURE_TOUR_EVENTS.PAUSE_TOUR);
        }
        const formSubmissionData = getValues();
        const ideaSubmissionRequest = new IdeaSubmissionRequestBuilder(formSubmissionData, draftId.current, ideaFormFields, undefined, appliedClassifications, appliedAttachmentClassifications).build();
        ideaSubmissionRequest.saveAsDraft = true;
        setIsSubmittingDraft(true);
        try {
            await createIdea(ideaSubmissionRequest);
            resetClassificationSelection();
            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, localizer.msg('idea.form.success.draft')));
            if (location.pathname.split('/').includes('draft')) {
                queryClient.invalidateQueries(QUERY_KEYS.IDEA_LIST).then();
            }
            setMode('CREATE_IDEA');
            setIsLoadingForm(true);
            const data = await fetchFieldsByCampaignId(selectedCampaignId);
            setIdeaFormFields(data);
            draftSubmitterId.current = null;
            draftId.current = 0;
            resetFormFields(data);
            queryClient.invalidateQueries(QUERY_KEYS.DRAFT_IDEAS).then();
        } catch (error: any) {
            handleErrorResponse(error, {
                setFormError: setError,
                errorHandler: (error) => {
                    if (error.data?.reason) {
                        toggle();
                        eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.warn, localizer.msg(`frontend-shared.errors.${error.data.reason.toLowerCase()}`)));
                        if (query.get(ROUTES.QUERY_PARAMS.SUBMITTER_ID)) {
                            reloadPage(3000);
                        }
                    } else {
                        showErrorMessage(error);
                    }
                }
            });
        } finally {
            setIsLoadingForm(false);
            setIsSubmittingDraft(false);
        }
    };

    const getClassificationConfigData = useCallback((enableClassification: boolean) => {
        return {
            enableClassification: enableClassification,
            classifications: classificationsMenuData,
            selectedClassificationsData: appliedClassifications,
            updateAppliedClassifications
        };
    }, [appliedClassifications, classificationsMenuData, updateAppliedClassifications]);

    const getAttachmentClassificationConfigData = useCallback((enableClassification: boolean, fieldKey: string) => {
        return {
            enableClassification: enableClassification,
            classifications: classificationsMenuData,
            selectedClassificationsData: appliedClassifications,
            updateAppliedAttachmentClassifications,
            appliedAttachmentClassifications: appliedAttachmentClassifications[fieldKey]
        };
    }, [appliedAttachmentClassifications, appliedClassifications, classificationsMenuData, updateAppliedAttachmentClassifications]);

    const getIdeaTitle = useCallback(() => {
        return getValues(IdeaFormFieldKeys.TITLE);
    }, [getValues]);

    const suggestAtMentionUsers = useCallback(async (searchTerm: string, formField: CustomField) => {
        const isPrivateIdea = getValues(IdeaFormFieldKeys.ADMIN_OR_MODERATOR_ONLY) ?? false;
        const params = {
            term: searchTerm,
            campaignId: selectedCampaignId,
            ideaId: (mode === 'EDIT_IDEA' && initialEditIdeaId) ? initialEditIdeaId : 0,
            privateIdea: isPrivateIdea || formField.privateField,
            customFieldId: formField.id
        };
        return await fetchAtMentionsIdeaCustomField?.(params) ?? [];
    }, [fetchAtMentionsIdeaCustomField, getValues, initialEditIdeaId, mode, selectedCampaignId]);

    const getAiAssistedIdeaDescription = useCallback((prompt: string, fieldName: AiAssistanceFieldNames, chatId?: number, chatContinue?: boolean) => {
        return fetchAiAssistedDescription(prompt, fieldName, chatId, selectedCampaignId > 0 ? selectedCampaignId : undefined, chatContinue);
    }, [fetchAiAssistedDescription, selectedCampaignId]);

    const renderField = (ideaField: IdeaFieldData, isFirstField: boolean) => {
        switch (ideaField.type) {
            case FieldType.LABELS:
                break;
            case FieldType.TITLE:
                return (
                    <AdditionalInput
                        classifications={classificationsMenuData}
                        enabledClassification={classificationEnabled}
                        fieldKey={ideaField.key}
                        additionalConfig={ideaFormFields.additionalConfig}
                        updateAppliedClassifications={updateAppliedClassifications}
                        render={
                            ({enableClassification}) =>
                                <TitleField ideaField={ideaField}
                                            localizer={localizer}
                                            autoFocus={isFirstField}
                                            svgIcons={svgIconsPath}
                                            containerId={FORM_ID}
                                            classificationConfig={getClassificationConfigData(enableClassification)}
                                />
                        }/>
                );
            case FieldType.HAPYAK:
            case FieldType.YOUTUBE:
            case FieldType.SCREENR:
            case FieldType.HYPERLINK:
            case FieldType.TEXTINPUT:
            case FieldType.INTEGER:
                return <AdditionalInput
                    classifications={classificationsMenuData}
                    enabledClassification={classificationEnabled}
                    fieldKey={ideaField.key}
                    additionalConfig={ideaFormFields.additionalConfig}
                    updateAppliedClassifications={updateAppliedClassifications}
                    render={
                        ({enableClassification}) =>
                            <TextField formField={ideaField}
                                       autoFocus={isFirstField}
                                       localizer={localizer}
                                       classificationConfig={getClassificationConfigData(enableClassification)}/>
                    }/>;
            case FieldType.ZIPCODE:
                return <AdditionalInput
                    classifications={classificationsMenuData}
                    enabledClassification={classificationEnabled}
                    fieldKey={ideaField.key}
                    additionalConfig={ideaFormFields.additionalConfig}
                    updateAppliedClassifications={updateAppliedClassifications}
                    render={
                        ({enableClassification}) =>
                            <TextField formField={ideaField}
                                       maxLength={enableClassification ? 100 : US_ZIPCODE_MAXLENGTH}
                                       autoFocus={isFirstField}
                                       localizer={localizer}
                                       classificationConfig={getClassificationConfigData(enableClassification)}/>
                    }/>;
            case FieldType.DESCRIPTION:
                return <AdditionalInput
                    classifications={classificationsMenuData}
                    enabledClassification={classificationEnabled}
                    fieldKey={ideaField.key}
                    additionalConfig={ideaFormFields.additionalConfig}
                    render={
                        ({enableClassification}) =>
                            <DescriptionField
                                autoFocus={isFirstField}
                                svgIconsPath={svgIconsPath}
                                maxFileSizeLimit={maxFileSizeLimit}
                                localizer={localizer}
                                ideaField={ideaField}
                                campaignId={selectedCampaignId}
                                atMentionEnabled={atMentionEnabled}
                                fetchAtMentionMembers={fetchAtMentionMembers}
                                offensiveEmojis={offensiveEmojis}
                                ideaId={(mode === 'EDIT_IDEA' && initialEditIdeaId) ? initialEditIdeaId : 0}
                                tempImageUpload={tempImageUpload}
                                classificationConfig={getClassificationConfigData(enableClassification)}
                                defaultFileNamePrefix={EMBEDDED_FILE_NAME_PREFIX.IDEA_DESCRIPTION}
                                getIdeaTitle={getIdeaTitle}
                                userAvatar={actor.avatar}
                                aiAssistanceEnabled={aiAssistanceEnabled}
                                fetchAiAssistedDescription={getAiAssistedIdeaDescription}
                            />
                    }/>;
            case FieldType.TEXT_AREA:
                return <AdditionalInput
                    classifications={classificationsMenuData}
                    enabledClassification={classificationEnabled}
                    fieldKey={ideaField.key}
                    additionalConfig={ideaFormFields.additionalConfig}
                    updateAppliedClassifications={updateAppliedClassifications}
                    render={
                        ({enableClassification}) =>
                            <TextAreaField
                                formField={ideaField}
                                localizer={localizer}
                                autoFocus={isFirstField}
                                tempImageUpload={tempImageUpload}
                                maxFileSize={maxFileSizeLimit}
                                classificationConfig={{
                                    ...getClassificationConfigData(enableClassification),
                                    selectedClassificationsData: {}
                                }}
                                defaultFileNamePrefix={EMBEDDED_FILE_NAME_PREFIX.IDEA_CUSTOM_FIELD}
                                atMentionEnabled={atMentionEnabled}
                                fetchAtMentionMembers={suggestAtMentionUsers}
                            />
                    }/>;
            case FieldType.CURRENCY:
                return <AdditionalInput
                    classifications={classificationsMenuData}
                    enabledClassification={classificationEnabled}
                    fieldKey={ideaField.key}
                    additionalConfig={ideaFormFields.additionalConfig}
                    updateAppliedClassifications={updateAppliedClassifications}
                    render={
                        ({enableClassification}) =>
                            <CurrencyField formField={ideaField}
                                           autoFocus={isFirstField}
                                           localizer={localizer}
                                           classificationConfig={getClassificationConfigData(enableClassification)}/>
                    }/>;
            case FieldType.CHECKBOX:
                return <AdditionalInput
                    classifications={classificationsMenuData}
                    enabledClassification={classificationEnabled}
                    fieldKey={ideaField.key}
                    additionalConfig={ideaFormFields.additionalConfig}
                    updateAppliedClassifications={updateAppliedClassifications}
                    render={
                        ({enableClassification}) =>
                            <CheckboxField formField={ideaField}
                                           autoFocus={isFirstField}
                                           localizer={localizer}
                                           classificationConfig={getClassificationConfigData(enableClassification)}
                            />
                    }/>;
            case FieldType.SINGLE_CHOICE:
                return <AdditionalInput
                    classifications={classificationsMenuData}
                    enabledClassification={classificationEnabled}
                    fieldKey={ideaField.key}
                    additionalConfig={ideaFormFields.additionalConfig}
                    updateAppliedClassifications={updateAppliedClassifications}
                    render={
                        ({enableClassification}) =>
                            <SingleChoiceField localizer={localizer} formField={ideaField}
                                               autoFocus={isFirstField}
                                               defaultValue={(mode === 'EDIT_IDEA' || mode === 'EDIT_DRAFT') ? CustomField.getSingleChoiceSavedValue(ideaField) : CustomField.getSingleChoiceDefaultValue(ideaField)}
                                               classificationConfig={getClassificationConfigData(enableClassification)}/>
                    }/>;
            case FieldType.MULTIPLE_CHOICE:
                return <AdditionalInput
                    classifications={classificationsMenuData}
                    enabledClassification={classificationEnabled}
                    fieldKey={ideaField.key}
                    additionalConfig={ideaFormFields.additionalConfig}
                    updateAppliedClassifications={updateAppliedClassifications}
                    render={
                        ({enableClassification}) =>
                            <fieldset aria-label={ideaField.label}>
                                <MultipleChoiceField formField={ideaField}
                                                     autoFocus={isFirstField}
                                                     localizer={localizer}
                                                     defaultValues={(mode === 'EDIT_IDEA' || mode === 'EDIT_DRAFT') ? CustomField.getMultipleChoiceSavedValues(ideaField) : CustomField.getMultipleChoiceDefaultValues(ideaField)}
                                                     classificationConfig={getClassificationConfigData(enableClassification)}/>
                            </fieldset>
                    }/>;
            case FieldType.DATE:
                return <AdditionalInput
                    classifications={classificationsMenuData}
                    enabledClassification={classificationEnabled}
                    fieldKey={ideaField.key}
                    additionalConfig={ideaFormFields.additionalConfig}
                    updateAppliedClassifications={updateAppliedClassifications}
                    render={
                        ({enableClassification}) =>
                            <DateField
                                localizer={localizer}
                                formField={ideaField}
                                dateFormat={dateTimeFormat.datePattern}
                                autoFocus={isFirstField}
                                classificationConfig={getClassificationConfigData(enableClassification)}/>
                    }/>;
            case FieldType.CO_SUBMITTER:
                return !hiddenAuthorField &&
                    <AdditionalInput
                        classifications={classificationsMenuData}
                        enabledClassification={classificationEnabled}
                        fieldKey={ideaField.key}
                        additionalConfig={ideaFormFields.additionalConfig}
                        updateAppliedClassifications={updateAppliedClassifications}
                        render={
                            ({enableClassification}) =>
                                <CoSubmitterField
                                    autoFocus={isFirstField}
                                    ideaId={(mode === 'EDIT_IDEA' && initialEditIdeaId) ? initialEditIdeaId : 0}
                                    campaignId={selectedCampaignId}
                                    localizer={localizer}
                                    ideaField={ideaField}
                                    fetchCoSubmitters={fetchContributors}
                                    classificationConfig={getClassificationConfigData(enableClassification)}/>
                        }/>;
            case FieldType.ON_BEHALF_OF:
                return <AdditionalInput
                    classifications={classificationsMenuData}
                    enabledClassification={classificationEnabled}
                    fieldKey={ideaField.key}
                    additionalConfig={ideaFormFields.additionalConfig}
                    updateAppliedClassifications={updateAppliedClassifications}
                    render={
                        ({enableClassification}) =>
                            <SubmitOnBehalfField
                                autoFocus={isFirstField}
                                ideaId={(mode === 'EDIT_IDEA' && initialEditIdeaId) ? initialEditIdeaId : 0}
                                campaignId={selectedCampaignId}
                                localizer={localizer}
                                ideaField={ideaField}
                                fetchOnBehalfMembers={fetchOnBehalfMembers}
                                classificationConfig={getClassificationConfigData(enableClassification)}/>
                    }/>;
            case FieldType.LINK_IDEAS:
                return <LinkIdeasField
                    autoFocus={isFirstField}
                    localizer={localizer}
                    ideaId={(mode === 'EDIT_IDEA' && initialEditIdeaId) ? initialEditIdeaId : 0}
                    ideaField={ideaField}
                    fetchLinkableIdeas={fetchLinkableIdeas}
                    fetchLinkableIdeasByKeywords={fetchLinkableIdeasByKeywords}/>;
            case FieldType.TAGS:
                return <TagsField
                    autoFocus={isFirstField}
                    localizer={localizer}
                    ideaField={ideaField}
                    fetchTags={fetchInputTags}
                    campaignId={selectedCampaignId}
                    fetchSuggestedTags={fetchSuggestedTags}/>;
            case FieldType.MODERATOR_OR_ADMIN_ONLY:
                return <AdditionalInput
                    classifications={classificationsMenuData}
                    enabledClassification={classificationEnabled}
                    fieldKey={ideaField.key}
                    additionalConfig={ideaFormFields.additionalConfig}
                    updateAppliedClassifications={updateAppliedClassifications}
                    render={
                        ({enableClassification}) =>
                            <ModeratorOrAdminOnlyField localizer={localizer} ideaField={ideaField}
                                                       classificationConfig={getClassificationConfigData(enableClassification)}/>
                    }/>;
            case FieldType.HIDDEN_AUTHOR:
                return <HideAuthorField localizer={localizer} ideaField={ideaField} helpSiteBaseUrl={helpSiteBaseUrl}/>;
            case FieldType.GLOBAL_ZIPCODE:
                return <AdditionalInput
                    classifications={classificationsMenuData}
                    enabledClassification={classificationEnabled}
                    fieldKey={ideaField.key}
                    additionalConfig={ideaFormFields.additionalConfig}
                    updateAppliedClassifications={updateAppliedClassifications}
                    render={
                        ({enableClassification}) =>
                            <GlobalZipcodeField localizer={localizer} ideaField={ideaField} autoFocus={isFirstField}
                                                classificationConfig={getClassificationConfigData(enableClassification)}/>
                    }/>;
            case FieldType.ATTACHMENT:
                return <AdditionalInput
                    classifications={classificationsMenuData}
                    enabledClassification={classificationEnabled}
                    fieldKey={ideaField.key}
                    additionalConfig={ideaFormFields.additionalConfig}
                    updateAppliedAttachmentClassifications={updateAppliedAttachmentClassifications}
                    render={
                        ({enableClassification}) =>
                            <AttachmentField
                                autoFocus={isFirstField}
                                localizer={localizer}
                                ideaField={ideaField}
                                maxFileSizeLimit={maxFileSizeLimit}
                                uploadFile={onUploadFile}
                                mode={mode}
                                defaultAttachments={mode === 'EDIT_DRAFT' ? [] : ideaField.attachments as FileAttachmentDetail[]}
                                existingAttachments={{
                                    defaultAttachments: existingDefaultAttachments,
                                    customAttachments: existingCustomAttachments,
                                    enabled: ideaField.key !== IdeaFormFieldKeys.ATTACHMENT && mode === 'EDIT_IDEA',
                                    attributes: ClassificationUtil.getAppliedFileClassifications('filename', ideaFormFields.additionalConfig?.classificationAttributes)
                                }}
                                svgIconPath={svgIconsPath}
                                classificationConfig={getAttachmentClassificationConfigData(enableClassification, ideaField.key)}/>
                    }/>;
            default:
                return null;
        }
    };

    useEffect(() => {
        const loadInitialFormFields = async () => {
            if (open) {
                try {
                    let data: IdeaFormFields | undefined;
                    if (defaultMode === 'COPY_IDEA' && initialEditIdeaId) {
                        data = await fetchCopyIdeaFormFieldsByIdeaId(initialEditIdeaId);
                    } else if (defaultMode === 'EDIT_IDEA' && initialEditIdeaId) {
                        data = await fetchFieldsByIdeaId(initialEditIdeaId);
                    } else if (defaultMode === 'EDIT_DRAFT' && initialDraftIdeaId) {
                        data = await fetchFieldsByIdeaId(Number(initialDraftIdeaId));
                    } else if (initialCampaignContext) {
                        data = await fetchFieldsByCampaignId(initialCampaignContext.id);
                    } else {
                        data = await fetchFieldsByCampaignId(0);
                    }
                    setIdeaFormFields(data);

                    if (defaultMode === 'EDIT_DRAFT') {
                        resetFormFields(data, {}, defaultMode);
                    }

                    if (data.campaign) {
                        setValue(IdeaFormFieldKeys.CAMPAIGN, data.campaign ? {
                            label: data.campaign.name,
                            value: data.campaign.id,
                            labelAsJsx: <CampaignDropdownLabel campaign={data.campaign}
                                                               classificationEnabled={classificationEnabled}/>
                        } : {
                            label: `-- ${localizer.msg('idea.fields.campaign.please-select')} --`,
                            value: DEFAULT_CAMPAIGN_ID
                        });
                    }
                } catch (error: any) {
                    handleErrorResponse(error, {
                        errorHandler: (error) => {
                            showErrorMessage(error);
                            toggle();
                        }
                    });
                } finally {
                    setIsLoadingForm(false);
                }
            }
        };
        void loadInitialFormFields();
    }, [defaultMode, fetchCopyIdeaFormFieldsByIdeaId, fetchFieldsByCampaignId, fetchFieldsByIdeaId, initialEditIdeaId, initialCampaignContext, initialDraftIdeaId, localizer, open, setValue, toggle, handleErrorResponse, showErrorMessage, classificationEnabled, resetFormFields]);

    const scrollbarContainer = useRef<ScrollbarInstance>(null);

    useEffect(() => {
        if (ideaFormFields.instruction) {
            CommonUtil.wait(100).then(() => scrollbarContainer?.current?.scrollToTop());
        }
    }, [ideaFormFields.instruction]);

    useInterval(async () => {
        if ((mode === 'CREATE_IDEA' || mode === 'EDIT_DRAFT') && selectedCampaignId) {
            const formSubmissionData = getValues();
            const autoSaveRequest = new IdeaSubmissionRequestBuilder(formSubmissionData, draftId.current, ideaFormFields, undefined, appliedClassifications, appliedAttachmentClassifications).build();
            autoSaveRequest.autoSaveDraft = true;
            try {
                const {id} = await createIdea(autoSaveRequest);
                if (id) {
                    draftId.current = id;
                }
                await queryClient.invalidateQueries(QUERY_KEYS.DRAFT_IDEAS);
            } catch (error: any) {
                handleErrorResponse(error);
            }
        }
    }, AUTO_SAVE_DRAFT_INTERVAL);

    return (
        <Fragment>
            <PageTitle
                title={`${mode === 'EDIT_IDEA' ? localizer.msg('common.edit-idea') : localizer.msg('common.submit-idea')} | ${communityName}`}/>
            <HookFormProvider {...methods}>
                <Modal isOpen={open} modalBodyId={MODAL_BODY_ID}
                       closeOnEscape={false} toggle={() => {
                    lastSubmittedIdeaRef.current && setLastSubmittedIdea(lastSubmittedIdeaRef.current);
                    toggle();
                }} className="modal-lg idea-form-modal"
                       title={
                           mode === 'EDIT_IDEA'
                               ? localizer.msg('idea.form.heading.edit')
                               : localizer.msg('idea.form.heading.submission')
                       }
                       modalBodyClassName="pb-4 pt-0 px-0"
                       aria-label={
                           mode === 'EDIT_IDEA'
                               ? localizer.msg('idea.form.heading.edit')
                               : localizer.msg('idea.form.heading.submission')}
                       modalFooterClassName="d-flex justify-content-between box-shadow">

                    <form className="position-relative showing idea-submission-form"
                          id={FORM_ID}
                          onSubmit={onFormSubmit((data) => onSubmitSuccess(data, false), isSubmitting || isSubmittingDraft || fileUploading || isLoadingForm, onSubmitError)}
                    >

                        <Scrollbar ref={scrollbarContainer} autoHeight autoHeightMin="60vh" autoHeightMax="80vh">
                            <div className="px-5 form-scroll-wrapper">
                                <div className="d-flex justify-content-end mb-lg-4 mb-2">
                                    {
                                        (mode === 'CREATE_IDEA' || mode === 'EDIT_DRAFT') &&
                                        <DraftIdeas
                                            localizer={localizer}
                                            fetchDraftIdeas={fetchDrafts}
                                            deleteDraftIdea={deleteDraft}
                                            onClickDraft={onSelectDraft}
                                        />
                                    }
                                </div>
                                {
                                    ideaFormFields.instruction &&
                                    <div className="alert alert-info" role="alert">
                                        <HtmlRenderer content={ideaFormFields.instruction}
                                                      elementId="idea-submit-instruction"/>
                                    </div>
                                }
                                {
                                    renderLanguageField()
                                }
                                <CampaignDropdown
                                    localizer={localizer}
                                    defaultCampaign={ideaFormFields.campaign}
                                    fetchCampaigns={fetchCampaigns}
                                    onSelectCampaign={onSelectCampaign}
                                    isDisabled={isCampaignDropdownDisabled}
                                    autoFocus={!isCampaignDropdownDisabled}
                                    ideaFormFields={ideaFormFields}
                                />

                                {
                                    ideaFormFields.campaign &&
                                    <div className="mt-n2 mb-3">
                                        {
                                            ideaFormFields.campaign.subtitle ? (
                                                <Fragment>
                                            <span className="me-1 text-break">
                                                {StringUtil.textTruncate(ideaFormFields.campaign.subtitle, SUBTITLE_LENGTH)}
                                            </span>
                                                    <Link
                                                        to={appLinks.aboutCampaign(ideaFormFields.campaign.id.toString())}
                                                        target="_blank">
                                                        {localizer.msg('sidebar.campaign-activity.see-more')}
                                                    </Link>
                                                </Fragment>
                                            ) : (
                                                <Link to={appLinks.aboutCampaign(ideaFormFields.campaign.id.toString())}
                                                      target="_blank">
                                                    {localizer.msg('sidebar.campaign-activity.see-details')}
                                                </Link>
                                            )
                                        }
                                    </div>
                                }
                                <div className="pt-3 pb-4">
                                    {isLoadingForm
                                        ? <IdeaFormSkeleton/>
                                        : <Fragment>
                                            {
                                                ideaFormFields.formSections.map((section, sectionIndex) => {
                                                    return FormSectionData.isSection(section)
                                                        ?
                                                        <FormSection localizer={localizer} section={section}
                                                                     key={sectionIndex}>
                                                            {
                                                                section.formFields.map((field, fieldIndex) =>
                                                                    <Fragment key={field.key}>
                                                                        {renderField(field, (isCampaignDropdownDisabled) && sectionIndex === 0 && fieldIndex === 0)}
                                                                    </Fragment>
                                                                )
                                                            }
                                                        </FormSection>
                                                        : section.formFields.map((field, fieldIndex) =>
                                                            <Fragment key={field.key}>
                                                                {renderField(field, (isCampaignDropdownDisabled) && sectionIndex === 0 && fieldIndex === 0)}
                                                            </Fragment>
                                                        );
                                                })
                                            }
                                            {
                                                (selectedCampaignId > DEFAULT_CAMPAIGN_ID && ideaFormFields.campaignTosNotAccepted) &&
                                                <TermsOfServiceField
                                                    localizer={localizer}
                                                    campaign={{
                                                        id: selectedCampaignOption.value,
                                                        name: selectedCampaignOption.label
                                                    }}/>
                                            }
                                        </Fragment>
                                    }
                                </div>
                            </div>
                        </Scrollbar>
                        <div className="d-flex px-5 pt-3">
                            {
                                ideaFormFields.labelApplicable &&
                                <LabelsField defaultValue={ideaFormFields.customLabelIds || []}
                                             localizer={localizer}
                                             fetchLabels={fetchIdeaLabelsToAdd}
                                             svgIconPath={svgIconsPath}
                                             targetId={initialEditIdeaId || draftId.current || 0}
                                             fieldKey={IdeaFormFieldKeys.LABELS}/>
                            }
                            <FormButtons
                                draftsSubmitterId={draftSubmitterId.current}
                                moveToRecycleBinAllowed={ideaFormFields.moveToRecycleBinAllowed}
                                formId={FORM_ID}
                                isLoadingForm={isLoadingForm}
                                localizer={localizer}
                                onSaveAsDraft={onSubmitDraft}
                                moveToRecycleBin={moveToRecycleBin}
                                onSubmitSuccess={onSubmitSuccess}
                                onSubmitError={onSubmitError}
                                mode={mode}
                                isSubmittingDraft={isSubmittingDraft}
                                fileUploading={fileUploading}
                            />
                        </div>
                    </form>
                </Modal>
            </HookFormProvider>
        </Fragment>
    );
};

const createFieldValues = (ideaFormFields: IdeaFormFields, existingValues?: Record<string, any>, mode?: IdeaFormMode) => {
    const {formSections, language, customLabelIds} = ideaFormFields;
    let defaultValues: Record<string, any> = {};
    formSections.forEach(section => {
        section.formFields.forEach(field => {
            if (field.type === FieldType.ATTACHMENT) {
                defaultValues[field.key] = field.attachments || [];
            } else if (field.type === FieldType.CO_SUBMITTER) {
                defaultValues[field.key] = (field.contributors || []).map(contributor => {
                    return {value: contributor.id, label: contributor.name};
                });
            } else if (field.type === FieldType.ON_BEHALF_OF) {
                defaultValues[field.key] = field.submitOnBehalf ? {
                    value: field.submitOnBehalf.id,
                    label: field.submitOnBehalf.name
                } : null;
            } else if (field.type === FieldType.TAGS) {
                defaultValues[field.key] = stringToOptionType(field.tags || []);
            } else if (field.type === FieldType.LINK_IDEAS) {
                defaultValues[field.key] = field.linkedIdeas || [];
            } else if (field.type === FieldType.SINGLE_CHOICE) {
                if (existingValues && existingValues[field.key]) {
                    defaultValues[field.key] = existingValues[field.key];
                } else {
                    defaultValues[field.key] = (mode === 'EDIT_DRAFT' || mode === 'EDIT_IDEA') ? CustomField.getSingleChoiceSavedValue(field) : CustomField.getSingleChoiceDefaultValue(field);
                }
            } else if (field.type === FieldType.MULTIPLE_CHOICE) {
                if (existingValues && existingValues[field.key] && existingValues[field.key].length > 0) {
                    defaultValues[field.key] = existingValues[field.key];
                } else {
                    defaultValues[field.key] = (mode === 'EDIT_DRAFT' || mode === 'EDIT_IDEA') ? CustomField.getMultipleChoiceSavedValues(field) : CustomField.getMultipleChoiceDefaultValues(field);
                }
            } else {
                defaultValues[field.key] = (existingValues && existingValues[field.key]) || field.value || '';
            }
        });
    });
    defaultValues[IdeaFormFieldKeys.LABELS] = language
        ? {value: language.id, label: language.name}
        : DEFAULT_LANGUAGE_OPTION;
    defaultValues[IdeaFormFieldKeys.LABELS] = customLabelIds || [];
    return defaultValues;
};