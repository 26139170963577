import {BaseIdeaStageSummary} from './BaseIdeaStageSummary';
import {StageSummaryData} from './types/StageSummaryData';

export class AssessmentStageSummary extends BaseIdeaStageSummary {
    constructor(
        public stage: StageSummaryData,
        public viewAllowed: boolean,
        public participationAllowed: boolean,
        public percentage: number,
        public callToActionLabel: string,
        public availableStatistic: boolean,
        public avgRating: number,
        public reviewCompletedCount: number,
        public reviewMemberCount: number,
        public viewOverallRating: boolean,
    ) {
        super(stage, viewAllowed, participationAllowed, percentage, callToActionLabel);
    }

    getIconName(): string {
        return 'star-solid';
    }

    static isHalfStarPrinted(counter: number, avgRating: number): boolean {
        const fractionalNumber = AssessmentStageSummary.getFractionalNumberOfAvgNumber(counter, avgRating);
        return (fractionalNumber >= 0.50 && fractionalNumber <= 0.99);
    }

    private static getFractionalNumberOfAvgNumber(counter: number, avgNumber: number): number {
        let fractionalNumber: number = 0.0;
        if (avgNumber > 0.0 && counter - avgNumber <= 0.50) {
            let integerPart: number = parseInt(String(avgNumber));
            fractionalNumber = avgNumber - integerPart;
        }
        return fractionalNumber;
    }
}