import React from 'react';
import {Trans} from 'react-i18next';
import {Actor, Localizer, Member, MemberLink, TimeAgo} from '@ideascale/commons';

type LastEditedByProps = {
    localizer: Localizer;
    actor: Actor;
    editedBy: Member;
    time: string;
}

export const LastEditedBy = (props: LastEditedByProps) => {
    const {localizer, actor, editedBy, time} = props;
    return (
        <Trans i18nKey="comments.edited-by"
               values={{author: actor.id === editedBy.id ? localizer.msg('common.you') : editedBy.name}}>
            <MemberLink className="fw-bold" id={editedBy.id} identityHidden={editedBy.identityHidden}/>
            <TimeAgo localizer={localizer} dateISOString={time} prefixText={false}/>
        </Trans>
    );
};