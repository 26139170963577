import {CommunityHomeEditor} from './Editors/CommunityHomeEditor';
import {Command} from './Command';
import {ImageField} from 'models/edit-mode/ImageField';
import {CommunityOperationType} from 'models/edit-mode/CommunityOperationType';
import {Operation} from 'models/edit-mode/Operation';
import {ChangeCustomFaviconOperation} from 'models/edit-mode/ChangeCustomFaviconOperation';

export class UpdateCustomFaviconCommand implements Command {
    private readonly communityHomeEditor: CommunityHomeEditor;
    private readonly oldCustomFavicon: ImageField;
    private readonly newCustomFavicon: ImageField;
    private readonly name: CommunityOperationType;

    constructor(communityHomeEditor: CommunityHomeEditor, newCustomFavicon: ImageField) {
        this.communityHomeEditor = communityHomeEditor;
        this.newCustomFavicon = newCustomFavicon;
        this.oldCustomFavicon = this.communityHomeEditor.customFavicon;
        this.name = CommunityOperationType.CHANGE_CUSTOM_FAVICON;
    }

    execute() {
        this.communityHomeEditor.customFavicon = this.newCustomFavicon;
    }

    getType(): CommunityOperationType {
        return this.name;
    }

    getOperation(): Operation {
        return new ChangeCustomFaviconOperation(this.name, this.communityHomeEditor.customFavicon.fileId);
    }

    undo(): void {
        this.communityHomeEditor.customFavicon = this.oldCustomFavicon;
    }
}