import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {ParagraphSkeleton} from '@ideascale/ui';
import {useCommunityService} from 'hooks/useService';
import {CampaignFunnel} from 'components/CampaignFunnel';
import {CampaignWorkFlowChart} from 'components/CampaignWorkFlowChart';
import {SidebarToggleButton} from 'components/shared/SidebarToggleButton';
import {CampaignWorkFlowData} from 'models/CampaignWorkFlowData';
import {CampaignDetailsData} from 'models/CampaignDetailsData';

type CampaignWorkflowContainerProps = {
    campaignId: number;
}

export const CampaignWorkflowContainer = (props: CampaignWorkflowContainerProps) => {
    const {campaignId} = props;
    const communityService = useCommunityService();
    const [campaignData, setCampaignData] = useState<CampaignDetailsData>(CampaignDetailsData.EMPTY);
    const [loading, setLoading] = useState(true);

    const fetchCampaignDetails = useCallback(async (campaignId: number): Promise<CampaignDetailsData> => {
        if (communityService !== null) {
            return await communityService.fetchCampaignDetails(campaignId);
        }
        return CampaignDetailsData.EMPTY;
    }, [communityService]);

    const fetchCampaignWorkflow = useCallback(async (campaignId: number): Promise<CampaignWorkFlowData> => {
        if (communityService !== null) {
            return await communityService.fetchCampaignWorkflow(campaignId);
        }
        return CampaignWorkFlowData.EMPTY;
    }, [communityService]);

    useEffect(() => {
        fetchCampaignDetails(campaignId).then(data => {
            setCampaignData(data);
            setLoading(false);
        });
    }, [campaignId, fetchCampaignDetails]);

    return (
        <Fragment>
            <div className="mb-3 d-flex d-lg-none justify-content-end"><SidebarToggleButton/></div>
            <div className="campaign-workflow">
                <CampaignWorkFlowChart fetchCampaignWorkflow={fetchCampaignWorkflow}/>
                <div className="card panel">
                    <div className="card-body">
                        {
                            loading
                                ? <ParagraphSkeleton rows={6}/>
                                : <CampaignFunnel campaignData={campaignData}/>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    );
};