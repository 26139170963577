import React, {Fragment, useEffect} from 'react';
import {AppLoading, AppThemeUtil} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {
    AlertContainer,
    ErrorPage,
    eventDispatcher,
    Favicon,
    PermissionDeniedModal,
    removeBodyTagClass,
    removeHtmlTagClass,
    toggleBodyTagClass,
    toggleHtmlTagClass,
    useCommunityTosRedirect,
    useUrlQuery,
    ViewPortMetaData,
} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {EditModeContextProvider} from 'contexts/EditModeContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {Scroller} from 'utils/Scroller';
import {SCROLL_EVENTS} from 'constants/AppConstants';
import {AppContainer} from 'containers/AppContainer';
import {AppEditModeContainer} from 'containers/AppEditModeContainer';
import {ROUTES} from 'shared/Routes';
import {CommunityConfig} from 'models/CommunityConfig';

export const App = () => {
    const {
        authentication,
        communityConfig,
        editModeEnabled,
        toggleEditModeEnabled,
        toggleDarkMode,
        pageError,
        setShowPermissionDeniedModal,
        showPermissionDeniedModal,
    } = useAppContext();
    const query = useUrlQuery();
    const localizer = useLocalizer();

    useCommunityTosRedirect({authentication});

    useEffect(() => {
        eventDispatcher.addListener(SCROLL_EVENTS.SCROLL_TOP, () => {
            Scroller.scrollToTop();
        });

        return () => {
            eventDispatcher.removeListener(SCROLL_EVENTS.SCROLL_TOP);
        };
    }, []);

    useEffect(() => {
        if (query.has(ROUTES.SEARCH_QUERY.EDIT_MODE) && (communityConfig.hasAdminEditPermissions() || communityConfig.hasTranslationPermission())) {
            toggleEditModeEnabled(true);
        }
        return () => {
            editModeEnabled && toggleEditModeEnabled(false);
        };
    }, [communityConfig, editModeEnabled, query, toggleEditModeEnabled]);

    useEffect(() => {
        if (editModeEnabled) {
            toggleBodyTagClass('edit-mode-enabled');
        }
        return () => {
            removeBodyTagClass('edit-mode-enabled');
        };
    }, [editModeEnabled]);

    useEffect(() => {
        if (communityConfig.classificationEnabled) {
            toggleHtmlTagClass('classification-enabled');
        }
        return () => {
            removeHtmlTagClass('classification-enabled');
        };
    }, [communityConfig.classificationEnabled]);

    useEffect(() => {
        const dark = AppThemeUtil.isDarkThemePreferred(authentication.actor.appTheme, authentication.actor.isAnonymous());
        toggleDarkMode(dark);
    }, [authentication.actor, toggleDarkMode]);

    return (
        <Fragment>
            <Favicon faviconImageUrl={communityConfig.faviconImageUrl}/>
            <ViewPortMetaData/>
            {
                (authentication.isEmpty() || communityConfig.id === CommunityConfig.EMPTY.id)
                    ? <Fragment>
                        <AlertContainer svgIconsPath={svgIconsPath} containerClass="top-0"/>
                        {
                            pageError
                                ? <ErrorPage localizer={localizer} error={pageError}/>
                                : <AppLoading/>
                        }
                    </Fragment>
                    :
                    <Fragment>
                        <AlertContainer svgIconsPath={svgIconsPath} containerClass="position-fixed"/>
                        {
                            editModeEnabled
                                ?
                                <EditModeContextProvider>
                                    <AppEditModeContainer/>
                                </EditModeContextProvider>
                                :
                                <Fragment>
                                    {
                                        !query.has(ROUTES.SEARCH_QUERY.EDIT_MODE) &&
                                        <AppContainer/>
                                    }
                                </Fragment>
                        }
                    </Fragment>
            }
            {
                showPermissionDeniedModal &&
                <PermissionDeniedModal open={showPermissionDeniedModal}
                                       toggle={setShowPermissionDeniedModal}
                                       descriptionText={localizer.msg('frontend-shared.permission-denied-modal.description')}
                                       buttonText={localizer.msg('frontend-shared.permission-denied-modal.button-text')}
                                       titleText={localizer.msg('frontend-shared.permission-denied-modal.title')}/>
            }
        </Fragment>
    );
};
