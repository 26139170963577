import React, {Fragment, useCallback, useMemo} from 'react';
import {LabelData} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {LabelStackedView} from 'components/shared/LabelStackedView';
import trending from 'assets/img/trending.png';

type LabelsViewProps = {
    labels: LabelData[];
    onLabelClick?: (labelData: LabelData) => void;
    showStackView?: boolean;
}

export const LabelsView = (props: LabelsViewProps) => {
    const {labels, showStackView = true, onLabelClick} = props;
    const localizer = useLocalizer();
    const onIdeaLabelClick = useCallback(
        (labelData: LabelData) => {
            if (!labelData.searchable) {
                return;
            }
            onLabelClick && onLabelClick(labelData);
        }, [onLabelClick]);

    const getLabelsExceptTrending = useCallback((labels: LabelData[]) => {
        return labels.filter(label => !LabelData.isTrendingTop10(label.id));
    }, []);

    const renderSingleLabels = useCallback((labels: LabelData[]) => {
        return (
            <Fragment>
                {
                    labels.length > 0 &&
                    <div key={'labels-list'}
                         aria-label={localizer.msg('frontend-shared.labels.idea-labels')}
                         role="list">
                        {
                            labels.map(labelData => <span role="listitem" key={labelData.id}>
                                <button
                                    aria-label={localizer.msg('frontend-shared.labels.idea-label-aria-label', {label: labelData.name})}
                                    className={`btn btn-link label single-label label-field-${labelData.colorKey} ${labelData.searchable ? 'cursor-pointer' : ''} me-2`}
                                    onClick={() => onIdeaLabelClick(labelData)}>
                                {labelData.name}
                            </button>
                            </span>)
                        }
                    </div>
                }
            </Fragment>
        );
    }, [localizer, onIdeaLabelClick]);

    const renderLabels = useMemo(() => {
        const labelsJsx: JSX.Element[] = [];
        labels?.forEach(label => {
            if (LabelData.isTrendingTop10(label.id)) {
                labelsJsx.push(
                    label.searchable
                        ? <button key={label.id}
                                  className="btn btn-link trending-idea-icon me-1 p-0"
                                  onClick={() => onIdeaLabelClick(label)}>
                            <img src={trending} alt="trending Idea filter" title="Trending Idea"/>
                        </button>
                        : <span key={label.id}
                                className="trending-idea-icon me-1"
                                onClick={() => onIdeaLabelClick(label)}>
                            <img src={trending} alt="trending Idea filter" title="Trending Idea"/>
                        </span>
                );
            }
        });

        if (getLabelsExceptTrending(labels).length >= 2 && showStackView) {
            labelsJsx.push(
                <LabelStackedView key={Date.now()}
                                  labels={getLabelsExceptTrending(labels)}
                                  clickable={true}
                                  onIdeaLabelClicked={label => onIdeaLabelClick(label)}
                                  localizer={localizer}
                />
            );
        } else {
            labelsJsx.push(renderSingleLabels(getLabelsExceptTrending(labels)));
        }
        return labelsJsx;
    }, [getLabelsExceptTrending, labels, localizer, onIdeaLabelClick, renderSingleLabels, showStackView]);

    return (
        <Fragment key={Date.now()}>
            {renderLabels}
        </Fragment>
    );
};