import React from 'react';

type StageDropdownHeaderProps = {
    groupName: string
    className?: string;
}
export const StageDropdownHeader = (props: StageDropdownHeaderProps) => {
    const {groupName = 'megaphone', className} = props;
    return (
        <span className={`d-flex align-items-center ${className ? className : ''}`}>
            <span className="label-text text-truncate">{groupName}</span>
        </span>
    );
};