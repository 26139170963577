import React, {useEffect, useMemo, useState} from 'react';
import {ClassificationsHolder, ClassificationUtil, CommentType, useApiErrorResponseHandler} from '@ideascale/commons';
import {Route, Routes} from 'react-router-dom';
import {useLocalizer} from 'hooks/useLocalizer';
import {useAppContext} from 'contexts/AppContext';
import {useClassificationService} from 'hooks/useClassificationService';
import {CommunityCommentsContainer} from './CommunityCommentsContainer';
import {StageCommentsContainer} from './StageCommentsContainer';
import {ROUTES} from 'shared/Routes';
import {SortItem} from 'models/comments/SortItem';
import {StageCommentOption} from 'models/comments/StageCommentOption';
import './CommentsContainer.scss';

type CommentsContainerProps = {
    ideaId: number;
    sortBy: SortItem;
    commentType: CommentType;
    stageCommentOption: StageCommentOption;
    hasStageCommentOptions: boolean;
    campaignId: number;
}

export const CommentsContainer = (props: CommentsContainerProps) => {
    const {ideaId, sortBy, stageCommentOption, hasStageCommentOptions, campaignId} = props;
    const localizer = useLocalizer();
    const {communityConfig: {classificationEnabled}} = useAppContext();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const {fetchClassifications} = useClassificationService();
    const [classificationsMenuData, setClassificationsMenuData] = useState<ClassificationsHolder | {}>({});

    useEffect(() => {
        if (classificationEnabled && campaignId) {
            (async () => {
                    try {
                        const response = await fetchClassifications(campaignId);
                        setClassificationsMenuData(response);
                    } catch (error: any) {
                        handleErrorResponse(error);
                    }
                }
            )();
        }
    }, [campaignId, classificationEnabled, fetchClassifications, handleErrorResponse]);

    const richTextClassificationConfig = useMemo(() => {
        return ClassificationUtil.prepareRichTextClassificationConfig(classificationEnabled, classificationsMenuData);
    }, [classificationEnabled, classificationsMenuData]);

    return (
        <>
            {
                hasStageCommentOptions &&
                <h3 className="font-size-md mb-4">
                    {
                        stageCommentOption.commentType === CommentType.COMMUNITY
                            ? localizer.msg('comments.community-comments-title')
                            : stageCommentOption.stageLabel
                    }
                </h3>
            }

            <Routes>
                {
                    [ROUTES.IDEA_DETAILS.STAGE_COMMENTS, `${ROUTES.IDEA_DETAILS.STAGE_COMMENTS}${ROUTES.IDEA_DETAILS.COMMENT}`].map((path: string) => (
                        <Route path={path} key={path}
                               element={
                                   classificationEnabled && Object.keys(richTextClassificationConfig).length > 0
                                       ? <StageCommentsContainer ideaId={ideaId} commentOption={stageCommentOption}
                                                                 classificationsMenuData={classificationsMenuData}
                                                                 richTextClassificationConfig={richTextClassificationConfig}/>
                                       : !classificationEnabled &&
                                       <StageCommentsContainer ideaId={ideaId} commentOption={stageCommentOption}/>
                               }/>
                    ))
                }

                {
                    ['/', ROUTES.IDEA_DETAILS.COMMENT].map((path) => (
                        <Route path={path} key={path}
                               element={
                                   classificationEnabled && Object.keys(richTextClassificationConfig).length > 0
                                       ? <CommunityCommentsContainer ideaId={ideaId} sortBy={sortBy}
                                                                     classificationsMenuData={classificationsMenuData}
                                                                     richTextClassificationConfig={richTextClassificationConfig}/>
                                       : !classificationEnabled &&
                                       <CommunityCommentsContainer ideaId={ideaId} sortBy={sortBy}
                                                                   classificationsMenuData={classificationsMenuData}/>}/>
                    ))


                }
            </Routes>
        </>
    );
};