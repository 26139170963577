import {Type} from 'class-transformer';
import {IdeaSummary} from './IdeaSummary';

export class PageResponseIdeaSummary {
    @Type(() => IdeaSummary)
    content: IdeaSummary[];
    hasMore: boolean;
    pageNo: number;
    pageSize: number;
    totalElements: number;
}