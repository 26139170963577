import React, {useState} from 'react';
import {Collapse, Nav, Navbar, NavbarToggler} from 'reactstrap';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {Icon} from '@ideascale/ui';
import './PageNavbar.scss';

type PageNavbarType = {
    children: React.ReactNode;
    navbarAction?: React.ReactNode;
    navbarAriaLabel: string;
}

export const PageNavbar = (props: PageNavbarType) => {
    const {children, navbarAction, navbarAriaLabel} = props;
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    return (
        <Navbar className={`page-navbar ${navbarAction ? 'has-navbar-action' : ''}`}
                expand="md"
                aria-label={navbarAriaLabel}>
            <div className="container d-md-flex position-relative p-0">
                <NavbarToggler onClick={toggle}>
                    <Icon iconSpritePath={svgIconsPath} name="lines-three-horizontal" width={25} height={25}/>
                </NavbarToggler>
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="me-auto fw-bold" navbar>
                        {children}
                    </Nav>
                </Collapse>
                {navbarAction}
            </div>
        </Navbar>
    );
};