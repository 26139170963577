import React, {Fragment} from 'react';
import {useLocalizer} from 'hooks/useLocalizer';
import {StageSummaryHeading} from './StageSummaryHeading';
import {EstimateStageSummary} from 'models/EstimateStageSummary';
import {IdeaStageSummaryProps} from 'models/types/IdeaStageSummaryProps';

export const EstimateSummary = (props: IdeaStageSummaryProps<EstimateStageSummary>) => {
    const {ideaStageSummary: {participationAllowed, viewAllowed, averageEstimatedReturn}} = props;
    const localizer = useLocalizer();

    return (
        <Fragment>
            <StageSummaryHeading className="mb-3" heading={localizer.msg('stage.estimate.heading')}/>
            <div className="stage-data mt-n3 mb-2">
                {
                    participationAllowed || viewAllowed
                        ?
                        <>
                            <strong>
                                {averageEstimatedReturn}%
                            </strong>
                            <span>
                                {localizer.msg('stage.estimate.return-on-investment')}
                            </span>
                        </>
                        :
                        <em>{localizer.msg('stage.estimate.awaits')}</em>
                }
            </div>
        </Fragment>
    );
};