import React, {Fragment, useEffect, useState} from 'react';
import {useCommunityService} from '../hooks/useService';
import {Feature} from '../models/new-features/Feature';
import {Features} from '../components/Features';
import {useLocalizer} from '../hooks/useLocalizer';

export const UpcomingFeaturesContainer = () => {
    const communityService = useCommunityService();
    const localizer = useLocalizer();

    const [features, setFeatures] = useState<Feature[]>([]);

    useEffect(() => {
        if (communityService !== null) {
            try {
                communityService.fetchUpcomingFeatures().then(response => {
                    setFeatures(response);
                });
            } catch(e: any) {
                console.log(e);
            }
        }
    }, [communityService]);
    return (
        <section className="mt-5">
            {
                features && features.length > 0 &&
                <Fragment>
                    <h3 className="mb-3 h3">Upcoming New Features</h3>
                    <div className="bg-gray p-3 rounded">
                        <ul className="list-group">
                            {
                                features.map(feature => <li className="list-group-item" key={feature.id}>
                                    <Features localizer={localizer} feature={feature} dateTextPrefix="Proposed release date"/>
                                </li>)
                            }
                        </ul>
                    </div>
                </Fragment>
            }
        </section>
    );
};