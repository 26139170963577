import {Command} from './Command';
import {CampaignHomeEditor} from './Editors/CampaignHomeEditor';
import {Operation} from 'models/edit-mode/Operation';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';
import {ChangeCampaignDescriptionOperation} from 'models/edit-mode/ChangeCampaignDescriptionOperation';

export class UpdateCampaignDescriptionCommand implements Command {
    private readonly campaignHomeEditor: CampaignHomeEditor;
    private readonly oldCampaignDescription: string;
    private readonly newCampaignDescription: string;
    private readonly name: CampaignOperationType;
    private readonly campaignId: number;

    constructor(campaignHomeEditor: CampaignHomeEditor, description: string, campaignId: number) {
        this.campaignHomeEditor = campaignHomeEditor;
        this.oldCampaignDescription = this.campaignHomeEditor.description;
        this.newCampaignDescription = description;
        this.campaignId = campaignId;
        this.name = CampaignOperationType.CHANGE_CAMPAIGN_DESCRIPTION;
    }

    execute(): void {
        this.campaignHomeEditor.description = this.newCampaignDescription;
    }

    undo(): void {
        this.campaignHomeEditor.description = this.oldCampaignDescription;
    }

    getType(): CampaignOperationType {
        return this.name;
    }

    getOperation(): Operation {
        return new ChangeCampaignDescriptionOperation(this.name, this.campaignHomeEditor.description, this.campaignId);
    }
}