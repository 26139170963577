import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {clsx} from 'clsx';
import range from 'lodash/range';
import {InfoTip} from '@ideascale/ui';
import {useAppContext} from 'contexts/AppContext';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useRouteUtils} from 'hooks/useRouteUtils';
import {useLocalizer} from 'hooks/useLocalizer';
import {useCommunityService} from 'hooks/useService';
import {CampaignTeamMember} from 'components/CampaignTeamMember';
import {MemberSkeleton} from 'components/MemberSkeleton';
import {SidebarToggleButton} from 'components/shared/SidebarToggleButton';
import {EditVisibilityActionWrapper} from 'components/shared/EditVisibilityActionWrapper';
import {TeamPlaceholder} from 'components/TeamPlaceholder';
import {CampaignTeamData} from 'models/CampaignTeamData';
import {IdeaListRouteMatchParams} from 'models/types/IdeaListRouteMatchParams';
import {
    UpdateCampaignModeratorTeamVisibilityCommand
} from 'commands/edit-mode/UpdateCampaignModeratorTeamVisibilityCommand';
import {
    UpdateGlobalModeratorTeamVisibilityCommand
} from 'commands/edit-mode/UpdateGlobalModeratorTeamVisibilityCommand';
import './CampaignTeamContainer.scss';

export const CampaignTeamContainer = () => {
    const communityService = useCommunityService();
    const localizer = useLocalizer();
    const [campaignTeam, setCampaignTeam] = useState<CampaignTeamData>(CampaignTeamData.EMPTY);
    const [loading, setLoading] = useState(true);
    const params = useParams<IdeaListRouteMatchParams>();
    const {campaignRouteMatch} = useRouteUtils();

    const {editModeEnabled} = useAppContext();
    const {campaignHomeEditor, commandExecutor, validationErrors} = useEditModeContext();

    const fetchCampaignTeamMembers = useCallback(async (campaignId: number): Promise<CampaignTeamData> => {
        if (communityService !== null) {
            return await communityService.fetchCampaignTeamMembers(campaignId);
        }
        return CampaignTeamData.EMPTY;
    }, [communityService]);

    const toggleVisibilityCampaignModeratorTeam = () => {
        const campaignId = (campaignRouteMatch?.params as any)?.campaignId;
        const command = new UpdateCampaignModeratorTeamVisibilityCommand(
            campaignHomeEditor,
            !campaignHomeEditor.campaignModeratorTeamVisibility.value,
            campaignId);
        commandExecutor.execute(command);
        validationErrors.clearError(command.getType());
    };

    const toggleVisibilityGlobalModeratorTeam = () => {
        const campaignId = (campaignRouteMatch?.params as any)?.campaignId;
        const command = new UpdateGlobalModeratorTeamVisibilityCommand(
            campaignHomeEditor,
            !campaignHomeEditor.globalModeratorTeamVisibility.value,
            campaignId);
        commandExecutor.execute(command);
        validationErrors.clearError(command.getType());
    };
    const shouldRenderCampaignModerators = () => campaignTeam.moderators?.length > 0 || editModeEnabled || (!campaignTeam.campaignModeratorsSectionEnabled && !campaignTeam.canSeeCampaignTeam);

    const shouldRenderGlobalModerators = () => campaignTeam.globalModerators?.length > 0 || editModeEnabled || (!campaignTeam.globalModeratorsSectionEnabled && !campaignTeam.canSeeCampaignTeam);

    useEffect(() => {
        if (params?.campaignId) {
            fetchCampaignTeamMembers(+params?.campaignId).then(data => {
                setCampaignTeam(data);
                setLoading(false);
            });
        }
    }, [fetchCampaignTeamMembers, params.campaignId]);

    return (
        <Fragment>
            <div className="mb-3 d-flex d-lg-none justify-content-end"><SidebarToggleButton/></div>
            <div className="campaign-team card panel">
                {
                    loading
                        ? <div className="p-3">
                            <div className="row">
                                {
                                    range(0, 8).map(item => (
                                        <div className="col-md-4 mb-3" key={item}>
                                            <MemberSkeleton avatarHeight="60px" avatarWidth="60px"/>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        : <Fragment>
                            {
                                campaignTeam.sponsors?.length > 0 &&
                                <div className="campaign-sponsors p-4"
                                     data-test-element-id="campaign-sponsors">
                                    <h2 className="h5 mb-4">{localizer.msg('common.campaign-sponsors')}</h2>
                                    <div className="row g-4">
                                        {
                                            campaignTeam.sponsors.map(member => {
                                                return (
                                                    <div className="col-md-4" key={member.id}>
                                                        <CampaignTeamMember localizer={localizer} member={member}/>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            }
                            {
                                campaignTeam.sponsors?.length > 0 && shouldRenderCampaignModerators() &&
                                <hr className="my-0"/>
                            }
                            {
                                shouldRenderCampaignModerators() &&
                                <div className="p-4" data-test-element-id="campaign-moderators">
                                    <div className="d-flex mb-4">
                                        <h2 className="h5">{localizer.msg('common.campaign-moderators')}</h2>
                                        {
                                            campaignTeam.moderators?.length > 0 && !campaignTeam.campaignModeratorsSectionEnabled && !editModeEnabled &&
                                            <InfoTip className="mt-n1" id="campaign-moderators-infotip"
                                                     placement="bottom"
                                                     content={localizer.msg('campaign.campaign-team.campaign-moderators-info-tip')}/>
                                        }
                                    </div>
                                    {
                                        editModeEnabled
                                            ? <EditVisibilityActionWrapper
                                                editAllowed={true}
                                                visibility={campaignHomeEditor.campaignModeratorTeamVisibility.value}
                                                toggleVisibility={toggleVisibilityCampaignModeratorTeam}
                                                editBtnTestElementId="btn-toggle-moderators-visbility"
                                                value={
                                                    campaignHomeEditor.campaignModeratorTeamVisibility.value
                                                        ? localizer.msg('campaign.campaign-team.hide-moderators')
                                                        : localizer.msg('campaign.campaign-team.show-moderators')
                                                }
                                            >
                                                <div>
                                                    <div
                                                        className={clsx('row g-4 py-2', !campaignHomeEditor.campaignModeratorTeamVisibility.value && 'opacity-50')}>
                                                        {
                                                            campaignTeam.moderators?.length > 0
                                                                ? campaignTeam.moderators.map(member => {
                                                                    return (
                                                                        <div className="col-md-4"
                                                                             key={member.id}>
                                                                            <CampaignTeamMember
                                                                                localizer={localizer}
                                                                                member={member}/>
                                                                        </div>
                                                                    );
                                                                })
                                                                : <TeamPlaceholder
                                                                    message={localizer.msg('campaign.campaign-team.no-moderators')}
                                                                    className="pb-2" hidden={false}/>

                                                        }
                                                    </div>
                                                </div>
                                            </EditVisibilityActionWrapper>
                                            : campaignTeam.moderators?.length > 0
                                                ? <div className="row g-4">
                                                    {
                                                        campaignTeam.moderators.map(member => (
                                                                <div className="col-md-4" key={member.id}>
                                                                    <CampaignTeamMember
                                                                        localizer={localizer}
                                                                        member={member}/>
                                                                </div>
                                                            )
                                                        )
                                                    }
                                                </div>
                                                : !campaignTeam.campaignModeratorsSectionEnabled &&
                                                <TeamPlaceholder
                                                    message={localizer.msg('campaign.campaign-team.campaign-moderators-private')}/>
                                    }
                                </div>
                            }
                            {(campaignTeam.sponsors?.length > 0 || shouldRenderCampaignModerators()) && shouldRenderGlobalModerators() &&
                                <hr className="my-0"/>
                            }
                            {
                                shouldRenderGlobalModerators() &&
                                <div className="p-4" data-test-element-id="global-moderators">
                                    <div className="d-flex mb-4">
                                        <h2 className="h5">{localizer.msg('common.global-moderators')}</h2>
                                        {
                                            campaignTeam.globalModerators?.length > 0 && !campaignTeam.globalModeratorsSectionEnabled && !editModeEnabled &&
                                            <InfoTip className="mt-n1" id="global-moderators-infotip"
                                                     placement="bottom"
                                                     content={localizer.msg('campaign.campaign-team.global-moderators-info-tip')}/>
                                        }
                                    </div>
                                    {
                                        editModeEnabled
                                            ? <EditVisibilityActionWrapper
                                                editAllowed={true}
                                                visibility={campaignHomeEditor.globalModeratorTeamVisibility.value}
                                                toggleVisibility={toggleVisibilityGlobalModeratorTeam}
                                                editBtnTestElementId="btn-toggle-global-moderators-visbility"
                                                value={
                                                    campaignHomeEditor.globalModeratorTeamVisibility.value
                                                        ? localizer.msg('campaign.campaign-team.hide-moderators')
                                                        : localizer.msg('campaign.campaign-team.show-moderators')
                                                }
                                            >
                                                <div>
                                                    <div
                                                        className={clsx('row g-4 py-2', !campaignHomeEditor.globalModeratorTeamVisibility.value && 'opacity-50')}>
                                                        {
                                                            campaignTeam.globalModerators?.length > 0
                                                                ? campaignTeam.globalModerators.map(member =>
                                                                    <div className="col-md-4" key={member.id}>
                                                                        <CampaignTeamMember localizer={localizer}
                                                                                            member={member}/>
                                                                    </div>
                                                                )
                                                                : <TeamPlaceholder
                                                                    message={localizer.msg('campaign.campaign-team.no-global-moderators')}
                                                                    className="pb-2" hidden={false}/>

                                                        }
                                                    </div>
                                                </div>
                                            </EditVisibilityActionWrapper>
                                            : campaignTeam.globalModerators?.length > 0
                                                ? <div className="row g-4">
                                                    {
                                                        campaignTeam.globalModerators.map(member =>
                                                            <div className="col-md-4" key={member.id}>
                                                                <CampaignTeamMember
                                                                    localizer={localizer}
                                                                    member={member}/>
                                                            </div>)
                                                    }
                                                </div>
                                                : !campaignTeam.globalModeratorsSectionEnabled &&
                                                <TeamPlaceholder
                                                    message={localizer.msg('campaign.campaign-team.global-moderators-private')}/>
                                    }
                                </div>
                            }
                            {
                                campaignTeam.sponsors?.length === 0 && !shouldRenderGlobalModerators() && !shouldRenderCampaignModerators() &&
                                <TeamPlaceholder message={localizer.msg('campaign.campaign-team.no-team-member')}
                                                 className="py-4" hidden={false}/>
                            }
                        </Fragment>
                }
            </div>
        </Fragment>
    );
};