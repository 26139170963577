import {useCallback, useEffect, useState} from 'react';
import {LabelData, useApiErrorResponseHandler} from '@ideascale/commons';
import {useIdeaListContext} from 'contexts/IdeaListContext';
import {useIdeaDetailsContext} from 'contexts/IdeaDetailsContext';
import {useLocalizer} from './useLocalizer';

export const useIdeaLabelAction = (appliedLabels: LabelData[]) => {
    const [labels, setLabels] = useState<LabelData[]>([]);
    const [labelsDirty, setLabelsDirty] = useState(false);

    const localizer = useLocalizer();
    const {updateLabels: updateListLabels} = useIdeaListContext();
    const {updateLabels: updateDetailsLabels} = useIdeaDetailsContext();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});

    const onAddIdeaLabel = (labelData: LabelData) => {
        const newLabels = [...labels];
        const labelIndex = newLabels.findIndex(label => label.id === labelData.id);
        if (labelIndex < 0) {
            newLabels.push(labelData);
        }
        setLabels(newLabels);
        setLabelsDirty(true);
    };

    const onRemoveIdeaLabel = (labelData: LabelData) => {
        const newLabels = [...labels];
        const labelIndex = newLabels.findIndex(label => label.id === labelData.id);
        if (labelIndex >= 0) {
            newLabels.splice(labelIndex, 1);
        }
        setLabels(newLabels);
        setLabelsDirty(true);
    };

    const updateIdeaListLabels = useCallback(async (ideaId: number) => {
        if (labelsDirty) {
            try {
                await updateListLabels({ideaId, labelId: labels.map(label => label.id)});
                setLabelsDirty(false);
            } catch (e) {
                handleErrorResponse(e);
            }
        }
    }, [handleErrorResponse, labels, labelsDirty, updateListLabels]);

    const updateIdeaDetailsLabels = useCallback(async (ideaId: number) => {
        if (labelsDirty) {
            try {
                await updateDetailsLabels({ideaId, labelId: labels.map(label => label.id)});
                setLabelsDirty(false);
            } catch (e) {
                handleErrorResponse(e);
            }

        }
    }, [handleErrorResponse, labels, labelsDirty, updateDetailsLabels]);

    useEffect(() => {
        setLabels(appliedLabels.filter(label => label.id > 0));
    }, [appliedLabels]);

    return {
        onAddIdeaLabel,
        onRemoveIdeaLabel,
        updateIdeaListLabels,
        updateIdeaDetailsLabels
    };
};