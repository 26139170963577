import React, {Fragment, useCallback, useEffect} from 'react';
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import {CommonUtil} from '@ideascale/ui';
import {EditModeUtil} from 'utils/EditModeUtil';
import {SearchContextProvider} from 'contexts/SearchContext';
import {IdeasContextProvider} from 'contexts/IdeasContext';
import {LandingPageContextProvider} from 'contexts/LandingPageContext';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useEditModeService} from 'hooks/useService';
import {useRouteUtils} from 'hooks/useRouteUtils';
import {useEditModeAccessibilityHelper} from 'hooks/useEditModeAccessibilityHelper';
import {IdeaListPage} from 'pages/IdeaListPage';
import {CampaignPage} from 'pages/CampaignPage';
import {LandingPage} from 'pages/LandingPage';
import {LayoutEditModeContainer} from './LayoutEditModeContainer';
import {MembershipTosContainer} from './MembershipTosContainer';
import {HomeConfigHolder} from 'models/edit-mode/HomeConfigHolder';
import {ROUTES} from 'shared/Routes';
import {TABINDEX_SET_DELAY} from 'constants/AppConstants';

export const AppEditModeContainer = () => {
    const {setHomeConfig} = useEditModeContext();
    const navigate = useNavigate();
    const editModeService = useEditModeService();
    const {homeRouteMatch, campaignRouteMatch} = useRouteUtils();
    const {isEditModeAccessibleRoute} = useEditModeAccessibilityHelper();

    const initEditMode = useCallback((config: HomeConfigHolder) => {
        setHomeConfig(config);
        CommonUtil.wait(TABINDEX_SET_DELAY).then(() => {
            EditModeUtil.setTabIndexOnlyForEditModeElements();
        });
    }, [setHomeConfig]);

    const fetchCommunityHomeConfig = useCallback(async () => {
        if (editModeService !== null) {
            try {
                return await editModeService.fetchCommunityConfig();
            } catch (e) {
                console.log(e);
            }
        }
        return HomeConfigHolder.EMPTY;
    }, [editModeService]);

    const fetchCampaignHomeConfig = useCallback(async (campaignId: number) => {
        if (editModeService !== null && campaignId) {
            try {
                return await editModeService.fetchCampaignConfig(campaignId);
            } catch (e) {
                console.log(e);
            }
        }
        return HomeConfigHolder.EMPTY;
    }, [editModeService]);

    useEffect(() => {
        if (!isEditModeAccessibleRoute()) {
            navigate(ROUTES.HOME, {replace: true});
        } else if (homeRouteMatch) {
            fetchCommunityHomeConfig().then(initEditMode);
        } else if (campaignRouteMatch) {
            const campaignId = (campaignRouteMatch?.params as any)?.campaignId;
            fetchCampaignHomeConfig(+campaignId).then(initEditMode);
        }
    }, [campaignRouteMatch, fetchCampaignHomeConfig, fetchCommunityHomeConfig, homeRouteMatch, initEditMode, isEditModeAccessibleRoute, navigate, setHomeConfig]);

    return (
        <Fragment>
            <LandingPageContextProvider>
                <IdeasContextProvider>
                    <SearchContextProvider>
                        <Routes>
                            <Route path={'/'} element={<LayoutEditModeContainer/>}>
                                {
                                    ['/', ROUTES.HOME, ROUTES.ALL_ACTIVE_IDEAS, ROUTES.ALL_EXPIRED_IDEAS]
                                        .map((path, index) => <Route path={path} key={index}
                                                                     element={<IdeaListPage/>}/>)
                                }
                                {
                                    [ROUTES.LANDING_PAGE_ACTION, ROUTES.LANDING].map((path) =>
                                        <Route path={path} key={path} element={
                                            <LandingPage/>
                                        }/>
                                    )
                                }
                                <Route path={ROUTES.CAMPAIGN.BASE_WITH_WILDCARD} element={<CampaignPage/>}/>
                                <Route path={'*'} element={<Navigate to={ROUTES.HOME}/>}/>
                            </Route>
                        </Routes>
                    </SearchContextProvider>
                </IdeasContextProvider>
            </LandingPageContextProvider>
            <MembershipTosContainer/>
        </Fragment>
    );
};