import {useCallback} from 'react';
import {useEditModeService} from './useService';
import {Operation} from 'models/edit-mode/Operation';
import {UploadFileRequest} from '../models/edit-mode/UploadFileRequest';

export const useEditModeFileOperationService = () => {
    const editModeService = useEditModeService();

    const fetchCommunityLibraryImages = useCallback(async () => {
        if (editModeService !== null) {
            return await editModeService.fetchCommunityLibraryImages();
        }
        return [];
    }, [editModeService]);

    const fetchCampaignLibraryImages = useCallback(async () => {
        if (editModeService !== null) {
            return await editModeService.fetchCampaignLibraryImages();
        }
        return [];
    }, [editModeService]);

    const fetchCommunityBannerImages = useCallback(async () => {
        if (editModeService !== null) {
            return await editModeService.fetchCommunityBannerImages();
        }
        return [];
    }, [editModeService]);

    const fetchCampaignBannerImages = useCallback(async () => {
        if (editModeService !== null) {
            return await editModeService.fetchCampaignBannerImages();
        }
        return [];
    }, [editModeService]);

    const fetchCampaignFeaturedGalleryImages = useCallback(async () => {
        if (editModeService !== null) {
            return await editModeService.fetchCampaignFeaturedGalleryImages();
        }
        return [];
    }, [editModeService]);

    const saveCommunityOperations = useCallback(async (operations: Operation[]) => {
        if (editModeService !== null) {
            return editModeService.saveCommunityOperations(operations);
        }
        return [];
    }, [editModeService]);

    const communityUploadLibraryFiles = useCallback(async (uploadFileRequest: UploadFileRequest) => {
        if (editModeService !== null) {
            return await editModeService.communityUploadLibraryFiles(uploadFileRequest);
        }
        return [];
    }, [editModeService]);

    const campaignUploadLibraryFiles = useCallback(async (uploadFileRequest: UploadFileRequest) => {
        if (editModeService !== null) {
            return await editModeService.campaignUploadLibraryFiles(uploadFileRequest);
        }
        return [];
    }, [editModeService]);

    return {
        fetchCommunityLibraryImages,
        fetchCampaignLibraryImages,
        fetchCommunityBannerImages,
        fetchCampaignBannerImages,
        saveCommunityOperations,
        fetchCampaignFeaturedGalleryImages,
        communityUploadLibraryFiles,
        campaignUploadLibraryFiles
    };
};