import React, {useEffect, useState} from 'react';
import {Progress} from 'reactstrap';
import {Localizer} from '@ideascale/commons';
import {EstimateStageActivity} from 'models/stage-activity/EstimateStageActivity';

type EstimateReportProps = {
    localizer: Localizer;
    activity: EstimateStageActivity;
}

type ProgressValues = {
    valueHigh: number;
    valueMedium: number;
    valueLow: number;
    costHigh: number;
    costMedium: number;
    costLow: number;
}

export const EstimateReport = (props: EstimateReportProps) => {
    const {localizer, activity} = props;
    const [progressValues, setProgressValues] = useState<ProgressValues>({
        valueHigh: 0,
        valueMedium: 0,
        valueLow: 0,
        costHigh: 0,
        costMedium: 0,
        costLow: 0
    });

    useEffect(() => {
        setTimeout(() => {
            const allProgressValues = {
                valueHigh: activity.memberEstimateStatistics.estimateValue.high.percentage,
                valueMedium: activity.memberEstimateStatistics.estimateValue.medium.percentage,
                valueLow: activity.memberEstimateStatistics.estimateValue.low.percentage,
                costHigh: activity.memberEstimateStatistics.estimateCost.high.percentage,
                costMedium: activity.memberEstimateStatistics.estimateCost.medium.percentage,
                costLow: activity.memberEstimateStatistics.estimateCost.low.percentage
            };
            setProgressValues(allProgressValues);
        }, 500);
    }, [activity.memberEstimateStatistics.estimateCost.high.percentage, activity.memberEstimateStatistics.estimateCost.low.percentage, activity.memberEstimateStatistics.estimateCost.medium.percentage, activity.memberEstimateStatistics.estimateValue.high.percentage, activity.memberEstimateStatistics.estimateValue.low.percentage, activity.memberEstimateStatistics.estimateValue.medium.percentage]);

    return (
        <div>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-3">
                    <h3 className="font-size-md fw-bold">{localizer.msg('stage.estimate.estimate-value')}</h3>
                </div>
                <div className="col-12 col-md-6 col-lg-9">
                    <h4 className="fw-bold">
                        {localizer.msg('stage.estimate.estimate-average-value', {
                            amount: activity.averageValue.value,
                            currency: activity.fundUnit
                        })}</h4>
                </div>
            </div>
            <ul className="list-unstyled estimate-value rounded border-1 mb-5">
                <li className="border-bottom-1">
                    <div className="row py-4 align-items-center m-0">
                        <div className="col-12 col-md-6 col-lg-2">
                            <div className="question-summary font-size-md">
                               {localizer.msg('stage.estimate.estimate-value-high')}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-7">
                            <div role="progressbar" aria-valuenow={progressValues.valueHigh} className="sr-only"
                                 aria-label={localizer.msg('stage.estimate.high-value-estimate')}/>
                            <Progress aria-hidden="true" className="mt-3 mb-3 me-5 progress-high"
                                      value={progressValues.valueHigh}/>
                        </div>
                        <div className="col-12 col-lg-3 question-summary-outer mb-0">
                            <div className="question-summary-value">
                                <span className="d-block">{localizer.msg('stage.estimate.your-estimate')}</span>
                                <span
                                    className="font-size-md d-block break-all-words">{`${localizer.msg(activity.fundUnitKey)} ${activity.memberEstimateStatistics.estimateValue.high.value}`}</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="border-bottom-1">
                    <div className="row py-4 align-items-center m-0">
                        <div className="col-12 col-md-6 col-lg-2">
                            <div className="question-summary font-size-md">
                                {localizer.msg('stage.estimate.estimate-value-medium')}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-7">
                            <div role="progressbar" aria-valuenow={progressValues.valueMedium} className="sr-only"
                                 aria-label={localizer.msg('stage.estimate.medium-value-estimate')}/>
                            <Progress aria-hidden="true" className="mt-3 mb-3 me-5 progress-medium"
                                      value={progressValues.valueMedium} />
                        </div>
                        <div className="col-12 col-lg-3 question-summary-outer mb-0">
                            <div className="question-summary-value">
                                <span className="d-block">{localizer.msg('stage.estimate.your-estimate')}</span>
                                <span
                                    className="font-size-md d-block break-all-words">{`${localizer.msg(activity.fundUnitKey)} ${activity.memberEstimateStatistics.estimateValue.medium.value}`}</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="border-bottom-1">
                    <div className="row py-4 align-items-center m-0">
                        <div className="col-12 col-md-6 col-lg-2">
                            <div className="question-summary font-size-md">
                                {localizer.msg('stage.estimate.estimate-value-low')}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-7">
                            <div role="progressbar" aria-valuenow={progressValues.valueLow} className="sr-only"
                                 aria-label={localizer.msg('stage.estimate.low-value-estimate')}/>
                            <Progress aria-hidden="true" className="mt-3 mb-3 me-5 progress-low"
                                      value={progressValues.valueLow}/>
                        </div>
                        <div className="col-12 col-lg-3 question-summary-outer mb-0">
                            <div className="question-summary-value">
                                <span className="d-block">{localizer.msg('stage.estimate.your-estimate')}</span>
                                <span
                                    className="font-size-md d-block break-all-words">{`${localizer.msg(activity.fundUnitKey)} ${activity.memberEstimateStatistics.estimateValue.low.value}`}</span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-3">
                    <h3 className="font-size-md fw-bold">{localizer.msg('stage.estimate.estimate-cost')}</h3>
                </div>
                <div className="col-12 col-md-6 col-lg-9">
                    <h4 className="fw-bold">
                        {localizer.msg('stage.estimate.estimate-average-cost', {
                            amount: activity.averageCost.value,
                            currency: activity.fundUnit
                        })}</h4>
                </div>
            </div>
            <ul className="list-unstyled estimate-cost rounded border-1 mb-5">
                <li className="border-bottom-1">
                    <div className="row py-4 align-items-center m-0">
                        <div className="col-12 col-md-6 col-lg-2">
                            <div className="question-summary font-size-md">
                                {localizer.msg('stage.estimate.estimate-cost-high')}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-7">
                            <div role="progressbar" aria-valuenow={progressValues.costHigh} className="sr-only"
                                 aria-label={localizer.msg('stage.estimate.high-cost-estimate')}/>
                            <Progress aria-hidden="true" className="mt-3 mb-3 me-5 progress-high"
                                      value={progressValues.costHigh}/>
                        </div>
                        <div className="col-12 col-lg-3 question-summary-outer mb-0">
                            <div className="question-summary-value">
                                <span className="d-block">{localizer.msg('stage.estimate.your-estimate')}</span>
                                <span
                                    className="font-size-md d-block break-all-words">{`${localizer.msg(activity.fundUnitKey)} ${activity.memberEstimateStatistics.estimateCost.high.value}`}</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="border-bottom-1">
                    <div className="row py-4 align-items-center m-0">
                        <div className="col-12 col-md-6 col-lg-2">
                            <div className="question-summary font-size-md">
                                {localizer.msg('stage.estimate.estimate-cost-medium')}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-7">
                            <div role="progressbar" aria-valuenow={progressValues.costMedium} className="sr-only"
                                 aria-label={localizer.msg('stage.estimate.medium-cost-estimate')}/>
                            <Progress aria-hidden="true" className="mt-3 mb-3 me-5 progress-medium"
                                      value={progressValues.costMedium}/>
                        </div>
                        <div className="col-12 col-lg-3 question-summary-outer mb-0">
                            <div className="question-summary-value">
                                <span className="d-block">{localizer.msg('stage.estimate.your-estimate')}</span>
                                <span
                                    className="font-size-md d-block break-all-words">{`${localizer.msg(activity.fundUnitKey)} ${activity.memberEstimateStatistics.estimateCost.medium.value}`}</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="border-bottom-1">
                    <div className="row py-4 align-items-center m-0">
                        <div className="col-12 col-md-6 col-lg-2">
                            <div className="question-summary font-size-md">
                                {localizer.msg('stage.estimate.estimate-cost-low')}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-7">
                            <div role="progressbar" aria-valuenow={progressValues.costLow} className="sr-only"
                                 aria-label={localizer.msg('stage.estimate.low-cost-estimate')}/>
                            <Progress aria-hidden="true" className="mt-3 mb-3 me-5 progress-low"
                                      value={progressValues.costLow}/>
                        </div>
                        <div className="col-12 col-lg-3 question-summary-outer mb-0">
                            <div className="question-summary-value">
                                <span className="d-block">{localizer.msg('stage.estimate.your-estimate')}</span>
                                <span
                                    className="font-size-md d-block break-all-words">{`${localizer.msg(activity.fundUnitKey)} ${activity.memberEstimateStatistics.estimateCost.low.value}`}</span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    );
};