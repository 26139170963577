import {KudoType} from './enums/KudoType';
import {MemberSummary} from './MemberSummary';

export class ProfileAboutMeData {
    constructor(
        public memberSummary: MemberSummary,
        public ideaCount: number,
        public commentCount: number,
        public voteCount: number,
        public implementedIdeaCount: number,
        public followed: boolean,
        public kudos: { type: KudoType, count: number }[],
        public createdAt: string,
        public editName: boolean,
        public editAvatar: boolean,
        public editProfile: boolean
    ) {
    }
}