import {ClassificationSummary, Member} from '@ideascale/commons';

type CampaignStats = {
    ideaCount: number,
    voteCount: number,
    commentCount: number,
    ideaViewCount: number
}

export class CampaignActivity {
    static readonly EMPTY = new CampaignActivity(0, '', '', '', false, false, {
        ideaCount: 0,
        voteCount: 0,
        commentCount: 0,
        ideaViewCount: 0,
    }, [], {
        id: 0,
        name: '',
        locationExtensions: {
            countriesExtensions: [],
            fvey: {
                id: 0,
                name: ''
            },
            nf: {
                id: 0,
                name: ''
            }
        },
        sensitiveExtensions: []
    }, '');

    constructor(
        public id: number,
        public logoUrl: string,
        public name: string,
        public subTitle: string,
        public subscribed: boolean,
        public teamLinkAllowed: boolean,
        public campaignStats: CampaignStats,
        public sponsors: Member[],
        public classificationSummary: ClassificationSummary,
        public altText: string,
    ) {
    }
}