import {ImageField} from './ImageField';
import {CommunityEditableFieldType} from './CommunityEditableFieldType';
import {CampaignEditableFieldType} from './CampaignEditableFieldType';
import {ImageType} from '../enums/ImageType';

export class BannerFieldBuilder {
    public bannerField: BannerField;

    constructor() {
        this.bannerField = {...BannerField.EMPTY};
    }

    name(name: CommunityEditableFieldType | CampaignEditableFieldType) {
        this.bannerField.name = name;
        return this;
    }

    url(url: string) {
        this.bannerField.url = url;
        return this;
    }

    fileId(fileId: number) {
        this.bannerField.fileId = fileId;
        return this;
    }

    imageType(imageType: ImageType) {
        this.bannerField.imageType = imageType;
        return this;
    }

    defaultImage(defaultImage: boolean) {
        this.bannerField.defaultImage = defaultImage;
        return this;
    }

    filename(filename: string) {
        this.bannerField.filename = filename;
        return this;
    }

    altText(altText: string) {
        this.bannerField.altText = altText;
        return this;
    }

    gradientEnabled(gradientEnabled: boolean) {
        this.bannerField.gradientEnabled = gradientEnabled;
        return this;
    }

    blackPageTitleEnabled(blackPageTitleEnabled: boolean) {
        this.bannerField.blackPageTitleEnabled = blackPageTitleEnabled;
        return this;
    }

    build() {
        return this.bannerField;
    }
}

export class BannerField extends ImageField {
    static EMPTY = new BannerField(CommunityEditableFieldType.UNKNOWN, '', 0, ImageType.UNKNOWN, false, false, false);

    constructor(
        name: CommunityEditableFieldType | CampaignEditableFieldType,
        url: string,
        fileId: number,
        imageType: ImageType,
        defaultImage: boolean,
        public gradientEnabled: boolean,
        public blackPageTitleEnabled: boolean,
        filename?: string,
        altText?: string
    ) {
        super(name, url, fileId, imageType, defaultImage, filename, altText);
    }
}