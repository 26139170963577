import {BaseConfigField} from './BaseConfigField';
import {CommunityEditableFieldType} from './CommunityEditableFieldType';
import {CampaignEditableFieldType} from './CampaignEditableFieldType';

export class WidgetField extends BaseConfigField {
    public static EMPTY = new WidgetField(CommunityEditableFieldType.UNKNOWN, '', '', '');

    constructor(
        name: CommunityEditableFieldType | CampaignEditableFieldType,
        public title: string,
        public content: string,
        public key: string
    ) {
        super(name);
    }
}