import React, {useCallback} from 'react';
import {GroupBase, OptionsOrGroups} from 'react-select';
import {IdeascaleSelectWithPagination, OptionType} from '@ideascale/ui';
import {PagedResponseContent} from '@ideascale/commons';
import {useLocalizer} from '../../hooks/useLocalizer';
import {SearchInputGroup} from '../../shared/SearchInputGroup';
import {PageParameters} from '../../models/types/PageParameters';
import {StageGroup} from '../../models/StageGroup';
import styles from './SearchField.module.scss';

type MemberProfileSearchFieldProps = {
    fetchAdvanceSearchProfileFields(pageParameters: PageParameters): Promise<PagedResponseContent<StageGroup>>;
    values: OptionType[];
    onChange: (options: OptionType[] | null, mode: 'IDEA' | 'MEMBER') => void;
}

export const MemberProfileSearchField = (props: MemberProfileSearchFieldProps) => {
    const {fetchAdvanceSearchProfileFields, values, onChange} = props;
    const localizer = useLocalizer();

    const loadProfileFields = useCallback(async (term: string, prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>, additionalParams: any) => {
        try {
            const {page} = additionalParams;
            const customFieldData = await fetchAdvanceSearchProfileFields({page, term, limit: 10});
            const tags: OptionType[] = customFieldData.content.map((fieldItem) => ({
                value: fieldItem.id,
                label: fieldItem.label,
                labelAsJsx: <span className="label customize-label">{fieldItem.label}</span>
            }));
            return {
                options: tags,
                hasMore: customFieldData.hasMore,
                additional: {
                    page: page + 1,
                }
            };
        } catch(e: any) {
            return {
                options: []
            }
        }
    }, [fetchAdvanceSearchProfileFields]);

    return (
        <div data-test-element-id="member-profile-search" className={`form-group ${styles.memberSearch}`}>
            <label className="fw-bold">{localizer.msg('search.member.member-field-label')}</label>
            <SearchInputGroup groupText={''}>
                <IdeascaleSelectWithPagination
                    ariaLabel={localizer.msg('search.member.select-member-field-label')}
                    isMulti={true}
                    loadOptions={loadProfileFields}
                    placeholder={localizer.msg('search.member.member-field-placeholder')}
                    value={values}
                    onChange={(options: any) => onChange(options, 'MEMBER')}
                    additional={{
                        page: 0
                    }}/>
            </SearchInputGroup>
        </div>
    );
};