import React, {Fragment} from 'react';
import {HtmlUtils, Localizer} from '@ideascale/commons';
import {FooterData} from 'models/FooterData';
import {helpLinks, LINKS} from 'constants/AppConstants';

type FooterContentProps = {
    localizer: Localizer;
    brandingAllowed: boolean;
    freeLicense: boolean;
    readCommunity: boolean;
    seoLinkHidden: boolean;
    footerData: FooterData,
    onCookieConsentClicked: () => void;
    onAccessibilityStatementClicked: () => void;
    helpSiteBaseUrl: string;
}

export const FooterContent = (props: FooterContentProps) => {
    const {
        brandingAllowed,
        readCommunity,
        freeLicense,
        seoLinkHidden,
        footerData,
        localizer,
        onCookieConsentClicked,
        onAccessibilityStatementClicked,
        helpSiteBaseUrl
    } = props;

    const getCurrentYear = () => {
        return new Date().getFullYear();
    };

    return (
        <footer className="footer-content py-3">
            {
                footerData.customCopyright ? <span>{HtmlUtils.htmlToReact(footerData.customCopyright)}</span> : (
                    <Fragment>
                        <span>{`${localizer.msg('Copyright')} \u00A9 ${getCurrentYear()}`}</span>
                        {
                            brandingAllowed && (
                                <Fragment>
                                    <a href={LINKS.BASE_URL} title={localizer.msg('crowdsourcing-title')}>
                                        {localizer.msg('footer.crowdsourcing-text')}
                                    </a>
                                    {
                                        !seoLinkHidden &&
                                        <a href={`${footerData.seoLinkDetails.pseudonymUrl}`}>
                                            {footerData.seoLinkDetails.pseudonymText}
                                        </a>
                                    }
                                </Fragment>
                            )
                        }
                    </Fragment>
                )
            }
            <span>|</span>
            <button className="cookie-policy border-0 p-0"
                    onClick={onAccessibilityStatementClicked}>{localizer.msg('footer.accessibility.title')}</button>
            {
                readCommunity && (
                    footerData.contactPageUrl && (
                        <Fragment>
                            <span>|</span>
                            <a href={footerData.contactPageUrl}>{localizer.msg('footer.contact')}</a>
                        </Fragment>
                    )
                )
            }
            {
                brandingAllowed && (
                    <Fragment>
                        <span>|</span>
                        <a href={helpLinks.endUserTermsUrl(helpSiteBaseUrl)}>{localizer.msg('footer.user-terms')}</a>
                    </Fragment>
                )
            }
            {
                footerData.tosPageUrl && (
                    <Fragment>
                        <span>|</span>
                        <a href={footerData.tosPageUrl}>{localizer.msg('footer.tos')}</a>
                    </Fragment>
                )
            }
            {
                footerData.customPages?.map(customPage => {
                    if ((customPage.publicPage || readCommunity)) {
                        return (
                            <React.Fragment key={customPage.id}>
                                <span>|</span>
                                <a href={customPage.url}>{customPage.name}</a>
                            </React.Fragment>
                        );
                    } else {
                        return null;
                    }
                })
            }
            {
                freeLicense && !seoLinkHidden && (
                    footerData.seoLinkDetails?.links?.map(seoLink => (
                        <React.Fragment key={seoLink.url}>
                            <span>|</span>
                            <a href={seoLink.url}>{seoLink.text}</a>
                        </React.Fragment>
                    ))
                )
            }
            {
                footerData.customFooterContent &&
                (
                    HtmlUtils.htmlToReact(footerData.customFooterContent)
                )
            }
            {
                brandingAllowed && (
                    <Fragment>
                        <span>|</span>
                        <a href={helpLinks.privacyPolicyUrl(helpSiteBaseUrl)}>{localizer.msg('footer.privacy-policy')}</a>
                    </Fragment>
                )
            }
            <span>|</span>
            <button className="cookie-policy border-0 p-0"
                    onClick={onCookieConsentClicked}> {brandingAllowed ? localizer.msg('footer.ideascale-cookie-policy') : localizer.msg('footer.cookie-policy')}</button>
        </footer>
    );
};