import {AdminPermissionScope} from '../enums/AdminPermissionScope';

export class AdminPermissionHolder {
    public static readonly EMPTY = new AdminPermissionHolder(false, []);

    constructor(
        public translationEnabled: boolean,
        public editPermissions: AdminPermissionScope[],
    ) {
    }

}