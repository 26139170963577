import React, {useCallback, useEffect, useState} from 'react';
import {Navigate, NavLink, Route, Routes} from 'react-router-dom';
import {Icon} from '@ideascale/ui';
import {
    ClassificationsHolder,
    FieldAttributeParameters,
    Member,
    PrivateMessageRequestParams
} from '@ideascale/commons';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useCommunityService} from 'hooks/useService';
import {useProfilePageParams} from 'hooks/useProfilePageParams';
import {useLocalizer} from 'hooks/useLocalizer';
import {Sent} from 'components/profile/messages/Sent';
import {Inbox} from 'components/profile/messages/Inbox';
import {Compose} from 'components/profile/messages/Compose';
import {appLinks} from 'services/AppLinks';
import {PageParameters} from 'models/types/PageParameters';
import {GenericResponse} from 'models/GenericResponse';
import {ProfileInboxData} from 'models/ProfileInboxData';
import {MessageReplyData} from 'models/MessageReplyData';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {ConversationThreadData} from 'models/ConversationThreadData';
import {ROUTES} from 'shared/Routes';
import 'components/profile/messages/Messages.scss';


type messagesProps = {
    fetchInboxMessages: (pageParams: PageParameters) => Promise<PagedResponseContent<ProfileInboxData>>;
    fetchSentMessages: (pageParams: PageParameters) => Promise<PagedResponseContent<ProfileInboxData>>;
    fetchRecipients: (pageParameters: PageParameters) => Promise<PagedResponseContent<Member>>;
    onSendMessage(requestParams: PrivateMessageRequestParams): Promise<void>;
    fetchCountUnread: () => Promise<number>;
    fetchClassifications: () => Promise<ClassificationsHolder>
}

export const Messages = (props: messagesProps) => {
    const {
        fetchInboxMessages,
        fetchSentMessages,
        fetchCountUnread,
        fetchRecipients,
        onSendMessage,
        fetchClassifications,
    } = props;
    const [count, setCount] = useState(0);
    const communityService = useCommunityService();
    const currentProfileId = useProfilePageParams();
    const localizer = useLocalizer();

    const trashConversationThread = useCallback(async (conversationThreadId: number) => {
        if (communityService !== null) {
            return await communityService.trashConversationThread(conversationThreadId);
        }
        return GenericResponse.EMPTY;
    }, [communityService]);

    const fetchConversationThread = useCallback(async (conversationThreadId: number): Promise<ConversationThreadData> => {
        if (communityService !== null) {
            return await communityService.fetchConversationThread(conversationThreadId);
        }
        return ConversationThreadData.EMPTY;
    }, [communityService]);


    const conversationThreadReply = useCallback(async (recipientIds: number[], body: string, subject: string, subjectClassificationAttributes: FieldAttributeParameters[], conversationThreadId: number) => {
        if (communityService !== null) {
            return await communityService.conversationThreadReply(recipientIds, body, subject, subjectClassificationAttributes, conversationThreadId);
        }
        return MessageReplyData.EMPTY;
    }, [communityService]);

    useEffect(() => {
        fetchCountUnread().then(data => setCount(data));
    }, [fetchCountUnread]);

    return (
        <div className="row profile-messages-container ms-0">
            <div className="col-md-1 message-menus p-0 text-center" >
                <nav className="pt-3" aria-label={localizer.msg('topbar.messages.message-actions')}>
                    <div className="mb-4">
                        <NavLink to={appLinks.messagesCompose(currentProfileId)}
                                 data-test-element-id="btn-messages-compose"
                                 title={localizer.msg('topbar.messages.compose')}
                                 className={({isActive}) => `${isActive ? 'active' : ''} mb-3 sent-message-icon`}>
                            <Icon iconSpritePath={svgIconPath} name="pencil" fill={'#fff'} width={16} height={16}/>
                        </NavLink>
                    </div>
                    <div className="mb-4">
                        <NavLink className={({isActive}) => `${isActive ? 'active' : ''} mb-3 position-relative`}
                                 data-test-element-id="btn-messages-inbox"
                                 to={appLinks.messagesInbox(currentProfileId)}
                                 title={localizer.msg('common.inbox')}>
                            <Icon iconSpritePath={svgIconPath} name="boxes-open-stacked" fill={'#fff'} width={18} height={18}/>
                            {count > 0 ?
                                <span className="badge bg-primary position-absolute">{count}</span> : ''
                            }
                        </NavLink>
                    </div>
                    <div className="mb-4">
                        <NavLink to={appLinks.messagesSent(currentProfileId)}
                                 data-test-element-id="btn-messages-sent"
                                 title={localizer.msg('common.sent')}
                                 className={({isActive}) => isActive ? 'active' : ''}>
                            <Icon iconSpritePath={svgIconPath} name="paper-plane-right" fill={'#fff'} width={18} height={18}/>
                        </NavLink>
                    </div>
                </nav>
            </div>
            <Routes>
                {
                    [ROUTES.PROFILE.INBOX_WITH_WILDCARD, ROUTES.PROFILE.INBOX].map((path) =>
                        <Route path={path} key={path} element={
                            <Inbox fetchInboxMessages={fetchInboxMessages}
                                   fetchClassifications={fetchClassifications}
                                   trashConversationThread={trashConversationThread}
                                   fetchConversationThread={fetchConversationThread}
                                   conversationThreadReply={conversationThreadReply}/>}/>
                    )
                }
                <Route path={ROUTES.PROFILE.COMPOSE} element={
                    <Compose fetchClassifications={fetchClassifications} fetchRecipients={fetchRecipients}
                             onSendMessage={onSendMessage}/>}/>
                {
                    [ROUTES.PROFILE.SENT_WITH_WILDCARD, ROUTES.PROFILE.SENT].map((path) =>
                        <Route path={path} key={path} element={
                            <Sent fetchConversationThread={fetchConversationThread}
                                  fetchSentMessages={fetchSentMessages}
                                  trashConversationThread={trashConversationThread}
                                  fetchClassifications={fetchClassifications}
                                  conversationThreadReply={conversationThreadReply}/>}/>
                    )
                }
                <Route path="*" element={<Navigate to={'inbox'}/>}/>
            </Routes>
        </div>
    );
};
