import React, {Fragment, useMemo, useRef, useState} from 'react';
import {useInfiniteQuery} from 'react-query';
import range from 'lodash/range';
import {InfiniteScrollLoadMoreButton, Localizer, useIntersectionObserver} from '@ideascale/commons';
import {IdeascaleSelect, ParagraphSkeleton, Scrollbar, ScrollbarInstance} from '@ideascale/ui';
import {ActivityList} from 'components/profile/activity-stream/ActivityList';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {ActivityStreamData} from 'models/ActivityStreamData';
import {PageParameters} from 'models/types/PageParameters';
import {ActivityType} from 'models/enums/ActivityType';
import {ACTIVITY_STREAM_FILTERS, QUERY_KEYS} from 'constants/AppConstants';
import './ActivityStream.scss';

type ActivityStreamProps = {
    localizer: Localizer;
    memberId: number;
    ownActivity: boolean;
    fetchActivityStream: (memberId: number, type: string, pageParameters: PageParameters) => Promise<PagedResponseContent<ActivityStreamData>>;
}

const PAGE_SIZE = 15;
const STALE_TIME = 10 * 60 * 1000;

export const ActivityStream = (props: ActivityStreamProps) => {
    const {localizer, memberId, ownActivity, fetchActivityStream} = props;
    const scrollContainer = useRef<ScrollbarInstance>(null);
    const loadMoreButtonRef = useRef<HTMLButtonElement>(null);
    const [filter, setFilter] = useState(ACTIVITY_STREAM_FILTERS[0]);

    const {
        data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage
    } = useInfiniteQuery([QUERY_KEYS.ACTIVITY_STREAM, memberId, filter.value],
        async ({queryKey, pageParam = 0}) => {
            const activities = await fetchActivityStream(Number(queryKey[1]), String(queryKey[2]), {
                page: pageParam,
                limit: PAGE_SIZE
            });

            return {
                data: activities.content,
                nextPage: activities.hasMore ? activities.pageNo + 1 : undefined,
                prevPage: activities.pageNo > 0 ? activities.pageNo - 1 : undefined,
                allPages: Math.ceil(activities.totalElements / activities.pageSize)
            };
        },
        {
            staleTime: STALE_TIME,
            getNextPageParam: ({nextPage}) => nextPage,
            getPreviousPageParam: ({prevPage}) => prevPage,
            notifyOnChangeProps: 'tracked',
            enabled: memberId > 0
        }
    );

    useIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: fetchNextPage,
        enabled: hasNextPage || false,
        options: {
            root: scrollContainer.current?.container
        }
    });

    const ListSkeleton = useMemo(() => {
        return <Fragment>
            {range(0, 9).map(item => <div className="my-2" key={item}><ParagraphSkeleton rows={2}/></div>)}
        </Fragment>;
    }, []);

    return (
        <div className="card panel mb-3 py-3">
            <div
                className="card-header px-3 py-0 pb-3 bg-transparent border-bottom-0 d-flex justify-content-between align-items-center">
                <div>
                    <h2 className="font-size-md fw-bold mb-0 h6" id="activity-stream-heading">
                        {localizer.msg('profile.activity-stream.heading')}
                    </h2>
                </div>
                <div className="activity-stream-select">
                    <IdeascaleSelect
                        options={ACTIVITY_STREAM_FILTERS
                            .filter(filter => ownActivity || filter.value !== ActivityType.PRIVATE_ACTIONS)
                            .map(activity => ({
                                ...activity,
                                label: localizer.msg(activity.label)
                            }))}
                        ariaLabel={localizer.msg('profile.activity-stream.heading')}
                        inputId="activity-stream"
                        onChange={(value: any) => setFilter(value)}
                        defaultValue={{
                            ...ACTIVITY_STREAM_FILTERS[0],
                            label: localizer.msg(ACTIVITY_STREAM_FILTERS[0].label)
                        }}/>
                </div>
            </div>
            <Scrollbar className="card-body py-0 border-top-0 activity-list-holder"
                       autoHeight
                       autoHeightMin="60vh"
                       autoHeightMax="80vh"
                       ref={scrollContainer}>
                {
                    isLoading
                        ? ListSkeleton
                        : (
                            <ul className="list-unstyled m-0 activity-list pe-2" aria-labelledby="activity-stream-heading">
                                {
                                    data && data.pages[0].data.length > 0
                                        ? (
                                            data.pages.map((page, index) => <ActivityList activities={page.data}
                                                                                          key={index}/>)
                                        ) : (
                                            <li className="text-muted pt-3">
                                                {
                                                    filter.value
                                                        ? localizer.msg('profile.activity-stream.no-activity-for-filter', {
                                                            activity: filter.label,
                                                            user: ownActivity ? localizer.msg('profile.activity-stream.you') : localizer.msg('profile.activity-stream.this-user')
                                                        })
                                                        : localizer.msg('profile.activity-stream.no-activities', {
                                                            user: ownActivity ? localizer.msg('profile.activity-stream.you') : localizer.msg('profile.activity-stream.this-user')
                                                        })
                                                }
                                            </li>
                                        )
                                }
                            </ul>
                        )
                }
                <InfiniteScrollLoadMoreButton hasMore={hasNextPage || false}
                                              localizer={localizer}
                                              loading={isFetchingNextPage}
                                              onCLick={fetchNextPage}
                                              skeletonComponent={ListSkeleton}
                                              loadMoreButtonRef={loadMoreButtonRef}/>
            </Scrollbar>
        </div>
    );
};