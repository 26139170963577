import React, {Fragment} from 'react';
import {useAppContext} from 'contexts/AppContext';
import {StageSummaryHeading} from './StageSummaryHeading';
import {useLocalizer} from 'hooks/useLocalizer';
import {IdeateStageSummary} from 'models/IdeateStageSummary';
import {IdeaStageSummaryProps} from 'models/types/IdeaStageSummaryProps';
import './IdeateSummary.scss';

export const IdeateSummary = (props: IdeaStageSummaryProps<IdeateStageSummary>) => {
    const {ideaStageSummary: {participationAllowed, viewAllowed, upVoteCount, downVoteCount}} = props;
    const {communityConfig: {displayUpDownVoteCounts, voteDownEnabled, classificationEnabled}} = useAppContext();

    const localizer = useLocalizer();
    return (
        (participationAllowed || viewAllowed)
            ? <Fragment>
                {
                    displayUpDownVoteCounts && voteDownEnabled &&
                    <div
                        className={`vote-counts px-2 fw-bold text-start ${classificationEnabled ? 'mt-n1' : 'mt-2'}`}
                    >
                        <p className="sr-only">{localizer.msg('stage.common.vote_other')}</p>
                        <div className="up-vote-counts text-truncate">
                            <svg className="me-1 position-relative pos-top-n1" width="14" height="14"
                                 viewBox="0 0 14 14"
                                 fill="#666666"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0 6.66667L1.175 7.84167L5.83333 3.19167V13.3333H7.5V3.19167L12.15 7.85L13.3333 6.66667L6.66667 0L0 6.66667Z"/>
                            </svg>
                            <span title={localizer.msg('stage.ideate.upvote_count', {count: upVoteCount})}>
                                            {localizer.msg('stage.ideate.upvote_count', {count: upVoteCount})}
                                        </span>
                        </div>
                        <div className="down-vote-counts text-truncate">
                            <svg className="me-1 position-relative pos-top-n1" width="14" height="14"
                                 viewBox="0 0 14 14"
                                 fill="#666666"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M13.3333 6.66671L12.1583 5.49171L7.49998 10.1417L7.49998 4.24716e-05L5.83331 4.26173e-05L5.83331 10.1417L1.18331 5.48338L-1.96563e-05 6.66671L6.66665 13.3334L13.3333 6.66671Z"/>
                            </svg>
                            <span title={localizer.msg('stage.ideate.downvote_count', {count: downVoteCount})}>
                                            {localizer.msg('stage.ideate.downvote_count', {count: downVoteCount})}
                                        </span>
                        </div>
                    </div>
                }
            </Fragment>
            : <StageSummaryHeading heading={localizer.msg('stage.ideate.heading')}/>
    );
};