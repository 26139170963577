import React, {Fragment, useCallback} from 'react';
import {useCommunityService} from 'hooks/useService';
import {useLocalizer} from 'hooks/useLocalizer';
import {useSearchContext} from 'contexts/SearchContext';
import {useAppContext} from 'contexts/AppContext';
import {IdeaSearchResultContainer} from './IdeaSearchResultContainer';
import {CampaignSearchResults} from 'components/CampaignSearchResults';
import {MemberSearchResults} from 'components/MemberSearchResults';
import {CampaignSearchParameters} from 'models/types/CampaignSearchParameters';
import {MemberSearchParams} from 'models/types/MemberSearchParamaters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {GenericResponse} from 'models/GenericResponse';
import {SearchedMember} from 'models/SearchedMember';
import {CampaignSearchItem} from 'models/CampaignSearchItem';
import {SearchCategory} from 'models/enums/SearchCategory';

export const SearchResultContainer = () => {
    const localizer = useLocalizer();
    const {authentication: {actor}} = useAppContext();
    const communityService = useCommunityService();
    const {searchCategory} = useSearchContext();

    const searchMembers = useCallback(async (searchParams: MemberSearchParams): Promise<PagedResponseContent<SearchedMember>> => {
        if (communityService !== null) {
            return communityService.searchMembers(searchParams);
        }
        return PagedResponseContent.nullObject();
    }, [communityService]);

    const toggleMemberFollowed = useCallback(async (memberId: number, followed: boolean) => {
        if (communityService !== null) {
            if (followed) {
                return communityService.unfollowMember(memberId);
            } else {
                return communityService.followMember(memberId);
            }
        }
        return GenericResponse.EMPTY;
    }, [communityService]);

    const searchCampaigns = useCallback(async (parameters: CampaignSearchParameters) => {
        if (communityService) {
            return await communityService.searchCampaigns(parameters);
        }
        return PagedResponseContent.nullObject<CampaignSearchItem>();
    }, [communityService]);

    return (
        <Fragment>
            {
                (searchCategory === SearchCategory.ALL || searchCategory === SearchCategory.IDEAS) &&
                <IdeaSearchResultContainer/>
            }
            {
                (searchCategory === SearchCategory.ALL || searchCategory === SearchCategory.CAMPAIGN) &&
                <CampaignSearchResults
                    localizer={localizer}
                    searchCampaigns={searchCampaigns}
                />
            }
            {
                !actor.isCommunityMember() &&
                (searchCategory === SearchCategory.ALL || searchCategory === SearchCategory.MEMBERS) && (
                    <MemberSearchResults
                        localizer={localizer}
                        fetchResults={searchMembers}
                        toggleMemberFollowed={toggleMemberFollowed}
                    />
                )
            }
        </Fragment>
    );
};
