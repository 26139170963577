import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Avatar, Icon, Scrollbar, ScrollbarInstance} from '@ideascale/ui';
import debounce from 'lodash/debounce';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {
    AlertEvent,
    AlertType,
    buildAlertEventData,
    eventDispatcher,
    InfiniteScrollLoadMoreButton,
    Localizer,
    MemberLink,
    ParagraphSkeleton,
    useApiErrorResponseHandler,
    useIntersectionObserver
} from '@ideascale/commons';
import {useSearchContext} from 'contexts/SearchContext';
import {MemberSearchParams} from 'models/types/MemberSearchParamaters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {SearchedMember} from 'models/SearchedMember';
import {GenericResponse} from 'models/GenericResponse';
import './MemberSearchResults.scss';

type MemberSearchResultsProps = {
    localizer: Localizer;
    fetchResults: (searchParams: MemberSearchParams) => Promise<PagedResponseContent<SearchedMember>>;
    toggleMemberFollowed: (memberId: number, followed: boolean) => Promise<GenericResponse>;
}

export const MemberSearchResults = (props: MemberSearchResultsProps) => {
    const {localizer, fetchResults, toggleMemberFollowed} = props;
    const {term, getSearchParams} = useSearchContext();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const scrollbarContainer = useRef<ScrollbarInstance>(null);
    const loadMoreButtonRef = useRef<HTMLButtonElement>(null);
    const currentPage = useRef(0);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);
    const [memberData, setMemberData] = useState<SearchedMember[]>([]);
    const [totalResults, setTotalResults] = useState(0);

    const toggleFollow = async (memberId: number, followed: boolean) => {
        try {
            const {message} = await toggleMemberFollowed(memberId, followed);
            const newMemberData = [...memberData];
            const toggledMember = newMemberData.find(member => member.id === memberId);
            if (toggledMember) {
                toggledMember.followedByActor = !followed;
            }
            setMemberData(newMemberData);
            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, message));
        } catch (error: any) {
            handleErrorResponse(error);
        }
    };

    const loadMembers = useCallback(async () => {
        try {
            const newSearchParams: MemberSearchParams = {
                ...getSearchParams,
                profileFields: getSearchParams.authorSelectionFields
            };
            newSearchParams.pageParameters.page = currentPage.current;
            const response = await fetchResults(newSearchParams);
            if (response.pageNo === 0) {
                currentPage.current = 0;
                setMemberData(response.content);
            } else {
                setMemberData(prevData => [...prevData, ...response.content]);
            }
            currentPage.current = response.hasMore ? currentPage.current + 1 : currentPage.current;
            setHasMore(response.hasMore);
            setTotalResults(response.totalElements);
        } catch (e: any) {
            handleErrorResponse(e);
        } finally {
            setLoading(false);
        }
    }, [fetchResults, getSearchParams, handleErrorResponse]);

    useIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: loadMembers,
        enabled: hasMore,
        options: {
            root: scrollbarContainer.current?.container
        }
    });

    useEffect(() => {
        currentPage.current = 0;
        setLoading(false);
        setTotalResults(0);
        setHasMore(false);
        setMemberData([]);
    }, [getSearchParams]);

    useEffect(() => {
        let debounceLoadMembers: any = undefined;
        if (currentPage.current === 0) {
            debounceLoadMembers = debounce(loadMembers, 300);
        }
        debounceLoadMembers && debounceLoadMembers();
        return () => {
            debounceLoadMembers && debounceLoadMembers.cancel();
        };
    }, [loadMembers, getSearchParams]);

    return (
        loading ? (
            <section className="mt-4 searched-members">
                <div className="card panel panel-default">
                    <div className="card-body py-2">
                        <ParagraphSkeleton rows={5}/>
                    </div>
                </div>
            </section>
        ) : (
            <section className="mt-4">
                <h2 className="fw-bold font-size-md h3" data-test-element-id="search-member-count">
                    {
                        term
                            ? (
                                totalResults === 0
                                    ? localizer.msg('search.member.no-results-with-term', {term: term})
                                    : localizer.msg('search.member.result', {count: totalResults, term: term})
                            )
                            : (
                                totalResults === 0
                                    ? localizer.msg('search.member.no-results')
                                    : localizer.msg('search.member.result-without-term', {count: totalResults})
                            )
                    }
                </h2>
                {
                    memberData.length > 0 &&
                    (
                        <div className="card panel panel-default" data-test-element-id="member-search-list">
                            <div className="card-body pe-2 py-2">
                                <Scrollbar ref={scrollbarContainer}
                                           autoHeight
                                           autoHeightMax={250}>
                                    <ul className="list-unstyled row">
                                        {
                                            memberData.map(member =>
                                                <li key={member.id} className="col-12 col-sm-6 col-md-4">
                                                    <div className="d-flex align-items-center my-3 text-truncate">
                                                        <MemberLink id={member.id}
                                                                    identityHidden={member.identityHidden}>
                                                            <Avatar className="me-4" size="md"
                                                                    alt={member.username ? localizer.msg('common.user-avatar', {username: member.username}) : ''}
                                                                    src={member.avatar}/>
                                                        </MemberLink>
                                                        <div className="d-flex align-items-start">
                                                            <button className="btn p-0 me-1 subscribe-icon"
                                                                    role="switch"
                                                                    aria-checked={member.followedByActor}
                                                                    title={localizer.msg(member.followedByActor
                                                                        ? 'profile.followed-sections.member.unfollow'
                                                                        : 'profile.followed-sections.member.follow')}
                                                                    onClick={() => toggleFollow(member.id, member.followedByActor)}>
                                                                <Icon className="active"
                                                                      iconSpritePath={svgIconPath}
                                                                      name={member.followedByActor ? 'star-solid' : 'star-outlined'}
                                                                      width={20}
                                                                      height={20}/>
                                                            </button>
                                                            <MemberLink className="member-name" id={member.id}
                                                                        identityHidden={member.identityHidden}
                                                                        title={member.name}>
                                                                <p className="h5 text-truncate font-size-lg fw-bold mb-1">{member.name}</p>
                                                                {
                                                                    member.username && <span
                                                                        className="text-truncate font-size-sm text-muted d-block">@{member.username}</span>
                                                                }
                                                            </MemberLink>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        }
                                    </ul>
                                    <InfiniteScrollLoadMoreButton hasMore={hasMore}
                                                                  localizer={localizer}
                                                                  loading={loading}
                                                                  onCLick={loadMembers}
                                                                  loadMoreButtonRef={loadMoreButtonRef}/>
                                </Scrollbar>
                            </div>
                        </div>
                    )
                }
            </section>
        )
    );
};