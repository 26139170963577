import {UiAttribute} from 'models/landing-page/UiAttribute';
import {LandingPageComponentType} from '../enums/landing-page/LandingPageComponentType';

export abstract class Component {

    protected constructor(
        public id: number,
        public languageId: number,
        public type: LandingPageComponentType,
        public visible: boolean,
        public attribute: UiAttribute,
        public title?: string,
        public subtitle?: string
    ) {
    }
}