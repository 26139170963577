import {useCallback} from 'react';
import {ClassifiableContributionType, useApiErrorResponseHandler} from '@ideascale/commons';
import {useClassificationService} from './useClassificationService';
import {useIdeaUpdater} from './useIdeaUpdater';
import {useLocalizer} from './useLocalizer';

export const useUpdateEffectiveClassifications = () => {
    const {fetchEffectiveClassifications} = useClassificationService();
    const {updateIdeaEffectiveClassificationAttribute} = useIdeaUpdater();
    const localizer = useLocalizer();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});

    const updateIdeaEffectiveClassifications = useCallback(async (ideaId: number, classificationEnabled: boolean) => {
        if (classificationEnabled) {
            const requestBody = [{id: ideaId, type: ClassifiableContributionType.IDEA}];
            try {
                const response = await fetchEffectiveClassifications(requestBody);
                const ideaEffectiveClassification = response?.find(item => item.id === ideaId);
                updateIdeaEffectiveClassificationAttribute(ideaId, ideaEffectiveClassification);
            } catch (e) {
                handleErrorResponse(e);
            }
        }
    }, [fetchEffectiveClassifications, handleErrorResponse, updateIdeaEffectiveClassificationAttribute]);

    return {updateIdeaEffectiveClassifications};
};