import {ConfigField} from './ConfigField';
import {CampaignEditableFieldType} from './CampaignEditableFieldType';

export class TosConfigField extends ConfigField {
    public static EMPTY = new TosConfigField(CampaignEditableFieldType.UNKNOWN, '', false);

    constructor(
        name: CampaignEditableFieldType,
        public value: string,
        public tosEnabled: boolean,
    ) {
        super(name, value);
    }
}