import {Command} from './Command';
import {CommunityHomeEditor} from './Editors/CommunityHomeEditor';
import {Operation} from 'models/edit-mode/Operation';
import {CommunityOperationType} from 'models/edit-mode/CommunityOperationType';
import {ChangeCommunitySubtitleOperation} from 'models/edit-mode/ChangeCommunitySubtitleOperation';

export class UpdateCommunitySubtitleCommand implements Command {
    private readonly communityHomeEditor: CommunityHomeEditor;
    private readonly oldCommunitySubtitle: string;
    private readonly newCommunitySubtitle: string;
    private readonly type: CommunityOperationType;

    constructor(communityHomeEditor: CommunityHomeEditor, newSubtitle: string) {
        this.communityHomeEditor = communityHomeEditor;
        this.oldCommunitySubtitle = this.communityHomeEditor.communitySubtitle;
        this.newCommunitySubtitle = newSubtitle;
        this.type = CommunityOperationType.CHANGE_COMMUNITY_SUBTITLE;
    }

    execute(): void {
        this.communityHomeEditor.communitySubtitle = this.newCommunitySubtitle;
    }

    getType(): CommunityOperationType {
        return this.type;
    }

    getOperation(): Operation {
        return new ChangeCommunitySubtitleOperation(this.type, this.communityHomeEditor.communitySubtitle);
    }

    undo(): void {
        this.communityHomeEditor.communitySubtitle = this.oldCommunitySubtitle;
    }
}