import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Trans} from 'react-i18next';
import {
    CLASSIFICATION_NAME,
    ClassificationLabel,
    ClassificationUtil,
    Localizer,
    MemberLink,
    TimeAgo
} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {appLinks} from 'services/AppLinks';
import {NotificationAvatar} from './NotificationAvatar';
import {IdeaActionNotification} from './IdeaActionNotification';
import {IdeaEventNotification} from './IdeaEventNotification';
import {PositionAssignmentNotification} from './PositionAssignmentNotification';
import {StageActionNotification} from './StageActionNotification';
import {CommentApprovedNotification} from './CommentApprovedNotification';
import {IdeaKudoReceivedNotification} from './IdeaKudoReceivedNotification';
import {CommentKudoReceivedNotification} from './CommentKudoReceivedNotification';
import {IdeaCampaignChangedNotification} from './IdeaCampaignChangedNotification';
import {IdeaStageChangedNotification} from './IdeaStageChangedNotification';
import {IdeaAnnotatedNotification} from './IdeaAnnotatedNotification';
import {IdeaTopTrendingNotification} from './IdeaTopTrendingNotification';
import {IdeaTagAddedNotification} from './IdeaTagAddedNotification';
import {IdeaShareNotification} from './IdeaShareNotification';
import {CampaignDigestNotification} from './CampaignDigestNotification';
import {IdeaOwnerAssignedNotification} from './IdeaOwnerAssignedNotification';
import {DigestNotification} from './DigestNotification';
import {NotificationData} from 'models/NotificationData';
import {NotificationType} from 'models/enums/NotificationType';
import './NotificationList.scss';

type NotificationListProps = {
    localizer: Localizer;
    getDataForDigestModal: (year: string, notificationType: NotificationType, month?: string, week?: string, campaignId?: number, memberId?: number) => void;
    notifications: NotificationData[];
    actorId: number
}

export const NotificationList = (props: NotificationListProps) => {
    const {localizer, actorId, notifications, getDataForDigestModal} = props;
    const {communityConfig} = useAppContext();
    const renderNotification = (notification: NotificationData) => {
        const {
            type: {name: typeName},
            viewAttributes,
            ideaAuthorId,
            campaignId,
            ideaId,
            sourceMemberId,
            identityHidden,
            commentId,
            targetMemberId,
            fieldValueId
        } = notification;
        switch (typeName) {
            case NotificationType.IDEA_SUBMITTED:
                return <IdeaActionNotification
                    type={typeName} memberId={ideaAuthorId} identityHidden={identityHidden}
                    memberLinkText={viewAttributes.authorName}
                    ideaLink={appLinks.ideaDetails(ideaId)}
                    ideaLinkText={viewAttributes.ideaTitle}/>;
            case NotificationType.IDEA_FILE_ATTACHED:
            case NotificationType.IDEA_MODIFIED:
                return <IdeaActionNotification
                    type={typeName} self={actorId === ideaAuthorId}
                    memberId={sourceMemberId} identityHidden={identityHidden}
                    memberLinkText={viewAttributes.sourceMemberName}
                    ideaLink={appLinks.ideaDetails(ideaId)}
                    ideaLinkText={viewAttributes.ideaTitle}/>;
            case NotificationType.IDEA_VOTED:
                return <Trans
                    values={{
                        member: viewAttributes.sourceMemberName,
                        idea: viewAttributes.ideaTitle,
                        voteDirection: viewAttributes.voteDirection
                    }}
                    i18nKey={`notification.idea.voted${actorId === ideaAuthorId ? '.self' : '.others'}`}>
                    <MemberLink id={sourceMemberId} identityHidden={identityHidden}/>
                    <Link to={appLinks.ideaDetails(ideaId)}/>
                </Trans>;
            case NotificationType.IDEA_APPROVED:
            case NotificationType.IDEA_AT_MENTIONED:
            case NotificationType.IDEA_TEAM_POSITION_REMOVED:
            case NotificationType.IDEA_TEAM_MEMBER_LEFT:
            case NotificationType.IDEA_TEAM_MEMBER_JOINED:
            case NotificationType.IDEA_CUSTOM_FIELD_AT_MENTIONED:
                return <IdeaActionNotification
                    type={typeName} memberId={sourceMemberId} identityHidden={identityHidden}
                    memberLinkText={viewAttributes.sourceMemberName}
                    ideaLink={appLinks.ideaDetails(ideaId)}
                    ideaLinkText={viewAttributes.ideaTitle}/>;
            case NotificationType.STAGE_CUSTOM_FIELD_AT_MENTIONED:
                return <IdeaActionNotification
                    type={typeName} memberId={sourceMemberId} identityHidden={identityHidden}
                    memberLinkText={viewAttributes.sourceMemberName}
                    ideaLink={appLinks.ideaDetailsMentionedActivity(ideaId, fieldValueId)}
                    ideaLinkText={viewAttributes.ideaTitle}/>;
            case NotificationType.COMMENT_POSTED:
                return <IdeaActionNotification
                    type={typeName} self={actorId === ideaAuthorId}
                    memberId={sourceMemberId} identityHidden={identityHidden}
                    memberLinkText={viewAttributes.authorName}
                    ideaLink={appLinks.ideaDetailsComment(ideaId, commentId)}
                    ideaLinkText={viewAttributes.ideaTitle}/>;
            case NotificationType.COMMENT_AT_MENTIONED:
                return <IdeaActionNotification
                    type={typeName} memberId={sourceMemberId} identityHidden={identityHidden}
                    memberLinkText={viewAttributes.sourceMemberName}
                    ideaLink={appLinks.ideaDetailsComment(ideaId, commentId)}
                    ideaLinkText={viewAttributes.ideaTitle}/>;
            case NotificationType.STAGE_COMMENT_AT_MENTIONED:
                return <IdeaActionNotification
                    type={typeName} memberId={sourceMemberId} identityHidden={identityHidden}
                    memberLinkText={viewAttributes.sourceMemberName}
                    ideaLink={appLinks.stageComment(campaignId, ideaId, notification.stageCommentId)}
                    ideaLinkText={viewAttributes.ideaTitle}/>;
            case NotificationType.IDEA_INACTIVE:
                return <IdeaEventNotification
                    ideaId={ideaId}
                    ideaTitle={viewAttributes.ideaTitle}
                    count={notification.inactiveDaysThreshold}
                    messageKey="notification.idea.inactive"/>;
            case NotificationType.IDEA_VOTE_MILESTONE_ACHIEVED:
                return <IdeaEventNotification
                    ideaId={ideaId}
                    ideaTitle={viewAttributes.ideaTitle}
                    count={notification.voteCount}
                    messageKey="notification.idea.idea-vote-milestone"/>;
            case NotificationType.IDEA_VOTE_THRESHOLD_ACHIEVED:
                return <IdeaEventNotification
                    ideaId={ideaId}
                    ideaTitle={viewAttributes.ideaTitle}
                    messageKey="notification.idea.idea-vote-threshold"/>;
            case NotificationType.IDEA_TEAM_MEMBER_ASSIGNED:
                return <PositionAssignmentNotification
                    memberId={sourceMemberId} identityHidden={identityHidden}
                    memberLinkText={viewAttributes.sourceMemberName}
                    ideaLink={appLinks.ideaDetails(ideaId)}
                    ideaLinkText={viewAttributes.ideaTitle}
                    messageKey={'notification.idea.team.member.assigned'}
                    targetObject={actorId === targetMemberId ? (
                        <span/>
                    ) : (
                        <Link to={appLinks.profile(targetMemberId)}/>
                    )}
                    targetName={actorId === targetMemberId ?
                        localizer.msg('notification.idea.team.member.you')
                        :
                        viewAttributes.assignedMemberName}/>;
            case NotificationType.IDEA_TEAM_MEMBER_UNASSIGNED:
                return <PositionAssignmentNotification
                    memberId={sourceMemberId} identityHidden={identityHidden}
                    memberLinkText={viewAttributes.sourceMemberName} ideaLink={appLinks.ideaDetails(ideaId)}
                    ideaLinkText={viewAttributes.ideaTitle} messageKey={'notification.idea.team.member.unassigned'}
                    targetName={actorId === targetMemberId ?
                        localizer.msg('notification.idea.team.member.you')
                        :
                        viewAttributes.unassignedMemberName}
                    targetObject={
                        actorId === targetMemberId ? (
                            <span/>
                        ) : (
                            <Link to={appLinks.profile(targetMemberId)}/>
                        )
                    }/>;
            case NotificationType.IDEA_TEAM_POSITION_ADDED:
                return <PositionAssignmentNotification
                    memberId={sourceMemberId} identityHidden={identityHidden}
                    memberLinkText={viewAttributes.sourceMemberName} ideaLink={appLinks.ideaDetails(ideaId)}
                    ideaLinkText={viewAttributes.ideaTitle} messageKey="notification.idea.team.position.added"
                    targetName={viewAttributes.teamRoleName} targetObject={<strong/>}/>;
            case NotificationType.IDEA_TEAM_ROLE_ADDED:
                return <PositionAssignmentNotification
                    memberId={sourceMemberId} identityHidden={identityHidden}
                    memberLinkText={viewAttributes.sourceMemberName}
                    ideaLink={appLinks.ideaDetails(ideaId)}
                    ideaLinkText={viewAttributes.ideaTitle}
                    messageKey="notification.idea.team.role-assigned"
                    targetName={viewAttributes.teamRoleName} targetObject={<strong/>}/>;
            case NotificationType.IDEA_REFINE_ANSWER_MODIFIED:
            case NotificationType.IDEA_REFINE_ANSWER_SUBMITTED:
            case NotificationType.IDEA_FUND_TARGET_MODIFIED:
            case NotificationType.IDEA_FUND_TARGET_SUBMITTED:
            case NotificationType.ASSESSMENT_REVIEW_MODIFIED:
            case NotificationType.ASSESSMENT_REVIEW_SUBMITTED:
            case NotificationType.REVIEWSCALE_REVIEW_MODIFIED:
            case NotificationType.REVIEWSCALE_REVIEW_SUBMITTED:
            case NotificationType.ESTIMATE_ROI_SUBMITTED:
                return <StageActionNotification
                    type={typeName}
                    memberId={sourceMemberId} identityHidden={identityHidden}
                    memberLinkText={viewAttributes.sourceMemberName}
                    ideaLink={appLinks.ideaDetails(ideaId)}
                    ideaLinkText={viewAttributes.ideaTitle}/>;
            case NotificationType.COMMENT_APPROVED:
                return <CommentApprovedNotification notification={notification}/>;
            case NotificationType.IDEA_KUDO_RECEIVED:
                return <IdeaKudoReceivedNotification
                    localizer={localizer} actorId={actorId}
                    notification={notification}/>;
            case NotificationType.COMMENT_KUDO_RECEIVED:
                return <CommentKudoReceivedNotification
                    localizer={localizer}
                    actorId={actorId}
                    notification={notification}/>;
            case NotificationType.BONUS_5X_KUDO_ISSUED:
                return localizer.msg('notification.kudos.bonus.5x-kudos-issued');
            case NotificationType.IDEA_CAMPAIGN_CHANGED:
                return <IdeaCampaignChangedNotification
                    actorId={actorId}
                    notification={notification}/>;
            case NotificationType.IDEA_STAGE_CHANGED:
                return <IdeaStageChangedNotification
                    actorId={actorId}
                    notification={notification}/>;
            case NotificationType.IDEA_ANNOTATED:
                return <IdeaAnnotatedNotification notification={notification}/>;
            case NotificationType.IDEA_TOP_TRENDING:
                return <IdeaTopTrendingNotification localizer={localizer} notification={notification}/>;
            case NotificationType.IDEA_TAG_ADDED:
                return <IdeaTagAddedNotification notification={notification}/>;
            case NotificationType.IDEA_OWNER_ASSIGNED:
                return <IdeaOwnerAssignedNotification
                    actorId={actorId}
                    notification={notification}/>;
            case NotificationType.IDEA_SHARED:
                return <IdeaShareNotification localizer={localizer} notification={notification}/>;
            case NotificationType.MONTHLY_MODERATOR_DIGEST:
            case NotificationType.WEEKLY_MODERATOR_DIGEST:
                return <DigestNotification localizer={localizer} notification={notification}
                                           getDataForDigestModal={getDataForDigestModal}/>;
            case NotificationType.SHARED_WEEKLY_CAMPAIGN_PERFORMANCE:
            case NotificationType.SHARED_MONTHLY_CAMPAIGN_PERFORMANCE:
                return <CampaignDigestNotification localizer={localizer} notification={notification}
                                                   getDataForDigestModal={getDataForDigestModal}/>;
        }
    };

    return (
        <Fragment>
            {
                notifications.map(notification =>
                    <li key={notification.id} className={`d-flex my-2${!notification.seenByActor ? ' unseen' : ''}`}>
                        <NotificationAvatar
                            type={notification.type.name}
                            voteCount={notification.voteCount}
                            notificationIcon={notification.notificationIcon}/>
                        <div>
                            {communityConfig.classificationEnabled && notification.classificationAttributes &&
                                <ClassificationLabel
                                    classification={ClassificationUtil.getAttributeByName(CLASSIFICATION_NAME.IDEA_CLASSIFICATION, notification.classificationAttributes)}
                                    extraClasses="me-1 align-bottom text-nowrap"/>
                            }
                                {renderNotification(notification)}
                            <div className="text-muted">
                                <TimeAgo localizer={localizer} dateISOString={notification.createdAt}
                                         prefixText={false}/>
                            </div>
                        </div>
                    </li>
                )
            }
        </Fragment>
    );
};