import {Stage} from './types/Stage';
import {Member} from '@ideascale/commons';
import {StageGroup} from './enums/StageGroup';
import {CampaignChangeActivity} from './CampaignChangeActivity';

export class StageActivitySummary {
    public static readonly EMPTY = new StageActivitySummary('', '', {
        id: 0,
        key: '',
        name: '',
        label: '',
        description: '',
        functionName: '',
        stageGroup: StageGroup.ACTIVE
    }, {
        id: 0,
        key: '',
        name: '',
        label: '',
        description: '',
        functionName: '',
        stageGroup: StageGroup.ACTIVE
    }, false, {
        id: 0,
        name: '',
        username: '',
        avatar: '',
        url: ''
    }, '', '', CampaignChangeActivity.EMPTY);

    constructor(
        public activitySource: string,
        public activityType: string,
        public oldStage: Stage,
        public newStage: Stage,
        public funnelChanged: boolean,
        public member: Member,
        public description: string,
        public createdAt: string,
        public campaignChangeActivity: CampaignChangeActivity,
        public campaignName? : string
    ) {
    }
}