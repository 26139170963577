import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {useCommunityService} from '../hooks/useService';
import {FeatureHolder} from '../models/new-features/FeatureHolder';
import {useLocalizer} from '../hooks/useLocalizer';
import {Features} from '../components/Features';
import {ParagraphSkeleton} from '@ideascale/ui';

export const ReleasedFeaturesContainer = () => {
    const communityService = useCommunityService();
    const localizer = useLocalizer();
    const pageRef = useRef(0);
    const [progress, setProgress] = useState(false);
    const [featureHolders, setFeatureHolders] = useState<FeatureHolder[]>([]);
    const [hasMore, setHasMore] = useState(false);

    const loadReleaseFeatures = useCallback(() => {
        if (communityService !== null) {
            try {
                communityService.fetchReleasedFeatures(pageRef.current).then(response => {
                    setFeatureHolders(prevState => [...prevState, ...response.featureHolders]);
                    setHasMore(response.hasMore);
                    if (response.hasMore) {
                        pageRef.current = response.nextPage ?? pageRef.current + 1;
                    }
                    setProgress(false);
                });
            } catch(e: any) {
                console.log(e);
            }
        }
    }, [communityService]);

    useEffect(() => {
        setProgress(true);
        loadReleaseFeatures();
    }, [communityService, loadReleaseFeatures]);

    const loadNextReleaseFeatures = () => {
        setProgress(true);
        loadReleaseFeatures();
    };

    return (
        <section>
            <h1 className="h2 mb-4">{localizer.msg('new-features.heading')}</h1>
            {
                (progress && pageRef.current === 0)
                    ?
                    <ParagraphSkeleton rows={6}/>
                    :
                    <Fragment>
                        {
                            featureHolders && featureHolders.length > 0
                                ?
                                <Fragment>
                                    {
                                        featureHolders.map(featureHolder => (
                                            <div className="mb-5" key={featureHolder.releasedAt}>
                                                <h2 className="h3">{featureHolder.releasedAt}</h2>
                                                <div className="bg-gray p-3 rounded">
                                                    <ul className="list-group">
                                                        {
                                                            featureHolder.features.map(feature => <li
                                                                className="list-group-item" key={feature.id}>
                                                                <Features localizer={localizer} feature={feature} dateTextPrefix="Released"/>
                                                            </li>)
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    {
                                        progress && pageRef.current > 0 &&
                                        <div className="mb-5">
                                            <ParagraphSkeleton rows={4}/>
                                        </div>
                                    }
                                    {
                                        hasMore &&
                                        <button className="btn btn-secondary mt-n3" type="button"
                                                onClick={loadNextReleaseFeatures}>
                                            Show Older
                                        </button>
                                    }
                                </Fragment>
                                :
                                <Fragment>No released features.</Fragment>
                        }
                    </Fragment>

            }
        </section>
    );
};