import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {useQueryClient} from 'react-query';
import {AlertEvent, AlertType, buildAlertEventData, eventDispatcher, useApiErrorResponseHandler} from '@ideascale/commons';
import {useLocalizer} from './useLocalizer';
import {useAppContext} from 'contexts/AppContext';
import {useIdeaListContext} from 'contexts/IdeaListContext';
import {useIdeaDetailsContext} from 'contexts/IdeaDetailsContext';
import {appLinks} from 'services/AppLinks';
import {QUERY_KEYS, SCROLL_EVENTS, SHOW_LOGIN_PROMPT} from 'constants/AppConstants';
import {BaseIdeaStageSummary} from 'models/BaseIdeaStageSummary';
import {GenericResponse} from 'models/GenericResponse';
import {PermissionHolder} from 'models/IdeaDetail';

export const useIdeaActions = () => {
    const {
        authentication,
        communityConfig: {
            ssoEnabled,
        }
    } = useAppContext();
    const localizer = useLocalizer();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const {
        updateIdeaAuthorFollow,
        updateIdeaCommentingEnabled,
        updateIdeaSubscription,
        onTogglePinToLandingPage,
    } = useIdeaListContext();
    const {
        updateIdeaAuthorFollow: updateIdeaDetailsAuthorFollow,
        updateIdeaCommentingEnabled: updateIdeaDetailsCommentingEnabled,
        updateIdeaSubscription: updateIdeaDetailsSubscription,
        updateIdeaPermissionHolder,
        onTogglePinToLandingPage: onTogglePinIdeaToLandingPageOnDetailsPage,
    } = useIdeaDetailsContext();

    const onStageActionClick = useCallback((stageSummary: BaseIdeaStageSummary, ideaId: number, detailPage?: boolean) => {
        if (stageSummary?.stage?.name) {
            if (!ssoEnabled && authentication.actor.isAnonymous()) {
                eventDispatcher.dispatch(SHOW_LOGIN_PROMPT, localizer.msg(`login-prompt.message.${stageSummary.stage.name.toLowerCase()}`));
            } else {
                if (detailPage) {
                    eventDispatcher.dispatch(SCROLL_EVENTS.DETAILS_TABS);
                }

                navigate({
                    pathname: appLinks.ideaDetailsActivity(ideaId),
                    hash: stageSummary.stage.key.toLowerCase()
                });
            }
        }
    }, [authentication, navigate, localizer, ssoEnabled]);

    const onToggleAuthorFollowAction = useCallback((toggleAuthorFollowed: (memberId: number, followed: boolean) => Promise<GenericResponse>, ideaId: number, memberId: number, followed: boolean, detailPage?: boolean) => {
        toggleAuthorFollowed(memberId, followed).then(response => {
            if (response?.status === 'success') {
                if (detailPage) {
                    updateIdeaDetailsAuthorFollow(ideaId, !followed);
                } else {
                    updateIdeaAuthorFollow(ideaId, !followed);
                }
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, followed ? localizer.msg('idea.actions.unfollow-author-succeeded') : localizer.msg('idea.actions.follow-author-succeeded')));
            }
        }).catch((e) => {
           handleErrorResponse(e);
        });
    }, [handleErrorResponse, localizer, updateIdeaAuthorFollow, updateIdeaDetailsAuthorFollow]);

    const onToggleFollowAction = useCallback(async (toggleIdeaFollowed: (ideaId: number, followed: boolean) => Promise<GenericResponse>, ideaId: number, followed: boolean, detailPage?: boolean, followersCount?: number) => {
        try {
            const response = await toggleIdeaFollowed(ideaId, followed);
            if (response && response.status === 'success') {
                if (detailPage) {
                    const followers = followersCount || 0;
                    updateIdeaDetailsSubscription(ideaId, !followed, followed ? followers - 1 : followers + 1);
                } else {
                    updateIdeaSubscription(ideaId, !followed);
                }
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, response.message));
            }
        } catch(e: any) {
            handleErrorResponse(e);
        }
    }, [handleErrorResponse, updateIdeaDetailsSubscription, updateIdeaSubscription]);

    const onToggleCommentEnabled = useCallback(async (toggleCommentingEnabled: (ideaId: number, enabled: boolean) => Promise<void>, ideaId: number, ideaTitle: string, commentingEnabled: boolean, detailPage?: boolean, detailPermissions?: PermissionHolder) => {
        try {
            await toggleCommentingEnabled(ideaId, commentingEnabled);
            if (detailPage) {
                if (detailPermissions) {
                    const newPermissionHolder = {...detailPermissions};
                    newPermissionHolder.moderationPermissionHolder.commentingEnabled = !commentingEnabled;
                    updateIdeaDetailsCommentingEnabled(ideaId, !commentingEnabled, newPermissionHolder);
                }
            } else {
                updateIdeaCommentingEnabled(ideaId, !commentingEnabled);
            }
            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, commentingEnabled
                ? localizer.msg('idea.actions.comment-disable-success', {ideaTitle: ideaTitle})
                : localizer.msg('idea.actions.comment-enable-success', {ideaTitle: ideaTitle})));
        } catch(e: any) {
            handleErrorResponse(e);
        }
    }, [handleErrorResponse, localizer, updateIdeaCommentingEnabled, updateIdeaDetailsCommentingEnabled]);

    const onBanMemberAction = useCallback(async (banMember: (memberId: number) => Promise<GenericResponse>, ideaId: number, memberId: number, detailPage?: boolean, detailPermissions?: PermissionHolder) => {
        try {
            const response = await banMember(memberId);
            if (response && response.status === 'success') {
                if (detailPage && detailPermissions) {
                    const newPermissionHolder = {...detailPermissions};
                    newPermissionHolder.banMemberAllowed = !detailPermissions.banMemberAllowed;
                    newPermissionHolder.ideaAuthorFollowAllowed = !detailPermissions.ideaAuthorFollowAllowed;
                    updateIdeaPermissionHolder(ideaId, newPermissionHolder);
                }
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, response.message));
            }
            await queryClient.invalidateQueries([QUERY_KEYS.MORE_ACTIONS, ideaId]);
        } catch(e: any) {
            handleErrorResponse(e);
        }
    }, [handleErrorResponse, queryClient, updateIdeaPermissionHolder]);

    const onTogglePinToLandingPageAction = useCallback(async (togglePinIdeas: (ideaId: number, pinned: boolean) => Promise<void>, ideaId: number, pinned: boolean, detailPage?: boolean) => {
        try {
            await togglePinIdeas(ideaId, pinned);
            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, pinned ? localizer.msg('idea.actions.pin-to-landing-success') : localizer.msg('idea.actions.unpin-from-landing-success')));
            if(detailPage) {
                onTogglePinIdeaToLandingPageOnDetailsPage(ideaId, pinned);
            } else {
                onTogglePinToLandingPage(ideaId, pinned);
            }
        } catch(e: any) {
            handleErrorResponse(e);
        }
    }, [localizer, onTogglePinIdeaToLandingPageOnDetailsPage, onTogglePinToLandingPage, handleErrorResponse]);

    return {
        onStageActionClick,
        onToggleAuthorFollowAction,
        onToggleFollowAction,
        onToggleCommentEnabled,
        onBanMemberAction,
        onTogglePinToLandingPageAction,
    };
};