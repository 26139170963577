import {AssessmentRating} from './AssessmentRating';
import {AssessmentRatingHint} from './AssessmentRatingHint';
import {AssessmentStageActivity} from './AssessmentStageActivity';

export class AssessmentStageRatingActivity extends AssessmentStageActivity {
    public static readonly EMPTY: AssessmentStageRatingActivity = {
        ...AssessmentStageActivity.EMPTY,
        notesRequired: false,
        assessmentRatings: [],
        hints: [],
    };

    notesRequired: boolean;
    assessmentRatings: AssessmentRating[];
    hints: AssessmentRatingHint[];
}