import React, {Fragment} from 'react';
import {Localizer} from '@ideascale/commons';
import {ActivityStreamData} from '../../../../models/ActivityStreamData';
import {ActionType} from '../../../../models/enums/ActionType';
import {ActivityLink} from '../ActivityLink';

type RecycleBinActivityProps = {
    localizer: Localizer;
    activity: ActivityStreamData;
    action: ActionType.IDEA_RECYCLE_BIN | ActionType.COMMENT_RECYCLE_BIN;
}

export const RecycleBinActivity = (props: RecycleBinActivityProps) => {
    const {localizer, activity, action} = props;

    return (
        <Fragment>
            {action === ActionType.IDEA_RECYCLE_BIN
                ? localizer.msg('profile.activity-stream.recycle-bin.idea')
                : localizer.msg('profile.activity-stream.recycle-bin.comment')
            }{' - '}
            <ActivityLink title={activity.idea.title} ideaId={activity.idea.id}/>
        </Fragment>
    );
};