export enum ReviewScaleResponseType {
    HIGH = 'HIGH',
    MEDIUM = 'MEDIUM',
    LOW = 'LOW',
    NA = 'NA',
    YES = 'YES',
    NO = 'NO'
}

export const findReviewScaleResponseTypeShortForm = (responseType: ReviewScaleResponseType) => {
    switch (responseType) {
        case ReviewScaleResponseType.HIGH:
            return 'H';
        case ReviewScaleResponseType.LOW:
            return 'L';
        case ReviewScaleResponseType.MEDIUM:
            return 'M';
        case ReviewScaleResponseType.NA:
            return 'NA';
        case ReviewScaleResponseType.NO:
            return 'NO';
        case ReviewScaleResponseType.YES:
            return 'YES';
    }
};