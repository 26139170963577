import {CookieConsent} from '@ideascale/commons';

type seoLinkDetailsData = {
    pseudonymUrl: string;
    pseudonymText: string;
    links: seoLinksData[];
}
type seoLinksData = {
    url: string;
    text: string;
}

type customPagesData = {
    id : number;
    publicPage : boolean;
    name: string;
    friendlyUrl: string;
    url: string;
}

export class FooterData {
    static readonly EMPTY = new FooterData('', '', '', '', CookieConsent.EMPTY, {
        pseudonymUrl: '',
        pseudonymText: '',
        links: [],
    }, [])

    constructor(
        public customCopyright: string,
        public contactPageUrl: string,
        public tosPageUrl: string,
        public customFooterContent: string,
        public cookieConsent: CookieConsent,
        public seoLinkDetails: seoLinkDetailsData,
        public customPages: customPagesData[]
    ) {
    }
}