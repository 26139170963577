import {useMutation, useQueryClient} from 'react-query';
import {AlertEvent, AlertType, buildAlertEventData, eventDispatcher, useApiErrorResponseHandler} from '@ideascale/commons';
import {useLocalizer} from './useLocalizer';
import {useIdeaService} from './useIdeaService';
import {useIdeaUpdater} from './useIdeaUpdater';
import {useIdeasContext} from 'contexts/IdeasContext';
import {IDEA_EVENTS} from 'constants/AppConstants';
import {IdeaSummary} from 'models/IdeaSummary';
import {IdeaDetail} from 'models/IdeaDetail';

export const useIdeaDelete = (ideaListQueryKey: any = '') => {
    const queryClient = useQueryClient();
    const localizer = useLocalizer();
    const {deleteIdea,} = useIdeaService();
    const {ideaDetailsQueryKey, removeIdeaFromIdeaList} = useIdeaUpdater();
    const {ideaListFilterQueryKey, ideaListData, updateIdeaListData} = useIdeasContext();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});

    function handleErrors(error: any) {
        handleErrorResponse(error, {
            fallbackMessage: {
                type: AlertType.error,
                message: localizer.msg('idea.state.unsuccessful')
            }
        });
    }

    const ideaListDeleteIdeaMutation = useMutation((ideaId: number) => deleteIdea(ideaId), {
        onSuccess: async (data, ideaId) => {
            eventDispatcher.dispatch(IDEA_EVENTS.IDEA_DELETED, ideaId);
            await queryClient.cancelQueries(ideaListQueryKey);
            const deletedIdea = removeIdeaFromIdeaList(ideaListQueryKey, ideaId);
            if (deletedIdea) {
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, localizer.msg('idea.actions.idea-deleted',
                    {
                        ideaTitle: (deletedIdea as IdeaSummary).title
                    })));
            }
        },
        onError: (error: any) => {
            handleErrors(error);
        },
        onSettled: async () => {
            await queryClient.invalidateQueries(ideaListQueryKey);
        }
    });

    const ideaDetailsDeleteIdeaMutation = useMutation((ideaId: number) => deleteIdea(ideaId), {
        onSuccess: async (data, ideaId) => {
            eventDispatcher.dispatch(IDEA_EVENTS.IDEA_DELETED, ideaId);
            await queryClient.cancelQueries(ideaDetailsQueryKey(ideaId));
            let deletedIdea: any = queryClient.getQueryData(ideaDetailsQueryKey(ideaId)) as IdeaDetail;
            if (ideaListFilterQueryKey) {
                removeIdeaFromIdeaList(ideaListFilterQueryKey, ideaId);
            }
            queryClient.removeQueries(ideaDetailsQueryKey(ideaId));
            if (deletedIdea) {
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, localizer.msg('idea.actions.idea-deleted',
                    {
                        ideaTitle: (deletedIdea as IdeaSummary).title
                    })));
            }
        },
        onError: (error: any) => {
            handleErrors(error);
        },
        onSettled: async (_, __, ideaId: number) => {
            //TODO:Ahsan- fix and remove hard setting of data.
            ideaListData.refetch().then((data: any) => {
                updateIdeaListData({
                    pagedIdeas: data.data,
                    totalIdeas: data.data?.pages[0]?.totalIdeas || 0,
                    isLoading: data.isLoading,
                    refetch: data.refetch,
                    hasNextPage: data.hasNextPage || false,
                    hasPreviousPage: data.hasPreviousPage || false,
                    isFetchingPreviousPage: data.isFetchingPreviousPage,
                    isFetchingNextPage: data.isFetchingNextPage,
                    fetchNextPage: data.fetchNextPage,
                    fetchPreviousPage: data.fetchPreviousPage
                });
            });
            await queryClient.invalidateQueries(ideaDetailsQueryKey(ideaId));
        }
    });

    return {ideaListDeleteIdeaMutation, ideaDetailsDeleteIdeaMutation};
};