import React, {Fragment, useCallback, useMemo, useState} from 'react';
import {
    PageTheme,
    UploadedResponse,
    UploadProgressCallback,
    useApiErrorResponseHandler,
    useToggle
} from '@ideascale/commons';
import {useLandingPageService} from 'hooks/useService';
import {useLocalizer} from 'hooks/useLocalizer';
import {useTranslation} from 'hooks/useTranslation';
import {InstructionListComponent} from 'components/landing-page/instruction-list/InstructionListComponent';
import {ConfigWrapper} from 'components/landing-page/ConfigWrapper';
import {CommonConfigModal} from 'components/landing-page/CommonConfigModal';
import {TranslationModal} from 'shared/TranslationModal';
import {InstructionListComponentData} from 'models/landing-page/InstructionListComponentData';
import {InstructionListItem} from 'models/landing-page/InstructionListItem';
import {GeneralText} from 'models/landing-page/GeneralText';
import {Component} from 'models/landing-page/Component';
import {TranslationContentType} from 'models/enums/landing-page/TranslationContentType';
import {TranslationContentResponse} from 'models/landing-page/TranslationContentResponse';
import {TranslationConfig} from 'models/TranslationConfig';
import {TranslationFieldType} from 'models/enums/TranslationFieldType';
import 'components/landing-page/instruction-list/InstructionListComponent.scss';

type InstructionListContainerProps = {
    showConfig: boolean;
    data: InstructionListComponentData;
    theme: PageTheme;
    showTranslationConfig: boolean,
    translationLanguageId?: number,
    onComponentEdited: () => void,
}

export const InstructionListContainer = (props: InstructionListContainerProps) => {
    const {
        showConfig,
        data,
        theme,
        showTranslationConfig,
        translationLanguageId,
        onComponentEdited,
    } = props;
    const landingPageService = useLandingPageService();
    const localizer = useLocalizer();
    const {showErrorMessage} = useApiErrorResponseHandler({localizer});
    const [showEditConfigModal, toggleEditConfigModal] = useToggle(false);
    const [showTranslationModal, toggleTranslationModal] = useToggle(false);
    const [instructionListConfig, setInstructionListConfig] = useState(data);
    const {getTranslatedContent, saveTranslatedContent} = useTranslation();

    const saveComponentConfigData = useCallback(async (componentData: InstructionListComponentData) => {
        if (landingPageService) {
            return landingPageService.saveInstructionListConfig(componentData);
        }
        return InstructionListComponentData.EMPTY;
    }, [landingPageService]);

    const updateConfigData = useCallback(async (componentData: InstructionListComponentData) => {
        try {
            const response = await saveComponentConfigData(componentData);
            if (response) {
                setInstructionListConfig(response);
            }
        } catch (e: any) {
            if (e.data && 'validationErrors' in e.data) {
                throw e.data;
            } else {
                showErrorMessage(e);
            }
        }
    }, [saveComponentConfigData, showErrorMessage]);

    const updateItemConfigData = useCallback(async (item: InstructionListItem) => {
        const newData = {...instructionListConfig};
        const itemToChange = newData.items?.find(configItem => configItem.id === item.id);
        if (itemToChange) {
            itemToChange.title = item.title;
            itemToChange.description = item.description;
            itemToChange.url = item.url;
            itemToChange.altText = item.altText;
            await updateConfigData(newData);
        }
    }, [instructionListConfig, updateConfigData]);

    const addItemConfigData = useCallback(async (item: InstructionListItem) => {
        const newItem = {
            altText: item.altText,
            visible: item.visible,
            url: item.url,
            title: item.title,
            description: item.description
        } as InstructionListItem;

        const oldData = instructionListConfig.items || [];

        const newData: InstructionListComponentData = {
            ...instructionListConfig,
            items: [...oldData, newItem]
        };
        await updateConfigData(newData);
        onComponentEdited();
    }, [instructionListConfig, onComponentEdited, updateConfigData]);

    const deleteItemConfigData = useCallback(async (item: InstructionListItem) => {
        const oldData = instructionListConfig.items?.filter((config) => config.id !== item.id) || [];
        const newData: InstructionListComponentData = {
            ...instructionListConfig,
            items: oldData
        };
        await updateConfigData(newData);
        onComponentEdited();
    }, [instructionListConfig, onComponentEdited, updateConfigData]);

    const onToggleVisibility = useCallback(async () => {
        const newData = {...instructionListConfig};
        newData.visible = !newData.visible;
        await updateConfigData(newData);
        onComponentEdited();
    }, [instructionListConfig, onComponentEdited, updateConfigData]);

    const onToggleItemVisibility = useCallback(async (item: InstructionListItem) => {
        const newData: InstructionListComponentData = {...instructionListConfig};
        const data = newData.items?.find(listItem => listItem.id === item.id);
        if (data) {
            data.visible = !item.visible;
            await updateConfigData(newData);
            onComponentEdited();
        }
    }, [instructionListConfig, onComponentEdited, updateConfigData]);

    const onToggleTextVisibility = useCallback(async (data: GeneralText) => {
        const newData: InstructionListComponentData = {...instructionListConfig};
        const newText = newData.texts?.find(text => text.id === data.id);

        if (newText) {
            newText.visible = !data.visible;
            await updateConfigData(newData);
            onComponentEdited();
        }
    }, [instructionListConfig, onComponentEdited, updateConfigData]);

    const onUpdateText = useCallback(async (data: GeneralText) => {
        const newData: InstructionListComponentData = {...instructionListConfig};
        const newText = newData.texts?.find(text => text.id === data.id);

        if (newText) {
            newText.text = data.text;
            newText.textColor = data.textColor;
            await updateConfigData(newData);
        }
    }, [instructionListConfig, updateConfigData]);

    const onEditClicked = useCallback(() => {
        toggleEditConfigModal();
    }, [toggleEditConfigModal]);

    const onUpdateConfig = useCallback(async (component: Component) => {
        const newData: InstructionListComponentData = {...instructionListConfig};
        newData.title = component.title;
        newData.subtitle = component.subtitle;
        await updateConfigData(newData);
    }, [instructionListConfig, updateConfigData]);

    const onTranslationClicked = useCallback(() => {
        if (translationLanguageId !== undefined) {
            toggleTranslationModal();
        }
    }, [toggleTranslationModal, translationLanguageId]);

    const uploadImage = useCallback(async (data: FormData, onUploadProgress: UploadProgressCallback) => {
        if (landingPageService) {
            return await landingPageService.uploadImage(data, onUploadProgress);
        }
        return UploadedResponse.EMPTY;
    }, [landingPageService]);

    const onTextTranslated = useCallback((response: TranslationContentResponse) => {
        setInstructionListConfig(prev => {
            const newData = {...prev};
            if (response.contentType === TranslationContentType.PAGE_COMPONENT_TITLE) {
                newData.title = response.translation;
            } else if (response.contentType === TranslationContentType.PAGE_COMPONENT_SUBTITLE) {
                newData.subtitle = response.translation;
            } else if (
                [
                    TranslationContentType.COMPONENT_INSTRUCTION_DESCRIPTION,
                    TranslationContentType.COMPONENT_INSTRUCTION_TITLE
                ].includes(response.contentType)
            ) {
                const itemToUpdate = newData.items?.find(item => item.id === response.sourceObjectId);
                if (itemToUpdate) {
                    if (response.contentType === TranslationContentType.COMPONENT_INSTRUCTION_TITLE) {
                        itemToUpdate.title = response.translation;
                    } else {
                        itemToUpdate.description = response.translation;
                    }
                }
            } else if (response.contentType === TranslationContentType.COMPONENT_TEXT_TEXT) {
                const textComponentToUpdate = newData.texts.find(text => text.id === response.sourceObjectId);
                if (textComponentToUpdate) {
                    textComponentToUpdate.text = response.translation;
                }
            }
            return newData;
        });
    }, []);

    const translationConfigs = useMemo(() => {
        return theme === PageTheme.CURIE ?
            [
                new TranslationConfig(
                    TranslationContentType.PAGE_COMPONENT_TITLE,
                    instructionListConfig.id,
                    TranslationFieldType.TEXT,
                ),
                new TranslationConfig(
                    TranslationContentType.PAGE_COMPONENT_SUBTITLE,
                    instructionListConfig.id,
                    TranslationFieldType.TEXT,
                ),
            ] : [
                new TranslationConfig(
                    TranslationContentType.PAGE_COMPONENT_TITLE,
                    instructionListConfig.id,
                    TranslationFieldType.TEXT,
                ),
            ];
    }, [instructionListConfig.id, theme]);

    return (
        <Fragment>
            <ConfigWrapper showTranslationConfig={showTranslationConfig} onTranslationClicked={onTranslationClicked}
                           visible={instructionListConfig.visible} onEditClicked={onEditClicked}
                           toggleVisibility={onToggleVisibility} localizer={localizer}
                           className={`instruction-list ${instructionListConfig.attribute?.className ? instructionListConfig.attribute?.className : ''}`}
                           showConfig={showConfig}
                           accessibleName={localizer.msg('landing-page.instruction-list')}>
                <InstructionListComponent onTextTranslated={onTextTranslated}
                                          onComponentEdited={onComponentEdited}
                                          getTranslatedInstructionText={getTranslatedContent}
                                          saveTranslatedInstructionText={saveTranslatedContent}
                                          showTranslationConfig={showTranslationConfig}
                                          uploadImage={uploadImage}
                                          translationLanguageId={translationLanguageId}
                                          toggleItemVisibility={onToggleItemVisibility}
                                          localizer={localizer}
                                          onToggleTextVisibility={onToggleTextVisibility}
                                          theme={theme}
                                          onUpdateConfigText={onUpdateText}
                                          addConfigItem={addItemConfigData}
                                          deleteConfigItem={deleteItemConfigData}
                                          showConfig={showConfig}
                                          config={instructionListConfig}
                                          updateItemConfig={updateItemConfigData}
                />
            </ConfigWrapper>
            {
                showEditConfigModal &&
                <CommonConfigModal renderSubtitle={theme === PageTheme.CURIE} open={showEditConfigModal}
                                   onComponentEdited={onComponentEdited}
                                   toggle={toggleEditConfigModal} configData={instructionListConfig}
                                   updateData={onUpdateConfig}
                                   localizer={localizer} theme={theme}/>
            }

            {
                showTranslationModal && translationLanguageId !== undefined &&
                <TranslationModal translationLanguageId={translationLanguageId} localizer={localizer}
                                  open={showTranslationModal}
                                  toggle={toggleTranslationModal}
                                  onTextTranslated={onTextTranslated}
                                  onComponentEdited={onComponentEdited}
                                  translationConfigs={translationConfigs}
                                  getTranslatedContent={getTranslatedContent}
                                  saveTranslatedContent={saveTranslatedContent}/>
            }
        </Fragment>
    );
};