import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ParagraphSkeleton, Scrollbar, ScrollbarInstance} from '@ideascale/ui';
import {LayoutUtil, Localizer, useApiErrorResponseHandler, useDropdownFocus} from '@ideascale/commons';
import {ActionItem} from './ActionItem';
import {GroupedActionItem} from 'models/topbar-action-item/GroupedActionItem';

type ActionItemsProps = {
    localizer: Localizer;
    fetchTopbarActionItems: () => Promise<GroupedActionItem[]>;
    toggleProfileQuestionsModal: () => void;
    toggleVerifiableFieldsOpen: () => void;
}

const FOCUS_ID = 'topbar-focusable-action-item';

export const ActionItems = (props: ActionItemsProps) => {
    const {localizer, fetchTopbarActionItems, toggleProfileQuestionsModal, toggleVerifiableFieldsOpen} = props;
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [actionItemList, setActionItemList] = useState<GroupedActionItem[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const {focus} = useDropdownFocus();
    const scrollbarContainer = useRef<ScrollbarInstance>(null);

    const fetchActionItems = useCallback(async () => {
        try {
            setIsFetching(true);
            const response = await fetchTopbarActionItems();
            setActionItemList(response);
            focus('#' + FOCUS_ID);
        } catch (e: any) {
            handleErrorResponse(e);
        } finally {
            setIsFetching(false);
        }
    }, [fetchTopbarActionItems, focus, handleErrorResponse]);

    useEffect(() => {
        void fetchActionItems();
    }, [fetchActionItems]);

    return (
        <Scrollbar
            autoHeight
            autoHeightMax={LayoutUtil.isMobileLayout() ? '100vh' : '70vh'}
            ref={scrollbarContainer}>
            {
                isFetching
                    ? <ParagraphSkeleton rows={4}/>
                    :
                    actionItemList.length > 0
                        ? actionItemList.map((groupActions, index) => (
                            <div id={index === 0 ? FOCUS_ID : undefined} key={groupActions.group}>
                                <ActionItem localizer={localizer}
                                            groupedActionItem={groupActions}
                                            toggleProfileQuestionsModal={toggleProfileQuestionsModal}
                                            toggleVerifiableFieldsOpen={toggleVerifiableFieldsOpen}/>
                            </div>
                        ))
                        : <div className="mx-3 my-2 text-muted">
                            {localizer.msg('topbar.action-items.no-items')}
                        </div>
            }
        </Scrollbar>
    );
};