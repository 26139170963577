import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {
    ConfirmationModal,
    Localizer,
    PageTheme,
    UploadedResponse,
    UploadProgressCallback,
    useToggle
} from '@ideascale/commons';
import {Icon} from '@ideascale/ui';
import iconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useDefaultImage} from 'hooks/useDefaultImage';
import {EastmanInstructionItemContainer} from 'containers/landing-page/EastmanInstructionItemContainer';
import {DisplayHeader} from 'components/landing-page/DisplayHeader';
import {InstructionListItem} from 'components/landing-page/instruction-list/InstructionListItem';
import {GeneralTextComponent} from 'components/landing-page/GeneralTextComponent';
import {
    EditInstructionListItemConfigModal
} from 'components/landing-page/instruction-list/EditInstructionListItemConfigModal';
import {TranslationModal} from 'shared/TranslationModal';
import {TextType} from 'models/enums/landing-page/TextType';
import {GeneralText} from 'models/landing-page/GeneralText';
import {TranslationConfig} from 'models/TranslationConfig';
import {RichTextToolbarType} from 'models/enums/landing-page/RichTextToolbarType';
import {TranslationFieldType} from 'models/enums/TranslationFieldType';
import {TranslationContentType} from 'models/enums/landing-page/TranslationContentType';
import {TranslationContentRequest} from 'models/landing-page/TranslationContentRequest';
import {TranslationContentResponse} from 'models/landing-page/TranslationContentResponse';
import {InstructionListComponentData} from 'models/landing-page/InstructionListComponentData';
import {InstructionListItem as InstructionListItemModel} from 'models/landing-page/InstructionListItem';

const CROPPER_DIMENSIONS: Record<PageTheme, any> = {
    BERNERS_LEE: {
        width: 540,
        height: 194
    },
    CURIE: {
        width: 330,
        height: 170
    },
    ARMSTRONG: {
        width: 360,
        height: 200
    },
    EASTMAN: {
        width: 465,
        height: 402,
    },
};

type InstructionListWrapperProps = {
    theme: PageTheme;
    showConfig: boolean;
    config: InstructionListComponentData;
    localizer: Localizer;
    deleteConfigItem: (item: InstructionListItemModel) => Promise<void>;
    addConfigItem: (item: InstructionListItemModel) => Promise<void>;
    toggleVisibility: (item: InstructionListItemModel) => Promise<void>;
    onToggleTextVisibility: (data: GeneralText) => Promise<void>;
    onUpdateConfigText: (data: GeneralText) => Promise<void>;
    updateItemConfig: (item: InstructionListItemModel) => Promise<void>;
    showTranslationConfig: boolean,
    onTextTranslated: (response: TranslationContentResponse) => void;
    getTranslatedInstructionText: (parameters: TranslationContentRequest) => Promise<TranslationContentResponse[]>
    saveTranslatedInstructionText: (parameters: TranslationContentRequest) => Promise<TranslationContentResponse[]>;
    uploadImage: (data: FormData, onUploadProgress: UploadProgressCallback) => Promise<UploadedResponse>;
    onComponentEdited: () => void,
    headerClass?: string;
    bodyClass?: string;
    translationLanguageId?: number,
}

export const InstructionListWrapper = (props: InstructionListWrapperProps) => {
    const {
        showConfig,
        deleteConfigItem,
        onToggleTextVisibility,
        toggleVisibility,
        theme,
        headerClass,
        bodyClass,
        config,
        onUpdateConfigText,
        localizer,
        addConfigItem,
        updateItemConfig,
        showTranslationConfig,
        translationLanguageId,
        getTranslatedInstructionText,
        saveTranslatedInstructionText,
        onTextTranslated,
        onComponentEdited,
        uploadImage
    } = props;
    const {getDefaultImages, containsDefaultImage, getDefaultImageIndex} = useDefaultImage(theme);
    const [selectedItem, setSelectedItem] = useState<InstructionListItemModel>();
    const [addButtonDisabled, toggleAddButtonDisabled] = useToggle(false);
    const [showConfirmDeleteModal, toggleConfirmDeleteModal] = useToggle(false);
    const [showItemTranslationModal, toggleItemTranslationModal] = useToggle(false);
    const [showEditModal, toggleEditModal] = useToggle(false);

    const addNewConfigItem = useCallback(async () => {
        toggleAddButtonDisabled(true);
        const newItem = {
            ...InstructionListItemModel.EMPTY,
            id: -1,
            title: 'Default title',
            description: [PageTheme.BERNERS_LEE, PageTheme.EASTMAN].includes(theme)
                ? `<p>This is a sample default description for this item</p>`
                : `<p class="ql-align-center">This is a sample default description for this item</p>`,
            url: 'DEFAULT',
            visible: true,
            altText: 'instruction item'
        };
        addConfigItem(newItem)
            .then(() => {
                onComponentEdited();
            })
            .finally(() => toggleAddButtonDisabled(false));
    }, [addConfigItem, onComponentEdited, theme, toggleAddButtonDisabled]);

    const bottomText = useMemo(() => {
        return config.texts?.[0];
    }, [config.texts]);

    const onSelectItem = useCallback((item: InstructionListItemModel) => {
        setSelectedItem(item);
    }, []);

    const onToggleVisibility = useCallback(async (item: InstructionListItemModel) => {
        toggleVisibility(item).then(() => {
            onComponentEdited();
        });
        onSelectItem(item);
    }, [onComponentEdited, onSelectItem, toggleVisibility]);

    const onEditClicked = useCallback((item: InstructionListItemModel) => {
        toggleEditModal(true);
        onSelectItem(item);
    }, [onSelectItem, toggleEditModal]);

    const onTranslationClicked = useCallback((item: InstructionListItemModel) => {
        toggleItemTranslationModal(true);
        onSelectItem(item);
    }, [onSelectItem, toggleItemTranslationModal]);

    const onDeleteConfirmed = useCallback(async () => {
        if (selectedItem) {
            deleteConfigItem(selectedItem).then(() => {
                onComponentEdited();
            });
        }
    }, [deleteConfigItem, onComponentEdited, selectedItem]);

    const onDeleteClicked = useCallback((item: InstructionListItemModel) => {
        toggleConfirmDeleteModal(true);
        onSelectItem(item);
    }, [onSelectItem, toggleConfirmDeleteModal]);

    const translationConfigs = useMemo(() => {
        return selectedItem ? [
            new TranslationConfig(
                TranslationContentType.COMPONENT_INSTRUCTION_TITLE,
                selectedItem.id,
                TranslationFieldType.TEXT,
            ),
            new TranslationConfig(
                TranslationContentType.COMPONENT_INSTRUCTION_DESCRIPTION,
                selectedItem.id,
                TranslationFieldType.RICH_TEXT,
                RichTextToolbarType.MINIMAL
            ),
        ] : [];
    }, [selectedItem]);

    const renderInstructionList = () => {
        switch (theme) {
            case PageTheme.EASTMAN:
                return <Fragment>
                    {
                        selectedItem &&
                        <EastmanInstructionItemContainer theme={theme}
                                                         config={config}
                                                         bodyClass={bodyClass}
                                                         localizer={localizer}
                                                         showConfig={showConfig}
                                                         onSelectItem={onSelectItem}
                                                         selectedItem={selectedItem}
                                                         onEditClicked={onEditClicked}
                                                         onDeleteClicked={onDeleteClicked}
                                                         toggleVisibility={onToggleVisibility}
                                                         onTranslationClicked={onTranslationClicked}
                                                         showTranslationConfig={showTranslationConfig}/>
                    }
                </Fragment>;
            case PageTheme.CURIE:
            case PageTheme.BERNERS_LEE:
            case PageTheme.ARMSTRONG:
            default:
                return (
                    <ul className={`${bodyClass ? bodyClass : ''} instruction-list-items list-unstyled`}
                        aria-labelledby="instruction-list-heading">
                        {
                            config.items
                                ?.filter(item => (item.visible || showConfig))
                                .map((item, index) => (
                                    <InstructionListItem index={index}
                                                         theme={theme}
                                                         key={item.id}
                                                         localizer={localizer}
                                                         itemConfig={item}
                                                         showConfig={showConfig}
                                                         onEditClicked={onEditClicked}
                                                         onDeleteClicked={onDeleteClicked}
                                                         onTranslationClicked={onTranslationClicked}
                                                         showTranslationConfig={showTranslationConfig}
                                                         onToggleVisibilityClicked={onToggleVisibility}
                                                         defaultImage={containsDefaultImage(item.url) ? getDefaultImages(config)[getDefaultImageIndex(item.url)] : undefined}/>
                                ))
                        }
                    </ul>
                );

        }
    };

    useEffect(() => {
        setSelectedItem(prev => {
            if (prev) {
                const updatedSelectedItem = config.items
                    ?.filter(item => (item.visible || showConfig))
                    .find(item => item.id === prev.id);
                if (updatedSelectedItem) {
                    return updatedSelectedItem;
                }
            }
            return config.items?.filter(item => (item.visible || showConfig))?.[0];
        });
    }, [config.items, showConfig]);

    return (
        <Fragment>
            <DisplayHeader theme={theme} subtitle={config.subtitle} title={config.title} className={headerClass}
                           headingId="instruction-list-heading"/>
            {
                config.items?.filter(item => (item.visible || showConfig))?.length === 0
                    ?
                    <div className="panel card">
                        <div className="card-body panel-body">
                            <div className="alert alert-warning mb-0 text-center">
                                {localizer.msg('landing-page.components.common.no-instruction-items')}
                            </div>
                        </div>
                    </div>
                    :
                    renderInstructionList()
            }
            {
                showConfig &&
                <button
                    className={`my-3 edit-mode-element mx-auto d-flex align-items-center btn ${theme === PageTheme.EASTMAN ? 'btn-eastman' : 'btn-secondary'}`}
                    onClick={addNewConfigItem} disabled={addButtonDisabled}>
                    <Icon className="me-1 active" name="plus" iconSpritePath={iconPath} width={15} height={15}/>
                    {localizer.msg('landing-page.components.edit.add-new-item')}
                </button>
            }
            {
                bottomText && theme === PageTheme.ARMSTRONG &&
                <div className="instruction-list-footer d-flex align-items-center justify-content-center">
                    <GeneralTextComponent showTranslationConfig={showTranslationConfig}
                                          translationLanguageId={translationLanguageId}
                                          onComponentEdited={onComponentEdited}
                                          toolbarType={bottomText.type === TextType.HEADING ? RichTextToolbarType.TITLE_TEXT : RichTextToolbarType.MINIMAL}
                                          onTextTranslated={onTextTranslated} key={bottomText.type}
                                          showConfig={showConfig} data={bottomText}
                                          onToggleVisibility={onToggleTextVisibility}
                                          onUpdateText={onUpdateConfigText}/>
                </div>
            }
            {
                showConfirmDeleteModal && selectedItem &&
                <ConfirmationModal localizer={localizer}
                                   open={showConfirmDeleteModal}
                                   onConfirm={onDeleteConfirmed}
                                   toggle={toggleConfirmDeleteModal}
                                   message={localizer.msg('common.are-you-sure')}/>
            }
            {
                showEditModal && selectedItem &&
                <EditInstructionListItemConfigModal open={showEditModal}
                                                    onComponentEdited={onComponentEdited}
                                                    toggle={toggleEditModal}
                                                    uploadImage={uploadImage}
                                                    localizer={localizer}
                                                    imageCropHeight={CROPPER_DIMENSIONS[theme || PageTheme.ARMSTRONG].height}
                                                    imageCropWidth={CROPPER_DIMENSIONS[theme || PageTheme.ARMSTRONG].width}
                                                    config={selectedItem}
                                                    previewMode="medium"
                                                    defaultImage={containsDefaultImage(selectedItem.url) ? getDefaultImages(config)[getDefaultImageIndex(selectedItem.url)] : undefined}
                                                    updateItemConfig={updateItemConfig}/>
            }
            {
                showItemTranslationModal && translationLanguageId !== undefined &&
                <TranslationModal translationLanguageId={translationLanguageId} localizer={localizer}
                                  open={showItemTranslationModal}
                                  toggle={toggleItemTranslationModal}
                                  onTextTranslated={onTextTranslated}
                                  onComponentEdited={onComponentEdited}
                                  translationConfigs={translationConfigs}
                                  getTranslatedContent={getTranslatedInstructionText}
                                  saveTranslatedContent={saveTranslatedInstructionText}/>
            }
        </Fragment>
    );
};