import React, {useMemo} from 'react';
import {Localizer} from '@ideascale/commons';
import {KudoType} from 'models/enums/KudoType';
import regular from 'assets/img/kudos/regular.png';

type KudoImageProps = {
    localizer: Localizer;
    kudoType: KudoType;
    width?: number;
    height?: number;
    className?: string
}

export const KudoImage = (props: KudoImageProps) => {
    const {kudoType, className = '', localizer, ...rest} = props;
    const title = localizer.msg('kudos.type.name.' + kudoType?.toLocaleLowerCase().replace(/_/g, '-'));

    const imageSource = useMemo(() => {
        switch (kudoType) {
            case KudoType.REGULAR:
            default:
                return regular;
        }
    }, [kudoType]);

    return <img src={imageSource} className={className} {...rest} alt={title} title={title}/>;
};