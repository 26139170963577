import React from 'react';
import {Icon} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {Localizer, Member} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import './DraftIdeaAction.scss';

type DraftIdeaActionType = {
    localizer: Localizer;
    onDeleteClick: () => void;
    onEditClick: () => void;
    ideaSubmitter: Member
}
export const DraftIdeaAction = (props: DraftIdeaActionType) => {
    const {localizer, onDeleteClick, onEditClick, ideaSubmitter} = props;
    const {authentication} = useAppContext();
    return (
        <div className="draft-idea-action">
            <button className="btn btn-secondary order-1"
                    onClick={onEditClick}>{localizer.msg('idea.draft.edit')}</button>
            {ideaSubmitter.id === authentication.actor.id &&
            <button className="btn btn-light order-0" onClick={onDeleteClick}><Icon
                className="position-relative pos-top-n1"
                name="trash-can-open"
                fill="#666"
                iconSpritePath={svgIconsPath}/> {localizer.msg('idea.draft.delete')}
            </button>
            }
        </div>
    );
};