import React, {useCallback, useEffect, useState} from 'react';
import {SearchInputGroup} from '../../shared/SearchInputGroup';
import {GroupOptionType, IdeascaleSelect, OptionType} from '@ideascale/ui';
import {useLocalizer} from '../../hooks/useLocalizer';
import {CustomFieldData} from '../../models/CustomFieldData';

type DependantCustomSearchFieldProps = {
    fetchDependantCustomFields(fieldId: number): Promise<CustomFieldData>;
    fieldId: number;
    value: OptionType[];
    mode: 'IDEA' | 'MEMBER';
    onChangeDependantField: (fieldId: number, options: OptionType[] | null, mode: 'IDEA' | 'MEMBER') => void;
}
export const DependantCustomSearchField = (props: DependantCustomSearchFieldProps) => {
    const {fetchDependantCustomFields, fieldId, value, mode, onChangeDependantField} = props;
    const localizer = useLocalizer();
    const [field, setField] = useState<CustomFieldData>(CustomFieldData.EMPTY);

    const loadDependantCustomSearchFieldData = useCallback(async (fieldId: number) => {
        const dependantCustomFieldData = await fetchDependantCustomFields(fieldId);
        setField(dependantCustomFieldData);
    }, [fetchDependantCustomFields]);

    const optionItems: GroupOptionType[] = field.optionGroups.map(group => ({
        label: group.name,
        options: group.options.map(option => ({
            label: option.name,
            value: option.id
        }))
    }));

    useEffect(() => {
        loadDependantCustomSearchFieldData(fieldId).then();
    }, [fieldId, loadDependantCustomSearchFieldData]);

    return (
        <div>
            <label className="fw-bold" htmlFor={String(fieldId)}>{localizer.msg(field.label)}</label>
            <SearchInputGroup groupText={''}>
                <IdeascaleSelect
                    isMulti={true}
                    placeholder={localizer.msg('common.select-options')}
                    inputId={String(fieldId)}
                    value={value}
                    onChange={(values: any) => onChangeDependantField(fieldId, values, mode)}
                    options={optionItems}
                />
            </SearchInputGroup>
        </div>
    );
};