import {ClassificationSummary} from '@ideascale/commons';

export class CampaignHeaderData {
    static readonly EMPTY = new CampaignHeaderData(
        0,
        '',
        '',
        '',
        '',
        '',
        false,
        false,
        {
            id: 0,
            name: '',
            locationExtensions: {
                countriesExtensions: [],
                fvey: {
                    id: 0,
                    name: ''
                },
                nf: {
                    id: 0,
                    name: ''
                }
            },
            sensitiveExtensions: []
        },
        false,
        false
    );

    constructor(
        public id: number,
        public name: string,
        public bannerImageUrl: string,
        public bannerImageAltText: string,
        public logoUrl: string,
        public logoAltText: string,
        public pageTitleInBlackEnabled: boolean,
        public bannerGradientEnabled: boolean,
        public classificationSummary: ClassificationSummary,
        public logoFrameEnabled: boolean,
        public logoOnBannerEnabled: boolean
    ) {
    }
}
