import React, {Dispatch, Fragment, SetStateAction, useEffect, useMemo, useRef, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {ActionButton, HtmlConverter, NewRichTextEditor, RichTextEditorHandler} from '@ideascale/ui';
import {
    AlertEvent,
    AlertType,
    buildAlertEventData,
    ClassificationAttribute,
    ClassificationsHolder,
    ClassificationTreeMenu,
    ClassificationUtil,
    eventDispatcher,
    FieldAttributeParameters,
    useApiErrorResponseHandler,
    useHandleFormSubmit
} from '@ideascale/commons';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useFileUploadService} from 'hooks/useFileUploadService';
import {MessageReplyData} from 'models/MessageReplyData';
import {ConversationThreadData} from 'models/ConversationThreadData';
import {InboxListRouteMatchParams} from 'models/types/InboxListRouteMatchParams';

type FormInputs = {
    body: string;
}

type replyFormProps = {
    details: ConversationThreadData;
    setDetails: Dispatch<SetStateAction<ConversationThreadData>>;
    setShowReply: Dispatch<SetStateAction<boolean>>;
    conversationThreadReply: (recipientIds: number[], body: string, subject: string, subjectClassificationAttributes: FieldAttributeParameters[], conversationThreadId: number) => Promise<MessageReplyData>;
    replyResponse: Dispatch<SetStateAction<MessageReplyData>>;
    fetchConversationThread: (conversationThreadId: number) => Promise<ConversationThreadData>;
    fetchClassifications: () => Promise<ClassificationsHolder>
}

const DEFAULT_CLASSIFICATION: ClassificationAttribute = {
    classification: 'U',
    locationExtensions: [],
    sensitiveExtensions: []
};

export const ReplyForm = (props: replyFormProps) => {
    const {
        details,
        conversationThreadReply,
        setShowReply,
        replyResponse,
        fetchConversationThread,
        setDetails,
        fetchClassifications,
    } = props;
    const {communityConfig: {maxFileSizeLimit, offensiveEmojis, classificationEnabled}} = useAppContext();
    const {tempImageUpload} = useFileUploadService();
    const localizer = useLocalizer();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const params = useParams<InboxListRouteMatchParams>();
    const richTextEditorNode = useRef<RichTextEditorHandler>(null);
    const {handleSubmit, reset, control, formState: {errors, isSubmitting}, setError} = useForm<FormInputs>();
    const onFormSubmit = useHandleFormSubmit(handleSubmit);
    const [classificationsMenuData, setClassificationsMenuData] = useState<ClassificationsHolder>();

    const replyToAll = details.messages.flatMap(item => item.recipients.map(member => member.id));

    const richTextClassificationConfig = useMemo(() => {
        return ClassificationUtil.prepareRichTextClassificationConfig(classificationEnabled, classificationsMenuData);
    }, [classificationEnabled, classificationsMenuData]);

    const onReplyClicked = async (values: FormInputs) => {
        let fieldAttributeParameters: ClassificationAttribute | undefined;
        if (classificationEnabled) {
            fieldAttributeParameters = DEFAULT_CLASSIFICATION;
            if (details.subjectClassification) {
                fieldAttributeParameters = ClassificationUtil.getClassificationAttribute(details.subjectClassification);
            }
        }
        if (params?.messageId) {
            try {
                const response = await conversationThreadReply(
                    replyToAll,
                    HtmlConverter.toServerHtmlFormat(values.body),
                    details.subject,
                    classificationEnabled && fieldAttributeParameters ?
                        [ClassificationUtil.buildFieldClassificationRequest('subject', fieldAttributeParameters)] : [],
                    +params?.messageId
                );
                replyResponse(response);
                setShowReply(false);
                setTimeout(() => {
                    fetchConversationThread((+params?.messageId!)).then((data) => setDetails(data));
                }, 1000);
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, localizer.msg('common.message-sent-successfully')));
                reset({body: ''});
            } catch (e: any) {
                handleErrorResponse(e, {
                    setFormError: () => {
                        setError('body', {
                            type: 'server',
                            message: e.data.validationErrors?.body
                        });

                    }
                });
            }
        }
    };

    useEffect(() => {
        if (classificationEnabled) {
            fetchClassifications().then(data => {
                setClassificationsMenuData(data);
            });
        }
    }, [classificationEnabled, fetchClassifications]);

    useEffect(() => {
        richTextEditorNode.current?.focus();
    }, []);

    return (
        <form className="message-form" onSubmit={onFormSubmit(onReplyClicked, isSubmitting)}>
            <div className={`form-group reply-form mb-0 ${errors.body ? 'has-error' : ''}`}>
                <Controller
                    control={control}
                    name={'body'}
                    defaultValue={''}
                    rules={{
                        required: localizer.msg('common.errors.required'),
                        ...(classificationEnabled && {validate: () => !richTextEditorNode.current?.hasInvalidClassifications() || localizer.msg('frontend-shared.classification.invalid-classifications-msg')})
                    }}
                    render={({field}) =>
                        <Fragment>
                            {
                                classificationEnabled
                                    ? (
                                        <ClassificationTreeMenu
                                            className="mb-2"
                                            localizer={localizer}
                                            id="mail-body-classification"
                                            classifications={classificationsMenuData || {}}
                                            onApplyClassifications={value => {
                                                richTextEditorNode.current?.insertClassification(ClassificationUtil.getLabel(value));
                                            }}/>
                                    )
                                    : null
                            }
                            <NewRichTextEditor
                                id={'reply-body'}
                                toolbar={'standard'}
                                className={'form-control h-auto'}
                                placeholder={localizer.msg('common.write-reply')}
                                svgIconPath={svgIconsPath}
                                onChange={field.onChange}
                                ref={richTextEditorNode}
                                enableEmojiPicker={true}
                                offensiveEmojis={offensiveEmojis}
                                defaultValue={''}
                                uploadImage={tempImageUpload}
                                maxFileSize={maxFileSizeLimit}
                                classificationConfig={richTextClassificationConfig}
                            />
                        </Fragment>
                    }/>

                {
                    errors.body &&
                    <div className="invalid-feedback d-block">
                        {errors.body.message}
                    </div>
                }

            </div>
            <div className="actions-button float-end mt-4 pb-4">
                <button className="btn btn-cancel me-4"
                        onClick={() => setShowReply(false)}>
                    {localizer.msg('common.cancel')}
                </button>
                <ActionButton loading={isSubmitting}>
                    {localizer.msg('common.send')}
                </ActionButton>
            </div>
        </form>
    );
};
