import {Command} from './Command';
import {CommunityHomeEditor} from './Editors/CommunityHomeEditor';
import {CommunityOperationType} from 'models/edit-mode/CommunityOperationType';
import {Operation} from 'models/edit-mode/Operation';
import {ImageField} from 'models/edit-mode/ImageField';
import {ChangeCommunityTileOperation} from 'models/edit-mode/ChangeCommunityTileOperation';

export class UpdateCommunityTileCommand implements Command {
    private readonly communityHomeEditor: CommunityHomeEditor;
    private readonly newTileImage: ImageField;
    private readonly oldTileImage: ImageField;
    private readonly type: CommunityOperationType;

    constructor(communityHomeEditor: CommunityHomeEditor, tileImage: ImageField) {
        this.communityHomeEditor = communityHomeEditor;
        this.oldTileImage = this.communityHomeEditor.communityTileImage;
        this.newTileImage = tileImage;
        this.type = CommunityOperationType.CHANGE_COMMUNITY_TILE;
    }
    execute(): void {
        this.communityHomeEditor.communityTileImage = this.newTileImage;
    }

    getType(): CommunityOperationType {
        return this.type;
    }

    getOperation(): Operation {
        return new ChangeCommunityTileOperation(this.type, this.communityHomeEditor.communityTileImage.fileId);
    }

    undo(): void {
        this.communityHomeEditor.communityTileImage = this.oldTileImage;
    }
}