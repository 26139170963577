export enum StageFunctionName {
    IDEATE = 'ideate',
    BUILD_TEAM = 'build_team',
    REFINE = 'refine',
    ESTIMATE = 'estimate',
    REVIEW_SCALE = 'reviewscale',
    FUND = 'fund',
    ASSESSMENT = 'assessment',
    RESERVE = 'reserve',
    PENDING = 'pending',
    RECYCLE_BIN = 'recycle_bin',
    OFFTOPIC = 'offtopic',
    INBOUND = 'inbound',
    ACTIVE = 'active'
}
