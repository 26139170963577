import React from 'react';
import {Avatar, Icon} from '@ideascale/ui';
import {NotificationIcon} from 'models/NotificationIcon';
import {NotificationType} from 'models/enums/NotificationType';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';

type NotificationAvatarProps = {
    type: NotificationType;
    notificationIcon: NotificationIcon | undefined;
    voteCount: number | undefined
}

export const NotificationAvatar = (props: NotificationAvatarProps) => {
    const {type, notificationIcon, voteCount} = props;

    const renderIcon = () => {
        if (notificationIcon && notificationIcon.isSvg()) {
            return <div
                className={`notification-avatar ${notificationIcon.isDigest() ? 'digest-svg' : ''} ${notificationIcon.isTagSvg() ? 'tag-svg' : 'idea-avatar-bg'}`}>
                <Icon name={notificationIcon.getSvgName()} iconSpritePath={svgIconPath}/>
            </div>;
        } else if (notificationIcon && (type === NotificationType.BONUS_5X_KUDO_ISSUED || type === NotificationType.IDEA_INACTIVE || type === NotificationType.IDEA_TOP_TRENDING)) {
            return <div
                className={`notification-avatar ${type === NotificationType.IDEA_INACTIVE ? 'idea-inactive' : ''}`}>
                <img className="avatar" src={notificationIcon.getUrl()} alt={notificationIcon.alt}
                     title={notificationIcon.title}/>
            </div>;
        } else if (notificationIcon) {
            return <Avatar src={notificationIcon.getUrl()} size="sm" alt={notificationIcon.alt}/>;
        } else if (type === NotificationType.IDEA_VOTE_MILESTONE_ACHIEVED && voteCount) {
            return <div className="vote-notification notification-avatar">
                <span className="text-center text-truncate vote-count text-dark">{voteCount}</span>
            </div>;
        }
    };

    return (
        <div className="notification-icon">
            {renderIcon()}
        </div>
    );
};