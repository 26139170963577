import React from 'react';
import {Localizer} from '@ideascale/commons';
import {Button, Modal} from '@ideascale/ui';

type UnsavedContentConfirmationModalProps = {
    localizer: Localizer;
    open: boolean;
    toggle: () => void;
    onDiscardUnsavedChanges: () => void;
}

export const UnsavedContentConfirmationModal = (props: UnsavedContentConfirmationModalProps) => {
    const {toggle, open, localizer, onDiscardUnsavedChanges} = props;

    return (
        <Modal isOpen={open} toggle={toggle}
               title={localizer.msg('landing-page.action-topbar.edit-mode.unsaved-confirmation-modal.title')}>
            <div>
                {localizer.msg('landing-page.action-topbar.edit-mode.unsaved-confirmation-modal.description')}
            </div>
            <div className="mt-5 mb-3 text-end">
                <Button className="me-3" color="cancel" type="button" onClick={toggle}>
                    {localizer.msg('landing-page.action-topbar.edit-mode.unsaved-confirmation-modal.continue-editing')}
                </Button>
                <Button color="danger" type="button" onClick={onDiscardUnsavedChanges}>
                    {localizer.msg('landing-page.action-topbar.edit-mode.unsaved-confirmation-modal.discard-changes')}
                </Button>
            </div>
        </Modal>
    );
};