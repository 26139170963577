import {Operation} from './Operation';
import {CampaignOperationType} from './CampaignOperationType';

export class ChangeCampaignBriefOperation implements Operation {
    constructor(public type: CampaignOperationType, public brief: string, public briefEnabled:boolean, public campaignId: number) {
    }

    getType(): CampaignOperationType {
        return this.type;
    }
}