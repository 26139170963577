import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {DropdownItem, ParagraphSkeleton} from '@ideascale/ui';
import {LabelData, Localizer, Stage, useDropdownFocus} from '@ideascale/commons';
import {useRefetchIdeaDetails} from 'hooks/useRefetchIdeaDetails';
import {IdeateStageSummary} from 'models/IdeateStageSummary';
import {BuildTeamStageSummary} from 'models/BuildTeamStageSummary';
import {RefineStageSummary} from 'models/RefineStageSummary';
import {EstimateStageSummary} from 'models/EstimateStageSummary';
import {ReviewScaleStageSummary} from 'models/ReviewScaleStageSummary';
import {FundStageSummary} from 'models/FundStageSummary';
import {AssessmentStageSummary} from 'models/AssessmentStageSummary';
import {ReserveStageSummary} from 'models/ReserveStageSummary';
import {PendingStageSummary} from 'models/PendingStageSummary';
import {BaseIdeaStageSummary} from 'models/BaseIdeaStageSummary';
import {IdeaLabelActionResponse} from 'models/IdeaLabelActionResponse';
import {QUERY_KEYS} from 'constants/AppConstants';

type IdeaLabelItemsProps = {
    localizer: Localizer;
    focusId: string;
    ideaId: number;
    fetchIdeaLabels: (ideaId: number) => Promise<IdeaLabelActionResponse>;
    onAddIdeaLabel: (labelData: LabelData) => void;
    onRemoveIdeaLabel: (labelData: LabelData) => void;
    toggleDropdown: () => void;
    toggleReportAbuseConfirmation: () => void;
    toggleReportDuplicateConfirmation: () => void;
    onChangeIdeaStage: (ideaId: number, stageId: number) => void;
    stageSummary: IdeateStageSummary | BuildTeamStageSummary | RefineStageSummary | EstimateStageSummary | ReviewScaleStageSummary | FundStageSummary | AssessmentStageSummary | ReserveStageSummary | PendingStageSummary | BaseIdeaStageSummary;
    isDetailsPage: boolean;
}

export const IdeaLabelItems = (props: IdeaLabelItemsProps) => {
    const {
        localizer,
        ideaId,
        focusId,
        fetchIdeaLabels,
        onAddIdeaLabel,
        onRemoveIdeaLabel,
        toggleDropdown,
        toggleReportAbuseConfirmation,
        toggleReportDuplicateConfirmation,
        onChangeIdeaStage,
        stageSummary,
        isDetailsPage
    } = props;

    const {focus} = useDropdownFocus();

    const {
        isLoading,
        isRefetching,
        data
    } = useQuery(
        [QUERY_KEYS.IDEA_LABELS, ideaId],
        ({queryKey: [, ideaId]}) => fetchIdeaLabels(Number(ideaId)),
        {
            staleTime: 0,
            onSuccess: useCallback(() => focus('#' + focusId, 10), [focus, focusId])
        }
    );
    const {refetchIdeaDetails} = useRefetchIdeaDetails();
    const [labels, setLabels] = useState<LabelData[]>(data?.labels || []);

    const onAddOrRemoveLabel = useCallback(async (labelData: LabelData, targetLabel: EventTarget) => {
        if (labelData.applied) {
            onRemoveIdeaLabel(labelData);
        } else {
            onAddIdeaLabel(labelData);
        }
        const newLabels = [...labels];
        const changedLabel = newLabels.find(label => label.id === labelData.id);
        if (changedLabel) {
            changedLabel.applied = !changedLabel.applied;
        }
        setLabels(newLabels);
        setTimeout(() => {
            (targetLabel as HTMLElement)?.focus();
        }, 0);
    }, [labels, onAddIdeaLabel, onRemoveIdeaLabel]);

    const onOffTopicClicked = useCallback(async (ideaId: number, stageId: number) => {
        toggleDropdown();
        await onChangeIdeaStage(ideaId, stageId);
        if (isDetailsPage) {
            refetchIdeaDetails(ideaId);
        }
    }, [isDetailsPage, onChangeIdeaStage, refetchIdeaDetails, toggleDropdown]);

    useEffect(() => {
        if (data?.labels && !(isRefetching || isLoading)) {
            setLabels(data.labels);
        }
    }, [data, isLoading, isRefetching]);

    return (
        isLoading || isRefetching
            ? (
                <DropdownItem>
                    <ParagraphSkeleton rows={5}/>
                </DropdownItem>
            )
            : (
                data && !(data.stage || data?.labels.length > 0 || data.reportAbuseAllowed || data.reportDuplicateAllowed)
                    ? <DropdownItem disabled>{localizer.msg('idea.actions.label-not-found')}</DropdownItem>
                    : (
                        <Fragment>
                            {
                                data?.labels.map(label => (
                                        (label.applied && label.removeAllowed) || (!label.applied && label.addAllowed)
                                            ? <DropdownItem key={label.id} toggle={false}
                                                            className={`${label.applied ? `label-field-${label.colorKey} applied` : ''} text-truncate`}
                                                            title={label.name}
                                                            onClick={(event) => onAddOrRemoveLabel(label, event.target)}>
                                                <span className={`icon-circle label-field-${label.colorKey}`}/>
                                                {label.name}
                                            </DropdownItem>
                                            : <DropdownItem key={label.id} toggle={false}
                                                            className={`${label.applied ? `label-field-${label.colorKey} applied` : ''} text-truncate cursor-default`}
                                                            title={label.name}>
                                                <span className={`icon-circle label-field-${label.colorKey}`}/>
                                                {label.name}
                                            </DropdownItem>
                                    )
                                )
                            }

                            {
                                data?.reportAbuseAllowed && (
                                    <DropdownItem className="d-flex align-items-center text-truncate" toggle={true}
                                                  title={localizer.msg('idea.actions.report-abuse')} onClick={() => {
                                        toggleReportAbuseConfirmation();
                                    }}>
                                        <span className="icon-circle label-field-abuse me-2"/>
                                        {localizer.msg('idea.actions.report-abuse')}
                                    </DropdownItem>
                                )
                            }

                            {
                                data?.reportDuplicateAllowed && (
                                    <DropdownItem className="d-flex align-items-center text-truncate" toggle={true}
                                                  title={localizer.msg('idea.actions.report-duplicate')}
                                                  onClick={() => {
                                                      toggleReportDuplicateConfirmation();
                                                  }}>
                                        <span className="icon-circle label-field-duplicate me-2"/>
                                        {localizer.msg('idea.actions.report-duplicate')}
                                    </DropdownItem>
                                )
                            }

                            {
                                data?.stage && (
                                    <DropdownItem
                                        className={`${Stage.isOffTopic(stageSummary.stage.id) ? `label-field-off-topic applied` : ''} text-truncate`}
                                        toggle={false}
                                        title={data.stage.label}
                                        onClick={() => {
                                            onOffTopicClicked(ideaId, data.stage.id).then();
                                        }}>
                                    <span
                                        className={`icon-circle ${Stage.isOffTopic(data.stage.id) ? 'stage-dot-5' : ''}`}/>
                                        {data.stage.label}
                                    </DropdownItem>
                                )
                            }
                        </Fragment>
                    )
            )
    );
};