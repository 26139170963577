import {useCommunityService} from './useService';
import {useCallback} from 'react';
import {ProfileFieldData} from 'models/ProfileFieldData';
import {ProfileFieldUpdateRequest} from 'models/types/ProfileFieldUpdateRequest';
import {ProfileSidebarResponse} from 'models/ProfileSidebarResponse';
import {ProfileFieldModalStatus} from 'models/ProfileFieldModalStatus';

export const useProfileFieldsService = () => {
    const communityService = useCommunityService();

    const fetchProfileFields = useCallback(async (memberId: number): Promise<ProfileFieldData[]> => {
        if (communityService) {
            return await communityService.fetchProfileFields(memberId);
        }
        return [];
    }, [communityService]);

    const fetchIncompleteRequiredFields = useCallback(async (): Promise<ProfileFieldData[]> => {
        if (communityService) {
            return await communityService.fetchMemberIncompleteProfileFields();
        }
        return [];
    }, [communityService]);

    const fetchPendingVerifiableFields = useCallback(async (): Promise<ProfileFieldData[]> => {
        if (communityService) {
            return await communityService.fetchMemberPendingVerifiableProfileFields();
        }
        return [];
    }, [communityService]);

    const fetchIncompleteVerifiableProfileFields = useCallback(async (): Promise<ProfileFieldData[]> => {
        if (communityService) {
            return await communityService.fetchMemberIncompleteVerifiableProfileFields();
        }
        return [];
    }, [communityService]);

    const updateProfileFields = useCallback(async (updateRequest: ProfileFieldUpdateRequest, memberId: number) => {
        if (communityService) {
            return await communityService.updateProfileFields(updateRequest, memberId);
        }
        return ProfileSidebarResponse.EMPTY;
    }, [communityService]);

    const fetchProfileFieldModalStatus = useCallback(async (): Promise<ProfileFieldModalStatus> => {
        if (communityService !== null) {
            return await communityService.fetchProfileFieldModalStatus();
        }
        return ProfileFieldModalStatus.EMPTY;
    }, [communityService]);

    return {
        fetchProfileFields,
        fetchIncompleteRequiredFields,
        fetchPendingVerifiableFields,
        fetchIncompleteVerifiableProfileFields,
        updateProfileFields,
        fetchProfileFieldModalStatus
    };
};