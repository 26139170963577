import React, {Fragment} from 'react';
import {Localizer} from '@ideascale/commons';
import {HtmlRenderer} from 'components/shared/HtmlRenderer';
import {Feature} from 'models/new-features/Feature';

type FeatureType = {
    localizer: Localizer;
    feature: Feature;
    dateTextPrefix: string
}

export const Features = (props: FeatureType) => {
    const {localizer, feature, dateTextPrefix} = props;

    return (
        <Fragment>
            <div className="d-md-flex w-100 justify-content-md-between">
                <h3 className="h5 fw-bold">{feature.title}</h3>
                {
                    feature.releaseDate &&
                    <small>{dateTextPrefix}: {localizer.date(feature.releaseDate)}</small>
                }
            </div>

            <HtmlRenderer content={feature.description}/>

            <div className="links mt-1">
                {
                    feature.supportUrl &&
                    <a href={feature.supportUrl}>{localizer.msg('new-features.help-file')}</a>
                }
                {
                    feature.featureUrl &&
                    <Fragment>
                        {
                            feature.supportUrl && <Fragment>&nbsp; | &nbsp;</Fragment>
                        }
                        <a href={feature.featureUrl}>{localizer.msg('new-features.go-to-feature')}</a>
                    </Fragment>
                }
            </div>
        </Fragment>
    );
};