import {useEffect, useState} from 'react';

export const useResizeWindow = () => {
    const [size, setSize] = useState([0, 0]);
    const breakpoint = 991;

    useEffect(() => {
        const setWindowSize = () => setSize([window.innerWidth, window.innerHeight]);

        setWindowSize();

        window.addEventListener('resize', setWindowSize);

        return () => window.removeEventListener('resize', setWindowSize);
    }, []);

    return {windowSize: size, windowWidth: size[0], windowHeight: size[1], isMobileLayout: size[0] < breakpoint};
};