import React from 'react';
import {Trans} from 'react-i18next';
import {Localizer, useHookFormContext} from '@ideascale/commons';

const REGEX_NUMBER = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/;

type EstimateFormSectionProps = {
    localizer: Localizer;
    fundUnit: string;
    fieldName: {
        high: string;
        medium: string;
        low: string;
    };
    sectionName: string;
    stageId: number;
}

export const EstimateFormSection = (props: EstimateFormSectionProps) => {
    const {localizer, fundUnit, fieldName, sectionName, stageId} = props;
    const {register, watch, formState: {errors}} = useHookFormContext();

    const watchHigh = watch(fieldName.high);
    const watchMedium = watch(fieldName.medium);
    const watchLow = watch(fieldName.low);

    return (
        <ul className={`list-unstyled estimate-${sectionName} rounded border-1 mb-5`}>
            <li className="border-bottom-1">
                <div className="row py-4 align-items-center m-0">
                    <div className="col-12 col-md-6 col-lg-8">
                        <div className="question-summary font-size-md">
                            <p className="mb-0">
                                <Trans i18nKey={`stage.estimate.high-${sectionName}-label`}>
                                    <span className="high"/>
                                </Trans>
                            </p>
                        </div>
                    </div>
                    <div className="form-group col-12 col-lg-4 question-summary-outer mb-0">
                        <div className="question-summary-value">
                            <label className="high fw-bold" htmlFor={`${fieldName.high}-${sectionName}-${stageId}`}>
                                {localizer.msg(`stage.estimate.estimate-${sectionName}-high`)}
                            </label>
                            <div className={`input-group ${errors[fieldName.high] ? 'has-error' : ''}`}>
                                <input type="text" className="form-control"
                                       id={`${fieldName.high}-${sectionName}-${stageId}`}
                                       {...register(fieldName.high, {
                                           required: {
                                               value: true,
                                               message: localizer.msg('stage.estimate.required-warn')
                                           },
                                           pattern: {
                                               value: REGEX_NUMBER,
                                               message: localizer.msg('stage.estimate.number-warn')
                                           },
                                           validate: (value) => (Number(value) >= Number(watchMedium) && Number(value) >= Number(watchLow)) || localizer.msg(`stage.estimate.warn-${sectionName}-high`)
                                       })}/>
                                <span
                                    className={`input-group-text input-group-append ${errors[fieldName.high] ? 'has-error' : ''}`}>
                                    {fundUnit}
                                </span>
                                {
                                    errors[fieldName.high] &&
                                    <div className="invalid-feedback d-block">
                                        {errors[fieldName.high].message}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li className="border-bottom-1">
                <div className="row py-4 align-items-center m-0">
                    <div className="col-12 col-md-6 col-lg-8">
                        <div className="question-summary font-size-md">
                            <p className="mb-0">
                                <Trans i18nKey={`stage.estimate.medium-${sectionName}-label`}>
                                    <span className="medium"/>
                                </Trans>
                            </p>
                        </div>
                    </div>
                    <div className="form-group col-12 col-lg-4 question-summary-outer mb-0">
                        <div className="question-summary-value">
                            <label className="medium fw-bold" htmlFor={`${fieldName.medium}-${sectionName}-${stageId}`}>
                                {localizer.msg(`stage.estimate.estimate-${sectionName}-medium`)}
                            </label>
                            <div className={`input-group ${errors[fieldName.medium] ? 'has-error' : ''}`}>
                                <input type="text" className="form-control"
                                       id={`${fieldName.medium}-${sectionName}-${stageId}`}
                                       {...register(fieldName.medium, {
                                           required: {
                                               value: true,
                                               message: localizer.msg('stage.estimate.required-warn')
                                           },
                                           pattern: {
                                               value: REGEX_NUMBER,
                                               message: localizer.msg('stage.estimate.number-warn')
                                           },
                                           validate: (value) => (Number(value) <= Number(watchHigh) && Number(value) >= Number(watchLow)) || localizer.msg(`stage.estimate.warn-${sectionName}-medium`)
                                       })}/>
                                <span
                                    className={`input-group-text input-group-append ${errors[fieldName.medium] ? 'has-error' : ''}`}>
                                    {fundUnit}
                                </span>
                                {
                                    errors[fieldName.medium] &&
                                    <div className="invalid-feedback d-block">
                                        {errors[fieldName.medium].message}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li className="border-bottom-1">
                <div className="row py-4 align-items-center m-0">
                    <div className="col-12 col-md-6 col-lg-8">
                        <div className="question-summary font-size-md">
                            <p className="mb-0">
                                <Trans i18nKey={`stage.estimate.low-${sectionName}-label`}>
                                    <span className="low"/>
                                </Trans>
                            </p>
                        </div>
                    </div>
                    <div className="form-group col-12 col-lg-4 question-summary-outer mb-0">
                        <div className="question-summary-value">
                            <label className="low fw-bold" htmlFor={`${fieldName.low}-${sectionName}-${stageId}`}>
                                {localizer.msg(`stage.estimate.estimate-${sectionName}-low`)}
                            </label>
                            <div className={`input-group ${errors[fieldName.low] ? 'has-error' : ''}`}>
                                <input type="text" className="form-control"
                                       id={`${fieldName.low}-${sectionName}-${stageId}`}
                                       {...register(fieldName.low, {
                                           required: {
                                               value: true,
                                               message: localizer.msg('stage.estimate.required-warn')
                                           },
                                           pattern: {
                                               value: REGEX_NUMBER,
                                               message: localizer.msg('stage.estimate.number-warn')
                                           },
                                           validate: (value) => (Number(value) <= Number(watchMedium) && Number(value) <= Number(watchHigh)) || localizer.msg(`stage.estimate.warn-${sectionName}-low`)
                                       })}/>
                                <span
                                    className={`input-group-text input-group-append ${errors[fieldName.low] ? 'has-error' : ''}`}>{fundUnit}</span>
                                {
                                    errors[fieldName.low] &&
                                    <div className="invalid-feedback d-block">
                                        {errors[fieldName.low].message}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    );
};