import React from 'react';
import {Localizer} from '@ideascale/commons';

type CommentApprovalProps = {
    localizer: Localizer;
    approvalAllowed: boolean;
    rejectionAllowed: boolean;
    onApproveClick: () => void;
    onRejectClick: () => void;
}

export const CommentApproval = (props: CommentApprovalProps) => {
    const {localizer, approvalAllowed, rejectionAllowed, onApproveClick, onRejectClick} = props;

    return (
        <div className="approve-reject-container text-end">
            {
                rejectionAllowed && (
                    <button className="btn btn-light mb-2 mb-sm-0 me-sm-2" onClick={onRejectClick}>
                        {localizer.msg('comments.actions.reject-comment')}
                    </button>
                )
            }
            {
                approvalAllowed && (
                    <button className="btn btn-secondary" onClick={onApproveClick}>
                        {localizer.msg('comments.actions.approve-comment')}
                    </button>
                )
            }
        </div>
    );
};