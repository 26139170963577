import React from 'react';
import {Localizer} from '@ideascale/commons';
import {LeaderboardSummary} from 'components/leaderboard/LeaderboardSummary';
import {LeaderboardParameters} from 'models/types/LeaderboardParameters';
import {TimePeriod} from 'models/enums/TimePeriod';
import {LeaderboardResponse} from 'models/LeaderboardResponse';
import {LEADERBOARD_TYPES} from 'constants/AppConstants';

type AllLeaderboardsTabProps = {
    localizer: Localizer;
    fetchLeaderboard: (parameters: LeaderboardParameters, timePeriod?: { mode: TimePeriod, unit: number }) => Promise<LeaderboardResponse>;
}

export const AllLeaderboardsTab = (props: AllLeaderboardsTabProps) => {
    const {localizer, fetchLeaderboard} = props;

    return (
        <div className="row mt-4">
            {
                LEADERBOARD_TYPES.map(({value}) => {
                        return <div className="col-12 col-lg-6 mb-4" key={value}>
                            <LeaderboardSummary localizer={localizer} fetchLeaderboard={fetchLeaderboard} type={value}/>
                        </div>;
                    }
                )
            }
        </div>
    );
};