import React, {useEffect} from 'react';
import {CommonUtil} from '@ideascale/ui';
import {PageTheme} from '@ideascale/commons';
import {useLandingPageContext} from 'contexts/LandingPageContext';
import {IdeaSubmissionPreviewContainer} from 'containers/IdeaSubmissionPreviewContainer';
import {RenderPageLayoutContainer} from './RenderPageLayoutContainer';
import {EditModeUtil} from 'utils/EditModeUtil';
import {TABINDEX_SET_DELAY} from 'constants/AppConstants';
import {useAppContext} from '../../contexts/AppContext';

type LandingPageContentContainerProps = {
    containerId: string;
    editMode: boolean;
};

export const LandingPageContentContainer = (props: LandingPageContentContainerProps) => {
    const {containerId, editMode} = props;
    const {landingPage: {theme = PageTheme.CURIE, permissions: {edit, translation}}} = useLandingPageContext();
    const {communityConfig:{name : communityName}} = useAppContext();

    useEffect(() => {
        if (editMode && (edit || translation)) {
            CommonUtil.wait(TABINDEX_SET_DELAY).then(() => {
                EditModeUtil.setTabIndexOnlyForEditModeElements();
            });
        }
    }, [edit, editMode, translation]);

    return (
        <main
            className={`w-100 landing-page-container main-content ${editMode ? 'landing-page-edit-mode' : ''} ${theme.toLowerCase().replace(/_/g, '-')}`}
            id={containerId} tabIndex={-1}>
            <h1 className="sr-only">{communityName}</h1>
            <IdeaSubmissionPreviewContainer displayAsModal/>
            <RenderPageLayoutContainer editMode={editMode}/>
        </main>
    );
};