import {useCallback} from 'react';
import {Scroller} from '../utils/Scroller';

type Comment = {
    id: number;
    replies: Comment[];
}

export const useCommentUtils = () => {
    const findCommentIdById = useCallback((comments: Comment[], commentId: number) => {
        let findComment: Comment | undefined;
        comments.every((comment) => {
            findComment = comments.find(item => item.id === commentId);
            if (findComment) {
                return false;
            }

            if (comment.replies) {
                findComment = comment.replies.find(item => item.id === commentId);
                if (findComment) {
                    return false;
                }

                comment.replies.every((comment3) => {
                    if (comment3.replies) {
                        findComment = comment3.replies.find(item => item.id === commentId);
                        if (findComment) {
                            return false;
                        }
                    }
                    return true;
                });
            }
            return true;
        });
        if (findComment) {
            return findComment.id;
        }
        return findComment;
    }, []);

    const scrollToComment = useCallback((commentId: string, flashing = true) => {
        setTimeout(() => {
            Scroller.scrollTo(commentId, {offset: -200, flashing: flashing});
        }, 300);
    }, []);

    return {
        findCommentIdById,
        scrollToComment
    };
};