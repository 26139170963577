import React from 'react';
import {Skeleton} from '@ideascale/ui';
import './PageHeaderSkeleton.scss';

export const PageHeaderSkeleton = () => {
    return (
        <header className="page-header page-header-skeleton">
            <div className="container px-0">
                <div className="pt-5 px-3 pb-3 header-bg">
                    <div className="d-flex align-items-center mt-3">
                        <Skeleton cssClass="header-logo me-3" circle width="74px" height="74px"/>
                        <div>
                            <div className="mb-2">
                                <Skeleton width="200px"/>
                            </div>
                            <Skeleton width="140px"/>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};