import {ConfigField} from './ConfigField';
import {CampaignEditableFieldType} from './CampaignEditableFieldType';

export class BriefConfigField extends ConfigField {
    public static EMPTY = new BriefConfigField(CampaignEditableFieldType.UNKNOWN, '', false);

    constructor(
        name: CampaignEditableFieldType,
        public value: string,
        public briefEnabled: boolean,
    ) {
        super(name, value);
    }
}