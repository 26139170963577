import React, {forwardRef, useCallback, useImperativeHandle, useRef} from 'react';
import {Icon} from '@ideascale/ui';
import {LinkIdeaQualifier, LinkQualifier, Localizer, PagedResponseContent, useToggle} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useQualifierLinkedIdeasAction} from 'hooks/useQualifierLinkedIdeasAction';
import {LinkedIdeas} from 'shared/LinkedIdeas';
import {LinkableIdeas} from '../idea/details/LinkableIdeas';
import {LinkableIdea} from 'models/LinkableIdea';
import {PageParameters} from 'models/types/PageParameters';
import {QualifierLinkedIdeas} from 'models/QualifierLinkedIdeas';
import {LinkedIdea} from 'models/LinkedIdea';
import svgIconsPath from '@ideascale/ui/src/assets/fonts/is-icon-defs.svg';

type IdeaLinkFieldProps = {
    localizer: Localizer;
    ideaId: number;
    ideaTitle: string;
    ideaDescription: string;
    linkQualifiers: LinkQualifier;
    values: QualifierLinkedIdeas[];
    onChange: (linkedIdeas: QualifierLinkedIdeas[]) => void;
    fetchLinkableIdeas: (ideaId: number, ideaTitle: string, ideaDescription: string, pageParameters: PageParameters) => Promise<PagedResponseContent<LinkableIdea>>;
    fetchLinkableIdeasByKeywords: (ideaId: number, pageParameters: PageParameters) => Promise<PagedResponseContent<LinkableIdea>>;
}

export type LinkIdeasHandle = {
    focus: () => void;
}

export const LinkIdeas = forwardRef<LinkIdeasHandle, IdeaLinkFieldProps>((props, ref) => {
    const {
        localizer,
        fetchLinkableIdeas,
        fetchLinkableIdeasByKeywords,
        ideaId,
        ideaTitle,
        ideaDescription,
        values,
        onChange,
        linkQualifiers,
    } = props;

    const {addIdeaToQualifiedLinkedIdeas} = useQualifierLinkedIdeasAction();
    const {authentication: {actor}} = useAppContext();
    const [showIdeaSearchField, toggleIdeaSearchField] = useToggle(false);
    const searchButtonRef = useRef<HTMLButtonElement>(null);

    const unlinkQualifierLinkIdea = useCallback((linkedIdea: LinkedIdea, qualifierId: number) => {
        const newQualifierIdeas: QualifierLinkedIdeas[] = values.map(qualifierLinkedIdeas => {
            if (qualifierLinkedIdeas.linkQualifier.id === qualifierId) {
                return {
                    ...qualifierLinkedIdeas,
                    ideas: qualifierLinkedIdeas.ideas.filter(idea => idea.id !== linkedIdea.id)
                };
            } else {
                return qualifierLinkedIdeas;
            }
        });
        onChange(newQualifierIdeas);
    }, [onChange, values]);

    const addQualifiedLinkedIdea = useCallback((idea: LinkedIdea, linkIdeaQualifier: LinkIdeaQualifier) => {
        const newQualifierIdeas: QualifierLinkedIdeas[] = values.map(qualifierLinkIdea => ({
            linkQualifier: qualifierLinkIdea.linkQualifier,
            ideas: [...qualifierLinkIdea.ideas]
        }));
        onChange(addIdeaToQualifiedLinkedIdeas(newQualifierIdeas, idea, linkIdeaQualifier));
    }, [addIdeaToQualifiedLinkedIdeas, onChange, values]);

    const onClickQualifier = useCallback((idea: LinkableIdea, linkIdeaQualifier: LinkIdeaQualifier) => {
        addQualifiedLinkedIdea({
            id: idea.id,
            title: idea.title,
            deletable: true,
            linkedBy: actor,
            labels: [],
            attributes: idea.attributes
        }, linkIdeaQualifier);
    }, [actor, addQualifiedLinkedIdea]);

    useImperativeHandle(ref, () => {
        return {
            focus() {
                searchButtonRef.current?.focus();
            }
        };
    }, []);

    return (
        <div className="linked-idea-container mb-3">
            <div className="d-flex justify-content-between mb-3">
                <strong>{localizer.msg('ideas.terms.linked.long')}</strong>
                <button className="btn bg-transparent p-0 text-link" type="button" data-test-element-id="toggle-idea-search"
                        onClick={toggleIdeaSearchField} ref={searchButtonRef}>
                    {
                        showIdeaSearchField
                            ?
                            localizer.msg('common.hide')
                            :
                            <Icon name="magnifying-glass-left" iconSpritePath={svgIconsPath} width={16} height={14}/>
                    }
                    <span> {localizer.msg('common.search')}</span>
                </button>
            </div>
            <LinkableIdeas localizer={localizer}
                           ideaId={ideaId}
                           ideasContainerIdPrefix="idea-form"
                           ideaTitle={ideaTitle}
                           ideaDescription={ideaDescription}
                           fetchLinkableIdeas={fetchLinkableIdeas}
                           fetchLinkableIdeasByKeywords={fetchLinkableIdeasByKeywords}
                           linkQualifiers={linkQualifiers}
                           onClickQualifier={onClickQualifier}
                           showIdeaSearchField={showIdeaSearchField}/>

            <div className="mt-3">
                <LinkedIdeas actorMemberId={actor.id} localizer={localizer} linkedIdeas={values}
                             linkQualifiers={linkQualifiers}
                             unlinkIdea={unlinkQualifierLinkIdea}/>
            </div>
        </div>
    );
});