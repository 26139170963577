import React, {useState} from 'react';
import {FieldValues, useForm} from 'react-hook-form';
import {ActionButton, Modal} from '@ideascale/ui';
import {isHtmlInject, Localizer, PageTheme, useApiErrorResponseHandler, useHandleFormSubmit} from '@ideascale/commons';
import {Component} from 'models/landing-page/Component';
import {LANDING_PAGE_PLAIN_TEXT_MAX_CHAR_COUNT} from 'constants/AppConstants';

type CommonConfigModalProps = {
    open: boolean;
    toggle: () => void;
    configData: Component;
    updateData: (config: Component) => Promise<void>;
    localizer: Localizer;
    theme: PageTheme;
    onComponentEdited: () => void;
    renderSubtitle?: boolean;
}

export const CommonConfigModal = (props: CommonConfigModalProps) => {
    const {open, toggle, configData, updateData, localizer, renderSubtitle = false, onComponentEdited} = props;
    const {register, handleSubmit, formState: {errors, isSubmitting}, setError} = useForm();
    const onFormSubmit = useHandleFormSubmit(handleSubmit);
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [configUpdated, setConfigUpdated] = useState(false);

    const submitConfig = async (data: FieldValues) => {
        const newConfig = {...configData};
        newConfig.title = data.title;
        newConfig.subtitle = data.subtitle;
        try {
            await updateData(newConfig);
            onComponentEdited();
            toggle();
        } catch (e) {
            handleErrorResponse(e, {setFormError: setError});
        }
    };

    return (
        <Modal isOpen={open} toggle={toggle}
               title={localizer.msg('landing-page.components.edit.heading')} autoFocus={false}>
            <form id={`title-form-${configData.id}`}
                  onSubmit={onFormSubmit(submitConfig, isSubmitting || !configUpdated)}>
                <div className={`form-group ${errors.title ? 'has-error' : ''}`}>
                    <label htmlFor="title" className="control-label fw-bold">
                        {localizer.msg('landing-page.components.common.heading')}
                        <span className="font-size-lg" aria-hidden={true}>*</span>
                    </label>
                    <input className="form-control" type="text" id="title" autoFocus
                           defaultValue={configData.title}
                           aria-required="true"
                           {...register('title', {
                               onChange: () => setConfigUpdated(prevState => !prevState ? true : prevState),
                               required: localizer.msg('common.errors.required'),
                               validate: value => !isHtmlInject(value) || localizer.msg('common.errors.html'),
                               maxLength: {
                                   value: LANDING_PAGE_PLAIN_TEXT_MAX_CHAR_COUNT,
                                   message: localizer.msg('common.errors.max-length', {count: LANDING_PAGE_PLAIN_TEXT_MAX_CHAR_COUNT}),
                               },
                               setValueAs: value => value.trim()
                           })}/>
                    {
                        errors.title &&
                        <div className="invalid-feedback d-block">
                            {errors.title.message}
                        </div>
                    }
                </div>

                {
                    renderSubtitle &&
                    <div className={`form-group ${errors.subtitle ? 'has-error' : ''}`}>
                        <label htmlFor="subtitle"
                               className="control-label fw-bold">{localizer.msg('landing-page.components.common.subtitle')}</label>
                        <input type="text" id="subtitle" className="form-control"
                               defaultValue={configData.subtitle}
                               {...register('subtitle', {
                                   onChange: () => setConfigUpdated(prevState => !prevState ? true : prevState),
                                   validate: value => !isHtmlInject(value) || localizer.msg('common.errors.html'),
                                   setValueAs: value => value.trim(),
                                   maxLength: {
                                       value: LANDING_PAGE_PLAIN_TEXT_MAX_CHAR_COUNT,
                                       message: localizer.msg('common.errors.max-length', {count: LANDING_PAGE_PLAIN_TEXT_MAX_CHAR_COUNT}),
                                   }
                               })}/>
                        {
                            errors.subtitle &&
                            <div className="invalid-feedback d-block">
                                {errors.subtitle.message}
                            </div>
                        }
                    </div>
                }
                <div className="form-group mt-5 text-end">
                    <button className="btn btn-cancel me-3" type="button" onClick={toggle}
                            data-test-element-id="btn-cancel">
                        {localizer.msg('common.actions.cancel')}
                    </button>
                    <ActionButton type="submit" form={`title-form-${configData.id}`} loading={isSubmitting}
                                  disabled={!configUpdated} data-test-element-id="btn-submit">
                        {localizer.msg('common.actions.submit')}
                    </ActionButton>
                </div>
            </form>
        </Modal>
    );
};