import React, {Fragment, useEffect, useState} from 'react';
import {Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import {Icon} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {TabItem} from './TabItem';

type TabPanelProps = {
    tabItems: TabItem[];
    onTabChange?: (selectedTab: number) => void;
    defaultActiveTab?: number;
}

export const TabPanel = ({tabItems, onTabChange, defaultActiveTab = 1}: TabPanelProps) => {
    const [selectedTab, setSelectedTab] = useState(defaultActiveTab);

    useEffect(() => {
        const filterActiveTabs = tabItems.filter(tabs => !tabs.disabled);
        const findActiveTab = filterActiveTabs.find(tabs => tabs.value === defaultActiveTab);
        if (findActiveTab) {
            setSelectedTab(defaultActiveTab);
        } else if (filterActiveTabs.length > 0) {
            setSelectedTab(filterActiveTabs[0].value);
        } else {
            setSelectedTab(-1);
        }
        //eslint-disable-next-line
    }, [defaultActiveTab]);

    const setCurrentActiveTab = (value: number) => {
        setSelectedTab(value);
        if (selectedTab !== value) {
            onTabChange?.(value);
        }
    };

    return (
        <Fragment>
            <Nav pills={true} className="nav-pills-loose custom-tabs" role="tablist">
                {
                    tabItems.map(tabItem => (
                        <Fragment key={tabItem.value}>
                            <NavItem
                                className={`${tabItem.additionalTabInfo ? 'd-inline-flex align-items-center' : ''} ${selectedTab === tabItem.value ? ' active' : ''}`}
                                key={tabItem.value}>
                                <NavLink className={selectedTab === tabItem.value ? ' active' : ''}
                                         role="tab" aria-selected={selectedTab === tabItem.value}
                                         href="#"
                                         onClick={() => setCurrentActiveTab(tabItem.value)}
                                         disabled={tabItem.disabled}>
                                    <Icon
                                        className={`tab-icon${selectedTab === tabItem.value ? ' active' : ''}`}
                                        iconSpritePath={svgIconPath}
                                        name={tabItem.icon}
                                        width={16}
                                        height={16}
                                    />
                                    {tabItem.text}
                                </NavLink>
                                {
                                    tabItem.disabled && tabItem.additionalTabInfo
                                        ? tabItem.additionalTabInfo
                                        : null
                                }
                            </NavItem>
                        </Fragment>
                    ))
                }
            </Nav>
            <TabContent activeTab={selectedTab} className="mt-4" role="tabpanel">
                {
                    tabItems.map(tabItem => (
                            <TabPane key={tabItem.value} tabId={tabItem.value}>
                                {tabItem.content}
                            </TabPane>
                        )
                    )
                }
            </TabContent>
        </Fragment>

    );
};
