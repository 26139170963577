import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {CollapseExpand, Icon, ParagraphSkeleton, Scrollbar, ScrollbarInstance} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {
    CLASSIFICATION_NAME,
    ClassificationLabel,
    ClassificationUtil,
    InfiniteScrollLoadMoreButton,
    Localizer,
    StringUtil,
    useApiErrorResponseHandler,
    useIntersectionObserver
} from '@ideascale/commons';
import {useProfilePageParams} from 'hooks/useProfilePageParams';
import {appLinks} from 'services/AppLinks';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {GenericResponse} from 'models/GenericResponse';
import {FollowedIdea} from 'models/types/FollowedIdea';

const PAGE_SIZE = 30;
const UNAUTHORIZED_PRIVATE = 'unauthorized/private';

type FollowedIdeasProps = {
    localizer: Localizer;
    expanded: boolean;
    fetchFollowedIdeas: (pageParameters: PageParameters) => Promise<PagedResponseContent<FollowedIdea>>;
    unfollowIdea: (ideaId: number) => Promise<GenericResponse>;
    followIdea: (ideaId: number) => Promise<GenericResponse>;
    actionable: boolean;
}

interface IdeaSubscription extends FollowedIdea {
    followed: boolean;
}

export const FollowedIdeas = (props: FollowedIdeasProps) => {
    const {localizer, expanded, fetchFollowedIdeas, unfollowIdea, followIdea, actionable} = props;
    const currentProfileId = useProfilePageParams();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const scrollbarContainer = useRef<ScrollbarInstance>(null);
    const loadMoreButtonRef = useRef<HTMLButtonElement>(null);
    const currentPage = useRef(0);
    const [ideaData, setIdeaData] = useState<IdeaSubscription[]>([]);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        currentPage.current = 0;
        setHasMore(true);
    }, [currentProfileId]);

    const loadIdeas = useCallback(async () => {
        fetchFollowedIdeas({page: currentPage.current, limit: PAGE_SIZE}).then(data => {
            let hasMore = data.hasMore;
            setHasMore(hasMore);
            setLoading(false);
            let newData = data.content.map(idea => ({...idea, followed: true}));
            if (currentPage.current === 0) {
                setIdeaData(newData);
            } else {
                setIdeaData(prevData => [...prevData, ...newData]);
            }
            if (hasMore) {
                currentPage.current = currentPage.current + 1;
            }
        }).catch(e => {
            setLoading(false);
            handleErrorResponse(e);
        });
    }, [fetchFollowedIdeas, handleErrorResponse]);

    const toggleFollowed = async (ideaId: number) => {
        let newIdeaData = [...ideaData];
        let idea = newIdeaData.find(idea => idea.id === ideaId);
        if (idea) {
            try {
                if (idea.followed) {
                    await unfollowIdea(ideaId);
                    idea.followed = false;
                } else {
                    await followIdea(ideaId);
                    idea.followed = true;
                }
                setIdeaData(newIdeaData);
            } catch (error: any) {
                handleErrorResponse(error);
            }
        }
    };

    useEffect(() => {
        if (expanded && hasMore && currentPage.current === 0) {
            loadIdeas().then();
        }
    }, [expanded, hasMore, loadIdeas]);

    const onExpanded = () => {
        if (hasMore && currentPage.current === 0) {
            loadIdeas().then();
        }
    };

    useIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: loadIdeas,
        enabled: hasMore,
        options: {
            root: scrollbarContainer.current?.container
        }
    });

    return (
        <CollapseExpand containerClass={'followed-ideas mb-3 panel-light'}
                        headerTitle={localizer.msg('profile.followed-sections.idea.heading')}
                        svgIconSprite={svgIconPath}
                        defaultOpen={expanded} onEntering={onExpanded}>
            {
                loading ? (
                    <ParagraphSkeleton rows={6}/>
                ) : (
                    ideaData.length > 0 ? (
                        <Scrollbar
                            className="follow-section"
                            autoHeight
                            autoHeightMax={275}
                            ref={scrollbarContainer}>
                            <ul className="list-unstyled pe-2" aria-label={localizer.msg('profile.followed-sections.idea.heading')}>
                                {
                                    ideaData.map(idea => (
                                        <li className="d-flex py-1 px-1" key={idea.id}>
                                            {
                                                actionable ? (
                                                    <button className="d-flex align-items-start btn btn-link p-0"
                                                            aria-label={idea.followed ? localizer.msg('profile.followed-sections.idea.unfollow') : localizer.msg('profile.followed-sections.idea.follow')}
                                                            onClick={() => toggleFollowed(idea.id)} role="switch" aria-checked={idea.followed}>
                                                        <Icon className="subscribe-icon active"
                                                              iconSpritePath={svgIconPath}
                                                              name={idea.followed ? 'star-solid' : 'star-outlined'} width={18}
                                                              height={18}/>

                                                    </button>
                                                ) : (
                                                    <Icon className="subscribe-icon active" iconSpritePath={svgIconPath}
                                                          name="star-solid" width={18}
                                                          height={18}/>
                                                )
                                            }
                                            {idea.title === UNAUTHORIZED_PRIVATE ?
                                                <>
                                                    <ClassificationLabel
                                                        classification={ClassificationUtil.getAttributeByName(CLASSIFICATION_NAME.EFFECTIVE_CLASSIFICATION, idea.attributes)}
                                                        extraClasses="me-1 align-middle"/>
                                                    {StringUtil.textTruncate(idea.title, 30)}
                                                </> :
                                                <Link className="fw-bold font-size-sm ms-1 w-100"
                                                      to={appLinks.ideaDetails(idea.id)} title={idea.title}>
                                                    <ClassificationLabel
                                                        classification={ClassificationUtil.getAttributeByName(CLASSIFICATION_NAME.EFFECTIVE_CLASSIFICATION, idea.attributes)}
                                                        extraClasses="me-1 align-middle"/>
                                                    {StringUtil.textTruncate(idea.title, 30)}
                                                </Link>
                                            }
                                        </li>
                                    ))
                                }
                            </ul>
                            <InfiniteScrollLoadMoreButton hasMore={hasMore}
                                                          localizer={localizer}
                                                          loading={loading}
                                                          onCLick={loadIdeas}
                                                          loadMoreButtonRef={loadMoreButtonRef}/>
                        </Scrollbar>
                    ) : (
                        <span className="text-muted d-block mt-2">
                            {localizer.msg('profile.followed-sections.idea.empty')}
                        </span>
                    )
                )
            }
        </CollapseExpand>
    );
};