import React from 'react';
import {Icon, OptionType} from '@ideascale/ui';
import {useLocalizer} from 'hooks/useLocalizer';
import {SearchInputGroup} from 'shared/SearchInputGroup';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from '@ideascale/commons';
import {StagesHolder} from 'models/StagesHolder';
import {StageGroup} from 'models/StageGroup';
import {StageSelect} from 'shared/StageSelect';
import styles from './SearchField.module.scss';

type StageSearchFieldProps = {
    fetchStages(pageParameters: PageParameters): Promise<PagedResponseContent<StagesHolder<StageGroup>>>;
    defaultValue: OptionType[];
    setStages: (stages: OptionType[]) => void;
}

export const StageSearchField = (props: StageSearchFieldProps) => {
    const {fetchStages, defaultValue, setStages} = props;
    const localizer = useLocalizer();

    return (
        <div data-test-element-id="stages-search" className={`form-group ${styles.stageSearch}`}>
            <label className="fw-bold"
                   htmlFor="stage">{localizer.msg('search.input-label.stages')}</label>
            <SearchInputGroup
                groupText={<Icon name="funnel-lines" fill="#666" height={27} width={18} iconSpritePath={svgIconsPath}/>}>
                <StageSelect
                    localizer={localizer}
                    placeholder={'All Stages'}
                    inputId={'stage'}
                    value={defaultValue}
                    onSelect={(value) => setStages(value)}
                    fetchStages={fetchStages}
                />
            </SearchInputGroup>
        </div>
    );
};
