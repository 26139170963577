import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {NavLink, useParams} from 'react-router-dom';
import {NavItem} from 'reactstrap';
import {useQueryClient} from 'react-query';
import {
    AlertEvent,
    AlertType,
    buildAlertEventData,
    eventDispatcher,
    PagedResponseContent,
    useApiErrorResponseHandler,
    useRoutesMatch,
    useToggle
} from '@ideascale/commons';
import {Button, Icon} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useAppContext} from 'contexts/AppContext';
import {useCommunityService} from 'hooks/useService';
import {useLocalizer} from 'hooks/useLocalizer';
import {useAiAssistanceService} from 'hooks/useAiAssistanceService';
import {PageNavbar} from 'components/PageNavbar';
import {PageHeader} from 'components/PageHeader';
import {PageHeaderSkeleton} from 'components/PageHeaderSkeleton';
import {PageNavbarSkeleton} from 'components/PageNavbarSkeleton';
import {CampaignFollowersModal} from 'components/CampaignFollowersModal';
import {IdeaListRouteMatchParams} from 'models/types/IdeaListRouteMatchParams';
import {CampaignSubscribeResponse} from 'models/CampaignSubscribeResponse';
import {CampaignHeaderData} from 'models/CampaignHeaderData';
import {FollowingData} from 'models/FollowingData';
import {FollowerInfo} from 'models/types/FollowerInfo';
import {PageParameters} from 'models/types/PageParameters';
import {ROUTES} from 'shared/Routes';
import {appLinks} from 'services/AppLinks';

type CampaignHeaderContainerProps = {
    navigationDisabled?: boolean;
}

export const CampaignHeaderContainer = (props: CampaignHeaderContainerProps) => {
    const {navigationDisabled = false} = props;
    const localizer = useLocalizer();
    const {communityConfig, currentCampaign} = useAppContext();
    const communityService = useCommunityService();
    const {fetchAiAssistedText, fetchAiAssistedImages} = useAiAssistanceService();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [loading, setLoading] = useState(true);
    const [followingInfo, setFollowingInfo] = useState(FollowingData.EMPTY);
    const [showCampaignFollows, toggleCampaignFollows] = useToggle(false);
    const [isFetchingCampaignHeader, setIsFetchingCampaignHeader] = useToggle(true);
    const [campaignHeaderData, setCampaignHeaderData] = useState<CampaignHeaderData>(CampaignHeaderData.EMPTY);
    const params = useParams<IdeaListRouteMatchParams>();
    const ideaRouteMatch = useRoutesMatch(ROUTES.CAMPAIGN.IDEAS_PATH);

    const queryClient = useQueryClient();

    const campaignId = useMemo(() => {
        return currentCampaign ? currentCampaign.id : 0;
    }, [currentCampaign]);

    const fetchCampaignHeader = useCallback(async (campaignId: number): Promise<CampaignHeaderData> => {
        if (communityService !== null && campaignId !== 0) {
            return await communityService.fetchCampaignHeader(campaignId);
        }
        return CampaignHeaderData.EMPTY;
    }, [communityService]);

    const subscribeCampaign = useCallback(async (campaignId: number): Promise<CampaignSubscribeResponse> => {
        if (communityService !== null && campaignId !== 0) {
            try {
                return await communityService.subscribeCampaign(campaignId);
            } catch (error: any) {
                handleErrorResponse(error);
            }
        }
        return CampaignSubscribeResponse.nullObject();
    }, [communityService, handleErrorResponse]);

    const unsubscribeCampaign = useCallback(async (campaignId: number): Promise<CampaignSubscribeResponse> => {
        if (communityService !== null && campaignId !== 0) {
            try {
                return await communityService.unsubscribeCampaign(campaignId);
            } catch (error: any) {
                handleErrorResponse(error);
            }
        }
        return CampaignSubscribeResponse.nullObject();
    }, [communityService, handleErrorResponse]);

    const fetchFollowInfo = useCallback(async (campaignId: number): Promise<FollowingData> => {
        if (communityService !== null && campaignId !== 0) {
            return await communityService.fetchCampaignFollows(campaignId);
        }
        return FollowingData.EMPTY;
    }, [communityService]);

    const fetchFollowersInfo = useCallback(async (campaignId: number, sort: string, pageParams: PageParameters): Promise<PagedResponseContent<FollowerInfo>> => {
        if (communityService !== null && campaignId !== 0) {
            return await communityService.fetchCampaignFollowers(campaignId, sort, pageParams);
        }
        return PagedResponseContent.nullObject();
    }, [communityService]);

    const onSubscribeClick = useCallback(() => {
        if (followingInfo.following) {
            unsubscribeCampaign(campaignId).then(status => {
                if (status?.status) {
                    eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, status.message));
                    if (followingInfo.totalFollowers !== undefined) {
                        setFollowingInfo({
                            communityWideAnonymityEnabled: followingInfo.communityWideAnonymityEnabled,
                            following: false,
                            totalFollowers: followingInfo.totalFollowers - 1,
                        });
                        void queryClient.invalidateQueries('campaign-followers');
                    }
                }
            });
        } else {
            subscribeCampaign(campaignId).then(status => {
                if (status?.status) {
                    eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, status.message));
                    if (followingInfo.totalFollowers !== undefined) {
                        setFollowingInfo({
                            communityWideAnonymityEnabled: followingInfo.communityWideAnonymityEnabled,
                            following: true,
                            totalFollowers: followingInfo.totalFollowers + 1,
                        });
                        void queryClient.invalidateQueries('campaign-followers');
                    }
                }
            });
        }
    }, [campaignId, followingInfo.communityWideAnonymityEnabled, followingInfo.following, followingInfo.totalFollowers, queryClient, subscribeCampaign, unsubscribeCampaign]);

    useEffect(() => {
        if (campaignId !== 0) {
            fetchFollowInfo(campaignId).then(data => {
                setFollowingInfo(data);
                setLoading(false);
            });
        }
    }, [campaignId, fetchFollowInfo]);

    useEffect(() => {
        if (campaignId !== 0) {
            fetchCampaignHeader(campaignId).then(data => {
                setCampaignHeaderData(data);
                setIsFetchingCampaignHeader(false);
            });
        }
    }, [campaignId, fetchCampaignHeader, setIsFetchingCampaignHeader]);

    return (
        <Fragment>
            {
                isFetchingCampaignHeader ?
                    <Fragment>
                        <PageHeaderSkeleton/>
                        <PageNavbarSkeleton/>
                    </Fragment>
                    :
                    <Fragment>
                        <PageHeader
                            logo={campaignHeaderData.logoUrl}
                            hideLogo={!campaignHeaderData.logoOnBannerEnabled}
                            logoBgTransparent={!campaignHeaderData.logoFrameEnabled}
                            bannerImage={campaignHeaderData.bannerImageUrl}
                            heading={campaignHeaderData.name}
                            headingMeta={communityConfig.name}
                            bannerAltText={campaignHeaderData.bannerImageAltText}
                            logoAltText={campaignHeaderData.logoAltText}
                            pageTitleInBlackEnabled={campaignHeaderData.pageTitleInBlackEnabled}
                            bannerGradientEnabled={campaignHeaderData.bannerGradientEnabled}
                            classificationSummary={campaignHeaderData.classificationSummary}
                            fetchAiAssistedCampaignName={fetchAiAssistedText}
                            fetchAiAssistedCampaignLogo={fetchAiAssistedImages}
                        />

                        <PageNavbar navbarAriaLabel={localizer.msg('campaign.nav-label')}
                                    navbarAction={
                                        <div className="nav-item ms-auto nav-action-items d-flex align-items-center">
                                            {!loading &&
                                                <Fragment>
                                                    {
                                                        (followingInfo.communityWideAnonymityEnabled || followingInfo.totalFollowers === 0)
                                                            ? <div className="me-4 d-flex align-items-center">
                                                                <Icon name="people-outlined-two" width={20}
                                                                      height={20} iconSpritePath={svgIconPath}/>
                                                                <span className="ms-1 d-block d-sm-none">
                                                                    {followingInfo.totalFollowers || 0}
                                                                </span>
                                                                <span className="ms-1 d-none d-sm-block">
                                                                    {localizer.msg('campaign.followers.follow-count', {count: followingInfo.totalFollowers || 0})}
                                                                </span>
                                                            </div>
                                                            : <Button color="transparent"
                                                                      className="text-default d-flex align-items-center fw-normal me-2 bg-hover-gray"
                                                                      data-test-element-id="followers-modal-toggle"
                                                                      onClick={toggleCampaignFollows}>
                                                                <Icon name="people-outlined-two" width={20}
                                                                      height={20} iconSpritePath={svgIconPath}/>
                                                                <span className="ms-1 d-block d-sm-none">
                                                                    {followingInfo.totalFollowers || 0}
                                                                </span>
                                                                <span className="ms-1 d-none d-sm-block">
                                                                    {localizer.msg('campaign.followers.follow-count', {count: followingInfo.totalFollowers || 0})}
                                                                </span>
                                                                <Icon name="chevron-right" className="ms-1" width={16}
                                                                      height={16} iconSpritePath={svgIconPath}/>
                                                            </Button>
                                                    }
                                                    <Button color="secondary" className="me-2"
                                                            onClick={onSubscribeClick}>
                                                        {
                                                            <Fragment>
                                                                <Icon className="follow-icon me-1 active"
                                                                      iconSpritePath={svgIconPath}
                                                                      name={followingInfo.following ? 'star-solid' : 'star-outlined'}
                                                                      width={20}
                                                                      height={20} fill={'#384EC1'}/>
                                                                {followingInfo.following ?
                                                                    <Fragment>
                                                                        {localizer.msg('campaign.unsubscribed')}

                                                                    </Fragment> :
                                                                    <Fragment>
                                                                        {localizer.msg('campaign.subscribe')}
                                                                    </Fragment>
                                                                }
                                                            </Fragment>
                                                        }
                                                    </Button>
                                                </Fragment>
                                            }
                                        </div>
                                    }>
                            <Fragment>
                                <NavItem className="nav-item px-2 px-md-3">
                                    <NavLink className={`nav-link px-0 ${ideaRouteMatch ? 'active' : ''}`}
                                             {...(navigationDisabled ? {tabIndex: -1} : {})}
                                             to={appLinks.ideas(String(campaignId), currentCampaign?.defaultStage?.key, params.mode)}>
                                        {localizer.msg('page.ideas')}
                                    </NavLink>
                                </NavItem>
                                <NavItem className="nav-item px-2 px-md-3">
                                    <NavLink className="nav-link px-0"
                                             {...(navigationDisabled ? {tabIndex: -1} : {})}
                                             to={appLinks.aboutCampaign(String(campaignId))}>{localizer.msg('page.about')}</NavLink>
                                </NavItem>
                                <NavItem className="nav-item px-2 px-md-3">
                                    <NavLink className="nav-link px-0"
                                             {...(navigationDisabled ? {tabIndex: -1} : {})}
                                             to={appLinks.campaignTeam(String(campaignId))}>{localizer.msg('page.team')}</NavLink>
                                </NavItem>
                                <NavItem className="nav-item px-2 px-md-3">
                                    <NavLink className="nav-link px-0"
                                             {...(navigationDisabled ? {tabIndex: -1} : {})}
                                             to={appLinks.campaignWorkflow(String(campaignId))}>{localizer.msg('page.workflow')}</NavLink>
                                </NavItem>
                            </Fragment>
                        </PageNavbar>
                    </Fragment>
            }
            {
                showCampaignFollows &&
                <CampaignFollowersModal open={showCampaignFollows}
                                        toggle={toggleCampaignFollows}
                                        localizer={localizer}
                                        campaignId={campaignId}
                                        totalFollowers={followingInfo.totalFollowers || 0}
                                        fetchFollowers={fetchFollowersInfo}/>
            }
        </Fragment>
    );
};