import React, {memo, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Localizer, StringUtil, useApiErrorResponseHandler} from '@ideascale/commons';
import {useLeaderboardContext} from 'contexts/LeaderboardContext';
import {LeaderboardList} from 'components/leaderboard/LeaderboardList';
import {LeaderboardSkeleton} from 'components/leaderboard/LeaderboardSkeleton';
import {LeaderboardType} from 'models/enums/LeaderboardType';
import {LeaderboardParameters} from 'models/types/LeaderboardParameters';
import {TimePeriod} from 'models/enums/TimePeriod';
import {LeaderboardItem} from 'models/LeaderboardItem';
import {LeaderboardResponse} from 'models/LeaderboardResponse';
import {appLinks} from 'services/AppLinks';

type LeaderboardProps = {
    localizer: Localizer;
    type: LeaderboardType;
    fetchLeaderboard: (parameters: LeaderboardParameters, timePeriod?: { mode: TimePeriod, unit: number }) => Promise<LeaderboardResponse>;
}

const SHOW_SUMMARY = true;
const PAGE_SIZE = 10;
const DEFAULT_SUMMARY_STATE = {content: [], hasMore: false, enabled: false};

export const LeaderboardSummary = memo((props: LeaderboardProps) => {
    const {localizer, type, fetchLeaderboard} = props;
    const {campaign, group, timePeriod} = useLeaderboardContext();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [loading, setLoading] = useState(true);
    const [leaderboardData, setLeaderboard] = useState<{ content: LeaderboardItem[], hasMore: boolean, enabled: boolean }>(DEFAULT_SUMMARY_STATE);
    const [enabled, setEnabled] = useState(false);
    const heading = localizer.msg(`leaderboard.type.${StringUtil.snakeCaseToKebabCase(type)}.label`);

    useEffect(() => {
        setLoading(true);
        const params: LeaderboardParameters = {
            leaderboardType: type,
            page: 0,
            limit: PAGE_SIZE,
            groupId: group?.value,
            campaignId: campaign?.value,
            summary: SHOW_SUMMARY
        };
        fetchLeaderboard(params, timePeriod?.value)
            .then(response => {
                setLeaderboard({content: response.content, hasMore: response.hasMore, enabled: response.enabled});
                setEnabled(enabled);
            })
            .catch((e) => {
                handleErrorResponse(e);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [campaign?.value, fetchLeaderboard, group?.value, enabled, timePeriod?.value, type, handleErrorResponse]);

    return (
        <div className="card panel panel-light">
            <div className="card-header panel-heading bg-transparent border-bottom-1 pt-3 pb-3 rounded-top">
                {heading}
            </div>
            <div className="card-body p-0">
                <ul className="list-unstyled mb-0">
                    {
                        loading
                            ? <LeaderboardSkeleton/>
                            : <LeaderboardList type={type} localizer={localizer} items={leaderboardData.content}
                                               disabled={!leaderboardData.enabled}/>
                    }
                </ul>
                <Link to={appLinks.leaderboard(type)}
                      className="ps-4 mb-3 d-inline-block font-size-sm mt-3">
                    {localizer.msg('leaderboard.view-leaderboard', {label: heading})}
                </Link>
            </div>
        </div>
    );
});