import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Progress} from 'reactstrap';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {
    AlertEvent,
    AlertType,
    buildAlertEventData,
    ErrorReason,
    eventDispatcher,
    HookFormProvider,
    useHandleFormSubmit,
    useToggle
} from '@ideascale/commons';
import {Button, Icon, ParagraphSkeleton} from '@ideascale/ui';
import {useIdeaDetailsContext} from 'contexts/IdeaDetailsContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {FunderList} from 'components/stage-activities/FunderList';
import {ActivityCard} from 'components/idea/details/ActivityCard';
import {FundTargetPopover} from 'components/stage-activities/FundTargetPopover';
import {FundUnit} from 'models/enums/FundUnit';
import {TargetFormType} from 'models/stage-activity/FundTargetFormType';
import {FundStageSummary} from 'models/FundStageSummary';
import {StageActionResponse} from 'models/StageActionResponse';
import {BaseIdeaStageSummary} from 'models/BaseIdeaStageSummary';
import {BaseStageActivityProps} from 'models/types/BaseStageActivityProps';
import {FundStageActivity, NonEmptyFundUnitKey} from 'models/FundStageActivity';
import iconRightLarge from 'assets/img/icon-right-large.png';
import IconToolsLarge from 'assets/img/icon-tools-large.png';
import {FUND_REGEX_PATTERN} from 'constants/AppConstants';
import 'components/stage-activities/Fund.scss';

const TARGET_AMOUNT_CONTAINER_ID = 'target-amount-container-id';
const SET_TARGET_POPOVER_TRIGGER_ID = 'set-target';
const EDIT_TARGET_POPOVER_TRIGGER_ID = 'edit-target';

type FundActivityProps = BaseStageActivityProps & {
    fetchFund: (ideaId: number, stageId: number) => Promise<FundStageActivity>;
    editFundPledge: (ideaId: number, stageId: number) => Promise<FundStageActivity>;
    fundTarget: (ideaId: number, amount: number) => Promise<StageActionResponse<FundStageActivity, FundStageSummary>>;
    modifyFundPledge: (ideaId: number, amount: number) => Promise<StageActionResponse<FundStageActivity, FundStageSummary>>;
    updateIdeaStageSummary: <T extends BaseIdeaStageSummary>(stageSummary: T) => void;
    ideaId: number;
};

type ContributionFormType = {
    amount: number;
}

export const FundActivity = (props: FundActivityProps) => {
    const {
        fetchFund,
        editFundPledge,
        stageId,
        ideaId,
        fundTarget,
        modifyFundPledge,
        isCurrentStage,
        headerSummary,
        headerTitle,
        updateIdeaStageSummary
    } = props;

    const {ideaDetails: {stageSummary}} = useIdeaDetailsContext();
    const [open, toggle] = useToggle(false);
    const [renderPopover, setRenderPopover] = useState(false);
    const [showContributionForm, toggleContributionForm] = useToggle(false);
    const [loading, setLoading] = useState(true);
    const [fundData, setFundData] = useState<FundStageActivity>(FundStageActivity.EMPTY);
    const [targetFieldDirty, setTargetFieldDirty] = useState(false);
    const [contributionFieldDirty, setContributionFieldDirty] = useState(false);
    const [editTargetLoading, setEditTargetLoading] = useState(false);
    const [modifyFundLoading, setModifyFundLoading] = useState(false);
    const [editFundLoading, setEditFundLoading] = useState(false);
    const [cancelEditFundLoading, setCancelEditFundLoading] = useState(false);
    const {ideaFundAccount} = fundData;
    const localizer = useLocalizer();
    const targetMethods = useForm<TargetFormType>({mode: 'all'});
    const {reset: targetReset} = targetMethods;
    const {
        handleSubmit,
        control: contributionFormControl,
    } = useForm<ContributionFormType>({
        mode: 'all'
    });
    const onFormSubmit = useHandleFormSubmit(handleSubmit);
    const toggleFundEdit = useCallback((nextValue: boolean, fundData: FundStageActivity) => {
        setFundData(fundData);
        toggleContributionForm(nextValue);
    }, [toggleContributionForm]);

    const onTargetClicked = useCallback((values: TargetFormType) => {
        if (isNaN(values.target)) {
            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.error, localizer.msg('frontend-shared.errors.invalid_fund_target')));
            return;
        }

        setEditTargetLoading(true);
        fundTarget(ideaId, values.target).then((status) => {
            setEditTargetLoading(false);
            if (status) {
                updateIdeaStageSummary(status.stageSummary);
                if (status.stageActivity) {
                    toggleFundEdit(status.stageActivity.memberPledgeAllowed && status.stageActivity.memberPledgeAmount <= 0, status.stageActivity);
                }
                setTargetFieldDirty(false);
                toggle(false);
            }
        }).catch(error => {
            setEditTargetLoading(false);
            if (error.data.reason === ErrorReason.INVALID_FUND_AMOUNT) {
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.error, localizer.msg('frontend-shared.errors.invalid_fund_amount')));
            }
            if (error.data.reason === ErrorReason.INVALID_FUND_TARGET) {
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.error, localizer.msg('frontend-shared.errors.invalid_fund_target')));
            }
            if (error.data.reason === ErrorReason.FUND_TARGET_LOWERING_NOT_ALLOWED) {
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.warn, localizer.msg('frontend-shared.errors.fund_target_lowering_not_allowed')));
            }
        });
    }, [fundTarget, ideaId, localizer, toggle, toggleFundEdit, updateIdeaStageSummary]);

    const modifyFundPledgeClick = (values: ContributionFormType) => {
        if (isNaN(values.amount)) {
            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.error, localizer.msg('frontend-shared.errors.invalid_fund_amount')));
            return;
        }

        setModifyFundLoading(true);
        modifyFundPledge(ideaId, values.amount).then((status) => {
            setModifyFundLoading(false);
            if (status) {
                updateIdeaStageSummary(status.stageSummary);
                if (status.stageActivity) {
                    toggleFundEdit(status.stageActivity.memberPledgeAllowed && status.stageActivity.memberPledgeAmount <= 0, status.stageActivity);
                }
                setContributionFieldDirty(false);
            }
        }).catch(error => {
            setModifyFundLoading(false);
            if (error.data.reason === ErrorReason.FUND_TRANSACTION_INADEQUATE_FUND_BALANCE) {
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.error, localizer.msg('frontend-shared.errors.fund_transaction_inadequate_fund_balance')));
            }
            if (error.data.reason === ErrorReason.INVALID_FUND_AMOUNT) {
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.error, localizer.msg('frontend-shared.errors.invalid_fund_amount')));
            }
            if (error.data.reason === ErrorReason.FUND_TRANSACTION_TARGET_AMOUNT_EXCEED) {
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.error, localizer.msg('frontend-shared.errors.fund_transaction_target_amount_exceed')));
            }
        });
    };

    const numberFormat = (value: number) => {
        return new Intl.NumberFormat().format(value);
    };

    const editStageFund = useCallback(() => {
        setEditFundLoading(true);
        editFundPledge(ideaId, stageId).then(data => {
            setEditFundLoading(false);
            setContributionFieldDirty(false);
            toggleFundEdit(true, data);
            setLoading(false);
        });
    }, [editFundPledge, ideaId, stageId, toggleFundEdit]);

    const onEditFundCanceled = useCallback(() => {
        setCancelEditFundLoading(true);
        fetchFund(ideaId, stageId).then(data => {
            setCancelEditFundLoading(false);
            toggleFundEdit(false, data);
        });
    }, [fetchFund, ideaId, stageId, toggleFundEdit]);

    const renderFundUnit = (fundUnit?: FundUnit, fundUnitKey?: string) => {
        return fundUnit
            ?
            <span>
                {fundUnit}
                {
                    fundUnitKey &&
                    Object.values(NonEmptyFundUnitKey).includes(fundUnitKey as NonEmptyFundUnitKey) &&
                    <Fragment>
                        ({localizer.msg(fundUnitKey!)})
                    </Fragment>
                }
            </span>
            :
            null;
    };

    const onActivityCardExited = useCallback(() => {
        setRenderPopover(false);
    }, []);

    const onActivityCardEnter = useCallback(() => {
        setLoading(true);
        if (isCurrentStage && showContributionForm) {
            editFundPledge(ideaId, stageId).then(data => {
                setFundData(data);
                setLoading(false);
                setTimeout(() => {
                    setRenderPopover(true);
                }, 0);
            });
        } else {
            fetchFund(ideaId, stageId).then(data => {
                setFundData(data);
                setLoading(false);
                setTimeout(() => {
                    setRenderPopover(true);
                }, 0);
            });
        }
    }, [editFundPledge, fetchFund, ideaId, isCurrentStage, showContributionForm, stageId]);

    useEffect(() => {
        if (isCurrentStage) {
            setLoading(true);
            fetchFund(ideaId, stageId).then(data => {
                toggleFundEdit(data.memberPledgeAllowed && data.memberPledgeAmount <= 0, data);
                targetReset({target: data.ideaFundAccount?.collectionTarget || 0});
                setLoading(false);
                setTimeout(() => {
                    setRenderPopover(true);
                }, 0);
            });
        }
    }, [fetchFund, ideaId, isCurrentStage, stageId, targetReset, toggleFundEdit]);

    return (
        <HookFormProvider {...targetMethods}>
            <div className="pb-3">
                <ActivityCard containerClass="campaign-stages"
                              headerTitle={headerTitle}
                              headerSummary={
                                  <p className="mb-0 mt-3 fw-normal">
                                      {headerSummary}
                                  </p>
                              }
                              isCurrentStage={isCurrentStage}
                              svgIconSprite={svgIconPath}
                              defaultOpen={isCurrentStage}
                              onExited={onActivityCardExited}
                              onEntered={onActivityCardEnter}>
                    <Fragment>
                        {
                            loading ?
                                <ParagraphSkeleton rows={10}/>
                                :
                                <Fragment>
                                    {
                                        (fundData.viewAllowed || fundData.participationAllowed)
                                            ?
                                            <Fragment>
                                                {
                                                    !isCurrentStage &&
                                                    <Fragment>
                                                        <div className="mb-3">
                                                            {localizer.msg('profile.activity-stream.fund.completed')}
                                                        </div>
                                                        <h5>{headerTitle}</h5>
                                                    </Fragment>
                                                }
                                                <div
                                                    className="d-block d-lg-flex justify-content-between fund-stage mb-2">
                                                    <div className="fund-goal w-100">
                                                        <Fragment>
                                                            <div
                                                                className="d-inline-flex justify-content-between w-100">
                                                                {
                                                                    fundData?.ideaFundAccount?.collectionTarget !== undefined &&
                                                                    <span
                                                                        className="d-inline-flex flex-column flex-md-row">
                                                                        <span className="text-gray">
                                                                            {localizer.msg('stage.fund.target')}: &nbsp;
                                                                        </span>
                                                                    <span>
                                                                    <span className="fw-bold">
                                                                        {numberFormat(fundData.ideaFundAccount.collectionTarget)}
                                                                    </span>
                                                                        &nbsp;
                                                                        {renderFundUnit(ideaFundAccount?.fundUnit, ideaFundAccount?.fundUnitKey)}
                                                                        {
                                                                            isCurrentStage && fundData.fundTargetModifyAllowed &&
                                                                            fundData.participationAllowed &&
                                                                            <Fragment>
                                                                                <Button
                                                                                    color="transparent"
                                                                                    className="p-0 ms-1"
                                                                                    id={EDIT_TARGET_POPOVER_TRIGGER_ID}
                                                                                    aria-label={localizer.msg('stage.fund.edit-target')}>
                                                                                    <Icon
                                                                                        className="position-relative pos-top-n1 active"
                                                                                        name="pencil" fill="#666"
                                                                                        width={15}
                                                                                        height={15}
                                                                                        iconSpritePath={svgIconPath}/>
                                                                                </Button>

                                                                                <span
                                                                                    id={`${TARGET_AMOUNT_CONTAINER_ID}-${EDIT_TARGET_POPOVER_TRIGGER_ID}`}>
                                                                                {
                                                                                    renderPopover &&
                                                                                    <FundTargetPopover
                                                                                        localizer={localizer}
                                                                                        open={open}
                                                                                        toggle={toggle}
                                                                                        loading={editTargetLoading}
                                                                                        targetId={EDIT_TARGET_POPOVER_TRIGGER_ID}
                                                                                        containerId={`${TARGET_AMOUNT_CONTAINER_ID}-${EDIT_TARGET_POPOVER_TRIGGER_ID}`}
                                                                                        onSubmit={onTargetClicked}
                                                                                        fundData={fundData}
                                                                                        stageSummary={stageSummary as FundStageSummary}
                                                                                        targetFieldDirty={targetFieldDirty}
                                                                                        setTargetFieldDirty={setTargetFieldDirty}/>
                                                                                }
                                                                            </span>
                                                                            </Fragment>
                                                                        }
                                                                        </span>
                                                                    </span>
                                                                }
                                                                {
                                                                    (fundData?.ideaFundAccount || fundData?.fundingPercentage === undefined) &&
                                                                    <span
                                                                        className={`d-inline-flex ${fundData.ideaFundAccount?.collectionTarget ? 'flex-column' : ''} flex-md-row`}>
                                                                        <span className="text-gray">
                                                                            {localizer.msg('stage.fund.raised')}: &nbsp;
                                                                        </span>
                                                                    <span>
                                                                    <span className="fw-bold">
                                                                        {numberFormat(fundData?.ideaFundBalance || 0)}
                                                                    </span>
                                                                        &nbsp;
                                                                        {
                                                                            renderFundUnit(
                                                                                ideaFundAccount?.fundUnit || fundData.memberFundAccount?.fundUnit || (stageSummary as FundStageSummary).fundUnit,
                                                                                ideaFundAccount?.fundUnitKey || fundData.memberFundAccount?.fundUnitKey || (stageSummary as FundStageSummary).fundUnitKey
                                                                            )
                                                                        }
                                                                </span>
                                                                </span>
                                                                }
                                                            </div>
                                                            {
                                                                fundData.fundingPercentage !== undefined &&
                                                                <Fragment>
                                                                    <div role="progressbar"
                                                                         aria-valuenow={fundData.fundingPercentage}
                                                                         className="sr-only"
                                                                         aria-label={localizer.msg('stage.fund.fund-progress')}/>
                                                                    <Progress aria-hidden="true" className="mt-3 mb-2"
                                                                              color="primary"
                                                                              value={fundData.fundingPercentage}>
                                                                        {fundData.fundingPercentage}%
                                                                    </Progress>
                                                                </Fragment>
                                                            }
                                                        </Fragment>
                                                        {
                                                            isCurrentStage && fundData.fundTargetModifyAllowed &&
                                                            fundData.participationAllowed && !fundData.ideaFundAccount &&
                                                            <Fragment>
                                                                <Button className="px-5" color="primary"
                                                                        type="button"
                                                                        id={SET_TARGET_POPOVER_TRIGGER_ID}>
                                                                    {localizer.msg('stage.fund.add-target')}
                                                                </Button>
                                                                <span
                                                                    id={`${TARGET_AMOUNT_CONTAINER_ID}-${SET_TARGET_POPOVER_TRIGGER_ID}`}>
                                                                    {
                                                                        renderPopover &&
                                                                        <FundTargetPopover
                                                                            localizer={localizer}
                                                                            open={open}
                                                                            toggle={toggle}
                                                                            loading={editTargetLoading}
                                                                            targetId={SET_TARGET_POPOVER_TRIGGER_ID}
                                                                            containerId={`${TARGET_AMOUNT_CONTAINER_ID}-${SET_TARGET_POPOVER_TRIGGER_ID}`}
                                                                            onSubmit={onTargetClicked}
                                                                            fundData={fundData}
                                                                            stageSummary={stageSummary as FundStageSummary}
                                                                            targetFieldDirty={targetFieldDirty}
                                                                            setTargetFieldDirty={setTargetFieldDirty}/>
                                                                    }
                                                                </span>
                                                            </Fragment>
                                                        }
                                                    </div>
                                                </div>

                                                {
                                                    fundData.memberPledgeAllowed && fundData?.memberFundBalance >= 0 &&
                                                    <div className="contribution">
                                                        {
                                                            fundData?.memberPledgeAmount > 0 &&
                                                            <p className="h5 fw-bold">
                                                                {localizer.msg('stage.fund.your-contribution')}
                                                            </p>
                                                        }
                                                        {
                                                            showContributionForm &&
                                                            <form
                                                                className="bg-gray p-4 mb-2 rounded edit-contribution-form"
                                                                id="contribution-form"
                                                                noValidate
                                                                onSubmit={onFormSubmit(modifyFundPledgeClick, editFundLoading)}>
                                                                <div
                                                                    className="form-group mb-0 fund-input-field d-flex flex-column flex-md-row">
                                                                    <div className="input-group me-2">
                                                                        <label className="sr-only"
                                                                               htmlFor="amount">{localizer.msg('stage.fund.contribution-amount')}</label>
                                                                        <Controller
                                                                            control={contributionFormControl}
                                                                            name="amount"
                                                                            shouldUnregister={true}
                                                                            defaultValue={fundData.memberPledgeAmount || 0}
                                                                            render={({
                                                                                         field: {
                                                                                             name,
                                                                                             onChange,
                                                                                             value
                                                                                         }
                                                                                     }) => (
                                                                                <input className="form-control"
                                                                                       id={name}
                                                                                       disabled={modifyFundLoading}
                                                                                       value={value}
                                                                                       onChange={event => {
                                                                                           if (FUND_REGEX_PATTERN.test(event.target.value) || event.target.value === '') {
                                                                                               setContributionFieldDirty(true);
                                                                                               return onChange(event.target.value);
                                                                                           }
                                                                                       }}
                                                                                       onBlur={event => onChange(isNaN(+event.target.value) ? 0 : +event.target.value)}
                                                                                />
                                                                            )}/>
                                                                        {
                                                                            fundData.memberFundAccount?.fundUnit &&
                                                                            <span
                                                                                className="input-group-text input-group-append"
                                                                                id="basic-addon2">
                                                                                {fundData.memberFundAccount.fundUnit}
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                    <div className="d-flex mt-3 mt-md-0">
                                                                        <Button className="px-3" type="submit"
                                                                                color="primary"
                                                                                disabled={!contributionFieldDirty || modifyFundLoading || cancelEditFundLoading}>
                                                                            {
                                                                                modifyFundLoading
                                                                                    ? <span
                                                                                        className="spinner-border spinner-border-sm position-relative pos-top-n1"/>
                                                                                    :
                                                                                    localizer.msg('stage.fund.contribute')
                                                                            }
                                                                        </Button>
                                                                        {
                                                                            fundData.memberPledgeAmount > 0 &&
                                                                            <Button color="secondary" className="ms-2"
                                                                                    onClick={onEditFundCanceled}
                                                                                    disabled={modifyFundLoading || cancelEditFundLoading}>
                                                                                {
                                                                                    cancelEditFundLoading
                                                                                        ? <span
                                                                                            className="spinner-border spinner-border-sm position-relative pos-top-n1"/>
                                                                                        : localizer.msg('common.cancel')
                                                                                }
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {
                                                                    fundData?.memberFundBalance >= 0 &&
                                                                    fundData.participationAllowed && isCurrentStage &&
                                                                    <p className="mb-0 mt-3 mt-md-2">
                                                                        <span className="text-gray">
                                                                            ({localizer.msg('stage.fund.you-have')}
                                                                        </span>
                                                                        &nbsp;
                                                                        <span className="fw-bold">
                                                                            {numberFormat(fundData.memberFundBalance)}
                                                                        </span>
                                                                        &nbsp;
                                                                        {renderFundUnit(ideaFundAccount?.fundUnit, ideaFundAccount?.fundUnitKey)}
                                                                        &nbsp;
                                                                        <span className="text-gray">
                                                                            {localizer.msg('stage.fund.left-to-contribute')})
                                                                        </span>
                                                                    </p>
                                                                }
                                                            </form>
                                                        }
                                                        {
                                                            !showContributionForm &&
                                                            <div className="mb-1 d-flex align-items-center">
                                                                <span>
                                                                    <h3 className="d-inline-block mb-0">
                                                                        <span className="fw-bold">
                                                                            {numberFormat(fundData.memberPledgeAmount)}
                                                                        </span>
                                                                    </h3>
                                                                    &nbsp;
                                                                    {renderFundUnit(ideaFundAccount?.fundUnit, ideaFundAccount?.fundUnitKey)}
                                                                </span>
                                                                <button
                                                                    className="p-0 ms-1 border-0 bg-transparent line-height-0"
                                                                    disabled={editFundLoading}
                                                                    onClick={editStageFund}
                                                                    aria-label={localizer.msg('stage.fund.edit-contribution')}>
                                                                    {
                                                                        editFundLoading
                                                                            ? <span
                                                                                className="text-primary spinner-border spinner-border-sm"/>
                                                                            : <Icon className="active"
                                                                                    name="pencil"
                                                                                    width={15} height={15}
                                                                                    iconSpritePath={svgIconPath}/>
                                                                    }
                                                                </button>
                                                            </div>
                                                        }
                                                    </div>
                                                }

                                                {
                                                    fundData?.memberFundBalance >= 0 && fundData.participationAllowed &&
                                                    fundData.memberPledgeAllowed && isCurrentStage &&
                                                    !showContributionForm &&
                                                    <p className="mb-0 mt-2">
                                                        <span className="text-gray">
                                                            ({localizer.msg('stage.fund.you-have')}
                                                        </span>
                                                        &nbsp;
                                                        <span className="fw-bold">
                                                            {numberFormat(fundData.memberFundBalance)}
                                                        </span>
                                                        &nbsp;
                                                        {renderFundUnit(ideaFundAccount?.fundUnit, ideaFundAccount?.fundUnitKey)}
                                                        &nbsp;
                                                        <span className="text-gray">
                                                            {localizer.msg('stage.fund.left-to-contribute')})
                                                        </span>
                                                    </p>
                                                }

                                                {
                                                    fundData.viewFunderAllowed &&
                                                    <div className="funder-list mt-4">
                                                        <FunderList fundData={fundData} numberFormat={numberFormat}/>
                                                    </div>
                                                }
                                            </Fragment>
                                            :
                                            <div className="p-1">
                                                {
                                                    isCurrentStage &&
                                                    <Fragment>
                                                        <span>{localizer.msg('stage.fund.funding-in-progress')}</span><br/>
                                                        <div className="text-center">
                                                            <img src={IconToolsLarge} alt={'in progress'}/>
                                                        </div>
                                                    </Fragment>
                                                }
                                                {
                                                    !isCurrentStage &&
                                                    <Fragment>
                                                        <span>{localizer.msg('profile.activity-stream.fund.completed')}</span><br/>
                                                        <div className="text-center">
                                                            <img src={iconRightLarge} alt={'completed'}/>
                                                        </div>
                                                    </Fragment>
                                                }
                                            </div>
                                    }
                                </Fragment>
                        }
                    </Fragment>
                </ActivityCard>
            </div>
        </HookFormProvider>
    );
};