import React, {Fragment, useCallback} from 'react';
import {Button, Icon} from '@ideascale/ui';
import svgIcons from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useLandingPageContext} from 'contexts/LandingPageContext';
import {IdeasContextProvider} from 'contexts/IdeasContext';
import {IdeaListContextProvider} from 'contexts/IdeaListContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {HeroComponentContainer} from './HeroComponentContainer';
import {FeaturedCampaignsContainer} from './FeaturedCampaignsContainer';
import {IdeasContainer} from './IdeasContainer';
import {CommunityStatisticsContainer} from './CommunityStatisticsContainer';
import {CommunityTagsContainer} from './CommunityTagsContainer';
import {InstructionListContainer} from './InstructionListContainer';
import {CommunityLeadersContainer} from './CommunityLeadersContainer';
import {ActivitySummaryContainer} from './ActivitySummaryContainer';
import {TextComponentContainer} from './TextComponentContainer';
import {LandingPageComponentType} from 'models/enums/landing-page/LandingPageComponentType';
import {HeroComponentData} from 'models/landing-page/HeroComponentData';
import {FeaturedCampaignsComponentData} from 'models/landing-page/FeaturedCampaignsComponentData';
import {IdeasComponentData} from 'models/landing-page/IdeasComponentData';
import {CommunityStatisticsData} from 'models/landing-page/CommunityStatisticsData';
import {TagListComponent} from 'models/landing-page/TagListComponent';
import {InstructionListComponentData} from 'models/landing-page/InstructionListComponentData';
import {LeaderListComponent} from 'models/landing-page/LeaderListComponent';
import {ActivityListComponent} from 'models/landing-page/ActivityListComponent';
import {TextComponentData} from 'models/landing-page/TextComponentData';
import {Component} from 'models/landing-page/Component';

type RenderPageLayoutProps = {
    editMode: boolean;
}

export const RenderPageLayoutContainer = (props: RenderPageLayoutProps) => {
    const {editMode} = props;
    const localizer = useLocalizer();
    const {
        landingPage: {rows, translatedLanguageId, theme, languageId},
        reorder,
        translationMode,
        reorderRowId,
        handleComponentEdit
    } = useLandingPageContext();

    const translationAllowed = useCallback(() => {
        return translationMode && languageId !== translatedLanguageId;
    }, [languageId, translatedLanguageId, translationMode]);

    const onMoveUp = (rowId: number, index: number) => {
        reorder(rowId, index, 'up');
    };

    const onMoveDown = (rowId: number, index: number) => {
        reorder(rowId, index, 'down');
    };

    const isMoveDownButtonDisabled = (rowId: number, index: number) => {
        return (rows.length - 1) === index || (reorderRowId !== 0 && reorderRowId !== rowId);
    };

    const isUpDownButtonDisabled = (rowId: number, index: number) => {
        return index === 0 || (reorderRowId !== 0 && reorderRowId !== rowId);
    };

    const onComponentEdited = useCallback(() => {
        handleComponentEdit(true);
    }, [handleComponentEdit]);

    const renderWidget = useCallback((component: Component) => {
        switch (component.type) {
            case LandingPageComponentType.HERO:
                return <HeroComponentContainer theme={theme}
                                               showConfig={editMode}
                                               onComponentEdited={onComponentEdited}
                                               showTranslationConfig={translationAllowed()}
                                               translationLanguageId={translatedLanguageId}
                                               data={component as HeroComponentData}/>;
            case LandingPageComponentType.CAMPAIGNS:
                return ((component as FeaturedCampaignsComponentData)?.count > 0 || editMode || translationAllowed()) &&
                    <FeaturedCampaignsContainer
                        theme={theme}
                        showConfig={editMode}
                        onComponentEdited={onComponentEdited}
                        showTranslationConfig={translationAllowed()}
                        translationLanguageId={translatedLanguageId}
                        data={component as FeaturedCampaignsComponentData}/>;
            case LandingPageComponentType.PINNED_IDEAS:
                return ((component as IdeasComponentData)?.count > 0 || editMode || translationAllowed()) &&
                    <IdeasContextProvider>
                        <IdeaListContextProvider>
                            <IdeasContainer theme={theme}
                                            onComponentEdited={onComponentEdited}
                                            pinnedIdeas={true}
                                            showConfig={editMode}
                                            data={component as IdeasComponentData}
                                            showTranslationConfig={translationAllowed()}
                                            translationLanguageId={translatedLanguageId}
                                            accessibleName={localizer.msg('landing-page.pinned-ideas')}/>
                        </IdeaListContextProvider>
                    </IdeasContextProvider>;
            case LandingPageComponentType.IDEAS:
                return ((component as IdeasComponentData)?.count > 0 || editMode || translationAllowed()) &&
                    <IdeasContextProvider>
                        <IdeaListContextProvider>
                            <IdeasContainer showTranslationConfig={translationAllowed()}
                                            onComponentEdited={onComponentEdited}
                                            translationLanguageId={translatedLanguageId}
                                            theme={theme}
                                            showConfig={editMode}
                                            data={component as IdeasComponentData}
                                            accessibleName={localizer.msg('landing-page.ideas')}/>
                        </IdeaListContextProvider>
                    </IdeasContextProvider>;
            case LandingPageComponentType.STATISTICS:
                return <CommunityStatisticsContainer showTranslationConfig={translationAllowed()}
                                                     translationLanguageId={translatedLanguageId}
                                                     onComponentEdited={onComponentEdited}
                                                     theme={theme}
                                                     showConfig={editMode}
                                                     data={component as CommunityStatisticsData}/>;
            case LandingPageComponentType.TAGS:
                return ((component as TagListComponent)?.count > 0 || editMode || translationAllowed()) &&
                    <CommunityTagsContainer showTranslationConfig={translationAllowed()}
                                            onComponentEdited={onComponentEdited}
                                            translationLanguageId={translatedLanguageId} theme={theme}
                                            showConfig={editMode} data={component as TagListComponent}/>;
            case LandingPageComponentType.INSTRUCTIONS:
                return <InstructionListContainer showTranslationConfig={translationAllowed()}
                                                 translationLanguageId={translatedLanguageId}
                                                 theme={theme}
                                                 onComponentEdited={onComponentEdited}
                                                 showConfig={editMode}
                                                 data={component as InstructionListComponentData}/>;
            case LandingPageComponentType.LEADERS:
                return ((component as LeaderListComponent)?.count > 0 || editMode || translationAllowed()) &&
                    <CommunityLeadersContainer showTranslationConfig={translationAllowed()}
                                               translationLanguageId={translatedLanguageId}
                                               onComponentEdited={onComponentEdited}
                                               theme={theme} showConfig={editMode}
                                               data={component as LeaderListComponent}/>;
            case LandingPageComponentType.ACTIVITY_SUMMARY:
                return <ActivitySummaryContainer showTranslationConfig={translationAllowed()}
                                                 onComponentEdited={onComponentEdited}
                                                 translationLanguageId={translatedLanguageId}
                                                 theme={theme}
                                                 showConfig={editMode} data={component as ActivityListComponent}/>;
            case LandingPageComponentType.TEXT:
                return <TextComponentContainer showTranslationConfig={translationAllowed()}
                                               onComponentEdited={onComponentEdited} showConfig={editMode}
                                               translationLanguageId={translatedLanguageId} theme={theme}
                                               data={component as TextComponentData}/>;
            default:
                return <Fragment/>;
        }
    }, [editMode, localizer, onComponentEdited, theme, translatedLanguageId, translationAllowed]);

    return (
        <Fragment>
            {
                rows?.map((outerRow, outerRowIndex) => (
                    <section className={outerRow.attribute?.className} id={`section-${outerRow.id}`}
                             key={`section-${outerRow.id}`}>
                        {
                            outerRow.cells && outerRow.cells.length > 0 && (
                                <div className="container px-lg-0">
                                    {
                                        editMode &&
                                        <div className="d-flex justify-content-end reorder-action-bar">
                                            <Button className="pt-1 px-2 me-2 edit-mode-element"
                                                    disabled={isMoveDownButtonDisabled(outerRow.id, outerRowIndex)}
                                                    color="primary"
                                                    onClick={() => onMoveDown(outerRow.id, outerRowIndex)}
                                                    title={localizer.msg('landing-page.move-down')}
                                                    data-test-element-id="btn-move-down">
                                                <Icon iconSpritePath={svgIcons} width={20} height={20}
                                                      name="arrow-down"/>
                                            </Button>
                                            <Button className="pt-1 px-2 edit-mode-element"
                                                    disabled={isUpDownButtonDisabled(outerRow.id, outerRowIndex)}
                                                    color="primary"
                                                    onClick={() => onMoveUp(outerRow.id, outerRowIndex)}
                                                    title={localizer.msg('landing-page.move-up')}
                                                    data-test-element-id="btn-move-up">
                                                <Icon iconSpritePath={svgIcons} width={20} height={20} name="arrow-up"/>
                                            </Button>
                                        </div>
                                    }
                                    <div className="row">
                                        {
                                            outerRow.cells.map((outerCell) => (
                                                <div key={`section-${outerRow.id}-${outerCell.id}`}
                                                     className={outerCell.attribute?.className}>
                                                    {
                                                        outerCell.component && renderWidget(outerCell.component)
                                                    }
                                                    {
                                                        outerCell.rows && outerCell.rows.map((innerRow) =>
                                                            <div
                                                                key={`row-${outerRow.id}-${outerCell.id}-${innerRow.id}`}
                                                                className="row">
                                                                {
                                                                    innerRow.cells && innerRow.cells.map((innerCell) =>
                                                                        <div
                                                                            key={`cell-${outerRow.id}-${outerCell.id}-${innerRow.id}-${innerCell.id}`}
                                                                            className={innerCell.attribute?.className}>
                                                                            {
                                                                                innerCell.component && renderWidget(innerCell.component)
                                                                            }
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </section>
                ))
            }
        </Fragment>
    );
};