import React, {useCallback, useEffect, useRef, useState} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useParams} from 'react-router-dom';
import {Icon, ParagraphSkeleton, RichTextClassificationConfig} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {
    ClassifiableContributionType,
    ClassificationAttachmentAttribute,
    ClassificationsHolder,
    CommentType,
    eventDispatcher,
    FileAttachmentDetail,
    LabelData,
    SUPER_COMMENT_EVENTS,
    useApiErrorResponseHandler
} from '@ideascale/commons';
import {COMMENT_LIST_PAGE_SIZE, DEFAULT_STALE_TIME, EFFECTIVE_CLASSIFICATION, ID_PREFIXES, QUERY_KEYS} from 'constants/AppConstants';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useCommentService} from 'hooks/useCommentService';
import {useCommentUtils} from 'hooks/useCommentUtils';
import {useIdeaUpdater} from 'hooks/useIdeaUpdater';
import {useClassificationService} from 'hooks/useClassificationService';
import {useFileUploadService} from 'hooks/useFileUploadService';
import {Comment} from 'components/idea-details-tabs/Comment';
import {CommentForm} from 'components/idea-details-tabs/CommentForm';
import {Scroller} from 'utils/Scroller';
import {CommonUtil} from 'utils/CommonUtil';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {CommentOrder} from 'models/comments/CommentOrder';
import {CommentLevel} from 'models/comments/CommentLevel';
import {CommentSummary} from 'models/comments/CommentSummary';
import {CommentPermissions} from 'models/comments/CommentPermissions';
import {SortItem} from 'models/comments/SortItem';
import {CommentVoteResponse} from 'models/comments/CommentVoteResponse';
import {CommentRouteMatchParams} from 'models/types/CommentRouteMatchParams';
import {StageFunctionName} from 'models/enums/StageFunctionName';
import {KudoActivitySummary} from 'models/KudoActivitySummary';
import styles from 'components/idea-details-tabs/Comment.module.scss';

type CommunityCommentsContainerProps = {
    ideaId: number;
    sortBy: SortItem;
    className?: string;
    richTextClassificationConfig?: RichTextClassificationConfig;
    classificationsMenuData?: ClassificationsHolder | {};
}

export const CommunityCommentsContainer = (props: CommunityCommentsContainerProps) => {
    const {ideaId, sortBy, className, classificationsMenuData, richTextClassificationConfig} = props;
    const params = useParams<CommentRouteMatchParams>();
    const localizer = useLocalizer();
    const {authentication: {actor}, communityConfig: {classificationEnabled}} = useAppContext();
    const {
        fetchPaginatedCommentList,
        fetchPinnedComments,
        fetchCommentPermissions,
        deleteComment,
        pinComment,
        unpinComment,
        createSuperComment,
        addCommentsLabel,
        removeCommentsLabel,
        commentUpDownVote,
        commentRetractVote,
        commentApprove,
        commentReject,
        giveCommentKudo,
        reportAbuseComment,
        fetchOriginalComment,
        fetchEditComment,
        commentAttachments,
        unfurlUrl,
    } = useCommentService();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const {uploadFile} = useFileUploadService();
    const {fetchEffectiveClassifications} = useClassificationService();
    const {findCommentIdById, scrollToComment} = useCommentUtils();
    const {getCachedIdeaDetails, updateIdeaDetails, updateIdeaEffectiveClassificationAttribute} = useIdeaUpdater();
    const [commentsList, setCommentsList] = useState<CommentSummary[]>([]);
    const [pinnedCommentsList, setPinnedCommentsList] = useState<CommentSummary[]>([]);
    const [hasNextPageComments, setHasNextPageComments] = useState<boolean>(false);
    const [hasPrevPageComments, setHasPrevPageComments] = useState<boolean>(false);
    const [isCommentLoading, setIsCommentLoading] = useState<boolean>(false);
    const [isPinnedCommentLoading, setIsPinnedCommentLoading] = useState<boolean>(true);
    const newCommentIdsRef = useRef<number[]>([]);
    const pageRef = useRef(0);
    const prevPageRef = useRef(-1);
    const commentHighlighted = useRef(false);
    const queryClient = useQueryClient();

    const {data: commentPermissions = CommentPermissions.EMPTY} = useQuery([QUERY_KEYS.COMMENT_PERMISSION, ideaId], () => fetchCommentPermissions(ideaId), {
        staleTime: DEFAULT_STALE_TIME
    });
    const commentKudosGiveMutation = useMutation((commentId: number): any => giveCommentKudo(commentId), {
        onSuccess: async (data: KudoActivitySummary, commentId) => {
            setCommentsList(prev => {
                return updateKudosCount(prev, commentId, data.kudoCount);
            });

            setPinnedCommentsList(prev => {
                return updateKudosCount(prev, commentId, data.kudoCount);
            });
        }
    });

    const updateKudosCount = (prev: CommentSummary[], commentId: number, kudoCount: number) => {
        const oldComments = cloneDeep(prev);

        oldComments.every(comment => {
            if (comment.id === commentId) {
                comment.kudoCount = kudoCount;
                return false;
            }

            comment.replies?.every(comment2 => {
                if (comment2.id === commentId) {
                    comment2.kudoCount = kudoCount;
                    return false;
                }
                comment2.replies?.every(comment3 => {
                    if (comment3.id === commentId) {
                        comment3.kudoCount = kudoCount;
                        return false;
                    }
                    return true;
                });
                return true;
            });
            return true;
        });
        return oldComments;
    };

    const scrollToCommentsTop = () => {
        Scroller.scrollTo(ID_PREFIXES.NORMAL_COMMENT_LIST, {offset: -200, duration: 500});
    };

    const scrollTo = useCallback((comment: CommentSummary, flashing = true) => {
        setTimeout(() => {
            if (comment.pinned) {
                scrollToComment(`${ID_PREFIXES.PINNED_COMMENT}-${comment.id}`, flashing);
            } else {
                scrollToComment(`${ID_PREFIXES.COMMENT}-${comment.id}`, flashing);
            }
        }, 300);
    }, [scrollToComment]);

    const fetchPaginatedComments = useCallback(async (loadPrevComments = false, commentId?: number) => {
        try {
            const commentResponse: PagedResponseContent<CommentSummary> = await fetchPaginatedCommentList(ideaId, {
                order: sortBy.order,
                pageParameters: {
                    page: loadPrevComments ? prevPageRef.current : pageRef.current,
                    limit: COMMENT_LIST_PAGE_SIZE
                }
            }, commentId);

            if (loadPrevComments) {
                setCommentsList(prev => [...prev, ...commentResponse.content]);
                scrollTo(commentResponse.content[0], false);
            } else {
                if (sortBy.order === CommentOrder.DATE_NEWEST) {
                    setCommentsList(prev => [...commentResponse.content, ...prev]);
                    if (pageRef.current > 0) {
                        scrollToCommentsTop();
                    }
                } else {
                    setCommentsList(prev => [...prev, ...commentResponse.content]);
                }
            }

            if (params.commentId) {
                if (prevPageRef.current === -1 && commentResponse.pageNo > 0) {
                    setHasPrevPageComments(true);
                    prevPageRef.current = commentResponse.pageNo - 1;
                } else if (loadPrevComments) {
                    setHasPrevPageComments(prevPageRef.current > 0);
                    prevPageRef.current = prevPageRef.current !== 0 ? prevPageRef.current - 1 : 0;
                }
            }

            if (!loadPrevComments) {
                setHasNextPageComments(commentResponse.hasMore);
                if (pageRef.current === 0 && commentResponse.pageNo > 0) {
                    pageRef.current = commentResponse.pageNo;
                }
                pageRef.current = commentResponse.hasMore ? pageRef.current + 1 : pageRef.current;
            }

        } catch (error: any) {
            handleErrorResponse(error);
        } finally {
            setIsCommentLoading(false);
        }
    }, [fetchPaginatedCommentList, handleErrorResponse, ideaId, params.commentId, scrollTo, sortBy.order]);

    const fetchAllPinnedComments = useCallback(async () => {
        try {
            const pinnedComments: CommentSummary[] = await fetchPinnedComments(ideaId, {
                order: sortBy.order
            });
            setPinnedCommentsList(pinnedComments);
        } catch (error: any) {
            handleErrorResponse(error);
        } finally {
            setIsPinnedCommentLoading(false);
        }
    }, [fetchPinnedComments, handleErrorResponse, ideaId, sortBy.order]);

    const onHasMoreCommentsClick = () => {
        if (hasNextPageComments) {
            if (sortBy.order !== CommentOrder.DATE_NEWEST) {
                setCommentsList(prev => {
                    let newList = [...prev];
                    newList = newList.filter(item => !newCommentIdsRef.current.includes(item.id));
                    return newList;
                });
            }
            fetchPaginatedComments().then();
        }
    };

    const onPrevCommentsClick = () => {
        if (hasPrevPageComments) {
            fetchPaginatedComments(true).then();
        }
    };

    const updateCommentsCount = useCallback((ideaId: number, operation: 'ADD' | 'REMOVE') => {
        const cachedIdeaDetails = getCachedIdeaDetails(ideaId);
        if (cachedIdeaDetails) {
            updateIdeaDetails(ideaId, 'commentCount', operation === 'ADD' ? cachedIdeaDetails.commentCount + 1 : cachedIdeaDetails.commentCount - 1);
        }
    }, [getCachedIdeaDetails, updateIdeaDetails]);

    const findComment = useCallback((comment: CommentSummary[], commentId: number): CommentSummary | undefined => {
        let findItem: CommentSummary | undefined;
        comment.every((item) => {
            if (item.id === commentId) {
                findItem = item;
                return false;
            }
            if (item.replies) {
                findItem = findComment(item.replies, commentId);
                return !findItem;
            }
            return !findItem;
        });
        return findItem;
    }, []);

    const updateCommentEffectiveClassificationAttribute = useCallback(async (comment: CommentSummary, updateState: React.Dispatch<React.SetStateAction<CommentSummary[]>>) => {
        try {
            const requestBody = [{id: comment.ideaId, type: ClassifiableContributionType.IDEA}];
            if (comment.ideaId !== comment.parentId) {
                requestBody.push({id: comment.parentId, type: ClassifiableContributionType.COMMENT});
                requestBody.push({id: comment.id, type: ClassifiableContributionType.COMMENT});
            } else {
                requestBody.push({id: comment.id, type: ClassifiableContributionType.COMMENT});
            }

            const response = await fetchEffectiveClassifications(requestBody);
            const ideaEffectiveClassification = response?.find(item => item.id === comment.ideaId);
            updateIdeaEffectiveClassificationAttribute(comment.ideaId, ideaEffectiveClassification);

            updateState(prev => {
                const oldComments = cloneDeep(prev);
                const commentFound = findComment(oldComments, comment.id);
                if (commentFound) {
                    const classificationSummary = response.find(item => item.id === commentFound.id);
                    if (classificationSummary) {
                        commentFound.attributes = [...commentFound?.attributes?.filter(attr => attr.name !== EFFECTIVE_CLASSIFICATION) ?? [], {
                            name: EFFECTIVE_CLASSIFICATION,
                            value: classificationSummary.classificationSummary
                        }];
                    }
                }
                return oldComments;
            });
        } catch (error: any) {
            handleErrorResponse(error);
        }
    }, [fetchEffectiveClassifications, findComment, handleErrorResponse, updateIdeaEffectiveClassificationAttribute]);

    const updateEffectiveClassification = useCallback(async (commentSummary: CommentSummary) => {
        if (classificationEnabled) {
            await updateCommentEffectiveClassificationAttribute(commentSummary, setCommentsList);

            if (pinnedCommentsList.length) {
                await updateCommentEffectiveClassificationAttribute(commentSummary, setPinnedCommentsList);
            }
        }
    }, [classificationEnabled, pinnedCommentsList.length, updateCommentEffectiveClassificationAttribute]);

    const onAddNewComment = useCallback(async (comment: CommentSummary) => {
        newCommentIdsRef.current = [...newCommentIdsRef.current, comment.id];
        if (comment.stage.toLowerCase() !== StageFunctionName.PENDING) {
            updateCommentsCount(comment.ideaId, 'ADD');
        }

        if (comment.pinned) {
            setPinnedCommentsList(prev => {
                return [...prev, comment];
            });
        }

        setCommentsList(prev => {
            return [...prev, comment];
        });

        if (comment.annotated) {
            eventDispatcher.dispatch(SUPER_COMMENT_EVENTS.SUPER_COMMENTS_RELOAD);
        }

        scrollTo(comment);

        await updateEffectiveClassification(comment);
    }, [scrollTo, updateCommentsCount, updateEffectiveClassification]);

    const updateCommentsInList = (prev: CommentSummary[], commentId: number, replyComment: CommentSummary, commentLevel: CommentLevel) => {
        const oldComments = cloneDeep(prev);
        oldComments.every(comment => {
            if (comment.id === commentId) {
                comment.replies = [...comment.replies || [], replyComment];
                return false;
            }

            if (commentLevel === CommentLevel.Level2 && comment.replies?.length > 0) {
                comment.replies.every((comment2) => {
                    if (comment2.id === commentId) {
                        comment2.replies = [...comment2.replies || [], replyComment];
                        return false;
                    }
                    return true;
                });
            }
            return true;
        });
        return oldComments;
    };

    const addReplyComment = useCallback((parentComment: CommentSummary, replyComment: CommentSummary, commentLevel: CommentLevel) => {
        if (replyComment.stage.toLowerCase() !== StageFunctionName.PENDING) {
            updateCommentsCount(replyComment.ideaId, 'ADD');
        }

        setCommentsList(prev => {
            return updateCommentsInList(prev, parentComment.id, replyComment, commentLevel);
        });

        setPinnedCommentsList(prev => {
            return updateCommentsInList(prev, parentComment.id, replyComment, commentLevel);
        });


        if (replyComment.annotated) {
            eventDispatcher.dispatch(SUPER_COMMENT_EVENTS.SUPER_COMMENTS_RELOAD);
        }

        if (parentComment.pinned) {
            CommonUtil.flashElement(`${ID_PREFIXES.PINNED_COMMENT}-${replyComment.id}`);
        }
        CommonUtil.flashElement(`${ID_PREFIXES.COMMENT}-${replyComment.id}`);
    }, [updateCommentsCount]);

    const onReplyComment = useCallback(async (comment: CommentSummary, replyComment: CommentSummary, commentLevel: CommentLevel) => {
        addReplyComment(comment, replyComment, commentLevel);
        await updateEffectiveClassification(replyComment);
    }, [addReplyComment, updateEffectiveClassification]);

    const deleteAttachmentFromList = (prev: CommentSummary[], commentId: number, attachmentId: number, commentLevel: CommentLevel) => {
        const oldAttachments = cloneDeep(prev);
        oldAttachments.every(comment => {
            if (comment.attachments && comment.id === commentId) {
                comment.attachments = comment.attachments.filter(attachment => attachment.id !== attachmentId);
                return false;
            }

            if (commentLevel === CommentLevel.Level2 && comment.replies?.length > 0) {
                comment.replies.every((comment2) => {
                    if (comment2.id === commentId) {
                        comment2.attachments = comment2.attachments.filter(attachment => attachment.id !== attachmentId);
                        return false;
                    }
                    return true;
                });
            }

            if (commentLevel === CommentLevel.Level3 && comment.replies?.length > 0) {
                comment.replies.every((comment2) => {
                    let goToNextItem = true;
                    if (comment2.replies?.length > 0) {
                        comment2.replies.every((comment3) => {
                            if (comment3.id === commentId) {
                                comment3.attachments = comment3.attachments.filter(attachment => attachment.id !== attachmentId);
                                goToNextItem = false;
                                return false;
                            }
                            return true;
                        });
                    }
                    return goToNextItem;
                });
            }
            return true;
        });
        return oldAttachments;
    };

    const deleteAttachment = useCallback((commentId: number, attachmentId: number, commentLevel: CommentLevel) => {
        setCommentsList(prev => {
            return deleteAttachmentFromList(prev, commentId, attachmentId, commentLevel);
        });

        setPinnedCommentsList(prev => {
            return deleteAttachmentFromList(prev, commentId, attachmentId, commentLevel);
        });
    }, []);

    const onDeleteAttachment = useCallback(async (commentSummary: CommentSummary, attachmentId: number, commentLevel: CommentLevel) => {
        deleteAttachment(commentSummary.id, attachmentId, commentLevel);
        await updateEffectiveClassification(commentSummary);
    }, [deleteAttachment, updateEffectiveClassification]);

    const updateAttachmentsInLists = (prev: CommentSummary[], commentId: number, attachments: FileAttachmentDetail[], commentLevel: CommentLevel, attributes?: ClassificationAttachmentAttribute[]) => {
        const oldAttachments = cloneDeep(prev);
        oldAttachments.every(comment => {
            if (comment.id === commentId) {
                comment.attachments = attachments;
                comment.attributes = attributes;
                return false;
            }

            if (commentLevel === CommentLevel.Level2 && comment.replies?.length > 0) {
                comment.replies.every((comment2) => {
                    if (comment2.id === commentId) {
                        comment2.attachments = attachments;
                        comment2.attributes = attributes;
                        return false;
                    }
                    return true;
                });
            }

            if (commentLevel === CommentLevel.Level3 && comment.replies?.length > 0) {
                comment.replies.every((comment2) => {
                    let goToNextItem = true;
                    if (comment2.replies?.length > 0) {
                        comment2.replies.every((comment3) => {
                            if (comment3.id === commentId) {
                                comment3.attachments = attachments;
                                comment3.attributes = attributes;
                                goToNextItem = false;
                                return false;
                            }
                            return true;
                        });
                    }
                    return goToNextItem;
                });
            }
            return true;
        });
        return oldAttachments;
    };

    const updateCommentsListAttachments = useCallback((commentId: number, attachments: FileAttachmentDetail[], commentLevel: CommentLevel, attributes?: ClassificationAttachmentAttribute[]) => {
        setCommentsList(prev => {
            return updateAttachmentsInLists(prev, commentId, attachments, commentLevel, attributes);
        });

        setPinnedCommentsList(prev => {
            return updateAttachmentsInLists(prev, commentId, attachments, commentLevel, attributes);
        });
    }, []);

    const onAddAttachments = useCallback(async (comment: CommentSummary, attachments: FileAttachmentDetail[], commentLevel: CommentLevel, attributes?: ClassificationAttachmentAttribute[]) => {
        updateCommentsListAttachments(comment.id, attachments, commentLevel, attributes);
        await updateEffectiveClassification(comment);
    }, [updateCommentsListAttachments, updateEffectiveClassification]);

    const updateListCommentAfterEdit = (prev: CommentSummary[], editedComment: CommentSummary, replyLevel: CommentLevel) => {
        const oldComments = cloneDeep(prev);
        oldComments.every((comment, index) => {
            if (comment.id === editedComment.id) {
                oldComments[index] = editedComment;
                return false;
            }
            if (replyLevel === CommentLevel.Level2 && comment.replies?.length > 0) {
                comment.replies.every((reply, index) => {
                    if (reply.id === editedComment.id) {
                        comment.replies[index] = editedComment;
                        return false;
                    }
                    return true;
                });
            }
            return true;
        });
        return oldComments;
    };

    const editComment = useCallback(async (editedComment: CommentSummary, replyLevel: CommentLevel) => {
        if (editedComment.annotated || editedComment.privateComment) {
            eventDispatcher.dispatch(SUPER_COMMENT_EVENTS.SUPER_COMMENTS_RELOAD);
        }
        setCommentsList(prev => {
            return updateListCommentAfterEdit(prev, editedComment, replyLevel);
        });

        setPinnedCommentsList(prev => {
            return updateListCommentAfterEdit(prev, editedComment, replyLevel);
        });

        await updateEffectiveClassification(editedComment);
    }, [updateEffectiveClassification]);

    const deleteCommentFromList = (prev: CommentSummary[], commentId: number, commentLevel: CommentLevel) => {
        const oldComments = cloneDeep(prev);
        oldComments.every((comment, index) => {
            if (comment.id === commentId) {
                oldComments.splice(index, 1);
                return false;
            }

            if (commentLevel === CommentLevel.Level2 && comment.replies?.length > 0) {
                comment.replies.every((comment2, index) => {
                    if (comment2.id === commentId && comment.replies?.length > 0) {
                        comment.replies.splice(index, 1);
                        return false;
                    }
                    return true;
                });
            }

            if (commentLevel === CommentLevel.Level3 && comment?.replies.length > 0) {
                comment.replies.every((comment2) => {
                    let goToNextItem = true;
                    if (comment2.replies?.length > 0) {
                        comment2.replies.every((comment3, index) => {
                            if (comment3.id === commentId && comment2.replies?.length > 0) {
                                comment2.replies.splice(index, 1);
                                goToNextItem = false;
                                return false;
                            }
                            return true;
                        });
                    }
                    return goToNextItem;
                });
            }
            return true;
        });
        return oldComments;
    };

    const onDeleteComment = useCallback(async (comment: CommentSummary, commentLevel: CommentLevel) => {
        await deleteComment(comment.id);
        updateCommentsCount(comment.ideaId, 'REMOVE');

        if (comment.annotated) {
            eventDispatcher.dispatch(SUPER_COMMENT_EVENTS.SUPER_COMMENTS_RELOAD);
        }

        setCommentsList(prev => {
            return deleteCommentFromList(prev, comment.id, commentLevel);
        });

        setPinnedCommentsList(prev => {
            return deleteCommentFromList(prev, comment.id, commentLevel);
        });

        await updateEffectiveClassification(comment);
    }, [deleteComment, updateCommentsCount, updateEffectiveClassification]);

    const updatePinUnpinCommentInList = (prev: CommentSummary[], commentId: number, pinned: boolean) => {
        let updatedComments = cloneDeep(prev);
        updatedComments.every((comment) => {
            if (comment.id === commentId) {
                comment.pinned = pinned;
                return false;
            }
            return true;
        });
        return updatedComments;
    };

    const onPinUnpinComment = useCallback(async (commentId: number, pinned: boolean) => {
        if (pinned) {
            await pinComment(commentId);
        } else {
            await unpinComment(commentId);
        }
        await queryClient.invalidateQueries([QUERY_KEYS.COMMENT_PERMISSION, commentId]);

        setCommentsList(prev => {
            return updatePinUnpinCommentInList(prev, commentId, pinned);
        });
        let findPinnedComment: CommentSummary | undefined;
        setPinnedCommentsList(prev => {
            let updatedComments = cloneDeep(prev);
            if (updatedComments && updatedComments.length > 0) {
                updatedComments.every((comment, index) => {
                    if (comment.id === commentId && !pinned) {
                        updatedComments.splice(index, 1);
                        return false;
                    }
                    return true;
                });
            }
            if (pinned) {
                findPinnedComment = commentsList.find(comment => comment.id === commentId);
                if (findPinnedComment) {
                    findPinnedComment.pinned = pinned;
                    findPinnedComment.pinnedBy = actor;
                    updatedComments = [...updatedComments || [], findPinnedComment];
                }
            }
            return updatedComments;
        });

        findPinnedComment && scrollToComment(`${ID_PREFIXES.PINNED_COMMENT}-${findPinnedComment.id}`);
    }, [actor, commentsList, pinComment, queryClient, scrollToComment, unpinComment]);

    const updateCommentLabel = (prev: CommentSummary[], commentId: number, labels: LabelData[], labelId?: number, removeLabel: boolean = false) => {
        const oldComments = cloneDeep(prev);
        oldComments.every((comment, index, argsArray) => {
            let findComment = argsArray.find(item => item.id === commentId);
            if (findComment) {
                findComment.labels = removeLabel ? findComment.labels.filter(label => label.id !== labelId) : labels;
                return false;
            }

            if (comment.replies) {
                findComment = comment.replies.find(item => item.id === commentId);
                if (findComment) {
                    findComment.labels = removeLabel ? findComment.labels.filter(label => label.id !== labelId) : labels;
                    return false;
                }

                comment.replies.every((comment3) => {
                    if (comment3.replies) {
                        findComment = comment3.replies.find(item => item.id === commentId);
                        if (findComment) {
                            findComment.labels = removeLabel ? findComment.labels.filter(label => label.id !== labelId) : labels;
                            return false;
                        }
                    }
                    return true;
                });
            }
            return true;
        });

        return oldComments;
    };

    const onAddLabel = useCallback(async (commentId: number, labelData: LabelData) => {
        const response = await addCommentsLabel(commentId, labelData.id);
        if (response) {
            setCommentsList(prev => {
                return updateCommentLabel(prev, commentId, response);
            });

            setPinnedCommentsList(prev => {
                return updateCommentLabel(prev, commentId, response);
            });
        }
        return response;
    }, [addCommentsLabel]);

    const onRemoveLabel = useCallback(async (commentId: number, labelData: LabelData) => {
        await removeCommentsLabel(commentId, labelData.id);
        setCommentsList(prev => {
            return updateCommentLabel(prev, commentId, [], labelData.id, true);
        });

        setPinnedCommentsList(prev => {
            return updateCommentLabel(prev, commentId, [], labelData.id, true);
        });
    }, [removeCommentsLabel]);

    const updateVoteCount = (prev: CommentSummary[], commentId: number, commentVoteResponse: CommentVoteResponse) => {
        const oldComments = cloneDeep(prev);

        function updateVote(findComment: CommentSummary) {
            findComment.actorVoteValue = commentVoteResponse.actorVoteValue;
            findComment.netVoteCount = commentVoteResponse.netVoteCount;
            findComment.upVoteCount = commentVoteResponse.upVoteCount;
            findComment.downVoteCount = commentVoteResponse.downVoteCount;
        }

        oldComments.every((comment, index, argsArray) => {
            let findComment = argsArray.find(item => item.id === commentId);
            if (findComment) {
                updateVote(findComment);
                return false;
            }

            if (comment.replies) {
                findComment = comment.replies.find(item => item.id === commentId);
                if (findComment) {
                    updateVote(findComment);
                    return false;
                }

                comment.replies.every((comment3) => {
                    if (comment3.replies) {
                        findComment = comment3.replies.find(item => item.id === commentId);
                        if (findComment) {
                            updateVote(findComment);
                            return false;
                        }
                    }
                    return true;
                });
            }
            return true;
        });
        return oldComments;
    };

    const upDownVote = useCallback(async (comment: CommentSummary, upVote: boolean = true) => {
        try {
            const response = await commentUpDownVote(comment.id, {
                voteValue: upVote ? 1 : -1,
                previousVote: comment.actorVoteValue,
                previousTotalVoteUp: comment.upVoteCount,
                previousTotalVoteDown: comment.downVoteCount
            });

            if (response) {
                setCommentsList(prev => {
                    return updateVoteCount(prev, comment.id, response);
                });

                setPinnedCommentsList(prev => {
                    return updateVoteCount(prev, comment.id, response);
                });
            }
        } catch (error: any) {
            handleErrorResponse(error);
        }
    }, [commentUpDownVote, handleErrorResponse]);

    const retractVote = useCallback(async (comment: CommentSummary) => {
        try {
            const response = await commentRetractVote(comment.id);

            if (response) {
                setCommentsList(prev => {
                    return updateVoteCount(prev, comment.id, response);
                });

                setPinnedCommentsList(prev => {
                    return updateVoteCount(prev, comment.id, response);
                });
            }
        } catch (error: any) {
            handleErrorResponse(error);
        }
    }, [commentRetractVote, handleErrorResponse]);

    const onUpVote = useCallback(async (comment: CommentSummary) => {
        if (comment.actorVoteValue > 0) {
            await retractVote(comment);
        } else {
            await upDownVote(comment);
        }
    }, [retractVote, upDownVote]);

    const onDownVote = useCallback(async (comment: CommentSummary) => {
        if (comment.actorVoteValue < 0) {
            await retractVote(comment);
        } else {
            await upDownVote(comment, false);
        }
    }, [retractVote, upDownVote]);

    const updateComment = (prev: CommentSummary[], commentSummary: CommentSummary) => {
        const oldComments = cloneDeep(prev);
        oldComments.every((comment, index, argsArray) => {
            let findCommentIndex = argsArray.findIndex(item => item.id === commentSummary.id);
            if (findCommentIndex >= 0) {
                oldComments[findCommentIndex] = commentSummary;
                return false;
            }

            if (comment.replies) {
                const findCommentIndex = comment.replies.findIndex(item => item.id === commentSummary.id);
                if (findCommentIndex >= 0) {
                    comment.replies[findCommentIndex] = commentSummary;
                    return false;
                }

                comment.replies.every((comment3) => {
                    if (comment3.replies) {
                        const findCommentIndex = comment3.replies.findIndex(item => item.id === commentSummary.id);
                        if (findCommentIndex >= 0) {
                            comment3.replies[findCommentIndex] = commentSummary;
                            return false;
                        }
                    }
                    return true;
                });
            }
            return true;
        });
        return oldComments;
    };

    const removeCommentFromList = (prev: CommentSummary[], commentId: number) => {
        const oldComments = cloneDeep(prev);
        oldComments.every((comment, index, argsArray) => {
            let findCommentIndex = argsArray.findIndex(item => item.id === commentId);
            if (findCommentIndex >= 0) {
                argsArray.splice(findCommentIndex, 1);
                return false;
            }

            if (comment.replies) {
                findCommentIndex = comment.replies.findIndex(item => item.id === commentId);
                if (findCommentIndex >= 0) {
                    comment.replies.splice(findCommentIndex, 1);
                    return false;
                }

                comment.replies.every((comment3) => {
                    if (comment3.replies) {
                        findCommentIndex = comment3.replies.findIndex(item => item.id === commentId);
                        if (findCommentIndex >= 0) {
                            comment3.replies.splice(findCommentIndex, 1);
                            return false;
                        }
                    }
                    return true;
                });
            }
            return true;
        });
        return oldComments;
    };

    const onApproveComment = useCallback(async (comment: CommentSummary) => {
        const response = await commentApprove(comment.id);

        if (response && response.stage.toLowerCase() !== StageFunctionName.PENDING) {
            updateCommentsCount(response.ideaId, 'ADD');
        }

        if (response) {
            setCommentsList(prev => {
                return updateComment(prev, response);
            });

            setPinnedCommentsList(prev => {
                return updateComment(prev, response);
            });
        }
    }, [commentApprove, updateCommentsCount]);

    const onRejectComment = useCallback(async (comment: CommentSummary) => {
        const response = await commentReject(comment.id);
        if (response) {
            setCommentsList(prev => {
                return removeCommentFromList(prev, comment.id);
            });

            setPinnedCommentsList(prev => {
                return removeCommentFromList(prev, comment.id);
            });
        }
    }, [commentReject]);

    const onReportAbuse = useCallback(async (comment: CommentSummary) => {
        await reportAbuseComment(comment.id);
        updateCommentsCount(comment.ideaId, 'REMOVE');

        setCommentsList(prev => {
            return removeCommentFromList(prev, comment.id);
        });

        setPinnedCommentsList(prev => {
            return removeCommentFromList(prev, comment.id);
        });
    }, [reportAbuseComment, updateCommentsCount]);

    const updateCommentListAfterAnnotated = (prev: CommentSummary[], commentId: number, commentLevel: CommentLevel) => {
        const oldComments = cloneDeep(prev);
        oldComments.every((comment) => {
            if (comment.id === commentId) {
                comment.annotated = true;
                return false;
            }

            if (commentLevel === CommentLevel.Level2 && comment.replies?.length > 0) {
                comment.replies.every((comment2) => {
                    if (comment2.id === commentId && comment.replies?.length > 0) {
                        comment2.annotated = true;
                        return false;
                    }
                    return true;
                });
            }

            if (commentLevel === CommentLevel.Level3 && comment.replies?.length > 0) {
                comment.replies.every((comment2) => {
                    let goToNextItem = true;
                    if (comment2.replies?.length > 0) {
                        comment2.replies.every((comment3) => {
                            if (comment3.id === commentId && comment2.replies?.length > 0) {
                                comment3.annotated = true;
                                goToNextItem = false;
                                return false;
                            }
                            return true;
                        });
                    }
                    return goToNextItem;
                });
            }
            return true;
        });
        return oldComments;
    };

    const onAnnotateComment = useCallback(async (commentId: number, commentLevel: CommentLevel) => {
        const response = await createSuperComment(commentId, CommentType.COMMUNITY);
        if (response) {
            eventDispatcher.dispatch(SUPER_COMMENT_EVENTS.SUPER_COMMENTS_RELOAD);

            setCommentsList(prev => {
                return updateCommentListAfterAnnotated(prev, commentId, commentLevel);
            });

            setPinnedCommentsList(prev => {
                return updateCommentListAfterAnnotated(prev, commentId, commentLevel);
            });
        }
    }, [createSuperComment]);

    const updateUnAnnotateComment = (prev: CommentSummary[], commentId: number) => {
        const oldComments = cloneDeep(prev);
        oldComments.every((comment, index, argsArray) => {
            let findComment = argsArray.find(item => item.id === commentId);
            if (findComment) {
                findComment.annotated = false;
                return false;
            }

            if (comment.replies) {
                findComment = comment.replies.find(item => item.id === commentId);
                if (findComment) {
                    findComment.annotated = false;
                    return false;
                }

                comment.replies.every((comment3) => {
                    if (comment3.replies) {
                        findComment = comment3.replies.find(item => item.id === commentId);
                        if (findComment) {
                            findComment.annotated = false;
                            return false;
                        }
                    }
                    return true;
                });
            }
            return true;
        });

        return oldComments;
    };

    useEffect(() => {
        eventDispatcher.addListener(SUPER_COMMENT_EVENTS.SUPER_COMMENT_REMOVE, (eventData: { commentId: number, commentType: CommentType }) => {
            const {commentId} = eventData;
            setCommentsList(prev => {
                return updateUnAnnotateComment(prev, commentId);
            });

            setPinnedCommentsList(prev => {
                return updateUnAnnotateComment(prev, commentId);
            });
        });

        return () => {
            eventDispatcher.removeListener(SUPER_COMMENT_EVENTS.SUPER_COMMENT_REMOVE);
        };
    }, [fetchPaginatedCommentList]);

    useEffect(() => {
        pageRef.current = 0;
        setCommentsList([]);
        setPinnedCommentsList([]);
    }, [sortBy]);

    useEffect(() => {
        prevPageRef.current = -1;
        pageRef.current = 0;
        setHasPrevPageComments(false);
        setIsCommentLoading(true);
        setCommentsList([]);
        setPinnedCommentsList([]);
        fetchAllPinnedComments().then();
        fetchPaginatedComments(false, +params?.commentId!).then();
    }, [fetchAllPinnedComments, fetchPaginatedComments, params.commentId]);

    useEffect(() => {
        if (params.commentId && !commentHighlighted.current) {
            const findCommentId = findCommentIdById(commentsList, +params.commentId);

            if (findCommentId) {
                scrollToComment(`${ID_PREFIXES.COMMENT}-${findCommentId}`);
                commentHighlighted.current = true;
            }
        }
    }, [commentsList, findCommentIdById, params.commentId, scrollToComment]);

    return (
        <section className={className}>
            <ul id={ID_PREFIXES.COMMENT_LIST}
                className={`list-unstyled comment-list${pinnedCommentsList.length > 0 && ' pt-3'}`}>
                {
                    isPinnedCommentLoading
                        ? <ParagraphSkeleton rows={7}/>
                        : (
                            pinnedCommentsList.map(comment => (
                                <li key={comment.id}>
                                    <Comment
                                        className={`${comment.pinned ? 'pinned' : ''} ${comment.pinned && styles.pinned}`}
                                        localizer={localizer}
                                        comment={comment}
                                        pinned={comment.pinned}
                                        deleteAttachment={(commentSummary, attachmentId, commentLevel) => onDeleteAttachment(commentSummary, attachmentId, commentLevel)}
                                        onReplyComment={(comment, replyComment, commentLevel) => onReplyComment(comment, replyComment, commentLevel)}
                                        ideaId={ideaId}
                                        commentPermissions={commentPermissions}
                                        onAddFileAttachments={(commentSummary, attachments, commentLevel, attributes) => onAddAttachments(commentSummary, attachments, commentLevel, attributes)}
                                        editComment={editComment}
                                        deleteComment={onDeleteComment}
                                        pinUnpinComment={onPinUnpinComment}
                                        annotateComment={onAnnotateComment}
                                        addLabel={onAddLabel}
                                        removeLabel={onRemoveLabel}
                                        upVote={onUpVote}
                                        downVote={onDownVote}
                                        approveComment={onApproveComment}
                                        rejectComment={onRejectComment}
                                        giveKudo={commentKudosGiveMutation.mutateAsync}
                                        reportAbuse={onReportAbuse}
                                        idPrefix={ID_PREFIXES.PINNED_COMMENT}
                                        fetchOriginalComment={fetchOriginalComment}
                                        addAttachments={commentAttachments}
                                        uploadFile={uploadFile}
                                        unfurlUrl={unfurlUrl}
                                        fetchEditComment={fetchEditComment}
                                        classificationsMenuData={classificationsMenuData}
                                        richTextClassificationConfig={richTextClassificationConfig}
                                    />
                                </li>
                            ))
                        )
                }
            </ul>
            <ul id={ID_PREFIXES.NORMAL_COMMENT_LIST} className="list-unstyled comment-list">
                {
                    !isCommentLoading && hasPrevPageComments && (
                        <li>
                            <div className="mx-4 mb-2">
                                <button
                                    className="btn btn-link text-decoration-none d-flex hide-replies px-3 align-items-center mt-3"
                                    onClick={onPrevCommentsClick}
                                    disabled={isCommentLoading}>
                                    <span>{localizer.msg('common.load-prev')}</span>
                                    <Icon iconSpritePath={svgIconsPath} width={12} height={12}
                                          name="chevron-down"
                                          className="ms-1 fill-primary"/>
                                </button>
                            </div>
                        </li>
                    )
                }
                {
                    isCommentLoading
                        ? <div className="mt-5"><ParagraphSkeleton rows={7}/></div>
                        : (
                            commentsList.map(comment => (
                                <li key={comment.id}>
                                    <Comment
                                        localizer={localizer}
                                        comment={comment}
                                        deleteAttachment={(commentSummary, attachmentId, commentLevel) => onDeleteAttachment(commentSummary, attachmentId, commentLevel)}
                                        onReplyComment={(commentId, replyComment, commentLevel) => onReplyComment(commentId, replyComment, commentLevel)}
                                        ideaId={ideaId}
                                        commentPermissions={commentPermissions}
                                        onAddFileAttachments={(commentSummary, attachments, commentLevel, attributes) => onAddAttachments(commentSummary, attachments, commentLevel, attributes)}
                                        editComment={editComment}
                                        deleteComment={onDeleteComment}
                                        pinUnpinComment={onPinUnpinComment}
                                        annotateComment={onAnnotateComment}
                                        addLabel={onAddLabel}
                                        removeLabel={onRemoveLabel}
                                        upVote={onUpVote}
                                        downVote={onDownVote}
                                        approveComment={onApproveComment}
                                        rejectComment={onRejectComment}
                                        giveKudo={commentKudosGiveMutation.mutateAsync}
                                        reportAbuse={onReportAbuse}
                                        idPrefix={ID_PREFIXES.COMMENT}
                                        fetchOriginalComment={fetchOriginalComment}
                                        addAttachments={commentAttachments}
                                        uploadFile={uploadFile}
                                        unfurlUrl={unfurlUrl}
                                        fetchEditComment={fetchEditComment}
                                        classificationsMenuData={classificationsMenuData}
                                        richTextClassificationConfig={richTextClassificationConfig}
                                    />
                                </li>
                            ))
                        )
                }
                {
                    !isCommentLoading && hasNextPageComments && (
                        <li>
                            <div className="mx-4 mb-2">
                                <button
                                    className="btn btn-link text-decoration-none d-flex hide-replies px-3 align-items-center mt-3"
                                    onClick={onHasMoreCommentsClick}
                                    disabled={isCommentLoading}>
                                    <span>{localizer.msg('common.load-more')}</span>
                                    <Icon iconSpritePath={svgIconsPath} width={12} height={12}
                                          name="chevron-down"
                                          className="ms-1 fill-primary"/>
                                </button>
                            </div>
                        </li>
                    )
                }
            </ul>
            {
                commentPermissions.commentingAllowed
                    ? (
                        <CommentForm localizer={localizer} actor={actor} ideaId={ideaId} addNewComment={onAddNewComment}
                                     commentPermissions={commentPermissions}
                                     classificationsMenuData={classificationsMenuData}
                                     richTextClassificationConfig={richTextClassificationConfig}/>
                    )
                    : null
            }
        </section>
    );
};