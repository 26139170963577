import React, {Fragment, ReactNode, useCallback, useEffect, useRef} from 'react';
import {Button, Icon, Modal} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import './EditActionWrapper.scss';

type EditActionWrapperProps = {
    children: ReactNode;
    editAllowed: boolean;
    configurationChildren: ReactNode;
    modalOpen: boolean;
    modalToggle: () => void;
    hasError?: boolean;
    editBtnTitle?: string;
    modalTitle?: string;
    className?: string;
    editBtnTestElementId: string;
}

export const EditActionWrapper = (props: EditActionWrapperProps) => {
    const {
        children,
        editAllowed,
        configurationChildren,
        modalOpen,
        modalToggle,
        hasError = false,
        editBtnTitle = 'Edit',
        modalTitle = 'Configurations',
        className = 'd-inline-flex',
        editBtnTestElementId = ''
    } = props;

    const scrollTopRef = useRef(0);

    const onModalClosed = useCallback(() => {
        setTimeout(() => {
            if (document.documentElement.scrollTop !== scrollTopRef.current) {
                window.scrollTo({left: 0, top: scrollTopRef.current, behavior: 'smooth'});
            }
        }, 600);
    }, []);

    useEffect(() => {
        if (modalOpen) {
            scrollTopRef.current = document.documentElement.scrollTop;
        }
    }, [modalOpen]);

    return (
        <Fragment>
            <div className={`${className} position-relative edit-action-wrapper ${hasError ? 'has-error' : ''} ${editAllowed ? 'edit-mode-element align-items-center active' : ''}`}>
                {
                    editAllowed &&
                    <Button className="edit-mode-element position-absolute px-2 pt-1 pb-2"
                            color={`${hasError ? 'danger' : 'primary'}`}
                            title={editBtnTitle} onClick={modalToggle} data-test-element-id={editBtnTestElementId}>
                        <Icon iconSpritePath={svgIconPath} width={17} height={17} name="pencil"/>
                    </Button>
                }
                {children}
            </div>
            {
                editAllowed &&
                <Modal isOpen={modalOpen}
                       title={modalTitle}
                       onClosed={onModalClosed}
                       toggle={modalToggle}>
                    {configurationChildren}
                </Modal>
            }
        </Fragment>
    );
};
