export enum ActivityType {
    IDEAS = 'IDEAS',
    VOTES = 'VOTES',
    COMMENTS = 'COMMENTS',
    MODIFY = 'MODIFY',
    KUDOS = 'KUDOS',
    FOLLOWS = 'FOLLOWS',
    ASSESSMENT = 'ASSESSMENT',
    REVIEWSCALE = 'REVIEWSCALE',
    FUND = 'FUND',
    ESTIMATES = 'ESTIMATES',
    REFINEMENT = 'REFINEMENT',
    STAGE_CHANGE = 'STAGE_CHANGE',
    TAGS = 'TAGS',
    LABELS = 'LABELS',
    LINKS = 'LINKS',
    PRIVATE_ACTIONS = 'PRIVATE_ACTIONS'
}