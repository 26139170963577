import React, {Fragment} from 'react';
import {Button, Icon} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {Localizer} from '@ideascale/commons';
import 'components/landing-page/ConfigWrapper.scss';

type ConfigWrapperProps = {
    visible: boolean;
    showConfig: boolean;
    children: React.ReactNode;
    toggleVisibility?: () => Promise<void>;
    localizer: Localizer
    className?: string;
    componentClass?: string;
    onEditClicked?: () => void;
    onDeleteClicked?: () => void;
    showTranslationConfig?: boolean,
    onTranslationClicked?: () => void;
    onItemMouseOver?: () => void;
    onItemMouseOut?: () => void;
    showOutline?: boolean;
    accessibleName?: string;
}

export const ConfigWrapper = (props: ConfigWrapperProps) => {
    const {
        visible,
        toggleVisibility,
        showConfig,
        children,
        onEditClicked,
        className = '',
        componentClass = '',
        onDeleteClicked,
        showTranslationConfig,
        onTranslationClicked,
        onItemMouseOver,
        localizer,
        onItemMouseOut,
        showOutline = false,
        accessibleName = ''
    } = props;

    const renderConfigClassNames = () => {
        let className = '';
        if (!visible) {
            className += 'component-hidden ';
        }
        if (showConfig || showTranslationConfig) {
            className += 'show-editor ';
        }
        if (!visible && !showConfig && !showTranslationConfig) {
            className += 'd-none ';
        }
        return className;
    };

    return (
        <div
            className={`component edit-mode-element ${renderConfigClassNames()} ${className} ${showOutline ? 'show-outline' : ''}`}
            onMouseOver={onItemMouseOver} onMouseOut={onItemMouseOut}
            aria-disabled={!visible}>
            {accessibleName && showConfig && <span className="sr-only">{accessibleName}</span>}
            {
                (showConfig || showTranslationConfig) &&
                <div className="d-flex component-config justify-content-end mb-2">
                    {
                        showTranslationConfig && onTranslationClicked
                            ?
                            <Button className="pt-1 px-2 edit-mode-element config-button" type="button" color="primary"
                                    onClick={onTranslationClicked}
                                    title={localizer.msg('landing-page.components.common.translate')}>
                                <Icon iconSpritePath={svgIconPath} name="pencil" width={18} height={18}/>
                            </Button>
                            :
                            <Fragment>
                                {
                                    onEditClicked &&
                                    <Button className="pt-1 px-2 edit-mode-element config-button" type="button"
                                            color="primary" onClick={onEditClicked}
                                            data-test-element-id="btn-edit-component"
                                            title={localizer.msg('landing-page.components.common.edit')}>
                                        <Icon iconSpritePath={svgIconPath} name="pencil" width={18} height={18}/>
                                    </Button>
                                }
                                {
                                    toggleVisibility &&
                                    <Button className="pt-1 px-2 ms-2 edit-mode-element config-button" type="button"
                                            color="primary" onClick={toggleVisibility}
                                            data-test-element-id="btn-toggle-visibility"
                                            title={localizer.msg('landing-page.components.common.toggle-visibility')}>
                                        <Icon iconSpritePath={svgIconPath}
                                              name={`${visible ? 'eye-open' : 'eye-open-slash'}`}
                                              width={18}
                                              height={18}/>
                                    </Button>
                                }
                                {
                                    onDeleteClicked &&
                                    <Button className="pt-1 px-2 ms-2 edit-mode-element config-button" type="button"
                                            color="primary" data-test-element-id="btn-delete-component"
                                            title={localizer.msg('landing-page.components.common.delete')}
                                            onClick={onDeleteClicked}>
                                        <Icon iconSpritePath={svgIconPath} name="trash-can-open" width={18}
                                              height={18}/>
                                        <span className="sr-only">
                                            {localizer.msg('landing-page.components.common.delete')}
                                        </span>
                                    </Button>
                                }
                            </Fragment>
                    }
                </div>
            }
            <div
                className={`${showConfig || showTranslationConfig ? 'show-edit-border' : 'preview-config'} ${componentClass} component-body`}>
                {children}
            </div>
        </div>
    );
};