import {Command} from './Command';
import {CampaignHomeEditor} from './Editors/CampaignHomeEditor';
import {WidgetField} from 'models/edit-mode/WidgetField';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';
import {Operation} from 'models/edit-mode/Operation';
import {ChangeCampaignWidgetOperation} from 'models/edit-mode/ChangeCampaignWidgetOperation';

export class UpdateCampaignWidgetTwoCommand implements Command {
    private readonly campaignHomeEditor: CampaignHomeEditor;
    private readonly oldCampaignWidget: WidgetField;
    private readonly newCampaignWidget: WidgetField;
    private readonly name: CampaignOperationType;
    private readonly campaignId: number;

    constructor(campaignHomeEditor: CampaignHomeEditor, newWidgetField: WidgetField, campaignId: number) {
        this.campaignHomeEditor = campaignHomeEditor;
        this.oldCampaignWidget = this.campaignHomeEditor.campaignWidgetTwo;
        this.newCampaignWidget = newWidgetField;
        this.campaignId = campaignId;
        this.name = CampaignOperationType.CHANGE_CAMPAIGN_WIDGET_TWO;
    }

    execute(): void {
        this.campaignHomeEditor.campaignWidgetTwo = this.newCampaignWidget;
    }

    getType(): CampaignOperationType {
        return this.name;
    }

    getOperation(): Operation {
        return new ChangeCampaignWidgetOperation(
            this.name,
            this.campaignHomeEditor.campaignWidgetTwo.title,
            this.campaignHomeEditor.campaignWidgetTwo.content,
            this.campaignId,
            this.campaignHomeEditor.campaignWidgetTwo.key
        );
    }

    undo(): void {
        this.campaignHomeEditor.campaignWidgetTwo = this.oldCampaignWidget;
    }
}