import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import {CropperModal, Spinner} from '@ideascale/ui';
import loadingSvg from '@ideascale/ui/dist/assets/loading.svg';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {
    AiAssistance,
    AiImageResponse,
    AlertEvent,
    AlertType,
    buildAlertEventData,
    eventDispatcher,
    EXTERNAL_FILE_BASE_PATH,
    Localizer,
    useFileUploadHandlers,
    useFileUploadIntermediary,
    useToggle
} from '@ideascale/commons';
import {useFileUploadService} from 'hooks/useFileUploadService';
import {UploadedImage} from 'models/types/UploadedImage';
import {AI_IMAGES_NUMBER} from 'constants/AppConstants';
import {ImageUploadSource} from 'models/enums/ImageUploadSource';
import {FileUploadPreview} from './FileUploadPreview';

type AiFileUploadProps = {
    localizer: Localizer;
    avatar?: string;
    initialPrompt?: string;
    imageSize?: string;
    cropWidth?: number;
    cropHeight?: number;
    maxFileSizeLimit: number;
    uploadedType?: ImageUploadSource;
    altTextErrorReason?: string;
    previewMode?: 'full' | 'medium' | 'small';
    fetchAiAssistedImage: (prompt: string, size?: (string | undefined), numberOfImages?: (number | undefined)) => Promise<AiImageResponse>;
    onUploaded: (image: UploadedImage, type: ImageUploadSource) => void;
    updateFileUploadingStatus?: Dispatch<SetStateAction<boolean>>
}

export const AiFileUpload = (props: AiFileUploadProps) => {
    const {
        localizer,
        avatar,
        initialPrompt,
        imageSize,
        cropWidth,
        cropHeight,
        maxFileSizeLimit,
        uploadedType,
        altTextErrorReason,
        previewMode,
        fetchAiAssistedImage,
        onUploaded,
        updateFileUploadingStatus
    } = props;
    const [showCropper, toggleCropper] = useToggle(false);
    const [imageFile, setImageFile] = useState<File>();
    const [previewImage, setPreviewImage] = useState<string | null>(null);
    const [altText, setAltText] = useState('');
    const [altTextError, setAltTextError] = useState<string | null>(null);
    const [uploadedImage, setUploadedImage] = useState<UploadedImage>(UploadedImage.EMPTY);
    const [loading, setLoading] = useState(false);

    const {uploadImage} = useFileUploadService();
    const {onUploadFile, fileUploading} = useFileUploadIntermediary(uploadImage);
    const {onInputFileChange} = useFileUploadHandlers({
        localizer,
        onUploadSuccess: (fileName, previewImageUrl) => {
            setPreviewImage(previewImageUrl);
            const uploadedImageFile = new UploadedImage(0, previewImageUrl, fileName, altText);
            setUploadedImage(uploadedImageFile);
            onUploaded(uploadedImageFile, ImageUploadSource.AI);
        },
        uploadFile: onUploadFile,
        maxFileSizeInMb: maxFileSizeLimit,
    });

    const onAiImageSelected = useCallback((imageUrl: string) => {
        const base64Url = window.btoa(imageUrl);
        const urlEncode = encodeURIComponent(base64Url);
        const requestUrl = `${EXTERNAL_FILE_BASE_PATH}?url=${urlEncode}`;
        setLoading(true);
        fetch(requestUrl)
            .then(response => response.blob())
            .then(data => {
                setLoading(false);
                if (maxFileSizeLimit > 0 && data.size > maxFileSizeLimit * 1024 * 1024) {
                    eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.error, localizer.msg('common.form.attachment.upload.error.size-limit', {size: maxFileSizeLimit})));
                    return;
                }
                setImageFile(new File([data], 'AI.' + data.type.split('/')[1], {type: data.type}));
                toggleCropper(true);
            });
    }, [localizer, maxFileSizeLimit, toggleCropper]);

    const onPreviewRemoved = useCallback(() => {
        setPreviewImage(null);
        onUploaded(UploadedImage.EMPTY, ImageUploadSource.AI);
    }, [onUploaded]);

    const onAltTextChanged = useCallback((text?: string) => {
        onUploaded?.({...uploadedImage, altText: text?.trim()}, ImageUploadSource.AI);
    }, [onUploaded, uploadedImage]);

    useEffect(() => {
        if (altTextErrorReason) {
            setAltTextError(localizer.msg(`frontend-shared.errors.${altTextErrorReason.toLowerCase()}`));
        }
    }, [altTextErrorReason, localizer]);

    useEffect(() => {
        if(updateFileUploadingStatus) {
            updateFileUploadingStatus(loading || fileUploading);
        }
    }, [fileUploading, loading, updateFileUploadingStatus])

    return (
        <div>
            <AiAssistance className="mt-2"
                          type="image"
                          disabled={loading}
                          size={imageSize}
                          numberOfImages={AI_IMAGES_NUMBER}
                          localizer={localizer}
                          title={localizer.msg('ai-assistance.images-label')}
                          userAvatar={avatar}
                          getInitialPrompt={initialPrompt ? (() => initialPrompt) : undefined}
                          fetchAiAssistedImages={fetchAiAssistedImage}
                          onAiResponseUsed={onAiImageSelected}/>
            {
                loading &&
                <div className="mt-4 w-100 d-flex align-items-center justify-content-center">
                    <Spinner src={loadingSvg} size={50}/>
                </div>
            }
            {
                previewImage && uploadedType === ImageUploadSource.AI && (
                    <div className="file-upload-wrapper">
                        <FileUploadPreview localizer={localizer}
                                           previewMode={previewMode}
                                           showAltText={true}
                                           previewImage={previewImage}
                                           altText={altText}
                                           setAltText={setAltText}
                                           altTextError={altTextError}
                                           setAltTextError={setAltTextError}
                                           onPreviewRemoved={onPreviewRemoved}
                                           onAltTextChanged={onAltTextChanged}/>
                    </div>
                )
            }
            {
                showCropper &&
                <CropperModal
                    isOpen={showCropper}
                    toggle={toggleCropper}
                    title={localizer.msg('frontend-shared.common.adjust-image')}
                    saveButtonText={localizer.msg('frontend-shared.common.save-changes')}
                    closeButtonText={localizer.msg('frontend-shared.common.close')}
                    file={imageFile}
                    handleUpload={onInputFileChange}
                    svgIconPath={svgIconPath}
                    loadingSvg={loadingSvg}
                    autoCropArea={1}
                    cropHeight={cropHeight}
                    cropWidth={cropWidth}/>
            }
        </div>
    );
};

