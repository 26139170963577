import React from 'react';
import {Button, Icon} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {eventDispatcher} from '@ideascale/commons';
import {TOGGLE_SIDEBAR} from 'constants/AppConstants';

type SidebarToggleButtonProps = {
    className?: string;
}

export const SidebarToggleButton = (props: SidebarToggleButtonProps) => {
    const {className = ''} = props;

    const onClick = (e: React.MouseEvent) => {
        eventDispatcher.dispatch(TOGGLE_SIDEBAR);
    };

    return (
        <Button
            id="sidebar-toggle-button"
            color="default"
            title="LayoutSidebarContainer"
            className={`${className ? className : ''} d-block d-lg-none p-0`}
            onClick={onClick}>
            <span className="pointer-events-none">
                 <Icon iconSpritePath={svgIconsPath}
                       name="chevron-left-page-square-outlined-rounded"
                       width={28}
                       height={36}/>
            </span>
        </Button>
    );
};
