import {ImageryOperation} from './ImageryOperation';
import {CampaignOperationType} from './CampaignOperationType';
import {ImageType} from '../enums/ImageType';

export class ChangeCampaignFeaturedImageOperation extends ImageryOperation {
    constructor(
        public type: CampaignOperationType,
        public fileId: number,
        public gradientEnabled: boolean,
        public blackPageTitleEnabled: boolean,
        public imageType: ImageType | null,
        public campaignId: number,
    ) {
        super(type, fileId);
    }
}