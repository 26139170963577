import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import debounce from 'lodash/debounce';
import {Icon, Scrollbar, ScrollbarInstance} from '@ideascale/ui';
import {
    ClassificationLabel,
    InfiniteScrollLoadMoreButton,
    LayoutUtil,
    Localizer,
    ParagraphSkeleton,
    StringUtil,
    useApiErrorResponseHandler,
    useApplicableClassifications,
    useIntersectionObserver
} from '@ideascale/commons';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useSearchContext} from 'contexts/SearchContext';
import {appLinks} from 'services/AppLinks';
import {CampaignSearchParameters} from 'models/types/CampaignSearchParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {CampaignSearchItem} from 'models/CampaignSearchItem';
import {useAppContext} from 'contexts/AppContext';

type CampaignSearchResultsProps = {
    localizer: Localizer;
    searchCampaigns: (params: CampaignSearchParameters) => Promise<PagedResponseContent<CampaignSearchItem>>;
}

const DEFAULT_STATE = {campaigns: [], total: 0};

export const CampaignSearchResults = (props: CampaignSearchResultsProps) => {
    const {localizer, searchCampaigns} = props;
    const {communityConfig} = useAppContext();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const {term, getSearchParams} = useSearchContext();
    const scrollbarContainer = useRef<ScrollbarInstance>(null);
    const loadMoreButtonRef = useRef<HTMLButtonElement>(null);
    const pageNo = useRef(0);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<{ campaigns: CampaignSearchItem[], total: number }>(DEFAULT_STATE);
    const [hasMore, setHasMore] = useState(false);
    const {getClassificationAttribute} = useApplicableClassifications('', []);

    const loadCampaigns = useCallback(async () => {
        try {
            const response = await searchCampaigns({
                ...getSearchParams,
                pageParameters: {page: pageNo.current, limit: 20}
            });
            if (response.pageNo === 0) {
                pageNo.current = 0;
                setData({campaigns: response.content, total: response.totalElements});
            } else {
                setData(prev => ({campaigns: prev.campaigns.concat(response.content), total: response.totalElements}));
            }
            pageNo.current = response.hasMore ? pageNo.current + 1 : pageNo.current;
            setHasMore(response.hasMore);
        } catch (error: any) {
            handleErrorResponse(error);
        } finally {
            setLoading(false);
        }
    }, [searchCampaigns, getSearchParams, handleErrorResponse]);

    useIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: loadCampaigns,
        enabled: hasMore,
        options: {
            root: scrollbarContainer.current?.container
        }
    });

    useEffect(() => {
        pageNo.current = 0;
        setLoading(false);
        setHasMore(false);
    }, [getSearchParams]);

    useEffect(() => {
        let debounceLoadCampaigns: any = undefined;
        if (pageNo.current === 0) {
            debounceLoadCampaigns = debounce(loadCampaigns, 300);
        }
        debounceLoadCampaigns && debounceLoadCampaigns();
        return () => {
            debounceLoadCampaigns && debounceLoadCampaigns.cancel();
        };
    }, [loadCampaigns, getSearchParams]);

    return (
        loading ? (
            <section className="mt-4">
                <div className="card panel panel-default">
                    <div className="card-body py-2">
                        <ParagraphSkeleton rows={5}/>
                    </div>
                </div>
            </section>
        ) : (
            <section className="mt-4" aria-labelledby="campaign-search-results">
                <h2 className="font-size-md fw-bold" id="campaign-search-results" data-test-element-id="search-campaign-count">
                    {
                        term
                            ? (
                                data.total === 0
                                    ? localizer.msg('search.campaign.no-results-with-term', {term: term})
                                    : localizer.msg('search.campaign.results', {count: data.total, term: term})
                            )
                            : (
                                data.total === 0
                                    ? localizer.msg('search.campaign.no-results')
                                    : localizer.msg('search.campaign.result-without-term', {count: data.total})
                            )
                    }
                </h2>
                {
                    data.campaigns.length > 0 &&
                    <div className="mt-3 card panel panel-default" data-test-element-id="campaign-search-list">
                        <div className="card-body pe-2">
                            <Scrollbar ref={scrollbarContainer}
                                       autoHeight
                                       autoHeightMax={350}>
                                <ul className={`list-unstyled row mb-0`}>
                                    {
                                        data.campaigns.map(campaign =>
                                            <li className="col-12 col-sm-6 col-md-4 d-flex align-items-center my-2"
                                                key={campaign.id}>
                                                <img className="rounded-circle" src={campaign.image}
                                                     alt={campaign.altText}
                                                     width={60} height={60}/>
                                                <div className="ms-4">
                                                    <Link className="font-size-lg fw-bold mb-0" to={appLinks.campaign(String(campaign.id))} title={campaign.name}>
                                                        {communityConfig.classificationEnabled && campaign.classificationSummary &&
                                                            <ClassificationLabel
                                                                classification={getClassificationAttribute(campaign.classificationSummary)}
                                                                extraClasses="me-1 align-text-top"/>
                                                        }
                                                        {StringUtil.textTruncate(campaign.name, LayoutUtil.isMobileLayout() ? 15 : 25)}
                                                        {
                                                            campaign.privateCampaign && (
                                                                <span className="private-campaign-icon-container">
                                                                    <Icon className="ms-2 mt-n1 active" name="lock-closed-rounded"
                                                                          iconSpritePath={svgIconsPath}
                                                                          width={16}
                                                                          height={16}/>
                                                                </span>
                                                            )
                                                        }
                                                    </Link>
                                                    {
                                                        campaign.subtitle &&
                                                        <p className="text-truncate text-muted font-size-sm mb-0"
                                                           title={campaign.subtitle}>
                                                            {StringUtil.textTruncate(campaign.subtitle, LayoutUtil.isMobileLayout() ? 25 : 40)}
                                                        </p>
                                                    }
                                                </div>
                                            </li>
                                        )
                                    }
                                </ul>
                                <InfiniteScrollLoadMoreButton hasMore={hasMore}
                                                              loading={loading}
                                                              localizer={localizer}
                                                              onCLick={loadCampaigns}
                                                              loadMoreButtonRef={loadMoreButtonRef}/>
                            </Scrollbar>
                        </div>
                    </div>
                }
            </section>
        )
    );
};