import React, {useCallback} from 'react';
import {appLinks} from 'services/AppLinks';
import {ActionItemHeader} from './ActionItemHeader';
import {GroupedActionItem} from 'models/topbar-action-item/GroupedActionItem';
import {ModeratorActionItem} from 'models/topbar-action-item/ModeratorActionItem';
import {ModeratorActionType} from 'models/topbar-action-item/ModeratorActionType';

type ModeratorActionItemsProps = {
    groupedActionItem: GroupedActionItem;
}

export const ModeratorActionItems = (props: ModeratorActionItemsProps) => {
    const {groupedActionItem} = props;

    const buildUrl = useCallback((actionItem: ModeratorActionItem) => {
        switch (actionItem.type) {
            case ModeratorActionType.IDEAS:
                return appLinks.moderateIdeas;
            case ModeratorActionType.COMMENTS:
                return appLinks.moderateComments;
            case ModeratorActionType.MEMBERS:
                return appLinks.moderateMembers;
            case ModeratorActionType.IDEAS_CUSTOM_FIELD:
                return appLinks.moderateIdeas;
            default:
                return '/';
        }
    }, []);

    return (
        <div className="action-item-container">
            <ActionItemHeader id="moderator-action-heading"
                              groupTitle={groupedActionItem.groupTitle}
                              seeAllLink={groupedActionItem.seeAllLink}/>
            <div className="divider"/>
            <ul className="action-item-list-container list-unstyled mx-3 mt-3"
                aria-labelledby="moderator-action-heading">
                {
                    (groupedActionItem.actionItems as ModeratorActionItem[]).map((actionItem: ModeratorActionItem) => (
                        <li key={actionItem.name}>
                            <a className="btn btn-link p-0 font-size-sm text-decoration-none"
                               aria-label={`${!!actionItem.count && actionItem.count} ${actionItem.name}`}
                               href={buildUrl(actionItem)}>
                                <span>{actionItem.name} {actionItem.count && `[ ${actionItem.count} ]`}</span>
                            </a>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
};
