import React from 'react';
import {useLocalizer} from 'hooks/useLocalizer';
import {TopbarCommonEditModeActions} from 'components/topbar/TopbarCommonEditModeActions';

type CampaignPageTopbarEditModeContainerProps = {
    onCancel: () => void;
    onPublish: () => void;
    publishDisabled: boolean;
}
export const CampaignPageTopbarEditModeContainer = (props: CampaignPageTopbarEditModeContainerProps) => {
    const {onCancel, onPublish, publishDisabled} = props;
    const localizer = useLocalizer();

    return (
        <div className="d-flex justify-content-end w-100">
            <TopbarCommonEditModeActions
                publishDisabled={publishDisabled}
                onPublish={onPublish}
                localizer={localizer}
                onCancel={onCancel}
            />
        </div>
    );
};

