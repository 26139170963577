import {BaseIdeaStageSummary} from './BaseIdeaStageSummary';
import {StageSummaryData} from './types/StageSummaryData';
import {FundUnit} from './enums/FundUnit';

export class FundStageSummary extends BaseIdeaStageSummary {
    constructor(
        public stage: StageSummaryData,
        public viewAllowed: boolean,
        public participationAllowed: boolean,
        public percentage: number,
        public callToActionLabel: string,
        public fundBalance: number,
        public fundUnit: FundUnit,
        public fundUnitKey: string,
        public fundPledgedByActor: boolean,
        public availableFundAccount: boolean
    ) {
        super(stage, viewAllowed, participationAllowed, percentage, callToActionLabel);
    }

    getIconName(): string {
        return 'money-bundle';
    }
}