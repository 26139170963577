import React, {useRef, useState} from 'react';
import {Controller, useForm, useWatch} from 'react-hook-form';
import {ActionButton, CommonUtil, HtmlConverter, Modal, NewRichTextEditor, RichTextEditorHandler} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {
    EXTERNAL_FILE_BASE_PATH,
    HookFormProvider,
    isHtmlInject,
    isImageLink,
    Localizer,
    UploadedResponse,
    UploadProgressCallback,
    useApiErrorResponseHandler
} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useFileUploadService} from 'hooks/useFileUploadService';
import {ImageOrLink} from 'components/landing-page/ImageOrLink';
import {InstructionListItem} from 'models/landing-page/InstructionListItem';
import {RichTextToolbarType} from 'models/enums/landing-page/RichTextToolbarType';
import {LANDING_PAGE_PLAIN_TEXT_MAX_CHAR_COUNT} from 'constants/AppConstants';

type EditInstructionListConfigModalProps = {
    open: boolean;
    toggle: () => void;
    config: InstructionListItem;
    updateItemConfig: (config: InstructionListItem) => Promise<void>;
    localizer: Localizer;
    imageCropWidth: number,
    imageCropHeight: number,
    onComponentEdited: () => void;
    uploadImage: (data: FormData, onUploadProgress: UploadProgressCallback) => Promise<UploadedResponse>;
    defaultImage?: string,
    previewMode?: 'full' | 'small' | 'medium';
}

type InstructionListItemFields = {
    uploadedImage: string,
    imageLink: string,
    altText: string,
    title: string,
    description: string,
};

export const EditInstructionListItemConfigModal = (props: EditInstructionListConfigModalProps) => {
    const {
        open,
        toggle,
        updateItemConfig,
        config,
        localizer,
        imageCropWidth,
        imageCropHeight,
        defaultImage,
        onComponentEdited,
        previewMode,
        uploadImage
    } = props;

    const richTextEditorNode = useRef<RichTextEditorHandler>(null);
    const {communityConfig: {offensiveEmojis, maxFileSizeLimit}} = useAppContext();
    const {tempImageUpload} = useFileUploadService();
    const methods = useForm<InstructionListItemFields>({
        defaultValues: {
            altText: config.altText,
            imageLink: isImageLink(config.url) ? atob(decodeURIComponent(config.url.split('?url=')[1])) : '',
            uploadedImage: isImageLink(config.url) ? '' : defaultImage ? defaultImage : config.url,
            description: config.description,
            title: config.title
        }
    });
    const {formState: {errors, isSubmitting}, register, control, setError, handleSubmit} = methods;
    const {imageLink, uploadedImage} = useWatch({control});
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [configUpdated, setConfigUpdated] = useState(false);
    const [imageUploading, setImageUploading] = useState(false);

    const onSubmitConfig = async (data: InstructionListItemFields) => {
        const newData = {...config};
        newData.title = data.title;
        newData.description = HtmlConverter.toServerHtmlFormat(data.description);
        newData.altText = data.altText.trim() || 'Instruction item';

        if (data.imageLink) {
            const newUrl = data.imageLink;
            new URL(newUrl);
            if (CommonUtil.isSameOrigin(window.location.href, newUrl)) {
                newData.url = newUrl;
            } else {
                const base64Url = btoa(newUrl);
                const urlEncode = encodeURIComponent(base64Url);
                newData.url = `${EXTERNAL_FILE_BASE_PATH}?url=${urlEncode}`;
            }
        } else if (data.uploadedImage) {
            newData.url = data.uploadedImage;
        }

        try {
            await updateItemConfig(newData);
            onComponentEdited();
            toggle();
        } catch (e) {
            handleErrorResponse(e, {setFormError: setError});
        }
    };

    return (
        <Modal isOpen={open} toggle={toggle} autoFocus={false} modalHeaderId="instruction-list-item-header"
               title={localizer.msg('landing-page.components.edit.instruction-list.edit-item-modal-title')}>
            <HookFormProvider {...methods}>
                <form id={`instruction-item-form-${config.id}`} onSubmit={handleSubmit(onSubmitConfig)}>
                    <div className={`form-group ${errors.title ? 'has-error' : ''}`}>
                        <label htmlFor="title" className="control-label fw-bold">
                            {localizer.msg('landing-page.components.common.title')}
                            <span className="font-size-lg" aria-hidden={true}>*</span>
                        </label>
                        <input className="form-control" type="text" id="title" autoFocus
                               aria-required="true"
                               {...register('title', {
                                   onChange: () => setConfigUpdated(prev => !prev ? true : prev),
                                   required: localizer.msg('common.errors.required'),
                                   validate: value => !isHtmlInject(value) || localizer.msg('common.errors.html'),
                                   maxLength: {
                                       value: LANDING_PAGE_PLAIN_TEXT_MAX_CHAR_COUNT,
                                       message: localizer.msg('common.errors.max-length', {count: LANDING_PAGE_PLAIN_TEXT_MAX_CHAR_COUNT}),
                                   },
                                   setValueAs: value => value.trim()
                               })}/>
                        {
                            errors.title &&
                            <div className="invalid-feedback d-block">
                                {errors.title.message}
                            </div>
                        }
                    </div>
                    <div
                        className={`form-group ${errors.description ? 'has-error' : ''}`}>
                        <label className="control-label fw-bold" htmlFor="description"
                               onClick={() => richTextEditorNode.current?.focus()}>
                            {localizer.msg('landing-page.components.common.description')}
                            <span className="font-size-lg" aria-hidden={true}>*</span>
                            <span className="sr-only">{localizer.msg('common.form.required')}</span>
                        </label>
                        <div
                            className={`form-group ${errors.description ? 'has-error' : ''}`}>
                            <Controller
                                name="description"
                                control={control}
                                rules={{
                                    required: localizer.msg('common.errors.required')
                                }}
                                render={({field: {onChange}}) =>
                                    <NewRichTextEditor
                                        id="description"
                                        toolbar={RichTextToolbarType.MINIMAL}
                                        className="form-control"
                                        placeholder={localizer.msg('landing-page.components.edit.add-description')}
                                        svgIconPath={svgIconPath}
                                        defaultValue={config.description}
                                        onChange={(value, delta, source, editor) => {
                                            setConfigUpdated(prev => !prev ? true : prev);
                                            onChange(value, delta, source, editor);
                                        }}
                                        ref={richTextEditorNode}
                                        enableEmojiPicker={false}
                                        offensiveEmojis={offensiveEmojis}
                                        enableAtMention={false}
                                        uploadImage={tempImageUpload}
                                        maxFileSize={maxFileSizeLimit}
                                    />
                                }
                            />
                            {
                                errors.description && (
                                    <p className="invalid-feedback d-block" data-role="field-error" aria-live="polite">
                                        {errors.description.message}
                                    </p>
                                )
                            }
                        </div>
                    </div>

                    <ImageOrLink localizer={localizer}
                                 previewMode={previewMode}
                                 setImageUploading={setImageUploading}
                                 uploadImage={uploadImage}
                                 configUpdated={configUpdated}
                                 cropWidth={imageCropWidth}
                                 cropHeight={imageCropHeight}
                                 onConfigUpdated={() => setConfigUpdated(prev => !prev ? true : prev)}
                                 maxFileSizeLimit={maxFileSizeLimit}/>

                    <div className="form-group mt-5 text-end">
                        <button className="btn btn-cancel me-3" type="button" onClick={toggle}
                                data-test-element-id="btn-cancel">
                            {localizer.msg('common.actions.cancel')}
                        </button>
                        <ActionButton type="submit" onSubmit={handleSubmit(onSubmitConfig)}
                                      loading={isSubmitting || imageUploading}
                                      disabled={!configUpdated || (!imageLink && !uploadedImage)}
                                      data-test-element-id="btn-submit">
                            {localizer.msg('common.actions.submit')}
                        </ActionButton>
                    </div>
                </form>
            </HookFormProvider>
        </Modal>
    );
};