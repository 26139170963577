import React, {Dispatch, SetStateAction, useCallback, useRef, useState} from 'react';
import {CommonUtil} from '@ideascale/ui';
import {CommandExecutor} from 'commands/edit-mode/CommandExecutor';
import {CommunityHomeEditor} from 'commands/edit-mode/Editors/CommunityHomeEditor';
import {CampaignHomeEditor} from 'commands/edit-mode/Editors/CampaignHomeEditor';
import {Command} from 'commands/edit-mode/Command';
import {HomeConfigHolder} from 'models/edit-mode/HomeConfigHolder';
import {Operation} from 'models/edit-mode/Operation';
import {EditModeValidationErrors} from 'models/edit-mode/EditModeValidationErrors';
import {EditModeUtil} from 'utils/EditModeUtil';
import {TABINDEX_SET_DELAY} from 'constants/AppConstants';

export type EditModeContextState = {
    homeConfig: HomeConfigHolder;
    setHomeConfig: Dispatch<SetStateAction<HomeConfigHolder>>;
    communityHomeEditor: CommunityHomeEditor;
    campaignHomeEditor: CampaignHomeEditor;
    commandExecutor: CommandExecutor;
    getEditOperationData: () => Operation[];
    publishable: boolean;
    setPublishable: Dispatch<SetStateAction<boolean>>;
    resetEditMode: () => void;
    validationErrors: EditModeValidationErrors;
    setValidationErrors: Dispatch<SetStateAction<EditModeValidationErrors>>;
}

const contextDefaultValue: EditModeContextState = {
    homeConfig: HomeConfigHolder.EMPTY,
    setHomeConfig: () => null,
    communityHomeEditor: CommunityHomeEditor.EMPTY,
    campaignHomeEditor: CampaignHomeEditor.EMPTY,
    commandExecutor: CommandExecutor.EMPTY,
    getEditOperationData: () => [],
    publishable: false,
    setPublishable: () => null,
    resetEditMode: () => null,
    validationErrors: EditModeValidationErrors.EMPTY,
    setValidationErrors: () => null,
};

const editModeContext = React.createContext<EditModeContextState>(contextDefaultValue);

export type EditModeContextType = {
    children: React.ReactNode;
}

export const useEditModeContext = () => {
    return React.useContext<EditModeContextState>(editModeContext);
};

export const EditModeContextProvider = ({children}: EditModeContextType) => {
    const commandExecutorRef = useRef<CommandExecutor>(CommandExecutor.EMPTY);
    const communityHomeEditorRef = useRef<CommunityHomeEditor>(CommunityHomeEditor.EMPTY);
    const campaignHomeEditorRef = useRef<CampaignHomeEditor>(CampaignHomeEditor.EMPTY);

    const [homeConfig, setHomeConfig] = useState(HomeConfigHolder.EMPTY);
    const [publishable, setPublishable] = useState(false);

    const [validationErrors, setValidationErrors] = useState<EditModeValidationErrors>(EditModeValidationErrors.EMPTY);

    communityHomeEditorRef.current = new CommunityHomeEditor(homeConfig, {
        applyChange: (newHomeConfig: HomeConfigHolder) => {
            setHomeConfig(newHomeConfig);
        }
    });

    campaignHomeEditorRef.current = new CampaignHomeEditor(homeConfig, {
        applyChange: (newHomeConfig: HomeConfigHolder) => {
            setHomeConfig(newHomeConfig);
        }
    });

    const getCommandExecutor = useCallback(() => {
        return commandExecutorRef.current;
    }, []);

    getCommandExecutor().onExecute((executedCommands: Command[]) => {
        if (executedCommands.length > 0) {
            setPublishable(true);
            CommonUtil.wait(TABINDEX_SET_DELAY).then(() => {
                EditModeUtil.setTabIndexOnlyForEditModeElements();
            });
        }
    });

    const getEditOperationData = useCallback(() => {
        const operations: Operation[] = [];
        getCommandExecutor().getLastCommands().forEach(command => operations.push(command.getOperation()));
        return operations;
    }, [getCommandExecutor]);

    const resetEditMode = useCallback(() => {
        commandExecutorRef.current.reset();
        setHomeConfig(HomeConfigHolder.EMPTY);
    }, []);

    return (
        <editModeContext.Provider value={{
            homeConfig,
            setHomeConfig,
            communityHomeEditor: communityHomeEditorRef.current,
            campaignHomeEditor: campaignHomeEditorRef.current,
            commandExecutor: commandExecutorRef.current,
            getEditOperationData,
            publishable,
            setPublishable,
            resetEditMode,
            validationErrors,
            setValidationErrors,
        }}>
            {children}
        </editModeContext.Provider>
    );
};
