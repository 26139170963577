import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {AlertEvent, AlertType, BodyTagAttributes, buildAlertEventData, eventDispatcher} from '@ideascale/commons';
import {PAGE_IDS, SHOW_JOIN_COMMUNITY_PROMPT} from 'constants/AppConstants';
import {useLocalizer} from 'hooks/useLocalizer';
import {useAppContext} from 'contexts/AppContext';
import {appLinks} from 'services/AppLinks';

export const PrivateCommunityJoinPage = () => {
    const {authentication} = useAppContext();
    const localizer = useLocalizer();
    const navigate = useNavigate();

    useEffect(() => {
        if (authentication.community.isNotPublicCommunity() && authentication.isJoinCommunityAvailable()) {
            eventDispatcher.dispatch(SHOW_JOIN_COMMUNITY_PROMPT);
        } else if (authentication.actor.hasPendingMembership()) {
            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.warn, localizer.msg('frontend-shared.errors.member_awaiting_approval')));
        } else {
            navigate(appLinks.home(), {replace: true});
        }
    }, [authentication, navigate, localizer]);

    return (
        <>
            <BodyTagAttributes data-test-element-id={PAGE_IDS.JOIN_COMMUNITY}/>
            <div className="private-community-join container d-flex align-items-center justify-content-center">
                {
                    authentication.actor.hasPendingMembership()
                        ? (
                            <div className="p-2">
                                <strong className="sr-only">Awaiting Approval Message</strong>
                                <span className="font-size-lg">{localizer.msg('join-community.member-awaiting-approval')}</span>
                            </div>
                        )
                        : null
                }
            </div>
        </>
    );
};