import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Form} from 'reactstrap';
import {Controller, useForm} from 'react-hook-form';
import {useQuery} from 'react-query';
import {
    ActionButton,
    Button,
    Checkbox,
    HtmlConverter,
    NewRichTextEditor,
    RichTextClassificationConfig,
    RichTextEditorHandler
} from '@ideascale/ui';
import {
    ClassificationsHolder,
    ClassificationTreeMenu,
    ClassificationUtil,
    EMBEDDED_FILE_NAME_PREFIX,
    LabelData,
    LabelsAction,
    Localizer,
    RenderFormat,
    useApiErrorResponseHandler,
    useAtMentionConfiguration,
    useHandleFormSubmit
} from '@ideascale/commons';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useAppContext} from 'contexts/AppContext';
import {useCommentService} from 'hooks/useCommentService';
import {DEFAULT_STALE_TIME, QUERY_KEYS} from 'constants/AppConstants';
import {useFileUploadService} from 'hooks/useFileUploadService';
import {CommentFormInputs} from 'models/comments/CommentFormInputs';
import {ContributionType} from 'models/comments/ContributionType';
import {CommentSummary} from 'models/comments/CommentSummary';
import {CommentLabelsResponse} from 'models/comments/CommentLabelsResponse';
import styles from './CommentForm.module.scss';

type EditFormProps = {
    id: string;
    parentId: number;
    localizer: Localizer;
    ideaId: number;
    comment: CommentSummary;
    onEditComment: (comment: CommentSummary) => void;
    onEditCancel: () => void;
    fetchEditComment: (commentId: number) => Promise<CommentSummary>;
    classificationsMenuData?: ClassificationsHolder | {};
    richTextClassificationConfig?: RichTextClassificationConfig;
}

export const EditForm = (props: EditFormProps) => {
    const {
        id,
        localizer,
        ideaId,
        comment,
        onEditComment,
        onEditCancel,
        parentId,
        fetchEditComment,
        classificationsMenuData = {},
        richTextClassificationConfig
    } = props;
    const {communityConfig: {atMentionEnabled, offensiveEmojis, classificationEnabled}} = useAppContext();
    const {
        fetchAtMentionMembers,
        fetchCommentPermissions,
        commentModification,
        fetchCommentLabelsById
    } = useCommentService();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const {tempImageUpload} = useFileUploadService();
    const {currentCampaign, communityConfig} = useAppContext();
    const [selectedLabelIds, setSelectedLabelIds] = useState<number[]>([]);
    const richTextEditorNode = useRef<RichTextEditorHandler>(null);
    const [editComment, setEditComment] = useState<CommentSummary>(CommentSummary.EMPTY);

    const {data: commentPermissions} = useQuery([QUERY_KEYS.COMMENT_PERMISSION, parentId], () => fetchCommentPermissions(parentId), {
        staleTime: DEFAULT_STALE_TIME
    });

    const fetchAtMentionData = useAtMentionConfiguration(
        atMentionEnabled,
        fetchAtMentionMembers,
        {
            campaignId: currentCampaign?.id ?? 0,
            ideaId,
        }
    );

    useEffect(() => {
        (async () => {
            const response = await fetchEditComment(comment.id);
            if (response) {
                setEditComment(response);
                richTextEditorNode.current?.setHtml(HtmlConverter.toRenderHtmlFormat(response.text));
                richTextEditorNode.current?.focus();
            }
        })();
    }, [comment.id, fetchEditComment]);

    const {
        register,
        handleSubmit,
        control,
        reset,
        setError,
        clearErrors,
        formState: {errors, isSubmitting}
    } = useForm<CommentFormInputs>({
        defaultValues: {
            parentId: 0,
            parentType: ContributionType.comment,
            text: '',
            privateComment: false,
            addTracking: commentPermissions?.trackingIdea ?? false,
            labelIds: [],
            hideCommentAuthor: comment.hideAuthor,
            renderFormat: RenderFormat.HTML,
        },
        mode: 'onSubmit',
        reValidateMode: 'onSubmit'
    });
    const onSubmit = useHandleFormSubmit(handleSubmit);
    const onFormSubmit = async (formInputs: CommentFormInputs) => {
        try {
            const response = await commentModification(editComment.id, {
                ...formInputs,
                text: HtmlConverter.toServerHtmlFormat(formInputs.text),
                parentId: parentId,
                labelIds: selectedLabelIds,
                renderFormat: editComment.renderFormat,
            });
            if (response) {
                onEditComment(response);
                setSelectedLabelIds([]);
                reset({
                    parentId: 0,
                    parentType: ContributionType.idea,
                    text: '',
                    privateComment: false,
                    addTracking: commentPermissions?.trackingIdea ?? false,
                    labelIds: [],
                    hideCommentAuthor: false
                }, {
                    keepDefaultValues: true,
                    keepIsSubmitted: false
                });
                richTextEditorNode.current?.setTextWithBlur('');
            }
        } catch (error: any) {
            handleErrorResponse(error, {setFormError: setError});
        }
    };

    const addLabel = useCallback(async (labelData: LabelData) => {
        setSelectedLabelIds(prevState => [...prevState, labelData.id]);
    }, []);

    const removeLabel = useCallback(async (labelData: LabelData) => {
        setSelectedLabelIds(prevState => prevState.filter(id => id !== labelData.id));
    }, []);

    const fetchLabelsById = useCallback(async (commentId: number) => {
        const response: CommentLabelsResponse = await fetchCommentLabelsById(commentId);
        if (response) {
            return Promise.resolve(response.labels);
        }
        return [];
    }, [fetchCommentLabelsById]);

    useEffect(() => {
        (async () => {
            if (editComment?.id > 0) {
                const labelsResponse = await fetchLabelsById(editComment.id);
                if (labelsResponse) {
                    const selectedIds = labelsResponse.filter(label => label.applied).map(item => item.id);
                    setSelectedLabelIds(selectedIds);
                }
            }
        })();
    }, [editComment.id, fetchLabelsById]);

    return (
        <div id={id} className={`${styles.commentForm} d-flex my-n4`}>
            <div className="flex-grow-1">
                <Form id={`COMMENT_EDIT_FORM_ID-${id}`} onSubmit={onSubmit(onFormSubmit, isSubmitting)}>
                    <div className={`form-group ${errors.text ? 'has-error' : ''}`}>
                        <Controller
                            name="text"
                            control={control}
                            rules={{
                                required: localizer.msg('comments.comment-form.idea-text-required'),
                                ...(classificationEnabled && {validate: () => !richTextEditorNode.current?.hasInvalidClassifications() || localizer.msg('frontend-shared.classification.invalid-classifications-msg')})
                            }}
                            render={({field: {onChange}}) =>
                                <>
                                    {
                                        classificationEnabled
                                            ? (
                                                <ClassificationTreeMenu
                                                    localizer={localizer}
                                                    id={`edit-comment-classification-menu-${id}`}
                                                    classifications={classificationsMenuData || {}}
                                                    onApplyClassifications={value => {
                                                        richTextEditorNode.current?.insertClassification(ClassificationUtil.getLabel(value));
                                                    }}/>
                                            )
                                            : null
                                    }
                                    <NewRichTextEditor
                                        toolbar={'standard'}
                                        className={'form-control'}
                                        placeholder={atMentionEnabled ? localizer.msg('idea.comment.form.placeholder-with-at-mention') : localizer.msg('idea.comment.form.placeholder')}
                                        svgIconPath={svgIconsPath}
                                        onChange={value => {
                                            clearErrors('text');
                                            onChange(value);
                                        }}
                                        ref={richTextEditorNode}
                                        enableEmojiPicker={true}
                                        offensiveEmojis={offensiveEmojis}
                                        enableAtMention={atMentionEnabled}
                                        fetchMentionUsers={fetchAtMentionData}
                                        uploadImage={tempImageUpload}
                                        maxFileSize={communityConfig.maxFileSizeLimit}
                                        classificationConfig={richTextClassificationConfig}
                                        defaultFileNamePrefix={EMBEDDED_FILE_NAME_PREFIX.COMMENT}
                                    />
                                </>
                            }
                        />
                        {
                            errors.text && (
                                <p className="invalid-feedback d-block" data-role="field-error" aria-live="polite">
                                    {errors.text.message}
                                </p>
                            )
                        }
                    </div>

                    {
                        commentPermissions?.privateSubmissionAllowed && (
                            <div className="form-group">
                                <Checkbox className="fw-bold"
                                          inputId={`private-comment-field-edit-${parentId}-${editComment.id}`}
                                          defaultChecked={comment.privateComment}
                                          label={localizer.msg('comments.comment-form.idea-comment-private')}
                                          disabled={comment.privateComment}
                                          {...register('privateComment')}/>
                            </div>
                        )
                    }

                    {
                        commentPermissions?.anonymousSubmissionAllowed && (
                            <div className="form-group">
                                <Checkbox className="fw-bold"
                                          inputId={`hide-comment-author-field-edit-${parentId}-${editComment.id}}`}
                                          defaultChecked={comment.hideAuthor}
                                          label={localizer.msg('comments.comment-form.submit-comment-anonymously')}
                                          {...register('hideCommentAuthor')}/>

                            </div>
                        )
                    }

                    {
                        commentPermissions?.emailNotificationsAllowed && (
                            <div className="form-group">
                                <Checkbox className="fw-bold"
                                          inputId={`notify-via-mail-field-edit-${parentId}-${editComment.id}`}
                                          label={localizer.msg('comments.comment-form.idea-comment-post-notify')}
                                          defaultChecked={commentPermissions.trackingIdea}
                                          {...register('addTracking')}/>
                            </div>
                        )
                    }

                    <div className="form-group mb-0 d-flex">
                        {
                            commentPermissions?.labelsApplicable && (
                                <LabelsAction
                                    localizer={localizer}
                                    fetchLabels={fetchLabelsById}
                                    targetId={editComment.id}
                                    addLabel={addLabel}
                                    svgIconPath={svgIconsPath}
                                    removeLabel={removeLabel} toggleButtonTag="button"
                                    selectedLabelIds={selectedLabelIds}
                                    toggleButtonClass={`btn-label bg-transparent px-0 ${selectedLabelIds.length > 0 ? 'selected' : ''}`}/>
                            )
                        }
                        <Button color="cancel" disabled={isSubmitting} className="ms-auto" onClick={onEditCancel}>
                            {localizer.msg('common.cancel')}
                        </Button>
                        <ActionButton className="ms-2" loading={isSubmitting}>
                            {localizer.msg('common.submit')}
                        </ActionButton>
                    </div>
                </Form>
            </div>
        </div>
    );
};
