import React, {Fragment, useEffect} from 'react';
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import {Alert} from '@ideascale/ui';
import {useRoutesMatch, useUrlQuery} from '@ideascale/commons';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useAppContext} from 'contexts/AppContext';
import {IdeasContextProvider} from 'contexts/IdeasContext';
import {SearchContextProvider} from 'contexts/SearchContext';
import {SidebarContextProvider} from 'contexts/SidebarContext';
import {IdeaListContextProvider} from 'contexts/IdeaListContext';
import {LandingPageContextProvider} from 'contexts/LandingPageContext';
import {IdeaDetailsContextProvider} from 'contexts/IdeaDetailsContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useRouteUtils} from 'hooks/useRouteUtils';
import {useProfilePageParams} from 'hooks/useProfilePageParams';
import {appLinks} from 'services/AppLinks';
import {TagsPage} from 'pages/TagsPage';
import {ProfilePage} from 'pages/ProfilePage';
import {SearchPage} from 'pages/SearchPage';
import {DigestPage} from 'pages/DigestPage';
import {LandingPage} from 'pages/LandingPage';
import {IdeaListPage} from 'pages/IdeaListPage';
import {CampaignPage} from 'pages/CampaignPage';
import {CampaignTosPage} from 'pages/CampaignTosPage';
import {IdeaDetailsPage} from 'pages/IdeaDetailsPage';
import {LeaderboardPage} from 'pages/LeaderboardPage';
import {NewFeaturesPage} from 'pages/NewFeaturesPage';
import {CampaignTagsPage} from 'pages/CampaignTagsPage';
import {InviteFriendsPage} from 'pages/InviteFriendsPage';
import {MembershipTosViewPage} from 'pages/MembershipTosViewPage';
import {MembershipTosAcceptPage} from 'pages/MembershipTosAcceptPage';
import {ChatContainer} from 'containers/ChatContainer';
import {FooterContainer} from 'containers/FooterContainer';
import {TopBarContainer} from 'containers/topbar/TopBarContainer';
import {MessageContainer} from 'containers/MessageContainer';
import {ActivityContainer} from 'containers/ActivityContainer';
import {ScrollToTopButton} from 'components/ScrollToTopButton';
import {IdeaFormContainer} from 'containers/IdeaFormContainer';
import {NotificationContainer} from 'containers/NotificationContainer';
import {FeatureTourContainer} from 'containers/FeatureTourContainer';
import {MembershipTosContainer} from 'containers/MembershipTosContainer';
import {GoogleAnalyticsContainer} from 'containers/GoogleAnalyticsContainer';
import {MyIdeasListWithContextContainer} from 'containers/MyIdeasListWithContextContainer';
import {LoginPrompt} from 'components/LoginPrompt';
import {JoinCommunityPrompt} from 'components/join-community/JoinCommunityPrompt';
import {RedirectionAlertMessage} from 'components/RedirectionAlertMessage';
import {PrivateCommunityJoinPage} from 'components/join-community/PrivateCommunityJoinPage';
import {ROUTES} from 'shared/Routes';

export const AppContainer = () => {
    const {authentication, communityConfig} = useAppContext();
    const localizer = useLocalizer();
    const query = useUrlQuery();
    const currentProfileId = useProfilePageParams();
    const {profileRoutesMatch} = useRouteUtils();

    const navigate = useNavigate();
    const rootPath = useRoutesMatch('/');

    useEffect(() => {
        if (((rootPath?.pathname === '/') || window?.location?.pathname?.includes(ROUTES.LANDING)) &&
            (authentication.community.isNotPublicCommunity() &&
                authentication.isAuthenticated() &&
                authentication.community.landingPagePublished &&
                authentication.community.publicLandingPageEnabled
            )) {
            navigate(appLinks.landing(query));
        } else if ((authentication.community.isNotPublicCommunity() && authentication.isJoinCommunityAvailable()) || authentication.actor.hasPendingMembership()) {
            navigate(ROUTES.JOIN_COMMUNITY);
        } else if ((rootPath?.pathname === '/' && rootPath) && !window?.location?.pathname?.includes('ideas') && authentication.community?.landingPagePublished) {
            navigate(appLinks.landing(query));
        }
    }, [authentication, navigate, query, rootPath]);

    return (
        (authentication.community.isAccessLocked() || authentication.community.isAccessSandBoxed())
            ? (
                <Fragment>
                    <TopBarContainer/>
                    <Alert className="alert-popup" svgIconsPath={svgIconsPath} variant="warning" open={true}
                           message={localizer.msg(`access-state.${authentication.community.accessState.toLowerCase()}`)}/>
                </Fragment>)
            : (
                <Fragment>
                    <TopBarContainer/>
                    <RedirectionAlertMessage/>
                    {authentication.isJoinCommunityAvailable() && <JoinCommunityPrompt/>}

                    {authentication.actor.isAnonymous() && <LoginPrompt/>}

                    <IdeaFormContainer/>

                    <SidebarContextProvider>
                        <IdeasContextProvider>
                            <SearchContextProvider>
                                <Routes>
                                    <Route path={'/'} element={
                                        (authentication.community.isNotPublicCommunity() && authentication.isAuthenticated() && authentication.community.landingPagePublished && authentication.community.publicLandingPageEnabled)
                                            ?
                                            <Navigate to={appLinks.landing(query)}/>
                                            :
                                            (authentication.community.isNotPublicCommunity() && authentication.isJoinCommunityAvailable()) || authentication.actor.hasPendingMembership()
                                                ?
                                                <Navigate to={ROUTES.JOIN_COMMUNITY}/>
                                                :
                                                authentication.community?.landingPagePublished
                                                    ?
                                                    <Navigate to={appLinks.landing(query)}/>
                                                    :
                                                    <IdeaListPage/>
                                    }/>
                                    <Route path={ROUTES.JOIN_COMMUNITY} element={<PrivateCommunityJoinPage/>}/>
                                    {
                                        [ROUTES.HOME, ROUTES.ALL_ACTIVE_IDEAS, ROUTES.ALL_EXPIRED_IDEAS].map((path) =>
                                            <Route path={path} key={path} element={<IdeaListPage/>}/>
                                        )
                                    }
                                    {
                                        [ROUTES.LANDING_PAGE_ACTION, ROUTES.LANDING].map((path) =>
                                            <Route path={path} key={path} element={<LandingPageContextProvider>
                                                <LandingPage/>
                                            </LandingPageContextProvider>}/>
                                        )
                                    }
                                    <Route path={ROUTES.CAMPAIGN_TOS} element={<CampaignTosPage/>}/>
                                    <Route path={ROUTES.MEMBERSHIP_TOS} element={<MembershipTosAcceptPage/>}/>
                                    <Route path={ROUTES.MEMBERSHIP_TOS_VIEW}
                                           element={<MembershipTosViewPage/>}/>
                                    <Route path={ROUTES.CAMPAIGN.BASE_WITH_WILDCARD} element={<CampaignPage/>}/>
                                    <Route path={ROUTES.PROFILE.BASE_WITH_WILDCARD} element={<ProfilePage/>}>
                                        <Route path={ROUTES.PROFILE.ACTIVITY} element={<ActivityContainer/>}/>
                                        <Route path={ROUTES.PROFILE.NOTIFICATIONS}
                                               element={Number(profileRoutesMatch?.params.memberId) === authentication.actor.id &&
                                                   <NotificationContainer/>}/>
                                        <Route path={ROUTES.PROFILE.MY_IDEAS}
                                               element={Number(profileRoutesMatch?.params.memberId) === authentication.actor.id &&
                                                   <MyIdeasListWithContextContainer/>}/>
                                        <Route path={ROUTES.PROFILE.MESSAGES}
                                               element={Number(profileRoutesMatch?.params.memberId) === authentication.actor.id &&
                                                   <MessageContainer/>}/>
                                        <Route path={ROUTES.PROFILE.MEMBER_ID} element={
                                            <Navigate
                                                to={profileRoutesMatch?.params.memberId ? appLinks.activity(profileRoutesMatch?.params.memberId) : ROUTES.HOME}
                                                replace/>
                                        }/>
                                        <Route path="*" element={<Navigate
                                            to={currentProfileId > 0 ? appLinks.activity(currentProfileId) : ROUTES.HOME}
                                            replace/>}/>
                                    </Route>
                                    <Route path={ROUTES.IDEA_DETAILS.BASE_WITH_WILDCARD} element={
                                        <IdeaDetailsContextProvider>
                                            <IdeaDetailsPage/>
                                        </IdeaDetailsContextProvider>
                                    }/>
                                    <Route path={ROUTES.LEADERBOARD.BASE_ROUTE} element={<LeaderboardPage/>}/>
                                    <Route path={ROUTES.TAGS} element={<TagsPage/>}/>
                                    <Route path={ROUTES.CAMPAIGN_TAGS} element={<CampaignTagsPage/>}/>
                                    <Route path={ROUTES.SEARCH} element={
                                        <IdeaListContextProvider>
                                            <SearchPage/>
                                        </IdeaListContextProvider>
                                    }/>
                                    <Route path={ROUTES.NEW_FEATURES} element={<NewFeaturesPage/>}/>
                                    {
                                        [ROUTES.DIGEST_SHARE, ROUTES.CAMPAIGN_PERFORMANCE].map((path) =>
                                            <Route path={path} key={path} element={<DigestPage/>}/>
                                        )
                                    }
                                    <Route path={ROUTES.INVITE_FRIENDS} element={<InviteFriendsPage/>}/>
                                    <Route path="*" element={<Navigate to={ROUTES.HOME} replace/>}/>
                                </Routes>
                            </SearchContextProvider>
                        </IdeasContextProvider>
                    </SidebarContextProvider>
                    {
                        !authentication.isCommunityTosAcceptanceRequired() && <FooterContainer/>
                    }
                    {
                        authentication.isAuthenticated() &&
                        <FeatureTourContainer/>
                    }
                    {
                        authentication.isAuthenticated() && communityConfig.supportChatEnabled &&
                        <ChatContainer/>
                    }
                    <GoogleAnalyticsContainer/>
                    <MembershipTosContainer/>
                    <ScrollToTopButton/>
                </Fragment>
            )
    );
};