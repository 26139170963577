import React, {useCallback} from 'react';
import {GroupBase, OptionsOrGroups} from 'react-select';
import {GroupOptionType, IdeascaleSelectWithPagination, OptionType} from '@ideascale/ui';
import {Localizer} from '@ideascale/commons';
import {StageDropdownHeader} from './StageDropdownHeader';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {StagesHolder} from 'models/StagesHolder';
import {StageGroup} from 'models/StageGroup';
import styles from 'shared/StageSelect.module.scss';

type Parameters = {
    term: string,
    page: number
}

export type StageSelectProps = {
    value?: OptionType[];
    className?: string;
    name?: string;
    inputId?: string;
    isDisabled?: boolean;
    onSelect: (option: OptionType[]) => void;
    localizer: Localizer;
    placeholder?: string;
    menuIsOpen?: boolean;
    fetchStages: (params: Parameters) => Promise<PagedResponseContent<StagesHolder<StageGroup>>>;
}

export const StageSelect = (props: StageSelectProps) => {
    const {
        value,
        inputId,
        name,
        className,
        fetchStages,
        onSelect,
        isDisabled,
        placeholder,
        menuIsOpen,
        localizer
    } = props;

    const loadStages = useCallback(async (term: string, prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>, additionalParams: any) => {
        try {
            const {page} = additionalParams;
            const {content, hasMore} = await fetchStages({term, page});

            const stageGroupedData: any[] = content.map(stageItem => {
                const existingGroupIndex = prevOptions.findIndex(group => (group.label !== '' && group.label === stageItem.name));
                const groupName = existingGroupIndex > -1 ? '' : stageItem.name;

                const newStages = stageItem.stages.map(stage => ({
                    value: stage.id,
                    label: stage.label
                }));

                if (prevOptions[existingGroupIndex]) {
                    return {
                        options: newStages
                    };
                } else {
                    return {
                        label: groupName,
                        options: newStages,
                        header: groupName !== '' ? <StageDropdownHeader groupName={groupName}/> : undefined
                    } as GroupOptionType;
                }
            });

            return {
                options: stageGroupedData.filter((item: any) => !Array.isArray(item)),
                hasMore: hasMore,
                additional: {
                    page: page + 1
                }
            };
        } catch (e: any) {
            return {
                options: []
            };
        }
    }, [fetchStages]);

    return (
        <div className={`${styles.stageSelect} ${className ? className : ''}`}>
            <IdeascaleSelectWithPagination
                ariaLabel={localizer.msg('frontend-shared.common.select-stages')}
                menuIsOpen={menuIsOpen}
                loadOptions={loadStages}
                isMulti={true}
                name={name}
                inputId={inputId}
                additional={{page: 0}}
                value={value}
                disabled={isDisabled}
                onChange={(value: any) => onSelect(value)}
                placeholder={placeholder}
            />
        </div>
    );
};