import React from 'react';
import {useParams} from 'react-router-dom';
import {ClassificationFieldAttribute} from '@ideascale/commons';
import {IdeaDetailsRouteMatchParams} from 'models/types/IdeaDetailsRouteMatchParams';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import './BasicStageCallToAction.scss';

type BasicStageCallToActionProps = {
    stageName: string;
    netVote: number;
    netVotesEnabled: boolean;
    classification?: ClassificationFieldAttribute[]
}

export const BasicStageCallToAction = (props: BasicStageCallToActionProps) => {
    const {stageName, netVote, netVotesEnabled, classification} = props;
    const localizer = useLocalizer();
    const {communityConfig} = useAppContext();
    const params = useParams<IdeaDetailsRouteMatchParams>();

    return (
        <div
            className={`stage-action stage-action-basic ${communityConfig.classificationEnabled && params.ideaId && classification ? 'mt-4' : ''}`}
            data-fn-key={stageName}>
            <div className="stage-data text-center text-truncate px-1"
                 title={netVotesEnabled ? localizer.msg('stage.common.vote', {count: netVote}) : localizer.msg('stage.ideate.net-votes-hidden')}>
                {netVotesEnabled ? netVote : localizer.msg('stage.ideate.net-votes-hidden')}
            </div>
        </div>
    );
};