import React, {ReactNode} from 'react';
import styles from './SearchInputGroup.module.scss';

type SearchInputGroupProps = {
    children: ReactNode;
    groupText: string | JSX.Element;
    className?: string;
}

export const SearchInputGroup = (props: SearchInputGroupProps) => {
    const {children, groupText, className = ''} = props;

    return (
        <div className={`input-group ${styles.inputGroup} ${className}`}>
            <span
                className={`input-group-text input-group-prepend border-end-0 position-relative px-2 ${styles.inputGroupText}`}>
                {groupText}
            </span>
            <div className="min-width flex-grow-1">
                {children}
            </div>
        </div>
    );
};
