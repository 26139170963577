export class UploadedImage {
    static EMPTY = new UploadedImage(0, '', '', '');

    constructor(
        public fileId: number,
        public url: string,
        public filename: string,
        public altText?: string
    ) {
    }
}
