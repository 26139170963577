import React, {Fragment, useCallback, useMemo} from 'react';
import {useToggle} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {useTextColor} from 'hooks/useTextColor';
import {useTranslation} from 'hooks/useTranslation';
import {HtmlRenderer} from 'components/shared/HtmlRenderer';
import {ConfigWrapper} from 'components/landing-page/ConfigWrapper';
import {GeneralTextConfigModal} from 'components/landing-page/GeneralTextConfigModal';
import {TextType} from 'models/enums/landing-page/TextType';
import {GeneralText} from 'models/landing-page/GeneralText';
import {TranslationConfig} from 'models/TranslationConfig';
import {RichTextToolbarType} from 'models/enums/landing-page/RichTextToolbarType';
import {TranslationFieldType} from 'models/enums/TranslationFieldType';
import {TranslationContentType} from 'models/enums/landing-page/TranslationContentType';
import {TranslationContentResponse} from 'models/landing-page/TranslationContentResponse';
import {TranslationModal} from 'shared/TranslationModal';
import './GeneralTextComponent.scss';

type GeneralTextComponentProps = {
    showConfig: boolean;
    data: GeneralText;
    onUpdateText: (data: GeneralText) => Promise<void>;
    onToggleVisibility: (data: GeneralText) => void;
    onTextTranslated: (response: TranslationContentResponse) => void;
    onComponentEdited: () => void,
    className?: string;
    toolbarType?: RichTextToolbarType;
    showTranslationConfig?: boolean,
    translationLanguageId?: number;
    maxCharacterLimit?: number;
    accessibleName?: string;
}

export const GeneralTextComponent = (props: GeneralTextComponentProps) => {
    const {
        data,
        showConfig,
        onUpdateText,
        className = '',
        onTextTranslated,
        onToggleVisibility,
        showTranslationConfig,
        translationLanguageId,
        maxCharacterLimit,
        onComponentEdited,
        toolbarType = RichTextToolbarType.TEXT_ONLY,
        accessibleName = ''
    } = props;
    const [showEditModal, toggleEditModal] = useToggle(false);
    const [showTranslationModal, toggleTranslationModal] = useToggle(false);
    const localizer = useLocalizer();
    const {getTranslatedContent, saveTranslatedContent} = useTranslation();
    const {getTextColorClassName} = useTextColor();

    const updateTextConfigData = useCallback(async (config: GeneralText) => {
        await onUpdateText(config);
    }, [onUpdateText]);

    const onEditClicked = useCallback(() => {
        toggleEditModal();
    }, [toggleEditModal]);

    const onTranslationClicked = useCallback(() => {
        if (translationLanguageId !== undefined) {
            toggleTranslationModal();
        }
    }, [toggleTranslationModal, translationLanguageId]);

    const toggleVisibility = useCallback(async () => {
        onToggleVisibility(data);
    }, [data, onToggleVisibility]);

    const translationConfigs = useMemo(() => {
        return [
            new TranslationConfig(
                TranslationContentType.COMPONENT_TEXT_TEXT,
                data.id,
                TranslationFieldType.RICH_TEXT,
                toolbarType
            ),
        ];
    }, [data.id, toolbarType]);

    return (
        <Fragment>
            <ConfigWrapper visible={data.visible}
                           toggleVisibility={toggleVisibility}
                           showConfig={showConfig}
                           localizer={localizer}
                           showTranslationConfig={showTranslationConfig}
                           onEditClicked={onEditClicked}
                           onTranslationClicked={onTranslationClicked}
                           componentClass="general-text"
                           className={className}
                           accessibleName={accessibleName || localizer.msg('landing-page.components.edit.general-text.general-text')}>
                {
                    data.type === TextType.HEADING &&
                    <HtmlRenderer content={data.text}
                                  className={`header-component ${getTextColorClassName(data.textColor)}`}/>
                }
                {
                    data.type === TextType.PARAGRAPH &&
                    <HtmlRenderer content={data.text}
                                  className={`font-size-lg text-widget ${getTextColorClassName(data.textColor)}`}/>
                }
            </ConfigWrapper>
            {
                showEditModal &&
                <GeneralTextConfigModal config={data}
                                        open={showEditModal}
                                        onComponentEdited={onComponentEdited}
                                        localizer={localizer}
                                        maxCharacterLimit={maxCharacterLimit}
                                        toolbar={toolbarType}
                                        toggle={toggleEditModal}
                                        updateConfig={updateTextConfigData}/>
            }
            {
                showTranslationModal && translationLanguageId !== undefined &&
                <TranslationModal translationLanguageId={translationLanguageId} localizer={localizer}
                                  open={showTranslationModal}
                                  toggle={toggleTranslationModal}
                                  onTextTranslated={onTextTranslated}
                                  maxCharacterLimit={maxCharacterLimit}
                                  onComponentEdited={onComponentEdited}
                                  translationConfigs={translationConfigs}
                                  getTranslatedContent={getTranslatedContent}
                                  saveTranslatedContent={saveTranslatedContent}/>
            }
        </Fragment>
    );
};