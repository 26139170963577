import React from 'react';
import {Button, Icon} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {Localizer} from '@ideascale/commons';

type TopbarCommonEditModeActionsProps = {
    localizer: Localizer;
    onCancel: () => void;
    onPublish: () => void;
    publishDisabled?: boolean;
    cancelDisabled?: boolean;
    showPublishButton?: boolean
}

export const TopbarCommonEditModeActions = (props: TopbarCommonEditModeActionsProps) => {
    const {localizer, onCancel, onPublish, publishDisabled, cancelDisabled, showPublishButton = true} = props;

    return (
        <div>
            <Button color="secondary" className="edit-mode-element" onClick={onCancel} disabled={cancelDisabled}
                    title={localizer.msg('common.cancel')} data-test-element-id="btn-cancel-edit-mode">
                <Icon className="active position-relative pos-top-n1 me-2" width={11}
                      iconSpritePath={svgIconsPath} name="cross"/>
                {localizer.msg('common.cancel')}
            </Button>
            {
                showPublishButton &&
                <Button color="primary" className="edit-mode-element ms-3" onClick={onPublish}
                        disabled={publishDisabled} title={localizer.msg('common.publish')}
                        data-test-element-id="btn-publish-edit-mode">
                    <Icon className="me-1 fill-white" width={24} iconSpritePath={svgIconsPath} name="paper-plane"/>
                    {localizer.msg('common.publish')}
                </Button>
            }
        </div>
    );
};
