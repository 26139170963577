import React, {useEffect, useRef, useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import {Trans} from 'react-i18next';
import {Tooltip} from 'reactstrap';
import {useOnClickOutside} from '@ideascale/ui';
import {eventDispatcher, Member, useToggle} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {InfiniteUserPopover} from 'components/InfiniteUserPopover';
import {Image} from 'shared/Image';
import {KudoActivitySummary} from 'models/KudoActivitySummary';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {PageParameters} from 'models/types/PageParameters';
import {SHOW_LOGIN_PROMPT} from 'constants/AppConstants';
import kudos from 'assets/img/kudos/regular.png';
import './KudosAction.scss';

type KudosActionProps = {
    elementId: string;
    id: number;
    kudosCount: number;
    kudoGiven: boolean;
    canGiveKudos: boolean;
    giveKudos: (id: number) => Promise<KudoActivitySummary>;
    className?: string;
    fetchKudoGivers?: (pageParameters: PageParameters, ideaId: number) => Promise<PagedResponseContent<Member>>;
    draftIdea?: boolean;
}

export const KudosAction = (props: KudosActionProps) => {
    const {
        elementId,
        kudosCount,
        giveKudos,
        kudoGiven,
        canGiveKudos,
        id,
        className = '',
        fetchKudoGivers,
        draftIdea
    } = props;
    const localizer = useLocalizer();
    const kudoButtonRef = useRef(null);
    const kudoUpdatedCountRef = useRef(kudosCount);
    const [tooltipContentKey, setTooltipContentKey] = useState('');
    const [animateKudoCount, setAnimatedKudoCount] = useState(false);
    const [showUserPopover, toggleUserPopover] = useToggle(false);
    const {authentication, communityConfig: {ssoEnabled}} = useAppContext();

    const onClickKudos = async () => {
        if (canGiveKudos) {
            if (authentication.actor.isAnonymous() && !ssoEnabled) {
                eventDispatcher.dispatch(SHOW_LOGIN_PROMPT, localizer.msg('login-prompt.message.kudo'));
            } else {
                const response = await giveKudos(id);
                if (response?.outOfKudos) {
                    setTooltipContentKey('kudos.action.out-of-kudo');
                } else if (response?.insufficientKudos) {
                    setTooltipContentKey('kudos.action.not-enough-kudo');
                } else if (response?.kudoCount) {
                    kudoUpdatedCountRef.current = response.kudoCount;
                    setAnimatedKudoCount(true);
                }
            }
        }
    };

    const onOutSideClick = () => {
        setTooltipContentKey('');
    };

    useEffect(() => {
        kudoUpdatedCountRef.current = kudosCount;
    }, [kudosCount]);

    useOnClickOutside(kudoButtonRef, onOutSideClick);
    return (
        <div id={`kudos-action-${elementId}`} className="d-inline-block"
             onFocus={() => toggleUserPopover(true)}
             onBlur={(event) => {
                 if (!event.currentTarget.contains(event.relatedTarget)) {
                     toggleUserPopover(false);
                 }
             }}
             onMouseEnter={() => toggleUserPopover(true)}
             onMouseLeave={() => toggleUserPopover(false)}
        >
            {
                !draftIdea &&
                <button
                    aria-label={localizer.msg('common.kudos-count', {count: kudoUpdatedCountRef.current})}
                    aria-roledescription={localizer.msg('common.kudos-button')}
                    className={`focus-visible-box-shadow kudos kudos-link kudos-link-popup text-normal position-relative ${kudoGiven ? 'active' : ''} ${canGiveKudos ? '' : 'cursor-default'} ${className}`}
                    onClick={onClickKudos} id={`kudos-${elementId}`} ref={kudoButtonRef} type="button">
                    <CSSTransition in={animateKudoCount} timeout={450} unmountOnExit aria-live="polite"
                                   onEntered={() => setAnimatedKudoCount(false)}>
                        <div className="animate-kudo-count">{kudoUpdatedCountRef.current}</div>
                    </CSSTransition>
                    <Image src={kudos} alt="kudos icon" className="img-size-18p" aria-hidden={true}/>
                    <span className="ms-1"
                          aria-hidden={true}>{kudoUpdatedCountRef.current ? kudoUpdatedCountRef.current : '+'}</span>
                </button>
            }
            {tooltipContentKey && <Tooltip
                container={`kudos-${elementId}`}
                placement={'top'}
                isOpen={tooltipContentKey !== ''}
                className="kudos-tooltip"
                target={'kudos-' + elementId}>
                    <span aria-live="polite">
                        <Trans i18nKey={tooltipContentKey}>
                            <Image src={kudos} alt={localizer.msg('common.kudos')} className="img-size-18p"/>
                        </Trans>
                    </span>
            </Tooltip>}
            {
                fetchKudoGivers && kudoUpdatedCountRef.current > 0 && showUserPopover &&
                <InfiniteUserPopover id={id} actorId={authentication.actor.id}
                                     container={`kudos-action-${elementId}`}
                                     selector={`kudos-${elementId}`}
                                     header={localizer.msg('idea-details.kudo-givers')} fetchUsers={fetchKudoGivers}
                                     isOpen={showUserPopover} toggle={toggleUserPopover}/>
            }
        </div>
    );
};