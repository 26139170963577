import {ApiResponseError, appConstants} from '@ideascale/commons';
import {ActivityType} from 'models/enums/ActivityType';
import {MyIdeaListMode} from 'models/enums/MyIdeaListMode';
import {LeaderboardType} from 'models/enums/LeaderboardType';

export const PAGE_IDS = Object.freeze({
    LANDING_PAGE: 'landing-page',
    COMMUNITY_HOME: 'community-home',
    LEADERBOARD: 'leaderboard',
    CAMPAIGN_HOME: 'campaign-home',
    ADVANCED_SEARCH: 'advanced-search',
    IDEA_DETAILS: 'idea-details',
    PROFILE_PAGE: 'profile-page',
    NEW_FEATURES: 'new-features',
    COMMUNITY_TAGS_PAGE: 'community-tags',
    CAMPAIGN_TAGS_PAGE: 'campaign-tags',
    JOIN_COMMUNITY: 'join-community',
});

export const NOTIFICATION_FILTERS = Object.freeze([
    {groupId: 0, labelLocalization: 'notification.group.all'},
    {groupId: 1, labelLocalization: 'notification.group.ideas'},
    {groupId: 3, labelLocalization: 'notification.group.kudos'}
]);

export const ACTIVITY_STREAM_FILTERS = Object.freeze([
    {label: 'profile.activity.all', value: ''},
    {label: 'profile.activity.ideas', value: ActivityType.IDEAS},
    {label: 'profile.activity.comments', value: ActivityType.COMMENTS},
    {label: 'profile.activity.modify', value: ActivityType.MODIFY},
    {label: 'profile.activity.votes', value: ActivityType.VOTES},
    {label: 'profile.activity.follows', value: ActivityType.FOLLOWS},
    {label: 'profile.activity.kudos', value: ActivityType.KUDOS},
    {label: 'profile.activity.tags', value: ActivityType.TAGS},
    {label: 'profile.activity.labels', value: ActivityType.LABELS},
    {label: 'profile.activity.stage-change', value: ActivityType.STAGE_CHANGE},
    {label: 'profile.activity.idea-linking', value: ActivityType.LINKS},
    {label: 'profile.activity.assessment', value: ActivityType.ASSESSMENT},
    {label: 'profile.activity.reviewscale', value: ActivityType.REVIEWSCALE},
    {label: 'profile.activity.funds', value: ActivityType.FUND},
    {label: 'profile.activity.estimates', value: ActivityType.ESTIMATES},
    {label: 'profile.activity.refinement', value: ActivityType.REFINEMENT},
    {label: 'profile.activity.private-actions', value: ActivityType.PRIVATE_ACTIONS},
]);

export const MY_IDEAS_FILTER = Object.freeze([
    {value: MyIdeaListMode.MY_IDEAS, label: 'profile.my-ideas.filters.submitted'},
    {value: MyIdeaListMode.MY_OWNED_IDEAS, label: 'profile.my-ideas.filters.owned-ideas'},
    {value: MyIdeaListMode.JOINED_IDEAS, label: 'profile.my-ideas.filters.joined-ideas'},
    {value: MyIdeaListMode.FOLLOWING, label: 'profile.my-ideas.filters.following'},
    {value: MyIdeaListMode.MY_ACTION_ITEMS, label: 'profile.my-ideas.filters.action-items'},
    {value: MyIdeaListMode.MY_DRAFTS, label: 'profile.my-ideas.filters.drafts'},
    {value: MyIdeaListMode.PRIVATE_IDEAS, label: 'profile.my-ideas.filters.private-ideas'},
]);

export const LEADERBOARD_TYPES = Object.freeze([
    {value: LeaderboardType.KUDOS_RECEIVED},
    {value: LeaderboardType.KUDOS_GIVEN},
    {value: LeaderboardType.IDEA},
    {value: LeaderboardType.COMMENT},
    {value: LeaderboardType.VOTE},
]);

export const LINKS = Object.freeze({
    LOGIN: '/a/login',
    REGISTER: '/a/register',
    BASE_URL: 'https://www.ideascale.com',
    HOME: '/a/home',
    INDEX: '/a/index',
});

export const helpLinks = Object.freeze({
    endUserTermsUrl: (baseUrl = appConstants.helpSiteBaseUrl) => `${baseUrl}/end-user-terms-and-conditions`,
    privacyPolicyUrl: (baseUrl = appConstants.helpSiteBaseUrl) => `${baseUrl}/ideascale-privacy-policy`,
    anonymousBestPracticeUrl: (baseUrl = appConstants.helpSiteBaseUrl) => `${baseUrl}/best-practices-for-anonymous-activity`,
    cookiePolicyUrl: (baseUrl = appConstants.helpSiteBaseUrl) => `${baseUrl}/ideascale-cookie-policy`,
});

export const externalLinks = Object.freeze({
    onlineLearning: () => 'https://ideascale-partner.teachable.com/p/inspiring-innovation-creative-thinking',
    articlesResources: () => 'https://innovationmanagement.se',
    getUpgradeLicenseUrl: (personId: number) => `/a/accounts/${personId}/plan`
});

export const LIST_NEXT_PAGE_THRESHOLD_OFFSET = 5;
export const DEFAULT_STALE_TIME = 1000 * 60 * 5; //5 minutes
export const COMMENT_LIST_PAGE_SIZE = 20;

export const QUERY_KEYS = {
    IDEA_LIST: 'idea-list',
    IDEA_DETAILS: 'idea-details',
    MODERATE_ACTIONS: 'idea-moderate-actions',
    MORE_ACTIONS: 'idea-more-actions',
    ACTIVITY_STREAM: 'activity-stream',
    PROFILE_SIDEBAR: 'sidebar-profile',
    IDEA_ATTACHMENTS: 'idea-attachments',
    UNFURL_URL: 'unfurl-url',
    IDEA_LABELS: 'idea-labels',
    IDEA_COMMENTS: 'idea-comments',
    IDEA_PINNED_COMMENTS: 'idea-pinned-comments',
    COMMENT_PERMISSION: 'comment-permission',
    FOOTER: 'footer',
    STAGE_COMMENT_PERMISSION: 'stage-comment-permission',
    PROFILE_NOTIFICATIONS: 'profile-notifications',
    DRAFT_IDEAS: 'draft-ideas',
    LANDING_PAGE_IDEAS: 'landing-page-ideas',
    PROFILE_FIELD_MODAL_STATUS: 'profile-field-modal-status',
    COMMUNITY_LIBRARY_IMAGES: 'community-library-images',
    COMMUNITY_GALLERY_IMAGES: 'community-gallery-images',
    ADVANCED_SETTINGS_CONFIG: 'advanced-settings-config',
    CAMPAIGN_FEATURED_GALLERY_IMAGES: 'campaign-featured-gallery-images',
    CAMPAIGN_BANNER_GALLERY_IMAGES: 'campaign-banner-gallery-images',
    CAMPAIGN_LIBRARY_IMAGES: 'campaign-library-images',
    CAMPAIGN_FUNNEL: 'campaign-funnel'
};

export const IDEA_EVENTS = {
    IDEA_MODIFIED: 'IDEA_MODIFIED',
    STAGE_CHANGED: 'STAGE_CHANGED',
    IDEA_DELETED: 'IDEA_DELETED',
    DRAFT_IDEA_DELETED: 'DRAFT_IDEA_DELETED',
    IDEA_VOTED: 'IDEA_VOTED',
};

export const EDIT_MODE_EVENTS = {
    PUBLISHED: 'CONFIG_PUBLISHED',
};

export const SCROLL_EVENTS = {
    DETAILS_TABS: 'DETAILS_TABS',
    SCROLL_TOP: 'SCROLL_TOP'
};

export const ID_PREFIXES = {
    COMMENT: 'comment',
    PINNED_COMMENT: 'pinned-comment',
    COMMENT_FORM: 'comment-form',
    REPLY_FORM: 'reply-form',
    REPLY_PINNED_COMMENT_FORM: 'reply-pinned-comment-form',
    COMMENT_LIST: 'comment-list',
    NORMAL_COMMENT_LIST: 'normal-comment-list',
};

export const STAGE_KEYS = {
    UNSPECIFIED: 'all',
    ALL_STAGES: 'all-stages'
};

export const SHOW_LOGIN_PROMPT = 'SHOW_LOGIN_PROMPT';
export const IMAGE_TYPE_REGEX = /^(image)\/(png|jpe?g|bmp|gif)$/i;
export const IMAGE_TYPE_WITHOUT_GIF_REGEX = /^(image)\/(png|jpe?g|bmp)$/i;

export const SHOW_JOIN_COMMUNITY_PROMPT = 'SHOW_JOIN_COMMUNITY_PROMPT';

export const LOCALIZATION_KEY_PREFIX = 'community.app.is.';

export const HTML_TO_REACT_CONFIG = {atMention: true};
export const VALIDATION_ERRORS = 'validationErrors';
export const COMMON_VALIDATION_ERROR = 'common';
export const ATTACHMENTS_VALIDATION_ERROR = 'attachments';
export const LANDING_PAGE_PLAIN_TEXT_MAX_CHAR_COUNT = 255;
export const LANDING_PAGE_BUTTON_TEXT_MAX_CHAR_COUNT = 120;

export const APP_HANDLED_API_ERRORS = Object.values(ApiResponseError);

export const VALID_EMAIL_REGEX = /^[^@]+@[^\s@]+\.[^\s@]+$/i;

export const FUND_REGEX_PATTERN = /^[-+]?\d*\.?\d*$/;
export const VALID_EMAIL_PREFIX_REGEX = /^[^@]+$/i;
export const EFFECTIVE_CLASSIFICATION = 'effective-classification';

export const CAROUSEL_DEFAULT_SLIDES_TO_SHOW = 3;
export const CAROUSEL_PAGE_SIZE = 15;
export const LIST_PAGE_SIZE = 50;
export const ONBOARDING_PROFILE_QUESTIONS_MAX_NAME_LENGTH = 63;
export const FEATURE_TOUR_DELAY = 1000;
export const CAMPAIGN_SUBTITLE_MAX_LENGTH = 500;
export const IDEASCALE_LOGO = '/img/ideascale-icon.png';
export const AI_IMAGES_NUMBER = 3;

export const VERSION_NOTE_MAX_LENGTH = 45;
export const TABINDEX_SET_DELAY = 1000;

export const MENTIONED_FIELD_VALUE_ID = 'mentioned_field_value_id';
export const FIELD_VALUE_ID_PREFIX = 'field-value-id';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
