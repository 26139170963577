import React, {Fragment, useState} from 'react';
import {Switch} from '@ideascale/ui';
import {
    ClassificationAttribute,
    ClassificationConfig,
    ClassificationTreeMenu,
    FieldExtensionsWithInputGroup,
    IdeaFieldData,
    Localizer,
    useHookFormContext
} from '@ideascale/commons';

type ModeratorOrAdminOnlyFieldProps = {
    localizer: Localizer;
    ideaField: IdeaFieldData;
    classificationConfig?: ClassificationConfig;
    svgIcons?: string;
    containerId?: string;
}

export const ModeratorOrAdminOnlyField = (props: ModeratorOrAdminOnlyFieldProps) => {
    const {
        localizer,
        ideaField,
        classificationConfig,
    } = props;
    const {register} = useHookFormContext();
    const [, setSelectedClassifications] = useState<ClassificationAttribute | undefined>(classificationConfig?.selectedClassificationsData[ideaField.key]);

    return (
        <Fragment>
            {
                classificationConfig?.enableClassification
                    ? (
                        <ClassificationTreeMenu localizer={localizer}
                                                id={ideaField.key}
                                                classifications={classificationConfig.classifications || {}}
                                                onApplyClassifications={value => {
                                                    setSelectedClassifications(value);
                                                    classificationConfig.updateAppliedClassifications && classificationConfig.updateAppliedClassifications(ideaField.key, value);
                                                }}
                                                appliedClassifications={classificationConfig?.selectedClassificationsData[ideaField.key]}/>
                    )
                    : null
            }

            <FieldExtensionsWithInputGroup enabledClassification={classificationConfig?.enableClassification}
                                           classificationAttribute={classificationConfig?.selectedClassificationsData[ideaField.key]}>
                <div className="form-group pb-3 border-bottom-1 w-100" key={ideaField.key}>
                    <Switch offText={localizer.msg('switch.off')} onText={localizer.msg('switch.on')}
                            inputId={ideaField.type}
                            defaultChecked={Boolean(ideaField.value)}
                            label={<strong>{ideaField.label}</strong>}
                            {...register(ideaField.key)}
                    />
                </div>
                <p className="text-muted mt-1">
                    {localizer.msg('idea.fields.admin-or-moderator-only.warning')}
                </p>
            </FieldExtensionsWithInputGroup>

        </Fragment>
    );
};