type Sender = {
    id: number,
    name: string,
    username: string
    avatar: string,
    identityHidden: boolean
}

export class MessageReplyData {
    static readonly EMPTY = new MessageReplyData('', '', {
        id: 0,
        name: '',
        username: '',
        avatar: '',
        identityHidden: false
    }, []);

    constructor(
        public body: string,
        public createdAt: string,
        public sender: Sender,
        public recipients: Sender[]
    ) {
    }
}