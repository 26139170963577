import {Operation} from './Operation';
import {CampaignOperationType} from './CampaignOperationType';

export class ChangeCampaignTosOperation implements Operation {
    constructor(public type: CampaignOperationType, public tosContent: string, public tosEnabled: boolean, public campaignId: number) {
    }

    getType(): CampaignOperationType {
        return this.type;
    }
}