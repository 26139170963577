import React, {Fragment, ReactNode} from 'react';
import isEmpty from 'lodash/isEmpty';
import {Button, Icon} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useLocalizer} from 'hooks/useLocalizer';
import './EditActionWrapper.scss';

type EditVisibilityActionWrapperProps = {
    children: ReactNode;
    editAllowed: boolean;
    editBtnTestElementId: string;
    toggleVisibility: () => void;
    visibility: boolean;
    hasError?: boolean;
    editBtnTitle?: string;
    modalTitle?: string;
    className?: string;
    value?: string;
}

export const EditVisibilityActionWrapper = (props: EditVisibilityActionWrapperProps) => {
    const {
        children,
        editAllowed,
        hasError = false,
        className = '',
        editBtnTestElementId = '',
        toggleVisibility,
        visibility,
        editBtnTitle = '',
        value
    } = props;

    const localizer = useLocalizer();

    return (
        <Fragment>
            <div
                className={`${className} position-relative edit-action-wrapper ${hasError ? 'has-error' : ''} ${editAllowed ? 'edit-mode-element align-items-center active' : ''}`}>
                {
                    editAllowed &&
                    <Button className="edit-mode-element position-absolute py-1 px-2 fw-semibold font-size-sm"
                            color={`${hasError ? 'danger' : 'primary'}`}
                            onClick={toggleVisibility} data-test-element-id={editBtnTestElementId}
                            editBtnTitle={editBtnTitle}>
                        <Icon iconSpritePath={svgIconPath} width={15} height={15}
                              ariaHidden={true}
                              className="me-2 position-relative pos-top-n1"
                              name={visibility ? 'eye-open-slash' : 'eye-open'}/>
                        {
                            isEmpty(value)
                                ? visibility
                                    ? localizer.msg('common.hide')
                                    : localizer.msg('common.show')
                                : value
                        }
                    </Button>
                }
                {children}
            </div>
        </Fragment>
    );
};
