import React, {Fragment} from 'react';
import {Localizer} from '@ideascale/commons';
import {ActivityLink} from '../ActivityLink';
import {ActivityStreamData} from 'models/ActivityStreamData';
import {ActionType} from 'models/enums/ActionType';

type ModifyActivityProps = {
    localizer: Localizer;
    activity: ActivityStreamData;
    action: ActionType.MODIFY;
}

export const ModifyActivity = (props: ModifyActivityProps) => {
    const {localizer, activity} = props;
    const {modifyType = 'IDEA_MODIFY'} = activity;
    const isComment = modifyType === 'COMMENT_MODIFY';

    return (
        <Fragment>
            {isComment
                ? localizer.msg('profile.activity-stream.comment.modified')
                : localizer.msg('profile.activity-stream.idea.modified')
            }
            {' - '}
            <ActivityLink title={activity.idea.title} ideaId={activity.idea.id} commentId={activity.commentId}/>
        </Fragment>
    );
};