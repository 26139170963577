import React from 'react';
import {Button} from '@ideascale/ui';
import {Localizer} from '@ideascale/commons';

type CommonProps = {
    localizer: Localizer;
    onCancel: () => void;
    updateDisabled?: boolean;
}

type SubmitButtonProps = CommonProps & {
    updateBtnType: 'submit';
    onUpdate?: never;
}

type UpdateButtonProps = CommonProps & {
    updateBtnType?: never;
    onUpdate: () => void;
}

type ConfigurationActionBarProps =
    | SubmitButtonProps
    | UpdateButtonProps;

export const ConfigurationActionBar = (props: ConfigurationActionBarProps) => {
    const {localizer, onCancel, onUpdate, updateDisabled, updateBtnType = 'button'} = props;
    return (
        <div className="action-bar mt-4 mb-3 text-end">
            <Button color="cancel" size="lg" type="button" onClick={onCancel} tabIndex={0}
                    data-test-element-id="cancel">{localizer.msg('common.cancel')}</Button>
            <Button color="primary" size="lg" type={updateBtnType} disabled={updateDisabled}
                    onClick={onUpdate} tabIndex={0} data-test-element-id="update">{localizer.msg('common.update')}</Button>
        </div>
    );
};