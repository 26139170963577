import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';
import {
    CLASSIFICATION_NAME,
    ClassificationFieldAttribute,
    ClassificationLabel,
    ClassificationUtil,
    FileAttachment,
    HtmlUtils,
    Localizer,
    useApplicableClassifications
} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {appLinks} from 'services/AppLinks';
import {HtmlRenderer} from 'components/shared/HtmlRenderer';
import {IdeaSummary} from 'models/IdeaSummary';

type IdeaCardProps = {
    idea: IdeaSummary;
    editMode?: boolean;
    localizer: Localizer;
    sideView?: boolean;
}

const SUMMARY_LENGTH = 300;

const IdeaCard = (props: IdeaCardProps) => {
    const {idea, editMode = false, localizer, sideView} = props;
    const {communityConfig: {classificationEnabled}} = useAppContext();
    const {getClassificationAttribute} = useApplicableClassifications('', []);

    const getFirstImageOfAttachments = () => {
        return idea.attachments?.find(attachment => FileAttachment.isPreviewableImg(attachment.filename));
    };

    const summary = useMemo(() => {
        const descriptionLength = HtmlUtils.getHtmlTextLength(idea.description);
        return descriptionLength > SUMMARY_LENGTH ? HtmlUtils.truncateHtml(idea.description, SUMMARY_LENGTH, '...') : idea.description;
    }, [idea.description]);

    if (sideView) {
        return (
            <div
                className="trending-idea d-flex flex-row card panel align-items-center side-idea">
                {
                    getFirstImageOfAttachments()?.url &&
                    <div className="flex-shrink-1 col-5 p-0">
                        <img className="img-fluid rounded-start" src={getFirstImageOfAttachments()?.url}
                             alt={getFirstImageOfAttachments()?.altText || getFirstImageOfAttachments()?.filename}/>
                    </div>
                }
                <div
                    className={`idea-info card-body panel-body d-flex flex-column align-items-start ${getFirstImageOfAttachments()?.url ? 'col-7' : 'col-12'}`}>
                    {
                        classificationEnabled && idea.campaign?.classificationSummary &&
                        <ClassificationLabel
                            extraClasses="mb-1"
                            classification={getClassificationAttribute(idea.campaign.classificationSummary)}/>
                    }
                    <Link className="campaign-name text-primary text-break"
                          {...(editMode ? {tabIndex: -1} : {})}
                          to={
                              idea.campaign?.defaultStage
                                  ? appLinks.ideas(String(idea.campaign.id), idea.campaign.defaultStage.key)
                                  : appLinks.campaign(String(idea.campaign?.id))
                          }>
                        {idea.campaign?.name || ''}
                    </Link>
                    <h3 className="idea-title pb-2 text-break">
                        {
                            classificationEnabled && idea.attributes &&
                            <ClassificationLabel
                                classification={ClassificationUtil.getAttributeByName(
                                    CLASSIFICATION_NAME.EFFECTIVE_CLASSIFICATION,
                                    idea.attributes as ClassificationFieldAttribute[]
                                )}
                                extraClasses="me-2 align-middle"/>
                        }
                        {idea.title}
                    </h3>
                    <Link to={appLinks.ideaDetails(idea.id)}
                          className="btn btn-secondary mt-auto"
                          {...(editMode ? {tabIndex: -1} : {})}>
                        {localizer.msg('landing-page.components.common.read-more')}
                    </Link>
                </div>
            </div>
        );
    } else {
        return (
            <div className="trending-idea h-100 card panel">
                {
                    getFirstImageOfAttachments()?.url &&
                    <div className="flex-shrink-1 mx-auto">
                        <img className="img-fluid rounded-top" src={getFirstImageOfAttachments()?.url}
                             alt={getFirstImageOfAttachments()?.altText || getFirstImageOfAttachments()?.filename}/>
                    </div>
                }
                <div className={`idea-info card-body panel-body d-flex flex-column align-items-start`}>
                    {
                        classificationEnabled && idea.campaign?.classificationSummary &&
                        <ClassificationLabel
                            extraClasses="mb-1"
                            classification={getClassificationAttribute(idea.campaign.classificationSummary)}/>
                    }
                    <Link className="campaign-name text-primary text-break"
                          {...(editMode ? {tabIndex: -1} : {})}
                          to={
                              idea.campaign?.defaultStage
                                  ? appLinks.ideas(String(idea.campaign.id), idea.campaign.defaultStage.key)
                                  : appLinks.campaign(String(idea.campaign?.id))
                          }>
                        {idea.campaign?.name || ''}
                    </Link>
                    <h3 className="idea-title pb-2 text-break">
                        {
                            classificationEnabled && idea.attributes &&
                            <ClassificationLabel
                                classification={ClassificationUtil.getAttributeByName(
                                    CLASSIFICATION_NAME.EFFECTIVE_CLASSIFICATION,
                                    idea.attributes as ClassificationFieldAttribute[]
                                )}
                                extraClasses="me-2 align-middle"/>
                        }
                        {idea.title}
                    </h3>
                    {
                        summary &&
                        <HtmlRenderer className="idea-description text-truncate text-break" content={summary}/>
                    }
                    <Link className="btn btn-secondary mt-auto"
                          to={appLinks.ideaDetails(idea.id)}
                          {...(editMode ? {tabIndex: -1} : {})}>
                        {localizer.msg('landing-page.components.common.read-more')}
                    </Link>
                </div>
            </div>
        );
    }
};

export default IdeaCard;
