import {useCallback} from 'react';
import {useIdeaUpdater} from './useIdeaUpdater';
import {PermissionHolder} from 'models/IdeaDetail';

export const useIdeaPermissionHolder = () => {
    const {updateIdeaDetails} = useIdeaUpdater();

    const ideaDetailsPermissionHolderMutation = useCallback(async (ideaId: number, permissionHolder: PermissionHolder) => {
        await updateIdeaDetails(ideaId, 'permissionHolder', permissionHolder);
    }, [updateIdeaDetails]);

    return {ideaDetailsPermissionHolderMutation};
}