import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Localizer} from '@ideascale/commons';
import {useLeaderboardContext} from 'contexts/LeaderboardContext';
import {useLeaderboardPageParams} from 'hooks/useLeaderboardPageParams';
import {LeaderboardPagination} from './LeaderboardPagination';
import {LeaderboardParameters} from 'models/types/LeaderboardParameters';
import {TimePeriod} from 'models/enums/TimePeriod';
import {LeaderboardResponse} from 'models/LeaderboardResponse';
import {ROUTES} from 'shared/Routes';

type LeaderboardTabProps = {
    localizer: Localizer;
    actorId: number
    fetchLeaderboard: (parameters: LeaderboardParameters, timePeriod?: { mode: TimePeriod, unit: number }) => Promise<LeaderboardResponse>;
}

export const LeaderboardTab = (props: LeaderboardTabProps) => {
    const {localizer, fetchLeaderboard, actorId} = props;
    const navigate = useNavigate();
    const leaderboardType = useLeaderboardPageParams();
    const {campaign, group, timePeriod} = useLeaderboardContext();

    if (leaderboardType === null) {
        navigate(ROUTES.LEADERBOARD.BASE);
    }

    return (
        <div className="row">
            <div className="col-12">
                {
                    leaderboardType &&
                    <LeaderboardPagination
                        localizer={localizer} fetchLeaderboard={fetchLeaderboard} type={leaderboardType}
                        campaignId={campaign?.value} groupId={group?.value} timePeriod={timePeriod?.value}
                        actorId={actorId}
                    />
                }
            </div>
        </div>
    );
};
