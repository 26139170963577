import React from 'react';
import {Link} from 'react-router-dom';
import {Localizer, MemberLink} from '@ideascale/commons';
import {appLinks} from 'services/AppLinks';
import {NotificationData} from 'models/NotificationData';

type IdeaShareNotificationProps = {
    notification: NotificationData;
    localizer: Localizer;
}

export const IdeaShareNotification = (props: IdeaShareNotificationProps) => {
    const {notification, localizer} = props;
    return (
        <>
            <MemberLink id={notification.sourceMemberId} identityHidden={notification.identityHidden}>
                {notification.viewAttributes.sourceMemberName}
            </MemberLink>
            {' '}
            {localizer.msg('notification.common.has-shared')} {' '}
            <Link to={appLinks.ideaDetails(notification.ideaId)}>
                {notification.viewAttributes.ideaTitle}
            </Link>
            {' '} {localizer.msg('notification.common.with-you')}
        </>
    );
};