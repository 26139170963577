import React, {Fragment, useCallback, useEffect, useMemo, useRef} from 'react';
import {isEmpty} from 'lodash';
import {useNavigate, useParams} from 'react-router-dom';
import {Trans} from 'react-i18next';
import isEqual from 'lodash/isEqual';
import {Alert} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {PageTitle} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {useIdeaService} from 'hooks/useIdeaService';
import {useSuperCommentsAction} from 'hooks/useSuperCommentsAction';
import {useCommunityService} from 'hooks/useService';
import {useMessageService} from 'hooks/useMessageService';
import {useIdeaUpdater} from 'hooks/useIdeaUpdater';
import {useIdeaDetailsContext} from 'contexts/IdeaDetailsContext';
import {useIdeasContext} from 'contexts/IdeasContext';
import {LIST_NEXT_PAGE_THRESHOLD_OFFSET} from 'constants/AppConstants';
import {appLinks} from 'services/AppLinks';
import {IdeaDetailTabsContainer} from 'containers/IdeaDetailTabsContainer';
import {IdeaDetails} from 'components/idea/details/IdeaDetails';
import {IdeaDetailsNav} from 'components/idea/details/IdeaDetailsNav';
import {BackToList} from 'components/idea/details/BackToList';
import {IdeaSkeleton} from 'components/idea/IdeaSkeleton';
import {IdeaSummary} from 'models/IdeaSummary';
import {IdeaDetailsRouteMatchParams} from 'models/types/IdeaDetailsRouteMatchParams';
import {IdeaStagesData} from 'models/IdeaStagesData';
import {IdeaDetail} from 'models/IdeaDetail';

export const IdeaDetailsContainer = () => {
    const localizer = useLocalizer();
    const communityService = useCommunityService();
    const navigate = useNavigate();
    const params = useParams<IdeaDetailsRouteMatchParams>();
    const ideaId = +params?.ideaId!;
    const {ideaDetails, isFetchIdeaDetails} = useIdeaDetailsContext();
    const {ideaListData, ideaListPagePath, listPageUrlParams, updateIdeaListData} = useIdeasContext();
    const {replaceIdeaSummaryByDetails} = useIdeaUpdater();
    const originalIdeaDetailsRef = useRef<IdeaDetail>(ideaDetails);
    const {
        fetchMembers,
        fetchAssignedOwners,
        fetchRevisionHistory,
        fetchOriginalIdea,
        emailIdeaAuthor,
        fetchAuthorEmails,
        banMember,
        toggleIdeaFollowed,
        toggleAuthorFollowed,
        toggleCommentingEnabled,
        fetchModerateActions,
        fetchMoreActions,
        fetchIdeaLabels,
        togglePinIdeas,
        fetchFollowers,
        fetchOwners,
        fetchKudoGivers,
        unFurlUrl,
        fetchSuggestedTags,
        fetchTagsByTerm,
        fetchModeratorTagsByTerm,
        getAuthorEmailIdeaContent,
        getIdeaTeamMembers
    } = useIdeaService();

    const {fetchRecipients} = useMessageService();

    const approvePermissions = ideaDetails.permissionHolder && (ideaDetails.permissionHolder.moderationPermissionHolder.rejectIdeaEnabled);

    useEffect(() => {
        if (ideaDetails?.id <= 0) {
            originalIdeaDetailsRef.current = ideaDetails;
        }
    }, [ideaDetails]);

    const allIdeas = useMemo(() => {
        return ideaListData.pagedIdeas?.pages.flatMap((pageIdea) => pageIdea?.data) || [];
    }, [ideaListData.pagedIdeas]);

    const getCurrentIndex = useCallback(() => {
        let findIndex = -1;
        if (ideaId > 0) {
            findIndex = allIdeas?.findIndex(idea => idea?.id === ideaId) ?? -1;
        }
        return findIndex;
    }, [allIdeas, ideaId]);

    const updateListIdeaInBackground = useCallback(() => {
        if (!isEqual(originalIdeaDetailsRef.current, ideaDetails)) {
            replaceIdeaSummaryByDetails(ideaDetails.id);
        }
    }, [ideaDetails, replaceIdeaSummaryByDetails]);

    const onClickPreviousIdea = useCallback(() => {
        if (ideaId > 0) {
            updateListIdeaInBackground();
            const currentIndex = getCurrentIndex();
            if (!isEmpty(allIdeas) && currentIndex > 0) {
                navigate({
                    pathname: appLinks.ideaDetails((allIdeas[currentIndex - 1] as IdeaSummary).id)
                });
            }
        }
    }, [ideaId, updateListIdeaInBackground, allIdeas, getCurrentIndex, navigate]);

    const fetchNextPageData = useCallback(() => {
        const allIdeas = ideaListData.pagedIdeas?.pages.flatMap((pageIdea) => pageIdea?.data);
        const findIndex = allIdeas?.findIndex(idea => idea?.id === ideaId);
        const nextPageIdea = allIdeas && findIndex && allIdeas[findIndex + LIST_NEXT_PAGE_THRESHOLD_OFFSET];

        if (ideaListData.hasNextPage && nextPageIdea === undefined) {
            ideaListData.fetchNextPage().then(nextPageData => {
                updateIdeaListData({
                    pagedIdeas: nextPageData.data,
                    totalIdeas: nextPageData.data?.pages[0]?.totalIdeas || 0,
                    isLoading: nextPageData.isLoading,
                    refetch: nextPageData.refetch,
                    hasNextPage: nextPageData.hasNextPage || false,
                    hasPreviousPage: nextPageData.hasPreviousPage || false,
                    isFetchingPreviousPage: nextPageData.isFetchingPreviousPage,
                    isFetchingNextPage: nextPageData.isFetchingNextPage,
                    fetchNextPage: nextPageData.fetchNextPage,
                    fetchPreviousPage: nextPageData.fetchPreviousPage
                });
            });
        }
        return {allIdeas, findIndex};
    }, [ideaId, ideaListData, updateIdeaListData]);

    const onClickNextIdea = useCallback(() => {
        if (ideaId > 0) {
            updateListIdeaInBackground();
            const currentIndex = getCurrentIndex();

            if (!isEmpty(allIdeas) && currentIndex + 1 < allIdeas.length) {
                navigate({
                    pathname: appLinks.ideaDetails((allIdeas[currentIndex + 1] as IdeaSummary).id)
                });
            }
        }
    }, [ideaId, updateListIdeaInBackground, allIdeas, getCurrentIndex, navigate]);

    const backToListPage = useCallback(async () => {
        updateListIdeaInBackground();
        navigate({
            pathname: ideaListPagePath,
            search: listPageUrlParams,
            hash: `idea-${ideaDetails.id}`,
        }, {
            state: null
        });
    }, [navigate, ideaDetails.id, ideaListPagePath, listPageUrlParams, updateListIdeaInBackground]);

    const fetchIdeaStages = useCallback(async (ideaId: number): Promise<Array<IdeaStagesData>> => {
        if (communityService !== null) {
            return await communityService.fetchIdeaStages(ideaId);
        }
        return [];
    }, [communityService]);

    const {fetchMoreSuperCommentResponse, deleteSuperComment, reorderSuperComments} = useSuperCommentsAction();

    useEffect(() => {
        if (ideaId > 0 && ideaListData.hasNextPage) {
            fetchNextPageData();
        }
    }, [fetchNextPageData, ideaId, ideaListData.hasNextPage]);

    return (
        <Fragment>
            <PageTitle title={ideaDetails.title}/>
            {
                ideaListPagePath && getCurrentIndex() >= 0 && (
                    <BackToList localizer={localizer} onClick={backToListPage}/>
                )
            }

            {
                approvePermissions &&
                <Alert open={true} svgIconsPath={svgIconsPath}
                       message={
                           <Trans i18nKey="idea-details.approval-needed">
                               <a href={appLinks.approveIdeaModeration(ideaId, ideaDetails.seen)}>Incoming
                                   Moderation</a>
                           </Trans>
                       } variant="warning"/>
            }

            <div className={`box-shadow rounded mb-4`}>
                {
                    getCurrentIndex() >= 0 && (
                        <IdeaDetailsNav localizer={localizer}
                                        currentIndex={getCurrentIndex()}
                                        totalItems={ideaListData.totalIdeas}
                                        onClickPrev={onClickPreviousIdea}
                                        onClickNext={onClickNextIdea}/>
                    )
                }

                {
                    isFetchIdeaDetails ?
                        <IdeaSkeleton details={true}/>
                        :
                        <IdeaDetails localizer={localizer}
                                     content={ideaDetails}
                                     fetchIdeaStages={fetchIdeaStages}
                                     emailIdeaAuthor={emailIdeaAuthor}
                                     fetchAuthorEmails={fetchAuthorEmails}
                                     banMember={banMember}
                                     togglePinIdeas={togglePinIdeas}
                                     fetchAssignedOwners={fetchAssignedOwners}
                                     fetchModerateActions={fetchModerateActions}
                                     fetchRevisionHistory={fetchRevisionHistory}
                                     fetchOriginalIdea={fetchOriginalIdea}
                                     fetchMoreActions={fetchMoreActions}
                                     fetchIdeaLabels={fetchIdeaLabels}
                                     toggleIdeaFollowed={toggleIdeaFollowed}
                                     toggleAuthorFollowed={toggleAuthorFollowed}
                                     toggleCommentingEnabled={toggleCommentingEnabled}
                                     fetchMembers={fetchMembers}
                                     fetchFollowers={fetchFollowers}
                                     fetchOwners={fetchOwners}
                                     fetchKudoGivers={fetchKudoGivers}
                                     fetchSuperComments={fetchMoreSuperCommentResponse}
                                     deleteSuperComment={deleteSuperComment}
                                     reorderSuperComments={reorderSuperComments}
                                     fetchSuggestedTags={fetchSuggestedTags}
                                     fetchTagsByTerm={fetchTagsByTerm}
                                     fetchModeratorTagsByTerm={fetchModeratorTagsByTerm}
                                     unFurlUrl={unFurlUrl}
                                     totalIdeas={ideaListData.totalIdeas}
                                     getCurrentIndex={getCurrentIndex}
                                     navigateNextIdea={onClickNextIdea}
                                     fetchRecipients={fetchRecipients}
                                     getAuthorEmailIdeaContent={getAuthorEmailIdeaContent}
                                     getIdeaTeamMembers={getIdeaTeamMembers}

                        />
                }

                {
                    ideaDetails.id !== 0 &&
                    <IdeaDetailTabsContainer ideaId={ideaDetails.id} defaultTab={ideaDetails.defaultTab}
                                             stageId={ideaDetails.stageSummary.stage.id}
                                             stageCommentOptions={ideaDetails.commentOptionsHolder.commentOptions}
                                             campaignId={ideaDetails.campaign?.id || 0}/>
                }

            </div>
        </Fragment>
    );
};