import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {Popover, PopoverBody} from 'reactstrap';
import * as Popper from 'popper.js';
import {Avatar, Scrollbar, ScrollbarInstance} from '@ideascale/ui';
import {
    InfiniteScrollLoadMoreButton,
    Member,
    MemberLink,
    useApiErrorResponseHandler,
    useIntersectionObserver
} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import './InfiniteUserPopover.scss';

type InfiniteUserPopoverProps = {
    actorId: number;
    id: number;
    selector: string;
    isOpen: boolean;
    toggle: () => void;
    container?: string | HTMLElement | React.RefObject<HTMLElement>;
    placement?: Popper.Placement;
    header: string;
    pageSize?: number;
    fetchUsers: (pageParameters: PageParameters, id: number) => Promise<PagedResponseContent<Member>>
}

export const InfiniteUserPopover = (props: InfiniteUserPopoverProps) => {
    const {
        id,
        selector,
        isOpen,
        toggle,
        container,
        placement = 'right',
        header,
        fetchUsers,
        pageSize = 30,
        actorId
    } = props;
    const localizer = useLocalizer();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const scrollbarContainer = useRef<ScrollbarInstance>(null);
    const loadMoreButtonRef = useRef<HTMLButtonElement>(null);
    const [members, setMembers] = useState<Member[]>([]);
    const [pagination, setPagination] = useState({currentPage: 0, hasMore: true});

    const loadUsers = () => {
        fetchUsers({page: pagination.currentPage + 1, limit: pageSize}, id).then(data => {
            setPagination({currentPage: data.pageNo, hasMore: data.hasMore});
            setMembers(prevData => [...prevData, ...data.content]);
        }).catch(e => {
            handleErrorResponse(e);
        });
    };

    const loadInitialUsers = useCallback(() => {
        fetchUsers({page: 0, limit: pageSize}, id)
            .then(data => {
                setMembers(data.content);
                setPagination({currentPage: data.pageNo, hasMore: data.hasMore});
            }).catch(e => {
            handleErrorResponse(e);
        });
    }, [fetchUsers, handleErrorResponse, id, pageSize]);

    useIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: loadUsers,
        enabled: pagination.hasMore,
        options: {
            root: scrollbarContainer.current?.container
        }
    });

    useEffect(() => {
        if (id > 0) {
            loadInitialUsers();
        }
    }, [id, loadInitialUsers]);

    return (
        <Fragment>
            {
                members.length > 0 && (
                    <Popover className="member-list-popover"
                             container={container}
                             trigger="legacy"
                             placement={placement}
                             target={selector} isOpen={isOpen} toggle={toggle}>
                        <PopoverBody>
                            <Scrollbar
                                className="scrollable-member-list"
                                autoHeight
                                autoHeightMax={400}
                                ref={scrollbarContainer}>
                                <ul className="list-unstyled member-list">
                                    <li className="member-list-header">{header}</li>
                                    {
                                        members.map((member, index) => {
                                                return <li key={index} className="m-2">
                                                    <MemberLink id={member.id} identityHidden={member.identityHidden}
                                                                className="d-flex">
                                                        <Avatar src={member.avatar} size="sm"
                                                                alt={member.username ? localizer.msg('common.user-avatar', {username: member.username}) : ''}/>
                                                        <div className="member-list-item-content ms-2">
                                                            <strong>{actorId === member.id ? localizer.msg('common.you') : member.name}</strong>
                                                            {member.username && <small>@{member.username}</small>}
                                                        </div>
                                                    </MemberLink>
                                                </li>;
                                            }
                                        )
                                    }
                                </ul>
                                <InfiniteScrollLoadMoreButton hasMore={pagination.hasMore}
                                                              loading={false}
                                                              localizer={localizer}
                                                              onCLick={loadUsers}
                                                              loadMoreButtonRef={loadMoreButtonRef}/>
                            </Scrollbar>

                        </PopoverBody>
                    </Popover>
                )
            }
        </Fragment>
    );
};