import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Trans} from 'react-i18next';
import {
    AlertEvent,
    AlertType,
    buildAlertEventData,
    eventDispatcher,
    Member,
    MemberLink,
    TimeAgo,
    useToggle
} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {RevisionHistoryView} from 'components/RevisionHistoryView';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {RevisionHistory} from 'models/RevisionHistory';
import {PageParameters} from 'models/types/PageParameters';

type IdeaRevisionHistoryProps = {
    ideaId: number;
    lastEditedAt: string;
    lastEditedBy: Member;
    participationAllowed: boolean;
    fetchIdeaRevisionHistory: (ideaId: number, pageParameters: PageParameters) => Promise<PagedResponseContent<RevisionHistory>>
}

const PAGE_SIZE = 5;

export const IdeaRevisionHistory = (props: IdeaRevisionHistoryProps) => {
    const {ideaId, lastEditedAt, lastEditedBy, fetchIdeaRevisionHistory, participationAllowed} = props;
    const localizer = useLocalizer();
    const {authentication: {actor}} = useAppContext();
    const [ideaRevisionHistoryOpen, toggleIdeaRevisionHistory] = useToggle(false);
    const [revisions, setRevisions] = useState<RevisionHistory[]>([]);
    const [pagination, setPagination] = useState({currentPage: 0, hasMore: true});

    const loadMoreData = async () => {
        try {
            const {content, hasMore, pageNo} = await fetchIdeaRevisionHistory(ideaId, {
                page: pagination.currentPage + 1,
                limit: PAGE_SIZE
            });
            setPagination({currentPage: pageNo, hasMore});
            setRevisions(revisions.concat(content));
        } catch (e: any) {
            showAlert();
        }
    };

    const showAlert = useCallback(() => {
        eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.error, localizer.msg('idea.revision-history.failed')));
    }, [localizer]);

    useEffect(() => {
        if (ideaRevisionHistoryOpen) {
            fetchIdeaRevisionHistory(ideaId, {page: 0, limit: PAGE_SIZE})
                .then(({content, hasMore, pageNo}) => {
                    setPagination({currentPage: pageNo, hasMore});
                    setRevisions(content);
                })
                .catch(() => showAlert());
        }
    }, [fetchIdeaRevisionHistory, ideaId, ideaRevisionHistoryOpen, showAlert]);

    return (
        <div className="mb-3">
            <span className="text-muted">
                <Trans i18nKey="idea.revision-history.last-edited-by"
                       values={{username: actor.id === lastEditedBy.id ? localizer.msg('common.you') : lastEditedBy.name}}>
                    <MemberLink className="fw-bold" id={lastEditedBy.id}
                                identityHidden={lastEditedBy.identityHidden}/>
                </Trans>
                {' '}
                <TimeAgo localizer={localizer} dateISOString={lastEditedAt}/>

            </span>
            {
                participationAllowed &&
                <Fragment>
                    {' '}
                    <button className="btn btn-link p-0 mb-1 shadow-none text-decoration-none"
                            id="idea-revision-history"
                            onClick={toggleIdeaRevisionHistory}>
                        {localizer.msg('idea.revision-history.label', {
                            action: ideaRevisionHistoryOpen
                                ? localizer.msg('common.hide')
                                : localizer.msg('common.show')
                        })}
                    </button>
                    <RevisionHistoryView
                        localizer={localizer}
                        revisions={revisions}
                        actorId={actor.id}
                        hasMore={pagination.hasMore}
                        loadMore={loadMoreData}
                        editedText={localizer.msg('idea.revision-history.edited')}
                        isOpen={ideaRevisionHistoryOpen}
                        scrollTargetId={`scrollable-revision-history-${ideaId}`}
                    />
                </Fragment>
            }
        </div>
    );
};