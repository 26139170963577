import React, {Fragment} from 'react';
import {ClassificationFieldAttribute, emptyFunction, useRoutesMatch} from '@ideascale/commons';
import {Icon} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useAppContext} from 'contexts/AppContext';
import {ROUTES} from 'shared/Routes';
import {StageCallToAction} from '../stage-actions/StageCallToAction';
import {IdeateStageCallToAction} from '../stage-actions/IdeateStageCallToAction';
import {useLocalizer} from 'hooks/useLocalizer';
import {StageFunctionName} from 'models/enums/StageFunctionName';
import {BaseIdeaStageSummary} from 'models/BaseIdeaStageSummary';
import {IdeateStageSummary} from 'models/IdeateStageSummary';
import {FundStageSummary} from 'models/FundStageSummary';
import {RefineStageSummary} from 'models/RefineStageSummary';
import {BuildTeamStageSummary} from 'models/BuildTeamStageSummary';
import {EstimateStageSummary} from 'models/EstimateStageSummary';
import {BasicStageCallToAction} from '../stage-actions/BasicStageCallToAction';
import {PendingStageSummary} from 'models/PendingStageSummary';
import {RecycleBinStageSummary} from 'models/RecycleBinStageSummary';
import {VoteParameters} from 'models/VoteParameters';
import {VoteType} from 'models/enums/VoteType';
import {OffTopicStageSummary} from 'models/OffTopicStageSummary';
import {InboundStageSummary} from 'models/InboundStageSummary';
import {BasicStagesId} from 'models/enums/BasicStagesId';

type StageActionSummaryProps<T extends BaseIdeaStageSummary> = {
    ideaStageSummary: T;
    onClickAction?: () => void;
    onVote?: (voteParameters: VoteParameters, voteType: VoteType) => Promise<boolean>;
    toggleCopyIdeaFormModal?: (event: React.MouseEvent) => void;
    copyIdeaAllowed?: boolean;
    classification? : ClassificationFieldAttribute[]
}

export const StageAction = (props: StageActionSummaryProps<BaseIdeaStageSummary>) => {
    const {
        ideaStageSummary,
        onClickAction = emptyFunction,
        onVote = () => Promise.resolve(false),
        toggleCopyIdeaFormModal,
        copyIdeaAllowed,
        classification
    } = props;

    const ideaDetailsRouteMatch = useRoutesMatch(ROUTES.IDEA_DETAILS.BASE_WITH_WILDCARD);
    const localizer = useLocalizer();
    const {communityConfig: {netVotesEnabled, voteDownEnabled}} = useAppContext();

    const isDisabled = () => {
        return !(ideaStageSummary.participationAllowed || ideaStageSummary.viewAllowed);
    };

    const canNotParticipate = () => {
        if (ideaStageSummary instanceof FundStageSummary) {
            return !(ideaStageSummary.participationAllowed && !ideaStageSummary.fundPledgedByActor);
        } else if (ideaStageSummary instanceof RefineStageSummary) {
            return !(ideaStageSummary.participationAllowed && (ideaStageSummary.refineCompleteFieldCount !== ideaStageSummary.refineFieldCount));
        } else if (ideaStageSummary instanceof BuildTeamStageSummary) {
            return !(ideaStageSummary.participationAllowed && !ideaStageSummary.isTeamMember);
        } else if (ideaStageSummary instanceof EstimateStageSummary) {
            return !(ideaStageSummary.participationAllowed && !ideaStageSummary.estimatedByActor);
        } else {
            return !ideaStageSummary.participationAllowed;
        }
    };

    const renderStageAction = () => {
        switch (ideaStageSummary.stage?.name.toLowerCase()) {
            case StageFunctionName.IDEATE:
                return <IdeateStageCallToAction ideaStageSummary={ideaStageSummary as IdeateStageSummary}
                                                onVote={onVote}
                                                classification={classification}
                                                netVotesEnabled={netVotesEnabled}
                                                voteDownEnabled={voteDownEnabled}/>;
            case StageFunctionName.PENDING:
            case StageFunctionName.RECYCLE_BIN:
            case StageFunctionName.OFFTOPIC:
            case StageFunctionName.INBOUND:
                const stageSummary = (ideaStageSummary as PendingStageSummary) || (ideaStageSummary as RecycleBinStageSummary) || (ideaStageSummary as OffTopicStageSummary) || (ideaStageSummary as InboundStageSummary);
                return <BasicStageCallToAction stageName={stageSummary.stage?.name} netVote={stageSummary.netVoteCount}  classification={classification}
                                               netVotesEnabled={netVotesEnabled}/>;
            default:
                return <StageCallToAction className={`${canNotParticipate() ? 'only-view-allowed' : ''}`}
                                          disabled={isDisabled()}
                                          classification={classification}
                                          stageFnKey={ideaStageSummary.stage?.name}
                                          stageIconName={typeof ideaStageSummary.getIconName === 'function' ? ideaStageSummary.getIconName() : ''}
                                          label={ideaStageSummary.callToActionLabel} onClick={onClickAction}/>;
        }
    };

    return (
        <Fragment>
            {renderStageAction()}

            {
                ideaDetailsRouteMatch && ideaStageSummary.stage.id === BasicStagesId.RECYCLE_BIN && copyIdeaAllowed &&
                <button className="btn btn-secondary stage-action btn-copy-idea"
                        onClick={(event) => toggleCopyIdeaFormModal!(event)}>
                    <Icon iconSpritePath={svgIconsPath} name="copy" className="active mt-n1 me-1"
                          fill="#384EC1"/> {localizer.msg('idea.share.copy-title')}
                </button>
            }

        </Fragment>
    );
};