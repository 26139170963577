import React from 'react';
import {Button} from '@ideascale/ui';
import {useLocalizer} from 'hooks/useLocalizer';

type ActionItemHeaderProps = {
    id: string;
    groupTitle: string;
    seeAllLink: boolean;
    onSeeAllClick?: () => void;
}

export const ActionItemHeader = (props: ActionItemHeaderProps) => {
    const {id, groupTitle, seeAllLink, onSeeAllClick} = props;
    const localizer = useLocalizer();

    return (
        <div className="mx-3 my-2 py-2 action-item-header d-flex justify-content-between align-items-center">
            <span id={id} className="fw-bold">{groupTitle}</span>
            {
                seeAllLink
                    ? <Button color="link" className="p-0 w-auto bg-transparent dropdown-item"
                              onClick={onSeeAllClick}>
                        {localizer.msg('topbar.action-items.see-all')}
                    </Button>
                    : null
            }
        </div>
    );
};