import {Command} from './Command';

export class CommandExecutor {
    public static EMPTY = new CommandExecutor();

    private executedCommands: Command[];
    private onExecuteCallback: (executedCommands: Command[]) => void;

    constructor() {
        this.executedCommands = [];
    }

    reset() {
        this.executedCommands = [];
    }

    execute(command: Command) {
        command.execute();
        this.executedCommands.push(command);
        this.executeCallback();
    }

    undo() {
        const command = this.executedCommands.pop();
        command?.undo();
    }

    getLastCommands() {
        return this.executedCommands.reverse().reduce((accumulator: Command[], currentValue) => {
            return accumulator.findIndex(value => value.getType() === currentValue.getType()) > -1
                ? accumulator
                : [...accumulator, currentValue];
        }, []);
    }

    onExecute(callback: (executedCommands: Command[]) => void) {
        this.onExecuteCallback = callback;
    }

    private executeCallback() {
        this.onExecuteCallback(this.executedCommands);
    }
}