import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Link, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {
    ButtonDropdown,
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavbarToggler
} from 'reactstrap';
import {kebabCase} from 'lodash';
import {Icon} from '@ideascale/ui';
import {CommentType, eventDispatcher, useRoutesMatch, useToggle} from '@ideascale/commons';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useLocalizer} from 'hooks/useLocalizer';
import {CommentsContainer} from './CommentsContainer';
import {AttachmentsContainer} from './AttachmentsContainer';
import {IdeaActivityContainer} from './IdeaActivityContainer';
import {ROUTES} from 'shared/Routes';
import {appLinks} from 'services/AppLinks';
import {Scroller} from 'utils/Scroller';
import {CommonUtil} from 'utils/CommonUtil';
import {SCROLL_EVENTS} from 'constants/AppConstants';
import {IdeaDetailsTab} from 'models/enums/IdeaDetailsTab';
import {CommentOrder} from 'models/comments/CommentOrder';
import {SortItem} from 'models/comments/SortItem';
import {StageCommentOption} from 'models/comments/StageCommentOption';
import './IdeaDetailTabsContainer.scss';


type IdeaDetailTabsContainerProps = {
    ideaId: number;
    defaultTab?: IdeaDetailsTab;
    stageCommentOptions: StageCommentOption[];
    stageId: number;
    campaignId: number;
}

const STAGE_COMMENT_ROUTES = [ROUTES.IDEA_DETAILS.STAGE_COMMENTS_PATH, ROUTES.IDEA_DETAILS.STAGE_COMMENT_PATH];

export const IdeaDetailTabsContainer = (props: IdeaDetailTabsContainerProps) => {
    const {ideaId, defaultTab = IdeaDetailsTab.COMMENTS, stageCommentOptions, stageId, campaignId} = props;
    const localizer = useLocalizer();

    const sortingMenuItems = useMemo(() => {
        return [
            {
                icon: 'arrow-down-lines-narrow-wide',
                order: CommentOrder.DATE_NEWEST,
                name: localizer.msg('idea.comment.sort-options.newest')
            },
            {
                icon: 'lines-wide-narrow-arrow-up',
                order: CommentOrder.DATE_OLDEST,
                name: localizer.msg('idea.comment.sort-options.oldest')
            },
            {
                icon: 'ballot-box',
                order: CommentOrder.VOTES_MOST,
                name: localizer.msg('idea.comment.sort-options.votes')
            },
        ];
    }, [localizer]);
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const matchedRoute = useRoutesMatch<any>(STAGE_COMMENT_ROUTES);
    const [activeTab, setActiveTab] = useState<IdeaDetailsTab>(defaultTab);
    const [activeSort, setActiveSort] = useState<SortItem>(sortingMenuItems[0]);
    const [sortingDropdownOpen, toggleSortingDropdown] = useToggle(false);
    const [openCommentDropdown, toggleCommentDropdown] = useToggle(false);
    const [navbarOpen, toggleNavbar] = useToggle(false);
    const isMounted = useRef(false);

    const defaultCommentOption = {
        stageId: 0,
        stageLabel: localizer.msg('idea-details.tab.community'),
        commentType: CommentType.COMMUNITY,
        commentingEnabled: true
    };

    const [selectedCommentOption, setSelectedCommentOption] = useState<StageCommentOption>(defaultCommentOption);

    const toggle = useCallback((tab: IdeaDetailsTab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }, [activeTab]);

    const scrollToTabs = () => {
        if (!isMounted.current) {
            Scroller.scrollTo('idea-detail-tabs');
            isMounted.current = true;
        }
    };

    useEffect(() => {
        eventDispatcher.addListener(SCROLL_EVENTS.DETAILS_TABS, () => {
            Scroller.scrollTo('idea-detail-tabs', {offset: -150});
        });
        return () => {
            eventDispatcher.removeListener(SCROLL_EVENTS.DETAILS_TABS);
        };
    }, []);

    useEffect(() => {
        const pathSplit = pathname.split('/');
        if (pathSplit.includes(IdeaDetailsTab.COMMENTS.toLowerCase())) {
            toggle(IdeaDetailsTab.COMMENTS);
            if (pathSplit.includes('stage')) {
                const selectedCommentOption = stageCommentOptions.find(option => option.stageId === +matchedRoute?.params?.stageId);
                if (selectedCommentOption) {
                    setSelectedCommentOption(selectedCommentOption);
                } else {
                    navigate(appLinks.ideaDetailsComment(ideaId));
                }
            }
            scrollToTabs();
        } else if (pathSplit.includes(IdeaDetailsTab.ATTACHMENTS.toLowerCase())) {
            toggle(IdeaDetailsTab.ATTACHMENTS);
            scrollToTabs();
        } else if (pathSplit.includes(IdeaDetailsTab.ACTIVITY.toLowerCase())) {
            toggle(IdeaDetailsTab.ACTIVITY);
            CommonUtil.wait(100).then(() => {
                scrollToTabs();
            });
        } else {
            setActiveTab(defaultTab);
            isMounted.current = true;
        }
    }, [defaultTab, ideaId, matchedRoute?.params?.stageId, navigate, pathname, stageCommentOptions, toggle]);

    const renderDefaultTab = () => {
        switch (activeTab) {
            case IdeaDetailsTab.ACTIVITY:
                return <IdeaActivityContainer ideaId={ideaId} stageId={stageId} campaignId={campaignId}/>;
            case IdeaDetailsTab.COMMENTS:
                return <CommentsContainer ideaId={ideaId} sortBy={activeSort}
                                          commentType={selectedCommentOption.commentType}
                                          stageCommentOption={selectedCommentOption}
                                          hasStageCommentOptions={stageCommentOptions.length > 0}
                                          campaignId={campaignId}/>;
            case IdeaDetailsTab.ATTACHMENTS:
                return <AttachmentsContainer ideaId={ideaId}/>;
            default:
                return <Fragment/>;
        }
    };

    const onSortingClick = (value: SortItem) => {
        setActiveSort(value);
        navigate({pathname: appLinks.ideaDetailsComment(ideaId)});
    };

    return (
        <section className="idea-detail-tabs border-top-1">
            <div className="d-flex justify-content-between align-items-baseline mb-4">
                <Navbar expand="md" className="border-bottom-0 d-inline-flex align-items-center px-0" aria-label="Idea Detail Tabs">
                    <NavbarToggler onClick={toggleNavbar} className="px-0">
                        <Icon className="position-relative pos-top-n1 me-2" iconSpritePath={svgIconsPath}
                              name="lines-three-horizontal" width={25}
                              height={25}/>
                        {
                            localizer.msg(`idea-details.tab.${activeTab.toLowerCase()}`)
                        }
                    </NavbarToggler>
                    <Collapse isOpen={navbarOpen} navbar>
                        <Nav className="me-auto fw-bold" navbar>
                            <li className="nav-item mb-2 mb-lg-0">
                                <Link
                                    className={`btn btn-${activeTab === IdeaDetailsTab.ACTIVITY ? 'primary' : 'light'} me-2`}
                                    data-test-element-id="idea-activity"
                                    to={appLinks.ideaDetailsActivity(ideaId)}
                                    onClick={() => {
                                        toggle(IdeaDetailsTab.ACTIVITY);
                                        toggleNavbar(false);
                                    }}>{localizer.msg('idea-details.tab.activity')}
                                </Link>
                            </li>
                            <li className="nav-item mb-2 mb-lg-0">
                                {
                                    stageCommentOptions && stageCommentOptions.length > 0
                                        ? <ButtonDropdown isOpen={openCommentDropdown} toggle={toggleCommentDropdown}
                                                          className="text-start">
                                            <DropdownToggle tag="button" caret type="button"
                                                            data-test-element-id="idea-comments"
                                                            className={`btn btn-${activeTab === IdeaDetailsTab.COMMENTS ? 'primary' : 'light'} me-2`}>
                                                {localizer.msg('idea-details.tab.comments')}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {
                                                    [defaultCommentOption, ...stageCommentOptions].map(option =>
                                                        <DropdownItem
                                                            key={option.stageId}
                                                            className={selectedCommentOption.stageId === option.stageId && activeTab === IdeaDetailsTab.COMMENTS ? 'active' : ''}
                                                            data-test-element-id={`${kebabCase(option.stageLabel)}-comments`}
                                                            onClick={() => {
                                                                setSelectedCommentOption(option);
                                                                toggleNavbar(false);
                                                                navigate({
                                                                    pathname: option.commentType === CommentType.COMMUNITY ? appLinks.ideaDetailsComment(ideaId) : appLinks.ideaDetailsStageComment(ideaId, option.stageId)
                                                                });
                                                            }}>
                                                            {option.stageLabel}
                                                        </DropdownItem>)
                                                }
                                            </DropdownMenu>
                                        </ButtonDropdown>
                                        :
                                        <Link
                                            className={`btn btn-${activeTab === IdeaDetailsTab.COMMENTS ? 'primary' : 'light'} me-2`}
                                            to={appLinks.ideaDetailsComment(ideaId)}
                                            data-test-element-id="idea-comments"
                                            onClick={() => {
                                                toggle(IdeaDetailsTab.COMMENTS);
                                                toggleNavbar(false);
                                            }}>{localizer.msg('idea-details.tab.comments')}
                                        </Link>
                                }
                            </li>
                            {
                                <li className="nav-item">
                                    <Link
                                        className={`btn btn-${activeTab === IdeaDetailsTab.ATTACHMENTS ? 'primary' : 'light'} me-2`}
                                        data-test-element-id="idea-attachments"
                                        to={appLinks.ideaDetailsAttachment(ideaId)}
                                        onClick={() => {
                                            toggle(IdeaDetailsTab.ATTACHMENTS);
                                            toggleNavbar(false);
                                        }}>{localizer.msg('idea-details.tab.attachments')}
                                    </Link>
                                </li>
                            }

                        </Nav>
                    </Collapse>
                </Navbar>
                {
                    activeTab === IdeaDetailsTab.COMMENTS && selectedCommentOption.commentType === CommentType.COMMUNITY && (
                        <ButtonDropdown className="comment-sort-menu" isOpen={sortingDropdownOpen}
                                        toggle={toggleSortingDropdown}>
                            <DropdownToggle caret
                                            className="text-gray fw-normal border-0 shadow-none d-inline-flex align-items-center">
                                <span className="d-flex align-items-center pointer-events-none">
                                    <Icon iconSpritePath={svgIconsPath} width={16} height={16} name={activeSort.icon}/>
                                    <span className="ms-2"> {activeSort.name}</span>
                                </span>
                            </DropdownToggle>
                            <DropdownMenu>
                                {
                                    sortingMenuItems.map((item: SortItem, index) => (
                                        <DropdownItem key={index}
                                                      className={`${item.name === activeSort.name ? 'active' : ''}`}
                                                      onClick={() => onSortingClick(item)}>
                                            <Icon iconSpritePath={svgIconsPath} width={16} height={16}
                                                  name={item.icon}/>
                                            <span className="ms-2">{item.name} </span>
                                        </DropdownItem>
                                    ))
                                }
                            </DropdownMenu>
                        </ButtonDropdown>
                    )
                }
            </div>
            <Routes>
                <Route path={ROUTES.IDEA_DETAILS.ACTIVITY_BASE}
                       element={<IdeaActivityContainer ideaId={ideaId} stageId={stageId} campaignId={campaignId}/>}/>
                {
                    [ROUTES.IDEA_DETAILS.COMMENTS_WITH_WILDCARD, ROUTES.IDEA_DETAILS.STAGE_COMMENTS_WITH_WILDCARD].map((path) => (
                        <Route path={path} key={path} element={
                            <CommentsContainer ideaId={ideaId} sortBy={activeSort}
                                               commentType={selectedCommentOption.commentType}
                                               stageCommentOption={selectedCommentOption}
                                               hasStageCommentOptions={stageCommentOptions.length > 0}
                                               campaignId={campaignId}/>
                        }/>
                    ))
                }
                <Route path={ROUTES.IDEA_DETAILS.ATTACHMENTS} element={
                    <AttachmentsContainer ideaId={ideaId}/>}/>
                <Route path="*" element={renderDefaultTab()}/>
            </Routes>
        </section>
    );
};
