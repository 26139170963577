import {PagedResponseContent} from './PagedResponseContent';
import {Member} from '@ideascale/commons';

export class AdminModeratorData {
    static readonly EMPTY = new AdminModeratorData(PagedResponseContent.nullObject(), false);
    public constructor(
        public powerUsers: PagedResponseContent<Member>,
        public managePowerUsers: boolean,
    ) {
    }
}