import React, {Fragment} from 'react';
import {Icon, Modal} from '@ideascale/ui';
import {
    ClassificationSummary,
    EmailIdeaContent,
    EmailIdeaModal,
    EmailRequest,
    EmailResponse,
    Member,
    PrivateMessageRequestParams,
    useToggle
} from '@ideascale/commons';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useLocalizer} from 'hooks/useLocalizer';
import {useFileUploadService} from 'hooks/useFileUploadService';
import {useClassificationService} from 'hooks/useClassificationService';
import {useAppContext} from 'contexts/AppContext';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {InternalShareModal} from 'components/share/InternalShareModal';
import {ShareableLink} from './ShareableLink';
import {SocialShare} from './SocialShare';
import './ShareModal.scss';

type ShareModalProps = {
    open: boolean;
    toggle: () => void;
    title: string;
    socialShareURLs: { facebook: string, twitter: string, linkedin: string };
    shareContentId?: number;
    shareContentTitle?: string;
    emailIdeaAllowed?: boolean;
    shareableText: string;
    shareableLink: string;
    internalMessageAdditionalURL?: string;
    emailShareModalTitle?: string;
    emailSubject?: string;
    internalShareButtonTitle?: string;
    getEmailContent?: () => Promise<EmailIdeaContent>;
    onSendMessage?: (requestParams: PrivateMessageRequestParams) => Promise<void>;
    emailIdeaSendByRecipientIds?: (requestParams: EmailRequest) => Promise<void>;
    emailIdeaSendByRecipientEmails?: (requestParams: EmailRequest) => Promise<EmailResponse | void>;
    getEmailRecipients: (parameters: PageParameters) => Promise<PagedResponseContent<Member>>;
    emailInvitationText?: string;
    sharable: boolean;
    moderatorDigest?: boolean;
    classificationSummary?: ClassificationSummary;
    campaignId?: number;
    privateCampaign: boolean;
}

export const ShareModal = (props: ShareModalProps) => {
    const {
        open,
        toggle,
        shareContentId,
        title,
        shareContentTitle,
        emailIdeaAllowed = false,
        socialShareURLs,
        shareableText,
        internalMessageAdditionalURL,
        emailShareModalTitle,
        getEmailContent,
        onSendMessage,
        shareableLink,
        internalShareButtonTitle,
        emailIdeaSendByRecipientIds,
        emailIdeaSendByRecipientEmails,
        getEmailRecipients,
        emailInvitationText,
        sharable,
        moderatorDigest,
        emailSubject,
        classificationSummary,
        campaignId,
        privateCampaign
    } = props;
    const localizer = useLocalizer();
    const {
        communityConfig: {
            ideaEmailShareEnabled,
            name: communityName,
            maxFileSizeLimit,
            offensiveEmojis,
            classificationEnabled,
            privateMessageEnabled
        }, authentication: {actor}
    } = useAppContext();
    const {tempImageUpload} = useFileUploadService();
    const {fetchClassifications} = useClassificationService();
    const [memberShareIdeaOpen, toggleMemberShareIdeaOpen] = useToggle(false);
    const [emailIdeaOpen, toggleEmailIdeaModal] = useToggle(false);

    return (
        <Fragment>
            <Modal isOpen={open}
                   toggle={toggle}
                   modalHeaderId="share-header"
                   className="share-idea-modal"
                   title={title}>
                {shareableText &&
                    <div className="row mx-0">
                        <ShareableLink shareableText={shareableText} shareableLink={shareableLink}
                                       internalShareButtonTitle={internalShareButtonTitle}/>
                    </div>
                }
                <div className="internal-share mt-4 mb-3">
                    <p className="fw-bold mb-3">{localizer.msg('common.share-message')}</p>
                    {
                        privateMessageEnabled && sharable && (
                            <button className="btn btn-cancel p-0 me-4 mt-n1"
                                    title={localizer.msg('common.share-via-message')}
                                    onClick={toggleMemberShareIdeaOpen}>
                                <Icon name="lightbulb-curved-arrow-right" fill="#999" width={20} height={20}
                                      iconSpritePath={svgIconPath}/>
                            </button>
                        )
                    }
                    {
                        emailIdeaAllowed &&
                        <button className="btn btn-cancel p-0 me-4 mt-n1"
                                title={localizer.msg('common.share-via-email')}
                                onClick={toggleEmailIdeaModal}>
                            <Icon name="envelope" fill="#999" width={20} height={20}
                                  iconSpritePath={svgIconPath}/>
                        </button>
                    }
                    <SocialShare socialShareURLs={socialShareURLs}/>
                </div>
                <div className="float-end pb-3">
                    <button type="button" className="btn btn-cancel me-3" onClick={toggle}>
                        {localizer.msg('common.cancel')}
                    </button>
                    <button type="button" className="btn btn-primary" onClick={toggle}>
                        {localizer.msg('common.done')}
                    </button>
                </div>
            </Modal>
            {
                memberShareIdeaOpen &&
                <InternalShareModal
                    shareContentTitle={shareContentTitle}
                    open={memberShareIdeaOpen}
                    shareableText={shareableText}
                    toggle={toggleMemberShareIdeaOpen}
                    shareableLink={shareableLink}
                    internalMessageAdditionalURL={internalMessageAdditionalURL}
                    onSendMessage={onSendMessage}
                    fetchRecipients={getEmailRecipients}
                    classificationSummary={classificationSummary}
                    campaignId={campaignId}
                />
            }
            {
                emailIdeaOpen && (
                    ideaEmailShareEnabled && !privateCampaign
                        ? <EmailIdeaModal open={emailIdeaOpen}
                                          toggle={toggleEmailIdeaModal}
                                          localizer={localizer}
                                          svgIconsPath={svgIconPath}
                                          maxFileSizeLimit={maxFileSizeLimit}
                                          emailContentTitle={shareContentTitle || ''}
                                          tempImageUpload={tempImageUpload}
                                          mode="RECIPIENT_EMAILS"
                                          communityName={communityName}
                                          actorName={actor.name}
                                          getEmailContent={getEmailContent}
                                          emailContentId={shareContentId}
                                          offensiveEmojis={offensiveEmojis}
                                          modalTitle={emailShareModalTitle}
                                          emailByRecipientEmails={emailIdeaSendByRecipientEmails}
                                          emailInvitationText={emailInvitationText}
                                          emailSubject={emailSubject}
                                          classificationEnabled={classificationEnabled}
                                          fetchClassifications={() => fetchClassifications(campaignId)}
                                          classificationSummary={classificationSummary}
                                          moderatorDigest={moderatorDigest}
                        />
                        : <EmailIdeaModal open={emailIdeaOpen}
                                          toggle={toggleEmailIdeaModal}
                                          localizer={localizer}
                                          svgIconsPath={svgIconPath}
                                          maxFileSizeLimit={maxFileSizeLimit}
                                          emailContentTitle={shareContentTitle || ''}
                                          tempImageUpload={tempImageUpload}
                                          mode="RECIPIENT_IDS"
                                          communityName={communityName}
                                          actorName={actor.name}
                                          getEmailContent={getEmailContent}
                                          emailContentId={shareContentId}
                                          offensiveEmojis={offensiveEmojis}
                                          modalTitle={emailShareModalTitle}
                                          emailInvitationText={emailInvitationText}
                                          emailSubject={emailSubject}
                                          getEmailRecipients={getEmailRecipients}
                                          emailByRecipientIds={emailIdeaSendByRecipientIds}
                                          classificationEnabled={classificationEnabled}
                                          fetchClassifications={() => fetchClassifications(campaignId)}
                                          classificationSummary={classificationSummary}
                                          moderatorDigest={moderatorDigest}
                        />
                )
            }
        </Fragment>
    );
};