import React, {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {Modal} from '@ideascale/ui';
import {emptyFunction, eventDispatcher, Member, useRoutesMatch, useToggle} from '@ideascale/commons';
import {PageLayoutContainer} from 'containers/PageLayoutContainer';
import {CommunitySidebarContainer} from 'containers/sidebar/CommunitySidebarContainer';
import {useAppContext} from 'contexts/AppContext';
import {NotificationType} from 'models/enums/NotificationType';
import {DigestType} from 'models/enums/DigestType';
import {useIdeaSubmissionActions} from 'hooks/useIdeaSubmissionActions';
import {useLocalizer} from 'hooks/useLocalizer';
import {useIdeasPageRouteMatch} from 'hooks/useIdeasPageRouteMatch';
import {ROUTES} from 'shared/Routes';
import {SHOW_LOGIN_PROMPT, STAGE_KEYS} from 'constants/AppConstants';
import {DigestContent} from 'components/DigestContent';
import {useDigestService} from 'hooks/useDigestService';
import {CampaignSliderContainer} from 'containers/CampaignSliderContainer';
import {IdeaListWithContextProviderContainer} from 'containers/IdeaListWithContextProviderContainer';
import {IdeaSubmissionEntry} from 'components/IdeaSubmissionEntry';

type digestNotificationData = { year: string, type: DigestType, number: string, campaignId?: string, memberId?: string };

export const DigestPage = () => {
    const {
        fetchMonthlyModeratorDigest,
        fetchCampaignPerformanceWeeklyModeratorDigest,
        fetchCampaignPerformanceMonthlyModeratorDigest,
        fetchWeeklyModeratorDigest
    } = useDigestService();
    const {authentication, lastSubmittedIdea,} = useAppContext();
    const navigate = useNavigate();
    const {actor} = authentication;
    const {routeParams, urlTag, urlModeratorTag, urlLabel, urlOrder} = useIdeasPageRouteMatch();
    const {onSubmitIdeaButtonClick} = useIdeaSubmissionActions();
    const localizer = useLocalizer();
    const [showDigestNotificationModal, toggleDigestNotificationModal] = useToggle(true);
    const digestShare = useRoutesMatch<digestNotificationData>(ROUTES.DIGEST_SHARE);
    const campaignPerformanceShare = useRoutesMatch<digestNotificationData>(ROUTES.CAMPAIGN_PERFORMANCE);

    const digestNotificationData = useMemo(() => {
        if (digestShare) {
            return {
                notificationType: digestShare.params.type === DigestType.WEEKLY ? NotificationType.WEEKLY_MODERATOR_DIGEST : NotificationType.MONTHLY_MODERATOR_DIGEST,
                year: digestShare.params.year,
                month: digestShare.params.type === DigestType.MONTHLY ? digestShare.params.number : '',
                week: digestShare.params.type === DigestType.WEEKLY ? digestShare.params.number : '',
                memberId: digestShare.params.memberId ? Number(digestShare.params.memberId) : 0
            };
        } else if (campaignPerformanceShare && campaignPerformanceShare) {
            return {
                notificationType: campaignPerformanceShare.params.type === DigestType.WEEKLY ? NotificationType.SHARED_WEEKLY_CAMPAIGN_PERFORMANCE : NotificationType.SHARED_MONTHLY_CAMPAIGN_PERFORMANCE,
                year: campaignPerformanceShare.params.year,
                month: campaignPerformanceShare.params.type === DigestType.MONTHLY ? campaignPerformanceShare.params.number : '',
                week: campaignPerformanceShare.params.type === DigestType.WEEKLY ? campaignPerformanceShare.params.number : '',
                campaignId: campaignPerformanceShare.params.campaignId ? Number(campaignPerformanceShare.params.campaignId) : 0,
                memberId: campaignPerformanceShare.params.memberId ? Number(campaignPerformanceShare.params.memberId) : 0
            };
        }
    }, [digestShare, campaignPerformanceShare]);

    const closeDigestModal = () => {
        toggleDigestNotificationModal();
        navigate('/');
    };

    return (
        <>
            <PageLayoutContainer
                mainContent={
                    <>
                        <CampaignSliderContainer hidden={lastSubmittedIdea !== null}/>
                        <IdeaSubmissionEntry
                            localizer={localizer}
                            actionMember={new Member(actor.id, actor.name, actor.username, actor.avatar, '')}
                            onClickAvatar={authentication.actor.isAnonymous() ? () => eventDispatcher.dispatch(SHOW_LOGIN_PROMPT) : emptyFunction}
                            onClick={onSubmitIdeaButtonClick}
                        />

                        <IdeaListWithContextProviderContainer
                            campaignId={routeParams?.campaignId || 'active'}
                            stageKey={routeParams?.stageKey || STAGE_KEYS.UNSPECIFIED}
                            urlLabel={urlLabel}
                            urlModeratorTag={urlModeratorTag}
                            urlTag={urlTag}
                            urlOrder={urlOrder}/>

                        <div className="spacer mb-5"/>
                        {
                            digestNotificationData &&
                            <Modal isOpen={showDigestNotificationModal}
                                   toggle={closeDigestModal}
                                   className="modal-lg" modalBodyClassName="pb-4 pt-0">
                                <DigestContent
                                    digestNotificationData={digestNotificationData}
                                    fetchMonthlyModeratorDigest={fetchMonthlyModeratorDigest}
                                    fetchCampaignPerformanceMonthlyModeratorDigest={fetchCampaignPerformanceMonthlyModeratorDigest}
                                    fetchCampaignPerformanceWeeklyModeratorDigest={fetchCampaignPerformanceWeeklyModeratorDigest}
                                    fetchWeeklyModeratorDigest={fetchWeeklyModeratorDigest}/>
                            </Modal>
                        }
                    </>
                }
                sidebar={<section className="idea-sidebar" aria-label={localizer.msg('sidebar.community-sidebar')}>
                    <CommunitySidebarContainer/>
                </section>}
            />
        </>
    );
};