import {ImageField} from './ImageField';
import {CommunityEditableFieldType} from './CommunityEditableFieldType';
import {CampaignEditableFieldType} from './CampaignEditableFieldType';
import {ImageType} from '../enums/ImageType';

export class LogoFieldBuilder {
    public logoField: LogoField;

    constructor() {
        this.logoField = {...LogoField.EMPTY};
    }

    name(name: CommunityEditableFieldType | CampaignEditableFieldType) {
        this.logoField.name = name;
        return this;
    }

    url(url: string) {
        this.logoField.url = url;
        return this;
    }

    fileId(fileId: number) {
        this.logoField.fileId = fileId;
        return this;
    }

    imageType(imageType: ImageType) {
        this.logoField.imageType = imageType;
        return this;
    }

    defaultImage(defaultImage: boolean) {
        this.logoField.defaultImage = defaultImage;
        return this;
    }

    filename(filename: string) {
        this.logoField.filename = filename;
        return this;
    }

    altText(altText: string) {
        this.logoField.altText = altText;
        return this;
    }

    logoFrameEnabled(logoFrameEnabled: boolean) {
        this.logoField.logoFrameEnabled = logoFrameEnabled;
        return this;
    }

    logoOnBannerEnabled(logoOnBannerEnabled: boolean) {
        this.logoField.logoOnBannerEnabled = logoOnBannerEnabled;
        return this;
    }

    build() {
        return this.logoField;
    }
}

export class LogoField extends ImageField {
    static EMPTY = new LogoField(CommunityEditableFieldType.UNKNOWN, '', 0, ImageType.UNKNOWN, false, false, false);

    constructor(
        name: CommunityEditableFieldType | CampaignEditableFieldType,
        url: string,
        fileId: number,
        imageType: ImageType,
        defaultImage: boolean,
        public logoFrameEnabled: boolean,
        public logoOnBannerEnabled: boolean,
        filename?: string,
        altText?: string
    ) {
        super(name, url, fileId, imageType, defaultImage, filename, altText);
    }
}