import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Trans} from 'react-i18next';
import {Avatar, ParagraphSkeleton} from '@ideascale/ui';
import {
    ClassificationAttachmentAttribute,
    ClassificationLabel,
    ClassificationUtil,
    FileAttachment,
    FileAttachmentDetail,
    FileAttribute,
    Localizer,
    MemberLink,
    TimeAgo
} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useIdeasContext} from 'contexts/IdeasContext';
import {useIdeaAttachment} from 'hooks/useIdeaAttachment';
import {AttachmentType} from 'models/enums/AttachmentType';
import styles from './AttachmentList.module.scss';

type AttachmentListProps = {
    ideaId: number;
    localizer: Localizer;
    attachments: FileAttachmentDetail[];
    isLoading: boolean;
    attributes?: ClassificationAttachmentAttribute[];
    classificationEnabled: boolean;
}

export const AttachmentList = (props: AttachmentListProps) => {
    const {ideaId, localizer, attachments, isLoading, attributes, classificationEnabled = false} = props;
    const {authentication: {actor}} = useAppContext();
    const {ideaListFilterQueryKey} = useIdeasContext();
    const {deleteAttachmentMutation: {mutate}} = useIdeaAttachment(ideaListFilterQueryKey);
    const [attachmentsData, setAttachmentsData] = useState(attachments);

    useEffect(() => {
        setAttachmentsData(attachments);
    }, [attachments]);

    const onDeleteAttachment = useCallback(async (attachment: FileAttachmentDetail) => {
        mutate({
            ideaId,
            fileId: attachment.id,
            type: attachment.type,
            commentId: attachment.type === AttachmentType.COMMENT ? attachment.contributionId : undefined,
            fieldId: [AttachmentType.IDEA_FIELD, AttachmentType.STAGE_FIELD].includes(attachment.type) ? attachment.fieldId : undefined,
            fileName: attachment.filename,
            classificationEnabled: classificationEnabled
        });
    }, [classificationEnabled, ideaId, mutate]);

    const renderAttachmentPreview = (attachment: FileAttachment) => {
        if (FileAttachment.isPreviewableImg(attachment.filename)) {
            return <img src={attachment.url} alt={attachment.altText || attachment.filename}/>;
        } else if (FileAttachment.isAudio(attachment.filename)) {
            return <audio src={attachment.url} controls={true} preload="metadata">
                {localizer.msg('unsupported.audio')}
            </audio>;
        } else if (FileAttachment.isVideo(attachment.filename)) {
            return <video controls preload="metadata" width="100%" height="360px">
                <source src={attachment.url}/>
                {localizer.msg('unsupported.video')}
            </video>;
        } else {
            return null;
        }
    };


    return (
        <ul className={`list-unstyled ${styles.attachmentList}`}>
            {isLoading
                ? <ParagraphSkeleton rows={4}/>
                : attachmentsData.map(attachment =>
                    <li className="py-3" key={attachment.id}>
                        <div className="d-flex align-items-lg-center">
                            <Avatar src={attachment.submitter.avatar} size="md" className="me-3 d-inline-block"
                                    alt={attachment.submitter.username ? localizer.msg('common.user-avatar', {username: attachment.submitter.username}) : ''}/>
                            <div>
                                {
                                    classificationEnabled && attachment.filename &&
                                    <div className="file-attribute-wrapper">
                                        <ClassificationLabel
                                            classification={ClassificationUtil.getAppliedFileAttribute(attachment.filename, (attributes?.flatMap(item => item && item.fileAttributeValues) as FileAttribute[]))}
                                            extraClasses="my-1"/>
                                    </div>
                                }
                                {
                                    [AttachmentType.IDEA_FIELD, AttachmentType.STAGE_FIELD].includes(attachment.type)
                                        ?
                                        <Trans
                                            i18nKey={AttachmentType.IDEA_FIELD === attachment.type ?
                                                'idea.attachment.custom-field' :
                                                'idea.attachment.stage-custom-field'}
                                            values={
                                                {
                                                    member: attachment.submitter.id === actor.id ?
                                                        localizer.msg('common.you') : attachment.submitter.name,
                                                    file: attachment.filename,
                                                    fieldName: attachment.fieldName
                                                }
                                            }>
                                            <MemberLink className="fw-bold" id={attachment.submitter.id}
                                                        identityHidden={attachment.submitter.id === actor.id ?
                                                            false : attachment.submitter.identityHidden}/>
                                            <a className="fw-bold" href={attachment.url}>link</a>
                                            <span className="fw-bold">fieldName</span>
                                        </Trans>
                                        :
                                        <Fragment>
                                            {
                                                attachment.submitter.id === actor.id
                                                    ? <Trans i18nKey="idea.attachment.by.you">
                                                        <MemberLink className="fw-bold"
                                                                    id={attachment.submitter.id}/>
                                                    </Trans>
                                                    :
                                                    <Trans i18nKey="idea.attachment.by.member"
                                                           values={{member: attachment.submitter.name}}>
                                                        <MemberLink className="fw-bold"
                                                                    id={attachment.submitter.id}
                                                                    identityHidden={attachment.submitter.identityHidden}>
                                                            {attachment.submitter.name}
                                                        </MemberLink>
                                                    </Trans>
                                            }{' '}
                                            <a className="fw-bold" href={attachment.url}>{attachment.filename}</a>
                                        </Fragment>
                                }
                                {' '}
                                <TimeAgo localizer={localizer} dateISOString={attachment.submittedAt}/>
                            </div>
                        </div>
                        <div className={`${styles.attachmentContent} ms-5 d-flex flex-column align-items-start`}>
                            {
                                attachment.note &&
                                <p className="mt-2 mb-0">{attachment.note}</p>
                            }
                            {
                                attachment.previewSupported &&
                                renderAttachmentPreview(attachment)
                            }
                        </div>
                        {
                            attachment.deletionAllowed &&
                            <div className="d-flex flex-row-reverse">
                                <button className="btn btn-link px-0 shadow-none"
                                        onClick={() => onDeleteAttachment(attachment)}>
                                    {localizer.msg('common.actions.delete')}
                                </button>
                            </div>
                        }
                    </li>
                )}
        </ul>
    );
};