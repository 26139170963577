import React, {Fragment, useCallback} from 'react';
import {Member, ClassificationLabel, useApplicableClassifications, BodyTagAttributes} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useCommunityService} from 'hooks/useService';
import {useLocalizer} from 'hooks/useLocalizer';
import {useIdeaSubmissionActions} from 'hooks/useIdeaSubmissionActions';
import {useUpdateCurrentCampaign} from 'hooks/useUpdateCurrentCampaign';
import {PageLayoutContainer} from 'containers/PageLayoutContainer';
import {CampaignSidebarContainer} from 'containers/sidebar/CampaignSidebarContainer';
import {CampaignHeaderContainer} from 'containers/CampaignHeaderContainer';
import {IdeaSubmissionPreviewContainer} from 'containers/IdeaSubmissionPreviewContainer';
import {IdeaSubmissionEntry} from 'components/IdeaSubmissionEntry';
import {TagsList} from 'components/TagsList';
import {PAGE_IDS} from 'constants/AppConstants';
import {TagData} from 'models/TagData';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';

export const CampaignTagsPage = () => {
    const {
        authentication: {actor},
        currentCampaign
    } = useAppContext();
    const communityService = useCommunityService();
    const {onSubmitIdeaButtonClick} = useIdeaSubmissionActions();
    const localizer = useLocalizer();
    useUpdateCurrentCampaign();
    const {getClassificationAttribute} = useApplicableClassifications('', []);
    const {communityConfig} = useAppContext();

    const fetchTags = useCallback(async (pageParameters: PageParameters, campaignId?: number, stageId?: number): Promise<PagedResponseContent<TagData>> => {
        if (communityService !== null && currentCampaign?.id) {
            return await communityService.fetchTags(pageParameters, currentCampaign?.id, stageId);
        }
        return PagedResponseContent.nullObject();
    }, [communityService, currentCampaign?.id]);

    const fetchModeratorTags = useCallback(async (pageParameters: PageParameters, campaignId?: number, stageId?: number): Promise<PagedResponseContent<TagData>> => {
        if (communityService !== null && currentCampaign?.id) {
            return await communityService.fetchModeratorTags(pageParameters, currentCampaign?.id, stageId);
        }
        return PagedResponseContent.nullObject();
    }, [communityService, currentCampaign?.id]);

    return (
        <Fragment>
            <BodyTagAttributes data-test-element-id={PAGE_IDS.CAMPAIGN_TAGS_PAGE}/>
            <CampaignHeaderContainer/>
            <PageLayoutContainer
                mainContent={
                    <div className="mb-3">
                        <IdeaSubmissionEntry
                            localizer={localizer}
                            actionMember={new Member(actor.id, actor.name, actor.username, actor.avatar, '')}
                            onClick={onSubmitIdeaButtonClick}/>
                        <div className="spacer mb-4"/>
                        <IdeaSubmissionPreviewContainer displayAsModal={false}/>
                        <div className="card panel tags-list mt-2">
                            <div className="panel-body card-body p-4">
                                <TagsList fetchModeratorTags={fetchModeratorTags} fetchTags={fetchTags}
                                          title={
                                              <>
                                                  {communityConfig.classificationEnabled && currentCampaign?.classificationSummary &&
                                                      <ClassificationLabel
                                                          extraClasses="align-baseline me-1"
                                                          classification={getClassificationAttribute(currentCampaign?.classificationSummary)}/>
                                                  }
                                                  {currentCampaign?.name || ''} {localizer.msg('sidebar.tags.tags')}
                                              </>
                                          }
                                />
                            </div>
                        </div>
                    </div>
                }
                sidebar={<CampaignSidebarContainer/>}
            />
        </Fragment>
    );
};
