import React from 'react';
import {Trans} from 'react-i18next';
import {Localizer, TimeAgo, ClassificationLabel, useApplicableClassifications} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {BasicActivityCard} from '../idea/details/BasicActivityCard';
import {CampaignChangeActivity} from 'models/CampaignChangeActivity';

type CampaignChangeStageActivityProps = {
    localizer: Localizer
    campaignChangeActivity: CampaignChangeActivity
}

export const CampaignChangeStageActivity = (props: CampaignChangeStageActivityProps) => {
    const {localizer, campaignChangeActivity} = props;
    const {authentication: {actor}} = useAppContext();
    const {communityConfig} = useAppContext();
    const {getClassificationAttribute} = useApplicableClassifications('', []);

    const renderActivitySummary = () => {
        if (campaignChangeActivity.newCampaign || campaignChangeActivity.oldCampaign) {
            if (campaignChangeActivity.newCampaign && campaignChangeActivity.oldCampaign) {
                return (
                    <>
                        <Trans i18nKey="stage.common.campaign-changed" values={{
                            initialCampaign: campaignChangeActivity.oldCampaign.name,
                            newCampaign: campaignChangeActivity.newCampaign.name,
                            member: campaignChangeActivity.member.id === actor.id ?
                                localizer.msg('stage.common.changed-self') : campaignChangeActivity.member.name
                        }}>
                            {communityConfig.classificationEnabled ?
                            <ClassificationLabel
                                classification={getClassificationAttribute(campaignChangeActivity.oldCampaign.classificationSummary)}
                                extraClasses="mx-1 d-inline"/> : <span/>
                            }
                            <em>Initial Campaign</em>
                            {communityConfig.classificationEnabled ?
                            <ClassificationLabel
                                classification={getClassificationAttribute(campaignChangeActivity.newCampaign.classificationSummary)}
                                extraClasses="mx-1 d-inline"/> : <span/>
                            }
                            <em>New Campaign</em>
                            <strong>Member</strong>
                            <TimeAgo localizer={localizer} dateISOString={campaignChangeActivity.createdAt}/>
                        </Trans>
                    </>
                );
            } else {
                return (
                    <>
                        <Trans i18nKey="stage.common.campaign-change-deleted" values={{
                            member: campaignChangeActivity.member.id === actor.id ?
                                localizer.msg('stage.common.changed-self') : campaignChangeActivity.member.name
                        }}>
                            <strong>Member</strong>
                            <TimeAgo localizer={localizer} dateISOString={campaignChangeActivity.createdAt}/>
                        </Trans>
                    </>
                );
            }
        } else {
            return campaignChangeActivity.description;
        }
    };

    return (
        <div className="pb-3">
            <BasicActivityCard headerTitle={localizer.msg('stage.common.campaign-change')}
                               headerSummary={renderActivitySummary()}/>
        </div>
    );
};