import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {GroupBase, OptionsOrGroups} from 'react-select';
import {Controller, useForm} from 'react-hook-form';
import {IdeascaleSelectWithPagination, Modal, OptionType} from '@ideascale/ui';
import {
    AlertEvent,
    AlertType,
    buildAlertEventData,
    eventDispatcher,
    isHtmlInject,
    Localizer,
    Member,
    ParagraphSkeleton,
    useApiErrorResponseHandler,
    useHandleFormSubmit
} from '@ideascale/commons';
import {IdeaSummary} from 'models/IdeaSummary';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {PageParameters} from 'models/types/PageParameters';
import {AssignOwnersParams} from 'models/types/AssignOwnersParams';
import {IdeaOwnerAssignmentResponse} from 'models/IdeaOwnerAssignmentResponse';

type AssignOwnersModalProps = {
    localizer: Localizer;
    open: boolean;
    toggle: () => void;
    selectedIdea: IdeaSummary;
    fetchOwnersByName: (data: PageParameters, ideaId: number) => Promise<PagedResponseContent<Member> | undefined>;
    assignReassignOwners: (assignOwnersParams: AssignOwnersParams) => Promise<IdeaOwnerAssignmentResponse>;
    fetchAssignedOwners: (ideaId: number) => Promise<Member[]>;
}

type AssignmentData = {
    assignmentText?: string;
    memberSelect?: OptionType[];
}

export const AssignOwnersModal = (props: AssignOwnersModalProps) => {
    const {
        localizer,
        open,
        toggle,
        fetchOwnersByName,
        assignReassignOwners,
        selectedIdea,
        fetchAssignedOwners,
    } = props;
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [assignInProgress, setAssignInProgress] = useState(false);
    const [assignedOwners, setAssignedOwners] = useState<OptionType[]>([]);
    const [loading, setLoading] = useState(true);
    const {register, handleSubmit, control, setError, reset, formState: {errors}} = useForm();
    const onFormSubmit = useHandleFormSubmit(handleSubmit);


    useEffect(() => {
        fetchAssignedOwners(selectedIdea.id)
            .then(result => {
                const owners: OptionType[] = result.map(member => ({
                    value: member.id,
                    label: member.name
                }));
                setAssignedOwners(owners);
                reset({
                    memberSelect: owners,
                    assignmentText: selectedIdea.assignmentText
                });
                setLoading(false);
            }).catch(() => {
            setLoading(false);
        });
    }, [fetchAssignedOwners, reset, selectedIdea]);

    const loadMembers = useCallback(async (term: string, prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>, additionalParams: any) => {
        try {
            const {page} = additionalParams;
            const ownerData = await fetchOwnersByName({term, page, limit: undefined}, selectedIdea.id);
            const owners: OptionType[] | undefined = ownerData?.content.map(member => ({
                value: member.id,
                label: member.name,
                image: member.avatar,
                labelAsJsx: (
                    <div className="member-list-item-content" title={member.name}>
                        <strong>{member.name}</strong>
                        {
                            member.username &&
                            <small className="text-truncate">@{member.username}</small>
                        }
                    </div>
                )
            }));

            if (owners) {
                return {
                    options: owners,
                    hasMore: ownerData?.hasMore,
                    additional: {
                        page: (ownerData?.pageNo ?? 0) + 1,
                    }
                };
            } else {
                return {
                    options: [],
                };
            }
        } catch (e: any) {
            return {
                options: [],
            };
        }
    }, [fetchOwnersByName, selectedIdea]);

    const onAssignOwners = useCallback(async (data: AssignmentData) => {
        if (selectedIdea && data.memberSelect) {
            try {
                setAssignInProgress(true);
                const response = await assignReassignOwners({
                    data: {
                        memberIds: data.memberSelect.map(member => member.value),
                        message: data.assignmentText || ''
                    },
                    ideaId: selectedIdea.id
                });
                if (response.unchanged) {
                    eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.warn, localizer.msg('idea.actions.assign-owner.unchanged')));
                } else {
                    eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, localizer.msg('idea.actions.assign-owner.success')));
                }
                setAssignInProgress(false);
                toggle();
            } catch (e: any) {
                handleErrorResponse(e, {
                    setFormError: () => {
                        setError('assignmentText', {
                            type: 'server',
                            message: e.data.validationErrors?.message
                        });
                    }
                });
                setAssignInProgress(false);
            }
        }
    }, [selectedIdea, assignReassignOwners, toggle, localizer, handleErrorResponse, setError]);

    const onChangOwners = (options: any, onChangeReactForm: any) => {
        if (options) {
            onChangeReactForm(options);
        } else {
            onChangeReactForm([]);
        }
    };

    return (
        <Modal backdrop="static" isOpen={open} toggle={toggle}
               modalHeaderId="assign-owners-header"
               title={
                   localizer.msg(`${assignedOwners.length === 0
                       ? 'idea.actions.assign-owner.title-assign'
                       : 'idea.actions.assign-owner.title-change'}`)
               }
               primaryButton={{
                   btnProps: {
                       form: 'assign-form',
                       type: 'submit',
                       disabled: assignInProgress
                   },
                   btnLabel: localizer.msg('common.actions.save')
               }}
               autoFocus={false}
        >
            <form id="assign-form" onSubmit={onFormSubmit(onAssignOwners, assignInProgress)}
                  aria-labelledby="assign-owners-header">
                {
                    loading
                        ? <ParagraphSkeleton rows={6}/>
                        : (
                            <Fragment>
                                <div className="form-group">
                                    <label className="fw-bold"
                                           htmlFor="assign-owner-input">{localizer.msg('idea.actions.assign-owner.assign-to')}</label>
                                    <div className="member-list">
                                        <Controller
                                            control={control} name="memberSelect" defaultValue={assignedOwners}
                                            render={({field: {value, onChange}}) =>
                                                <IdeascaleSelectWithPagination
                                                    autoFocus
                                                    isMulti
                                                    inputId="assign-owner-input"
                                                    value={value}
                                                    placeholder={localizer.msg('idea.actions.assign-owner.assignment-placeholder')}
                                                    onChange={(options) => onChangOwners(options, onChange)}
                                                    loadOptions={loadMembers}
                                                    ariaLabel={localizer.msg('idea.actions.assign-owner.assign-to')}
                                                    additional={{
                                                        page: 0
                                                    }}
                                                />}
                                        />
                                    </div>
                                </div>
                                <div className={`form-group ${errors.assignmentText ? 'has-error' : ''}`}>
                                    <label className="fw-bold"
                                           htmlFor="assignmentText">{localizer.msg('idea.actions.assign-owner.assignment-message')}</label>
                                    <textarea
                                        className="form-control"
                                        id="assignmentText"
                                        defaultValue={selectedIdea.assignmentText}
                                        {...register('assignmentText', {validate: (value: string) => !isHtmlInject(value) || localizer.msg('common.errors.html')})}
                                    />
                                    {
                                        errors.assignmentText &&
                                        <div className="invalid-feedback d-block">
                                            {errors.assignmentText.message}
                                        </div>
                                    }
                                </div>
                            </Fragment>
                        )
                }
            </form>
        </Modal>
    );
};
