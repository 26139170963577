export class PagedResponseContent<T> {
    static nullObject<U>(): PagedResponseContent<U> {
        return new PagedResponseContent([], false, 0, 0, 0);
    }

    constructor(
        public content: T[],
        public hasMore: boolean,
        public pageNo: number,
        public pageSize: number,
        public totalElements: number
    ) {
    }
}