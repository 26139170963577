import {useEffect} from 'react';
import {useIntercom} from 'react-use-intercom';

type IntercomType = {
    email: string,
    appToken?: string,
    licenceName: string,
}

export const IntercomChat = (props: IntercomType) => {
    const {email, appToken = '', licenceName} = props;

    const {boot} = useIntercom();

    useEffect(() => {
        boot({
            email: email,
            userHash: appToken,
            customAttributes: {
                LICENSE_TYPE: licenceName
            }
        });
    }, [appToken, boot, email, licenceName]);
    return null;
};