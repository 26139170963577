import React from 'react';

type StageSummaryHeadingProps = {
    heading: string | JSX.Element;
    link?: string;
    className?: string;
}

export const StageSummaryHeading = (props: StageSummaryHeadingProps) => {
    const {heading, className = ''} = props;
    return (
        <h2 className={`${className}`}>{heading}</h2>
    );
};