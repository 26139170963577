import {TranslationContentType} from 'models/enums/landing-page/TranslationContentType';
import {TranslationFieldType} from 'models/enums/TranslationFieldType';
import {RichTextToolbarType} from 'models/enums/landing-page/RichTextToolbarType';

export class TranslationConfig {
    static EMPTY = new TranslationConfig(TranslationContentType.COMPONENT_TEXT_TEXT, 0, TranslationFieldType.TEXT);
    constructor(
        public contentType: TranslationContentType,
        public id: number,
        public fieldType: TranslationFieldType,
        public toolbar?: RichTextToolbarType,
    ) {
    }
}