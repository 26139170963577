import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Localizer} from '@ideascale/commons';
import {OriginalComment} from 'models/comments/OriginalComment';
import {useCommentService} from 'hooks/useCommentService';
import {HtmlRenderer} from 'components/shared/HtmlRenderer';
import './ViewOriginalLanguageCard.scss';

type ViewOriginalLanguageCardProps = {
    commentId: number; id: string; className?: string, localizer: Localizer;
}

function ViewOriginalLanguageCard(props: ViewOriginalLanguageCardProps) {
    const {id, commentId, className, localizer} = props;
    const [originalLanguage, setOriginalLanguage] = useState<OriginalComment>();
    const {fetchOriginalComment} = useCommentService();

    const fetchOriginalLanguageData = useCallback(async () => {
        const data = await fetchOriginalComment(commentId);
        setOriginalLanguage(data);
    }, [commentId, fetchOriginalComment]);

    useEffect(() => {
        void fetchOriginalLanguageData();
    }, [fetchOriginalLanguageData]);

    return <div id={id} className={`${className ? className : ''} view-original-language card panel panel-default`} lang={originalLanguage?.language.code}>
        <div className="card-header panel-heading">
            {originalLanguage && <Fragment>
                <span
                    className="fw-bold">{localizer.msg('original-idea.original-language')}</span> - {originalLanguage?.language.name}
            </Fragment>}
        </div>
        <div className="card-body">
            {
                originalLanguage &&
                <HtmlRenderer content={originalLanguage?.text}/>
            }
        </div>
    </div>;
}

export default ViewOriginalLanguageCard;