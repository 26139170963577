import React, {Fragment, useCallback, useMemo, useState} from 'react';
import {Controller, FieldValues, useForm} from 'react-hook-form';
import {GroupBase, OptionsOrGroups} from 'react-select';
import {
    ActionButton,
    Icon,
    IdeascaleSelect,
    IdeascaleSelectWithPagination,
    Modal,
    OptionType,
    Radio
} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {
    Campaign,
    CampaignsHolder,
    isHtmlInject,
    LabelData,
    Localizer,
    PageTheme,
    useApiErrorResponseHandler,
    useHandleFormSubmit
} from '@ideascale/commons';
import {useIdeaListMode} from 'hooks/useIdeaListMode';
import {LabelSearchField} from 'components/search/LabelSearchField';
import {CampaignSearchField} from 'components/search/CampaignSearchField';
import {SearchInputGroup} from 'shared/SearchInputGroup';
import {IdeaListMode} from 'models/enums/IdeaListMode';
import {PageParameters} from 'models/types/PageParameters';
import {IdeasComponentData} from 'models/landing-page/IdeasComponentData';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {LandingPageComponentType} from 'models/enums/landing-page/LandingPageComponentType';
import {LANDING_PAGE_PLAIN_TEXT_MAX_CHAR_COUNT} from 'constants/AppConstants';

enum FilterType {
    LABEL = 'label',
    TAG = 'tag'
}

const FIELD_NAMES: Record<string, string> = {
    title: 'title',
    subtitle: 'subtitle',
    campaignFilter: 'campaignFilter',
    listMode: 'listMode',
    filter: 'filter',
    tag: 'tag',
    label: 'label'
};

type IdeaConfigModalProps = {
    open: boolean;
    toggle: () => void;
    localizer: Localizer;
    theme: PageTheme
    config: IdeasComponentData;
    renderSubtitle?: boolean;
    saveConfig: (config: IdeasComponentData) => Promise<void>;
    getIdeaTags: (param: PageParameters) => Promise<PagedResponseContent<string>>;
    getFilterLabels: (param: PageParameters) => Promise<PagedResponseContent<LabelData>>;
    getFilterCampaigns: (param: PageParameters) => Promise<PagedResponseContent<CampaignsHolder<Campaign>>>
    onComponentEdited: () => void;
}

export const IdeaConfigModal = (props: IdeaConfigModalProps) => {
    const {
        open,
        toggle,
        localizer,
        config,
        saveConfig,
        getIdeaTags,
        getFilterLabels,
        getFilterCampaigns,
        renderSubtitle,
        onComponentEdited,
    } = props;
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        formState: {errors, isSubmitting},
        control,
        setError
    } = useForm();
    const onFormSubmit = useHandleFormSubmit(handleSubmit);
    const [filter, setFilter] = useState((!config.tag && config.label) ? FilterType.LABEL : FilterType.TAG);
    const {getIdeaListModesWithoutRecommended} = useIdeaListMode();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [configUpdated, setConfigUpdated] = useState(false);

    const viewModeOptions = useMemo((): OptionType[] => {
        const listOptions = getIdeaListModesWithoutRecommended().filter(item => item !== IdeaListMode.RECOMMENDED);
        return listOptions.map(mode => ({
            label: localizer.msg(`ideas.mode.${mode}`),
            value: mode.toUpperCase()
        }));
    }, [getIdeaListModesWithoutRecommended, localizer]);

    const loadTags = useCallback(async (term: string, prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>, additionalParams: any) => {
        try {
            const {page} = additionalParams;
            const tagData = await getIdeaTags({term, page, limit: 10});
            let tags: OptionType[] | undefined;
            tags = tagData?.content.map(tag => ({
                value: tag,
                label: tag
            }));

            if (tags && tags.length > 0) {
                if (page === 0) {
                    tags = [{
                        value: '',
                        label: localizer.msg('landing-page.components.idea.all-tags')
                    } as OptionType, ...tags];
                }
                return {
                    options: tags,
                    hasMore: tagData?.hasMore,
                    additional: {
                        page: page + 1,
                    }
                };
            } else {
                return {
                    options: [],
                };
            }
        } catch (e: any) {
            return {
                options: [],
            };
        }
    }, [getIdeaTags, localizer]);

    const replaceDefaultValue = (selectedOption: any) => {
        const currentTitle = getValues(FIELD_NAMES.title);
        const listOptions = getIdeaListModesWithoutRecommended().filter(item => item !== IdeaListMode.RECOMMENDED);
        let defaultTitles = listOptions.map(item =>
            (localizer.msg(`landing-page.components.idea.titles.${item.toLowerCase()}`).toLowerCase().split(' ').join(''))
        );
        if (defaultTitles.includes(currentTitle.toLowerCase().split(' ').join(''))) {
            setValue(FIELD_NAMES.title, localizer.msg(`landing-page.components.idea.titles.${selectedOption.value.toLowerCase()}`));
        }
    };

    const submitConfig = async (data: FieldValues) => {
        const newConfig = {...config};
        newConfig.title = data[FIELD_NAMES.title];
        newConfig.subtitle = data[FIELD_NAMES.subtitle];
        newConfig.ideaListMode = data[FIELD_NAMES.listMode]?.value || IdeaListMode.TRENDING;
        newConfig.tag = (filter === FilterType.TAG && data[FIELD_NAMES.tag] && data.tag.value) ? data.tag.value : undefined;
        newConfig.campaigns = data[FIELD_NAMES.campaignFilter]?.map((item: OptionType) => ({
            id: item.value,
            name: item.label
        })) || [];
        newConfig.label = (filter === FilterType.LABEL && data[FIELD_NAMES.label] && data.label.value) ? data.label.value : undefined;
        try {
            await saveConfig(newConfig);
            onComponentEdited();
            toggle();
        } catch (e) {
            handleErrorResponse(e, {setFormError: setError});
        }
    };
    return (
        <Modal isOpen={open} toggle={toggle} modalHeaderId="idea-config-header"
               title={localizer.msg(`${config.type === LandingPageComponentType.PINNED_IDEAS
                   ? 'landing-page.components.idea.pinned-ideas'
                   : 'landing-page.components.idea.edit-ideas'}`)}
               autoFocus={false}>
            <form id={`idea-form-${config.id}`} onSubmit={onFormSubmit(submitConfig, isSubmitting || !configUpdated)}>
                <div className={`form-group ${errors[FIELD_NAMES.title] ? 'has-error' : ''}`}>
                    <label className="control-label fw-bold" htmlFor="heading">
                        {localizer.msg('landing-page.components.common.heading')}
                        <span className="font-size-lg" aria-hidden={true}>*</span>
                    </label>
                    <input type="text" id="heading" className="form-control" autoFocus
                           defaultValue={config.title}
                           aria-required="true"
                           {...register(FIELD_NAMES.title, {
                               onChange: () => setConfigUpdated(prevState => !prevState ? true : prevState),
                               required: localizer.msg('idea.form.field.errors.required'),
                               validate: value => !isHtmlInject(value) || localizer.msg('common.errors.html'),
                               maxLength: {
                                   value: LANDING_PAGE_PLAIN_TEXT_MAX_CHAR_COUNT,
                                   message: localizer.msg('common.errors.max-length', {count: LANDING_PAGE_PLAIN_TEXT_MAX_CHAR_COUNT}),
                               },
                               setValueAs: value => value.trim()
                           })}/>
                    {
                        errors[FIELD_NAMES.title] &&
                        <div className="invalid-feedback d-block">
                            {errors[FIELD_NAMES.title].message}
                        </div>
                    }
                </div>
                {
                    renderSubtitle &&
                    <div className={`form-group ${errors[FIELD_NAMES.subtitle] ? 'has-error' : ''}`}>
                        <label htmlFor="subtitle" className="control-label fw-bold">
                            {localizer.msg('landing-page.components.common.subtitle')}
                        </label>
                        <input type="text" id="subtitle" className="form-control"
                               defaultValue={config.subtitle}
                               {...register(FIELD_NAMES.subtitle, {
                                   onChange: () => setConfigUpdated(prevState => !prevState ? true : prevState),
                                   validate: value => !isHtmlInject(value) || localizer.msg('common.errors.html'),
                                   maxLength: {
                                       value: LANDING_PAGE_PLAIN_TEXT_MAX_CHAR_COUNT,
                                       message: localizer.msg('common.errors.max-length', {count: LANDING_PAGE_PLAIN_TEXT_MAX_CHAR_COUNT}),
                                   },
                                   setValueAs: value => value.trim()
                               })}/>
                        {
                            errors[FIELD_NAMES.subtitle] &&
                            <div className="invalid-feedback d-block">
                                {errors[FIELD_NAMES.subtitle].message}
                            </div>
                        }
                    </div>
                }
                {
                    !(config.type === LandingPageComponentType.PINNED_IDEAS) &&
                    <Fragment>
                        <div className={`form-group ${errors[FIELD_NAMES.campaignFilter] ? 'has-error' : ''}`}>
                            <Controller
                                control={control} name={FIELD_NAMES.campaignFilter}
                                defaultValue={config.campaigns?.map(item => ({
                                    label: item.name,
                                    value: item.id
                                }))}
                                render={({field}) =>
                                    <CampaignSearchField inputGroupClassName="flex-nowrap"
                                                         fetchCampaigns={getFilterCampaigns}
                                                         setCampaigns={(stages) => {
                                                             setConfigUpdated(prevState => !prevState ? true : prevState);
                                                             field.onChange(stages);
                                                         }}
                                                         defaultValue={field.value}
                                    />
                                }/>
                        </div>
                        <div className={`form-group ${errors[FIELD_NAMES.listMode] ? 'has-error' : ''}`}>
                            <label className="fw-bold" htmlFor="list-view-mode">
                                {localizer.msg('landing-page.components.idea.edit-idea-sort')}
                            </label>
                            <Controller
                                control={control} name={FIELD_NAMES.listMode}
                                defaultValue={{
                                    label: config.ideaListMode ? localizer.msg(`ideas.mode.${config.ideaListMode.toLowerCase()}`) : localizer.msg(`ideas.mode.${IdeaListMode.TRENDING}`),
                                    value: config.ideaListMode || IdeaListMode.TRENDING.toUpperCase()
                                } as OptionType}
                                rules={{
                                    required: localizer.msg('idea.form.field.errors.required')
                                }}
                                render={({field}) =>
                                    <IdeascaleSelect
                                        name={field.name}
                                        isMulti={false}
                                        inputId="list-view-mode"
                                        placeholder="Select Idea Mode"
                                        value={field.value}
                                        options={viewModeOptions}
                                        onChange={(selectedOption: any) => {
                                            setConfigUpdated(prevState => !prevState ? true : prevState);
                                            replaceDefaultValue(selectedOption);
                                            field.onChange(selectedOption);
                                        }}
                                    />
                                }/>
                        </div>
                        <div className="d-flex">
                            <Radio label={<strong>{localizer.msg('landing-page.components.idea.segment-tag')}</strong>}
                                   inputId="segment-tag"
                                   value={FilterType.TAG}
                                   defaultChecked={filter === FilterType.TAG}
                                   {...register(FIELD_NAMES.filter)}
                                   onChange={() => {
                                       setConfigUpdated(prevState => !prevState ? true : prevState);
                                       setFilter(FilterType.TAG);
                                   }}
                            />
                            <Radio className="ms-3"
                                   label={
                                       <strong>{localizer.msg('landing-page.components.idea.segment-label')}</strong>}
                                   inputId="segment-label"
                                   value={FilterType.LABEL}
                                   defaultChecked={filter === FilterType.LABEL}
                                   {...register(FIELD_NAMES.filter)}
                                   onChange={() => {
                                       setConfigUpdated(prevState => !prevState ? true : prevState);
                                       setFilter(FilterType.LABEL);
                                   }}
                            />
                        </div>
                        {
                            filter === FilterType.TAG && (
                                <div className="form-group">
                                    <SearchInputGroup className="flex-nowrap" groupText={
                                        <Icon iconSpritePath={svgIconPath} name="tag" width={18} height={18}/>
                                    }>
                                        <Controller
                                            control={control} name={FIELD_NAMES.tag}
                                            defaultValue={config.tag
                                                ? {
                                                    label: config.tag,
                                                    value: config.tag
                                                } as OptionType
                                                :
                                                {
                                                    value: '',
                                                    label: localizer.msg('landing-page.components.idea.all-tags')
                                                } as OptionType}
                                            render={({field}) =>
                                                <IdeascaleSelectWithPagination
                                                    ariaLabel={localizer.msg('sidebar.tags.select-tags')}
                                                    isMulti={false}
                                                    inputId="select-tag"
                                                    placeholder={localizer.msg('landing-page.components.idea.all-tags')}
                                                    onChange={(option) => {
                                                        setConfigUpdated(prevState => !prevState ? true : prevState);
                                                        field.onChange(option);
                                                    }}
                                                    value={field.value}
                                                    loadOptions={loadTags}
                                                    additional={{
                                                        page: 0
                                                    }}
                                                />}
                                        />
                                    </SearchInputGroup>
                                </div>
                            )
                        }
                        {
                            filter === FilterType.LABEL && (
                                <div className="form-group landing-label-search">
                                    <Controller
                                        control={control} name={FIELD_NAMES.label}
                                        defaultValue={config.label
                                            ? {
                                                label: config.label.name,
                                                value: config.label,
                                                labelAsJsx: <div className="label-item"><span
                                                    className={`label customize-label text-truncate label-field-${config.label.colorKey}`}>{config.label.name}</span>
                                                </div>
                                            } as OptionType
                                            :
                                            {
                                                value: '',
                                                label: localizer.msg('landing-page.components.idea.all-labels'),
                                                labelAsJsx: <div className="label-item"><span
                                                    className={`label customize-label text-truncate label-field-in-reserve`}>{localizer.msg('landing-page.components.idea.all-labels')}</span>
                                                </div>
                                            } as OptionType}
                                        render={({field}) =>
                                            <LabelSearchField fetchLabels={getFilterLabels}
                                                              setLabels={(labels) => {
                                                                  setConfigUpdated(prevState => !prevState ? true : prevState);
                                                                  field.onChange(labels);
                                                              }}
                                                              initialValue={config.label
                                                                  ? {
                                                                      label: config.label.name,
                                                                      value: config.label,
                                                                      labelAsJsx: <div className="label-item"><span
                                                                          className={`label customize-label text-truncate label-field-${config.label.colorKey}`}>{config.label.name}</span>
                                                                      </div>
                                                                  } as OptionType
                                                                  :
                                                                  {
                                                                      value: '',
                                                                      label: localizer.msg('landing-page.components.idea.all-labels'),
                                                                      labelAsJsx: <div className="label-item"><span
                                                                          className={`label customize-label text-truncate label-field-in-reserve`}>{localizer.msg('landing-page.components.idea.all-labels')}</span>
                                                                      </div>
                                                                  } as OptionType}
                                                              landingPageSearch
                                                              isMulti={false}/>}
                                    />
                                </div>
                            )
                        }
                    </Fragment>
                }
                <div className="form-group mt-5 text-end">
                    <button className="btn btn-cancel me-3" type="button" onClick={toggle}>
                        {localizer.msg('common.actions.cancel')}
                    </button>
                    <ActionButton type="submit" form={`idea-form-${config.id}`} loading={isSubmitting}
                                  disabled={!configUpdated}>
                        {localizer.msg('common.actions.submit')}
                    </ActionButton>
                </div>
            </form>
        </Modal>
    );
};