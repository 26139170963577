import React from 'react';
import {Link} from 'react-router-dom';
import {Trans} from 'react-i18next';
import {NotificationData} from 'models/NotificationData';
import {appLinks} from 'services/AppLinks';

type IdeaTagAddedNotificationProps = {
    notification: NotificationData;
}

export const IdeaTagAddedNotification = (props: IdeaTagAddedNotificationProps) => {
    const {notification} = props;

    return (
        <Trans i18nKey="notification.idea.tag-added" values={{
            tag: notification.viewAttributes.tagName,
            idea: notification.viewAttributes.ideaTitle
        }}>
            <strong/>
            <strong/>
            <Link to={appLinks.ideaDetails(notification.ideaId)}/>
        </Trans>
    );
};