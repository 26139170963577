import React, {Fragment, useCallback, useMemo} from 'react';
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from '@ideascale/ui';
import {LabelData, Localizer, uuid} from '@ideascale/commons';
import './LabelStackedView.scss';

type LabelStackedViewProps = {
    labels: LabelData[];
    container?: string;
    clickable?: boolean;
    onIdeaLabelClicked?: (labelData: LabelData) => void;
    localizer: Localizer;
}

export const LabelStackedView = (props: LabelStackedViewProps) => {
    const {labels, container = undefined, clickable, onIdeaLabelClicked, localizer} = props;

    const onIdeaLabelClick = (event: React.MouseEvent<HTMLElement>, labelData: LabelData) => {
        event.stopPropagation();
        event.preventDefault();
        clickable && labelData.searchable && onIdeaLabelClicked && onIdeaLabelClicked(labelData);
    };

    const renderLabelStackedView = useCallback((labels: LabelData[]) => {
        const stackLabels: JSX.Element[] = [];
        let count = 1;
        labels.forEach(label => {
            if (count <= 3) {
                stackLabels.push(<Fragment key={label.id}>
                    <span key={label.id}
                          className={`label pointer-events-none label-field-${label.colorKey}`}>{label.name}</span>
                    {count === 1 && ' '}
                </Fragment>);
                count++;
            }
        });
        return stackLabels;
    }, []);

    const uniqueId = useMemo(() => uuid(), []);

    return (
        <UncontrolledDropdown
            className="grid-label-dropdown"
            a11y={false}
            role="group" aria-label={localizer.msg('common.toggle-labels-dropdown')}
            id={uniqueId}>
            <DropdownToggle color="default" role="button" className="btn-label-stacked-view" caret
                            aria-labelledby={uniqueId}>
                {renderLabelStackedView(labels)}
            </DropdownToggle>
            <DropdownMenu container={container} className="clickable-idea-label-dropdown-menu label-dropdown">
                {
                    labels.map(label =>
                        <DropdownItem
                            aria-label={localizer.msg('frontend-shared.labels.idea-label-aria-label', {label: label.name})}
                            key={label.id}
                            title={label.name}
                            className={`label label-field-${label.colorKey} text-truncate ${(clickable && label.searchable) ? 'cursor-pointer' : 'cursor-default'}`}
                            onClick={(event) => onIdeaLabelClick(event, label)}>
                            {label.name}
                        </DropdownItem>)
                }
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};