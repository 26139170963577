import React from 'react';
import {useMutation, useQueryClient} from 'react-query';
import {Avatar, Icon} from '@ideascale/ui';
import {
    AlertEvent,
    AlertType,
    buildAlertEventData,
    ClassificationsHolder,
    eventDispatcher,
    FileUploadResponse,
    Member,
    MessageModal,
    UploadProgressCallback,
    useApiErrorResponseHandler,
    useToggle
} from '@ideascale/commons';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useMessageService} from 'hooks/useMessageService';
import {useFileUploadService} from 'hooks/useFileUploadService';
import {EditAboutMeModal} from 'components/profile/sidebar/EditAboutMeModal';
import {ProfileAboutMeData} from 'models/ProfileAboutMeData';
import {ProfileSidebarResponse} from 'models/ProfileSidebarResponse';
import {GenericResponse} from 'models/GenericResponse';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {QUERY_KEYS} from 'constants/AppConstants';
import kudoImg from 'assets/img/kudos/regular.png';

interface AboutMeProps extends ProfileAboutMeData {
    actorId: number;
    currentProfileId: number;
    toggleMemberSubscription: (memberId: number, follow: boolean) => Promise<GenericResponse>;
    uploadImage: (data: FormData, onUploadProgress: UploadProgressCallback) => Promise<FileUploadResponse>;
    updateProfileAboutMeSection: (parameters: {
        id: number,
        avatar: string,
        firstName: string,
        lastName: string
    }) => Promise<ProfileSidebarResponse>;

    fetchRecipients(pageParameters: PageParameters): Promise<PagedResponseContent<Member>>;

    fetchClassifications: () => Promise<ClassificationsHolder>;
}

export const AboutMe = (props: AboutMeProps) => {
    const {
        memberSummary,
        followed,
        createdAt,
        implementedIdeaCount,
        commentCount,
        voteCount,
        kudos,
        ideaCount,
        actorId,
        currentProfileId,
        toggleMemberSubscription,
        uploadImage,
        updateProfileAboutMeSection,
        fetchRecipients,
        editAvatar,
        editName,
        fetchClassifications,
    } = props;
    const [aboutModalIsOpen, toggleAboutMeModal] = useToggle(false);
    const localizer = useLocalizer();
    const queryClient = useQueryClient();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [messageOpen, toggleMessageOpen] = useToggle(false);
    const selfProfile = actorId === currentProfileId && actorId === memberSummary.id;
    const {
        authentication,
        communityConfig: {
            name: communityName,
            offensiveEmojis,
            maxFileSizeLimit,
            maxAvatarFileSizeLimit,
            classificationEnabled,
            privateMessageMaxRecipients,
            privateMessageMaxSubjectLength,
            privateMessageEnabled
        }
    } = useAppContext();
    const {onMemberSendMessages} = useMessageService();
    const {tempImageUpload} = useFileUploadService();
    const totalKudoCount = kudos.map(kudo => kudo.count).reduce((prev, next) => prev + next, 0);
    const {mutate: mutateMemberSubscription, isLoading} = useMutation(
        () => toggleMemberSubscription(memberSummary.id, !followed),
        {
            onSuccess: async (response) => {
                const queryKey = [QUERY_KEYS.PROFILE_SIDEBAR, currentProfileId];
                const oldData: ProfileSidebarResponse | undefined = queryClient.getQueryData(queryKey);
                if (oldData) {
                    queryClient.setQueryData(queryKey, {...oldData, followed: !oldData.followed});
                } else {
                    await queryClient.invalidateQueries(queryKey);
                }
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, response.message));
            },
            onError: (error: any) => {
                handleErrorResponse(error, {
                    fallbackMessage: {
                        type: AlertType.error,
                        message: localizer.msg(followed ? 'profile.about-me.errors.unfollow' : 'profile.about-me.errors.follow')
                    }
                });
            }
        }
    );

    return (
        <section className="card panel text-center profile-about-me">
            <div className="card-body">
                {
                    selfProfile && (editAvatar || editName) &&
                    <button type="button" className="btn btn-link p-0 btn-edit"
                            title={localizer.msg('profile.about-me.edit.heading')}
                            onClick={toggleAboutMeModal}>
                        <Icon iconSpritePath={svgIconPath} name="pencil"/>
                    </button>
                }
                <div>
                    <Avatar src={memberSummary.avatar} size="lg"
                            alt={memberSummary.username ? localizer.msg('common.user-avatar', {username: memberSummary.username}) : ''}/>
                    <p className="font-size-md fw-bold mt-3 mb-0 h5">{memberSummary.name}</p>
                    <p>{memberSummary.username && <>@{memberSummary.username}</>}</p>
                    {(!selfProfile && authentication.isAuthenticated()) &&
                        <button className="btn btn-secondary w-100 d-flex align-items-center justify-content-center"
                                onClick={() => mutateMemberSubscription()}
                                disabled={isLoading}>
                            <Icon iconSpritePath={svgIconPath} name={followed ? 'star-solid' : 'star-outlined'}
                                  className="me-1 active position-relative pos-top-n1"
                                  width={18} height={18}/>
                            {followed
                                ? localizer.msg('profile.about-me.unfollow')
                                : localizer.msg('profile.about-me.follow')
                            }
                        </button>
                    }
                </div>
                <div className="text-start">
                    <div className="mt-2 profile-stat">
                        <Icon iconSpritePath={svgIconPath} name="calendar-day" fill="#666"/>
                        {' '}{localizer.msg('profile.about-me.member-since', {time: localizer.dateTime(createdAt)})}
                    </div>
                    <div className="mt-2 profile-stat">
                        <Icon iconSpritePath={svgIconPath} name="lightbulb" fill="#666"/>
                        {' '}{localizer.msg('profile.about-me.ideas-posted', {count: ideaCount})}
                    </div>
                    <div className="mt-2 profile-stat">
                        <Icon iconSpritePath={svgIconPath} name="message-solid-right" fill="#666"/>
                        {' '}{localizer.msg('profile.about-me.comments', {count: commentCount})}
                    </div>
                    <div className="mt-2 profile-stat">
                        <Icon iconSpritePath={svgIconPath} name="square-check-rounded" fill="#666"/>
                        {' '}{localizer.msg('profile.about-me.votes', {count: voteCount})}
                    </div>
                    <div className="mt-2 profile-stat">
                        <Icon iconSpritePath={svgIconPath} name="flag-waving" fill="#666"/>
                        {' '}{localizer.msg('profile.about-me.ideas-implemented.n', {count: implementedIdeaCount})}{' '}
                        <span
                            className="label label-field-implemented">{localizer.msg('profile.about-me.ideas-implemented.implemented')}</span>
                    </div>
                    <div className="mt-2">
                        <img src={kudoImg} className="img-size-18p"
                             alt="kudos icon"/>
                        {' '}
                        {
                            localizer.msg('profile.about-me.kudos.summary.n', {count: totalKudoCount})
                        }
                    </div>
                    {
                        privateMessageEnabled && (!selfProfile && authentication.isAuthenticated()) &&
                        <div className="mt-2">
                            <Icon iconSpritePath={svgIconPath} name="envelope" fill="#666"
                                  title={localizer.msg('profile.about-me.send-pm.title')}
                                  desc={localizer.msg('profile.about-me.send-pm.title')}/>
                            <button className="btn btn-link p-0 ms-1 fw-normal"
                                    onClick={toggleMessageOpen}>
                                {localizer.msg('profile.about-me.send-pm.label')}
                            </button>
                            {
                                messageOpen &&
                                <MessageModal open={messageOpen} toggle={toggleMessageOpen}
                                              fetchRecipients={fetchRecipients}
                                              fetchClassifications={fetchClassifications}
                                              tempImageUpload={tempImageUpload}
                                              onMemberSendMessages={onMemberSendMessages}
                                              localizer={localizer}
                                              communityName={communityName}
                                              offensiveEmojis={offensiveEmojis}
                                              maxFileSizeLimit={maxFileSizeLimit}
                                              maxSubjectLength={privateMessageMaxSubjectLength}
                                              classificationEnabled={classificationEnabled}
                                              privateMessageMaxRecipients={privateMessageMaxRecipients}
                                              defaultMember={[{
                                                  value: memberSummary.id,
                                                  label: memberSummary.name,
                                                  image: memberSummary.avatar
                                              }]}/>
                            }
                        </div>
                    }

                </div>
            </div>
            {
                aboutModalIsOpen && selfProfile && (editAvatar || editName) &&
                <EditAboutMeModal
                    canEditName={editName}
                    canEditAvatar={editAvatar}
                    localizer={localizer}
                    toggle={toggleAboutMeModal}
                    isOpen={aboutModalIsOpen}
                    firstName={memberSummary.firstName}
                    lastName={memberSummary.lastName}
                    actorId={actorId}
                    uploadImage={uploadImage}
                    maxFileSizeInMb={maxAvatarFileSizeLimit}
                    updateProfileAboutMeSection={updateProfileAboutMeSection}
                />
            }
        </section>
    );
};