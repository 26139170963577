import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {useInfiniteQuery} from 'react-query';
import {CommonUtil} from '@ideascale/ui';
import {
    Campaign,
    CampaignsHolder,
    LabelData,
    PageTheme,
    useApiErrorResponseHandler,
    useToggle
} from '@ideascale/commons';
import {EditModeUtil} from 'utils/EditModeUtil';
import {useAppContext} from 'contexts/AppContext';
import {PagedIdeas, useIdeasContext} from 'contexts/IdeasContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useIdeaService} from 'hooks/useIdeaService';
import {useMessageService} from 'hooks/useMessageService';
import {useTranslation} from 'hooks/useTranslation';
import {useLandingPageService} from 'hooks/useService';
import {IdeaConfigModal} from 'components/landing-page/IdeaConfigModal';
import {IdeasComponent} from 'components/landing-page/IdeasComponent';
import {ConfigWrapper} from 'components/landing-page/ConfigWrapper';
import {TranslationModal} from 'shared/TranslationModal';
import {DEFAULT_STALE_TIME, QUERY_KEYS, TABINDEX_SET_DELAY} from 'constants/AppConstants';
import {IdeasComponentData} from 'models/landing-page/IdeasComponentData';
import {PageParameters} from 'models/types/PageParameters';
import {IdeaSummary} from 'models/IdeaSummary';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {IdeaSearchParameters} from 'models/types/IdeaSearchParameters';
import {IdeasComponentRequest} from 'models/landing-page/IdeasComponentRequest';
import {IdeaListMode} from 'models/enums/IdeaListMode';
import {TranslationConfig} from 'models/TranslationConfig';
import {TranslationContentType} from 'models/enums/landing-page/TranslationContentType';
import {TranslationFieldType} from 'models/enums/TranslationFieldType';
import {TranslationContentResponse} from 'models/landing-page/TranslationContentResponse';
import {LandingPageComponentType} from 'models/enums/landing-page/LandingPageComponentType';

type IdeasContainerProps = {
    showConfig: boolean;
    pinnedIdeas?: boolean;
    data: IdeasComponentData;
    theme: PageTheme,
    showTranslationConfig: boolean,
    onComponentEdited: () => void,
    translationLanguageId?: number,
    accessibleName: string;
}

const DEFAULT_PAGE_LIMIT = 4;

export const IdeasContainer = (props: IdeasContainerProps) => {
    const {
        showConfig,
        pinnedIdeas = false,
        data: IdeaContainerData,
        theme,
        showTranslationConfig,
        translationLanguageId,
        onComponentEdited,
        accessibleName = ''
    } = props;
    const localizer = useLocalizer();
    const {editModeEnabled} = useAppContext();
    const {handleErrorResponse, showErrorMessage} = useApiErrorResponseHandler({localizer});
    const [currentPage, setCurrentPage] = useState(0);
    const [showEditModal, toggleEditModal] = useToggle(false);
    const [showTranslationModal, toggleTranslationModal] = useToggle(false);
    const [ideasConfig, setIdeasConfig] = useState(IdeaContainerData);
    const {
        fetchModerateActions,
        fetchMoreActions,
        fetchIdeaLabels,
        toggleIdeaFollowed,
        toggleAuthorFollowed,
        toggleCommentingEnabled,
        fetchMembers,
        fetchAssignedOwners,
        emailIdeaAuthor,
        fetchAuthorEmails,
        banMember,
        togglePinIdeas,
        getAuthorEmailIdeaContent
    } = useIdeaService();
    const {getTranslatedContent, saveTranslatedContent} = useTranslation();
    const {fetchRecipients} = useMessageService();
    const {setIdeaListFilterQueryKey} = useIdeasContext();
    const landingPageService = useLandingPageService();

    const saveIdeasConfig = useCallback(async (config: IdeasComponentRequest) => {
        if (landingPageService !== null) {
            return await landingPageService.saveIdeasConfig(config);
        }
        return IdeasComponentData.EMPTY;
    }, [landingPageService]);

    const getIdeaTags = useCallback(async (param: PageParameters): Promise<PagedResponseContent<string>> => {
        if (landingPageService !== null) {
            return await landingPageService.fetchIdeaTags(param);
        }
        return PagedResponseContent.nullObject();
    }, [landingPageService]);

    const getFilterLabels = useCallback(async (param: PageParameters): Promise<PagedResponseContent<LabelData>> => {
        if (landingPageService !== null) {
            return await landingPageService.fetchLabels(param);
        }
        return PagedResponseContent.nullObject();
    }, [landingPageService]);

    const getFilterCampaigns = useCallback(async (param: PageParameters): Promise<PagedResponseContent<CampaignsHolder<Campaign>>> => {
        if (landingPageService !== null) {
            return await landingPageService.fetchCampaigns(param);
        }
        return PagedResponseContent.nullObject();
    }, [landingPageService]);

    const onPrevClicked = () => {
        setCurrentPage(prevState => prevState - 1);
    };

    const onNextClicked = () => {
        if (!data?.pages[currentPage + 1]) {
            fetchNextPage().then(() => setCurrentPage(prevState => prevState + 1));
        } else {
            setCurrentPage(prevState => prevState + 1);
        }
    };

    const onToggleVisibility = async () => {
        const newData = {...ideasConfig};
        newData.visible = !newData.visible;
        await updateIdeasConfigData(newData);
        onComponentEdited();
    };

    const generateQueryKey = useMemo(() => {
        return QUERY_KEYS.LANDING_PAGE_IDEAS + '-' + ideasConfig.id;
    }, [ideasConfig.id]);

    const getLandingIdeasListQueryKey = useMemo(() => {
        return [generateQueryKey, {
            labelKey: ideasConfig.label?.key || '',
            tag: ideasConfig.tag || '',
            campaignIds: ideasConfig.campaigns?.map(item => item.id) || [],
            mode: ideasConfig.ideaListMode?.toLowerCase() || IdeaListMode.TRENDING.toLowerCase(),
            listViewMode: ideasConfig.ideaListViewMode,
        }];
    }, [generateQueryKey, ideasConfig.label?.key, ideasConfig.tag, ideasConfig.campaigns, ideasConfig.ideaListMode, ideasConfig.ideaListViewMode]);

    const getIdeas = useCallback(async ({pageParam = 0, queryKey}: any) => {
        if (landingPageService !== null) {
            try {
                const {labelKey, tag, campaignIds, mode, listViewMode} = queryKey[1];
                const searchParams: IdeaSearchParameters = {
                    tag: tag || '',
                    moderatorTag: '',
                    applicableIdeasOnly: false,
                    labelKey: labelKey,
                    campaignIds: campaignIds,
                    pageParameters: {page: pageParam},
                    listViewMode: listViewMode
                };
                let pagedResponse;
                if (pinnedIdeas) {
                    pagedResponse = await landingPageService.fetchPinnedIdeas({
                        ...searchParams,
                        pageParameters: {page: pageParam, limit: DEFAULT_PAGE_LIMIT}
                    });
                } else {
                    pagedResponse = await landingPageService.fetchIdeas(mode, {
                        ...searchParams,
                        pageParameters: {page: pageParam, limit: DEFAULT_PAGE_LIMIT}
                    });
                }
                return {
                    data: pagedResponse.content,
                    totalIdeas: pagedResponse.totalElements,
                    allPages: Math.ceil(pagedResponse.totalElements / pagedResponse.pageSize),
                    nextPage: pagedResponse.hasMore ? pagedResponse.pageNo + 1 : undefined,
                    prevPage: pagedResponse.pageNo > 0 ? pagedResponse.pageNo - 1 : undefined
                } as PagedIdeas;
            } catch (error: any) {
                handleErrorResponse(error);
            }
        }
    }, [handleErrorResponse, landingPageService, pinnedIdeas]);

    const {
        data,
        fetchNextPage,
        isLoading,
        isRefetching
    } = useInfiniteQuery<PagedIdeas | undefined>(getLandingIdeasListQueryKey, getIdeas, {
        staleTime: DEFAULT_STALE_TIME,
        getNextPageParam: (lastPage) => lastPage?.nextPage,
        getPreviousPageParam: (firstPage) => firstPage?.prevPage
    });

    const onEditClicked = useCallback(() => {
        toggleEditModal();
    }, [toggleEditModal]);

    const nextPageDisabled = useMemo(() => {
        return !data?.pages[currentPage]?.nextPage;
    }, [currentPage, data?.pages]);

    const ideaList = useMemo(() => {
        let ideas: IdeaSummary[] | undefined = [];
        if (data) {
            if (data.pages[currentPage]?.data as IdeaSummary[]) {
                ideas?.push(...data.pages[currentPage]?.data as IdeaSummary[]);
            }
        }

        if (editModeEnabled) {
            CommonUtil.wait(TABINDEX_SET_DELAY).then(() => {
                EditModeUtil.setTabIndexOnlyForEditModeElements();
            });
        }
        return ideas;
    }, [currentPage, data, editModeEnabled]);

    const updateIdeasConfigData = useCallback(async (component: IdeasComponentData) => {
        const ideasRequest: IdeasComponentRequest = {
            ...component,
            campaignIds: component.campaigns?.map(item => item.id) || [],
            label: component.label?.key || ''
        };
        try {
            const response = await saveIdeasConfig(ideasRequest);
            if (response) {
                setIdeasConfig(response);
                setCurrentPage(0);
            }
        } catch (e: any) {
            if (e.data && 'validationErrors' in e.data) {
                throw e.data;
            } else {
                showErrorMessage(e);
            }
        }
    }, [saveIdeasConfig, showErrorMessage]);

    const onTranslationClicked = useCallback(() => {
        if (translationLanguageId !== undefined) {
            toggleTranslationModal();
        }
    }, [toggleTranslationModal, translationLanguageId]);

    const onTextTranslated = useCallback((response: TranslationContentResponse) => {
        setIdeasConfig(prev => {
            const newData = {...prev};
            if (response.contentType === TranslationContentType.PAGE_COMPONENT_TITLE) {
                newData.title = response.translation;
            } else {
                newData.subtitle = response.translation;
            }
            return newData;
        });
    }, []);

    const translationConfigs = useMemo(() => {
        return theme === PageTheme.CURIE && ideasConfig.type === LandingPageComponentType.PINNED_IDEAS ?
            [
                new TranslationConfig(
                    TranslationContentType.PAGE_COMPONENT_TITLE,
                    ideasConfig.id,
                    TranslationFieldType.TEXT,
                ),
                new TranslationConfig(
                    TranslationContentType.PAGE_COMPONENT_SUBTITLE,
                    ideasConfig.id,
                    TranslationFieldType.TEXT,
                ),
            ] :
            [
                new TranslationConfig(
                    TranslationContentType.PAGE_COMPONENT_TITLE,
                    ideasConfig.id,
                    TranslationFieldType.TEXT,
                ),
            ];
    }, [ideasConfig.id, ideasConfig.type, theme]);

    useEffect(() => {
        setIdeaListFilterQueryKey(getLandingIdeasListQueryKey);
    }, [getLandingIdeasListQueryKey, setIdeaListFilterQueryKey]);

    return (
        <Fragment>
            <ConfigWrapper visible={ideasConfig.visible} toggleVisibility={onToggleVisibility}
                           showConfig={showConfig} localizer={localizer}
                           showTranslationConfig={showTranslationConfig}
                           onEditClicked={onEditClicked} onTranslationClicked={onTranslationClicked}
                           className={`${ideasConfig.attribute?.className} ideas-component`}
                           accessibleName={accessibleName}>
                <IdeasComponent config={ideasConfig}
                                editMode={showConfig || showTranslationConfig}
                                localizer={localizer}
                                currentPage={currentPage}
                                isLoadingIdeas={isLoading}
                                isRefetchingIdeas={isRefetching}
                                ideaList={ideaList}
                                onNextClicked={onNextClicked}
                                onPrevClicked={onPrevClicked}
                                nextPageDisabled={nextPageDisabled}
                                fetchModerateActions={fetchModerateActions}
                                fetchMoreActions={fetchMoreActions}
                                fetchIdeaLabels={fetchIdeaLabels}
                                toggleIdeaFollowed={toggleIdeaFollowed}
                                toggleAuthorFollowed={toggleAuthorFollowed}
                                toggleCommentingEnabled={toggleCommentingEnabled}
                                fetchMembers={fetchMembers}
                                togglePinIdeas={togglePinIdeas}
                                getAuthorEmailIdeaContent={getAuthorEmailIdeaContent}
                                emailIdeaAuthor={emailIdeaAuthor}
                                fetchAuthorEmails={fetchAuthorEmails}
                                fetchAssignedOwners={fetchAssignedOwners}
                                fetchRecipients={fetchRecipients}
                                pinnedIdeas={pinnedIdeas}
                                banMember={banMember} theme={theme}/>
            </ConfigWrapper>
            {
                showEditModal &&
                <IdeaConfigModal theme={theme}
                                 open={showEditModal}
                                 toggle={toggleEditModal}
                                 config={ideasConfig}
                                 localizer={localizer}
                                 renderSubtitle={theme === PageTheme.CURIE && ideasConfig.type === LandingPageComponentType.PINNED_IDEAS}
                                 saveConfig={updateIdeasConfigData}
                                 getIdeaTags={getIdeaTags}
                                 onComponentEdited={onComponentEdited}
                                 getFilterLabels={getFilterLabels}
                                 getFilterCampaigns={getFilterCampaigns}/>
            }
            {
                showTranslationModal && translationLanguageId !== undefined &&
                <TranslationModal translationLanguageId={translationLanguageId} localizer={localizer}
                                  open={showTranslationModal}
                                  toggle={toggleTranslationModal}
                                  onTextTranslated={onTextTranslated}
                                  onComponentEdited={onComponentEdited}
                                  translationConfigs={translationConfigs}
                                  getTranslatedContent={getTranslatedContent}
                                  saveTranslatedContent={saveTranslatedContent}/>
            }
        </Fragment>
    );
};