import React, {ReactNode} from 'react';
import {CollapseExpand} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import styles from 'components/sidebar/StaticContentWidget.module.scss';

type StaticContentWidgetProps = {
    heading: string;
    title: string;
    content: ReactNode;
    button?: {
        label: string,
        onClick: () => void,
        external: boolean
    }
}

export const StaticContentWidget = (props: StaticContentWidgetProps) => {
    const {
        heading,
        title,
        content,
        button,
    } = props;

    return (
        <CollapseExpand containerClass="contact panel-light"
                        headerTitleAttr={heading}
                        headerTitle={heading}
                        svgIconSprite={svgIconPath}
                        defaultOpen={true}>
            <strong>{title}</strong>
            <p className="mt-3">{content}</p>
            {
                button &&
                <div className="d-flex justify-content-center w-100">
                    <button className={`btn btn-secondary px-5 ${button.external ? styles.btnWidgetExternal : ''}`}
                            onClick={button.onClick}>
                        {button.label}
                    </button>
                </div>
            }
        </CollapseExpand>
    );
};