import {Command} from './Command';
import {CampaignHomeEditor} from './Editors/CampaignHomeEditor';
import {Operation} from 'models/edit-mode/Operation';
import {BannerField} from 'models/edit-mode/BannerField';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';
import {ChangeCampaignFeaturedImageOperation} from 'models/edit-mode/ChangeCampaignFeaturedImageOperation';

export class UpdateCampaignFeaturedImage implements Command {
    private readonly campaignHomeEditor: CampaignHomeEditor;
    private readonly newFeaturedImage: BannerField;
    private readonly oldFeaturedImage: BannerField;
    private readonly name: CampaignOperationType;
    private readonly campaignId: number;

    constructor(campaignHomeEditor: CampaignHomeEditor, newFeaturedImage: BannerField, campaignId: number) {
        this.campaignHomeEditor = campaignHomeEditor;
        this.oldFeaturedImage = this.campaignHomeEditor.campaignFeaturedImage;
        this.newFeaturedImage = newFeaturedImage;
        this.campaignId = campaignId;
        this.name = CampaignOperationType.CHANGE_CAMPAIGN_FEATURED_IMAGE;
    }

    execute(): void {
        this.campaignHomeEditor.campaignFeaturedImage = this.newFeaturedImage;
    }

    getType(): CampaignOperationType {
        return this.name;
    }

    getOperation(): Operation {
        const featuredImage = this.campaignHomeEditor.campaignFeaturedImage;
        return new ChangeCampaignFeaturedImageOperation(
            this.name,
            featuredImage.fileId,
            featuredImage.gradientEnabled,
            featuredImage.blackPageTitleEnabled,
            featuredImage.fileId === 0 ? null : featuredImage.imageType,
            this.campaignId
        );
    }

    undo(): void {
        this.campaignHomeEditor.campaignFeaturedImage = this.oldFeaturedImage;
    }
}