import React from 'react';
import {Trans} from 'react-i18next';
import {LabelData} from '@ideascale/commons';
import {IdeaSummary} from 'models/IdeaSummary';
import './IdeaListSelectedLabel.scss';

type IdeaListSelectedLabelProps = {
    labeledIdea: IdeaSummary | undefined;
    labelKey: string;
    count: number;
}

export const IdeaListSelectedLabel = (props: IdeaListSelectedLabelProps) => {
    const {labeledIdea, labelKey, count} = props;

    const getLabel = () => {
        let label;
        if(labeledIdea) {
            label = labeledIdea.labels.find(label => label.key === labelKey)
        }
        return label || LabelData.EMPTY;
    }

    return (
        <div className="d-flex mt-3 mb-3 align-items-center fw-bold label-search-result">
            <Trans i18nKey="idea-list.selected-label" values={{
                count: count,
                label: getLabel().name
            }}>
                <span className={`ms-1 label label-field-${getLabel().colorKey}`}>Label</span>
            </Trans>
        </div>
    )
}