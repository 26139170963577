import React, {Fragment, useRef, useState} from 'react';
import range from 'lodash/range';
import {Collapse} from 'reactstrap';
import {Icon} from '@ideascale/ui';
import {Localizer, MemberLink, useToggle} from '@ideascale/commons';
import {ReviewScore} from 'models/stage-activity/ReviewScore';
import {ReviewscaleStageViewActivity} from 'models/stage-activity/ReviewscaleStageViewActivity';
import {ReviewNotesResponse} from 'models/stage-activity/ReviewNotesResponse';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import './ReviewScaleReport.scss';

type ReviewScaleReportProps = {
    localizer: Localizer;
    stageId: number;
    activity: ReviewscaleStageViewActivity;
    fetchNotes: (stageId: number) => Promise<ReviewNotesResponse>;
}

export const ReviewScaleReport = (props: ReviewScaleReportProps) => {
    const {localizer, activity, fetchNotes, stageId} = props;

    const [notes, setNotes] = useState<ReviewNotesResponse | null>(null);
    const [showNotes, toggleNotes] = useToggle(false);

    const chartHolderRef = useRef<HTMLDivElement>(null);
    const chartSummaryHolderRef = useRef<HTMLDivElement>(null);
    const chartNotesRef = useRef<HTMLDivElement>(null);

    const onShowNotes = async () => {
        if (notes === null) {
            const response = await fetchNotes(stageId);
            setNotes(response);
        }
        toggleNotes();
    };

    const getFactorScore = (scores: ReviewScore[], factorId: number) => {
        return scores.find(value => value.factorId === factorId)?.score || 0;
    };

    const getChartValue = (scores: ReviewScore[], factorId: number, totalScore: number) => {
        return totalScore === 0 ? 0 : getFactorScore(scores, factorId) / totalScore * 10;
    };

    const getChartWidth = () => {
        return (activity?.totalFactorCount || 1) * 75 + 'px';
    };

    const adjustSummaryHolderAndNoteScrollbar = () => {
        const scrollLeft = chartHolderRef.current ? chartHolderRef.current.scrollLeft : 0;
        if (chartSummaryHolderRef.current) {
            chartSummaryHolderRef.current.scrollLeft = scrollLeft;
        }
        if (chartNotesRef.current) {
            chartNotesRef.current.scrollLeft = scrollLeft;
        }
    };

    const adjustChartHolderAndNoteScrollbar = () => {
        const scrollLeft = chartSummaryHolderRef.current ? chartSummaryHolderRef.current.scrollLeft : 0;
        if (chartHolderRef.current) {
            chartHolderRef.current.scrollLeft = scrollLeft;
        }
        if (chartNotesRef.current) {
            chartNotesRef.current.scrollLeft = scrollLeft;
        }
    };

    const adjustChartHolderAndSummaryScrollbar = () => {
        const scrollLeft = chartNotesRef.current ? chartNotesRef.current.scrollLeft : 0;
        if (chartHolderRef.current) {
            chartHolderRef.current.scrollLeft = scrollLeft;
        }
        if (chartSummaryHolderRef.current) {
            chartSummaryHolderRef.current.scrollLeft = scrollLeft;
        }
    };

    return (
        <div className="reviewscale-report">
            <div className="vector-chart-holder">
                <div className="summary-v-chart">
                    <div>{localizer.msg('stage.reviewscale.impact-ratio')}</div>
                    <h4>{activity?.roiScore.toFixed(2)}</h4>
                    <div>{localizer.msg('stage.reviewscale.value-score')}</div>
                    <h4>{(activity?.valueFactorResult?.totalScore || 0).toFixed(2)}</h4>
                    <div>{localizer.msg('stage.reviewscale.cost-score')}</div>
                    <h4>{(activity?.costFactorResult?.totalScore || 0).toFixed(2)}</h4>
                </div>
                <div className="reviewscale-scrollable" ref={chartHolderRef}
                     onScroll={adjustSummaryHolderAndNoteScrollbar}>
                    <div className="review-scale-slide vector-chart"
                         style={{width: getChartWidth()}}>

                        {
                            activity?.valueFactorResult &&
                            <div className="group value-group">
                                {
                                    activity?.valueFactorResult.factors.map(factor =>
                                        <Fragment key={factor.id}>
                                            <div className="review-slider" id={`value-factor-score-${factor.id}`}>
                                                <ul className="list-unstyled">
                                                    {
                                                        range(10, 0, -1).map(index => <li key={index}
                                                                                          className={`${index > Math.round(getChartValue(activity?.valueFactorResult.scores, factor.id, activity?.valueFactorResult.totalScore)) ? 'fill' : ''}`}/>)
                                                    }
                                                </ul>
                                            </div>
                                        </Fragment>
                                    )
                                }
                            </div>
                        }

                        {
                            activity?.costFactorResult &&
                            <div className="group cost-group">
                                {
                                    activity?.costFactorResult.factors.map(factor =>
                                        <Fragment key={factor.id}>
                                            <div className="review-slider" id={`cost-factor-score-${factor.id}`}>
                                                <ul className="list-unstyled">
                                                    {
                                                        range(10, 0, -1).map(index => <li key={index}
                                                                                          className={`${index > Math.round(getChartValue(activity?.costFactorResult.scores, factor.id, activity?.costFactorResult.totalScore)) ? 'fill' : ''}`}/>)
                                                    }
                                                </ul>
                                            </div>
                                        </Fragment>
                                    )
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="vector-chart-summary-holder mb-4">
                <div className="factor-label-container hyphenate">
                    <div className="factor-score-label">{localizer.msg('stage.reviewscale.factor-score')}</div>
                    <div className="factor-weight-label">{localizer.msg('stage.reviewscale.factor-weights')}</div>
                </div>
                <div className="overflow-auto" ref={chartSummaryHolderRef} onScroll={adjustChartHolderAndNoteScrollbar}>
                    <ul className="list-unstyled reviewscale-scroll" style={{width: getChartWidth()}}>
                        {
                            activity?.valueFactorResult?.factors.map(factor => <li key={factor.id}>
                                <div
                                    className="factor-score">{getFactorScore(activity?.valueFactorResult.scores, factor.id).toFixed(2)}</div>
                                <div className="text-truncate" title={factor.name}>{factor.name}</div>
                                <div className="factor-weight">{factor.weight}%</div>
                            </li>)
                        }
                        {
                            activity?.costFactorResult?.factors.map(factor => <li key={factor.id}>
                                <div
                                    className="factor-score">{getFactorScore(activity?.costFactorResult.scores, factor.id).toFixed(2)}</div>
                                <div className="text-truncate" title={factor.name}>{factor.name}</div>
                                <div className="factor-weight">{factor.weight}%</div>
                            </li>)
                        }
                    </ul>
                </div>
            </div>
            {
                activity?.valueOrCostFactorNoteCount > 0 &&
                <div className="vector-chart-summary-holder review-scale-view-notes">
                    <div className="factor-label-container">
                        <div className="factor-score-label">
                            <button
                                className="btn btn-link-secondary p-0 mt-n3 font-size-sm text-decoration-none shadow-none"
                                type="button"
                                onClick={onShowNotes}>
                                {
                                    showNotes
                                        ?
                                        <>
                                            <span>{localizer.msg('stage.reviewscale.hide-notes')}</span>
                                            <Icon className="ms-1" iconSpritePath={svgIconsPath}
                                                  name="chevron-up" width={11}
                                                  height={11}/>
                                        </>
                                        :
                                        <>
                                            <span>{localizer.msg('stage.reviewscale.view-notes')}</span>
                                            <Icon className="ms-1" iconSpritePath={svgIconsPath}
                                                  name="chevron-down"
                                                  width={11} height={11}/>
                                        </>
                                }

                            </button>
                        </div>
                    </div>
                    <Collapse className="review-scale-view-notes-items" isOpen={showNotes}>
                        <div className="overflow-auto" ref={chartNotesRef}
                             onScroll={adjustChartHolderAndSummaryScrollbar}>
                            <ul className="list-unstyled reviewscale-scroll" style={{width: getChartWidth()}}>
                                {
                                    notes?.valueFactors.map(factor => <li key={factor.id}>
                                        {notes?.valueNotes.map(note => <div key={note.createdAt}>
                                            {
                                                note.factorId === factor.id &&
                                                <p>
                                                    {note.note}
                                                    <MemberLink className="fw-bold" id={note.member.id}
                                                                identityHidden={note.member.identityHidden}>
                                                        {note.member.name}
                                                    </MemberLink>
                                                    {localizer.date(note.createdAt)}
                                                </p>
                                            }
                                        </div>)}
                                    </li>)
                                }
                                {
                                    notes?.costFactors.map(factor => <li key={factor.id}>
                                        {
                                            notes?.costNotes.map(note => <div key={note.createdAt}>
                                                {
                                                    note.factorId === factor.id &&
                                                    <p>
                                                        {note.note}
                                                        <MemberLink className="fw-bold" id={note.member.id}
                                                                    identityHidden={note.member.identityHidden}>
                                                            {note.member.name}
                                                        </MemberLink>
                                                        {localizer.date(note.createdAt)}
                                                    </p>
                                                }
                                            </div>)
                                        }
                                    </li>)
                                }
                            </ul>
                        </div>
                    </Collapse>
                </div>
            }
        </div>
    );
};