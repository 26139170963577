import React, {Fragment, RefObject, useCallback, useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {Icon, ParagraphSkeleton, ScrollbarInstance} from '@ideascale/ui';
import {
    Campaign,
    ClassificationLabel,
    Localizer,
    StringUtil,
    useApiErrorResponseHandler,
    useApplicableClassifications
} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useProfilePageParams} from 'hooks/useProfilePageParams';
import {appLinks} from 'services/AppLinks';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {CampaignSubscribeResponse} from 'models/CampaignSubscribeResponse';

const PAGE_SIZE = 5;

type FollowedCampaignGroupProps = {
    localizer: Localizer;
    groupId: number;
    fetchCampaignsByGroup: (pageParameters: PageParameters, groupId: number) => Promise<PagedResponseContent<Campaign>>;
    unsubscribeCampaign: (campaignId: number) => Promise<CampaignSubscribeResponse>;
    subscribeCampaign: (campaignId: number) => Promise<CampaignSubscribeResponse>;
    actionable: boolean;
    scrollRef: RefObject<ScrollbarInstance>
}

interface CampaignSubscription extends Campaign {
    followed: boolean;
}

export const FollowedGroupedCampaigns = (props: FollowedCampaignGroupProps) => {
    const {
        localizer,
        groupId,
        fetchCampaignsByGroup,
        unsubscribeCampaign,
        subscribeCampaign,
        actionable,
        scrollRef
    } = props;
    const {communityConfig} = useAppContext();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [campaignData, setCampaignData] = useState<CampaignSubscription[]>([]);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const currentProfileId = useProfilePageParams();
    const currentPage = useRef(0);
    const {getClassificationAttribute} = useApplicableClassifications('', []);

    useEffect(() => {
        currentPage.current = 0;
        setHasMore(true);
    }, [currentProfileId]);

    const loadCampaigns = useCallback(async () => {
        setLoading(true);
        fetchCampaignsByGroup({page: currentPage.current, limit: PAGE_SIZE}, groupId)
            .then(data => {
                let hasMore = data.hasMore;
                setHasMore(hasMore);
                setLoading(false);
                let newData = data.content.map(member => ({...member, followed: true}));
                if (currentPage.current === 0) {
                    setCampaignData(newData);
                } else {
                    setCampaignData(prevData => [...prevData, ...newData]);
                }
                if (hasMore) {
                    currentPage.current = currentPage.current + 1;
                }
                scrollRef.current?.scrollTop(scrollRef.current?.getScrollTop() + 1);
            })
            .catch(e => {
                setLoading(false);
                handleErrorResponse(e);
                setLoading(false);
            });
    }, [fetchCampaignsByGroup, groupId, handleErrorResponse, scrollRef]);

    const toggleFollowed = async (campaignId: number) => {
        let newCampaignData = [...campaignData];
        let campaign = newCampaignData.find(campaign => campaign.id === campaignId);
        if (campaign) {
            if (campaign.followed) {
                try {
                    await unsubscribeCampaign(campaignId);
                    campaign.followed = false;
                    setCampaignData(newCampaignData);
                } catch (error: any) {
                    handleErrorResponse(error);
                }
            } else {
                try {
                    await subscribeCampaign(campaignId);
                    campaign.followed = true;
                    setCampaignData(newCampaignData);
                } catch (error: any) {
                    handleErrorResponse(error);
                }
            }
        }
    };

    useEffect(() => {
        if (hasMore && currentPage.current === 0) {
            loadCampaigns().then();
        }
    }, [hasMore, loadCampaigns]);

    return (
        <Fragment>
            {
                campaignData.length > 0 && <ul className="list-unstyled">
                    {campaignData.map(campaign => (
                        <li className="d-flex align-items-center py-1 px-1" key={campaign.id}>
                            {
                                actionable ? (
                                    <button className="btn btn-link p-0 mb-1"
                                            aria-label={campaign.followed ? localizer.msg('profile.followed-sections.campaign.unfollow') : localizer.msg('profile.followed-sections.campaign.follow')}
                                            onClick={() => toggleFollowed(campaign.id)} role="switch"
                                            aria-checked={campaign.followed}>
                                        <Icon className="active" iconSpritePath={svgIconPath}
                                              name={campaign.followed ? 'star-solid' : 'star-outlined'} width={20}
                                              height={20}/>
                                    </button>
                                ) : (
                                    <Icon className="active" iconSpritePath={svgIconPath} name="star-solid" width={20}
                                          height={20}/>
                                )
                            }
                            <Link to={appLinks.campaign(campaign.id.toString())} className="text-truncate">
                                {communityConfig.classificationEnabled && campaign.classificationSummary &&
                                    <ClassificationLabel
                                        classification={getClassificationAttribute(campaign.classificationSummary)}
                                        extraClasses="mb-1"/>
                                }
                                <div className="d-flex">
                                    <img className="mx-2 rounded" src={campaign.image}
                                         alt={campaign.altText}
                                         width={20}
                                         height={20}/>
                                    <span className="text-muted" title={campaign.name}>
                                        {StringUtil.textTruncate(campaign.name, 30)}
                                    </span>
                                </div>
                            </Link>
                        </li>
                    ))}
                </ul>
            }
            {
                loading && <ParagraphSkeleton rows={5}/>
            }
            {
                hasMore && (
                    <li>
                        <button className="btn btn-link fw-normal btn-view-more p-0 ms-1 mb-1"
                                onClick={() => loadCampaigns()}>
                            {localizer.msg('profile.followed-sections.campaign.view-more')}
                        </button>
                    </li>
                )
            }
        </Fragment>
    );
};