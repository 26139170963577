import React from 'react';
import {Icon} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';

type emptyDataProps = {
    icon: string;
    message: string;
    badge: boolean;
}

export const Empty = (props: emptyDataProps) => {
    const {icon, message, badge} = props;
    return (
        <div
            className="text-center empty-message col-md-11 d-flex flex-row align-items-center flex-row justify-content-center">
            <ul className="list-unstyled">
                <li className="mb-3 position-relative">
                    {badge &&
                    <div className="badge bg-primary position-absolute rounded-circle">
                        0
                    </div>
                    }
                    <Icon iconSpritePath={svgIconPath} name={icon} fill={'#999999'} width={50}
                          height={50}/>
                </li>
                <li className="fw-bold font-size-md">
                    {message}
                </li>
            </ul>
        </div>
    );
};
