import React from 'react';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {appLinks} from 'services/AppLinks';

type IdeaEventNotificationProps = {
    ideaId: number;
    ideaTitle: string;
    messageKey: string
    count?: number;
}

export const IdeaEventNotification = (props: IdeaEventNotificationProps) => {
    const {ideaId, ideaTitle, count, messageKey} = props;

    return (
        <Trans i18nKey={messageKey} values={{
            idea: ideaTitle,
            n: count
        }}>
            <Link to={appLinks.ideaDetails(ideaId)}/>
        </Trans>
    );
};