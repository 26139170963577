import React, {useCallback, useEffect, useState} from 'react';
import {AlertEvent, AlertType, buildAlertEventData, eventDispatcher, Localizer, useApiErrorResponseHandler} from '@ideascale/commons';
import {RevisionHistoryView} from 'components/RevisionHistoryView';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {RevisionHistory} from 'models/RevisionHistory';

type RefineFieldRevisionHistory = {
    fieldId: number;
    localizer: Localizer;
    actorId: number;
    fetchRevisionHistory: (fieldId: number, pageParameters: PageParameters) => Promise<PagedResponseContent<RevisionHistory>>;
}

const PAGE_SIZE = 10;

export const RefineRevisionHistory = (props: RefineFieldRevisionHistory) => {
    const {fieldId, localizer, actorId, fetchRevisionHistory} = props;
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [revisions, setRevisions] = useState<RevisionHistory[]>([]);
    const [pagination, setPagination] = useState({currentPage: 0, hasMore: true});

    const loadMoreData = async () => {
        try {
            const {content, hasMore, pageNo} = await fetchRevisionHistory(fieldId, {
                page: pagination.currentPage + 1,
                limit: PAGE_SIZE
            });
            setPagination({currentPage: pageNo, hasMore});
            setRevisions(revisions.concat(content));
        } catch (e: any) {
            handleErrorResponse(e, {
                errorHandler: showAlert
            });
        }
    };

    const showAlert = useCallback(() => {
        eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.error, localizer.msg('idea.revision-history.failed')));
    }, [localizer]);

    useEffect(() => {
        fetchRevisionHistory(fieldId, {page: 0, limit: PAGE_SIZE})
            .then(({content, hasMore, pageNo}) => {
                setPagination({currentPage: pageNo, hasMore});
                setRevisions(content);
            })
            .catch((e: any) => {
                handleErrorResponse(e, {
                    errorHandler: showAlert
                });
            });
    }, [fetchRevisionHistory, fieldId, handleErrorResponse, showAlert]);

    return (
        <RevisionHistoryView
            localizer={localizer} isOpen={true} actorId={actorId} editedText={'edited the answer'}
            scrollTargetId={`refine-revision-history-${fieldId}`} loadMore={loadMoreData}
            hasMore={pagination.hasMore}
            revisions={revisions}/>
    );
};