import React, {Fragment, useCallback} from 'react';
import {BodyTagAttributes, emptyFunction, eventDispatcher, Member, PageTitle} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useCommunityService} from 'hooks/useService';
import {useIdeaSubmissionActions} from 'hooks/useIdeaSubmissionActions';
import {PageLayoutContainer} from 'containers/PageLayoutContainer';
import {CampaignSliderContainer} from 'containers/CampaignSliderContainer';
import {CommunitySidebarContainer} from 'containers/sidebar/CommunitySidebarContainer';
import {IdeaSubmissionPreviewContainer} from 'containers/IdeaSubmissionPreviewContainer';
import {IdeaSubmissionEntry} from 'components/IdeaSubmissionEntry';
import {TagsList} from 'components/TagsList';
import {PAGE_IDS, SHOW_LOGIN_PROMPT} from 'constants/AppConstants';
import {TagData} from 'models/TagData';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {PageParameters} from 'models/types/PageParameters';

export const TagsPage = () => {
    const {authentication, lastSubmittedIdea, communityConfig} = useAppContext();
    const {actor} = authentication;
    const {onSubmitIdeaButtonClick} = useIdeaSubmissionActions();
    const communityService = useCommunityService();
    const localizer = useLocalizer();

    const fetchTags = useCallback(async (pageParameters: PageParameters, campaignId?: number, stageId?: number): Promise<PagedResponseContent<TagData>> => {
        if (communityService !== null) {
            return await communityService.fetchTags(pageParameters, campaignId, stageId);
        }
        return PagedResponseContent.nullObject();
    }, [communityService]);

    const fetchModeratorTags = useCallback(async (pageParameters: PageParameters, campaignId?: number, stageId?: number): Promise<PagedResponseContent<TagData>> => {
        if (communityService !== null) {
            return await communityService.fetchModeratorTags(pageParameters, campaignId, stageId);
        }
        return PagedResponseContent.nullObject();
    }, [communityService]);

    return (
        <Fragment>
            <BodyTagAttributes data-test-element-id={PAGE_IDS.COMMUNITY_TAGS_PAGE}/>
            <PageTitle
                title={`${communityConfig.name} - by IdeaScale | ${localizer.msg('search.input-label.tags')}`}/>
            <PageLayoutContainer
                mainContent={
                    <div>
                        <h1 className="sr-only">{localizer.msg('sidebar.tags.tags')}</h1>
                        <CampaignSliderContainer hidden={lastSubmittedIdea !== null}/>
                        <IdeaSubmissionEntry
                            localizer={localizer}
                            actionMember={new Member(actor.id, actor.name, actor.username, actor.avatar, '')}
                            onClickAvatar={() => authentication.actor.isAnonymous() ? eventDispatcher.dispatch(SHOW_LOGIN_PROMPT) : emptyFunction}
                            onClick={onSubmitIdeaButtonClick}
                        />
                        <div className="spacer mb-4"/>
                        <IdeaSubmissionPreviewContainer displayAsModal={false}/>
                        <div className="card panel tags-list mt-2">
                            <div className="panel-body card-body p-4">
                                <TagsList fetchTags={fetchTags} fetchModeratorTags={fetchModeratorTags}
                                          title={localizer.msg('sidebar.tags.community-tags')}/>
                            </div>
                        </div>
                        <div className="spacer mb-5"/>
                    </div>
                }
                sidebar={<section className="idea-sidebar" aria-label={localizer.msg('sidebar.community-sidebar')}>
                    <CommunitySidebarContainer/>
                </section>}
            />
        </Fragment>
    );
};