import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {isEmpty} from 'lodash';
import {ErrorReason, isHtmlInject, isJavascriptInject, useApiErrorResponseHandler} from '@ideascale/commons';
import {ActionButton, Button} from '@ideascale/ui';
import {FileLibrary} from './advanced-settings/FileLibrary';
import {FileUpload} from './advanced-settings/FileUpload';
import {TabItem} from '../share/TabItem';
import {TabPanel} from '../share/TabPanel';
import {BannerImagePreview} from './advanced-settings/BannerImagePreview';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useEditModeFileOperationService} from 'hooks/useEditModeFileOperationService';
import {useRouteUtils} from 'hooks/useRouteUtils';
import {useTabPermission} from 'hooks/useTabPermission';
import {LicenseUpgradeInfo} from 'components/edit-mode/LicenseUpgradeInfo';
import {DEFAULT_STALE_TIME, IMAGE_TYPE_WITHOUT_GIF_REGEX, QUERY_KEYS} from 'constants/AppConstants';
import {UpdateCampaignBanner} from 'commands/edit-mode/UpdateCampaignBanner';
import {useEditModeFormErrorHandler} from 'hooks/useEditModeFormErrorHandler';
import {ErrorMessage} from './advanced-settings/ErrorMessage';
import {RequiredMsgInfo} from './advanced-settings/RequiredMsgInfo';
import {BannerField, BannerFieldBuilder} from 'models/edit-mode/BannerField';
import {ImageType} from 'models/enums/ImageType';
import {CampaignEditableFieldType} from 'models/edit-mode/CampaignEditableFieldType';
import {ImageFile} from 'models/edit-mode/ImageFile';
import {UploadFileRequest} from 'models/edit-mode/UploadFileRequest';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';
import {ImageUploadSource} from 'models/enums/ImageUploadSource';

export type CampaignBannerEditProps = {
    maxFileSizeLimit: number;
    toggle: () => void;
}

export const CampaignBannerEdit = ({maxFileSizeLimit, toggle}: CampaignBannerEditProps) => {
    const localizer = useLocalizer();
    const {homeConfig, campaignHomeEditor, commandExecutor, validationErrors} = useEditModeContext();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const {
        fetchCampaignBannerImages,
        fetchCampaignLibraryImages,
        campaignUploadLibraryFiles
    } = useEditModeFileOperationService();
    const [selectedGalleryImage, setSelectedGalleryImage] = useState<ImageFile | null>(null);
    const [selectedLibraryImage, setSelectedLibraryImage] = useState<ImageFile | null>(null);
    const [bannerImage, setBannerImage] = useState<BannerField>(BannerField.EMPTY);
    const [checkedBannerGradient, setCheckedBannerGradient] = useState<boolean>(false);
    const [checkedBlackPageTitle, setCheckedBlackPageTitle] = useState<boolean>(false);
    const [uploadedImageType, setUploadedImageType] = useState<ImageUploadSource | undefined>(undefined);
    const [defaultActiveTab, setDefaultActiveTab] = useState<number>(1);
    const {campaignRouteMatch} = useRouteUtils();
    const {tabPermission} = useTabPermission(bannerImage);
    const [fileValidationErrors, setFileValidationErrors] = useState<Record<string, string>>({});
    const [fileTypeErrorMessage, setFileTypeErrorMessage] = useState<string | null>(null);
    const [fileUploading, setFileUploadingStatus] = useState(false);
    const {fieldError} = useEditModeFormErrorHandler({
        localizer,
        validationErrors: validationErrors.findError(CampaignOperationType.CHANGE_CAMPAIGN_BANNER)
    });

    const {data: featuredImages = []} = useQuery([QUERY_KEYS.CAMPAIGN_BANNER_GALLERY_IMAGES], () => fetchCampaignBannerImages(), {
        staleTime: DEFAULT_STALE_TIME,
        cacheTime: 0,
        onError: (error: any) => {
            handleErrorResponse(error);
        }
    });

    const {data: libraryImages = []} = useQuery([QUERY_KEYS.CAMPAIGN_LIBRARY_IMAGES], () => fetchCampaignLibraryImages(), {
        staleTime: DEFAULT_STALE_TIME,
        cacheTime: 0,
        onError: (error: any) => {
            handleErrorResponse(error);
        }
    });

    const validateFileType = useCallback((url: string) => {
        setFileTypeErrorMessage(null);
        if (/\.(gif)$/.test(url)) {
            setFileTypeErrorMessage(localizer.msg(`frontend-shared.errors.${ErrorReason.ANIMATED_GIF_NOT_SUPPORTED.toLowerCase()}`));
            return false;
        }
        return true;
    }, [localizer]);

    const updateSelectedImage = useCallback((image: ImageFile, imageType: ImageType) => {
        validateFileType(image.url);

        if (imageType === ImageType.STANDARD) {
            setSelectedGalleryImage(image);
            setSelectedLibraryImage(null);
        } else {
            setSelectedGalleryImage(null);
            setSelectedLibraryImage(image);
        }

        const newImage = new BannerFieldBuilder()
            .name(CampaignEditableFieldType.CAMPAIGN_BANNER_IMAGE)
            .fileId(image.fileId)
            .url(image.url)
            .imageType(imageType)
            .gradientEnabled(checkedBannerGradient)
            .blackPageTitleEnabled(checkedBlackPageTitle)
            .build();
        setBannerImage(prev => ({
            ...newImage,
            standardFilePermission: prev.standardFilePermission,
            fileLibraryPermission: prev.fileLibraryPermission,
            fileUploadPermission: prev.fileUploadPermission
        }));
        setUploadedImageType(undefined);
    }, [checkedBannerGradient, checkedBlackPageTitle, validateFileType]);

    const onGalleryImageSelected = useCallback((image: ImageFile) => {
        updateSelectedImage(image, ImageType.STANDARD);
    }, [updateSelectedImage]);

    const onLibraryImageSelected = useCallback((image: ImageFile) => {
        updateSelectedImage(image, ImageType.CUSTOM);
    }, [updateSelectedImage]);

    const updateCampaignBanner = useCallback(() => {
        const campaignId = (campaignRouteMatch?.params as any)?.campaignId;
        if (bannerImage !== homeConfig.getBannerField(CampaignEditableFieldType.CAMPAIGN_BANNER_IMAGE) && campaignId) {
            const command = new UpdateCampaignBanner(campaignHomeEditor, bannerImage, campaignId);
            commandExecutor.execute(command);
            validationErrors.clearError(command.getType());
        }
    }, [campaignRouteMatch?.params, bannerImage, campaignHomeEditor, commandExecutor, homeConfig, validationErrors]);

    const onClick = useCallback(async () => {
        setFileValidationErrors({});
        try {
            if (validateFileType(bannerImage.url)) {
                if (bannerImage.fileId <= 0 && bannerImage.filename?.length) {
                    const uploadFileRequest = new UploadFileRequest([]);
                    uploadFileRequest.files.push({filename: bannerImage.filename, altText: bannerImage.altText ?? ''});

                    const response = await campaignUploadLibraryFiles(uploadFileRequest);
                    if (response) {
                        const uploadedFile = response.find(uploadedResponse => uploadedResponse.filename === bannerImage.filename)?.file
                            || {id: 0, url: ''};
                        bannerImage.fileId = uploadedFile.id;
                        bannerImage.url = uploadedFile.url;
                        bannerImage.imageType = ImageType.CUSTOM;
                    }
                    updateCampaignBanner();
                }
                toggle();
            }
        } catch (e: any) {
            if (e?.data?.validationErrors) {
                setFileValidationErrors(e?.data?.validationErrors);
            } else {
                handleErrorResponse(e);
            }
        }
        updateCampaignBanner();
    }, [validateFileType, bannerImage, updateCampaignBanner, toggle, campaignUploadLibraryFiles, handleErrorResponse]);

    const campaignBannerImageTabItems = useMemo(() => {
        return [
            {
                value: 1,
                icon: 'image-layered',
                text: localizer.msg('edit-mode.image-setting.tabs.banner-gallery'),
                disabled: tabPermission.standardFileDisabled,
                content: (
                    <Fragment>
                        {
                            !tabPermission.standardFileDisabled
                                ? (
                                    <FileLibrary
                                        localizer={localizer}
                                        images={featuredImages}
                                        previewMode="full"
                                        selectedImage={selectedGalleryImage}
                                        onSelectedImage={onGalleryImageSelected}
                                        ariaLabel={localizer.msg('edit-mode.image-setting.tabs.banner-gallery')}
                                    />
                                )
                                : null
                        }
                    </Fragment>
                ),
                additionalTabInfo: (
                    <LicenseUpgradeInfo
                        className="edit-mode-element"
                        triggerButtonId="campaign-banner-license-popover-trigger-1"
                        showIconOnly={true}
                        localizer={localizer}
                    />
                )
            },
            {
                value: 2,
                icon: 'plus-solid-box-rounded-layered',
                text: localizer.msg('edit-mode.image-setting.tabs.file-library'),
                disabled: tabPermission.libraryDisabled,
                content: (
                    <Fragment>
                        {
                            !tabPermission.libraryDisabled
                                ? (
                                    <FileLibrary localizer={localizer}
                                                 images={libraryImages}
                                                 previewMode="xs-small"
                                                 selectedImage={selectedLibraryImage}
                                                 onSelectedImage={onLibraryImageSelected}
                                                 ariaLabel={localizer.msg('edit-mode.image-setting.tabs.file-library')}
                                    />
                                )
                                : null
                        }
                    </Fragment>
                ),
                additionalTabInfo: (
                    <LicenseUpgradeInfo
                        className="edit-mode-element"
                        triggerButtonId="campaign-banner-license-popover-trigger-2"
                        showIconOnly={true}
                        localizer={localizer}
                    />
                )
            },
            {
                value: 3,
                icon: 'arrow-up-from-line',
                text: localizer.msg('edit-mode.image-setting.tabs.upload'),
                disabled: tabPermission.uploadDisabled,
                content: (
                    <Fragment>
                        {
                            !tabPermission.uploadDisabled
                                ? (
                                    <FileUpload
                                        previewMode="full"
                                        localizer={localizer}
                                        maxFileSizeLimit={maxFileSizeLimit}
                                        fileValidation={IMAGE_TYPE_WITHOUT_GIF_REGEX}
                                        cropWidth={1130}
                                        cropHeight={150}
                                        uploadedType={uploadedImageType}
                                        errorReason={fileValidationErrors[`files[0].altText`]}
                                        onUploadedSuccessfulImage={(image, type) => {
                                            setFileTypeErrorMessage(null);
                                            const newImage = new BannerFieldBuilder()
                                                .name(CampaignEditableFieldType.CAMPAIGN_BANNER_IMAGE)
                                                .fileId(image.fileId)
                                                .filename(image.filename)
                                                .url(image.url)
                                                .gradientEnabled(checkedBannerGradient)
                                                .blackPageTitleEnabled(checkedBlackPageTitle)
                                                .imageType(ImageType.UPLOADED)
                                                .altText(image.altText || '')
                                                .build();

                                            setBannerImage(prev => ({
                                                ...newImage,
                                                standardFilePermission: prev.standardFilePermission,
                                                fileLibraryPermission: prev.fileLibraryPermission,
                                                fileUploadPermission: prev.fileUploadPermission
                                            }));
                                            setUploadedImageType(type);
                                            setSelectedGalleryImage(null);
                                            setSelectedLibraryImage(null);
                                        }}
                                        updateFileUploadingStatus={setFileUploadingStatus}
                                    />
                                )
                                : null
                        }
                    </Fragment>
                ),
                additionalTabInfo: (
                    <LicenseUpgradeInfo
                        className="edit-mode-element"
                        triggerButtonId="campaign-banner-license-popover-trigger-3"
                        showIconOnly={true}
                        localizer={localizer}
                    />
                )
            }
        ] as TabItem[];
    }, [checkedBannerGradient, checkedBlackPageTitle, featuredImages, fileValidationErrors, libraryImages, localizer, maxFileSizeLimit, onGalleryImageSelected, onLibraryImageSelected, selectedGalleryImage, selectedLibraryImage, tabPermission.libraryDisabled, tabPermission.standardFileDisabled, tabPermission.uploadDisabled, uploadedImageType]);

    useEffect(() => {
        const defaultBannerImage = homeConfig.getBannerField(CampaignEditableFieldType.CAMPAIGN_BANNER_IMAGE);

        if (defaultBannerImage?.imageType === ImageType.STANDARD) {
            setSelectedGalleryImage(defaultBannerImage);
            setDefaultActiveTab(1);
        } else if (defaultBannerImage?.imageType === ImageType.CUSTOM) {
            setSelectedLibraryImage(defaultBannerImage);
            setDefaultActiveTab(2);
        }

        setCheckedBannerGradient(defaultBannerImage?.gradientEnabled);
        setCheckedBlackPageTitle(defaultBannerImage?.blackPageTitleEnabled);
        setBannerImage(defaultBannerImage);
    }, [homeConfig]);

    const isFormDirty = () => {
        return bannerImage !== homeConfig.getBannerField(CampaignEditableFieldType.CAMPAIGN_BANNER_IMAGE);
    };

    const hasAllTabsDisabled = useMemo(() => {
        return tabPermission.standardFileDisabled && tabPermission.libraryDisabled && tabPermission.uploadDisabled;
    }, [tabPermission.libraryDisabled, tabPermission.standardFileDisabled, tabPermission.uploadDisabled]);

    const hasErrorInAltText = () => {
        const hasInjectedAltText = bannerImage.altText ? (isHtmlInject(bannerImage.altText) || isJavascriptInject(bannerImage.altText)) : false;
        const hasFileValidationErrors = Object.keys(fileValidationErrors).length > 0;
        return hasInjectedAltText || hasFileValidationErrors;
    };

    return (
        <div>
            <div className="d-flex align-items-center">
                <h5 className={`my-3 fw-semibold ${validationErrors.findError(CampaignOperationType.CHANGE_CAMPAIGN_BANNER) || !!fileTypeErrorMessage ? 'has-error' : ''}`}>{localizer.msg('edit-mode.campaign.banner-image.title')}</h5>
                {
                    hasAllTabsDisabled && (
                        <LicenseUpgradeInfo
                            triggerButtonId="campaign-banner-title-license-popover"
                            className="ms-2"
                            localizer={localizer}
                        />
                    )
                }
            </div>
            <ErrorMessage message={fieldError('fileId')?.message || fileTypeErrorMessage!}/>
            {
                !hasAllTabsDisabled && (
                    <Fragment>
                        <TabPanel defaultActiveTab={defaultActiveTab} tabItems={campaignBannerImageTabItems}/>
                        <BannerImagePreview
                            localizer={localizer}
                            bannerImage={bannerImage}
                            setBannerImage={setBannerImage}
                            checkedBannerGradient={checkedBannerGradient}
                            setCheckedBannerGradient={setCheckedBannerGradient}
                            checkedBlackPageTitle={checkedBlackPageTitle}
                            setCheckedBlackPageTitle={setCheckedBlackPageTitle}
                        />
                        {
                            ((!bannerImage || bannerImage.fileId === 0) && isEmpty(bannerImage?.url)) &&
                            <RequiredMsgInfo localizer={localizer}/>
                        }
                    </Fragment>
                )
            }

            <div className="action-bar mt-4 mb-3 p-0 text-end">
                <Button className="btn btn-cancel" color="cancel" aria-label="Close modal" onClick={toggle}
                        data-test-element-id="cancel">
                    {localizer.msg('common.actions.cancel')}
                </Button>
                <ActionButton
                    onClick={onClick}
                    className="btn btn-primary btn-lg"
                    type="button"
                    disabled={!isFormDirty() || isEmpty(bannerImage.url) || hasErrorInAltText() || !!fileTypeErrorMessage}
                    loading={fileUploading}
                    data-test-element-id="update"
                >
                    {localizer.msg('common.update')}
                </ActionButton>
            </div>
        </div>
    );
};