import React, {Dispatch, Fragment, SetStateAction, useCallback, useEffect, useMemo, useState} from 'react';
import {ErrorReason, isKeyExists, Localizer} from '@ideascale/commons';
import {useTabPermission} from 'hooks/useTabPermission';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useEditModeFormErrorHandler} from 'hooks/useEditModeFormErrorHandler';
import {FileLibrary} from './FileLibrary';
import {FileUpload} from './FileUpload';
import {TabItem} from '../../share/TabItem';
import {TabPanel} from '../../share/TabPanel';
import {InfoMessage} from './InfoMessage';
import {ErrorMessage} from './ErrorMessage';
import {LicenseUpgradeInfo} from 'components/edit-mode/LicenseUpgradeInfo';
import {ImageField, ImageFieldBuilder} from 'models/edit-mode/ImageField';
import {CommunityEditableFieldType} from 'models/edit-mode/CommunityEditableFieldType';
import {ImageType} from 'models/enums/ImageType';
import {ImageFile} from 'models/edit-mode/ImageFile';
import {CommunityOperationType} from 'models/edit-mode/CommunityOperationType';
import {ImageUploadSource} from 'models/enums/ImageUploadSource';

export type CustomFaviconProps = {
    localizer: Localizer;
    maxFileSizeLimit: number;
    libraryImages: ImageFile[];
    customFavicon: ImageField,
    setCustomFavicon: Dispatch<SetStateAction<ImageField>>;
    errorReason: string;
    updateFileUploadingStatus?: Dispatch<SetStateAction<Map<string, boolean>>>;
}

const FAVICON_IMAGE_RATIO = {w: 310, h: 310};
export const CustomFavicon = (props: CustomFaviconProps) => {
    const {localizer, maxFileSizeLimit, customFavicon, setCustomFavicon, libraryImages, errorReason, updateFileUploadingStatus} = props;
    const [uploadedImageType, setUploadedImageType] = useState<ImageUploadSource | undefined>(undefined);
    const {tabPermission} = useTabPermission(customFavicon);
    const {validationErrors} = useEditModeContext();
    const [fileUploading, setFileUploadingStatus] = useState(false);
    const {fieldError} = useEditModeFormErrorHandler({
        localizer,
        validationErrors: validationErrors.findError(CommunityOperationType.CHANGE_CUSTOM_FAVICON)
    });

    const onCustomFaviconSelected = useCallback((image: ImageFile) => {
        const newImage = new ImageFieldBuilder()
            .name(CommunityEditableFieldType.CUSTOM_FAVICON)
            .fileId(image.fileId)
            .url(image.url)
            .imageType(ImageType.CUSTOM)
            .build();
        setCustomFavicon(prev => ({
            ...newImage,
            fileLibraryPermission: prev.fileLibraryPermission,
            fileUploadPermission: prev.fileUploadPermission
        }));
        setUploadedImageType(undefined);
    }, [setCustomFavicon]);

    const customFaviconTabItems = useMemo(() => {
        return [
            {
                value: 1,
                icon: 'plus-solid-box-rounded-layered',
                text: localizer.msg('edit-mode.image-setting.tabs.file-library'),
                disabled: tabPermission.libraryDisabled,
                content: (
                    <Fragment>
                        {
                            !tabPermission.libraryDisabled
                                ? (
                                    <FileLibrary localizer={localizer}
                                                 images={libraryImages}
                                                 selectedImage={customFavicon}
                                                 onSelectedImage={onCustomFaviconSelected}
                                                 disabled={tabPermission.libraryDisabled}
                                                 ariaLabel={localizer.msg('edit-mode.image-setting.tabs.file-library')}
                                    />
                                )
                                : null
                        }
                    </Fragment>
                ),
                additionalTabInfo: (
                    <LicenseUpgradeInfo className="edit-mode-element"
                                        triggerButtonId="custom-favicon-license-popover-trigger-1"
                                        showIconOnly={true}
                                        localizer={localizer}
                    />
                )
            },
            {
                value: 2,
                icon: 'arrow-up-from-line',
                text: localizer.msg('edit-mode.image-setting.tabs.upload'),
                disabled: tabPermission.uploadDisabled,
                content: (
                    <Fragment>
                        {
                            !tabPermission.uploadDisabled
                                ? (
                                    <FileUpload
                                        localizer={localizer}
                                        maxFileSizeLimit={maxFileSizeLimit}
                                        uploadedType={uploadedImageType}
                                        cropWidth={FAVICON_IMAGE_RATIO.w}
                                        cropHeight={FAVICON_IMAGE_RATIO.h}
                                        errorReason={errorReason}
                                        onUploadedSuccessfulImage={(image, type) => {
                                            const newImage = new ImageFieldBuilder()
                                                .name(CommunityEditableFieldType.CUSTOM_FAVICON)
                                                .fileId(image.fileId)
                                                .filename(image.filename)
                                                .url(image.url)
                                                .altText(image.altText ?? '')
                                                .imageType(ImageType.UPLOADED)
                                                .build();

                                            setCustomFavicon(prev => ({
                                                ...newImage,
                                                fileLibraryPermission: prev.fileLibraryPermission,
                                                fileUploadPermission: prev.fileUploadPermission
                                            }));
                                            setUploadedImageType(type);
                                        }}
                                        updateFileUploadingStatus={setFileUploadingStatus}
                                    />
                                )
                                : null
                        }
                    </Fragment>
                ),
                additionalTabInfo: (
                    <LicenseUpgradeInfo
                        className="edit-mode-element"
                        triggerButtonId="custom-favicon-license-popover-trigger-2"
                        showIconOnly={true}
                        localizer={localizer}
                    />
                )
            }
        ] as TabItem[];
    }, [customFavicon, errorReason, libraryImages, localizer, maxFileSizeLimit, onCustomFaviconSelected, setCustomFavicon, tabPermission.libraryDisabled, tabPermission.uploadDisabled, uploadedImageType]);

    const hasAllTabsDisabled = useMemo(() => {
        return tabPermission.libraryDisabled && tabPermission.uploadDisabled;
    }, [tabPermission.libraryDisabled, tabPermission.uploadDisabled]);

    useEffect(() => {
        updateFileUploadingStatus?.(prev => new Map(prev.set(CommunityOperationType.CHANGE_CUSTOM_FAVICON, fileUploading)));
    }, [fileUploading, updateFileUploadingStatus]);

    return (
        <Fragment>
            <div className="d-flex align-items-center">
                <h5 className={`my-3 fw-semibold ${validationErrors.findError(CommunityOperationType.CHANGE_CUSTOM_FAVICON) ? 'has-error' : ''}`}>{localizer.msg('edit-mode.advanced-settings.custom-favicon-title')}</h5>
                {
                    hasAllTabsDisabled && (
                        <LicenseUpgradeInfo
                            triggerButtonId="custom-favicon-title-license-popover"
                            className="ms-2"
                            localizer={localizer}
                        />
                    )
                }
            </div>
            <ErrorMessage message={fieldError('fileId')?.message}/>
            {
                (fieldError('fileId')?.reason !== ErrorReason.FEATURE_IS_NOT_ACCESSIBLE && !hasAllTabsDisabled)
                    ? (
                        <Fragment>
                            <TabPanel tabItems={customFaviconTabItems}/>
                            {(!isKeyExists(customFavicon, 'fileId') || customFavicon.url?.length === 0) &&
                                <InfoMessage localizer={localizer}/>}
                        </Fragment>

                    )
                    : <div className="mt-4"></div>
            }
        </Fragment>
    );
};