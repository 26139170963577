import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {OptionType} from '@ideascale/ui';
import {Campaign, CampaignsHolder, LabelData, Localizer, PagedResponseContent} from '@ideascale/commons';
import {CustomFieldOption, SearchState, useSearchContext} from 'contexts/SearchContext';
import {useAppContext} from 'contexts/AppContext';
import {ROUTES} from 'shared/Routes';
import {TimeFrameSearchField} from './TimeFrameSearchField';
import {LabelSearchField} from './LabelSearchField';
import {StageSearchField} from './StageSearchField';
import {TagSearchField} from './TagSearchField';
import {ModeratorTagSearchField} from './ModeratorTagSearchField';
import {ArchiveCampaignSearchField} from './ArchiveCampaignSearchField';
import {CampaignSearchField} from './CampaignSearchField';
import {FilterActionButtons} from './FilterActionButtons';
import {CustomSearchField} from './CustomSearchField';
import {MemberProfileSearchField} from './MemberProfileSearchField';
import {DependantCustomSearchField} from './DependantCustomSearchField';
import {CustomFieldData} from 'models/CustomFieldData';
import {PageParameters} from 'models/types/PageParameters';
import {StagesHolder} from 'models/StagesHolder';
import {StageGroup} from 'models/StageGroup';
import {SearchCategory} from 'models/enums/SearchCategory';

type IdeaSearchFilterProps = {
    localizer: Localizer;
    fetchLabels(pageParameters: PageParameters): Promise<PagedResponseContent<LabelData>>;
    fetchStages(pageParameters: PageParameters): Promise<PagedResponseContent<StagesHolder<StageGroup>>>;
    fetchTags(pageParameters: PageParameters): Promise<PagedResponseContent<string>>;
    fetchModeratorTags(pageParameters: PageParameters): Promise<PagedResponseContent<string>>;
    fetchCampaigns(pageParameters: PageParameters): Promise<PagedResponseContent<CampaignsHolder<Campaign>>>;
    fetchDependantCustomFields(fieldId: number): Promise<CustomFieldData>;
    fetchIdeasCustomFields(pageParameters: PageParameters, campaignIds?: number, includeArchivedCampaigns?: boolean): Promise<PagedResponseContent<StageGroup>>;
    fetchAdvanceSearchProfileFields(pageParameters: PageParameters): Promise<PagedResponseContent<StageGroup>>;
    fetchDependantProfileFields(fieldId: number): Promise<CustomFieldData>;
    onClearFilters: () => void;
    onApplyFilters: (params: Partial<SearchState>) => void;
}

export const IdeaSearchFilter = (props: IdeaSearchFilterProps) => {
    const {
        localizer, fetchLabels, fetchStages, fetchTags, fetchAdvanceSearchProfileFields,
        fetchDependantProfileFields, fetchModeratorTags, fetchCampaigns, fetchIdeasCustomFields,
        fetchDependantCustomFields, onClearFilters, onApplyFilters
    } = props;
    const {
        fromDate,
        toDate,
        archiveCampaign: includeArchiveCampaign,
        labels,
        campaigns,
        tags,
        moderatorTags,
        stages,
        ideaCustomFields: ideaCustomFieldsDefaultValue,
        memberCustomFields: memberCustomFieldsDefaultValue,
        setSearchCategory
    } = useSearchContext();
    const {authentication, communityConfig} = useAppContext();
    const navigate = useNavigate();
    const [dates, setDates] = useState<Date[]>([]);
    const [archiveCampaign, setIncludeArchivedCampaigns] = useState(true);
    const [selectedLabels, setSelectedLabels] = useState<OptionType[]>([]);
    const [selectedIdeaCustomFields, setSelectedIdeaCustomFields] = useState<CustomFieldOption[]>([]);
    const [selectedMemberCustomFields, setSelectedMemberCustomFields] = useState<CustomFieldOption[]>([]);
    const [selectedCampaigns, setSelectedCampaigns] = useState<OptionType[]>([]);
    const [selectedTags, setSelectedTags] = useState<OptionType[]>([]);
    const [selectedModeratorTags, setSelectedModeratorTags] = useState<OptionType[]>([]);
    const [selectedStages, setSelectedStages] = useState<OptionType[]>([]);

    const onChangeCustomField = (options: OptionType[] | null, mode: 'IDEA' | 'MEMBER') => {
        if (options && options.length > 0) {
            const newValue: CustomFieldOption[] = [];
            options.forEach(option => {
                const found = (mode === 'IDEA' ? selectedIdeaCustomFields : selectedMemberCustomFields)
                    .find(field => field.field.value === option.value);
                if (!found) {
                    newValue.push({field: option, values: []});
                } else {
                    newValue.push(found);
                }
            });
            mode === 'IDEA'
                ? setSelectedIdeaCustomFields(newValue)
                : setSelectedMemberCustomFields(newValue);
        } else {
            mode === 'IDEA'
                ? setSelectedIdeaCustomFields([])
                : setSelectedMemberCustomFields([]);
        }
    };

    const onChangeDependantField = (fieldId: number, options: OptionType[] | null, mode: 'IDEA' | 'MEMBER') => {
        const ideaField = (mode === 'IDEA' ? selectedIdeaCustomFields : selectedMemberCustomFields).find(field => field.field.value === fieldId);
        if (ideaField) {
            const newIdeaFields = [...(mode === 'IDEA' ? selectedIdeaCustomFields : selectedMemberCustomFields)];
            const index = (mode === 'IDEA' ? selectedIdeaCustomFields : selectedMemberCustomFields).findIndex(iField => iField.field.value === fieldId);
            if (options && options.length > 0) {
                newIdeaFields[index] = {...ideaField, values: options};
            } else {
                newIdeaFields[index] = {...ideaField, values: []};
            }
            mode === 'IDEA'
                ? setSelectedIdeaCustomFields(newIdeaFields)
                : setSelectedMemberCustomFields(newIdeaFields);
        }
    };

    const updateSelectedValue = (values: OptionType[] | null, stateAction: Dispatch<SetStateAction<any>>) => {
        if (values && values.length > 0) {
            stateAction(values);
        } else {
            stateAction([]);
        }
    };

    const onApplySearchFilter = useCallback(() => {
        let fromDate = null;
        let toDate = null;
        if (dates.length === 2) {
            fromDate = dates[0];
            toDate = dates[1];
        }
        onApplyFilters({
            fromDate,
            toDate,
            stages: selectedStages,
            labels: selectedLabels,
            campaigns: selectedCampaigns,
            tags: selectedTags,
            moderatorTags: selectedModeratorTags,
            ideaCustomFields: selectedIdeaCustomFields,
            memberCustomFields: selectedMemberCustomFields,
            archiveCampaign
        });

    }, [archiveCampaign, dates, onApplyFilters, selectedCampaigns, selectedIdeaCustomFields, selectedLabels, selectedMemberCustomFields, selectedModeratorTags, selectedStages, selectedTags]);

    const clearLocalStateValues = useCallback(() => {
        setDates([]);
        setIncludeArchivedCampaigns(false);
        setSelectedLabels([]);
        setSelectedIdeaCustomFields([]);
        setSelectedMemberCustomFields([]);
        setSelectedCampaigns([]);
        setSelectedTags([]);
        setSelectedModeratorTags([]);
        setSelectedStages([]);
    }, []);

    const onClearAllFiltersClick = useCallback(() => {
        clearLocalStateValues();
        onClearFilters();
    }, [clearLocalStateValues, onClearFilters]);

    const onCancel = useCallback(() => {
        onClearAllFiltersClick();
        setSearchCategory(SearchCategory.ALL);
        navigate(ROUTES.HOME);
    }, [navigate, onClearAllFiltersClick, setSearchCategory]);

    useEffect(() => {
        setDates(fromDate && toDate ? [fromDate, toDate] : []);
        setIncludeArchivedCampaigns(includeArchiveCampaign);
        setSelectedLabels(labels);
        setSelectedIdeaCustomFields(ideaCustomFieldsDefaultValue);
        setSelectedMemberCustomFields(memberCustomFieldsDefaultValue);
        setSelectedCampaigns(campaigns);
        setSelectedTags(tags);
        setSelectedModeratorTags(moderatorTags);
        setSelectedStages(stages);
    }, [campaigns, fromDate, ideaCustomFieldsDefaultValue, includeArchiveCampaign, labels, memberCustomFieldsDefaultValue, moderatorTags, stages, tags, toDate]);

    return (
        <>
            <div className="row">
                <div className="col-12 col-lg-4">
                    <TimeFrameSearchField localizer={localizer} dates={dates} onChangeDate={setDates}/>
                </div>
                <div className="col-12 col-lg-4">
                    <CampaignSearchField fetchCampaigns={fetchCampaigns} defaultValue={selectedCampaigns}
                                         setCampaigns={(options) => updateSelectedValue(options, setSelectedCampaigns)}/>
                </div>
                <div className="col-12 col-lg-4">
                    <LabelSearchField
                        fetchLabels={fetchLabels}
                        defaultValue={selectedLabels}
                        setLabels={(options) => updateSelectedValue(options, setSelectedLabels)}
                    />
                </div>

            </div>
            <div className="row">
                <div className="col-12 col-lg-4">
                    <TagSearchField fetchTags={fetchTags} defaultValue={selectedTags}
                                    setTags={(options) => updateSelectedValue(options, setSelectedTags)}/>
                </div>
                {
                    communityConfig.moderatorTagEnabled && authentication.isModerator() && (
                        <div className="col-12 col-lg-4">
                            <ModeratorTagSearchField fetchModeratorTags={fetchModeratorTags}
                                                     defaultValue={selectedModeratorTags}
                                                     setModeratorTags={setSelectedModeratorTags}/>
                        </div>
                    )
                }
                <div className="col-12 col-lg-4">
                    <StageSearchField fetchStages={fetchStages} setStages={setSelectedStages}
                                      defaultValue={selectedStages}/>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-12 col-lg-4">
                    <CustomSearchField
                        fetchIdeasCustomFields={fetchIdeasCustomFields}
                        values={selectedIdeaCustomFields.map(field => field.field)}
                        onChange={onChangeCustomField}
                    />
                </div>

                {selectedIdeaCustomFields.map(item => {
                    return (
                        <div className="col-12 col-lg-4 mb-3" key={item.field.value}>
                            <DependantCustomSearchField
                                fieldId={item.field.value}
                                value={item.values}
                                mode="IDEA"
                                onChangeDependantField={onChangeDependantField}
                                fetchDependantCustomFields={fetchDependantCustomFields}
                            />
                        </div>
                    );
                })}

                <div className="col-12 col-lg-4">
                    <MemberProfileSearchField
                        fetchAdvanceSearchProfileFields={fetchAdvanceSearchProfileFields}
                        values={selectedMemberCustomFields.map(field => field.field)}
                        onChange={onChangeCustomField}
                    />
                </div>

                {selectedMemberCustomFields.map(item => {
                    return (
                        <div className="col-12 col-lg-4 mb-3" key={item.field.value}>
                            <DependantCustomSearchField
                                fieldId={item.field.value}
                                value={item.values}
                                mode="MEMBER"
                                onChangeDependantField={onChangeDependantField}
                                fetchDependantCustomFields={fetchDependantProfileFields}
                            />
                        </div>
                    );
                })}

                {
                    !authentication.actor.isCommunityMember() && (
                        <div className="col-12 col-lg-4 mt-5">
                            <ArchiveCampaignSearchField
                                localizer={localizer}
                                isChecked={archiveCampaign}
                                setIsChecked={setIncludeArchivedCampaigns}
                            />
                        </div>
                    )
                }
            </div>
            <FilterActionButtons
                localizer={localizer}
                onCancel={onCancel}
                onClear={onClearAllFiltersClick}
                onApply={onApplySearchFilter}
            />
        </>
    );
};