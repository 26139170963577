import React, {useEffect, useState} from 'react';
import {useLocalizer} from '../../../hooks/useLocalizer';
import {TimeUtil} from '../../../utils/TimeUtil';

type CountDownModuleProps = {
    date: string | undefined;
    info: string;
}

export const CountDownModule = ({date = new Date().toDateString(), info}: CountDownModuleProps) => {
    const localizer = useLocalizer();
    const [timeLeft, setTimeLeft] = useState(TimeUtil.calculateTimeLeft(date));

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(TimeUtil.calculateTimeLeft(date));
        }, 1000);

        return () => clearTimeout(timer);
    }, [date]);

    return (
        <div className="count-down-timer">
            <ul className="list-unstyled list-inline">
                <li className="list-inline-item text-center">
                    <div className="countdown-days">{timeLeft.days.toString().padStart(2, '0')}</div>
                    <div className="fw-bold">{localizer.msg('sidebar.count-down-timer.days')}</div>
                </li>
                <li className="list-inline-item text-center">
                    <div className="countdown-hours">{timeLeft.hours.toString().padStart(2, '0')}</div>
                    <div className="fw-bold">{localizer.msg('sidebar.count-down-timer.hours')}</div>
                </li>
                {
                    timeLeft.days.toString().length < 3 && (
                        <li className="list-inline-item text-center">
                            <div className="countdown-minutes">{timeLeft.minutes.toString().padStart(2, '0')}</div>
                            <div className="fw-bold">{localizer.msg('sidebar.count-down-timer.minutes')}</div>
                        </li>)
                }

            </ul>
            <div className="text-center fw-bold">{info}</div>
        </div>
    );
};
