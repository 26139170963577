import {Editor, EditorCallback} from './Editor';
import {HomeConfigHolder} from 'models/edit-mode/HomeConfigHolder';
import {SidebarItem} from 'models/sidebar/SidebarItem';
import {CampaignEditableFieldType} from 'models/edit-mode/CampaignEditableFieldType';
import {ConfigField} from 'models/edit-mode/ConfigField';
import {TosConfigField} from 'models/edit-mode/TosConfigField';
import {WidgetField} from 'models/edit-mode/WidgetField';
import {BannerField} from 'models/edit-mode/BannerField';
import {BriefConfigField} from 'models/edit-mode/BriefConfigField';
import {LogoField} from 'models/edit-mode/LogoField';
import {CampaignTeamVisibilityConfigField} from 'models/edit-mode/CampaignTeamVisibilityConfigField';

export class CampaignHomeEditor extends Editor {
    public static EMPTY = new CampaignHomeEditor(HomeConfigHolder.EMPTY, {applyChange: () => null});
    private readonly homeConfigHolder: HomeConfigHolder;
    public readonly campaignId: number;

    constructor(homeConfigHolder: HomeConfigHolder, callback: EditorCallback) {
        super(callback);
        this.homeConfigHolder = homeConfigHolder;
    }

    set sidebarItems(sidebarItems: SidebarItem[]) {
        this.homeConfigHolder.sidebarItems = sidebarItems;
        this.applyChange();
    }

    get sidebarItems() {
        return this.homeConfigHolder.sidebarItems;
    }

    get name() {
        return this.homeConfigHolder.getConfigField(CampaignEditableFieldType.CAMPAIGN_NAME).value;
    }

    set name(name: string) {
        this.homeConfigHolder.updateField(new ConfigField(CampaignEditableFieldType.CAMPAIGN_NAME, name));
        this.applyChange();
    }

    get subtitle() {
        return this.homeConfigHolder.getConfigField(CampaignEditableFieldType.CAMPAIGN_SUBTITLE).value;
    }

    set subtitle(subtitle: string) {
        this.homeConfigHolder.updateField(new ConfigField(CampaignEditableFieldType.CAMPAIGN_SUBTITLE, subtitle));
        this.applyChange();
    }

    get description() {
        return this.homeConfigHolder.getConfigField(CampaignEditableFieldType.CAMPAIGN_DESCRIPTION).value;
    }

    set description(description: string) {
        this.homeConfigHolder.updateField(new ConfigField(CampaignEditableFieldType.CAMPAIGN_DESCRIPTION, description));
        this.applyChange();
    }

    get brief() {
        return this.homeConfigHolder.getBriefConfigField(CampaignEditableFieldType.CAMPAIGN_BRIEF);
    }

    set brief(brief: BriefConfigField) {
        this.homeConfigHolder.updateField(brief);
        this.applyChange();
    }

    get tos() {
        return this.homeConfigHolder.getTosConfigField(CampaignEditableFieldType.CAMPAIGN_TOS);
    }

    set tos(tos: TosConfigField) {
        this.homeConfigHolder.updateField(tos);
        this.applyChange();
    }

    set campaignWidgetOne(campaignWidget: WidgetField) {
        this.homeConfigHolder.updateField(campaignWidget);
        this.sidebarItems = this.sidebarItems.map(item => {
            if (item.key === campaignWidget.key) {
                item.title = campaignWidget.title;
            }
            return item;
        });
        this.applyChange();
    }

    get campaignWidgetOne() {
        return this.homeConfigHolder.getWidgetField(CampaignEditableFieldType.CAMPAIGN_WIDGET_ONE);
    }

    set campaignWidgetTwo(campaignWidget: WidgetField) {
        this.homeConfigHolder.updateField(campaignWidget);
        this.sidebarItems = this.sidebarItems.map(item => {
            if (item.key === campaignWidget.key) {
                item.title = campaignWidget.title;
            }
            return item;
        });
        this.applyChange();
    }

    get campaignWidgetTwo() {
        return this.homeConfigHolder.getWidgetField(CampaignEditableFieldType.CAMPAIGN_WIDGET_TWO);
    }

    set campaignWidgetThree(campaignWidget: WidgetField) {
        this.homeConfigHolder.updateField(campaignWidget);
        this.sidebarItems = this.sidebarItems.map(item => {
            if (item.key === campaignWidget.key) {
                item.title = campaignWidget.title;
            }
            return item;
        });
        this.applyChange();
    }

    get campaignWidgetThree() {
        return this.homeConfigHolder.getWidgetField(CampaignEditableFieldType.CAMPAIGN_WIDGET_THREE);
    }

    get campaignBanner() {
        return this.homeConfigHolder.getBannerField(CampaignEditableFieldType.CAMPAIGN_BANNER_IMAGE);
    }

    set campaignBanner(campaignBanner: BannerField) {
        this.homeConfigHolder.updateField(campaignBanner);
        this.applyChange();
    }

    get campaignLogo() {
        return this.homeConfigHolder.getLogoField(CampaignEditableFieldType.CAMPAIGN_LOGO);
    }

    set campaignLogo(campaignLogo: LogoField) {
        this.homeConfigHolder.updateField(campaignLogo);
        this.applyChange();
    }

    get campaignFeaturedImage() {
        return this.homeConfigHolder.getBannerField(CampaignEditableFieldType.CAMPAIGN_FEATURE_IMAGE);
    }

    set campaignFeaturedImage(featuredImage: BannerField) {
        this.homeConfigHolder.updateField(featuredImage);
        this.applyChange();
    }

    get campaignModeratorTeamVisibility() {
        return this.homeConfigHolder.getTeamVisibilityField(CampaignEditableFieldType.CAMPAIGN_MODERATORS_TEAM);
    }

    set campaignModeratorTeamVisibility(field: CampaignTeamVisibilityConfigField) {
        this.homeConfigHolder.updateField(field);
        this.applyChange();
    }

    get globalModeratorTeamVisibility() {
        return this.homeConfigHolder.getTeamVisibilityField(CampaignEditableFieldType.GLOBAL_MODERATORS_TEAM);
    }

    set globalModeratorTeamVisibility(field: CampaignTeamVisibilityConfigField) {
        this.homeConfigHolder.updateField(field);
        this.applyChange();
    }

    protected applyChange() {
        this.onChangeCallbacks.applyChange({...this.homeConfigHolder});
    }
}