import React, {Fragment, useCallback, useMemo} from 'react';
import {useMutation} from 'react-query';
import {Link} from 'react-router-dom';
import {
    ClassificationAttachmentAttribute,
    ClassificationFieldAttribute,
    ClassificationTitle,
    EmailRequest,
    Localizer,
    Member,
    PrivateMessageRequestParams,
    UnfurledUrlList,
    useToggle
} from '@ideascale/commons';
import {Button, Icon} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useAppContext} from 'contexts/AppContext';
import {useIdeaService} from 'hooks/useIdeaService';
import {IdeaDetail} from 'models/IdeaDetail';
import {IdeaSubmissionRequest} from 'models/IdeaSubmissionRequest';
import {PageParameters} from 'models/types/PageParameters';
import {useSocialShareServices} from 'hooks/useSocialShareServices';
import {useIdeaShare} from 'hooks/useIdeaShare';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {appLinks} from 'services/AppLinks';
import {EditIdeaFormContainer} from 'containers/EditIdeaFormContainer';
import {IdeaCustomFields} from 'components/idea/IdeaCustomFields';
import {IdeaHeader} from 'components/idea/IdeaHeader';
import {IdeaContent} from 'components/idea/IdeaContent';
import {AssignedOwnerGroup} from 'components/AssignedOwnerGroup';
import {ShareModal} from 'components/share/ShareModal';
import {LinkedIdeas} from 'shared/LinkedIdeas';
import styles from './IdeaSubmissionPreview.module.scss';

const MEMBER_PAGE_SIZE = 30;

type IdeaSubmissionPreviewProps = {
    localizer: Localizer;
    submittedIdea: IdeaDetail;
    setLastSubmittedIdea: (idea: IdeaDetail) => void;
    onClosePreview: () => void;
    modalVersion?: boolean;
    fetchOwners: (pageParameters: PageParameters, ideaId: number) => Promise<PagedResponseContent<Member>>;
}

type EditIdeaActionParams = {
    ideaId: number;
    submissionRequest: IdeaSubmissionRequest;
}

export const IdeaSubmissionPreview = (props: IdeaSubmissionPreviewProps) => {
    const {localizer, submittedIdea, setLastSubmittedIdea, onClosePreview, modalVersion, fetchOwners} = props;
    const ideaId = submittedIdea.id;
    const [shareIdeaOpen, toggleShareIdeaModal] = useToggle(false);
    const [editIdeaOpen, toggleEditIdea] = useToggle(false);
    const {
        currentCampaign,
        communityConfig: {linkQualifiers, url, classificationEnabled, unfurlUrlEnabled},
        authentication: {actor},
    } = useAppContext();
    const shareableText = url + process.env.PUBLIC_URL + appLinks.ideaDetails(ideaId);
    const shareableLink = appLinks.ideaDetails(ideaId);
    const internalMessageAdditionalURL = `<a class= "d-block cursor-pointer" href=${url + process.env.PUBLIC_URL + appLinks.ideaDetails(ideaId)}>${classificationEnabled ? '<span class=\'idea-title\'> - Go to the Idea</span>' : submittedIdea.title}</a>`;
    const {getSocialIdeaShareURLs} = useSocialShareServices();
    const {
        ideaShareMessagesSend,
        getIdeaEmailRecipients,
        emailIdeaSendByRecipientEmails,
        emailIdeaSendByRecipientIds,
        getEmailIdeaContent
    } = useIdeaShare();
    const {unFurlUrl, modifyIdea} = useIdeaService();

    const editIdeaMutation = useMutation((params: EditIdeaActionParams) => modifyIdea(params.ideaId, params.submissionRequest),
        {
            onSuccess: (modifiedIdea) => {
                setLastSubmittedIdea({
                    ...submittedIdea, ...modifiedIdea,
                    ideaTagHolder: {...submittedIdea.ideaTagHolder, tags: modifiedIdea.tags}
                });
            }
        }
    );

    const editIdea = useCallback((ideaId: number, submissionRequest: IdeaSubmissionRequest) => {
        return editIdeaMutation.mutateAsync({ideaId, submissionRequest});
    }, [editIdeaMutation]);

    const unfurlingContent = useMemo(() => {
        let text = submittedIdea.description;
        submittedIdea.fieldSections.forEach(section => {
            section.ideaFieldValues.forEach(field => {
                text += ' ' + field.value;
            });
        });
        return text;
    }, [submittedIdea.description, submittedIdea.fieldSections]);


    return (
        <Fragment>
            {!modalVersion &&
                <Button color="cancel" className={`btn btn-cancel float-end opacity-50 ${styles.closeButton}`}
                        aria-label={localizer.msg('idea.actions.close-preview')}
                        onClick={onClosePreview}>
                    <Icon iconSpritePath={svgIconsPath} name="cross"/>
                </Button>
            }
            {classificationEnabled && submittedIdea.attributes &&
                <ClassificationTitle attributes={submittedIdea.attributes} extraClasses="mb-3" localizer={localizer}/>
            }
            <IdeaHeader
                ideaNumber={submittedIdea.ideaNumber}
                idPrefix={'preview-'}
                campaign={submittedIdea.campaign}
                ideaId={submittedIdea.id}
                ideaTitle={submittedIdea.title}
                ideaCreatedAt={submittedIdea.createdAt}
                linkableIdeaTitle={true}
                submitter={submittedIdea.submitter}
                coSubmitters={submittedIdea.coSubmitters}
                kudosCount={submittedIdea.kudoCount}
                kudoGiven={submittedIdea.kudoGiven}
                labels={submittedIdea.labels}
                showLabelsStackView={false}
                classification={submittedIdea.attributes as ClassificationFieldAttribute[]}
                onTitleClickCallback={onClosePreview}/>

            {
                submittedIdea.ownerCount > 0 &&
                <AssignedOwnerGroup ideaId={submittedIdea.id} ownerCount={submittedIdea.ownerCount}
                                    localizer={localizer}
                                    fetchOwners={fetchOwners} pageSize={MEMBER_PAGE_SIZE}/>
            }

            <IdeaContent localizer={localizer}
                         ideaId={submittedIdea.id}
                         customDescriptionLabel={submittedIdea?.customDescriptionLabel}
                         listPage={false}
                         description={submittedIdea.description}
                         attachments={submittedIdea.attachments}
                         classification={submittedIdea.attributes as ClassificationAttachmentAttribute[]}
            />

            <IdeaCustomFields fieldSections={submittedIdea.fieldSections}
                              classification={submittedIdea.attributes as ClassificationFieldAttribute[]}/>

            <div className="mt-2 mb-4">
                <LinkedIdeas localizer={localizer} linkQualifiers={linkQualifiers}
                             linkedIdeas={submittedIdea.linkedIdeas}
                             actorMemberId={actor.id}/>
            </div>

            {(submittedIdea.ideaTagHolder.editTagsAllowed || submittedIdea.ideaTagHolder.addTagsAllowed) &&
                <div>
                    <h3 className="sr-only">{localizer.msg('frontend-shared.tags.label')}</h3>
                    {
                        submittedIdea.ideaTagHolder.tags?.map(tag =>
                            <Link className="tag" key={tag} to={appLinks.tag(tag, currentCampaign?.id.toString())}>
                                <span className="tag-label">{tag}</span>
                            </Link>
                        )
                    }
                </div>
            }

            <UnfurledUrlList content={unfurlingContent} unfurlUrl={unFurlUrl} localizer={localizer}
                             enabled={unfurlUrlEnabled}/>
            {classificationEnabled && submittedIdea.attributes &&
                <ClassificationTitle attributes={submittedIdea.attributes} localizer={localizer}/>
            }

            <div className={`mt-4 float-end ${modalVersion ? 'pb-3' : ''}`}>
                <div className="float-end mt-sm-0">
                    <button className="btn btn-cancel" onClick={onClosePreview}>{localizer.msg('common.close')}</button>
                    {
                        submittedIdea.permissionHolder && submittedIdea.permissionHolder.editIdeaEnabled &&
                        <button className="btn btn-secondary mx-3" onClick={toggleEditIdea}>
                            {localizer.msg('common.actions.edit')}
                        </button>
                    }
                    {
                        submittedIdea.sharable && (
                            <button className="btn btn-primary" onClick={toggleShareIdeaModal}
                                    title={localizer.msg('idea.actions.share-title')}>
                                <Icon name="arrow-up-from-tray" iconSpritePath={svgIconsPath} fill="#fff" width={18}
                                      height={18}
                                      className="me-1 mt-n1"/>{localizer.msg('idea.actions.share-title')}
                            </button>
                        )
                    }

                    <ShareModal
                        shareableText={shareableText}
                        shareableLink={shareableLink}
                        socialShareURLs={getSocialIdeaShareURLs(submittedIdea.permissionHolder?.allowedMediaList, submittedIdea.title, ideaId)}
                        emailInvitationText={localizer.msg('idea.actions.email.invitation')}
                        internalShareButtonTitle={localizer.msg('idea.share.copy-title')}
                        emailShareModalTitle={localizer.msg('idea.actions.email-idea-title')}
                        open={shareIdeaOpen}
                        toggle={toggleShareIdeaModal}
                        shareContentId={submittedIdea.id}
                        title={localizer.msg('idea.share-idea')}
                        shareContentTitle={submittedIdea.title}
                        emailIdeaAllowed={submittedIdea.permissionHolder?.emailIdeaAllowed}
                        internalMessageAdditionalURL={internalMessageAdditionalURL}
                        onSendMessage={(requestParams: PrivateMessageRequestParams) => ideaShareMessagesSend(requestParams, ideaId)}
                        emailIdeaSendByRecipientIds={(requestParams: EmailRequest) => emailIdeaSendByRecipientIds(requestParams, ideaId)}
                        emailIdeaSendByRecipientEmails={(requestParams: EmailRequest) => emailIdeaSendByRecipientEmails(requestParams, ideaId)}
                        getEmailRecipients={(parameters: PageParameters) => getIdeaEmailRecipients(ideaId, parameters)}
                        getEmailContent={() => getEmailIdeaContent(ideaId)}
                        sharable={submittedIdea.sharable}
                        privateCampaign={submittedIdea.campaign?.privateCampaign || false}
                    />
                    {editIdeaOpen &&
                        <EditIdeaFormContainer ideaId={ideaId} open={editIdeaOpen} toggle={toggleEditIdea}
                                               editIdea={editIdea}/>
                    }
                </div>
            </div>
        </Fragment>
    );
};