import {useCallback} from 'react';
import {isNil} from 'lodash';
import {useAppContext} from 'contexts/AppContext';
import {useRouteUtils} from 'hooks/useRouteUtils';
import {AdminPermissionScope} from 'models/enums/AdminPermissionScope';

export const useEditModeAccessibilityHelper = () => {
    const {communityConfig} = useAppContext();
    const {
        landingPageRouteMatch,
        homeRouteMatch,
        campaignRouteMatch,
        landingPageActionRouteMatch
    } = useRouteUtils();

    const getCurrentEditableRoute = useCallback(() => {
        return homeRouteMatch || campaignRouteMatch || landingPageRouteMatch || landingPageActionRouteMatch;
    }, [campaignRouteMatch, homeRouteMatch, landingPageActionRouteMatch, landingPageRouteMatch]);

    const isCurrentPageEditable = useCallback(() => {
        const currentRoute = getCurrentEditableRoute();
        switch (currentRoute) {
            case null:
                return false;
            case homeRouteMatch:
                return communityConfig.hasEditPermission(AdminPermissionScope.COMMUNITY_HOME);
            case campaignRouteMatch:
                return communityConfig.hasEditPermission(AdminPermissionScope.CAMPAIGN_HOME);
            case landingPageRouteMatch:
            case landingPageActionRouteMatch:
                return communityConfig.hasEditPermission(AdminPermissionScope.LANDING_PAGE);
            default:
                return false;
        }

    }, [campaignRouteMatch, communityConfig, getCurrentEditableRoute, homeRouteMatch, landingPageActionRouteMatch, landingPageRouteMatch]);

    const isTranslationEditable = useCallback(() => {
        return !isNil(landingPageRouteMatch || landingPageActionRouteMatch) && communityConfig.hasTranslationPermission();
    }, [communityConfig, landingPageActionRouteMatch, landingPageRouteMatch]);

    const isEditModeAccessibleRoute = useCallback((): boolean => {
        return isCurrentPageEditable() || isTranslationEditable();
    }, [isCurrentPageEditable, isTranslationEditable]);

    return {
        isEditModeAccessibleRoute
    };
};