import React, {useCallback} from 'react';
import {Button, Modal} from '@ideascale/ui';
import {Localizer, useApiErrorResponseHandler} from '@ideascale/commons';
import {LandingPageVersion} from 'models/types/LandingPageVersion';

type VersionDeleteConfirmationModalProps = {
    localizer: Localizer;
    open: boolean;
    toggleModal: () => void;
    versionData: LandingPageVersion;
    onVersionDelete: (pageId: number, version: number) => Promise<void>;
    onVersionDeleteSuccessful: (newVersionData: LandingPageVersion) => void;
    landingPageId: number;
}

export const VersionDeleteConfirmationModal = (props: VersionDeleteConfirmationModalProps) => {
    const {
        onVersionDeleteSuccessful,
        versionData,
        toggleModal,
        open,
        localizer,
        onVersionDelete,
        landingPageId
    } = props;
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});

    const deleteVersion = useCallback(async () => {
        try {
            await onVersionDelete(landingPageId, versionData.version);
            onVersionDeleteSuccessful(versionData);
        } catch (error) {
            handleErrorResponse(error);
        }
    }, [handleErrorResponse, landingPageId, onVersionDelete, onVersionDeleteSuccessful, versionData]);

    return (
        <Modal isOpen={open} toggle={toggleModal} className="edit-version-modal"
               title={localizer.msg('landing-page.action-topbar.version-delete-modal-title', {versionTitle: versionData.title})}>
            <div>{localizer.msg('landing-page.action-topbar.version-delete-modal-description')}</div>
            <div className="mt-5 mb-3 text-end">
                <Button className="me-3" color="cancel" type="button" onClick={toggleModal}>
                    {localizer.msg('common.actions.cancel')}
                </Button>
                <Button color="danger" onClick={deleteVersion}>
                    {localizer.msg('landing-page.action-topbar.delete')}
                </Button>
            </div>
        </Modal>
    );
};
