import {FieldValues} from 'react-hook-form';
import {HtmlConverter, OptionType} from '@ideascale/ui';
import {
    AttachmentAttributeApiParameters,
    AttachmentAttributeParameters,
    ClassificationAttachmentAttributesData,
    ClassificationAttribute,
    ClassificationAttributesData,
    CustomField,
    FieldAttributeApiParameters,
    FieldAttributeParameters,
    FieldType,
    FileAttachmentDetail,
    FileAttributeApiParameters,
    FormAttachment,
    IdeaFormFieldKeys,
    RenderFormat
} from '@ideascale/commons';
import {QualifierLinkedIdeas} from './QualifierLinkedIdeas';
import {IdeaFormFields} from './IdeaFormFields';
import {DEFAULT_LANGUAGE_OPTION} from 'components/idea-submission/fields/LanguageField';

export class IdeaSubmissionRequestBuilder {
    private customFieldTypeMapper: Map<string, FieldType>;
    private isCustomField = (fieldKey: string) => {
        return fieldKey.startsWith(CustomField.CUSTOM_FIELD_PREFIX);
    };

    constructor(
        public submittedData: FieldValues,
        public draftId: number | null,
        public ideaFormFields: IdeaFormFields,
        public copiedIdeaAuthorId?: number,
        public classificationAttributesData?: ClassificationAttributesData,
        public classificationAttachmentAttributesData?: ClassificationAttachmentAttributesData,
    ) {
        const fieldTypeMap = new Map<string, FieldType>();
        ideaFormFields.formSections.forEach(section => {
            section.formFields.forEach(field => {
                if (this.isCustomField(field.key)) {
                    fieldTypeMap.set(field.key, field.type);
                }
            });
        });
        this.customFieldTypeMapper = fieldTypeMap;
    }

    build(): IdeaSubmissionRequest {
        const campaignId = (this.submittedData[IdeaFormFieldKeys.CAMPAIGN] as OptionType).value;
        const title = this.submittedData[IdeaFormFieldKeys.TITLE];
        const description = HtmlConverter.toServerHtmlFormat(this.submittedData[IdeaFormFieldKeys.DESCRIPTION]);
        const request = new IdeaSubmissionRequest(campaignId, title, description, RenderFormat.HTML);

        request.attributeApiParameters = [];

        request.customFieldValues = this.createCustomFieldValues(this.submittedData);

        if (IdeaFormFieldKeys.LANGUAGE in this.submittedData) {
            if (typeof this.submittedData[IdeaFormFieldKeys.LANGUAGE] === 'object') {
                request.languageId = (this.submittedData[IdeaFormFieldKeys.LANGUAGE] as OptionType)?.value;
            } else if (typeof this.submittedData[IdeaFormFieldKeys.LANGUAGE] === 'number') {
                request.languageId = this.submittedData[IdeaFormFieldKeys.LANGUAGE];
            } else {
                request.languageId = DEFAULT_LANGUAGE_OPTION.value;
            }
        }

        if (IdeaFormFieldKeys.ATTACHMENT in this.submittedData) {
            const attachmentList = Array.from(this.submittedData[IdeaFormFieldKeys.ATTACHMENT]) as FileAttachmentDetail[];
            request.attachments = attachmentList.map(attachment => ({
                filename: attachment.filename,
                altText: attachment.altText.trim() || attachment.filename
            }));
        }

        if (IdeaFormFieldKeys.TAGS in this.submittedData) {
            request.tags = ((this.submittedData[IdeaFormFieldKeys.TAGS] || []) as OptionType[]).map(tag => tag.value);
        }
        if (IdeaFormFieldKeys.LINK_IDEA in this.submittedData) {
            request.linkedIdeas = QualifierLinkedIdeas.toSimpleLinkedIdea(this.submittedData[IdeaFormFieldKeys.LINK_IDEA] as QualifierLinkedIdeas[]);
        }
        if (IdeaFormFieldKeys.HIDDEN_AUTHOR in this.submittedData) {
            request.hideIdeaAuthor = this.submittedData[IdeaFormFieldKeys.HIDDEN_AUTHOR];
        }
        if (IdeaFormFieldKeys.SUBMIT_ON_BEHALF_OF in this.submittedData) {
            request.onBehalfOfMemberId = (this.submittedData[IdeaFormFieldKeys.SUBMIT_ON_BEHALF_OF] as OptionType | null)?.value;
        }
        if (IdeaFormFieldKeys.CO_SUBMITTER in this.submittedData) {
            request.contributorIds = ((this.submittedData[IdeaFormFieldKeys.CO_SUBMITTER] || []) as OptionType[]).map(member => member.value);
        }
        if (this.ideaFormFields.labelApplicable) {
            request.labelIds = this.submittedData[IdeaFormFieldKeys.LABELS];
        }
        if (IdeaFormFieldKeys.ADMIN_OR_MODERATOR_ONLY in this.submittedData) {
            request.authorizedMemberOnly = this.submittedData[IdeaFormFieldKeys.ADMIN_OR_MODERATOR_ONLY];
        }
        if (this.ideaFormFields.campaignTosNotAccepted && IdeaFormFieldKeys.TERMS_OF_SERVICE in this.submittedData) {
            request.campaignTosAccepted = this.submittedData[IdeaFormFieldKeys.TERMS_OF_SERVICE];
        }
        if (this.draftId) {
            request.draftId = this.draftId;
        }
        if (this.copiedIdeaAuthorId) {
            request.copiedIdeaAuthorId = this.copiedIdeaAuthorId;
        }
        this.prepareClassificationsRequest(request);
        return request;
    }

    private buildFieldClassificationRequest(fieldKey: string, fieldType?: string) {
        const classificationValue = this.classificationAttributesData && this.classificationAttributesData[fieldKey];
        return FieldAttributeApiParameters.builder()
            .type('field')
            .name(fieldKey)
            .classification(classificationValue?.classification || '')
            .locationExtensions(classificationValue?.locationExtensions || [])
            .sensitiveExtensions(classificationValue?.sensitiveExtensions || [])
            .fieldDisplayType(fieldType)
            .build();
    }

    private buildAttachmentClassificationRequest(fieldKey: string, attachments: string[]) {
        const classifiedAttachments = this.classificationAttachmentAttributesData && this.classificationAttachmentAttributesData[fieldKey];

        const attachmentAttributes = attachments.map(item => {
            const classifiedFile = (classifiedAttachments || {})[item] as ClassificationAttribute;
            return FileAttributeApiParameters.builder()
                .filename(item)
                .classification(classifiedFile?.classification || '')
                .locationExtensions(classifiedFile?.locationExtensions || [])
                .sensitiveExtensions(classifiedFile?.sensitiveExtensions || [])
                .build();
        });

        return AttachmentAttributeApiParameters.builder()
            .type('attachment')
            .name(fieldKey)
            .fileAttributes(attachmentAttributes)
            .build();
    }

    private prepareClassificationsRequest(request: IdeaSubmissionRequest) {
        const allFormFields = this.ideaFormFields.formSections.flatMap(item => item.formFields);
        const allKeys = [...(this.classificationAttributesData ? Object.keys(this.classificationAttributesData) : []), ...(this.classificationAttachmentAttributesData ? Object.keys(this.classificationAttachmentAttributesData) : [])];

        if (allKeys.length > 0) {
            allKeys.filter(key => key !== 'text').forEach(fieldKey => {
                const field = allFormFields.find(item => item.key === fieldKey);
                if (field) {
                    if (this.isCustomField(fieldKey)) {
                        const fieldType = this.customFieldTypeMapper.get(fieldKey);
                        if (fieldType === FieldType.ATTACHMENT) {
                            const attachments = (request.customFieldValues || [])[fieldKey]?.value?.split(',') || [];
                            request.attributeApiParameters?.push(this.buildAttachmentClassificationRequest(fieldKey, attachments));
                        } else {
                            if (field.type === FieldType.TEXT_AREA) {
                                request.attributeApiParameters?.push(this.buildFieldClassificationRequest(fieldKey, field.type));
                            } else {
                                request.customFieldValues?.[field.key] && request.attributeApiParameters?.push(this.buildFieldClassificationRequest(fieldKey));
                            }
                        }
                    } else {
                        if (field.type === FieldType.ATTACHMENT) {
                            const attachments = request.attachments?.map(attachment => attachment.filename) || [];
                            request.attributeApiParameters?.push(this.buildAttachmentClassificationRequest(fieldKey, attachments));
                        } else {
                            request.attributeApiParameters?.push(this.buildFieldClassificationRequest(fieldKey));
                        }
                    }
                }

            });
        }
    }

    private createCustomFieldValues(formDataObject: FieldValues) {
        const customFieldValues: { [key: string]: any } = {};

        Object.keys(formDataObject).forEach(fieldKey => {
            if (this.isCustomField(fieldKey)) {
                let value = formDataObject[fieldKey];
                let renderFormat = RenderFormat.PLAIN_TEXT;
                let fieldType = this.customFieldTypeMapper.get(fieldKey);
                let attachments: FormAttachment[] = [];
                const fieldDisplayType = fieldType;
                if (fieldType === FieldType.TEXT_AREA) {
                    // Forcefully make user's text area into rich text editor
                    value = HtmlConverter.toServerHtmlFormat(value);
                    renderFormat = RenderFormat.HTML;
                }
                if (fieldType === FieldType.ATTACHMENT || fieldType === FieldType.MULTIPLE_CHOICE) {
                    if (fieldType === FieldType.ATTACHMENT) {
                        const attachmentList = Array.from(value) as FileAttachmentDetail[];
                        attachments = attachmentList.map(attachment => ({
                            filename: attachment.filename,
                            altText: attachment.altText.trim() || attachment.filename
                        }));
                        value = (attachmentList || []).map((attachment: FileAttachmentDetail) => attachment.filename).join();
                    } else {
                        if (Array.isArray(value)) {
                            value = (value || []).join();
                        } else {
                            value = String(value || '');
                        }
                    }
                }
                customFieldValues[fieldKey] = {
                    value,
                    renderFormat,
                    fieldDisplayType,
                };

                if (attachments.length > 0) {
                    customFieldValues[fieldKey] = {...customFieldValues[fieldKey], attachments};
                }
            }
        });
        return Object.keys(customFieldValues).length > 0 ? customFieldValues : undefined;
    }
}

export class IdeaSubmissionRequest {
    constructor(
        public campaignId: number,
        public title: string,
        public description: string,
        public renderFormat: RenderFormat,
        public copiedIdeaAuthorId?: number,
        public customFieldValues?: { [p: string]: any },
        public languageId?: number | string | null,
        public tags?: string[],
        public attachments?: FormAttachment[],
        public labelIds?: number[],
        public linkedIdeas?: { linkQualifierId: number, ideaId: number }[],
        public contributorIds?: number[],
        public onBehalfOfMemberId?: number,
        public saveAsDraft?: boolean,
        public autoSaveDraft?: boolean,
        public draftId?: number,
        public campaignTosAccepted?: boolean,
        public hideIdeaAuthor?: boolean,
        public authorizedMemberOnly?: boolean,
        public attributeApiParameters?: Array<FieldAttributeParameters | AttachmentAttributeParameters>
    ) {
    }
}