export enum CommunityOperationType {
    CHANGE_COMMUNITY_NAME = 'CHANGE_COMMUNITY_NAME',
    CHANGE_COMMUNITY_SUBTITLE = 'CHANGE_COMMUNITY_SUBTITLE',
    CHANGE_COMMUNITY_TILE = 'CHANGE_COMMUNITY_TILE',
    CHANGE_IDEA_VIEW_MODE = 'CHANGE_IDEA_VIEW_MODE',
    CHANGE_IDEA_SORT_MODE = 'CHANGE_IDEA_SORT_MODE',
    CHANGE_COMMUNITY_SIDEBAR_ORDER = 'CHANGE_COMMUNITY_SIDEBAR_ORDER',
    CHANGE_CONTACT_WIDGET = 'CHANGE_CONTACT_WIDGET',
    CHANGE_TOPBAR_LOGO = 'CHANGE_TOPBAR_LOGO',
    CHANGE_COMMUNITY_BANNER = 'CHANGE_COMMUNITY_BANNER',
    CHANGE_COMMUNITY_LOGO = 'CHANGE_COMMUNITY_LOGO',
    CHANGE_CUSTOM_FAVICON = 'CHANGE_CUSTOM_FAVICON',
    CHANGE_COMMUNITY_WIDGET = 'CHANGE_COMMUNITY_WIDGET',
    PUBLISH_COMMUNITY_HOME_CONFIG = 'PUBLISH_COMMUNITY_HOME_CONFIG',
}