import {KudoType} from './enums/KudoType';
import {Member} from '@ideascale/commons';

type kudoActivities = {
    receiver: Member,
    giver: Member,
    kudoImage: string,
    kudoType: KudoType
}

export class KudosData {
    static readonly EMPTY = new KudosData([], 0, 0, 0);

    constructor(
        public kudoActivities: kudoActivities [],
        public receivedCount: number,
        public givenCount: number,
        public kudoBalance: number,
    ) {
    }
}