import {Type} from 'class-transformer';
import {NotificationType} from './enums/NotificationType';
import {NotificationIcon} from './NotificationIcon';
import {KudoType} from './enums/KudoType';
import {ClassificationFieldAttribute} from '@ideascale/commons';

export class NotificationData {

    public id: number;
    public sourceMemberId: number;
    public identityHidden: boolean;
    public type: { id: number; name: NotificationType };
    public createdAt: string;
    public seenByActor: boolean;
    public notificationGroup: 'IDEA' | 'KUDO';
    @Type(() => NotificationIcon)
    public notificationIcon: NotificationIcon;
    public classificationAttributes: ClassificationFieldAttribute[];
    public viewAttributes: {
        slug: string,
        ideaTitle: string,
        authorName: string,
        commentText: string,
        campaignName: string,
        stageName: string,
        assignedMemberName: string,
        unassignedMemberName: string,
        teamRoleName: string,
        tagName: string,
        voteDirection: string,
        kudoReceiverName: string,
        sourceMemberName: string,
        month: string,
        year: string,
        weekOfYear: string,
        fromDate: string,
        toDate: string
    };
    public ideaId: number;
    public campaignId: number;
    public commentId: number;
    public kudoType: KudoType;
    public receiverId: number;
    public ideaAuthorId: number;
    public targetMemberId: number;
    public inactiveDaysThreshold: number;
    public voteCount: number;
    public stageCommentId: number;
    public commentType: { id: number, name: string };
    public stageMarker: { id: number, name: string };
    public stageId: number;
    public fieldValueId: number;
}