import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {ParagraphSkeleton} from '@ideascale/ui';
import {AlertEvent, AlertType, buildAlertEventData, eventDispatcher, useApiErrorResponseHandler} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {appLinks} from 'services/AppLinks';
import {useLocalizer} from 'hooks/useLocalizer';
import {useCampaignModuleService} from 'hooks/useCampaignModuleService';
import {useUpdateCurrentCampaign} from 'hooks/useUpdateCurrentCampaign';
import {CampaignHeaderContainer} from 'containers/CampaignHeaderContainer';
import {PageLayoutContainer} from 'containers/PageLayoutContainer';
import {IdeaSubmissionPreviewContainer} from 'containers/IdeaSubmissionPreviewContainer';
import {HtmlRenderer} from 'components/shared/HtmlRenderer';
import {IdeaListRouteMatchParams} from 'models/types/IdeaListRouteMatchParams';
import {TosData} from 'models/TosData';
import {SHOW_JOIN_COMMUNITY_PROMPT} from 'constants/AppConstants';
import './CampaignTosPage.scss';

export const CampaignTosPage = () => {
    const localizer = useLocalizer();
    const {authentication} = useAppContext();
    const {fetchCampaignTosContent, campaignTosAccept} = useCampaignModuleService();
    const {fetchCampaignDetails} = useCampaignModuleService();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const params = useParams<IdeaListRouteMatchParams>();
    const {state}: any = useLocation();
    const [tosContent, setTosContent] = useState<TosData>(TosData.EMPTY);
    const navigate = useNavigate();
    const [isDataFetching, setIsDataFetching] = useState(true);
    useUpdateCurrentCampaign();

    useEffect(() => {
        if (params.campaignId && params.campaignId.length > 0)
            fetchCampaignDetails(+params.campaignId).then(campaignData => {
                if (!campaignData.tosAcceptPending) {
                    navigate(appLinks.aboutCampaign(params?.campaignId!), {replace: true});
                }
            }).finally(() => {
                setIsDataFetching(false);
            });
    }, [fetchCampaignDetails, navigate, params.campaignId]);

    useEffect(() => {
        if (params?.campaignId) {
            fetchCampaignTosContent(+params?.campaignId).then(data => {
                setTosContent(data);
            });
        }
    }, [fetchCampaignTosContent, params.campaignId]);

    const handleCampaignAgree = (e: React.MouseEvent, campaignId: number) => {
        e.preventDefault();
        if (authentication.actor.hasPendingMembership()) {
            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.warn, localizer.msg('frontend-shared.errors.member_awaiting_approval')));
            return;
        }

        campaignTosAccept(campaignId).then(status => {
            if (status) {
                if (status.message !== '') {
                    eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, localizer.msg('page.campaign-terms-of-service.accepted')));
                }
                navigate({
                    pathname: state ? state?.fromUrl : appLinks.campaign(params?.campaignId!),
                }, {
                    state: null,
                    replace: true
                });
            }
        }).catch((error) => {
            handleErrorResponse(error);
        });
    };

    useEffect(() => {
        if (authentication.isJoinCommunityAvailable()) {
            eventDispatcher.dispatch(SHOW_JOIN_COMMUNITY_PROMPT);
        }
    }, [authentication]);

    return (
        <>
            {
                isDataFetching
                    ? <ParagraphSkeleton rows={5}/>
                    : (
                        <div className="campaign-tos-page">
                            <CampaignHeaderContainer navigationDisabled={true} />
                            <IdeaSubmissionPreviewContainer displayAsModal/>
                            <PageLayoutContainer mainContent={
                                <div className="pt-3 pb-2 pe-lg-5 px-lg-5 me-5 ms-5 tos-container">
                                    <div className="card panel">
                                        <div className="card-body">
                                            {
                                                tosContent.introductoryMessage
                                                    ? (
                                                        <p className="pe-4" data-test-element-id="campaign-tos-introductory-message">{localizer.msg(`${tosContent.introductoryMessage}`)}</p>
                                                    )
                                                    : null
                                            }
                                            <h2 className="h5 mb-0" data-test-element-id="campaign-tos-message">{localizer.msg('sidebar.campaign.terms-of-service')}</h2>
                                            <HtmlRenderer content={tosContent.termsOfService}
                                                          className="mt-3 mb-4 tos-content pe-4"/>
                                            <button type="button"
                                                    onClick={(e) => handleCampaignAgree(e, +params?.campaignId!)}
                                                    className="btn btn-primary">{localizer.msg('page.campaign-terms-of-service.agree')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }/>
                        </div>
                    )
            }
        </>
    );
};