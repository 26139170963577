import {ImageryOperation} from './ImageryOperation';
import {CommunityOperationType} from './CommunityOperationType';

export class ChangeTopbarLogoOperation extends ImageryOperation {
    constructor(
        public type: CommunityOperationType,
        public fileId: number,
        public topbarLogoUrl: string,
        public topbarLogoAltText?: string
    ) {
        super(type, fileId);
    }
}