import React, {Fragment, useState} from 'react';
import range from 'lodash/range';
import {cloneDeep} from 'lodash';
import {Button} from '@ideascale/ui';
import {
    ClassificationLabel,
    Localizer,
    PageTheme,
    useApiErrorResponseHandler,
    useApplicableClassifications
} from '@ideascale/commons';
import {PageData} from 'containers/landing-page/FeaturedCampaignsContainer';
import {DisplayHeader} from 'components/landing-page/DisplayHeader';
import {CampaignCard} from 'components/landing-page/CampaignCard';
import {FeaturedCampaignsComponentData} from 'models/landing-page/FeaturedCampaignsComponentData';
import {CampaignSlide} from 'models/CampaignSlide';
import {CampaignDisplayType} from 'models/enums/landing-page/CampaignDisplayType';
import {Slider} from 'models/Slider';
import {CampaignFilter} from 'models/enums/landing-page/CampaignFilter';
import 'components/landing-page/ListViewComponent.scss';
import {LIST_PAGE_SIZE} from 'constants/AppConstants';

type FeaturedCampaignsComponentProps = {
    theme: PageTheme;
    localizer: Localizer;
    loading: boolean,
    editMode?: boolean
    config: FeaturedCampaignsComponentData;
    campaignData: CampaignSlide[];
    setCampaignData: (campaign: CampaignSlide[]) => void;
    lastFetchedPageData: PageData;
    setLastFetchedPageData: (page: PageData) => void;
    loadCampaigns: (limit: number, page: number) => Promise<Slider>;
}

export const ListViewComponent = (props: FeaturedCampaignsComponentProps) => {
    const {
        theme,
        localizer,
        loading,
        editMode = false,
        config,
        campaignData,
        setCampaignData,
        lastFetchedPageData,
        setLastFetchedPageData,
        loadCampaigns
    } = props;
    const {getClassificationAttribute} = useApplicableClassifications('', []);
    const {showErrorMessage} = useApiErrorResponseHandler({localizer});
    const [fetchingMore, setFetchingMore] = useState(false);

    const onLoadMoreClicked = async () => {
        setFetchingMore(true);
        let oldData = cloneDeep(campaignData);
        try {
            const data = await loadCampaigns(LIST_PAGE_SIZE, lastFetchedPageData.currentPage + 1);
            setCampaignData([...oldData, ...data.content as CampaignSlide[]]);
            setLastFetchedPageData({hasMore: data.hasMore, currentPage: data.pageNo});
        } catch (error) {
            showErrorMessage(error);
        } finally {
            setFetchingMore(false);
        }
    };

    const getViewMode = () => {
        return config.campaignDisplayType || CampaignDisplayType.GRID;
    };

    return (
        <Fragment>
            <DisplayHeader theme={theme} title={config.title}/>
            {
                !loading && campaignData.length === 0
                    ?
                    <div className="panel card">
                        <div className="card-body panel-body">
                            <div className="alert alert-warning mb-0 text-center">
                                {localizer.msg('search.campaign.no-results')}
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        {
                            loading
                                ? (
                                    <div
                                        className={`featured-campaigns-list ${getViewMode() === CampaignDisplayType.GRID ? 'row' : ''}`}>
                                        {range(0, 9).map((index) => (
                                            <div key={index}
                                                 className={`mb-4 ${getViewMode() === CampaignDisplayType.GRID ? 'col-12 col-md-6 col-lg-4' : ''}`}>
                                                <CampaignCard theme={theme} key={index}
                                                              localizer={localizer}
                                                              loading={true}
                                                              editMode={editMode}
                                                              displayType={config.campaignDisplayType}/>
                                            </div>
                                        ))}
                                    </div>
                                )
                                : (
                                    <div
                                        className={`featured-campaigns-list ${getViewMode() === CampaignDisplayType.GRID ? 'row' : ''}`}>
                                        {
                                            campaignData.map(campaign => (
                                                <div key={campaign.id}
                                                     className={`mb-4 ${getViewMode() === CampaignDisplayType.GRID ? 'col-12 col-md-6 col-lg-4' : ''}`}>
                                                    <CampaignCard key={campaign.id}
                                                                  localizer={localizer}
                                                                  editMode={editMode}
                                                                  campaign={campaign}
                                                                  theme={theme}
                                                                  classificationLabelConfig={{
                                                                      ClassificationLabel: ClassificationLabel,
                                                                      getClassificationAttribute
                                                                  }}
                                                                  displayType={config.campaignDisplayType}
                                                    />
                                                </div>
                                            ))
                                        }
                                    </div>
                                )

                        }
                    </div>
            }
            {
                config.campaignFilter === CampaignFilter.ACTIVE
                && lastFetchedPageData.hasMore
                && (
                    <div className="w-100 d-flex align-items-center justify-content-center">
                        <Button className={editMode ? 'pe-none' : ''} color="secondary" disabled={fetchingMore}
                                onClick={onLoadMoreClicked} {...(editMode ? {tabIndex: -1} : {})}>
                            {localizer.msg('common.load-more')}
                        </Button>
                    </div>
                )
            }

        </Fragment>
    );
};