import {ProfileAboutMeData} from './ProfileAboutMeData';
import {KudoType} from './enums/KudoType';
import {ProfileResponse} from './types/ProfileResponse';
import {MemberSummary} from './MemberSummary';

export class ProfileSidebarResponse extends ProfileAboutMeData {
    static readonly EMPTY = new ProfileSidebarResponse(MemberSummary.EMPTY, 0, 0, 0, 0, false, [], new Date().toISOString(), '', [], false, false, false);

    bioContent: string;
    profileResponses: ProfileResponse[];

    constructor(
        memberSummary: MemberSummary,
        ideaCount: number,
        commentCount: number,
        voteCount: number,
        implementedIdeaCount: number,
        followed: boolean,
        kudos: { type: KudoType, count: number }[],
        createdAt: string,
        bioContent: string,
        profileResponses: ProfileResponse[],
        editName: boolean,
        editAvatar: boolean,
        editProfile: boolean
    ) {
        super(memberSummary, ideaCount, commentCount, voteCount, implementedIdeaCount, followed, kudos, createdAt, editName, editAvatar, editProfile);
        this.bioContent = bioContent;
        this.profileResponses = profileResponses;
    }
}