import React, {Fragment, useCallback, useMemo, useState} from 'react';
import {PageTheme, useApiErrorResponseHandler, useToggle} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {useLandingPageService} from 'hooks/useService';
import {useTranslation} from 'hooks/useTranslation';
import {CommonConfigModal} from 'components/landing-page/CommonConfigModal';
import {CommunityStatisticsComponent} from 'components/landing-page/CommunityStatisticsComponent';
import {ConfigWrapper} from 'components/landing-page/ConfigWrapper';
import {TranslationModal} from 'shared/TranslationModal';
import {Component} from 'models/landing-page/Component';
import {CommunityStatisticsData} from 'models/landing-page/CommunityStatisticsData';
import {StatisticsItem} from 'models/landing-page/StatisticsItem';
import {StatisticsRequest} from 'models/landing-page/StatisticsRequest';
import {TranslationConfig} from 'models/TranslationConfig';
import {TranslationContentType} from 'models/enums/landing-page/TranslationContentType';
import {TranslationFieldType} from 'models/enums/TranslationFieldType';
import {TranslationContentResponse} from 'models/landing-page/TranslationContentResponse';

type CommunityStatisticsContainerProps = {
    showConfig: boolean;
    data: CommunityStatisticsData;
    theme: PageTheme;
    showTranslationConfig: boolean,
    onComponentEdited: () => void;
    translationLanguageId?: number,
}

export const CommunityStatisticsContainer = (props: CommunityStatisticsContainerProps) => {
    const {showConfig, data, theme, showTranslationConfig, translationLanguageId, onComponentEdited} = props;
    const [showEditModal, toggleEditModal] = useToggle(false);
    const [showTranslationModal, toggleTranslationModal] = useToggle(false);
    const [statsConfig, setStatsConfig] = useState(data);
    const localizer = useLocalizer();
    const {showErrorMessage} = useApiErrorResponseHandler({localizer});
    const landingPageService = useLandingPageService();
    const {getTranslatedContent, saveTranslatedContent} = useTranslation();

    const onEditClicked = useCallback(() => {
        toggleEditModal();
    }, [toggleEditModal]);

    const saveCommunityStatsData = useCallback(async (component: CommunityStatisticsData) => {
        if (landingPageService) {
            return landingPageService.saveCommunityStatistics(component);
        }
        return CommunityStatisticsData.EMPTY;
    }, [landingPageService]);

    const getCommunityStats = useCallback(async (items: StatisticsRequest) => {
        if (landingPageService) {
            return landingPageService.fetchCommunityStatistics(items);
        }
        return [];
    }, [landingPageService]);

    const onToggleVisibility = async () => {
        const newData = {...statsConfig};
        newData.visible = !newData.visible;
        await updateCommunityStatsData(newData);
        onComponentEdited();
    };

    const updateCommunityStatsData = useCallback(async (component: CommunityStatisticsData) => {
        try {
            const response = await saveCommunityStatsData(component);
            if (response) {
                setStatsConfig(response);
            }
        } catch (e: any) {
            if (e.data && 'validationErrors' in e.data) {
                throw e.data;
            } else {
                showErrorMessage(e);
            }
        }
    }, [saveCommunityStatsData, showErrorMessage]);

    const onEditCommonConfig = useCallback(async (component: Component) => {
        const newData = {...statsConfig};
        newData.title = component.title;
        newData.subtitle = component.subtitle;
        await updateCommunityStatsData(newData);
    }, [statsConfig, updateCommunityStatsData]);

    const onToggleItemVisibility = useCallback(async (item: StatisticsItem) => {
        const newData = {...statsConfig};
        const index = newData.statisticsList?.findIndex(statistic => statistic.id === item.id);
        if (index >= 0) {
            newData.statisticsList[index] = item;
        }
        await updateCommunityStatsData(newData);
        onComponentEdited();
    }, [onComponentEdited, statsConfig, updateCommunityStatsData]);

    const onTranslationClicked = useCallback(() => {
        if (translationLanguageId !== undefined) {
            toggleTranslationModal();
        }
    }, [toggleTranslationModal, translationLanguageId]);

    const onTextTranslated = useCallback((response: TranslationContentResponse) => {
        setStatsConfig(prev => {
            const newData = {...prev};
            if (response.contentType === TranslationContentType.PAGE_COMPONENT_TITLE) {
                newData.title = response.translation;
            } else {
                newData.subtitle = response.translation;
            }
            return newData;
        });
    }, []);

    const translationConfigs = useMemo(() => {
        return [
            new TranslationConfig(
                TranslationContentType.PAGE_COMPONENT_TITLE,
                statsConfig.id,
                TranslationFieldType.TEXT,
            ),
        ];
    }, [statsConfig.id]);

    return (
        <Fragment>
            <ConfigWrapper visible={statsConfig.visible}
                           toggleVisibility={onToggleVisibility}
                           showConfig={showConfig}
                           localizer={localizer}
                           showTranslationConfig={showTranslationConfig}
                           onTranslationClicked={onTranslationClicked}
                           onEditClicked={onEditClicked}
                           className={`${statsConfig.attribute?.className} community-stats-component`}
                           accessibleName={localizer.msg('landing-page.community-statistics')}>
                <CommunityStatisticsComponent theme={theme} config={statsConfig} showConfig={showConfig}
                                              getCommunityStats={getCommunityStats}
                                              toggleItemVisibility={onToggleItemVisibility}/>
            </ConfigWrapper>
            {
                showEditModal &&
                <CommonConfigModal renderSubtitle={theme === PageTheme.CURIE} theme={theme} open={showEditModal}
                                   onComponentEdited={onComponentEdited}
                                   toggle={toggleEditModal} configData={statsConfig} updateData={onEditCommonConfig}
                                   localizer={localizer}/>
            }
            {
                showTranslationModal && translationLanguageId !== undefined &&
                <TranslationModal translationLanguageId={translationLanguageId} localizer={localizer}
                                  open={showTranslationModal}
                                  toggle={toggleTranslationModal}
                                  onTextTranslated={onTextTranslated}
                                  onComponentEdited={onComponentEdited}
                                  translationConfigs={translationConfigs}
                                  getTranslatedContent={getTranslatedContent}
                                  saveTranslatedContent={saveTranslatedContent}/>
            }
        </Fragment>
    );
};