import {LabelData} from '@ideascale/commons';
import {Stage} from './types/Stage';
import {StageGroup} from './enums/StageGroup';

export class IdeaLabelActionResponse {
    static readonly EMPTY = new IdeaLabelActionResponse([], {
        id: 0,
        key: '',
        name: '',
        label: '',
        description: '',
        functionName: '',
        stageGroup: StageGroup.COMPLETED
    }, false, false);

    constructor(
        public labels: LabelData[],
        public stage: Stage,
        public reportAbuseAllowed: boolean,
        public reportDuplicateAllowed: boolean,
    ) {
    }
}