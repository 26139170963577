import {Component} from 'models/landing-page/Component';
import {StatisticsItem} from 'models/landing-page/StatisticsItem';
import {LandingPageComponentType} from 'models/enums/landing-page/LandingPageComponentType';
import {UiAttribute} from 'models/landing-page/UiAttribute';

export class CommunityStatisticsData extends Component {
    public static EMPTY = new CommunityStatisticsData(0, 0, LandingPageComponentType.STATISTICS, false, UiAttribute.EMPTY, [], '', '');

    public constructor(
        public id: number,
        public languageId: number,
        public type: LandingPageComponentType,
        public visible: boolean,
        public attribute: UiAttribute,
        public statisticsList: StatisticsItem[],
        public title?: string,
        public subtitle?: string
    ) {
        super(id, languageId, type, visible, attribute, title, subtitle);
    }
}