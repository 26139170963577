import React from 'react';
import {Button} from 'reactstrap';
import {Localizer} from '@ideascale/commons';

type FilterActionButtonsProps = {
    localizer: Localizer;
    onClear: () => void;
    onApply: () => void;
    onCancel: () => void;
}

export const FilterActionButtons = (props: FilterActionButtonsProps) => {
    const {localizer, onClear, onApply, onCancel} = props;

    return (
        <div className="form-group text-end mb-0 mt-2">
            <button className="btn btn-link px-1 me-2 text-decoration-none" type="button"
                    onClick={onClear}>{localizer.msg('search.clear-all-filters')}</button>
            <button className="btn btn-cancel px-1 me-3" type="button" onClick={onCancel}>
                {localizer.msg('search.cancel')}
            </button>
            <Button form="search-form" color="primary" type="submit"
                    onClick={onApply}>{localizer.msg('search.label')}</Button>
        </div>
    );
};