import React from 'react';
import {Icon} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import styles from './CommentVote.module.scss';

type CommentVoteProps = {
    upVoteCount: number;
    downVoteCount: number;
    onUpVoteCount: () => void;
    onDownVoteCount: () => void;
    upVoteEnabled: boolean;
    downVoteEnabled: boolean;
}

export const CommentVote = (props: CommentVoteProps) => {
    const {upVoteCount, downVoteCount, onUpVoteCount, onDownVoteCount, upVoteEnabled, downVoteEnabled} = props;

    return (
        <div className="comment-vote-actions mt-2">
            <button className="btn btn-comment-vote p-0 active me-2 fw-normal" onClick={onUpVoteCount}
                    disabled={!upVoteEnabled}>
                <Icon className="thumbs-up me-1" name="thumb-up" iconSpritePath={svgIconsPath}/>
                <span className="vote-data text-gray">{`${upVoteCount > 0 ? `+${upVoteCount}` : upVoteCount}`}</span>
            </button>
            <button className="btn btn-comment-vote p-0 fw-normal" onClick={onDownVoteCount}
                    disabled={!downVoteEnabled}>
                <Icon className={`${styles.thumbsDown} me-1`} name="thumb-up" iconSpritePath={svgIconsPath}/>
                <span
                    className="vote-data text-gray">{`${downVoteCount > 0 ? `-${downVoteCount}` : downVoteCount}`}</span>
            </button>
        </div>
    );
};
