import {Operation} from './Operation';
import {CommunityOperationType} from './CommunityOperationType';

export class ChangeIdeaViewModeOperation implements Operation {
    constructor(public type: CommunityOperationType, public viewMode: string) {
    }

    getType(): CommunityOperationType {
        return this.type;
    }
}