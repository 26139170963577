import {useCallback} from 'react';
import {useCommunityService} from 'hooks/useService';
import {SharingMedia} from 'models/SharingMedia';
import {DigestShareData} from 'models/DigestShareData';
import {DigestEmailRequestParams} from 'models/DigestEmailRequestParams';
import {PageParameters} from 'models/types/PageParameters';
import {DigestResponseContent} from 'models/DigestResponseContent';

export const useDigestService = () => {
    const communityService = useCommunityService();

    const fetchMonthlyModeratorDigest = useCallback(async (pageParameters: PageParameters, year: string, month: string): Promise<DigestResponseContent> => {
        if (communityService !== null) {
            return await communityService.fetchMonthlyModeratorDigest(pageParameters, year, month);
        }
        return DigestResponseContent.EMPTY;
    }, [communityService]);


    const fetchWeeklyModeratorDigest = useCallback(async (pageParameters: PageParameters, year: string, weekOfYear: string): Promise<DigestResponseContent> => {
        if (communityService !== null) {
            return await communityService.fetchWeeklyModeratorDigest(pageParameters, year, weekOfYear);
        }
        return DigestResponseContent.EMPTY;
    }, [communityService]);

    const fetchCampaignPerformanceMonthlyModeratorDigest = useCallback(async (campaignId: number, memberId: number, year: string, month: string): Promise<DigestShareData> => {
        if (communityService !== null) {
            return await communityService.fetchCampaignPerformanceMonthlyModeratorDigest(campaignId, memberId, year, month);
        }
        return DigestShareData.EMPTY;
    }, [communityService]);

    const fetchCampaignPerformanceWeeklyModeratorDigest = useCallback(async (campaignId: number, memberId: number, year: string, weekOfYear: string): Promise<DigestShareData> => {
        if (communityService !== null) {
            return await communityService.fetchCampaignPerformanceWeeklyModeratorDigest(campaignId, memberId, year, weekOfYear);
        }
        return DigestShareData.EMPTY;
    }, [communityService]);

    const fetchCampaignPerformanceSharingMedia = useCallback(async (campaignId: number): Promise<SharingMedia[]> => {
        if (communityService !== null) {
            return await communityService.fetchCampaignPerformanceSharingMedia(campaignId);
        }
        return [];
    }, [communityService]);

    const sendDigestEmailViaRecipientEmails = useCallback(async (requestParams: DigestEmailRequestParams, campaignId: number) => {
        if (communityService !== null) {
            return communityService.sendDigestEmailViaRecipientEmails(requestParams, campaignId);
        }
    }, [communityService]);

    const sendDigestEmailViaRecipientIds = useCallback(async (requestParams: DigestEmailRequestParams, campaignId: number) => {
        if (communityService !== null) {
            return communityService.sendDigestEmailViaRecipientIds(requestParams, campaignId);
        }
    }, [communityService]);

    return {
        fetchMonthlyModeratorDigest,
        fetchWeeklyModeratorDigest,
        fetchCampaignPerformanceMonthlyModeratorDigest,
        fetchCampaignPerformanceWeeklyModeratorDigest,
        fetchCampaignPerformanceSharingMedia,
        sendDigestEmailViaRecipientEmails,
        sendDigestEmailViaRecipientIds
    };
};