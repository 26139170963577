import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {useQuery} from 'react-query';
import {ApiResponseError, FileUploadResponse, LayoutUtil, Member, PageTitle, UploadProgressCallback,} from '@ideascale/commons';
import {CollapseExpand} from '@ideascale/ui';
import iconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useAppService, useCommunityService} from 'hooks/useService';
import {useProfilePageParams} from 'hooks/useProfilePageParams';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {ProfileSidebarResponse} from 'models/ProfileSidebarResponse';
import {ProfileFieldData} from 'models/ProfileFieldData';
import {ProfileFieldUpdateRequest} from 'models/types/ProfileFieldUpdateRequest';
import {GenericResponse} from 'models/GenericResponse';
import {PageParameters} from 'models/types/PageParameters';
import {ROUTES} from 'shared/Routes';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {QUERY_KEYS} from 'constants/AppConstants';
import {MemberBio} from 'models/MemberBio';
import {Bio} from 'components/profile/sidebar/Bio';
import {AboutMe} from 'components/profile/sidebar/AboutMe';
import {ProfileDetails} from 'components/profile/sidebar/ProfileDetails';
import {useClassificationService} from 'hooks/useClassificationService';

export const ProfileSidebarContainer = () => {
    const navigate = useNavigate();
    const {authentication: {actor}} = useAppContext();
    const communityService = useCommunityService();
    const appService = useAppService();
    const currentProfileId = useProfilePageParams();
    const {communityConfig} = useAppContext();
    const localizer = useLocalizer();
    const {fetchClassifications} = useClassificationService();

    const fetchProfileSidebar = useCallback(async (memberId?: number) => {
        if (communityService) {
            return await communityService.fetchProfileSidebar(memberId);
        }
        return ProfileSidebarResponse.EMPTY;
    }, [communityService]);

    const {data = ProfileSidebarResponse.EMPTY} = useQuery([QUERY_KEYS.PROFILE_SIDEBAR, currentProfileId],
        ({queryKey}) => fetchProfileSidebar(Number(queryKey[1])),
        {
            staleTime: 10 * 60 * 1000,
            onError: (error: any) => {
                if (error && [ApiResponseError.NOT_FOUND, ApiResponseError.FORBIDDEN].includes(error?.status)) {
                    navigate(ROUTES.HOME);
                }
            }
        }
    );

    const {bioContent, profileResponses, ...memberProfileAboutMe} = data;

    const uploadImage = useCallback(async (data: FormData, onUploadProgress: UploadProgressCallback) => {
        if (appService) {
            return await appService.uploadImage(data, onUploadProgress);
        }
        return FileUploadResponse.EMPTY;
    }, [appService]);

    const fetchProfileFields = useCallback(async (memberId: number): Promise<ProfileFieldData[]> => {
        if (communityService) {
            return await communityService.fetchProfileFields(memberId);
        }
        return [];
    }, [communityService]);

    const updateProfileFields = useCallback(async (updateRequest: ProfileFieldUpdateRequest, memberId: number) => {
        if (communityService) {
            return await communityService.updateProfileFields(updateRequest, memberId);
        }
        return ProfileSidebarResponse.EMPTY;
    }, [communityService]);

    const updateProfileAboutMeSection = useCallback(async (requestParameters: { id: number, avatar: string, firstName: string, lastName: string }) => {
        if (communityService) {
            return await communityService.updateProfileAboutMeSection(requestParameters);
        }
        return ProfileSidebarResponse.EMPTY;
    }, [communityService]);

    const toggleMemberSubscription = useCallback(async (memberId: number, follow: boolean) => {
        if (communityService) {
            return await communityService.toggleMemberSubscription(memberId, follow);
        }
        return GenericResponse.EMPTY;
    }, [communityService]);

    const fetchRecipients = useCallback(async (pageParameters: PageParameters): Promise<PagedResponseContent<Member>> => {
        if (communityService !== null) {
            return await communityService.fetchRecipients(pageParameters);
        }
        return PagedResponseContent.nullObject<Member>();
    }, [communityService]);

    const fetchMemberBio = useCallback(async (): Promise<MemberBio> => {
        if (communityService) {
            return await communityService.fetchMemberBio();
        }
        return MemberBio.EMPTY;
    }, [communityService]);

    const SaveMemberBio = useCallback(async (memberId: number, content: string): Promise<MemberBio> => {
        if (communityService) {
            return await communityService.SaveMemberBio(memberId, content);
        }
        return MemberBio.EMPTY;
    }, [communityService]);

    return (
        <div className="profile-sidebar">
            <PageTitle
                title={`Profile for  ${data?.memberSummary.name} - ${communityConfig.name} - by IdeaScale`}/>
            <AboutMe
                {...memberProfileAboutMe} uploadImage={uploadImage}
                fetchClassifications={fetchClassifications}
                toggleMemberSubscription={toggleMemberSubscription}
                updateProfileAboutMeSection={updateProfileAboutMeSection}
                currentProfileId={currentProfileId}
                actorId={actor.id}
                fetchRecipients={fetchRecipients}
            />

            <Bio fetchMemberBio={fetchMemberBio}
                 bioContent={bioContent} actorId={actor.id} currentProfileId={currentProfileId}
                 saveMemberBio={SaveMemberBio}/>

            <CollapseExpand
                containerClass="profile-details panel-light"
                headerClass={`${data.editProfile ? 'me-3' : ''}`}
                headerTitle={localizer.msg('profile.profile-details.heading')}
                svgIconSprite={iconPath}
                defaultOpen={!LayoutUtil.isMobileLayout()}>
                <ProfileDetails
                    canEditProfile={data.editProfile}
                    profileResponses={profileResponses}
                    fetchProfileFields={fetchProfileFields}
                    updateProfileFields={updateProfileFields}
                    currentProfileId={currentProfileId}
                />
            </CollapseExpand>
        </div>
    );
};
