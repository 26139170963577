import React, {Fragment} from 'react';
import {Trans} from 'react-i18next';
import {Avatar, Button, Icon} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {Localizer, MemberLink, StringUtil, TimeAgo} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {LandingPageVersion} from 'models/types/LandingPageVersion';
import {VERSION_NOTE_MAX_LENGTH} from 'constants/AppConstants';

type LandingPageVersionProp = {
    versionData: LandingPageVersion;
    localizer: Localizer
    onVersionDelete: (pageId: number, version: number) => Promise<void>;
    onVersionEditClicked: (versionData: LandingPageVersion) => void;
    onVersionDeleteClicked: (versionData: LandingPageVersion) => void;
    publishedVersion: boolean;
    defaultVersion: boolean;
    editPermission: boolean;
}

export const LandingPageTemplateVersion = (props: LandingPageVersionProp) => {
    const {
        versionData,
        localizer,
        onVersionEditClicked,
        publishedVersion,
        defaultVersion,
        onVersionDeleteClicked,
        editPermission
    } = props;
    const {authentication} = useAppContext();

    return (
        <Fragment>
            <div className={`d-flex align-items-start ${defaultVersion ? 'my-2' : 'mb-2'}`}>
                <div className="me-2">
                    <Icon className="" name="arrow-looped-around-clock-alt" iconSpritePath={svgIconPath} width={15} height={15}/>
                </div>
                <div className="version-info d-flex flex-column align-items-start text-truncate mt-1">
                    <div className={`d-flex align-items-baseline ${!defaultVersion ? 'mb-2' : ''}`}>
                        <h5 className={`fw-bold font-size-normal p-0 text-start text-truncate border-0 bg-transparent edit-mode-element version-title ${publishedVersion ? 'me-2' : ''}`}>
                            {versionData.title}
                        </h5>
                        {
                            publishedVersion &&
                            <span
                                className={`label pos-top-n1 single-label label-field-published me-2 edit-mode-element`}>
                                {localizer.msg('landing-page.action-topbar.published')}
                            </span>
                        }
                    </div>
                    {
                        !defaultVersion &&
                        <Fragment>
                            {
                                versionData.note &&
                                <span className="text-gray mb-1 edit-mode-element version-note">
                                    {StringUtil.textTruncate(versionData.note, VERSION_NOTE_MAX_LENGTH)}
                                </span>
                            }
                            <div
                                className="py-1 pe-1 d-inline-flex align-items-center gap-1 text-gray font-size-sm text-truncate edit-info">
                                {
                                    versionData.modifiedBy
                                        ?
                                        (
                                            <Trans
                                                i18nKey="landing-page.action-topbar.edit-mode.version-last-edited-user">
                                                <TimeAgo localizer={localizer} dateISOString={versionData.modifiedAt}
                                                         prefixText={false}/>
                                                <MemberLink
                                                    className="edit-mode-element d-inline-flex align-items-center"
                                                    id={versionData.modifiedBy.id}
                                                    identityHidden={versionData.modifiedBy.identityHidden}>
                                                    <Avatar size="xs" src={versionData.modifiedBy.avatar}
                                                            alt={versionData.modifiedBy.username ? localizer.msg('common.user-avatar', {username: versionData.modifiedBy.username}) : ''}/>
                                                    <span
                                                        className="ms-1 d-inline-block text-truncate author-name text-gray">
                                                    {
                                                        authentication.actor.id === versionData.modifiedBy.id
                                                            ? localizer.msg('common.you')
                                                            : versionData.modifiedBy.name
                                                    }
                                                </span>
                                                </MemberLink>
                                            </Trans>
                                        )
                                        :
                                        (
                                            <Trans values={{deletedUser: localizer.msg('common.deleted-user')}}
                                                   i18nKey="landing-page.action-topbar.edit-mode.version-last-edited-deleted-user">
                                                <TimeAgo localizer={localizer} dateISOString={versionData.modifiedAt}
                                                         prefixText={false}/>
                                                <span className="edit-mode-element">deletedUser</span>
                                            </Trans>
                                        )
                                }
                            </div>
                        </Fragment>
                    }
                </div>
            </div>
            {
                editPermission && !defaultVersion &&
                <div className="d-flex">
                    <Button
                        className={`p-0 me-3 edit-mode-element delete-landing-page-version ${publishedVersion ? 'opacity-50' : ''}`}
                        type="button" color="transparent"
                        title={localizer.msg('landing-page.action-topbar.edit-mode.delete-version')}
                        onClick={(event) => {
                            event?.stopPropagation();
                            if (!publishedVersion) {
                                onVersionDeleteClicked(versionData);
                            }
                        }}>
                        <Icon name="trash-can-open" iconSpritePath={svgIconPath} width={15} height={15}/>
                    </Button>
                    <Button className="p-0 edit-mode-element edit-landing-page-version" type="button"
                            color="transparent"
                            title={localizer.msg('landing-page.action-topbar.edit-mode.edit-button')}
                            onClick={(event) => {
                                event?.stopPropagation();
                                onVersionEditClicked(versionData);
                            }}>
                        <Icon name="pencil" iconSpritePath={svgIconPath} width={15} height={15}/>
                    </Button>
                </div>
            }
        </Fragment>
    );
};
