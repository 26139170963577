import {HomeConfigHolder} from 'models/edit-mode/HomeConfigHolder';

export type EditorCallback = {
    applyChange: (configHolder: HomeConfigHolder) => void;
}

export abstract class Editor {
    protected readonly onChangeCallbacks: EditorCallback;

    protected constructor(callback: EditorCallback) {
        this.onChangeCallbacks = callback;
    }

    protected abstract applyChange(): void;
}