import {Type} from 'class-transformer';
import {ProfileFieldData} from './ProfileFieldData';

export class MemberProfileAndFieldValuesHolder {
    static EMPTY = new MemberProfileAndFieldValuesHolder('', '','', '', false, false, '', [], []);
    displayName: string;
    firstName: string;
    lastName: string;
    username: string;
    firstAndLastNameSetupRequired: boolean;
    usernameSetupRequired: boolean;
    timeZone: string;
    allTimeZones: string[];
    @Type(() => ProfileFieldData)
    incompleteFieldValues: ProfileFieldData[];

    constructor(
        displayName: string,
        firstName: string,
        lastName: string,
        username: string,
        firstAndLastNameSetupRequired: boolean,
        usernameSetupRequired: boolean,
        timeZone: string,
        allTimeZones: string[],
        incompleteFieldValues: ProfileFieldData[]
    ) {
        this.displayName = displayName;
        this.firstName = firstName;
        this.lastName = lastName;
        this.username = username;
        this.firstAndLastNameSetupRequired = firstAndLastNameSetupRequired;
        this.usernameSetupRequired = usernameSetupRequired;
        this.timeZone = timeZone;
        this.allTimeZones = allTimeZones;
        this.incompleteFieldValues = incompleteFieldValues;
    }
}