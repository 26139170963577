import React from 'react';
import {Link} from 'react-router-dom';
import {Trans} from 'react-i18next';
import {MemberLink} from '@ideascale/commons';
import {NotificationType} from 'models/enums/NotificationType';

type IdeaActionNotificationProps = {
    type: NotificationType.IDEA_SUBMITTED | NotificationType.IDEA_MODIFIED |
        NotificationType.IDEA_APPROVED | NotificationType.IDEA_AT_MENTIONED |
        NotificationType.IDEA_FILE_ATTACHED | NotificationType.IDEA_TEAM_MEMBER_JOINED |
        NotificationType.IDEA_TEAM_MEMBER_LEFT | NotificationType.IDEA_TEAM_POSITION_REMOVED |
        NotificationType.COMMENT_POSTED | NotificationType.COMMENT_AT_MENTIONED |
        NotificationType.STAGE_COMMENT_AT_MENTIONED | NotificationType.IDEA_CUSTOM_FIELD_AT_MENTIONED |
        NotificationType.STAGE_CUSTOM_FIELD_AT_MENTIONED;
    memberId: number;
    identityHidden: boolean;
    memberLinkText: string;
    ideaLink: string;
    ideaLinkText: string;
    self?: boolean;
}

export const IdeaActionNotification = (props: IdeaActionNotificationProps) => {
    const {ideaLinkText, memberLinkText, ideaLink, memberId, identityHidden, type, self} = props;

    const renderText = () => {
        switch (type) {
            case NotificationType.IDEA_SUBMITTED:
                return 'notification.idea.submitted';
            case NotificationType.IDEA_MODIFIED:
                return `notification.idea.modified${self ? '.self' : '.others'}`;
            case NotificationType.IDEA_APPROVED:
                return 'notification.idea.approved';
            case NotificationType.IDEA_AT_MENTIONED:
                return 'notification.idea.at-mentioned';
            case NotificationType.IDEA_FILE_ATTACHED:
                return `notification.idea.attachment.added${self ? '.self' : '.others'}`;
            case NotificationType.IDEA_TEAM_POSITION_REMOVED:
                return 'notification.idea.team.position.removed';
            case NotificationType.IDEA_TEAM_MEMBER_LEFT:
                return 'notification.idea.team.member.left';
            case NotificationType.IDEA_TEAM_MEMBER_JOINED:
                return 'notification.idea.team.member.joined';
            case NotificationType.COMMENT_POSTED:
                return `notification.idea.comment.posted${self ? '.self' : '.others'}`;
            case NotificationType.COMMENT_AT_MENTIONED:
            case NotificationType.STAGE_COMMENT_AT_MENTIONED:
                return 'notification.idea.comment.at-mentioned';
            case NotificationType.IDEA_CUSTOM_FIELD_AT_MENTIONED:
                return 'notification.idea.at-mentioned-idea-custom-field';
            case NotificationType.STAGE_CUSTOM_FIELD_AT_MENTIONED:
                return 'notification.idea.at-mentioned-refine-custom-field';
        }
    };

    return (
        <Trans values={{member: memberLinkText, idea: ideaLinkText}}
               i18nKey={renderText()}>
            <MemberLink id={memberId} identityHidden={identityHidden}/>
            <Link to={ideaLink}/>
        </Trans>
    );
};