import React, {Fragment, useEffect} from 'react';
import {animateScroll} from 'react-scroll';
import {useLocation} from 'react-router-dom';
import {BodyTagAttributes} from '@ideascale/commons';
import {PAGE_IDS} from 'constants/AppConstants';
import {useAppContext} from 'contexts/AppContext';
import {PageLayoutContainer} from 'containers/PageLayoutContainer';
import {CampaignHeaderContainer} from 'containers/CampaignHeaderContainer';
import {CampaignSidebarContainer} from 'containers/sidebar/CampaignSidebarContainer';
import {IdeaSubmissionPreviewContainer} from 'containers/IdeaSubmissionPreviewContainer';
import {IdeaDetailsContainer} from 'containers/IdeaDetailsContainer';
import {SidebarSkeleton} from 'components/sidebar/common/SidebarSkeleton';
import {IdeaDetailsTab} from 'models/enums/IdeaDetailsTab';

export const IdeaDetailsPage = () => {
    const {currentCampaign} = useAppContext();
    const {pathname} = useLocation();

    useEffect(() => {
        const pathSplit = pathname.split('/');
        if (!(pathSplit.includes(IdeaDetailsTab.ACTIVITY.toLowerCase()) ||
            pathSplit.includes(IdeaDetailsTab.ATTACHMENTS.toLowerCase()) ||
            pathSplit.includes(IdeaDetailsTab.COMMENTS.toLowerCase()))) {
            animateScroll.scrollToTop();
        }
    }, [pathname]);

    return (
        <Fragment>
            <BodyTagAttributes data-test-element-id={PAGE_IDS.IDEA_DETAILS}/>
            <IdeaSubmissionPreviewContainer displayAsModal/>

            {
                <CampaignHeaderContainer/>
            }
            <PageLayoutContainer className="idea-details-content"
                                 mainContent={<IdeaDetailsContainer/>}
                                 sidebar={
                                     currentCampaign
                                         ? <CampaignSidebarContainer/>
                                         : <div className="sidebar-skeleton"><SidebarSkeleton/></div>
                                 }
            />
        </Fragment>
    );
};