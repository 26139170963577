import {useCallback} from 'react';
import {FieldValues, UseFormSetError} from 'react-hook-form';
import {ErrorReason, Localizer} from '@ideascale/commons';
import {EditModeValidationError} from 'models/edit-mode/EditModeValidationError';

type EditModeFormErrorHandlerProps = {
    localizer: Localizer;
    validationErrors?: EditModeValidationError;
}

export const useEditModeFormErrorHandler = (props: EditModeFormErrorHandlerProps) => {
    const {localizer, validationErrors} = props;

    const validationErrorHandler = useCallback(<TFieldValues extends FieldValues>(setFormValidationError?: UseFormSetError<TFieldValues>) => {
        if (validationErrors) {
            Object.keys(validationErrors.fields).forEach((fieldKey, index) => {
                const errorKey = validationErrors.fields[fieldKey].toLowerCase();
                setFormValidationError?.(
                    fieldKey as any,
                    {type: 'server', message: localizer.msg(`frontend-shared.errors.${errorKey}`)},
                    {shouldFocus: index === 0}
                );
            });
        }
    }, [validationErrors, localizer]);

    const fieldError = useCallback((fieldKey: string) => {
        if (validationErrors) {
            const errorReason = validationErrors.fields[fieldKey];
            return {
                reason: errorReason as ErrorReason,
                message: errorReason ? localizer.msg(`frontend-shared.errors.${errorReason.toLowerCase()}`) : undefined
            };
        }
    }, [localizer, validationErrors]);

    return {validationErrorHandler, fieldError};
};