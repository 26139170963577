import {Fragment, useCallback, useEffect, useRef} from 'react';
import {
    eventDispatcher,
    MembershipTosModal,
    reloadPage,
    SHOW_COMMUNITY_TOS_ACCEPTANCE_PROMPT,
    useToggle
} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {useCommunityService} from 'hooks/useService';

export const MembershipTosContainer = () => {
    const communityService = useCommunityService();
    const localizer = useLocalizer();
    const tosModalOpened = useRef(false);
    const [showMembershipTosModal, toggleMembershipModal] = useToggle(false);

    const fetchCommunityTosContent = useCallback(async (): Promise<string> => {
        if (communityService !== null) {
            return await communityService.fetchCommunityTosContent();
        }
        return '';
    }, [communityService]);

    const submitMembershipTos = useCallback(async (): Promise<void> => {
        if (communityService !== null) {
            return await communityService.submitMembershipTos();
        }
    }, [communityService]);

    useEffect(() => {
        eventDispatcher.addListener(SHOW_COMMUNITY_TOS_ACCEPTANCE_PROMPT, () => {
            tosModalOpened.current = true;
            if (!showMembershipTosModal) {
                toggleMembershipModal(true);
            }
        });

        return () => {
            eventDispatcher.removeListener(SHOW_COMMUNITY_TOS_ACCEPTANCE_PROMPT);
        };
    }, [showMembershipTosModal, toggleMembershipModal]);

    useEffect(() => {
        // Reloading page after TOS modal closes
        if (!showMembershipTosModal && tosModalOpened.current) {
            tosModalOpened.current = false;
            reloadPage();
        }
    }, [showMembershipTosModal]);

    return <Fragment>
        {
            showMembershipTosModal && <MembershipTosModal localizer={localizer}
                                                          open={showMembershipTosModal}
                                                          onSubmitTos={submitMembershipTos}
                                                          fetchTosContent={fetchCommunityTosContent}
                                                          toggle={toggleMembershipModal}/>
        }
    </Fragment>;
};