import React, {Fragment, useCallback, useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {cloneDeep} from 'lodash';
import {IdeascaleSlider} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {
    AlertEvent,
    AlertType,
    buildAlertEventData,
    ClassificationLabel,
    eventDispatcher,
    LayoutUtil,
    Localizer,
    PageTheme,
    useApiErrorResponseHandler,
    useApplicableClassifications
} from '@ideascale/commons';
import {useExpiryMessage} from 'hooks/useExpiryMessage';
import {PageData} from 'containers/landing-page/FeaturedCampaignsContainer';
import {DisplayHeader} from 'components/landing-page/DisplayHeader';
import {FeaturedCampaignsComponentData} from 'models/landing-page/FeaturedCampaignsComponentData';
import {CampaignSlide} from 'models/CampaignSlide';
import {CampaignSubscribeResponse} from 'models/CampaignSubscribeResponse';
import {CampaignFilter} from 'models/enums/landing-page/CampaignFilter';
import {Slider} from 'models/Slider';
import {appLinks} from 'services/AppLinks';
import {CAROUSEL_DEFAULT_SLIDES_TO_SHOW, CAROUSEL_PAGE_SIZE} from 'constants/AppConstants';

type CarouselViewComponentProps = {
    theme: PageTheme;
    localizer: Localizer;
    loading: boolean;
    config: FeaturedCampaignsComponentData;
    editMode?: boolean;
    campaignSliderData: CampaignSlide[];
    setCampaignSliderData: (campaign: CampaignSlide[]) => void;
    lastFetchedPageData: PageData;
    setLastFetchedPageData: (page: PageData) => void;
    unsubscribeToCampaign(id: number): Promise<CampaignSubscribeResponse>;
    subscribeToCampaign(id: number): Promise<CampaignSubscribeResponse>;
    loadCampaigns: (limit: number, page: number) => Promise<Slider>;

}

export const CarouselViewComponent = (props: CarouselViewComponentProps) => {
    const {
        theme,
        localizer,
        loading,
        config,
        editMode = false,
        campaignSliderData,
        setCampaignSliderData,
        lastFetchedPageData,
        setLastFetchedPageData,
        unsubscribeToCampaign,
        subscribeToCampaign,
        loadCampaigns
    } = props;

    const {getClassificationAttribute} = useApplicableClassifications('', []);
    const campaignsPerSlideRef = useRef(CAROUSEL_DEFAULT_SLIDES_TO_SHOW);
    const {showErrorMessage} = useApiErrorResponseHandler({localizer});
    const navigate = useNavigate();
    const expiryMessage = useExpiryMessage();

    const dataBuilder = useCallback(() => {
        let newData = campaignSliderData;
        newData.forEach(data => {
            data.content = data.subtitle;
            data.expiryDate = data.countdownEnabled ? data.expiryDate : '';
            data.ariaLabel = localizer.msg('campaign.details.aria-label', {
                title: data.title,
                status: data.status ? `(${data.status})` : ''
            });
        });
        return newData;
    }, [campaignSliderData, localizer]);

    const visitCampaignPage = (campaignId: number) => {
        const clickedCampaign: CampaignSlide | undefined = campaignSliderData.find(data => data.id === campaignId);
        navigate(clickedCampaign?.defaultStage
            ? appLinks.ideas(String(campaignId), clickedCampaign?.defaultStage.key)
            : appLinks.campaign(String(campaignId))
        );
    };

    const toggleSubscribed = (isSubscribed: boolean, campaignId: number) => {
        let newData = cloneDeep(campaignSliderData);
        newData.forEach(slide => {
            if (slide.id === campaignId) {
                if (slide.subscribed) {
                    unsubscribeToCampaign(campaignId).then((response) => {
                        if (response && response.status) {
                            slide.subscribed = false;
                            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, response.message));
                        }
                    });
                } else {
                    subscribeToCampaign(campaignId).then((response) => {
                        if (response && response.status) {
                            slide.subscribed = true;
                            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, response.message));
                        }
                    });
                }
                slide.subscribed = !isSubscribed;
            }
        });
        setCampaignSliderData(newData);
    };

    const onSlidesChanged = useCallback(async (currentSlide: number) => {
        let oldData = cloneDeep(campaignSliderData);
        if (config.campaignFilter === CampaignFilter.ACTIVE) {
            if (lastFetchedPageData.hasMore && (currentSlide * campaignsPerSlideRef.current >= ((lastFetchedPageData.currentPage + 1) * CAROUSEL_PAGE_SIZE - (2 * campaignsPerSlideRef.current)))) {
                try {
                    const data = await loadCampaigns(CAROUSEL_PAGE_SIZE, lastFetchedPageData.currentPage + 1);
                    setCampaignSliderData([...oldData, ...data.content as CampaignSlide[]]);
                    setLastFetchedPageData({hasMore: data.hasMore, currentPage: data.pageNo});
                } catch (error) {
                    showErrorMessage(error);
                }
            }
        }
    }, [config.campaignFilter, setLastFetchedPageData, campaignSliderData, setCampaignSliderData, loadCampaigns, lastFetchedPageData.currentPage, lastFetchedPageData.hasMore, showErrorMessage]);

    useEffect(() => {
        if (LayoutUtil.isMobileLayout()) {
            campaignsPerSlideRef.current = 1;
        }
    }, []);

    return (
        <Fragment>
            <DisplayHeader theme={theme} title={config.title}/>
            {
                !loading && campaignSliderData.length === 0
                    ?
                    <div className="panel card">
                        <div className="card-body panel-body">
                            <div className="alert alert-warning mb-0 text-center">
                                {localizer.msg('search.campaign.no-results')}
                            </div>
                        </div>
                    </div>
                    :
                    <IdeascaleSlider loading={loading}
                                     ariaLabel={localizer.msg('campaign.carousel')}
                                     autoplay={!editMode}
                                     autoplaySpeed={5000}
                                     infiniteSlides={true}
                                     itemsPerSlide={campaignsPerSlideRef.current}
                                     slides={dataBuilder()}
                                     afterChange={onSlidesChanged}
                                     onItemSubscribeClicked={toggleSubscribed}
                                     onSlideClicked={visitCampaignPage}
                                     onSlideEntered={visitCampaignPage}
                                     svgIconPath={svgIconPath}
                                     statusMessage={localizer.msg('campaign.details.new')}
                                     classificationLabelConfig={{
                                         ClassificationLabel: ClassificationLabel,
                                         getClassificationAttribute
                                     }}
                                     expiryMessages={expiryMessage}/>
            }
        </Fragment>
    );
};


