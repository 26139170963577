import React, {useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {ActionButton, Modal} from '@ideascale/ui';
import {isHtmlInject, Localizer, useHandleFormSubmit} from '@ideascale/commons';
import {Button} from 'models/landing-page/Button';
import {LANDING_PAGE_BUTTON_TEXT_MAX_CHAR_COUNT} from 'constants/AppConstants';

type ButtonsConfigModalProps = {
    open: boolean;
    toggle: () => void;
    buttonConfig: Button;
    updateConfig: (config: Button) => Promise<void>;
    localizer: Localizer;
    onComponentEdited: () => void;
}

export const ButtonsConfigModal = (props: ButtonsConfigModalProps) => {
    const {buttonConfig, updateConfig, localizer, toggle, open, onComponentEdited} = props;
    const {register, formState: {errors, isSubmitting}, handleSubmit, setError} = useForm<Button>();
    const onFormSubmit = useHandleFormSubmit(handleSubmit);
    const [configUpdated, setConfigUpdated] = useState(false);

    const submitConfig: SubmitHandler<Button> = async (formData) => {
        try {
            const newButtonConfig = {...buttonConfig};
            newButtonConfig.text = formData.text.trim();
            await updateConfig(newButtonConfig);
            onComponentEdited();
            toggle();
        } catch (error: any) {
            Object.keys(error['validationErrors']).forEach(fieldName => {
                const localizerErrorKey = `frontend-shared.errors.${error.validationErrors[fieldName].toLowerCase()}`;
                setError(fieldName as keyof Button, {message: localizer.msg(localizerErrorKey)});
            });
        }
    };

    return (
        <Modal isOpen={open} toggle={toggle} modalHeaderId="button-config-modal-header"
               title={localizer.msg('landing-page.components.edit.edit-button-modal-title')} autoFocus={false}>
            <form id={`button-form-${buttonConfig.id}`}
                  onSubmit={onFormSubmit(submitConfig, isSubmitting || !configUpdated)}>
                <div className={`form-group ${errors.text ? 'has-error' : ''}`}>
                    <label htmlFor="text" className="control-label fw-bold">
                        {localizer.msg('landing-page.components.edit.button-text')}
                        <span className="font-size-lg" aria-hidden={true}>*</span>
                    </label>
                    <input type="text" id="text" className="form-control" autoFocus
                           defaultValue={buttonConfig.text}
                           aria-required="true"
                           {...register('text', {
                               onChange: () => setConfigUpdated(prevState => !prevState ? true : prevState),
                               required: localizer.msg('common.errors.required'),
                               validate: value => !isHtmlInject(value) || localizer.msg('common.errors.html'),
                               maxLength: {
                                   value: LANDING_PAGE_BUTTON_TEXT_MAX_CHAR_COUNT,
                                   message: localizer.msg('common.errors.max-length', {count: LANDING_PAGE_BUTTON_TEXT_MAX_CHAR_COUNT}),
                               },
                               setValueAs: value => value.trim()
                           })}/>
                    {
                        errors.text &&
                        <div className="invalid-feedback d-block">
                            {errors.text.message}
                        </div>
                    }
                </div>
                <div className="form-group mt-5 text-end">
                    <button className="btn btn-cancel me-3" type="button" onClick={toggle}
                            data-test-element-id="btn-cancel">
                        {localizer.msg('common.actions.cancel')}
                    </button>
                    <ActionButton type="submit" form={`button-form-${buttonConfig.id}`} loading={isSubmitting}
                                  disabled={!configUpdated} data-test-element-id="btn-submit">
                        {localizer.msg('common.actions.submit')}
                    </ActionButton>
                </div>
            </form>
        </Modal>
    );
};
