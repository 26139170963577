import React from 'react';
import {PageTheme} from '@ideascale/commons';
import 'components/landing-page/DisplayHeader.scss';

type DisplayHeaderProps = {
    title?: string;
    subtitle?: string;
    theme: PageTheme;
    className?: string;
    headingId?: string;
}

export const DisplayHeader = (props: DisplayHeaderProps) => {
    const {theme, title, subtitle, className, headingId} = props;

    switch (theme) {
        case PageTheme.CURIE:
            return (
                <div
                    className={`component-header d-flex flex-column justify-content-between align-items-center pb-5 ${className ? className : ''}`}>
                    <h2 className="title text-center text-wrap text-break" id={headingId}>{title}</h2>
                    {subtitle && <p className="subtitle text-center text-wrap text-break">{subtitle}</p>}
                    <hr className="curie-divider"/>
                </div>
            );
        case PageTheme.ARMSTRONG:
            return (
                <div className={`component-header ${className ? className : ''}`}>
                    <h2 className="title text-center text-wrap text-break" id={headingId}>
                        {title}
                    </h2>
                </div>
            );
        case PageTheme.EASTMAN:
            return (
                <div
                    className={`component-header d-flex flex-column justify-content-between align-items-center pb-3 ${className ? className : ''}`}>
                    <h2 className="title text-center text-wrap text-break" id={headingId}>{title}</h2>
                    {subtitle && <p className="subtitle text-center text-wrap text-break">{subtitle}</p>}
                </div>
            );
        case PageTheme.BERNERS_LEE:
        default:
            return (
                <div className={`component-header pb-3 ${className ? className : ''}`}>
                    <h2 className="title text-wrap text-break" id={headingId}>
                        <strong>{title}</strong>
                    </h2>
                </div>
            );
    }
};