import React, {memo} from 'react';
import {HtmlRenderer as HtmlContentRenderer, HtmlToReactConfig} from '@ideascale/commons';
import {HtmlFormatType} from '@ideascale/commons/dist/components/shared/HtmlRenderer';
import {useCookieConsentConfiguration} from 'hooks/useCookieConsentConfiguration';

type HtmlRendererProps = {
    content: string;
    reactOptions?: HtmlToReactConfig;
    htmlFormatType?: HtmlFormatType;
    WrapperTag?: keyof JSX.IntrinsicElements;
    className?: string;
    includeCookieConsent?: boolean;
    elementId?: string;
}
export const HtmlRenderer = memo((props: HtmlRendererProps) => {
    const {includeCookieConsent = true, ...rest} = props;
    const cookieConsentConfig = useCookieConsentConfiguration();

    return (
        <HtmlContentRenderer
            {...rest}
            cookieConsentConfig={includeCookieConsent ? cookieConsentConfig : undefined}
        />
    );
});