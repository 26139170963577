import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {Controller, FieldValues, useForm} from 'react-hook-form';
import {QueryObserverResult} from 'react-query/types/core/types';
import {isEmpty} from 'lodash';
import {
    AlertEvent,
    AlertType,
    buildAlertEventData,
    eventDispatcher,
    HookFormProvider,
    isHtmlInject,
    useApiErrorResponseHandler,
    useHandleFormSubmit
} from '@ideascale/commons';
import {ActionButton, IdeascaleSelect, InfoTip, Modal, OptionType, ParagraphSkeleton} from '@ideascale/ui';
import {useLocalizer} from 'hooks/useLocalizer';
import {usePrepareCustomFields} from 'hooks/usePrepareCustomFields';
import {useJoinCommunityService} from 'hooks/useJoinCommunityService';
import {useFeatureTourStore} from 'stores/useFeatureTourStore';
import {CommonUtil} from 'utils/CommonUtil';
import {MemberProfileAndFieldValuesHolder} from 'models/MemberProfileAndFieldValuesHolder';
import {CustomFieldRequestValue} from 'models/types/ProfileFieldUpdateRequest';
import {MemberProfileAndFieldRequestParameters} from 'models/MemberProfileAndFieldRequestParameters';
import {ProfileFieldModalStatus} from 'models/ProfileFieldModalStatus';
import {ONBOARDING_PROFILE_QUESTIONS_MAX_NAME_LENGTH} from 'constants/AppConstants';

const FORM_ID = 'join-community-profile-questions-form';

type FormInputs = {
    firstName: string;
    lastName: string;
    username: string;
    timeZone: string
}

type OnboardingProfileQuestionsProps = {
    isOpen: boolean;
    toggle: () => void;
    reFetchProfileFieldModalStatus: () => Promise<QueryObserverResult<ProfileFieldModalStatus, any>>;
}

export const OnboardingProfileQuestions = (props: OnboardingProfileQuestionsProps) => {
    const {
        isOpen,
        toggle,
        reFetchProfileFieldModalStatus
    } = props;
    const localizer = useLocalizer();
    const {renderCustomField, getFieldValues, prepareFieldTypeMapping} = usePrepareCustomFields();
    const {fetchJoinCommunityProfileFields, saveJoinCommunityProfileFields} = useJoinCommunityService();

    const [loading, setLoading] = useState(true);
    const [profileFields, setProfileFields] = useState<MemberProfileAndFieldValuesHolder>(MemberProfileAndFieldValuesHolder.EMPTY);
    const methods = useForm<Partial<FormInputs>>();
    const {handleSubmit, setError, control, setValue, register, formState: {errors, isSubmitting}, setFocus} = methods;
    const onFormSubmit = useHandleFormSubmit(handleSubmit);
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});

    const {setOnboardInProgress} = useFeatureTourStore();

    const onSubmit = async (data: FieldValues) => {
        const fieldValues: CustomFieldRequestValue[] = getFieldValues(data);
        const profileAndFieldRequestParams: MemberProfileAndFieldRequestParameters = {
            firstName: data.firstName,
            lastName: data.lastName,
            username: data.username,
            timezone: data.timeZone,
            fieldValues: fieldValues
        };

        try {
            await saveJoinCommunityProfileFields(profileAndFieldRequestParams);
            await reFetchProfileFieldModalStatus();
            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, localizer.msg('profile.profile-details.profile-fields-answered')));
            setOnboardInProgress(false);
            toggle();
        } catch (error: any) {
            handleErrorResponse(error, {
                setFormError: setError
            });
        }
    };

    const setFocusOnFirstFocusableInput = useCallback((field: any) => {
        CommonUtil.wait().then(() => setFocus(field));
    }, [setFocus]);

    const fetchMemberAndProfileFields = useCallback(async () => {
        try {
            setLoading(true);
            const response = await fetchJoinCommunityProfileFields();
            prepareFieldTypeMapping(response.incompleteFieldValues);
            setProfileFields(response);
            setValue('firstName', response.firstName);
            setValue('lastName', response.lastName);
            setValue('username', response.username);
            setValue('timeZone', response.timeZone);

            setFocusOnFirstFocusableInput(response.firstAndLastNameSetupRequired ? 'firstName' : response.usernameSetupRequired ? 'username' : 'timeZone');
        } catch (e: any) {
            handleErrorResponse(e, {
                setFormError: setError
            });
        } finally {
            setLoading(false);
        }
    }, [fetchJoinCommunityProfileFields, handleErrorResponse, prepareFieldTypeMapping, setError, setFocusOnFirstFocusableInput, setValue]);

    useEffect(() => {
        fetchMemberAndProfileFields().then();
    }, [fetchMemberAndProfileFields]);

    const timeZoneOptions = useMemo(() => {
        const zoneOptions: OptionType[] = profileFields.allTimeZones.map(timeZone => {
            return {value: timeZone, label: timeZone};
        });
        return zoneOptions;
    }, [profileFields.allTimeZones]);

    const renderExtraFields = useCallback(() => {
        return (
            <Fragment>
                <div className="alert alert-light p-2">
                    <strong className="sr-only">Attention:</strong>
                    <span className="font-size-lg" aria-hidden="true">*</span>{localizer.msg('common.required')}
                </div>
                {
                    !isEmpty(profileFields.displayName) &&
                    <div className="form-group w-100">
                        <label className="fw-bold" htmlFor="displayName">
                            {localizer.msg('registration.profile-display-name')} <InfoTip id={'displayNameInfo'}
                                                                                          content={localizer.msg('registration.profile-display-name-info-tip')}/>
                        </label>
                        <input className="form-control" id="displayName" value={profileFields.displayName}
                               disabled={true} aria-required="true"/>
                    </div>
                }
                {
                    profileFields.firstAndLastNameSetupRequired &&
                    <div className="d-md-flex gap-4">
                        <div className={`form-group w-100 ${errors.firstName ? 'has-error' : ''}`}>
                            <label className="fw-bold" htmlFor="firstName">
                                {localizer.msg('registration.profile-first-name')}<span className="font-size-lg"
                                                                                        aria-hidden="true">*</span>
                            </label>
                            <input className="form-control" type="text" id="firstName"
                                   aria-required="true"
                                   defaultValue={profileFields.firstName}
                                   {...register('firstName', {
                                       required: localizer.msg('registration.profile-first-name-error-required'),
                                       validate: (value: any) => !isHtmlInject(value) || localizer.msg('common.errors.html'),
                                       maxLength: {
                                           value: ONBOARDING_PROFILE_QUESTIONS_MAX_NAME_LENGTH,
                                           message: localizer.msg('registration.max-length-exceeded', {length: ONBOARDING_PROFILE_QUESTIONS_MAX_NAME_LENGTH}),
                                       }
                                   })}
                            />
                            {
                                errors.firstName &&
                                <div className="invalid-feedback d-block">
                                    {errors.firstName.message}
                                </div>
                            }
                        </div>
                        <div className={`form-group w-100 ${errors.lastName ? 'has-error' : ''}`}>
                            <label className="fw-bold" htmlFor="lastName">
                                {localizer.msg('registration.profile-last-name')}<span className="font-size-lg"
                                                                                       aria-hidden="true">*</span>
                            </label>
                            <input className="form-control" type="text" id="lastName"
                                   defaultValue={profileFields.lastName}
                                   aria-required="true"
                                   {...register('lastName', {
                                       required: localizer.msg('registration.profile-last-name-error-required'),
                                       validate: (value: any) => !isHtmlInject(value) || localizer.msg('common.errors.html'),
                                       maxLength: {
                                           value: ONBOARDING_PROFILE_QUESTIONS_MAX_NAME_LENGTH,
                                           message: localizer.msg('registration.max-length-exceeded', {length: ONBOARDING_PROFILE_QUESTIONS_MAX_NAME_LENGTH}),
                                       }
                                   })}
                            />
                            {
                                errors.lastName &&
                                <div className="invalid-feedback d-block">
                                    {errors.lastName.message}
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    profileFields.usernameSetupRequired &&
                    <div className={`form-group ${errors.username ? 'has-error' : ''}`}>
                        <label className="fw-bold" htmlFor="username">
                            {localizer.msg('registration.your-user-name')}<span className="font-size-lg"
                                                                                aria-hidden="true">*</span>
                        </label>
                        <input className="form-control" type="text" id="username"
                               defaultValue={profileFields.username}
                               aria-required="true"
                               {...register('username', {
                                   required: localizer.msg('registration.your-user-name-error-required'),
                                   validate: (value: any) => !isHtmlInject(value) || localizer.msg('common.errors.html'),
                               })}
                        />
                        <div
                            className="text-muted font-size-sm mt-1">{localizer.msg('registration.your-user-name-help-note')}</div>
                        {
                            errors.username &&
                            <div className="invalid-feedback d-block">
                                {errors.username.message}
                            </div>
                        }
                    </div>
                }
                <div className={`form-group`}>
                    <label className="fw-bold" htmlFor="time-zone">
                        {localizer.msg('common.select-time-zone')}
                    </label>
                    <Controller
                        control={control} name="timeZone"
                        render={({field}) =>
                            <IdeascaleSelect
                                ariaLabel={localizer.msg('common.select-time-zone')}
                                name={field.name}
                                isMulti={false}
                                inputId="time-zone"
                                ref={field.ref}
                                defaultValue={{
                                    value: profileFields.timeZone,
                                    label: profileFields.timeZone
                                } as OptionType}
                                options={timeZoneOptions}
                                onChange={(selectedOption: any) => {
                                    field.onChange(selectedOption.value);
                                }}
                            />
                        }/>
                    {
                        errors.timeZone &&
                        <div className="invalid-feedback d-block">
                            {errors.timeZone.message}
                        </div>
                    }
                </div>
            </Fragment>
        );
    }, [localizer, profileFields.displayName, profileFields.firstName, profileFields.lastName, profileFields.usernameSetupRequired, profileFields.username, profileFields.timeZone, errors.firstName, errors.lastName, errors.username, errors.timeZone, register, control, timeZoneOptions]);

    return (
        <Modal className="modal-lg" isOpen={isOpen} toggle={undefined} autoFocus={false}
               title={localizer.msg('profile.profile-details.edit.heading')}
               modalHeaderId="profile-questions-header"
               aria-labelledby="profile-questions-header"
               modalBodyClassName="my-2">
            <HookFormProvider {...methods}>
                <form id={FORM_ID} onSubmit={onFormSubmit(onSubmit, isSubmitting)}
                      aria-labelledby="profile-questions-header">
                    {
                        loading
                            ? <Fragment>
                                <div className="my-3"><ParagraphSkeleton rows={3}/></div>
                                <div className="my-3"><ParagraphSkeleton rows={3}/></div>
                                <div className="my-3"><ParagraphSkeleton rows={3}/></div>
                            </Fragment>
                            : (
                                <>
                                    {
                                        renderExtraFields()
                                    }

                                    {
                                        profileFields.incompleteFieldValues.length > 0
                                            ? profileFields.incompleteFieldValues.map((field) => renderCustomField(field))
                                            : null
                                    }
                                </>
                            )
                    }
                    <div className="d-flex flex-row justify-content-end">
                        <ActionButton className="ms-3" type="submit" form={FORM_ID} loading={isSubmitting}>
                            {localizer.msg('common.submit')}
                        </ActionButton>
                    </div>
                </form>
            </HookFormProvider>
        </Modal>
    );
};
