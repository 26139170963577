import React, {useCallback, useState} from 'react';
import range from 'lodash/range';
import {HtmlConverter, Skeleton} from '@ideascale/ui';
import {useToggle} from '@ideascale/commons';
import {SidebarWidgetEditModal} from './SidebarWidgetEditModal';
import {SidebarDragAndDropElements} from './SidebarDragAndDropElements';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useRouteUtils} from 'hooks/useRouteUtils';
import {useSidebarEditHelper} from 'hooks/useSidebarEditHelper';
import {ReorderCampaignSidebarCommand} from 'commands/edit-mode/ReorderCampaignSidebarCommand';
import {UpdateCampaignWidgetOneCommand} from 'commands/edit-mode/UpdateCampaignWidgetOneCommand';
import {UpdateCampaignWidgetTwoCommand} from 'commands/edit-mode/UpdateCampaignWidgetTwoCommand';
import {UpdateCampaignWidgetThreeCommand} from 'commands/edit-mode/UpdateCampaignWidgetThreeCommand';
import {SidebarItem} from 'models/sidebar/SidebarItem';
import {SidebarItemKey} from 'models/enums/SidebarItemKey';
import {WidgetField} from 'models/edit-mode/WidgetField';
import {CampaignEditableFieldType} from 'models/edit-mode/CampaignEditableFieldType';
import {SidebarCustomWidgetInput} from 'models/edit-mode/SidebarCustomWidgetInput';

const CAMPAIGN_WIDGET_EDIT_MAPPING: Record<string, string> = {
    [SidebarItemKey.CAMPAIGN_CUSTOM_WIDGET_ONE]: CampaignEditableFieldType.CAMPAIGN_WIDGET_ONE,
    [SidebarItemKey.CAMPAIGN_CUSTOM_WIDGET_TWO]: CampaignEditableFieldType.CAMPAIGN_WIDGET_TWO,
    [SidebarItemKey.CAMPAIGN_CUSTOM_WIDGET_THREE]: CampaignEditableFieldType.CAMPAIGN_WIDGET_THREE
};

export const CampaignSidebarEdit = () => {
    const localizer = useLocalizer();
    const {homeConfig, campaignHomeEditor, commandExecutor, validationErrors} = useEditModeContext();
    const {campaignRouteMatch} = useRouteUtils();
    const [campaignWidget, setCampaignWidget] = useState<WidgetField>(WidgetField.EMPTY);
    const [campaignWidgetEditModalOpen, toggleCampaignWidgetEditModalOpen] = useToggle(false);

    const {
        sidebarItems,
        dataFetching,
        onDragEnd,
        onVisibilityButtonClick
    } = useSidebarEditHelper(
        homeConfig.sidebarItems,
        (newItems: SidebarItem[]) => {
            const campaignId = (campaignRouteMatch?.params as any)?.campaignId;
            const command = new ReorderCampaignSidebarCommand(campaignHomeEditor, newItems, campaignId);
            commandExecutor.execute(command);
            validationErrors.clearError(command.getType());
        }
    );

    const onEditItemClicked = useCallback((sidebarItem: SidebarItem) => {
        const CAMPAIGN_WIDGETS_KEYS = [
            SidebarItemKey.CAMPAIGN_CUSTOM_WIDGET_ONE,
            SidebarItemKey.CAMPAIGN_CUSTOM_WIDGET_TWO,
            SidebarItemKey.CAMPAIGN_CUSTOM_WIDGET_THREE
        ];
        if (CAMPAIGN_WIDGETS_KEYS.includes(sidebarItem.key as SidebarItemKey)) {
            const targetCampaignWidget = CAMPAIGN_WIDGET_EDIT_MAPPING[sidebarItem?.key as string];
            setCampaignWidget(homeConfig.getWidgetField(targetCampaignWidget as CampaignEditableFieldType));
            toggleCampaignWidgetEditModalOpen(true);
        }
    }, [toggleCampaignWidgetEditModalOpen, homeConfig]);

    const onSubmitCampaignWidgetChanges = useCallback(async (values: SidebarCustomWidgetInput) => {
        let command: UpdateCampaignWidgetOneCommand | UpdateCampaignWidgetTwoCommand | UpdateCampaignWidgetThreeCommand | null = null;
        const newWidgetField: WidgetField = {
            name: campaignWidget.name,
            title: values.title,
            content: HtmlConverter.toServerHtmlFormat(values.content),
            key: campaignWidget.key
        };
        const campaignId = (campaignRouteMatch?.params as any)?.campaignId;

        if (campaignWidget.name === CampaignEditableFieldType.CAMPAIGN_WIDGET_ONE) {
            command = new UpdateCampaignWidgetOneCommand(campaignHomeEditor, newWidgetField, campaignId);
        } else if (campaignWidget.name === CampaignEditableFieldType.CAMPAIGN_WIDGET_TWO) {
            command = new UpdateCampaignWidgetTwoCommand(campaignHomeEditor, newWidgetField, campaignId);
        } else if (campaignWidget.name === CampaignEditableFieldType.CAMPAIGN_WIDGET_THREE) {
            command = new UpdateCampaignWidgetThreeCommand(campaignHomeEditor, newWidgetField, campaignId);
        }

        if (command) {
            commandExecutor.execute(command);
            validationErrors.clearError(command.getType());
        }
        toggleCampaignWidgetEditModalOpen();
    }, [campaignRouteMatch?.params, campaignHomeEditor, campaignWidget.key, campaignWidget.name, commandExecutor, toggleCampaignWidgetEditModalOpen, validationErrors]);

    return (
        <>
            {
                dataFetching
                    ? (
                        <div className="sidebar-skeleton-wrapper">
                            {
                                range(1, 12).map(index => (
                                    <div key={index}
                                         className="mb-2 d-flex justify-content-center align-items-center"
                                         style={{height: '50px'}}>
                                        <Skeleton height={'25px'} width={'70%'} cssClass={'me-2'}/>
                                        <Skeleton height={'25px'} width={'10%'} cssClass={'me-2'}/>
                                        <Skeleton height={'25px'} width={'10%'}/>
                                    </div>
                                ))
                            }
                        </div>
                    )
                    : (
                        <div className="sidebar-edit-mode">
                            <SidebarDragAndDropElements
                                sidebarItems={sidebarItems}
                                onDragEnd={onDragEnd}
                                localizer={localizer}
                                onVisibilityButtonClick={onVisibilityButtonClick}
                                onEditButtonClick={onEditItemClicked}
                            />
                            {
                                campaignWidgetEditModalOpen &&
                                <SidebarWidgetEditModal
                                    isOpen={campaignWidgetEditModalOpen}
                                    toggle={toggleCampaignWidgetEditModalOpen}
                                    widgetField={campaignWidget}
                                    onSubmitWidgetChanges={onSubmitCampaignWidgetChanges}
                                    placeholder={localizer.msg('sidebar.custom.campaign-tile')}
                                />
                            }
                        </div>
                    )
            }
        </>
    );
};