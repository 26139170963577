import React, {Fragment, useEffect, useState} from 'react';
import {ParagraphSkeleton} from '@ideascale/ui';
import {useParams} from 'react-router-dom';
import {useLocalizer} from 'hooks/useLocalizer';
import {WorkFlowChart} from './WorkFlowChart';
import {CampaignWorkFlowData} from 'models/CampaignWorkFlowData';
import {IdeaListRouteMatchParams} from 'models/types/IdeaListRouteMatchParams';
import './CampaignWorkflowChart.scss';

type WorkFlowChartProps = {
    fetchCampaignWorkflow: (campaignId: number) => Promise<CampaignWorkFlowData>
}

export const CampaignWorkFlowChart = (props: WorkFlowChartProps) => {
    const {fetchCampaignWorkflow} = props;
    const params = useParams<IdeaListRouteMatchParams>();
    const [loading, setLoading] = useState(true);
    const [workflow, setWorkflow] = useState<CampaignWorkFlowData>(CampaignWorkFlowData.EMPTY);
    const localizer = useLocalizer();

    useEffect(() => {
        fetchCampaignWorkflow(+params?.campaignId!).then(data => {
            setWorkflow(data);
            setLoading(false);
        });
    }, [fetchCampaignWorkflow, params.campaignId]);

    const getWorkflowChartItems = () => {
        return workflow.timeline.map(item => ({
            id: item.id,
            completed: item.expired,
            date: item.expireAt,
            hoverHeading: false,
            visited: false,
            current: false,
            heading: item.label,
        }));
    };

    return (
        <Fragment>
            {
                workflow.timeline &&
                <div className="workflow-chart campaign-workflow-chart">
                    <div className="card panel mb-4">
                        <div className="card-body">
                            {
                                loading ? <ParagraphSkeleton rows={6}/> :
                                    <>
                                        <h2 className="h5 mb-4">{localizer.msg('sidebar.campaign.campaign-timeline')}</h2>
                                        <WorkFlowChart workflowItems={getWorkflowChartItems()}/>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    );
};
