import React, {Fragment, useCallback} from 'react';
import {Campaign, Member} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useCommunityService} from 'hooks/useService';
import {useProfilePageParams} from 'hooks/useProfilePageParams';
import {useLocalizer} from 'hooks/useLocalizer';
import {FollowedCampaigns} from 'components/FollowedCampaigns';
import {FollowedMembers} from 'components/FollowedMembers';
import {FollowedIdeas} from 'components/FollowedIdeas';
import {FollowedTags} from 'components/FollowedTags';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {GenericResponse} from 'models/GenericResponse';
import {CampaignGroup} from 'models/CampaignGroup';
import {CampaignSubscribeResponse} from 'models/CampaignSubscribeResponse';
import {FollowedIdea} from 'models/types/FollowedIdea';
import 'components/FollowedSections.scss';

type ProfileFollowContainerProps = {
    expanded: boolean
}

export const ProfileFollowContainer = (props: ProfileFollowContainerProps) => {
    const {expanded} = props;
    const communityService = useCommunityService();
    const localizer = useLocalizer();
    const memberId = useProfilePageParams();
    const {authentication: {actor}} = useAppContext();

    const fetchFollowedMembers = useCallback(async (pageParameters: PageParameters): Promise<PagedResponseContent<Member>> => {
        if (communityService) {
            return await communityService.fetchFollowedMembers(pageParameters, memberId);
        }
        return PagedResponseContent.nullObject();
    }, [communityService, memberId]);

    const unfollowMember = useCallback(async (memberId: number) => {
        if (communityService) {
            return communityService.unfollowMember(memberId);
        }
        return GenericResponse.EMPTY;
    }, [communityService]);

    const followMember = useCallback(async (memberId: number) => {
        if (communityService) {
            return communityService.followMember(memberId);
        }
        return GenericResponse.EMPTY;
    }, [communityService]);

    const fetchFollowedIdeas = useCallback(async (pageParameters: PageParameters): Promise<PagedResponseContent<FollowedIdea>> => {
        if (communityService) {
            return communityService.fetchFollowedIdeas(pageParameters, memberId);
        }
        return PagedResponseContent.nullObject();
    }, [communityService, memberId]);

    const unfollowIdea = useCallback(async (ideaId: number) => {
        if (communityService) {
            return communityService.unfollowIdea(ideaId);
        }
        return GenericResponse.EMPTY;
    }, [communityService]);

    const followIdea = useCallback(async (ideaId: number) => {
        if (communityService) {
            return communityService.followIdea(ideaId);
        }
        return GenericResponse.EMPTY;
    }, [communityService]);

    const fetchFollowedTags = useCallback(async (pageParameters: PageParameters): Promise<PagedResponseContent<string>> => {
        if (communityService) {
            return communityService.fetchFollowedTags(pageParameters, memberId);
        }
        return PagedResponseContent.nullObject();
    }, [communityService, memberId]);

    const unfollowTag = useCallback(async (tagName: string) => {
        if (communityService) {
            return communityService.unfollowTag(tagName);
        }
        return GenericResponse.EMPTY;
    }, [communityService]);

    const followTag = useCallback(async (tagName: string) => {
        if (communityService) {
            return communityService.followTag(tagName);
        }
        return GenericResponse.EMPTY;
    }, [communityService]);

    const fetchFollowedCampaignGroups = useCallback(async (pageParameters: PageParameters): Promise<PagedResponseContent<CampaignGroup>> => {
        if (communityService) {
            return communityService.fetchFollowedCampaignGroups(pageParameters, memberId);
        }
        return PagedResponseContent.nullObject();
    }, [communityService, memberId]);

    const fetchFollowedCampaignsByGroupId = useCallback(async (pageParameters: PageParameters, groupId: number): Promise<PagedResponseContent<Campaign>> => {
        if (communityService) {
            return communityService.fetchFollowedCampaignByGroupId(pageParameters, memberId, groupId);
        }
        return PagedResponseContent.nullObject();
    }, [communityService, memberId]);

    const unfollowCampaign = useCallback(async (campaignId: number) => {
        if (communityService) {
            return communityService.unsubscribeCampaign(campaignId);
        }
        return CampaignSubscribeResponse.EMPTY;
    }, [communityService]);

    const followCampaign = useCallback(async (campaignId: number) => {
        if (communityService) {
            return communityService.subscribeCampaign(campaignId);
        }
        return CampaignSubscribeResponse.EMPTY;
    }, [communityService]);

    return (
        <Fragment>
            <FollowedCampaigns localizer={localizer} expanded={expanded}
                               fetchCampaignGroups={fetchFollowedCampaignGroups}
                               fetchCampaignsByGroup={fetchFollowedCampaignsByGroupId}
                               unsubscribeCampaign={unfollowCampaign}
                               subscribeCampaign={followCampaign} actionable={actor.id === memberId}/>
            <FollowedMembers localizer={localizer} expanded={expanded} fetchFollowedMembers={fetchFollowedMembers}
                             unfollowMember={unfollowMember} followMember={followMember}
                             actionable={actor.id === memberId}/>
            <FollowedIdeas localizer={localizer} expanded={expanded} fetchFollowedIdeas={fetchFollowedIdeas}
                           unfollowIdea={unfollowIdea} followIdea={followIdea} actionable={actor.id === memberId}/>
            <FollowedTags localizer={localizer} expanded={expanded} fetchFollowedTags={fetchFollowedTags}
                          unfollowTag={unfollowTag} followTag={followTag} actionable={actor.id === memberId}/>
        </Fragment>
    );
};