import React, {useEffect, useRef} from 'react';
import {Controller, useWatch} from 'react-hook-form';
import {
    IDEA_DESCRIPTION_VALUE_ON_BLUR,
    IdeaFieldData,
    IdeaFormFieldKeys,
    Localizer,
    useFormValidationRules,
    useHookFormContext
} from '@ideascale/commons';
import {HtmlConverter} from '@ideascale/ui';
import {useAppContext} from 'contexts/AppContext';
import {LinkIdeas, LinkIdeasHandle} from 'components/idea-submission/LinkIdeas';
import {PageParameters} from 'models/types/PageParameters';
import {LinkableIdea} from 'models/LinkableIdea';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {IDEA_TITLE_VALUE_ON_BLUR} from './TitleField';

type LinkIdeasFieldProps = {
    localizer: Localizer;
    ideaId: number;
    ideaField: IdeaFieldData;
    fetchLinkableIdeas: (ideaId: number, ideaTitle: string, ideaDescription: string, pageParameters: PageParameters) => Promise<PagedResponseContent<LinkableIdea>>;
    fetchLinkableIdeasByKeywords: (ideaId: number, pageParameters: PageParameters) => Promise<PagedResponseContent<LinkableIdea>>;
    autoFocus?: boolean;
}

export const LinkIdeasField = (props: LinkIdeasFieldProps) => {
    const {ideaField, localizer, ideaId, fetchLinkableIdeas, fetchLinkableIdeasByKeywords, autoFocus} = props;

    const {communityConfig: {linkQualifiers}} = useAppContext();
    const validationRules = useFormValidationRules(ideaField.validationRules || []);
    const {control, getValues, setValue} = useHookFormContext();

    const ideaTitle = useWatch({name: IDEA_TITLE_VALUE_ON_BLUR, control});
    const ideaDescription = useWatch({name: IDEA_DESCRIPTION_VALUE_ON_BLUR, control});
    const linkIdeasHandle = useRef<LinkIdeasHandle | null>(null);

    useEffect(() => {
        setValue(IDEA_TITLE_VALUE_ON_BLUR, getValues(IdeaFormFieldKeys.TITLE));
        setValue(IDEA_DESCRIPTION_VALUE_ON_BLUR, getValues(IdeaFormFieldKeys.DESCRIPTION));
    }, [getValues, setValue]);

    useEffect(() => {
        if (autoFocus) {
            setTimeout(() => linkIdeasHandle.current?.focus());
        }
    }, [autoFocus]);

    return (
        <Controller
            name={ideaField.key}
            control={control}
            rules={validationRules}
            defaultValue={ideaField.linkedIdeas || []}
            render={({field: {onChange, value, name, ref}}) =>
                <LinkIdeas
                    key={name}
                    localizer={localizer}
                    ideaId={ideaId}
                    ideaTitle={ideaTitle}
                    ideaDescription={ideaDescription ? HtmlConverter.getPlainText(ideaDescription) : ''}
                    linkQualifiers={linkQualifiers}
                    fetchLinkableIdeas={fetchLinkableIdeas}
                    values={value}
                    onChange={onChange}
                    ref={(handle) => {
                        ref(handle);
                        linkIdeasHandle.current = handle;
                    }}
                    fetchLinkableIdeasByKeywords={fetchLinkableIdeasByKeywords}/>
            }
        />
    );
};