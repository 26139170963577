import {Fragment, useCallback} from 'react';
import {Localizer} from '@ideascale/commons';
import {DraftActionItems} from 'components/topbar/action-items/DraftActionItems';
import {ModeratorActionItems} from 'components/topbar/action-items/ModeratorActionItems';
import {ProfileActionItems} from 'components/topbar/action-items/ProfileActionItems';
import {StageActionItems} from 'components/topbar/action-items/StageActionItems';
import {InnovationAssistantActionItems} from 'components/topbar/action-items/InnovationAssistantActionItems';
import {GroupedActionItem} from 'models/topbar-action-item/GroupedActionItem';
import {ActionItemGroupType} from 'models/topbar-action-item/ActionItemGroupType';

type ActionItemProps = {
    groupedActionItem: GroupedActionItem;
    toggleProfileQuestionsModal: () => void;
    toggleVerifiableFieldsOpen: () => void;
    localizer: Localizer;
}

export const ActionItem = (props: ActionItemProps) => {
    const {groupedActionItem, toggleProfileQuestionsModal, toggleVerifiableFieldsOpen, localizer} = props;

    const renderActionItem = useCallback(() => {
        switch (groupedActionItem?.group) {
            case ActionItemGroupType.DRAFT:
                return <DraftActionItems groupedActionItem={groupedActionItem} localizer={localizer}/>;
            case ActionItemGroupType.MODERATOR:
                return <ModeratorActionItems groupedActionItem={groupedActionItem}/>;
            case ActionItemGroupType.PROFILE:
                return <ProfileActionItems groupedActionItem={groupedActionItem}
                                           toggleProfileQuestionsModal={toggleProfileQuestionsModal}
                                           toggleVerifiableFieldsOpen={toggleVerifiableFieldsOpen}/>;
            case ActionItemGroupType.STAGE:
                return <StageActionItems groupedActionItem={groupedActionItem}/>;
            case ActionItemGroupType.INNOVATION_ASSISTANT:
                return <InnovationAssistantActionItems groupedActionItem={groupedActionItem}/>;
            default:
                return <Fragment/>;
        }
    }, [groupedActionItem, localizer, toggleProfileQuestionsModal, toggleVerifiableFieldsOpen]);

    return renderActionItem();
};