import React, {Dispatch, Fragment, SetStateAction} from 'react';
import {Checkbox} from '@ideascale/ui';
import {Localizer} from '@ideascale/commons';
import {BannerField} from 'models/edit-mode/BannerField';

export type BannerImagePreviewProps = {
    localizer: Localizer;
    bannerImage: BannerField;
    setBannerImage: Dispatch<SetStateAction<BannerField>>;
    checkedBannerGradient: boolean,
    setCheckedBannerGradient: Dispatch<SetStateAction<boolean>>;
    checkedBlackPageTitle: boolean,
    setCheckedBlackPageTitle: Dispatch<SetStateAction<boolean>>;
}

export const BannerImagePreview = (props: BannerImagePreviewProps) => {
    const {localizer, bannerImage, setBannerImage, checkedBannerGradient, setCheckedBannerGradient, checkedBlackPageTitle, setCheckedBlackPageTitle} = props;

    return (
        <Fragment>
            {
                bannerImage?.url?.length > 0 && (
                    <Fragment>
                        <div className="banner-preview w-100 mt-5">
                            <h6 className="my-3 preview-title">{localizer.msg('edit-mode.advanced-settings.banner-image.banner-preview-title')}</h6>
                            <div className="banner-preview-container">
                                <div className={`image-edit-container ${checkedBannerGradient ? 'active' : ''}`}>
                                    <img className="preview rounded w-100 mt-0" src={bannerImage.url} alt={bannerImage.altText} height={120}/>
                                    <div className="overlay d-none"/>
                                </div>
                                <p className={`preview-info fw-semibold ${checkedBlackPageTitle ? 'text-ideascale-black' : 'text-white'}`}>{localizer.msg('edit-mode.advanced-settings.banner-image.page-title-preview-info')}</p>
                            </div>
                        </div>
                        <div className="form-content mt-4">
                            <div className="form-group">
                                <Checkbox
                                    className="fw-normal"
                                    inputId="use-banner-gradient"
                                    checked={checkedBannerGradient}
                                    label={localizer.msg('edit-mode.advanced-settings.banner-image.use-banner-gradient')}
                                    onChange={event => {
                                        setCheckedBannerGradient(event.target.checked);
                                        setBannerImage(prevImage => {
                                            if (prevImage) {
                                                return {...prevImage, gradientEnabled: event.target.checked, blackPageTitleEnabled: checkedBlackPageTitle};
                                            }
                                            return prevImage;
                                        });
                                    }}
                                />
                            </div>

                            <div className="form-group">
                                <Checkbox
                                    className="fw-normal"
                                    inputId="show-page-title"
                                    checked={checkedBlackPageTitle}
                                    label={localizer.msg('edit-mode.advanced-settings.banner-image.show-page-title-black')}
                                    onChange={event => {
                                        setCheckedBlackPageTitle(event.target.checked);
                                        setBannerImage(prevImage => {
                                            if (prevImage) {
                                                return {...prevImage, blackPageTitleEnabled: event.target.checked};
                                            }
                                            return prevImage;
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </Fragment>
                )
            }
        </Fragment>
    );
};