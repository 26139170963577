import React, {Dispatch, Fragment} from 'react';
import {Button, Icon} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {isHtmlInject, isJavascriptInject, Localizer} from '@ideascale/commons';
import './FileUploadPreview.scss';

type FileUploadPreviewProps = {
    localizer: Localizer;
    showAltText: boolean;
    previewMode?: 'full' | 'medium' | 'small';
    previewImage: string;
    altText: string;
    setAltText: Dispatch<React.SetStateAction<string>>;
    altTextError: string | null;
    setAltTextError: Dispatch<React.SetStateAction<string | null>>;
    onPreviewRemoved: () => void;
    onAltTextChanged: (text?: string) => void;
}

export const FileUploadPreview = (props: FileUploadPreviewProps) => {
    const {
        localizer,
        showAltText,
        previewMode = 'small',
        previewImage,
        altTextError,
        setAltTextError,
        altText,
        setAltText,
        onPreviewRemoved,
        onAltTextChanged
    } = props;

    return (
        <Fragment>
            <div className="w-100 py-2 mb-md-0 position-relative">
                <img className={`preview rounded ${previewMode}`} src={previewImage} alt={altText}/>
                <Button className="remove-image" aria-label={localizer.msg('common.actions.delete')} type={'button'}
                        onClick={onPreviewRemoved}>
                    <Icon iconSpritePath={svgIconPath} name="trash-can-alt" width={18} height={18}/>
                </Button>
            </div>
            {
                showAltText && (
                    <div className={`form-group my-3 ${altTextError ? 'has-error' : ''}`}>
                        <label className="control-label fw-bold my-1"
                               htmlFor={`altText`}>
                            {localizer.msg('frontend-shared.fields.attachment.alt-text')}
                        </label>
                        <input className="form-control"
                               type="text"
                               id={`altText`}
                               value={altText}
                               placeholder={localizer.msg('edit-mode.alt-text')}
                               onChange={(event) => {
                                   setAltText(event.target.value);
                                   setAltTextError(null);
                                   if (isHtmlInject(event.target.value)) {
                                       setAltTextError(localizer.msg('common.errors.html'));
                                   } else if (isJavascriptInject(event.target.value)) {
                                       setAltTextError(localizer.msg('common.errors.js'));
                                   }
                                   onAltTextChanged(event.target.value);
                               }}
                               maxLength={255}
                        />
                        {
                            altTextError && altTextError.length > 0 &&
                            <div className="invalid-feedback d-block">
                                {altTextError}
                            </div>
                        }
                    </div>
                )
            }
        </Fragment>
    );
};