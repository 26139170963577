import React, {Fragment, useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useQuery} from 'react-query';
import {ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Icon, ParagraphSkeleton} from '@ideascale/ui';
import {
    Backdrop,
    DropdownSubMenu,
    DropdownSubmenuItem,
    Localizer,
    useDropdownFocus,
    useToggle
} from '@ideascale/commons';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {appLinks} from 'services/AppLinks';
import {useIdeaDetailsContext} from 'contexts/IdeaDetailsContext';
import {IdeaModerateActionResponse} from 'models/IdeaModerateActionResponse';
import {Stage} from 'models/types/Stage';
import {IdeaSummary} from 'models/IdeaSummary';
import {IdeaDetail} from 'models/IdeaDetail';
import {StageFunctionName} from 'models/enums/StageFunctionName';
import {BasicStagesId} from 'models/enums/BasicStagesId';
import {DEFAULT_STALE_TIME, QUERY_KEYS} from 'constants/AppConstants';
import './IdeaActions.scss';

type IdeaModerateActionsProps = {
    localizer: Localizer;
    selectedIdea: IdeaSummary
    moderationPermissions?: IdeaModerateActionResponse;
    fetchModerateActions?: (ideaId: number) => Promise<IdeaModerateActionResponse>;
    toggleCommentEnabled: () => void;
    toggleChangeCampaignModal: () => void;
    togglePinToLandingPage: (pinned: boolean) => void
    toggleEditIdeaModal?: () => void;
    onIdeaStageChange: (ideaId: number, stageId: number) => Promise<IdeaDetail | undefined> | void;
    onIdeaDelete: (ideaId: number) => void;
}

const getStageIconClassName = (stageId: number) => {
    switch (stageId) {
        case 99:
        case 98:
        case 5:
        case 1:
            return `stage-dot-${stageId}`;
        default:
            return '';
    }
};

export const IdeaModerateActions = (props: IdeaModerateActionsProps) => {
    const {
        localizer,
        selectedIdea,
        moderationPermissions,
        fetchModerateActions,
        toggleCommentEnabled,
        toggleEditIdeaModal,
        toggleChangeCampaignModal,
        togglePinToLandingPage,
        onIdeaStageChange,
        onIdeaDelete,
    } = props;

    const [dropdownOpen, toggleDropdown] = useToggle(false);
    const navigate = useNavigate();
    const {refetch: refetchIdeaDetails} = useIdeaDetailsContext();
    const {focus} = useDropdownFocus();

    const menuFocusId = useMemo(() => (`idea-${selectedIdea.id}-moderate-menu`), [selectedIdea.id]);

    const {data, isLoading, isRefetching, isFetching} = useQuery(
        [QUERY_KEYS.MODERATE_ACTIONS, selectedIdea.id],
        ({queryKey: [, ideaId]}) => fetchModerateActions && fetchModerateActions(Number(ideaId)),
        {
            enabled: !moderationPermissions && dropdownOpen,
            staleTime: DEFAULT_STALE_TIME,
            onSuccess: useCallback(() => focus('#' + menuFocusId, 10), [focus, menuFocusId])
        });

    const moderateActionData = moderationPermissions || data || IdeaModerateActionResponse.EMPTY;

    const onChangeIdeaStage = useCallback(async (ideaId: number, stageId: number) => {
        toggleDropdown();
        const response = await onIdeaStageChange(ideaId, stageId);
        if (response) {
            navigate({
                pathname: appLinks.ideaDetails(ideaId)
            });
            refetchIdeaDetails().then();
        }
    }, [navigate, onIdeaStageChange, refetchIdeaDetails, toggleDropdown]);

    const onDeleteIdea = useCallback((ideaId: number) => {
        toggleDropdown();
        if (selectedIdea.stageSummary.stage.name.toLowerCase() === StageFunctionName.RECYCLE_BIN) {
            onIdeaDelete(ideaId);
        } else {
            onChangeIdeaStage(selectedIdea.id, BasicStagesId.RECYCLE_BIN).then();
        }
    }, [onChangeIdeaStage, onIdeaDelete, selectedIdea.id, selectedIdea.stageSummary.stage.name, toggleDropdown]);

    const renderStageLists = useCallback((stages: Stage[]) => {
        if (stages && stages.length > 0) {
            return (
                <Fragment>
                    {
                        stages.map((stage, index) => (
                            <DropdownSubmenuItem
                                title={localizer.msg('idea.actions.stage-title', {label: stage.label})}
                                className={`${(selectedIdea.stageSummary.stage.id === stage.id) ? 'active' : ''}`}
                                key={index}
                                onClick={() => onChangeIdeaStage(selectedIdea.id, stage.id)}>
                                <span className={`icon-circle me-2 ${getStageIconClassName(stage.id)}`}/>
                                {stage.label}
                            </DropdownSubmenuItem>
                        ))
                    }
                </Fragment>
            );
        }
        return <Fragment/>;
    }, [onChangeIdeaStage, localizer, selectedIdea.id, selectedIdea.stageSummary.stage.id]);

    const hasActions = useCallback(() => {
        return (moderateActionData.approveIdeaEnabled ||
            moderateActionData.rejectIdeaEnabled ||
            moderateActionData.stageChangeEnabled ||
            moderateActionData.editIdeaEnabled ||
            moderateActionData.pinnedIdeaEnabled ||
            moderateActionData.deleteIdeaEnabled ||
            moderateActionData.commentingToggleEnabled ||
            moderateActionData.campaignChangeEnabled);
    }, [moderateActionData.approveIdeaEnabled,
        moderateActionData.rejectIdeaEnabled,
        moderateActionData.stageChangeEnabled,
        moderateActionData.editIdeaEnabled,
        moderateActionData.pinnedIdeaEnabled,
        moderateActionData.deleteIdeaEnabled,
        moderateActionData.commentingToggleEnabled,
        moderateActionData.campaignChangeEnabled]);

    return (
        <Fragment>
            <ButtonDropdown className="drawer-able moderation-action-menu ms-2"
                            drop="down"
                            isOpen={dropdownOpen}
                            toggle={toggleDropdown}>
                <Backdrop/>

                <DropdownToggle tag="button" className="btn btn-idea-moderator p-0"
                                data-test-element-id="btn-moderate-idea"
                                title={localizer.msg('idea.actions.moderate-actions-title')}>
                    <Icon className="pointer-events-none" name="gavel-solid" width={17}
                          height={17} fill="#666" iconSpritePath={svgIconsPath}/>
                </DropdownToggle>

                <div className="right-panel">
                    <DropdownMenu id={menuFocusId}
                                  className="dropdown-menu moderate-dropdown" end>
                        {
                            (isLoading || isRefetching || isFetching)
                                ? <DropdownItem>
                                    <ParagraphSkeleton rows={6}/>
                                </DropdownItem>
                                : <Fragment>
                                    {
                                        moderateActionData.approveIdeaEnabled && (
                                            <DropdownItem className="d-flex align-items-center"
                                                          data-test-element-id="approve">
                                                <Icon className="me-2" name="check" fill="#666" width={15} height={12}
                                                      iconSpritePath={svgIconsPath}/>
                                                {localizer.msg('idea.actions.approve')}
                                            </DropdownItem>
                                        )
                                    }

                                    {
                                        moderateActionData.rejectIdeaEnabled && (
                                            <DropdownItem className="d-flex align-items-center"
                                                          data-test-element-id="reject">
                                                <Icon className="me-2" name="cross" fill="#666" width={15} height={11}
                                                      iconSpritePath={svgIconsPath}/>
                                                {localizer.msg('idea.actions.reject')}
                                            </DropdownItem>
                                        )
                                    }

                                    {
                                        moderateActionData.stageChangeEnabled && (
                                            <Fragment>
                                                <DropdownSubMenu className="change-stage"
                                                                 icon={<Icon className="me-2 stage-change-icon"
                                                                             name="fast-forward"
                                                                             fill="#666"
                                                                             width={12} height={14}
                                                                             iconSpritePath={svgIconsPath}/>}
                                                                 menuElementId="stage-menu"
                                                                 buttonElementId="change-stage"
                                                                 label={localizer.msg('idea.actions.change-stage')}
                                                                 direction="end"
                                                                 modifiers={[
                                                                     {
                                                                         name: 'applyMaxSize',
                                                                         enabled: true,
                                                                         phase: 'write',
                                                                         fn({state}) {
                                                                             if (window.innerWidth > 991) {
                                                                                 state.styles.popper = {
                                                                                     ...state.styles.popper,
                                                                                     overflow: 'auto',
                                                                                     maxHeight: window.innerHeight - 60 + 'px',
                                                                                 };
                                                                             } else {
                                                                                 delete state.styles.popper.overflow;
                                                                                 delete state.styles.popper.maxHeight;
                                                                             }
                                                                         }
                                                                     }
                                                                 ]}>
                                                    {renderStageLists(moderateActionData.activeStages)}
                                                    {
                                                        moderateActionData.archivesStages && moderateActionData.archivesStages.length > 0 &&
                                                        <DropdownSubmenuItem divider/>
                                                    }
                                                    {renderStageLists(moderateActionData.archivesStages)}
                                                    {
                                                        moderateActionData.rejectedStages && moderateActionData.rejectedStages.length > 0 &&
                                                        <DropdownSubmenuItem divider/>
                                                    }
                                                    {renderStageLists(moderateActionData.rejectedStages)}
                                                </DropdownSubMenu>
                                            </Fragment>
                                        )
                                    }

                                    {
                                        moderateActionData.editIdeaEnabled && (
                                            <DropdownItem className="d-flex align-items-center"
                                                          onClick={toggleEditIdeaModal}
                                                          data-test-element-id="edit-idea">
                                                <Icon className="me-2" name="pencil-square" fill="#666" width={15} height={15}
                                                      iconSpritePath={svgIconsPath}/>
                                                {localizer.msg('idea.actions.edit')}
                                            </DropdownItem>
                                        )
                                    }

                                    {
                                        moderateActionData.pinnedIdeaEnabled && (
                                            selectedIdea.pinned ? (
                                                <DropdownItem className="d-flex align-items-center"
                                                              data-test-element-id="unpin"
                                                              onClick={() => togglePinToLandingPage(false)}>
                                                    <Icon className="me-2" iconSpritePath={svgIconsPath} name="thumbtack-slash"
                                                          width={13}
                                                          height={13}/>
                                                    {localizer.msg('idea.actions.unpin-from-landing')}
                                                </DropdownItem>
                                            ) : (
                                                <DropdownItem className="d-flex align-items-center"
                                                              data-test-element-id="pin"
                                                              onClick={() => togglePinToLandingPage(true)}>
                                                    <Icon className="me-2" iconSpritePath={svgIconsPath} name="thumbtack"
                                                          width={13}
                                                          height={13}/>
                                                    {localizer.msg('idea.actions.pin-to-landing')}
                                                </DropdownItem>
                                            )
                                        )
                                    }

                                    {
                                        moderateActionData.deleteIdeaEnabled && (
                                            <DropdownItem className="d-flex align-items-center"
                                                          data-test-element-id="delete-idea"
                                                          onClick={() => onDeleteIdea(selectedIdea.id)} toggle={true}>
                                                <Icon className="me-2" name="cross" fill="#666" width={15} height={11}
                                                      iconSpritePath={svgIconsPath}/>
                                                {localizer.msg('idea.actions.delete')}
                                            </DropdownItem>
                                        )
                                    }
                                    {
                                        moderateActionData.commentingToggleEnabled && (
                                            moderateActionData.commentingEnabled ? (
                                                <DropdownItem className="d-flex align-items-center"
                                                              onClick={() => toggleCommentEnabled()}
                                                              data-test-element-id="disable-comment">
                                                    <Icon className="me-2" name="toggle-off" fill="#666" width={15}
                                                          height={17}
                                                          iconSpritePath={svgIconsPath}/>
                                                    {localizer.msg('idea.actions.comment-disable')}
                                                </DropdownItem>
                                            ) : (
                                                <DropdownItem className="d-flex align-items-center"
                                                              onClick={() => toggleCommentEnabled()}
                                                              data-test-element-id="enable-comment">
                                                    <Icon className="me-2" name="toggle-on" fill="#666" width={15}
                                                          height={15}
                                                          iconSpritePath={svgIconsPath}/>
                                                    {localizer.msg('idea.actions.comment-enable')}
                                                </DropdownItem>
                                            )
                                        )
                                    }

                                    {
                                        moderateActionData.campaignChangeEnabled && (
                                            <DropdownItem className="d-flex align-items-center"
                                                          onClick={toggleChangeCampaignModal}
                                                          data-test-element-id="change-campaign">
                                                <Icon className="me-2" name="arrows-up-down-left-right" fill="#666" width={15} height={15}
                                                      iconSpritePath={svgIconsPath}/>
                                                {localizer.msg('idea.actions.change-campaign')}
                                            </DropdownItem>
                                        )
                                    }

                                    {
                                        !hasActions() &&
                                        <DropdownItem className="d-flex align-items-center pointer-events-none">
                                            {localizer.msg('idea.actions.no-actions')}
                                        </DropdownItem>
                                    }
                                </Fragment>
                        }
                    </DropdownMenu>
                </div>
            </ButtonDropdown>
        </Fragment>
    );
};