import React, {Fragment, useCallback, useMemo, useState} from 'react';
import {PageTheme, useApiErrorResponseHandler, useToggle} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {useLandingPageService} from 'hooks/useService';
import {useTranslation} from 'hooks/useTranslation';
import {ConfigWrapper} from 'components/landing-page/ConfigWrapper';
import {CommonConfigModal} from 'components/landing-page/CommonConfigModal';
import {ActivitySummaryListComponent} from 'components/landing-page/ActivitySummaryListComponent';
import {Component} from 'models/landing-page/Component';
import {UiAttribute} from 'models/landing-page/UiAttribute';
import {ActivitySummaryRequest} from 'models/landing-page/ActivitySummaryRequest';
import {LandingPageComponentType} from 'models/enums/landing-page/LandingPageComponentType';
import {TranslationConfig} from 'models/TranslationConfig';
import {TranslationContentType} from 'models/enums/landing-page/TranslationContentType';
import {TranslationFieldType} from 'models/enums/TranslationFieldType';
import {TranslationModal} from 'shared/TranslationModal';
import {TranslationContentResponse} from 'models/landing-page/TranslationContentResponse';
import {ActivityListComponent} from 'models/landing-page/ActivityListComponent';

type ActivitySummaryContainerProps = {
    showConfig: boolean;
    data: ActivityListComponent;
    theme: PageTheme;
    showTranslationConfig: boolean,
    onComponentEdited: () => void;
    translationLanguageId?: number,
}

export const ActivitySummaryContainer = (props: ActivitySummaryContainerProps) => {
    const {showConfig, data, theme, showTranslationConfig, translationLanguageId, onComponentEdited} = props;
    const [showEditModal, toggleEditModal] = useToggle(false);
    const [showTranslationModal, toggleTranslationModal] = useToggle(false);
    const [activitySummaryConfig, setActivitySummaryConfig] = useState(data);
    const localizer = useLocalizer();
    const {showErrorMessage} = useApiErrorResponseHandler({localizer});
    const landingPageService = useLandingPageService();
    const {getTranslatedContent, saveTranslatedContent} = useTranslation();

    const onEditClicked = useCallback(() => {
        toggleEditModal();
    }, [toggleEditModal]);

    const saveActivitySummaryData = useCallback(async (component: Component) => {
        if (landingPageService) {
            return landingPageService.saveCommonConfig(component);
        }
        return {
            id: 0,
            visible: false,
            type: LandingPageComponentType.ACTIVITY_SUMMARY,
            attribute: UiAttribute.EMPTY,
            title: ''
        };
    }, [landingPageService]);

    const getActivitySummary = useCallback(async (timePeriodGroup: string, timePeriodUnit: number, parameters: ActivitySummaryRequest) => {
        if (landingPageService) {
            return landingPageService.fetchActivitySummaries(timePeriodGroup, timePeriodUnit, parameters);
        }
        return [];
    }, [landingPageService]);

    const updateActivitySummaryData = useCallback(async (component: Component) => {
        try {
            const response = await saveActivitySummaryData(component);
            if (response) {
                setActivitySummaryConfig(response as ActivityListComponent);
            }
        } catch (e: any) {
            if (e.data && 'validationErrors' in e.data) {
                throw e.data;
            } else {
                showErrorMessage(e);
            }
        }
    }, [saveActivitySummaryData, showErrorMessage]);

    const onToggleVisibility = useCallback(async () => {
        const newData = {...activitySummaryConfig};
        newData.visible = !newData.visible;
        await updateActivitySummaryData(newData);
        onComponentEdited();
    }, [activitySummaryConfig, onComponentEdited, updateActivitySummaryData]);

    const onTranslationClicked = useCallback(() => {
        if (translationLanguageId !== undefined) {
            toggleTranslationModal();
        }
    }, [toggleTranslationModal, translationLanguageId]);

    const onTextTranslated = useCallback((response: TranslationContentResponse) => {
        setActivitySummaryConfig(prev => {
            const newData = {...prev};
            if (response.contentType === TranslationContentType.PAGE_COMPONENT_TITLE) {
                newData.title = response.translation;
            } else {
                newData.subtitle = response.translation;
            }
            return newData;
        });
    }, []);

    const translationConfigs = useMemo(() => {
        return theme === PageTheme.CURIE ?
            [
                new TranslationConfig(
                    TranslationContentType.PAGE_COMPONENT_TITLE,
                    activitySummaryConfig.id,
                    TranslationFieldType.TEXT,
                ),
                new TranslationConfig(
                    TranslationContentType.PAGE_COMPONENT_SUBTITLE,
                    activitySummaryConfig.id,
                    TranslationFieldType.TEXT,
                ),
            ] : [
                new TranslationConfig(
                    TranslationContentType.PAGE_COMPONENT_TITLE,
                    activitySummaryConfig.id,
                    TranslationFieldType.TEXT,
                ),
            ];
    }, [activitySummaryConfig.id, theme]);

    return (
        <Fragment>
            <ConfigWrapper showTranslationConfig={showTranslationConfig} onTranslationClicked={onTranslationClicked}
                           visible={activitySummaryConfig.visible}
                           toggleVisibility={onToggleVisibility}
                           localizer={localizer}
                           showConfig={showConfig}
                           onEditClicked={onEditClicked}
                           className={`${activitySummaryConfig.attribute?.className} activity-summary-component`}
                           accessibleName={localizer.msg('landing-page.activity-summary')}>
                <ActivitySummaryListComponent localizer={localizer}
                                              config={activitySummaryConfig}
                                              theme={theme}
                                              getActivitySummary={getActivitySummary}/>
            </ConfigWrapper>
            {
                showEditModal &&
                <CommonConfigModal theme={theme}
                                   onComponentEdited={onComponentEdited}
                                   open={showEditModal}
                                   toggle={toggleEditModal}
                                   configData={activitySummaryConfig}
                                   updateData={updateActivitySummaryData}
                                   localizer={localizer}
                                   renderSubtitle={theme === PageTheme.CURIE}/>
            }
            {
                showTranslationModal && translationLanguageId !== undefined &&
                <TranslationModal translationLanguageId={translationLanguageId} localizer={localizer}
                                  open={showTranslationModal}
                                  toggle={toggleTranslationModal}
                                  onTextTranslated={onTextTranslated}
                                  onComponentEdited={onComponentEdited}
                                  translationConfigs={translationConfigs}
                                  getTranslatedContent={getTranslatedContent}
                                  saveTranslatedContent={saveTranslatedContent}/>
            }
        </Fragment>
    );
};