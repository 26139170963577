import React, {Dispatch, Fragment, SetStateAction, useCallback, useMemo, useState} from 'react';
import {UncontrolledTooltip} from 'reactstrap';
import {OnChangeValue} from 'react-select';
import {useNavigate} from 'react-router-dom';
import {Button, Icon, IdeascaleSelect, OptionType} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {KEYBOARD_KEYS, LandingPageMode, Localizer, SimpleDropdown, useToggle} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useLandingPageContext} from 'contexts/LandingPageContext';
import {appLinks} from 'services/AppLinks';
import {EditVersionModal} from 'components/landing-page/EditVersionModal';
import {LandingPageTemplateVersion} from 'components/landing-page/LandingPageTemplateVersion';
import {LandingPageTemplateListModal} from 'components/landing-page/LandingPageTemplateListModal';
import {VersionDeleteConfirmationModal} from 'components/landing-page/VersionDeleteConfirmationModal';
import {VersionPayload} from 'models/landing-page/VersionPayload';
import {LandingPageData} from 'models/landing-page/LandingPageData';
import {LandingPageVersion} from 'models/types/LandingPageVersion';
import {LandingPageResponse} from 'models/landing-page/LandingPageResponse';
import {SearchInputGroup} from 'shared/SearchInputGroup';
import {VERSION_NOTE_MAX_LENGTH} from 'constants/AppConstants';
import 'components/topbar/LandingPageSettings.scss';

type LandingPageSettingsProps = {
    localizer: Localizer;
    onVersionChange: (versionData: LandingPageVersion) => Promise<void>;
    onVersionDelete: (pageId: number, version: number) => Promise<void>;
    onVersionEdit: (pageId: number, version: number, newVersion: VersionPayload) => Promise<LandingPageVersion>;
    onStartEditing: (pageId: number, version?: number, onSuccess?: () => void) => Promise<void>;
    onStartTranslation: (languageId: number, version: number) => void;
    fetchLandingPageById: (pageId: number) => Promise<LandingPageData>;
    onLanguageChange: (languageId: number, version: number) => void;
    mode?: LandingPageMode;
    versions: LandingPageVersion[];
    setVersions: Dispatch<SetStateAction<LandingPageVersion[]>>
    landingPageTemplates: LandingPageResponse;
}

export const LandingPageSettings = (props: LandingPageSettingsProps) => {
    const {
        localizer,
        onVersionChange,
        onVersionEdit,
        onVersionDelete,
        onStartEditing,
        onStartTranslation,
        onLanguageChange,
        fetchLandingPageById,
        landingPageTemplates,
        versions,
        setVersions,
        mode
    } = props;
    const {
        selectedVersionData,
        setSelectedVersionData,
        landingPage,
        loading,
        translationMode,
        setLandingPage,
    } = useLandingPageContext();
    const {editModeEnabled, communityConfig} = useAppContext();
    const navigate = useNavigate();
    const [showVersionEditModal, toggleVersionEditModal] = useToggle(false);
    const [showVersionDeleteModal, toggleVersionDeleteModal] = useToggle(false);
    const [showTemplateListModal, toggleTemplateListModal] = useToggle(false);
    const [selectedVersionToChange, setSelectedVersionToChange] = useState<LandingPageVersion | null>(null);
    const [showVersionsDropdown, toggleVersionsDropdown] = useToggle(false);

    const onVersionEditClicked = useCallback((versionData: LandingPageVersion) => {
        setSelectedVersionToChange(versionData);
        toggleVersionEditModal(true);
    }, [toggleVersionEditModal]);

    const onVersionDeleteClicked = useCallback((versionData: LandingPageVersion) => {
        setSelectedVersionToChange(versionData);
        toggleVersionDeleteModal(true);
    }, [toggleVersionDeleteModal]);

    const onVersionEditSuccessful = useCallback((newVersionData: LandingPageVersion) => {
        setVersions(prevVersions => prevVersions.map(versionData => {
            if (versionData.version !== newVersionData.version) {
                return versionData;
            } else {
                return {...versionData, ...newVersionData};
            }
        }));
        toggleVersionEditModal();
    }, [setVersions, toggleVersionEditModal]);

    const onVersionDeleteSuccessful = useCallback((versionDataToDelete: LandingPageVersion) => {
        setSelectedVersionData(currentSelectedVersionData => {
            if (versionDataToDelete.version === currentSelectedVersionData?.version && landingPage.currentVersion !== undefined) {
                const newSelectedVersion = versions.find(versionData => versionData.version !== versionDataToDelete.version);
                if (newSelectedVersion) {
                    void onVersionChange(newSelectedVersion);
                }
                return newSelectedVersion;
            } else {
                return currentSelectedVersionData;
            }
        });
        setVersions(prevVersions => {
            return prevVersions.filter(versionData => versionData.version !== versionDataToDelete.version);
        });
        toggleVersionDeleteModal();
    }, [landingPage.currentVersion, onVersionChange, setSelectedVersionData, setVersions, toggleVersionDeleteModal, versions]);

    const onChangeTemplate = useCallback((pageId: number) => {
        toggleTemplateListModal();
        navigate(appLinks.landingPageAction(LandingPageMode.SELECT, String(pageId), editModeEnabled));
        fetchLandingPageById(pageId).then(data => {
            setLandingPage(data);
            setSelectedVersionData(data.currentVersion);
        });
    }, [editModeEnabled, fetchLandingPageById, navigate, setLandingPage, setSelectedVersionData, toggleTemplateListModal]);

    const languageOptions = useMemo(() => {
        const languageOptions: OptionType[] = communityConfig.supportedLanguages.map(language => {
            return {value: language.id, label: language.name};
        });
        return languageOptions;
    }, [communityConfig.supportedLanguages]);

    const getSelectedLanguage = useCallback(() => {
        return languageOptions.find(language => language.value === landingPage.translatedLanguageId);
    }, [languageOptions, landingPage.translatedLanguageId]);

    const onSelectLanguage = useCallback((option: OnChangeValue<OptionType, boolean>) => {
        onLanguageChange((option as OptionType).value, selectedVersionData?.version || 0);
    }, [onLanguageChange, selectedVersionData?.version]);

    const onStartEditingClicked = useCallback(async () => {
        if (selectedVersionData !== undefined) {
            void onStartEditing(landingPage.id, selectedVersionData.version, () => {
                navigate(appLinks.landingPageAction(LandingPageMode.EDIT, String(landingPage.id), editModeEnabled));
            });
        } else {
            navigate(appLinks.landingPageAction(LandingPageMode.EDIT, String(landingPage.id), editModeEnabled));
        }
    }, [selectedVersionData, onStartEditing, landingPage.id, navigate, editModeEnabled]);

    const onVersionSelected = (versionData: LandingPageVersion) => {
            onVersionChange(versionData).then();
            toggleVersionsDropdown();
    };

    return (
        <div className="d-flex align-items-center flex-nowrap">
            {
                landingPage.permissions.edit && !translationMode &&
                <Button
                    className={`${loading || mode === LandingPageMode.PREVIEW ? '' : 'edit-mode-element'} d-inline-flex align-items-center btn-templates me-3`}
                    color="gray" onClick={toggleTemplateListModal}
                    disabled={loading || mode === LandingPageMode.PREVIEW}
                    title={localizer.msg('landing-page.action-topbar.edit-mode.show-templates')}>
                    <Icon className="me-1" width={24} iconSpritePath={svgIconPath} name="image-layered"/>
                    <span className="edit-mode-element template-text text-truncate">
                            {localizer.msg('landing-page.action-topbar.edit-mode.template')}:&nbsp;
                        <span className="first-letter-uppercase text-capitalize edit-mode-element">
                                {landingPage.name}
                        </span>
                        </span>
                </Button>
            }
            {
                mode !== LandingPageMode.PREVIEW &&
                <SimpleDropdown
                    open={showVersionsDropdown}
                    toggle={toggleVersionsDropdown}
                    align="left"
                    dropdownClassName="versions-dropdown me-3 py-0"
                    menuClassName="versions-menu p-0 rounded overflow-auto"
                    toggleDisabled={loading || showVersionEditModal || showVersionDeleteModal}
                    toggleContent={
                        <span className="d-inline-flex align-items-center justify-content-start edit-mode-element">
                                        <Icon name="arrow-looped-around-clock-alt" iconSpritePath={svgIconPath} width={16}
                                              height={16}/>
                                        <span
                                            className="ms-2 version-toggle-text text-truncate text-start edit-mode-element">
                                            {
                                                selectedVersionData
                                                    ? versions.find(versionData => versionData.version === selectedVersionData.version)?.title
                                                    : localizer.msg('landing-page.action-topbar.default-version')
                                            }
                                        </span>
                                        <Icon iconSpritePath={svgIconPath} name="triangle-down" width={12} height={12}/>
                                    </span>
                    }
                    toggleTitle={
                        selectedVersionData?.version
                            ? versions.find(versionData => versionData.version === selectedVersionData.version)?.title || ''
                            : localizer.msg('landing-page.action-topbar.default-version')
                    }
                    toggleClassName="w-100 btn-gray edit-mode-element d-inline-flex btn-version align-items-center justify-content-start">
                    <Fragment>
                        {
                            versions.map((versionData, index) => {
                                const isPublished = versionData.version === landingPage.publishedVersion?.version;
                                const isDefault = index === versions.length - 1;
                                return <div tabIndex={0} role="button" aria-describedby={`version-${versionData.version}-tooltip`}
                                    className={`d-flex border-bottom-1 version cursor-pointer edit-mode-element p-4 align-items-start ${versionData.version === landingPage.publishedVersion?.version ? 'published-version' : ''} ${selectedVersionData?.version === versionData.version ? 'current-version' : ''}`}
                                    id={`version-${versionData.version}`}
                                    onClick={(event) => onVersionSelected(versionData)}
                                    onKeyUp={(event) => {
                                        if (event.key === KEYBOARD_KEYS.ENTER) {
                                            onVersionSelected(versionData);
                                        }
                                    }}
                                    key={versionData.version}
                                    aria-label={
                                        (!isDefault && landingPage.permissions.edit)
                                            ? isPublished
                                                ? localizer.msg('landing-page.action-topbar.edit-mode.version-item-edit', {
                                                    title: versionData.title
                                                })
                                                : localizer.msg('landing-page.action-topbar.edit-mode.version-item-delete-edit', {
                                                    title: versionData.title
                                                })
                                            : versionData.title
                                    }>
                                    <LandingPageTemplateVersion onVersionDelete={onVersionDelete}
                                                                defaultVersion={index === versions.length - 1}
                                                                key={versionData.version}
                                                                editPermission={landingPage.permissions.edit}
                                                                onVersionDeleteClicked={onVersionDeleteClicked}
                                                                publishedVersion={versionData.version === landingPage.publishedVersion?.version}
                                                                onVersionEditClicked={onVersionEditClicked}
                                                                versionData={versionData}
                                                                localizer={localizer}/>
                                    {
                                        versionData.note && versionData.note.length >= VERSION_NOTE_MAX_LENGTH &&
                                        <UncontrolledTooltip placement="right" className="version-tooltip" id={`version-${versionData.version}-tooltip`}
                                                             target={`version-${versionData.version}`}>
                                            <div className="p-3">
                                                <p className="fw-bold font-size-normal text-start mb-2">
                                                    {versionData.title}
                                                </p>
                                                <p className="text-start mb-0">{versionData.note}</p>
                                            </div>
                                        </UncontrolledTooltip>
                                    }
                                </div>;
                            })
                        }
                    </Fragment>
                </SimpleDropdown>
            }
            {
                landingPage.permissions?.edit && !translationMode && mode !== LandingPageMode.PREVIEW &&
                <Button className="btn me-3 edit-mode-element d-flex align-items-center" color="secondary"
                        title={localizer.msg('common.start-editing')}
                        disabled={loading} onClick={onStartEditingClicked}>
                    <Icon className="active me-1" name="pencil" iconSpritePath={svgIconPath} width={16}
                          height={16}/>
                    {localizer.msg('common.start-editing')}
                </Button>
            }
            {
                landingPage.permissions?.translation && !translationMode && selectedVersionData && mode !== LandingPageMode.PREVIEW &&
                <Button className="d-flex me-3 align-items-center edit-mode-element" color="secondary" type="button"
                        disabled={loading} title={localizer.msg('landing-page.action-topbar.start-translation')}
                        onClick={() => onStartTranslation(landingPage.languageId, selectedVersionData?.version)}>
                    <Icon className="me-1 active" name="language" iconSpritePath={svgIconPath}
                          width={15} height={15}/>
                    {localizer.msg('landing-page.action-topbar.start-translation')}
                </Button>
            }
            {
                translationMode &&
                <SearchInputGroup
                    className={`${loading ? 'disabled' : ''} language-select edit-mode-element`}
                    groupText={
                        <Icon className="mt-1 position-relative" name="globe-lines" iconSpritePath={svgIconPath} width={17}
                              height={17}/>
                    }>
                    <IdeascaleSelect ariaLabel={localizer.msg('common.select-a-version')}
                                     placeholder={localizer.msg('common.select-a-version')} name="language"
                                     key={`landing-page-translation-${loading ? 'disabled' : 'enabled'}`}
                                     isMulti={false}
                                     disabled={loading}
                                     inputId="landing-page-language"
                                     value={getSelectedLanguage()}
                                     defaultValue={getSelectedLanguage()}
                                     options={languageOptions} onChange={onSelectLanguage}/>
                </SearchInputGroup>
            }
            {
                showVersionEditModal && selectedVersionToChange &&
                <EditVersionModal localizer={localizer}
                                  onVersionEditSuccessful={onVersionEditSuccessful}
                                  versionData={selectedVersionToChange}
                                  open={showVersionEditModal}
                                  landingPageId={landingPage.id}
                                  onVersionEdit={onVersionEdit}
                                  toggleModal={toggleVersionEditModal}/>
            }
            {
                showVersionDeleteModal && selectedVersionToChange &&
                <VersionDeleteConfirmationModal localizer={localizer}
                                                landingPageId={landingPage.id}
                                                open={showVersionDeleteModal}
                                                onVersionDeleteSuccessful={onVersionDeleteSuccessful}
                                                onVersionDelete={onVersionDelete}
                                                toggleModal={toggleVersionDeleteModal}
                                                versionData={selectedVersionToChange}/>
            }
            {
                showTemplateListModal &&
                <LandingPageTemplateListModal localizer={localizer}
                                              onChangeTemplate={onChangeTemplate}
                                              open={showTemplateListModal}
                                              activePageId={landingPage.id}
                                              publishedPageId={landingPageTemplates.activePageId}
                                              toggleModal={toggleTemplateListModal}
                                              pages={landingPageTemplates.pages}/>
            }
        </div>
    );
};