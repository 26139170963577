import React, {Dispatch, SetStateAction} from 'react';
import {Icon} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';

type closeButtonProps = {
    setHide : Dispatch<SetStateAction<string>>;
    setInboxList: Dispatch<SetStateAction<string>>;
}
export const CloseButton = (props: closeButtonProps) => {
    const {setHide, setInboxList} = props;
    return (
        <>
            <button className="btn btn-cancel float-end p-0 ms-3 mt-n2 me-2"
                    onClick={() => {
                        setHide('d-none');
                        setInboxList('d-block');
                    }}>
                <Icon iconSpritePath={svgIconPath} name="cross"/>
            </button>
        </>
    );
};