import React, {Fragment, useEffect, useRef} from 'react';
import {Modal} from '@ideascale/ui';
import {eventDispatcher, postLoginRequest, useToggle, useUrlQuery} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {SHOW_LOGIN_PROMPT} from 'constants/AppConstants';
import {ROUTES} from 'shared/Routes';

export const LoginPrompt = () => {
    const localizer = useLocalizer();
    const query = useUrlQuery();
    const {authentication, communityConfig} = useAppContext();

    const messageRef = useRef<string>(localizer.msg('login-prompt.message.default'));
    const [open, toggle] = useToggle(false);

    useEffect(() => {
        eventDispatcher.addListener(SHOW_LOGIN_PROMPT, (message?: string) => {
            if (message) {
                messageRef.current = message;
            } else {
                messageRef.current = localizer.msg('login-prompt.message.default');
            }
            toggle(true);
        });

        return () => {
            eventDispatcher.removeListener(SHOW_LOGIN_PROMPT);
            toggle(false);
        };
    }, [localizer, toggle]);

    useEffect(() => {
        if (query.get(ROUTES.QUERY_PARAMS.SUBMISSION_FORM) === 'open' && !authentication.isAuthenticated() && authentication.community.isPubicCommunity()) {
            eventDispatcher.dispatch(SHOW_LOGIN_PROMPT);
        }
    }, [authentication, query]);

    return (
        <Modal modalBodyClassName="text-center" isOpen={open} toggle={toggle} modalHeaderId="login-prompt-header"
               aria-labelledby="login-prompt-header"
               title={localizer.msg('login-prompt.login-to-contribute')}>
            <div className="fw-bold font-size-lg mt-4">{messageRef.current}</div>
            <button className="btn btn-primary mt-3" onClick={() => postLoginRequest({
                url: authentication.community.loginUrl,
                explicitLogin: true
            })}>{localizer.msg('login-prompt.login')}</button>

            <div className="mt-5 mb-3">
                {
                    communityConfig.ssoEnabled
                        ? <Fragment>&nbsp;</Fragment>
                        : <Fragment>
                            {localizer.msg('login-prompt.dont-have-account')}
                            {' '}
                            <a className="fw-bold"
                               href={authentication.community.signupUrl}>{localizer.msg('login-prompt.register')}</a>
                        </Fragment>
                }
            </div>
        </Modal>
    );
};