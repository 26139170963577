import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {AlertEvent, AlertType, buildAlertEventData, eventDispatcher, PageTitle, StringUtil, useApiErrorResponseHandler} from '@ideascale/commons';
import {Icon, ParagraphSkeleton} from '@ideascale/ui';
import {Link} from 'react-router-dom';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {FollowingResponse} from 'models/FollowingResponse';
import {FollowingData} from 'models/FollowingData';
import {useCommunityService} from 'hooks/useService';
import './IdeaListSelectedTag.scss';

type ideaListSelectedTagProps = {
    urlTagString: string;
    urlModeratorTagString: string;
    hasCampaign: boolean;
    totalIdeasCount: number;
}

export const IdeaListSelectedTag = (props: ideaListSelectedTagProps) => {
    const {
        urlTagString,
        urlModeratorTagString,
        hasCampaign,
        totalIdeasCount
    } = props;
    const localizer = useLocalizer();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const communityService = useCommunityService();
    const {currentCampaign, communityConfig} = useAppContext();
    const [data, setData] = useState(FollowingData.EMPTY);
    const [loading, setLoading] = useState(true);
    const getTagTypes = (urlTagString && urlTagString) || (urlModeratorTagString && urlModeratorTagString);

    const followTag = async (name: string): Promise<FollowingResponse> => {
        if (communityService !== null) {
            try {
                return await communityService.followTag(name);
            } catch (error: any) {
                handleErrorResponse(error);
            }
        }
        return FollowingResponse.EMPTY;
    };

    const unFollowTag = async (name: string): Promise<FollowingResponse> => {
        if (communityService !== null) {
            try {
                return await communityService.unfollowTag(name);
            } catch (error: any) {
                handleErrorResponse(error);
            }
        }
        return FollowingResponse.EMPTY;
    };

    const fetchFollowingTag = useCallback(async (name: string): Promise<FollowingData> => {
        if (communityService !== null) {
            return await communityService.fetchFollowingTag(name);
        }
        return FollowingData.EMPTY;
    }, [communityService]);

    const onFollowingClicked = () => {
        if (data.following) {
            unFollowTag(urlTagString).then(status => {
                if (status?.status) {
                    eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, status.message));
                    setData({
                        following: false
                    });
                }
            });
        } else {
            followTag(urlTagString).then(status => {
                if (status?.status) {
                    eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, status.message));
                    setData({
                        following: true
                    });
                }
            });
        }
    };

    useEffect(() => {
        fetchFollowingTag(getTagTypes).then(data => {
            setData(data);
            setLoading(false);
        });
    }, [fetchFollowingTag, getTagTypes]);


    const renderTagActions = (tagName: string) => {
        return (
            <div className="idea-tag-container d-flex mt-3 mb-3 align-items-center justify-content-between">
                <PageTitle title={`${communityConfig.name} - by IdeaScale | Tags | ${tagName}`}/>
                <div className="fw-bold col-md-6 ps-0">
                    {localizer.msg('sidebar.tags.showing')} {''}
                    {totalIdeasCount} {''}
                    {localizer.msg('sidebar.tags.ideas-for-tag')} <span
                    title={tagName}>"{StringUtil.textTruncate(tagName, 30)}"</span>
                </div>
                <div>
                    <div className="d-flex align-items-center">
                        {
                            urlTagString &&
                            <button className="btn btn-gray me-2" onClick={onFollowingClicked}>
                                {
                                    <Fragment>
                                        <Icon className="follow-icon me-1" iconSpritePath={svgIconPath}
                                              name={data.following ? 'star-solid' : 'star-outlined'} width={18}
                                              height={18} fill={'#384EC1'}/>
                                        {
                                            data.following
                                                ? localizer.msg('sidebar.tags.following-tag')
                                                : localizer.msg('sidebar.tags.follow-tag')
                                        }
                                    </Fragment>
                                }
                            </button>
                        }
                        {
                            hasCampaign
                                ? <Link to={`/campaigns/${currentCampaign?.id}/tags`} className="btn btn-gray">
                                    {localizer.msg('common.show-all')}
                                </Link>
                                : <Link to={'/tags'} className="btn btn-gray">
                                    {localizer.msg('common.show-all')}
                                </Link>
                        }
                    </div>
                </div>
            </div>
        );
    };

    return (
        loading
            ? <ParagraphSkeleton rows={2}/>
            : renderTagActions(getTagTypes)
    );
};