import {Member} from '@ideascale/commons';

export class AuthorChangeActivity {
    public static readonly EMPTY = new AuthorChangeActivity({
        id: 0,
        name: '',
        username: '',
        avatar: '',
        url: ''
    }, {
        id: 0,
        name: '',
        username: '',
        avatar: '',
        url: ''
    }, {
        id: 0,
        name: '',
        username: '',
        avatar: '',
        url: ''
    }, '');

    public constructor(
        public newMember: Member,
        public oldMember: Member,
        public member: Member,
        public createdAt: string,
    ) {
    }
}