import {Command} from './Command';
import {CampaignHomeEditor} from './Editors/CampaignHomeEditor';
import {Operation} from 'models/edit-mode/Operation';
import {BannerField} from 'models/edit-mode/BannerField';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';
import {ChangeCampaignBannerOperation} from 'models/edit-mode/ChangeCampaignBannerOperation';

export class UpdateCampaignBanner implements Command {
    private readonly campaignHomeEditor: CampaignHomeEditor;
    private readonly newBanner: BannerField;
    private readonly oldBanner: BannerField;
    private readonly name: CampaignOperationType;
    private readonly campaignId: number;

    constructor(campaignHomeEditor: CampaignHomeEditor, newBanner: BannerField, campaignId: number) {
        this.campaignHomeEditor = campaignHomeEditor;
        this.oldBanner = this.campaignHomeEditor.campaignBanner;
        this.newBanner = newBanner;
        this.campaignId = campaignId;
        this.name = CampaignOperationType.CHANGE_CAMPAIGN_BANNER;
    }

    execute(): void {
        this.campaignHomeEditor.campaignBanner = this.newBanner;
    }

    getType(): CampaignOperationType {
        return this.name;
    }

    getOperation(): Operation {
        const banner = this.campaignHomeEditor.campaignBanner;
        return new ChangeCampaignBannerOperation(
            this.name,
            banner.fileId,
            banner.gradientEnabled,
            banner.blackPageTitleEnabled,
            banner.imageType,
            this.campaignId
        );
    }

    undo(): void {
        this.campaignHomeEditor.campaignBanner = this.oldBanner;
    }
}