import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Trans} from 'react-i18next';
import {DateTime} from "luxon";
import {Localizer} from '@ideascale/commons';
import {TimeUtil} from 'utils/TimeUtil';
import {CommonUtil} from 'utils/CommonUtil';

type SubmissionEndNoticeProps = {
    localizer: Localizer;
    date: string;
}
export const SubmissionEndNotice = (props: SubmissionEndNoticeProps) => {
    const {localizer, date} = props;
    const [timeLeft, setTimeLeft] = useState(TimeUtil.calculateTimeLeft(date));

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(TimeUtil.calculateTimeLeft(date));
        }, 1000);

        return () => clearTimeout(timer);
    }, [date]);

    const hasTimeLeft = useCallback(() => {
        return DateTime.fromISO(date).toMillis() - TimeUtil.currentTimeInMilliSecond() > 0;
    }, [date]);

    return (
        <div
            className={`alert font-size-md box-shadow ${hasTimeLeft() ? 'alert-warning' : 'alert-danger'}`} data-test-element-id={`submission-deadline-${hasTimeLeft() ? 'ending' : 'ended'}`}>
            {
                hasTimeLeft()
                    ?
                    <Fragment>
                        {
                            timeLeft.days > 0
                                ?
                                <Trans i18nKey="campaign.submission-end-day-notice" values={{
                                    days: CommonUtil.formatNumberWithLeadingZero(timeLeft.days),
                                    hours: CommonUtil.formatNumberWithLeadingZero(timeLeft.hours)
                                }}>
                                    <strong/>
                                </Trans>
                                :
                                <Trans i18nKey="campaign.submission-end-time-notice" values={{
                                    hours: CommonUtil.formatNumberWithLeadingZero(timeLeft.hours),
                                    //Since we are not showing the seconds, let's add one minute instead.
                                    minutes: CommonUtil.formatNumberWithLeadingZero(timeLeft.minutes + 1)
                                }}>
                                    <strong className={`${timeLeft.hours <= 0 ? 'd-none' : ''}`}/>
                                    <strong/>
                                </Trans>
                        }
                    </Fragment>
                    : localizer.msg('campaign.submission-end-notice')
            }
        </div>
    );
};