import React from 'react';
import {Localizer, PageTheme} from '@ideascale/commons';
import {ActivitySummaryResponse} from 'models/landing-page/ActivitySummaryResponse';

type ActivitySummaryItemProps = {
    localizer: Localizer;
    activitySummary: ActivitySummaryResponse;
    theme: PageTheme;
}

export const ActivitySummaryItem = (props: ActivitySummaryItemProps) => {
    const {activitySummary, localizer, theme} = props;

    const renderActivitySummary = () => {
        let bgClass = '', percentageSymbol = '↔', percentageClass = 'activity-percentage-neutral';

        if (activitySummary.changePercentage > 0) {
            percentageSymbol = '↑';
            percentageClass = 'activity-percentage-up';
        } else if (activitySummary.changePercentage < 0) {
            percentageSymbol = '↓';
            percentageClass = 'activity-percentage-down';
        }

        switch (theme) {
            case PageTheme.EASTMAN:
                return (
                    <div className="col-lg-2 col-md-4 col-6 my-3">
                        <div className={`summary-item text-break rounded text-center ${bgClass}`}>
                            <h3 className="summary-title font-size-sm fw-bold h6">
                                {localizer.msg(`landing-page.components.activity-summary.${activitySummary.activityType.toLowerCase().replace(/_/g, '-')}`)}
                            </h3>
                            <div className="activity-count font-size-lg fw-bold">{activitySummary.count}</div>
                            <span className={`activity-percentage rounded fw-bold ${percentageClass}`}>
                                {percentageSymbol}{activitySummary.changePercentage}%
                            </span>
                        </div>
                    </div>
                );
            case PageTheme.CURIE:
            case PageTheme.BERNERS_LEE:
            case PageTheme.ARMSTRONG:
            default:
                bgClass = 'bg-activity-neutral';

                if (activitySummary.changePercentage > 0) {
                    bgClass = 'bg-activity-up';
                } else if (activitySummary.changePercentage < 0) {
                    bgClass = 'bg-activity-down';
                }

                return (
                    <div className="col-lg-3 col-6">
                        <div className={`summary-item text-break rounded ${bgClass}`}>
                            <h3 className="summary-title fw-bold h6">
                                {localizer.msg(`landing-page.components.activity-summary.${activitySummary.activityType.toLowerCase().replace(/_/g, '-')}`)}
                            </h3>
                            <div className="activity-count">{activitySummary.count}</div>
                            <span className={`activity-percentage rounded ${percentageClass}`}>
                                {percentageSymbol}{activitySummary.changePercentage}%
                            </span>
                            <div className="activity-percentage-meta font-size-sm mt-1">
                                {localizer.msg('landing-page.components.activity-summary.from-prev-week')}
                            </div>
                        </div>
                    </div>
                );
        }
    };

    return renderActivitySummary();
};
