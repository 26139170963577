import {TagData} from './TagData';

export class TagStatsHolder {
    static readonly EMPTY = new TagStatsHolder(false, []);

    constructor(
        public viewAll: boolean,
        public tags: TagData[]
    ) {
    }
}