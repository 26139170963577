import {useCallback} from 'react';
import {useIdeaDetailsContext} from '../contexts/IdeaDetailsContext';
import {useNavigate} from 'react-router-dom';
import {appLinks} from '../services/AppLinks';

export const useRefetchIdeaDetails = () => {
    const {refetch} = useIdeaDetailsContext();
    const navigate = useNavigate();

    const refetchIdeaDetails = useCallback((ideaId?: number) => {
        if (ideaId) {
            navigate({
                pathname: appLinks.ideaDetails(ideaId)
            });
        }
        refetch().then();
    }, [navigate, refetch]);

    return {refetchIdeaDetails};
};