import React, {Fragment, useEffect, useState} from 'react';
import {CollapseExpand} from '@ideascale/ui';
import {useToggle} from '@ideascale/commons';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {HtmlRenderer} from './shared/HtmlRenderer';
import {EditActionWrapper} from './shared/EditActionWrapper';
import {CampaignTosEditForm} from './edit-mode/CampaignTosEditForm';
import {TosData} from 'models/TosData';
import {CampaignEditableFieldType} from 'models/edit-mode/CampaignEditableFieldType';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';

type CampaignTosProps = {
    campaignId: number;
    fetchCampaignTosContent: (campaignId: number) => Promise<TosData>
    tosEnabled: boolean;
}

export const CampaignTos = (props: CampaignTosProps) => {
    const {campaignId, fetchCampaignTosContent, tosEnabled} = props;
    const {editModeEnabled} = useAppContext();
    const {homeConfig, campaignHomeEditor, validationErrors} = useEditModeContext();
    const localizer = useLocalizer();
    const [tosContent, setTosContent] = useState<TosData>(TosData.EMPTY);
    const [editTosModal, toggleEditTosModal] = useToggle(false);

    useEffect(() => {
        if (tosEnabled) {
            fetchCampaignTosContent(campaignId).then(data => {
                setTosContent(data);
            });
        }
    }, [campaignId, fetchCampaignTosContent, tosEnabled]);

    return (
        <Fragment>
            {
                editModeEnabled && homeConfig.isOperationAllowed(CampaignEditableFieldType.CAMPAIGN_TOS)
                    ? <div className="card panel mb-4" data-test-element-id="campaign-tos">
                        <div className="card-body">
                            <h2 className="h4 text-muted">{localizer.msg('terms-of-service.title')}</h2>
                            {
                                <EditActionWrapper
                                    className={campaignHomeEditor.tos.value && campaignHomeEditor.tos.tosEnabled ? 'd-flex' : 'd-inline-flex'}
                                    editAllowed={homeConfig.isOperationAllowed(CampaignEditableFieldType.CAMPAIGN_TOS)}
                                    configurationChildren={<CampaignTosEditForm toggleModal={toggleEditTosModal}
                                                                                localizer={localizer}/>}
                                    hasError={validationErrors.hasError(CampaignOperationType.CHANGE_CAMPAIGN_TOS)}
                                    modalTitle={localizer.msg('edit-mode.campaign.edit-tos')}
                                    modalOpen={editTosModal}
                                    modalToggle={toggleEditTosModal}
                                    editBtnTitle={localizer.msg('edit-mode.campaign.edit-tos')}
                                    editBtnTestElementId="btn-edit-campaign-tos">
                                    {
                                        campaignHomeEditor.tos.value && campaignHomeEditor.tos.tosEnabled
                                            ? <HtmlRenderer className="w-100" content={campaignHomeEditor.tos.value}/>
                                            : <div className="text-muted pe-5">
                                                {localizer.msg('edit-mode.campaign.add-tos')}</div>
                                    }
                                </EditActionWrapper>
                            }
                        </div>
                    </div>
                    : <div className={editModeEnabled ? 'opacity-50' : ''} data-test-element-id="campaign-tos">
                        {
                            !!tosContent.termsOfService && tosEnabled &&
                            <CollapseExpand containerClass="mb-3 panel-default"
                                            headerTitle={localizer.msg('terms-of-service.title')}
                                            svgIconSprite={svgIconPath}
                                            defaultOpen={true}>
                                <HtmlRenderer content={tosContent.termsOfService}/>
                            </CollapseExpand>
                        }
                    </div>
            }
        </Fragment>
    );
};