import {ImageryOperation} from './ImageryOperation';
import {CommunityOperationType} from './CommunityOperationType';

export class ChangeCommunityLogoOperation extends ImageryOperation {
    constructor(
        type: CommunityOperationType,
        fileId: number,
        public logoFrameEnabled: boolean,
        public logoOnBannerEnabled: boolean
    ) {
        super(type, fileId);
    }
}