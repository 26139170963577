import React, {Fragment, useEffect, useState} from 'react';
import {range} from 'lodash';
import {Skeleton} from '@ideascale/ui';
import {PageTheme} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {DisplayHeader} from 'components/landing-page/DisplayHeader';
import {CommunityLeader} from 'components/landing-page/CommunityLeader';
import {Component} from 'models/landing-page/Component';
import {SidebarLeaderboardItem as LeaderboardItem} from 'models/SidebarLeaderboardItem';
import {LEADERBOARD_TYPES} from 'constants/AppConstants';
import 'components/landing-page/CommunityLeaders.scss';

type CommunityStatsComponentProps = {
    config: Component;
    theme: PageTheme;
    editMode?: boolean;
    getLeaderboardItems: () => Promise<LeaderboardItem[]>
}

const renderCommunityLeadersSkeleton = (amount: number = 6) => {
    return (
        <div className="row justify-content-center">
            {
                range(0, amount).map((_, index) => (
                    <div key={index} className="d-flex flex-column py-2 px-4">
                        <Skeleton cssClass="mx-auto" circle width="102px" height="102px"/>
                        <Skeleton cssClass="mx-auto" width="60px" height="20px"/>
                        <Skeleton cssClass="mx-auto" width="100px" height="20px"/>
                        <Skeleton cssClass="mx-auto" width="75px" height="10px"/>
                    </div>
                ))
            }
        </div>
    );
};

export const CommunityLeadersComponent = (props: CommunityStatsComponentProps) => {
    const {config, theme, editMode = false, getLeaderboardItems} = props;
    const [leaderboardItems, setLeaderboardItems] = useState<LeaderboardItem[]>([]);
    const [loading, setLoading] = useState(true);
    const localizer = useLocalizer();

    useEffect(() => {
        setLoading(true);
        getLeaderboardItems().then(data => {
            setLeaderboardItems(data);
            setLoading(false);
        });
    }, [getLeaderboardItems]);


    return (
        <Fragment>
            <DisplayHeader theme={theme} subtitle={config.subtitle} title={config.title}/>
            {
                loading ?
                    renderCommunityLeadersSkeleton(6)
                    :
                    leaderboardItems.length > 0
                        ? (
                            <div className="d-flex flex-wrap justify-content-center community-leaders">
                                {
                                    LEADERBOARD_TYPES.map((type, index) => {
                                        const leaderboardItem = leaderboardItems.find(item => item.leaderboardType === type.value);
                                        if (leaderboardItem) {
                                            return <CommunityLeader localizer={localizer} key={index}
                                                                    editMode={editMode}
                                                                    leaderboardItem={leaderboardItem}/>;
                                        } else {
                                            return null;
                                        }
                                    })
                                }
                            </div>
                        )
                        : (
                            <div className="panel card">
                                <div className="card-body panel-body">
                                    <div className="alert alert-warning mb-0 text-center">
                                        {localizer.msg('sidebar.leaderboard.no-leader')}
                                    </div>
                                </div>
                            </div>
                        )
            }
        </Fragment>
    );
};