import {useMutation, useQueryClient} from 'react-query';
import {useIdeaService} from './useIdeaService';
import {useIdeaUpdater} from './useIdeaUpdater';
import {KudoActivitySummary} from 'models/KudoActivitySummary';
import {IdeaSummary} from 'models/IdeaSummary';

export const useKudoGive = (ideaListQueryKey: any = '') => {
    const {giveKudo} = useIdeaService();
    const queryClient = useQueryClient();
    const {updateIdeaDetails} = useIdeaUpdater();

    const ideaListGiveKudosMutation = useMutation((ideaId: number): any => giveKudo(ideaId), {
        onSuccess: async (data: KudoActivitySummary, ideaId) => {
            queryClient.setQueryData(ideaListQueryKey, (oldIdeas: any) => {
                if (oldIdeas) {
                    const newIdeas = {...oldIdeas};
                    newIdeas.pages.forEach((page: any) => {
                        page.data.forEach((idea: IdeaSummary) => {
                            if (idea.id === ideaId) {
                                idea.kudoCount = data.kudoCount;
                            }
                        });
                    });
                    return newIdeas;
                }
                return [];
            });
        }
    });

    const ideaDetailsGiveKudosMutation = useMutation((ideaId: number): any => giveKudo(ideaId), {
        onSuccess: async (data: KudoActivitySummary, ideaId) => {
            await updateIdeaDetails(ideaId, 'kudoCount', data.kudoCount);
        }
    });

    return {giveKudo, ideaListGiveKudosMutation, ideaDetailsGiveKudosMutation};
};