export enum NotificationType {
    IDEA_SHARED = 'IDEA_SHARED',
    IDEA_SUBMITTED = 'IDEA_SUBMITTED',
    IDEA_MODIFIED = 'IDEA_MODIFIED',
    IDEA_STAGE_CHANGED = 'IDEA_STAGE_CHANGED',
    IDEA_APPROVED = 'IDEA_APPROVED',
    IDEA_VOTED = 'IDEA_VOTED',
    IDEA_AT_MENTIONED = 'IDEA_AT_MENTIONED',
    IDEA_FILE_ATTACHED = 'IDEA_FILE_ATTACHED',
    IDEA_CAMPAIGN_CHANGED = 'IDEA_CAMPAIGN_CHANGED',
    IDEA_TOP_TRENDING = 'IDEA_TOP_TRENDING',
    IDEA_INACTIVE = 'IDEA_INACTIVE',
    IDEA_TAG_ADDED = 'IDEA_TAG_ADDED',
    IDEA_VOTE_MILESTONE_ACHIEVED = 'IDEA_VOTE_MILESTONE_ACHIEVED',
    IDEA_ANNOTATED = 'IDEA_ANNOTATED',
    IDEA_VOTE_THRESHOLD_ACHIEVED = 'IDEA_VOTE_THRESHOLD_ACHIEVED',
    IDEA_TEAM_MEMBER_JOINED = 'IDEA_TEAM_MEMBER_JOINED',
    IDEA_TEAM_MEMBER_ASSIGNED = 'IDEA_TEAM_MEMBER_ASSIGNED',
    IDEA_TEAM_MEMBER_LEFT = 'IDEA_TEAM_MEMBER_LEFT',
    IDEA_TEAM_MEMBER_UNASSIGNED = 'IDEA_TEAM_MEMBER_UNASSIGNED',
    IDEA_OWNER_ASSIGNED = 'IDEA_OWNER_ASSIGNED',
    IDEA_TEAM_POSITION_ADDED = 'IDEA_TEAM_POSITION_ADDED',
    IDEA_TEAM_POSITION_REMOVED = 'IDEA_TEAM_POSITION_REMOVED',
    IDEA_TEAM_ROLE_ADDED = 'IDEA_TEAM_ROLE_ADDED',
    IDEA_REFINE_ANSWER_SUBMITTED = 'IDEA_REFINE_ANSWER_SUBMITTED',
    IDEA_REFINE_ANSWER_MODIFIED = 'IDEA_REFINE_ANSWER_MODIFIED',
    IDEA_FUND_TARGET_SUBMITTED = 'IDEA_FUND_TARGET_SUBMITTED',
    IDEA_FUND_TARGET_MODIFIED = 'IDEA_FUND_TARGET_MODIFIED',
    ASSESSMENT_REVIEW_SUBMITTED = 'ASSESSMENT_REVIEW_SUBMITTED',
    ASSESSMENT_REVIEW_MODIFIED = 'ASSESSMENT_REVIEW_MODIFIED',
    REVIEWSCALE_REVIEW_SUBMITTED = 'REVIEWSCALE_REVIEW_SUBMITTED',
    REVIEWSCALE_REVIEW_MODIFIED = 'REVIEWSCALE_REVIEW_MODIFIED',
    ESTIMATE_ROI_SUBMITTED = 'ESTIMATE_ROI_SUBMITTED',
    COMMENT_POSTED = 'COMMENT_POSTED',
    COMMENT_AT_MENTIONED = 'COMMENT_AT_MENTIONED',
    COMMENT_APPROVED = 'COMMENT_APPROVED',
    STAGE_COMMENT_AT_MENTIONED = 'STAGE_COMMENT_AT_MENTIONED',
    IDEA_KUDO_RECEIVED = 'IDEA_KUDO_RECEIVED',
    COMMENT_KUDO_RECEIVED = 'COMMENT_KUDO_RECEIVED',

    BONUS_5X_KUDO_ISSUED = 'BONUS_5X_KUDO_ISSUED',
    MONTHLY_MODERATOR_DIGEST = 'MONTHLY_MODERATOR_DIGEST',
    WEEKLY_MODERATOR_DIGEST = 'WEEKLY_MODERATOR_DIGEST',
    SHARED_WEEKLY_CAMPAIGN_PERFORMANCE = 'SHARED_WEEKLY_CAMPAIGN_PERFORMANCE',
    SHARED_MONTHLY_CAMPAIGN_PERFORMANCE = 'SHARED_MONTHLY_CAMPAIGN_PERFORMANCE',
    IDEA_CUSTOM_FIELD_AT_MENTIONED = 'IDEA_CUSTOM_FIELD_AT_MENTIONED',
    STAGE_CUSTOM_FIELD_AT_MENTIONED = 'STAGE_CUSTOM_FIELD_AT_MENTIONED'
}