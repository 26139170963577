import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {CollapseExpand, InfoTip} from '@ideascale/ui';
import iconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import ideascaleLogo from '@ideascale/ui/dist/assets/ideascale-logo-blue.svg';
import {goTo, Member, TobBarLogo, useApiErrorResponseHandler} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useSidebarContext} from 'contexts/SidebarContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useCommunityService} from 'hooks/useService';
import {externalLinks} from 'constants/AppConstants';
import {appLinks} from 'services/AppLinks';
import {useCampaignModuleService} from 'hooks/useCampaignModuleService';
import {useClassificationService} from 'hooks/useClassificationService';
import {useSidebarService} from './useSidebarService';
import {TimeUtil} from 'utils/TimeUtil';
import {SocialShare} from 'components/sidebar/SocialShare';
import {SocialSubscribe} from 'components/sidebar/SocialSubscribe';
import {CountDownModule} from 'components/sidebar/common/CountDownModule';
import {CampaignStats} from 'components/sidebar/CampaignStats';
import {TagsModule} from 'components/sidebar/common/TagsModule';
import {Stages} from 'components/sidebar/Stages';
import {SidebarSkeleton} from 'components/sidebar/common/SidebarSkeleton';
import {ListModule} from 'components/sidebar/common/ListModule';
import {SidebarLeaderboard} from 'components/sidebar/common/SidebarLeaderboard';
import {ActiveCampaignsModule} from 'components/sidebar/common/ActiveCampaignsModule';
import {ArchiveCampaignsModule} from 'components/sidebar/common/ArchiveCampaignsModule';
import {StaticContentWidget} from 'components/sidebar/StaticContentWidget';
import {Kudos} from 'components/sidebar/Kudos';
import {CustomWidget} from 'components/sidebar/CustomWidget';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {CampaignActivity} from 'models/CampaignActivity';
import {SidebarItem} from 'models/sidebar/SidebarItem';
import {SidebarItemKey} from 'models/enums/SidebarItemKey';
import {StageDetails} from 'models/StageDetails';
import {AdminModeratorData} from 'models/AdminModeratorData';

const MODERATOR_MODULE_ID = 'moderator-sidebar-module';

export const CampaignSidebarContainer = () => {
    const localizer = useLocalizer();
    const communityService = useCommunityService();
    const {currentCampaign, currentStage} = useAppContext();
    const {activeCampaignsHolder, archivedCampaignsHolder} = useSidebarContext();
    const {
        fetchSidebarActiveCampaignModule,
        fetchActiveCampaignGroups,
        fetchActiveCampaignsByGroup,
        fetchSidebarArchivedCampaignModule,
        fetchArchivedCampaignGroups,
        fetchArchivedCampaignsByGroup,
        fetchSidebarCampaignSummary
    } = useCampaignModuleService();

    const {
        addSidebarComponent,
        fetchSidebarSocialShare,
        fetchSidebarTags,
        fetchSocialSubscription,
        fetchSidebarContactWidget,
        fetchKudos,
        fetchSidebarLeaderboardItem,
        fetchCommunitySummary,
        fetchCampaignWidget
    } = useSidebarService();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [sidebarItems, setSidebarItems] = useState<SidebarItem[]>([]);
    const [dataFetching, setDataFetching] = useState<boolean>(true);
    const sidebarItemsRef = useRef<{ [key: string]: JSX.Element }>({});
    const {fetchClassifications} = useClassificationService();

    const fetchCampaignModerators = useCallback(async (data: PageParameters): Promise<AdminModeratorData> => {
        if (communityService !== null && currentCampaign !== null) {
            return await communityService.fetchSidebarCampaignModerators(data, currentCampaign.id);
        }
        return AdminModeratorData.EMPTY;
    }, [communityService, currentCampaign]);

    const fetchCampaignActivity = useCallback(async (): Promise<CampaignActivity> => {
        if (communityService !== null && currentCampaign !== null) {
            return await communityService.fetchCampaignActivity(currentCampaign.id);
        }
        return CampaignActivity.EMPTY;
    }, [communityService, currentCampaign]);

    const fetchSidebarStages = useCallback(async (campaignId: number, stageKey?: string, tag?: string, moderatorTag?: string, labelKey?: string): Promise<StageDetails> => {
        if (communityService !== null) {
            return await communityService.fetchSidebarStages(campaignId, stageKey, tag, moderatorTag, labelKey);
        }
        return StageDetails.EMPTY;
    }, [communityService]);

    const fetchCampaignSidebarItemsData = useCallback(async () => {
        if (communityService !== null && currentCampaign?.id) {
            return await communityService.fetchCampaignSidebarItems(currentCampaign.id);
        }
        return [SidebarItem.EMPTY];
    }, [communityService, currentCampaign?.id]);

    const fetchCampaignSidebarItems = useCallback(async () => {
        try {
            setDataFetching(true);
            const response = await fetchCampaignSidebarItemsData();
            setSidebarItems(response);
        } catch (error: any) {
            handleErrorResponse(error);
        } finally {
            setDataFetching(false);
        }
    }, [fetchCampaignSidebarItemsData, handleErrorResponse]);

    const fetchRecipients = useCallback(async (pageParameters: PageParameters): Promise<PagedResponseContent<Member>> => {
        if (communityService !== null) {
            return await communityService.fetchRecipients(pageParameters);
        }
        return PagedResponseContent.nullObject<Member>();
    }, [communityService]);

    sidebarItemsRef.current = useMemo(() => {
        const components: { [key: string]: JSX.Element } = {};

        addSidebarComponent(
            components,
            SidebarItemKey.CAMPAIGN_ACTIVITY,
            <CollapseExpand containerClass="campaign-stats-module panel-light"
                            headerTitle={localizer.msg('sidebar.campaign-activity.header-title')}
                            svgIconSprite={iconPath}
                            headerTag={'h1'}
                            defaultOpen={true}>
                <CampaignStats fetchCampaignActivity={fetchCampaignActivity} localizer={localizer}/>
            </CollapseExpand>
        );

        addSidebarComponent(
            components,
            SidebarItemKey.FUNNEL,
            <CollapseExpand containerClass="campaign-stages panel-light"
                            headerTitle={localizer.msg('campaign.stages.campaign-funnel')}
                            svgIconSprite={iconPath}
                            defaultOpen={true}>
                <Stages fetchSidebarStages={fetchSidebarStages}/>
            </CollapseExpand>
        );

        addSidebarComponent(
            components,
            SidebarItemKey.CAMPAIGN_TAGS,
            <CollapseExpand containerClass="campaign-tags panel-light"
                            headerTitle={localizer.msg('sidebar.tags.campaign-tags')}
                            svgIconSprite={iconPath}
                            defaultOpen={true}>
                <TagsModule fetchTags={fetchSidebarTags} manageLink={`/campaigns/${currentCampaign?.id}/tags`}/>
            </CollapseExpand>
        );

        addSidebarComponent(
            components,
            SidebarItemKey.KUDOS_TICKER,
            <CollapseExpand
                containerClass="kudos-module panel-light"
                headerTitle={localizer.msg('sidebar.kudos.community-kudos')}
                svgIconSprite={iconPath}
                infoTip={
                    <InfoTip id="kudos-infoTip"
                             placement="top"
                             content={localizer.msg('info-tip.kudos-sidebar-info')}/>
                }
                defaultOpen={true}>
                <Kudos fetchKudos={fetchKudos}/>
            </CollapseExpand>
        );

        addSidebarComponent(
            components,
            SidebarItemKey.ACTIVE_CAMPAIGNS,
            <ActiveCampaignsModule
                headerTitle={localizer.msg('sidebar.campaign.active-campaigns')}
                config={{allIdeas: true, manageCampaign: true, defaultExpand: true}}
                dataFetchingType="EAGER"
                addCampaigns={activeCampaignsHolder.addCampaigns}
                updateCampaignGroupPaginationData={activeCampaignsHolder.updateCampaignGroupPaginationData}
                fetchSidebarCampaignModule={fetchSidebarActiveCampaignModule}
                fetchCampaignGroups={fetchActiveCampaignGroups}
                fetchCampaignsByGroup={fetchActiveCampaignsByGroup}
                fetchSidebarCampaignSummary={fetchSidebarCampaignSummary}
                updateCampaignGroupExpansion={activeCampaignsHolder.updateCampaignGroupExpansion}
            />
        );

        addSidebarComponent(
            components,
            SidebarItemKey.LEADERBOARD,
            <CollapseExpand
                containerClass="leaderboard panel-light"
                headerClass="tour-lucido_leaderboard"
                headerTitle={localizer.msg('sidebar.leaderboard.campaign-leaderboard')}
                svgIconSprite={iconPath}
                defaultOpen={true}>
                <SidebarLeaderboard fetchSidebarLeaderboardItem={fetchSidebarLeaderboardItem}/>
            </CollapseExpand>
        );

        addSidebarComponent(
            components,
            SidebarItemKey.ARCHIVED_CAMPAIGNS,
            <ArchiveCampaignsModule
                headerTitle={localizer.msg('sidebar.campaign.archived-campaigns')}
                config={{allIdeas: false, manageCampaign: false, defaultExpand: true}}
                dataFetchingType="EAGER"
                addCampaigns={archivedCampaignsHolder.addCampaigns}
                updateCampaignGroupPaginationData={archivedCampaignsHolder.updateCampaignGroupPaginationData}
                fetchSidebarCampaignModule={fetchSidebarArchivedCampaignModule}
                fetchCampaignGroups={fetchArchivedCampaignGroups}
                fetchCampaignsByGroup={fetchArchivedCampaignsByGroup}
                fetchSidebarCampaignSummary={fetchSidebarCampaignSummary}
                updateCampaignGroupExpansion={archivedCampaignsHolder.updateCampaignGroupExpansion}
            />
        );

        addSidebarComponent(
            components,
            SidebarItemKey.SOCIAL_SHARE,
            <CollapseExpand containerClass="social-web panel-light"
                            headerTitle={localizer.msg('sidebar.social.social-web')}
                            svgIconSprite={iconPath}
                            defaultOpen={true}>
                <SocialShare fetchRecipients={fetchRecipients} fetchClassifications={fetchClassifications}
                             fetchSidebarSocialShare={fetchSidebarSocialShare}/>
            </CollapseExpand>
        );

        addSidebarComponent(
            components,
            SidebarItemKey.SOCIAL_SUBSCRIBE,
            <SocialSubscribe localizer={localizer} fetchSocialSubscription={fetchSocialSubscription}/>
        );

        addSidebarComponent(
            components,
            SidebarItemKey.MODERATORS,
            <ListModule inputId={MODERATOR_MODULE_ID} headerTitle={localizer.msg('sidebar.moderators.header-title')}
                        fetchMemberList={fetchCampaignModerators}
                        manageLink={appLinks.campaignModeratorSettings}
                        manageText={localizer.msg('sidebar.moderators.manage-campaign')}/>
        );

        addSidebarComponent(
            components,
            SidebarItemKey.CONTACT,
            <CustomWidget containerClass={'contact'} fetchCustomWidgetData={fetchSidebarContactWidget}/>
        );

        addSidebarComponent(
            components,
            SidebarItemKey.COMMUNITY_SUMMARY,
            <CustomWidget containerClass={'community-summary'} fetchCustomWidgetData={fetchCommunitySummary}/>
        );

        addSidebarComponent(
            components,
            SidebarItemKey.CAMPAIGN_CUSTOM_WIDGET_ONE,
            <CustomWidget containerClass={'campaign-widget'} fetchCustomWidgetData={() => {
                return fetchCampaignWidget(currentCampaign?.id || 0, SidebarItemKey.CAMPAIGN_CUSTOM_WIDGET_ONE);
            }
            }/>
        );

        addSidebarComponent(
            components,
            SidebarItemKey.CAMPAIGN_CUSTOM_WIDGET_TWO,
            <CustomWidget containerClass={'campaign-widget'} fetchCustomWidgetData={() => {
                return fetchCampaignWidget(currentCampaign?.id || 0, SidebarItemKey.CAMPAIGN_CUSTOM_WIDGET_TWO);
            }
            }/>
        );

        addSidebarComponent(
            components,
            SidebarItemKey.CAMPAIGN_CUSTOM_WIDGET_THREE,
            <CustomWidget containerClass={'campaign-widget'} fetchCustomWidgetData={() => {
                return fetchCampaignWidget(currentCampaign?.id || 0, SidebarItemKey.CAMPAIGN_CUSTOM_WIDGET_THREE);
            }
            }/>
        );

        addSidebarComponent(
            components,
            SidebarItemKey.STAGE_COUNTDOWN,
            <CollapseExpand
                containerClass="stage-countdown panel-light"
                headerTitle={localizer.msg('sidebar.count-down-timer.stage-title')}
                svgIconSprite={iconPath}
                defaultOpen={true}>
                <CountDownModule
                    date={currentStage?.endDate}
                    info={localizer.msg('sidebar.count-down-timer.info-label-stage', {stage: currentStage?.stage?.label || ''})}
                />
            </CollapseExpand>
        );

        addSidebarComponent(
            components,
            SidebarItemKey.CAMPAIGN_COUNTDOWN,
            <CollapseExpand
                containerClass="campaign-countdown panel-light"
                headerTitle={localizer.msg('sidebar.count-down-timer.campaign-title')}
                svgIconSprite={iconPath}
                defaultOpen={true}>
                <CountDownModule
                    date={currentCampaign?.expiryDate}
                    info={localizer.msg('sidebar.count-down-timer.info-label-campaign')}
                />
            </CollapseExpand>
        );

        addSidebarComponent(
            components,
            SidebarItemKey.ONLINE_LEARNING,
            <StaticContentWidget
                heading={localizer.msg('sidebar.static-content.online-learning.heading')}
                title={localizer.msg('sidebar.static-content.online-learning.title')}
                content={localizer.msg('sidebar.static-content.online-learning.content')}
                button={{
                    label: localizer.msg('sidebar.static-content.online-learning.action'),
                    external: true,
                    onClick: () => goTo(externalLinks.onlineLearning(), '_blank')
                }}/>
        );

        addSidebarComponent(
            components,
            SidebarItemKey.ARTICLES_RESOURCES,
            <StaticContentWidget
                heading={localizer.msg('sidebar.static-content.articles-and-resources.heading')}
                title={localizer.msg('sidebar.static-content.articles-and-resources.title')}
                content={localizer.msg('sidebar.static-content.articles-and-resources.content')}
                button={{
                    label: localizer.msg('sidebar.static-content.articles-and-resources.action'),
                    external: true,
                    onClick: () => goTo(externalLinks.articlesResources(), '_blank')
                }}/>
        );

        return components;
    }, [
        addSidebarComponent,
        localizer,
        fetchCampaignActivity,
        fetchSidebarStages,
        fetchSidebarTags,
        currentCampaign?.id,
        currentCampaign?.expiryDate,
        fetchKudos,
        activeCampaignsHolder.addCampaigns,
        activeCampaignsHolder.updateCampaignGroupPaginationData,
        activeCampaignsHolder.updateCampaignGroupExpansion,
        fetchSidebarActiveCampaignModule,
        fetchActiveCampaignGroups,
        fetchActiveCampaignsByGroup,
        fetchSidebarCampaignSummary,
        fetchSidebarLeaderboardItem,
        archivedCampaignsHolder.addCampaigns,
        archivedCampaignsHolder.updateCampaignGroupPaginationData,
        archivedCampaignsHolder.updateCampaignGroupExpansion,
        fetchSidebarArchivedCampaignModule,
        fetchArchivedCampaignGroups,
        fetchArchivedCampaignsByGroup,
        fetchRecipients,
        fetchClassifications,
        fetchSidebarSocialShare,
        fetchSocialSubscription,
        fetchCampaignModerators,
        fetchSidebarContactWidget,
        fetchCommunitySummary,
        currentStage?.endDate,
        currentStage?.stage?.label,
        fetchCampaignWidget
    ]);

    useEffect(() => {
        fetchCampaignSidebarItems().then();
    }, [fetchCampaignSidebarItems]);

    const renderSidebarComponents = useMemo(() => {
        return (
            sidebarItems.filter(item => item.visible).map((sidebarItem, index) => {
                    let sidebarComponent: JSX.Element | null;
                    if ((sidebarItem.key === SidebarItemKey.CAMPAIGN_COUNTDOWN && currentCampaign?.expiryDate) &&
                        (!currentStage || (currentStage && !currentStage.endDate) || (currentStage && currentStage.endDate && TimeUtil.isBefore(currentStage.endDate)))
                    ) {
                        sidebarComponent = sidebarItemsRef.current[SidebarItemKey.CAMPAIGN_COUNTDOWN];
                    } else if (sidebarItem.key === SidebarItemKey.STAGE_COUNTDOWN && (currentStage && currentStage.endDate && TimeUtil.isAfter(currentStage.endDate))) {
                        sidebarComponent = sidebarItemsRef.current[SidebarItemKey.STAGE_COUNTDOWN];
                    } else if (sidebarItem.key === SidebarItemKey.CAMPAIGN_COUNTDOWN || sidebarItem.key === SidebarItemKey.STAGE_COUNTDOWN) {
                        sidebarComponent = null;
                    } else {
                        sidebarComponent = sidebarItemsRef.current[sidebarItem.key];
                    }

                    return (
                        <Fragment key={`sidebar-${index}`}>
                            {sidebarComponent}
                        </Fragment>
                    );
                }
            )
        );
    }, [currentStage, sidebarItems, currentCampaign?.expiryDate]);

    return (
        <Fragment>
            {
                (
                    <div className={`sidebar-view-mode`}>
                        {
                            dataFetching
                                ? <SidebarSkeleton/>
                                : renderSidebarComponents
                        }
                   </div>
                )
            }

            <div className="footer-ideascale-logo mt-4 mb-2 d-none d-lg-block">
                <a href="https://ideascale.com" title="Go to ideascale.com">
                    <TobBarLogo logo={ideascaleLogo} alt="Powered by IdeaScale" className="w-100"/>
                </a>
            </div>
        </Fragment>
    );
};