import {Member} from '@ideascale/commons';
import {StageActivity} from 'models/stage-activity/StageActivity';
import {FundUnit} from 'models/enums/FundUnit';

type FundPledgeSummaries = {
    balance: number,
    fundUnit: FundUnit,
    fundUnitKey: string,
    funder: Member,
    lastContributedAt: string
}

type IdeaFundAccount = {
    balance: number,
    fundUnit?: FundUnit,
    fundUnitKey: string,
    collectionTarget: number
}

type MemberFundAccount = {
    balance: number,
    fundUnit?: FundUnit,
    fundUnitKey: string
}

export enum NonEmptyFundUnitKey {
    WOODEN_DOLLAR = 'fund.wd',
    USD = 'fund.usd',
    EUR = 'fund.eur',
    GBP = 'fund.gbp',
    JPY = 'fund.jpy',
    AUD = 'fund.aud',
    CAD = 'fund.cad',
    HKD = 'fund.hkd',
}

export class FundStageActivity extends StageActivity {
    static readonly EMPTY = {
       ...StageActivity.EMPTY,
        fundTargetModifyAllowed: false,
        memberPledgeAmount: 0,
        fundingPercentage: 0,
        memberPledgeAllowed: false,
        pledgeThresholdRestrictionEnabled: false,
        viewFunderAllowed: false,
        ideaFundBalance: 0,
        memberFundBalance: 0,
        ideaFundAccount: ({
            balance: 0,
            fundUnit: FundUnit.UNSPECIFIED,
            fundUnitKey: '',
            collectionTarget: 0
        }),
        memberFundAccount: ({
            balance: 0,
            fundUnit: FundUnit.UNSPECIFIED,
            fundUnitKey: '',
        }),
        fundPledgeSummaries: ([{
            balance: 0,
            fundUnit: FundUnit.UNSPECIFIED,
            fundUnitKey: '',
            funder: Member.EMPTY,
            lastContributedAt: ''
        }])
    };
    fundTargetModifyAllowed: boolean;
    memberPledgeAmount: number;
    fundingPercentage: number;
    memberPledgeAllowed: boolean;
    pledgeThresholdRestrictionEnabled: boolean;
    viewFunderAllowed: boolean;
    ideaFundBalance: number;
    memberFundBalance: number;
    ideaFundAccount?: IdeaFundAccount;
    memberFundAccount: MemberFundAccount;
    fundPledgeSummaries: FundPledgeSummaries[];
}