import {VoteRetractType} from './enums/VoteRetractType';
import {BaseIdeaStageSummary} from './BaseIdeaStageSummary';
import {Type, TypeHelpOptions} from 'class-transformer';
import {ClassTransformerUtil} from '../utils/ClassTransformerUtil';

export class VoteActionResponse<T extends BaseIdeaStageSummary> {
    @Type((type?: TypeHelpOptions) => {
        return ClassTransformerUtil.getIdeaStageType(type?.object.stageSummary.stage?.name.toLowerCase());
    })
    stageSummary: T;
    retractType: VoteRetractType;
    stageChanged: boolean;
}