import React, {useCallback, useMemo, useRef} from 'react';
import {Avatar, Icon, RichTextClassificationConfig} from '@ideascale/ui';
import uniqBy from 'lodash/uniqBy';
import {
    AttachmentAttributeParameters,
    ClassificationAttachmentAttribute,
    ClassificationLabel,
    ClassificationsHolder,
    ClassificationUtil,
    ConfirmationModal,
    FileAttachmentDetail,
    FileUploadResponse,
    LabelData,
    Localizer,
    Member,
    TimeAgo,
    UnfurledUrl,
    UnfurledUrlList,
    UploadProgressCallback,
    useToggle
} from '@ideascale/commons';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useIdeaAttachment} from 'hooks/useIdeaAttachment';
import {useAppContext} from 'contexts/AppContext';
import {CommentVote} from './CommentVote';
import {CommentActions} from './CommentActions';
import {ReplyForm} from './ReplyForm';
import {LabelsView} from 'shared/LabelsView';
import {Scroller} from 'utils/Scroller';
import {AttachmentForm} from './AttachmentForm';
import {EditForm} from './EditForm';
import {CommentMeta} from './CommentMeta';
import {AttachmentPreview} from 'components/idea-details-tabs/AttachmentPreview';
import {CommentApproval} from './CommentApproval';
import {HtmlRenderer} from '../shared/HtmlRenderer';
import {CommentSummary} from 'models/comments/CommentSummary';
import {CommentLevel} from 'models/comments/CommentLevel';
import {CommentLabelsResponse} from 'models/comments/CommentLabelsResponse';
import {KudoActivitySummary} from 'models/KudoActivitySummary';
import {AttachmentFormInputs} from 'models/comments/AttachmentFormInputs';
import {ID_PREFIXES} from 'constants/AppConstants';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {StageFunctionName} from 'models/enums/StageFunctionName';
import {OriginalComment} from 'models/comments/OriginalComment';
import {FileAttachmentsHolder} from 'models/FileAttachmentsHolder';
import styles from './Comment.module.scss';

type ReplyCommentProps = {
    idPrefix: string;
    parentComment: CommentSummary;
    localizer: Localizer;
    ideaId: number;
    labelsPermitted: boolean;
    comment: CommentSummary;
    className?: string;
    deleteAttachment: (comment: CommentSummary, attachmentId: number, commentLevel: CommentLevel) => void;
    onReplyComment?: (comment: CommentSummary, replyComment: CommentSummary, replyLevel: CommentLevel) => void;
    onAddFileAttachments: (commentSummary: CommentSummary, attachments: FileAttachmentDetail[], commentLevel: CommentLevel, attributes?: ClassificationAttachmentAttribute[]) => void;
    editComment?: (comment: CommentSummary, commentLevel: CommentLevel) => void;
    deleteComment: (comment: CommentSummary, commentLevel: CommentLevel) => void;
    annotateComment: (commentId: number, commentLevel: CommentLevel) => void;
    commentLevel: CommentLevel;
    fetchCommentLabelsById: (commentId: number) => Promise<CommentLabelsResponse>;
    addLabel: (commentId: number, labelData: LabelData) => Promise<LabelData[]>;
    removeLabel: (commentId: number, labelData: LabelData) => Promise<void>;
    upVote: (commentSummary: CommentSummary) => void;
    downVote: (commentSummary: CommentSummary) => void;
    approveComment: (commentSummary: CommentSummary) => void;
    rejectComment: (commentSummary: CommentSummary) => void;
    giveKudo: (commentId: number) => Promise<KudoActivitySummary>;
    reportAbuse: (comment: CommentSummary) => void;
    fetchKudoGivers: (pageParameters: PageParameters, commentId: number) => Promise<PagedResponseContent<Member>>;
    fetchOriginalComment: (commentId: number) => Promise<OriginalComment>;
    uploadFile: (data: FormData, onUploadProgress: UploadProgressCallback) => Promise<FileUploadResponse>;
    addAttachments: (commentId: number, formInputs: AttachmentFormInputs, attributeApiParameters?: Array<AttachmentAttributeParameters>) => Promise<FileAttachmentsHolder>;
    unfurlUrl: (url: string) => Promise<UnfurledUrl>;
    fetchEditComment: (commentId: number) => Promise<CommentSummary>;
    classificationsMenuData?: ClassificationsHolder | {};
    richTextClassificationConfig?: RichTextClassificationConfig;
}

export const ReplyComment = (props: ReplyCommentProps) => {
    const {
        idPrefix,
        localizer,
        comment,
        className = '',
        ideaId,
        parentComment,
        labelsPermitted,
        onReplyComment,
        onAddFileAttachments,
        editComment,
        deleteAttachment,
        commentLevel,
        deleteComment,
        annotateComment,
        fetchCommentLabelsById,
        addLabel,
        removeLabel,
        upVote,
        downVote,
        approveComment,
        rejectComment,
        giveKudo,
        reportAbuse,
        fetchKudoGivers,
        fetchOriginalComment,
        uploadFile,
        addAttachments,
        unfurlUrl,
        fetchEditComment,
        classificationsMenuData,
        richTextClassificationConfig
    } = props;
    const {author, text, upVoteCount, downVoteCount, createdAt, attachments} = comment;
    const {
        authentication: {actor},
        communityConfig: {maxFileSizeLimit, classificationEnabled, unfurlUrlEnabled}
    } = useAppContext();
    const {updateIdeaDetailsCommentAttachments} = useIdeaAttachment();
    const [showReplyForm, toggleReplyForm] = useToggle(false);
    const [showReplies, toggleReplies] = useToggle(true);
    const [showAttachmentForm, toggleAttachmentForm] = useToggle(false);
    const [showEditForm, toggleEditForm] = useToggle(false);
    const [showDeleteConfirmation, toggleDeleteConfirmation] = useToggle(false);
    const [showRejectConfirmation, toggleRejectConfirmation] = useToggle(false);
    const [showReportAbuseConfirmation, toggleReportAbuseConfirmation] = useToggle(false);
    const [showDeleteAttachmentConfirmation, toggleDeleteAttachmentConfirmation] = useToggle(false);
    const deleteAttachmentId = useRef<number>(0);

    const onShowRepliesComments = () => {
        toggleReplies(true);
    };

    const scrollToForm = () => {
        const timeOutId = setTimeout(() => {
            Scroller.scrollTo(`${idPrefix}-${ID_PREFIXES.REPLY_FORM}-${comment.id}`, {offset: -300, duration: 300});
            clearTimeout(timeOutId);
        }, 100);
    };

    const scrollToReplyComment = () => {
        const timeOutId = setTimeout(() => {
            Scroller.scrollTo(`${idPrefix}-${comment.id}`, {offset: -300, duration: 300});
            clearTimeout(timeOutId);
        }, 100);
    };

    const onReplyClick = () => {
        toggleReplyForm(true);
        scrollToForm();
    };

    const onAttachmentClick = () => {
        toggleAttachmentForm(true);
        toggleReplyForm(false);
        scrollToForm();
    };

    const onSubmitReplyClick = (replyComment: CommentSummary) => {
        toggleReplyForm(false);
        onReplyComment && onReplyComment(parentComment, replyComment, CommentLevel.Level2);
        toggleReplies(true);
    };

    const onAddAttachments = useCallback(async (commentSummary: CommentSummary, attachments: FileAttachmentDetail[], commentLevel: CommentLevel, attributes?: ClassificationAttachmentAttribute[]) => {
        onAddFileAttachments && onAddFileAttachments(commentSummary, attachments, commentLevel, attributes);
        toggleAttachmentForm(false);

        const newAttachments = attachments.filter(item => !comment.attachments?.map(file => file.filename).includes(item.filename));
        if (comment.annotated && newAttachments?.length > 0) {
            await updateIdeaDetailsCommentAttachments(ideaId, newAttachments, (attributes?.filter(item => item.name === 'filename') as ClassificationAttachmentAttribute[]));
        }
    }, [comment.annotated, comment.attachments, ideaId, onAddFileAttachments, toggleAttachmentForm, updateIdeaDetailsCommentAttachments]);

    const onDeleteAttachment = async (commentSummary: CommentSummary, attachmentId: number, commentLevel: CommentLevel) => {
        deleteAttachment(commentSummary, attachmentId, commentLevel);
        if (comment.annotated) {
            await updateIdeaDetailsCommentAttachments(ideaId, [], [], attachmentId);
        }
    };

    const onEditComment = (comment: CommentSummary, commentLevel: CommentLevel) => {
        editComment && editComment(comment, commentLevel);
        toggleEditForm(false);
    };

    const onReplyCancel = () => {
        toggleReplyForm();
        scrollToReplyComment();
    };

    const onEditCancel = () => {
        toggleEditForm();
        scrollToReplyComment();
    };

    const onAttachmentCancel = () => {
        toggleAttachmentForm();
        scrollToReplyComment();
    };

    const getLastThreeReplier = useMemo(() => {
        const allReplier = comment?.replies?.map(reply => reply.author);
        return uniqBy(allReplier, 'id').slice(-3).reverse();
    }, [comment.replies]);

    const getLastReply = useCallback(() => {
        return comment.replies[comment.replies.length - 1];
    }, [comment.replies]);

    return (
        <article id={`${idPrefix}-${comment.id}`} className={`${styles.comment} ${className}`}>
            <h3 className="sr-only">{localizer.msg('comments.comment-title')}</h3>
            <div className="d-flex">
                {
                    !showEditForm && (
                        <Avatar className="me-2 me-lg-4" src={author.avatar} size="md"
                                alt={author.username ? localizer.msg('common.user-avatar', {username: author.username}) : ''}/>
                    )
                }
                <div className="flex-grow-1">
                    {
                        showEditForm && (
                            <EditForm id={`${idPrefix}-${ID_PREFIXES.REPLY_FORM}-${comment.id}`} comment={comment}
                                      parentId={parentComment.id}
                                      localizer={localizer} ideaId={ideaId}
                                      onEditCancel={onEditCancel}
                                      onEditComment={(comment) => onEditComment(comment, commentLevel)}
                                      fetchEditComment={fetchEditComment}
                                      classificationsMenuData={classificationsMenuData}
                                      richTextClassificationConfig={richTextClassificationConfig}/>
                        )
                    }
                    {
                        !showEditForm && (
                            <>
                                <CommentMeta elementId={`${idPrefix}-${comment.id}`} comment={comment}
                                             localizer={localizer} actor={actor}
                                             createdAt={createdAt}
                                             onGiveKudo={giveKudo} fetchKudoGivers={fetchKudoGivers}/>
                                <div className="comment-labels mt-2">
                                    <LabelsView labels={comment.labels} showStackView={false}/>
                                </div>
                                {
                                    classificationEnabled && comment.attributes &&
                                    <ClassificationLabel
                                        classification={ClassificationUtil.getEffectiveAttribute(comment.attributes)}/>
                                }
                                <div className="comment-content mt-2">
                                    <div
                                        className={`${comment.stage.toLowerCase() === StageFunctionName.PENDING ? 'approval-pending' : ''}`}>
                                        <HtmlRenderer content={text} reactOptions={{atMention: true}}/>
                                    </div>
                                    {
                                        comment.reviewTranslationUrl && (
                                            <a className="btn btn-link px-0 shadow-none d-inline-block"
                                               href={comment.reviewTranslationUrl}>
                                                {localizer.msg('comments.actions.review-translation')}
                                            </a>
                                        )
                                    }
                                    <UnfurledUrlList content={text} unfurlUrl={unfurlUrl} localizer={localizer}
                                                     enabled={unfurlUrlEnabled}/>
                                </div>
                                {
                                    (!comment.actions?.approvalAllowed || !comment.actions?.rejectionAllowed) && (
                                        <CommentVote
                                            upVoteCount={upVoteCount}
                                            downVoteCount={downVoteCount}
                                            onUpVoteCount={() => upVote(comment)}
                                            onDownVoteCount={() => downVote(comment)}
                                            upVoteEnabled={actor.id !== comment.author.id}
                                            downVoteEnabled={actor.id !== comment.author.id}
                                        />
                                    )
                                }
                            </>
                        )
                    }
                    <AttachmentPreview className="border-bottom-0" attachments={attachments} localizer={localizer}
                                       onDeleteAttachment={(attachmentId) => {
                                           deleteAttachmentId.current = attachmentId;
                                           toggleDeleteAttachmentConfirmation(true);
                                       }}
                                       enabledClassification={classificationEnabled}
                                       fileAttributes={ClassificationUtil.getAppliedFileClassifications('filename', comment.attributes)}/>
                    {
                        (comment.actions?.approvalAllowed || comment.actions?.rejectionAllowed) && (
                            <CommentApproval
                                localizer={localizer}
                                approvalAllowed={comment.actions.approvalAllowed}
                                rejectionAllowed={comment.actions.rejectionAllowed}
                                onApproveClick={() => approveComment(comment)}
                                onRejectClick={() => toggleRejectConfirmation(true)}/>
                        )
                    }
                    {
                        !showReplies && comment.replies?.length > 0 &&
                        <div className="replies-summary d-flex align-items-center mt-3">
                            <div className="multiple-avatar">
                                {
                                    getLastThreeReplier.map((commenter, index) => (
                                        <img key={index} className="avatar avatar-sm"
                                             src={commenter.avatar}
                                             alt={commenter.name}/>
                                    ))
                                }
                            </div>
                            <button className="btn btn-link px-2"
                                    onClick={onShowRepliesComments}>{localizer.msg('comments.reply', {count: comment?.replies.length})}</button>
                            <span>
                                {`${localizer.msg('comments.last-reply')} `}<TimeAgo localizer={localizer}
                                                                                     dateISOString={getLastReply().updatedAt}/>
                            </span>
                        </div>
                    }
                    {
                        showReplies && comment.replies?.length > 0 && <>
                            <button
                                className="btn btn-link text-decoration-none d-flex w-100 hide-replies px-3 align-items-center justify-content-between mt-3"
                                onClick={() => toggleReplies(false)}>
                                <span>{localizer.msg('comments.hide-replies')}</span>
                                <Icon iconSpritePath={svgIconsPath} width={12} height={12}
                                      name="chevron-down"/>
                            </button>
                            <ul className="list-unstyled child-comments">
                                {
                                    (
                                        comment?.replies?.map(reply => (
                                            <li key={reply.id}>
                                                <ReplyComment
                                                    localizer={localizer} comment={reply} ideaId={ideaId}
                                                    parentComment={parentComment}
                                                    labelsPermitted={labelsPermitted}
                                                    onAddFileAttachments={(commentSummary, attachments, commentLevel, attributes) => onAddAttachments(commentSummary, attachments, commentLevel, attributes)}
                                                    deleteAttachment={(commentSummary, attachmentId, commentLevel) => onDeleteAttachment(commentSummary, attachmentId, commentLevel)}
                                                    commentLevel={CommentLevel.Level3}
                                                    deleteComment={deleteComment}
                                                    annotateComment={annotateComment}
                                                    fetchCommentLabelsById={fetchCommentLabelsById}
                                                    addLabel={addLabel}
                                                    removeLabel={removeLabel}
                                                    upVote={upVote}
                                                    downVote={downVote}
                                                    approveComment={approveComment}
                                                    rejectComment={rejectComment}
                                                    giveKudo={giveKudo}
                                                    reportAbuse={reportAbuse}
                                                    idPrefix={`${idPrefix}-${reply.id}`}
                                                    fetchKudoGivers={fetchKudoGivers}
                                                    fetchOriginalComment={fetchOriginalComment}
                                                    uploadFile={uploadFile}
                                                    addAttachments={addAttachments}
                                                    unfurlUrl={unfurlUrl}
                                                    fetchEditComment={fetchEditComment}
                                                    richTextClassificationConfig={richTextClassificationConfig}
                                                    classificationsMenuData={classificationsMenuData}
                                                />
                                            </li>
                                        ))
                                    )
                                }
                            </ul>
                        </>
                    }

                    {
                        !showEditForm && comment.actions && (
                            <CommentActions
                                annotated={comment.annotated}
                                onReplyClick={onReplyClick}
                                actionsPermitted={comment.actions}
                                labelsPermitted={labelsPermitted}
                                onAttachmentClick={onAttachmentClick}
                                onEditClick={() => {
                                    toggleEditForm(true);
                                    scrollToForm();
                                }}
                                onDeleteClick={() => toggleDeleteConfirmation(true)}
                                onAnnotateClick={() => annotateComment(comment.id, commentLevel)}
                                commentId={comment.id}
                                localizer={localizer}
                                fetchCommentLabelsById={fetchCommentLabelsById}
                                onAddLabelClick={addLabel}
                                onRemoveLabelClick={removeLabel}
                                onReportAbuseClick={() => toggleReportAbuseConfirmation(true)}
                            />
                        )
                    }
                    {
                        showReplyForm &&
                        <ReplyForm id={`${idPrefix}-${ID_PREFIXES.REPLY_FORM}-${comment.id}`} localizer={localizer}
                                   actor={actor}
                                   onSubmitReply={onSubmitReplyClick}
                                   ideaId={ideaId} replyId={comment.id} parentId={parentComment.id}
                                   commentAuthor={comment.author}
                                   onReplyCancel={onReplyCancel}
                                   privateComment={comment.privateComment}
                                   classificationsMenuData={classificationsMenuData}
                                   richTextClassificationConfig={richTextClassificationConfig}
                        />
                    }

                    {
                        showAttachmentForm &&
                        <AttachmentForm id={`${idPrefix}-${ID_PREFIXES.REPLY_FORM}-${comment.id}`} localizer={localizer}
                                        maxFileSizeLimit={maxFileSizeLimit}
                                        commentId={comment.id}
                                        uploadFile={uploadFile}
                                        onAttachmentCancel={onAttachmentCancel}
                                        addAttachments={addAttachments}
                                        classificationsMenuData={classificationsMenuData}
                                        classificationEnabled={classificationEnabled}
                                        onAddAttachments={(commentId, attachments, attributes) => onAddAttachments(comment, attachments, commentLevel, attributes)}/>
                    }

                    {
                        showDeleteConfirmation && (
                            <ConfirmationModal localizer={localizer} message={localizer.msg('comments.confirm-message')}
                                               open={showDeleteConfirmation} toggle={toggleDeleteConfirmation}
                                               onConfirm={() => deleteComment(comment, commentLevel)}/>
                        )
                    }

                    {
                        showRejectConfirmation && (
                            <ConfirmationModal localizer={localizer} message={localizer.msg('comments.confirm-message')}
                                               open={showRejectConfirmation} toggle={toggleRejectConfirmation}
                                               onConfirm={() => rejectComment(comment)}/>
                        )
                    }

                    {
                        showReportAbuseConfirmation && (
                            <ConfirmationModal localizer={localizer}
                                               message={localizer.msg('comments.comment-abused-confirm')}
                                               open={showReportAbuseConfirmation} toggle={toggleReportAbuseConfirmation}
                                               onConfirm={() => reportAbuse(comment)}/>
                        )
                    }

                    {
                        showDeleteAttachmentConfirmation && (
                            <ConfirmationModal localizer={localizer} message={localizer.msg('comments.confirm-message')}
                                               open={showDeleteAttachmentConfirmation}
                                               toggle={toggleDeleteAttachmentConfirmation}
                                               onConfirm={() => {
                                                   onDeleteAttachment(comment, deleteAttachmentId.current, commentLevel).then();
                                                   deleteAttachmentId.current = 0;
                                               }}/>
                        )
                    }
                </div>
            </div>
        </article>
    );
};
