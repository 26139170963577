import React, {Fragment, useEffect, useState} from 'react';
import {ParagraphSkeleton} from '@ideascale/ui';
import {Link} from 'react-router-dom';
import {eventDispatcher} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {LinkedTag} from '../../shared/LinkedTag';
import {SCROLL_EVENTS} from 'constants/AppConstants';
import {appLinks} from 'services/AppLinks';
import {useAppContext} from 'contexts/AppContext';
import {TagStatsHolder} from 'models/TagStatsHolder';

type tagsModuleData = {
    fetchTags: (campaignId?: number, stageId?: number, limit?: number) => Promise<TagStatsHolder>;
    campaignId?: number;
    stageId?: number;
    limit?: number;
    manageLink: string;
}

export const TagsModule = (props: tagsModuleData) => {
    const {fetchTags, campaignId, stageId, limit, manageLink} = props;
    const [tagStatsHolder, setTagStatsHolder] = useState<TagStatsHolder>(TagStatsHolder.EMPTY);
    const [loading, setLoading] = useState(true);
    const localizer = useLocalizer();
    const {currentCampaign} = useAppContext();

    useEffect(() => {
        fetchTags(currentCampaign?.id, stageId, limit).then(response => {
            setTagStatsHolder(response);
            setLoading(false);
        });
    }, [campaignId, currentCampaign?.id, fetchTags, limit, stageId]);

    return (
        <Fragment>
            {loading ?
                <ParagraphSkeleton rows={6}/>
                :
                (tagStatsHolder?.tags?.length > 0
                        ?
                        <ul className="list-inline">
                            {tagStatsHolder.tags.map((tag, index) => {
                                return (
                                    <li className="list-inline-item mb-1 me-2" key={index}>
                                        <LinkedTag item={tag} link={appLinks.tag(tag.label, currentCampaign?.id.toString())}/>
                                    </li>
                                );
                            })}
                        </ul>
                        :
                        <div className="mb-2">{localizer.msg('sidebar.tags.no-tags')}</div>
                )
            }
            {
                tagStatsHolder.viewAll &&
                <Link to={manageLink} onClick={() => eventDispatcher.dispatch(SCROLL_EVENTS.SCROLL_TOP)}>
                    {localizer.msg('sidebar.tags.view-all')}
                </Link>
            }
        </Fragment>
    );
};