import React, {Fragment} from 'react';
import {Outlet} from 'react-router-dom';
import {TopbarEditModeContainer} from './topbar/TopbarEditModeContainer';
import './LayoutEditModeContainer.scss';

export const LayoutEditModeContainer = () => {
    return (
        <Fragment>
            <TopbarEditModeContainer/>
            <div className="edit-mode-container edit-mode-body">
                <Outlet/>
            </div>
        </Fragment>
    );
};