import React from 'react';
import {Localizer} from '@ideascale/commons';
import {FieldValues} from 'react-hook-form';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useRouteUtils} from 'hooks/useRouteUtils';
import {CampaignRichTextEditForm} from './CampaignRichTextEditForm';
import {UpdateCampaignTosCommand} from 'commands/edit-mode/UpdateCampaignTosCommand';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';

const CAMPAIGN_FIELDS = {
    TOS: 'tosContent',
    ENABLE_TOS: 'tosEnabled'
};

type CampaignTosEditFormProps = {
    toggleModal: () => void;
    localizer: Localizer;
}

export const CampaignTosEditForm = (props: CampaignTosEditFormProps) => {
    const {toggleModal, localizer} = props;
    const {campaignHomeEditor, commandExecutor, validationErrors} = useEditModeContext();
    const {campaignRouteMatch} = useRouteUtils();

    const onSubmit = (data: FieldValues) => {
        const campaignId = (campaignRouteMatch?.params as any)?.campaignId;
        const command = new UpdateCampaignTosCommand(
            campaignHomeEditor,
            data[CAMPAIGN_FIELDS.TOS],
            data[CAMPAIGN_FIELDS.ENABLE_TOS],
            campaignId);

        commandExecutor.execute(command);
        validationErrors.clearError(command.getType());
        toggleModal();
    };

    return <CampaignRichTextEditForm localizer={localizer} toggleModal={toggleModal}
                                     defaultValue={campaignHomeEditor.tos.value} toolbarType="minimal"
                                     label={localizer.msg('terms-of-service.title')} fieldId={CAMPAIGN_FIELDS.TOS}
                                     operationType={CampaignOperationType.CHANGE_CAMPAIGN_TOS}
                                     onSubmit={onSubmit} switchFieldId={CAMPAIGN_FIELDS.ENABLE_TOS}
                                     switchLabel={localizer.msg('edit-mode.campaign.tos-enable')}
                                     defaultSwitchState={campaignHomeEditor.tos.tosEnabled}
                                     textRequired={true}/>;
};