import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {Button, Icon} from '@ideascale/ui';
import {ClassificationsHolder, Member, MessageModal, SharingMedium, useToggle} from '@ideascale/commons';
import iconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useAppContext} from 'contexts/AppContext';
import {useMessageService} from 'hooks/useMessageService';
import {useLocalizer} from 'hooks/useLocalizer';
import {useSocialShareServices} from 'hooks/useSocialShareServices';
import {useFileUploadService} from 'hooks/useFileUploadService';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {SharingMedia} from 'models/SharingMedia';
import {appLinks} from 'services/AppLinks';
import './SocialShare.scss';

type SocialShareProps = {
    fetchSidebarSocialShare: () => Promise<Array<SharingMedia>>;
    fetchRecipients(pageParameters: PageParameters): Promise<PagedResponseContent<Member>>;
    fetchClassifications: () => Promise<ClassificationsHolder>;
}

export const SocialShare = (props: SocialShareProps) => {
    const {fetchSidebarSocialShare, fetchRecipients, fetchClassifications} = props;
    const [sharingMedia, setSharingMedia] = useState<SharingMedia[]>([]);
    const localizer = useLocalizer();
    const {tempImageUpload} = useFileUploadService();
    const {onMemberSendMessages} = useMessageService();
    const [messageOpen, toggleMessageOpen] = useToggle(false);
    const {
        communityConfig: {
            name: communityName,
            offensiveEmojis,
            maxFileSizeLimit,
            classificationEnabled,
            url: communityUrl,
            whiteLabeled,
            privateMessageMaxRecipients,
            privateMessageMaxSubjectLength,
            privateMessageEnabled
        }
    } = useAppContext();
    const {facebookShare, twitterShare, linkedinShare} = useSocialShareServices();

    useEffect(() => {
        fetchSidebarSocialShare().then(data => setSharingMedia(data));
    }, [fetchSidebarSocialShare]);

    const communityShareUrl = useMemo(() => {
        return communityUrl + appLinks.communityShare();
    }, [communityUrl]);

    const onShare = (media: SharingMedia) => {
        switch (media.id) {
            case SharingMedium.FACEBOOK :
                return facebookShare(sharingMedia, communityShareUrl);
            case SharingMedium.TWITTER:
                return twitterShare(true, communityUrl, communityName, communityShareUrl, sharingMedia, whiteLabeled);
            case SharingMedium.LINKEDIN:
                return linkedinShare(sharingMedia, communityShareUrl);
            default:
                return '#';
        }
    };

    return (
        <Fragment>
            <ul className="list-unstyled list-inline mb-0">
                {
                    sharingMedia.map((media, index) => {
                        return (
                            <Fragment key={index}>
                                {
                                    (privateMessageEnabled && media.id === SharingMedium.EMAIL) &&
                                    <li className="list-inline-item">
                                        <Button color="link" className="p-0" onClick={() => toggleMessageOpen(true)}
                                                aria-label={'Email'} title={media.name}>
                                            <Icon name="envelope-solid-circle" iconSpritePath={iconPath} fill={'#384EC1'}
                                                  width={35} height={35}/>
                                            <span className="sr-only">Email</span>
                                        </Button>
                                    </li>
                                }
                                {
                                    media.id !== SharingMedium.EMAIL &&
                                    <li className="list-inline-item">
                                        <a href={onShare(media)} target="_blank" rel="noreferrer" title={media.name}>
                                            <Icon name={`${media.name.toLowerCase()}-solid-circle`} iconSpritePath={iconPath} width={35}
                                                  height={35}/>
                                            <span className="sr-only">{media.name.toLowerCase()}</span>
                                        </a>
                                    </li>
                                }
                            </Fragment>
                        );
                    })
                }
            </ul>
            {
                messageOpen &&
                <MessageModal fetchClassifications={fetchClassifications} open={messageOpen}
                              toggle={toggleMessageOpen}
                              fetchRecipients={fetchRecipients}
                              tempImageUpload={tempImageUpload}
                              localizer={localizer}
                              onMemberSendMessages={onMemberSendMessages}
                              communityName={communityName}
                              offensiveEmojis={offensiveEmojis}
                              maxFileSizeLimit={maxFileSizeLimit}
                              maxSubjectLength={privateMessageMaxSubjectLength}
                              classificationEnabled={classificationEnabled}
                              privateMessageMaxRecipients={privateMessageMaxRecipients}/>
            }
        </Fragment>
    );
};