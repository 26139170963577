import React from 'react';
import {Link} from 'react-router-dom';
import {Tag as IdeascaleTag} from '@ideascale/ui';
import {eventDispatcher, thousandFormat} from '@ideascale/commons';
import {TagData} from 'models/TagData';
import {SCROLL_EVENTS} from 'constants/AppConstants';

type TagProps = {
    item: TagData,
    link: string
}

export const LinkedTag = (props: TagProps) => {
    const {item, link} = props;
    return (
        <Link to={link} onClick={() => eventDispatcher.dispatch(SCROLL_EVENTS.SCROLL_TOP)}
              className="text-decoration-none me-1">
            <IdeascaleTag label={item.label} badge={thousandFormat(item.taggedCount) || ''}/>
        </Link>
    );
};

