import {useMemo} from 'react';
import {useLocalizer} from './useLocalizer';

export const useExpiryMessage = () => {
    const localizer = useLocalizer();
    return useMemo(() => {
        return {
            expired: localizer.msg('campaign.expiry.expired'),
            timeLeft: localizer.msg('campaign.expiry.time-left'),
            daysLeft: localizer.msg('campaign.expiry.days', {days: '{{time}}'}),
            hoursLeft: localizer.msg('campaign.expiry.hours', {hours: '{{time}}'}),
            minutesLeft: localizer.msg('campaign.expiry.minutes', {minutes: '{{time}}'})
        };
    }, [localizer]);
};
