import {ReviewFactor} from './ReviewFactor';
import {ReviewNote} from './ReviewNote';

export class ReviewNotesResponse {
    static readonly EMPTY = {valueFactors: [], costFactors: [], valueNotes: [], costNotes: []};

    valueFactors: ReviewFactor[];
    costFactors: ReviewFactor[];
    valueNotes: ReviewNote[];
    costNotes: ReviewNote[];
}