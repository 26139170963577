import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useApiErrorResponseHandler} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from './useLocalizer';
import {useCampaignModuleService} from './useCampaignModuleService';
import {IdeaListRouteMatchParams} from 'models/types/IdeaListRouteMatchParams';
import {isNil} from 'lodash';

export const useUpdateCurrentCampaign = () => {
    const params = useParams<IdeaListRouteMatchParams>();
    const {currentCampaign, setCurrentCampaign} = useAppContext();
    const localizer = useLocalizer();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});

    const {fetchCampaignSummary} = useCampaignModuleService();

    useEffect(() => {
        if (isNil(params?.campaignId)) {
            setCurrentCampaign(null);
        } else if (currentCampaign?.id !== +params.campaignId) {
            (async () => {
                try {
                    const campaign = await fetchCampaignSummary(+params?.campaignId!);
                    setCurrentCampaign(campaign);
                } catch (e: any) {
                    handleErrorResponse(e);
                }
            })();
        }
    }, [currentCampaign?.id, fetchCampaignSummary, handleErrorResponse, params.campaignId, setCurrentCampaign]);
};