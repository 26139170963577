import {useCallback} from 'react';
import {PageTheme} from '@ideascale/commons';
import {Component} from 'models/landing-page/Component';
import {LandingPageComponentType} from 'models/enums/landing-page/LandingPageComponentType';
import {HeroComponentData} from 'models/landing-page/HeroComponentData';
import {HeroType} from 'models/enums/landing-page/HeroType';
import armstrong_hero_general from 'assets/img/landing-page/armstrong/hero-general-default.png';
import armstrong_hero_secondary_1 from 'assets/img/landing-page/armstrong/hero-secondary-default-1.png';
import armstrong_hero_secondary_2 from 'assets/img/landing-page/armstrong/hero-secondary-default-2.png';
import armstrong_instruction_1 from 'assets/img/landing-page/armstrong/instruction-list-default-1.png';
import armstrong_instruction_2 from 'assets/img/landing-page/armstrong/instruction-list-default-2.png';
import armstrong_instruction_3 from 'assets/img/landing-page/armstrong/instruction-list-default-3.png';
import berners_hero_general_1 from 'assets/img/landing-page/berners-lee/hero-general-default-1.png';
import berners_hero_general_2 from 'assets/img/landing-page/berners-lee/hero-general-default-2.png';
import berners_lee_instruction_1 from 'assets/img/landing-page/berners-lee/instruction-list-default-1.png';
import berners_lee_instruction_2 from 'assets/img/landing-page/berners-lee/instruction-list-default-2.png';
import berners_lee_instruction_3 from 'assets/img/landing-page/berners-lee/instruction-list-default-3.png';
import curie_hero_primary from 'assets/img/landing-page/curie/hero-primary-default.png';
import curie_hero_general_1 from 'assets/img/landing-page/curie/hero-general-default-1.png';
import curie_hero_general_2 from 'assets/img/landing-page/curie/hero-general-default-2.png';
import curie_instruction_1 from 'assets/img/landing-page/curie/instruction-list-default-1.png';
import curie_instruction_2 from 'assets/img/landing-page/curie/instruction-list-default-2.png';
import curie_instruction_3 from 'assets/img/landing-page/curie/instruction-list-default-3.png';
import eastman_instruction_1 from 'assets/img/landing-page/eastman/instruction-list-default-1.png';
import eastman_instruction_2 from 'assets/img/landing-page/eastman/instruction-list-default-2.png';
import eastman_instruction_3 from 'assets/img/landing-page/eastman/instruction-list-default-3.png';
import eastman_hero_primary from 'assets/img/landing-page/eastman/hero-primary-default.png';


export const useDefaultImage = (theme: PageTheme) => {

    const getDefaultImageIndex = useCallback((url: string) => {
        if (url) {
            const lastDigit = url[url.length - 1];
            if (!isNaN(Number(lastDigit))) {
                return Number(url[url.length - 1]) - 1;
            }
        }
        return 0;
    }, []);

    const containsDefaultImage = useCallback((url: string) => {
        const regex = /^default(_\d+)*$/gi;
        return regex.test(url);
    }, []);

    const getHeroImages = useCallback((componentData: Component) => {
        const heroData = componentData as HeroComponentData;

        switch (theme) {
            case PageTheme.ARMSTRONG:
                switch (heroData.heroType) {
                    case HeroType.SECONDARY:
                        return [armstrong_hero_secondary_1, armstrong_hero_secondary_2];
                    case HeroType.PRIMARY:
                    case HeroType.GENERAL:
                    default:
                        return [armstrong_hero_general];
                }
            case PageTheme.BERNERS_LEE:
                switch (heroData.heroType) {
                    case HeroType.GENERAL:
                    default:
                        const index = getDefaultImageIndex(heroData.medias[0].url);
                        const image = [berners_hero_general_1, berners_hero_general_2][index];
                        return [image];
                }
            case PageTheme.EASTMAN:
                switch (heroData.heroType) {
                    case HeroType.PRIMARY:
                    default:
                        return [eastman_hero_primary];
                }
            case PageTheme.CURIE:
            default:
                switch (heroData.heroType) {
                    case HeroType.GENERAL:
                        const index = getDefaultImageIndex(heroData.medias[0].url);
                        const image = [curie_hero_general_1, curie_hero_general_2][index];
                        return [image];
                    case HeroType.PRIMARY:
                    default:
                        return [curie_hero_primary];
                }
        }
    }, [getDefaultImageIndex, theme]);

    const getInstructionImages = useCallback(() => {
        switch (theme) {
            case PageTheme.ARMSTRONG:
                return [armstrong_instruction_1, armstrong_instruction_2, armstrong_instruction_3];
            case PageTheme.BERNERS_LEE:
                return [berners_lee_instruction_1, berners_lee_instruction_2, berners_lee_instruction_3];
            case PageTheme.EASTMAN:
                return [eastman_instruction_1, eastman_instruction_2, eastman_instruction_3];
            case PageTheme.CURIE:
            default:
                return [curie_instruction_1, curie_instruction_2, curie_instruction_3];
        }
    }, [theme]);


    const getDefaultImages = useCallback((componentData: Component) => {
        switch (componentData.type) {
            case LandingPageComponentType.HERO:
                return getHeroImages(componentData);
            case LandingPageComponentType.INSTRUCTIONS:
            default:
                return getInstructionImages();
        }
    }, [getHeroImages, getInstructionImages])

    return {getDefaultImages, containsDefaultImage, getDefaultImageIndex};
};