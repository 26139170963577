import {useIdeaUpdater} from './useIdeaUpdater';
import {useCallback} from 'react';

export const useIdeaPinToLandingPage = (ideaListQueryKey: any = '') => {
    const {updateIdea, updateIdeaDetails} = useIdeaUpdater();

    const ideaListIdeaPinToLandingPageMutation = useCallback(async (ideaId: number, pinned: boolean) => {
        await updateIdea(ideaListQueryKey, ideaId, 'pinned', pinned);
    }, [ideaListQueryKey, updateIdea]);

    const ideaDetailsIdeaPinToLandingPageMutation = useCallback(async (ideaId: number, pinned: boolean) => {
        await updateIdeaDetails(ideaId, 'pinned', pinned);
    }, [updateIdeaDetails]);

    return {ideaListIdeaPinToLandingPageMutation, ideaDetailsIdeaPinToLandingPageMutation};
};