import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Trans} from 'react-i18next';
import {NotificationData} from 'models/NotificationData';
import {appLinks} from 'services/AppLinks';

type IdeaOwnerAssignedNotificationProps = {
    actorId: number;
    notification: NotificationData;
}

export const IdeaOwnerAssignedNotification = (props: IdeaOwnerAssignedNotificationProps) => {
    const {actorId, notification} = props;

    const actorBecameIdeaOwner = actorId === notification.targetMemberId;

    return (
        <Fragment>
            {
                actorBecameIdeaOwner ?
                    <Trans i18nKey="notification.idea.owner.assign-self" values={{
                        idea: notification.viewAttributes.ideaTitle
                    }}>
                        <Link to={appLinks.ideaDetails(notification.ideaId)}/>
                    </Trans>
                    : <Trans i18nKey="notification.idea.owner.assign-others" values={{
                        member: notification.viewAttributes.assignedMemberName,
                        idea: notification.viewAttributes.ideaTitle
                    }}>
                        <Link to={appLinks.profile(notification.targetMemberId)}/>
                        <Link to={appLinks.ideaDetails(notification.ideaId)}/>
                    </Trans>
            }
        </Fragment>
    );
};