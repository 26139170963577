import React from 'react';
import {ParagraphSkeleton, Skeleton} from '@ideascale/ui';
import {Localizer, PageTheme} from '@ideascale/commons';
import {CampaignDisplayType} from 'models/enums/landing-page/CampaignDisplayType';

type CampaignCardSkeletonProps = {
    localizer: Localizer;
    theme: PageTheme;
    displayType: CampaignDisplayType;
}

export const CampaignCardSkeleton = (props: CampaignCardSkeletonProps) => {
    const {localizer, theme, displayType} = props;

    const renderSkeleton = () => {
        switch (theme) {
            case PageTheme.EASTMAN:
            case PageTheme.ARMSTRONG:
            case PageTheme.BERNERS_LEE:
            case PageTheme.CURIE:
            default:
                return (
                    <div className="card panel">
                        <div className={`card-body panel-body d-flex flex-column ${displayType === CampaignDisplayType.STACK ? 'flex-md-row p-0' : ''}`}>
                            <div className={`rounded ${displayType === CampaignDisplayType.STACK ? 'pe-3 h-100' : 'pb-3 w-100'}`}>
                                <Skeleton width={displayType === CampaignDisplayType.STACK ? "238px" : "100%"}
                                          height={displayType === CampaignDisplayType.STACK ? "160px" : "230px"}/>
                            </div>
                            <div className={`d-flex flex-column flex-grow-1 flex-shrink-1 ${displayType === CampaignDisplayType.STACK ? 'p-3' : ''}`}>
                                <div>
                                    <ParagraphSkeleton rows={1}/>
                                </div>
                                <div className="mt-3">
                                    <ParagraphSkeleton rows={1}/>
                                </div>
                                <button className="btn btn-primary mt-auto align-self-start" disabled>
                                    {localizer.msg('landing-page.components.common.view-campaigns')}
                                </button>
                            </div>
                        </div>
                    </div>
                );
        }
    };

    return renderSkeleton();
};