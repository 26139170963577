import {StageFunctionName} from 'models/enums/StageFunctionName';
import {IdeateStageSummary} from 'models/IdeateStageSummary';
import {BuildTeamStageSummary} from 'models/BuildTeamStageSummary';
import {RefineStageSummary} from 'models/RefineStageSummary';
import {EstimateStageSummary} from 'models/EstimateStageSummary';
import {ReviewScaleStageSummary} from 'models/ReviewScaleStageSummary';
import {FundStageSummary} from 'models/FundStageSummary';
import {AssessmentStageSummary} from 'models/AssessmentStageSummary';
import {ReserveStageSummary} from 'models/ReserveStageSummary';
import {PendingStageSummary} from 'models/PendingStageSummary';
import {BaseIdeaStageSummary} from 'models/BaseIdeaStageSummary';

export class ClassTransformerUtil {
    static getIdeaStageType(stageName: string) {
        switch (stageName) {
            case StageFunctionName.IDEATE:
                return IdeateStageSummary;
            case StageFunctionName.BUILD_TEAM:
                return BuildTeamStageSummary;
            case StageFunctionName.REFINE:
                return RefineStageSummary;
            case StageFunctionName.ESTIMATE:
                return EstimateStageSummary;
            case StageFunctionName.REVIEW_SCALE:
                return ReviewScaleStageSummary;
            case StageFunctionName.FUND:
                return FundStageSummary;
            case StageFunctionName.ASSESSMENT:
                return AssessmentStageSummary;
            case StageFunctionName.RESERVE:
                return ReserveStageSummary;
            case StageFunctionName.PENDING:
                return PendingStageSummary;
            default:
                return BaseIdeaStageSummary;
        }
    }
}