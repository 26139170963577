import React, {Dispatch, SetStateAction, useCallback} from 'react';
import {Controller} from 'react-hook-form';
import {Popover, PopoverBody} from 'reactstrap';
import {CustomInputGroup, Icon} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {Localizer, useHandleFormSubmit, useHookFormContext} from '@ideascale/commons';
import {TargetFormType} from 'models/stage-activity/FundTargetFormType';
import {FundStageSummary} from 'models/FundStageSummary';
import {FundStageActivity, NonEmptyFundUnitKey} from 'models/FundStageActivity';
import {FUND_REGEX_PATTERN} from 'constants/AppConstants';

type FundTargetPopoverProps = {
    localizer: Localizer;
    open: boolean;
    toggle: (nextValue?: any) => void;
    loading: boolean;
    targetId: string;
    containerId: string;
    onSubmit: (values: TargetFormType) => void;
    fundData: FundStageActivity;
    stageSummary: FundStageSummary;
    targetFieldDirty: boolean;
    setTargetFieldDirty: Dispatch<SetStateAction<boolean>>;
}

export const FundTargetPopover = (props: FundTargetPopoverProps) => {
    const {
        fundData: {ideaFundAccount, memberFundAccount},
        toggle,
        containerId,
        onSubmit,
        open,
        loading,
        targetId,
        localizer,
        stageSummary,
        targetFieldDirty,
        setTargetFieldDirty
    } = props;
    const {handleSubmit, control, reset} = useHookFormContext<TargetFormType>();
    const onFormSubmit = useHandleFormSubmit(handleSubmit);
    const targetFundUnit = ideaFundAccount?.fundUnit || memberFundAccount?.fundUnit || (stageSummary as FundStageSummary).fundUnit;
    const targetFundUnitKey = ideaFundAccount?.fundUnitKey || memberFundAccount?.fundUnitKey || (stageSummary as FundStageSummary).fundUnitKey;
    const hasFundUnitKey = targetFundUnitKey && Object.values(NonEmptyFundUnitKey).includes(targetFundUnitKey as NonEmptyFundUnitKey);

    const onToggleTargetPopover = useCallback(() => {
        reset({target: ideaFundAccount?.collectionTarget || 0});
        setTargetFieldDirty(false);
        toggle();
    }, [ideaFundAccount?.collectionTarget, reset, setTargetFieldDirty, toggle]);

    return (
        <Popover className="me-n2 fund-target-popover" trigger="click" placement="top" isOpen={open} toggle={toggle}
                 target={targetId} container={containerId}>
            <PopoverBody className="p-0">
                <div className="p-3">
                    <form className="d-flex align-items-center" id="edit-form" noValidate={true}
                          onSubmit={onFormSubmit(onSubmit, loading)}>
                        <label className="sr-only" htmlFor="target">
                            {localizer.msg('profile.activity.funds')}
                        </label>
                        <div className="me-2">
                            <Controller control={control}
                                        name="target"
                                        render={({field: {name, onChange, value}}) => (
                                            <CustomInputGroup size="default" appendGroup={
                                                targetFundUnit
                                                    ? `${targetFundUnit}${hasFundUnitKey ? `(${localizer.msg(targetFundUnitKey)})` : ''}`
                                                    : ''
                                            }>
                                                <input id={name} className="form-control"
                                                       value={value}
                                                       onChange={event => {
                                                           if (FUND_REGEX_PATTERN.test(event.target.value) || event.target.value === '') {
                                                               setTargetFieldDirty(true);
                                                               return onChange(event.target.value);
                                                           }
                                                       }}
                                                       onBlur={event => onChange(isNaN(+event.target.value) ? 0 : +event.target.value)}
                                                       autoFocus
                                                />
                                            </CustomInputGroup>
                                        )}/>
                        </div>
                        <div className="d-flex">
                            <button className="btn btn-sm btn-primary px-2 line-height-0" type="submit"
                                    disabled={!targetFieldDirty || loading}
                                    title={localizer.msg('common.select')}>
                                {
                                    loading
                                        ? <span
                                            className="spinner-border spinner-border-sm"/>
                                        : <Icon iconSpritePath={svgIconPath} name="check" fill="#fff"/>
                                }
                            </button>
                            <button className="btn btn-sm btn-light ms-2 px-2" type="button" disabled={loading}
                                    onClick={onToggleTargetPopover} title={localizer.msg('common.cancel')}>
                                <Icon iconSpritePath={svgIconPath} name="cross" width={13} height={13}/>
                            </button>
                        </div>
                    </form>
                </div>
            </PopoverBody>
        </Popover>
    );
};