export enum SidebarItemKey {
    CAMPAIGN_COUNTDOWN = 'campaign-countdown',
    STAGE_COUNTDOWN = 'stage-countdown',
    CAMPAIGN_ACTIVITY = 'campaign-activity',
    FUNNEL = 'funnel',
    ACTIVE_CAMPAIGNS = 'active-campaigns',
    ARCHIVED_CAMPAIGNS = 'archived-campaigns',
    KUDOS_TICKER = 'kudos-ticker',
    LEADERBOARD = 'leaderboard',
    CONTACT = 'contact',
    SOCIAL_SHARE = 'social-share',
    SOCIAL_SUBSCRIBE = 'social-subscribe',
    MODERATORS = 'moderators',
    ADMINISTRATORS = 'administrators',
    COMMUNITY_SUMMARY = 'community-widget',
    COMMUNITY_TAGS = 'community-tags',
    CAMPAIGN_TAGS = 'campaign-tags',
    ONLINE_LEARNING = 'online-learning',
    ARTICLES_RESOURCES = 'articles-resources',
    CAMPAIGN_WIDGET = 'campaign-widget',
    CAMPAIGN_CUSTOM_WIDGET_ONE = 'campaign-custom-widget-one',
    CAMPAIGN_CUSTOM_WIDGET_TWO = 'campaign-custom-widget-two',
    CAMPAIGN_CUSTOM_WIDGET_THREE = 'campaign-custom-widget-three',
    UNKNOWN = 'unknown'
}