import React, {Fragment} from 'react';
import {LayoutUtil, useToggle} from '@ideascale/commons';
import {CollapseExpand, Icon, Scrollbar} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useLocalizer} from 'hooks/useLocalizer';
import {EditBioModal} from 'components/profile/sidebar/EditBioModal';
import {HtmlRenderer} from 'components/shared/HtmlRenderer';
import {MemberBio} from 'models/MemberBio';
import styles from './Bio.module.scss';

type BioProps = {
    fetchMemberBio: () => Promise<MemberBio>;
    saveMemberBio: (memberId: number, content: string) => Promise<MemberBio>;
    bioContent: string;
    actorId: number;
    currentProfileId: number;
}

export const Bio = (props: BioProps) => {
    const {bioContent, currentProfileId, actorId, saveMemberBio, fetchMemberBio} = props;
    const localizer = useLocalizer();
    const selfProfile = currentProfileId === actorId;
    const [editBioModalIsOpen, toggleEditBioModalIsOpen] = useToggle(false);

    return (
        <Fragment>
            {(selfProfile || bioContent.length !== 0) &&
            <CollapseExpand
                containerClass={`profile-bio panel-light ${styles.profileBio}`}
                headerClass={selfProfile ? 'me-3' : ''}
                headerTitle={localizer.msg('profile.bio.heading')}
                svgIconSprite={svgIconPath}
                defaultOpen={!LayoutUtil.isMobileLayout()}>
                {
                    selfProfile &&
                    <button type="button" className="float-end btn btn-link p-0 btn-edit"
                            title={localizer.msg('profile.bio.edit-profile-bio')}
                            onClick={toggleEditBioModalIsOpen}>
                        <Icon iconSpritePath={svgIconPath} name="pencil"/>
                    </button>
                }
                <Scrollbar className="profile-bio-content" autoHeight autoHeightMax={300}>
                    <div className="border-0 d-flex justify-content-between align-items-center">
                        <div className="content w-100 pe-3">
                            <HtmlRenderer content={bioContent}/>
                        </div>
                    </div>
                </Scrollbar>
                {
                    (selfProfile && editBioModalIsOpen) &&
                    <EditBioModal
                        fetchMemberBio={fetchMemberBio}
                        saveMemberBio={saveMemberBio}
                        toggle={toggleEditBioModalIsOpen}
                        isOpen={editBioModalIsOpen}
                    />
                }
            </CollapseExpand>
            }
        </Fragment>
    );
};