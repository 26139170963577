import React from 'react';
import {Link} from 'react-router-dom';
import {Trans} from 'react-i18next';
import {MemberLink} from '@ideascale/commons';

type PositionAssignmentNotificationProps = {
    memberId: number;
    identityHidden: boolean;
    memberLinkText: string;
    ideaLink: string;
    ideaLinkText: string;
    messageKey: string;
    targetName: string;
    targetObject: React.ReactNode;
}

export const PositionAssignmentNotification = (props: PositionAssignmentNotificationProps) => {
    const {
        memberId,
        identityHidden,
        memberLinkText,
        ideaLink,
        ideaLinkText,
        messageKey,
        targetObject,
        targetName
    } = props;

    return (
        <Trans i18nKey={messageKey} values={{
            sourceMember: memberLinkText,
            target: targetName,
            idea: ideaLinkText
        }}>
            <MemberLink id={memberId} identityHidden={identityHidden}/>
            {targetObject}
            <Link to={ideaLink}/>
        </Trans>
    );
};