import React from 'react';
import {Trans} from 'react-i18next';
import {Localizer, TimeAgo} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {BasicActivityCard} from '../idea/details/BasicActivityCard';
import {LabelActivity} from 'models/LabelActivity';
import {ContributionActivityType} from 'models/enums/ContributionActivityType';
import {ContributionActivitySource} from 'models/enums/ContributionActivitySource';
import './LabelChangeActivity.scss';

type LabelChangeStageActivityProps = {
    localizer: Localizer;
    labelChangeActivity: LabelActivity[];
}

export const LabelChangeActivity = (props: LabelChangeStageActivityProps) => {
    const {localizer, labelChangeActivity} = props;
    const {authentication: {actor}} = useAppContext();

    return (
        <div className="pb-3 label-change-activity">
            <BasicActivityCard headerTitle={localizer.msg('stage.common.label-activities')}>
                {labelChangeActivity.map(changeActivity => {
                    if (changeActivity.activityType === ContributionActivityType.LABEL_ADDED) {
                        if (changeActivity.activitySource === ContributionActivitySource.UPLOADED) {
                            return (
                                <div className="py-2" key={changeActivity.createdAt}>
                                    <Trans i18nKey="stage.common.label-imported" values={{
                                        label: changeActivity.label.name,
                                        member: changeActivity.member.id === actor.id ? localizer.msg('stage.common.changed-self') : changeActivity.member.name
                                    }}>
                                        <span className={`label label-field-${changeActivity.label.colorKey}`}>Label Name</span>
                                        <strong>Member Name</strong>
                                        <TimeAgo localizer={localizer} dateISOString={changeActivity.createdAt}/>
                                    </Trans>
                                </div>
                            );
                        } else {
                            return (
                                <div className="py-2" key={changeActivity.createdAt}>
                                    <Trans i18nKey="stage.common.label-added" values={{
                                        label: changeActivity.label.name,
                                        member: changeActivity.member.id === actor.id ? localizer.msg('stage.common.changed-self') : changeActivity.member.name
                                    }}>
                                        <span className={`label label-field-${changeActivity.label.colorKey}`}>Label Name</span>
                                        <strong>Member Name</strong>
                                        <TimeAgo localizer={localizer} dateISOString={changeActivity.createdAt}/>
                                    </Trans>
                                </div>
                            );
                        }
                    } else if (changeActivity.activityType === ContributionActivityType.LABEL_REMOVED) {
                        return (
                            <div className="py-2">
                                <Trans i18nKey="stage.common.label-removed" values={{
                                    label: changeActivity.label.name,
                                    member: changeActivity.member.id === actor.id ? localizer.msg('stage.common.changed-self') : changeActivity.member.name
                                }}>
                                    <span
                                        className={`label label-field-${changeActivity.label.colorKey}`}>Label Name</span>
                                    <strong>Member Name</strong>
                                    <TimeAgo localizer={localizer} dateISOString={changeActivity.createdAt}/>
                                </Trans>
                            </div>
                        );
                    } else
                        return '';
                })}
            </BasicActivityCard>
        </div>
    );
};