import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useLocation} from "react-router-dom";
import {
    FileUploadResponse,
    Member,
    MemberSearchRequest,
    UploadedResponse,
    UploadProgressCallback,
    useApiErrorResponseHandler
} from '@ideascale/commons';
import {ParagraphSkeleton} from '@ideascale/ui';
import {ActivityCard} from 'components/idea/details/ActivityCard';
import {RefineField} from 'components/stage-activities/refine/RefineField';
import {CommonUtil} from "utils/CommonUtil";
import {Scroller} from "utils/Scroller";
import {FIELD_VALUE_ID_PREFIX, MENTIONED_FIELD_VALUE_ID} from "constants/AppConstants";
import {BaseStageActivityProps} from 'models/types/BaseStageActivityProps';
import {RefineStageActivity} from 'models/stage-activity/RefineStageActivity';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {RevisionHistory} from 'models/RevisionHistory';
import {StageActionResponse} from 'models/StageActionResponse';
import {RefineStageSummary} from 'models/RefineStageSummary';
import {RefineStageField} from 'models/stage-activity/RefineStageField';
import {StageFieldValueParameters} from 'models/types/StageFieldValueParameters';
import {BaseIdeaStageSummary} from 'models/BaseIdeaStageSummary';
import {IdeaDetailsTab} from "models/enums/IdeaDetailsTab";
import iconToolsLarge from 'assets/img/icon-tools-large.png';

type RefineActivityProps = BaseStageActivityProps & {
    saveRefineStageField: (fieldId: number, fieldParameters: StageFieldValueParameters) => Promise<StageActionResponse<RefineStageActivity, RefineStageSummary>>;
    fetchRevisionHistory: (fieldId: number, pageParameters: PageParameters) => Promise<PagedResponseContent<RevisionHistory>>;
    uploadFile: (data: FormData, onUploadProgress: UploadProgressCallback) => Promise<FileUploadResponse>;
    updateIdeaStageSummary: <T extends BaseIdeaStageSummary>(stageSummary: T) => void;
    tempImageUpload?: (data: FormData, onUploadProgress: UploadProgressCallback) => Promise<UploadedResponse>;
    ideaId?: number;
    campaignId?: number;
    atMentionEnabled?: boolean;
    fetchAtMentionsRefineCustomField?: (parameters: MemberSearchRequest) => Promise<Member[]>;
};

export const RefineActivity = (props: RefineActivityProps) => {
    const {
        isCurrentStage,
        headerSummary,
        headerTitle,
        localizer,
        stageId,
        updateIdeaStageSummary,
        fetchActivity,
        fetchRevisionHistory,
        saveRefineStageField,
        uploadFile,
        tempImageUpload,
        ideaId,
        campaignId,
        atMentionEnabled,
        fetchAtMentionsRefineCustomField
    } = props;
    const {pathname, search} = useLocation();
    const mentionedFieldValueId = useMemo(() => new URLSearchParams(search).get(MENTIONED_FIELD_VALUE_ID), [search])
    const [loading, setLoading] = useState(true);
    const [activity, setActivity] = useState<RefineStageActivity>();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});

    const renderRefinePermission = () => {
        if (isCurrentStage && activity) {
            if (activity.participationAllowed) {
                return <div className="text-muted">
                    {activity.customIntroduction}
                </div>;
            } else if (activity.viewAllowed) {
                return localizer.msg('stage.refine.view');
            } else {
                return localizer.msg('stage.refine.in-progress');
            }
        } else {
            return localizer.msg('stage.refine.completed');
        }
    };

    const goToMentionedField = useCallback(() => {
        const pathSplit = pathname.split('/');
        if (pathSplit.includes(IdeaDetailsTab.ACTIVITY.toLowerCase())) {
            CommonUtil.wait(200).then(() => {
                mentionedFieldValueId && Scroller.scrollTo(`${FIELD_VALUE_ID_PREFIX}-${mentionedFieldValueId}`, {offset: -180});
            })
        }
    }, [mentionedFieldValueId, pathname]);

    const loadRefineActivity = useCallback(async () => {
        try {
            const activity = await fetchActivity(stageId);
            setActivity(activity as RefineStageActivity);
            mentionedFieldValueId && goToMentionedField();
        } catch (e: any) {
            handleErrorResponse(e);
        } finally {
            setLoading(false);
        }
    }, [fetchActivity, goToMentionedField, handleErrorResponse, mentionedFieldValueId, stageId]);

    const onCardEntered = () => {
        if (!activity) {
            void loadRefineActivity();
        }
    };

    useEffect(() => {
        if (isCurrentStage) {
            void loadRefineActivity();
        }
    }, [isCurrentStage, loadRefineActivity]);

    return (
        <div className="pb-3">
            <ActivityCard
                containerClass="campaign-stages"
                headerTitle={headerTitle}
                headerSummary={
                    <p className="mb-0 mt-3 fw-normal">
                        {headerSummary}
                    </p>
                }
                isCurrentStage={isCurrentStage}
                svgIconSprite={svgIconPath}
                defaultOpen={isCurrentStage} onEntered={onCardEntered}>
                {(loading || !activity)
                    ? <ParagraphSkeleton rows={5}/>
                    : <Fragment>
                        <div className="mb-4">
                            {renderRefinePermission()}
                            {!(activity.viewAllowed || activity.participationAllowed) &&
                                <div className="text-center my-4">
                                    <img src={iconToolsLarge} alt="Hammer and wrench in a cross"/>
                                </div>
                            }
                        </div>
                        <ul className="mt-2 list-unstyled" aria-label={localizer.msg('stage.refine.refine-questions')}>
                            {
                                activity.stageFieldValues.map((field, index) =>
                                    <RefineField
                                        key={`${field.key}_${index}`} field={field} index={index} localizer={localizer}
                                        canEdit={isCurrentStage && activity.participationAllowed}
                                        setActivity={setActivity}
                                        updateIdeaStageSummary={updateIdeaStageSummary}
                                        saveRefineStageField={saveRefineStageField}
                                        fetchRevisionHistory={fetchRevisionHistory}
                                        defaultMode={
                                            isCurrentStage && activity.participationAllowed && !RefineStageField.isPersisted(field)
                                                ? 'EDIT'
                                                : 'VIEW'
                                        }
                                        uploadFile={uploadFile}
                                        tempImageUpload={tempImageUpload}
                                        ideaId={ideaId}
                                        campaignId={campaignId}
                                        stageId={stageId}
                                        atMentionEnabled={atMentionEnabled}
                                        fetchAtMentionsRefineCustomField={fetchAtMentionsRefineCustomField}
                                    />
                                )
                            }
                        </ul>
                    </Fragment>
                }
            </ActivityCard>
        </div>
    );
};