import React, {Dispatch, Fragment, SetStateAction, useCallback, useEffect, useMemo, useState} from 'react';
import {isEmpty} from 'lodash';
import {ErrorReason, isHtmlInject, isJavascriptInject, isKeyExists, Localizer} from '@ideascale/commons';
import {useTabPermission} from 'hooks/useTabPermission';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useEditModeFormErrorHandler} from 'hooks/useEditModeFormErrorHandler';
import {FileLibrary} from './FileLibrary';
import {FileUpload} from './FileUpload';
import {TabItem} from '../../share/TabItem';
import {TabPanel} from '../../share/TabPanel';
import {InfoMessage} from './InfoMessage';
import {ErrorMessage} from './ErrorMessage';
import {LicenseUpgradeInfo} from 'components/edit-mode/LicenseUpgradeInfo';
import {CommunityEditableFieldType} from 'models/edit-mode/CommunityEditableFieldType';
import {ImageType} from 'models/enums/ImageType';
import {ImageFile} from 'models/edit-mode/ImageFile';
import {CommunityOperationType} from 'models/edit-mode/CommunityOperationType';
import {TopbarLogoField, TopbarLogoFieldBuilder} from 'models/edit-mode/TopbarLogoField';
import {ImageUploadSource} from 'models/enums/ImageUploadSource';

type TopbarLogoInfo = {
    topbarLogoUrl: string;
    topbarLogoAltText?: string;
}

export type TopBarLogoProps = {
    localizer: Localizer;
    maxFileSizeLimit: number;
    libraryImages: ImageFile[];
    topbarLogo: TopbarLogoField;
    setTopbarLogo: Dispatch<SetStateAction<TopbarLogoField>>;
    errorReason: string;
    updateFileUploadingStatus?: Dispatch<SetStateAction<Map<string, boolean>>>;
}

const TOPBAR_LOGO_RATIO = {w: 145, h: 48};
export const TopBarLogo = (props: TopBarLogoProps) => {
    const {
        localizer,
        maxFileSizeLimit,
        libraryImages,
        topbarLogo,
        setTopbarLogo,
        errorReason,
        updateFileUploadingStatus
    } = props;
    const [uploadedImageType, setUploadedImageType] = useState<ImageUploadSource | undefined>(undefined);
    const {tabPermission} = useTabPermission(topbarLogo);
    const {validationErrors} = useEditModeContext();
    const [fileUploading, setFileUploadingStatus] = useState(false);
    const {fieldError} = useEditModeFormErrorHandler({
        localizer,
        validationErrors: validationErrors.findError(CommunityOperationType.CHANGE_TOPBAR_LOGO)
    });
    const [logoLinkUrlError, setLogoLinkUrlError] = useState<string>('');
    const [logoAltTextError, setLogoAltTextError] = useState<string>('');
    const [topbarLogoInfo, setTopbarLogoInfo] = useState<TopbarLogoInfo>({
        topbarLogoUrl: topbarLogo.topbarLogoUrl,
        topbarLogoAltText: topbarLogo.topbarLogoAltText
    });

    const onTopBarLogoSelected = useCallback((image: ImageFile) => {
        setUploadedImageType(undefined);
        const newImage = new TopbarLogoFieldBuilder()
            .name(CommunityEditableFieldType.TOPBAR_LOGO)
            .fileId(image.fileId)
            .url(image.url)
            .imageType(ImageType.CUSTOM)
            .topbarLogoUrl(topbarLogoInfo.topbarLogoUrl)
            .topbarLogoAltText(topbarLogoInfo.topbarLogoAltText)
            .build();
        setTopbarLogo(prev => ({
            ...newImage,
            fileLibraryPermission: prev.fileLibraryPermission,
            fileUploadPermission: prev.fileUploadPermission
        }));
    }, [setTopbarLogo, topbarLogoInfo]);

    const topBarLogoTabItems = useMemo(() => {
        return [
            {
                value: 1,
                icon: 'plus-solid-box-rounded-layered',
                text: localizer.msg('edit-mode.image-setting.tabs.file-library'),
                disabled: tabPermission.libraryDisabled,
                content: (
                    <Fragment>
                        {
                            !tabPermission.libraryDisabled
                                ? (
                                    <FileLibrary
                                        localizer={localizer}
                                        images={libraryImages}
                                        selectedImage={topbarLogo}
                                        onSelectedImage={onTopBarLogoSelected}
                                        disabled={tabPermission.libraryDisabled}
                                        ariaLabel={localizer.msg('edit-mode.image-setting.tabs.file-library')}
                                    />
                                )
                                : null
                        }
                    </Fragment>
                ),
                additionalTabInfo: <LicenseUpgradeInfo
                    className="edit-mode-element"
                    triggerButtonId="topbar-logo-license-popover-trigger-1"
                    showIconOnly={true}
                    localizer={localizer}/>
            },
            {
                value: 2,
                icon: 'arrow-up-from-line',
                text: localizer.msg('edit-mode.image-setting.tabs.upload'),
                disabled: tabPermission.uploadDisabled,
                content: (
                    <Fragment>
                        {
                            !tabPermission.uploadDisabled
                                ? <FileUpload
                                    localizer={localizer}
                                    maxFileSizeLimit={maxFileSizeLimit}
                                    cropWidth={TOPBAR_LOGO_RATIO.w}
                                    cropHeight={TOPBAR_LOGO_RATIO.h}
                                    uploadedType={uploadedImageType}
                                    errorReason={errorReason}
                                    showAltText={false}
                                    onUploadedSuccessfulImage={(image, type) => {
                                        const altText = image.url ? topbarLogoInfo.topbarLogoAltText : undefined;
                                        const newImage = new TopbarLogoFieldBuilder()
                                            .name(CommunityEditableFieldType.TOPBAR_LOGO)
                                            .fileId(image.fileId)
                                            .url(image.url)
                                            .filename(image.filename)
                                            .altText(altText)
                                            .imageType(ImageType.UPLOADED)
                                            .topbarLogoUrl(topbarLogoInfo.topbarLogoUrl)
                                            .topbarLogoAltText(altText)
                                            .build();

                                        setTopbarLogo(prev => ({
                                            ...newImage,
                                            fileLibraryPermission: prev.fileLibraryPermission,
                                            fileUploadPermission: prev.fileUploadPermission
                                        }));
                                        setUploadedImageType(type);
                                    }}
                                    updateFileUploadingStatus={setFileUploadingStatus}
                                />
                                : null
                        }
                    </Fragment>
                ),
                additionalTabInfo: <LicenseUpgradeInfo
                    className="edit-mode-element"
                    triggerButtonId="topbar-logo-license-popover-trigger-2"
                    showIconOnly={true}
                    localizer={localizer}/>
            }
        ] as TabItem[];
    }, [localizer, tabPermission.libraryDisabled, tabPermission.uploadDisabled, libraryImages, topbarLogo, onTopBarLogoSelected, maxFileSizeLimit, uploadedImageType, errorReason, topbarLogoInfo, setTopbarLogo]);

    const hasAllTabsDisabled = useMemo(() => {
        return tabPermission.libraryDisabled && tabPermission.uploadDisabled;
    }, [tabPermission.libraryDisabled, tabPermission.uploadDisabled]);

    useEffect(() => {
        updateFileUploadingStatus?.(prev => new Map(prev.set(CommunityOperationType.CHANGE_TOPBAR_LOGO, fileUploading)));
    }, [fileUploading, updateFileUploadingStatus]);

    useEffect(() => {
        setLogoAltTextError(fieldError('topbarLogoAltText')?.message || '');
        setLogoLinkUrlError(fieldError('topbarLogoUrl')?.message || '');
    }, [fieldError]);

    return (
        <Fragment>
            <div className="d-flex align-items-center">
                <h5 className={`my-3 fw-semibold ${validationErrors.findError(CommunityOperationType.CHANGE_TOPBAR_LOGO) ? 'has-error' : ''}`}>{localizer.msg('edit-mode.advanced-settings.top-bar-logo-title')}</h5>
                {
                    hasAllTabsDisabled && (
                        <LicenseUpgradeInfo
                            triggerButtonId="topbar-logo-title-license-popover" className="ms-2"
                            localizer={localizer}
                        />
                    )
                }
            </div>
            <ErrorMessage message={fieldError('fileId')?.message}/>
            {
                (fieldError('fileId')?.reason !== ErrorReason.FEATURE_IS_NOT_ACCESSIBLE && !hasAllTabsDisabled)
                    ? (
                        <Fragment>
                            <TabPanel tabItems={topBarLogoTabItems}/>
                            {
                                ((isKeyExists(topbarLogo, 'fileId') && topbarLogo.fileId !== 0) || (topbarLogo.imageType === ImageType.UPLOADED && !isEmpty(topbarLogo.url))) &&
                                <Fragment>
                                    <div className={`form-group my-3 ${logoAltTextError ? 'has-error' : ''}`}>
                                        <label className="control-label fw-bold my-1"
                                               htmlFor={`topbarAltText`}>
                                            {localizer.msg('edit-mode.advanced-settings.topbar-logo-alt-text')}
                                        </label>
                                        <input className="form-control"
                                               type="text"
                                               id={`topbarAltText`}
                                               value={topbarLogoInfo.topbarLogoAltText}
                                               placeholder={localizer.msg('edit-mode.advanced-settings.topbar-logo-alt-text')}
                                               onChange={(event) => {
                                                   setTopbarLogo((prev) => ({
                                                       ...prev,
                                                       topbarLogoAltText: event.target.value,
                                                       fileId: prev.fileId ?? 0,
                                                       altText: event.target.value
                                                   }));

                                                   setTopbarLogoInfo((prev) => ({
                                                       ...prev,
                                                       topbarLogoAltText: event.target.value
                                                   }));

                                                   setLogoAltTextError('');
                                                   if (isHtmlInject(event.target.value)) {
                                                       setLogoAltTextError(localizer.msg('common.errors.html'));
                                                   } else if (isJavascriptInject(event.target.value)) {
                                                       setLogoAltTextError(localizer.msg('common.errors.js'));
                                                   }
                                               }}
                                               maxLength={128}
                                        />
                                        {
                                            !isEmpty(logoAltTextError) &&
                                            <div className="invalid-feedback d-block">
                                                {logoAltTextError}
                                            </div>
                                        }
                                    </div>
                                </Fragment>
                            }
                            <div className={`form-group my-3 ${logoLinkUrlError ? 'has-error' : ''}`}>
                                <label className="control-label fw-bold my-1"
                                       htmlFor={`topbarLogoLinkUrl`}>
                                    {localizer.msg('edit-mode.advanced-settings.logo-link-url')}
                                </label>
                                <input className="form-control"
                                       type="text"
                                       id={`topbarLogoLinkUrl`}
                                       value={topbarLogoInfo.topbarLogoUrl}
                                       placeholder={localizer.msg('edit-mode.advanced-settings.logo-link-url')}
                                       onChange={(event) => {
                                           setTopbarLogo((prev) => ({
                                               ...prev, topbarLogoUrl: event.target.value
                                           }));

                                           setTopbarLogoInfo((prev) => ({
                                               ...prev,
                                               topbarLogoUrl: event.target.value
                                           }));

                                           setLogoLinkUrlError('');
                                           if (isHtmlInject(event.target.value)) {
                                               setLogoLinkUrlError(localizer.msg('common.errors.html'));
                                           } else if (isJavascriptInject(event.target.value)) {
                                               setLogoLinkUrlError(localizer.msg('common.errors.js'));
                                           }
                                       }}
                                       maxLength={128}
                                />
                                {
                                    !isEmpty(logoLinkUrlError) &&
                                    <div className="invalid-feedback d-block">
                                        {logoLinkUrlError}
                                    </div>
                                }
                            </div>
                            {(!isKeyExists(topbarLogo, 'fileId') || (!isEmpty(topbarLogo.imageType) && isEmpty(topbarLogo.url))) &&
                                <InfoMessage localizer={localizer}/>}
                        </Fragment>
                    )
                    : <div className="mt-4"></div>
            }
        </Fragment>
    );
};