import {Type, TypeHelpOptions} from 'class-transformer';
import {BaseIdeaStageSummary} from 'models/BaseIdeaStageSummary';
import {StageActivity} from 'models/stage-activity/StageActivity';
import {StageFunctionName} from 'models/enums/StageFunctionName';
import {StageGroup} from 'models/enums/StageGroup';
import {ClassTransformerUtil} from 'utils/ClassTransformerUtil';

export class StageActionResponse<A extends StageActivity, S extends BaseIdeaStageSummary> {
    stageActivity?: A;
    @Type((type?: TypeHelpOptions) => {
        return ClassTransformerUtil.getIdeaStageType(type?.object.stageSummary.stage?.name.toLowerCase());
    })
    stageSummary: S;
    stageChanged: boolean;
    error: string;

    static readonly EMPTY = new StageActionResponse(
        StageActivity.EMPTY,
        {
            stage: {
                id: 0,
                key: '',
                name: StageFunctionName.IDEATE,
                label: '',
                description: '',
                functionName: StageFunctionName.IDEATE,
                stageGroup: StageGroup.ACTIVE
            },
            viewAllowed: false,
            participationAllowed: false,
            percentage: 0,
            callToActionLabel: '',
            getIconName: () => ''
        },
        false,
        ''
    );

    constructor(
        stageActivity: A,
        stageSummary: S,
        stageChanged: boolean,
        error: string
    ) {
        this.stageActivity = stageActivity;
        this.stageSummary = stageSummary;
        this.stageChanged = stageChanged;
        this.error = error;
    }
}