import {useCallback} from 'react';
import {AttachmentAttributeParameters, Member, MemberSearchRequest, SuperCommentData, UnfurledUrl, useApiErrorResponseHandler} from '@ideascale/commons';
import {useCommunityService} from './useService';
import {useLocalizer} from './useLocalizer';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {CommentsParams} from 'models/comments/CommentsParams';
import {CommentSummary} from 'models/comments/CommentSummary';
import {CommentPermissions} from 'models/comments/CommentPermissions';
import {CommentFormInputs} from 'models/comments/CommentFormInputs';
import {GenericResponse} from 'models/GenericResponse';
import {AttachmentFormInputs} from 'models/comments/AttachmentFormInputs';
import {IdeaLabelActionResponse} from 'models/IdeaLabelActionResponse';
import {PageParameters} from 'models/types/PageParameters';
import {StageCommentSummary} from 'models/comments/StageCommentSummary';
import {CommentVoteResponse} from 'models/comments/CommentVoteResponse';
import {CommentVoteRequest} from 'models/comments/CommentVoteRequest';
import {KudoActivitySummary} from 'models/KudoActivitySummary';
import {StageCommentPermissions} from 'models/comments/StageCommentPermissions';
import {StageCommentFormInputs} from 'models/comments/StageCommentFormInputs';
import {OriginalComment} from 'models/comments/OriginalComment';
import {FileAttachmentsHolder} from 'models/FileAttachmentsHolder';
import {COMMENT_LIST_PAGE_SIZE} from 'constants/AppConstants';

export const useCommentService = () => {
    const localizer = useLocalizer();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const communityService = useCommunityService();

    const fetchPaginatedCommentList = useCallback((ideaId: number, commentParams: CommentsParams, commentId?: number) => {
        if (communityService !== null) {
            try {
                return commentId ? communityService.fetchCommentsById(commentId, commentParams.pageParameters?.limit || COMMENT_LIST_PAGE_SIZE) : communityService.fetchComments(ideaId, commentParams);
            } catch (e: any) {
                throw e;
            }
        }
        return PagedResponseContent.nullObject<CommentSummary>();
    }, [communityService]);

    const fetchStageComments = useCallback((ideaId: number, stageId: number, pageParams: PageParameters) => {
        if (communityService !== null) {
            try {
                return communityService.fetchStageComments(ideaId, stageId, pageParams);
            } catch (e: any) {
                throw e;
            }
        }
        return PagedResponseContent.nullObject<StageCommentSummary>();
    }, [communityService]);

    const fetchChildComments = useCallback(async (commentId: number) => {
        if (communityService !== null) {
            try {
                return communityService.fetchChildComments(commentId);
            } catch (e: any) {
                throw e;
            }
        }
        return [];
    }, [communityService]);

    const fetchPinnedComments = useCallback((ideaId: number, commentParams: CommentsParams) => {
        if (communityService !== null) {
            try {
                return communityService.fetchPinnedComments(ideaId, commentParams);
            } catch (e: any) {
                throw e;
            }
        }
        return [];
    }, [communityService]);

    const fetchAtMentionMembers = useCallback(async (searchParameters: MemberSearchRequest) => {
        if (communityService !== null) {
            return await communityService.fetchAtMentionMembers(searchParameters);
        }
        return [];
    }, [communityService]);

    const fetchCommentPermissions = useCallback(async (parentId: number): Promise<CommentPermissions> => {
        if (communityService !== null) {
            return await communityService.fetchCommentPermissions(parentId);
        }
        return CommentPermissions.EMPTY;
    }, [communityService]);

    const fetchStageCommentPermissions = useCallback(async (ideaId: number): Promise<StageCommentPermissions> => {
        if (communityService !== null) {
            return await communityService.fetchStageCommentPermissions(ideaId);
        }
        return StageCommentPermissions.EMPTY;
    }, [communityService]);

    const fetchCommentLabels = useCallback(async (ideaId: number) => {
        if (communityService !== null) {
            return await communityService.fetchCommentLabels(ideaId);
        }
        return [];
    }, [communityService]);

    const fetchCommentLabelsById = useCallback(async (commentId: number) => {
        if (communityService !== null) {
            return await communityService.fetchCommentLabelsById(commentId);
        }
        return IdeaLabelActionResponse.EMPTY;
    }, [communityService]);

    const commentCreation = useCallback(async (formInputs: CommentFormInputs) => {
        if (communityService !== null) {
            try {
                return communityService.commentCreation(formInputs);
            } catch (e: any) {
                throw e;
            }
        }
        return CommentSummary.EMPTY;
    }, [communityService]);

    const createStageComment = useCallback(async (formInputs: StageCommentFormInputs) => {
        if (communityService !== null) {
            try {
                return communityService.createStageComment(formInputs);
            } catch (e: any) {
                throw e;
            }
        }
        return StageCommentSummary.EMPTY;
    }, [communityService]);

    const commentModification = useCallback(async (commentId: number, formInputs: CommentFormInputs) => {
        if (communityService !== null) {
            try {
                return communityService.commentModification(commentId, formInputs);
            } catch (e: any) {
                throw e;
            }
        }
        return CommentSummary.EMPTY;
    }, [communityService]);

    const deleteCommentAttachment = useCallback(async (commentId: number, fileId: number) => {
        if (communityService !== null) {
            try {
                return communityService.deleteCommentAttachment(commentId, fileId);
            } catch (e: any) {
                handleErrorResponse(e);
                throw e;
            }
        }
        return GenericResponse.EMPTY;
    }, [communityService, handleErrorResponse]);


    const deleteStageCommentAttachment = useCallback(async (fileId: number) => {
        if (communityService !== null) {
            try {
                return communityService.deleteStageCommentAttachment(fileId);
            } catch (e: any) {
                handleErrorResponse(e);
                throw e;
            }
        }
        return GenericResponse.EMPTY;
    }, [communityService, handleErrorResponse]);

    const commentAttachments = useCallback((commentId: number, formInputs: AttachmentFormInputs, attributeApiParameters?: Array<AttachmentAttributeParameters>): Promise<FileAttachmentsHolder> => {
        if (communityService !== null) {
            try {
                return communityService.commentAttachments(commentId, formInputs, attributeApiParameters);
            } catch (e: any) {
                handleErrorResponse(e);
                throw e;
            }
        }
        return Promise.resolve(FileAttachmentsHolder.EMPTY);
    }, [communityService, handleErrorResponse]);

    const addStageCommentAttachments = useCallback((commentId: number, formInputs: AttachmentFormInputs, attributeApiParameters?: Array<AttachmentAttributeParameters>): Promise<FileAttachmentsHolder> => {
        if (communityService !== null) {
            try {
                return communityService.addStageCommentAttachments(commentId, formInputs, attributeApiParameters);
            } catch (e: any) {
                handleErrorResponse(e);
                throw e;
            }
        }
        return Promise.resolve(FileAttachmentsHolder.EMPTY);
    }, [communityService, handleErrorResponse]);

    const deleteComment = useCallback((commentId: number) => {
        if (communityService !== null) {
            try {
                return communityService.deleteComment(commentId);
            } catch (e: any) {
                handleErrorResponse(e);
                throw e;
            }
        }
        return undefined;
    }, [communityService, handleErrorResponse]);

    const deleteStageComment = useCallback((commentId: number) => {
        if (communityService !== null) {
            try {
                return communityService.deleteStageComment(commentId);
            } catch (e: any) {
                handleErrorResponse(e);
                throw e;
            }
        }
        return undefined;
    }, [communityService, handleErrorResponse]);

    const pinComment = useCallback((commentId: number) => {
        if (communityService !== null) {
            try {
                return communityService.pinComment(commentId);
            } catch (e: any) {
                handleErrorResponse(e);
                throw e;
            }
        }
        return undefined;
    }, [communityService, handleErrorResponse]);

    const unpinComment = useCallback((commentId: number) => {
        if (communityService !== null) {
            try {
                return communityService.unpinComment(commentId);
            } catch (e: any) {
                handleErrorResponse(e);
                throw e;
            }
        }
        return undefined;
    }, [communityService, handleErrorResponse]);

    const createSuperComment = useCallback((commentId: number, commentTypeId: number) => {
        if (communityService !== null) {
            try {
                return communityService.createSuperComment(commentId, commentTypeId);
            } catch (e: any) {
                handleErrorResponse(e);
                throw e;
            }
        }
        return SuperCommentData.EMPTY;
    }, [communityService, handleErrorResponse]);

    const addCommentsLabel = useCallback((commentId: number, labelId: number) => {
        if (communityService !== null) {
            try {
                return communityService.addCommentsLabel(commentId, labelId);
            } catch (e: any) {
                handleErrorResponse(e);
                throw e;
            }
        }
        return [];
    }, [communityService, handleErrorResponse]);

    const removeCommentsLabel = useCallback((commentId: number, labelId: number) => {
        if (communityService !== null) {
            try {
                return communityService.removeCommentsLabel(commentId, labelId);
            } catch (e: any) {
                handleErrorResponse(e);
                throw e;
            }
        }
        return [];
    }, [communityService, handleErrorResponse]);

    const commentUpDownVote = useCallback((commentId: number, voteRequest: CommentVoteRequest) => {
        if (communityService !== null) {
            try {
                return communityService.commentUpDownVote(commentId, voteRequest);
            } catch (e: any) {
                handleErrorResponse(e);
                throw e;
            }
        }
        return CommentVoteResponse.EMPTY;
    }, [communityService, handleErrorResponse]);

    const commentRetractVote = useCallback((commentId: number) => {
        if (communityService !== null) {
            try {
                return communityService.commentRetractVote(commentId);
            } catch (e: any) {
                throw e;
            }
        }
        return CommentVoteResponse.EMPTY;
    }, [communityService]);

    const commentApprove = useCallback((commentId: number) => {
        if (communityService !== null) {
            try {
                return communityService.commentApprove(commentId);
            } catch (e: any) {
                handleErrorResponse(e);
                throw e;
            }
        }
        return CommentSummary.EMPTY;
    }, [communityService, handleErrorResponse]);

    const commentReject = useCallback((commentId: number) => {
        if (communityService !== null) {
            try {
                return communityService.commentReject(commentId);
            } catch (e: any) {
                handleErrorResponse(e);
                throw e;
            }
        }
        return CommentSummary.EMPTY;
    }, [communityService, handleErrorResponse]);

    const reportAbuseComment = useCallback((commentId: number) => {
        if (communityService !== null) {
            try {
                return communityService.commentAbuse(commentId);
            } catch (e: any) {
                handleErrorResponse(e);
                throw e;
            }
        }
        return undefined;
    }, [communityService, handleErrorResponse]);

    const giveCommentKudo = useCallback((commentId: number) => {
        if (communityService !== null) {
            try {
                return communityService.giveCommentKudo(commentId);
            } catch (e: any) {
                handleErrorResponse(e);
                throw e;
            }
        }
        return KudoActivitySummary.EMPTY;
    }, [communityService, handleErrorResponse]);

    const getCommentKudoGivers = useCallback(async (pageParameters: PageParameters, commentId: number): Promise<PagedResponseContent<Member>> => {
        if (communityService !== null) {
            try {
                return communityService.getCommentKudoGivers(pageParameters, commentId);
            } catch (e: any) {
                handleErrorResponse(e);
                throw e;
            }
        }
        return PagedResponseContent.nullObject();
    }, [communityService, handleErrorResponse]);

    const fetchOriginalComment = useCallback((commentId: number) => {
        if (communityService !== null) {
            try {
                return communityService.fetchOriginalComment(commentId);
            } catch (e: any) {
                handleErrorResponse(e);
                throw e;
            }
        }

        return Promise.resolve({id: 0, text: '', language: {id: 0, name: '', code: ''}} as OriginalComment);
    }, [communityService, handleErrorResponse]);

    const fetchEditComment = useCallback((commentId: number) => {
        if (communityService !== null) {
            try {
                return communityService.fetchEditComment(commentId);
            } catch (e: any) {
                handleErrorResponse(e);
                throw e;
            }
        }

        return Promise.resolve(CommentSummary.EMPTY);
    }, [communityService, handleErrorResponse]);

    const unfurlUrl = useCallback(async (url: string) => {
        if (communityService !== null) {
            return await communityService.fetchUnfurledUrl(url);
        }
        return {url: '', title: '', description: '', image: '', site: '', type: '', icon: '',} as UnfurledUrl;
    }, [communityService]);

    return {
        fetchPaginatedCommentList,
        fetchPinnedComments,
        fetchChildComments,
        fetchCommentPermissions,
        fetchAtMentionMembers,
        fetchCommentLabels,
        fetchCommentLabelsById,
        commentCreation,
        commentModification,
        deleteCommentAttachment,
        deleteStageCommentAttachment,
        commentAttachments,
        addStageCommentAttachments,
        deleteComment,
        deleteStageComment,
        pinComment,
        unpinComment,
        createSuperComment,
        fetchStageComments,
        addCommentsLabel,
        removeCommentsLabel,
        commentUpDownVote,
        commentRetractVote,
        commentApprove,
        commentReject,
        reportAbuseComment,
        giveCommentKudo,
        getCommentKudoGivers,
        fetchOriginalComment,
        fetchEditComment,
        fetchStageCommentPermissions,
        createStageComment,
        unfurlUrl,
    };
};