import {useAppContext} from 'contexts/AppContext';
import {IdeaListMode} from 'models/enums/IdeaListMode';
import {useCallback} from 'react';

export const useIdeaListMode = () => {
    const {communityConfig: {showRecommendedIdeas}} = useAppContext();

    const getIdeaListModes = useCallback(() => {
        return Object.values(IdeaListMode).filter(item => showRecommendedIdeas ? item : item !== IdeaListMode.RECOMMENDED);
    }, [showRecommendedIdeas]);

    const getIdeaListModesWithoutRecommended = useCallback(() => {
        return Object.values(IdeaListMode).filter(item => item !== IdeaListMode.RECOMMENDED);
    }, []);

    return {
        getIdeaListModes,
        getIdeaListModesWithoutRecommended
    };
};