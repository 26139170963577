import React, {useCallback, useMemo, useState} from 'react';
import {Modal} from '@ideascale/ui';
import {Localizer, UploadedResponse, UploadProgressCallback,} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {MediaConfigVideoTab} from 'components/landing-page/MediaConfigVideoTab';
import {MediaConfigImageTab} from 'components/landing-page/MediaConfigImageTab';
import {TabItem} from '../share/TabItem';
import {TabPanel} from '../share/TabPanel';
import {Media} from 'models/landing-page/Media';
import {MediaType} from 'models/enums/landing-page/MediaType';
import './MediaComponent.scss';

type MediaConfigModalProps = {
    open: boolean;
    toggle: () => void;
    config: Media;
    updateConfig: (config: Media) => Promise<void>;
    localizer: Localizer;
    imageCropWidth: number;
    imageCropHeight: number;
    onComponentEdited: () => void;
    uploadImage: (data: FormData, onUploadProgress: UploadProgressCallback) => Promise<UploadedResponse>;
    note?: JSX.Element;
    defaultImage?: string;
    allowVideo?: boolean;
    previewMode?: 'full' | 'small' | 'medium';
}

export const MediaConfigModal = (props: MediaConfigModalProps) => {
    const {
        open,
        toggle,
        config,
        updateConfig,
        localizer,
        imageCropWidth,
        imageCropHeight,
        note,
        defaultImage,
        allowVideo,
        onComponentEdited,
        previewMode,
        uploadImage
    } = props;
    const {communityConfig: {maxFileSizeLimit}} = useAppContext();
    const [configUpdated, setConfigUpdated] = useState(false);

    const onConfigUpdated = useCallback(() => {
        setConfigUpdated(prev => !prev ? true : prev);
    }, []);

    const mediaConfigTabItems = useMemo(() => {
        return [
            {
                value: 1,
                icon: 'image',
                text: localizer.msg('landing-page.components.media.image-tab'),
                content: <MediaConfigImageTab note={note}
                                              updateConfig={updateConfig}
                                              onComponentEdited={onComponentEdited}
                                              onConfigUpdated={onConfigUpdated}
                                              maxFileSizeLimit={maxFileSizeLimit}
                                              toggleModal={toggle}
                                              configUpdated={configUpdated}
                                              config={config}
                                              uploadImage={uploadImage}
                                              previewMode={previewMode}
                                              imageCropWidth={imageCropWidth}
                                              imageCropHeight={imageCropHeight}
                                              localizer={localizer}
                                              defaultImage={defaultImage}/>
            },
            ...allowVideo
                ? [
                    {
                        value: 2,
                        icon: 'camcorder',
                        text: localizer.msg('landing-page.components.media.video-tab'),
                        content: <MediaConfigVideoTab toggle={toggle}
                                                      config={config}
                                                      localizer={localizer}
                                                      updateConfig={updateConfig}
                                                      configUpdated={configUpdated}
                                                      onConfigUpdated={onConfigUpdated}
                                                      onComponentEdited={onComponentEdited}/>
                    }
                ]
                : []
        ] as TabItem[];
    }, [allowVideo, config, configUpdated, defaultImage, imageCropHeight, imageCropWidth, localizer, maxFileSizeLimit, note, onComponentEdited, onConfigUpdated, previewMode, toggle, updateConfig, uploadImage]);

    return (
        <Modal isOpen={open} toggle={toggle} autoFocus={false}
               title={localizer.msg(`landing-page.components.media.config-modal.title`)}>
            <TabPanel tabItems={mediaConfigTabItems}
                      defaultActiveTab={[MediaType.IMAGE, MediaType.VIDEO].indexOf(config.type) + 1}/>
        </Modal>
    );
};