import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {
    Campaign,
    CampaignsHolder,
    PagedResponseContent,
    PageParameters,
    PageTheme,
    useApiErrorResponseHandler,
    useToggle
} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {useTranslation} from 'hooks/useTranslation';
import {useLandingPageService} from 'hooks/useService';
import {ConfigWrapper} from 'components/landing-page/ConfigWrapper';
import {CampaignsConfigModal} from 'components/landing-page/CampaignsConfigModal';
import {ListViewComponent} from 'components/landing-page/ListViewComponent';
import {CarouselViewComponent} from 'components/landing-page/CarouselViewComponent';
import {TranslationModal} from 'shared/TranslationModal';
import {SlideType} from 'models/enums/SlideType';
import {CampaignSlide} from 'models/CampaignSlide';
import {CampaignFilter} from 'models/enums/landing-page/CampaignFilter';
import {TranslationConfig} from 'models/TranslationConfig';
import {TranslationFieldType} from 'models/enums/TranslationFieldType';
import {TranslationContentType} from 'models/enums/landing-page/TranslationContentType';
import {CampaignSubscribeResponse} from 'models/CampaignSubscribeResponse';
import {TranslationContentResponse} from 'models/landing-page/TranslationContentResponse';
import {FeaturedCampaignsComponentData} from 'models/landing-page/FeaturedCampaignsComponentData';
import {FeaturedCampaignsComponentRequest} from 'models/landing-page/FeaturedCampaignsComponentRequest';
import {CampaignDisplayType} from 'models/enums/landing-page/CampaignDisplayType';
import {Slider} from 'models/Slider';
import {CAROUSEL_PAGE_SIZE, LIST_PAGE_SIZE} from 'constants/AppConstants';

export type PageData = {
    hasMore: boolean,
    currentPage: number
}

type FeaturedCampaignsProps = {
    showConfig: boolean;
    data: FeaturedCampaignsComponentData;
    theme: PageTheme;
    showTranslationConfig: boolean;
    onComponentEdited: () => void,
    translationLanguageId?: number;
}

export const FeaturedCampaignsContainer = (props: FeaturedCampaignsProps) => {
    const {
        showConfig,
        data,
        theme,
        showTranslationConfig,
        translationLanguageId,
        onComponentEdited,
    } = props;
    const localizer = useLocalizer();
    const landingPageService = useLandingPageService();
    const {getTranslatedContent, saveTranslatedContent} = useTranslation();
    const {handleErrorResponse, showErrorMessage} = useApiErrorResponseHandler({localizer});
    const [showEditModal, toggleEditModal] = useToggle(false);
    const [showTranslationModal, toggleTranslationModal] = useToggle(false);
    const [loading, setLoading] = useToggle(true);
    const [lastFetchedPageData, setLastFetchedPageData] = useState<PageData>({hasMore: true, currentPage: 0});
    const [campaignConfig, setCampaignConfig] = useState(data);
    const [campaignData, setCampaignData] = useState<CampaignSlide[]>([]);

    const getCampaigns = useCallback(async (param: PageParameters): Promise<PagedResponseContent<CampaignsHolder<Campaign>>> => {
        if (landingPageService !== null) {
            return await landingPageService.fetchCampaigns(param);
        }
        return PagedResponseContent.nullObject();
    }, [landingPageService]);

    const saveCampaignData = useCallback(async (component: FeaturedCampaignsComponentRequest) => {
        if (landingPageService !== null) {
            return landingPageService.saveFeaturedCampaignConfig(component);
        }
        return FeaturedCampaignsComponentData.EMPTY;
    }, [landingPageService]);

    const onToggleVisibility = async () => {
        const newData = {...campaignConfig};
        newData.visible = !newData.visible;
        await updateCampaignData(newData);
        onComponentEdited();
    };

    const updateCampaignData = useCallback(async (component: FeaturedCampaignsComponentData) => {
        const campaignRequest: FeaturedCampaignsComponentRequest = {
            ...component, campaignIds: component.campaigns?.map(item => item.id) || [],
        };
        try {
            const response = await saveCampaignData(campaignRequest);
            if (response) {
                setCampaignConfig(response);
            }
        } catch (e: any) {
            if (e.data && 'validationErrors' in e.data) {
                throw e.data;
            } else {
                showErrorMessage(e);
            }
        }
    }, [saveCampaignData, showErrorMessage]);

    const onEditClicked = useCallback(() => {
        toggleEditModal();
    }, [toggleEditModal]);


    const subscribeToCampaign = async (id: number): Promise<CampaignSubscribeResponse> => {
        if (landingPageService !== null) {
            try {
                return await landingPageService.subscribeCampaign(id);
            } catch (error: any) {
                handleErrorResponse(error);
            }
        }
        return CampaignSubscribeResponse.EMPTY;
    };

    const unsubscribeToCampaign = async (id: number): Promise<CampaignSubscribeResponse> => {
        if (landingPageService !== null) {
            try {
                return await landingPageService.unsubscribeCampaign(id);
            } catch (error: any) {
                handleErrorResponse(error);
            }
        }
        return CampaignSubscribeResponse.EMPTY;
    };

    const onTranslationClicked = useCallback(() => {
        if (translationLanguageId !== undefined) {
            toggleTranslationModal();
        }
    }, [toggleTranslationModal, translationLanguageId]);

    const onTextTranslated = useCallback((response: TranslationContentResponse) => {
        setCampaignConfig(prev => {
            const newData = {...prev};
            if (response.contentType === TranslationContentType.PAGE_COMPONENT_TITLE) {
                newData.title = response.translation;
            } else {
                newData.subtitle = response.translation;
            }
            return newData;
        });
    }, []);

    const translationConfigs = useMemo(() => {
        return [
            new TranslationConfig(
                TranslationContentType.PAGE_COMPONENT_TITLE,
                campaignConfig.id,
                TranslationFieldType.TEXT,
            ),
        ];
    }, [campaignConfig.id]);

    const loadCampaigns = useCallback(async (limit: number, page: number): Promise<Slider> => {
        if (landingPageService !== null) {
            return landingPageService.fetchFeaturedCampaigns({limit, page});
        }
        return Slider.EMPTY;
    }, [landingPageService]);

    const loadInitialCampaigns = useCallback(async () => {
        if (campaignConfig.campaignFilter !== CampaignFilter.SELECTED) {
            try {
                const campaignLimit = campaignConfig.campaignDisplayType === CampaignDisplayType.CAROUSEL ? CAROUSEL_PAGE_SIZE : LIST_PAGE_SIZE;
                const initialData = await loadCampaigns(campaignLimit, 0);
                setLastFetchedPageData({hasMore: initialData.hasMore, currentPage: initialData.pageNo});
                let dataArray = initialData.content.filter(data => data.type === SlideType.CAMPAIGN) as CampaignSlide[];
                setCampaignData(dataArray);
            } catch (error) {
                handleErrorResponse(error);
            } finally {
                setLoading(false);
            }
        } else {
            setCampaignData(campaignConfig.campaigns || []);
            setLoading(false);
        }
    }, [campaignConfig.campaignFilter, campaignConfig.campaigns, campaignConfig.campaignDisplayType, handleErrorResponse, setLoading, loadCampaigns]);

    const renderCampaignView = () => {
        switch (campaignConfig.campaignDisplayType) {
            case CampaignDisplayType.CAROUSEL:
                return <CarouselViewComponent theme={theme}
                                              editMode={showConfig || showTranslationConfig}
                                              localizer={localizer}
                                              config={campaignConfig}
                                              loading={loading}
                                              campaignSliderData={campaignData}
                                              setCampaignSliderData={setCampaignData}
                                              lastFetchedPageData={lastFetchedPageData}
                                              setLastFetchedPageData={setLastFetchedPageData}
                                              subscribeToCampaign={subscribeToCampaign}
                                              unsubscribeToCampaign={unsubscribeToCampaign}
                                              loadCampaigns={loadCampaigns}/>;
            case CampaignDisplayType.GRID:
            case CampaignDisplayType.STACK:
            default:
                return <ListViewComponent theme={theme}
                                          editMode={showConfig || showTranslationConfig}
                                          localizer={localizer}
                                          loading={loading}
                                          config={campaignConfig}
                                          campaignData={campaignData}
                                          setCampaignData={setCampaignData}
                                          lastFetchedPageData={lastFetchedPageData}
                                          setLastFetchedPageData={setLastFetchedPageData}
                                          loadCampaigns={loadCampaigns}/>;
        }
    };

    const renderAccessibleName = useCallback(() => {
        switch (campaignConfig.campaignDisplayType) {
            case CampaignDisplayType.CAROUSEL:
                return localizer.msg('landing-page.featured-campaign-carousel-view');
            case CampaignDisplayType.GRID:
                return localizer.msg('landing-page.featured-campaign-grid-view');
            case CampaignDisplayType.STACK:
            default:
                return localizer.msg('landing-page.featured-campaign-list-view');
        }
    }, [campaignConfig.campaignDisplayType, localizer]);

    useEffect(() => {
        void loadInitialCampaigns();
    }, [loadInitialCampaigns]);

    return (
        <Fragment>
            <ConfigWrapper visible={campaignConfig.visible} showConfig={showConfig || showTranslationConfig}
                           onEditClicked={onEditClicked}
                           showTranslationConfig={showTranslationConfig}
                           localizer={localizer}
                           onTranslationClicked={onTranslationClicked}
                           toggleVisibility={onToggleVisibility}
                           className={`${campaignConfig.attribute?.className} featured-campaigns`}
                           accessibleName={renderAccessibleName()}>
                {
                    renderCampaignView()
                }
            </ConfigWrapper>
            {
                showEditModal &&
                <CampaignsConfigModal open={showEditModal}
                                      toggle={toggleEditModal}
                                      onComponentEdited={onComponentEdited}
                                      config={campaignConfig}
                                      updateConfig={updateCampaignData}
                                      localizer={localizer} getCampaigns={getCampaigns}/>
            }
            {
                showTranslationModal && translationLanguageId !== undefined &&
                <TranslationModal translationLanguageId={translationLanguageId} localizer={localizer}
                                  open={showTranslationModal}
                                  toggle={toggleTranslationModal}
                                  onTextTranslated={onTextTranslated}
                                  onComponentEdited={onComponentEdited}
                                  translationConfigs={translationConfigs}
                                  getTranslatedContent={getTranslatedContent}
                                  saveTranslatedContent={saveTranslatedContent}/>
            }
        </Fragment>
    );
};