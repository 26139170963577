import React from 'react';
import {Localizer, TimeAgo} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {MemberSummary} from '../MemberSummary';
import {VoteType} from 'models/enums/VoteType';
import {IdeateStageActivity} from 'models/stage-activity/IdeateStageActivity';
import IconToolsLarge from 'assets/img/icon-tools-large.png';
import IconRightLarge from 'assets/img/icon-right-large.png';

type IdeateReportProps = {
    localizer: Localizer;
    activity: IdeateStageActivity;
    isCurrentActivity: boolean;
}

const VOTE_COUNT_LIMIT = 20;

export const IdeateReport = (props: IdeateReportProps) => {
    const {localizer, activity, isCurrentActivity} = props;
    const {authentication: {actor}} = useAppContext();

    const renderNetVotes = () => {
        return (
            activity.voteEnabled ? (
                <span>
                    {localizer.msg('stage.ideate.net-vote-count')}{' '}
                    <strong>{activity.voteCount}</strong>
                </span>
            ) : (
                <span>{localizer.msg('stage.ideate.net-votes-hidden')}</span>
            )
        );
    };

    const renderReportHeading = () => {
        if (isCurrentActivity) {
            if (activity.participationAllowed) {
                return (
                    <div className="d-flex flex-column flex-md-row justify-content-between">
                        <span>
                            {
                                activity.votingAllowed ? (
                                    localizer.msg('stage.ideate.vote-participate')
                                ) : (
                                    localizer.msg('stage.ideate.voting-disabled', {count: activity.totalVoteCast})
                                )
                            }
                        </span>
                        {
                            renderNetVotes()
                        }
                    </div>
                );
            } else if (activity.viewAllowed) {
                return (
                    <div className="d-flex flex-column flex-md-row justify-content-between">
                        <span>
                            {localizer.msg('stage.ideate.vote-view')}
                        </span>
                        {
                            renderNetVotes()
                        }
                    </div>
                );
            } else {
                return (
                    <>
                        {
                            <div>{localizer.msg('stage.ideate.vote-no-view')}</div>
                        }
                        <div className="text-center mt-3">
                            <img src={IconToolsLarge} alt="Hammer and wrench in a cross"/>
                        </div>
                    </>
                );
            }
        } else {
            return (
                <>
                    <div className="d-flex flex-column flex-md-row justify-content-between">
                        <span>
                            {localizer.msg('stage.ideate.voting-disabled', {count: activity.totalVoteCast})}
                        </span>
                        {
                            (activity.participationAllowed || activity.viewAllowed) &&
                            renderNetVotes()
                        }
                    </div>
                    {
                        (!(activity.participationAllowed || activity.viewAllowed)) && (
                            <div className="text-center mt-3">
                                <img src={IconRightLarge} alt="Checked Icon"/>
                            </div>
                        )
                    }
                </>
            );
        }
    };

    return (
        <div className="ideate-activity-details">
            <div className="mb-3">
                {
                    renderReportHeading()
                }
            </div>
            {
                activity.voters.length > 0 && (activity.participationAllowed || activity.viewAllowed) && (
                    <>
                        <div className="ideate-activity-votes">
                            {
                                activity.voters.map(vote => (
                                    <div className="d-flex justify-content-between border-bottom-1 py-3" key={vote.voter.id}>
                                        <MemberSummary id={vote.voter.id} avatarUrl={vote.voter.avatar} username={vote.voter.username}
                                                       name={vote.voter.id === actor.id ? localizer.msg('stage.common.changed-self') : vote.voter.name}
                                                       identityHidden={vote.voter.identityHidden}/>
                                        <div className="d-none d-sm-block text-end">
                                            {
                                                vote.voteType === VoteType.UP ? (
                                                    <>
                                                        <strong>
                                                            <div className="vote">
                                                                {localizer.msg('stage.ideate.upvoted')}
                                                            </div>
                                                            <TimeAgo localizer={localizer}
                                                                     dateISOString={vote.votedAt}/>
                                                        </strong>
                                                    </>
                                                ) : (
                                                    <>
                                                        <strong>
                                                            <div className="vote">
                                                                {localizer.msg("stage.ideate.downvoted")}
                                                            </div>
                                                            <TimeAgo localizer={localizer}
                                                                     dateISOString={vote.votedAt}/>
                                                        </strong>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        {
                            activity.voters.length === VOTE_COUNT_LIMIT && (
                                <div className="mt-2">
                                    ({localizer.msg("stage.ideate.latest-votes", {voteLimit: VOTE_COUNT_LIMIT})})
                                </div>
                            )
                        }
                    </>
                )
            }
        </div>
    )
}