import {Operation} from './Operation';
import {CampaignOperationType} from './CampaignOperationType';

export class ChangeCampaignWidgetOperation implements Operation {
    constructor(
        public type: CampaignOperationType,
        public title: string,
        public content: string,
        public campaignId: number,
        public sidebarItemKey: string,
    ) {
    }

    getType(): CampaignOperationType {
        return this.type;
    }
}