import {Command} from './Command';
import {CampaignHomeEditor} from './Editors/CampaignHomeEditor';
import {Operation} from 'models/edit-mode/Operation';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';
import {ChangeCampaignBriefOperation} from 'models/edit-mode/ChangeCampaignBriefOperation';
import {BriefConfigField} from 'models/edit-mode/BriefConfigField';
import {CampaignEditableFieldType} from 'models/edit-mode/CampaignEditableFieldType';

export class UpdateCampaignBriefCommand implements Command {
    private readonly campaignHomeEditor: CampaignHomeEditor;
    private readonly oldCampaignBrief: BriefConfigField;
    private readonly newCampaignBrief: BriefConfigField;
    private readonly name: CampaignOperationType;
    private readonly campaignId: number;

    constructor(campaignHomeEditor: CampaignHomeEditor, brief: string, briefEnabled: boolean, campaignId: number) {
        this.campaignHomeEditor = campaignHomeEditor;
        this.oldCampaignBrief = this.campaignHomeEditor.brief;
        this.newCampaignBrief = {
            name: CampaignEditableFieldType.CAMPAIGN_BRIEF,
            value: brief,
            briefEnabled: briefEnabled
        };
        this.campaignId = campaignId;
        this.name = CampaignOperationType.CHANGE_CAMPAIGN_BRIEF;
    }

    execute(): void {
        this.campaignHomeEditor.brief = this.newCampaignBrief;
    }

    undo(): void {
        this.campaignHomeEditor.brief = this.oldCampaignBrief;
    }

    getType(): CampaignOperationType {
        return this.name;
    }

    getOperation(): Operation {
        return new ChangeCampaignBriefOperation(
            this.name,
            this.campaignHomeEditor.brief.value,
            this.campaignHomeEditor.brief.briefEnabled,
            this.campaignId);
    }
}