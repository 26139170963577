import {useCallback} from 'react';
import {useCommunityService} from 'hooks/useService';
import {TagStatsHolder} from 'models/TagStatsHolder';
import {SidebarSocialSubscription} from 'models/SidebarSocialSubscription';
import {KudosData} from 'models/KudosData';
import {SidebarLeaderboardItem} from 'models/SidebarLeaderboardItem';
import {SharingMedia} from 'models/SharingMedia';
import {SidebarCustomWidget} from 'models/SidebarCustomWidget';

export const useSidebarService = () => {
    const communityService = useCommunityService();

    const addSidebarComponent = useCallback((components: { [key: string]: JSX.Element }, key: string, component: JSX.Element) => {
        components[key] = component;
    }, []);

    const fetchSidebarTags = useCallback(async (campaignId?: number, stageId?: number, limit?: number): Promise<TagStatsHolder> => {
        if (communityService !== null) {
            return await communityService.fetchSidebarTags(campaignId, stageId, limit);
        }
        return TagStatsHolder.EMPTY;
    }, [communityService]);


    const fetchSidebarSocialShare = useCallback(async (): Promise<SharingMedia[]> => {
        if (communityService !== null) {
            return await communityService.fetchSidebarSocialShare();
        }
        return [];
    }, [communityService]);

    const fetchSocialSubscription = useCallback(async (): Promise<SidebarSocialSubscription> => {
        if (communityService !== null) {
            return await communityService.fetchSocialSubscription();
        }
        return SidebarSocialSubscription.Empty;
    }, [communityService]);

    const fetchSidebarContactWidget = useCallback(async (): Promise<SidebarCustomWidget> => {
        if (communityService !== null) {
            return await communityService.fetchSidebarContactWidget();
        }
        return SidebarCustomWidget.EMPTY;
    }, [communityService]);

    const fetchKudos = useCallback(async (): Promise<KudosData> => {
        if (communityService !== null) {
            return await communityService.fetchKudos();
        }
        return KudosData.EMPTY;
    }, [communityService]);

    const fetchSidebarLeaderboardItem = useCallback(async (campaignId?: number): Promise<Array<SidebarLeaderboardItem>> => {
        if (communityService !== null) {
            return await communityService.fetchSidebarLeaderboardItem(campaignId);
        }
        return [];
    }, [communityService]);

    const fetchCommunitySummary = useCallback(async (): Promise<SidebarCustomWidget> => {
        if (communityService !== null) {
            return await communityService.fetchCommunityWidget();
        }
        return SidebarCustomWidget.EMPTY;
    }, [communityService]);

    const fetchCampaignWidget = useCallback(async (campaignId: number, sidebarItemKey: string): Promise<SidebarCustomWidget> => {
        if (communityService !== null) {
            return await communityService.fetchCampaignWidget(campaignId, sidebarItemKey);
        }
        return SidebarCustomWidget.EMPTY;
    }, [communityService]);


    return {
        addSidebarComponent,
        fetchSidebarTags,
        fetchSidebarSocialShare,
        fetchSocialSubscription,
        fetchSidebarContactWidget,
        fetchKudos,
        fetchSidebarLeaderboardItem,
        fetchCommunitySummary,
        fetchCampaignWidget,
    };
};