import React, {Fragment, useCallback, useState} from 'react';
import {FieldValues, useForm, useWatch} from 'react-hook-form';
import {ActionButton, Avatar} from '@ideascale/ui';
import {
    Actor,
    AttachmentType,
    ClassificationAttachmentAttributesData,
    ClassificationAttribute,
    ClassificationsHolder,
    ClassificationTreeMenu,
    ClassificationUtil,
    ClassifiedAttachment,
    FileAttachmentDetail,
    FileUploadResponse,
    HookFormProvider,
    Localizer,
    UploadProgressCallback,
    useApiErrorResponseHandler,
    useAttachmentClassificationErrorHandler,
    useFileUploadIntermediary,
    useHandleFormSubmit
} from '@ideascale/commons';
import {useIdeasContext} from 'contexts/IdeasContext';
import {useIdeaAttachment} from 'hooks/useIdeaAttachment';
import {useUpdateEffectiveClassifications} from 'hooks/useUpdateEffectiveClassifications';
import {AttachmentParameters} from 'models/AttachmentParameters';
import {CreateFileAttachment} from 'components/idea-details-tabs/CreateFileAttachment';


type AttachmentFormProps = {
    ideaId: number;
    localizer: Localizer;
    maxFileSizeLimit: number;
    actor: Actor;
    uploadFile: (formData: FormData, onUploadProgress: UploadProgressCallback) => Promise<FileUploadResponse>;
    classificationEnabled: boolean;
    classificationsMenuData?: ClassificationsHolder | {};
}

const DEFAULT_FIELD_VALUES = {attachments: []};

export const AttachmentForm = (props: AttachmentFormProps) => {
    const {
        ideaId,
        localizer,
        maxFileSizeLimit,
        actor,
        uploadFile,
        classificationEnabled,
        classificationsMenuData,
    } = props;
    const methods = useForm<{ attachments: FileAttachmentDetail[] }>({defaultValues: DEFAULT_FIELD_VALUES});
    const {
        handleSubmit,
        reset,
        control,
        clearErrors,
        formState: {isSubmitting, errors},
        setError
    } = methods;
    const attachments = useWatch({control, name: 'attachments'});
    const {ideaListFilterQueryKey} = useIdeasContext();
    const onFormSubmit = useHandleFormSubmit(handleSubmit);
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const {saveAttachmentMutation} = useIdeaAttachment(ideaListFilterQueryKey);
    const {onUploadFile, fileUploading} = useFileUploadIntermediary(uploadFile);
    const {onClassificationError} = useAttachmentClassificationErrorHandler({
        fieldKey: 'attachments',
        setError,
        localizer
    });
    const {updateIdeaEffectiveClassifications} = useUpdateEffectiveClassifications();
    const [selectedAttachmentClassification, setSelectedAttachmentClassification] = useState<ClassificationAttribute>();
    const [appliedAttachmentClassificationsData, setAppliedAttachmentClassificationsData] = useState<ClassificationAttachmentAttributesData>({});
    const [appliedAttachmentClassifications, setAppliedAttachmentClassifications] = useState<ClassifiedAttachment>({});

    const updateAppliedAttachmentClassifications = useCallback((key: string, value: ClassifiedAttachment) => {
        setAppliedAttachmentClassificationsData(prev => {
            return {...prev, [key]: value};
        });
        setAppliedAttachmentClassifications(value);
    }, []);

    const onSubmitSuccess = async (data: FieldValues) => {
        try {
            const attributeApiParameters = classificationEnabled ?
                [
                    ClassificationUtil.buildAttachmentClassificationRequest(
                        'filename',
                        data.attachments.map((attachmentDetail: FileAttachmentDetail) => attachmentDetail.filename),
                        appliedAttachmentClassificationsData['attachments']
                    )
                ] : undefined;
            await saveAttachmentMutation.mutateAsync({
                ideaId,
                attachmentParams: {
                    attachments: data.attachments.map((attachmentDetail: FileAttachmentDetail) => ({
                        fileName: attachmentDetail.filename,
                        note: attachmentDetail.note,
                        altText: attachmentDetail.altText.trim() || attachmentDetail.filename
                    })),
                    attributeApiParameters
                } as AttachmentParameters
            });
            await updateIdeaEffectiveClassifications(ideaId, classificationEnabled);
            reset(DEFAULT_FIELD_VALUES);
            setSelectedAttachmentClassification(undefined);
            setAppliedAttachmentClassificationsData({});
            setAppliedAttachmentClassifications({});
        } catch (error: any) {
            handleErrorResponse(error, {setFormError: setError});
        }
    };

    return (
        <Fragment>
            <div className="d-flex">
                <Avatar className="me-4" src={actor.avatar} size="md"
                        alt={actor.username ? localizer.msg('common.user-avatar', {username: actor.username}) : ''}/>
                <HookFormProvider {...methods}>
                    <form className="flex-grow-1" id="idea-attachment-form"
                          aria-label={localizer.msg('common.form.attachment.upload-attachment')}
                          onSubmit={onFormSubmit(data => onSubmitSuccess(data), (isSubmitting || fileUploading || (attachments?.length || 0) < 1))}>
                        <div
                            className={`${errors.attachments && !Array.isArray(errors.attachments) && classificationEnabled ? 'has-error' : ''}`}>
                            {
                                classificationEnabled &&
                                <ClassificationTreeMenu
                                    className="mb-2"
                                    localizer={localizer}
                                    id={`attachments-tab`}
                                    classifications={classificationsMenuData || {}}
                                    appliedClassifications={selectedAttachmentClassification}
                                    onApplyClassifications={value => {
                                        clearErrors('attachments');
                                        setSelectedAttachmentClassification(value);
                                    }}/>
                            }
                            <CreateFileAttachment
                                attachmentType={AttachmentType.IDEA}
                                localizer={localizer}
                                inputId="attachments"
                                maxFileSizeInMb={maxFileSizeLimit}
                                uploadFile={onUploadFile}
                                multiple={true}
                                includeAttachmentNote={true}
                                classificationsMenuData={classificationsMenuData}
                                classificationEnabled={classificationEnabled}
                                selectedClassification={selectedAttachmentClassification}
                                updateAppliedAttachmentClassifications={updateAppliedAttachmentClassifications}
                                appliedClassifications={appliedAttachmentClassifications}
                                onClassificationError={onClassificationError}/>
                            {
                                methods.formState.errors?.attachments && (methods.formState.errors?.attachments as any)?.type === 'classificationRequired' && (
                                    <p className="invalid-feedback d-block" data-role="field-error" aria-live="polite">
                                        {(methods.formState.errors.attachments as any)?.message}
                                    </p>
                                )
                            }
                        </div>
                    </form>
                </HookFormProvider>
            </div>
            <div className="d-flex flex-row-reverse mt-4">
                <ActionButton type="submit"
                              form="idea-attachment-form"
                              loading={isSubmitting || fileUploading}
                              disabled={(attachments?.length || 0) < 1}>
                    {localizer.msg('idea.attachment.upload')}
                </ActionButton>
            </div>
        </Fragment>
    );
};