import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import cache from 'i18next-localstorage-cache';
import languageDetector from 'i18next-browser-languagedetector';
import {I18nUtil} from '@ideascale/commons';

const translationEN = require('./assets/lang/en');

i18n
  .use(initReactI18next)
  .use(cache)
  .use(languageDetector)
  .init({
    ...I18nUtil.defaultOptions,
    resources: {
      en: {
        translation: Object.assign(translationEN, I18nUtil.commonLocalizations())
      }
    }
  } as object).then();

export default i18n;