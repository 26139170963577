import React, {useCallback, useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {ClassificationsHolder, useApiErrorResponseHandler} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useClassificationService} from 'hooks/useClassificationService';
import {useCommunityService} from 'hooks/useService';
import {useFileUploadService} from 'hooks/useFileUploadService';
import {AttachmentForm} from 'components/idea/AttachmentForm';
import {AttachmentList} from 'components/idea/AttachmentList';
import {FileAttachmentsHolder} from 'models/FileAttachmentsHolder';
import {QUERY_KEYS} from 'constants/AppConstants';
import './AttachmentsContainer.scss';

type AttachmentsContainerProps = {
    ideaId: number;
}

export const AttachmentsContainer = (props: AttachmentsContainerProps) => {
    const {ideaId} = props;
    const localizer = useLocalizer();
    const {authentication: {actor}, communityConfig: {maxFileSizeLimit, classificationEnabled}} = useAppContext();
    const communityService = useCommunityService();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const {data, isLoading} = useQuery([QUERY_KEYS.IDEA_ATTACHMENTS, ideaId],
        ({queryKey}) => fetchIdeaAttachments(Number(queryKey[1]))
    );

    const [classificationsMenuData, setClassificationsMenuData] = useState<ClassificationsHolder | {}>({});
    const {currentCampaign, communityConfig} = useAppContext();
    const {uploadFile} = useFileUploadService();
    const {fetchClassifications} = useClassificationService();

    const fetchIdeaAttachments = useCallback(async (ideaId: number) => {
        if (communityService) {
            return await communityService.fetchIdeaAttachments(ideaId);
        }
        return FileAttachmentsHolder.EMPTY;
    }, [communityService]);

    useEffect(() => {
        (async () => {
                try {
                    if (classificationEnabled && currentCampaign?.id) {
                        const response = await fetchClassifications(currentCampaign?.id);
                        setClassificationsMenuData(response);
                    }
                } catch (error: any) {
                    handleErrorResponse(error);
                }
            }
        )();
    }, [classificationEnabled, currentCampaign?.id, fetchClassifications, handleErrorResponse]);

    return (
        <article className="idea-attachments-tab">
            <div className="attachment-list d-flex flex-column">
                <h3 className="sr-only">Add your attachment</h3>
                <AttachmentList
                    ideaId={ideaId}
                    localizer={localizer}
                    attachments={data?.attachments || []}
                    isLoading={isLoading}
                    classificationEnabled={communityConfig.classificationEnabled}
                    attributes={data?.attributes}/>
            </div>
            {
                data?.fileAttachmentAllowed &&
                <div className="react-file-uploader attachment-form-container border-top-1">
                    <AttachmentForm classificationEnabled={communityConfig.classificationEnabled}
                                    classificationsMenuData={classificationsMenuData} localizer={localizer}
                                    actor={actor} uploadFile={uploadFile} ideaId={ideaId}
                                    maxFileSizeLimit={maxFileSizeLimit}/>
                </div>
            }
        </article>
    );
};