import React, {Fragment, useCallback} from 'react';
import {Trans} from 'react-i18next';
import {PageTheme, UploadedResponse, UploadProgressCallback, useToggle} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {useLandingPageService} from 'hooks/useService';
import {MediaComponent} from 'components/landing-page/MediaComponent';
import {MediaConfigModal} from 'components/landing-page/MediaConfigModal';
import {ConfigWrapper} from 'components/landing-page/ConfigWrapper';
import {Media} from 'models/landing-page/Media';
import {useAppContext} from 'contexts/AppContext';

type MediaContainerProps = {
    showConfig: boolean;
    data: Media;
    onToggleVisibility: (data: Media) => Promise<void>;
    theme: PageTheme;
    updateMediaData: (data: Media) => Promise<void>
    imageCropWidth: number;
    imageCropHeight: number;
    onComponentEdited: () => void;
    defaultImage?: string;
    allowVideo?: boolean;
    mediaPreviewMode?: 'full' | 'small' | 'medium';
    accessibleName?: string;
}

export const MediaContainer = (props: MediaContainerProps) => {
    const {
        showConfig,
        data,
        onToggleVisibility,
        updateMediaData,
        imageCropWidth,
        imageCropHeight,
        theme,
        defaultImage,
        allowVideo = true,
        onComponentEdited,
        mediaPreviewMode = 'small',
        accessibleName = ''
    } = props;
    const [showEditModal, toggleEditModal] = useToggle(false);
    const localizer = useLocalizer();
    const landingPageService = useLandingPageService();
    const {editModeEnabled} = useAppContext();

    const onEditClicked = useCallback(() => {
        toggleEditModal();
    }, [toggleEditModal]);

    const uploadImage = useCallback(async (data: FormData, onUploadProgress: UploadProgressCallback) => {
        if (landingPageService) {
            return await landingPageService.uploadImage(data, onUploadProgress);
        }
        return UploadedResponse.EMPTY;
    }, [landingPageService]);

    const toggleVisibility = async () => {
        onToggleVisibility(data).then(() => {
            onComponentEdited();
        });
    };

    return (
        <Fragment>
            <ConfigWrapper showConfig={showConfig} onEditClicked={onEditClicked} toggleVisibility={toggleVisibility}
                           visible={data?.visible} localizer={localizer}
                           className={`${theme === PageTheme.BERNERS_LEE ? 'highlight-shadow' : ''} media-component`}
                           accessibleName={accessibleName || localizer.msg('landing-page.components.media.media')}>
                <MediaComponent editModeEnabled={editModeEnabled}
                                defaultImage={defaultImage} config={data} allowVideo={allowVideo}/>
            </ConfigWrapper>
            {
                showEditModal &&
                <MediaConfigModal
                    onComponentEdited={onComponentEdited}
                    open={showEditModal} toggle={toggleEditModal}
                    previewMode={mediaPreviewMode}
                    uploadImage={uploadImage}
                    config={data}
                    localizer={localizer}
                    allowVideo={allowVideo}
                    note={theme === PageTheme.ARMSTRONG
                        ?
                        <Trans i18nKey="landing-page.components.edit.instruction-list.image-note">
                            <strong>Note</strong>
                        </Trans>
                        :
                        undefined
                    }
                    defaultImage={defaultImage}
                    updateConfig={updateMediaData}
                    imageCropWidth={imageCropWidth} imageCropHeight={imageCropHeight}/>
            }
        </Fragment>
    );
};