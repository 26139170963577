import {EditModeValidationError} from './EditModeValidationError';
import {CommunityOperationType} from './CommunityOperationType';
import {CampaignOperationType} from './CampaignOperationType';

export class EditModeValidationErrors {
    public static EMPTY = new EditModeValidationErrors([]);

    constructor(private editModeValidationError: EditModeValidationError[]) {
    }

    findError(fieldType: CommunityOperationType | CampaignOperationType) {
        return this.editModeValidationError.find(validationError => validationError.type === fieldType);
    };

    hasError(fieldType: CommunityOperationType | CampaignOperationType): boolean {
        return !!this.findError(fieldType);
    };

    clearError(fieldType: CommunityOperationType | CampaignOperationType) {
        this.editModeValidationError = this.editModeValidationError.filter(validationError => validationError.type !== fieldType);
    }
}