import React, {useEffect, useState} from 'react';
import {Progress} from 'reactstrap';
import {Localizer} from '@ideascale/commons';
import {EstimateStageActivity} from 'models/stage-activity/EstimateStageActivity';

type EstimateOverallReportProps = {
    localizer: Localizer;
    activity: EstimateStageActivity;
}

type ProgressValues = {
    valueHigh: number;
    costHigh: number;
}

export const EstimateOverallReport = (props: EstimateOverallReportProps) => {
    const {localizer, activity} = props;
    const [progressValues, setProgressValues] = useState<ProgressValues>({
        valueHigh: 0,
        costHigh: 0,
    });

    useEffect(() => {
        setTimeout(() => {
            const allProgressValues = {
                valueHigh: activity.averageValue.percentage,
                costHigh: activity.averageCost.percentage,
            };
            setProgressValues(allProgressValues);
        }, 500);
    }, [activity.averageCost.percentage, activity.averageValue.percentage]);

    return (
        <div>
            <ul className="list-unstyled estimate-value overall-report rounded border-1 mb-5">
                <li className="estimate-value border-bottom-1">
                    <div className="row py-4 align-items-center m-0">
                        <div className="col-12 col-md-6 col-lg-2">
                            <div className="question-summary font-size-md">
                                {localizer.msg('stage.estimate.overall-average-value')}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 estimate-progress">
                            <div role="progressbar" aria-valuenow={progressValues.valueHigh} className="sr-only"
                                 aria-label={localizer.msg('stage.estimate.average-value-estimate')}/>
                            <Progress aria-hidden="true" className="mt-3 mb-3 me-5 progress-high"
                                      value={progressValues.valueHigh}/>
                        </div>
                        <div className="col-12 col-lg-3 question-summary-outer mb-0">
                            <div className="question-summary-value">
                                <span className="d-block">{localizer.msg('stage.estimate.average-estimate')}</span>
                                <span className="font-size-md d-block break-all-words">
                                    {`${localizer.msg(activity.fundUnitKey)} ${activity.averageValue.value}`}
                                </span>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="estimate-cost border-bottom-1">
                    <div className="row py-4 align-items-center m-0">
                        <div className="col-12 col-md-6 col-lg-2">
                            <div className="question-summary font-size-md">
                                {localizer.msg('stage.estimate.overall-average-cost')}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 estimate-progress">
                            <div role="progressbar" aria-valuenow={progressValues.costHigh} className="sr-only"
                                 aria-label={localizer.msg('stage.estimate.average-cost-estimate')}/>
                            <Progress aria-hidden="true" className="mt-3 mb-3 me-5 progress-high"
                                      value={progressValues.costHigh}/>
                        </div>
                        <div className="col-12 col-lg-3 question-summary-outer mb-0">
                            <div className="question-summary-value">
                                <span className="d-block">{localizer.msg('stage.estimate.average-estimate')}</span>
                                <span
                                    className="font-size-md d-block break-all-words">{`${localizer.msg(activity.fundUnitKey)} ${activity.averageCost.value}`}
                                 </span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>

            <div className="row">
                <div className="col-12 col-md-6 col-lg-2">
                </div>
                <div className="col-12 col-md-6 col-lg-10">
                    <h4>{localizer.msg('stage.estimate.average-return', {return: activity.averageReturn})}</h4>
                </div>
            </div>

        </div>
    );
};