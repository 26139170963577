import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import ReactJoyride, {ACTIONS, CallBackProps, EVENTS, LIFECYCLE} from 'react-joyride';
import {eventDispatcher, FEATURE_TOUR_EVENTS, FeatureTourStep, LayoutUtil} from '@ideascale/commons';
import {Scroller} from 'utils/Scroller';
import {CommonUtil} from 'utils/CommonUtil';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useCommunityService} from 'hooks/useService';
import {useFeatureTourStore} from 'stores/useFeatureTourStore';
import {TooltipComponent} from 'components/feature-tour/TooltipComponent';
import {FeatureTourKey} from 'models/enums/FeatureTourKey';
import {IdeaScaleTourStep} from 'models/types/IdeaScaleTourStep';
import {TourStep} from 'models/TourStep';
import './FeatureTourContainer.scss';

interface tourCallbackProps extends CallBackProps {
    stepName: string;
    visited: boolean;
    step: IdeaScaleTourStep;
    type: any;
}

export const FeatureTourContainer = () => {
    const communityService = useCommunityService();
    const localizer = useLocalizer();
    const location = useLocation();

    const [tourSteps, setTourSteps] = useState<IdeaScaleTourStep[]>([]);
    const prevTourStepIndexRef = useRef<number>(0);
    const tourStepIndexSetByOutsideRef = useRef<boolean>(false);
    const {communityConfig: {aiTextAssistEnabled}} = useAppContext();
    const {
        runTour,
        setRunTour,
        onboardInProgress,
        incrementStepIndex,
        currentStepIndex,
        setTourStep,
        setTourStepIndex
    } = useFeatureTourStore();

    const resetTour = useCallback(() => {
        if (tourStepIndexSetByOutsideRef.current) {
            setRunTour(false);
            CommonUtil.wait(300).then(() => {
                setTourStepIndex(prevTourStepIndexRef.current);
                setRunTour(true);
                tourStepIndexSetByOutsideRef.current = false;
            });
        }
    }, [setRunTour, setTourStepIndex]);

    const tourCallback = useCallback((data: tourCallbackProps) => {
        const {action, type, step, lifecycle} = data;

        if (step) {
            setTourStep(step.stepName as FeatureTourStep);
        }

        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type) || step?.visited) {
            incrementStepIndex(action === ACTIONS.PREV ? -1 : 1);
        }

        if (step?.stepName === FeatureTourStep.LUCIDO_SORT_BY_DROPDOWN && LIFECYCLE.READY) {
            CommonUtil.wait(300).then(() => {
                Scroller.scrollToTop();
            });
        }

        if (step?.stepName === FeatureTourStep.LUCIDO_LEADERBOARD && LIFECYCLE.READY) {
            CommonUtil.wait(500).then(() => {
                Scroller.scrollTo('sidebar-leaderboard-members', {offset: -180});
            });
        }

        if (action === ACTIONS.SKIP) {
            setTourSteps(prevState => prevState.map(stepItem => {
                if (stepItem.tourKey === step.tourKey) {
                    stepItem.visited = true;
                }
                return stepItem;
            }));
            communityService?.skipFeatureTour(step.tourKey);
        }

        if ((action === ACTIONS.NEXT || action === ACTIONS.CLOSE) && lifecycle === LIFECYCLE.COMPLETE) {
            const acceptedStepId = tourSteps.find(step => step.stepName === FeatureTourStep.IDEA_SUBMISSION_ASSIST_ACCEPT_DESCRIPTION)?.stepId;
            setTourSteps(prevState => prevState.map(stateStep => {
                if (stateStep.stepName === step.stepName) {
                    stateStep.visited = true;
                }
                return stateStep;
            }));
            if (!step.visited) {
                communityService?.dismissFeatureTour(step.tourKey, step.stepId);
                if (step.stepName === FeatureTourStep.IDEA_SUBMISSION_ASSIST_EDIT_DESCRIPTION && acceptedStepId) {
                    communityService?.dismissFeatureTour(step.tourKey, acceptedStepId);
                }
            }

            if (step.stepName === FeatureTourStep.LUCIDO_NEW_PROFILE) {
                resetTour();
            }
        }
    }, [communityService, incrementStepIndex, resetTour, setTourStep, tourSteps]);

    const getTourSteps = useCallback((stepsResponse: TourStep[]) => {
        const steps: IdeaScaleTourStep[] = [];
        stepsResponse
            .forEach(step => {
                if (!step.visited) {
                    const tourStep: IdeaScaleTourStep = {
                        tourKey: step.tourKey,
                        stepName: step.name,
                        stepId: step.id,
                        content: <span>{localizer.msg(`tour.${step.name.toLowerCase()}.content`)}</span>,
                        title: '',
                        disableBeacon: true,
                        offset: -5,
                        visited: step.visited,
                        target: `.tour-${step.name.toLowerCase()}`,
                        placement: 'top',
                        floaterProps: {id: step.name.toLowerCase()}
                    };

                    if (step.name === FeatureTourStep.LUCIDO_CAMPAIGN_DROPDOWN ||
                        step.name === FeatureTourStep.IDEA_SUBMISSION_ASSIST_NEW_IDEA ||
                        step.name === FeatureTourStep.IDEA_SUBMISSION_ASSIST_EDIT_DESCRIPTION ||
                        step.name === FeatureTourStep.IDEA_SUBMISSION_ASSIST_ACCEPT_DESCRIPTION) {
                        tourStep.placement = 'right';
                    } else if (step.name === FeatureTourStep.LUCIDO_EXPLORE ||
                        step.name === FeatureTourStep.LUCIDO_IDEA_SUBMISSION ||
                        step.name === FeatureTourStep.LUCIDO_LABEL ||
                        step.name === FeatureTourStep.LUCIDO_NEW_PROFILE
                    ) {
                        tourStep.placement = 'left';
                        tourStep.offset = -10;
                    } else if (step.name === FeatureTourStep.LUCIDO_LEADERBOARD) {
                        tourStep.placement = 'left';
                        tourStep.offset = 10;
                    }
                    steps.push({...tourStep});
                }
            });
        return steps;
    }, [localizer]);

    useEffect(() => {
        if (communityService && !LayoutUtil.isMobileLayout()) {
            CommonUtil.wait(1000).then(() => {
                const tourKeys = Object.values(FeatureTourKey).filter(key => !(key === FeatureTourKey.IDEA_SUBMISSION_ASSIST && !aiTextAssistEnabled));
                communityService.getFeatureTours(tourKeys)
                    .then(stepResponse => {
                        setTourSteps(getTourSteps(stepResponse));
                    });
            });
        }
    }, [aiTextAssistEnabled, communityService, getTourSteps, setTourSteps]);

    useEffect(() => {
        eventDispatcher.addListener(FEATURE_TOUR_EVENTS.SET_TOUR_STEP_INDEX, (eventData) => {
            prevTourStepIndexRef.current = currentStepIndex;
            const tourIndex = tourSteps.findIndex(step => !step.visited && step.stepName === eventData);
            if (tourIndex > -1) {
                setRunTour(false);
                CommonUtil.wait(300).then(() => {
                    setTourStepIndex(tourIndex);
                    setRunTour(true);
                    tourStepIndexSetByOutsideRef.current = true;
                });
            }
        });
        return () => {
            eventDispatcher.removeListener(FEATURE_TOUR_EVENTS.SET_TOUR_STEP_INDEX);
        };
    }, [currentStepIndex, setRunTour, setTourStepIndex, tourSteps]);

    useEffect(() => {
        eventDispatcher.addListener(FEATURE_TOUR_EVENTS.RESET_TOUR_STEP_INDEX, resetTour);
        return () => {
            eventDispatcher.removeListener(FEATURE_TOUR_EVENTS.RESET_TOUR_STEP_INDEX);
        };
    }, [resetTour, setRunTour, setTourStepIndex, tourSteps]);

    useEffect(() => {
        eventDispatcher.addListener(FEATURE_TOUR_EVENTS.PAUSE_TOUR, () => {
            setRunTour(false);
        });
        return () => {
            eventDispatcher.removeListener(FEATURE_TOUR_EVENTS.PAUSE_TOUR);
        };
    }, [setRunTour]);

    useEffect(() => {
        eventDispatcher.addListener(FEATURE_TOUR_EVENTS.RUN_TOUR, () => {
            setRunTour(true);
        });
        return () => {
            eventDispatcher.removeListener(FEATURE_TOUR_EVENTS.RUN_TOUR);
        };
    }, [setRunTour]);

    useEffect(() => {
        if (tourSteps && tourSteps.filter(step => !step.visited).length > 0 && !onboardInProgress) {
            setRunTour(false);
            CommonUtil.wait(1000).then(() => {
                setRunTour(true);
            });
        }
    }, [location, onboardInProgress, setRunTour, tourSteps]);

    return (
        <Fragment>
            {
                tourSteps.filter(step => !step.visited).length > 0 &&
                <ReactJoyride steps={tourSteps}
                              callback={(callBackProps) => tourCallback(callBackProps as tourCallbackProps)}
                              tooltipComponent={TooltipComponent}
                              showSkipButton={true}
                              disableOverlay={true}
                              scrollOffset={200}
                              disableScrollParentFix={true}
                              stepIndex={currentStepIndex}
                              run={runTour}
                              locale={{
                                  skip: localizer.msg('common.skip-tour'),
                                  close: localizer.msg('common.close'),
                                  next: localizer.msg('common.next'),
                                  last: localizer.msg('common.finish')
                              }}/>
            }
        </Fragment>
    );
};