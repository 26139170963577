import {Component} from 'models/landing-page/Component';
import {LandingPageComponentType} from 'models/enums/landing-page/LandingPageComponentType';
import {UiAttribute} from 'models/landing-page/UiAttribute';
import {TextColor} from 'models/enums/landing-page/TextColor';

export class TextComponentData extends Component {
    public static EMPTY = new TextComponentData(0, 0, LandingPageComponentType.TEXT, false, UiAttribute.EMPTY, '', TextColor.DEFAULT, '', '');

    constructor(
        public id: number,
        public languageId: number,
        public type: LandingPageComponentType.TEXT,
        public visible: boolean,
        public attribute: UiAttribute,
        public text: string,
        public textColor: TextColor,
        public title?: string,
        public subtitle?: string
    ) {
        super(id, languageId, type, visible, attribute, title, subtitle);
    }
}