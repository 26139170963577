import {useCallback} from 'react';
import {useQueryClient} from 'react-query';
import {useIdeaUpdater} from './useIdeaUpdater';
import {PermissionHolder} from 'models/IdeaDetail';
import {QUERY_KEYS} from 'constants/AppConstants';

export const useIdeaCommentEnabled = (ideaListQueryKey: any = '') => {
    const queryClient = useQueryClient();
    const {updateIdea, updateIdeaDetails} = useIdeaUpdater();

    const ideaListIdeaCommentEnabledMutation = useCallback(async (ideaId: number, commentEnabled: boolean) => {
        await updateIdea(ideaListQueryKey, ideaId, 'commentingEnabled', commentEnabled);
        await queryClient.invalidateQueries([QUERY_KEYS.MODERATE_ACTIONS, ideaId]);
    }, [ideaListQueryKey, queryClient, updateIdea]);

    const ideaDetailsIdeaCommentEnabledMutation = useCallback(async (ideaId: number, commentEnabled: boolean, permissionHolder: PermissionHolder) => {
        await updateIdeaDetails(ideaId, 'commentingEnabled', commentEnabled);
        await updateIdeaDetails(ideaId, 'permissionHolder', permissionHolder);
        await queryClient.invalidateQueries([QUERY_KEYS.MODERATE_ACTIONS, ideaId]);
    }, [queryClient, updateIdeaDetails]);

    return {ideaListIdeaCommentEnabledMutation, ideaDetailsIdeaCommentEnabledMutation};
};