import React from 'react';
import {IdeaListContextProvider} from 'contexts/IdeaListContext';
import {IdeaListContainer} from './IdeaListContainer';

type IdeaListWithContextProviderContainerProps = {
    campaignId: string | 'active' | 'expired',
    stageKey: string;
    urlOrder: string;
    urlTag: string;
    urlModeratorTag: string;
    urlLabel: string;
}

export const IdeaListWithContextProviderContainer = (props: IdeaListWithContextProviderContainerProps) => {
    return <IdeaListContextProvider>
        <IdeaListContainer {...props}/>
    </IdeaListContextProvider>;
};