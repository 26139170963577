import {Operation} from './Operation';
import {CommunityOperationType} from './CommunityOperationType';

export class ChangeCommunityTileOperation implements Operation {
    constructor(private type: CommunityOperationType, private fileId: number) {
    }

    getType(): CommunityOperationType {
        return this.type;
    }

}