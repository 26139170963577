import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Checkbox, ParagraphSkeleton} from '@ideascale/ui';
import {
    BodyTagAttributes,
    goTo,
    HtmlRenderer,
    PageTitle,
    useApiErrorResponseHandler,
    useToggle,
    useUrlQuery
} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useCommunityService} from 'hooks/useService';
import {PageLayoutContainer} from 'containers/PageLayoutContainer';
import {PageHeader} from 'components/PageHeader';
import {ROUTES} from 'shared/Routes';
import 'pages/MembershipTosAcceptPage.scss';

export const MembershipTosAcceptPage = () => {
    const localizer = useLocalizer();
    const {communityConfig, authentication} = useAppContext();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const communityService = useCommunityService();
    const [dataFetching, setDataFetching] = useState(false);
    const [loading, setLoading] = useState(false);
    const [communityTosChecked, toggleCommunityTosChecked] = useToggle(false);
    const [membershipTosContent, setMembershipTosContent] = useState('');
    const navigate = useNavigate();
    const urlQuery = useUrlQuery();

    const fetchCommunityTosContent = useCallback(async (): Promise<string> => {
        if (communityService !== null) {
            return await communityService.fetchCommunityTosContent();
        }
        return '';
    }, [communityService]);

    const submitMembershipTos = useCallback(async (): Promise<void> => {
        if (communityService !== null) {
            return await communityService.submitMembershipTos();
        }
    }, [communityService]);

    const submitTos = async () => {
        try {
            setLoading(true);
            await submitMembershipTos();

            if (urlQuery.has(ROUTES.QUERY_PARAMS.TOS_REDIRECT_URL)) {
                const encodedTosReturnUrl = urlQuery.get(ROUTES.QUERY_PARAMS.TOS_REDIRECT_URL);

                if (encodedTosReturnUrl) {
                    const decodedTosReturnUrl = decodeURIComponent(encodedTosReturnUrl);
                    const tosReturnUrl = new URL(decodedTosReturnUrl);
                    goTo(tosReturnUrl.host === window.location.host ? tosReturnUrl : communityConfig.url);
                }
            } else {
                goTo(communityConfig.url);
            }
        } catch (error: any) {
            handleErrorResponse(error);
        } finally {
            setLoading(false);
        }
    };

    const getCommunityTosContent = useCallback(async () => {
        try {
            setDataFetching(true);
            const tosContent = await fetchCommunityTosContent();
            setMembershipTosContent(tosContent);
        } catch (error: any) {
            handleErrorResponse(error);
        } finally {
            setDataFetching(false);
        }
    }, [fetchCommunityTosContent, handleErrorResponse]);

    useEffect(() => {
        void getCommunityTosContent();
    }, [getCommunityTosContent]);

    useEffect(() => {
        if (!authentication.isEmpty() && !authentication.isCommunityTosAcceptanceRequired()) {
            navigate(ROUTES.HOME);
        }
    }, [authentication, navigate]);

    return (
        <div className="membership-tos-accept-page">
            <PageTitle title={`${communityConfig.name} - ${localizer.msg('page.community-terms-of-service.title')}`}/>
            <BodyTagAttributes data-test-element-id="community-tos-accept-page"/>
            <PageHeader logo={communityConfig.logo} logoAltText={communityConfig.altText}
                        hideLogo={communityConfig.hideCommunityLogo}
                        bannerImage={communityConfig.bannerImageUrl}
                        logoBgTransparent={!communityConfig.logoFrameEnabled}
                        bannerAltText={communityConfig.bannerImageAltText}
                        bannerGradientEnabled={communityConfig.bannerGradientEnabled}
                        textColor={communityConfig.bannerTitleInBlack ? 'dark' : 'light'}
                        heading={communityConfig.name}
                        headingMeta=""/>
            <PageLayoutContainer mainContent={
                <div className="card panel py-3 px-4 mx-lg-5 tos-container">
                    <div className="card-body pt-0 membership-tos-accept-content mb-3 shadow-none border-1 pb-2">
                        {
                            dataFetching ?
                                <ParagraphSkeleton rows={14}/>
                                :
                                <HtmlRenderer className="tos-content" content={membershipTosContent}/>
                        }
                    </div>
                    <div className="my-3 d-flex justify-content-between align-items-center">
                        <Checkbox inputId="terms-field" checked={communityTosChecked}
                                  className="fw-bold" disabled={dataFetching || loading}
                                  label={localizer.msg('terms-of-service.accept-the-community-terms-of-service')}
                                  onChange={toggleCommunityTosChecked}/>
                        <button className="btn btn-primary" type="button" onClick={submitTos}
                                disabled={!communityTosChecked || dataFetching || loading}>
                            {localizer.msg('common.confirm')}
                        </button>
                    </div>
                </div>
            }/>
        </div>
    );
};