import {StageSummaryData} from './types/StageSummaryData';

export abstract class BaseIdeaStageSummary {
    protected constructor(
        public stage: StageSummaryData,
        public viewAllowed: boolean,
        public participationAllowed: boolean,
        public percentage: number,
        public callToActionLabel: string,
    ) {
    }

    abstract getIconName(): string;
}