import React, {Fragment} from 'react';
import {Campaign, CampaignSelect, CampaignsHolder, PagedResponseContent} from '@ideascale/commons';
import {Icon, OptionType} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {SearchInputGroup} from 'shared/SearchInputGroup';
import {PageParameters} from 'models/types/PageParameters';
import styles from './SearchField.module.scss';

type CampaignSearchFieldProps = {
    fetchCampaigns(pageParameters: PageParameters): Promise<PagedResponseContent<CampaignsHolder<Campaign>>>;
    defaultValue?: OptionType[] | OptionType;
    setCampaigns: (stages: OptionType[]) => void;
    inputGroupClassName?: string;
    required?: boolean;
}

export const CampaignSearchField = (props: CampaignSearchFieldProps) => {
    const {fetchCampaigns, setCampaigns, defaultValue, inputGroupClassName = "", required = false} = props;
    const localizer = useLocalizer();
    const {communityConfig: {classificationEnabled}} = useAppContext();

    return (
        <div data-test-element-id="all-campaign" className={`form-group ${styles.campaignSearch}`}>
            <label className="fw-bold control-label" htmlFor="campaign-search">
                {localizer.msg('Campaign')}
                {required && <Fragment>
                    <span className="font-size-lg" aria-hidden={true}>*</span>
                    <span className="sr-only">{localizer.msg('common.form.required')}</span>
                </Fragment>}
            </label>
            <SearchInputGroup className={inputGroupClassName}
                              groupText={<Icon name="loudspeaker" fill="#666" height={18} width={18}
                                               iconSpritePath={svgIconsPath}/>}>
                <CampaignSelect
                    localizer={localizer}
                    svgIconsPath={svgIconsPath}
                    classificationEnabled={classificationEnabled}
                    placeholder={'All Campaigns'}
                    isMulti={true}
                    className={'campaign-search'}
                    inputId={'campaign-search'}
                    value={defaultValue}
                    onSelect={(value) => setCampaigns(value as OptionType[])}
                    fetchCampaigns={fetchCampaigns}
                />
            </SearchInputGroup>
        </div>
    );
};
