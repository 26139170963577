import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {useApiErrorResponseHandler} from '@ideascale/commons';
import {ParagraphSkeleton} from '@ideascale/ui';
import {ActivityCard} from 'components/idea/details/ActivityCard';
import {AssessmentRatingActivity} from 'components/stage-activities/AssessmentRatingActivity';
import {AssessmentViewActivity} from 'components/stage-activities/AssessmentViewActivity';
import {BaseStageActivityProps} from 'models/types/BaseStageActivityProps';
import {StageActivityType} from 'models/enums/StageActivityType';
import {AssessmentStageActivity} from 'models/stage-activity/AssessmentStageActivity';
import {AssessmentStageRatingActivity} from 'models/stage-activity/AssessmentStageRatingActivity';
import {AssessmentStageViewActivity} from 'models/stage-activity/AssessmentStageViewActivity';
import {AssessmentStageSummary} from 'models/AssessmentStageSummary';
import {StageActionResponse} from 'models/StageActionResponse';
import {AssessmentRatingParameters} from 'models/types/AssessmentRatingParameters';
import {BaseIdeaStageSummary} from 'models/BaseIdeaStageSummary';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import iconRightLarge from 'assets/img/icon-right-large.png';
import iconToolsLarge from 'assets/img/icon-tools-large.png';
import './AssessmentActivity.scss';

type AssessmentActivityProps = BaseStageActivityProps & {
    stageLabel: string;
    stageDescription: string;
    updateIdeaStageSummary: <T extends BaseIdeaStageSummary>(stageSummary: T) => void;
    fetchAssessmentStageRankActivity: (stageId: number) => Promise<AssessmentStageRatingActivity>;
    saveAssessmentRank: (assessmentId: number, questionId: number, parameters: AssessmentRatingParameters) => Promise<StageActionResponse<AssessmentStageActivity, AssessmentStageSummary>>;
};

export const AssessmentActivity = (props: AssessmentActivityProps) => {
    const {
        localizer,
        isCurrentStage,
        stageId,
        headerSummary,
        headerTitle,
        stageLabel,
        stageDescription,
        fetchActivity,
        fetchAssessmentStageRankActivity,
        saveAssessmentRank,
        updateIdeaStageSummary,
    } = props;
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [loading, setLoading] = useState(true);
    const [activity, setActivity] = useState<AssessmentStageActivity>(AssessmentStageActivity.EMPTY);
    const {assessmentId} = activity;
    const canViewAssessmentText = activity.privateReview ? ((activity as AssessmentStageViewActivity).viewOverallRating) : true;

    const renderPermissionBlock = () => {
        if (isCurrentStage) {
            if (activity.participationAllowed) {
                return <div className="mb-3">{localizer.msg('stage.assessment.assess-current')}</div>;
            } else if (activity.editRatingAllowed) {
                return <>
                    {
                        activity.activityType === StageActivityType.VIEW &&
                        <div className="text-end mb-3">
                            <button className="btn btn-secondary" type="button" onClick={() => onEditRank()}>
                                {localizer.msg('stage.assessment.edit-rank')}
                            </button>
                        </div>
                    }
                    <div className="mb-3">{localizer.msg('stage.assessment.assess-latest-edit')}</div>
                </>;
            } else if (activity.viewAllowed && canViewAssessmentText) {
                return <div className="mb-3">{localizer.msg('stage.assessment.assess-latest-view')}</div>;
            } else {
                return <>
                    <div className="mb-3">{localizer.msg('stage.assessment.assess-latest')}</div>
                    <div className="text-center"><img src={iconToolsLarge} alt="Hammer and wrench in a cross"/></div>
                </>;
            }
        } else {
            return <Fragment>
                <div className="mb-3">{localizer.msg('stage.assessment.closed-stage', {stageLabel})}</div>
                {!(activity.participationAllowed || activity.viewAllowed) &&
                    <div className="text-center mt-3"><img src={iconRightLarge} alt="Checked Icon"/></div>
                }
            </Fragment>;
        }
    };

    const onEditRank = useCallback(async () => {
        try {
            const activity = await fetchAssessmentStageRankActivity(stageId);
            setActivity(activity);
        } catch (error: any) {
            handleErrorResponse(error);
        }
    }, [fetchAssessmentStageRankActivity, handleErrorResponse, stageId]);

    const onSaveAssessmentActivity = useCallback(async (questionId: number, parameters: AssessmentRatingParameters) => {
        try {
            const response = await saveAssessmentRank(assessmentId, questionId, parameters);
            if (!response.error) {
                updateIdeaStageSummary(response.stageSummary);
            }
            if (response.stageActivity) {
                setActivity(response.stageActivity);
            }
        } catch (e: any) {
            throw e;
        }
    }, [assessmentId, saveAssessmentRank, updateIdeaStageSummary]);

    const loadAssessmentActivity = useCallback(async () => {
        try {
            const activity = await fetchActivity(stageId);
            setActivity(activity as AssessmentStageActivity);
        } catch (error: any) {
            handleErrorResponse(error);
        } finally {
            setLoading(false);
        }
    }, [fetchActivity, handleErrorResponse, stageId]);

    const onCardEntered = () => {
        if (activity.assessmentId === AssessmentStageActivity.EMPTY.assessmentId) {
            void loadAssessmentActivity();
        }
    };

    useEffect(() => {
        if (isCurrentStage) {
            void loadAssessmentActivity();
        }
    }, [isCurrentStage, loadAssessmentActivity]);

    return (
        <div className="pb-3">
            <ActivityCard containerClass="campaign-stages"
                          headerTitle={headerTitle}
                          headerSummary={
                              <p className="mb-0 mt-3 fw-normal">
                                  {headerSummary}
                              </p>
                          }
                          isCurrentStage={isCurrentStage}
                          svgIconSprite={svgIconPath}
                          defaultOpen={isCurrentStage} onEntered={onCardEntered}>
                {(loading || !activity)
                    ? <ParagraphSkeleton rows={5}/>
                    : <div>
                        {
                            renderPermissionBlock()
                        }
                        {
                            activity.activityType === StageActivityType.PARTICIPATE
                                ? <AssessmentRatingActivity
                                    localizer={localizer}
                                    activity={activity as AssessmentStageRatingActivity}
                                    stageLabel={stageLabel} stageDescription={stageDescription}
                                    reloadAssessmentActivity={loadAssessmentActivity}
                                    onSaveAssessmentActivity={onSaveAssessmentActivity}
                                />
                                : activity.viewAllowed &&
                                <AssessmentViewActivity
                                    localizer={localizer}
                                    activity={activity as AssessmentStageViewActivity}
                                />
                        }
                    </div>
                }
            </ActivityCard>
        </div>
    );
};
