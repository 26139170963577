import React, {Fragment} from 'react';
import {Icon} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useLocalizer} from 'hooks/useLocalizer';
import {chartItem} from './WorkFlowChart';

type WorkFlowChartItemProps = {
    workflowItem: chartItem
}

export const WorkFlowChartItem = (props: WorkFlowChartItemProps) => {
    const {workflowItem} = props;
    const localizer = useLocalizer();

    return (
        <Fragment>
            <li className={`item position-relative list-inline-item ${workflowItem.visited ? 'stage-visited' : ''} ${workflowItem.current ? 'current-stage' : ''}`}
                key={workflowItem.id}>
                <section className="header">
                    <header>
                        <p className={`h4 stage-name text-truncate ${workflowItem.completed ? '' : 'no-expire-date'}`}
                           title={workflowItem.heading}>
                            {workflowItem.heading}
                        </p>
                        {
                            workflowItem.date &&
                            <p className="mb-0 text-truncate"
                               title={localizer.date(workflowItem.date)}>
                                {
                                    workflowItem.completed
                                        ? localizer.msg('sidebar.campaign.expired-on')
                                        : localizer.msg('sidebar.campaign.expires-on')
                                }
                                {' '} {localizer.date(workflowItem.date)}
                            </p>
                        }
                        <Icon iconSpritePath={svgIconPath}
                              className="mt-n2 active"
                              width={10}
                              height={10}
                              name="chevron-down"/>
                    </header>
                </section>
                <div className="circle d-flex align-items-center justify-content-center">
                    {
                        (workflowItem.completed || workflowItem.visited)
                            ?
                            <div className={`icon ${workflowItem.current ? 'dot' : ''}`}>
                                <Icon className="active"
                                      name="check"
                                      iconSpritePath={svgIconPath}/>
                                <p className="sr-only">{localizer.msg(workflowItem.current ? 'campaign.stages.stage-current' : 'campaign.stages.stage-visited')}</p>
                            </div>
                            :
                            <div className="dot"/>
                    }
                </div>
                <div className="mobile-arrow position-absolute d-md-none">
                    <Icon className="active"
                          name="chevron-down"
                          width={10}
                          height={10}
                          iconSpritePath={svgIconPath}/>
                </div>
            </li>
        </Fragment>
    );
};