import {useMemo} from 'react';
import {PermissionType} from 'models/edit-mode/ResourcePermission';
import {ImageField} from 'models/edit-mode/ImageField';
import {BannerField} from 'models/edit-mode/BannerField';
import {LogoField} from 'models/edit-mode/LogoField';

export const useTabPermission = (imageField: ImageField | BannerField | LogoField) => {
    const tabPermission = useMemo(() => {
        const standardFileDisabled = imageField.standardFilePermission ? imageField.standardFilePermission !== PermissionType.ALLOWED : false;
        const libraryDisabled = imageField.fileLibraryPermission ? imageField.fileLibraryPermission !== PermissionType.ALLOWED : false;
        const uploadDisabled = imageField.fileUploadPermission ? imageField.fileUploadPermission !== PermissionType.ALLOWED : false;

        return {
            libraryDisabled: libraryDisabled,
            uploadDisabled: uploadDisabled,
            standardFileDisabled: standardFileDisabled
        };
    }, [imageField.fileLibraryPermission, imageField.fileUploadPermission, imageField.standardFilePermission]);

    return {tabPermission};
};
