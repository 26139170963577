import {Command} from './Command';
import {CommunityHomeEditor} from './Editors/CommunityHomeEditor';
import {IdeaListMode} from 'models/enums/IdeaListMode';
import {CommunityOperationType} from 'models/edit-mode/CommunityOperationType';
import {CommunityEditableFieldType} from 'models/edit-mode/CommunityEditableFieldType';
import {ChangeIdeaSortModeOperation} from 'models/edit-mode/ChangeIdeaSortModeOperation';
import {Operation} from 'models/edit-mode/Operation';

export class UpdateIdeaListModeCommand implements Command {
    private readonly communityHomeEditor: CommunityHomeEditor;
    private readonly oldIdeaListMode: IdeaListMode;
    private readonly newIdeaListMode: IdeaListMode;
    private readonly name: CommunityOperationType;

    constructor(communityHomeEditor: CommunityHomeEditor, ideaListMode: IdeaListMode) {
        this.communityHomeEditor = communityHomeEditor;
        this.oldIdeaListMode = this.communityHomeEditor.ideaListModeConfig.value.toUpperCase() as IdeaListMode;
        this.newIdeaListMode = ideaListMode;
        this.name = CommunityOperationType.CHANGE_IDEA_SORT_MODE;
    }

    execute(): void {
        this.communityHomeEditor.ideaListModeConfig = {
            name: CommunityEditableFieldType.IDEA_LIST_MODE,
            value: this.newIdeaListMode.toUpperCase(),
            editPermission: this.communityHomeEditor.ideaListModeConfig.editPermission
        };
    }

    undo(): void {
        this.communityHomeEditor.ideaListModeConfig = {
            name: CommunityEditableFieldType.IDEA_LIST_MODE,
            value: this.oldIdeaListMode.toUpperCase(),
            editPermission: this.communityHomeEditor.ideaListModeConfig.editPermission
        };
    }

    getType(): CommunityOperationType {
        return this.name;
    }

    getOperation(): Operation {
        return new ChangeIdeaSortModeOperation(this.name, this.communityHomeEditor.ideaListModeConfig.value.toUpperCase());
    }
}