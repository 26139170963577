import {Operation} from './Operation';
import {CommunityOperationType} from './CommunityOperationType';

export class ChangeCommunityWidgetOperation implements Operation {
    constructor(
        public type: CommunityOperationType,
        public title: string,
        public content: string
    ) {
    }

    getType(): CommunityOperationType {
        return this.type;
    }
}