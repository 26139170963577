import {useCallback} from 'react';
import {useLandingPageService} from 'hooks/useService';
import {TranslationContentRequest} from 'models/landing-page/TranslationContentRequest';

export const useTranslation = () => {
    const landingPageService = useLandingPageService();

    const getTranslatedContent = useCallback(async (parameters: TranslationContentRequest) => {
        if (landingPageService !== null) {
            return await landingPageService.fetchTranslatedAdminContent(parameters);
        }
        return [];
    }, [landingPageService]);

    const saveTranslatedContent = useCallback(async (parameters: TranslationContentRequest) => {
        if (landingPageService !== null) {
            return await landingPageService.saveTranslatedAdminContent(parameters);
        }
        return [];
    }, [landingPageService]);

    return {getTranslatedContent, saveTranslatedContent};
};