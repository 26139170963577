import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Controller, useWatch} from 'react-hook-form';
import {IdeascaleSelect, OptionType} from '@ideascale/ui';
import {
    IDEA_DESCRIPTION_VALUE_ON_BLUR,
    IdeaFormFieldKeys,
    Localizer,
    useHookFormContext,
    useRenderValidationError
} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {Language} from 'models/types/Language';
import {IDEA_TITLE_VALUE_ON_BLUR} from './TitleField';

type LanguageFieldProps = {
    localizer: Localizer;
    detectLanguage: (text: string) => Promise<Language>;
}

export const DEFAULT_LANGUAGE_OPTION = {
    value: '',
    label: `[ Detect Automatically ]`
};

export const LanguageField = (props: LanguageFieldProps) => {
    const {localizer, detectLanguage} = props;
    const {communityConfig: {supportedLanguages}} = useAppContext();
    const {renderValidationError, errors} = useRenderValidationError(IdeaFormFieldKeys.LANGUAGE, localizer);
    const {control, setValue, clearErrors} = useHookFormContext();
    const [autoDetectedLanguage, setAutoDetectedLanguage] = useState<Language | null>(null);
    const autoDetectionDisabled = useRef(false);
    const ideaDescription = useWatch({name: IDEA_DESCRIPTION_VALUE_ON_BLUR, control});
    const ideaTitle = useWatch({name: IDEA_TITLE_VALUE_ON_BLUR, control});

    const getOptionLabel = useCallback((option: OptionType) => {
        if (autoDetectedLanguage && option.value === autoDetectedLanguage.id) {
            return `${option.label} [ ${localizer.msg('idea.fields.language.detected')} ]`;
        }
        return option.label;
    }, [autoDetectedLanguage, localizer]);

    const options = useMemo(() => {
        const languageOptions: OptionType[] = supportedLanguages.map(language => {
            return {value: language.id, label: language.name};
        });
        return [DEFAULT_LANGUAGE_OPTION].concat(languageOptions);
    }, [supportedLanguages]);

    useEffect(() => {
        const text = (ideaTitle || '') + ' ' + (ideaDescription || '');
        if (!autoDetectionDisabled.current && text.trim().length > 3) {
            detectLanguage(text)
                .then(language => {
                    if (language && 'id' in language && 'name' in language && language.name.length > 0) {
                        setAutoDetectedLanguage(language);
                        setValue(IdeaFormFieldKeys.LANGUAGE, {
                            label: language.name,
                            value: language.id
                        });
                    }
                });
        }
    }, [detectLanguage, ideaDescription, ideaTitle, setValue]);

    return (
        <div className={`form-group ${errors[IdeaFormFieldKeys.LANGUAGE] ? 'has-error' : ''}`}>
            <label className="fw-bold" htmlFor={IdeaFormFieldKeys.LANGUAGE}>
                {localizer.msg('idea.fields.submission-language.label')}
                <span className="font-size-lg" aria-hidden={true}>*</span>
                <span className="sr-only">{localizer.msg('common.form.required')}</span>
            </label>
            <Controller
                control={control}
                name={IdeaFormFieldKeys.LANGUAGE}
                render={({field}) =>
                    <IdeascaleSelect
                        name={field.name}
                        isMulti={false}
                        inputId={IdeaFormFieldKeys.LANGUAGE}
                        defaultValue={DEFAULT_LANGUAGE_OPTION}
                        options={options}
                        ref={field.ref}
                        onChange={(selectedOption: any) => {
                            // This runs only during manual selection. So here we disable auto-detection
                            autoDetectionDisabled.current = selectedOption.value !== DEFAULT_LANGUAGE_OPTION.value;
                            setAutoDetectedLanguage(null);
                            field.onChange(selectedOption);
                            clearErrors(IdeaFormFieldKeys.LANGUAGE);
                        }}
                        value={field.value}
                        getOptionLabel={getOptionLabel}
                    />
                }/>
            {renderValidationError()}
        </div>
    );
};