import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useQuery} from 'react-query';
import {Controller, useForm} from 'react-hook-form';
import {Form} from 'reactstrap';
import {
    ActionButton,
    Avatar,
    Button,
    Checkbox,
    HtmlConverter,
    Icon,
    IdeascaleSelect,
    NewRichTextEditor,
    OptionType,
    RichTextClassificationConfig,
    RichTextEditorHandler
} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {
    Actor,
    AttachmentType,
    ClassificationAttachmentAttribute,
    ClassificationAttachmentAttributesData,
    ClassificationAttribute,
    ClassificationsHolder,
    ClassificationTreeMenu,
    ClassificationUtil,
    ClassifiedAttachment,
    EMBEDDED_FILE_NAME_PREFIX,
    HookFormProvider,
    LabelData,
    LabelsAction,
    Localizer,
    Member,
    RenderFormat,
    useApiErrorResponseHandler,
    useAtMentionConfiguration,
    useAttachmentClassificationErrorHandler,
    useFileUploadIntermediary,
    useHandleFormSubmit
} from '@ideascale/commons';
import {DEFAULT_STALE_TIME, QUERY_KEYS} from 'constants/AppConstants';
import {useAppContext} from 'contexts/AppContext';
import {useCommentService} from 'hooks/useCommentService';
import {useFileUploadService} from 'hooks/useFileUploadService';
import {useIdeaAttachment} from 'hooks/useIdeaAttachment';
import {CreateFileAttachment} from 'components/idea-details-tabs/CreateFileAttachment';
import {ContributionType} from 'models/comments/ContributionType';
import {CommentSummary} from 'models/comments/CommentSummary';
import styles from './CommentForm.module.scss';
import {CommentFormFields} from 'models/types/CommentFormFields';

const COMMENT_REPLY_FORM_ID = 'comment-reply-form';

type ReplyFormProps = {
    id: string;
    parentId: number;
    localizer: Localizer;
    ideaId: number;
    replyId: number;
    actor: Actor;
    onSubmitReply: (comment: CommentSummary) => void;
    onReplyCancel: () => void;
    commentAuthor: Member;
    privateComment: boolean;
    classificationsMenuData?: ClassificationsHolder | {};
    richTextClassificationConfig?: RichTextClassificationConfig;
}

export const ReplyForm = (props: ReplyFormProps) => {
    const {
        id,
        localizer,
        actor,
        ideaId,
        replyId,
        onSubmitReply,
        onReplyCancel,
        parentId,
        commentAuthor,
        privateComment,
        classificationsMenuData,
        richTextClassificationConfig
    } = props;
    const {
        communityConfig: {
            multipleLanguageSupported,
            preferredLanguage,
            supportedLanguages,
            atMentionEnabled,
            maxFileSizeLimit,
            offensiveEmojis,
            classificationEnabled
        },
        currentCampaign,
    } = useAppContext();
    const {
        fetchAtMentionMembers,
        fetchCommentPermissions,
        fetchCommentLabels,
        commentCreation
    } = useCommentService();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const {tempImageUpload, uploadFile} = useFileUploadService();
    const {onUploadFile, fileUploading} = useFileUploadIntermediary(uploadFile);
    const {updateIdeaDetailsCommentAttachments} = useIdeaAttachment();
    const [selectedLabelIds, setSelectedLabelIds] = useState<number[]>([]);
    const richTextEditorNode = useRef<RichTextEditorHandler>(null);
    const [selectedAttachmentClassification, setSelectedAttachmentClassification] = useState<ClassificationAttribute>();
    const [appliedAttachmentClassifications, setAppliedAttachmentClassifications] = useState<ClassifiedAttachment>({});
    const [appliedAttachmentClassificationsData, setAppliedAttachmentClassificationsData] = useState<ClassificationAttachmentAttributesData>({});

    const updateAppliedAttachmentClassifications = useCallback((key: string, value: ClassifiedAttachment) => {
        setAppliedAttachmentClassificationsData(prev => {
            return {...prev, [key]: value};
        });
        setAppliedAttachmentClassifications(value);
    }, []);

    const {data: commentPermissions} = useQuery([QUERY_KEYS.COMMENT_PERMISSION, parentId], () => fetchCommentPermissions(parentId), {
        staleTime: DEFAULT_STALE_TIME
    });

    const fetchAtMentionData = useAtMentionConfiguration(
        atMentionEnabled,
        fetchAtMentionMembers,
        {
            campaignId: currentCampaign?.id ?? 0,
            ideaId,
        }
    );

    const methods = useForm<CommentFormFields>({
        defaultValues: {
            parentId: 0,
            parentType: ContributionType.comment,
            text: '',
            pinned: false,
            privateComment: privateComment,
            addTracking: commentPermissions?.trackingIdea ?? false,
            annotated: false,
            hideCommentAuthor: false,
            attachments: [],
            labelIds: [],
            languageId: preferredLanguage?.id ?? -1
        },
        mode: 'onSubmit',
        reValidateMode: 'onSubmit'
    });
    const {
        register,
        handleSubmit,
        control,
        reset,
        setError,
        formState: {errors, isSubmitting},
        watch,
        setValue,
        clearErrors,
    } = methods;
    const onSubmit = useHandleFormSubmit(handleSubmit);
    const {onClassificationError} = useAttachmentClassificationErrorHandler({
        fieldKey: 'attachments',
        setError,
        localizer
    });

    const privateCommentIsChecked = watch('privateComment', privateComment);
    useEffect(() => {
        if (privateCommentIsChecked) {
            setValue('annotated', false);
        }
    }, [privateCommentIsChecked, setValue]);

    const onFormSubmit = async (formInputs: CommentFormFields) => {
        try {
            const response = await commentCreation({
                ...formInputs,
                text: HtmlConverter.toServerHtmlFormat(formInputs.text),
                privateComment: privateCommentIsChecked ?? privateComment,
                parentId: parentId,
                labelIds: selectedLabelIds,
                attachments: formInputs.attachments.map(attachment => ({
                    filename: attachment.filename,
                    altText: attachment.altText.trim() || attachment.filename,
                    note: attachment.note
                })),
                renderFormat: RenderFormat.HTML,
                attributeApiParameters: classificationEnabled ?
                    [
                        ClassificationUtil.buildAttachmentClassificationRequest(
                            'filename',
                            formInputs.attachments.map(attachment => attachment.filename),
                            appliedAttachmentClassificationsData['attachments'])
                    ] : undefined
            });
            if (response) {
                onSubmitReply(response);
                setSelectedLabelIds([]);
                reset({
                    parentId: 0,
                    parentType: ContributionType.idea,
                    text: '',
                    pinned: false,
                    privateComment: privateCommentIsChecked,
                    addTracking: commentPermissions?.trackingIdea ?? false,
                    annotated: false,
                    hideCommentAuthor: false,
                    attachments: [],
                    labelIds: [],
                    languageId: preferredLanguage?.id ?? 0
                }, {
                    keepDefaultValues: true,
                    keepIsSubmitted: false
                });
                richTextEditorNode.current?.setTextWithBlur('');
                setSelectedAttachmentClassification(undefined);

                if (response.annotated && response?.attachments?.length > 0) {
                    await updateIdeaDetailsCommentAttachments(ideaId, response.attachments, (response.attributes?.filter(item => item.name === 'filename') as ClassificationAttachmentAttribute[]));
                }
            }
        } catch (error: any) {
            handleErrorResponse(error, {setFormError: setError});
        }
    };

    useEffect(() => {
        if (errors.text) {
            richTextEditorNode.current?.focus();
        }
    }, [errors]);

    const options = useMemo(() => {
        const languageOptions: OptionType[] = supportedLanguages.map(language => {
            return {value: language.id, label: language.name};
        });
        return languageOptions;
    }, [supportedLanguages]);

    const addLabel = useCallback(async (labelData: LabelData) => {
        setSelectedLabelIds(prevState => [...prevState, labelData.id]);
    }, []);

    const removeLabel = useCallback(async (labelData: LabelData) => {
        setSelectedLabelIds(prevState => prevState.filter(id => id !== labelData.id));
    }, []);

    useEffect(() => {
        richTextEditorNode.current?.setText((atMentionEnabled && actor.id !== commentAuthor.id && commentAuthor.username) ? `@${commentAuthor.username} ` : '');
        richTextEditorNode.current?.setCursorAtEnd();
    }, [actor.id, atMentionEnabled, commentAuthor.id, commentAuthor.username]);

    return (
        <div id={id} className={`${styles.commentForm} d-flex`}>
            <Avatar className="me-4" src={actor.avatar}
                    alt={actor.username ? localizer.msg('common.user-avatar', {username: actor.username}) : ''}
                    size="md"/>
            <div className="flex-grow-1 min-width">
                <HookFormProvider {...methods}>
                    <Form id={`${COMMENT_REPLY_FORM_ID}-${id}`}
                          onSubmit={onSubmit(onFormSubmit, isSubmitting || fileUploading)}
                          aria-label={localizer.msg('profile.activity-stream.comment.reply-comment')}>
                        {
                            commentPermissions?.commentApprovalRequired && (
                                <span className="d-inline-block mb-2 text-warning">
                                    {localizer.msg('comments.comment-form.audit-warning')}
                                </span>
                            )
                        }
                        <div className={`form-group ${errors.text ? 'has-error' : ''}`}>
                            <Controller
                                name="text"
                                control={control}
                                rules={{
                                    required: localizer.msg('comments.comment-form.idea-text-required'),
                                    ...(classificationEnabled && {validate: () => !richTextEditorNode.current?.hasInvalidClassifications() || localizer.msg('frontend-shared.classification.invalid-classifications-msg')})
                                }}
                                render={({field: {onChange}}) =>
                                    <Fragment>
                                        {
                                            classificationEnabled
                                                ? (
                                                    <ClassificationTreeMenu
                                                        localizer={localizer}
                                                        id={`reply-comment-classification-menu-${id}`}
                                                        classifications={classificationsMenuData || {}}
                                                        onApplyClassifications={value => {
                                                            richTextEditorNode.current?.insertClassification(ClassificationUtil.getLabel(value));
                                                        }}/>
                                                )
                                                : null
                                        }
                                        <NewRichTextEditor
                                            toolbar={'standard'}
                                            className={'form-control'}
                                            placeholder={atMentionEnabled ? localizer.msg('idea.comment.form.placeholder-with-at-mention') : localizer.msg('idea.comment.form.placeholder')}
                                            svgIconPath={svgIconsPath}
                                            onChange={value => {
                                                clearErrors('text');
                                                onChange(value);
                                            }}
                                            ref={richTextEditorNode}
                                            enableEmojiPicker={true}
                                            offensiveEmojis={offensiveEmojis}
                                            enableAtMention={atMentionEnabled}
                                            fetchMentionUsers={fetchAtMentionData}
                                            uploadImage={tempImageUpload}
                                            maxFileSize={maxFileSizeLimit}
                                            classificationConfig={richTextClassificationConfig}
                                            defaultFileNamePrefix={EMBEDDED_FILE_NAME_PREFIX.COMMENT}
                                        />
                                    </Fragment>
                                }
                            />
                            {
                                errors.text && (
                                    <p className="invalid-feedback d-block" data-role="field-error" aria-live="polite">
                                        {errors.text.message}
                                    </p>
                                )
                            }
                        </div>

                        {
                            multipleLanguageSupported && (
                                <div className="form-group">
                                    <label className="fw-bold" htmlFor="language-field">
                                        {localizer.msg('comments.comment-form.comment-reply-language')}<span
                                        className="font-size-lg" aria-hidden={true}>*</span>
                                        <span className="sr-only">{localizer.msg('common.form.required')}</span>
                                    </label>
                                    <Controller
                                        control={control} name="languageId"
                                        render={({field}) =>
                                            <IdeascaleSelect
                                                name={field.name}
                                                isMulti={false}
                                                inputId={`language-field-reply-${parentId}-${replyId}`}
                                                defaultValue={{
                                                    value: preferredLanguage.id,
                                                    label: preferredLanguage.name
                                                } as OptionType}
                                                value={options.find(item => item.value === field.value)}
                                                options={options}
                                                onChange={(selectedOption: any) => {
                                                    field.onChange(selectedOption.value);
                                                }}
                                            />
                                        }/>
                                </div>
                            )
                        }
                        {
                            commentPermissions?.attachmentAllowed && (
                                <div
                                    className={`form-group ${errors['attachments'] && !Array.isArray(errors['attachments']) ? 'has-error' : ''}`}>
                                    <label htmlFor="attachments" className="fw-bold me-1">
                                        <span>
                                           {localizer.msg('comments.comment-form.attachment-label')}
                                        </span>
                                    </label>
                                    <Fragment>
                                        {
                                            classificationEnabled && (
                                                <ClassificationTreeMenu
                                                    localizer={localizer}
                                                    id={`reply-comment-attachment-classification-menu-${id}`}
                                                    classifications={classificationsMenuData || {}}
                                                    onApplyClassifications={value => {
                                                        clearErrors('attachments');
                                                        setSelectedAttachmentClassification(value);
                                                    }}/>
                                            )
                                        }
                                        <CreateFileAttachment
                                            attachmentType={AttachmentType.COMMENT}
                                            localizer={localizer}
                                            inputId="attachments"
                                            maxFileSizeInMb={maxFileSizeLimit}
                                            uploadFile={onUploadFile}
                                            multiple={true}
                                            classificationsMenuData={classificationsMenuData}
                                            classificationEnabled={classificationEnabled}
                                            selectedClassification={selectedAttachmentClassification}
                                            updateAppliedAttachmentClassifications={updateAppliedAttachmentClassifications}
                                            appliedClassifications={appliedAttachmentClassifications}
                                            onClassificationError={onClassificationError}
                                        />
                                        {
                                            errors?.attachments && (errors?.attachments as any)?.type === 'classificationRequired' && (
                                                <p className="invalid-feedback d-block" data-role="field-error"
                                                   aria-live="polite">
                                                    {(errors.attachments as any)?.message}
                                                </p>
                                            )
                                        }
                                    </Fragment>
                                </div>
                            )
                        }

                        {
                            commentPermissions?.privateSubmissionAllowed && (
                                <div className="form-group">
                                    <Checkbox className="fw-bold"
                                              inputId={`private-comment-field-reply-${parentId}-${replyId}`}
                                              label={localizer.msg('comments.comment-form.idea-comment-private')}
                                              defaultChecked={privateCommentIsChecked}
                                              disabled={privateComment}
                                              {...register('privateComment')} />
                                </div>
                            )
                        }

                        {
                            commentPermissions?.emailNotificationsAllowed && (
                                <div className="form-group">
                                    <Checkbox className="fw-bold"
                                              inputId={`notify-via-mail-field-reply-${parentId}-${replyId}`}
                                              label={localizer.msg('comments.comment-form.idea-comment-post-notify')}
                                              defaultChecked={commentPermissions.trackingIdea}
                                              {...register('addTracking')}/>
                                </div>
                            )
                        }

                        {
                            commentPermissions?.anonymousSubmissionAllowed && (
                                <div className="form-group">
                                    <Checkbox className="fw-bold"
                                              inputId={`hide-comment-author-field-reply-${parentId}-${replyId}`}
                                              defaultChecked={commentPermissions.defaultAnonymousSubmissionEnabled}
                                              label={localizer.msg('comments.comment-form.submit-comment-anonymously')}
                                              {...register('hideCommentAuthor')}/>
                                </div>
                            )
                        }

                        {
                            commentPermissions?.annotationAllowed && !privateCommentIsChecked && (
                                <div className="form-group">
                                    <Checkbox className="fw-bold"
                                              inputId={`annotate-comment-field-reply-${parentId}-${replyId}`}
                                              label={
                                                  <Fragment>
                                                      <Icon className="position-relative pos-top-n1"
                                                            iconSpritePath={svgIconsPath} width={12}
                                                            height={12} name="arrow-up-to-line"/>
                                                      <span
                                                          className="ms-2">{localizer.msg('comments.comment-form.idea-comment-annotate')}</span>
                                                  </Fragment>
                                              }
                                              {...register('annotated')}/>

                                </div>
                            )
                        }
                        <div className="form-group mb-0 d-flex">
                            {
                                commentPermissions?.labelsApplicable && (
                                    <LabelsAction
                                        localizer={localizer}
                                        fetchLabels={() => fetchCommentLabels(ideaId)}
                                        targetId={replyId}
                                        addLabel={addLabel}
                                        svgIconPath={svgIconsPath}
                                        removeLabel={removeLabel} toggleButtonTag="button"
                                        selectedLabelIds={selectedLabelIds}
                                        toggleButtonClass={`btn-label bg-transparent me-auto px-0 ${selectedLabelIds.length > 0 ? 'selected' : ''}`}/>
                                )
                            }
                            <Button className="ms-auto"
                                    color="cancel"
                                    disabled={isSubmitting || fileUploading}
                                    onClick={onReplyCancel}
                                    data-test-element-id="cancel-reply">
                                {localizer.msg('common.cancel')}
                            </Button>
                            <ActionButton className="ms-2" loading={isSubmitting || fileUploading}
                                          data-test-element-id="submit-reply">
                                {localizer.msg('common.submit')}
                            </ActionButton>
                        </div>
                    </Form>
                </HookFormProvider>
            </div>
        </div>
    );
};
