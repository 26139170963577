import React, {Fragment, useCallback} from 'react';
import {eventDispatcher, FEATURE_TOUR_EVENTS} from '@ideascale/commons';
import {useIdeaSubmissionService} from 'hooks/useIdeaSubmissionService';
import {useAppContext} from 'contexts/AppContext';
import {IdeaFormModal} from 'components/idea-submission/IdeaFormModal';
import {CommonUtil} from 'utils/CommonUtil';
import {ModifiedIdeaDetail} from 'models/ModifiedIdeaDetail';
import {IdeaSubmissionRequest} from 'models/IdeaSubmissionRequest';

type EditIdeaFormContainerProps = {
    open: boolean;
    toggle: () => void;
    ideaId: number;
    editIdea: (ideaId: number, submissionRequest: IdeaSubmissionRequest) => Promise<ModifiedIdeaDetail>;
}

export const EditIdeaFormContainer = (props: EditIdeaFormContainerProps) => {
    const {open, toggle, ideaId, editIdea} = props;
    const {communityConfig: {aiTextAssistEnabled}} = useAppContext();
    const methods = useIdeaSubmissionService();

    const toggleEditModal = useCallback(() => {
        if (aiTextAssistEnabled && open) {
            eventDispatcher.dispatch(FEATURE_TOUR_EVENTS.PAUSE_TOUR);
            CommonUtil.wait(300).then(() => eventDispatcher.dispatch(FEATURE_TOUR_EVENTS.RUN_TOUR));
        }
        toggle();
    }, [aiTextAssistEnabled, open, toggle]);

    return (
        <Fragment>
            {
                open &&
                <IdeaFormModal
                    {...methods}
                    open={open}
                    toggle={toggleEditModal}
                    initialDraftIdeaId={null}
                    initialEditIdeaId={ideaId}
                    aiAssistanceEnabled={aiTextAssistEnabled}
                    editIdea={editIdea}
                    defaultMode="EDIT_IDEA"
                />
            }
        </Fragment>
    );
};