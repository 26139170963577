import React, {Fragment} from 'react';
import {Collapse} from 'reactstrap';
import {Icon} from '@ideascale/ui';
import {useToggle} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import './ActivityCard.scss';

type ActivityCardType = {
    headerTitle: string;
    isCurrentStage?: boolean,
    children: React.ReactNode;
    svgIconSprite: string;
    defaultOpen?: boolean;
    headerUrl?: string;
    headerIcon?: string | JSX.Element;
    headerClass?: string;
    containerClass?: string;
    collapseHeaderClass?: string;
    collapseIcon?: string | JSX.Element;
    expandIcon?: string | JSX.Element;
    headerTag?: React.ElementType | string;
    headerSummary?: JSX.Element;
    onOpened?: () => void;
    onClosed?: () => void;
    onEntering?: () => void;
    onEntered?: () => void;
    onExit?: () => void;
    onExiting?: () => void;
    onExited?: () => void;
}

export const ActivityCard = (props: ActivityCardType) => {
    const {
        headerTitle,
        isCurrentStage,
        children,
        svgIconSprite,
        headerIcon,
        defaultOpen = false,
        headerUrl,
        headerClass,
        containerClass,
        collapseHeaderClass = '',
        collapseIcon,
        expandIcon,
        headerTag = 'h3',
        headerSummary = <Fragment/>,
        onOpened,
        onClosed,
        onEntering,
        onEntered,
        onExit,
        onExiting,
        onExited
    } = props;
    const HeaderTag = headerTag;
    const localizer = useLocalizer();
    const [open, toggle] = useToggle(defaultOpen);
    const renderTitle = () => {
        return headerUrl?.trim() ? headerTitle : <a href={headerUrl}>{headerTitle}</a>;
    };

    return (
        <section className={`card panel activity-card border-0 ${containerClass && containerClass}`}>
            <div
                onClick={toggle}
                className={`card-header d-flex justify-content-between align-items-center py-2 ${open ? 'header-open' : 'header-close'} ${collapseHeaderClass}`}>
                <div className="mt-2 mb-2">
                    <HeaderTag className={`h6 fw-bold mb-0 ${headerClass}`}>
                        {
                            typeof (headerIcon) === 'string'
                                ? <Icon className="icon-link me-2 link-icon" name={headerIcon} width={14} height={14}
                                        iconSpritePath={svgIconSprite}/>
                                : headerIcon
                        }
                        {renderTitle()}
                        {isCurrentStage !== undefined &&
                        <>
                            {isCurrentStage ? <span
                                    className="text-capitalize label label-field-open ms-2">{localizer.msg('common.open')}</span> :
                                <span
                                    className="text-capitalize label label-field-closed ms-2">{localizer.msg('common.closed')}</span>
                            }
                        </>
                        }
                    </HeaderTag>
                        {headerSummary}
                </div>

                <button className="btn btn-link p-0" type="button" aria-expanded={open}
                        title={
                            open ?
                                localizer.msg('stage.common.collapse-stage-activity') :
                                localizer.msg('stage.common.expand-stage-activity')
                        }>
                    <>
                        {
                            open
                                ? collapseIcon ? collapseIcon :
                                <Icon name="chevron-up" width={15} height={15}
                                      iconSpritePath={svgIconSprite}/>
                                : expandIcon ? expandIcon :
                                <Icon name="chevron-down" width={15} height={15}
                                      iconSpritePath={svgIconSprite}/>
                        }
                    </>
                </button>

            </div>
            <Collapse className="card-body"
                      tag="article"
                      isOpen={open}
                      onOpened={onOpened}
                      onClosed={onClosed}
                      onEntering={onEntering}
                      onEntered={onEntered}
                      onExit={onExit}
                      onExiting={onExiting}
                      onExited={onExited}>
                {children}
            </Collapse>
        </section>
    );
};