import {SidebarItem} from '../sidebar/SidebarItem';
import {CommunityEditableFieldType} from './CommunityEditableFieldType';
import {ConfigField} from './ConfigField';
import {BaseConfigField} from './BaseConfigField';
import {WidgetField} from './WidgetField';
import {CampaignEditableFieldType} from './CampaignEditableFieldType';
import {ImageField} from './ImageField';
import {LogoField} from './LogoField';
import {BannerField} from './BannerField';
import {TosConfigField} from './TosConfigField';
import {BriefConfigField} from './BriefConfigField';
import {PermissionType} from './ResourcePermission';
import {TopbarLogoField} from './TopbarLogoField';
import {CampaignTeamVisibilityConfigField} from './CampaignTeamVisibilityConfigField';

export class HomeConfigHolder {
    public static EMPTY = new HomeConfigHolder([], []);

    constructor(
        public fields: BaseConfigField[],
        public sidebarItems: SidebarItem[],
    ) {
    }

    getConfigField = (fieldType: CommunityEditableFieldType | CampaignEditableFieldType) => {
        const field = this.fields.find(field => field.name === fieldType);
        return field ? (field as ConfigField) : ConfigField.EMPTY;
    };

    getWidgetField = (fieldType: CommunityEditableFieldType | CampaignEditableFieldType) => {
        const field = this.fields.find(field => field.name === fieldType);
        return field ? (field as WidgetField) : WidgetField.EMPTY;
    };

    getImageField = (fieldType: CommunityEditableFieldType | CampaignEditableFieldType) => {
        const field = this.fields.find(field => field.name === fieldType);
        return field ? (field as ImageField) : ImageField.EMPTY;
    };

    getLogoField = (fieldType: CommunityEditableFieldType | CampaignEditableFieldType) => {
        const field = this.fields.find(field => field.name === fieldType);
        return field ? (field as LogoField) : LogoField.EMPTY;
    };

    getTopbarLogoField = (fieldType: CommunityEditableFieldType | CampaignEditableFieldType) => {
        const field = this.fields.find(field => field.name === fieldType);
        return field ? (field as TopbarLogoField) : TopbarLogoField.EMPTY;
    };

    getBannerField = (fieldType: CommunityEditableFieldType | CampaignEditableFieldType) => {
        const field = this.fields.find(field => field.name === fieldType);
        return field ? (field as BannerField) : BannerField.EMPTY;
    };

    isOperationAllowed = (fieldType: CommunityEditableFieldType | CampaignEditableFieldType) => {
        return (fieldType === CommunityEditableFieldType.SIDEBAR)
            ? this.sidebarItems && this.sidebarItems.length > 0
            : this.fields.findIndex(field => field.name === fieldType) > -1;
    };

    hasEditPermission = (fieldType: CommunityEditableFieldType | CampaignEditableFieldType) => {
        const field = this.fields.find(field => field.name === fieldType);
        return !!field && ((field as ConfigField).editPermission === PermissionType.ALLOWED ?? false);
    };

    updateField = (newField: BaseConfigField) => {
        const index = this.fields.findIndex(field => field.name === newField.name);
        if (index >= 0) {
            this.fields[index] = newField;
        }
    };

    getTosConfigField = (fieldType: CampaignEditableFieldType) => {
        const field = this.fields.find(field => field.name === fieldType);
        return field ? (field as TosConfigField) : TosConfigField.EMPTY;
    };

    getBriefConfigField = (fieldType: CampaignEditableFieldType) => {
        const field = this.fields.find(field => field.name === fieldType);
        return field ? (field as BriefConfigField) : BriefConfigField.EMPTY;
    };

    getTeamVisibilityField = (fieldType: CampaignEditableFieldType) => {
        const field = this.fields.find(field => field.name === fieldType);
        return field ? (field as CampaignTeamVisibilityConfigField) : CampaignTeamVisibilityConfigField.EMPTY;
    };
}