import {LabelData} from '@ideascale/commons';
import {Component} from 'models/landing-page/Component';
import {UiAttribute} from 'models/landing-page/UiAttribute';
import {IdeaListMode} from 'models/enums/IdeaListMode';
import {LandingPageComponentType} from 'models/enums/landing-page/LandingPageComponentType';
import {IdeaListComponentViewMode} from 'models/enums/landing-page/IdeaListComponentViewMode';

type SelectedCampaign = {
    id: number,
    name: string
}

export class IdeasComponentData extends Component {
    public static EMPTY = new IdeasComponentData(0, 0, LandingPageComponentType.IDEAS, false, UiAttribute.EMPTY, IdeaListMode.TRENDING, 0, IdeaListComponentViewMode.GRID_VIEW, [], '', LabelData.EMPTY, '', '');

    constructor(
        public id: number,
        public languageId: number,
        public type: LandingPageComponentType,
        public visible: boolean,
        public attribute: UiAttribute,
        public ideaListMode: IdeaListMode,
        public count: number,
        public ideaListViewMode?: IdeaListComponentViewMode,
        public campaigns?: SelectedCampaign[],
        public tag?: string,
        public label?: LabelData,
        public title?: string,
        public subtitle?: string,
    ) {
        super(id, languageId, type, visible, attribute, title, subtitle);
    }
}