import React, {Dispatch, SetStateAction, useEffect, useMemo, useRef, useState} from 'react';
import {FormGroup, Popover, PopoverBody, PopoverHeader} from 'reactstrap';
import {useToggle} from '@ideascale/commons';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {Icon, IdeascaleSelect} from '@ideascale/ui';
import {useAppContext} from 'contexts/AppContext';
import {TeamRoleData} from 'models/TeamRoleData';
import {BuildTeamStageActivity} from 'models/BuildTeamStageActivity';
import {StageActionResponse} from 'models/StageActionResponse';
import {useLocalizer} from 'hooks/useLocalizer';
import {BuildTeamStageSummary} from 'models/BuildTeamStageSummary';
import {BaseIdeaStageSummary} from 'models/BaseIdeaStageSummary';

type EditRoleProps = {
    fetchTeamRole(): Promise<Array<TeamRoleData>>;
    assignTeamRole: (ideaId: number, stageId: number, positionId: number, teamRoleId: number) => Promise<StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>>;
    setActivityData: Dispatch<SetStateAction<BuildTeamStageActivity>>;
    updateIdeaStageSummary: <T extends BaseIdeaStageSummary>(stageSummary: T) => void;
    positionId: number;
    ideaId: number;
    stageId: number;
}

export const BuildTeamEditRole = (props: EditRoleProps) => {
    const {fetchTeamRole, assignTeamRole, positionId, ideaId, stageId, setActivityData, updateIdeaStageSummary} = props;
    const localizer = useLocalizer();
    const {communityConfig} = useAppContext();
    const [open, toggle] = useToggle(false);
    const [teamRole, setTeamRole] = useState<TeamRoleData[]>([]);
    const [teamRoleValue, setTeamRoleValue] = useState(0);
    const popoverContainerRef = useRef(null);

    const roleOptions = useMemo(() => {
        const options = [{value: 0, label: `${localizer.msg('stage.build-team.team-member')}`}];
        return [...options, ...(teamRole || []).map(role => ({
            value: role.id,
            label: role.name
        }))];
    }, [localizer, teamRole]);


    useEffect(() => {
        open &&
        fetchTeamRole().then(data => setTeamRole(data));
    }, [fetchTeamRole, open]);

    const assignRole = (ideaId: number, stageId: number, positionId: number, teamRoleId: number) => {
        if (teamRoleValue < 0) {
            toggle();
        } else {
            assignTeamRole(ideaId, stageId, positionId, teamRoleId).then((status) => {
                toggle(false);
                if (status.stageActivity) {
                    setActivityData(status.stageActivity);
                }
                if (status?.stageSummary) {
                    updateIdeaStageSummary(status.stageSummary);
                }
            });
        }
    };

    const getRole = (value: number) => {
        setTeamRoleValue(value);
    };

    const closePopOver = () => {
        toggle();
        setTeamRoleValue(-1);
    };

    return (
        <div className="d-inline-block" ref={popoverContainerRef}>
            <button id={`edit-role-member-${positionId}`}
                    className="p-0 font-size-sm btn btn-link"
                    data-test-element-id="btn-edit-role"
                    disabled={communityConfig.communityReadOnly}>
                {localizer.msg('stage.build-team.edit-role')}
            </button>
            <Popover className="me-n2 edit-role-member-popover"
                     trigger="click"
                     placement="top"
                     isOpen={open}
                     toggle={toggle}
                     container={popoverContainerRef}
                     target={`edit-role-member-${positionId}`}>
                <PopoverHeader className="fw-bold font-size-sm p-3">
                    {localizer.msg('stage.build-team.choose-position')}
                </PopoverHeader>
                <PopoverBody className="p-0">
                    <div className="px-3 pt-3">
                        <div className="d-flex align-items-center">
                            <div className="assign-member me-2">
                                <FormGroup>
                                    <IdeascaleSelect
                                        ariaLabel={localizer.msg('notification.idea.team.select-member-role')}
                                        options={roleOptions}
                                        onChange={(optionType: any) => getRole(optionType.value)}
                                    />
                                </FormGroup>
                            </div>
                            <div className="mb-3">
                                <button className="btn btn-sm btn-primary" title={localizer.msg('common.select')}
                                        onClick={() => assignRole(ideaId, stageId, positionId, teamRoleValue)}>
                                    <Icon iconSpritePath={svgIconPath} name="check"
                                          fill="#fff"/>
                                </button>
                                <button className="btn btn-sm btn-light ms-2" title={localizer.msg('common.cancel')}
                                        onClick={closePopOver}>
                                    <Icon iconSpritePath={svgIconPath} name="cross"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </PopoverBody>
            </Popover>
        </div>
    );
};