import {
    ClassificationAttachmentAttribute,
    ClassificationFieldAttribute,
    FileAttachmentDetail,
    LabelData,
    Member
} from '@ideascale/commons';
import {CommentActionsPermission} from './CommentActionsPermission';
import {StageCommentPermissions} from './StageCommentPermissions';

export class StageCommentSummary {
    static EMPTY = new StageCommentSummary(0, '', Member.EMPTY, false, [], [], [], Date.now().toString(), CommentActionsPermission.EMPTY);

    constructor(
        public id: number,
        public text: string,
        public author: Member,
        public annotated: boolean,
        public labels: LabelData[],
        public attachments: FileAttachmentDetail[],
        public replies: StageCommentSummary[],
        public createdAt: string,
        public actions: StageCommentPermissions,
        public attributes?: Array<ClassificationFieldAttribute | ClassificationAttachmentAttribute>
    ) {
    }
}