import React from 'react';
import {Link} from 'react-router-dom';
import {eventDispatcher, Localizer} from '@ideascale/commons';
import {Avatar} from '@ideascale/ui';
import {appLinks} from 'services/AppLinks';
import {LeaderboardType} from 'models/enums/LeaderboardType';
import {SidebarLeaderboardItem as LeaderboardItem} from 'models/SidebarLeaderboardItem';
import {SCROLL_EVENTS} from 'constants/AppConstants';

type CommunityLeaderProps = {
    localizer: Localizer;
    editMode?: boolean;
    leaderboardItem: LeaderboardItem;
}

export const CommunityLeader = (props: CommunityLeaderProps) => {
    const {localizer, editMode = false, leaderboardItem} = props;

    const renderLeaderSummary = () => {
        switch (leaderboardItem.leaderboardType) {
            case LeaderboardType.KUDOS_RECEIVED:
                return localizer.msg('sidebar.leaderboard.kudos-received');
            case LeaderboardType.KUDOS_GIVEN:
                return localizer.msg('sidebar.leaderboard.kudos-given');
            case LeaderboardType.IDEA:
                return localizer.msg('sidebar.leaderboard.ideas');
            case LeaderboardType.COMMENT:
                return localizer.msg('sidebar.leaderboard.comments');
            case LeaderboardType.VOTE:
                return localizer.msg('sidebar.leaderboard.votes');
            default:
                return '';
        }
    };

    return (
        <Link onClick={() => eventDispatcher.dispatch(SCROLL_EVENTS.SCROLL_TOP)}
              {...(editMode ? {tabIndex: -1} : {})}
              to={appLinks.leaderboard(leaderboardItem.leaderboardType)}>
            <div className="community-leader d-flex flex-column align-items-center p-2 py-4">
                <div className="leader-image">
                    <Avatar size="lg" src={leaderboardItem.leader.avatar}
                            alt={leaderboardItem.leader.username ? localizer.msg('common.user-avatar', {username: leaderboardItem.leader.username}) : ''}/>
                </div>
                <div className="info-text text-center">
                    <h3 title={leaderboardItem.leader.name} className="leader-name pt-3 px-3 text-truncate">
                        {leaderboardItem.leader.name}
                    </h3>
                    <div className="kudos-received">
                        {leaderboardItem.count + ' ' + renderLeaderSummary()}
                    </div>
                </div>
            </div>
        </Link>
    );
};