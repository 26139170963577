import React, {useEffect} from 'react';
import {IdeaFormFieldKeys, useHookFormContext} from '@ideascale/commons';
import {Language} from 'models/types/Language';

type LanguageHiddenFieldProps = {
    language: Language
}

export const LanguageHiddenField = (props: LanguageHiddenFieldProps) => {
    const {language} = props;
    const {register, setValue} = useHookFormContext();
    useEffect(() => {
        setValue(IdeaFormFieldKeys.LANGUAGE, language.id);
    }, [language, setValue]);
    return (
        <input type="hidden" value={language.id} {...register(IdeaFormFieldKeys.LANGUAGE)}/>
    );
};