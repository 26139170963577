import React from 'react';
import {Link} from 'react-router-dom';
import {
    CLASSIFICATION_NAME,
    ClassificationLabel,
    ClassificationUtil,
    Localizer,
    MemberLink
} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {NotificationData} from 'models/NotificationData';
import {NotificationType} from 'models/enums/NotificationType';

type CampaignDigestNotificationProps = {
    localizer: Localizer;
    notification: NotificationData;
    getDataForDigestModal: (year: string, notificationType: NotificationType, month?: string, week?: string, campaignId?: number, memberId?: number) => void;
}

export const CampaignDigestNotification = (props: CampaignDigestNotificationProps) => {
    const {localizer, notification, getDataForDigestModal} = props;
    const {communityConfig} = useAppContext();
    return (
        <>
            {
                communityConfig.classificationEnabled && notification.classificationAttributes &&
                <ClassificationLabel
                    classification={ClassificationUtil.getAttributeByName(CLASSIFICATION_NAME.CAMPAIGN_CLASSIFICATION, notification.classificationAttributes)}
                    extraClasses="me-1 align-bottom text-nowrap"/>
            }
            <MemberLink id={notification.sourceMemberId} identityHidden={notification.identityHidden}>
                {notification.viewAttributes.sourceMemberName}
            </MemberLink>
            {' '} {localizer.msg('notification.common.has-shared-the', {campaignName: notification.viewAttributes.campaignName})} {' '}
            {
                notification.type.name === NotificationType.SHARED_MONTHLY_CAMPAIGN_PERFORMANCE
                    ? <Link to="#" onClick={() => getDataForDigestModal(notification.viewAttributes.year,
                        NotificationType.SHARED_MONTHLY_CAMPAIGN_PERFORMANCE,
                        notification.viewAttributes.month, '', notification.campaignId, notification.sourceMemberId)}>
                        {localizer.msg('notification.moderator-digest.moderators-digest')}
                    </Link>
                    : <Link to="#" onClick={() => getDataForDigestModal(notification.viewAttributes.year,
                        NotificationType.SHARED_WEEKLY_CAMPAIGN_PERFORMANCE, '', notification.viewAttributes.weekOfYear, notification.campaignId, notification.sourceMemberId)}>
                        {localizer.msg('notification.moderator-digest.moderators-digest')}
                    </Link>
            } {' '} {localizer.msg('notification.common.with-you')}
        </>
    );
};