import React, {Fragment, useEffect, useState} from 'react';
import iconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {Avatar, Icon, InfoTip, ParagraphSkeleton, Button} from '@ideascale/ui';
import {
    ClassificationLabel,
    Localizer,
    MemberLink,
    StringUtil,
    useApiErrorResponseHandler,
    useApplicableClassifications,
    useIsMounted,
    useRoutesMatch,
    useToggle
} from '@ideascale/commons';
import {Link} from 'react-router-dom';
import {useAppContext} from 'contexts/AppContext';
import {appLinks} from 'services/AppLinks';
import {CampaignActivity} from 'models/CampaignActivity';
import {ROUTES} from 'shared/Routes';

const MAX_SUBTITLE_SIZE = 50;

type CampaignStatsProps = {
    fetchCampaignActivity: () => Promise<CampaignActivity>
    localizer: Localizer
}

export const CampaignStats = (props: CampaignStatsProps) => {
    const {fetchCampaignActivity, localizer} = props;
    const [activityData, setActivityData] = useState(CampaignActivity.EMPTY);
    const [loading, setLoading] = useState(true);
    const [campaignSubtitleExpanded, toggleCampaignSubtitleExpanded] = useToggle(false);
    const {communityConfig} = useAppContext();
    const {getClassificationAttribute} = useApplicableClassifications('', []);
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const componentIsMounted = useIsMounted();
    const aboutCampaignRouteMatch = useRoutesMatch(ROUTES.CAMPAIGN.ABOUT_PATH);

    useEffect(() => {
        fetchCampaignActivity().then(data => {
            if (componentIsMounted()) {
                setActivityData(data);
                setLoading(false);
            }
        }).catch(e => {
            handleErrorResponse(e);
        });
    }, [componentIsMounted, fetchCampaignActivity, handleErrorResponse]);

    return (
        loading ? (
            <div className="p-3">
                <ParagraphSkeleton rows={8}/>
            </div>
        ) : (
            <Fragment>
                <div className="campaign-details">
                    {communityConfig.classificationEnabled && activityData.classificationSummary &&
                        <div className="pt-3 pb-1">
                            <ClassificationLabel
                                classification={getClassificationAttribute(activityData.classificationSummary)}
                            />
                        </div>
                    }
                    <div className="py-2 d-flex align-items-center">
                        <img src={activityData.logoUrl}
                             width="30" height="30" alt={activityData.altText}
                             className="campaign-logo me-2"/>
                        <span className="text-truncate fw-bold" title={activityData.name}>
                            {activityData.name}
                        </span>
                    </div>
                    <div className="campaign-subtitle pt-1">
                        {
                            activityData.subTitle ? (
                                <p className="mb-0 ps-2" data-test-element-id="sidebar-campaign-subtitle">
                                    {
                                        campaignSubtitleExpanded
                                            ? activityData.subTitle
                                            : StringUtil.textTruncate(activityData.subTitle, MAX_SUBTITLE_SIZE)
                                    }
                                    {
                                        activityData.subTitle.length > MAX_SUBTITLE_SIZE &&
                                        <Button color="link" className="p-0 fw-normal mt-n1 ms-1"
                                              onClick={toggleCampaignSubtitleExpanded}>
                                            &nbsp;
                                            {
                                                campaignSubtitleExpanded
                                                    ? localizer.msg('sidebar.campaign-activity.see-less')
                                                    : localizer.msg('sidebar.campaign-activity.see-more')
                                            }
                                        </Button>
                                    }
                                </p>
                            ) : (
                                !aboutCampaignRouteMatch &&
                                <Link to={appLinks.aboutCampaign(activityData.id.toString())}>
                                    {localizer.msg('sidebar.campaign-activity.see-details')}
                                </Link>
                            )
                        }
                    </div>
                    <div className="campaign-stats">
                        <ul className="list-unstyled mb-0">
                            <li className="mb-2">
                                <Icon className="me-1 ms-n1" name="lightbulb" iconSpritePath={iconPath}
                                      fill={'#767676'}
                                      width={26} height={26}/>
                                <span>
                                {activityData.campaignStats.ideaCount} {localizer.msg('sidebar.campaign-activity.idea-posted')}
                            </span>
                                <InfoTip id="infotip-top"
                                         placement="top"
                                         content={localizer.msg('sidebar.campaign-activity.infotip-idea-posted')}/>
                            </li>
                            <li className="mb-2">
                                <Icon className="me-2" name="eye-open" iconSpritePath={iconPath} fill={'#767676'}
                                      width={22}
                                      height={22}/>
                                <span className="me-2">
                                {activityData.campaignStats.ideaViewCount} {localizer.msg('sidebar.campaign-activity.idea-views')}
                            </span>
                                <InfoTip id="infotip-top3"
                                         placement="top"
                                         content={
                                             <ul className="ps-3">
                                                 <li>
                                                     {localizer.msg('sidebar.campaign-activity.infotip-idea-views')}
                                                 </li>
                                                 <li>
                                                     {localizer.msg('sidebar.campaign-activity.infotip-view-details')}
                                                 </li>
                                             </ul>
                                         }/>
                            </li>
                            <li className="mb-2">
                                <Icon className="me-2" name="message-solid-left" iconSpritePath={iconPath}
                                      fill={'#767676'}
                                      width={18}
                                      height={18}/>
                                <span className="me-2 ps-1">
                                {activityData.campaignStats.commentCount} {localizer.msg('sidebar.campaign-activity.comments')}
                            </span>
                                <InfoTip id="infotip-top1"
                                         placement="top"
                                         content={localizer.msg('sidebar.campaign-activity.infotip-comments')}/>
                            </li>
                            <li>
                                <Icon className="me-3" name="square-check-rounded" iconSpritePath={iconPath}
                                      fill={'#767676'}
                                      width={18} height={18}/>
                                <span className="me-2">
                                {activityData.campaignStats.voteCount} {localizer.msg('sidebar.campaign-activity.votes')}
                            </span>
                                <InfoTip id="infotip-top2"
                                         placement="top"
                                         content={localizer.msg('sidebar.campaign-activity.infotip-votes')}/>
                            </li>
                        </ul>
                    </div>
                    {
                        activityData.sponsors.length > 0 ? (
                            <div className="campaign-Sponsor">
                                <h2 className="fw-bold mb-3 h6">{localizer.msg('common.campaign-sponsors')}</h2>
                                <div className="campaign-Sponsor-member">
                                    <ul className="list-unstyled mb-0">
                                        {activityData.sponsors.map(sponsor => (
                                            <li className="mb-2" key={sponsor.id}>
                                                <MemberLink
                                                    className="text-decoration-none d-block d-flex align-items-center"
                                                    id={sponsor.id}
                                                    identityHidden={sponsor.identityHidden}>
                                                    <div className="me-2">
                                                        <Avatar className="sb-avatar avatar avatar-sm"
                                                                src={sponsor.avatar}
                                                                alt={sponsor.username ? localizer.msg('common.user-avatar', {username: sponsor.username}) : ''}/>
                                                    </div>
                                                    <div className="text-break">
                                                        <div className="user-name text-lowercase">{sponsor.name}</div>
                                                        {
                                                            sponsor.username &&
                                                            <span className="text-muted">@{sponsor.username}</span>
                                                        }
                                                    </div>
                                                </MemberLink>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ) : null
                    }
                    {
                        activityData.teamLinkAllowed && (
                            <div className="campaign-team">
                                <Link
                                    to={appLinks.campaignTeam(activityData.id.toString())}>{localizer.msg('sidebar.campaign-activity.campaign-team')}</Link>
                            </div>
                        )
                    }
                </div>
            </Fragment>
        )
    );
};