import React, {Fragment} from 'react';
import {Localizer} from '@ideascale/commons';
import {ActivityLink} from '../ActivityLink';
import {ActivityStreamData} from 'models/ActivityStreamData';
import {ActionType} from 'models/enums/ActionType';

type VoteActivityProps = {
    localizer: Localizer;
    action: ActionType.VOTE_POSITIVE | ActionType.VOTE_NEGATIVE | ActionType.VOTE_POSITIVE_RETRACT | ActionType.VOTE_NEGATIVE_RETRACT;
    activity: ActivityStreamData;
}

export const VoteActivity = (props: VoteActivityProps) => {
    const {localizer, activity, action} = props;

    const renderActivityLabel = () => {
        if (action === ActionType.VOTE_POSITIVE || action === ActionType.VOTE_NEGATIVE) {
            return localizer.msg('profile.activity-stream.vote.voted');
        } else if (action === ActionType.VOTE_POSITIVE_RETRACT || action === ActionType.VOTE_NEGATIVE_RETRACT) {
            return localizer.msg('profile.activity-stream.vote.retracted');
        }
    };

    return (
        <Fragment>
            {renderActivityLabel()}{' - '}
            <ActivityLink ideaId={activity.idea.id} title={activity.idea.title}/>
        </Fragment>
    );
};