import React, {useCallback, useState} from 'react';
import {Modal, Spinner} from '@ideascale/ui';
import loadingIcon from '@ideascale/ui/dist/assets/loading.svg';
import {Localizer} from '@ideascale/commons';

type LandingPageEditConfirmationModalProps = {
    localizer: Localizer;
    open: boolean;
    toggle: () => void;
    onContinue: () => Promise<boolean>;
    onStartFresh: () => Promise<boolean>;
}

export const LandingPageEditConfirmationModal = (props: LandingPageEditConfirmationModalProps) => {
    const {localizer, onContinue, onStartFresh, open, toggle} = props;
    const [processing, setProcessing] = useState(false);
    const onCancelClicked = useCallback(async () => {
        setProcessing(true);
        const result = await onStartFresh();
        if (result) {
            setProcessing(false);
            toggle();
        }
    }, [onStartFresh, toggle]);

    const onSaveClicked = useCallback(async () => {
        setProcessing(true);
        const result = await onContinue();
        if (result) {
            setProcessing(false);
            toggle();
        }
    }, [onContinue, toggle]);
    return (
        <Modal isOpen={open} toggle={toggle} modalHeaderId="edit-confirmation-header"
               title={localizer.msg('landing-page.confirmation')}
               cancelButton={{
                   btnLabel: localizer.msg('common.cancel'),
                   btnProps: {
                       onClick: onCancelClicked
                   }
               }}
               primaryButton={{
                   btnLabel: localizer.msg('landing-page.continue'),
                   btnProps: {
                       onClick: onSaveClicked
                   }
               }}>
            {
                processing && <div className="mb-3 text-center"><Spinner src={loadingIcon} size={60}/></div>
            }
            {
                localizer.msg('landing-page.edit-confirmation-msg')
            }
        </Modal>
    );
};