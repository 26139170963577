import React from 'react';
import {TooltipRenderProps} from 'react-joyride';
import './TooltipComponent.scss';

export const TooltipComponent = (props: TooltipRenderProps) => {
    const {continuous, step, closeProps, tooltipProps, skipProps, isLastStep, primaryProps} = props;
    return (
        <div {...tooltipProps}
             className="tour-popover-container">
            {
                step.title &&
                <h3 className="title">{step.title}</h3>
            }
            <div className="body">
                {step.content}
                <div
                    className={`nav-container d-flex ${isLastStep ? 'justify-content-end' : 'justify-content-between'}`}>
                    {
                        !isLastStep && (
                            <button {...skipProps} className="btn btn-cancel" onMouseDown={skipProps.onClick}>
                                {skipProps.title}
                            </button>
                        )
                    }
                    {
                        continuous && (
                            <button {...primaryProps} className="btn btn-cancel" onMouseDown={primaryProps.onClick}>
                                {primaryProps.title}
                            </button>
                        )
                    }
                    {
                        !continuous && (
                            <button {...closeProps} className="btn btn-cancel" onMouseDown={closeProps.onClick}>
                                {closeProps.title}
                            </button>
                        )
                    }
                </div>
            </div>
        </div>
    );
};