import React from 'react';
import {
    ClassificationLabel,
    ClassificationUtil, FileAttachment,
    FileAttribute,
    Localizer,
    MemberLink,
    TimeAgo,
    FileAttachmentDetail
} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import styles from 'components/idea/AttachmentList.module.scss';

type AttachmentPreviewProps = {
    localizer: Localizer;
    attachments: FileAttachmentDetail[];
    onDeleteAttachment?: (attachmentId: number) => void;
    className?: string;
    enabledClassification?: boolean
    fileAttributes?: FileAttribute[];
}

export const AttachmentPreview = (props: AttachmentPreviewProps) => {
    const {
        localizer,
        attachments,
        onDeleteAttachment,
        className = '',
        enabledClassification = false,
        fileAttributes
    } = props;

    const {authentication: {actor}} = useAppContext();
    if (!attachments) {
        return null;
    }

    return (
        <ul className={`list-unstyled ${styles.attachmentList}`}>
            {attachments?.map(attachment =>
                <li className={`py-3 ${className}`} key={attachment.id}>
                    <div>
                        {
                            enabledClassification && fileAttributes &&
                            <div className="attribute-wrapper">
                                <ClassificationLabel
                                    classification={ClassificationUtil.getAppliedFileAttribute(attachment.filename, fileAttributes)}/>
                            </div>
                        }
                        <MemberLink className="fw-bold" id={attachment.submitter.id}
                                    identityHidden={attachment.submitter.identityHidden}>
                            {attachment.submitter.id === actor.id ? localizer.msg('common.you') : attachment.submitter.name}
                        </MemberLink>
                        {' '}{localizer.msg('common.attached')}: <a className="fw-bold"
                                                                    href={attachment.url}>{attachment.filename}</a>
                        {' '}<TimeAgo localizer={localizer} dateISOString={attachment.submittedAt}/>
                    </div>
                    <div className={`${styles.attachmentContent}`}>
                        <p className="mt-2 mb-1">{attachment.note && attachment.note}</p>
                        {
                            FileAttachment.isPreviewableImg(attachment.filename) &&
                            <img src={attachment.url} alt={attachment.altText || attachment.filename}/>
                        }
                    </div>
                    {
                        attachment.deletionAllowed &&
                        <div className="d-flex flex-row-reverse">
                            <button className="btn btn-link px-0 shadow-none"
                                    onClick={() => onDeleteAttachment && onDeleteAttachment(attachment.id)}>
                                {localizer.msg('common.actions.delete')}
                            </button>
                        </div>
                    }
                </li>
            )}
        </ul>
    );
};