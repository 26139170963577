import React from 'react';
import {Icon} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {Localizer} from '@ideascale/commons';
import {IdeaListViewMode} from 'models/enums/IdeaListViewMode';

type IdeaListViewModesProps = {
    localizer: Localizer;
    listViewMode: IdeaListViewMode;
    onClickViewMode: (viewMode: IdeaListViewMode) => void;
}

export const IdeaListViewModes = (props: IdeaListViewModesProps) => {
    const {localizer, onClickViewMode, listViewMode} = props;

    const renderIdeaViewModeIcon = (viewMode: IdeaListViewMode) => {
        let icon = <Icon name="lines-four-horizontal" width={22} height={22} fill="#999"
                         iconSpritePath={svgIconsPath}/>;
        if (viewMode === IdeaListViewMode.LIST_VIEW) {
            icon = <Icon name="rectangles-top-bottom" width={22} height={22} fill="#999"
                         iconSpritePath={svgIconsPath}/>;
        } else if (viewMode === IdeaListViewMode.GRID_VIEW) {
            icon = <Icon name="grid-squares" width={22} height={22} fill="#999"
                         iconSpritePath={svgIconsPath}/>;
        }
        return icon;
    };

    return (
        <div className="idea-view-mode me-n2 d-none d-lg-block"
             aria-label={localizer.msg('ideas.view-mode.label')}
             role="radiogroup">
            {
                Object.values(IdeaListViewMode)
                    .filter(viewMode => viewMode !== IdeaListViewMode.UNDEFINED)
                    .map(viewMode => {
                        return (
                            <button key={viewMode}
                                    className={`btn p-0 me-2 ${(listViewMode === viewMode) ? 'active' : ''} `}
                                    onClick={() => onClickViewMode(viewMode)}
                                    data-test-element-id={`${viewMode.toLowerCase().replace('_', '-')}-mode`}
                                    title={viewMode.replace('_', ' ').toLowerCase()}
                                    role="radio"
                                    aria-checked={listViewMode === viewMode}>
                                {renderIdeaViewModeIcon(viewMode)}
                            </button>
                        );
                    })
            }
        </div>
    );
};