import {CampaignEditableFieldType} from './CampaignEditableFieldType';
import {BaseConfigField} from './BaseConfigField';

export class CampaignTeamVisibilityConfigField extends BaseConfigField {
    public static EMPTY = new CampaignTeamVisibilityConfigField(CampaignEditableFieldType.UNKNOWN, false);

    constructor(
        name: CampaignEditableFieldType,
        public value: boolean
    ) {
        super(name);
    }
}