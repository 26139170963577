import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Icon} from '@ideascale/ui';
import {
    LabelData,
    ListSkeleton,
    Localizer,
    useApiErrorResponseHandler,
    useDropdownFocus,
    useToggle,
    uuid
} from '@ideascale/commons';
import {CommentLabelsResponse} from 'models/comments/CommentLabelsResponse';

type CommentLabelsActionProps = {
    localizer: Localizer;
    fetchLabels: (targetId: number) => Promise<CommentLabelsResponse>;
    targetId: number;
    addLabel: (commentId: number, label: LabelData) => Promise<LabelData[]>;
    removeLabel: (commentId: number, label: LabelData) => Promise<void>;
    svgIconPath: string;
    selectedLabelIds?: number[];
    toggleButtonClass?: string;
    toggleButtonTag?: React.ElementType;
    toggleButtonLabel?: string;
    onReportAbuseClick?: () => void;
}

export const CommentLabelsAction = (props: CommentLabelsActionProps) => {
    const {
        localizer,
        addLabel,
        removeLabel,
        targetId,
        fetchLabels,
        selectedLabelIds,
        svgIconPath,
        toggleButtonTag = 'button',
        toggleButtonClass = '',
        toggleButtonLabel = '',
        onReportAbuseClick
    } = props;

    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [dropdownOpen, toggleDropdown] = useToggle(false);
    const [labels, setLabels] = useState<LabelData[]>([]);
    const [fetchingLabels, setFetchingLabels] = useState(true);
    const [reportAbuseAllowed, setReportAbuseAllowed] = useState(false);
    const {focus} = useDropdownFocus();

    const menuFocusId = useMemo(() => {
        return `comment-${targetId}-labels-menu-${uuid()}`;
    }, [targetId]);

    const toggleLabel = useCallback(async (labelData: LabelData) => {
        try {
            if (labelData.addAllowed && !labelData.applied) {
                const response = await addLabel(targetId, labelData);
                const findAppliedLabel = response.find(item => item.id === labelData.id);
                if (findAppliedLabel) {
                    setLabels(labels.map(label => {
                        if (label.id === findAppliedLabel.id) {
                            label.applied = findAppliedLabel.applied;
                            label.removeAllowed = findAppliedLabel.removeAllowed;
                            return label;
                        }
                        return label;
                    }));
                }
            } else if (labelData.removeAllowed && labelData.applied) {
                await removeLabel(targetId, labelData);
                setLabels(labels.map(label => {
                    if (label.id === labelData.id) {
                        label.applied = false;
                        return label;
                    }
                    return label;
                }));
            }
        } catch (error: any) {
            handleErrorResponse(error);
        }
    }, [addLabel, handleErrorResponse, labels, removeLabel, targetId]);

    const allowedLabelAction = (label: LabelData) => {
        return (label.applied && label.removeAllowed) || (!label.applied && label.addAllowed);
    };

    useEffect(() => {
        if (dropdownOpen) {
            fetchLabels(targetId).then(labelsResponse => {
                if (selectedLabelIds) {
                    labelsResponse.labels.forEach(label => {
                        label.applied = selectedLabelIds.includes(label.id) || label.applied;
                    });
                }
                setLabels(labelsResponse.labels);
                setReportAbuseAllowed(labelsResponse.reportAbuseAllowed);
                focus('#' + menuFocusId);
            }).finally(() => setFetchingLabels(false));
        }
    }, [dropdownOpen, fetchLabels, focus, menuFocusId, selectedLabelIds, targetId]);

    return (
        <ButtonDropdown
            className="modify-label drawer-able"
            isOpen={dropdownOpen}
            toggle={toggleDropdown}>
            <DropdownToggle tag={toggleButtonTag} role="button" className={`btn btn-link ${toggleButtonClass}`}
                            title="Add or Remove label" aria-label="Add or Remove label">
                <Icon className="pointer-events-none" iconSpritePath={svgIconPath} name="flag-waving" width={18}
                      height={18}/>
                <span className="d-none d-lg-inline fw-normal pointer-events-none">{' '}{toggleButtonLabel}</span>
            </DropdownToggle>

            <div className="panel-backdrop"/>
            <div className={`right-panel ${!dropdownOpen ? 'bg-transparent' : ''}`}>
                <DropdownMenu id={menuFocusId} end modifiers={[
                    {
                        name: 'applyMaxSize',
                        enabled: true,
                        phase: 'write',
                        fn({state}) {
                            if (window.innerWidth > 991) {
                                state.styles.popper = {
                                    ...state.styles.popper,
                                    overflow: 'auto',
                                    maxHeight: '300px',
                                };
                            } else {
                                delete state.styles.popper.overflow;
                                delete state.styles.popper.maxHeight;
                            }
                        }
                    }
                ]}>
                    {
                        fetchingLabels
                            ? <DropdownItem>
                                <ListSkeleton items={3} cssClass="w-100"/>
                            </DropdownItem>
                            : labels.length > 0
                                ? labels.map(label =>
                                    allowedLabelAction(label)
                                        ? <DropdownItem key={label.id} toggle={false}
                                                        onClick={() => toggleLabel(label)} title={label.name}
                                                        className={`cursor-pointer ${label.applied ? `label-field-${label.colorKey} applied` : ''}`}>
                                            <span className={`icon-circle label-field-${label.colorKey}`}/>
                                            {label.name}
                                        </DropdownItem>
                                        : <DropdownItem key={label.id} toggle={false}
                                                        title={label.name}
                                                        className={`cursor-default ${label.applied ? `label-field-${label.colorKey} applied` : ''}`}>
                                            <span className={`icon-circle label-field-${label.colorKey}`}/>
                                            {label.name}
                                        </DropdownItem>
                                )
                                : (
                                    !reportAbuseAllowed &&
                                    <DropdownItem>{localizer.msg('comments.actions.add-labels.not-found')}</DropdownItem>
                                )
                    }

                    {
                        reportAbuseAllowed && onReportAbuseClick && (
                            <DropdownItem className="d-flex align-items-center" toggle={true} onClick={() => {
                                onReportAbuseClick();
                                toggleDropdown(true);
                            }}>
                                <span className="icon-circle label-field-abuse me-2"/>
                                {localizer.msg('idea.actions.report-abuse')}
                            </DropdownItem>
                        )
                    }
                </DropdownMenu>
            </div>
        </ButtonDropdown>
    );
};