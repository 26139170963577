import React, {Fragment, useState} from 'react';
import {useQueryClient} from 'react-query';
import {Button, Modal, Switch} from '@ideascale/ui';
import {Cookies, Localizer, reloadPage} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {helpLinks, QUERY_KEYS} from 'constants/AppConstants';
import {FooterData} from 'models/FooterData';
import 'components/CookieConsentModal.scss';

type CookieConsentModalProps = {
    localizer: Localizer;
    showCookieModal: boolean;
    toggleCookieModal: () => void;
    brandingAllowed: boolean;
    confirmCookieConsent: (cookieCategories: Cookies[]) => Promise<void>;
}

export const CookieConsentModal = (props: CookieConsentModalProps) => {
    const {
        localizer,
        showCookieModal,
        toggleCookieModal,
        brandingAllowed,
        confirmCookieConsent
    } = props;

    const queryClient = useQueryClient();
    const {cookieConsent, setCookieConsent, communityConfig: {helpSiteBaseUrl}} = useAppContext();
    const [activeCookieCategories, setActiveCookieCategories] = useState(cookieConsent.cookieCategories);
    const [showCookieDetails, setShowCookieDetails] = useState(!cookieConsent.needsCookieConsent);

    const onAcceptClicked = () => {
        confirmCookieConsent(Object.values(Cookies)).then(() => {
            queryClient.setQueryData(QUERY_KEYS.FOOTER, (data): FooterData => ({
                ...data as FooterData,
                cookieConsent: {needsCookieConsent: false, cookieCategories: Object.values(Cookies)}
            }));
            setCookieConsent({
                needsCookieConsent: false,
                cookieCategories: Object.values(Cookies)
            });
            toggleCookieModal();
            reloadPage();
        });
    };

    const onConfirmClicked = () => {
        confirmCookieConsent(activeCookieCategories).then(() => {
            setCookieConsent(prevState => ({
                ...prevState,
                cookieCategories: activeCookieCategories
            }));
            toggleCookieModal();
            reloadPage();
        });
    };

    return (
        <Modal className="cookie-modal"
               isOpen={showCookieModal}
               toggle={toggleCookieModal}
               modalHeaderId="cookie-policy-header"
               title={localizer.msg(`${showCookieDetails
                   ? 'footer.cookie-consent-modal.advanced-heading'
                   : 'footer.cookie-consent-modal.basic-heading'}`)}
               aria-labelledby="cookie-policy-header"
               zIndex={9999999999}
               aria-live="polite">
            {
                showCookieDetails ? (
                    <div className="py-2">
                        <p>{localizer.msg('footer.cookie-consent-modal.advanced-content')}
                            {
                                brandingAllowed && (
                                    <Fragment>
                                        {' '}{localizer.msg('footer.cookie-consent-modal.advanced-more-info')}
                                        <a href={helpLinks.cookiePolicyUrl(helpSiteBaseUrl)}>
                                            {localizer.msg('footer.cookie-consent-modal.cookie-page')}
                                        </a>
                                    </Fragment>
                                )
                            }
                        </p>
                        <Switch name="essential-cookies" inputId="essential-cookies" defaultChecked={true}
                                disabled={true}
                                label={<span
                                    className="fw-bold">{localizer.msg('footer.cookie-consent-modal.essential')}</span>}
                                onText={localizer.msg('footer.cookie-consent-modal.on-text')}
                                offText={localizer.msg('footer.cookie-consent-modal.off-text')}/>
                        <p>{localizer.msg('footer.cookie-consent-modal.essential-desc')}</p>
                        <Switch name="analytical-cookies" inputId="analytical-cookies"
                                defaultChecked={activeCookieCategories.includes(Cookies.ANALYTICS)}
                                onChange={(event) => setActiveCookieCategories(prev => {
                                    return event.target.checked
                                        ? [...prev, Cookies.ANALYTICS]
                                        : prev.filter(cookie => cookie !== Cookies.ANALYTICS);
                                })}
                                label={<span
                                    className="fw-bold">{localizer.msg('footer.cookie-consent-modal.analytical')}</span>}
                                onText={localizer.msg('footer.cookie-consent-modal.on-text')}
                                offText={localizer.msg('footer.cookie-consent-modal.off-text')}/>
                        <p>{localizer.msg('footer.cookie-consent-modal.analytical-desc')}</p>
                        <Switch name="non-essential-cookies" inputId="non-essential-cookies"
                                defaultChecked={activeCookieCategories.includes(Cookies.NON_ESSENTIAL)}
                                onChange={(event) => setActiveCookieCategories(prev => {
                                    return event.target.checked
                                        ? [...prev, Cookies.NON_ESSENTIAL]
                                        : prev.filter(cookie => cookie !== Cookies.NON_ESSENTIAL);
                                })}
                                label={<span
                                    className="fw-bold">{localizer.msg('footer.cookie-consent-modal.non-essential')}</span>}
                                onText={localizer.msg('footer.cookie-consent-modal.on-text')}
                                offText={localizer.msg('footer.cookie-consent-modal.off-text')}/>
                        {
                            activeCookieCategories.includes(Cookies.NON_ESSENTIAL) &&
                            <p>{localizer.msg('footer.cookie-consent-modal.non-essential-desc')}</p>
                        }
                        <div className="mt-3">
                            <Button color="primary" onClick={onConfirmClicked}>
                                {localizer.msg('footer.cookie-consent-modal.confirm')}
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="row">
                        <div className="col py-3">
                            <p className="mb-0">{localizer.msg('footer.cookie-consent-modal.basic-content')}</p>
                        </div>
                        <div className="col-md-auto py-2 d-flex align-items-center">
                            <button type="button" className="btn btn-link flex-fill"
                                    onClick={() => setShowCookieDetails(true)}>
                                {localizer.msg('footer.cookie-consent-modal.read-more')}
                            </button>
                            <button type="button" className="btn btn-primary flex-fill"
                                    onClick={onAcceptClicked}>
                                {localizer.msg('footer.cookie-consent-modal.accept')}
                            </button>
                        </div>
                    </div>
                )
            }
        </Modal>
    );
};