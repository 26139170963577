import {useMutation, useQueryClient} from 'react-query';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import {useIdeaService} from 'hooks/useIdeaService';
import {PagedIdeas} from 'contexts/IdeasContext';
import {QUERY_KEYS} from 'constants/AppConstants';
import {IdeaSubmissionRequest} from 'models/IdeaSubmissionRequest';
import {IdeaDetail} from 'models/IdeaDetail';

type EditIdeaActionParams = {
    ideaId: number;
    submissionRequest: IdeaSubmissionRequest;
}

export const useEditIdeaActions = (ideaListQueryKey: any = '') => {
    const queryClient = useQueryClient();
    const {modifyIdea} = useIdeaService();

    const ideaListEditIdeaMutation = useMutation(
        ({ideaId, submissionRequest}: EditIdeaActionParams) => modifyIdea(ideaId, submissionRequest),
        {
            onSuccess: (newIdeaSummary) => {
                queryClient.setQueryData(ideaListQueryKey, (oldIdeaList) => {
                    if (oldIdeaList) {
                        const newIdeaLIst = cloneDeep(oldIdeaList) as { pages: PagedIdeas[], pageParams: any };
                        newIdeaLIst.pages.map(page => {
                            const index = page.data.findIndex(idea => idea.id === newIdeaSummary.id);
                            if (index > -1) {
                                page.data[index] = newIdeaSummary;
                            }
                            return page;
                        });
                        return newIdeaLIst;
                    }
                    return {pages: [], pageParams: []};
                });
            }
        }
    );

    const ideaDetailsEditMutation = useMutation(({
                                                     ideaId,
                                                     submissionRequest
                                                 }: EditIdeaActionParams) => modifyIdea(ideaId, submissionRequest),
        {
            onSuccess: async (modifiedIdeaDetail) => {
                queryClient.setQueryData([QUERY_KEYS.IDEA_DETAILS, modifiedIdeaDetail.id], (oldIdeaDetail: IdeaDetail | undefined): IdeaDetail => {
                    if (oldIdeaDetail) {
                        return {
                            ...oldIdeaDetail,
                            ...modifiedIdeaDetail,
                            ideaTagHolder: {...oldIdeaDetail.ideaTagHolder, tags: modifiedIdeaDetail.tags},
                            attributes: modifiedIdeaDetail.attributes,
                            commentAttachments: uniqBy([...oldIdeaDetail.commentAttachments, ...modifiedIdeaDetail.commentAttachments], 'filename')
                        };
                    } else {
                        return {...IdeaDetail.EMPTY, ...modifiedIdeaDetail};
                    }
                });
            },
            onSettled: async (ideaDetail, error) => {
                if (!error && ideaDetail) {
                    await queryClient.invalidateQueries([QUERY_KEYS.IDEA_DETAILS, ideaDetail.id]);
                }
            }
        }
    );

    return {ideaListEditIdeaMutation, ideaDetailsEditMutation};
};