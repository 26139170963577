import React, {Fragment} from 'react';
import {useIdeaSubmissionService} from 'hooks/useIdeaSubmissionService';
import {IdeaFormModal} from 'components/idea-submission/IdeaFormModal';

type IdeaFormContainerProps = {
    copyIdeaFormModalOpen: boolean;
    toggleCopyIdeaFormModalOpen: () => void;
    ideaId: number;
    isIdeaCopyMode: boolean;
}

export const CopyIdeaFormContainer = (props: IdeaFormContainerProps) => {
    const {copyIdeaFormModalOpen, toggleCopyIdeaFormModalOpen, ideaId} = props;
    const methods = useIdeaSubmissionService();

    return (
        <Fragment>
            {
                copyIdeaFormModalOpen &&
                <IdeaFormModal
                    {...methods}
                    initialDraftIdeaId={null}
                    open={copyIdeaFormModalOpen}
                    toggle={toggleCopyIdeaFormModalOpen}
                    initialEditIdeaId={ideaId}
                    defaultMode="COPY_IDEA"
                />
            }
        </Fragment>
    );
};