import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {isEmpty} from 'lodash';
import {ActionButton, Button, Checkbox} from '@ideascale/ui';
import {
    AiImageResponse,
    isHtmlInject,
    isJavascriptInject,
    isKeyExists,
    Localizer,
    useApiErrorResponseHandler
} from '@ideascale/commons';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useAppContext} from 'contexts/AppContext';
import {useTabPermission} from 'hooks/useTabPermission';
import {useEditModeFileOperationService} from 'hooks/useEditModeFileOperationService';
import {useEditModeFormErrorHandler} from 'hooks/useEditModeFormErrorHandler';
import {useRouteUtils} from 'hooks/useRouteUtils';
import {FileLibrary} from './advanced-settings/FileLibrary';
import {FileUpload} from './advanced-settings/FileUpload';
import {InfoMessage} from './advanced-settings/InfoMessage';
import {AiFileUpload} from './advanced-settings/AiFileUpload';
import {TabItem} from '../share/TabItem';
import {TabPanel} from '../share/TabPanel';
import {LicenseUpgradeInfo} from 'components/edit-mode/LicenseUpgradeInfo';
import {UpdateCampaignLogoCommand} from 'commands/edit-mode/UpdateCampaignLogoCommand';
import {ErrorMessage} from './advanced-settings/ErrorMessage';
import {CampaignEditableFieldType} from 'models/edit-mode/CampaignEditableFieldType';
import {LogoField, LogoFieldBuilder} from 'models/edit-mode/LogoField';
import {ImageType} from 'models/enums/ImageType';
import {ImageFile} from 'models/edit-mode/ImageFile';
import {UploadFileRequest} from 'models/edit-mode/UploadFileRequest';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';
import {UploadedImage} from 'models/types/UploadedImage';
import {ImageUploadSource} from 'models/enums/ImageUploadSource';
import {DEFAULT_STALE_TIME, QUERY_KEYS} from 'constants/AppConstants';

const CAMPAIGN_LOGO_RATIO = {w: 300, h: 300};
const LOGO_AI_IMAGE_SIZE = '256x256';

export type CampaignLogoProps = {
    localizer: Localizer;
    maxFileSizeLimit: number;
    toggle: () => void;
    fetchAiAssistedImage: (prompt: string, size?: string, numberOfImages?: number) => Promise<AiImageResponse>;
}

export const CampaignLogo = (props: CampaignLogoProps) => {
    const {localizer, maxFileSizeLimit, toggle, fetchAiAssistedImage} = props;
    const {fetchCampaignLibraryImages, campaignUploadLibraryFiles} = useEditModeFileOperationService();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const {homeConfig, campaignHomeEditor, commandExecutor, validationErrors} = useEditModeContext();
    const {communityConfig: {aiImageAssistEnabled}, authentication: {actor}} = useAppContext();
    const [checkedHideLogoFrame, setCheckedHideLogoFrame] = useState<boolean>(false);
    const [checkedHideCampaignLogo, setCheckedHideCampaignLogo] = useState<boolean>(false);
    const [campaignLogo, setCampaignLogo] = useState<LogoField>(LogoField.EMPTY);
    const [uploadedImageType, setUploadedImageType] = useState<ImageUploadSource | undefined>(undefined);
    const {campaignRouteMatch} = useRouteUtils();
    const {tabPermission} = useTabPermission(campaignLogo);
    const [fileValidationErrors, setFileValidationErrors] = useState<Record<string, string>>({});
    const [fileUploading, setFileUploadingStatus] = useState(false);
    const {fieldError} = useEditModeFormErrorHandler({
        localizer,
        validationErrors: validationErrors.findError(CampaignOperationType.CHANGE_CAMPAIGN_LOGO)
    });

    const {data: libraryImages = []} = useQuery([QUERY_KEYS.CAMPAIGN_LIBRARY_IMAGES], () => fetchCampaignLibraryImages(), {
        staleTime: DEFAULT_STALE_TIME,
        cacheTime: 0,
        onError: (error: any) => {
            handleErrorResponse(error);
        }
    });

    const onCampaignLogoSelected = useCallback((image: ImageFile) => {
        const newImage = new LogoFieldBuilder()
            .name(CampaignEditableFieldType.CAMPAIGN_LOGO)
            .fileId(image.fileId)
            .url(image.url)
            .imageType(ImageType.CUSTOM)
            .defaultImage(false)
            .logoFrameEnabled(!checkedHideLogoFrame)
            .logoOnBannerEnabled(!checkedHideCampaignLogo)
            .build();
        setCampaignLogo(prev => ({
            ...newImage,
            standardFilePermission: prev.standardFilePermission,
            fileLibraryPermission: prev.fileLibraryPermission,
            fileUploadPermission: prev.fileUploadPermission
        }));
        setUploadedImageType(undefined);
    }, [checkedHideCampaignLogo, checkedHideLogoFrame, setCampaignLogo]);

    const onLogoUploaded = useCallback((image: UploadedImage, uploadType: ImageUploadSource) => {
        const newImage = new LogoFieldBuilder()
            .name(CampaignEditableFieldType.CAMPAIGN_LOGO)
            .fileId(image.fileId)
            .filename(image.filename)
            .url(image.url)
            .altText(image.altText ?? '')
            .imageType(ImageType.UPLOADED)
            .defaultImage(false)
            .logoFrameEnabled(!checkedHideLogoFrame)
            .logoOnBannerEnabled(!checkedHideCampaignLogo)
            .build();
        setCampaignLogo(prev => ({
            ...newImage,
            standardFilePermission: prev.standardFilePermission,
            fileLibraryPermission: prev.fileLibraryPermission,
            fileUploadPermission: prev.fileUploadPermission
        }));
        setUploadedImageType(uploadType);
    }, [checkedHideCampaignLogo, checkedHideLogoFrame]);

    const campaignLogoTabItems = useMemo(() => {
        const tabs = [
            {
                value: 1,
                icon: 'plus-solid-box-rounded-layered',
                text: localizer.msg('edit-mode.image-setting.tabs.file-library'),
                disabled: tabPermission.libraryDisabled,
                content: (
                    <Fragment>
                        {
                            !tabPermission.libraryDisabled
                                ? (
                                    <FileLibrary
                                        localizer={localizer}
                                        images={libraryImages}
                                        previewMode="xs-small"
                                        onSelectedImage={onCampaignLogoSelected}
                                        selectedImage={campaignLogo}
                                        ariaLabel={localizer.msg('edit-mode.image-setting.tabs.file-library')}
                                    />
                                )
                                : null
                        }
                    </Fragment>
                ),
                additionalTabInfo: (
                    <LicenseUpgradeInfo
                        className="edit-mode-element"
                        triggerButtonId="campaign-logo-license-popover-trigger-1"
                        showIconOnly={true}
                        localizer={localizer}
                    />
                )
            },
            {
                value: 2,
                icon: 'arrow-up-from-line',
                text: localizer.msg('edit-mode.image-setting.tabs.upload'),
                disabled: tabPermission.uploadDisabled,
                content: (
                    <Fragment>
                        {
                            !tabPermission.uploadDisabled
                                ? (
                                    <Fragment>
                                        <FileUpload localizer={localizer}
                                                    maxFileSizeLimit={maxFileSizeLimit}
                                                    uploadedType={uploadedImageType}
                                                    cropWidth={CAMPAIGN_LOGO_RATIO.w}
                                                    cropHeight={CAMPAIGN_LOGO_RATIO.h}
                                                    errorReason={fileValidationErrors[`files[0].altText`]}
                                                    onUploadedSuccessfulImage={onLogoUploaded}
                                                    updateFileUploadingStatus={setFileUploadingStatus}
                                        />
                                    </Fragment>
                                )
                                : null
                        }
                    </Fragment>
                ),
                additionalTabInfo: (
                    <LicenseUpgradeInfo
                        className="edit-mode-element"
                        triggerButtonId="campaign-logo-license-popover-trigger-2"
                        showIconOnly={true}
                        localizer={localizer}
                    />
                )
            },
        ] as TabItem[];

        if (aiImageAssistEnabled) {
            tabs.push({
                value: 3,
                icon: 'sparkles',
                text: localizer.msg('ai-assistance.image-upload-label'),
                disabled: false,
                content: (
                    <AiFileUpload localizer={localizer}
                                  avatar={actor.avatar}
                                  imageSize={LOGO_AI_IMAGE_SIZE}
                                  initialPrompt={campaignHomeEditor.name}
                                  maxFileSizeLimit={maxFileSizeLimit}
                                  cropWidth={CAMPAIGN_LOGO_RATIO.w}
                                  cropHeight={CAMPAIGN_LOGO_RATIO.h}
                                  uploadedType={uploadedImageType}
                                  altTextErrorReason={fileValidationErrors[`files[0].altText`]}
                                  onUploaded={onLogoUploaded}
                                  fetchAiAssistedImage={fetchAiAssistedImage}
                                  updateFileUploadingStatus={setFileUploadingStatus}/>
                ),
                additionalTabInfo: (
                    <LicenseUpgradeInfo
                        className="edit-mode-element"
                        triggerButtonId="campaign-logo-license-popover-trigger-1"
                        showIconOnly={true}
                        localizer={localizer}
                    />
                )
            });
        }
        return tabs;
    }, [localizer, tabPermission.libraryDisabled, tabPermission.uploadDisabled, libraryImages, onCampaignLogoSelected, campaignLogo, maxFileSizeLimit, uploadedImageType, fileValidationErrors, onLogoUploaded, aiImageAssistEnabled, actor.avatar, campaignHomeEditor.name, fetchAiAssistedImage]);

    const updateCampaignLogo = useCallback(() => {
        const campaignId = (campaignRouteMatch?.params as any)?.campaignId;
        if (campaignLogo !== homeConfig.getLogoField(CampaignEditableFieldType.CAMPAIGN_LOGO) && campaignId) {
            const command = new UpdateCampaignLogoCommand(campaignHomeEditor, {
                ...campaignLogo,
                defaultImage: campaignLogo.imageType === ImageType.UPLOADED && campaignLogo.fileId === 0
            }, +campaignId);
            commandExecutor.execute(command);
            validationErrors.clearError(command.getType());
        }
    }, [campaignRouteMatch?.params, campaignHomeEditor, campaignLogo, commandExecutor, homeConfig, validationErrors]);

    const onUpdateClicked = useCallback(async () => {
        setFileValidationErrors({});
        try {
            if (campaignLogo.fileId <= 0 && campaignLogo.filename?.length) {
                const uploadFileRequest = new UploadFileRequest([]);
                uploadFileRequest.files.push({filename: campaignLogo.filename, altText: campaignLogo.altText ?? ''});

                const response = await campaignUploadLibraryFiles(uploadFileRequest);
                if (response) {
                    const uploadedFile = response.find(uploadedResponse => uploadedResponse.filename === campaignLogo.filename)?.file
                        || {id: 0, url: ''};
                    campaignLogo.fileId = uploadedFile.id;
                    campaignLogo.url = uploadedFile.url;
                    campaignLogo.imageType = ImageType.CUSTOM;
                }
                updateCampaignLogo();
            }
            toggle();
        } catch (e: any) {
            if (e?.data?.validationErrors) {
                setFileValidationErrors(e?.data?.validationErrors);
            } else {
                handleErrorResponse(e);
            }
        }
        updateCampaignLogo();
    }, [campaignLogo, handleErrorResponse, toggle, updateCampaignLogo, campaignUploadLibraryFiles]);

    useEffect(() => {
        const defaultCampaignLogo = homeConfig.getLogoField(CampaignEditableFieldType.CAMPAIGN_LOGO);
        setCampaignLogo(defaultCampaignLogo);
        setCheckedHideLogoFrame(!defaultCampaignLogo?.logoFrameEnabled);
        setCheckedHideCampaignLogo(!defaultCampaignLogo?.logoOnBannerEnabled);
    }, [homeConfig]);

    const isFormDirty = () => {
        return campaignLogo !== homeConfig.getLogoField(CampaignEditableFieldType.CAMPAIGN_LOGO);
    };

    const hasAllTabsDisabled = useMemo(() => {
        return tabPermission.libraryDisabled && tabPermission.uploadDisabled;
    }, [tabPermission.libraryDisabled, tabPermission.uploadDisabled]);

    const hasErrorInAltText = () => {
        const hasInjectedAltText = campaignLogo.altText ? (isHtmlInject(campaignLogo.altText) || isJavascriptInject(campaignLogo.altText)) : false;
        const hasFileValidationErrors = Object.keys(fileValidationErrors).length > 0;
        return hasInjectedAltText || hasFileValidationErrors;
    };

    return (
        <Fragment>
            <div className="d-flex align-items-center">
                <h5 className={`my-3 fw-semibold ${validationErrors.findError(CampaignOperationType.CHANGE_CAMPAIGN_LOGO) ? 'has-error' : ''}`}>{localizer.msg('edit-mode.campaign.logo-title')}</h5>
                {
                    hasAllTabsDisabled && (
                        <LicenseUpgradeInfo
                            triggerButtonId="campaign-logo-title-license-popover"
                            className="ms-2"
                            localizer={localizer}
                        />
                    )
                }
            </div>
            <ErrorMessage message={fieldError('fileId')?.message}/>
            {
                !hasAllTabsDisabled
                    ? (
                        <Fragment>
                            <TabPanel tabItems={campaignLogoTabItems}/>

                            <div className="form-content mt-4">
                                <div className="form-group">
                                    <Checkbox
                                        className="fw-normal"
                                        inputId="hide-campaign-logo-frame"
                                        label={localizer.msg('edit-mode.campaign.hide-logo-frame')}
                                        checked={checkedHideLogoFrame}
                                        onChange={event => {
                                            setCheckedHideLogoFrame(event.target.checked);
                                            setCampaignLogo(prevImage => {
                                                if (prevImage) {
                                                    return {
                                                        ...prevImage,
                                                        fileId: prevImage?.fileId ?? 0,
                                                        logoFrameEnabled: !event.target.checked
                                                    };
                                                }
                                                return prevImage;
                                            });
                                        }}
                                    />
                                </div>

                                <div className="form-group">
                                    <Checkbox
                                        className="fw-normal"
                                        inputId="hide-campaign-logo"
                                        label={localizer.msg('edit-mode.campaign.hide-logo')}
                                        checked={checkedHideCampaignLogo}
                                        onChange={event => {
                                            setCheckedHideCampaignLogo(event.target.checked);
                                            setCampaignLogo(prevImage => {
                                                if (prevImage) {
                                                    return {
                                                        ...prevImage,
                                                        fileId: prevImage?.fileId ?? 0,
                                                        logoOnBannerEnabled: !event.target.checked
                                                    };
                                                }
                                                return prevImage;
                                            });
                                        }}
                                    />
                                </div>
                            </div>

                            {(!isKeyExists(campaignLogo, 'fileId') || isEmpty(campaignLogo.url) || campaignLogo.defaultImage) &&
                                <InfoMessage localizer={localizer}/>}
                        </Fragment>
                    )
                    : null
            }

            <div className="action-bar mt-4 mb-3 p-0 text-end">
                <Button className="btn btn-cancel" color="cancel" aria-label="Close modal" onClick={toggle}
                        data-test-element-id="cancel">
                    {localizer.msg('common.actions.cancel')}
                </Button>
                <ActionButton
                    className="btn btn-primary btn-lg"
                    type="button"
                    loading={fileUploading}
                    disabled={!isFormDirty() || hasErrorInAltText()}
                    onClick={onUpdateClicked}
                    data-test-element-id="update"
                >
                    {localizer.msg('common.update')}
                </ActionButton>
            </div>
        </Fragment>
    );
};
