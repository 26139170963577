import React, {useCallback, useState} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import {Icon} from '@ideascale/ui';
import iconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {Localizer} from '@ideascale/commons';
import {SidebarItem} from 'models/sidebar/SidebarItem';

declare type EditModuleType = {
    index: number;
    sidebarItem: SidebarItem;
    onEditButtonClick: (sidebarItem: SidebarItem) => void;
    onVisibilityButtonClick: (sidebarItem: SidebarItem) => void;
    localizer: Localizer;
    hasError?: boolean;
};

export const SidebarEditableElement = (props: EditModuleType) => {
    const {index, sidebarItem, onEditButtonClick, onVisibilityButtonClick, localizer, hasError = false} = props;
    const [, setHidden] = useState(false);

    const handleVisibility = useCallback((visible: boolean) => {
        setHidden(prev => !prev);
        sidebarItem.visible = visible;
        onVisibilityButtonClick(sidebarItem);
    }, [sidebarItem, onVisibilityButtonClick]);

    return (
        <Draggable key={sidebarItem.key} draggableId={sidebarItem.key} index={index}>
            {
                ((provided) => (
                    <div {...provided.draggableProps} className="card panel panel-light"
                         data-test-element-id={sidebarItem.key} ref={provided.innerRef}>
                        <div
                            className={`card-header panel-heading d-flex align-items-center h-auto ${hasError ? 'has-error' : 'border-bottom-0'}`}>
                            <div className="edit-mode-element me-2" {...provided.dragHandleProps} role="button"
                                 title={localizer.msg('common.reorder')}>
                                <Icon name="dots-grid" iconSpritePath={iconPath}
                                      fill={'#333333'}
                                      width={17} height={17}/>
                            </div>
                            <div
                                className={`module-title ${sidebarItem.visible ? 'no-opacity' : 'opacity-50'} text-truncate me-1`}
                                title={sidebarItem.title}>
                                {sidebarItem.title}
                            </div>
                            <div
                                className="edit-module-actions d-flex ms-auto">
                                <button type="button"
                                        className={`${sidebarItem.visible ? 'no-opacity' : 'opacity-50'} ${sidebarItem.systemDefault ? 'cursor-default' : ''} btn p-0 mt-0 edit-mode-element`}
                                        onClick={() => sidebarItem.systemDefault ? null : handleVisibility(!sidebarItem.visible)}
                                        title={localizer.msg('common.toggle-visibility')} role="switch"
                                        aria-checked={sidebarItem.visible}
                                        data-test-element-id="toggle-visibility">
                                    <Icon name={sidebarItem.visible ? 'eye-open' : 'eye-open-slash'}
                                          className={sidebarItem.systemDefault ? 'opacity-50' : ''}
                                          iconSpritePath={iconPath} fill={'#333333'} width={20}
                                          height={20}
                                    />
                                </button>
                                <div className={`${sidebarItem.visible ? 'd-block' : 'd-none'}`}>
                                    {
                                        sidebarItem.editable && onEditButtonClick && (
                                            <button
                                                className="btn p-0 ms-2 mt-0 edit-mode-element btn-edit"
                                                title={localizer.msg('common.actions.edit')} type="button"
                                                onClick={() => onEditButtonClick(sidebarItem)}>
                                                <Icon name="pencil" iconSpritePath={iconPath} fill={'#333333'}
                                                      width={20}/>
                                            </button>
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </Draggable>
    );
};
