import {ImageField} from './ImageField';
import {CommunityEditableFieldType} from './CommunityEditableFieldType';
import {CampaignEditableFieldType} from './CampaignEditableFieldType';
import {ImageType} from '../enums/ImageType';

export class TopbarLogoFieldBuilder {
    public topbarLogoField: TopbarLogoField;

    constructor() {
        this.topbarLogoField = {...TopbarLogoField.EMPTY};
    }

    name(name: CommunityEditableFieldType | CampaignEditableFieldType) {
        this.topbarLogoField.name = name;
        return this;
    }

    url(url: string) {
        this.topbarLogoField.url = url;
        return this;
    }

    fileId(fileId: number) {
        this.topbarLogoField.fileId = fileId;
        return this;
    }

    imageType(imageType: ImageType) {
        this.topbarLogoField.imageType = imageType;
        return this;
    }

    defaultImage(defaultImage: boolean) {
        this.topbarLogoField.defaultImage = defaultImage;
        return this;
    }

    filename(filename: string) {
        this.topbarLogoField.filename = filename;
        return this;
    }

    altText(altText?: string) {
        this.topbarLogoField.altText = altText;
        return this;
    }

    topbarLogoAltText(topbarLogoAltText?: string) {
        this.topbarLogoField.topbarLogoAltText = topbarLogoAltText;
        return this;
    }

    topbarLogoUrl(topbarLogoUrl: string) {
        this.topbarLogoField.topbarLogoUrl = topbarLogoUrl;
        return this;
    }

    build() {
        return this.topbarLogoField;
    }
}

export class TopbarLogoField extends ImageField {
    static EMPTY = new TopbarLogoField(CommunityEditableFieldType.UNKNOWN, '', 0, ImageType.UNKNOWN, false, '');

    constructor(
        name: CommunityEditableFieldType | CampaignEditableFieldType,
        url: string,
        fileId: number,
        imageType: ImageType,
        defaultImage: boolean,
        public topbarLogoUrl: string,
        public topbarLogoAltText?: string,
        filename?: string,
        altText?: string
    ) {
        super(name, url, fileId, imageType, defaultImage, filename, altText);
    }
}