import {useCallback} from 'react';
import {CommentType, SuperCommentResponse} from '@ideascale/commons';
import {useCommunityService} from './useService';

export const useSuperCommentsAction = () => {
    const communityService = useCommunityService();
    const fetchMoreSuperCommentResponse = useCallback(async (ideaId: number, pageNo: number) => {
        if (communityService !== null) {
            return await communityService.fetchSuperComments(ideaId, {page: pageNo, limit: 3});
        }
        return SuperCommentResponse.EMPTY;
    }, [communityService]);

    const deleteSuperComment = useCallback(async (commentId: number, commentType: CommentType) => {
        if (communityService !== null) {
            return await communityService.deleteSuperComment(commentId, commentType);
        }
    }, [communityService]);

    const reorderSuperComments = useCallback(async (ideaId: number, reorderedIds: number[]) => {
        if (communityService !== null) {
            return await communityService.reorderSuperComments(ideaId, reorderedIds);
        }
    }, [communityService]);

    return {
        fetchMoreSuperCommentResponse,
        deleteSuperComment,
        reorderSuperComments
    };
};