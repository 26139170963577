import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    AlertEvent,
    AlertType,
    buildAlertEventData,
    EncodeUtil,
    eventDispatcher,
    HtmlUtils,
    useUrlQuery
} from '@ideascale/commons';
import {CommonUtil} from "utils/CommonUtil";

export const RedirectionAlertMessage = () => {
    const query = useUrlQuery();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        CommonUtil.wait(500).then(() => {
            const queryAlert = query.get('alert');
            if (queryAlert) {
                const appAlert: { type: AlertType, message: string } = EncodeUtil.decodeToObject(queryAlert);
                if (appAlert?.type && appAlert?.message) {
                    const message = HtmlUtils.htmlToReact(appAlert.message) as JSX.Element;
                    eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData((appAlert.type.toLowerCase() as AlertType), message));
                }
                query.delete('alert');
                navigate({
                    pathname: location.pathname,
                    search: query.toString()
                }, {replace: true});
            }
        })
    }, [location, navigate, query]);

    return null;
};