export class CommentPermissions {
    static EMPTY = new CommentPermissions(false, false, false, false, false, false, false, false, false, false, false, false, false);

    constructor(
        public trackingIdea: boolean,
        public pinnedAllowed: boolean,
        public attachmentAllowed: boolean,
        public attachmentMandatory: boolean,
        public privateSubmissionAllowed: boolean,
        public emailNotificationsAllowed: boolean,
        public anonymousSubmissionAllowed: boolean,
        public defaultAnonymousSubmissionEnabled: boolean,
        public annotationAllowed: boolean,
        public labelsApplicable: boolean,
        public moderationAllowed: boolean,
        public commentApprovalRequired: boolean,
        public commentingAllowed: boolean
    ) {
    }
}