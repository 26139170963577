import {FileUploadResponse, UploadedResponse, UploadProgressCallback} from '@ideascale/commons';
import {serviceLinks} from '../ServiceLinks';
import {AppService} from '../AppService';
import {AbstractService} from '../AbstractService';
import {TopBarData} from 'models/TopBarData';
import {CommunityConfig} from 'models/CommunityConfig';
import {TosTopBarData} from 'models/TosTopBarData';

export class AppServiceImpl extends AbstractService implements AppService {

    async fetchTopBarData(): Promise<TopBarData> {
        return await this.getAndUnwrap<TopBarData>(serviceLinks.topBar(), undefined, TopBarData);
    }

    async fetchLocalizations(lang: string): Promise<Map<string, string>> {
        return await this.getAndUnwrap<Map<string, string>>(serviceLinks.localizations(lang));
    }

    async fetchCommunityConfig(): Promise<CommunityConfig> {
        return await this.getAndUnwrap<CommunityConfig>(serviceLinks.communityConfig(), {}, CommunityConfig);
    }

    async uploadFile(data: FormData, onUploadProgress: UploadProgressCallback): Promise<FileUploadResponse> {
        return await this.postAndUnwrap(serviceLinks.fileUpload(), data, {
            baseURL: '/a/',
            onUploadProgress
        });
    }

    async uploadImage(data: FormData, onUploadProgress: UploadProgressCallback): Promise<FileUploadResponse> {
        return await this.postAndUnwrap(serviceLinks.imageUpload(), data, {
            baseURL: '/a/',
            onUploadProgress
        });
    }

    async tempImageUpload(data: FormData, onUploadProgress: UploadProgressCallback): Promise<UploadedResponse> {
        return await this.postAndUnwrap(serviceLinks.tempImageUpload(), data, {
            baseURL: '/a/',
            onUploadProgress
        });
    }

    async fetchTosTopBarData(): Promise<TosTopBarData> {
        return await this.getAndUnwrap<TosTopBarData>(serviceLinks.tosTopBar(), undefined, TosTopBarData);
    }
}
