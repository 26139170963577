import React, {useCallback, useEffect, useState} from 'react';
import {ParagraphSkeleton} from '@ideascale/ui';
import {HtmlRenderer, PageTitle, useApiErrorResponseHandler} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {PageLayoutContainer} from 'containers/PageLayoutContainer';
import {useLocalizer} from 'hooks/useLocalizer';
import {useCommunityService} from 'hooks/useService';
import {PageHeader} from 'components/PageHeader';

export const MembershipTosViewPage = () => {
    const localizer = useLocalizer();
    const {communityConfig} = useAppContext();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const communityService = useCommunityService();
    const [dataFetching, setDataFetching] = useState(false);
    const [membershipTosContent, setMembershipTosContent] = useState('');

    const fetchCommunityTosContent = useCallback(async (): Promise<string> => {
        if (communityService !== null) {
            return await communityService.fetchCommunityTosContent();
        }
        return '';
    }, [communityService]);

    const getCommunityTosContent = useCallback(async () => {
        try {
            setDataFetching(true);
            const tosContent = await fetchCommunityTosContent();
            setMembershipTosContent(tosContent);
        } catch (error: any) {
            handleErrorResponse(error);
        } finally {
            setDataFetching(false);
        }
    }, [fetchCommunityTosContent, handleErrorResponse]);

    useEffect(() => {
        void getCommunityTosContent();
    }, [getCommunityTosContent]);

    return (
        <div className="membership-tos-view-page">
            <PageTitle title={`${communityConfig.name} - ${localizer.msg('page.community-terms-of-service.title')}`}/>
            <PageHeader logo={communityConfig.logo} logoAltText={communityConfig.altText}
                        hideLogo={communityConfig.hideCommunityLogo}
                        bannerImage={communityConfig.bannerImageUrl}
                        logoBgTransparent={!communityConfig.logoFrameEnabled}
                        bannerAltText={communityConfig.bannerImageAltText}
                        bannerGradientEnabled={communityConfig.bannerGradientEnabled}
                        textColor={communityConfig.bannerTitleInBlack ? 'dark' : 'light'}
                        heading={communityConfig.name}
                        headingMeta=""/>
            <PageLayoutContainer mainContent={
                <div className="tos-container">
                    <div className="card panel mb-3 shadow-none pb-2">
                        <div className="card-body py-0 membership-tos-content">
                            {
                                dataFetching ?
                                    <ParagraphSkeleton rows={25}/>
                                    :
                                    <HtmlRenderer className="tos-content" content={membershipTosContent}/>
                            }
                        </div>
                    </div>
                </div>
            }/>
        </div>
    );
};