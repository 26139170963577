import React, {useEffect, useRef} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {ActionButton, Modal, NewRichTextEditor, RichTextEditorHandler} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {isHtmlInject, useHandleFormSubmit} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useFileUploadService} from 'hooks/useFileUploadService';
import {useEditModeFormErrorHandler} from 'hooks/useEditModeFormErrorHandler';
import {WidgetField} from 'models/edit-mode/WidgetField';
import {SidebarCustomWidgetInput} from 'models/edit-mode/SidebarCustomWidgetInput';
import {CommunityEditableFieldType} from 'models/edit-mode/CommunityEditableFieldType';
import {CommunityOperationType} from 'models/edit-mode/CommunityOperationType';
import {CampaignEditableFieldType} from 'models/edit-mode/CampaignEditableFieldType';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';

type SidebarWidgetEditModalProps = {
    toggle: () => void;
    isOpen: boolean;
    widgetField: WidgetField;
    onSubmitWidgetChanges: (values: SidebarCustomWidgetInput) => void;
    placeholder: string;
}

export const SidebarWidgetEditModal = (props: SidebarWidgetEditModalProps) => {
    const {toggle, isOpen, widgetField, onSubmitWidgetChanges, placeholder} = props;
    const {communityConfig: {maxFileSizeLimit, offensiveEmojis}} = useAppContext();
    const {validationErrors} = useEditModeContext();
    const localizer = useLocalizer();
    const operationType = (() => {
        switch (widgetField.name) {
            case CommunityEditableFieldType.CUSTOM_WIDGET:
                return CommunityOperationType.CHANGE_CONTACT_WIDGET;
            case CommunityEditableFieldType.COMMUNITY_WIDGET:
                return CommunityOperationType.CHANGE_COMMUNITY_WIDGET;
            case CampaignEditableFieldType.CAMPAIGN_WIDGET_ONE:
                return CampaignOperationType.CHANGE_CAMPAIGN_WIDGET_ONE;
            case CampaignEditableFieldType.CAMPAIGN_WIDGET_TWO:
                return CampaignOperationType.CHANGE_CAMPAIGN_WIDGET_TWO;
            case CampaignEditableFieldType.CAMPAIGN_WIDGET_THREE:
                return CampaignOperationType.CHANGE_CAMPAIGN_WIDGET_THREE;
            default:
                return CampaignOperationType.UNKNOWN_OPERATION;
        }
    })();
    const {validationErrorHandler} = useEditModeFormErrorHandler({
        localizer,
        validationErrors: validationErrors.findError(operationType)
    });
    const {tempImageUpload} = useFileUploadService();
    const {
        register,
        handleSubmit,
        control,
        setError,
        formState: {errors, isSubmitting, isDirty}
    } = useForm<SidebarCustomWidgetInput>({
        defaultValues: {
            title: widgetField.title,
            content: widgetField.content
        }
    });
    const onFormSubmit = useHandleFormSubmit(handleSubmit);
    const richTextEditorNode = useRef<RichTextEditorHandler>(null);

    useEffect(() => {
        validationErrorHandler(setError);
    }, [setError, validationErrorHandler]);

    return (
        <Modal className="modal-md" isOpen={isOpen} toggle={toggle} title={widgetField.title} autoFocus={false}>
            <form className="pb-2 pb-4" onSubmit={onFormSubmit(onSubmitWidgetChanges, isSubmitting || !isDirty)}>
                <div className={`form-group mb-4 ${errors.title ? 'has-error' : ''}`}>
                    <label className="fw-bold" htmlFor="contact-widget-edit-title">
                        {localizer.msg('sidebar.custom.title')}*
                    </label>
                    <input className="form-control" type="text" id="contact-widget-edit-title"
                           maxLength={20}
                           autoFocus
                           {...register('title', {
                               validate: (value: any) => {
                                   if (!value.trim()) {
                                       return localizer.msg('common.errors.required');
                                   } else if (isHtmlInject(value)) {
                                       return localizer.msg('common.errors.html');
                                   }
                               }
                           })} />
                    {
                        errors.title &&
                        <div className="invalid-feedback d-block">
                            {errors.title.message}
                        </div>
                    }
                </div>
                <div className={`form-group mb-4 ${errors.content ? 'has-error' : ''}`}>
                    <label className="fw-bold" htmlFor="contact-widget-edit-content">
                        {localizer.msg('sidebar.custom.information')}*
                    </label>
                    <Controller
                        rules={{
                            validate: (value: any) => {
                                if (!value.trim()) {
                                    return localizer.msg('common.errors.required');
                                }
                            }
                        }}
                        control={control}
                        name="content"
                        render={({field: {onChange}}) =>
                            <NewRichTextEditor
                                id={'contact-widget-edit-content'}
                                className={'form-control h-auto'}
                                toolbar={'standard'}
                                placeholder={placeholder}
                                svgIconPath={svgIconsPath}
                                enableEmojiPicker={true}
                                offensiveEmojis={offensiveEmojis}
                                enableAtMention={false}
                                defaultValue={widgetField.content}
                                uploadImage={tempImageUpload}
                                maxFileSize={maxFileSizeLimit}
                                ref={richTextEditorNode}
                                onChange={onChange}
                            />
                        }/>
                    {
                        errors.content &&
                        <div className="invalid-feedback d-block">
                            {errors.content.message}
                        </div>
                    }
                </div>
                <ActionButton type="submit" className="float-end mb-2" loading={isSubmitting} disabled={!isDirty}
                              data-test-element-id="update">
                    {localizer.msg('sidebar.edit.update')}
                </ActionButton>
            </form>
        </Modal>
    );
};