import {useParams} from 'react-router-dom';
import {StringUtil} from '@ideascale/commons';
import {LeaderboardType} from 'models/enums/LeaderboardType';

type LeaderboardPageParams = {
    leaderboardType?: any;
}

export const useLeaderboardPageParams = (): LeaderboardType | null => {
    const {leaderboardType = ''} = useParams<LeaderboardPageParams>();

    return Object.keys(LeaderboardType).includes(StringUtil.kebabCaseToScreamingSnakeCase(leaderboardType) as any)
        ? LeaderboardType[StringUtil.kebabCaseToScreamingSnakeCase(leaderboardType) as keyof typeof LeaderboardType] : null;
};