import {useEffect, useState} from 'react';
import {ClassificationAttribute} from '@ideascale/commons';

export const useSelectionClassifications = (applicableClassifications?: ClassificationAttribute) => {
    const [selectedClassifications, setSelectedClassifications] = useState<ClassificationAttribute | undefined>(applicableClassifications);

    useEffect(() => {
        if (applicableClassifications) {
            setSelectedClassifications(applicableClassifications);
        }
    }, [applicableClassifications]);

    return {selectedClassifications, setSelectedClassifications};

};