import React from 'react';
import {ActionItemHeader} from './ActionItemHeader';
import {GroupedActionItem} from 'models/topbar-action-item/GroupedActionItem';
import {InnovationAssistantActionItem} from 'models/topbar-action-item/InnovationAssistantActionItem';

type InnovationAssistantActionItemsProps = {
    groupedActionItem: GroupedActionItem;
}

export const InnovationAssistantActionItems = (props: InnovationAssistantActionItemsProps) => {
    const {groupedActionItem} = props;

    return (
        <div className="action-item-container">
            <ActionItemHeader id="innovation-assistant-action-heading"
                              groupTitle={groupedActionItem.groupTitle}
                              seeAllLink={groupedActionItem.seeAllLink}/>
            <div className="divider"/>
            <ul className="action-item-list-container list-unstyled mx-3 mt-3"
                aria-labelledby="innovation-assistant-action-heading">
                {
                    (groupedActionItem.actionItems as InnovationAssistantActionItem[]).map((actionItem: InnovationAssistantActionItem) => (
                        <li key={actionItem.name}>
                            <a className="btn btn-link p-0 font-size-sm text-decoration-none"
                               href={actionItem.url}
                               aria-label={`${!!actionItem.count && actionItem.count} ${actionItem.name}`}>
                                <span>{actionItem.name} {actionItem.count && `[ ${actionItem.count} ]`}</span>
                            </a>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
};