import React, {Fragment, useEffect} from 'react';
import {useIdeaSubmissionService} from 'hooks/useIdeaSubmissionService';
import {useAppContext} from 'contexts/AppContext';
import {useToggleIdeaSubmissionModal} from 'hooks/useToggleIdeaSubmissionModal';
import {IdeaFormModal} from 'components/idea-submission/IdeaFormModal';
import {eventDispatcher, FEATURE_TOUR_EVENTS, FeatureTourStep} from '@ideascale/commons';
import {FEATURE_TOUR_DELAY} from 'constants/AppConstants';
import {CommonUtil} from '../utils/CommonUtil';

export const IdeaFormContainer = () => {
    const [ideaFormModalOpen, toggleByRouteParam, draftId] = useToggleIdeaSubmissionModal();
    const {communityConfig: {aiTextAssistEnabled}} = useAppContext();
    const methods = useIdeaSubmissionService();

    useEffect(() => {
        if (ideaFormModalOpen && aiTextAssistEnabled) {
            CommonUtil.wait(FEATURE_TOUR_DELAY + 100)
                .then(() => eventDispatcher.dispatch(FEATURE_TOUR_EVENTS.SET_TOUR_STEP_INDEX, FeatureTourStep.IDEA_SUBMISSION_ASSIST_ASK_HELP));
        }
    }, [aiTextAssistEnabled, ideaFormModalOpen]);

    return (
        <Fragment>
            {
                ideaFormModalOpen &&
                <IdeaFormModal
                    {...methods}
                    initialDraftIdeaId={draftId}
                    open={ideaFormModalOpen}
                    toggle={toggleByRouteParam}
                    aiAssistanceEnabled={aiTextAssistEnabled}
                    defaultMode={draftId ? 'EDIT_DRAFT' : 'CREATE_IDEA'}
                />
            }
        </Fragment>
    );
};