import React, {useRef} from 'react';
import {PopoverBody, UncontrolledPopover} from 'reactstrap';
import parse from 'html-react-parser';
import {Icon} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {Localizer} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import 'components/edit-mode/LicenseUpgradeInfo.scss';

type LicenseUpgradeInfoProps = {
    localizer: Localizer;
    text?: string;
    color?: 'primary' | 'warning';
    className?: string;
    showIconOnly?: boolean;
    triggerButtonId: string;
}

export const LicenseUpgradeInfo = (props: LicenseUpgradeInfoProps) => {
    const {
        text,
        className,
        localizer,
        triggerButtonId,
        color = 'primary',
        showIconOnly = false
    } = props;
    const containerRef = useRef(null);
    const {communityConfig: {upgradePlanUrl}} = useAppContext();

    return (
        <div className="d-inline-flex align-items-center" ref={containerRef} aria-live="assertive">
            <button
                className={`${className ? className : ''} ${showIconOnly ? 'btn-license-icon p-0 rounded-circle' : `btn-outline btn-license py-1 px-2 btn ${color}`} font-size-sm`}
                id={triggerButtonId} type="button">
                <Icon className={`${showIconOnly ? 'position-absolute' : 'position-relative pos-top-n1 me-1'} ${color}`}
                      name="arrow-up"
                      iconSpritePath={svgIconPath}
                      width={showIconOnly ? 15 : 13}
                      height={showIconOnly ? 15 : 13}/>
                <span className={`${showIconOnly ? 'sr-only' : ''}`}>
                    {text || localizer.msg('common.license-upgrade.button-text')}
                </span>
            </button>
            <UncontrolledPopover className="license-upgrade-popover"
                                 placement="bottom"
                                 trigger="legacy"
                                 boundariesElement="clippingParents"
                                 target={triggerButtonId}
                                 container={containerRef}>
                <PopoverBody className="popover-license-details">
                    <div>
                        <div className="d-flex">
                            <div className="d-flex justify-content-center me-2 license-icon">
                                <div className="rounded-circle icon-container">
                                    <Icon name="arrow-up" iconSpritePath={svgIconPath} width={17} height={17}/>
                                </div>
                            </div>
                            <div className="">
                                <h6 className="fw-bold font-size-normal">
                                    {localizer.msg('common.license-upgrade.info-title')}
                                </h6>
                                <span className="text-gray font-size-sm">
                                    {localizer.msg('common.license-upgrade.info-subtitle')}
                                </span>
                            </div>
                        </div>
                        <hr className="separator w-100"/>
                        <div className="d-flex justify-content-center fw-bold">
                            {parse(upgradePlanUrl)}
                        </div>
                    </div>
                </PopoverBody>
            </UncontrolledPopover>
        </div>
    );
};
