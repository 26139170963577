import React, {Fragment} from 'react';
import {CLASSIFICATION_NAME, ClassificationLabel, ClassificationUtil, TimeAgo} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {ActivityIcon} from './ActivityIcon';
import {VoteActivity} from './activities/VoteActivity';
import {IdeaActivity} from './activities/IdeaActivity';
import {StageActionActivity} from './activities/StageActionActivity';
import {CommentActivity} from './activities/CommentActivity';
import {ModifyActivity} from './activities/ModifyActivity';
import {LinkActivity} from './activities/LinkActivity';
import {ContributorActivity} from './activities/ContributorActivity';
import {TagActivity} from './activities/TagActivity';
import {LabelActivity} from './activities/LabelActivity';
import {CampaignChangeActivity} from './activities/CampaignChangeActivity';
import {StageChangeActivity} from './activities/StageChangeActivity';
import {IdeaMergeActivity} from './activities/IdeaMergeActivity';
import {KudoActivity} from './activities/KudoActivity';
import {RecycleBinActivity} from './activities/RecycleBinActivity';
import {ActionType} from 'models/enums/ActionType';
import {ActivityStreamData} from 'models/ActivityStreamData';


type ActivityListProps = {
    activities: ActivityStreamData[];
}

export const ActivityList = (props: ActivityListProps) => {
    const {activities} = props;
    const localizer = useLocalizer();
    const {communityConfig} = useAppContext();

    const renderActivity = (activity: ActivityStreamData) => {
        switch (activity.action) {
            case ActionType.VOTE_POSITIVE:
            case ActionType.VOTE_NEGATIVE:
            case ActionType.VOTE_POSITIVE_RETRACT:
            case ActionType.VOTE_NEGATIVE_RETRACT:
                return <VoteActivity action={activity.action} activity={activity} localizer={localizer}/>;
            case ActionType.IDEA:
            case ActionType.IDEA_REMOVE:
            case ActionType.MEMBER_FOLLOW_IDEA:
            case ActionType.MEMBER_UN_FOLLOW_IDEA:
                return <IdeaActivity activity={activity} localizer={localizer} action={activity.action}/>;
            case ActionType.MODIFY:
                return <ModifyActivity activity={activity} localizer={localizer} action={activity.action}/>;
            case ActionType.IDEA_CAMPAIGN_CHANGE:
                return <CampaignChangeActivity activity={activity} localizer={localizer} action={activity.action}/>;
            case ActionType.LINKED_IDEA:
            case ActionType.UNLINKED_IDEA:
                return <LinkActivity action={activity.action} activity={activity} localizer={localizer}/>;
            case ActionType.COMMENT:
            case ActionType.COMMENT_REMOVE:
                return <CommentActivity action={activity.action} localizer={localizer} activity={activity}/>;
            case ActionType.CONTRIBUTOR_ADDED:
            case ActionType.CONTRIBUTOR_REMOVED:
                return <ContributorActivity activity={activity} localizer={localizer} action={activity.action}/>;
            case ActionType.TAG_REMOVED:
            case ActionType.TAG_ADDED:
                return <TagActivity activity={activity} localizer={localizer} action={activity.action}/>;
            case ActionType.MEMBER_ASSESSMENT_COMPLETE:
            case ActionType.MEMBER_REVIEW_COMPLETE:
            case ActionType.MEMBER_ESTIMATE_ROI:
            case ActionType.MEMBER_FUND_PLEDGE:
            case ActionType.MEMBER_REFINEMENT_COMPLETE:
                return <StageActionActivity localizer={localizer} action={activity.action} activity={activity}/>;
            case ActionType.CONTRIBUTION_LABEL_ADDED:
            case ActionType.CONTRIBUTION_LABEL_REMOVED:
                return <LabelActivity activity={activity} localizer={localizer} action={activity.action}/>;
            case ActionType.IDEA_STATUS_CHANGE:
            case ActionType.IDEA_APPROVE:
                return <StageChangeActivity activity={activity} localizer={localizer} action={activity.action}/>;
            case ActionType.IDEA_MERGE:
                return <IdeaMergeActivity activity={activity} localizer={localizer} action={activity.action}/>;
            case ActionType.IDEA_KUDO:
            case ActionType.COMMENT_KUDO:
                return <KudoActivity activity={activity} localizer={localizer} action={activity.action}/>;
            case ActionType.IDEA_RECYCLE_BIN:
            case ActionType.COMMENT_RECYCLE_BIN:
                return <RecycleBinActivity activity={activity} localizer={localizer} action={activity.action}/>;
        }
    };

    return (
        <Fragment>
            {
                activities.map((activity, index) =>
                    <li className="py-3 d-flex align-items-center" key={index}>
                        <ActivityIcon activity={activity}/>
                        <span>
                            {communityConfig.classificationEnabled && activity.idea.attributes &&
                            <ClassificationLabel
                                classification={ClassificationUtil.getAttributeByName(CLASSIFICATION_NAME.EFFECTIVE_CLASSIFICATION, activity.idea.attributes)}
                                extraClasses="me-2 text-nowrap"/>
                            }
                            {renderActivity(activity)}{' '}
                            <span className="text-muted"><TimeAgo localizer={localizer}
                                                                  dateISOString={activity.createdAt}/></span>
                        </span>
                    </li>
                )
            }
        </Fragment>
    );
};