import React, {Fragment} from 'react';
import orderBy from 'lodash/orderBy';
import {Avatar} from '@ideascale/ui';
import {Localizer, MemberLink} from '@ideascale/commons';
import {RatingView} from 'components/stage-activities/RatingView';
import {AssessmentStageViewActivity} from 'models/stage-activity/AssessmentStageViewActivity';

type AssessmentViewActivityProps = {
    localizer: Localizer;
    activity: AssessmentStageViewActivity;
}

export const AssessmentViewActivity = (props: AssessmentViewActivityProps) => {
    const {localizer, activity} = props;

    return (
        <div className="assessment-report">
            {
                activity.viewOverallRating &&
                <ul className="list-unstyled font-size-md border-bottom-0 rounded">
                    <li className="fw-bold">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                {localizer.msg('stage.assessment.overall-rating')}: {Number(activity.averageRating).toFixed(2)}
                            </div>
                            <div className="col-6 col-lg-6">
                                <div className="assessment-rated five-stars">
                                    <RatingView localizer={localizer} rating={activity.averageRating} zeroStarsAsText={false}/>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <ol className="list-unstyled">
                            {
                                orderBy(activity.assessmentResults, ['averageRating'], ['desc']).map((avgResult, index) =>
                                    !(avgResult.memberAssessmentResults.length === 0 && avgResult.averageRating === 0) &&
                                    <li key={index}>
                                        <div className="row">
                                            <div className="col-12 col-lg-6">
                                                {avgResult.question}
                                            </div>
                                            <div className="col-6 col-lg-6">
                                                <div className="assessment-rated five-stars">
                                                    <RatingView localizer={localizer} rating={avgResult.averageRating} zeroStarsAsText={false}/>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                )
                            }
                        </ol>
                    </li>
                </ul>
            }
            {
                activity.assessmentResults.some(assessmentResult => assessmentResult.memberAssessmentResults.length > 0) &&
                <ul className="list-unstyled font-size-md rounded">
                    {
                        activity.assessmentResults.map(result =>

                            <Fragment key={result.questionId}>
                                <li className="fw-bold">{result.question}</li>
                                {
                                    result.memberAssessmentResults.map(memberResult =>
                                        <li key={memberResult.member.id}>
                                            <div className="row">
                                                <div className="col-12 col-lg-6">
                                                    <div>
                                                        <Avatar className="me-3"
                                                                src={memberResult.member.avatar} size="md"
                                                                alt={memberResult.member.username ? localizer.msg('common.user-avatar', {username: memberResult.member.username}) : ''}/>
                                                        <MemberLink className="fw-bold emphasize"
                                                                    id={memberResult.member.id}
                                                                    identityHidden={memberResult.member.identityHidden}>
                                                            {memberResult.member.name}
                                                        </MemberLink>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-lg-6 assessment-rated">
                                                    <div className="assessment-rated five-stars">
                                                        <RatingView localizer={localizer} rating={memberResult.rating} zeroStarsAsText/>
                                                    </div>
                                                    <p>{memberResult.note}</p>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                }
                            </Fragment>
                        )
                    }
                </ul>
            }
        </div>
    );
};