import React from 'react';
import {Avatar, Icon} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {ActivityStreamData} from 'models/ActivityStreamData';
import {ActionType} from 'models/enums/ActionType';
import './ActivityIcon.scss';

type ActivityIconProps = {
    activity: ActivityStreamData;
}

export const ActivityIcon = (props: ActivityIconProps) => {
    const {activity} = props;

    const getIconClass = () => {
        switch (activity.action) {
            case ActionType.COMMENT:
            case ActionType.COMMENT_REMOVE:
                return 'comment-activity';
            case ActionType.TAG_ADDED:
            case ActionType.TAG_REMOVED:
                return 'tag-activity';
            case ActionType.IDEA_STATUS_CHANGE:
            case ActionType.IDEA_APPROVE:
            case ActionType.IDEA_RECYCLE_BIN:
            case ActionType.COMMENT_RECYCLE_BIN:
                return 'stage-change-activity';
            case ActionType.VOTE_NEGATIVE:
            case ActionType.VOTE_POSITIVE:
            case ActionType.VOTE_POSITIVE_RETRACT:
            case ActionType.VOTE_NEGATIVE_RETRACT:
                return 'vote-activity';
            case ActionType.MODIFY:
                return 'modify-activity';
            case ActionType.IDEA_CAMPAIGN_CHANGE:
                return 'campaign-activity';
            case ActionType.IDEA_MERGE:
                return 'merge-activity';
            case ActionType.IDEA:
            case ActionType.IDEA_REMOVE:
            case ActionType.CONTRIBUTOR_ADDED:
            case ActionType.CONTRIBUTOR_REMOVED:
            case ActionType.MEMBER_FOLLOW_IDEA:
            case ActionType.MEMBER_UN_FOLLOW_IDEA:
            case ActionType.CONTRIBUTION_LABEL_ADDED:
            case ActionType.CONTRIBUTION_LABEL_REMOVED:
                return 'idea-activity';
            case ActionType.LINKED_IDEA:
            case ActionType.UNLINKED_IDEA:
                return 'link-activity';
            case ActionType.MEMBER_REFINEMENT_COMPLETE:
                return 'refine-activity';
            case ActionType.MEMBER_ESTIMATE_ROI:
                return 'estimate-activity';
            case ActionType.MEMBER_FUND_PLEDGE:
                return 'fund-activity';
            case ActionType.MEMBER_REVIEW_COMPLETE:
                return 'reviewscale-activity';
            case ActionType.MEMBER_ASSESSMENT_COMPLETE:
                return 'assessment-activity';
            case ActionType.IDEA_KUDO:
            case ActionType.COMMENT_KUDO:
                return 'kudo-activity';
        }
    };

    return (
        <span className={`circle-icon-bg me-2 align-self-start ${getIconClass()}`}>
            {activity.activityIcon.isSvg()
                ? <Icon iconSpritePath={svgIconsPath} name={activity.activityIcon.getSvgName()}
                        title={activity.activityIcon.alt}/>
                : activity.action === ActionType.IDEA_CAMPAIGN_CHANGE
                    ? <img src={activity.activityIcon.getUrl()} alt={activity.activityIcon.alt} className="rounded"/>
                    : <Avatar src={activity.activityIcon.getUrl()} alt={activity.activityIcon.alt}/>
            }
        </span>
    );
};