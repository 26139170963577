import {CommunityEditableFieldType} from './CommunityEditableFieldType';
import {CampaignEditableFieldType} from './CampaignEditableFieldType';
import {BaseConfigField} from './BaseConfigField';
import {PermissionType} from './ResourcePermission';

export class ConfigField extends BaseConfigField {
    public static EMPTY = new ConfigField(CommunityEditableFieldType.UNKNOWN, '', PermissionType.ALLOWED);

    constructor(
        name: CommunityEditableFieldType | CampaignEditableFieldType,
        public value: string,
        public editPermission?: PermissionType
    ) {
        super(name);
    }
}
