import React, {FormEvent, useCallback, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle} from '@ideascale/ui';
import {
    Campaign,
    CampaignsHolder,
    LabelData,
    TagSearchParameters,
    useIsMounted,
    useToggle,
    useUrlQuery
} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {SearchState, useSearchContext} from 'contexts/SearchContext';
import {useIdeasContext} from 'contexts/IdeasContext';
import {useAppContext} from 'contexts/AppContext';
import {useCommunityService} from 'hooks/useService';
import {useHtmlOrJavascriptInjectionAlert} from 'hooks/useHtmlOrJavascriptInjectionAlert';
import {AllSearchFilter} from 'components/search/AllSearchFilter';
import {CampaignsSearchFilter} from 'components/search/CampaignsSearchFilter';
import {MemberSearchFilter} from 'components/search/MemberSearchFilter';
import {IdeaSearchFilter} from 'components/search/IdeaSearchFilter';
import {ROUTES} from 'shared/Routes';
import {SearchCategory} from 'models/enums/SearchCategory';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {StagesHolder} from 'models/StagesHolder';
import {StageGroup} from 'models/StageGroup';
import {CustomFieldData} from 'models/CustomFieldData';
import styles from './SearchFilterContainer.module.scss';

export const SearchFilterContainer = () => {
    const [open, toggle] = useToggle(false);
    const {authentication: {actor}} = useAppContext();
    const {state}: any = useLocation();
    const navigate = useNavigate();
    const localizer = useLocalizer();
    const communityService = useCommunityService();
    const {clearIdeaListCachedData} = useIdeasContext();
    const {isHtmlOrJavascriptInjection} = useHtmlOrJavascriptInjectionAlert();
    const urlQuery = useUrlQuery();
    const isMounted = useIsMounted();
    const [selectedItem, setSelectedItem] = useState<SearchCategory>(SearchCategory.ALL);
    const {
        setSearchCategory,
        updateFilters,
        clearFilters
    } = useSearchContext();

    const [searchInputValue, setSearchInputValue] = useState<string>('');

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    const clearAllFilters = () => {
        clearFilters();
        setSearchInputValue('');
    };

    const applyFilterParameters = useCallback((params: Partial<SearchState>) => {
        if (!isHtmlOrJavascriptInjection(searchInputValue)) {
            clearIdeaListCachedData();
            setSearchCategory(selectedItem);
            params.term = searchInputValue;
            updateFilters(params);
        }
    }, [isHtmlOrJavascriptInjection, clearIdeaListCachedData, searchInputValue, selectedItem, setSearchCategory, updateFilters]);

    const onChangeCategory = (category: SearchCategory) => {
        setSelectedItem(category);
    };

    const fetchLabels = useCallback(async (pageParameters: PageParameters): Promise<PagedResponseContent<LabelData>> => {
        if (communityService !== null) {
            return await communityService.searchLabels(pageParameters);
        }
        return PagedResponseContent.nullObject<LabelData>();
    }, [communityService]);

    const fetchStages = useCallback(async (pageParameters: PageParameters): Promise<PagedResponseContent<StagesHolder<StageGroup>>> => {
        if (communityService !== null) {
            return await communityService.searchStages(pageParameters);
        }
        return PagedResponseContent.nullObject<StagesHolder<StageGroup>>();
    }, [communityService]);

    const fetchTags = useCallback(async (pageParameters: TagSearchParameters): Promise<PagedResponseContent<string>> => {
        if (communityService !== null) {
            return await communityService.fetchAdvancedSearchTags(pageParameters);
        }
        return PagedResponseContent.nullObject<string>();
    }, [communityService]);

    const fetchModeratorTags = useCallback(async (pageParameters: PageParameters): Promise<PagedResponseContent<string>> => {
        if (communityService !== null) {
            return await communityService.fetchAdvancedSearchModeratorTags(pageParameters);
        }
        return PagedResponseContent.nullObject<string>();
    }, [communityService]);

    const fetchCampaigns = useCallback(async (pageParameters: PageParameters): Promise<PagedResponseContent<CampaignsHolder<Campaign>>> => {
        if (communityService !== null) {
            return await communityService.fetchTopBarCampaigns(pageParameters);
        }
        return PagedResponseContent.nullObject<CampaignsHolder<Campaign>>();
    }, [communityService]);

    const fetchIdeasCustomFields = useCallback(async (pageParameters: PageParameters, campaignIds?: number, includeArchivedCampaigns?: boolean): Promise<PagedResponseContent<StageGroup>> => {
        if (communityService !== null) {
            return await communityService.fetchIdeasCustomFields(pageParameters, campaignIds, includeArchivedCampaigns);
        }
        return PagedResponseContent.nullObject<StageGroup>();
    }, [communityService]);

    const fetchDependantCustomFields = useCallback(async (fieldId: number): Promise<CustomFieldData> => {
        if (communityService !== null) {
            return await communityService.fetchDependantCustomFields(fieldId);
        }
        return CustomFieldData.EMPTY;
    }, [communityService]);

    const fetchAdvanceSearchProfileFields = useCallback(async (pageParameters: PageParameters): Promise<PagedResponseContent<StageGroup>> => {
        if (communityService !== null) {
            return await communityService.fetchAdvanceSearchProfileFields(pageParameters);
        }
        return PagedResponseContent.nullObject<StageGroup>();
    }, [communityService]);

    const fetchDependantProfileFields = useCallback(async (fieldId: number): Promise<CustomFieldData> => {
        if (communityService !== null) {
            return await communityService.fetchDependantProfileFields(fieldId);
        }
        return CustomFieldData.EMPTY;
    }, [communityService]);

    useEffect(() => {
        if (isMounted() && urlQuery.has(ROUTES.QUERY_PARAMS.ADVANCED_SEARCH_QUERY) && urlQuery.get(ROUTES.QUERY_PARAMS.ADVANCED_SEARCH_QUERY) && state && state.searchApply) {
            const searchText = urlQuery.get(ROUTES.QUERY_PARAMS.ADVANCED_SEARCH_QUERY) as string;
            const newUrlQuery = new URLSearchParams(urlQuery);
            setSearchInputValue(searchText);
            updateFilters({term: searchText, archiveCampaign: true});
            searchText && newUrlQuery.set(ROUTES.QUERY_PARAMS.ADVANCED_SEARCH_QUERY, searchText);
            navigate({search: newUrlQuery.toString()}, {state: null, replace: true});
        } else if (isMounted() && !urlQuery.has(ROUTES.QUERY_PARAMS.ADVANCED_SEARCH_QUERY) && state && state.searchApply) {
            const newUrlQuery = new URLSearchParams(urlQuery);
            setSearchInputValue('');
            updateFilters({term: '', archiveCampaign: true});
            navigate({search: newUrlQuery.toString()}, {state: null, replace: true});
        }
    }, [navigate, isMounted, state, updateFilters, urlQuery]);

    return (
        <section className="card panel panel-default">
            <div className="card-body">
                <form id="search-form" onSubmit={onSubmit}>
                    <div className="form-group">
                        <div className="input-group">
                            <label className="sr-only" htmlFor="all-search">
                                {localizer.msg('search.placeholder.search')}
                            </label>
                            <input className={`form-control ${styles.searchInput} rounded-start`}
                                   type="text" data-test-element-id="all-search" id="all-search"
                                   placeholder={localizer.msg('search.placeholder.search')}
                                   onChange={(e) => setSearchInputValue(e.target.value)}
                                   value={searchInputValue}/>
                            {
                                searchInputValue.trim() &&
                                <button
                                    className={`${styles.btnSearchClear} btn rounded-0 border-0 me-n1 line-height-0 fw-normal z-index-4 pt-1`}
                                    type="button"
                                    onClick={() => {
                                        setSearchInputValue('');
                                    }}
                                    title={localizer.msg('search.clear-search-text')}>
                                    <span className="line-height-0 d-inline-block" aria-hidden="true">&times;</span>
                                </button>
                            }

                            <ButtonDropdown isOpen={open} toggle={toggle} className="text-start">
                                <DropdownToggle className={`btn btn-gray text-start shadow-none rounded-start-0 ${styles.btnSearchCategory} text-truncate`}
                                                tag="button" caret type="button" aria-label={`${localizer.msg('search.search-filter')}-${selectedItem}`}>
                                    {selectedItem}
                                </DropdownToggle>
                                <DropdownMenu className={styles.searchCategoryDropdown} end>
                                    {
                                        Object.values(SearchCategory).map(category => {
                                            return actor.isCommunityMember() && category === SearchCategory.MEMBERS
                                                ? null
                                                : (
                                                    <DropdownItem key={category}
                                                                  className={selectedItem === category ? 'active' : ''}
                                                                  onClick={() => onChangeCategory(category)}>
                                                        {localizer.msg(`search.filter.${category.toLowerCase()}`)}
                                                    </DropdownItem>
                                                );
                                        })
                                    }
                                </DropdownMenu>
                            </ButtonDropdown>
                        </div>
                    </div>
                    <div className={`border-top-1 pt-3 ${styles.advanceSearchField}`}>
                        {selectedItem === SearchCategory.ALL &&
                        <AllSearchFilter localizer={localizer}
                                         onApplyFilters={applyFilterParameters}
                                         onClearFilters={clearAllFilters}
                                         fetchDependantCustomFields={fetchDependantCustomFields}
                                         fetchIdeasCustomFields={fetchIdeasCustomFields}
                                         fetchLabels={fetchLabels} fetchStages={fetchStages} fetchTags={fetchTags}
                                         fetchModeratorTags={fetchModeratorTags}/>
                        }
                        {selectedItem === SearchCategory.CAMPAIGN &&
                        <CampaignsSearchFilter
                            localizer={localizer}
                            onApplyFilters={applyFilterParameters}
                            onClearFilters={clearAllFilters}
                        />
                        }
                        {selectedItem === SearchCategory.IDEAS &&
                        <IdeaSearchFilter localizer={localizer}
                                          onApplyFilters={applyFilterParameters}
                                          onClearFilters={clearAllFilters}
                                          fetchCampaigns={fetchCampaigns}
                                          fetchIdeasCustomFields={fetchIdeasCustomFields}
                                          fetchDependantCustomFields={fetchDependantCustomFields}
                                          fetchTags={fetchTags}
                                          fetchAdvanceSearchProfileFields={fetchAdvanceSearchProfileFields}
                                          fetchDependantProfileFields={fetchDependantProfileFields}
                                          fetchLabels={fetchLabels} fetchStages={fetchStages}
                                          fetchModeratorTags={fetchModeratorTags}/>
                        }
                        {selectedItem === SearchCategory.MEMBERS &&
                        <MemberSearchFilter
                            localizer={localizer}
                            fetchAdvanceSearchProfileFields={fetchAdvanceSearchProfileFields}
                            fetchDependantProfileFields={fetchDependantProfileFields}
                            onApplyFilters={applyFilterParameters}
                            onClearFilters={clearAllFilters}
                        />
                        }
                    </div>
                </form>
            </div>
        </section>
    );
};
