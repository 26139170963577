import {CampaignsHolder} from '@ideascale/commons';
import {PagedResponseContent} from './PagedResponseContent';
import {CampaignGroup} from './CampaignGroup';
import {SidebarCampaign} from './SidebarCampaign';

export class CampaignModuleContent {
    static readonly EMPTY = new CampaignModuleContent(PagedResponseContent.nullObject(), CampaignsHolder.EMPTY, false);

    campaignGroups: PagedResponseContent<CampaignGroup>;
    campaignsHolder: CampaignsHolder<PagedResponseContent<SidebarCampaign>>;
    manageCampaign: boolean;

    constructor(campaignGroups: PagedResponseContent<CampaignGroup>, campaignsHolder: CampaignsHolder<PagedResponseContent<SidebarCampaign>>,  manageCampaign: boolean) {
        this.campaignGroups = campaignGroups;
        this.campaignsHolder = campaignsHolder;
        this.manageCampaign = manageCampaign;
    }
}