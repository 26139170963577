import React from 'react';
import {DatePicker, Icon} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {Localizer} from '@ideascale/commons';
import {SearchInputGroup} from 'shared/SearchInputGroup';

const DATE_PICKER_SINGLE_MONTH_BREAKPOINT = 700;

type TimeFrameSearchFieldProps = {
    localizer: Localizer;
    dates: Date[]
    onChangeDate: (dates: Date[]) => void;
};

export const TimeFrameSearchField = (props: TimeFrameSearchFieldProps) => {
    const {localizer, dates: [start, end], onChangeDate} = props;

    return (
        <div className="form-group">
            <label className="fw-bold" htmlFor="time-frame">
                {localizer.msg('search.input-label.timeframe')}
            </label>
            <SearchInputGroup groupText={
                <Icon name="calendar" fill="#666" height={27} width={18} iconSpritePath={svgIconsPath}/>
            }>
                <DatePicker svgIconsPath={svgIconsPath}
                            wrapperClassName="w-100"
                            className="form-control timeframe-input ps-1 border-start-0"
                            data-test-element-id="time-frame-search"
                            id="time-frame"
                            placeholderText={localizer.msg('search.placeholder.timeframe')}
                            selectsRange={true}
                            monthsShown={window.innerWidth < DATE_PICKER_SINGLE_MONTH_BREAKPOINT ? 1 : 2}
                            dateFormat="dd-MMM-yyyy"
                            onChange={onChangeDate}
                            startDate={start || null}
                            endDate={end || null}
                            prevMonthLabel={localizer.msg('frontend-shared.common.date.prev-month')}
                            nextMonthLabel={localizer.msg('frontend-shared.common.date.next-month')}
                            prevYearLabel={localizer.msg('frontend-shared.common.date.prev-year')}
                            nextYearLabel={localizer.msg('frontend-shared.common.date.next-year')}/>
            </SearchInputGroup>
        </div>
    );
};
