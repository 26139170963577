import {UncontrolledTooltip} from 'reactstrap';
import range from 'lodash/range';
import {Icon} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useLocalizer} from 'hooks/useLocalizer';
import './Ratings.scss';
import {KEYBOARD_KEYS} from '@ideascale/commons';

type RatingProps = {
    inputId: string | number;
    value: number;
    hints: { rating: number, hint?: string }[];
    onChange: (value: number) => void;
}

const iconSizeProps = {iconSpritePath: svgIconsPath, width: 22, height: 22, fill: '#384EC1'};

const hasValue = (value: number) => {
    return value > -1;
};

export const RatingEdit = (props: RatingProps) => {
    const {inputId, value, onChange, hints} = props;
    const localizer = useLocalizer();

    return (
        <div className={`rating d-inline ${hasValue(value) ? 'selected' : ''}`} id={`rating-${inputId}`}>
            <ul className="list-unstyled rating-list">
                {
                    range(5, 0, -1).map(starCount => {
                        const id = `rating-tooltip-${inputId}-${starCount}`;
                        const hintText = hints.find(hint => hint.rating === starCount)?.hint || '';
                        return (
                            <li key={starCount} role="button"
                                aria-label={localizer.msg('stage.assessment.star', {count: starCount})}
                                id={id} tabIndex={0}
                                className={value === starCount ? 'rated' : ''}
                                onClick={() => onChange(starCount)}
                                onKeyUp={event => {
                                    if (event.key === KEYBOARD_KEYS.ENTER) {
                                        onChange(starCount);
                                    }
                                }}>
                                {
                                    hintText &&
                                    <UncontrolledTooltip placement="top" target={id}>
                                        {hintText}
                                    </UncontrolledTooltip>
                                }
                                <Icon className="icon-star-full" name="star-solid" {...iconSizeProps} />
                                <Icon className="icon-star-empty" name="star-outlined" {...iconSizeProps} />
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    );
};