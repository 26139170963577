import {EmailRequest, PrivateMessageRequestParams} from '@ideascale/commons';
import {useDigestService} from 'hooks/useDigestService';
import {useMessageService} from 'hooks/useMessageService';
import {useCampaignModuleService} from 'hooks/useCampaignModuleService';
import {CampaignPerformanceParameters} from 'models/CampaignPerformanceParameters';
import {DigestInternalShareRequestParams} from 'models/DigestInternalShareRequestParams';
import {DigestEmailRequestParams} from 'models/DigestEmailRequestParams';
import {PageParameters} from 'models/types/PageParameters';

export const useDigestShare = () => {
    const {onDigestSendMessages} = useMessageService();
    const {sendDigestEmailViaRecipientEmails, sendDigestEmailViaRecipientIds} = useDigestService();
    const {fetchRecipientsByCampaign} = useCampaignModuleService();

    const digestShareMessageSend = (requestParams: PrivateMessageRequestParams, campaignId: number | undefined, year: string, month: string | undefined, week: string | undefined) => {
        const sendMessageRequestPayload = new DigestInternalShareRequestParams(
            requestParams.recipientIds,
            requestParams.subject,
            requestParams.body,
            campaignId,
            new CampaignPerformanceParameters(
                year,
                month || '',
                week || ''
            ),
            requestParams.attributeApiParameters
        );
        return onDigestSendMessages(sendMessageRequestPayload);
    };

    const digestEmailSendViaRecipientEmails = (requestParams: EmailRequest, campaignId: number, year: string, month: string | undefined, week: string | undefined) => {
        const emailRequestParams = DigestEmailRequestParams.builder()
            .subject(requestParams.subject)
            .message(requestParams.message)
            .recipientEmails(requestParams.recipientEmails)
            .campaignPerformanceParameters(
                new CampaignPerformanceParameters(
                    year,
                    month || '',
                    week || ''
                ))
            .attributeApiParameters(requestParams.attributeApiParameters)
            .build();
        return sendDigestEmailViaRecipientEmails(emailRequestParams, campaignId);
    };

    const digestEmailSendViaRecipientIds = (requestParams: EmailRequest, campaignId: number, year: string, month: string | undefined, week: string | undefined) => {
        const emailRequestParams = DigestEmailRequestParams.builder()
            .subject(requestParams.subject)
            .message(requestParams.message)
            .recipientIds(requestParams.recipientIds)
            .campaignPerformanceParameters(
                new CampaignPerformanceParameters(
                    year,
                    month || '',
                    week || ''
                ))
            .attributeApiParameters(requestParams.attributeApiParameters)
            .build();
        return sendDigestEmailViaRecipientIds(emailRequestParams, campaignId);
    };

    const getDigestEmailRecipients = (parameters: PageParameters, campaignId: number) => {
        return fetchRecipientsByCampaign(parameters, campaignId);
    };

    return {
        digestShareMessageSend,
        digestEmailSendViaRecipientEmails,
        digestEmailSendViaRecipientIds,
        getDigestEmailRecipients
    };
};