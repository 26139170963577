import React, {useEffect} from 'react';
import {FieldValues, useForm} from 'react-hook-form';
import {useQuery} from 'react-query';
import {
    eventDispatcher,
    HookFormProvider,
    reloadPage,
    ServiceError,
    useApiErrorResponseHandler,
    useHandleFormSubmit,
    useToggle,
    useUrlQuery
} from '@ideascale/commons';
import {ActionButton, Modal, Spinner} from '@ideascale/ui';
import loadingSvg from '@ideascale/ui/dist/assets/loading.svg';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useJoinCommunityService} from 'hooks/useJoinCommunityService';
import {usePrepareCustomFields} from 'hooks/usePrepareCustomFields';
import {SHOW_JOIN_COMMUNITY_PROMPT} from 'constants/AppConstants';
import {ROUTES} from 'shared/Routes';
import {CustomFieldRequestValue} from 'models/types/ProfileFieldUpdateRequest';
import {ProfileFieldData} from 'models/ProfileFieldData';

const FORM_ID = 'join-community-questions-form';

export const JoinCommunityPrompt = () => {
    const localizer = useLocalizer();
    const query = useUrlQuery();
    const {authentication} = useAppContext();
    const {fetchJoinCommunityFields, joinCommunity} = useJoinCommunityService();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const {renderCustomField, getFieldValues, prepareFieldTypeMapping} = usePrepareCustomFields();
    const [openPrompt, togglePrompt] = useToggle(false);
    const methods = useForm();
    const {handleSubmit, setError, formState: {isSubmitting}} = methods;
    const onFormSubmit = useHandleFormSubmit(handleSubmit);

    const {data: memberFields, isLoading} = useQuery('join-community', fetchJoinCommunityFields, {
        refetchOnWindowFocus: false,
        onSuccess: (profileFields: ProfileFieldData[]) => {
            prepareFieldTypeMapping(profileFields);
        },
        onError: (error: ServiceError) => {
            handleErrorResponse(error, {
                setFormError: setError
            });
        }
    });

    const onSubmit = async (data: FieldValues) => {
        let fieldValues: CustomFieldRequestValue[] = getFieldValues(data);

        try {
            await joinCommunity(fieldValues);
            togglePrompt();
            reloadPage();
        } catch (error: any) {
            handleErrorResponse(error, {
                setFormError: setError
            });
        }
    };

    useEffect(() => {
        eventDispatcher.addListener(SHOW_JOIN_COMMUNITY_PROMPT, () => {
            togglePrompt(true);
        });

        return () => {
            eventDispatcher.removeListener(SHOW_JOIN_COMMUNITY_PROMPT);
            togglePrompt(false);
        };
    }, [togglePrompt]);

    useEffect(() => {
        if (query.get(ROUTES.QUERY_PARAMS.SUBMISSION_FORM) === 'open' && authentication.isAuthenticated() && authentication.isJoinCommunityAvailable()) {
            eventDispatcher.dispatch(SHOW_JOIN_COMMUNITY_PROMPT);
        }
    }, [authentication, query]);

    return (
        <Modal isOpen={openPrompt} toggle={authentication.community.isPubicCommunity() ? togglePrompt : undefined}
               title={localizer.msg('join-community.title')}
               modalHeaderId="join-community-header" aria-labelledby="join-community-header" autoFocus={false}>
            <span>{localizer.msg('join-community.description')}</span>
            {
                isLoading
                    ? (
                        <div className="text-center my-4">
                            <Spinner src={loadingSvg} size={50}/>
                        </div>
                    )
                    : (
                        <HookFormProvider {...methods}>
                            <form id={FORM_ID} onSubmit={onFormSubmit(onSubmit, isSubmitting)}>
                                {
                                    memberFields && memberFields.length > 0
                                        ? (
                                            <>
                                                <div className="alert alert-warning my-3">
                                                    {localizer.msg('join-community.profile-incomplete')}
                                                </div>
                                                {
                                                    memberFields.map((field, index) => renderCustomField(field, index))
                                                }
                                            </>
                                        )
                                        : null
                                }
                            </form>
                        </HookFormProvider>
                    )
            }
            <div className="text-end mt-4 mb-2">
                {
                    authentication.community.isPubicCommunity()
                        ? (
                            <button onClick={togglePrompt} className="btn btn-cancel">
                                {localizer.msg('common.actions.cancel')}
                            </button>
                        )
                        : null
                }
                <ActionButton form={FORM_ID} loading={isSubmitting}>
                    {localizer.msg('common.actions.join')}
                </ActionButton>
            </div>
        </Modal>
    );
};