import React, {useCallback, useMemo, useState} from 'react';
import {useForm, useWatch} from 'react-hook-form';
import {Button, InfoTip, Modal} from '@ideascale/ui';
import {isHtmlInject, Localizer, useApiErrorResponseHandler, useHandleFormSubmit} from '@ideascale/commons';
import {VersionPayload} from 'models/landing-page/VersionPayload';
import {LandingPageVersion} from 'models/types/LandingPageVersion';

type EditVersionModalProps = {
    localizer: Localizer;
    open: boolean;
    toggleModal: () => void;
    versionData: LandingPageVersion;
    onVersionEdit: (pageId: number, version: number, newVersion: VersionPayload) => Promise<LandingPageVersion>;
    onVersionEditSuccessful: (newVersion: LandingPageVersion) => void;
    landingPageId: number;
}

const MAX_TITLE_LENGTH = 32;
const MAX_NOTE_LENGTH = 500;

export const EditVersionModal = (props: EditVersionModalProps) => {
    const {
        toggleModal,
        open,
        localizer,
        versionData,
        onVersionEdit,
        onVersionEditSuccessful,
        landingPageId
    } = props;
    const {
        register,
        formState: {errors, isSubmitting},
        handleSubmit,
        control,
        setError
    } = useForm<VersionPayload>({
        defaultValues:
            {note: versionData.note, title: versionData.title}
    });
    const onFormSubmit = useHandleFormSubmit(handleSubmit);
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [versionDataChanged, setVersionDataChanged] = useState(false);

    const noteValue = useWatch({
        name: 'note',
        defaultValue: versionData.note,
        control
    });

    const editVersion = useCallback(async (data: VersionPayload) => {
        try {
            data.title = data.title.trim();
            data.note = data.note?.trim();
            const updatedVersionData = await onVersionEdit(landingPageId, versionData.version, data);
            onVersionEditSuccessful({...versionData, ...updatedVersionData});
        } catch (error) {
            handleErrorResponse(error, {setFormError: setError});
        }
    }, [handleErrorResponse, landingPageId, onVersionEdit, onVersionEditSuccessful, setError, versionData]);

    const remainingLength = useMemo(() => {
        return MAX_NOTE_LENGTH - (noteValue?.length ?? 0);
    }, [noteValue]);

    return (
        <Modal className="edit-version-modal" isOpen={open} toggle={toggleModal} autoFocus={false}
               title={localizer.msg('landing-page.action-topbar.edit-mode.version-edit-modal.title')}>
            <form onSubmit={onFormSubmit(editVersion, isSubmitting || !versionDataChanged)}>
                <div className={`form-group ${errors.title ? 'has-error' : ''}`}>
                    <label className="control-label fw-bold my-1 d-flex align-items-end" htmlFor="version-title">
                        <span>
                            {localizer.msg('landing-page.action-topbar.edit-mode.version-edit-modal.version-title')}
                        </span>
                        <InfoTip id="title-infotip" placement="top"
                                 content={localizer.msg('landing-page.action-topbar.edit-mode.version-edit-modal.title-infotip-content')}/>
                    </label>
                    <input className="form-control"
                           type="text"
                           autoFocus={true}
                           id="version-title"
                           maxLength={MAX_TITLE_LENGTH}
                           {...register('title', {
                               onChange: () => setVersionDataChanged(prev => !prev ? true : prev),
                               validate: value => !isHtmlInject(value || '') || localizer.msg('common.errors.html'),
                               required: localizer.msg('landing-page.action-topbar.edit-mode.version-edit-modal.version-title-required')
                           })}/>
                    {
                        errors?.title && (
                            <p className="invalid-feedback d-block" data-role="field-error" aria-live="polite">
                                {errors.title.message}
                            </p>
                        )
                    }
                </div>

                <div className={`form-group ${errors.note ? 'has-error' : ''}`}>
                    <label className="control-label fw-bold my-1" htmlFor="version-note">
                        {localizer.msg('landing-page.action-topbar.edit-mode.version-edit-modal.note')}
                        <span className={`${errors?.note ? '' : 'text-gray fw-lighter'} ms-1`}>
                            ({localizer.msg('common.optional')})
                        </span>
                    </label>
                    <textarea className="form-control" rows={4} id="version-note" maxLength={MAX_NOTE_LENGTH}
                              {...register('note', {
                                  onChange: () => setVersionDataChanged(prev => !prev ? true : prev),
                                  validate: value => !isHtmlInject(value || '') || localizer.msg('common.errors.html'),
                              })}/>
                    <div className="text-muted mt-1">
                        {localizer.msg('idea.form.field.feedback.text-length', {count: remainingLength})}
                    </div>
                    {
                        errors?.note && (
                            <p className="invalid-feedback d-block" data-role="field-error" aria-live="polite">
                                {errors.note.message}
                            </p>
                        )
                    }
                </div>

                <div className="mt-5 mb-3 text-end">
                    <Button className="me-3" color="cancel" type="button" onClick={toggleModal}>
                        {localizer.msg('common.actions.cancel')}
                    </Button>
                    <Button color="primary" type="submit" disabled={isSubmitting || !versionDataChanged}>
                        {localizer.msg('common.update')}
                    </Button>
                </div>
            </form>
        </Modal>
    );
};
