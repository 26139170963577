export enum BasicStagesId {
    DRAFT = 96,
    INBOUND = 98,
    PENDING = 99,
    ACTIVE = 0,
    REVIEW = 3,
    IN_PROGRESS = 2,
    COMPLETE = 4,
    RECYCLE_BIN = 1,
    OFFTOPIC = 5,
}