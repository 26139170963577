import React, {Fragment, useCallback, useMemo} from 'react';
import {Link, useLocation, useParams} from 'react-router-dom';
import {isNil} from 'lodash';
import {UncontrolledUserDropdown} from '@ideascale/ui';
import {
    Campaign,
    CLASSIFICATION_NAME,
    ClassificationFieldAttribute,
    ClassificationLabel,
    ClassificationUtil,
    emptyFunction,
    LabelData,
    Member,
    MemberLink,
    TimeAgo,
    useApplicableClassifications
} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useIdeasContext} from 'contexts/IdeasContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useIdeaLabels} from 'hooks/useIdeaLabels';
import {KudosAction} from 'shared/KudosAction';
import {LabelsView} from 'shared/LabelsView';
import {appLinks} from 'services/AppLinks';
import {KudoActivitySummary} from 'models/KudoActivitySummary';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {PageParameters} from 'models/types/PageParameters';
import {IdeaListRouteMatchParams} from 'models/types/IdeaListRouteMatchParams';
import './IdeaHeader.scss';

const STACKED_AVATARS_LIMIT = 3;

type IdeaHeaderProps = {
    ideaNumber: number;
    campaign?: Campaign;
    ideaId: number;
    ideaTitle: string;
    ideaCreatedAt: string;
    linkableIdeaTitle: boolean;
    submitter: Member;
    coSubmitters: Member[];
    kudosCount: number;
    labels: LabelData[];
    kudoGiven: boolean;
    isSearchResult?: boolean;
    giveKudo?: (ideaId: number) => Promise<KudoActivitySummary>;
    draft?: boolean;
    showLabelsStackView?: boolean;
    fetchKudoGivers?: (pageParameters: PageParameters, ideaId: number) => Promise<PagedResponseContent<Member>>;
    idPrefix?: string;
    ideaViewCount?: number;
    classification?: ClassificationFieldAttribute[];
    onTitleClickCallback?: () => void;
}

export const IdeaHeader = (props: IdeaHeaderProps) => {
    const {
        campaign,
        ideaId,
        ideaTitle,
        ideaCreatedAt,
        linkableIdeaTitle = false,
        kudosCount,
        submitter,
        coSubmitters,
        labels,
        giveKudo,
        kudoGiven,
        isSearchResult = false,
        draft = false,
        showLabelsStackView = true,
        fetchKudoGivers,
        idPrefix = '',
        ideaViewCount,
        ideaNumber,
        classification,
        onTitleClickCallback
    } = props;
    const {authentication: {actor}} = useAppContext();
    const {communityConfig: {classificationEnabled}} = useAppContext();
    const localizer = useLocalizer();
    const {ideaLabelRouteChange} = useIdeaLabels();
    const {setIdeaListPagePath, setListPageUrlParams} = useIdeasContext();
    const params = useParams<IdeaListRouteMatchParams>();
    let location = useLocation();

    const hasCampaignIdeas = () => {
        return !!params.campaignId;
    };

    const {getClassificationAttribute} = useApplicableClassifications('', []);

    const canGiveKudos = useCallback(() => {
        return submitter.id !== actor.id || coSubmitters.length > 0;
    }, [actor.id, coSubmitters.length, submitter.id]);

    const getIdeaAuthors = useMemo(() => {
        const authors = [submitter, ...coSubmitters];
        authors.forEach(author => {
            if (author.id === actor.id) {
                author.name = localizer.msg('common.you');
            }
            author.url = author.id ? process.env.PUBLIC_URL + appLinks.profile(author.id) : undefined;
        });
        return authors;
    }, [actor.id, coSubmitters, localizer, submitter]);

    const isNotLastAuthor = (index: number) => {
        return (getIdeaAuthors.length > STACKED_AVATARS_LIMIT ? STACKED_AVATARS_LIMIT : getIdeaAuthors.length) !== index + 1;
    };

    const onGoToDetails = useCallback(() => {
        setIdeaListPagePath(location.pathname);
        setListPageUrlParams(location.search);
        onTitleClickCallback?.();
    }, [onTitleClickCallback, location.pathname, location.search, setIdeaListPagePath, setListPageUrlParams]);

    const HeaderTag = useMemo(() => isSearchResult ? 'h3' : 'h2', [isSearchResult]);

    return (
        <header className="idea-header">
            {
                classificationEnabled && campaign?.classificationSummary && !hasCampaignIdeas() &&
                <div>
                    <ClassificationLabel
                        classification={getClassificationAttribute(campaign?.classificationSummary)}
                        extraClasses="me-2 mb-1 align-middle"/>
                </div>
            }
            {
                campaign &&
                <p className="idea-campaign">
                    <Link aria-label={localizer.msg('common.campaign-name', {name: campaign.name})}
                          to={campaign.defaultStage
                              ? appLinks.ideas(String(campaign.id), campaign.defaultStage.key)
                              : appLinks.campaign(String(campaign.id))}>
                        {campaign.name}
                    </Link>
                </p>
            }
            {
                linkableIdeaTitle
                    ? <div className="idea-title-holder pe-2 mb-2">
                        {
                            classificationEnabled && classification &&
                            <ClassificationLabel
                                classification={ClassificationUtil.getAttributeByName(CLASSIFICATION_NAME.EFFECTIVE_CLASSIFICATION, classification)}
                                extraClasses="me-2 d-inline-block align-middle mb-1"/>
                        }
                        {
                            <HeaderTag className="h2 idea-title d-inline mb-0 me-1" lang="en">
                                <Link className="classic-link"
                                      to={
                                          draft
                                              ? appLinks.draftIdea(location.pathname, ideaId, submitter.id)
                                              : {
                                                  pathname: appLinks.ideaDetails(ideaId)
                                              }
                                      }
                                      aria-label={ideaTitle}
                                      title={ideaTitle}
                                      onClick={draft ? emptyFunction : onGoToDetails}>
                                    {ideaTitle}
                                </Link>
                            </HeaderTag>
                        }

                        <LabelsView labels={labels} onLabelClick={ideaLabelRouteChange}
                                    showStackView={showLabelsStackView}/>
                    </div>
                    : <Fragment>
                        <HeaderTag className="fw-bold h3 d-inline">
                            {
                                classificationEnabled && classification &&
                                <ClassificationLabel
                                    classification={ClassificationUtil.getAttributeByName(CLASSIFICATION_NAME.EFFECTIVE_CLASSIFICATION, classification)}
                                    extraClasses="me-2 align-middle"/>
                            }
                            {ideaTitle}
                        </HeaderTag>
                        <span className="ms-2">
                            <LabelsView labels={labels} onLabelClick={ideaLabelRouteChange}
                                        showStackView={showLabelsStackView}/>
                        </span>
                    </Fragment>
            }

            <div className={`idea-meta-author idea-meta mt-2 ${coSubmitters.length > 0 ? 'has-contributors' : ''}`}>
                {
                    submitter &&
                    <div className="author-details">
                        {
                            coSubmitters.length === 0
                                ? <MemberLink className="avatar-link" id={submitter.id}
                                              identityHidden={submitter.identityHidden}
                                              ariaLabel={localizer.msg('common.profile-member-link-label', {username: submitter.username})}>
                                    <img className="avatar avatar-sm" src={submitter.avatar}
                                         alt={submitter.username ? localizer.msg('common.user-avatar', {username: submitter.username}) : ''}/>
                                </MemberLink>
                                : <UncontrolledUserDropdown ariaLabel={localizer.msg('idea.idea-author')}
                                                            triggerClassName="btn bg-transparent p-0 stacked-avatars border-0"
                                                            members={getIdeaAuthors as any}
                                                            triggerComponent={
                                                                <Fragment>
                                                                    {
                                                                        getIdeaAuthors
                                                                            .slice(0, STACKED_AVATARS_LIMIT)
                                                                            .map(submitter => <img
                                                                                className="avatar avatar-sm"
                                                                                key={submitter.id}
                                                                                src={submitter.avatar}
                                                                                alt={submitter.name}/>)
                                                                    }
                                                                    {
                                                                        getIdeaAuthors.length > STACKED_AVATARS_LIMIT &&
                                                                        <span
                                                                            className="remaining-contributors avatar avatar-sm">
                                                                          +{getIdeaAuthors.length - STACKED_AVATARS_LIMIT}
                                                                      </span>
                                                                    }
                                                                </Fragment>
                                                            }/>
                        }
                        {
                            giveKudo &&
                            <KudosAction elementId={`${idPrefix}-${ideaId}`} giveKudos={giveKudo}
                                         kudosCount={kudosCount}
                                         kudoGiven={kudoGiven}
                                         canGiveKudos={canGiveKudos()}
                                         id={ideaId}
                                         fetchKudoGivers={fetchKudoGivers} draftIdea={draft}/>
                        }
                        <span className="me-2 contributors-spacer"/>
                        {
                            coSubmitters.length === 0
                                ? (
                                    <div className="author-info">
                                        <MemberLink className="author-name text-truncate" id={submitter.id}
                                                    identityHidden={submitter.identityHidden}
                                                    title={submitter.name}>{submitter.name}</MemberLink>
                                        <div>
                                            <span className="d-inline-block" data-test-element-id="submitted-date">
                                                <TimeAgo localizer={localizer} dateISOString={ideaCreatedAt}
                                                         prefixText={false}/>
                                            </span>
                                            {
                                                !isNil(ideaNumber) &&
                                                <span className="idea-number ms-1 d-inline-block">
                                                    {localizer.msg('idea.actions.idea-number')} #{ideaNumber}
                                                </span>
                                            }
                                            {
                                                ideaViewCount &&
                                                <span className="idea-views">
                                                    {localizer.msg('idea.actions.idea-views')} {ideaViewCount}
                                                </span>
                                            }
                                        </div>
                                    </div>
                                )
                                : (
                                    <div className="author-info">
                                            <span className="contributors">
                                                {getIdeaAuthors.slice(0, STACKED_AVATARS_LIMIT).map((user, index) =>
                                                    <Fragment key={user.id}>
                                                        <MemberLink className="fw-bold" id={user.id}
                                                                    identityHidden={user.identityHidden}>
                                                            {user.name}
                                                        </MemberLink>
                                                        {
                                                            isNotLastAuthor(index) && <>, {' '}</>
                                                        }
                                                    </Fragment>
                                                )}
                                                {
                                                    getIdeaAuthors.length > STACKED_AVATARS_LIMIT &&
                                                    <Fragment>
                                                        {' '}{localizer.msg('common.and')}{' '}
                                                        <UncontrolledUserDropdown
                                                            ariaLabel={`${getIdeaAuthors.length - STACKED_AVATARS_LIMIT} ${localizer.msg('idea.more-idea-authors')}`}
                                                            triggerClassName="btn p-0 bg-transparent shadow-none"
                                                            triggerColor="link"
                                                            triggerComponent={`${getIdeaAuthors.length - STACKED_AVATARS_LIMIT} ${localizer.msg('common.more')}`}
                                                            members={getIdeaAuthors as any}/>
                                                    </Fragment>
                                                }
                                            </span>
                                        <div>
                                            <span className="d-inline-block" data-test-element-id="submitted-date">
                                                <TimeAgo localizer={localizer} dateISOString={ideaCreatedAt}
                                                         prefixText={false}/>
                                            </span>
                                            {
                                                !isNil(ideaNumber) &&
                                                <span className="idea-number ms-1 d-inline-block">
                                                    {localizer.msg('idea.actions.idea-number')} #{ideaNumber}
                                                </span>
                                            }
                                        </div>
                                    </div>
                                )
                        }
                    </div>
                }
            </div>
        </header>
    );
};