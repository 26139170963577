import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {PopoverBody, PopoverHeader, UncontrolledPopover} from 'reactstrap';
import {Icon} from '@ideascale/ui';
import {
    ClassificationAttachmentAttribute,
    ClassificationLabel,
    ClassificationUtil,
    FileAttachment,
    FileAttribute,
    Localizer
} from '@ideascale/commons';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {appLinks} from 'services/AppLinks';
import fileAttachmentPreview from 'assets/img/attachment-preview.png';
import styles from './AttachmentThumbnails.module.scss';

type AttachmentThumbnailsProps = {
    ideaId: number;
    localizer: Localizer;
    attachments: FileAttachment[];
    attributes?: ClassificationAttachmentAttribute[];
    classificationEnabled: boolean;
}

export const AttachmentThumbnails = (props: AttachmentThumbnailsProps) => {
    const {ideaId, localizer, attachments, attributes, classificationEnabled} = props;

    return (
        <div className="border-top-1 pt-3 mt-3">
            <header>
                <span className="text-muted fw-bold">{localizer.msg('idea-details.attachments')}</span>
                {' - '}
                <Link className="fw-semibold" to={appLinks.ideaDetailsAttachment(ideaId)}>{localizer.msg('common.show-all')}</Link>
            </header>
            <div className={styles.attachmentThumbnailList}>
                {
                    attachments.map((file, index) =>
                        <Fragment key={`${file.id}_${index}`}>
                            <div className={`d-inline-block mt-4 me-4 ${styles.attachmentThumbnail} rounded`}>
                                {
                                    classificationEnabled &&
                                    <ClassificationLabel extraClasses="mb-2"
                                                         classification={ClassificationUtil.getAppliedFileAttribute(file.filename, (attributes?.flatMap(item => item.fileAttributeValues) as FileAttribute[]))}/>
                                }
                                <div className="position-relative justify-content-center box-shadow">
                                    {
                                        FileAttachment.isPreviewableImg(file.filename) ?
                                            <Fragment>
                                                <img src={file.url}
                                                     alt={file.altText || file.filename}
                                                     title={file.filename}
                                                     className="img-fluid rounded"/>
                                                {
                                                    file.previewSupported && FileAttachment.isPreviewableImg(file.filename) &&
                                                    <Fragment>
                                                        <div className={`${styles.attachmentThumbnailOverlay} rounded`}
                                                             id={`attachment-preview-popover-${index}${file.id ? `-${file.id}` : ''}`}>
                                                            <Icon className="zoom-icon" name="magnifying-glass-plus"
                                                                  iconSpritePath={svgIconsPath} height={15} width={15}
                                                                  fill="#fff"/>
                                                        </div>
                                                        <UncontrolledPopover className="attachment-preview-popover"
                                                                             trigger="legacy"
                                                                             placement="bottom"
                                                                             target={`attachment-preview-popover-${index}${file.id ? `-${file.id}` : ''}`}>
                                                            {
                                                                classificationEnabled &&
                                                                <PopoverHeader className="text-center">
                                                                    <ClassificationLabel
                                                                        classification={ClassificationUtil.getAppliedFileAttribute(file.filename, (attributes?.flatMap(item => item.fileAttributeValues) as FileAttribute[]))}/>
                                                                </PopoverHeader>
                                                            }
                                                            <PopoverBody
                                                                className="p-3 d-flex flex-column justify-content-center align-items-center">
                                                                <img src={file.previewSupported ? file.url : fileAttachmentPreview}
                                                                     alt={file.altText || file.filename}
                                                                     title={file.filename}
                                                                     className="img-fluid"/>
                                                            </PopoverBody>
                                                        </UncontrolledPopover>
                                                    </Fragment>
                                                }
                                            </Fragment>
                                            : (
                                                <a href={file.url}>
                                                    <img src={fileAttachmentPreview}
                                                         alt={file.filename}
                                                         title={file.filename}
                                                         className="img-fluid rounded"/>
                                                </a>
                                            )
                                    }

                                </div>
                                <span className="text-truncate d-block text-muted font-size-sm" title={file.filename}>
                                    {file.filename}
                                </span>
                            </div>
                        </Fragment>
                    )
                }
            </div>
        </div>
    );
};