import React, {Fragment} from 'react';
import {WorkFlowChartItem} from './WorkFlowChartItem';
import './WorkFlowChart.scss';

export type chartItem = {
    id: number;
    current: boolean;
    completed: boolean;
    heading: string;
    visited: boolean;
    hoverHeading: boolean;
    date?: string;
}

type WorkFlowChartItemsProps = {
    workflowItems: chartItem[];
}

export const WorkFlowChart = (props: WorkFlowChartItemsProps) => {
    const {workflowItems} = props;
    return (
        <Fragment>
            <div className="workflow-chart">
                <ul className="items list-unstyled d-flex justify-content-center align-items-stretch flex-wrap flex-row row-gap-3">
                    {workflowItems.map(workflowItem => {
                        return (
                            <WorkFlowChartItem workflowItem={workflowItem} key={workflowItem.id}/>
                        );
                    })}
                </ul>
            </div>
        </Fragment>
    );
};