import React from 'react';
import user from 'assets/img/user.png';
import {Avatar, Icon} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import './TeamPlaceholder.scss';

type TeamPrivateOrEmptyProps = {
    message: string;
    className?: string;
    hidden?: boolean;
}

export const TeamPlaceholder = (props: TeamPrivateOrEmptyProps) => {
    const {message, className = '', hidden = true} = props;

    return (
        <div className={`d-flex flex-column align-items-center team-placeholder-container ${className}`}>
            <div
                className={`${hidden ? 'mb-4' : 'mb-2'}  position-relative image-container d-flex justify-content-center align-items-center shadow rounded-circle`}>
                <div className="avatar-bg rounded-circle">
                    <Avatar size="md" src={user} alt="user"/>
                </div>
                {
                    hidden && <div
                        className="position-absolute icon-container d-flex justify-content-center align-items-center rounded-circle">
                        <div className="icon-bg d-flex justify-content-center align-items-center rounded-circle">
                            <Icon iconSpritePath={svgIconPath} name="eye-open-slash" width={20} height={20}/>
                        </div>
                    </div>
                }
            </div>

            <div className={`text-muted ${hidden ? 'sr-only' : ''}`}>{message}</div>
        </div>
    );
};