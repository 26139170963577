import React, {Fragment, useCallback, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {
    BodyTagAttributes,
    LandingPageMode,
    PageTitle,
    QuickAccessibleMenu,
    useApiErrorResponseHandler,
} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useLandingPageContext} from 'contexts/LandingPageContext';
import {useUpdateCurrentCampaign} from 'hooks/useUpdateCurrentCampaign';
import {useLandingPageService} from 'hooks/useService';
import {useLocalizer} from 'hooks/useLocalizer';
import {LandingPageContentContainer} from 'containers/landing-page/LandingPageContentContainer';
import {LandingPageSkeleton} from 'components/landing-page/LandingPageSkeleton';
import {LandingPagePreviewParams} from 'models/types/LandingPagePreviewParams';
import {PAGE_IDS} from 'constants/AppConstants';
import {LandingPageData} from 'models/landing-page/LandingPageData';
import 'pages/LandingPage.scss';

const landingPageContainerId = 'landing-page-container';
const skipToMainContent = [{targetId: landingPageContainerId, label: 'Skip to main content'}];

export const LandingPage = () => {
    const params = useParams<LandingPagePreviewParams>();
    const localizer = useLocalizer();
    const {communityConfig} = useAppContext();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});


    const {
        loading,
        landingPage,
        setLandingPage,
        setLoading
    } = useLandingPageContext();

    useUpdateCurrentCampaign();

    const landingPageService = useLandingPageService();

    const fetchLandingPage = useCallback(async () => {
        if (landingPageService !== null) {
            return landingPageService.fetchLandingPage();
        }
        return LandingPageData.EMPTY;
    }, [landingPageService]);

    useEffect(() => {
        if (!params.mode) {
            setLoading(true);
            fetchLandingPage()
                .then((data) => {
                    setLandingPage(data);
                    setLoading(false);
                })
                .catch((error: any) => {
                    handleErrorResponse(error);
                });
        }
    }, [fetchLandingPage, handleErrorResponse, params.mode, setLandingPage, setLoading]);

    return (
        <Fragment>
            <BodyTagAttributes data-test-element-id={PAGE_IDS.LANDING_PAGE}/>
            <PageTitle title={`${communityConfig.name} - by IdeaScale`}/>
            <QuickAccessibleMenu items={skipToMainContent}/>
            {
                loading
                    ? <LandingPageSkeleton/>
                    : <LandingPageContentContainer containerId={landingPageContainerId}
                                                   editMode={params.mode === LandingPageMode.EDIT && landingPage.permissions?.edit}/>
            }
        </Fragment>
    );
};