import React, {Fragment} from 'react';
import {Progress} from 'reactstrap';
import {useLocalizer} from 'hooks/useLocalizer';
import {StageSummaryHeading} from './StageSummaryHeading';
import {FundStageSummary} from 'models/FundStageSummary';
import {IdeaStageSummaryProps} from 'models/types/IdeaStageSummaryProps';
import {FundUnit} from 'models/enums/FundUnit';
import {NonEmptyFundUnitKey} from 'models/FundStageActivity';

export const FundSummary = (props: IdeaStageSummaryProps<FundStageSummary>) => {
    const {
        ideaStageSummary: {
            fundUnit,
            fundUnitKey,
            fundBalance,
            availableFundAccount,
            percentage,
            viewAllowed,
            participationAllowed
        }
    } = props;
    const localizer = useLocalizer();

    const renderFundCurrency = () => {
        switch (fundUnit) {
            case FundUnit.HOUR:
                return <Fragment>
                    {fundBalance.toLocaleString()} {localizer.msg('stage.fund.unit.hour', {count: fundBalance})}
                </Fragment>;
            case FundUnit.Token:
                return <Fragment>
                    {fundBalance.toLocaleString()} {localizer.msg('stage.fund.unit.token', {count: fundBalance})}
                </Fragment>;
            case FundUnit.UNSPECIFIED:
                return <Fragment>
                    {fundBalance.toLocaleString()} {fundUnit}
                </Fragment>;
            default:
                return <Fragment>
                    {`${fundBalance.toLocaleString()} ${fundUnit}${fundUnitKey && Object.values(NonEmptyFundUnitKey).includes(fundUnitKey as NonEmptyFundUnitKey) ? `(${localizer.msg(fundUnitKey)})` : ''}`}
                </Fragment>;
        }
    };

    return (
        <Fragment>
            <StageSummaryHeading className="mb-2" heading={localizer.msg('stage.fund.heading')}/>
            <div className="stage-data mt-n1 mb-3">
                {
                    viewAllowed || participationAllowed
                        ?
                        <Fragment>
                            {
                                percentage !== undefined
                                    ?
                                    <Fragment>
                                        <Progress
                                            value={percentage}
                                            aria-hidden="true"/>
                                        <div className="sr-only"
                                             role='progressbar'
                                             aria-valuenow={percentage}
                                             aria-label="Funding Progress"/>
                                        {
                                            availableFundAccount &&
                                            <Fragment>
                                                {renderFundCurrency()}
                                                <span className="percentage-summary">
                                                / {percentage}% {localizer.msg('stage.fund.of-goals')}
                                            </span>
                                            </Fragment>
                                        }
                                    </Fragment>
                                    :
                                    <Fragment>
                                        {renderFundCurrency()}
                                        <span
                                            className="fw-bold amount-raised">&nbsp;{localizer.msg('stage.fund.raised')}</span>
                                    </Fragment>
                            }
                        </Fragment>
                        :
                        <em>{localizer.msg('stage.fund.awaits')}</em>
                }
            </div>
        </Fragment>
    );
};