import {ClassificationAttachmentAttribute, FileAttachmentDetail} from '@ideascale/commons';

export class FileAttachmentsHolder {
    static readonly EMPTY = new FileAttachmentsHolder([], false);

    constructor(
        public attachments: FileAttachmentDetail[],
        public fileAttachmentAllowed: boolean,
        public attributes?: ClassificationAttachmentAttribute[]
    ) {
    }
}