import React, {Fragment} from 'react';
import {ActionButton, Button, Icon} from '@ideascale/ui';
import {IdeaFormMode, Localizer, SimpleDropdown, useHookFormContext, useToggle} from '@ideascale/commons';
import {FieldValues} from 'react-hook-form';
import {FieldErrors} from 'react-hook-form/dist/types/errors';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useAppContext} from 'contexts/AppContext';

type FormButtonProps = {
    formId: string;
    draftsSubmitterId: number | null;
    localizer: Localizer;
    isLoadingForm: boolean;
    moveToRecycleBinAllowed: boolean;
    onSaveAsDraft: () => Promise<void>;
    moveToRecycleBin: (event: React.MouseEvent) => void;
    onSubmitSuccess: (submittedData: FieldValues, submitAnother: boolean) => Promise<void>;
    onSubmitError: (errors: FieldErrors) => void;
    mode: IdeaFormMode;
    isSubmittingDraft: boolean;
    fileUploading: boolean;
}

export const FormButtons = (props: FormButtonProps) => {
    const {
        formId,
        draftsSubmitterId,
        mode,
        moveToRecycleBinAllowed,
        localizer,
        isLoadingForm,
        onSaveAsDraft,
        moveToRecycleBin,
        onSubmitSuccess,
        onSubmitError,
        isSubmittingDraft,
        fileUploading,
    } = props;
    const {handleSubmit, formState: {isSubmitting}} = useHookFormContext();
    const {authentication} = useAppContext();
    const [showSubmitDropdown, toggleSubmitDropdown] = useToggle(false);

    return (
        <div className="ms-auto">
            {
                (mode === 'CREATE_IDEA' || mode === 'EDIT_DRAFT') &&
                <button className="btn btn-secondary" type="button" name="draft" value="draft"
                        disabled={isSubmittingDraft || isLoadingForm || isSubmitting || fileUploading} form={formId}
                        onClick={onSaveAsDraft}>
                    {
                        isSubmittingDraft &&
                        <span className="spinner-border spinner-border-sm me-2 position-relative pos-top-n1"/>
                    }
                    {
                        mode === 'EDIT_DRAFT'
                            ? localizer.msg('common.update-draft')
                            : localizer.msg('idea.form.actions.save-draft')
                    }
                </button>
            }

            {
                moveToRecycleBinAllowed && mode === 'EDIT_IDEA' &&
                <button className="btn btn-secondary" type="button"
                        title={localizer.msg('common.move-to-recycling-bin')}
                        onClick={moveToRecycleBin}>
                    <Icon className="active" iconSpritePath={svgIconsPath} name="trash-can-open" fill="#384EC1"/>
                </button>
            }

            {
                (draftsSubmitterId === null || draftsSubmitterId === authentication.actor.id) &&
                <div role="group" className="btn-nest btn-group ms-3">
                    <ActionButton className="btn btn-primary"
                                  type="submit"
                                  form={formId}
                                  loading={!isSubmittingDraft && isSubmitting}
                                  disabled={isSubmittingDraft || isLoadingForm || fileUploading}>
                        {
                            mode === 'EDIT_IDEA'
                                ? localizer.msg('common.update')
                                : mode === 'COPY_IDEA'
                                    ? <Fragment>
                                        <Icon className="me-2 mt-n1" iconSpritePath={svgIconsPath}
                                              name="copy"/>
                                        {localizer.msg('common.copy')}
                                    </Fragment>
                                    : localizer.msg('common.submit')
                        }
                    </ActionButton>
                    {
                        (mode === 'CREATE_IDEA' || mode === 'EDIT_DRAFT') &&
                        <Fragment>
                            <SimpleDropdown open={showSubmitDropdown}
                                            toggle={toggleSubmitDropdown}
                                            direction="top"
                                            toggleClassName="p-2 h-100"
                                            toggleSplit={true}
                                            toggleBtnColor="primary"
                                            toggleContent={
                                                <Icon iconSpritePath={svgIconsPath} name="triangle-down" width={14}
                                                      height={14}/>
                                            }>
                                <Button color="light" className="w-100 fw-normal"
                                        disabled={isSubmittingDraft || isLoadingForm || isSubmitting || fileUploading}
                                        onClick={handleSubmit((data) => onSubmitSuccess(data, true), onSubmitError)}>
                                    {localizer.msg('idea.form.actions.submit-another')}
                                </Button>
                            </SimpleDropdown>
                        </Fragment>
                    }
                </div>
            }
        </div>
    );
};
