import React, {useEffect, useRef, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useMutation, useQueryClient} from 'react-query';
import {HtmlConverter, Modal, NewRichTextEditor, RichTextEditorHandler} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {ServiceError, useApiErrorResponseHandler} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useProfilePageParams} from 'hooks/useProfilePageParams';
import {useFileUploadService} from 'hooks/useFileUploadService';
import {QUERY_KEYS} from 'constants/AppConstants';
import {ProfileSidebarResponse} from 'models/ProfileSidebarResponse';
import {MemberBio} from 'models/MemberBio';

type EditBioModalProps = {
    toggle: () => void;
    isOpen: boolean;
    fetchMemberBio: () => Promise<MemberBio>;
    saveMemberBio: (memberId: number, content: string) => Promise<MemberBio>;
}

type FormInputs = {
    content: string;
}

export const EditBioModal = (props: EditBioModalProps) => {
    const {toggle, isOpen, saveMemberBio, fetchMemberBio} = props;
    const {communityConfig: {maxFileSizeLimit, classificationEnabled}} = useAppContext();
    const {tempImageUpload} = useFileUploadService();
    const localizer = useLocalizer();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const queryClient = useQueryClient();
    const currentProfileId = useProfilePageParams();
    const [profileBio, setProfileBio] = useState<MemberBio>(MemberBio.EMPTY);
    const richTextEditorNode = useRef<RichTextEditorHandler>(null);

    const {handleSubmit, control, setError, formState: {errors}} = useForm<FormInputs>({
        mode: 'all'
    });

    useEffect(() => {
        fetchMemberBio().then(data => {
            setProfileBio(data);
            richTextEditorNode.current?.setHtml(HtmlConverter.toRenderHtmlFormat(data.content));
            richTextEditorNode.current?.focus();
        });
    }, [fetchMemberBio]);

    const {mutate} = useMutation(
        ({request}: { request: { memberId: number, content: string } }) => saveMemberBio(request.memberId, request.content),
        {
            onSuccess: (response) => {
                queryClient.setQueryData([QUERY_KEYS.PROFILE_SIDEBAR, currentProfileId], (oldSidebarData) => {
                    const newSidebarData = {...oldSidebarData as ProfileSidebarResponse};
                    newSidebarData.bioContent = response.content;
                    return newSidebarData;
                });
                toggle();
            },
            onError: (error: ServiceError) => {
                handleErrorResponse(error, {
                    setFormError: setError
                });
            }
        }
    );

    return (
        <Modal className="edit-profile-bio modal-lg"
               isOpen={isOpen}
               toggle={toggle}
               title={localizer.msg('profile.bio.edit-profile-bio')}
               autoFocus={false}
               modalHeaderId="edit-bio-header"
               aria-labelledby="edit-bio-header">
            {
                classificationEnabled &&
                <p className="fw-bold">{localizer.msg('common.visible-to-all-members')}</p>
            }
            <form onSubmit={handleSubmit(data => mutate({
                request: {
                    memberId: currentProfileId,
                    content: HtmlConverter.toServerHtmlFormat(data.content)
                }
            }))} className="pb-2 pb-4">
                <div className={`form-group mb-4 ${errors.content ? 'has-error' : ''}`}>
                    <Controller
                        control={control}
                        name="content"
                        defaultValue={profileBio.content}
                        render={({field}) =>
                            <NewRichTextEditor
                                id={'profile-bio'}
                                toolbar={'standard'}
                                className={'form-control h-auto'}
                                placeholder={localizer.msg('profile.bio.edit-profile-bio')}
                                svgIconPath={svgIconsPath}
                                onChange={field.onChange}
                                enableEmojiPicker={false}
                                enableAtMention={false}
                                ref={richTextEditorNode}
                                defaultValue={profileBio.content}
                                uploadImage={tempImageUpload}
                                maxFileSize={maxFileSizeLimit}
                            />
                        }/>
                    {
                        errors.content &&
                        <div className="invalid-feedback d-block">
                            {errors.content.message}
                        </div>
                    }
                </div>
                <button type="submit" className="btn btn-primary float-end mb-2">
                    {localizer.msg('common.actions.save')}
                </button>
            </form>
        </Modal>
    );
};