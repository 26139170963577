import React, {Fragment} from 'react';
import {Icon} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';

type ErrorMessageProps = {
    message?: string
}

export const ErrorMessage = ({message}: ErrorMessageProps) => {
    return (
        <Fragment>
            {
                message && message.length > 0
                    ? (
                        <div className="has-error mb-2">
                            <Icon className="is-icon icon-danger me-1" name="exclamation-outlined-circle" iconSpritePath={svgIconPath}/>
                            {message}
                        </div>
                    )
                    : null
            }
        </Fragment>
    );
};