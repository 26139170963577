import React, {Fragment, useEffect, useState} from 'react';
import {StringUtil} from '@ideascale/commons';
import {CollapseExpand} from '@ideascale/ui';
import iconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {HtmlRenderer} from 'components/shared/HtmlRenderer';
import {SidebarCustomWidget} from 'models/SidebarCustomWidget';

type CustomWidgetProps = {
    containerClass: string,
    fetchCustomWidgetData: () => Promise<SidebarCustomWidget>
}

const TITLE_LENGTH = 20;

export const CustomWidget = (props: CustomWidgetProps) => {
    const {containerClass, fetchCustomWidgetData} = props;

    const [widgetData, setWidgetData] = useState<SidebarCustomWidget>(SidebarCustomWidget.EMPTY);

    useEffect(() => {
        fetchCustomWidgetData().then(widgetData => {
            setWidgetData(widgetData);
        });
    }, [fetchCustomWidgetData]);

    return (
        <Fragment>
            {
                widgetData && widgetData.content?.length > 0
                    ? (
                        <CollapseExpand containerClass={`${containerClass} panel-light`}
                                        headerTitleAttr={widgetData.title}
                                        headerTitle={StringUtil.textTruncate(widgetData.title,TITLE_LENGTH)} svgIconSprite={iconPath}
                                        defaultOpen={true}>
                            <HtmlRenderer content={widgetData.content} />
                        </CollapseExpand>)
                    : null
            }
        </Fragment>
    );
};

