import React, {Fragment, useCallback} from 'react';
import {useParams} from 'react-router-dom';
import {useAppContext} from 'contexts/AppContext';
import {AssessmentSummary} from '../stage-summaries/AssessmentSummary';
import {BuildTeamSummary} from '../stage-summaries/BuildTeamSummary';
import {FundSummary} from '../stage-summaries/FundSummary';
import {ReviewScaleSummary} from '../stage-summaries/ReviewScaleSummary';
import {PendingSummary} from '../stage-summaries/PendingSummary';
import {RecycleBinSummary} from '../stage-summaries/RecycleBinSummary';
import {EstimateSummary} from '../stage-summaries/EstimateSummary';
import {RefineSummary} from '../stage-summaries/RefineSummary';
import {ReserveSummary} from '../stage-summaries/ReserveSummary';
import {IdeateSummary} from '../stage-summaries/IdeateSummary';
import {OffTopicSummary} from '../stage-summaries/OffTopicSummary';
import {BaseIdeaStageSummary} from 'models/BaseIdeaStageSummary';
import {FundStageSummary} from 'models/FundStageSummary';
import {ReviewScaleStageSummary} from 'models/ReviewScaleStageSummary';
import {EstimateStageSummary} from 'models/EstimateStageSummary';
import {RefineStageSummary} from 'models/RefineStageSummary';
import {AssessmentStageSummary} from 'models/AssessmentStageSummary';
import {BuildTeamStageSummary} from 'models/BuildTeamStageSummary';
import {IdeateStageSummary} from 'models/IdeateStageSummary';
import {StageFunctionName} from 'models/enums/StageFunctionName';
import {IdeaStageSummaryProps} from 'models/types/IdeaStageSummaryProps';
import {IdeaDetailsRouteMatchParams} from 'models/types/IdeaDetailsRouteMatchParams';
import './StageSummary.scss';


export const StageSummary = (props: IdeaStageSummaryProps<BaseIdeaStageSummary>) => {
    const {ideaStageSummary, ideaId, classification} = props;
    const {communityConfig} = useAppContext();
    const params = useParams<IdeaDetailsRouteMatchParams>();

    const renderStageSummary = useCallback(() => {
        switch (ideaStageSummary.stage?.name.toLowerCase()) {
            case StageFunctionName.ASSESSMENT:
                return <AssessmentSummary ideaStageSummary={ideaStageSummary as AssessmentStageSummary}/>;
            case StageFunctionName.BUILD_TEAM:
                return <BuildTeamSummary ideaStageSummary={ideaStageSummary as BuildTeamStageSummary}/>;
            case StageFunctionName.ESTIMATE:
                return <EstimateSummary ideaStageSummary={ideaStageSummary as EstimateStageSummary}/>;
            case StageFunctionName.FUND:
                return <FundSummary ideaStageSummary={ideaStageSummary as FundStageSummary}/>;
            case StageFunctionName.REFINE:
                return <RefineSummary ideaStageSummary={ideaStageSummary as RefineStageSummary}/>;
            case StageFunctionName.REVIEW_SCALE:
                return <ReviewScaleSummary ideaStageSummary={ideaStageSummary as ReviewScaleStageSummary}/>;
            case StageFunctionName.IDEATE:
                return <IdeateSummary ideaStageSummary={ideaStageSummary as IdeateStageSummary} ideaId={ideaId}/>;
            case StageFunctionName.PENDING:
                return <PendingSummary/>;
            case StageFunctionName.OFFTOPIC:
                return <OffTopicSummary/>;
            case StageFunctionName.RECYCLE_BIN:
                return <RecycleBinSummary/>;
            case StageFunctionName.RESERVE:
                return <ReserveSummary/>;
            default:
                return <Fragment/>;
        }
    }, [ideaId, ideaStageSummary]);

    return (
        <section
            className={`stage-summary stage-${ideaStageSummary.stage?.name.toLowerCase()} ${communityConfig.classificationEnabled && params.ideaId && classification ? 'mt-5' : ''}`}>
            {renderStageSummary()}
        </section>
    );
};