import {BaseIdeaStageSummary} from './BaseIdeaStageSummary';
import {StageSummaryData} from './types/StageSummaryData';

export class RefineStageSummary extends BaseIdeaStageSummary {
    constructor(
        public stage: StageSummaryData,
        public viewAllowed: boolean,
        public participationAllowed: boolean,
        public percentage: number,
        public callToActionLabel: string,
        public refineFieldCount: number,
        public refineCompleteFieldCount: number,
    ) {
        super(stage, viewAllowed, participationAllowed, percentage, callToActionLabel);
    }

    getIconName(): string {
        return 'pencil-erasing';
    }
}