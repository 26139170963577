import React from 'react';
import {FieldValues} from 'react-hook-form';
import {AiContentResponse, AiAssistanceFieldNames, Localizer} from '@ideascale/commons';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useAppContext} from 'contexts/AppContext';
import {useRouteUtils} from 'hooks/useRouteUtils';
import {CampaignRichTextEditForm} from './CampaignRichTextEditForm';
import {UpdateCampaignBriefCommand} from 'commands/edit-mode/UpdateCampaignBriefCommand';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';

const CAMPAIGN_BRIEF_FIELDS = {
    brief: 'brief',
    briefEnabled: 'briefEnabled'
};

type CampaignBriefEditFormProps = {
    toggleModal: () => void;
    localizer: Localizer;
    fetchAiAssistedBrief: (prompt: string, fieldName: AiAssistanceFieldNames, chatId?: number) => Promise<AiContentResponse>;
}

export const CampaignBriefEditForm = (props: CampaignBriefEditFormProps) => {
    const {toggleModal, localizer, fetchAiAssistedBrief} = props;
    const {campaignHomeEditor, commandExecutor, validationErrors} = useEditModeContext();
    const {communityConfig: {aiTextAssistEnabled}} = useAppContext();
    const {campaignRouteMatch} = useRouteUtils();

    const onSubmit = (data: FieldValues) => {
        const campaignId = (campaignRouteMatch?.params as any)?.campaignId;
        const command = new UpdateCampaignBriefCommand(
            campaignHomeEditor,
            data[CAMPAIGN_BRIEF_FIELDS.brief],
            data[CAMPAIGN_BRIEF_FIELDS.briefEnabled],
            campaignId);
        commandExecutor.execute(command);
        validationErrors.clearError(command.getType());
        toggleModal();
    };

    return <CampaignRichTextEditForm localizer={localizer}
                                     toggleModal={toggleModal}
                                     aiFieldName={AiAssistanceFieldNames.CAMPAIGN_BRIEF}
                                     defaultValue={campaignHomeEditor.brief.value} toolbarType="default"
                                     label={localizer.msg('edit-mode.campaign.brief')}
                                     fieldId={CAMPAIGN_BRIEF_FIELDS.brief}
                                     operationType={CampaignOperationType.CHANGE_CAMPAIGN_BRIEF}
                                     onSubmit={onSubmit}
                                     switchFieldId={CAMPAIGN_BRIEF_FIELDS.briefEnabled}
                                     switchLabel={localizer.msg('edit-mode.campaign.brief-enable')}
                                     defaultSwitchState={campaignHomeEditor.brief.briefEnabled}
                                     aiAssistanceEnabled={aiTextAssistEnabled}
                                     fetchAiAssistedText={fetchAiAssistedBrief}/>;
};