import {ImageryOperation} from './ImageryOperation';
import {CommunityOperationType} from './CommunityOperationType';
import {ImageType} from '../enums/ImageType';

export class ChangeCommunityBannerOperation extends ImageryOperation {
    constructor(
        type: CommunityOperationType,
        fileId: number,
        public gradientEnabled: boolean,
        public blackPageTitleEnabled: boolean,
        public imageType: ImageType
    ) {
        super(type, fileId);
    }
}