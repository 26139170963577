import React, {useCallback, useEffect, useState} from 'react';
import {scroller} from 'react-scroll';
import {ParagraphSkeleton} from '@ideascale/ui';
import {ActivityCard} from '../idea/details/ActivityCard';
import {ReviewScaleReport} from './ReviewScaleReport';
import {ReviewScaleRank} from './ReviewScaleRank';
import {BaseStageActivityProps} from 'models/types/BaseStageActivityProps';
import {StageActivityType} from 'models/enums/StageActivityType';
import {ReviewscaleStageViewActivity} from 'models/stage-activity/ReviewscaleStageViewActivity';
import {ReviewNotesResponse} from 'models/stage-activity/ReviewNotesResponse';
import {ReviewscaleStageActivity} from 'models/stage-activity/ReviewscaleStageActivity';
import {StageActivity} from 'models/stage-activity/StageActivity';
import {ReviewscaleStageRankActivity} from 'models/stage-activity/ReviewscaleStageRankActivity';
import {StageActionResponse} from 'models/StageActionResponse';
import {FactorType} from 'models/enums/FactorType';
import {BaseIdeaStageSummary} from 'models/BaseIdeaStageSummary';
import {ReviewScaleResponseType} from 'models/enums/ReviewScaleResponseType';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import iconToolsLarge from 'assets/img/icon-tools-large.png';
import iconRightLarge from 'assets/img/icon-right-large.png';

type ReviewScaleActivityProps = BaseStageActivityProps & {
    fetchNotes: (stageId: number) => Promise<ReviewNotesResponse>;
    getEditRank: (stageId: number) => Promise<StageActivity>;
    saveFactorReview: (stageId: number, factorType: FactorType, responseType: ReviewScaleResponseType | undefined, factorId: number, note: string) => Promise<StageActionResponse<StageActivity, BaseIdeaStageSummary>>;
    updateIdeaStageSummary: <T extends BaseIdeaStageSummary>(stageSummary: T) => void;
};

export const ReviewScaleActivity = (props: ReviewScaleActivityProps) => {
    const {
        localizer,
        isCurrentStage,
        headerSummary,
        headerTitle,
        fetchActivity,
        stageId,
        fetchNotes,
        getEditRank,
        saveFactorReview,
        updateIdeaStageSummary,
        stageKey
    } = props;

    const [loading, setLoading] = useState(true);
    const [activity, setActivity] = useState<ReviewscaleStageActivity>();

    const onEditRank = (stageId: number) => {
        getEditRank(stageId).then(response => setActivity(response as ReviewscaleStageActivity));
    };

    const activityContainerId = stageKey ? stageKey : 'reviewscale-activity';

    const loadActivity = useCallback((scrollToActivityHeader?: boolean) => {
        setLoading(true);
        fetchActivity(stageId).then(response => {
            setActivity(response as ReviewscaleStageActivity);
            setLoading(false);
        });

        if (scrollToActivityHeader) {
            scroller.scrollTo(activityContainerId, {
                offset: -150
            });
        }
    }, [fetchActivity, activityContainerId, stageId]);

    const onCardEntered = () => {
        if (!activity) {
            loadActivity();
        }
    };

    useEffect(() => {
        if (isCurrentStage && !activity) {
            loadActivity();
        }
    }, [activity, isCurrentStage, loadActivity]);

    const onSaveFactorReview = useCallback(async (factorType: FactorType, responseType: ReviewScaleResponseType | undefined, factorId: number, note: string) => {
        try {
            const response = await saveFactorReview(stageId, factorType, responseType, factorId, note);
            if (!response.error) {
                updateIdeaStageSummary(response.stageSummary);
            }
            setActivity(response.stageActivity as ReviewscaleStageRankActivity);
            return response;
        } catch (e: any) {
            throw e;
        }
    }, [saveFactorReview, stageId, updateIdeaStageSummary]);

    return (
        <div className="pb-3" id={activityContainerId}>
            <ActivityCard containerClass="campaign-stages"
                          headerTitle={headerTitle}
                          headerSummary={
                              <p className="mb-0 mt-3 fw-normal">
                                  {headerSummary}
                              </p>
                          }
                          isCurrentStage={isCurrentStage}
                          svgIconSprite={svgIconPath}
                          defaultOpen={isCurrentStage} onEntered={onCardEntered}>
                {(loading || !activity)
                    ?
                    <ParagraphSkeleton rows={6}/>
                    :
                    <>
                        {
                            (activity as ReviewscaleStageViewActivity)?.editRatingAllowed && activity?.activityType === StageActivityType.VIEW &&
                            <div className="text-end">
                                <button className="btn btn-secondary"
                                        onClick={() => onEditRank(stageId)}>{localizer.msg('stage.reviewscale.edit-rank')}</button>
                            </div>
                        }

                        <p className="mb-4">{activity?.activityMessage}</p>

                        {
                            !(activity.viewAllowed || activity.participationAllowed)
                                ?
                                <div className="text-center my-4">
                                    {
                                        isCurrentStage
                                            ?
                                            <img src={iconToolsLarge} alt="Hammer and wrench in a cross"/>
                                            :
                                            <img src={iconRightLarge} alt="Checked Icon"/>
                                    }
                                </div>
                                :
                                <>
                                    {
                                        activity?.activityType === StageActivityType.VIEW &&
                                        <ReviewScaleReport localizer={localizer}
                                                           stageId={stageId}
                                                           fetchNotes={fetchNotes}
                                                           activity={activity as ReviewscaleStageViewActivity}/>
                                    }
                                    {
                                        activity?.activityType === StageActivityType.PARTICIPATE &&
                                        <ReviewScaleRank onSaveFactorReview={onSaveFactorReview}
                                                         localizer={localizer}
                                                         loadActivity={loadActivity}
                                                         rankActivity={activity as ReviewscaleStageRankActivity}/>
                                    }
                                </>
                        }
                    </>
                }
            </ActivityCard>
        </div>
    );
};