import {useCallback} from 'react';
import {useApiErrorResponseHandler} from '@ideascale/commons';
import {useLocalizer} from './useLocalizer';
import {useCommunityService} from './useService';
import {MemberProfileAndFieldValuesHolder} from 'models/MemberProfileAndFieldValuesHolder';
import {CustomFieldRequestValue} from 'models/types/ProfileFieldUpdateRequest';
import {GenericResponse} from 'models/GenericResponse';
import {MemberProfileAndFieldRequestParameters} from 'models/MemberProfileAndFieldRequestParameters';

export const useJoinCommunityService = () => {
    const communityService = useCommunityService();
    const localizer = useLocalizer();
    const {throwApiErrorResponse} = useApiErrorResponseHandler({localizer});

    const fetchJoinCommunityFields = useCallback(async () => {
        if (communityService !== null) {
            try {
                return await communityService.fetchJoinCommunityFields();
            } catch (error: any) {
                throwApiErrorResponse(error);
            }
        }
        return undefined;
    }, [throwApiErrorResponse, communityService]);

    const fetchJoinCommunityProfileFields = useCallback(async () => {
        if (communityService !== null) {
            try {
                return await communityService.fetchJoinCommunityProfileFields();
            } catch (error: any) {
                throwApiErrorResponse(error);
            }
        }
        return MemberProfileAndFieldValuesHolder.EMPTY;
    }, [throwApiErrorResponse, communityService]);

    const joinCommunity = useCallback(async (fieldValues: CustomFieldRequestValue[]) => {
        if (communityService !== null) {
            try {
                return await communityService.joinCommunity(fieldValues);
            } catch (error: any) {
                throwApiErrorResponse(error);
            }
        }
        return GenericResponse.EMPTY;
    }, [throwApiErrorResponse, communityService]);

    const saveJoinCommunityProfileFields = useCallback(async (profileAndFieldRequestParams: MemberProfileAndFieldRequestParameters) => {
        if (communityService !== null) {
            try {
                return await communityService.saveJoinCommunityProfileFields(profileAndFieldRequestParams);
            } catch (error: any) {
                throwApiErrorResponse(error);
            }
        }
        return GenericResponse.EMPTY;
    }, [throwApiErrorResponse, communityService]);

    return {
        fetchJoinCommunityFields,
        fetchJoinCommunityProfileFields,
        joinCommunity,
        saveJoinCommunityProfileFields,
    };
};