import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {debounce} from 'lodash';
import {ActionButton, Icon, ReviewScaleRating} from '@ideascale/ui';
import {
    isHtmlInject,
    isJavascriptInject,
    Localizer,
    useApiErrorResponseHandler,
    useToggle,
    uuid
} from '@ideascale/commons';
import {ReviewFactor} from 'models/stage-activity/ReviewFactor';
import {FactorResponse} from 'models/stage-activity/FactorResponse';
import {ReviewScaleResponseType} from 'models/enums/ReviewScaleResponseType';
import {FactorType} from 'models/enums/FactorType';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {StageActionResponse} from 'models/StageActionResponse';
import {StageActivity} from 'models/stage-activity/StageActivity';
import {BaseIdeaStageSummary} from 'models/BaseIdeaStageSummary';
import styles from './ReviewFactorRankForm.module.scss';

type ReviewQuestionProps = {
    localizer: Localizer;
    factor: ReviewFactor;
    factorType: FactorType;
    factorResponse: FactorResponse | undefined;
    onChangeRankOrNote: (factorType: FactorType, responseType: ReviewScaleResponseType | undefined, factorId: number, note: string) => Promise<StageActionResponse<StageActivity, BaseIdeaStageSummary>>;
}

export const ReviewFactorRankForm = (props: ReviewQuestionProps) => {
    const {localizer, factor, factorType, factorResponse, onChangeRankOrNote} = props;
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});

    const sliderResponseRef = useRef<ReviewScaleResponseType>();
    const noteInputRef = useRef<HTMLTextAreaElement>(null);
    const [showNoteField, toggleNoteField] = useToggle(false);
    const [error, setError] = useState<{ type: 'factorResponse' | 'noteField', message: string } | null>();
    const [submitting, setSubmitting] = useState(false);

    const [ratingClass, setRatingClass] = useState('');
    const [ariaLiveValue, setAriaLiveValue] = useState('');

    const marks = useMemo(() => ({
        1: {
            style: {
                color: '#999'
            },
            label: localizer.msg('stage.reviewscale.na')
        },
        2: {
            style: {
                color: '#666'
            },
            label: localizer.msg('stage.reviewscale.l')
        },
        3: {
            style: {
                color: '#FF9800'
            },
            label: localizer.msg('stage.reviewscale.m')
        },
        4: {
            style: {
                color: '#384EC1'
            },
            label: localizer.msg('stage.reviewscale.h')
        },
    }), [localizer]);

    const getSliderValue = useCallback(() => {
        switch (factorResponse?.responseType) {
            case ReviewScaleResponseType.HIGH:
                return 4;
            case ReviewScaleResponseType.MEDIUM:
                return 3;
            case ReviewScaleResponseType.LOW:
                return 2;
            case ReviewScaleResponseType.NA:
                return 1;
            default:
                return 0;
        }
    }, [factorResponse?.responseType]);

    const getResponseType = (value: number) => {
        switch (value) {
            case 4:
                sliderResponseRef.current = ReviewScaleResponseType.HIGH;
                return ReviewScaleResponseType.HIGH;
            case 3:
                sliderResponseRef.current = ReviewScaleResponseType.MEDIUM;
                return ReviewScaleResponseType.MEDIUM;
            case 2:
                sliderResponseRef.current = ReviewScaleResponseType.LOW;
                return ReviewScaleResponseType.LOW;
            case 1:
                sliderResponseRef.current = ReviewScaleResponseType.NA;
                return ReviewScaleResponseType.NA;
        }
    };

    const getNote = () => {
        return noteInputRef.current ? noteInputRef.current.value : '';
    };

    const onAddOrEditReviewClick = () => {
        toggleNoteField();
        setTimeout(() => {
            noteInputRef.current?.focus();
        }, 0);
    };

    const changeRankOrNote = useRef(
        debounce((factorType: FactorType, responseType: ReviewScaleResponseType | undefined, factorId: number, note: string) => {
            void onChangeRankOrNote(factorType, responseType, factorId, note);
        }, 1000)).current;

    const onChangeRating = (value: number) => {
        setRatingClass(`reviewed value-${value}`);
        void changeRankOrNote(factorType, getResponseType(value), factor.id, getNote());
        sliderResponseRef.current = getResponseType(value);
        setAriaLiveValue(sliderResponseRef.current ? sliderResponseRef.current : '');
    };

    const onSliderFocus = (event: React.FocusEvent<HTMLDivElement>) => {
        if (!sliderResponseRef.current && !factorResponse?.responseType) {
            const valueNow = (event.target as HTMLDivElement).getAttribute('aria-valuenow');
            setRatingClass(`reviewed value-${valueNow}`);
            void changeRankOrNote(factorType, getResponseType(Number(valueNow)), factor.id, getNote());
            sliderResponseRef.current = getResponseType(Number(valueNow));
        }
    };

    const validateNoteField = () => {
        let errorMessage = '';
        let errorType: 'factorResponse' | 'noteField' = 'noteField';

        if (!sliderResponseRef.current && !factorResponse?.responseType) {
            if (factorType === FactorType.CONSTRAINT) {
                errorMessage = localizer.msg('stage.reviewscale.constrains-required-msg');
            } else {
                errorMessage = localizer.msg('stage.reviewscale.rank-required-msg');
            }
            errorType = 'factorResponse';
        } else if (isHtmlInject(getNote())) {
            errorMessage = localizer.msg('common.errors.html');
        } else if (isJavascriptInject(getNote())) {
            errorMessage = localizer.msg('common.errors.js');
        }
        if (errorMessage) {
            setError({type: errorType, message: errorMessage});
            noteInputRef.current?.focus();
            return false;
        } else {
            if (error) {
                setError(null);
            }
            return true;
        }
    };

    const onClickNoteSave = async () => {
        if (validateNoteField()) {
            setSubmitting(true);
            try {
                const responseType = factorType === FactorType.CONSTRAINT ? factorResponse?.responseType : sliderResponseRef.current;
                await onChangeRankOrNote(factorType, responseType, factor.id, getNote());
                setError(null);
                toggleNoteField(false);
            } catch (error: any) {
                handleErrorResponse(error, {
                    setFormError: () => {
                        setError({type: 'noteField', message: error?.data?.validationErrors?.note});
                    }
                });
            } finally {
                setSubmitting(false);
            }
        }
    };

    const hasNoteFieldError = useCallback(() => {
        return error?.type === 'noteField';
    }, [error]);

    useEffect(() => {
        if (getSliderValue()) {
            setRatingClass(`reviewed value-${getSliderValue()}`);
            sliderResponseRef.current = getResponseType(getSliderValue());
        }
    }, [getSliderValue]);

    const formLabelId = uuid();

    return (
        <form aria-labelledby={formLabelId}>
            <div className="row">
                <div className="col-md-7 col-xs-12">
                    <div className="d-flex align-content-center">
                        <p className="h6" id={formLabelId}>
                            {factor.name}
                        </p>
                        <span aria-hidden="true"
                              className={`ms-1 h6 ${factorResponse?.factorId ? 'question-completed' : 'd-none'}`}
                              role="presentation"> &#10003; </span>
                        <span
                            className="sr-only">{factorResponse?.factorId ? localizer.msg('stage.common.question-completed') : ''}</span>
                    </div>
                    {
                        factor.description &&
                        <p className="factor-desc">{factor.description}</p>
                    }
                    <div className="mb-3">
                        {
                            (!factorResponse?.note && !showNoteField) &&
                            <button className="btn btn-link text-decoration-none p-0 fw-normal"
                                    type="button" onClick={onAddOrEditReviewClick}
                                    data-test-element-id="btn-add-review-note">
                                {localizer.msg('stage.reviewscale.add-review-note')}
                            </button>
                        }
                        <div className={`comment-area  ${!showNoteField ? 'd-none' : ''}`}>
                            <div className={`form-group ${error ? 'has-error' : ''}`}>
                                <label className="sr-only" htmlFor={`comment-text-${factor.id}-${factorType}`}>
                                    {localizer.msg('stage.reviewscale.add-review-note')}
                                </label>
                                <textarea className="form-control" name="note" ref={noteInputRef}
                                          id={`comment-text-${factor.id}-${factorType}`} onChange={validateNoteField}
                                          defaultValue={factorResponse?.note}/>
                                {
                                    error &&
                                    <div data-role="field-error" className="help-block"
                                         role="alert" aria-live="polite">{error.message ?? error}</div>
                                }

                            </div>
                            <div className="form-group">
                                <ActionButton className="btn-sm" loading={submitting} type="button"
                                              onClick={onClickNoteSave}>
                                    {localizer.msg('stage.reviewscale.save')}
                                </ActionButton>
                            </div>
                        </div>
                        <div className={`comment-container ${!factorResponse?.note || showNoteField ? 'd-none' : ''}`}>
                            {
                                factorResponse?.note
                            }
                            <button className="btn btn-link p-0 ms-1 mt-n2" title={localizer.msg('edit')}
                                    onClick={onAddOrEditReviewClick}
                                    type="button">
                                <Icon iconSpritePath={svgIconsPath} name="pencil" width={16} height={16}/>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 col-xs-12">
                    {
                        factorType === FactorType.CONSTRAINT
                            ?
                            <>
                                <button
                                    className={`btn fw-normal me-2 px-3 py-1 ${factorResponse?.responseType === ReviewScaleResponseType.YES ? 'btn-primary' : 'btn-secondary'}`}
                                    type="button"
                                    onClick={() => {
                                        if (factorResponse?.responseType !== ReviewScaleResponseType.YES) {
                                            void onChangeRankOrNote(factorType, ReviewScaleResponseType.YES, factor.id, getNote());
                                        }
                                    }}>
                                    {localizer.msg('stage.reviewscale.yes')}
                                </button>
                                <button
                                    className={`btn fw-normal px-3 py-1 ${factorResponse?.responseType === ReviewScaleResponseType.NO ? 'btn-primary' : 'btn-secondary'}`}
                                    type="button"
                                    onClick={() => {
                                        if (factorResponse?.responseType !== ReviewScaleResponseType.NO) {
                                            void onChangeRankOrNote(factorType, ReviewScaleResponseType.NO, factor.id, getNote());
                                        }
                                    }}>
                                    {localizer.msg('stage.reviewscale.no')}
                                </button>
                            </>
                            :
                            <div className="row">
                                <div className="col">
                                    <span
                                        className="sr-only">{localizer.msg('stage.reviewscale.review-value-factors-slider')}</span>
                                    {
                                        ariaLiveValue
                                            ? <span className="sr-only" aria-live="assertive">
                                                {localizer.msg('stage.reviewscale.selected-cost-factor', {factor: ariaLiveValue})}
                                              </span>
                                            : null
                                    }
                                    <ReviewScaleRating className={ratingClass} disabled={hasNoteFieldError()} min={1}
                                                       max={4} marks={marks}
                                                       step={1}
                                                       onFocus={(e) => onSliderFocus(e)}
                                                       onChange={onChangeRating}
                                                       defaultValue={getSliderValue()}
                                                       ariaLabelForHandle={localizer.msg('common.slider-cursor')}/>
                                </div>
                                <div className={`col ps-0 flex-grow-0 mt-n1 ${styles.reviewStatus}`}>
                                    <span className={`${factorResponse?.factorId ? 'review-completed' : 'd-none'}`}
                                          role="presentation">
                                        <Icon iconSpritePath={svgIconsPath} name="check" fill="#666"/>
                                     </span>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </form>
    );
};