import {ChoiceGroup, CustomField, FieldDataValueType, FieldType, ValidationRule} from '@ideascale/commons';

export class ProfileFieldData extends CustomField {
    id: number;
    currency?: string;

    constructor(
        id: number,
        key: string,
        type: FieldType,
        label: string,
        validationRules: ValidationRule[] = [],
        privateField: boolean,
        moderatorOnly: boolean,
        teamOnly: boolean,
        value?: FieldDataValueType,
        truncatedInstruction?: string,
        instruction?: string,
        optionGroups?: ChoiceGroup[],
        traditionalCheckbox?: boolean,
        currency?: string,
    ) {
        super(key, type, label, validationRules, value, truncatedInstruction, instruction, privateField, moderatorOnly, teamOnly, optionGroups, traditionalCheckbox);
        this.id = id;
        this.currency = currency;
    }

}