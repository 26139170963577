import React, {useCallback, useMemo} from 'react';
import {FieldValues, useForm, useWatch} from 'react-hook-form';
import {Button, InfoTip, Modal} from '@ideascale/ui';
import {isHtmlInject, Localizer, useApiErrorResponseHandler, useHandleFormSubmit} from '@ideascale/commons';
import {VersionPayload} from 'models/landing-page/VersionPayload';
import {LandingPageData} from 'models/landing-page/LandingPageData';

type CreateNewVersionModalProps = {
    localizer: Localizer;
    open: boolean;
    toggle: () => void;
    onSave: (data: VersionPayload, publish: boolean) => Promise<LandingPageData>;
    onDoneEditing: (landingPageData: LandingPageData) => void;
    onDoneEditingAndPublish: (LandingPageData: LandingPageData) => Promise<void>;
}

const MAX_TITLE_LENGTH = 32;
const MAX_NOTE_LENGTH = 500;

const CREATE_NEW_VERSION_FIELDS = {
    title: 'pageVersion.title',
    note: 'pageVersion.note',
} as const;

export const CreateNewVersionModal = (props: CreateNewVersionModalProps) => {
    const {toggle, open, localizer, onSave, onDoneEditingAndPublish, onDoneEditing} = props;
    const {register, formState: {errors, isSubmitting}, handleSubmit, setError, control} = useForm();
    const onFormSubmit = useHandleFormSubmit(handleSubmit);
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const noteValue = useWatch({name: CREATE_NEW_VERSION_FIELDS.note, defaultValue: '', control});

    const createVersion = useCallback(async (data: FieldValues) => {
        try {
            data.pageVersion.title = data.pageVersion.title.trim();
            data.pageVersion.note = data.pageVersion.note?.trim();
            const landingPageData = await onSave(data.pageVersion as VersionPayload, false);
            onDoneEditing(landingPageData);
        } catch (error) {
            handleErrorResponse(error, {setFormError: setError});
        }
    }, [handleErrorResponse, onDoneEditing, onSave, setError]);

    const createVersionAndPublish = useCallback(async (data: FieldValues) => {
        try {
            data.pageVersion.title = data.pageVersion.title.trim();
            data.pageVersion.note = data.pageVersion.note?.trim();
            const landingPageData = await onSave(data.pageVersion as VersionPayload, true);
            await onDoneEditingAndPublish(landingPageData);
        } catch (error) {
            handleErrorResponse(error, {setFormError: setError});
        }
    }, [handleErrorResponse, onDoneEditingAndPublish, onSave, setError]);

    const remainingLength = useMemo(() => {
        return MAX_NOTE_LENGTH - (noteValue?.length ?? 0);
    }, [noteValue]);

    return (
        <Modal isOpen={open} toggle={toggle} title={localizer.msg('landing-page.create-new--version-modal.title')}
               autoFocus={false}>
            <form onSubmit={onFormSubmit(createVersionAndPublish, isSubmitting)}>
                <p className="text-gray font-size-sm">{localizer.msg('landing-page.create-new--version-modal.description')}</p>
                <div className={`form-group ${errors?.pageVersion?.title ? 'has-error' : ''}`}>
                    <label className="control-label fw-bold my-1 d-flex align-items-end" htmlFor="version-title">
                        <span>
                            {localizer.msg('landing-page.action-topbar.edit-mode.version-edit-modal.version-title')}
                        </span>
                        <InfoTip id="title-infotip" placement="top"
                                 content={localizer.msg('landing-page.action-topbar.edit-mode.version-edit-modal.title-infotip-content')}/>
                    </label>
                    <input className="form-control"
                           type="text"
                           autoFocus={true}
                           id="version-title"
                           maxLength={MAX_TITLE_LENGTH}
                           {...register(CREATE_NEW_VERSION_FIELDS.title, {
                               validate: value => !isHtmlInject(value || '') || localizer.msg('common.errors.html'),
                               required: localizer.msg('landing-page.action-topbar.edit-mode.version-edit-modal.version-title-required')
                           })}/>
                    {
                        errors?.pageVersion?.title && (
                            <p className="invalid-feedback d-block" data-role="field-error" aria-live="polite">
                                {errors?.pageVersion?.title.message}
                            </p>
                        )
                    }
                </div>

                <div className={`form-group ${errors?.pageVersion?.note ? 'has-error' : ''}`}>
                    <label className="control-label fw-bold my-1" htmlFor="version-note">
                        {localizer.msg('landing-page.action-topbar.edit-mode.version-edit-modal.note')}
                        <span className={`${errors?.pageVersion?.note ? '' : 'text-gray fw-lighter'} ms-1`}>
                            ({localizer.msg('common.optional')})
                        </span>
                    </label>
                    <textarea className="form-control" rows={4} id="version-note" maxLength={MAX_NOTE_LENGTH}
                              placeholder={localizer.msg('landing-page.action-topbar.edit-mode.version-edit-modal.note-placeholder')}
                              {...register(CREATE_NEW_VERSION_FIELDS.note, {
                                  validate: value => !isHtmlInject(value || '') || localizer.msg('common.errors.html'),
                              })}/>
                    <div className="text-muted mt-1">
                        {localizer.msg('idea.form.field.feedback.text-length', {count: remainingLength})}
                    </div>
                    {
                        errors?.pageVersion?.note && (
                            <p className="invalid-feedback d-block" data-role="field-error" aria-live="polite">
                                {errors?.pageVersion?.note.message}
                            </p>
                        )
                    }
                </div>

                <div className="mt-5 mb-3 text-end">
                    <Button className="me-3" color="secondary" type="button" onClick={handleSubmit(createVersion)}
                            disabled={isSubmitting}>
                        {localizer.msg('landing-page.create-new--version-modal.save-new-version')}
                    </Button>
                    <Button color="primary" type="submit" disabled={isSubmitting}
                            onSubmit={handleSubmit(createVersionAndPublish)}>
                        {localizer.msg('landing-page.create-new--version-modal.save-and-publish')}
                    </Button>
                </div>
            </form>
        </Modal>
    );
};
