import React from 'react';
import {AiAssistanceFieldNames, AiContentResponse, Localizer} from '@ideascale/commons';
import {FieldValues} from 'react-hook-form';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useAppContext} from 'contexts/AppContext';
import {useRouteUtils} from 'hooks/useRouteUtils';
import {UpdateCampaignDescriptionCommand} from 'commands/edit-mode/UpdateCampaignDescriptionCommand';
import {CampaignRichTextEditForm} from './CampaignRichTextEditForm';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';

const CAMPAIGN_DESCRIPTION = 'description';

type CampaignDescriptionEditFormProps = {
    toggleModal: () => void;
    localizer: Localizer;
    fetchAiAssistedDescription: (prompt: string, fieldName: AiAssistanceFieldNames, chatId?: number) => Promise<AiContentResponse>;
}

export const CampaignDescriptionEditForm = (props: CampaignDescriptionEditFormProps) => {
    const {toggleModal, localizer, fetchAiAssistedDescription} = props;
    const {campaignHomeEditor, commandExecutor, validationErrors} = useEditModeContext();
    const {communityConfig: {aiTextAssistEnabled}} = useAppContext();
    const {campaignRouteMatch} = useRouteUtils();

    const onSubmit = (data: FieldValues) => {
        const campaignId = (campaignRouteMatch?.params as any)?.campaignId;
        const command = new UpdateCampaignDescriptionCommand(campaignHomeEditor, data[CAMPAIGN_DESCRIPTION], campaignId);
        commandExecutor.execute(command);
        validationErrors.clearError(command.getType());
        toggleModal();
    };

    return <CampaignRichTextEditForm localizer={localizer}
                                     aiFieldName={AiAssistanceFieldNames.CAMPAIGN_STATEMENT}
                                     toggleModal={toggleModal}
                                     defaultValue={campaignHomeEditor.description}
                                     operationType={CampaignOperationType.CHANGE_CAMPAIGN_DESCRIPTION}
                                     label={localizer.msg('edit-mode.campaign.statements')}
                                     toolbarType="textOnly"
                                     fieldId={CAMPAIGN_DESCRIPTION}
                                     onSubmit={onSubmit}
                                     aiAssistanceEnabled={aiTextAssistEnabled}
                                     fetchAiAssistedText={fetchAiAssistedDescription}/>;
};