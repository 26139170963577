import React, {Fragment} from 'react';
import {useLocalizer} from 'hooks/useLocalizer';
import {CampaignPerformancesData} from 'models/CampaignPerformancesData';
import styles from '../DigestContent.module.scss';

type CampaignPerformancesProps = {
    campaignPerformanceData: CampaignPerformancesData;
}

export const CampaignPerformances = (props: CampaignPerformancesProps) => {
    const {campaignPerformanceData} = props;
    const localizer = useLocalizer();
    return (
        <Fragment>
            <ul className="ps-4">
                <li className="mb-4">
                    <span className="fw-bold">{campaignPerformanceData.engagedMember.count}</span>
                    {' '} {localizer.msg('notification.moderator-digest.engaged-members')}
                    <span className={`${styles.activityPercentage}
                ${campaignPerformanceData.engagedMember.percentage > 0 ? styles.activityPercentageUp : ''}
                ${campaignPerformanceData.engagedMember.percentage < 0 ? styles.activityPercentageDown : ''} float-end`}>
                {campaignPerformanceData.engagedMember.percentage}%</span>
                </li>
                <li className="mb-3">
                    <span className="fw-bold">{campaignPerformanceData.idea.count}</span>
                    {' '} {localizer.msg('notification.moderator-digest.ideas')}
                    <span className={`${styles.activityPercentage} 
                    ${campaignPerformanceData.idea.percentage > 0 ? styles.activityPercentageUp : ''}
                    ${campaignPerformanceData.idea.percentage < 0 ? styles.activityPercentageDown : ''} float-end`}>
                    {campaignPerformanceData.idea.percentage}%
                </span>
                </li>
                <li className="mb-3">
                    <span className="fw-bold">{campaignPerformanceData.comment.count}</span>
                    {' '} {localizer.msg('notification.moderator-digest.comments')}
                    <span className={`${styles.activityPercentage} 
                    ${campaignPerformanceData.comment.percentage > 0 ? styles.activityPercentageUp : ''}
                    ${campaignPerformanceData.comment.percentage < 0 ? styles.activityPercentageDown : ''} float-end`}>
                    {campaignPerformanceData.comment.percentage}%
                </span>
                </li>
                <li className="mb-3">
                    <span className="fw-bold">{campaignPerformanceData.vote.count}</span>
                    {' '} {localizer.msg('notification.moderator-digest.votes')}
                    <span className={`${styles.activityPercentage} 
                    ${campaignPerformanceData.vote.percentage > 0 ? styles.activityPercentageUp : ''}
                    ${campaignPerformanceData.vote.percentage < 0 ? styles.activityPercentageDown : ''} float-end`}>
                    {campaignPerformanceData.vote.percentage}%
                </span>
                </li>
                <li className="mb-3">
                    <span className="fw-bold">{campaignPerformanceData.ideaView.count}</span>
                    {' '} {localizer.msg('notification.moderator-digest.idea-views')}
                    <span className={`${styles.activityPercentage} 
                    ${campaignPerformanceData.ideaView.percentage > 0 ? styles.activityPercentageUp : ''}
                    ${campaignPerformanceData.ideaView.percentage < 0 ? styles.activityPercentageDown : ''} float-end`}>
                    {campaignPerformanceData.ideaView.percentage}%
                </span>
                </li>
                <li className="mb-3">
                    <span className="fw-bold">{campaignPerformanceData.selectedIdea.count}</span>
                    {' '} {localizer.msg('notification.moderator-digest.selected')}
                    <span className={`${styles.activityPercentage} 
                    ${campaignPerformanceData.selectedIdea.percentage > 0 ? styles.activityPercentageUp : ''}
                    ${campaignPerformanceData.selectedIdea.percentage < 0 ? styles.activityPercentageDown : ''} float-end`}>
                    {campaignPerformanceData.selectedIdea.percentage}%
                 </span>
                </li>
                <li className="mb-3">
                    <span className="fw-bold">{campaignPerformanceData.implementedIdea.count}</span>
                    {' '} {localizer.msg('notification.moderator-digest.implemented')}
                    <span className={`${styles.activityPercentage} 
                    ${campaignPerformanceData.implementedIdea.percentage > 0 ? styles.activityPercentageUp : ''}
                    ${campaignPerformanceData.implementedIdea.percentage < 0 ? styles.activityPercentageDown : ''} float-end`}>
                    {campaignPerformanceData.implementedIdea.percentage}%
                </span>
                </li>
            </ul>
            {
                campaignPerformanceData.activityTrendsUrl &&
                <a className="btn btn-secondary d-inline-block mt-2" href={campaignPerformanceData.activityTrendsUrl}>
                    {localizer.msg('notification.moderator-digest.activity-trends')}
                </a>
            }
        </Fragment>
    );
};