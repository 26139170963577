import {CampaignStage} from '@ideascale/commons';

export type Timeline = {
    id: number;
    label: string;
    expireAt: string;
    expired: boolean;
}

export class CampaignWorkFlowData {
    static readonly EMPTY = new CampaignWorkFlowData([], []);

    constructor(
        public timeline: Timeline[],
        public stages: CampaignStage[],
    ) {
    }
}