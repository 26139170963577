import {Operation} from './Operation';
import {CampaignOperationType} from './CampaignOperationType';
import {SidebarItem} from '../sidebar/SidebarItem';

export class ChangeCampaignSidebarOrderOperation implements Operation {
    constructor(public type: CampaignOperationType,
                public sidebarItems: SidebarItem[],
                public campaignId: number) {
    }

    getType(): CampaignOperationType {
        return this.type;
    }
}