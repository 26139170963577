import {Command} from './Command';
import {CommunityHomeEditor} from './Editors/CommunityHomeEditor';
import {WidgetField} from 'models/edit-mode/WidgetField';
import {CommunityOperationType} from 'models/edit-mode/CommunityOperationType';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';
import {Operation} from 'models/edit-mode/Operation';
import {ChangeContactWidgetOperation} from 'models/edit-mode/ChangeContactWidgetOperation';

export class UpdateContactWidgetCommand implements Command {
    private readonly communityHomeEditor: CommunityHomeEditor;
    private readonly oldCustomWidget: WidgetField;
    private readonly newCustomWidget: WidgetField;
    private readonly name: CommunityOperationType;

    constructor(communityHomeEditor: CommunityHomeEditor, newWidgetField: WidgetField) {
        this.communityHomeEditor = communityHomeEditor;
        this.oldCustomWidget = this.communityHomeEditor.customWidget;
        this.newCustomWidget = newWidgetField;
        this.name = CommunityOperationType.CHANGE_CONTACT_WIDGET;
    }

    execute(): void {
        this.communityHomeEditor.customWidget = this.newCustomWidget;
    }

    getType(): CommunityOperationType | CampaignOperationType  {
        return this.name;
    }

    getOperation(): Operation {
        return new ChangeContactWidgetOperation(this.name, this.communityHomeEditor.customWidget.title, this.communityHomeEditor.customWidget.content);
    }

    undo(): void {
        this.communityHomeEditor.customWidget = this.oldCustomWidget;
    }
}