import React, {Fragment, useRef} from 'react';
import {Collapse} from 'reactstrap';
import {InfiniteScrollLoadMoreButton, Localizer, MemberLink, useIntersectionObserver} from '@ideascale/commons';
import {ParagraphSkeleton, Scrollbar, ScrollbarInstance} from '@ideascale/ui';
import {HtmlRenderer} from 'components/shared/HtmlRenderer';
import {RevisionHistory} from 'models/RevisionHistory';
import styles from './RevisionHistoryView.module.scss';

type RevisionHistoryProps = {
    localizer: Localizer;
    isOpen: boolean;
    actorId: number;
    scrollTargetId: string;
    editedText: string;
    loadMore: () => any;
    hasMore: boolean;
    revisions: RevisionHistory[];
}

export const RevisionHistoryView = (props: RevisionHistoryProps) => {
    const {isOpen, localizer, actorId, editedText, hasMore, loadMore, revisions} = props;
    const scrollbarContainer = useRef<ScrollbarInstance>(null);
    const loadMoreButtonRef = useRef<HTMLButtonElement>(null);

    useIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: loadMore,
        enabled: hasMore,
        options: {
            root: scrollbarContainer.current?.container
        }
    });

    return (
        <Collapse isOpen={isOpen}>
            <div className={`${styles.ideaRevisionHistory} rounded py-1`}
                 aria-label={localizer.msg('idea.revision-history.section')}>
                <div>
                    {revisions.length === 0
                        ? <ParagraphSkeleton rows={5}/>
                        : <Scrollbar
                            className="pe-3"
                            autoHeight
                            autoHeightMax={200}
                            ref={scrollbarContainer}>
                            <Fragment>
                                {revisions.map(({id, member, content, createdAt}) =>
                                    <div key={id} className={`${styles.historyItem} px-3 pt-3 text-break`}>
                                        <div className="d-flex justify-content-between mb-1 font-size-sm">
                                            <div>
                                                <MemberLink className="fw-bold" id={member.id}
                                                            identityHidden={member.identityHidden}>
                                                    {actorId === member.id
                                                        ? localizer.msg('common.you')
                                                        : member.name
                                                    }
                                                </MemberLink> {editedText}
                                            </div>
                                            <time>{localizer.dateTime(createdAt)}</time>
                                        </div>
                                        <HtmlRenderer content={content}/>
                                    </div>
                                )}
                                <InfiniteScrollLoadMoreButton hasMore={hasMore}
                                                              localizer={localizer}
                                                              loading={false}
                                                              onCLick={loadMore}
                                                              loadMoreButtonRef={loadMoreButtonRef}/>
                            </Fragment>
                        </Scrollbar>
                    }
                </div>
            </div>
        </Collapse>
    );
};