export enum CampaignEditableFieldType {
    CAMPAIGN_NAME = 'CAMPAIGN_NAME',
    CAMPAIGN_SUBTITLE = 'CAMPAIGN_SUBTITLE',
    CAMPAIGN_DESCRIPTION = 'CAMPAIGN_DESCRIPTION',
    CAMPAIGN_BRIEF = 'CAMPAIGN_BRIEF',
    CAMPAIGN_TOS = 'CAMPAIGN_TOS',
    CAMPAIGN_BANNER_IMAGE = 'CAMPAIGN_BANNER_IMAGE',
    CAMPAIGN_LOGO = 'CAMPAIGN_LOGO',
    CAMPAIGN_FEATURE_IMAGE = 'CAMPAIGN_FEATURE_IMAGE',
    CAMPAIGN_MODERATORS_TEAM = 'CAMPAIGN_MODERATORS_TEAM',
    GLOBAL_MODERATORS_TEAM = 'GLOBAL_MODERATORS_TEAM',
    CAMPAIGN_WIDGET_ONE = 'CAMPAIGN_WIDGET_ONE',
    CAMPAIGN_WIDGET_TWO = 'CAMPAIGN_WIDGET_TWO',
    CAMPAIGN_WIDGET_THREE = 'CAMPAIGN_WIDGET_THREE',
    UNKNOWN = 'UNKNOWN',
}