import React, {Fragment, useCallback, useMemo, useState} from 'react';
import {PagedResponseContent, PageTheme, useApiErrorResponseHandler, useToggle} from '@ideascale/commons';
import {useLandingPageService} from 'hooks/useService';
import {useLocalizer} from 'hooks/useLocalizer';
import {useTranslation} from 'hooks/useTranslation';
import {CommunityTagsComponent} from 'components/landing-page/CommunityTagsComponent';
import {CommonConfigModal} from 'components/landing-page/CommonConfigModal';
import {ConfigWrapper} from 'components/landing-page/ConfigWrapper';
import {TranslationModal} from 'shared/TranslationModal';
import {Component} from 'models/landing-page/Component';
import {LandingPageComponentType} from 'models/enums/landing-page/LandingPageComponentType';
import {UiAttribute} from 'models/landing-page/UiAttribute';
import {TagData} from 'models/TagData';
import {TranslationConfig} from 'models/TranslationConfig';
import {TranslationContentType} from 'models/enums/landing-page/TranslationContentType';
import {TranslationFieldType} from 'models/enums/TranslationFieldType';
import {TranslationContentResponse} from 'models/landing-page/TranslationContentResponse';
import {TagListComponent} from 'models/landing-page/TagListComponent';

type CommunityTagsContainerProps = {
    showConfig: boolean;
    data: TagListComponent;
    theme: PageTheme;
    showTranslationConfig: boolean,
    onComponentEdited: () => void;
    translationLanguageId?: number,
}

export const CommunityTagsContainer = (props: CommunityTagsContainerProps) => {
    const {showConfig, data, theme, showTranslationConfig, translationLanguageId, onComponentEdited} = props;
    const [showEditModal, toggleEditModal] = useToggle(false);
    const [showTranslationModal, toggleTranslationModal] = useToggle(false);
    const [tagConfig, setTagConfig] = useState(data);
    const localizer = useLocalizer();
    const {showErrorMessage} = useApiErrorResponseHandler({localizer});
    const landingPageService = useLandingPageService();
    const {getTranslatedContent, saveTranslatedContent} = useTranslation();

    const onEditClicked = useCallback(() => {
        toggleEditModal();
    }, [toggleEditModal]);

    const getTags = useCallback(async () => {
        if (landingPageService) {
            return landingPageService.fetchTags({page: 0, limit: 40});
        }
        return PagedResponseContent.nullObject<TagData>();
    }, [landingPageService]);

    const saveCommunityTagsData = useCallback(async (component: Component) => {
        if (landingPageService) {
            return landingPageService.saveCommonConfig(component);
        }
        return {
            id: 0,
            visible: false,
            type: LandingPageComponentType.STATISTICS,
            attribute: UiAttribute.EMPTY,
            title: ''
        };
    }, [landingPageService]);

    const onToggleVisibility = async () => {
        const newData = {...tagConfig};
        newData.visible = !newData.visible;
        await updateTagsData(newData);
        onComponentEdited();
    };

    const updateTagsData = useCallback(async (component: Component) => {
        try {
            const response = await saveCommunityTagsData(component);
            if (response) {
                setTagConfig(response as TagListComponent);
            }
        } catch (e: any) {
            if (e.data && 'validationErrors' in e.data) {
                throw e.data;
            } else {
                showErrorMessage(e);
            }
        }
    }, [saveCommunityTagsData, showErrorMessage]);

    const onTranslationClicked = useCallback(() => {
        if (translationLanguageId !== undefined) {
            toggleTranslationModal();
        }
    }, [toggleTranslationModal, translationLanguageId]);

    const onTextTranslated = useCallback((response: TranslationContentResponse) => {
        setTagConfig(prev => {
            const newData = {...prev};
            if (response.contentType === TranslationContentType.PAGE_COMPONENT_TITLE) {
                newData.title = response.translation;
            } else {
                newData.subtitle = response.translation;
            }
            return newData;
        });
    }, []);

    const translationConfigs = useMemo(() => {
        if (theme === PageTheme.EASTMAN) {
            return [
                new TranslationConfig(
                    TranslationContentType.PAGE_COMPONENT_TITLE,
                    tagConfig.id,
                    TranslationFieldType.TEXT,
                ),
                new TranslationConfig(
                    TranslationContentType.PAGE_COMPONENT_SUBTITLE,
                    tagConfig.id,
                    TranslationFieldType.TEXT,
                ),
            ];
        } else {
            return [
                new TranslationConfig(
                    TranslationContentType.PAGE_COMPONENT_TITLE,
                    tagConfig.id,
                    TranslationFieldType.TEXT,
                ),
            ];
        }
    }, [tagConfig.id, theme]);

    return (
        <Fragment>
            <ConfigWrapper visible={tagConfig.visible}
                           toggleVisibility={onToggleVisibility}
                           showTranslationConfig={showTranslationConfig}
                           onTranslationClicked={onTranslationClicked}
                           showConfig={showConfig}
                           localizer={localizer}
                           onEditClicked={onEditClicked}
                           className={`${tagConfig.attribute?.className} community-tags-component`}
                           accessibleName={localizer.msg('landing-page.tags')}>
                <CommunityTagsComponent localizer={localizer} editMode={showConfig || showTranslationConfig}
                                        theme={theme} data={tagConfig} getTags={getTags}/>
            </ConfigWrapper>
            {
                showEditModal &&
                <CommonConfigModal theme={theme} open={showEditModal} renderSubtitle={theme === PageTheme.EASTMAN}
                                   onComponentEdited={onComponentEdited}
                                   toggle={toggleEditModal} configData={tagConfig} updateData={updateTagsData}
                                   localizer={localizer}/>
            }
            {
                showTranslationModal && translationLanguageId !== undefined &&
                <TranslationModal translationLanguageId={translationLanguageId} localizer={localizer}
                                  open={showTranslationModal}
                                  toggle={toggleTranslationModal}
                                  onTextTranslated={onTextTranslated}
                                  onComponentEdited={onComponentEdited}
                                  translationConfigs={translationConfigs}
                                  getTranslatedContent={getTranslatedContent}
                                  saveTranslatedContent={saveTranslatedContent}/>
            }
        </Fragment>
    );
};