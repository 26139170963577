import React, {useRef} from 'react';
import {Icon, useOnClickOutside} from '@ideascale/ui';
import {LabelData, Localizer, useToggle} from '@ideascale/commons';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {CommentLabelsAction} from './CommentLabelsAction';
import {CommentActionsPermission} from 'models/comments/CommentActionsPermission';
import {CommentLabelsResponse} from 'models/comments/CommentLabelsResponse';
import styles from './CommentActions.module.scss';

type CommentActionsProps = {
    localizer: Localizer;
    commentId: number;
    actionsPermitted: CommentActionsPermission;
    annotated: boolean;
    onReplyClick: () => void;
    onAttachmentClick: () => void;
    labelsPermitted: boolean;
    onDeleteClick: () => void;
    onAnnotateClick: () => void;
    pinned?: boolean;
    onEditClick?: () => void;
    onPinUnpinClick?: (pinned: boolean) => void;
    fetchCommentLabelsById?: (commentId: number) => Promise<CommentLabelsResponse>;
    onAddLabelClick?: (commentId: number, labelData: LabelData) => Promise<LabelData[]>;
    onRemoveLabelClick?: (commentId: number, labelData: LabelData) => Promise<void>;
    onReportAbuseClick?: () => void;
}

export const CommentActions = (props: CommentActionsProps) => {
    const {
        localizer,
        commentId,
        actionsPermitted,
        pinned,
        annotated,
        onReplyClick,
        labelsPermitted,
        onAttachmentClick,
        onEditClick,
        onDeleteClick,
        onAnnotateClick,
        onPinUnpinClick,
        fetchCommentLabelsById,
        onAddLabelClick,
        onRemoveLabelClick,
        onReportAbuseClick,
    } = props;
    const [showCommentActions, toggleCommentActions] = useToggle(false);
    const commentActionsRef = useRef(null);

    const onOutSideClick = () => {
        toggleCommentActions(false);
    };

    useOnClickOutside(commentActionsRef, onOutSideClick);

    return (
        <div className={`${styles.commentActions} rounded d-inline-flex`}
             ref={commentActionsRef}>
            <button className={`btn btn-sm px-0 py-1 me-n1 d-lg-none ${showCommentActions ? 'd-none' : ''}`}
                    type="button"
                    onClick={() => toggleCommentActions(true)}>
                <Icon iconSpritePath={svgIconsPath} name="dots-vertical" width={20} height={20}/>
                <span className="sr-only">actions</span>
            </button>
            <div
                className={`${styles.commentActionMenu}  ${showCommentActions ? 'd-inline-flex' : ''} rounded p-1`}>
                {
                    actionsPermitted.replyAllowed && (
                        <button className={`btn btn-sm btn-link ${styles.commentAction} p-1`}
                                title={localizer.msg('comments.actions.reply')} onClick={() => {
                            onReplyClick();
                            toggleCommentActions(false);
                        }}>
                            <Icon iconSpritePath={svgIconsPath} name="arrow-curved-left"/>
                        </button>
                    )
                }
                {
                    actionsPermitted.attachmentAllowed && (
                        <button className={`btn btn-sm btn-link ${styles.commentAction} p-1`}
                                title={localizer.msg('comments.actions.add-attachment')}
                                onClick={onAttachmentClick}>
                            <Icon iconSpritePath={svgIconsPath} name="paperclip"/>
                        </button>
                    )
                }
                {
                    actionsPermitted.modificationAllowed && (
                        <button className={`btn btn-sm btn-link ${styles.commentAction} p-1 me-1`}
                                title={localizer.msg('comments.actions.edit')} onClick={onEditClick}>
                            <Icon iconSpritePath={svgIconsPath} name="pencil" width={13} height={13}/>
                        </button>
                    )
                }
                {
                    actionsPermitted.deletionAllowed && (
                        <button className={`btn btn-sm btn-link ${styles.commentAction} p-1 me-1`}
                                title={localizer.msg('comments.actions.delete')} onClick={onDeleteClick}>
                            <Icon iconSpritePath={svgIconsPath} name="trash-can-open" width={14} height={14}/>
                        </button>
                    )
                }

                {
                    (labelsPermitted || actionsPermitted.abuseReportingAllowed) && fetchCommentLabelsById && onAddLabelClick && onRemoveLabelClick && (
                        <CommentLabelsAction
                            localizer={localizer}
                            fetchLabels={fetchCommentLabelsById}
                            targetId={commentId}
                            addLabel={onAddLabelClick}
                            svgIconPath={svgIconsPath}
                            removeLabel={onRemoveLabelClick} toggleButtonTag="button"
                            toggleButtonClass={`btn-sm rounded ${styles.commentAction} p-1 me-1`}
                            onReportAbuseClick={onReportAbuseClick}
                        />
                    )
                }

                {
                    actionsPermitted.pinnedAllowed && onPinUnpinClick && (
                        <>
                            {
                                !pinned && (
                                    <button className={`btn btn-sm btn-link ${styles.commentAction} p-1 me-1`}
                                            title={localizer.msg('comments.actions.pin')}
                                            onClick={() => onPinUnpinClick(true)}>
                                        <Icon iconSpritePath={svgIconsPath} name="thumbtack" width={14} height={14}/>
                                    </button>
                                )
                            }
                            {
                                pinned && (
                                    <button className={`btn btn-sm btn-link ${styles.commentAction} p-1 me-1`}
                                            title={localizer.msg('comments.actions.unpin')}
                                            onClick={() => onPinUnpinClick(false)}>
                                        <Icon iconSpritePath={svgIconsPath} name="thumbtack-slash" width={14} height={14}/>
                                    </button>
                                )
                            }
                        </>
                    )
                }
                {
                    actionsPermitted.annotationAllowed && (
                        <button
                            className={`btn btn-sm btn-link ${styles.commentAction} ${annotated ? 'pointer-events-none active' : ''} p-1`}
                            title={localizer.msg('comments.actions.annotate')}
                            onClick={onAnnotateClick}>
                            <Icon iconSpritePath={svgIconsPath} name="arrow-up-to-line"
                                  className={`${annotated ? 'annotated' : ''}`}/>
                        </button>
                    )
                }
            </div>
        </div>
    );
};
