import React, {useEffect} from 'react';
import {useFieldArray} from 'react-hook-form';
import {Progress} from 'reactstrap';
import {FileError, FileInput} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {
    AttachmentType,
    ClassificationAttribute,
    ClassificationsHolder,
    ClassificationUtil,
    ClassifiedAttachment,
    FileAttachment,
    FileAttachmentDetail,
    FileUploadResponse,
    ImageAttachmentField,
    Localizer,
    useFileUploadHandlers,
    useHookFormContext
} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';

const MAX_FILE_NUMBER = 5;

type CreateFileAttachmentProps = {
    inputId: string;
    value?: FileAttachmentDetail[];
    localizer: Localizer;
    multiple?: boolean;
    maxFileSizeInMb: number;
    uploadFile: (data: FormData, onUploadProgress: (progressEvent: ProgressEvent) => void) => Promise<FileUploadResponse>;
    onChange?: (attachments: FileAttachmentDetail[]) => void;
    clearAll?: boolean;
    classificationEnabled?: boolean;
    attachmentType: AttachmentType;
    selectedClassification?: ClassificationAttribute;
    appliedClassifications?: ClassifiedAttachment;
    updateAppliedAttachmentClassifications?: (key: string, value: ClassifiedAttachment) => void;
    onClassificationError?: (error: FileError) => void;
    classificationsMenuData?: ClassificationsHolder | {};
    includeAttachmentNote?: boolean;
}

export const CreateFileAttachment = (props: CreateFileAttachmentProps) => {
    const {
        localizer,
        inputId,
        multiple = false,
        maxFileSizeInMb,
        uploadFile,
        clearAll,
        appliedClassifications,
        classificationEnabled = false,
        selectedClassification,
        updateAppliedAttachmentClassifications,
        onClassificationError,
        includeAttachmentNote = false,
        classificationsMenuData,
        attachmentType,
    } = props;
    const {authentication: {actor}} = useAppContext();
    const {control, clearErrors} = useHookFormContext<{ [key: string]: FileAttachmentDetail[] }>();
    const {
        fields,
        append,
        remove,
        update,
    } = useFieldArray<{ [key: string]: FileAttachmentDetail[] }, string>({
        control,
        shouldUnregister: true,
        name: inputId
    });

    const onAttachmentClassificationChange = (fileName: string, classificationAttribute?: ClassificationAttribute) => {
        if (classificationEnabled && selectedClassification) {
            const combinedFiles = {...appliedClassifications, [fileName]: classificationAttribute ?? {}};
            updateAppliedAttachmentClassifications?.(inputId, combinedFiles);
        }
    };

    const onClassifiedAttachmentRemove = (fileName: string) => {
        if (classificationEnabled && selectedClassification) {
            const combinedFiles = {...appliedClassifications};
            delete combinedFiles[fileName];
            updateAppliedAttachmentClassifications?.(inputId, combinedFiles);
        }
    };

    const onFileUploadSuccess = (fileName: string, previewImageUrl: string) => {
        const attachment = new FileAttachment(0, fileName, previewImageUrl, attachmentType, !!previewImageUrl, false, '');
        const newAttachment = new FileAttachmentDetail('', true, actor, new Date().toDateString(), attachment);
        if (multiple) {
            append(newAttachment);
        } else {
            update(0, newAttachment);
        }
        clearErrors(inputId);
        onAttachmentClassificationChange(fileName, selectedClassification);
    };

    const onRemoveAttachment = (attachmentIndex: number) => {
        remove(attachmentIndex);
        onClassifiedAttachmentRemove(fields[attachmentIndex].filename);
    };

    const {
        averagePercentageCompleted,
        onInputFileChange,
        handleFileUploadError,
        uploadQueueSize,
    } = useFileUploadHandlers({
        localizer,
        onUploadSuccess: onFileUploadSuccess,
        uploadFile,
        maxFileSizeInMb,
    });

    useEffect(() => {
        if (clearAll) {
            fields.forEach((field, index) => remove(index));
        }
    }, [clearAll, fields, remove]);

    return (
        <div className="react-file-uploader">
            <FileInput
                labels={{
                    browseFile: localizer.msg('common.form.attachment.upload.browse-file'),
                    dragAndDrop: localizer.msg('common.form.attachment.upload.drag-drop'),
                    dropFile: localizer.msg('common.form.attachment.upload.drop-file-here'),
                    or: localizer.msg('common.or'),
                    uploadInstruction: localizer.msg('common.form.attachment.upload.upload-warning'),
                    classification: classificationEnabled && selectedClassification ? ClassificationUtil.getLabel(selectedClassification) : undefined,
                    classificationType: classificationEnabled && selectedClassification ? ClassificationUtil.getClassificationLevel(selectedClassification) : ''
                }}
                inputId={inputId} onSuccess={onInputFileChange}
                onError={(error) => handleFileUploadError(error)}
                maxFileNumber={multiple ? MAX_FILE_NUMBER : undefined}
                maxSizeInMB={maxFileSizeInMb}
                multiple={multiple}
                svgIconPath={svgIconsPath}
                onClassificationError={onClassificationError}
                hasClassification={classificationEnabled ? classificationEnabled && !!selectedClassification : undefined}
            />

            {
                (uploadQueueSize > 0) &&
                <Progress value={averagePercentageCompleted} max={100} barClassName="progressbar"
                          className="progressbar-track mt-1">
                    {localizer.msg('common.form.attachment.upload.uploading')}
                </Progress>
            }

            {
                fields.map((field, attachmentIndex) => (
                    <ImageAttachmentField field={field}
                                          inputId={inputId}
                                          key={field.id}
                                          localizer={localizer}
                                          fieldIndex={attachmentIndex}
                                          onRemoveAttachment={onRemoveAttachment}
                                          includeAttachmentNote={includeAttachmentNote}
                                          classificationEnabled={classificationEnabled}
                                          appliedClassifications={appliedClassifications}
                                          classificationsMenuData={classificationsMenuData}
                                          onAttachmentClassificationChange={onAttachmentClassificationChange}/>
                ))
            }
        </div>
    );
};