import React, {Fragment, useMemo, useRef, useState} from 'react';
import {useInfiniteQuery, useQueryClient} from 'react-query';
import {QueryFunctionContext} from 'react-query/types/core/types';
import {AlertType, InfiniteScrollLoadMoreButton, Localizer, useApiErrorResponseHandler, useIntersectionObserver} from '@ideascale/commons';
import {Button, Icon, ParagraphSkeleton, Scrollbar, ScrollbarInstance} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {NotificationList} from 'components/notification/NotificationList';
import {NotificationResponse} from 'models/NotificationResponse';
import {PageParameters} from 'models/types/PageParameters';
import {NotificationData} from 'models/NotificationData';
import {NotificationType} from 'models/enums/NotificationType';
import {NOTIFICATION_FILTERS, QUERY_KEYS} from 'constants/AppConstants';
import './ProfileNotifications.scss';

type PagedNotifications = {
    data: NotificationData[],
    allPages: number;
    nextPage?: number;
    prevPage?: number;
}

type ProfileNotificationProps = {
    localizer: Localizer;
    actorId: number;
    fetchProfileNotifications: (groupId: number, pageParameters: PageParameters) => Promise<NotificationResponse>;
    getDataForDigestModal: (year: string, notificationType: NotificationType, month?: string, week?: string) => void;
    markNotificationsAsSeen: () => Promise<void>;
}

const STALE_TIME = 20 * 60 * 1000;
const PAGE_SIZE = 10;

export const ProfileNotifications = (props: ProfileNotificationProps) => {
    const {localizer, actorId, fetchProfileNotifications, markNotificationsAsSeen, getDataForDigestModal} = props;
    const queryClient = useQueryClient();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const scrollbarContainer = useRef<ScrollbarInstance>(null);
    const loadMoreButtonRef = useRef<HTMLButtonElement>(null);
    const [filter, setFilter] = useState<typeof NOTIFICATION_FILTERS[number]>(NOTIFICATION_FILTERS[0]);
    const [remaining, setRemaining] = useState(0);

    const {
        data,
        isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage
    } = useInfiniteQuery(
        [QUERY_KEYS.PROFILE_NOTIFICATIONS, filter.groupId],
        async ({queryKey, pageParam = 0}: QueryFunctionContext) => {
            const {notifications, remainingUnseen} = await fetchProfileNotifications(Number(queryKey[1]), {
                page: pageParam,
                limit: PAGE_SIZE
            });
            setRemaining(remainingUnseen);
            return {
                data: notifications.content,
                nextPage: notifications.hasMore ? notifications.pageNo + 1 : undefined,
                prevPage: notifications.pageNo > 0 ? notifications.pageNo - 1 : undefined,
                allPages: Math.ceil(notifications.totalElements / notifications.pageSize)
            } as PagedNotifications;
        },
        {
            staleTime: STALE_TIME,
            refetchOnWindowFocus: true,
            onError: (error: any) => {
                handleErrorResponse(error, {
                    fallbackMessage: {
                        type: AlertType.error,
                        message: localizer.msg('notification.error.message')
                    }
                });
            },
            getNextPageParam: ({nextPage}) => nextPage,
            getPreviousPageParam: ({prevPage}) => prevPage
        }
    );

    useIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: fetchNextPage,
        enabled: hasNextPage || false,
        options: {
            root: scrollbarContainer.current?.container
        }
    });

    const onFilterChanged = (group: { groupId: number, labelLocalization: string }) => {
        setFilter(group);
        scrollbarContainer.current?.container.scrollTo(0, 0);
    };

    const onMarkAsSeen = async () => {
        await markNotificationsAsSeen();
        await queryClient.invalidateQueries(QUERY_KEYS.PROFILE_NOTIFICATIONS);
    };

    const ListSkeleton = useMemo(() => {
        return <Fragment>
            <ParagraphSkeleton rows={2}/>
            <ParagraphSkeleton rows={2}/>
            <ParagraphSkeleton rows={2}/>
        </Fragment>;
    }, []);

    return (
        <div className="card panel mb-3 py-3 profile-notifications">
            <div
                className="card-header py-0 bg-transparent border-bottom-0 d-flex justify-content-between align-items-center">
                <div>
                    <h2 id="notification-heading" className="mb-0 font-size-md fw-bold h6">{localizer.msg('notification.header')}</h2>
                </div>
                <div>
                    <Button color="light" className="btn-sm" type="button" disabled={!(remaining > 0)}
                            onClick={onMarkAsSeen}>
                        <Icon iconSpritePath={svgIconPath} name="broom-sweeping"
                              className="me-1"/> {localizer.msg('notification.mark.seen')}
                    </Button>
                </div>
            </div>
            <Scrollbar className="card-body px-2 py-0 border-top-0 notifications-list-holder"
                       autoHeight
                       autoHeightMax={'60vh'}
                       ref={scrollbarContainer}>
                <div className="mt-4 mb-3 ps-1">
                    {NOTIFICATION_FILTERS.map(value =>
                        <button key={value.groupId} onClick={() => onFilterChanged(value)}
                                className={`btn rounded font-size-sm me-2 ${filter.groupId === value.groupId ? 'btn-secondary' : 'btn-cancel fw-normal'}`}>
                            {localizer.msg(value.labelLocalization)}
                        </button>
                    )}
                </div>
                {
                    isLoading
                        ? ListSkeleton
                        : data && data.pages[0].data.length > 0
                            ? (
                                <Fragment>
                                    <ul className="list-unstyled px-3 me-2 notifications-list" aria-labelledby="notification-heading">
                                        {data && data.pages.map((page, index) => (
                                            <NotificationList getDataForDigestModal={getDataForDigestModal}
                                                              localizer={localizer}
                                                              notifications={page.data} actorId={actorId} key={index}/>
                                        ))}
                                    </ul>
                                    <InfiniteScrollLoadMoreButton hasMore={!!hasNextPage}
                                                                  localizer={localizer}
                                                                  loading={isLoading}
                                                                  skeletonComponent={ListSkeleton}
                                                                  onCLick={fetchNextPage}
                                                                  loadMoreButtonRef={loadMoreButtonRef}/>
                                </Fragment>
                            ) : (
                                <div className="fw-bold font-size-sm py-2">
                                    {localizer.msg('notification.no-new')}
                                </div>
                            )
                }
                {
                    isFetchingNextPage
                        ?
                        ListSkeleton
                        :
                        hasNextPage &&
                        <div className="text-center">
                            <button className="btn btn-gray" ref={loadMoreButtonRef}
                                    onClick={() => fetchNextPage()}>
                                {localizer.msg('notification.load-more')}
                            </button>
                        </div>
                }
            </Scrollbar>
        </div>
    );
};