import React, {Fragment} from 'react';
import {AiContentResponse, AiAssistanceFieldNames, useToggle} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {EditActionWrapper} from './shared/EditActionWrapper';
import {HtmlRenderer} from './shared/HtmlRenderer';
import {CampaignBriefEditForm} from './edit-mode/CampaignBriefEditForm';
import {CampaignDetailsData} from 'models/CampaignDetailsData';
import {CampaignEditableFieldType} from 'models/edit-mode/CampaignEditableFieldType';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';
import styles from './CampaignDetails.module.scss';

type CampaignBriefProps = {
    campaignData: CampaignDetailsData;
    fetchAiAssistedBrief: (prompt: string, fieldName: AiAssistanceFieldNames, chatId?: number) => Promise<AiContentResponse>;
}

export const CampaignBrief = (props: CampaignBriefProps) => {
    const {campaignData, fetchAiAssistedBrief} = props;
    const localizer = useLocalizer();
    const {homeConfig, campaignHomeEditor, validationErrors} = useEditModeContext();
    const {editModeEnabled} = useAppContext();
    const [editBriefModal, toggleEditBriefModal] = useToggle(false);

    return (
        <Fragment>
            {
                editModeEnabled && homeConfig.isOperationAllowed(CampaignEditableFieldType.CAMPAIGN_BRIEF)
                    ? <div className={`card panel mb-4 ${styles.campaignBrief}`} data-test-element-id="campaign-brief">
                        <div className="card-body">
                            <h2 className="h4 text-muted">{localizer.msg('campaign.details.campaign-brief')}</h2>
                            <EditActionWrapper
                                className={campaignHomeEditor.brief.value && campaignHomeEditor.brief.briefEnabled ? 'd-flex' : 'd-inline-flex'}
                                editAllowed={homeConfig.isOperationAllowed(CampaignEditableFieldType.CAMPAIGN_BRIEF)}
                                configurationChildren={<CampaignBriefEditForm localizer={localizer}
                                                                              toggleModal={toggleEditBriefModal}
                                                                              fetchAiAssistedBrief={fetchAiAssistedBrief}/>}
                                hasError={validationErrors.hasError(CampaignOperationType.CHANGE_CAMPAIGN_BRIEF)}
                                modalTitle={localizer.msg('edit-mode.campaign.edit-brief')}
                                modalOpen={editBriefModal}
                                modalToggle={toggleEditBriefModal}
                                editBtnTitle={localizer.msg('edit-mode.campaign.edit-brief')}
                                editBtnTestElementId="btn-edit-campaign-brief">
                                {
                                    campaignHomeEditor.brief.value && campaignHomeEditor.brief.briefEnabled
                                        ? <HtmlRenderer className="w-100" content={campaignHomeEditor.brief.value}
                                                        elementId="campaign-brief"/>
                                        : <div className="text-muted pe-5" data-test-element-id="campaign-brief">
                                            {localizer.msg('edit-mode.campaign.add-brief')}
                                        </div>
                                }
                            </EditActionWrapper>
                        </div>
                    </div>
                    : campaignData.summary && (
                    <div className={`card panel mb-4 ${styles.campaignBrief} ${editModeEnabled ? 'opacity-50' : ''}`} data-test-element-id="campaign-brief">
                        <div className="card-body">
                            <h2 className="h4">{localizer.msg('campaign.details.campaign-brief')}</h2>
                            <HtmlRenderer content={campaignData.summary} elementId="campaign-brief"/>
                        </div>
                    </div>
                )
            }
        </Fragment>
    );
};