import cloneDeep from 'lodash/cloneDeep';
import {useMutation, useQueryClient} from 'react-query';
import {useIdeaService} from './useIdeaService';
import {useIdeaUpdater} from './useIdeaUpdater';
import {PagedIdeas} from 'contexts/IdeasContext';
import {VoteType} from 'models/enums/VoteType';
import {VoteParameters} from 'models/VoteParameters';
import {VoteActionResponse} from 'models/VoteActionResponse';
import {IdeaSummary} from 'models/IdeaSummary';
import {IdeaDetail} from 'models/IdeaDetail';
import {BaseIdeaStageSummary} from 'models/BaseIdeaStageSummary';
import {QUERY_KEYS} from 'constants/AppConstants';

type ToggleVoteParams = {
    ideaId: number;
    voteType: VoteType;
    voteParameters: VoteParameters;
}

export const useVoteAction = (ideaListQueryKey: any = '') => {
    const {toggleVote} = useIdeaService();
    const {ideaDetailsQueryKey} = useIdeaUpdater();
    const queryClient = useQueryClient();

    const ideaListVoteMutation = useMutation(({
                                                  ideaId,
                                                  voteType,
                                                  voteParameters
                                              }: ToggleVoteParams): any => toggleVote(ideaId, voteType, voteParameters), {
        onSuccess: async (data: VoteActionResponse<BaseIdeaStageSummary>, {ideaId}) => {
            queryClient.setQueryData(ideaListQueryKey, (oldIdeas: any) => {
                if (oldIdeas) {
                    const newIdeas = cloneDeep(oldIdeas) as { pages: PagedIdeas[], pageParams: any };
                    newIdeas.pages.forEach((page: any) => {
                        page.data.forEach((idea: IdeaSummary) => {
                            if (idea.id === ideaId) {
                                idea.stageSummary = data.stageSummary;
                            }
                        });
                    });
                    return newIdeas;
                }
                return [];
            });
        }
    });

    const ideaDetailsVoteMutation = useMutation(({
                                                     ideaId,
                                                     voteType,
                                                     voteParameters
                                                 }: ToggleVoteParams): any => toggleVote(ideaId, voteType, voteParameters), {
        onSuccess: async (data: VoteActionResponse<BaseIdeaStageSummary>, {ideaId}) => {
            queryClient.setQueryData(ideaDetailsQueryKey(ideaId), (oldIdeaDetails) => {
                const newIdeaDetails = cloneDeep(oldIdeaDetails) as IdeaDetail;
                newIdeaDetails.stageSummary = data.stageSummary;
                return newIdeaDetails;
            });
        },
        onSettled: () => {
            queryClient.invalidateQueries(QUERY_KEYS.IDEA_DETAILS).then();
        }
    });

    return {ideaListVoteMutation, ideaDetailsVoteMutation};
};