import React, {Fragment, useCallback} from 'react';
import {useBeforeUnload, useLocation, useNavigate} from 'react-router-dom';
import {isEmpty} from 'lodash';
import {useEditModeContext} from 'contexts/EditModeContext';
import {
    AlertEvent,
    AlertType,
    buildAlertEventData,
    Campaign,
    eventDispatcher,
    useApiErrorResponseHandler,
    useToggle,
    useUrlQuery
} from '@ideascale/commons';
import {RequireAtLeastOne} from 'utils/TypeUtil';
import {useRouteUtils} from 'hooks/useRouteUtils';
import {useEditModeService} from 'hooks/useService';
import {useLocalizer} from 'hooks/useLocalizer';
import {useAppContext} from 'contexts/AppContext';
import {ROUTES} from 'shared/Routes';
import {CampaignPageTopbarEditModeContainer} from './CampaignPageTopbarEditModeContainer';
import {LandingPageTopbarEditModeContainer} from 'containers/topbar/LandingPageTopbarEditModeContainer';
import {HomePageTopbarEditModeContainer} from 'containers/topbar/HomePageTopbarEditModeContainer';
import {ConfirmCancellationModal} from 'components/edit-mode/ConfirmCancellationModal';
import {EDIT_MODE_EVENTS} from 'constants/AppConstants';
import {EditModeValidationErrors} from 'models/edit-mode/EditModeValidationErrors';
import {EditModeValidationError} from 'models/edit-mode/EditModeValidationError';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';
import {Operation} from 'models/edit-mode/Operation';
import isNumber from 'lodash/isNumber';

export const TopbarEditModeContainer = () => {
    const localizer = useLocalizer();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const navigate = useNavigate();
    const location = useLocation();
    const {
        landingPageRouteMatch,
        homeRouteMatch,
        landingPageActionRouteMatch,
        campaignRouteMatch
    } = useRouteUtils();
    const {updateCurrentCampaign} = useAppContext();
    const {
        getEditOperationData,
        publishable,
        resetEditMode,
        setValidationErrors,
        campaignHomeEditor,
    } = useEditModeContext();
    const editModeService = useEditModeService();
    const query = useUrlQuery();

    const [openConfirmationModal, toggleConfirmationModal] = useToggle(false);

    useBeforeUnload(useCallback(async (event) => {
        if (publishable) {
            event.preventDefault();
        }
    }, [publishable]));

    const exitEditMode = useCallback(() => {
        resetEditMode();
        const newUrlQuery = new URLSearchParams(query);
        newUrlQuery.delete(ROUTES.SEARCH_QUERY.EDIT_MODE);
        const pathname = landingPageActionRouteMatch !== null ? ROUTES.LANDING : location.pathname;
        navigate({pathname: pathname, search: newUrlQuery.toString()});
    }, [resetEditMode, landingPageActionRouteMatch, location.pathname, navigate, query]);

    const onCancelClicked = useCallback(() => {
        publishable ? toggleConfirmationModal() : exitEditMode();
    }, [exitEditMode, publishable, toggleConfirmationModal]);

    const updateCurrentCampaignData = useCallback((operations: Operation[]) => {
        const prepareUpdateData: Partial<Campaign> = {};

        const changeCampaignLogoOperation = operations?.find(op => op.getType() === CampaignOperationType.CHANGE_CAMPAIGN_LOGO);
        if (changeCampaignLogoOperation && campaignHomeEditor.campaignLogo) {
            prepareUpdateData.image = campaignHomeEditor.campaignLogo.url;
        }

        const changeCampaignNameOperation = operations?.find(op => op.getType() === CampaignOperationType.CHANGE_CAMPAIGN_NAME);
        if (changeCampaignNameOperation && campaignHomeEditor.name) {
            prepareUpdateData.name = campaignHomeEditor.name;
        }

        if (!isEmpty(prepareUpdateData)) {
            updateCurrentCampaign(prepareUpdateData as RequireAtLeastOne<Campaign>);
        }
    }, [campaignHomeEditor.campaignLogo, campaignHomeEditor.name, updateCurrentCampaign]);

    const onPublish = useCallback(async () => {
        const operations = getEditOperationData();
        if (editModeService !== null && publishable) {
            try {
                if (homeRouteMatch) {
                    await editModeService.publishCommunityOperations(operations);
                } else if (campaignRouteMatch) {
                    const campaignId = (campaignRouteMatch?.params as any)?.campaignId;
                    if (isNumber(+campaignId)) {
                        await editModeService.publishCampaignOperations(+campaignId, operations);
                        updateCurrentCampaignData(operations);
                    }
                }

                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, localizer.msg('edit-mode.publish-success-msg')));
                eventDispatcher.dispatch(EDIT_MODE_EVENTS.PUBLISHED);

                exitEditMode();
            } catch (e: any) {
                if (e.data?.validationErrors) {
                    setValidationErrors(new EditModeValidationErrors(e.data.validationErrors as EditModeValidationError[]));
                }

                handleErrorResponse(e, {
                    fallbackMessage: {
                        type: AlertType.error,
                        message: localizer.msg('edit-mode.field-validation-error-message')
                    }
                });
            }
        }
    }, [getEditOperationData, editModeService, publishable, homeRouteMatch, campaignRouteMatch, localizer, exitEditMode, updateCurrentCampaignData, handleErrorResponse, setValidationErrors]);

    const renderTopbarActions = useCallback(() => {
        if ([landingPageActionRouteMatch, landingPageRouteMatch].some(value => value !== null)) {
            return <LandingPageTopbarEditModeContainer/>;
        } else if (homeRouteMatch !== null) {
            return <HomePageTopbarEditModeContainer publishDisabled={!publishable}
                                                    onPublish={onPublish}
                                                    onCancel={onCancelClicked}/>;
        } else if (campaignRouteMatch !== null) {
            return <CampaignPageTopbarEditModeContainer publishDisabled={!publishable}
                                                        onCancel={onCancelClicked}
                                                        onPublish={onPublish}/>;
        } else {
            return <Fragment/>;
        }
    }, [landingPageActionRouteMatch, landingPageRouteMatch, homeRouteMatch, campaignRouteMatch, publishable, onPublish, onCancelClicked]);

    return (
        <Fragment>
            <div className="edit-mode-container edit-mode-topbar fixed-top">
                <nav className="navbar navbar-expand shadow-none">
                    <div className="collapse navbar-collapse d-flex justify-content-between px-4">
                        {renderTopbarActions()}
                    </div>
                </nav>
            </div>
            <ConfirmCancellationModal localizer={localizer}
                                      open={openConfirmationModal}
                                      onCancel={toggleConfirmationModal}
                                      onConfirmation={exitEditMode}/>
        </Fragment>
    );
};