import React from 'react';
import {ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle} from '@ideascale/ui';
import {Backdrop, useToggle} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {useIdeaListMode} from 'hooks/useIdeaListMode';
import {IdeaListOrder} from 'components/idea/list/IdeaListOrder';
import {IdeaListViewModes} from 'components/idea/list/IdeaListViewModes';
import {IdeaListViewMode} from 'models/enums/IdeaListViewMode';
import {IdeaListMode} from 'models/enums/IdeaListMode';
import {IdeaFilter} from 'models/enums/IdeaFilter';
import {OrderBy} from 'models/enums/OrderBy';
import {MyIdeaListMode} from 'models/enums/MyIdeaListMode';
import {MY_IDEAS_FILTER} from 'constants/AppConstants';
import './IdeaListNavigation.scss';

type MyIdeasListNavigationProps = {
    ideaMode: IdeaListMode;
    onChangeMode: (mode: IdeaListMode) => void;
    myIdeasMode: MyIdeaListMode;
    onChangeMyIdeaListMode: (mode: MyIdeaListMode) => void;
    orderBy: OrderBy;
    toggleOrder: () => void;
    ideaFilter?: IdeaFilter;
    onChangeFilter?: (filter: IdeaFilter) => void;
    listViewMode?: IdeaListViewMode;
    onClickViewMode?: (viewMode: IdeaListViewMode) => void;
}

export const MyIdeasListNavigation = (props: MyIdeasListNavigationProps) => {
    const {
        ideaMode,
        listViewMode,
        orderBy,
        toggleOrder,
        onClickViewMode,
        onChangeMode,
        myIdeasMode,
        onChangeMyIdeaListMode,
    } = props;
    const localizer = useLocalizer();
    const [filterDropdownOpen, toggleFilterDropdown] = useToggle(false);
    const [dropdownOpen, toggleDropdownOpen] = useToggle(false);
    const {getIdeaListModesWithoutRecommended} = useIdeaListMode();

    const getFilterLabel = () => {
        const filter = MY_IDEAS_FILTER.find(filter => filter.value.toLocaleLowerCase() === myIdeasMode.toLocaleLowerCase());
        if (filter) {
            return localizer.msg(filter.label);
        }
        return localizer.msg('profile.tabs.my-ideas');
    };

    return (
        <div className="idea-list-mode d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex justify-content-between">
                <div className="idea-filter-by me-3">
                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                        <ButtonDropdown drop="down"
                                        key="down"
                                        isOpen={filterDropdownOpen}
                                        toggle={toggleFilterDropdown}>
                            <Backdrop clicked={toggleFilterDropdown}/>

                            <DropdownToggle tag="button" data-test-element-id="idea-filter"
                                            className="btn btn-gray rounded-start border-end-0 text-capitalize"
                                            aria-label={localizer.msg('profile.tabs.filter-by-label', {filter: getFilterLabel()})}
                                            title={localizer.msg('profile.tabs.my-ideas')} caret>
                                {getFilterLabel()}&nbsp;&nbsp;
                            </DropdownToggle>

                            <DropdownMenu data-test-element-id="idea-filter-options">
                                {MY_IDEAS_FILTER.map(filter =>
                                    <DropdownItem
                                        key={filter.value}
                                        onClick={() => onChangeMyIdeaListMode(filter.value)}
                                        data-test-element-id={filter.value.toLowerCase()}
                                        className={`text-capitalize ${myIdeasMode.toLowerCase() === filter.value.toLowerCase() ? 'active' : ''}`}>
                                        {localizer.msg(filter.label)}
                                    </DropdownItem>
                                )}
                            </DropdownMenu>
                        </ButtonDropdown>
                    </div>
                </div>
                <div className="idea-sort-by">
                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                        <ButtonDropdown drop="down"
                                        key="down"
                                        isOpen={dropdownOpen}
                                        toggle={toggleDropdownOpen}>
                            <Backdrop clicked={toggleDropdownOpen}/>

                            <DropdownToggle tag="button" data-test-element-id="idea-sort-by"
                                            className="btn btn-gray rounded-start border-end-0 text-capitalize"
                                            aria-label={localizer.msg('profile.tabs.sort-by-label', {ideaMode})}
                                            title={localizer.msg('profile.tabs.sort-by')} caret>
                                {localizer.msg(`ideas.mode.${ideaMode}`)} &nbsp;&nbsp;
                            </DropdownToggle>

                            <DropdownMenu data-test-element-id="idea-sort-options">
                                {
                                    getIdeaListModesWithoutRecommended().filter(item => item !== IdeaListMode.RECOMMENDED).map(mode => (
                                            <DropdownItem
                                                key={mode}
                                                className={`text-capitalize ${mode.toLowerCase() === ideaMode.toLowerCase() ? 'active' : ''}`}
                                                data-test-element-id={mode.toLowerCase()}
                                                onClick={() => onChangeMode(mode)}>
                                                {localizer.msg(`ideas.mode.${mode}`)}
                                            </DropdownItem>
                                        )
                                    )
                                }
                            </DropdownMenu>
                        </ButtonDropdown>
                        <IdeaListOrder ideaMode={ideaMode} orderBy={orderBy} toggleOrder={toggleOrder}/>
                    </div>
                </div>
            </div>
            {
                listViewMode &&
                onClickViewMode &&
                <IdeaListViewModes listViewMode={listViewMode} onClickViewMode={onClickViewMode} localizer={localizer}/>
            }
        </div>
    );
};