import React from 'react';
import {Localizer, Member, MemberLink} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';

type PinnedByProps = {
    localizer: Localizer,
    author: Member
}

export const PinnedBy = ({localizer, author}: PinnedByProps) => {
    const {authentication} = useAppContext();

    return (
        <div className="pinned-by my-2">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8.79664 9.75495L12.6227 5.92894C13.632 6.57052 14.4848 6.80525 14.833 6.45316C15.3963 5.88982 14.4457 4.02376 12.7048 2.28288C10.9678 0.545921 9.09787 -0.408626 8.53453 0.154713C8.18635 0.502888 8.41717 1.35572 9.05875 2.36504L5.23274 6.19105C4.20386 5.56512 3.32364 5.36169 2.94026 5.74898C2.37301 6.31624 3.085 7.95931 4.53639 9.56326L0.679076 13.4206L0.585187 13.5145C0.342638 13.757 -0.189405 14.6842 0.057056 14.9306C0.299605 15.1732 1.22677 14.645 1.47323 14.4025L1.56712 14.3086L5.42443 10.4513C7.02838 11.9027 8.67537 12.6108 9.2387 12.0474C9.626 11.664 9.42257 10.7838 8.79664 9.75495Z"
                    fill="#B4B4B4"/>
            </svg>
            {' '} {localizer.msg('comments.pinned-by')} {' '}
            <MemberLink id={author.id} identityHidden={author.identityHidden}>
                <strong>{authentication.actor.id === author.id ? localizer.msg('comments.pinned-by-you') : author.name}</strong>
            </MemberLink>
        </div>
    );
};
