import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Icon, IdeascaleCustomRadio} from '@ideascale/ui';
import {ErrorReason} from '@ideascale/commons';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useLocalizer} from 'hooks/useLocalizer';
import {ConfigurationActionBar} from 'components/shared/ConfigurationActionBar';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useEditModeFormErrorHandler} from 'hooks/useEditModeFormErrorHandler';
import {LicenseUpgradeInfo} from './LicenseUpgradeInfo';
import {ErrorMessage} from './advanced-settings/ErrorMessage';
import {InfoMessage} from './advanced-settings/InfoMessage';
import {UpdateIdeaListViewModeCommand} from 'commands/edit-mode/UpdateIdeaListViewModeCommand';
import {IdeaListViewMode} from 'models/enums/IdeaListViewMode';
import {CommunityEditableFieldType} from 'models/edit-mode/CommunityEditableFieldType';
import {CommunityOperationType} from 'models/edit-mode/CommunityOperationType';

type IdeaListViewConfigurationFormProps = {
    toggleModal: () => void;
}
export const IdeaListViewConfigurationForm = (props: IdeaListViewConfigurationFormProps) => {
    const {toggleModal} = props;
    const localizer = useLocalizer();
    const {communityHomeEditor, commandExecutor, homeConfig, validationErrors} = useEditModeContext();
    const [selectedMode, setSelectedMode] = useState<IdeaListViewMode>();
    const [disabled, setDisabled] = useState(true);
    const {fieldError} = useEditModeFormErrorHandler({
        localizer,
        validationErrors: validationErrors.findError(CommunityOperationType.CHANGE_IDEA_VIEW_MODE)
    });

    const onUpdate = useCallback(() => {
        const command = new UpdateIdeaListViewModeCommand(communityHomeEditor, selectedMode as IdeaListViewMode);
        commandExecutor.execute(command);
        validationErrors.clearError(command.getType());
        toggleModal();
    }, [communityHomeEditor, selectedMode, commandExecutor, validationErrors, toggleModal]);

    const onChange = useCallback((mode: IdeaListViewMode) => {
        if (mode !== selectedMode) {
            setDisabled(false);
        }
        setSelectedMode(mode);
    }, [selectedMode]);

    useEffect(() => {
        setSelectedMode(homeConfig.getConfigField(CommunityEditableFieldType.IDEA_LIST_VIEW_MODE).value as IdeaListViewMode);
    }, [homeConfig]);

    const renderDisplayTypeIcon = (displayType: IdeaListViewMode) => {
        switch (displayType) {
            case IdeaListViewMode.LIST_VIEW:
                return <Icon className="me-1" iconSpritePath={svgIconPath}
                             name="rectangles-top-bottom"
                             width={16}
                             height={16}/>;
            case IdeaListViewMode.COMPACT_VIEW:
                return <Icon className="me-1"
                             iconSpritePath={svgIconPath}
                             name="lines-four-horizontal"
                             width={22}
                             height={14}/>;
            case IdeaListViewMode.GRID_VIEW:
                return <Icon className="me-1"
                             iconSpritePath={svgIconPath}
                             name="grid-squares"
                             width={18}
                             height={18}/>;
            default:
                return null;
        }
    };

    const renderDisplayTypeIconAndText = (viewMode: IdeaListViewMode) => {
        return <Fragment>
            {renderDisplayTypeIcon(viewMode)}
            {localizer.msg(`ideas.view-mode.${viewMode.toLowerCase()}`)}
        </Fragment>;
    };

    return (
        <Fragment>
            <InfoMessage cssClass="mt-0" message={localizer.msg('edit-mode.view-mode-change-info')}
                         localizer={localizer} textAlignment="start"/>
            <div className="d-flex align-items-center">
                <strong
                    className={`${validationErrors.findError(CommunityOperationType.CHANGE_IDEA_VIEW_MODE) ? 'has-error' : ''}`}>
                    {localizer.msg('edit-mode.choose-default-view')}
                </strong>
                {
                    !homeConfig.hasEditPermission(CommunityEditableFieldType.IDEA_LIST_VIEW_MODE)
                        ? (
                            <LicenseUpgradeInfo
                                triggerButtonId="campaign-logo-title-license-popover"
                                className="ms-2"
                                localizer={localizer}
                            />
                        )
                        : null
                }
            </div>
            <ErrorMessage message={fieldError('viewMode')?.message}/>
            {
                (homeConfig.hasEditPermission(CommunityEditableFieldType.IDEA_LIST_VIEW_MODE) && fieldError('viewMode')?.reason !== ErrorReason.FEATURE_IS_NOT_ACCESSIBLE)
                    ? (
                        <div className="d-flex mt-2">
                            {
                                Object.values(IdeaListViewMode)
                                    .filter(viewMode => viewMode !== IdeaListViewMode.UNDEFINED)
                                    .map(viewMode =>
                                        <IdeascaleCustomRadio
                                            key={viewMode}
                                            name="choose-default-view"
                                            defaultChecked={selectedMode as string}
                                            onClick={() => onChange(viewMode)}
                                            value={viewMode}
                                            inputId={`choose-default-view-${viewMode}`}
                                            renderLabel={renderDisplayTypeIconAndText}/>
                                    )
                            }
                        </div>
                    )
                    : null
            }

            <ConfigurationActionBar localizer={localizer} onCancel={toggleModal} onUpdate={onUpdate}
                                    updateDisabled={disabled}/>
        </Fragment>
    );
};