import React, {Fragment} from 'react';
import Masonry from 'react-masonry-component';
import {LayoutUtil, Localizer, Member, EmailRequest, EmailIdeaContent,} from '@ideascale/commons';
import {IdeaListViewMode} from 'models/enums/IdeaListViewMode';
import {IdeaSummary} from 'models/IdeaSummary';
import {IdeaModerateActionResponse} from 'models/IdeaModerateActionResponse';
import {IdeaMoreActionResponse} from 'models/IdeaMoreActionResponse';
import {GenericResponse} from 'models/GenericResponse';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {IdeaLabelActionResponse} from 'models/IdeaLabelActionResponse';
import {Idea} from './Idea';

type IdeaListProps = {
    localizer: Localizer;
    listViewMode: IdeaListViewMode;
    ideas: IdeaSummary[] | undefined;
    className?: string;
    containerId?: string;
    isSearchResult?: boolean;
    fetchModerateActions: (ideaId: number) => Promise<IdeaModerateActionResponse>;
    fetchMoreActions: (ideaId: number) => Promise<IdeaMoreActionResponse>;
    fetchIdeaLabels: (ideaId: number) => Promise<IdeaLabelActionResponse>;
    toggleIdeaFollowed: (ideaId: number, followed: boolean) => Promise<GenericResponse>;
    toggleAuthorFollowed: (memberId: number, followed: boolean) => Promise<GenericResponse>;
    toggleCommentingEnabled: (ideaId: number, enabled: boolean) => Promise<void>;
    fetchMembers: (data: PageParameters, ideaId: number) => Promise<PagedResponseContent<Member>>;
    fetchAssignedOwners: (ideaId: number) => Promise<Member[]>;
    fetchRecipients: (pageParameters: PageParameters) => Promise<PagedResponseContent<Member>>;
    emailIdeaAuthor: (data: EmailRequest, ideaId: number, groupId: number) => Promise<void>;
    fetchAuthorEmails: (ideaId: number) => Promise<string[]>;
    banMember: (memberId: number) => Promise<GenericResponse>;
    togglePinIdeas: (ideaId: number, pinned: boolean) => Promise<void>;
    getAuthorEmailIdeaContent: (ideaId: number) => Promise<EmailIdeaContent>;
}

export const IdeaList = (props: IdeaListProps) => {
    const {
        localizer,
        listViewMode,
        className,
        containerId,
        ideas,
        fetchModerateActions,
        fetchMoreActions,
        fetchIdeaLabels,
        toggleIdeaFollowed,
        toggleAuthorFollowed,
        toggleCommentingEnabled,
        fetchMembers,
        fetchAssignedOwners,
        fetchRecipients,
        emailIdeaAuthor,
        fetchAuthorEmails,
        banMember,
        togglePinIdeas,
        getAuthorEmailIdeaContent,
        isSearchResult = false,
    } = props;

    const renderIdeas = () => {
        if (listViewMode === IdeaListViewMode.GRID_VIEW && !LayoutUtil.isMobileLayout()) {
            return (
                // @ts-ignore
                <Masonry options={{
                    columnWidth: '.idea',
                    gutter: 15,
                    horizontalOrder: true,
                    transitionDuration: 0,
                }} enableResizableChildren={true}>
                    {ideas?.map(idea => <Idea key={idea.id}
                                              isSearchResult={isSearchResult}
                                              containerId={containerId}
                                              localizer={localizer}
                                              content={idea}
                                              fetchModerateActions={fetchModerateActions}
                                              fetchMoreActions={fetchMoreActions}
                                              fetchIdeaLabels={fetchIdeaLabels}
                                              toggleIdeaFollowed={toggleIdeaFollowed}
                                              toggleAuthorFollowed={toggleAuthorFollowed}
                                              toggleCommentingEnabled={toggleCommentingEnabled}
                                              fetchMembers={fetchMembers}
                                              fetchAssignedOwners={fetchAssignedOwners}
                                              fetchRecipients={fetchRecipients}
                                              emailIdeaAuthor={emailIdeaAuthor}
                                              fetchAuthorEmails={fetchAuthorEmails}
                                              banMember={banMember}
                                              togglePinIdeas={togglePinIdeas}
                                              getAuthorEmailIdeaContent={getAuthorEmailIdeaContent}
                                              listViewMode={listViewMode}/>)}
                </Masonry>
            );
        } else {
            return (
                <Fragment>
                    {ideas?.map(idea => <Idea key={idea.id}
                                              isSearchResult={isSearchResult}
                                              containerId={containerId}
                                              localizer={localizer}
                                              content={idea}
                                              fetchModerateActions={fetchModerateActions}
                                              fetchMoreActions={fetchMoreActions}
                                              fetchIdeaLabels={fetchIdeaLabels}
                                              toggleIdeaFollowed={toggleIdeaFollowed}
                                              toggleAuthorFollowed={toggleAuthorFollowed}
                                              toggleCommentingEnabled={toggleCommentingEnabled}
                                              fetchMembers={fetchMembers}
                                              fetchAssignedOwners={fetchAssignedOwners}
                                              fetchRecipients={fetchRecipients}
                                              fetchAuthorEmails={fetchAuthorEmails}
                                              emailIdeaAuthor={emailIdeaAuthor}
                                              banMember={banMember}
                                              togglePinIdeas={togglePinIdeas}
                                              getAuthorEmailIdeaContent={getAuthorEmailIdeaContent}
                                              listViewMode={listViewMode}/>)}
                </Fragment>
            );
        }
    };

    return (
        <div className={`ideas ${listViewMode.replace('_', '-').toLowerCase()} ${className}`}>
            {
                ideas && ideas.length > 0
                    ? renderIdeas()
                    : <div className="panel card">
                        <div className="card-body panel-body">
                            <div className="alert alert-warning mb-0 text-center">
                                {localizer.msg('idea-list.no-ideas-found')}
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
};