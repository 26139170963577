import React, {Fragment, useEffect, useState} from 'react';
import {UncontrolledTooltip} from 'reactstrap';
import {IntercomProvider} from 'react-use-intercom';
import {ChatBot, ChatConfig, Cookies, HubspotChat} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {useCommunityService} from 'hooks/useService';
import {useAppContext} from 'contexts/AppContext';
import {IntercomChat} from 'components/IntercomChat';
import hubSpotLogo from 'assets/img/logo/hubspot-disabled-chat.png';
import intercomLogo from 'assets/img/logo/intercom-chat.png';
import 'containers/ChatContainer.scss';

export const ChatContainer = () => {
    const communityService = useCommunityService();
    const {cookieConsent, setShowCookieModal} = useAppContext();
    const localizer = useLocalizer();
    const [chatConfig, setChatConfig] = useState<ChatConfig>();

    const renderChat = () => {
        if (chatConfig?.bot === ChatBot.HUBSPOT) {
            return <HubspotChat trackingCode={chatConfig.appId}/>;
        } else if (chatConfig?.bot === ChatBot.INTERCOM) {
            return <IntercomProvider appId={chatConfig.appId} autoBoot={true}>
                <IntercomChat licenceName={chatConfig.licenceName}
                              appToken={chatConfig.appToken}
                              email={chatConfig.emailAddress}/>
            </IntercomProvider>;
        }
    };

    const renderChatPopupLogo = () => {
        if (chatConfig?.bot === ChatBot.HUBSPOT) {
            return <img src={hubSpotLogo} alt="Hubspot"/>;
        } else {
            return <img src={intercomLogo} alt="Intercom"/>;
        }
    };

    useEffect(() => {
        if (communityService !== null) {
            communityService.fetchChatConfig().then(response => {
                setChatConfig(response);
            }).catch((e: any) => {
                console.error(e);
            });
        }
    }, [communityService]);

    return (
        <Fragment>
            {
                chatConfig?.appId &&
                <Fragment>
                    {
                        cookieConsent?.cookieCategories.includes(Cookies.NON_ESSENTIAL)
                            ?
                            renderChat()
                            :
                            <div className={`${chatConfig.bot.toLowerCase()} chat-unblocker position-fixed`}
                                 id="chat-unblock-tooltip-container" aria-live="polite">
                                <button className="btn btn-link text-decoration-none shadow-none position-relative"
                                        id="chat-unblock-tooltip" onClick={() => setShowCookieModal(true)}>
                                    {renderChatPopupLogo()}
                                </button>
                                <UncontrolledTooltip popperClassName="chat-unblock-tooltip-popover"
                                                     innerClassName="chat-tooltip"
                                                     placement="top"
                                                     target="chat-unblock-tooltip"
                                                     container="chat-unblock-tooltip-container">
                                    {localizer.msg('chat.unblock-info', {chat: chatConfig.bot})}
                                </UncontrolledTooltip>
                            </div>
                    }
                </Fragment>
            }
        </Fragment>
    );
};