import React, {Fragment} from 'react';
import {Trans} from 'react-i18next';
import {Localizer, MemberLink} from '@ideascale/commons';
import {ActivityLink} from 'components/profile/activity-stream/ActivityLink';
import {ActionType} from 'models/enums/ActionType';
import {ActivityStreamData} from 'models/ActivityStreamData';

type KudoActivityProps = {
    localizer: Localizer;
    action: ActionType.IDEA_KUDO | ActionType.COMMENT_KUDO;
    activity: ActivityStreamData;
}

export const KudoActivity = (props: KudoActivityProps) => {
    const {activity, action} = props;

    if (activity.kudoReceiver) {
        return (
            <Fragment>
                <Trans
                    i18nKey={action === ActionType.IDEA_KUDO ? 'profile.activity-stream.kudos.idea' : 'profile.activity-stream.kudos.comment'}
                    values={{member: activity.kudoReceiver.name}}>
                    <MemberLink id={activity.kudoReceiver.id} identityHidden={activity.kudoReceiver.identityHidden}/>
                </Trans>
                {' - '}
                <ActivityLink title={activity.idea.title} ideaId={activity.idea.id}/>
            </Fragment>
        );
    } else {
        return null;
    }
};