import React from 'react';
import {useLocalizer} from 'hooks/useLocalizer';
import {StageSummaryHeading} from './StageSummaryHeading';

export const PendingSummary = () => {
    const localizer = useLocalizer();

    return (
        <StageSummaryHeading className="mb-3" heading={localizer.msg('stage.pending.heading')}/>
    );
};
