import {CommunityHomeEditor} from './Editors/CommunityHomeEditor';
import {Command} from './Command';
import {CommunityOperationType} from 'models/edit-mode/CommunityOperationType';
import {Operation} from 'models/edit-mode/Operation';
import {ChangeTopbarLogoOperation} from 'models/edit-mode/ChangeTopbarLogoOperation';
import {TopbarLogoField} from 'models/edit-mode/TopbarLogoField';

export class UpdateTopbarLogoCommand implements Command {
    private readonly communityHomeEditor: CommunityHomeEditor;
    private readonly oldImageField: TopbarLogoField;
    private readonly newImageField: TopbarLogoField;
    private readonly name: CommunityOperationType;

    constructor(communityHomeEditor: CommunityHomeEditor, newTopbarLogoField: TopbarLogoField) {
        this.communityHomeEditor = communityHomeEditor;
        this.newImageField = newTopbarLogoField;
        this.oldImageField = this.communityHomeEditor.topbarLogo;
        this.name = CommunityOperationType.CHANGE_TOPBAR_LOGO;
    }

    execute() {
        this.communityHomeEditor.topbarLogo = this.newImageField;
    }

    getType(): CommunityOperationType {
        return this.name;
    }

    getOperation(): Operation {
        return new ChangeTopbarLogoOperation(
            this.name,
            this.communityHomeEditor.topbarLogo.fileId,
            this.communityHomeEditor.topbarLogo.topbarLogoUrl,
            this.communityHomeEditor.topbarLogo.topbarLogoAltText
        );
    }

    undo(): void {
        this.communityHomeEditor.topbarLogo = this.oldImageField;
    }
}