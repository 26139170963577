import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {ParagraphSkeleton} from '@ideascale/ui';
import {ClassificationsHolder, FieldAttributeParameters, LayoutUtil, useApiErrorResponseHandler} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {useProfilePageParams} from 'hooks/useProfilePageParams';
import {Empty} from 'components/profile/messages/Empty';
import {ReplyForm} from 'components/profile/messages/ReplyForm';
import {CloseButton} from 'components/profile/messages/CloseButton';
import {MessagesList} from 'components/profile/messages/MessagesList';
import {MessageDetails} from 'components/profile/messages/MessageDetails';
import {appLinks} from 'services/AppLinks';
import {PageParameters} from 'models/types/PageParameters';
import {GenericResponse} from 'models/GenericResponse';
import {ProfileInboxData} from 'models/ProfileInboxData';
import {MessageReplyData} from 'models/MessageReplyData';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {ConversationThreadData} from 'models/ConversationThreadData';
import {InboxListRouteMatchParams} from 'models/types/InboxListRouteMatchParams';

type inboxProps = {
    fetchInboxMessages: (pageParams: PageParameters) => Promise<PagedResponseContent<ProfileInboxData>>;
    trashConversationThread: (conversationThreadId: number) => Promise<GenericResponse>;
    fetchConversationThread: (conversationThreadId: number) => Promise<ConversationThreadData>;
    conversationThreadReply: (recipientIds: number[], body: string, subject: string, subjectClassificationAttributes: FieldAttributeParameters[], conversationThreadId: number) => Promise<MessageReplyData>;
    fetchClassifications: () => Promise<ClassificationsHolder>
}

const PAGE_SIZE = 5;

export const Inbox = (props: inboxProps) => {
    const {
        fetchInboxMessages,
        trashConversationThread,
        fetchConversationThread,
        conversationThreadReply,
        fetchClassifications,
    } = props;
    const localizer = useLocalizer();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const currentPage = useRef(0);
    const currentProfileId = useProfilePageParams();
    const params = useParams<InboxListRouteMatchParams>();
    const navigate = useNavigate();
    const [hide, setHide] = useState(LayoutUtil.isMobileLayout() ? 'd-none' : '');
    const [inboxList, setInboxList] = useState(LayoutUtil.isMobileLayout() ? 'd-block' : '');
    const [details, setDetails] = useState<ConversationThreadData>(ConversationThreadData.EMPTY);
    const [replyResponse, setReplyResponse] = useState<MessageReplyData>(MessageReplyData.EMPTY);
    const [messages, setMessages] = useState<ProfileInboxData[]>([]);
    const [showReply, setShowReply] = useState(false);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);

    const loadInboxMessages = useCallback(async () => {
        try {
            const pagedResponse = await fetchInboxMessages({page: currentPage.current, limit: PAGE_SIZE});
            if (pagedResponse.hasMore) {
                currentPage.current = currentPage.current + 1;
            }
            setHasMore(pagedResponse.hasMore);
            setMessages(prevData => [...prevData, ...pagedResponse.content]);
        } catch (error: any) {
            handleErrorResponse(error);
        }
    }, [fetchInboxMessages, handleErrorResponse]);

    useEffect(() => {
        if (messages.length > 0 && !params.messageId) {
            navigate({
                pathname: appLinks.messagesInboxDetails(currentProfileId, messages[0].id)
            });
        }
    }, [currentProfileId, navigate, messages, params.messageId]);

    useEffect(() => {
        loadInboxMessages().then(() => setLoading(false));
    }, [loadInboxMessages]);

    useEffect(() => {
        if (!isNaN(+params?.messageId!)) {
            fetchConversationThread((+params?.messageId!)).then((data) => setDetails(data));
        }
    }, [fetchConversationThread, params.messageId]);

    const onTrashCallback = useCallback(() => {
        navigate(appLinks.messagesInbox(currentProfileId))
    }, [navigate, currentProfileId])

    return (
        <Fragment>
            {
                loading ?
                    <div className="card panel col-11">
                        <div className="card-body">
                            <ParagraphSkeleton rows={15}/>
                        </div>
                    </div>
                    :
                    (
                        messages.length > 0 ?
                            <Fragment>
                                <div className={`col-md-4 messages-list p-0 pt-2 ${inboxList}`}
                                     onClick={() => {
                                         setHide('d-block');
                                         setInboxList(LayoutUtil.isMobileLayout() ? 'd-none' : '');
                                     }}>
                                    <MessagesList messageType="inbox" loadMessages={loadInboxMessages}
                                                  messages={messages} setMessages={setMessages} hasMore={hasMore}
                                                  trashConversationThread={trashConversationThread}
                                                  onTrashCallback={onTrashCallback}/>
                                </div>
                                <div className={`col-md-7 p-3 rounded-right message-details-container ${hide}`} >
                                    <div className="message-details">
                                        {LayoutUtil.isMobileLayout() &&
                                            <CloseButton setHide={setHide} setInboxList={setInboxList}/>
                                        }
                                        <MessageDetails details={details} replyResponse={replyResponse}/>
                                    </div>
                                    <div className="actions me-3">
                                        {
                                            showReply ?
                                                <ReplyForm details={details}
                                                           fetchClassifications={fetchClassifications}
                                                           fetchConversationThread={fetchConversationThread}
                                                           setDetails={setDetails}
                                                           replyResponse={setReplyResponse}
                                                           conversationThreadReply={conversationThreadReply}
                                                           setShowReply={setShowReply}/>
                                                :
                                                <div className="actions-button float-end mt-4 pb-4">
                                                    <button className="btn btn-primary"
                                                            onClick={() => setShowReply(true)}>
                                                        {localizer.msg('common.reply')}
                                                    </button>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </Fragment>
                            :
                            <Empty icon="boxes-open-stacked" message={localizer.msg('common.empty-inbox')} badge={true}/>
                    )
            }
        </Fragment>
    );
};

