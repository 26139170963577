import React from 'react';
import {EmailIdeaContent, EmailRequest, Localizer, Member, PageTheme} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {appLinks} from 'services/AppLinks';
import {IdeaList} from 'components/idea/list/IdeaList';
import {DisplayHeader} from 'components/landing-page/DisplayHeader';
import {IdeaListSkeleton} from 'components/idea/list/IdeaListSkeleton';
import {IdeaCardViewList} from 'components/landing-page/IdeaCardViewList';
import {DisplayHeaderWithPaginationButton} from 'components/landing-page/DisplayHeaderWithPaginationButton';
import {IdeaSummary} from 'models/IdeaSummary';
import {IdeaListMode} from 'models/enums/IdeaListMode';
import {PageParameters} from 'models/types/PageParameters';
import {GenericResponse} from 'models/GenericResponse';
import {IdeaListViewMode} from 'models/enums/IdeaListViewMode';
import {IdeasComponentData} from 'models/landing-page/IdeasComponentData';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {IdeaMoreActionResponse} from 'models/IdeaMoreActionResponse';
import {IdeaLabelActionResponse} from 'models/IdeaLabelActionResponse';
import {IdeaListComponentViewMode} from 'models/enums/landing-page/IdeaListComponentViewMode';
import {IdeaModerateActionResponse} from 'models/IdeaModerateActionResponse';
import {LandingPageComponentType} from 'models/enums/landing-page/LandingPageComponentType';
import 'components/landing-page/IdeasComponent.scss';

type IdeasComponentProps = {
    config: IdeasComponentData;
    editMode?: boolean;
    currentPage: number;
    onPrevClicked: () => void;
    onNextClicked: () => void;
    isLoadingIdeas: boolean;
    isRefetchingIdeas: boolean;
    nextPageDisabled: boolean;
    localizer: Localizer;
    ideaList: IdeaSummary[] | undefined;
    theme: PageTheme;
    fetchModerateActions: (ideaId: number) => Promise<IdeaModerateActionResponse>;
    fetchMoreActions: (ideaId: number) => Promise<IdeaMoreActionResponse>;
    fetchIdeaLabels: (ideaId: number) => Promise<IdeaLabelActionResponse>;
    toggleIdeaFollowed: (ideaId: number, followed: boolean) => Promise<GenericResponse>;
    toggleAuthorFollowed: (memberId: number, followed: boolean) => Promise<GenericResponse>;
    toggleCommentingEnabled: (ideaId: number, enabled: boolean) => Promise<void>;
    fetchMembers: (data: PageParameters, ideaId: number) => Promise<PagedResponseContent<Member>>;
    fetchAssignedOwners: (ideaId: number) => Promise<Member[]>;
    fetchRecipients: (pageParameters: PageParameters) => Promise<PagedResponseContent<Member>>;
    emailIdeaAuthor: (data: EmailRequest, ideaId: number, groupId: number) => Promise<void>;
    fetchAuthorEmails: (ideaId: number) => Promise<string[]>;
    banMember: (memberId: number) => Promise<GenericResponse>;
    togglePinIdeas: (ideaId: number, pinned: boolean) => Promise<void>;
    getAuthorEmailIdeaContent: (ideaId: number) => Promise<EmailIdeaContent>;
    pinnedIdeas?: boolean;
}

export const IdeasComponent = (props: IdeasComponentProps) => {
    const {
        config,
        editMode = false,
        currentPage,
        onPrevClicked,
        theme,
        onNextClicked,
        isLoadingIdeas,
        isRefetchingIdeas,
        nextPageDisabled,
        localizer,
        ideaList,
        fetchModerateActions,
        fetchMoreActions,
        fetchIdeaLabels,
        toggleIdeaFollowed,
        toggleAuthorFollowed,
        toggleCommentingEnabled,
        fetchMembers,
        fetchAssignedOwners,
        emailIdeaAuthor,
        fetchAuthorEmails,
        getAuthorEmailIdeaContent,
        togglePinIdeas,
        fetchRecipients,
        banMember,
        pinnedIdeas = false,
    } = props;
    const {communityConfig} = useAppContext();

    const renderHeader = () => {
        switch (theme) {
            case PageTheme.CURIE:
                return <DisplayHeader className="w-100" theme={theme} title={config.title}
                                      subtitle={config.type === LandingPageComponentType.PINNED_IDEAS ? config.subtitle : undefined}/>;
            case PageTheme.BERNERS_LEE:
            case PageTheme.ARMSTRONG:
            case PageTheme.EASTMAN:
            default:
                return <DisplayHeaderWithPaginationButton theme={theme}
                                                          nextButtonDisabled={nextPageDisabled || isLoadingIdeas || isRefetchingIdeas}
                                                          onNextClicked={onNextClicked} title={config.title}
                                                          previousButtonDisabled={currentPage === 0 || isLoadingIdeas || isRefetchingIdeas}
                                                          onPreviousClicked={onPrevClicked}/>;
        }
    };

    const getIdeaViewMode = () => {
        if (config.ideaListViewMode === IdeaListComponentViewMode.COMPACT_VIEW) {
            return IdeaListViewMode.COMPACT_VIEW;
        } else if (config.ideaListViewMode === IdeaListComponentViewMode.LIST_VIEW) {
            return IdeaListViewMode.LIST_VIEW;
        } else {
            return IdeaListViewMode.GRID_VIEW;
        }
    };

    const renderIdeas = () => {
        if (config.ideaListViewMode === IdeaListComponentViewMode.CARD_VIEW) {
            if (!isLoadingIdeas && !(ideaList && ideaList.length > 0)) {
                return (
                    <div className="panel card w-100">
                        <div className="card-body panel-body">
                            <div className="alert alert-warning mb-0 text-center">
                                {localizer.msg('idea-list.no-ideas-found')}
                            </div>
                        </div>
                    </div>
                );
            } else {
                return <IdeaCardViewList localizer={localizer} editMode={editMode} ideas={ideaList?.slice(0, 4) || []} loading={isLoadingIdeas}/>;
            }
        } else {
            return <div className="w-100">
                {
                    isLoadingIdeas || isRefetchingIdeas
                        ?
                        <IdeaListSkeleton items={4} currentViewMode={getIdeaViewMode()}/>
                        :
                        <IdeaList localizer={localizer}
                                  listViewMode={getIdeaViewMode()}
                                  ideas={ideaList}
                                  fetchModerateActions={fetchModerateActions}
                                  fetchMoreActions={fetchMoreActions}
                                  fetchIdeaLabels={fetchIdeaLabels}
                                  toggleIdeaFollowed={toggleIdeaFollowed}
                                  toggleAuthorFollowed={toggleAuthorFollowed}
                                  toggleCommentingEnabled={toggleCommentingEnabled}
                                  fetchMembers={fetchMembers}
                                  fetchAssignedOwners={fetchAssignedOwners}
                                  fetchRecipients={fetchRecipients}
                                  emailIdeaAuthor={emailIdeaAuthor}
                                  fetchAuthorEmails={fetchAuthorEmails}
                                  banMember={banMember}
                                  togglePinIdeas={togglePinIdeas}
                                  getAuthorEmailIdeaContent={getAuthorEmailIdeaContent}/>
                }
            </div>;
        }

    };

    return (
        <div className={`d-flex flex-column ${theme === PageTheme.CURIE ? 'align-items-center' : ''}`}>
            {renderHeader()}
            {renderIdeas()}
            {
                theme === PageTheme.CURIE && ideaList && ideaList.length > 0 && !pinnedIdeas &&
                <a className="btn btn-primary btn-lg mt-5"
                   {...(editMode ? {tabIndex: -1} : {})}
                   href={communityConfig.url + process.env.PUBLIC_URL + appLinks.ideas('active', 'all', config.ideaListMode.toLowerCase() as IdeaListMode)}>
                    {localizer.msg('landing-page.components.common.view-all')}
                </a>
            }
        </div>
    );
};