import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Controller, FieldValues, useForm} from 'react-hook-form';
import {GroupBase, OptionsOrGroups} from 'react-select';
import {
    ActionButton,
    HtmlConverter,
    IdeascaleSelectWithPagination,
    Modal,
    NewRichTextEditor,
    OptionType,
    RichTextEditorHandler
} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {
    ClassificationsHolder,
    ClassificationSummary,
    ClassificationTreeMenu,
    ClassificationUtil,
    isHtmlInject,
    Member,
    PrivateMessageRequestParams,
    StringUtil,
    useApiErrorResponseHandler,
    useHandleFormSubmit
} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useFileUploadService} from 'hooks/useFileUploadService';
import {useClassificationService} from 'hooks/useClassificationService';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import './ShareModal.scss';

type ShareIdeaToCommunityMembersProps = {
    open: boolean;
    toggle: () => void | null;
    shareableLink: string;
    member?: OptionType[];
    shareContentTitle?: string;
    shareableText?: string;
    internalMessageAdditionalURL?: string
    onSendMessage?: (requestParams: PrivateMessageRequestParams) => Promise<void>;
    fetchRecipients: (pageParameters: PageParameters) => Promise<PagedResponseContent<Member>>;
    classificationSummary?: ClassificationSummary;
    campaignId?: number;
}

export const InternalShareModal = (props: ShareIdeaToCommunityMembersProps) => {
    const {
        open,
        toggle,
        shareContentTitle,
        shareableText,
        internalMessageAdditionalURL,
        onSendMessage,
        fetchRecipients,
        classificationSummary,
        campaignId
    } = props;
    const {communityConfig: {maxFileSizeLimit, offensiveEmojis, classificationEnabled}} = useAppContext();
    const localizer = useLocalizer();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const {tempImageUpload} = useFileUploadService();
    const richTextEditorNode = useRef<RichTextEditorHandler>(null);
    const {register, handleSubmit, control, clearErrors, formState: {errors, isSubmitting}, setError} = useForm({
        mode: 'onSubmit', reValidateMode: 'onSubmit'
    });
    const onFormSubmit = useHandleFormSubmit(handleSubmit);
    const [classificationsMenuData, setClassificationsMenuData] = useState<ClassificationsHolder | {}>({});
    const {fetchClassifications} = useClassificationService();

    useEffect(() => {
        if (classificationEnabled) {
            (async () => {
                    try {
                        const response = await fetchClassifications?.(campaignId);
                        if (response) {
                            setClassificationsMenuData(response);
                        }
                    } catch (error: any) {
                        handleErrorResponse(error);
                    }
                }
            )();
        }
    }, [campaignId, classificationEnabled, fetchClassifications, handleErrorResponse]);

    const richTextClassificationConfig = useMemo(() => {
        return ClassificationUtil.prepareRichTextClassificationConfig(classificationEnabled, classificationsMenuData);
    }, [classificationEnabled, classificationsMenuData]);

    const loadMembers = useCallback(async (term: string, prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>, additionalParams: any) => {
        try {
            const {page} = additionalParams;
            const membersData = await fetchRecipients({page, term, limit: 10});
            const members: OptionType[] = membersData.content.map((member) => ({
                value: member.id,
                label: member.name,
                image: member.avatar,
                labelAsJsx: <div className="member-list-item-content">
                    <strong>{member.name}</strong>
                    {member.username && <small className="text-truncate">@{member.username}</small>}
                </div>
            }));
            return {
                options: members,
                hasMore: membersData.hasMore,
                additional: {
                    page: page + 1,
                }
            };
        } catch (e: any) {
            return {
                options: []
            };
        }
    }, [fetchRecipients]);

    const onSendClicked = async (values: FieldValues) => {
        if (onSendMessage) {
            try {
                let requestParams: PrivateMessageRequestParams = new PrivateMessageRequestParams(
                    values.to.map((member: OptionType) => member.value),
                    values.subject,
                    HtmlConverter.toServerHtmlFormat(values.body + ` ${internalMessageAdditionalURL}`)
                );

                if (classificationEnabled) {
                    if (classificationSummary) {
                        const classificationAttribute = ClassificationUtil.getClassificationAttribute(classificationSummary);
                        if (classificationAttribute) {
                            requestParams.attributeApiParameters = [{
                                type: 'field',
                                name: 'subject',
                                sensitiveExtensions: classificationAttribute.sensitiveExtensions,
                                classification: classificationAttribute.classification,
                                locationExtensions: classificationAttribute.locationExtensions,
                                fieldDisplayType: ''
                            }];
                        }
                    } else {
                        requestParams.attributeApiParameters = [{
                            type: 'field',
                            name: 'subject',
                            sensitiveExtensions: [],
                            classification: 'U',
                            locationExtensions: [],
                            fieldDisplayType: ''
                        }];
                    }
                }
                await onSendMessage(requestParams);
                richTextEditorNode.current?.setTextWithBlur('');
                toggle();
            } catch (e: any) {
                handleErrorResponse(e, {setFormError: setError});
            }
        }
    };

    return (
        <Modal isOpen={open}
               toggle={toggle}
               autoFocus={false}
               modalHeaderId="internal-share-header"
               className="modal-lg share-idea-community-member-modal"
               title={localizer.msg('common.share-with-community-members')}>
            <form id="send-message" onSubmit={onFormSubmit(onSendClicked, isSubmitting)}>
                {
                    classificationEnabled &&
                    <div className="alert alert-info">
                        <b>{localizer.msg('common.please-note')}:</b> {localizer.msg('idea.actions.email.classification-notice')}
                    </div>
                }
                <label htmlFor="to-select" className="fw-bold">
                    {localizer.msg('common.to')}
                    <span className="font-size-lg" aria-hidden={true}>*</span>
                    <span className="sr-only">{localizer.msg('common.form.required')}</span>
                </label>
                <div className={`form-group member-list ${errors.to ? 'has-error' : ''}`}>
                    <Controller
                        name="to"
                        control={control}
                        rules={{required: localizer.msg('common.errors.required')}}
                        render={({field}) =>
                            <IdeascaleSelectWithPagination
                                inputId="to-select"
                                autoFocus
                                isMulti={true}
                                defaultValue={[]}
                                placeholder={StringUtil.textTruncate(localizer.msg('common.to'), 32)}
                                loadOptions={loadMembers}
                                onChange={value => {
                                    clearErrors('to');
                                    field.onChange(value);
                                }}
                                additional={{
                                    page: 0
                                }}
                            />
                        }
                    />
                    {
                        errors.to &&
                        <div className="invalid-feedback d-block">
                            {errors.to.message}
                        </div>
                    }
                </div>
                <input
                    id="subject"
                    type="hidden"
                    maxLength={50}
                    defaultValue={StringUtil.textTruncate(`Shared with you ${classificationEnabled ? '' : ' - ' + shareContentTitle}`, 50)}
                    {...register('subject', {
                        required: localizer.msg('common.errors.required'),
                        validate: value => !isHtmlInject(value) || localizer.msg('common.errors.html')
                    })}
                    className="form-control"
                    placeholder={localizer.msg('common.subject')}
                />
                <div className={`form-group ${errors.body ? 'has-error' : ''}`}>
                    <label htmlFor="internal-message-body" className="fw-bold">
                        {localizer.msg('common.body')}
                        <span className="font-size-lg" aria-hidden={true}>*</span>
                        <span className="sr-only">{localizer.msg('common.form.required')}</span>
                    </label>
                    <Controller
                        control={control}
                        name="body"
                        defaultValue={''}
                        rules={{
                            required: localizer.msg('common.errors.required'),
                            ...(classificationEnabled && {validate: () => !richTextEditorNode.current?.hasInvalidClassifications() || localizer.msg('frontend-shared.classification.invalid-classifications-msg')})
                        }}
                        render={({field}) =>
                            <Fragment>
                                {
                                    classificationEnabled
                                        ? (
                                            <ClassificationTreeMenu
                                                className="ms-2 mb-2"
                                                localizer={localizer}
                                                id="mail-body-classification"
                                                classifications={classificationsMenuData || {}}
                                                onApplyClassifications={value => {
                                                    richTextEditorNode.current?.insertClassification(ClassificationUtil.getLabel(value));
                                                }}/>
                                        )
                                        : null
                                }
                                <NewRichTextEditor
                                    id="internal-message-body"
                                    toolbar={'standard'}
                                    className={'form-control h-auto'}
                                    placeholder={localizer.msg('common.message')}
                                    svgIconPath={svgIconsPath}
                                    onChange={value => {
                                        clearErrors('body');
                                        field.onChange(value);
                                    }}
                                    ref={richTextEditorNode}
                                    enableEmojiPicker={true}
                                    offensiveEmojis={offensiveEmojis}
                                    defaultValue={''}
                                    uploadImage={tempImageUpload}
                                    maxFileSize={maxFileSizeLimit}
                                    classificationConfig={richTextClassificationConfig}
                                />
                            </Fragment>
                        }/>
                    {
                        errors.body &&
                        <div className="invalid-feedback d-block">
                            {errors.body.message}
                        </div>
                    }
                </div>
                {
                    shareableText &&
                    <div className="text-primary pt-3 pb-3 alert-url-container">
                        <p className="text-primary col-9 alert alert-info mb-0 break-all-words">
                        <span className="text-truncate fw-normal text-primary text-decoration-none d-block"
                              title={shareableText}>
                            {shareableText}
                        </span>
                        </p>
                    </div>
                }
                <div className="float-end pb-3">
                    <button type="button" className="btn btn-cancel me-3" onClick={() => toggle()}>
                        {localizer.msg('common.actions.cancel')}
                    </button>
                    <ActionButton type="submit" form={'send-message'} loading={isSubmitting}>
                        {localizer.msg('idea.share.internal.share')}
                    </ActionButton>
                </div>
            </form>
        </Modal>
    );
};
