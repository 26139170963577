import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {appLinks} from 'services/AppLinks';

export const ActivityLink = (props: { ideaId?: number, commentId?: number, title: string }) => {
    const {ideaId, commentId, title} = props;

    return (
        <Fragment>
            {(commentId && ideaId)
                ? <Link to={appLinks.ideaDetailsComment(ideaId, commentId)}>{title}</Link>
                : ideaId
                    ? <Link to={appLinks.ideaDetails(ideaId)}>{title}</Link>
                    : <i>({title})</i>
            }
        </Fragment>
    );
};
