import React, {useCallback} from 'react';
import {Trans} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import {AlertEvent, AlertType, buildAlertEventData, eventDispatcher, useRoutesMatch} from '@ideascale/commons';
import {appLinks} from 'services/AppLinks';
import {ROUTES} from 'shared/Routes';
import {useIdeaStageChange} from 'hooks/useIdeaStageChange';
import {useIdeasContext} from 'contexts/IdeasContext';
import {useIdeaDetailsContext} from 'contexts/IdeaDetailsContext';
import {useAppContext} from 'contexts/AppContext';
import {MyIdeaListMode} from 'models/enums/MyIdeaListMode';
import {IdeaListMode} from 'models/enums/IdeaListMode';

export const useMoveIdeaToRecycle = () => {
    const {refetch: refetchIdeaDetails, changeIdeaStage: ideaDetailsChangeStage} = useIdeaDetailsContext();
    const {ideaListFilterQueryKey} = useIdeasContext();
    const {ideaListStageChangeMutation: {mutateAsync: changeStageAsync}} = useIdeaStageChange(ideaListFilterQueryKey);
    const navigate = useNavigate();
    const ideaDetailsRouteMatch = useRoutesMatch(ROUTES.IDEA_DETAILS.BASE_WITH_WILDCARD);
    const {authentication} = useAppContext();

    const moveToRecycle = useCallback(async (ideaId: number, stageId: number) => {
        if (ideaDetailsRouteMatch) {
            await ideaDetailsChangeStage(ideaId, stageId);
            navigate({
                pathname: appLinks.ideaDetails(ideaId)
            });
            refetchIdeaDetails().then();
            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success,
                <Trans i18nKey={'idea.state.recycle'}>
                    <Link
                        to={appLinks.myIdeas(String(authentication.actor.id), MyIdeaListMode.MY_IDEAS, 'all', IdeaListMode.RECENT)}/>
                </Trans>, false));
        } else {
            await changeStageAsync({ideaId, stageId});
            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success,
                <Trans i18nKey={'idea.state.recycle'}>
                    <Link
                        to={appLinks.myIdeas(String(authentication.actor.id), MyIdeaListMode.MY_IDEAS, 'all', IdeaListMode.RECENT)}/>
                </Trans>, false));
        }
    }, [authentication.actor.id, changeStageAsync, navigate, ideaDetailsChangeStage, ideaDetailsRouteMatch, refetchIdeaDetails]);

    return {moveToRecycle};
};