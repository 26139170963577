export enum LandingPageComponentType {
    STATISTICS = 'STATISTICS',
    TAGS = 'TAGS',
    IDEAS = 'IDEAS',
    PINNED_IDEAS = 'PINNED_IDEAS',
    CAMPAIGNS = 'CAMPAIGNS',
    HERO = 'HERO',
    INSTRUCTIONS = 'INSTRUCTIONS',
    LEADERS = 'LEADERS',
    ACTIVITY_SUMMARY = 'ACTIVITY_SUMMARY',
    TEXT = 'TEXT',
}