import React from 'react';
import {Link} from 'react-router-dom';
import {Trans} from 'react-i18next';
import {MemberLink} from '@ideascale/commons';
import {appLinks} from 'services/AppLinks';
import {NotificationData} from 'models/NotificationData';

type IdeaCampaignChangedNotificationProps = {
    actorId: number;
    notification: NotificationData;
}
export const IdeaCampaignChangedNotification = (props: IdeaCampaignChangedNotificationProps) => {
    const {actorId, notification} = props;
    const actorIsIdeaAuthor = notification.ideaAuthorId === actorId;
    return (
        <Trans i18nKey={`notification.idea.campaign.changed-${actorIsIdeaAuthor ? 'self' : 'others'}`} values={{
            member: notification.viewAttributes.sourceMemberName,
            idea: notification.viewAttributes.ideaTitle,
            campaign: notification.viewAttributes.campaignName
        }}>
            <MemberLink id={notification.sourceMemberId} identityHidden={notification.identityHidden}/>
            <Link to={appLinks.ideaDetails(notification.ideaId)}/>
            <strong/>
        </Trans>
    );
};