import {useCallback} from 'react';
import {useApiErrorResponseHandler} from '@ideascale/commons';
import {useCommunityService} from './useService';
import {useLocalizer} from './useLocalizer';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {SearchParameters} from 'models/SearchParameters';
import {IdeaSummary} from '../models/IdeaSummary';

export const useSearchService = () => {
    const communityService = useCommunityService();
    const localizer = useLocalizer();
    const {throwApiErrorResponse} = useApiErrorResponseHandler({localizer});

    const fetchSearchIdeas = useCallback((searchParams: SearchParameters) => {
        if (communityService !== null) {
            try {
                return communityService.fetchSearchIdeas(searchParams);
            } catch(e: any) {
                throwApiErrorResponse(e);
            }
        }
        return PagedResponseContent.nullObject<IdeaSummary>();
    }, [communityService, throwApiErrorResponse]);

    return {
        fetchSearchIdeas,
    };
};