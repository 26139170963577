import {ImageType} from '../enums/ImageType';
import {BaseConfigField} from './BaseConfigField';
import {CommunityEditableFieldType} from './CommunityEditableFieldType';
import {CampaignEditableFieldType} from './CampaignEditableFieldType';
import {PermissionType, ResourcePermission} from './ResourcePermission';

export class ImageFieldBuilder {
    public imageField: ImageField;

    constructor() {
        this.imageField = {...ImageField.EMPTY};
    }

    name(name: CommunityEditableFieldType | CampaignEditableFieldType) {
        this.imageField.name = name;
        return this;
    }

    url(url: string) {
        this.imageField.url = url;
        return this;
    }

    fileId(fileId: number) {
        this.imageField.fileId = fileId;
        return this;
    }

    imageType(imageType: ImageType) {
        this.imageField.imageType = imageType;
        return this;
    }

    defaultImage(defaultImage: boolean) {
        this.imageField.defaultImage = defaultImage;
        return this;
    }

    filename(filename: string) {
        this.imageField.filename = filename;
        return this;
    }

    altText(altText: string) {
        this.imageField.altText = altText;
        return this;
    }

    build() {
        return this.imageField;
    }
}

export class ImageField extends BaseConfigField implements ResourcePermission {
    fileLibraryPermission: PermissionType;
    fileUploadPermission: PermissionType;
    standardFilePermission: PermissionType;
    static EMPTY = new ImageField(CommunityEditableFieldType.UNKNOWN, '', 0, ImageType.UNKNOWN, false);

    constructor(
        name: CommunityEditableFieldType | CampaignEditableFieldType,
        public url: string,
        public fileId: number,
        public imageType: ImageType,
        public defaultImage: boolean,
        public filename?: string,
        public altText?: string
    ) {
        super(name);
    }
}