import {ResponseStatusType} from '../enums/ResponseStatusType';

export class IdeaReportingResponse {
    static readonly EMPTY = new IdeaReportingResponse(ResponseStatusType.success, '', false);
    
    constructor(
        public status: ResponseStatusType,
        public message: string,
        public thresholdReached: boolean
    ) {
    }
}