import React from 'react';
import {Alert} from 'reactstrap';
import {Icon} from '@ideascale/ui';
import {Localizer} from '@ideascale/commons';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';

type RequiredMsgInfoProps = {
    localizer: Localizer;
    message?: string;
}
export const RequiredMsgInfo = ({localizer, message}: RequiredMsgInfoProps) => {
    return (
        <Alert color="danger" className="my-4 text-center">
            <Icon className="position-relative is-icon icon-alert-warning me-1" name="exclamation-outlined-circle" iconSpritePath={svgIconPath}
                  fill="#611A15"/> {message ? message : localizer.msg('edit-mode.image-required-info')}
        </Alert>
    );
};