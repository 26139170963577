import {Command} from './Command';
import {CampaignHomeEditor} from './Editors/CampaignHomeEditor';
import {Operation} from 'models/edit-mode/Operation';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';
import {ChangeCampaignSubtitleOperation} from 'models/edit-mode/ChangeCampaignSubtitleOperation';

export class UpdateCampaignSubtitleCommand implements Command {
    private readonly campaignHomeEditor: CampaignHomeEditor;
    private readonly oldCampaignSubtitle: string;
    private readonly newCampaignSubtitle: string;
    private readonly name: CampaignOperationType;
    private readonly campaignId: number;

    constructor(campaignHomeEditor: CampaignHomeEditor, subtitle: string, campaignId: number) {
        this.campaignHomeEditor = campaignHomeEditor;
        this.oldCampaignSubtitle = this.campaignHomeEditor.subtitle;
        this.newCampaignSubtitle = subtitle;
        this.campaignId = campaignId;
        this.name = CampaignOperationType.CHANGE_CAMPAIGN_SUBTITLE;
    }

    execute(): void {
        this.campaignHomeEditor.subtitle = this.newCampaignSubtitle;
    }

    undo(): void {
        this.campaignHomeEditor.subtitle = this.oldCampaignSubtitle;
    }

    getType(): CampaignOperationType {
        return this.name;
    }

    getOperation(): Operation {
        return new ChangeCampaignSubtitleOperation(this.name, this.campaignHomeEditor.subtitle, this.campaignId);
    }
}