import {Slide} from 'models/Slide';

export class Slider {
    static readonly EMPTY = new Slider([], false, 0, 0, 0);

    constructor(
        public content: Slide[],
        public hasMore: false,
        public pageNo: number,
        public pageSize: number,
        public totalElements: number,
    ) {
    }
}
