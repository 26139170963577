import React, {useMemo} from 'react';
import {Localizer} from '@ideascale/commons';
import {IdeascaleSelect} from '@ideascale/ui';
import {Language} from 'models/types/Language';

type RefineLanguageFieldProps = {
    localizer: Localizer;
    fieldKey: string;
    setLangId: (langId: number) => void;
    defaultLanguage: Language;
    supportedLanguages: Language[];
}

export const RefineLanguageField = (props: RefineLanguageFieldProps) => {
    const {localizer, fieldKey, setLangId, defaultLanguage, supportedLanguages} = props;
    const fieldId = `refine-stage-field-language-${fieldKey}`;

    const options = useMemo(() => supportedLanguages.map(language => ({
        value: language.id,
        label: language.name
    })), [supportedLanguages]);

    return (
        <div className="form-group">
            <label className="fw-bold" htmlFor={fieldId}>
                {localizer.msg('stage.refine.language.placeholder')}
                <span className="font-size-lg" aria-hidden={true}>*</span>
                <span className="sr-only">{localizer.msg('common.form.required')}</span>
            </label>
            <IdeascaleSelect
                isMulti={false}
                defaultValue={{value: defaultLanguage.id, label: defaultLanguage.name}}
                inputId={fieldId}
                options={options}
                onChange={(option: any) => setLangId(option.value)}
            />
        </div>
    );
};