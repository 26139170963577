import React, {useCallback, useEffect, useState} from 'react';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {eventDispatcher, ParagraphSkeleton, useApiErrorResponseHandler} from '@ideascale/commons';
import {ActivityCard} from '../idea/details/ActivityCard';
import {IdeateReport} from './IdeateReport';
import {BaseStageActivityProps} from 'models/types/BaseStageActivityProps';
import {IdeateStageActivity} from 'models/stage-activity/IdeateStageActivity';
import {IDEA_EVENTS} from 'constants/AppConstants';
import './IdeateActivity.scss';

type IdeateActivityProps = BaseStageActivityProps;

export const IdeateActivity = (props: IdeateActivityProps) => {
    const {stageId, fetchActivity, isCurrentStage, headerSummary, headerTitle, localizer} = props;
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [loading, setLoading] = useState(true);
    const [ideateActivity, setIdeaActivity] = useState<IdeateStageActivity>();

    const loadIdeateActivity = useCallback(async () => {
        try {
            const ideateActivity = await fetchActivity(stageId);
            setIdeaActivity(ideateActivity as IdeateStageActivity);
            setLoading(false);
        } catch(e: any) {
            handleErrorResponse(e);
        }
    }, [fetchActivity, handleErrorResponse, stageId]);

    useEffect(() => {
        if (isCurrentStage) {
            loadIdeateActivity().then();
        }
    }, [isCurrentStage, loadIdeateActivity]);

    useEffect(() => {
        eventDispatcher.addListener(IDEA_EVENTS.IDEA_VOTED, () => {
            loadIdeateActivity().then();
        });
        return () => {
            eventDispatcher.removeListener(IDEA_EVENTS.IDEA_VOTED);
        };
    }, [loadIdeateActivity]);

    return (
        <div className="pb-3">
            <ActivityCard containerClass="campaign-stages ideate-stage"
                          headerTitle={headerTitle}
                          headerSummary={
                              <p className="mb-0 mt-3 fw-normal">
                                  {headerSummary}
                              </p>
                          }
                          isCurrentStage={isCurrentStage}
                          svgIconSprite={svgIconPath}
                          defaultOpen={isCurrentStage} onEntered={() => !ideateActivity && loadIdeateActivity().then()}>
                {
                    loading ? (
                        <ParagraphSkeleton rows={5}/>
                    ) : (
                        ideateActivity &&
                        <IdeateReport localizer={localizer} activity={ideateActivity}
                                      isCurrentActivity={isCurrentStage}/>
                    )
                }
            </ActivityCard>
        </div>
    );
};