import React, {Fragment, useEffect, useState} from 'react';
import {ParagraphSkeleton} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {ClassificationsHolder, Member, PagedResponseContent, useApiErrorResponseHandler} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {ActivityCard} from 'components/idea/details/ActivityCard';
import {BuildTeamMember} from './BuildTeamMember';
import {BaseStageActivityProps} from 'models/types/BaseStageActivityProps';
import {TeamRoleData} from 'models/TeamRoleData';
import {PageParameters} from 'models/types/PageParameters';
import {StageActionResponse} from 'models/StageActionResponse';
import {BuildTeamStageActivity} from 'models/BuildTeamStageActivity';
import {BuildTeamStageSummary} from 'models/BuildTeamStageSummary';
import {StageActivity} from 'models/stage-activity/StageActivity';
import {BaseIdeaStageSummary} from 'models/BaseIdeaStageSummary';
import IconToolsLarge from 'assets/img/icon-tools-large.png';
import IconRightLarge from 'assets/img/icon-right-large.png';
import './BuildTeam.scss';

type BuildTeamActivityProps = BaseStageActivityProps & {
    fetchBuildTeamData: (ideaId: number, stageId: number) => Promise<BuildTeamStageActivity>;
    fetchTeamRole: () => Promise<Array<TeamRoleData>>;
    fetchTeamMember(ideaId: number, stageId: number, pageParameters: PageParameters): Promise<PagedResponseContent<Member>>;
    assignTeamRole: (ideaId: number, stageId: number, positionId: number, teamRoleId: number) => Promise<StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>>;
    fetchRecipients(pageParameters: PageParameters): Promise<PagedResponseContent<Member>>;
    joinBuildTeam(ideaId: number, stageId: number, positionId: number): Promise<StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>>;
    assignMemberBuildTeam(ideaId: number, stageId: number, positionId: number, memberId: number): Promise<StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>>;
    unassignMemberBuildTeam(ideaId: number, stageId: number, memberId: number): Promise<StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>>;
    promoteToOwnerBuildTeam(ideaId: number, stageId: number, memberId: number): Promise<StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>>;
    addPositionBuildTeam(ideaId: number, stageId: number, buildTeamGroupId: number): Promise<StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>>;
    removePositionBuildTeam(ideaId: number, stageId: number, positionId: number): Promise<StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>>;
    updateIdeaStageSummary: <T extends BaseIdeaStageSummary>(stageSummary: T) => void;
    fetchClassifications: () => Promise<ClassificationsHolder>;
    ideaId: number;
};

export const BuildTeamActivity = (props: BuildTeamActivityProps) => {
    const {
        isCurrentStage, headerSummary, headerTitle,
        fetchBuildTeamData,
        fetchTeamRole,
        assignTeamRole,
        fetchRecipients,
        ideaId,
        stageId,
        joinBuildTeam,
        unassignMemberBuildTeam,
        promoteToOwnerBuildTeam,
        addPositionBuildTeam,
        removePositionBuildTeam,
        fetchTeamMember,
        assignMemberBuildTeam,
        updateIdeaStageSummary,
        fetchClassifications,
    } = props;

    const localizer = useLocalizer();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [loading, setLoading] = useState(true);
    const [activityData, setActivityData] = useState<BuildTeamStageActivity>(StageActivity.EMPTY as BuildTeamStageActivity);

    const handleAddPositionBuildTeam = (ideaId: number, stageId: number, buildTeamGroupId: number) => {
        addPositionBuildTeam(ideaId, stageId, buildTeamGroupId).then(status => {
            if (status) {
                if (status.stageActivity) {
                    setActivityData(status.stageActivity);
                }
                if (status?.stageSummary) {
                    updateIdeaStageSummary(status.stageSummary);
                }
            }
        }).catch((error) => {
            handleErrorResponse(error);
        });
    };

    useEffect(() => {
        if (isCurrentStage) {
            fetchBuildTeamData(ideaId, stageId).then(data => {
                setActivityData(data);
                setLoading(false);
            });
        }
    }, [fetchBuildTeamData, ideaId, isCurrentStage, stageId]);

    const renderActivityHeading = () => {
        if (isCurrentStage) {
            if (activityData?.participationAllowed) {
                return localizer.msg('stage.build-team.help-build-this-idea-team');
            } else if (activityData?.viewAllowed) {
                return localizer.msg('build-team-progress-underway');
            } else {
                return (
                    <Fragment>
                        {localizer.msg('stage.build-team.team-building-is-in-progress')}
                        <div className="text-center mt-3">
                            <img src={IconToolsLarge} alt="Hammer and wrench in a cross"/>
                        </div>
                    </Fragment>
                );
            }
        } else {
            return (
                <Fragment>
                    {
                        localizer.msg('stage.build-team.team-building-has-completed')
                    }
                    {
                        !(activityData?.participationAllowed || activityData?.viewAllowed) &&
                        <div className="text-center mt-3">
                            <img src={IconRightLarge} alt="Checked Icon"/>
                        </div>
                    }
                </Fragment>
            );
        }
    };

    return (
        <div className="pb-3">
            <ActivityCard containerClass="campaign-stages"
                          headerTitle={headerTitle}
                          headerSummary={
                              <p className="mb-0 mt-3 fw-normal">
                                  {headerSummary}
                              </p>
                          }
                          isCurrentStage={isCurrentStage}
                          svgIconSprite={svgIconPath}
                          defaultOpen={isCurrentStage}
                          onEntered={() => fetchBuildTeamData(ideaId, stageId).then(data => {
                              setActivityData(data);
                              setLoading(false);
                          })}>

                {
                    loading
                        ? <div className="pt-3">
                            <ParagraphSkeleton rows={10}/>
                        </div>
                        : <Fragment>
                            <div className="mb-3 font-size-sm">
                                {
                                    renderActivityHeading()
                                }
                            </div>
                            {
                                (activityData.viewAllowed || activityData.participationAllowed) &&
                                <ul className="list-unstyled team-member-list">
                                    {activityData.teamPositions &&
                                        activityData.teamPositions.map(teamPosition => {
                                            return (
                                                <BuildTeamMember key={teamPosition.position.positionId}
                                                                 teamPosition={teamPosition}
                                                                 defaultPositionRemovable={activityData.defaultPositionRemovable}
                                                                 updateIdeaStageSummary={updateIdeaStageSummary}
                                                                 setActivityData={setActivityData}
                                                                 assignMemberBuildTeam={assignMemberBuildTeam}
                                                                 promoteToOwnerBuildTeam={promoteToOwnerBuildTeam}
                                                                 unassignMemberBuildTeam={unassignMemberBuildTeam}
                                                                 ideaId={ideaId}
                                                                 stageId={activityData.stageId}
                                                                 addRemoveTeamPositionAllowed={activityData.addRemoveTeamPositionAllowed}
                                                                 assignUnassignTeamMemberAllowed={activityData.assignUnassignTeamMemberAllowed}
                                                                 joinTeamAllowed={activityData.joinTeamAllowed}
                                                                 removePositionBuildTeam={removePositionBuildTeam}
                                                                 fetchTeamRole={fetchTeamRole}
                                                                 assignTeamRole={assignTeamRole}
                                                                 fetchTeamMember={fetchTeamMember}
                                                                 joinBuildTeam={joinBuildTeam}
                                                                 fetchRecipients={fetchRecipients}
                                                                 fetchClassifications={fetchClassifications}
                                                />
                                            );
                                        })}
                                </ul>
                            }
                        </Fragment>
                }
                {
                    activityData.addRemoveTeamPositionAllowed &&
                    <button className="btn btn-secondary"
                            data-test-element-id="btn-add-position"
                            type="button"
                            onClick={() => handleAddPositionBuildTeam(ideaId, activityData.stageId, activityData.buildTeamGroupId)}>
                        {localizer.msg('stage.build-team.add-position')}
                    </button>
                }
            </ActivityCard>
        </div>
    );
};