import {CampaignStage} from '@ideascale/commons';

export type Stages = {
    ideaCount?: number,
    endDate?: string
    stage: CampaignStage
}

export class StageDetails {
    public static EMPTY = new StageDetails([], 0);

    constructor(
        public stages: Stages[],
        public countTotalIdeas?: number
    ) {
    }
}