import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useQuery} from 'react-query';
import {CSSTransition} from 'react-transition-group';
import {Trans} from 'react-i18next';
import {Icon, Modal} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {APP_EVENTS, Cookies, eventDispatcher, ParagraphSkeleton, useRoutesMatch, useToggle} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {useAppContext} from 'contexts/AppContext';
import {useCommunityService} from 'hooks/useService';
import {CookieConsentModal} from 'components/CookieConsentModal';
import {FooterContent} from 'components/FooterContent';
import {FooterData} from 'models/FooterData';
import {DEFAULT_STALE_TIME, QUERY_KEYS} from 'constants/AppConstants';
import {ROUTES} from 'shared/Routes';
import './Footer.scss';

export const FooterContainer = () => {
    const localizer = useLocalizer();
    const location = useLocation();
    const {
        setCookieConsent,
        showCookieModal,
        setShowCookieModal,
        communityConfig: {
            brandingAllowed,
            freeLicense,
            readCommunity,
            seoLinkHidden,
            helpSiteBaseUrl
        }
    } = useAppContext();
    const communityService = useCommunityService();
    const [isScrolledToFooter, setIsScrolledToFooter] = useState(false);
    const [showFloatingFooter, setShowFloatingFooter] = useState(false);
    const [showHoveringFooter, setShowHoveringFooter] = useState(false);
    const [showAccessibilityStatement, toggleAccessibilityStatement] = useToggle(false);
    const joinCommunityRouteMatch = useRoutesMatch(ROUTES.JOIN_COMMUNITY);
    const containerRef = useRef<HTMLDivElement>(null);

    const onFooterToggleClicked = () => {
        setShowFloatingFooter(prevState => !prevState);
    };

    const toggleHoveredFooter = (toggle: boolean) => {
        if (toggle && document.body.scrollHeight - window.scrollY <= (document.body.offsetHeight + containerRef.current!.scrollHeight)) return;
        setShowHoveringFooter(toggle);
    };

    const handleScroll = useCallback(() => {
        if (containerRef.current !== null) {
            if (document.body.scrollHeight - window.scrollY <= (document.body.offsetHeight + containerRef.current.scrollHeight)) {
                if (!isScrolledToFooter) {
                    setIsScrolledToFooter(true);
                }
            } else {
                if (isScrolledToFooter) {
                    setIsScrolledToFooter(false);
                }
            }
        }
    }, [isScrolledToFooter]);

    const onCookieConsentClicked = () => {
        setShowCookieModal(prevState => !prevState);
    };

    const loadFooterData = useCallback(async () => {
        if (communityService !== null) {
            return communityService.fetchFooter();
        }
        return FooterData.EMPTY;
    }, [communityService]);

    const {
        isLoading,
        isRefetching,
        data
    } = useQuery([QUERY_KEYS.FOOTER], () => loadFooterData(), {
        staleTime: DEFAULT_STALE_TIME,
        onSuccess: (response) => {
            const {cookieConsent: {cookieCategories, needsCookieConsent}} = response;
            setCookieConsent({
                needsCookieConsent: needsCookieConsent || false,
                cookieCategories: cookieCategories.length === 0 ? [Cookies.ESSENTIAL, Cookies.ANALYTICS] : cookieCategories
            });
        }
    });

    const confirmCookieConsent = useCallback(async (cookieCategories: Cookies[]) => {
        if (communityService !== null) {
            return communityService.confirmCookieConsent(cookieCategories);
        }
    }, [communityService]);

    useEffect(() => {
        return () => {
            handleScroll();
        };
    }, [location, handleScroll]);

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);

        return () => {
            document.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll);
        };
    }, [handleScroll]);

    useEffect(() => {
        eventDispatcher.addListener(APP_EVENTS.SHOW_COOKIE_POLICY, () => {
            setShowCookieModal(true);
        });
        return () => {
            eventDispatcher.removeListener(APP_EVENTS.SHOW_COOKIE_POLICY);
        };
    });

    useEffect(() => {
        if (data?.cookieConsent.needsCookieConsent) {
            setShowCookieModal(true);
        }
    }, [data?.cookieConsent.needsCookieConsent, location, setShowCookieModal]);

    return (
        isLoading || isRefetching ? (
            <ParagraphSkeleton rows={1}/>
        ) : (
            <Fragment>
                <div
                    className={`footer-toggle-container position-fixed w-100 ${isScrolledToFooter ? 'd-none' : 'd-flex flex-column'} ${joinCommunityRouteMatch?.pathname ? 'text-center' : ''}`}
                    onMouseEnter={() => toggleHoveredFooter(true)}>
                    <div
                        className={`footer-toggle-backdrop w-100 mx-auto d-none ${showHoveringFooter ? 'd-md-block' : ''}`}
                        onMouseLeave={() => toggleHoveredFooter(false)}>
                        <section className="container px-0">
                            {
                                data &&
                                <FooterContent brandingAllowed={brandingAllowed} freeLicense={freeLicense}
                                               readCommunity={readCommunity}
                                               seoLinkHidden={seoLinkHidden} footerData={data}
                                               localizer={localizer}
                                               onCookieConsentClicked={onCookieConsentClicked}
                                               onAccessibilityStatementClicked={toggleAccessibilityStatement}
                                               helpSiteBaseUrl={helpSiteBaseUrl}/>
                            }
                        </section>
                    </div>

                    <button className="footer-mobile-toggle d-block d-md-none border-1 border-bottom-0 mb-0 ms-0"
                            title="Footer Info"
                            onClick={onFooterToggleClicked}>
                        <Icon className="slider-icon mt-2 me-2" iconSpritePath={svgIconPath}
                              name="lines-three-horizontal"
                              width={15}
                              height={15} fill={'#000'}/>
                    </button>
                    <CSSTransition in={showFloatingFooter} timeout={300} classNames="footer-sticky" unmountOnExit>
                        <div className="footer-toggle-backdrop w-100 mx-auto">
                            <section className="col-12">
                                {
                                    data &&
                                    <FooterContent brandingAllowed={brandingAllowed} freeLicense={freeLicense}
                                                   readCommunity={readCommunity}
                                                   seoLinkHidden={seoLinkHidden} footerData={data}
                                                   localizer={localizer}
                                                   onCookieConsentClicked={onCookieConsentClicked}
                                                   onAccessibilityStatementClicked={toggleAccessibilityStatement}
                                                   helpSiteBaseUrl={helpSiteBaseUrl}/>
                                }
                            </section>
                        </div>
                    </CSSTransition>
                </div>


                <div className={`px-3 ${joinCommunityRouteMatch?.pathname ? 'text-center' : ''}`} id="fixed-footer"
                     ref={containerRef}>
                    <div className="container px-0">
                        {
                            data &&
                            <FooterContent brandingAllowed={brandingAllowed} freeLicense={freeLicense}
                                           readCommunity={readCommunity}
                                           seoLinkHidden={seoLinkHidden} footerData={data}
                                           localizer={localizer} onCookieConsentClicked={onCookieConsentClicked}
                                           onAccessibilityStatementClicked={toggleAccessibilityStatement}
                                           helpSiteBaseUrl={helpSiteBaseUrl}/>
                        }
                    </div>
                </div>
                {
                    showCookieModal &&
                    <CookieConsentModal localizer={localizer} showCookieModal={showCookieModal}
                                        toggleCookieModal={onCookieConsentClicked} brandingAllowed={brandingAllowed}
                                        confirmCookieConsent={confirmCookieConsent}/>
                }
                {
                    showAccessibilityStatement &&
                    <Modal isOpen={showAccessibilityStatement} toggle={toggleAccessibilityStatement}
                           title={localizer.msg('footer.accessibility.title')} size="lg">
                        <p>
                            <Trans i18nKey="footer.accessibility.description-text" values={{
                                link: 'https://www.access-board.gov/ict'
                            }}>
                                <a href="https://www.access-board.gov/ict" target="_blank" rel="noreferrer">link</a>
                            </Trans>
                        </p>

                        <p>
                            <Trans i18nKey="footer.accessibility.support-text" values={{
                                link: 'support@ideascale.com'
                            }}>
                                <a href="mailto: support@ideascale.com">link</a>
                            </Trans>
                        </p>
                    </Modal>
                }
            </Fragment>
        )
    );
};