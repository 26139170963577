export class GenericResponse {
    static readonly EMPTY = new GenericResponse('', '');

    static nullObject() {
        return new GenericResponse('','');
    }

    constructor(
        public message: string,
        public status: string,
    ) {
    }
}