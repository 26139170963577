import {PagedResponseContent} from './PagedResponseContent';
import {LeaderboardItem} from './LeaderboardItem';

export class LeaderboardResponse extends PagedResponseContent<LeaderboardItem> {
    enabled: boolean;

    static EMPTY() {
        return new LeaderboardResponse([], false, 0, 0, 0, false);
    }

    constructor(
        content: LeaderboardItem[],
        hasMore: boolean,
        pageNo: number,
        pageSize: number,
        totalElements: number,
        enabled: boolean
    ) {
        super(content, hasMore, pageNo, pageSize, totalElements);
        this.enabled = enabled;
    }
}