import React, {useContext, useState} from 'react';
import {OptionType} from '@ideascale/ui';
import {Campaign, CampaignDropdownLabel} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {TimePeriod} from 'models/enums/TimePeriod';

const ctx = React.createContext<LeaderboardContext>({
    setCampaign: () => console.error('function "setCampaign" not initialized'),
    setGroup: () => console.error('function "setGroup" not initialized'),
    setTimePeriod: () => console.error('function "setTimePeriod" not initialized')
});

type LeaderboardContext = {
    campaign?: OptionType;
    group?: OptionType;
    timePeriod?: OptionType;
    setCampaign: (option: OptionType) => void;
    setGroup: (option: OptionType) => void;
    setTimePeriod: (option: OptionType) => void;
}

export const useLeaderboardContext = () => {
    return useContext(ctx);
};

export const LeaderboardContextProvider = ({
                                               children,
                                               campaignContext
                                           }: { children: React.ReactNode, campaignContext?: Campaign }) => {
    const localizer = useLocalizer();
    const {communityConfig: {classificationEnabled}} = useAppContext();
    const [campaign, setCampaign] = useState<OptionType | undefined>(campaignContext
        ? {
            value: campaignContext.id,
            label: campaignContext.name,
            labelAsJsx: <CampaignDropdownLabel campaign={campaignContext}
                                               classificationEnabled={classificationEnabled}/>
        }
        : undefined);
    const [group, setGroup] = useState<OptionType>();
    const [timePeriod, setTimePeriod] = useState<OptionType>({
        value: {mode: TimePeriod.WEEKLY, unit: 4},
        label: localizer.msg('leaderboard.time-period.weekly', {count: 4})
    });

    return (
        <ctx.Provider value={{campaign, group, timePeriod, setCampaign, setTimePeriod, setGroup}}>
            {children}
        </ctx.Provider>
    );
};