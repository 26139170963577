import {Operation} from './Operation';
import {CampaignOperationType} from './CampaignOperationType';

export class ChangeCampaignSubtitleOperation implements Operation {
    constructor(public type: CampaignOperationType, public subtitle: string, public campaignId: number) {
    }

    getType(): CampaignOperationType {
        return this.type;
    }
}