import React from 'react';
import {Trans} from 'react-i18next';
import {Localizer, Member, TimeAgo} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {StageActivitySummary} from 'models/StageActivitySummary';
import {ContributionActivityType} from 'models/enums/ContributionActivityType';
import {ContributionActivitySource} from 'models/enums/ContributionActivitySource';

type ActivityHeaderSummaryProps = {
    localizer: Localizer;
    stageActivity: StageActivitySummary;
    author: Member;
}

export const ActivityHeaderSummary = (props: ActivityHeaderSummaryProps) => {
    const {localizer, stageActivity, author} = props;
    const {authentication: {actor}} = useAppContext();

    if (stageActivity.activityType === ContributionActivityType.STATUS_DELTA) {
        if (stageActivity.activitySource === ContributionActivitySource.AUTOMATED && stageActivity.oldStage && stageActivity.newStage) {
            return (
                <>
                    <Trans i18nKey="stage.common.automation-change" values={{
                        initialStage: stageActivity.oldStage.label,
                        newStage: stageActivity.newStage.label,
                        automation: localizer.msg('stage.common.change-member-automation')
                    }}>
                        <em>Initial Stage</em>
                        <em>New Stage</em>
                        <strong>Via Automation</strong>
                        <TimeAgo localizer={localizer} dateISOString={stageActivity.createdAt}/>
                    </Trans>
                </>
            );
        } else {
            const memberName = stageActivity.member.id === actor.id ? localizer.msg('stage.common.changed-self') : stageActivity.member.name;
            if (stageActivity.newStage && stageActivity.oldStage) {
                if (stageActivity.newStage.id === stageActivity.oldStage.id) {
                    return (
                        <>
                            <Trans i18nKey="stage.common.status-same" values={{stage: stageActivity.newStage.label}}>
                                <em>New Stage</em>
                            </Trans>
                        </>
                    );
                } else {
                    return (
                        <>
                            <Trans i18nKey="stage.common.member-change" values={{
                                initialStage: stageActivity.oldStage.label,
                                newStage: stageActivity.newStage.label,
                                member: memberName
                            }}>
                                <em>Initial Stage</em>
                                <em>New Stage</em>
                                <strong>Member</strong>
                                <TimeAgo localizer={localizer} dateISOString={stageActivity.createdAt}/>
                            </Trans>
                        </>
                    );
                }
            } else {
                return (
                    <>
                        {
                            stageActivity.description
                        }
                    </>
                );
            }
        }
    } else {
        if (stageActivity?.newStage.id === stageActivity?.oldStage.id) {
            return (
                <>
                    <Trans i18nKey="stage.common.status-same" values={{stage: stageActivity?.newStage.label}}>
                        <em>New Stage</em>
                    </Trans>
                </>
            );
        } else {
            return (
                <>
                    <Trans i18nKey="stage.common.submitted-to" values={{
                        stage: stageActivity.newStage.label,
                        member: author.id === actor.id ? localizer.msg('stage.common.changed-self') : author.name
                    }}>
                        <em>Stage</em>
                        <strong>Member</strong>
                        <TimeAgo localizer={localizer} dateISOString={stageActivity.createdAt}/>
                    </Trans>
                </>
            );
        }
    }
};