export class IdeaTagHolder {
    static readonly EMPTY = new IdeaTagHolder([], false, false, false, false, [], false, false);

    constructor(
        public tags: string[],
        public addTagsAllowed: boolean,
        public editTagsAllowed: boolean,
        public predefinedTagEnabled: boolean,
        public hybridTagEnabled: boolean,
        public moderatorTags: string[],
        public addModeratorTagsAllowed: boolean,
        public predefinedModeratorTagEnabled: boolean,
    ) {
    }
}