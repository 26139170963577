import React from 'react';
import range from 'lodash/range';
import {ParagraphSkeleton, Skeleton} from '@ideascale/ui';

export const SidebarSkeleton = () => {
    return (
        <div className={'sidebar-wrapper'}>
            {
                range(1, 5).map(index => (
                        <div className="card panel" key={index}>
                            <div className="card-header">
                                <Skeleton/>
                            </div>
                            <div className="card-body border-top-0">
                                <div className="m-3"><ParagraphSkeleton rows={6}/></div>
                            </div>
                        </div>
                    )
                )}
        </div>
    );
};