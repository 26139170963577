import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Avatar, Icon} from '@ideascale/ui';
import svgIcons from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {FeatureTourStep, Localizer, Member} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useIdeaSubmissionActions} from 'hooks/useIdeaSubmissionActions';
import {appLinks} from 'services/AppLinks';

type IdeaSubmissionEntryProps = {
    localizer: Localizer;
    actionMember: Member;
    onClick: () => void;
    onClickAvatar?: () => void;
}

export const IdeaSubmissionEntry = (props: IdeaSubmissionEntryProps) => {
    const {localizer, actionMember, onClick, onClickAvatar} = props;
    const {currentCampaign, lastSubmittedIdea, communityConfig: {submissionAllowed}, authentication} = useAppContext();
    const {isNotIdeaSubmissionAllowed} = useIdeaSubmissionActions();

    const shouldHideInlineSubmission = (): boolean => {
        if (lastSubmittedIdea) {
            return true;
        } else if (currentCampaign) {
            return !currentCampaign.ideaSubmissionAllowed;
        } else {
            return !submissionAllowed;
        }
    };

    return (
        <Fragment>
            {
                authentication.actor.hasPendingMembership()
                    ? null
                    : (
                        <div className={`mb-3 ${shouldHideInlineSubmission() ? 'd-none' : ''}`}>
                            <section
                                className="idea-submission-entry position-relative align-items-center box-shadow rounded p-3 d-flex">
                                <Link className="me-3"
                                      to={actionMember.id && actionMember.id !== 0 ? appLinks.profile(actionMember.id) : '#'}
                                      onClick={onClickAvatar}>
                                    <Avatar src={actionMember.avatar} size="md"
                                            alt={actionMember.username ? localizer.msg('common.user-avatar', {username: actionMember.username}) : localizer.msg('common.unknown-user-avatar')}/>
                                </Link>
                                <button
                                    className={`form-control text-start font-size-md text-truncate idea-submission-input tour-${FeatureTourStep.IDEA_SUBMISSION_ASSIST_NEW_IDEA.toLowerCase()}`}
                                    type="button"
                                    disabled={isNotIdeaSubmissionAllowed()}
                                    onClick={onClick}
                                    data-test-element-id="idea-submission-input">
                                    {localizer.msg('submission-entry.submit-your-idea')}
                                </button>
                                <span
                                    className={`btn btn-submission-entry position-absolute ${isNotIdeaSubmissionAllowed() ? 'disabled pointer-events-none' : ''}`}
                                    role="button"
                                    onClick={onClick}
                                    data-test-element-id="idea-submission-input-btn">
                                    <Icon name="plus-solid-circle" aria-hidden="true" iconSpritePath={svgIcons} width={35} height={35}/>
                                    <span className="sr-only">
                                        {localizer.msg('common.submit-idea')}
                                    </span>
                                </span>
                            </section>
                        </div>
                    )
            }
        </Fragment>
    );
};