export enum FundUnit {
    UNSPECIFIED = 'UNSPECIFIED',
    HOUR = 'HOUR',
    Token = 'Token',
    WOODEN_DOLLAR = 'WOODEN_DOLLAR',
    USD = 'USD',
    EUR = 'EUR',
    GBP = 'GBP',
    JPY = 'JPY',
    AUD = 'AUD',
    CAD = 'CAD',
    HKD = 'HKD',
    MYR = 'MYR',
    AED = 'AED',
    CHF = 'CHF'
}