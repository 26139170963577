import React from 'react';
import {Link} from 'react-router-dom';
import {Trans} from 'react-i18next';
import {MemberLink} from '@ideascale/commons';
import {appLinks} from 'services/AppLinks';
import {NotificationData} from 'models/NotificationData';

type CommentApprovedNotificationProps = {
    notification: NotificationData;
}

export const CommentApprovedNotification = (props: CommentApprovedNotificationProps) => {
    const {notification} = props;

    return (
        <Trans i18nKey="notification.idea.comment.comment-approved" values={{
            member: notification.viewAttributes.sourceMemberName,
            ideaTitle: notification.viewAttributes.ideaTitle
        }}>
            <MemberLink id={notification.sourceMemberId} identityHidden={notification.identityHidden}/>
            <Link to={appLinks.ideaDetailsComment(notification.ideaId, notification.commentId)}/>
            {
                notification.viewAttributes.ideaTitle ? <span/> : <Link to={appLinks.ideaDetails(notification.ideaId)}/>
            }
            {
                notification.viewAttributes.ideaTitle && <Link to={appLinks.ideaDetails(notification.ideaId)}/>
            }
        </Trans>
    );
};