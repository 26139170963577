import React from 'react';
import {Skeleton} from '@ideascale/ui';

export const PageNavbarSkeleton = () => {
    return (
        <nav className="page-navbar d-flex align-items-center h-60px">
            <div className="container">
                <Skeleton width="100px" cssClass="me-3"/>
                <Skeleton width="70px" cssClass="me-3"/>
                <Skeleton width="70px"/>
            </div>
        </nav>
    );
};