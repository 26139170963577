import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Avatar, ParagraphSkeleton} from '@ideascale/ui';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {SidebarLeaderboardItem} from 'models/SidebarLeaderboardItem';
import {LeaderboardType} from 'models/enums/LeaderboardType';
import {IdeaListRouteMatchParams} from 'models/types/IdeaListRouteMatchParams';
import {appLinks} from 'services/AppLinks';
import {ROUTES} from 'shared/Routes';
import {LEADERBOARD_TYPES, SCROLL_EVENTS} from 'constants/AppConstants';
import {eventDispatcher} from '@ideascale/commons';
import './SidebarLeaderboard.scss';

type SidebarLeaderboardProps = {
    fetchSidebarLeaderboardItem: (campaignId?: number) => Promise<Array<SidebarLeaderboardItem>>;
}

export const SidebarLeaderboard = (props: SidebarLeaderboardProps) => {
    const {fetchSidebarLeaderboardItem} = props;
    const localizer = useLocalizer();
    const [leader, setLeader] = useState<Array<SidebarLeaderboardItem>>([]);
    const params = useParams<IdeaListRouteMatchParams>();
    const {authentication: {actor}} = useAppContext();
    const {currentCampaign} = useAppContext();
    const [loading, setLoading] = useState(true);

    const campaignId = useMemo(() => {
        return currentCampaign ? currentCampaign.id : 0;
    }, [currentCampaign]);

    const leaderboardRoute = useCallback((type?: LeaderboardType) => {
        if (campaignId !== 0) {
            if (type) {
                return appLinks.campaignLeaderboard(campaignId, type);
            } else {
                return appLinks.campaignLeaderboardBase(campaignId);
            }
        } else {
            if (type) {
                return appLinks.leaderboard(type);
            } else {
                return ROUTES.LEADERBOARD.BASE;
            }
        }
    }, [campaignId]);

    const renderLeaderSummary = (leaderSummary: SidebarLeaderboardItem) => {
        switch (leaderSummary.leaderboardType) {
            case LeaderboardType.KUDOS_RECEIVED:
                return localizer.msg('sidebar.leaderboard.kudos-received');
            case LeaderboardType.KUDOS_GIVEN:
                return localizer.msg('sidebar.leaderboard.kudos-given');
            case LeaderboardType.IDEA:
                return localizer.msg('sidebar.leaderboard.ideas');
            case LeaderboardType.COMMENT:
                return localizer.msg('sidebar.leaderboard.comments');
            case LeaderboardType.VOTE:
                return localizer.msg('sidebar.leaderboard.votes');
            default:
                return '';
        }
    };

    useEffect(() => {
        fetchSidebarLeaderboardItem(campaignId).then(data => {
            setLeader(data);
            setLoading(false);
        });
    }, [campaignId, fetchSidebarLeaderboardItem, params.campaignId]);

    return (
        <Fragment>
            {loading
                ?
                <div className="p-2">
                    <ParagraphSkeleton rows={6}/>
                </div>
                :
                <>
                    {leader.length > 0
                        ?
                        <div className="leaderboard-members d-flex flex-wrap text-center" id="sidebar-leaderboard-members">
                            {LEADERBOARD_TYPES.map((type, index) => {
                                const leaderboardItem = leader.find(item => item.leaderboardType === type.value);
                                if (leaderboardItem && leaderboardItem.enabled) {
                                    return (
                                        <Link
                                            onClick={() => eventDispatcher.dispatch(SCROLL_EVENTS.SCROLL_TOP)}
                                            key={index}
                                            className={`person ${actor.id === leaderboardItem.leader.id ? 'own-profile' : ''}`}
                                            to={leaderboardRoute(type.value)}>
                                            <Avatar className="mb-2" size={'md'}
                                                    src={leaderboardItem.leader.avatar}
                                                    alt={leaderboardItem.leader.username ? localizer.msg('common.user-avatar', {username: leaderboardItem.leader.username}) : ''}/>
                                            <h3 className="h3 fw-bold mb-0 text-truncate"
                                                title={leaderboardItem.leader.name}>{leaderboardItem.leader.name}</h3>
                                            <div
                                                className="summary fw-bold font-size-sm text-truncate"
                                                title={renderLeaderSummary(leaderboardItem)}>
                                                {leaderboardItem.count} {renderLeaderSummary(leaderboardItem)}
                                            </div>
                                        </Link>
                                    );
                                } else {
                                    return null;
                                }
                            })
                            }
                        </div>
                        : <div className="py-2 empty-text">{localizer.msg('sidebar.leaderboard.no-leader')}</div>
                    }
                </>
            }
            <Link className="mt-4 ps-3 font-size-sm d-inline-block" to={leaderboardRoute()}
                  onClick={() => eventDispatcher.dispatch(SCROLL_EVENTS.SCROLL_TOP)}>
                {localizer.msg('sidebar.leaderboard.view-full-leaderboard')}
            </Link>
        </Fragment>
    );
};
