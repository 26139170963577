import React from 'react';

type ImageProps = {
    src: string;
    alt: string;
    className?: string;
    width?: number;
    height?: number;
    title?: string
}
export const Image = (props: ImageProps) => {
    const {src, alt, className, width, height, title} = props;
    return (
        <img src={src} alt={alt} width={width} height={height} className={className} title={title}/>
    );
};

