import {useMutation, useQueryClient} from 'react-query';
import {PagedIdeas} from '../contexts/IdeasContext';
import {useIdeaService} from './useIdeaService';
import {useIdeaUpdater} from './useIdeaUpdater';
import {AssignOwnersParams} from 'models/types/AssignOwnersParams';
import {IdeaOwnerAssignmentResponse} from 'models/IdeaOwnerAssignmentResponse';

export const useAssignOwners = (ideaListQueryKey: any = '') => {
    const queryClient = useQueryClient();
    const {assignOwners,} = useIdeaService();
    const {ideaDetailsQueryKey, updateIdeaDetails} = useIdeaUpdater();

    const ideaListAssignOwnersMutation = useMutation((assignOwnersParams: AssignOwnersParams) => assignOwners(assignOwnersParams), {
        onSuccess: async (response: IdeaOwnerAssignmentResponse, {data, ideaId}: AssignOwnersParams) => {
            await queryClient.cancelQueries(ideaListQueryKey);
            queryClient.setQueryData(ideaListQueryKey, oldIdeas => {
                const updateIdeaList = {...oldIdeas as { pages: PagedIdeas[], pageParams: any }};
                updateIdeaList.pages.forEach(page => {
                    page.data.forEach(idea => {
                        if (idea.id === ideaId) {
                            idea.ownerCount = response.ownerMemberIds.length;
                            idea.assignmentText = data.message;
                        }
                    });
                });
                return oldIdeas;
            });
        }
    });

    const ideaDetailsAssignOwnersMutation = useMutation((assignOwnersParams: AssignOwnersParams) => assignOwners(assignOwnersParams), {
        onSuccess: async (response: IdeaOwnerAssignmentResponse, {data, ideaId}: AssignOwnersParams) => {
            await queryClient.cancelQueries(ideaDetailsQueryKey(ideaId));
            await updateIdeaDetails(ideaId, 'ownerCount', response.ownerMemberIds.length);
            await updateIdeaDetails(ideaId, 'assignmentText', data.message);
        }
    });

    return {ideaListAssignOwnersMutation, ideaDetailsAssignOwnersMutation};
};
