import {CampaignsHolder} from '@ideascale/commons';
import {SidebarCampaign} from './SidebarCampaign';
import {CampaignGroup} from './CampaignGroup';

export class SidebarCampaignHolder extends CampaignsHolder<SidebarCampaign> {
    defaultGroupExpand: boolean;
    currentCampaignPage: number;
    hasMoreCampaigns: boolean;

    constructor(
        group: CampaignGroup,
        campaigns: SidebarCampaign[],
        defaultGroupExpand: boolean,
        currentCampaignPage: number,
        hasMoreCampaigns: boolean) {
        super(group, campaigns);
        this.defaultGroupExpand = defaultGroupExpand;
        this.currentCampaignPage = currentCampaignPage;
        this.hasMoreCampaigns = hasMoreCampaigns;
    }
}