import React, {Fragment} from 'react';
import {Localizer} from '@ideascale/commons';
import {ActivityLink} from '../ActivityLink';
import {ActivityStreamData} from 'models/ActivityStreamData';
import {ActionType} from 'models/enums/ActionType';

type CampaignChangeActivityProps = {
    localizer: Localizer;
    activity: ActivityStreamData;
    action: ActionType.IDEA_CAMPAIGN_CHANGE;
}

export const CampaignChangeActivity = (props: CampaignChangeActivityProps) => {
    const {localizer, activity} = props;

    return (
        <Fragment>
            {localizer.msg('profile.activity-stream.campaign.action')} <ActivityLink title={activity.idea.title}
                                                                                     ideaId={activity.idea.id}/>
            {' - '}{localizer.msg('profile.activity-stream.campaign.description', {campaignName: activity.campaignName})}
        </Fragment>
    );
};