import React, {Fragment, useCallback, useEffect, useMemo} from 'react';
import {FieldValues, useForm, useWatch} from 'react-hook-form';
import {
    AiAssistance,
    AiAssistanceFieldNames,
    AiContentResponse,
    isHtmlInject,
    Localizer,
    useHandleFormSubmit
} from '@ideascale/commons';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useAppContext} from 'contexts/AppContext';
import {useRouteUtils} from 'hooks/useRouteUtils';
import {useEditModeFormErrorHandler} from 'hooks/useEditModeFormErrorHandler';
import {UpdateCampaignSubtitleCommand} from 'commands/edit-mode/UpdateCampaignSubtitleCommand';
import {ConfigurationActionBar} from 'components/shared/ConfigurationActionBar';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';
import {CAMPAIGN_SUBTITLE_MAX_LENGTH} from 'constants/AppConstants';

const CAMPAIGN_SUBTITLE = 'subtitle';

type CampaignSubtitleEditFormProps = {
    toggleModal: () => void;
    localizer: Localizer;
    fetchAiAssistedSubtitle: (prompt: string, fieldName: AiAssistanceFieldNames, chatId?: number) => Promise<AiContentResponse>;
}

export const CampaignSubtitleEditForm = (props: CampaignSubtitleEditFormProps) => {
    const {toggleModal, localizer, fetchAiAssistedSubtitle} = props;
    const {campaignHomeEditor, commandExecutor, validationErrors} = useEditModeContext();
    const {communityConfig: {aiTextAssistEnabled}, authentication: {actor}} = useAppContext();
    const {register, handleSubmit, setFocus, control, setError, setValue, formState: {errors, isDirty}} = useForm({
        defaultValues: {
            [CAMPAIGN_SUBTITLE]: campaignHomeEditor.subtitle
        }
    });
    const onFormSubmit = useHandleFormSubmit(handleSubmit);
    const {validationErrorHandler} = useEditModeFormErrorHandler({
        localizer,
        validationErrors: validationErrors.findError(CampaignOperationType.CHANGE_CAMPAIGN_SUBTITLE)
    });
    const {campaignRouteMatch} = useRouteUtils();

    const subTitleValue: string = useWatch({
        name: CAMPAIGN_SUBTITLE,
        control
    });

    const remainingLength = useMemo(() => {
        return CAMPAIGN_SUBTITLE_MAX_LENGTH - (subTitleValue?.length ?? 0);
    }, [subTitleValue]);

    const onSubmit = (data: FieldValues) => {
        const campaignId = (campaignRouteMatch?.params as any)?.campaignId;
        const command = new UpdateCampaignSubtitleCommand(campaignHomeEditor, data[CAMPAIGN_SUBTITLE].trim(), campaignId);
        commandExecutor.execute(command);
        validationErrors.clearError(command.getType());
        toggleModal();
    };

    const validate = (value: any) => {
        if (isHtmlInject(value)) {
            return localizer.msg('common.errors.html');
        }
    };

    const onAiResponseUsed = useCallback((content: string) => {
        setValue(CAMPAIGN_SUBTITLE, content, {shouldDirty: true, shouldValidate: true, shouldTouch: true});
    }, [setValue]);

    useEffect(() => {
        setFocus(CAMPAIGN_SUBTITLE);
    }, [setFocus]);

    useEffect(() => {
        validationErrorHandler(setError);
    }, [setError, validationErrorHandler]);

    return (
        <Fragment>
            <form onSubmit={onFormSubmit(onSubmit, !isDirty)}>
                <div className={`form-group w-100 ${errors[CAMPAIGN_SUBTITLE] ? 'has-error' : ''}`}>
                    <div className="d-flex">
                        <label className="fw-bold" htmlFor={CAMPAIGN_SUBTITLE}>
                            {localizer.msg('edit-mode.campaign.subtitle')}
                        </label>
                        {
                            aiTextAssistEnabled &&
                            <AiAssistance className="ms-auto"
                                          type="text"
                                          fieldName={AiAssistanceFieldNames.CAMPAIGN_SUB_TITLE}
                                          localizer={localizer}
                                          title={localizer.msg('ai-assistance.campaign-sub-title-label')}
                                          onAiResponseUsed={onAiResponseUsed}
                                          userAvatar={actor.avatar}
                                          fetchAiAssistedText={fetchAiAssistedSubtitle}
                                          getInitialPrompt={() => campaignHomeEditor.name}/>
                        }
                    </div>
                    <input className="form-control form-control-lg" type="text" id={CAMPAIGN_SUBTITLE}
                           maxLength={CAMPAIGN_SUBTITLE_MAX_LENGTH}
                           {...register(CAMPAIGN_SUBTITLE, {
                               validate,
                               maxLength: {
                                   value: CAMPAIGN_SUBTITLE_MAX_LENGTH,
                                   message: localizer.msg('frontend-shared.fields.errors.character-limit', {characterCount: CAMPAIGN_SUBTITLE_MAX_LENGTH}),
                               }
                           })}
                    />
                    <div className="text-muted mt-1">
                        {localizer.msg('idea.form.field.feedback.text-length', {count: remainingLength})}
                    </div>
                    {
                        errors[CAMPAIGN_SUBTITLE] &&
                        <div className="invalid-feedback d-block" aria-live="assertive">
                            {errors[CAMPAIGN_SUBTITLE].message}
                        </div>
                    }
                </div>
                <ConfigurationActionBar updateBtnType="submit" localizer={localizer} onCancel={toggleModal}
                                        updateDisabled={!isDirty}/>
            </form>
        </Fragment>
    );
};