import {Component} from 'models/landing-page/Component';
import {UiAttribute} from 'models/landing-page/UiAttribute';
import {LandingPageComponentType} from 'models/enums/landing-page/LandingPageComponentType';
import {CampaignFilter} from 'models/enums/landing-page/CampaignFilter';
import {CampaignSlide} from 'models/CampaignSlide';
import {CampaignDisplayType} from 'models/enums/landing-page/CampaignDisplayType';

export class FeaturedCampaignsComponentData extends Component {
    public static EMPTY = new FeaturedCampaignsComponentData(0, 0, LandingPageComponentType.CAMPAIGNS, false, UiAttribute.EMPTY, CampaignFilter.ACTIVE, CampaignDisplayType.CAROUSEL, [], 0, '', '');

    constructor(
        public id: number,
        public languageId: number,
        public type: LandingPageComponentType,
        public visible: boolean,
        public attribute: UiAttribute,
        public campaignFilter: CampaignFilter,
        public campaignDisplayType: CampaignDisplayType,
        public campaigns: CampaignSlide[],
        public count: number,
        public title?: string,
        public subtitle?: string,
    ) {
        super(id, languageId, type, visible, attribute, title, subtitle);
    }
}