import React, {Fragment} from 'react';
import {PageLayoutContainer} from 'containers/PageLayoutContainer';
import {BodyTagAttributes, PageTitle} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useUpdateCurrentCampaign} from 'hooks/useUpdateCurrentCampaign';
import {ReleasedFeaturesContainer} from 'containers/ReleasedFeaturesContainer';
import {UpcomingFeaturesContainer} from 'containers/UpcomingFeaturesContainer';
import {PAGE_IDS} from 'constants/AppConstants';
import './NewFeaturesPage.scss';

export const NewFeaturesPage = () => {
    const {communityConfig} = useAppContext();
    useUpdateCurrentCampaign();

    return (
        <Fragment>
            <BodyTagAttributes data-test-element-id={PAGE_IDS.NEW_FEATURES}/>
            <PageTitle title={`${communityConfig.name} - by IdeaScale | New Features`}/>
            <PageLayoutContainer
                className="new-features"
                mainContent={
                    <div className="p-3 rounded">
                        <div className="row">
                            <div className="col-md-10 m-auto mt-md-3">
                                <ReleasedFeaturesContainer/>
                                <UpcomingFeaturesContainer/>
                            </div>
                        </div>
                    </div>
                }
            />
        </Fragment>
    );
};
