import {LabelData, LandingPageMode, SharedLinks, StringUtil, UriBuilder} from '@ideascale/commons';
import {MENTIONED_FIELD_VALUE_ID} from 'constants/AppConstants';
import {IdeaListMode} from 'models/enums/IdeaListMode';
import {MyIdeaListMode} from 'models/enums/MyIdeaListMode';
import {LeaderboardType} from 'models/enums/LeaderboardType';
import {ROUTES} from 'shared/Routes';

class AppLinks extends SharedLinks {
    moderateIdeas = '/m/ideas/unseen?query=pending';
    moderateComments = '/m/comments/unseen?query=pending';
    moderateMembers = '/mm/members?query=pending';
    reporting = '/a/reporting/';
    invite = '/a/invite/friend';
    adminSettings = '/a/admin/member-management/admin';
    globalModeratorSettings = '/a/admin/member-management/moderator#anchor-security-global-moderator';
    campaignModeratorSettings = '/a/admin/member-management/moderator#anchor-security-campaign-moderator';
    manageCampaigns = '/a/admin/engagement/campaigns/0';
    manageLookAndFeel = '/a/admin/ui-customization/manage-look';
    communityTos = '/a/membership/tos';

    home() {
        return new UriBuilder(ROUTES.HOME).build();
    }

    joinCommunity() {
        return new UriBuilder(ROUTES.JOIN_COMMUNITY).build();
    }

    ideas(campaignId: 'active' | 'expired' | string = 'active', stageKey: string = 'all', mode: IdeaListMode | 'unspecified' = 'unspecified') {
        return new UriBuilder(ROUTES.CAMPAIGN.IDEAS_PATH, {
            campaignId,
            stageKey,
            mode
        }).build();
    }

    activeIdeas() {
        return this.ideas('active');
    }

    expiredIdeas() {
        return this.ideas('expired');
    }

    campaign(campaignId: string) {
        return new UriBuilder(ROUTES.CAMPAIGN.BASE, {campaignId}).build();
    }

    campaignTeam(campaignId: string) {
        return new UriBuilder(ROUTES.CAMPAIGN.TEAM_PATH, {campaignId}).build();
    }

    campaignWorkflow(campaignId: string) {
        return new UriBuilder(ROUTES.CAMPAIGN.WORKFLOW_PATH, {campaignId}).build();
    }

    aboutCampaign(campaignId: string) {
        return new UriBuilder(ROUTES.CAMPAIGN.ABOUT_PATH, {campaignId}).build();
    }

    campaignTos(campaignId: string) {
        return new UriBuilder(ROUTES.CAMPAIGN_TOS, {campaignId}).build();
    }

    leaderboard(leaderboardType: LeaderboardType) {
        return new UriBuilder(ROUTES.LEADERBOARD.WITH_TYPE, {
            leaderboardType: StringUtil.snakeCaseToKebabCase(leaderboardType)
        }).build();
    }

    campaignLeaderboardBase(campaignId: number) {
        return new UriBuilder(ROUTES.LEADERBOARD.BASE)
            .addRequestParam(ROUTES.QUERY_PARAMS.CAMPAIGN, String(campaignId)).build();
    }

    campaignLeaderboard(campaignId: number, leaderboardType: LeaderboardType) {
        return new UriBuilder(leaderboardType ? ROUTES.LEADERBOARD.WITH_TYPE : ROUTES.LEADERBOARD.BASE, {
            leaderboardType: StringUtil.snakeCaseToKebabCase(leaderboardType)
        }).addRequestParam(ROUTES.QUERY_PARAMS.CAMPAIGN, String(campaignId)).build();
    }

    activity(memberId: number) {
        return new UriBuilder(ROUTES.PROFILE.ACTIVITY_PATH, {memberId: String(memberId)}).build();
    }

    notifications(memberId: number) {
        return new UriBuilder(ROUTES.PROFILE.NOTIFICATIONS_PATH, {memberId: String(memberId)}).build();
    }

    search() {
        return new UriBuilder(ROUTES.SEARCH).build();
    }

    myIdeas(memberId: string, myIdeasMode: MyIdeaListMode = MyIdeaListMode.MY_IDEAS, stageKey: string = 'all', sort: IdeaListMode | 'unspecified' = 'unspecified') {
        return new UriBuilder(ROUTES.MY_IDEAS, {
            memberId,
            myIdeasMode,
            stageKey,
            mode: sort
        }).build();
    }

    campaignPerformance(campaignId: number, memberId: number, type: 'monthly' | 'weekly', year: string, number: string) {
        return new UriBuilder(ROUTES.CAMPAIGN_PERFORMANCE, {
            campaignId: String(campaignId),
            memberId: String(memberId),
            type: type,
            year: year,
            number: number
        }).build();
    }

    messagesInbox(memberId: number) {
        return new UriBuilder(ROUTES.MESSAGE_INBOX, {memberId: String(memberId)}).build();
    }

    messagesSent(memberId: number) {
        return new UriBuilder(ROUTES.MESSAGE_SENT, {memberId: String(memberId)}).build();
    }

    messagesSentDetails(memberId: number, messageId: number) {
        return new UriBuilder(ROUTES.MESSAGE_SENT_DETAILS, {
            memberId: String(memberId),
            messageId: String(messageId)
        }).build();
    }

    messagesCompose(memberId: number) {
        return new UriBuilder(ROUTES.MESSAGE_COMPOSE, {memberId: String(memberId)}).build();
    }

    ideaDetails(ideaId: number) {
        return new UriBuilder(ROUTES.IDEA_DETAILS.BASE, {
            ideaId: String(ideaId)
        }).build();
    }

    draftIdea(pathname: string, ideaId: number, submitterId: number) {
        return new UriBuilder(pathname)
            .addRequestParam(ROUTES.QUERY_PARAMS.SUBMISSION_FORM, 'open')
            .addRequestParam(ROUTES.QUERY_PARAMS.DRAFT_IDEA, String(ideaId))
            .addRequestParam(ROUTES.QUERY_PARAMS.SUBMITTER_ID, String(submitterId))
            .build();
    }

    ideaDetailsComment(ideaId: number, commentId?: number) {
        return new UriBuilder(commentId ? ROUTES.IDEA_DETAILS_COMMENT : ROUTES.IDEA_DETAILS_COMMENTS, {
            ideaId: String(ideaId),
            commentId: String(commentId)
        }).build();
    }

    ideaDetailsStageComment(ideaId: number, stageId: number, commentId?: number) {
        return new UriBuilder(commentId ? ROUTES.IDEA_DETAILS_STAGE_COMMENT : ROUTES.IDEA_DETAILS.STAGE_COMMENTS_BASE, {
            ideaId: String(ideaId),
            stageId: String(stageId),
            commentId: String(commentId)
        }).build();
    }

    ideaDetailsActivity(ideaId: number) {
        return new UriBuilder(ROUTES.IDEA_DETAILS.ACTIVITY_PATH, {ideaId: String(ideaId)}).build();
    }

    ideaDetailsMentionedActivity(ideaId: number, fieldValueId: number) {
        return new UriBuilder(ROUTES.IDEA_DETAILS.ACTIVITY_PATH, {ideaId: String(ideaId)})
            .addRequestParam(MENTIONED_FIELD_VALUE_ID, String(fieldValueId))
            .build();
    }

    ideaDetailsAttachment(ideaId: number) {
        return new UriBuilder(ROUTES.IDEA_DETAILS.ATTACHMENTS_PATH, {ideaId: String(ideaId)}).build();
    }

    comment(ideaId: number, commentId: number) {
        return new UriBuilder(ROUTES.IDEA_DETAILS.BASE, {
            ideaId: String(ideaId)
        }).addRequestParam('comment', String(commentId)).build();
    }

    stageComment(campaignId: number, ideaId: number, stageCommentId: number) {
        return new UriBuilder(ROUTES.IDEA_DETAILS.BASE, {
            campaignId: String(campaignId),
            ideaId: String(ideaId)
        }).addRequestParam('stageComment', String(stageCommentId)).build();
    }

    label(labelData: LabelData, memberId: string) {
        const urlSlug = labelData.key.replace(/\s/g, '-');
        return new UriBuilder(LabelData.isRecyclingBin(labelData.id) || LabelData.isPending(labelData.id) ? appLinks.myIdeas(String(memberId)) : appLinks.ideas())
            .addRequestParam(ROUTES.QUERY_PARAMS.LABEL, urlSlug)
            .build();
    }

    tag(tag: string, campaignId: string = 'active', stageKey: string = 'all', mode: IdeaListMode | 'unspecified' = 'unspecified') {
        return new UriBuilder(ROUTES.CAMPAIGN.IDEAS_PATH, {
            campaignId: campaignId,
            stageKey: stageKey,
            mode: mode
        }).addRequestParam(ROUTES.QUERY_PARAMS.TAG, tag).build();
    }

    moderatorTag(moderatorTag: string, campaignId: string = 'active', stageKey: string = 'all', mode: IdeaListMode | 'unspecified' = 'unspecified') {
        return new UriBuilder(ROUTES.CAMPAIGN.IDEAS_PATH, {
            campaignId: campaignId,
            stageKey: stageKey,
            mode: mode
        }).addRequestParam(ROUTES.QUERY_PARAMS.MODERATOR_TAG, moderatorTag).build();
    }

    approveIdeaModeration(ideaId: number, seen: boolean) {
        return new UriBuilder(`/m/ideas/${seen ? 'seen' : 'unseen'}`).addRequestParam('id', String(ideaId)).build();
    }

    ideaShare(ideaId: number) {
        return `/a/idea-share/${ideaId}`;
    }

    communityShare() {
        return `/a/community-share`;
    }

    printIdea(ideaId: number) {
        return `/a/idea/export?ideaIds=${ideaId}`;
    }

    ideaPortfolioRecycleBinIdeas() {
        return '/ip/?query=recycleBin';
    }

    ideaPortfolioOffTopicIdeas() {
        return '/ip/?query=offTopic';
    }

    ideaPortfolioPendingApproval() {
        return '/ip/?query=pending';
    }

    landing(requestParams: URLSearchParams) {
        return new UriBuilder(ROUTES.LANDING).addRequestParams(requestParams).build();
    }

    landingPageAction(mode: LandingPageMode, pageId: string, editMode: boolean) {
        if (editMode) {
            return new UriBuilder(ROUTES.LANDING_PAGE_ACTION, {
                mode,
                pageId,
            }).addRequestParam(ROUTES.SEARCH_QUERY.EDIT_MODE, 'enabled').build();
        } else {
            return new UriBuilder(ROUTES.LANDING_PAGE_ACTION, {
                mode,
                pageId,
            }).build();
        }
    }

    tosAcceptRedirect(redirectUrl: string) {
        return new UriBuilder(ROUTES.MEMBERSHIP_TOS)
            .addRequestParam(ROUTES.QUERY_PARAMS.TOS_REDIRECT_URL, redirectUrl)
            .build();
    }
}

export const appLinks = new AppLinks();