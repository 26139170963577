import {useCommunityService} from './useService';
import {useCallback} from 'react';
import {AiAssistanceFieldNames, AiContentResponse, AiImageResponse} from '@ideascale/commons';

export const useAiAssistanceService = () => {
    const communityService = useCommunityService();

    const fetchAiAssistedText = useCallback(async (prompt: string, fieldName: AiAssistanceFieldNames, chatId?: number): Promise<AiContentResponse> => {
        if (communityService !== null) {
            return await communityService.fetchAiText(prompt, fieldName, chatId);
        }
        return AiContentResponse.EMPTY;
    }, [communityService]);

    const fetchAiAssistedImages = useCallback(async (prompt: string, size?: string, numberOfImages?: number): Promise<AiImageResponse> => {
        if (communityService !== null) {
            return await communityService.fetchAiImage(prompt, size, numberOfImages);
        }
        return AiImageResponse.EMPTY;
    }, [communityService]);

    return {
        fetchAiAssistedText,
        fetchAiAssistedImages
    };
};