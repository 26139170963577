export class UiAttribute {
    public static EMPTY = new UiAttribute('', '', 0, 0);

    constructor(
        public className: string,
        public style: string,
        public width: number,
        public height: number,
    ) {
    }
}