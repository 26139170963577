import React from 'react';
import {Link} from 'react-router-dom';
import {Localizer} from '@ideascale/commons';
import {TimeUtil} from 'utils/TimeUtil';
import {NotificationData} from 'models/NotificationData';
import {NotificationType} from 'models/enums/NotificationType';

type DigestNotificationProps = {
    localizer: Localizer;
    notification: NotificationData;
    getDataForDigestModal: (year: string, notificationType: NotificationType, month?: string, week?: string) => void;
}

export const DigestNotification = (props: DigestNotificationProps) => {
    const {localizer, notification, getDataForDigestModal} = props;

    return (
        <>
            {notification.type.name === NotificationType.MONTHLY_MODERATOR_DIGEST
                ?
                <>
                    {TimeUtil.getMonthNameFromNumber(+notification.viewAttributes.month)} {' '}
                    {notification.viewAttributes.year} {' '}
                    <Link to="#"
                          onClick={() => getDataForDigestModal(notification.viewAttributes.year,
                              NotificationType.MONTHLY_MODERATOR_DIGEST,
                              notification.viewAttributes.month, '')}>
                        {localizer.msg('notification.moderator-digest.moderators-digest')}
                    </Link>
                </> : <>
                    {TimeUtil.getFormattedDateTime(notification.viewAttributes.fromDate, 'dd LLLL yyyy')} {' - '}
                    {TimeUtil.getFormattedDateTime(notification.viewAttributes.toDate, 'dd LLLL yyyy')}
                    {' '}
                    <Link to="#"
                          onClick={() => getDataForDigestModal(notification.viewAttributes.year,
                              NotificationType.WEEKLY_MODERATOR_DIGEST, '', notification.viewAttributes.weekOfYear)}>
                        {localizer.msg('notification.moderator-digest.moderators-digest')}
                    </Link>
                </>
            }
        </>
    );
};