import React from 'react';
import './BasicActivityCard.scss';

type BasicActivitySummaryType = {
    headerTitle?: string;
    headerSummary?: React.ReactNode;
    children?: React.ReactNode;
}

export const BasicActivityCard = (props: BasicActivitySummaryType) => {
    const {headerTitle, headerSummary, children} = props;
    return (
        <div className="card panel basic-activity-card py-2">
            {headerTitle ?
                <h3 className="card-header h6 fw-bold mb-0">{headerTitle}</h3>
                : ''
            }
            {headerSummary ?
                <div className={`header-summary ${headerTitle ? 'mb-2' : 'p-3'}`}>
                    <p className="mb-0 fw-normal">{headerSummary}</p>
                </div>
                : ''
            }
            {children ?
                <div className="card-body">
                    {children}
                </div> : ''
            }
        </div>
    );
};