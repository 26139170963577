import React, {Fragment, useCallback} from 'react';
import {Icon} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useLocalizer} from 'hooks/useLocalizer';
import {StageSummaryHeading} from './StageSummaryHeading';
import {AssessmentStageSummary} from 'models/AssessmentStageSummary';
import {IdeaStageSummaryProps} from 'models/types/IdeaStageSummaryProps';

export const AssessmentSummary = (props: IdeaStageSummaryProps<AssessmentStageSummary>) => {
    const {ideaStageSummary: {availableStatistic, viewOverallRating, avgRating, viewAllowed, participationAllowed, reviewCompletedCount, reviewMemberCount}} = props;
    const localizer = useLocalizer();

    const ratings = useCallback(() => {
        const stars: string[] = [];
        for (let count = 1; count <= 5; count++) {
            if (count <= avgRating) {
                stars.push('star-solid');
            } else if (AssessmentStageSummary.isHalfStarPrinted(count, avgRating)) {
                stars.push('star-half');
            } else {
                stars.push('star-outlined');
            }
        }
        return stars;
    }, [avgRating]);

    return (
        <Fragment>
            <StageSummaryHeading className="mb-4"
                                 heading={viewOverallRating ? localizer.msg('stage.assessment.overall-rating') : localizer.msg('stage.assessment.heading')}/>

            <div className="stage-data mb-2 mt-n3">
                {
                    ((participationAllowed || viewAllowed) && viewOverallRating)
                        ?
                        <>
                            {
                                availableStatistic
                                    ?
                                    <>

                                        <div className="five-stars">
                                            {
                                                ratings().map((value, index) =>
                                                    <Icon key={index} name={value} iconSpritePath={svgIconsPath} width={20} height={20}/>)
                                            }
                                        </div>
                                        <span>
                                            {localizer.msg('stage.assessment.star', {count: Number(avgRating.toFixed(2))})}
                                            {' '}<span className="stage-progress">({localizer.msg('stage.common.stage-completed', {completed: reviewCompletedCount, total: reviewMemberCount.toLocaleString()})})</span>
                                        </span>

                                    </>
                                    :
                                    <em>{localizer.msg('stage.assessment.invalid-rating')}</em>
                            }
                        </>
                        :
                        <em>{localizer.msg('stage.assessment.awaits')}</em>
                }
            </div>
        </Fragment>
    );
};