import React, {useCallback, useRef} from 'react';
import {Avatar, RichTextClassificationConfig} from '@ideascale/ui';
import {
    AttachmentAttributeParameters,
    ClassificationAttachmentAttribute,
    ClassificationLabel,
    ClassificationsHolder,
    ClassificationUtil,
    ConfirmationModal,
    FileAttachmentDetail,
    FileUploadResponse,
    Localizer,
    UnfurledUrl,
    UnfurledUrlList,
    UploadProgressCallback,
    useToggle
} from '@ideascale/commons';
import {useIdeaAttachment} from 'hooks/useIdeaAttachment';
import {Scroller} from 'utils/Scroller';
import {useAppContext} from 'contexts/AppContext';
import {CommentActions} from './CommentActions';
import {AttachmentForm} from './AttachmentForm';
import {CommentMeta} from './CommentMeta';
import {LabelsView} from 'shared/LabelsView';
import {StageCommentForm} from './StageCommentForm';
import {AttachmentPreview} from 'components/idea-details-tabs/AttachmentPreview';
import {HtmlRenderer} from '../shared/HtmlRenderer';
import {CommentSummary} from 'models/comments/CommentSummary';
import {CommentLevel} from 'models/comments/CommentLevel';
import {StageCommentSummary} from 'models/comments/StageCommentSummary';
import {CommentActionsPermission} from 'models/comments/CommentActionsPermission';
import {AttachmentFormInputs} from 'models/comments/AttachmentFormInputs';
import {StageCommentType} from 'models/comments/StageCommentType';
import {StageCommentPermissions} from 'models/comments/StageCommentPermissions';
import {FileAttachmentsHolder} from 'models/FileAttachmentsHolder';
import {ID_PREFIXES} from 'constants/AppConstants';
import styles from './Comment.module.scss';

type StageCommentProps = {
    idPrefix: string;
    localizer: Localizer;
    ideaId: number;
    comment: StageCommentSummary;
    onAddFileAttachments: (commentId: number, attachments: FileAttachmentDetail[], commentLevel: CommentLevel, attributes?: ClassificationAttachmentAttribute[], parentId?: number) => void;
    deleteComment: (comment: StageCommentSummary, commentLevel: CommentLevel, parentId?: number) => void;
    annotateComment: (commentId: number, commentLevel: CommentLevel) => void;
    commentLevel: CommentLevel;
    uploadFile: (data: FormData, onUploadProgress: UploadProgressCallback) => Promise<FileUploadResponse>;
    addAttachments: (commentId: number, formInputs: AttachmentFormInputs, attributeApiParameters?: Array<AttachmentAttributeParameters>) => Promise<FileAttachmentsHolder>;
    sourceId: number;
    stageCommentType: StageCommentType;
    commentPermissions: StageCommentPermissions;
    onReplyComment: (commentId: number, comment: StageCommentSummary) => void;
    unfurlUrl: (url: string) => Promise<UnfurledUrl>;
    className?: string;
    deleteAttachment?: (commentId: number, attachmentId: number, commentLevel: CommentLevel, parentId?: number) => void;
    classificationsMenuData?: ClassificationsHolder | {};
    richTextClassificationConfig?: RichTextClassificationConfig;
}

export const StageComment = (props: StageCommentProps) => {
    const {
        idPrefix,
        localizer,
        comment,
        ideaId,
        sourceId,
        stageCommentType,
        className = '',
        deleteAttachment,
        onReplyComment,
        onAddFileAttachments,
        deleteComment,
        annotateComment,
        commentLevel,
        uploadFile,
        addAttachments,
        commentPermissions,
        unfurlUrl,
        richTextClassificationConfig,
        classificationsMenuData
    } = props;
    const {
        authentication: {actor},
        communityConfig: {maxFileSizeLimit, atMentionEnabled, classificationEnabled, unfurlUrlEnabled}
    } = useAppContext();
    const {updateIdeaDetailsCommentAttachments} = useIdeaAttachment();
    const {author, text, createdAt} = comment;
    const [showReplyForm, toggleReplyForm] = useToggle(false);
    const [showAttachmentForm, toggleAttachmentForm] = useToggle(false);
    const [showDeleteConfirmation, toggleDeleteConfirmation] = useToggle(false);
    const [showDeleteAttachmentConfirmation, toggleDeleteAttachmentConfirmation] = useToggle(false);
    const deleteAttachmentId = useRef<number>(0);

    const scrollToTargetedForm = useCallback(() => {
        const timeOutId = setTimeout(() => {
            Scroller.scrollTo(`${idPrefix}-${ID_PREFIXES.REPLY_FORM}-${comment.id}`, {offset: -300, duration: 300});
            clearTimeout(timeOutId);
        }, 100);
    }, [comment.id, idPrefix]);

    const scrollToComment = useCallback(() => {
        const timeOutId = setTimeout(() => {
            Scroller.scrollTo(`${idPrefix}-${comment.id}`, {offset: -300, duration: 300});
            clearTimeout(timeOutId);
        }, 100);
    }, [comment.id, idPrefix]);

    const onReplyClick = useCallback(() => {
        toggleReplyForm(true);
        toggleAttachmentForm(false);
        scrollToTargetedForm();
    }, [scrollToTargetedForm, toggleAttachmentForm, toggleReplyForm]);

    const onAttachmentClick = useCallback(() => {
        toggleAttachmentForm(true);
        toggleReplyForm(false);
        scrollToTargetedForm();
    }, [scrollToTargetedForm, toggleAttachmentForm, toggleReplyForm]);

    const onSubmitReplyClick = useCallback((commentId: number, replyComment: StageCommentSummary) => {
        toggleReplyForm(false);
        onReplyComment(commentId, replyComment);
    }, [onReplyComment, toggleReplyForm]);

    const onAddAttachments = useCallback(async (commentId: number, attachments: FileAttachmentDetail[], commentLevel: CommentLevel, attributes?: ClassificationAttachmentAttribute[]) => {
        onAddFileAttachments && onAddFileAttachments(commentId, attachments, commentLevel, attributes, comment.id);
        toggleAttachmentForm(false);

        const newAttachments = attachments.filter(item => !comment.attachments?.map(file => file.filename).includes(item.filename));
        if (comment.annotated && newAttachments?.length > 0) {
            await updateIdeaDetailsCommentAttachments(ideaId, newAttachments, (attributes?.filter(item => item.name === 'filename') as ClassificationAttachmentAttribute[]));
        }
    }, [comment.annotated, comment.attachments, comment.id, ideaId, onAddFileAttachments, toggleAttachmentForm, updateIdeaDetailsCommentAttachments]);

    const onDeleteAttachment = async (commentId: number, attachmentId: number, commentLevel: CommentLevel) => {
        deleteAttachment?.(commentId, attachmentId, commentLevel, comment.id);
        if (comment.annotated) {
            await updateIdeaDetailsCommentAttachments(ideaId, [], [], attachmentId);
        }
    };

    const onReplyCancel = () => {
        toggleReplyForm();
        scrollToComment();
    };

    const onAttachmentCancel = () => {
        toggleAttachmentForm();
        scrollToComment();
    };

    const onDeleteComment = useCallback((stageComment: StageCommentSummary, commentLevel: CommentLevel) => {
        deleteComment(stageComment, commentLevel, comment.id);
    }, [comment.id, deleteComment]);

    const getAtMention = () => {
        return (atMentionEnabled && actor.id !== comment.author.id && comment.author.username) ? `@${comment.author.username} ` : '';
    };

    return (
        <article id={`${idPrefix}-${comment.id}`} className={`${styles.comment} ${className}`} tabIndex={-1}>
            <h3 className="sr-only">{localizer.msg('comments.comment-title')}</h3>
            <div className="d-flex">
                <div className="avatar-container me-2 me-lg-4 avatar-md">
                    <Avatar src={author.avatar}
                            alt={author.username ? localizer.msg('common.user-avatar', {username: author.username}) : ''}
                            size="md"/>
                </div>

                <div className="flex-grow-1">
                    <CommentMeta elementId={`${idPrefix}-${comment.id}`} comment={comment as CommentSummary}
                                 localizer={localizer} actor={actor}
                                 createdAt={createdAt}/>
                    {
                        comment.labels && (
                            <div className="comment-labels mt-1">
                                <LabelsView labels={comment.labels} showStackView={false}/>
                            </div>
                        )
                    }
                    {
                        classificationEnabled && comment.attributes &&
                        <ClassificationLabel
                            classification={ClassificationUtil.getEffectiveAttribute(comment.attributes)}/>
                    }
                    <div className={`comment-content ${comment.labels ? 'mt-2' : 'mt-3'}`}>
                        <HtmlRenderer content={text} reactOptions={{atMention: true}}/>
                        <UnfurledUrlList content={text} unfurlUrl={unfurlUrl} localizer={localizer}
                                         enabled={unfurlUrlEnabled}/>
                    </div>
                    <AttachmentPreview attachments={comment.attachments} localizer={localizer}
                                       onDeleteAttachment={(attachmentId) => {
                                           deleteAttachmentId.current = attachmentId;
                                           toggleDeleteAttachmentConfirmation(true);
                                       }}
                                       enabledClassification={classificationEnabled}
                                       fileAttributes={ClassificationUtil.getAppliedFileClassifications('filename', comment.attributes)}/>

                    {
                        comment.replies && comment.replies.length > 0 && <>
                            <ul className="list-unstyled child-comments">
                                {
                                    comment.replies.map(reply => (
                                            <StageComment
                                                idPrefix={idPrefix}
                                                commentLevel={CommentLevel.Level2}
                                                localizer={localizer}
                                                ideaId={ideaId} comment={reply}
                                                onReplyComment={onSubmitReplyClick}
                                                onAddFileAttachments={onAddAttachments}
                                                deleteComment={onDeleteComment}
                                                annotateComment={annotateComment}
                                                uploadFile={uploadFile}
                                                addAttachments={addAttachments}
                                                commentPermissions={commentPermissions}
                                                stageCommentType={stageCommentType}
                                                sourceId={sourceId}
                                                unfurlUrl={unfurlUrl}
                                                deleteAttachment={(commentId, attachmentId, commentLevel) => onDeleteAttachment(commentId, attachmentId, commentLevel)}
                                                richTextClassificationConfig={richTextClassificationConfig}
                                                classificationsMenuData={classificationsMenuData}
                                            />
                                        )
                                    )
                                }
                            </ul>
                        </>
                    }
                    {
                        <CommentActions
                            localizer={localizer}
                            commentId={comment.id}
                            actionsPermitted={comment.actions as CommentActionsPermission}
                            labelsPermitted={false}
                            onReplyClick={onReplyClick}
                            onAttachmentClick={onAttachmentClick}
                            onDeleteClick={() => toggleDeleteConfirmation(true)}
                            pinned={false}
                            annotated={comment.annotated}
                            onAnnotateClick={() => annotateComment(comment.id, commentLevel)}
                        />
                    }

                    {
                        showReplyForm &&
                        <StageCommentForm
                            id={`${idPrefix}-${ID_PREFIXES.REPLY_FORM}-${comment.id}`}
                            localizer={localizer}
                            atMention={getAtMention()}
                            actor={actor}
                            ideaId={ideaId}
                            parentId={comment.id}
                            sourceId={sourceId}
                            onCancel={onReplyCancel}
                            commentPermissions={commentPermissions}
                            addNewComment={(replyComment) => onSubmitReplyClick(comment.id, replyComment)}
                            stageCommentType={stageCommentType}
                            richTextClassificationConfig={richTextClassificationConfig}
                            classificationsMenuData={classificationsMenuData}/>
                    }
                    {
                        showAttachmentForm &&
                        <AttachmentForm id={`${idPrefix}-${ID_PREFIXES.REPLY_FORM}-${comment.id}`} localizer={localizer}
                                        maxFileSizeLimit={maxFileSizeLimit}
                                        commentId={comment.id}
                                        addNotes={false}
                                        uploadFile={uploadFile}
                                        onAttachmentCancel={onAttachmentCancel}
                                        addAttachments={addAttachments}
                                        classificationsMenuData={classificationsMenuData}
                                        classificationEnabled={classificationEnabled}
                                        onAddAttachments={(commentId, attachments, attributes) => onAddAttachments(commentId, attachments, CommentLevel.Level1, attributes)}/>
                    }

                    {
                        showDeleteConfirmation && (
                            <ConfirmationModal localizer={localizer} message={localizer.msg('comments.confirm-message')}
                                               open={showDeleteConfirmation} toggle={toggleDeleteConfirmation}
                                               onConfirm={() => onDeleteComment(comment, commentLevel)}/>
                        )
                    }

                    {
                        showDeleteAttachmentConfirmation && (
                            <ConfirmationModal localizer={localizer} message={localizer.msg('comments.confirm-message')}
                                               open={showDeleteAttachmentConfirmation}
                                               toggle={toggleDeleteAttachmentConfirmation}
                                               onConfirm={() => {
                                                   onDeleteAttachment(comment.id, deleteAttachmentId.current, commentLevel).then();
                                                   deleteAttachmentId.current = 0;
                                               }}/>
                        )
                    }
                </div>
            </div>
        </article>
    );
};
