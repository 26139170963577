import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {flushSync} from 'react-dom';
import {Controller, useForm} from 'react-hook-form';
import {Form} from 'reactstrap';
import {
    ActionButton,
    Avatar,
    Checkbox,
    HtmlConverter,
    Icon,
    IdeascaleSelect,
    NewRichTextEditor,
    OptionType,
    RichTextClassificationConfig,
    RichTextEditorHandler
} from '@ideascale/ui';
import {
    Actor,
    AttachmentType,
    ClassificationAttachmentAttribute,
    ClassificationAttachmentAttributesData,
    ClassificationAttribute,
    ClassificationsHolder,
    ClassificationTreeMenu,
    ClassificationUtil,
    ClassifiedAttachment,
    EMBEDDED_FILE_NAME_PREFIX,
    HookFormProvider,
    LabelData,
    LabelsAction,
    Localizer,
    RenderFormat,
    useApiErrorResponseHandler,
    useAtMentionConfiguration,
    useAttachmentClassificationErrorHandler,
    useFileUploadIntermediary,
    useHandleFormSubmit
} from '@ideascale/commons';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {ID_PREFIXES} from 'constants/AppConstants';
import {useCommentService} from 'hooks/useCommentService';
import {useIdeaAttachment} from 'hooks/useIdeaAttachment';
import {useFileUploadService} from 'hooks/useFileUploadService';
import {useAppContext} from 'contexts/AppContext';
import {CreateFileAttachment} from 'components/idea-details-tabs/CreateFileAttachment';
import {ContributionType} from 'models/comments/ContributionType';
import {CommentSummary} from 'models/comments/CommentSummary';
import {CommentPermissions} from 'models/comments/CommentPermissions';
import {CommentFormFields} from 'models/types/CommentFormFields';
import styles from './CommentForm.module.scss';

const COMMENT_SUBMIT_FORM_ID = 'comment-submit-form';

type CommentFormProps = {
    localizer: Localizer;
    actor: Actor;
    ideaId: number;
    commentPermissions?: CommentPermissions;
    addNewComment?: (comment: CommentSummary) => void;
    classificationsMenuData?: ClassificationsHolder | {};
    richTextClassificationConfig?: RichTextClassificationConfig;
}

export const CommentForm = (props: CommentFormProps) => {
    const {
        localizer,
        actor,
        ideaId,
        addNewComment,
        commentPermissions,
        classificationsMenuData = {},
        richTextClassificationConfig
    } = props;
    const {
        currentCampaign,
        communityConfig: {
            multipleLanguageSupported,
            preferredLanguage,
            supportedLanguages,
            atMentionEnabled,
            maxFileSizeLimit,
            offensiveEmojis,
            classificationEnabled
        }
    } = useAppContext();
    const {fetchAtMentionMembers, fetchCommentLabels, commentCreation} = useCommentService();
    const {tempImageUpload, uploadFile} = useFileUploadService();
    const {onUploadFile, fileUploading} = useFileUploadIntermediary(uploadFile);
    const {updateIdeaDetailsCommentAttachments} = useIdeaAttachment();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [selectedLabelIds, setSelectedLabelIds] = useState<number[]>([]);
    const [privateComment, setPrivateComment] = useState<boolean>(false);
    const richTextEditorNode = useRef<RichTextEditorHandler>(null);
    const [clearAllAttachments, setClearAllAttachments] = useState<boolean>(false);
    const [selectedAttachmentClassification, setSelectedAttachmentClassification] = useState<ClassificationAttribute>();
    const [selectedTextClassification, setSelectedTextClassification] = useState<ClassificationAttribute>();
    const [appliedAttachmentClassifications, setAppliedAttachmentClassifications] = useState<ClassifiedAttachment>({});
    const [appliedAttachmentClassificationsData, setAppliedAttachmentClassificationsData] = useState<ClassificationAttachmentAttributesData>({});

    const updateAppliedAttachmentClassifications = useCallback((key: string, value: ClassifiedAttachment) => {
        setAppliedAttachmentClassificationsData(prev => {
            return {...prev, [key]: value};
        });
        setAppliedAttachmentClassifications(value);
    }, []);

    const fetchAtMentionData = useAtMentionConfiguration(
        atMentionEnabled,
        fetchAtMentionMembers,
        {
            campaignId: currentCampaign?.id ?? 0,
            ideaId,
        }
    );

    const defaultFormValues = {
        parentId: 0,
        parentType: ContributionType.idea,
        text: '',
        pinned: false,
        privateComment: false,
        addTracking: commentPermissions?.trackingIdea ?? false,
        annotated: false,
        hideCommentAuthor: false,
        attachments: [],
        labelIds: [],
        languageId: preferredLanguage?.id ?? -1
    };
    const methods = useForm<CommentFormFields>({
        defaultValues: defaultFormValues,
        mode: 'onSubmit',
        reValidateMode: 'onSubmit'
    });
    const {
        register,
        handleSubmit,
        control,
        reset,
        setError,
        clearErrors,
        formState: {errors, isSubmitting}
    } = methods;
    const onSubmit = useHandleFormSubmit(handleSubmit);
    const {onClassificationError} = useAttachmentClassificationErrorHandler({
        fieldKey: 'attachments',
        setError,
        localizer
    });

    const onFormSubmit = async (formInputs: CommentFormFields) => {
        try {
            const response = await commentCreation({
                ...formInputs,
                text: HtmlConverter.toServerHtmlFormat(formInputs.text),
                parentId: ideaId,
                labelIds: selectedLabelIds,
                renderFormat: RenderFormat.HTML,
                attachments: formInputs.attachments.map(attachment => ({
                    filename: attachment.filename,
                    altText: attachment.altText.trim() || attachment.filename,
                })),
                attributeApiParameters: classificationEnabled ?
                    [
                        ClassificationUtil.buildAttachmentClassificationRequest(
                            'filename',
                            formInputs.attachments.map(attachment => attachment.filename),
                            appliedAttachmentClassificationsData['attachments'])
                    ] : undefined
            });
            if (response) {
                addNewComment && addNewComment(response);
                setSelectedLabelIds([]);
                setPrivateComment(false);
                flushSync(() => setClearAllAttachments(true));
                reset(defaultFormValues, {
                    keepDefaultValues: true,
                    keepIsSubmitted: false
                });
                richTextEditorNode.current?.setTextWithBlur('');
                setSelectedTextClassification(undefined);
                setSelectedAttachmentClassification(undefined);
                if (response.annotated && response?.attachments?.length > 0) {
                    await updateIdeaDetailsCommentAttachments(ideaId, response.attachments, (response.attributes?.filter(item => item.name === 'filename') as ClassificationAttachmentAttribute[]));
                }
            }
        } catch (error: any) {
            handleErrorResponse(error, {setFormError: setError});
        } finally {
            setClearAllAttachments(false);
        }
    };

    const options = useMemo(() => {
        const languageOptions: OptionType[] = supportedLanguages.map(language => {
            return {value: language.id, label: language.name};
        });
        return languageOptions;
    }, [supportedLanguages]);

    const addLabel = useCallback(async (labelData: LabelData) => {
        setSelectedLabelIds(prevState => [...prevState, labelData.id]);
    }, []);

    const removeLabel = useCallback(async (labelData: LabelData) => {
        setSelectedLabelIds(prevState => prevState.filter(id => id !== labelData.id));
    }, []);

    useEffect(() => {
        if (errors.text) {
            setTimeout(() => {
                richTextEditorNode.current?.focus();
            }, 300);
        }
    }, [errors]);

    return (
        <div id={`${ID_PREFIXES.COMMENT_FORM}`} className={`${styles.commentForm} d-flex border-top-1`}>
            <Avatar className="me-2 me-lg-4" src={actor.avatar}
                    alt={actor.username ? localizer.msg('common.user-avatar', {username: actor.username}) : ''}
                    size="md"/>
            <div className="flex-grow-1 min-width">
                <HookFormProvider {...methods}>
                    <Form id={COMMENT_SUBMIT_FORM_ID} onSubmit={onSubmit(onFormSubmit, isSubmitting || fileUploading)}>
                        {
                            commentPermissions?.commentApprovalRequired
                                ? (
                                    <span
                                        className="d-inline-block mb-2 text-warning">{localizer.msg('comments.comment-form.audit-warning')}</span>
                                )
                                : null
                        }
                        <div className={`form-group ${errors.text ? 'has-error' : ''}`}>
                            <Controller
                                name="text"
                                control={control}
                                rules={{
                                    required: localizer.msg('comments.comment-form.idea-text-required'),
                                    ...(classificationEnabled && {validate: () => !richTextEditorNode.current?.hasInvalidClassifications() || localizer.msg('frontend-shared.classification.invalid-classifications-msg')})
                                }}
                                render={({field: {onChange}}) =>
                                    <Fragment>
                                        {
                                            classificationEnabled && (
                                                <ClassificationTreeMenu
                                                    localizer={localizer}
                                                    id={'post-comment-classification-menu'}
                                                    classifications={classificationsMenuData || {}}
                                                    appliedClassifications={selectedTextClassification}
                                                    onApplyClassifications={value => {
                                                        setSelectedTextClassification(value);
                                                        richTextEditorNode.current?.insertClassification(ClassificationUtil.getLabel(value));
                                                    }}/>
                                            )
                                        }
                                        <NewRichTextEditor
                                            id={'post-comment'}
                                            toolbar={'standard'}
                                            className={'form-control'}
                                            placeholder={atMentionEnabled ? localizer.msg('idea.comment.form.placeholder-with-at-mention') : localizer.msg('idea.comment.form.placeholder')}
                                            svgIconPath={svgIconsPath}
                                            onChange={value => {
                                                clearErrors('text');
                                                onChange(value);
                                            }}
                                            ref={richTextEditorNode}
                                            enableEmojiPicker={true}
                                            offensiveEmojis={offensiveEmojis}
                                            enableAtMention={atMentionEnabled}
                                            fetchMentionUsers={fetchAtMentionData}
                                            uploadImage={tempImageUpload}
                                            maxFileSize={maxFileSizeLimit}
                                            classificationConfig={richTextClassificationConfig}
                                            defaultFileNamePrefix={EMBEDDED_FILE_NAME_PREFIX.COMMENT}
                                        />
                                    </Fragment>
                                }
                            />
                            {
                                errors.text && (
                                    <p className="invalid-feedback d-block" data-role="field-error" aria-live="polite">
                                        {errors.text.message}
                                    </p>
                                )
                            }
                        </div>

                        {
                            multipleLanguageSupported && (
                                <div className="form-group">
                                    <label className="fw-bold" htmlFor="language-field">
                                        {localizer.msg('comments.comment-form.idea-submission-language')}
                                        <span className="font-size-lg" aria-hidden={true}>*</span>
                                        <span className="sr-only">{localizer.msg('common.form.required')}</span>
                                    </label>
                                    <Controller
                                        control={control} name="languageId"
                                        render={({field}) =>
                                            <IdeascaleSelect
                                                name={field.name}
                                                isMulti={false}
                                                inputId="language-field"
                                                defaultValue={{
                                                    value: preferredLanguage.id,
                                                    label: preferredLanguage.name
                                                } as OptionType}
                                                value={options.find(item => item.value === field.value)}
                                                options={options}
                                                onChange={(selectedOption: any) => {
                                                    field.onChange(selectedOption.value);
                                                }}
                                            />
                                        }/>
                                </div>
                            )
                        }

                        {
                            commentPermissions?.attachmentAllowed && (
                                <div
                                    className={`form-group ${errors.attachments && !Array.isArray(errors.attachments) ? 'has-error' : ''}`}>
                                    <label
                                        htmlFor="attachments" className="fw-bold me-2">
                                        <span>
                                           {localizer.msg('comments.comment-form.attachment-label')}
                                        </span>
                                    </label>
                                    <Fragment>
                                        {
                                            classificationEnabled && (
                                                <ClassificationTreeMenu
                                                    localizer={localizer}
                                                    id={'post-comment-attachments-classification-menu'}
                                                    classifications={classificationsMenuData || {}}
                                                    appliedClassifications={selectedAttachmentClassification}
                                                    onApplyClassifications={value => {
                                                        clearErrors('attachments');
                                                        setSelectedAttachmentClassification(value);
                                                    }}/>
                                            )
                                        }
                                        <CreateFileAttachment
                                            attachmentType={AttachmentType.COMMENT}
                                            inputId="attachments"
                                            classificationsMenuData={classificationsMenuData}
                                            localizer={localizer}
                                            maxFileSizeInMb={maxFileSizeLimit}
                                            uploadFile={onUploadFile}
                                            multiple={true}
                                            clearAll={clearAllAttachments}
                                            classificationEnabled={classificationEnabled}
                                            selectedClassification={selectedAttachmentClassification}
                                            updateAppliedAttachmentClassifications={updateAppliedAttachmentClassifications}
                                            appliedClassifications={appliedAttachmentClassifications}
                                            onClassificationError={onClassificationError}/>
                                        {
                                            errors?.attachments && (errors?.attachments as any)?.type === 'classificationRequired' && (
                                                <p className="invalid-feedback d-block" data-role="field-error"
                                                   aria-live="polite">
                                                    {(errors.attachments as any)?.message}
                                                </p>
                                            )
                                        }
                                    </Fragment>
                                </div>
                            )
                        }

                        {
                            commentPermissions?.pinnedAllowed && (
                                <div className="form-group">
                                    <Checkbox className="fw-bold" inputId="pin-comment-field"
                                              label={localizer.msg('comments.comment-form.idea-comment-pin')}
                                              {...register('pinned')}/>
                                </div>
                            )
                        }

                        {
                            commentPermissions?.privateSubmissionAllowed && (
                                <div className="form-group">
                                    <Checkbox className="fw-bold" inputId="private-comment-field"
                                              label={localizer.msg('comments.comment-form.idea-comment-private')}
                                              defaultChecked={privateComment}
                                              {...register('privateComment', {onChange: (e) => setPrivateComment(e.target.checked)})} />
                                </div>
                            )
                        }

                        {
                            commentPermissions?.emailNotificationsAllowed && (
                                <div className="form-group">
                                    <Checkbox className="fw-bold" inputId="notify-via-mail-field"
                                              label={localizer.msg('comments.comment-form.idea-comment-post-notify')}
                                              defaultChecked={commentPermissions.trackingIdea}
                                              {...register('addTracking')}/>
                                </div>
                            )
                        }

                        {
                            commentPermissions?.anonymousSubmissionAllowed && (
                                <div className="form-group">
                                    <Checkbox className="fw-bold"
                                              inputId="hide-comment-author-field"
                                              defaultChecked={commentPermissions.defaultAnonymousSubmissionEnabled}
                                              label={localizer.msg('comments.comment-form.submit-comment-anonymously')}
                                              {...register('hideCommentAuthor')}/>
                                </div>
                            )
                        }

                        {
                            commentPermissions?.annotationAllowed && !privateComment && (
                                <div className="form-group">
                                    <Checkbox className="fw-bold"
                                              inputId="annotate-comment-field"
                                              value="true"
                                              label={
                                                  <>
                                                      <Icon className="position-relative pos-top-n1"
                                                            iconSpritePath={svgIconsPath} width={12}
                                                            height={12}
                                                            name="arrow-up-to-line"/>
                                                      <span
                                                          className="ms-2">{localizer.msg('comments.comment-form.idea-comment-annotate')}</span>
                                                  </>
                                              }
                                              {...register('annotated')}/>

                                </div>
                            )
                        }

                        <div className="form-group mb-0 d-flex">
                            {
                                commentPermissions?.labelsApplicable && (
                                    <LabelsAction
                                        localizer={localizer}
                                        fetchLabels={() => fetchCommentLabels(ideaId)}
                                        targetId={ideaId}
                                        addLabel={addLabel}
                                        svgIconPath={svgIconsPath}
                                        removeLabel={removeLabel} toggleButtonTag="button"
                                        selectedLabelIds={selectedLabelIds}
                                        toggleButtonClass={`btn-label bg-transparent px-0 ${selectedLabelIds.length > 0 ? 'selected' : ''}`}/>
                                )
                            }
                            <ActionButton className="ms-auto" form={COMMENT_SUBMIT_FORM_ID} type="submit"
                                          loading={isSubmitting || fileUploading} data-test-element-id="submit-comment">
                                {localizer.msg('comments.comment-form.submit-comment')}
                            </ActionButton>
                        </div>
                    </Form>
                </HookFormProvider>
            </div>
        </div>
    );
};
