import {VoteRetractType} from '../enums/VoteRetractType';

export class CommentVoteResponse {
    static EMPTY = new CommentVoteResponse(0, 0, 0, 0, VoteRetractType.RETRACT);

    constructor(
        public actorVoteValue: number,
        public upVoteCount: number,
        public downVoteCount: number,
        public netVoteCount: number,
        public retractType: VoteRetractType
    ) {
    }
}