import {FieldAttributeParameters, PrivateMessageRequestParams} from '@ideascale/commons';

export class IdeaInternalShareRequestParams extends PrivateMessageRequestParams {
    constructor(
        ids: number[],
        subject: string,
        body: string,
        public ideaId: number,
        public attributeApiParameters?: FieldAttributeParameters[]
    ) {
        super(ids, subject, body, attributeApiParameters);
    }
}