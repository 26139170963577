import React, {Dispatch, Fragment, SetStateAction, useCallback, useState} from 'react';
import {Avatar} from '@ideascale/ui';
import {
    ClassificationsHolder,
    ConfirmationModal,
    Member,
    MemberLink,
    MessageModal,
    PagedResponseContent,
    StringUtil,
    useApiErrorResponseHandler,
    useToggle
} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useMessageService} from 'hooks/useMessageService';
import {useFileUploadService} from 'hooks/useFileUploadService';
import {BuildTeamAssignMember} from './BuildTeamAssignMember';
import {BuildTeamEditRole} from './BuildTeamEditRole';
import {BuildTeamStageActivity, TeamPosition} from 'models/BuildTeamStageActivity';
import {StageActionResponse} from 'models/StageActionResponse';
import {BuildTeamStageSummary} from 'models/BuildTeamStageSummary';
import {TeamRoleData} from 'models/TeamRoleData';
import {PageParameters} from 'models/types/PageParameters';
import {BaseIdeaStageSummary} from 'models/BaseIdeaStageSummary';
import unknownAvatar from 'assets/img/unknown.png';

type BuildTeamMemberProps = {
    ideaId: number;
    stageId: number;
    teamPosition: TeamPosition;
    defaultPositionRemovable: boolean;
    assignUnassignTeamMemberAllowed: boolean;
    joinTeamAllowed: boolean;
    addRemoveTeamPositionAllowed: boolean;
    setActivityData: Dispatch<SetStateAction<BuildTeamStageActivity>>;
    fetchTeamRole: () => Promise<Array<TeamRoleData>>;
    fetchRecipients(pageParameters: PageParameters): Promise<PagedResponseContent<Member>>;
    fetchTeamMember(ideaId: number, stageId: number, pageParameters: PageParameters): Promise<PagedResponseContent<Member>>;
    joinBuildTeam(ideaId: number, stageId: number, positionId: number): Promise<StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>>;
    assignTeamRole: (ideaId: number, stageId: number, positionId: number, teamRoleId: number) => Promise<StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>>;
    unassignMemberBuildTeam(ideaId: number, stageId: number, memberId: number): Promise<StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>>;
    promoteToOwnerBuildTeam(ideaId: number, stageId: number, memberId: number): Promise<StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>>;
    removePositionBuildTeam(ideaId: number, stageId: number, positionId: number): Promise<StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>>;
    assignMemberBuildTeam(ideaId: number, stageId: number, positionId: number, memberId: number): Promise<StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>>;
    updateIdeaStageSummary: <T extends BaseIdeaStageSummary>(stageSummary: T) => void;
    fetchClassifications: () => Promise<ClassificationsHolder>
}

enum CONFIRMATION_TYPE {
    UNASSIGN_MEMBER = 'UNASSIGN_MEMBER',
    REMOVE_POSITION = 'REMOVE_POSITION',
    PROMOTE_MEMBER = 'PROMOTE_MEMBER'
}

export const BuildTeamMember = (props: BuildTeamMemberProps) => {
    const {
        teamPosition,
        defaultPositionRemovable,
        joinBuildTeam,
        ideaId,
        stageId,
        fetchTeamRole,
        assignTeamRole,
        unassignMemberBuildTeam,
        promoteToOwnerBuildTeam,
        removePositionBuildTeam,
        fetchTeamMember,
        assignMemberBuildTeam,
        setActivityData,
        updateIdeaStageSummary,
        assignUnassignTeamMemberAllowed,
        joinTeamAllowed,
        addRemoveTeamPositionAllowed,
        fetchRecipients,
        fetchClassifications,
    } = props;
    const localizer = useLocalizer();
    const {
        authentication: {actor}, communityConfig: {
            name: communityName,
            offensiveEmojis,
            maxFileSizeLimit,
            classificationEnabled,
            privateMessageMaxRecipients,
            privateMessageMaxSubjectLength,
        }
    } = useAppContext();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const {tempImageUpload} = useFileUploadService();
    const {onMemberSendMessages} = useMessageService();
    const [messageOpen, toggleMessageOpen] = useToggle(false);
    const [confirmationState, setConfirmationState] = useState<CONFIRMATION_TYPE | null>(null);

    const handleJoin = (e: React.MouseEvent, ideaId: number, stageId: number, positionId: number) => {
        e.preventDefault();
        joinBuildTeam(ideaId, stageId, positionId).then(status => {
            if (status) {
                if (status.stageActivity) {
                    setActivityData(status.stageActivity);
                }
                if (status.stageSummary) {
                    updateIdeaStageSummary(status.stageSummary);
                }
            }
        }).catch((error) => {
            handleErrorResponse(error);
        });
    };

    const onConfirmAction = useCallback(async () => {
        try {
            let response;
            switch (confirmationState) {
                case CONFIRMATION_TYPE.PROMOTE_MEMBER:
                    response = teamPosition.member && await promoteToOwnerBuildTeam(ideaId, stageId, teamPosition.member.id);
                    break;
                case CONFIRMATION_TYPE.UNASSIGN_MEMBER:
                    response = teamPosition.member && await unassignMemberBuildTeam(ideaId, stageId, teamPosition.member.id);
                    break;
                case CONFIRMATION_TYPE.REMOVE_POSITION:
                    response = await removePositionBuildTeam(ideaId, stageId, teamPosition.position.positionId);
                    break;
                default:
                    break;
            }
            if (response) {
                if (response.stageActivity) {
                    setActivityData(response.stageActivity);
                }
                if (response.stageSummary) {
                    updateIdeaStageSummary(response.stageSummary);
                }
            }
            setConfirmationState(null);
        } catch (error) {
            handleErrorResponse(error);
        }
    }, [confirmationState, handleErrorResponse, ideaId, promoteToOwnerBuildTeam, removePositionBuildTeam, setActivityData, stageId, teamPosition.member, teamPosition.position.positionId, unassignMemberBuildTeam, updateIdeaStageSummary]);

    return (
        <Fragment>
            <li className="pb-3 pt-3 border-bottom-1">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center team-member-information">
                        <div>
                            <Avatar className="me-2" size="sm"
                                    src={teamPosition.member !== null ? teamPosition.member.avatar : unknownAvatar}
                                    alt={teamPosition.member?.username ? localizer.msg('common.user-avatar', {username: teamPosition.member.username}) : 'u'}/>
                        </div>
                        <div>
                            <span className="fw-bold font-size-sm">
                                {teamPosition.member !== null ?
                                    <MemberLink id={teamPosition.member.id}
                                                identityHidden={teamPosition.member.identityHidden}
                                                title={actor.id === teamPosition.member.id ? localizer.msg('common.you') : teamPosition.member.name}>
                                        {actor.id === teamPosition.member.id ? localizer.msg('common.you') : StringUtil.textTruncate(teamPosition.member.name, 30)}
                                    </MemberLink>
                                    : localizer.msg('stage.build-team.open-position')}
                            </span>
                            {actor.id === teamPosition.member?.id ? '' :
                                <span title={teamPosition?.member?.username}
                                      className="font-size-sm">
                                    {teamPosition?.member?.username ? '@' + StringUtil.textTruncate(teamPosition.member.username, 25) : ''}
                                </span>
                            }
                            <span className="font-size-sm fw-bold"
                                  title={teamPosition.position.roleName}>
                            {teamPosition.position.roleName === ''
                                ? localizer.msg('stage.build-team.team-member')
                                : localizer.msg('stage.build-team.team-member') + ' - ' + StringUtil.textTruncate(teamPosition.position.roleName, 30)}
                            </span>
                            {addRemoveTeamPositionAllowed &&
                                <BuildTeamEditRole
                                    updateIdeaStageSummary={updateIdeaStageSummary}
                                    ideaId={ideaId}
                                    stageId={stageId}
                                    positionId={teamPosition.position.positionId}
                                    fetchTeamRole={fetchTeamRole}
                                    assignTeamRole={assignTeamRole}
                                    setActivityData={setActivityData}/>
                            }
                        </div>
                    </div>
                    <div className="team-member-action">
                        {
                            teamPosition.messagingAllowed &&
                            <button className="btn btn-link p-0 me-2 fw-bold font-size-sm"
                                    data-test-element-id="btn-message-member"
                                    onClick={() => toggleMessageOpen()}>{localizer.msg('stage.build-team.message-member')}
                            </button>
                        }
                        {
                            teamPosition.promoteToOwnerAllowed &&
                            <button className="btn btn-link p-0 me-2 fw-bold font-size-sm"
                                    data-test-element-id="btn-promote-to-owner"
                                    type="button"
                                    onClick={() => setConfirmationState(CONFIRMATION_TYPE.PROMOTE_MEMBER)}>
                                {localizer.msg('stage.build-team.promote-to-owner')}</button>
                        }
                        {
                            (addRemoveTeamPositionAllowed && teamPosition.member === null && (!teamPosition.position.defaultPosition || defaultPositionRemovable)) &&
                            <button className="btn btn-link p-0 me-2 fw-bold font-size-sm"
                                    data-test-element-id="btn-remove-position"
                                    onClick={() => setConfirmationState(CONFIRMATION_TYPE.REMOVE_POSITION)}>
                                {localizer.msg('stage.build-team.remove-position')}
                            </button>
                        }
                        {
                            joinTeamAllowed && teamPosition.member === null &&
                            <button className="btn btn-link p-0 me-2 fw-bold font-size-sm"
                                    data-test-element-id="btn-join-team"
                                    onClick={(e) => handleJoin(e, ideaId, stageId, teamPosition.position.positionId)}>
                                {localizer.msg('stage.build-team.join-team')}
                            </button>
                        }
                        {
                            assignUnassignTeamMemberAllowed && teamPosition.member === null &&
                            <BuildTeamAssignMember
                                updateIdeaStageSummary={updateIdeaStageSummary}
                                positionId={teamPosition.position.positionId}
                                fetchTeamMember={fetchTeamMember}
                                ideaId={ideaId}
                                setActivityData={setActivityData}
                                stageId={stageId}
                                assignMemberBuildTeam={assignMemberBuildTeam}/>
                        }
                        {
                            assignUnassignTeamMemberAllowed && teamPosition.member &&
                            <button className="btn btn-link p-0 me-2 fw-bold font-size-sm"
                                    type="button"
                                    data-test-element-id="btn-leave-team"
                                    onClick={() => setConfirmationState(CONFIRMATION_TYPE.UNASSIGN_MEMBER)}>
                                {teamPosition.member.id === actor.id ? localizer.msg('stage.build-team.leave-this-team') : localizer.msg('stage.build-team.remove-from-team')}
                            </button>
                        }
                    </div>
                </div>
            </li>
            {
                messageOpen &&
                <MessageModal open={messageOpen} toggle={toggleMessageOpen}
                              fetchRecipients={fetchRecipients}
                              fetchClassifications={fetchClassifications}
                              tempImageUpload={tempImageUpload}
                              localizer={localizer}
                              communityName={communityName}
                              offensiveEmojis={offensiveEmojis}
                              maxFileSizeLimit={maxFileSizeLimit}
                              maxSubjectLength={privateMessageMaxSubjectLength}
                              classificationEnabled={classificationEnabled}
                              onMemberSendMessages={onMemberSendMessages}
                              privateMessageMaxRecipients={privateMessageMaxRecipients}
                              defaultMember={[{
                                  value: teamPosition.member?.id,
                                  label: teamPosition.member?.name || '',
                                  image: teamPosition.member?.avatar
                              }]}
                />
            }
            {
                confirmationState &&
                <ConfirmationModal localizer={localizer}
                                   open={!!confirmationState}
                                   toggle={() => setConfirmationState(null)}
                                   message={localizer.msg('common.are-you-sure')}
                                   onConfirm={onConfirmAction}
                                   size="sm"/>
            }
        </Fragment>
    );
};