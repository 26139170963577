import React, {Fragment, useMemo, useState} from 'react';
import {Controller, FieldValues, useForm} from 'react-hook-form';
import {ActionButton, Icon, IdeascaleCustomRadio, IdeascaleSelect, Modal, OptionType} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {
    Campaign,
    CampaignsHolder,
    isHtmlInject,
    Localizer,
    PagedResponseContent,
    PageParameters,
    useApiErrorResponseHandler,
    useHandleFormSubmit,
    useToggle
} from '@ideascale/commons';
import {SearchInputGroup} from 'shared/SearchInputGroup';
import {CampaignSearchField} from 'components/search/CampaignSearchField';
import {FeaturedCampaignsComponentData} from 'models/landing-page/FeaturedCampaignsComponentData';
import {CampaignFilter} from 'models/enums/landing-page/CampaignFilter';
import {CampaignDisplayType} from 'models/enums/landing-page/CampaignDisplayType';
import {LANDING_PAGE_PLAIN_TEXT_MAX_CHAR_COUNT} from 'constants/AppConstants';
import './CampaignsConfigModal.scss';

type CampaignsConfigModalProps = {
    open: boolean;
    toggle: () => void;
    config: FeaturedCampaignsComponentData
    updateConfig: (config: FeaturedCampaignsComponentData) => Promise<void>;
    getCampaigns: (param: PageParameters) => Promise<PagedResponseContent<CampaignsHolder<Campaign>>>
    localizer: Localizer;
    onComponentEdited: () => void;
}

const FIELD_NAMES: Record<string, string> = {
    title: 'title',
    campaignFilter: 'campaignOption',
    campaigns: 'campaigns',
    campaignDisplayType: 'campaignDisplayType'
};

export const CampaignsConfigModal = (props: CampaignsConfigModalProps) => {
    const {
        open,
        toggle,
        config,
        updateConfig,
        getCampaigns,
        localizer,
        onComponentEdited
    } = props;
    const {register, control, handleSubmit, formState: {errors, isSubmitting}, setError} = useForm();
    const onFormSubmit = useHandleFormSubmit(handleSubmit);
    const [showCampaignDropdown, toggleCampaignDropdown] = useToggle(config.campaigns?.length > 0 || false);
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [configUpdated, setConfigUpdated] = useState(false);

    const submitConfig = async (data: FieldValues) => {
        const newConfig = {...config};
        newConfig.title = data.title;
        newConfig.campaignFilter = data[FIELD_NAMES.campaignFilter].value;
        let newCampaigns = [];
        if (data[FIELD_NAMES.campaignFilter].value === campaignOptions.selectedCampaigns.value) {
            newCampaigns = data[FIELD_NAMES.campaigns].map((option: OptionType) => ({
                id: option.value,
                name: option.label
            }));
        }
        newConfig.campaigns = newCampaigns;
        newConfig.campaignDisplayType = data[FIELD_NAMES.campaignDisplayType];

        try {
            await updateConfig(newConfig);
            onComponentEdited();
            toggle();
        } catch (e) {
            handleErrorResponse(e, {setFormError: setError});
        }
    };

    const campaignOptions = {
        allCampaigns: {
            label: localizer.msg('leaderboard.campaign.all'),
            value: CampaignFilter.ACTIVE
        },
        selectedCampaigns: {
            label: localizer.msg('landing-page.components.campaign.selected-campaigns'),
            value: CampaignFilter.SELECTED
        }
    };

    const groupOptions = useMemo((): OptionType[] => {
        return [campaignOptions.allCampaigns, campaignOptions.selectedCampaigns];
    }, [campaignOptions.allCampaigns, campaignOptions.selectedCampaigns]);

    const renderDisplayTypeIcon = (displayType: CampaignDisplayType) => {
        switch (displayType) {
            case CampaignDisplayType.STACK:
                return <Icon className="me-1" iconSpritePath={svgIconPath}
                             name="lines-three-horizontal-strong"
                             width={16}
                             height={16}/>;
            case CampaignDisplayType.CAROUSEL:
                return <Icon className="me-1"
                             iconSpritePath={svgIconPath}
                             name="rectangles-three-foreground-background"
                             width={22}
                             height={14}/>;
            case CampaignDisplayType.GRID:
                return <Icon className="me-1"
                             iconSpritePath={svgIconPath}
                             name="grid-squares"
                             width={18}
                             height={18}/>;
            default:
                return null;
        }
    };

    const renderDisplayTypeIconAndText = (displayType: CampaignDisplayType) => {
        return <Fragment>
            {renderDisplayTypeIcon(displayType)}
            {localizer.msg(`landing-page.components.campaign.display-type.${displayType.toLowerCase()}`)}
        </Fragment>;
    };

    return (
        <Modal className="campaign-config-modal" isOpen={open} toggle={toggle}
               modalHeaderId="campaign-config-modal-header"
               title={localizer.msg('landing-page.components.edit.campaigns')}
               autoFocus={false}>
            <form className="featured-campaigns-form" id={`campaign-form-${config.id}`}
                  onSubmit={onFormSubmit(submitConfig, isSubmitting || !configUpdated)}>
                <div className={`form-group ${errors[FIELD_NAMES.title] ? 'has-error' : ''}`}>
                    <label htmlFor={FIELD_NAMES.title} className="control-label fw-bold">
                        {localizer.msg('landing-page.components.common.heading')}
                        <span className="font-size-lg" aria-hidden={true}>*</span>
                    </label>
                    <input type="text" id={FIELD_NAMES.title} className="form-control" autoFocus
                           defaultValue={config.title}
                           aria-required="true"
                           {...register(FIELD_NAMES.title, {
                               onChange: () => setConfigUpdated(prevState => !prevState ? true : prevState),
                               required: localizer.msg('common.errors.required'),
                               validate: value => !isHtmlInject(value) || localizer.msg('common.errors.html'),
                               maxLength: {
                                   value: LANDING_PAGE_PLAIN_TEXT_MAX_CHAR_COUNT,
                                   message: localizer.msg('common.errors.max-length', {count: LANDING_PAGE_PLAIN_TEXT_MAX_CHAR_COUNT}),
                               },
                               setValueAs: value => value.trim()
                           })}/>
                    {
                        errors[FIELD_NAMES.title] &&
                        <div className="invalid-feedback d-block">
                            {errors[FIELD_NAMES.title].message}
                        </div>
                    }
                </div>

                <div className={`form-group ${errors[FIELD_NAMES.campaignFilter] ? 'has-error' : ''}`}>
                    <label className="fw-bold" htmlFor={FIELD_NAMES.campaignFilter}>
                        {localizer.msg('landing-page.components.edit.campaign-group')}
                    </label>
                    <Controller
                        control={control} name={FIELD_NAMES.campaignFilter}
                        defaultValue={showCampaignDropdown ? campaignOptions.selectedCampaigns : campaignOptions.allCampaigns}
                        rules={{
                            required: localizer.msg('common.errors.required')
                        }}
                        render={({field}) =>
                            <SearchInputGroup className="flex-nowrap"
                                              groupText={<Icon name="loudspeaker" fill="#666" height={18} width={18}
                                                               iconSpritePath={svgIconPath}/>}>
                                <IdeascaleSelect
                                    ariaLabel={localizer.msg('landing-page.components.edit.campaign-group')}
                                    name={field.name}
                                    isMulti={false}
                                    inputId={FIELD_NAMES.campaignFilter}
                                    placeholder={localizer.msg('landing-page.components.edit.campaign-group')}
                                    value={field.value}
                                    options={groupOptions}
                                    onChange={(selectedOption: any) => {
                                        setConfigUpdated(prevState => !prevState ? true : prevState);
                                        field.onChange(selectedOption);
                                        toggleCampaignDropdown(selectedOption.value === campaignOptions.selectedCampaigns.value);
                                    }}
                                />
                            </SearchInputGroup>
                        }/>
                </div>

                {
                    showCampaignDropdown && (
                        <div className={`form-group ${errors[FIELD_NAMES.campaigns] ? 'has-error' : ''}`}>
                            <Controller
                                control={control} name={FIELD_NAMES.campaigns}
                                defaultValue={config.campaigns?.map(item => ({
                                    label: item.title,
                                    value: item.id
                                }))}
                                rules={{
                                    required: localizer.msg('common.errors.required')
                                }}
                                render={({field}) =>
                                    <CampaignSearchField inputGroupClassName="flex-nowrap" fetchCampaigns={getCampaigns}
                                                         setCampaigns={(option) => {
                                                             setConfigUpdated(prevState => !prevState ? true : prevState);
                                                             field.onChange(option);
                                                         }}
                                                         defaultValue={field.value} required={true}
                                    />
                                }/>
                            {
                                errors[FIELD_NAMES.campaigns] &&
                                <div className="invalid-feedback d-block">
                                    {errors[FIELD_NAMES.campaigns].message}
                                </div>
                            }
                        </div>
                    )
                }
                <div className="form-group">
                    <label className="fw-bold">
                        {localizer.msg('landing-page.components.campaign.display-type.label')}
                    </label>
                    <div className="d-flex mt-2">
                        {
                            Object.values(CampaignDisplayType).map(displayType =>
                                <IdeascaleCustomRadio
                                    key={displayType}
                                    defaultChecked={config.campaignDisplayType}
                                    value={displayType}
                                    inputId={`display-type-${displayType}`}
                                    renderLabel={renderDisplayTypeIconAndText}
                                    {...register(FIELD_NAMES.campaignDisplayType, {
                                        onChange: () => setConfigUpdated(prevState => !prevState ? true : prevState)
                                    })}/>
                            )
                        }
                    </div>
                </div>

                <div className="form-group mt-5 text-end">
                    <button className="btn btn-cancel me-3" type="button" onClick={toggle}
                            data-test-element-id="btn-cancel">
                        {localizer.msg('common.actions.cancel')}
                    </button>
                    <ActionButton type="submit" form={`campaign-form-${config.id}`} loading={isSubmitting}
                                  disabled={!configUpdated} data-test-element-id="btn-submit">
                        {localizer.msg('common.actions.submit')}
                    </ActionButton>
                </div>
            </form>
        </Modal>
    );
};