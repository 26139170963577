import React from 'react';
import {Link} from 'react-router-dom';
import {Trans} from 'react-i18next';
import {Localizer} from '@ideascale/commons';
import {Image} from 'shared/Image';
import {NotificationData} from 'models/NotificationData';
import {appLinks} from 'services/AppLinks';
import trendingIcon from 'assets/img/trending.png';

type IdeaTopTrendingNotificationProps = {
    localizer: Localizer;
    notification: NotificationData;
}

export const IdeaTopTrendingNotification = (props: IdeaTopTrendingNotificationProps) => {
    const {localizer, notification} = props;

    const trendingLabel = localizer.msg('notification.idea.trending.name');

    return (
        <Trans i18nKey="notification.idea.trending.message" values={{
            idea: notification.viewAttributes.ideaTitle
        }}>
            <Link to={appLinks.ideaDetails(notification.ideaId)}/>
            <Image src={trendingIcon} width={15} height={15} alt={trendingLabel} title={trendingLabel}/>
        </Trans>
    );
};