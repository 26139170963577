import React from 'react';
import {Alert} from 'reactstrap';
import {Icon} from '@ideascale/ui';
import {Localizer} from '@ideascale/commons';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';

type InfoMessageProps = {
    localizer: Localizer;
    message?: string;
    textAlignment?: 'start' | 'center' | 'end';
    cssClass?: string;
}

export const InfoMessage = ({localizer, message, textAlignment = 'center', cssClass}: InfoMessageProps) => {
    return (
        <Alert color="info" className={`my-4 text-${textAlignment} ${cssClass}`}>
            <Icon className="is-icon me-1" name="info-outlined-circle" iconSpritePath={svgIconPath}/> {message ? message : localizer.msg('edit-mode.default-image-info')}
        </Alert>
    );
};