import {useMutation, useQueryClient} from 'react-query';
import {ClassificationAttachmentAttribute, FileAttachment, useApiErrorResponseHandler} from '@ideascale/commons';
import {useIdeaService} from './useIdeaService';
import {useIdeaUpdater} from './useIdeaUpdater';
import {useUpdateEffectiveClassifications} from './useUpdateEffectiveClassifications';
import {useLocalizer} from './useLocalizer';
import {QUERY_KEYS} from 'constants/AppConstants';
import {IdeaDetail} from 'models/IdeaDetail';
import {AttachmentParameters} from 'models/AttachmentParameters';
import {FileAttachmentsHolder} from 'models/FileAttachmentsHolder';
import {AttachmentType} from 'models/enums/AttachmentType';

type DeleteAttachmentParams = {
    type: AttachmentType;
    ideaId: number;
    fileId: number;
    commentId?: number;
    fieldId?: number;
    fileName?: string;
    classificationEnabled: boolean
}

type SaveAttachmentParams = {
    ideaId: number;
    attachmentParams: AttachmentParameters;
}

export const useIdeaAttachment = (ideaListQueryKey: any = '') => {
    const queryClient = useQueryClient();
    const {
        deleteIdeaAttachment,
        deleteIdeaFieldAttachment,
        deleteIdeaStageAttachment,
        deleteCommentAttachment,
        deleteStageCommentAttachment,
        saveIdeaAttachments
    } = useIdeaService();
    const localizer = useLocalizer();
    const {ideaDetailsQueryKey, updateIdeaDetails, updateIdeaListCallback} = useIdeaUpdater();
    const {updateIdeaEffectiveClassifications} = useUpdateEffectiveClassifications();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});

    const deleteAttachmentMutation = useMutation((params: DeleteAttachmentParams) => {
            if (params.type === AttachmentType.STAGE_COMMENT) {
                return deleteStageCommentAttachment(params.fileId);
            } else if (params.type === AttachmentType.COMMENT && params.commentId) {
                return deleteCommentAttachment(params.commentId, params.fileId);
            } else if (params.type === AttachmentType.IDEA_FIELD && params.ideaId && params.fieldId && params.fileName) {
                return deleteIdeaFieldAttachment(params.ideaId, params.fieldId, params.fileName);
            } else if (params.type === AttachmentType.STAGE_FIELD && params.ideaId && params.fieldId && params.fileName) {
                return deleteIdeaStageAttachment(params.ideaId, params.fieldId, params.fileName);
            } else {
                return deleteIdeaAttachment(params.ideaId, params.fileId);
            }
        },
        {
            onSuccess: async (data, params) => {
                await queryClient.cancelQueries(ideaDetailsQueryKey(params.ideaId));
                const oldIdeaDetails = queryClient.getQueryData(ideaDetailsQueryKey(params.ideaId)) as IdeaDetail;
                const oldIdeaAttachments = queryClient.getQueryData([QUERY_KEYS.IDEA_ATTACHMENTS, params.ideaId]) as FileAttachmentsHolder;
                await updateIdeaEffectiveClassifications(params.ideaId, params.classificationEnabled);

                updateIdeaListCallback(ideaListQueryKey, params.ideaId, 'attachments', (modifiedIdea) => {
                    return modifiedIdea.attachments.filter(item => item.id !== params.fileId);
                });
                if (oldIdeaDetails) {
                    if (params.type === AttachmentType.STAGE_COMMENT || params.type === AttachmentType.COMMENT) {
                        await updateIdeaDetails(params.ideaId, 'commentAttachments', oldIdeaDetails.commentAttachments.filter(item => item.id !== params.fileId));
                    } else if (params.type === AttachmentType.IDEA_FIELD || params.type === AttachmentType.STAGE_FIELD) {
                        await updateIdeaDetails(params.ideaId, 'customAttachments', oldIdeaDetails.customAttachments.filter((item => item.id !== params.fileId)));
                    } else {
                        await updateIdeaDetails(params.ideaId, 'attachments', oldIdeaDetails.attachments.filter(item => item.id !== params.fileId));
                    }
                }
                if (oldIdeaAttachments) {
                    queryClient.setQueryData<FileAttachmentsHolder>([QUERY_KEYS.IDEA_ATTACHMENTS, params.ideaId], {
                        ...oldIdeaAttachments,
                        attachments: oldIdeaAttachments.attachments.filter(item => item.id !== params.fileId)
                    });
                }
            },
            onError: (error) => {
                handleErrorResponse(error);
            }
        }
    );

    const saveAttachmentMutation = useMutation(({
                                                    ideaId,
                                                    attachmentParams
                                                }: SaveAttachmentParams) => saveIdeaAttachments(ideaId, attachmentParams),
        {
            onSuccess: async (addedAttachments, params) => {
                await queryClient.cancelQueries(ideaDetailsQueryKey(params.ideaId));
                const oldIdeaDetails = queryClient.getQueryData(ideaDetailsQueryKey(params.ideaId)) as IdeaDetail;
                const oldIdeaAttachments = queryClient.getQueryData([QUERY_KEYS.IDEA_ATTACHMENTS, params.ideaId]) as FileAttachmentsHolder;
                updateIdeaListCallback(ideaListQueryKey, params.ideaId, 'attachments', (modifiedIdea) => {
                    return modifiedIdea.attachments.concat(addedAttachments.attachments);
                });

                const attachmentAttributes = addedAttachments?.attributes as ClassificationAttachmentAttribute[] ?? [];

                if (attachmentAttributes?.length > 0) {
                    updateIdeaListCallback(ideaListQueryKey, params.ideaId, 'attributes', (modifiedIdea) => {
                        return [...modifiedIdea?.attributes ?? [], ...attachmentAttributes];
                    });
                }

                if (oldIdeaDetails) {
                    await updateIdeaDetails(params.ideaId, 'attachments', oldIdeaDetails.attachments.concat(addedAttachments.attachments));

                    if (attachmentAttributes?.length > 0) {
                        await updateIdeaDetails(params.ideaId, 'attributes', [...oldIdeaDetails?.attributes ?? [], ...attachmentAttributes]);
                    }
                }

                if (oldIdeaAttachments) {
                    queryClient.setQueryData<FileAttachmentsHolder>([QUERY_KEYS.IDEA_ATTACHMENTS, params.ideaId], {
                        ...oldIdeaAttachments,
                        attachments: oldIdeaAttachments.attachments.concat(addedAttachments.attachments),
                        ...(attachmentAttributes?.length > 0 && {attributes: [...oldIdeaAttachments?.attributes ?? [], ...attachmentAttributes]})
                    });
                }
            }
        }
    );

    const updateIdeaDetailsCommentAttachments = async (ideaId: number, attachments: FileAttachment[], attributes?: ClassificationAttachmentAttribute[], attachmentId?: number) => {
        const ideaDetails = queryClient.getQueryData([QUERY_KEYS.IDEA_DETAILS, ideaId]) as IdeaDetail;

        if (ideaDetails) {
            const commentAttachments = ideaDetails.commentAttachments?.filter(item => item.id !== attachmentId);
            await updateIdeaDetails(ideaId, 'commentAttachments', [...commentAttachments, ...attachments]);
            if (attributes) {
                await updateIdeaDetails(ideaId, 'attributes', [...ideaDetails?.attributes ?? [], ...(attributes?.filter(item => item.name === 'filename') as ClassificationAttachmentAttribute[])]);
            }
        }
    };

    return {deleteAttachmentMutation, saveAttachmentMutation, updateIdeaDetailsCommentAttachments};
};