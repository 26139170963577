import React from 'react';
import {Link} from 'react-router-dom';
import {Trans} from 'react-i18next';
import {Localizer, MemberLink} from '@ideascale/commons';
import {NotificationData} from 'models/NotificationData';
import {KudoType} from 'models/enums/KudoType';
import {appLinks} from 'services/AppLinks';
import {KudoImage} from 'shared/KudoImage';

type CommentKudoReceivedNotificationProps = {
    localizer: Localizer;
    actorId: number;
    notification: NotificationData;
}

export const CommentKudoReceivedNotification = (props: CommentKudoReceivedNotificationProps) => {
    const {localizer, actorId, notification} = props;

    const actorIsKudosSender = notification.sourceMemberId === actorId;
    const otherMemberId = actorIsKudosSender ? notification.receiverId : notification.sourceMemberId;
    const otherMemberName = actorIsKudosSender ? notification.viewAttributes.kudoReceiverName : notification.viewAttributes.sourceMemberName;

    return (
        <Trans i18nKey={`notification.kudos.comment.${actorIsKudosSender ? 'received' : 'given'}`} values={{
            member: otherMemberName,
            ideaTitle: notification.viewAttributes.ideaTitle
        }}>
            <MemberLink id={otherMemberId}
                        identityHidden={notification.identityHidden}>{otherMemberName}</MemberLink>
            <KudoImage localizer={localizer} kudoType={KudoType.REGULAR} width={18} height={18}/>
            <Link to={appLinks.ideaDetailsComment(notification.ideaId, notification.commentId)}/>
            {
                notification.viewAttributes.ideaTitle ? <span/> : <Link to={appLinks.ideaDetails(notification.ideaId)}/>
            }
            {
                notification.viewAttributes.ideaTitle && <Link to={appLinks.ideaDetails(notification.ideaId)}/>
            }
        </Trans>
    );
};