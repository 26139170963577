import React, {Dispatch, Fragment, SetStateAction, useCallback, useEffect, useState} from 'react';
import {Progress} from 'reactstrap';
import {useWatch} from 'react-hook-form';
import {Button, CropperModal, Divider, FileError, FileInput, Icon} from '@ideascale/ui';
import loadingSvg from '@ideascale/ui/dist/assets/loading.svg';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {
    AlertEvent,
    AlertType,
    ALT_TEXT_MAX_LENGTH,
    buildAlertEventData,
    eventDispatcher,
    isHtmlInject,
    Localizer,
    UploadedResponse,
    UploadProgressCallback,
    useHookFormContext,
    useToggle
} from '@ideascale/commons';
import {ImageConfigFields} from 'models/landing-page/ImageConfigFields';
import 'components/landing-page/ImageOrLink.scss';

type ImageOrLinkProps = {
    localizer: Localizer;
    maxFileSizeLimit: number;
    onConfigUpdated: () => void;
    uploadImage: (data: FormData, onUploadProgress: UploadProgressCallback) => Promise<UploadedResponse>;
    previewMode?: 'full' | 'small' | 'medium';
    cropWidth?: number;
    cropHeight?: number;
    setImageUploading: Dispatch<SetStateAction<boolean>>;
    configUpdated: boolean;
}

export const ImageOrLink = (props: ImageOrLinkProps) => {
    const {
        cropWidth,
        cropHeight,
        localizer,
        maxFileSizeLimit,
        previewMode,
        onConfigUpdated,
        uploadImage,
        setImageUploading,
        configUpdated,
    } = props;
    const {
        control,
        clearErrors,
        setValue,
        register,
        formState: {errors},
        setError,
        resetField,
    } = useHookFormContext<ImageConfigFields>();
    const {imageLink, altText, uploadedImage} = useWatch({control});
    const [showCropper, toggleShowCropper] = useToggle(false);
    const [imageFile, setImageFile] = useState<File>();
    const [uploadQueueInfo, setUploadQueueInfo] = useState<Map<string, number>>(new Map());
    const [imageLinkInvalid, setImageLinkInvalid] = useState(false);

    const updateUploadQueue = useCallback((progressInfo: { fileName: string, percent: number }) => {
        setUploadQueueInfo(prevProgressInfo => new Map(prevProgressInfo.set(progressInfo.fileName, progressInfo.percent)));
    }, []);

    const uploadQueueSize = uploadQueueInfo.size;
    const totalPercentage = Array.from(uploadQueueInfo.values())
        .reduce((accumulatedPercentage, percentage) => accumulatedPercentage + percentage, 0);
    const averagePercentageCompleted = totalPercentage / uploadQueueSize;

    const onImageLinkLoaded = () => {
        setImageLinkInvalid(false);
        clearErrors(['imageLink', 'uploadedImage']);
        setValue('uploadedImage', '');
        configUpdated && setValue('altText', '');
    };

    const resetImageLink = useCallback(() => {
        setValue('imageLink', '');
        setValue('altText', '');
        clearErrors(['imageLink', 'altText']);
    }, [clearErrors, setValue]);

    const resetUploadedImage = () => {
        setValue('uploadedImage', '');
        resetField('altText');
        clearErrors(['uploadedImage', 'altText']);
    };

    const onImageLinkPreviewError = () => {
        if (imageLink) {
            setError(
                'imageLink',
                {message: localizer.msg('landing-page.components.media.image-error')}
            );
        } else {
            resetImageLink();
            resetField('altText');
        }
    };

    const onUploadedImageDelete = () => {
        onConfigUpdated();
        resetUploadedImage();
    };

    const onImageLinkDelete = () => {
        onConfigUpdated();
        resetImageLink();
        resetUploadedImage();
    };

    const handleUpload = useCallback(async (file: FileList | File[]) => {
        const imageFile = file[0];
        if (uploadImage) {
            const fileName = imageFile.name;
            const formData = new FormData();
            formData.append('file', imageFile);
            formData.append('fileType', 'IMAGE');

            try {
                setImageUploading(true);
                const fileUploadResponse = await uploadImage(formData, (progressEvent) => {
                    const percentCompleted = Math.ceil(((progressEvent.loaded * 100) / progressEvent.total) || 100);
                    updateUploadQueue({fileName, percent: percentCompleted});
                });
                if (fileUploadResponse) {
                    onConfigUpdated();
                    setValue('uploadedImage', fileUploadResponse.url);
                    resetField('altText', {defaultValue: ''});
                    resetImageLink();
                }

                setUploadQueueInfo(prevProgressInfo => {
                    const newQueueInfo = new Map(prevProgressInfo);
                    newQueueInfo.delete(fileName);
                    return newQueueInfo;
                });
            } catch (e: any) {
                if (e?.status && e?.status === 400) {
                    setError('uploadedImage', {message: e.reason});
                } else {
                    const {message} = (e as Error) || {};
                    if (message) {
                        setError('uploadedImage', {message});
                    }
                }
            } finally {
                setImageUploading(false);
            }
        }
    }, [onConfigUpdated, resetField, resetImageLink, setError, setImageUploading, setValue, updateUploadQueue, uploadImage]);

    const onFileUploadError = useCallback((error: FileError) => {
        switch (error.type) {
            case 'maxSizeExceeded':
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.error, localizer.msg('common.form.attachment.upload.error.size-limit', {size: maxFileSizeLimit})));
                break;
            case 'multipleNotAllowed':
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.warn, localizer.msg('common.form.attachment.upload.error.multiple-not-allowed')));
                break;
            case 'maxFileNumberExceeded':
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.warn, localizer.msg('common.form.attachment.upload.error.multiple-drop-limit-text', {amount: error.amount})));
                break;
            default:
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.error, localizer.msg('common.form.attachment.upload.error.unknown')));
        }
    }, [localizer, maxFileSizeLimit]);

    const onFileUploadSuccess = useCallback(async (files: FileList) => {
        if (files.length < 1 || !files[0]) return;
        const file = files[0];
        clearErrors('uploadedImage');

        if ((!/.*\.(gif|bmp|png|jpe?g)$/gmi.test(file.name))) {
            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.error, localizer.msg('landing-page.components.media.unsupported-file-type')));
            return;
        }

        setImageFile(file);
        toggleShowCropper(true);
    }, [clearErrors, localizer, toggleShowCropper]);

    useEffect(() => {
        setImageLinkInvalid(!!errors.imageLink);
    }, [errors.imageLink]);

    return (
        <Fragment>
            <div className="image-url">
                {
                    uploadedImage &&
                    <Fragment>
                        <div>
                            <div className="w-100 py-2 mb-md-0 position-relative">
                                <img className={`preview rounded ${previewMode}`} src={uploadedImage} alt={altText}/>
                                <Button className="remove-image" type="button" onClick={onUploadedImageDelete}
                                        aria-label={localizer.msg('common.actions.delete')}
                                        data-test-element-id="btn-delete-media">
                                    <Icon iconSpritePath={svgIconPath} name="trash-can-alt" width={18} height={18}/>
                                </Button>
                            </div>
                        </div>
                        <div className={`form-group my-3 ${errors.altText && uploadedImage ? 'has-error' : ''}`}>
                            <label className="control-label fw-bold my-1" htmlFor="altText">
                                {localizer.msg('frontend-shared.fields.attachment.alt-text')}
                            </label>
                            <input className="form-control mt-2" id="altText" type="text" autoFocus={true}
                                   placeholder={localizer.msg('landing-page.components.media.config-modal.alt-text-placeholder')}
                                   maxLength={ALT_TEXT_MAX_LENGTH}
                                   {...register('altText', {
                                       onChange: onConfigUpdated,
                                       validate: value => !isHtmlInject(value || '') || localizer.msg('frontend-shared.common.errors.html'),
                                       setValueAs: value => value.trim()
                                   })}
                            />
                            {
                                errors.altText && uploadedImage &&
                                <div className="invalid-feedback d-block">
                                    {errors.altText.message}
                                </div>
                            }
                        </div>
                    </Fragment>
                }
                <div className="my-3">
                    <div className="form-group react-file-uploader">
                        <FileInput
                            labels={{
                                browseFile: localizer.msg('frontend-shared.form.attachment.browse-file'),
                                dropFile: localizer.msg('frontend-shared.form.attachment.drop-file-here'),
                                or: localizer.msg('frontend-shared.form.attachment.or'),
                                dragAndDrop: localizer.msg('frontend-shared.form.attachment.drag-drop'),
                                uploadInstruction: localizer.msg('frontend-shared.form.attachment.upload-warning')
                            }}
                            svgIconPath={svgIconPath}
                            onError={onFileUploadError}
                            onSuccess={onFileUploadSuccess}
                            maxSizeInMB={maxFileSizeLimit}
                            multiple={false}
                        />
                        {
                            averagePercentageCompleted < 100 &&
                            <Progress className="progressbar-track mt-1" barClassName="progressbar" max={100}
                                      value={averagePercentageCompleted}>
                                {localizer.msg('common.form.attachment.upload.uploading')}
                            </Progress>
                        }
                    </div>
                </div>
            </div>

            <Divider/>

            <div className={`form-group ${errors.imageLink ? 'has-error' : ''} image-link`}>
                <label className="fw-bold" htmlFor="imageLink">
                    {localizer.msg('landing-page.components.media.config-modal.insert-link')}
                </label>
                <input className="form-control mt-2" id="imageLink" type="text"
                       placeholder={localizer.msg('landing-page.components.media.config-modal.image-link-placeholder')}
                       {...register('imageLink', {
                           onChange: () => {
                               onConfigUpdated();
                               if (!errors?.imageLink?.message) {
                                   resetUploadedImage();
                               }
                           },
                           validate: () => !imageLinkInvalid || localizer.msg('landing-page.components.media.image-error'),
                       })}
                />
                {
                    errors.imageLink &&
                    <div className="invalid-feedback d-block">
                        {errors.imageLink.message}
                    </div>
                }
                <div className="form-text text-gray my-2">
                    {localizer.msg('common.form.attachment.link-image-warning-msg')}
                </div>
                <div
                    className={`w-100 py-4 mb-md-0 position-relative image-link-preview ${errors?.imageLink?.message || !imageLink ? 'd-none' : ''}`}>
                    <img className="preview rounded" src={imageLink} alt={altText} onLoad={onImageLinkLoaded}
                         onError={onImageLinkPreviewError}/>
                    <Button className="remove-image position-absolute" type="button" color="danger"
                            aria-label={localizer.msg('common.actions.delete')}
                            onClick={onImageLinkDelete}>
                        <Icon iconSpritePath={svgIconPath} name="trash-can-alt" width={18} height={18}/>
                    </Button>
                    <div className={`form-group my-3 ${errors.altText && imageLink ? 'has-error' : ''}`}>
                        <label className="control-label fw-bold my-1" htmlFor="imageLinkAltText">
                            {localizer.msg('frontend-shared.fields.attachment.alt-text')}
                        </label>
                        {
                            imageLink &&
                            <input className="form-control" id="imageLinkAltText" maxLength={255}
                                   placeholder={localizer.msg('landing-page.components.media.config-modal.alt-text-placeholder')}
                                   {...register('altText', {
                                       onChange: onConfigUpdated,
                                       validate: value => !isHtmlInject(value || '') || localizer.msg('frontend-shared.common.errors.html'),
                                       setValueAs: value => value.trim()
                                   })}
                            />
                        }
                        {
                            errors.altText && imageLink &&
                            <div className="invalid-feedback d-block">
                                {errors.altText.message}
                            </div>
                        }
                    </div>
                </div>
            </div>

            {
                showCropper &&
                <CropperModal
                    isOpen={showCropper}
                    toggle={toggleShowCropper}
                    title={localizer.msg('frontend-shared.common.adjust-image')}
                    saveButtonText={localizer.msg('frontend-shared.common.save-changes')}
                    closeButtonText={localizer.msg('frontend-shared.common.close')}
                    file={imageFile}
                    handleUpload={handleUpload}
                    svgIconPath={svgIconPath}
                    loadingSvg={loadingSvg}
                    cropHeight={cropHeight}
                    cropWidth={cropWidth}/>
            }
        </Fragment>
    );
};