import {SidebarItemKey} from '../enums/SidebarItemKey';
import {DataFetchingType} from 'models/types/DataFetchingType';

export class SidebarItem {
    static readonly EMPTY = new SidebarItem(SidebarItemKey.UNKNOWN, '', 'LAZY', false, 0, false, false);

    constructor(
        public key: SidebarItemKey,
        public title: string,
        public loadingType: DataFetchingType,
        public visible: boolean,
        public position: number,
        public systemDefault: boolean,
        public editable?: boolean
    ) {
    }
}