import React, {useCallback} from 'react';
import {CollapseExpand} from '@ideascale/ui';
import iconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {LayoutUtil} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {useCommunityService} from 'hooks/useService';
import {useProfilePageParams} from 'hooks/useProfilePageParams';
import {useAppContext} from 'contexts/AppContext';
import {ProfileFollowContainer} from 'containers/ProfileFollowContainer';
import {ActivityStream} from 'components/profile/activity-stream/ActivityStream';
import {OwnedIdeas} from 'components/profile/owned-ideas/OwnedIdeas';
import {CommunityMemberships} from 'components/profile/community-memberships/CommunityMemberships';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {OwnedIdeasData} from 'models/OwnedIdeasData';
import {CommunityMembershipsData} from 'models/CommunityMembershipsData';
import {ActivityStreamData} from 'models/ActivityStreamData';
import {PagedResponseActivityStream} from 'models/PagedResponseActivityStream';

export const ActivityContainer = () => {
    const communityService = useCommunityService();
    const localizer = useLocalizer();
    const currentProfileId = useProfilePageParams();
    const {authentication: {actor}} = useAppContext();

    const fetchOwnedIdeas = useCallback(async (memberId: number, pageParams: PageParameters): Promise<PagedResponseContent<OwnedIdeasData>> => {
        if (communityService !== null) {
            return await communityService.fetchOwnedIdeas(memberId, pageParams);
        }
        return PagedResponseContent.nullObject<OwnedIdeasData>();
    }, [communityService]);

    const fetchCommunityMemberships = useCallback(async (memberId: number, pageParams: PageParameters): Promise<PagedResponseContent<CommunityMembershipsData>> => {
        if (communityService !== null) {
            return await communityService.fetchCommunityMemberships(memberId, pageParams);
        }
        return PagedResponseContent.nullObject<CommunityMembershipsData>();
    }, [communityService]);

    const fetchActivityStream = useCallback(async (memberId: number, type: string, pageParameters: PageParameters): Promise<PagedResponseActivityStream> => {
        if (communityService !== null) {
            return await communityService.fetchActivityStream(memberId, type, pageParameters);
        }
        return PagedResponseContent.nullObject<ActivityStreamData>();
    }, [communityService]);

    return (
        <div className="row profile-activity-stream ms-lg-0">
            <div className="col-sm-12 col-lg-8 col-md-12 px-lg-0 pe-lg-3">
                <ActivityStream
                    localizer={localizer}
                    fetchActivityStream={fetchActivityStream}
                    memberId={currentProfileId}
                    ownActivity={actor.id === currentProfileId}
                />
            </div>
            <div className="col-sm-12 col-lg-4 col-md-12 px-lg-0" data-test-element-id="profile-central-section">
                <CollapseExpand
                    containerClass="mb-3 owned-ideas panel-light"
                    headerTitle={localizer.msg('profile.owned-ideas')}
                    svgIconSprite={iconPath}
                    defaultOpen={!LayoutUtil.isMobileLayout()}>
                    <OwnedIdeas fetchOwnedIdeas={fetchOwnedIdeas}/>
                </CollapseExpand>
                {
                    actor.id !== currentProfileId && (
                        <ProfileFollowContainer expanded={!LayoutUtil.isMobileLayout()}/>
                    )
                }
                <CollapseExpand
                    containerClass="mb-3 community-memberships panel-light"
                    headerTitle={localizer.msg('profile.community-memberships')}
                    svgIconSprite={iconPath}
                    defaultOpen={!LayoutUtil.isMobileLayout()}>
                    <CommunityMemberships fetchCommunityMemberships={fetchCommunityMemberships}/>
                </CollapseExpand>
            </div>
        </div>
    );
};