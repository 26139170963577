import {StageActivity} from './StageActivity';

export abstract class AssessmentStageActivity extends StageActivity {
    static EMPTY = {
        ...StageActivity.EMPTY,
        assessmentId: 0,
        editRatingAllowed: false,
        privateReview: false
    };

    privateReview: boolean;
    assessmentId: number;
    editRatingAllowed: boolean;
    nextUnassessedIdeaId?: number;
}