import {Campaign, Member} from '@ideascale/commons';

export class CampaignChangeActivity {
    static readonly EMPTY = new CampaignChangeActivity(Campaign.EMPTY, Campaign.EMPTY, {
        id: 0,
        name: '',
        username: '',
        avatar: '',
        url: ''
    }, '', '');

    constructor(
        public newCampaign: Campaign,
        public oldCampaign: Campaign,
        public member: Member,
        public description: string,
        public createdAt: string,
    ) {
    }
}