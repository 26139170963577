import {Operation} from './Operation';
import {CommunityOperationType} from './CommunityOperationType';
import {CampaignOperationType} from './CampaignOperationType';

export class ImageryOperation implements Operation {
    constructor(
        public type: CommunityOperationType | CampaignOperationType,
        public fileId: number,
    ) {
    }

    getType(): CommunityOperationType | CampaignOperationType{
        return this.type;
    }
}