import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import {useAppContext} from 'contexts/AppContext';
import {SidebarCampaign} from 'models/SidebarCampaign';
import {CampaignGroup} from 'models/CampaignGroup';
import {SidebarCampaignHolder} from 'models/SidebarCampaignHolder';

export type CampaignGroupPagination = {
    currentPage: number;
    hasMore: boolean;
}

export type CampaignModuleConfig = {
    manageCampaign: boolean;
}

export type SidebarCampaignsDataHolder = {
    campaignHolders: SidebarCampaignHolder[];
    campaignGroupPagination: CampaignGroupPagination;
    addCampaigns: (group: CampaignGroup, campaigns: SidebarCampaign[], defaultExpand: boolean, page: number, hasMore: boolean) => void;
    updateCampaignGroupPaginationData: (page: number, hasMore: boolean) => void;
    updateCampaignGroupExpansion: (group?: CampaignGroup, expand?: boolean) => void;
    campaignModuleConfig: CampaignModuleConfig;
    setCampaignModuleConfig: Dispatch<SetStateAction<CampaignModuleConfig>>
}

export type SidebarContextState = {
    activeCampaignsHolder: SidebarCampaignsDataHolder;
    archivedCampaignsHolder: SidebarCampaignsDataHolder;
}

const contextDefaultValues: SidebarContextState = {
    activeCampaignsHolder: {
        campaignHolders: [],
        campaignGroupPagination: {currentPage: 0, hasMore: false},
        addCampaigns: () => {
        },
        updateCampaignGroupPaginationData: () => null,
        updateCampaignGroupExpansion: () => null,
        campaignModuleConfig: {
            manageCampaign: false
        },
        setCampaignModuleConfig: () => null
    },
    archivedCampaignsHolder: {
        campaignHolders: [],
        campaignGroupPagination: {currentPage: 0, hasMore: false},
        addCampaigns: () => {
        },
        updateCampaignGroupPaginationData: () => null,
        updateCampaignGroupExpansion: () => null,
        campaignModuleConfig: {
            manageCampaign: false
        },
        setCampaignModuleConfig: () => null
    }
};

const ctx = React.createContext<SidebarContextState>(contextDefaultValues);

export const useSidebarContext = () => {
    return React.useContext<SidebarContextState>(ctx);
};

type SidebarContextType = {
    children: React.ReactNode;
}

export const SidebarContextProvider = ({children}: SidebarContextType) => {
    const {currentCampaign} = useAppContext();
    const [activeCampaigns, setActiveCampaigns] = useState<SidebarCampaignsDataHolder>(contextDefaultValues.activeCampaignsHolder);
    const [archivedCampaigns, setArchivedCampaigns] = useState<SidebarCampaignsDataHolder>(contextDefaultValues.archivedCampaignsHolder);
    const [activeCampaignModuleConfig, setActiveCampaignModuleConfig] = useState<CampaignModuleConfig>(contextDefaultValues.activeCampaignsHolder.campaignModuleConfig);
    const [archiveCampaignModuleConfig, setArchiveCampaignModuleConfig] = useState<CampaignModuleConfig>(contextDefaultValues.archivedCampaignsHolder.campaignModuleConfig);

    const addCampaigns = useCallback(<T extends SidebarCampaignsDataHolder>(setCampaignsFn: Dispatch<SetStateAction<T>>, group: CampaignGroup, campaigns: SidebarCampaign[], page: number, hasMore: boolean, defaultExpand: boolean) => {
        setCampaignsFn(prev => {
            const newCampaignsHolder = cloneDeep(prev.campaignHolders) || [];
            const findIndex = newCampaignsHolder.findIndex(x => x.group.id === group.id);
            if (findIndex > -1) {
                newCampaignsHolder[findIndex].campaigns = uniqBy([...newCampaignsHolder[findIndex].campaigns, ...campaigns], 'id');
                newCampaignsHolder[findIndex].currentCampaignPage = page;
                newCampaignsHolder[findIndex].hasMoreCampaigns = hasMore;
            } else {
                newCampaignsHolder.push(new SidebarCampaignHolder(group, campaigns, defaultExpand, page, hasMore));
            }
            return {...prev, campaignHolders: newCampaignsHolder};
        });
    }, []);

    const updateCampaignGroupPagination = useCallback(<T extends SidebarCampaignsDataHolder>(setCampaignsFn: Dispatch<SetStateAction<T>>, page: number, hasMore: boolean) => {
        setCampaignsFn(prev => {
            const newPagination = {...prev.campaignGroupPagination};
            newPagination.currentPage = page;
            newPagination.hasMore = hasMore;
            return {...prev, campaignGroupPagination: newPagination};
        });
    }, []);

    const addActiveCampaigns = useCallback((group: CampaignGroup, campaigns: SidebarCampaign[], defaultExpand: boolean = false, page: number = 0, hasMore: boolean = false) => {
        addCampaigns(setActiveCampaigns, group, campaigns, page, hasMore, defaultExpand);
    }, [addCampaigns]);

    const updateActiveCampaignGroupPagination = useCallback((page: number, hasMore: boolean) => {
        updateCampaignGroupPagination(setActiveCampaigns, page, hasMore);
    }, [updateCampaignGroupPagination]);


    const addArchivedCampaigns = useCallback((group: CampaignGroup, campaigns: SidebarCampaign[], defaultExpand: boolean = false, page: number = 0, hasMore: boolean = false) => {
        addCampaigns(setArchivedCampaigns, group, campaigns, page, hasMore, defaultExpand);
    }, [addCampaigns]);

    const updateArchivedCampaignGroupPagination = useCallback((page: number, hasMore: boolean) => {
        updateCampaignGroupPagination(setArchivedCampaigns, page, hasMore);
    }, [updateCampaignGroupPagination]);

    const updateActiveCampaignGroupExpansion = useCallback((group?: CampaignGroup) => {
        setActiveCampaigns(prev => {
            const newActiveCampaigns = cloneDeep(prev);
            const holders = newActiveCampaigns.campaignHolders.map(campaign => {
                campaign.group.groupExpanded = campaign.group.id === group?.id;
                return campaign;
            });
            return {...newActiveCampaigns, campaignHolders: holders};
        });
    }, []);

    const updateArchiveCampaignGroupExpansion = useCallback((group?: CampaignGroup) => {
        setArchivedCampaigns(prev => {
            const newArchiveCampaigns = cloneDeep(prev);
            const holders = newArchiveCampaigns.campaignHolders.map(campaign => {
                campaign.group.groupExpanded = campaign.group.id === group?.id;
                return campaign;
            });
            return {...newArchiveCampaigns, campaignHolders: holders};
        });
    }, []);

    useEffect(() => {
        if (currentCampaign) {
            if (currentCampaign.active) {
                setActiveCampaigns(prev => {
                    const newActiveCampaigns = cloneDeep(prev);
                    const holders = newActiveCampaigns.campaignHolders.map(campaign => {
                        campaign.group.groupExpanded = (campaign.group.id === currentCampaign.campaignGroupId);
                        return campaign;
                    });
                    return {...newActiveCampaigns, campaignHolders: holders};
                });
                updateArchiveCampaignGroupExpansion();
            } else {
                setArchivedCampaigns(prev => {
                    const newArchiveCampaigns = cloneDeep(prev);
                    const holders = newArchiveCampaigns.campaignHolders.map(campaign => {
                        campaign.group.groupExpanded = (campaign.group.id === currentCampaign.campaignGroupId);
                        return campaign;
                    });
                    return {...newArchiveCampaigns, campaignHolders: holders};
                });
                updateActiveCampaignGroupExpansion();
            }
        } else {
            updateArchiveCampaignGroupExpansion();
            updateActiveCampaignGroupExpansion();
        }
    }, [currentCampaign, updateActiveCampaignGroupExpansion, updateArchiveCampaignGroupExpansion]);

    return (
        <ctx.Provider value={{
            activeCampaignsHolder: {
                campaignHolders: activeCampaigns.campaignHolders,
                addCampaigns: addActiveCampaigns,
                campaignGroupPagination: activeCampaigns.campaignGroupPagination,
                updateCampaignGroupPaginationData: updateActiveCampaignGroupPagination,
                updateCampaignGroupExpansion: updateActiveCampaignGroupExpansion,
                campaignModuleConfig: activeCampaignModuleConfig,
                setCampaignModuleConfig: setActiveCampaignModuleConfig
            },
            archivedCampaignsHolder: {
                campaignHolders: archivedCampaigns.campaignHolders,
                addCampaigns: addArchivedCampaigns,
                campaignGroupPagination: archivedCampaigns.campaignGroupPagination,
                updateCampaignGroupPaginationData: updateArchivedCampaignGroupPagination,
                updateCampaignGroupExpansion: updateArchiveCampaignGroupExpansion,
                campaignModuleConfig: archiveCampaignModuleConfig,
                setCampaignModuleConfig: setArchiveCampaignModuleConfig
            }
        }}>
            {children}
        </ctx.Provider>
    );
};

