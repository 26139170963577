import {Command} from './Command';
import {CommunityHomeEditor} from './Editors/CommunityHomeEditor';
import {CommunityOperationType} from 'models/edit-mode/CommunityOperationType';
import {WidgetField} from 'models/edit-mode/WidgetField';
import {Operation} from 'models/edit-mode/Operation';
import {ChangeCommunityWidgetOperation} from 'models/edit-mode/ChangeCommunityWidgetOperation';

export class UpdateCommunityWidgetCommand implements Command {
    private readonly communityHomeEditor: CommunityHomeEditor;
    private readonly oldCommunityWidget: WidgetField;
    private readonly newCommunityWidget: WidgetField;
    private readonly name: CommunityOperationType;

    constructor(communityHomeEditor: CommunityHomeEditor, newWidgetField: WidgetField) {
        this.communityHomeEditor = communityHomeEditor;
        this.oldCommunityWidget = this.communityHomeEditor.communityWidget;
        this.newCommunityWidget = newWidgetField;
        this.name = CommunityOperationType.CHANGE_COMMUNITY_WIDGET;
    }

    execute(): void {
        this.communityHomeEditor.communityWidget = this.newCommunityWidget;
    }

    getType(): CommunityOperationType  {
        return this.name;
    }

    getOperation(): Operation {
        return new ChangeCommunityWidgetOperation(this.name, this.newCommunityWidget.title, this.communityHomeEditor.communityWidget.content);
    }

    undo(): void {
        this.communityHomeEditor.communityWidget = this.oldCommunityWidget;
    }
}