import {Media} from './Media';
import {Button} from './Button';
import {GeneralText} from 'models/landing-page/GeneralText';
import {HeroType} from '../enums/landing-page/HeroType';
import {Component} from './Component';
import {LandingPageComponentType} from '../enums/landing-page/LandingPageComponentType';
import {UiAttribute} from './UiAttribute';

export class HeroComponentData extends Component {
    public static EMPTY = new HeroComponentData(0, 0, LandingPageComponentType.HERO, false, UiAttribute.EMPTY, [], [], [], HeroType.PRIMARY, '', '');

    public constructor(
        public id: number,
        public languageId: number,
        public type: LandingPageComponentType,
        public visible: boolean,
        public attribute: UiAttribute,
        public texts: GeneralText[],
        public medias: Media[],
        public buttons: Button[],
        public heroType: HeroType,
        public title?: string,
        public subtitle?: string
    ) {
        super(id, languageId, type, visible, attribute, title, subtitle);
    }
}