import React, {Fragment} from 'react';
import {useLocalizer} from 'hooks/useLocalizer';
import {StageSummaryHeading} from './StageSummaryHeading';
import {BuildTeamStageSummary} from 'models/BuildTeamStageSummary';
import {IdeaStageSummaryProps} from 'models/types/IdeaStageSummaryProps';

export const BuildTeamSummary = (props: IdeaStageSummaryProps<BuildTeamStageSummary>) => {
    const {ideaStageSummary: {actualTeamSize, expectedTeamSize, viewAllowed, participationAllowed}} = props;
    const localizer = useLocalizer();

    return (
        <Fragment>
            <StageSummaryHeading className="mb-3" heading={localizer.msg('stage.build-team.heading')}/>
            <div className="stage-data mb-2 mt-n3">
                {
                    participationAllowed || viewAllowed
                        ?
                        <>
                            <strong>
                                {actualTeamSize} {localizer.msg('stage.common.of')} {expectedTeamSize}
                            </strong>
                            <span>
                            {localizer.msg('stage.build-team.team-members-have-joined')}
                       </span>
                        </>
                        :
                        <em>{localizer.msg('stage.build-team.awaits')}</em>
                }
            </div>
        </Fragment>
    );
};