import {useIdeaUpdater} from './useIdeaUpdater';
import {useCallback} from 'react';

export const useIdeaAuthorFollow = (ideaListQueryKey: any = '') => {
    const {updateIdea, updateIdeaDetails} = useIdeaUpdater();

    const ideaListIdeaAuthorFollowMutation = useCallback(async (ideaId: number, followed: boolean) => {
        await updateIdea(ideaListQueryKey, ideaId, 'followingIdeaAuthor', followed);
    }, [ideaListQueryKey, updateIdea]);

    const ideaDetailsIdeaAuthorFollowMutation = useCallback(async (ideaId: number, followed: boolean) => {
        await updateIdeaDetails(ideaId, 'followingIdeaAuthor', followed);
    }, [updateIdeaDetails]);

    return {ideaListIdeaAuthorFollowMutation, ideaDetailsIdeaAuthorFollowMutation};
};