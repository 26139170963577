import React, {useCallback, useEffect, useState} from 'react';
import * as Popper from 'popper.js';
import {Popover, PopoverBody} from 'reactstrap';
import {Avatar} from '@ideascale/ui';
import {MemberLink} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {IdeaTeamMember} from 'models/IdeaTeamMember';
import {TeamPosition} from 'models/BuildTeamStageActivity';

type TeamMembersPopoverProps = {
    actorId: number;
    selector: string;
    trigger: 'click' | 'hover' | 'focus' | 'legacy';
    placement?: Popper.Placement;
    header: string;
    ideaId: number;
    getTeamData: (ideaId: number) => Promise<IdeaTeamMember[]>;
    showBuildTeamPopover: boolean;
    toggleBuildTeamPopover: () => void;
    containerId: string;
}

export const TeamMembersPopover = (props: TeamMembersPopoverProps) => {
    const [ideaTeams, setIdeaTeams] = useState<TeamPosition[][]>([]);
    const [groupHeaders, setGroupHeaders] = useState<string[]>([]);

    const {
        selector,
        actorId,
        placement,
        getTeamData,
        ideaId,
        trigger,
        showBuildTeamPopover,
        toggleBuildTeamPopover,
        containerId
    } = props;
    const localizer = useLocalizer();

    const loadInitialUsers = useCallback(async () => {
        const teams: IdeaTeamMember[] = await getTeamData(ideaId);
        setIdeaTeams(teams.map(ideaTeam => ideaTeam.teamPositions));
        setGroupHeaders(teams.map(team => team.stage.label));
    }, [getTeamData, ideaId]);

    useEffect(() => {
        void loadInitialUsers();
    }, [loadInitialUsers]);


    return (
        <Popover className="member-list-popover"
                 trigger={trigger}
                 placement={placement}
                 target={selector}
                 isOpen={showBuildTeamPopover}
                 toggle={toggleBuildTeamPopover}
                 container={containerId}
        >
            <PopoverBody>
                <ul className="list-unstyled member-list scrollable-member-list">
                    {ideaTeams.map((ideaTeam, groupIndex) => {
                            return <li key={groupIndex}>
                                <strong className="mx-3 my-2 block">{groupHeaders[groupIndex]}</strong>
                                <ul className="list-unstyled member-list">
                                    {
                                        ideaTeam.length > 0 ? ideaTeam.map((teamPosition) => {
                                                const {member, position} = teamPosition;
                                                return (
                                                    member &&
                                                    <li key={member.id} className="m-2">
                                                        <MemberLink className="pe-3" id={member.id}
                                                                    identityHidden={member.identityHidden}>
                                                            <div className="member-avatar-container">
                                                                <Avatar src={member.avatar} size="sm"
                                                                        alt={member.username ? localizer.msg('common.user-avatar', {username: member.username}) : ''}/>
                                                            </div>
                                                            <div className="member-list-item-content">
                                                                <strong>{member.id === actorId ? localizer.msg('common.you') : member.name}</strong>
                                                                {member.username && <small>@{member.username}</small>}
                                                                <small
                                                                    className="text-truncate">{position.roleName ? `${localizer.msg('stage.build-team.team-member')} - ${position.roleName}` : localizer.msg('stage.build-team.team-member')}</small>
                                                            </div>
                                                        </MemberLink>
                                                    </li>
                                                );
                                            }) :
                                            <li className="mx-3 my-2"
                                                aria-live="polite">{localizer.msg('stage.build-team.team-members-unassigned')}</li>
                                    }
                                </ul>
                            </li>;
                        }
                    )}
                </ul>
            </PopoverBody>
        </Popover>
    );
};