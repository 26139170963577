import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle} from '@ideascale/ui';
import {Backdrop, ErrorReason, useToggle} from '@ideascale/commons';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useIdeaListMode} from 'hooks/useIdeaListMode';
import {useEditModeFormErrorHandler} from 'hooks/useEditModeFormErrorHandler';
import {UpdateIdeaListModeCommand} from 'commands/edit-mode/UpdateIdeaListModeCommand';
import {ConfigurationActionBar} from 'components/shared/ConfigurationActionBar';
import {LicenseUpgradeInfo} from './LicenseUpgradeInfo';
import {ErrorMessage} from './advanced-settings/ErrorMessage';
import {IdeaListMode} from 'models/enums/IdeaListMode';
import {CommunityEditableFieldType} from 'models/edit-mode/CommunityEditableFieldType';
import {CommunityOperationType} from 'models/edit-mode/CommunityOperationType';

type IdeaListFilterConfigurationFormProps = {
    toggleModal: () => void;
}
export const IdeaListFilterConfigurationForm = (props: IdeaListFilterConfigurationFormProps) => {
    const {toggleModal} = props;
    const localizer = useLocalizer();
    const {getIdeaListModesWithoutRecommended} = useIdeaListMode();
    const {communityHomeEditor, commandExecutor, validationErrors, homeConfig} = useEditModeContext();
    const [defaultMode, setDefaultMode] = useState<IdeaListMode>();
    const [dropdownOpen, toggleDropdownOpen] = useToggle(false);
    const [disabled, setDisabled] = useState(true);
    const {fieldError} = useEditModeFormErrorHandler({
        localizer,
        validationErrors: validationErrors.findError(CommunityOperationType.CHANGE_IDEA_SORT_MODE)
    });

    const onUpdate = useCallback(() => {
        const command = new UpdateIdeaListModeCommand(communityHomeEditor, defaultMode as IdeaListMode);
        commandExecutor.execute(command);
        validationErrors.clearError(command.getType());
        toggleModal();
    }, [communityHomeEditor, defaultMode, commandExecutor, validationErrors, toggleModal]);

    const onChange = useCallback((mode: IdeaListMode) => {
        let modeNotMatched = false;
        setDefaultMode((prev) => {
            modeNotMatched = mode !== prev;
            return mode;
        });
        if (modeNotMatched) {
            setDisabled(false);
        }
    }, []);

    useEffect(() => {
        const defaultMode = getIdeaListModesWithoutRecommended()
            .filter(ideaListMode => ideaListMode.toUpperCase() === communityHomeEditor.ideaListModeConfig.value);
        setDefaultMode(defaultMode[0]);
    }, [communityHomeEditor, getIdeaListModesWithoutRecommended]);

    return (
        <Fragment>
            <div className="d-flex align-items-center">
                <strong onClick={toggleDropdownOpen}
                        className={`${validationErrors.findError(CommunityOperationType.CHANGE_IDEA_SORT_MODE) ? 'has-error' : ''}`}>
                    {localizer.msg('edit-mode.filter-by')}
                </strong>
                {
                    !homeConfig.hasEditPermission(CommunityEditableFieldType.IDEA_LIST_MODE)
                        ? (
                            <LicenseUpgradeInfo
                                triggerButtonId="campaign-logo-title-license-popover"
                                className="ms-2"
                                localizer={localizer}
                            />
                        )
                        : null
                }
            </div>
            <ErrorMessage message={fieldError('sortMode')?.message}/>
            {
                (homeConfig.hasEditPermission(CommunityEditableFieldType.IDEA_LIST_MODE) && fieldError('sortMode')?.reason !== ErrorReason.FEATURE_IS_NOT_ACCESSIBLE)
                    ? (
                        <ButtonDropdown drop="down"
                                        key="down"
                                        className="w-100 mt-2"
                                        isOpen={dropdownOpen}
                                        toggle={toggleDropdownOpen}>
                            <Backdrop clicked={toggleDropdownOpen}/>

                            <DropdownToggle tag="button" itemType={'button'}
                                            className="form-control text-start d-flex justify-content-between align-items-center"
                                            title={localizer.msg('profile.tabs.sort-by')}
                                            data-test-element-id="idea-sort-by" caret>
                                {localizer.msg(`ideas.mode.${defaultMode}`)}
                            </DropdownToggle>

                            <DropdownMenu data-test-element-id="idea-sort-options" className="w-100">
                                {
                                    getIdeaListModesWithoutRecommended().map(mode =>
                                        <DropdownItem key={mode}
                                                      className={`${mode.toLowerCase() === defaultMode ? 'active' : ''}`}
                                                      data-test-element-id={mode.toLowerCase()}
                                                      onClick={() => onChange(mode)}>
                                            {localizer.msg(`ideas.mode.${mode}`)}
                                        </DropdownItem>
                                    )
                                }
                            </DropdownMenu>
                        </ButtonDropdown>
                    )
                    : null
            }

            <ConfigurationActionBar localizer={localizer} onCancel={toggleModal} onUpdate={onUpdate}
                                    updateDisabled={disabled}/>
        </Fragment>
    );
};