import React, {Fragment} from 'react';
import {Avatar} from '@ideascale/ui';
import {MemberLink, StringUtil, TimeAgo} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {FundStageActivity, NonEmptyFundUnitKey} from 'models/FundStageActivity';

type FunderListProps = {
    fundData: FundStageActivity;
    numberFormat: (value: number) => string;
}

export const FunderList = (props: FunderListProps) => {
    const {fundData, numberFormat} = props;
    const localizer = useLocalizer();
    return (
        <Fragment>
            {(fundData.memberPledgeAllowed || fundData.viewFunderAllowed) &&
                <Fragment>
                    {fundData.fundPledgeSummaries.length > 0 ?
                        <p className="h5 fw-bold">{localizer.msg('stage.fund.contributors')}
                            {' '} ({fundData.fundPledgeSummaries.length})</p> : ''
                    }
                    <ul className="list-unstyled">
                        {fundData.fundPledgeSummaries.map(member => {
                            return (
                                <li className="d-flex justify-content-between border-bottom-1 pb-3 pt-3"
                                    key={member.funder.id}>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <MemberLink className="d-block" id={member.funder.id}
                                                        identityHidden={member.funder.identityHidden}>
                                                <Avatar className="me-2" size="sm"
                                                        src={member.funder.avatar}
                                                        alt={member.funder.username ? localizer.msg('common.user-avatar', {username: member.funder.username}) : ''}/>
                                            </MemberLink>
                                        </div>
                                        <div className="member-info">
                                            <MemberLink className="d-block" id={member.funder.id}
                                                        identityHidden={member.funder.identityHidden}
                                                        title={member.funder.name}>
                                                {StringUtil.textTruncate(member.funder.name, 30)}
                                            </MemberLink>
                                            <span>
                                           <TimeAgo localizer={localizer} dateISOString={member.lastContributedAt}
                                                    prefixText={false}/>
                                        </span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <span className="fw-bold">{numberFormat(member.balance)}</span>
                                        {
                                            fundData.ideaFundAccount?.fundUnit &&
                                            <span>
                                            &nbsp;
                                                {fundData.ideaFundAccount.fundUnit}
                                                {
                                                    fundData.ideaFundAccount.fundUnitKey &&
                                                    Object.values(NonEmptyFundUnitKey).includes(fundData.ideaFundAccount.fundUnitKey as NonEmptyFundUnitKey) &&
                                                    <Fragment>
                                                        ({localizer.msg(fundData.ideaFundAccount.fundUnitKey)})
                                                    </Fragment>
                                                }
                                        </span>
                                        }
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </Fragment>
            }
        </Fragment>
    );
};