import React from 'react';
import {Localizer, PageTheme, UploadedResponse, UploadProgressCallback} from '@ideascale/commons';
import {InstructionListWrapper} from 'components/landing-page/instruction-list/InstructionListWrapper';
import {InstructionListComponentData} from 'models/landing-page/InstructionListComponentData';
import {GeneralText} from 'models/landing-page/GeneralText';
import {
    InstructionListItem,
    InstructionListItem as InstructionListItemModel
} from 'models/landing-page/InstructionListItem';
import {TranslationContentResponse} from 'models/landing-page/TranslationContentResponse';
import {TranslationContentRequest} from 'models/landing-page/TranslationContentRequest';

type InstructionListComponentProps = {
    showConfig: boolean;
    config: InstructionListComponentData;
    localizer: Localizer;
    theme: PageTheme;
    addConfigItem: (item: InstructionListItemModel) => Promise<void>;
    toggleItemVisibility: (item: InstructionListItemModel) => Promise<void>;
    deleteConfigItem: (item: InstructionListItemModel) => Promise<void>;
    onToggleTextVisibility: (data: GeneralText) => Promise<void>;
    onUpdateConfigText: (data: GeneralText) => Promise<void>;
    updateItemConfig: (item: InstructionListItem) => Promise<void>;
    showTranslationConfig: boolean,
    translationLanguageId?: number,
    onTextTranslated: (response: TranslationContentResponse) => void;
    getTranslatedInstructionText: (parameters: TranslationContentRequest) => Promise<TranslationContentResponse[]>
    saveTranslatedInstructionText: (parameters: TranslationContentRequest) => Promise<TranslationContentResponse[]>;
    uploadImage: (data: FormData, onUploadProgress: UploadProgressCallback) => Promise<UploadedResponse>;
    onComponentEdited: () => void,
}

export const InstructionListComponent = (props: InstructionListComponentProps) => {
    const {
        showConfig,
        config,
        localizer,
        deleteConfigItem,
        addConfigItem,
        theme,
        onToggleTextVisibility,
        onUpdateConfigText,
        toggleItemVisibility,
        updateItemConfig,
        showTranslationConfig,
        translationLanguageId,
        getTranslatedInstructionText,
        saveTranslatedInstructionText,
        onTextTranslated,
        onComponentEdited,
        uploadImage
    } = props;

    const renderComponent = () => {
        switch (theme) {
            case PageTheme.EASTMAN:
            case PageTheme.BERNERS_LEE:
                return <InstructionListWrapper theme={theme}
                                               config={config}
                                               headerClass="mb-3"
                                               localizer={localizer}
                                               showConfig={showConfig}
                                               uploadImage={uploadImage}
                                               addConfigItem={addConfigItem}
                                               deleteConfigItem={deleteConfigItem}
                                               onTextTranslated={onTextTranslated}
                                               updateItemConfig={updateItemConfig}
                                               onComponentEdited={onComponentEdited}
                                               onUpdateConfigText={onUpdateConfigText}
                                               toggleVisibility={toggleItemVisibility}
                                               showTranslationConfig={showTranslationConfig}
                                               translationLanguageId={translationLanguageId}
                                               onToggleTextVisibility={onToggleTextVisibility}
                                               getTranslatedInstructionText={getTranslatedInstructionText}
                                               saveTranslatedInstructionText={saveTranslatedInstructionText}/>;
            case PageTheme.CURIE:
                return <InstructionListWrapper theme={theme}
                                               config={config}
                                               localizer={localizer}
                                               showConfig={showConfig}
                                               uploadImage={uploadImage}
                                               addConfigItem={addConfigItem}
                                               deleteConfigItem={deleteConfigItem}
                                               onTextTranslated={onTextTranslated}
                                               updateItemConfig={updateItemConfig}
                                               onComponentEdited={onComponentEdited}
                                               bodyClass="row justify-content-center"
                                               onUpdateConfigText={onUpdateConfigText}
                                               toggleVisibility={toggleItemVisibility}
                                               showTranslationConfig={showTranslationConfig}
                                               translationLanguageId={translationLanguageId}
                                               onToggleTextVisibility={onToggleTextVisibility}
                                               getTranslatedInstructionText={getTranslatedInstructionText}
                                               saveTranslatedInstructionText={saveTranslatedInstructionText}/>;
            case PageTheme.ARMSTRONG:
            default:
                return <InstructionListWrapper theme={theme}
                                               config={config}
                                               headerClass="mb-3"
                                               localizer={localizer}
                                               showConfig={showConfig}
                                               uploadImage={uploadImage}
                                               addConfigItem={addConfigItem}
                                               deleteConfigItem={deleteConfigItem}
                                               updateItemConfig={updateItemConfig}
                                               onTextTranslated={onTextTranslated}
                                               onComponentEdited={onComponentEdited}
                                               onUpdateConfigText={onUpdateConfigText}
                                               bodyClass="row justify-content-center"
                                               toggleVisibility={toggleItemVisibility}
                                               showTranslationConfig={showTranslationConfig}
                                               translationLanguageId={translationLanguageId}
                                               onToggleTextVisibility={onToggleTextVisibility}
                                               getTranslatedInstructionText={getTranslatedInstructionText}
                                               saveTranslatedInstructionText={saveTranslatedInstructionText}/>;
        }
    };

    return (
        <div className={`${config.attribute?.className}`}>
            {renderComponent()}
        </div>
    );
};