import {useMutation} from 'react-query';
import {useIdeaUpdater} from './useIdeaUpdater';
import {useIdeaService} from './useIdeaService';
import {useIdeasContext} from '../contexts/IdeasContext';

export const useIdeaTags = () => {
    const {saveTags, saveModeratorTags} = useIdeaService();
    const {ideaListFilterQueryKey} = useIdeasContext();
    const {updateIdeaDetailsCallback, updateIdea} = useIdeaUpdater();

    const ideaTagsMutation = useMutation(({ideaId, tags}: { ideaId: number, tags: string[] }) => saveTags(ideaId, tags),
        {
            onSuccess: async (updatedTags, params) => {
                await updateIdea(ideaListFilterQueryKey, params.ideaId, 'tags', updatedTags);
                await updateIdeaDetailsCallback(params.ideaId, 'ideaTagHolder', (ideaDetails) => {
                    return {...ideaDetails.ideaTagHolder, tags: updatedTags};
                });
            }
        }
    );

    const ideaModeratorTagsMutation = useMutation(({
                                                       ideaId,
                                                       tags
                                                   }: { ideaId: number, tags: string[] }) => saveModeratorTags(ideaId, tags),
        {
            onSuccess: async (updatedTags, params) => {
                await updateIdeaDetailsCallback(params.ideaId, 'ideaTagHolder', ({ideaTagHolder}) => {
                    return {...ideaTagHolder, moderatorTags: updatedTags};
                });
            }
        }
    );

    return {ideaTagsMutation, ideaModeratorTagsMutation};
};