import React from 'react';
import {Icon} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {PageTheme} from '@ideascale/commons';
import {DisplayHeader} from 'components/landing-page/DisplayHeader';

type DisplayHeaderWithPaginationButtonProps = {
    theme: PageTheme;
    editMode?: boolean;
    onNextClicked: () => void;
    onPreviousClicked: () => void;
    nextButtonDisabled: boolean;
    previousButtonDisabled: boolean;
    title?: string;
    subtitle?: string;
}

export const DisplayHeaderWithPaginationButton = (props: DisplayHeaderWithPaginationButtonProps) => {
    const {
        theme,
        editMode = false,
        nextButtonDisabled,
        previousButtonDisabled,
        onPreviousClicked,
        onNextClicked,
        title,
        subtitle
    } = props;
    return (
        <div className="d-flex w-100 justify-content-between align-items-start ideas-component-header">
            <DisplayHeader theme={theme} title={title} subtitle={subtitle}/>
            <div className="buttons d-flex">
                <button className={`btn pt-1 px-2 ${theme === PageTheme.EASTMAN ? 'btn-eastman' : 'btn-gray'}`}
                        {...(editMode ? {tabIndex: -1} : {})}
                        type="button" onClick={onPreviousClicked} disabled={previousButtonDisabled}>
                    <Icon iconSpritePath={svgIconPath} name="chevron-left" width={18} height={18}/>
                    <span className="sr-only">Previous</span>
                </button>
                <button className={`btn pt-1 px-2 ms-2 ${theme === PageTheme.EASTMAN ? 'btn-eastman' : 'btn-gray'}`}
                        {...(editMode ? {tabIndex: -1} : {})}
                        onClick={onNextClicked} disabled={nextButtonDisabled}>
                    <Icon iconSpritePath={svgIconPath} name="chevron-right" width={18}
                          height={18}/>
                    <span className="sr-only">Next</span>
                </button>
            </div>
        </div>
    );
};