import React, {Dispatch, SetStateAction, useCallback, useRef, useState} from 'react';
import {GroupBase, OptionsOrGroups} from 'react-select';
import {FormGroup, Popover, PopoverBody, PopoverHeader} from 'reactstrap';
import {Icon, IdeascaleSelectWithPagination, OptionType} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {
    AlertEvent,
    AlertType,
    buildAlertEventData,
    eventDispatcher,
    Member,
    PagedResponseContent,
    StringUtil,
    useApiErrorResponseHandler,
    useToggle
} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {PageParameters} from 'models/types/PageParameters';
import {StageActionResponse} from 'models/StageActionResponse';
import {BuildTeamStageActivity} from 'models/BuildTeamStageActivity';
import {BuildTeamStageSummary} from 'models/BuildTeamStageSummary';
import {BaseIdeaStageSummary} from 'models/BaseIdeaStageSummary';

type AssignOwnerProps = {
    fetchTeamMember(ideaId: number, stageId: number, pageParameters: PageParameters): Promise<PagedResponseContent<Member>>;
    assignMemberBuildTeam(ideaId: number, stageId: number, positionId: number, memberId: number): Promise<StageActionResponse<BuildTeamStageActivity, BuildTeamStageSummary>>;
    setActivityData: Dispatch<SetStateAction<BuildTeamStageActivity>>;
    updateIdeaStageSummary: <T extends BaseIdeaStageSummary>(stageSummary: T) => void;
    positionId: number;
    ideaId: number;
    stageId: number;
}

const PAGE_LIMIT = 10;

export const BuildTeamAssignMember = (props: AssignOwnerProps) => {
    const {
        fetchTeamMember,
        positionId,
        assignMemberBuildTeam,
        ideaId,
        stageId,
        setActivityData,
        updateIdeaStageSummary
    } = props;
    const localizer = useLocalizer();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [open, toggle] = useToggle(false);
    const [selectMember, setSelectMember] = useState<number>(0);
    const popoverContainerRef = useRef(null);

    const loadMembers = useCallback(async (term: string, _prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>, additionalParams: any) => {
        try {
            const {page} = additionalParams;
            const membersData = await fetchTeamMember(ideaId, stageId, {term, page, limit: PAGE_LIMIT});
            const options = membersData.content.map((member: any) => ({
                value: member.id,
                label: member.name,
                image: member.avatar,
                labelAsJsx: <div className="member-list-item-content">
                    <strong>{member.name}</strong>
                    {
                        member.username &&
                        <small className="username text-truncate">
                            <span>@</span>
                            {member.username}
                        </small>
                    }
                </div>
            }));
            return {
                options,
                hasMore: membersData.hasMore,
                additional: {
                    page: page + 1,
                }
            };
        } catch (e: any) {
            return {
                options: []
            };
        }
    }, [fetchTeamMember, ideaId, stageId]);

    const getMemberId = (option: any) => {
        setSelectMember(option.value);
    };

    const handleAssignMember = (e: React.MouseEvent, ideaId: number, stageId: number, positionId: number, memberId: number) => {
        e.preventDefault();
        if (selectMember) {
            assignMemberBuildTeam(ideaId, stageId, positionId, memberId).then(status => {
                if (status) {
                    toggle(false);
                    if (status.stageActivity) {
                        setActivityData(status.stageActivity);
                    }
                    if (status?.stageSummary) {
                        updateIdeaStageSummary(status.stageSummary);
                    }
                }
            }).catch((error) => {
                handleErrorResponse(error);
            });
        } else {
            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.warn, localizer.msg('common.please-select-a-member')));
        }
    };

    const closePopOver = () => {
        toggle();
        setSelectMember(0);
    };

    return (
        <div className="d-inline-block" ref={popoverContainerRef}>
            <button className="btn btn-link p-0 me-2 fw-bold font-size-sm"
                    id={`assign-member-${positionId}`}
                    data-test-element-id="btn-assign-member">
                {localizer.msg('stage.build-team.assign-member')}
            </button>
            <Popover className="me-n2 assign-member-popover"
                     trigger="click"
                     placement="top"
                     isOpen={open}
                     toggle={toggle}
                     container={popoverContainerRef}
                     target={`assign-member-${positionId}`}>
                <PopoverHeader className="fw-bold font-size-sm p-3">
                    {localizer.msg(`stage.build-team.assign-a-team-member-to-this-idea`)}
                </PopoverHeader>
                <PopoverBody className="p-0">
                    <div className="px-3 pt-3">
                        <div className="d-flex align-items-center">
                            <div className="assign-member me-2">
                                <FormGroup>
                                    <IdeascaleSelectWithPagination
                                        isMulti={false}
                                        inputId="assign-owner-input"
                                        defaultValue={[]}
                                        placeholder={StringUtil.textTruncate(localizer.msg('notification.common.assign-member-placeholder'), 32)}
                                        loadOptions={loadMembers}
                                        onChange={getMemberId}
                                        ariaLabel={localizer.msg('idea.actions.email.placeholder.select-members')}
                                        additional={{
                                            page: 0
                                        }}/>
                                </FormGroup>
                            </div>
                            <div className="mb-3">
                                <button className="btn btn-sm btn-primary" title={localizer.msg('common.select')}
                                        onClick={(e) => handleAssignMember(e, ideaId, stageId, positionId, selectMember)}>
                                    <Icon iconSpritePath={svgIconPath} name="check" fill="#fff"/>
                                </button>
                                <button className="btn btn-sm btn-light ms-2" title={localizer.msg('common.cancel')}
                                        onClick={closePopOver}>
                                    <Icon iconSpritePath={svgIconPath} name="cross"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </PopoverBody>
            </Popover>
        </div>
    );
};
