import React, {useCallback, useState} from 'react';
import {useMutation, useQueryClient} from 'react-query';
import {FieldValues, useForm} from 'react-hook-form';
import {Progress} from 'reactstrap';
import {ActionButton, CropperModal, FileInput, Modal} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import loadingSvg from '@ideascale/ui/dist/assets/loading.svg';
import {
    AlertType,
    FileUploadResponse,
    isHtmlInject,
    Localizer,
    reloadPage,
    UploadProgressCallback,
    useApiErrorResponseHandler,
    useFileUploadHandlers,
    useFileUploadIntermediary,
    useHandleFormSubmit,
    useToggle
} from '@ideascale/commons';
import {IMAGE_TYPE_REGEX, ONBOARDING_PROFILE_QUESTIONS_MAX_NAME_LENGTH, QUERY_KEYS} from 'constants/AppConstants';
import {ProfileSidebarResponse} from 'models/ProfileSidebarResponse';
import './EditAboutMeModal.scss';

type EditAboutMeModalProps = {
    isOpen: boolean
    toggle: () => void;
    localizer: Localizer;
    firstName: string;
    lastName: string;
    actorId: number;
    canEditName: boolean;
    canEditAvatar: boolean;
    maxFileSizeInMb: number;
    uploadImage: (data: FormData, onUploadProgress: UploadProgressCallback) => Promise<FileUploadResponse>;
    updateProfileAboutMeSection(parameters: { id: number, avatar: string, firstName: string, lastName: string }): Promise<ProfileSidebarResponse>;
}

const FIELD_NAMES = {
    firstName: 'firstName',
    lastName: 'lastName',
    avatar: 'avatar'
};

const FORM_ID = 'edit-about-me-form';
const MAX_IMAGE_WIDTH = 100;
const MAX_IMAGE_HEIGHT = 100;

export const EditAboutMeModal = (props: EditAboutMeModalProps) => {
    const {
        isOpen,
        toggle,
        localizer,
        firstName,
        lastName,
        actorId,
        canEditName,
        canEditAvatar,
        uploadImage,
        maxFileSizeInMb,
        updateProfileAboutMeSection
    } = props;

    const {onUploadFile, fileUploading} = useFileUploadIntermediary(uploadImage);
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [previewImage, setPreviewImage] = useState<string | null>(null);
    const {register, setValue, handleSubmit, setError, formState: {errors}} = useForm();
    const onFormSubmit = useHandleFormSubmit(handleSubmit);
    const [showCropper, toggleShowCropper] = useToggle(false);
    const [imageFile, setImageFile] = useState<File>();
    const queryClient = useQueryClient();
    const {mutate, isLoading} = useMutation(
        (requestParameters: { id: number, avatar: string, firstName: string, lastName: string }) => updateProfileAboutMeSection(requestParameters),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(QUERY_KEYS.PROFILE_SIDEBAR);
                toggle();
                reloadPage();
            },
            onError: (error: any) => {
                handleErrorResponse(error, {
                    setFormError: setError,
                    fallbackMessage: {
                        type: AlertType.error,
                        message: localizer.msg('profile.about-me.errors.update-profile')
                    }
                });
            }
        }
    );

    const removeImage = useCallback(() => {
        if (previewImage) {
            URL.revokeObjectURL(previewImage);
            setPreviewImage(null);
        }
        setValue(FIELD_NAMES.avatar, '');
    }, [previewImage, setValue]);

    const setImage = useCallback((fileName: string) => {
        setValue(FIELD_NAMES.avatar, fileName);
    }, [setValue]);

    const {averagePercentageCompleted, onInputFileChange, handleFileUploadError} = useFileUploadHandlers({
        localizer,
        onUploadSuccess: (fileName, previewImageUrl) => {
            setPreviewImage(previewImageUrl);
            setImage(fileName);
        },
        uploadFile: onUploadFile,
        maxFileSizeInMb,
    });

    const onSubmit = (data: FieldValues) => {
        const requestParameters = {
            id: actorId,
            avatar: data[FIELD_NAMES.avatar],
            firstName: data[FIELD_NAMES.firstName],
            lastName: data[FIELD_NAMES.lastName]
        };
        mutate(requestParameters);
    };

    return (
        <Modal className="modal-lg" isOpen={isOpen}
               toggle={toggle}
               autoFocus={false}
               title={localizer.msg('profile.about-me.edit.heading')}
               modalHeaderId="edit-about-me-header"
               aria-labelledby="edit-about-me-header">
            <form id={FORM_ID} onSubmit={onFormSubmit(onSubmit, isLoading || fileUploading)}>
                {
                    canEditName &&
                    <div className="d-md-flex gap-4">
                        <div className={`form-group w-100 ${errors[FIELD_NAMES.firstName] ? 'has-error' : ''}`}>
                            <label className="fw-bold" htmlFor="firstName">
                                {localizer.msg('registration.profile-first-name')}<span className="font-size-lg"
                                                                                        aria-hidden="true">*</span>
                            </label>
                            <input className="form-control" type="text" id="firstName"
                                   aria-required="true"
                                   defaultValue={firstName}
                                   {...register('firstName', {
                                       required: localizer.msg('registration.profile-first-name-error-required'),
                                       validate: (value: any) => !isHtmlInject(value) || localizer.msg('common.errors.html'),
                                       maxLength: {
                                           value: ONBOARDING_PROFILE_QUESTIONS_MAX_NAME_LENGTH,
                                           message: localizer.msg('registration.max-length-exceeded', {length: ONBOARDING_PROFILE_QUESTIONS_MAX_NAME_LENGTH}),
                                       }
                                   })}
                            />
                            {
                                errors[FIELD_NAMES.firstName]?.message &&
                                <div className="invalid-feedback d-block">
                                    {errors[FIELD_NAMES.firstName].message}
                                </div>
                            }
                        </div>
                        <div className={`form-group w-100 ${errors[FIELD_NAMES.lastName] ? 'has-error' : ''}`}>
                            <label className="fw-bold" htmlFor="lastName">
                                {localizer.msg('registration.profile-last-name')}<span className="font-size-lg"
                                                                                       aria-hidden="true">*</span>
                            </label>
                            <input className="form-control" type="text" id="lastName"
                                   defaultValue={lastName}
                                   aria-required="true"
                                   {...register('lastName', {
                                       required: localizer.msg('registration.profile-last-name-error-required'),
                                       validate: (value: any) => !isHtmlInject(value) || localizer.msg('common.errors.html'),
                                       maxLength: {
                                           value: ONBOARDING_PROFILE_QUESTIONS_MAX_NAME_LENGTH,
                                           message: localizer.msg('registration.max-length-exceeded', {length: ONBOARDING_PROFILE_QUESTIONS_MAX_NAME_LENGTH}),
                                       }
                                   })}
                            />
                            {
                                errors[FIELD_NAMES.lastName]?.message &&
                                <div className="invalid-feedback d-block">
                                    {errors[FIELD_NAMES.lastName].message}
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    canEditAvatar &&
                    <div className={`form-group ${errors[FIELD_NAMES.avatar] ? 'has-error' : ''}`}>
                        <div className="react-file-uploader">
                            <label className="fw-bold" htmlFor="avatar">
                                {localizer.msg('profile.about-me.edit.avatar')}
                            </label>
                            {
                                previewImage &&
                                <div className="image-preview mb-3">
                                    <img className="avatar avatar-lg" src={previewImage} height={100} width={100}
                                         alt="avatar-preview"/>
                                    <button className="btn btn-secondary remove-image" type="button"
                                            onClick={removeImage}>
                                        &#215;
                                    </button>
                                </div>
                            }
                            <FileInput
                                labels={{
                                    browseFile: localizer.msg('common.form.attachment.upload.browse-file'),
                                    dropFile: localizer.msg('common.form.attachment.upload.drop-file-here'),
                                    or: localizer.msg('common.or'),
                                    dragAndDrop: localizer.msg('common.form.attachment.upload.drag-drop'),
                                    uploadInstruction: localizer.msg('common.form.attachment.upload.upload-warning')
                                }}
                                svgIconPath={svgIconPath}
                                onSuccess={(files) => {
                                    if (IMAGE_TYPE_REGEX.test(files[0].type)) {
                                        toggleShowCropper(true);
                                        setImageFile(files[0]);
                                    } else {
                                        onInputFileChange(files).then();
                                    }
                                }}
                                inputId="avatar"
                                maxSizeInMB={maxFileSizeInMb}
                                onError={(error) => handleFileUploadError(error)}
                                multiple={false}
                            />
                            {
                                errors[FIELD_NAMES.avatar]?.message &&
                                <div className="invalid-feedback d-block">
                                    {errors[FIELD_NAMES.avatar].message}
                                </div>
                            }
                        </div>
                        <input type="hidden" {...register(FIELD_NAMES.avatar)} defaultValue=""/>

                        {averagePercentageCompleted < 100 &&
                            <Progress value={averagePercentageCompleted} max={100} barClassName="progressbar"
                                      className="progressbar-track mt-1">
                                {localizer.msg('common.form.attachment.upload.uploading')}
                            </Progress>
                        }
                    </div>
                }
            </form>
            <div className="d-flex flex-row justify-content-end mb-3">
                <button className="btn btn-cancel" type="button" onClick={toggle}>
                    {localizer.msg('common.cancel')}
                </button>
                <ActionButton type="submit" form={FORM_ID} loading={isLoading || fileUploading}>
                    {localizer.msg('common.submit')}
                </ActionButton>
            </div>
            {
                showCropper &&
                <CropperModal
                    isOpen={showCropper}
                    toggle={toggleShowCropper}
                    title={localizer.msg('profile.about-me.edit.adjust-image')}
                    saveButtonText={localizer.msg('profile.about-me.edit.save-changes')}
                    closeButtonText={localizer.msg('common.close')}
                    file={imageFile}
                    cropHeight={MAX_IMAGE_HEIGHT}
                    cropWidth={MAX_IMAGE_WIDTH}
                    handleUpload={onInputFileChange}
                    svgIconPath={svgIconPath}
                    loadingSvg={loadingSvg}
                    circle={true}/>
            }
        </Modal>
    );
};