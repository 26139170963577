import {Editor, EditorCallback} from './Editor';
import {SidebarItem} from 'models/sidebar/SidebarItem';
import {ConfigField} from 'models/edit-mode/ConfigField';
import {HomeConfigHolder} from 'models/edit-mode/HomeConfigHolder';
import {CommunityEditableFieldType} from 'models/edit-mode/CommunityEditableFieldType';
import {WidgetField} from 'models/edit-mode/WidgetField';

export class CommunityHomeEditor extends Editor {
    public static EMPTY = new CommunityHomeEditor(HomeConfigHolder.EMPTY, {applyChange: () => null});

    private readonly homeConfigHolder: HomeConfigHolder;

    constructor(homeConfigHolder: HomeConfigHolder, callback: EditorCallback) {
        super(callback);
        this.homeConfigHolder = homeConfigHolder;
    }

    get communityName() {
        return this.homeConfigHolder.getConfigField(CommunityEditableFieldType.COMMUNITY_TITLE).value;
    }

    set communityName(communityName: string) {
        this.homeConfigHolder.updateField(new ConfigField(CommunityEditableFieldType.COMMUNITY_TITLE, communityName));
        this.applyChange();
    }

    get communitySubtitle() {
        return this.homeConfigHolder.getConfigField(CommunityEditableFieldType.COMMUNITY_SUBTITLE).value;
    }

    set communitySubtitle(communitySubtitle: string) {
        this.homeConfigHolder.updateField(new ConfigField(CommunityEditableFieldType.COMMUNITY_SUBTITLE, communitySubtitle));
        this.applyChange();
    }

    set sidebarItems(sidebarItems: SidebarItem[]) {
        this.homeConfigHolder.sidebarItems = sidebarItems;
        this.applyChange();
    }

    get sidebarItems() {
        return this.homeConfigHolder.sidebarItems;
    }

    set communityWidget(communityWidget: WidgetField) {
        this.homeConfigHolder.updateField(communityWidget);
        this.sidebarItems = this.sidebarItems.map(item => {
            if (item.key === communityWidget.key) {
                item.title = communityWidget.title;
            }
            return item;
        });
        this.applyChange();
    }

    get communityWidget() {
        return this.homeConfigHolder.getWidgetField(CommunityEditableFieldType.COMMUNITY_WIDGET);
    }

    set customWidget(customWidget: WidgetField) {
        this.homeConfigHolder.updateField(customWidget);
        this.sidebarItems = this.sidebarItems.map(item => {
            if (item.key === customWidget.key) {
                item.title = customWidget.title;
            }
            return item;
        });
        this.applyChange();
    }

    get customWidget() {
        return this.homeConfigHolder.getWidgetField(CommunityEditableFieldType.CUSTOM_WIDGET);
    }

    set ideaListModeConfig(ideaListModeConfig: ConfigField) {
        this.homeConfigHolder.updateField(ideaListModeConfig);
        this.applyChange();
    }

    get ideaListModeConfig() {
        return this.homeConfigHolder.getConfigField(CommunityEditableFieldType.IDEA_LIST_MODE);
    }

    set ideaListViewModeConfig(ideaListViewModeConfig: ConfigField) {
        this.homeConfigHolder.updateField(ideaListViewModeConfig);
        this.applyChange();
    }

    get ideaListViewModeConfig() {
        return this.homeConfigHolder.getConfigField(CommunityEditableFieldType.IDEA_LIST_VIEW_MODE);
    }

    get topbarLogo() {
        return this.homeConfigHolder.getTopbarLogoField(CommunityEditableFieldType.TOPBAR_LOGO);
    }

    set topbarLogo(imageField) {
        this.homeConfigHolder.updateField(imageField);
        this.applyChange();
    }

    get bannerImage() {
        return this.homeConfigHolder.getBannerField(CommunityEditableFieldType.BANNER_IMAGE);
    }

    set bannerImage(bannerField) {
        this.homeConfigHolder.updateField(bannerField);
        this.applyChange();
    }

    get communityLogo() {
        return this.homeConfigHolder.getLogoField(CommunityEditableFieldType.COMMUNITY_LOGO);
    }

    set communityLogo(logoField) {
        this.homeConfigHolder.updateField(logoField);
        this.applyChange();
    }

    get communityTileImage() {
        return this.homeConfigHolder.getImageField(CommunityEditableFieldType.COMMUNITY_TILE_IMAGE);
    }

    set communityTileImage(imageField) {
        this.homeConfigHolder.updateField(imageField);
        this.applyChange();
    }

    get customFavicon() {
        return this.homeConfigHolder.getImageField(CommunityEditableFieldType.CUSTOM_FAVICON);
    }

    set customFavicon(imageField) {
        this.homeConfigHolder.updateField(imageField);
        this.applyChange();
    }

    protected applyChange() {
        this.onChangeCallbacks.applyChange({...this.homeConfigHolder});
    }
}