import React from 'react';
import {Link} from 'react-router-dom';
import {ClassificationLabelConfig} from '@ideascale/ui/dist/react-components/ideascale-slider/IdeascaleSlider';
import {ExpiryMessage} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {Localizer, PageTheme, StringUtil} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {CampaignCardSkeleton} from 'components/landing-page/CampaignCardSkeleton';
import {appLinks} from 'services/AppLinks';
import {CampaignSlide} from 'models/CampaignSlide';
import {CampaignDisplayType} from 'models/enums/landing-page/CampaignDisplayType';
import campaignImagePlaceholder from 'assets/img/landing-page/common/featured-campaigns-img-placeholder.png';
import {useExpiryMessage} from '../../hooks/useExpiryMessage';

const CAMPAIGN_SUBTITLE_MAX_CHARACTERS = 300;

type CampaignCardProps = {
    localizer: Localizer;
    theme: PageTheme
    editMode?: boolean;
    classificationLabelConfig?: ClassificationLabelConfig;
    campaign?: CampaignSlide;
    loading?: boolean;
    displayType: CampaignDisplayType;
}

export const CampaignCard = (props: CampaignCardProps) => {
    const {localizer, theme, editMode = false, classificationLabelConfig, campaign, loading, displayType} = props;
    const {communityConfig: {classificationEnabled}} = useAppContext();
    const expiryMessage = useExpiryMessage();
    const {ClassificationLabel, getClassificationAttribute} = classificationLabelConfig || {};

    return loading
        ? <CampaignCardSkeleton theme={theme} localizer={localizer} displayType={displayType}/>
        : <div
            className={`featured-campaign card panel h-100 ${displayType === CampaignDisplayType.STACK ? 'stack-view' : ''}`}>
            <div
                className={`card-body panel-body d-flex min-width flex-column ${displayType === CampaignDisplayType.STACK ? 'flex-md-row p-0' : ''}`}>
                <img
                    className={`campaign-image mb-3 ${displayType === CampaignDisplayType.STACK ? 'mb-md-0 me-md-3' : ''}`}
                    src={campaign?.image || campaignImagePlaceholder}
                    alt={campaign?.altText || localizer.msg('landing-page.components.campaign.alt-text')}/>
                <div
                    className={`d-flex min-width flex-column align-items-start flex-grow-1 flex-shrink-1 ${displayType === CampaignDisplayType.STACK ? 'p-3' : ''}`}>
                    {
                        classificationEnabled && ClassificationLabel && getClassificationAttribute &&
                        <ClassificationLabel
                            extraClasses="cursor-pointer mb-1"
                            classification={getClassificationAttribute(campaign?.classificationSummary || {})}/>
                    }
                    <Link
                        className={`campaign-title text-break ${displayType === CampaignDisplayType.STACK ? 'w-100 text-truncate' : ''}`}
                        {...(editMode ? {tabIndex: -1} : {})}
                        to={appLinks.aboutCampaign(campaign?.id.toString() || '#')}>
                        {campaign?.title}
                    </Link>
                    <div
                        className={`campaign-description mt-2 mb-3 text-break ${displayType === CampaignDisplayType.STACK ? 'w-100 text-truncate' : ''}`}>
                        {StringUtil.textTruncate(campaign?.subtitle || '', CAMPAIGN_SUBTITLE_MAX_CHARACTERS)}
                    </div>
                    <div className="w-100 d-flex justify-content-between align-items-center mt-auto">
                        <Link className={`btn ${theme === PageTheme.EASTMAN ? 'btn-eastman' : 'btn-primary'}`}
                              {...(editMode ? {tabIndex: -1} : {})}
                              aria-label={
                                  campaign?.title
                                      ? localizer.msg('landing-page.components.common.view-campaigns-name', {
                                          campaignName: campaign.title
                                      })
                                      : localizer.msg('landing-page.components.common.view-campaigns')
                              }
                              title={
                                  campaign?.title
                                      ? localizer.msg('landing-page.components.common.view-campaigns-name', {
                                          campaignName: campaign.title
                                      })
                                      : localizer.msg('landing-page.components.common.view-campaigns')
                              }
                              to={appLinks.ideas(campaign?.id.toString() || '#', campaign?.defaultStage?.key)}>
                            {localizer.msg('landing-page.components.campaign.view-ideas')}
                        </Link>
                        {
                            campaign?.expiryDate && campaign?.countdownEnabled &&
                            <ExpiryMessage className="ms-2" expiryDate={campaign.expiryDate}
                                           svgIconsPath={svgIconsPath}
                                           messages={expiryMessage}/>
                        }
                    </div>
                </div>
            </div>
        </div>;
};