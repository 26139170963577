import {SharingMedia} from './SharingMedia';

export class IdeaMoreActionResponse {
    static readonly EMPTY = new IdeaMoreActionResponse(false, false, false, false, false, false, '', [], false);

    constructor(
        public printIdeaAllowed: boolean,
        public ideaAuthorFollowAllowed: boolean,
        public emailIdeaAllowed: boolean,
        public emailAuthorAllowed: boolean,
        public assignOwnerAllowed: boolean,
        public banMemberAllowed: boolean,
        public reviewTranslationUrl: string,
        public allowedMediaList: SharingMedia[],
        public sharable: boolean
    ) {
    }
}