import React from 'react';
import {Icon} from '@ideascale/ui';
import {useLocalizer} from 'hooks/useLocalizer';
import {IdeaListMode} from 'models/enums/IdeaListMode';
import {OrderBy} from 'models/enums/OrderBy';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';

type IdeaListOrderProps = {
    ideaMode: IdeaListMode;
    orderBy: OrderBy;
    toggleOrder: () => void;
}

export const IdeaListOrder = (props: IdeaListOrderProps) => {
    const {orderBy, toggleOrder} = props;
    const localizer = useLocalizer();

    const renderOrderByIcon = () => {
        if (orderBy === OrderBy.ASC) {
            return (
                <>
                    <Icon iconSpritePath={svgIconsPath} name="arrow-up" width={18} height={18}/>
                    <span className="sr-only">Descending Order</span>
                </>

            );
        } else {
            return (
                <>
                    <Icon iconSpritePath={svgIconsPath} name="arrow-down" width={18} height={18}/>
                    <span className="sr-only">Ascending Order</span>
                </>
            );
        }
    };

    return (
        <button type="button" className="btn btn-gray border-start-custom-1 px-2" onClick={toggleOrder}
                data-test-element-id="idea-list-order" aria-roledescription={localizer.msg('profile.idea-order')}>
            {renderOrderByIcon()}
        </button>
    );
};