import {Command} from './Command';
import {CampaignHomeEditor} from './Editors/CampaignHomeEditor';
import {Operation} from 'models/edit-mode/Operation';
import {CampaignOperationType} from 'models/edit-mode/CampaignOperationType';
import {ChangeCampaignNameOperation} from 'models/edit-mode/ChangeCampaignNameOperation';

export class UpdateCampaignNameCommand implements Command {
    private readonly campaignHomeEditor: CampaignHomeEditor;
    private readonly oldCampaignName: string;
    private readonly newCampaignName: string;
    private readonly name: CampaignOperationType;
    private readonly campaignId: number;

    constructor(campaignHomeEditor: CampaignHomeEditor, campaignName: string, campaignId: number) {
        this.campaignHomeEditor = campaignHomeEditor;
        this.oldCampaignName = this.campaignHomeEditor.name;
        this.newCampaignName = campaignName;
        this.campaignId = campaignId;
        this.name = CampaignOperationType.CHANGE_CAMPAIGN_NAME;
    }

    execute(): void {
        this.campaignHomeEditor.name = this.newCampaignName;
    }

    undo(): void {
        this.campaignHomeEditor.name = this.oldCampaignName;
    }

    getType(): CampaignOperationType {
        return this.name;
    }

    getOperation(): Operation {
        return new ChangeCampaignNameOperation(this.name, this.campaignHomeEditor.name, this.campaignId);
    }
}