import {Stage} from './types/Stage';
import {StageActivitySummary} from './StageActivitySummary';
import {AuthorChangeActivity} from './AuthorChangeActivity';
import {Member} from '@ideascale/commons';
import {LabelActivity} from './LabelActivity';
import {StageGroup} from './enums/StageGroup';
import {TicketData} from './TicketData';

export class IdeaActivities {
    public static readonly Empty = new IdeaActivities(false, 0, {
        id: 0,
        key: '',
        name: '',
        label: '',
        description: '',
        functionName: '',
        stageGroup: StageGroup.ACTIVE
    }, StageActivitySummary.EMPTY, [], AuthorChangeActivity.EMPTY, '', {
        id: 0,
        name: '',
        username: '',
        avatar: '',
        url: ''
    }, [], []);

    constructor(
        public basicStage: boolean,
        public ideaId: number,
        public currentStage: Stage,
        public currentStageActivity: StageActivitySummary,
        public closedStageActivities: StageActivitySummary[],
        public authorChangeActivity: AuthorChangeActivity,
        public ideaSubmittedAt: string,
        public ideaAuthor: Member,
        public labelActivities: LabelActivity[],
        public tickets: TicketData[]
    ) {
    }
}