import React, {Fragment, useRef} from 'react';
import {HtmlRenderer} from 'components/shared/HtmlRenderer';
import {MediaType} from 'models/enums/landing-page/MediaType';
import {Media} from 'models/landing-page/Media';
import 'components/landing-page/MediaComponent.scss';

type ImageComponentProps = {
    config: Media;
    defaultImage?: string;
    allowVideo?: boolean;
    editModeEnabled?: boolean;
}

export const MediaComponent = (props: ImageComponentProps) => {
    const {config, defaultImage, allowVideo, editModeEnabled = false} = props;
    const videoRef = useRef<HTMLDivElement>(null);

    return (
        <Fragment>
            {
                config?.type === MediaType.IMAGE &&
                <img className={`media-item`} src={defaultImage ? defaultImage : config.url}
                     alt={config.altText}/>
            }
            {
                allowVideo && config?.type === MediaType.VIDEO &&
                <div ref={videoRef} className="video-container media-item">
                    <HtmlRenderer includeCookieConsent={!editModeEnabled} content={`<a href="${config.url}"/>`}/>
                </div>
            }
        </Fragment>
    );
};