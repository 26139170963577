import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {Localizer, Member, MemberLink, useApiErrorResponseHandler, useToggle} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {InfiniteUserPopover} from 'components/InfiniteUserPopover';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';

type AssignedOwnerGroupProps = {
    ideaId: number;
    ownerCount: number;
    localizer: Localizer;
    fetchOwners: (pageParameters: PageParameters, ideaId: number) => Promise<PagedResponseContent<Member>>;
    pageSize: number;
}

export const AssignedOwnerGroup = (props: AssignedOwnerGroupProps) => {
    const {ideaId, ownerCount, localizer, fetchOwners, pageSize} = props;
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [ideaOwners, setIdeaOwners] = useState<Member[]>([]);
    const [showOwnersPopover, toggleOwnersPopover] = useToggle(false);
    const {authentication: {actor}} = useAppContext();

    const containerId = useMemo(() => {
        return `idea-${ideaId}-owners-container`;
    }, [ideaId]);

    useEffect(() => {
        if (ideaId > 0) {
            fetchOwners({page: 0, limit: pageSize}, ideaId)
                .then(data => {
                    setIdeaOwners(data.content);
                }).catch(e => {
                handleErrorResponse(e);
            });
        }
    }, [fetchOwners, handleErrorResponse, ideaId, pageSize]);

    return (
        ideaOwners.length > 0 ? (
            <div id={containerId}>
                <p className="assignment-label">
                    {localizer.msg('idea-details.assigned-owners')}{' '}
                    {ownerCount > 1 ? (
                        <Fragment>
                            <button className="btn btn-link p-0 pb-1 fw-bold"
                                    onClick={() => toggleOwnersPopover()}
                                    title={localizer.msg('idea-details.grouped-owners-title')} id="idea-owner-group">
                                {localizer.msg('idea-details.grouped-owners')}
                            </button>
                            {
                                showOwnersPopover &&
                                <InfiniteUserPopover id={ideaId} actorId={actor.id}
                                                     container={containerId}
                                                     selector="idea-owner-group"
                                                     header={localizer.msg('idea-details.idea-owners')}
                                                     fetchUsers={fetchOwners}
                                                     pageSize={pageSize}
                                                     isOpen={showOwnersPopover}
                                                     toggle={toggleOwnersPopover}/>
                            }
                        </Fragment>
                    ) : (
                        <MemberLink id={ideaOwners[0].id} identityHidden={ideaOwners[0].identityHidden}
                                    className="d-inline">
                            <strong>{ideaOwners[0].name}</strong>
                        </MemberLink>
                    )}
                </p>
            </div>
        ) : <>
        </>
    );
};