import React, {Fragment} from 'react';
import {Avatar} from '@ideascale/ui';
import {MemberLink, StringUtil, TimeAgo} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {HtmlRenderer} from '../../shared/HtmlRenderer';
import {ConversationThreadData} from 'models/ConversationThreadData';
import {MessageReplyData} from 'models/MessageReplyData';

type messageDetailsProps = {
    details: ConversationThreadData;
    replyResponse: MessageReplyData;
}

export const MessageDetails = (props: messageDetailsProps) => {
    const {details} = props;
    const localizer = useLocalizer();

    return (
        <Fragment>
            <h2 className="sr-only">{localizer.msg('topbar.messages.details')}</h2>
            {details.messages.map(item => {
                return (
                    <Fragment key={item.createdAt}>
                        <div className="message-details-information">
                            <div className="user-information">
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <MemberLink id={item.sender.id} identityHidden={item.sender.identityHidden}>
                                                <Avatar className="me-2" size="sm"
                                                        alt={item.sender.username ? localizer.msg('common.user-avatar', {username: item.sender.username}) : ''}
                                                        src={item.sender.avatar}/>
                                            </MemberLink>
                                        </div>
                                        <div className="name">
                                            <span
                                                className="d-block fw-bold">
                                                <MemberLink id={item.sender.id}
                                                            ariaLabel={localizer.msg('topbar.messages.sender-label', {sender: item.sender.name})}
                                                            title={item.sender.name}
                                                            identityHidden={item.sender.identityHidden}>
                                                     {StringUtil.textTruncate(item.sender.name, 25)}
                                                </MemberLink>
                                            </span>
                                            <ul className="recipients list-inline mb-0 mt-n1"
                                                aria-label={localizer.msg('topbar.messages.recipients')}>
                                                <span
                                                    className="text-gray font-size-sm me-1"
                                                    aria-hidden="true">{localizer.msg('common.to')}</span>
                                                {item.recipients.map(sender => {
                                                    return (
                                                        <li className={`${item.recipients.length > 3 ? 'd-inline-block' : 'd-inline'} font-size-sm text-gray`}
                                                            key={sender.id}>
                                                            <MemberLink id={sender.id}
                                                                        className="text-gray"
                                                                        identityHidden={sender.identityHidden}>
                                                                {StringUtil.textTruncate(sender.name, 25)}
                                                            </MemberLink>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="date-time text-gray text-end">
                                        <TimeAgo localizer={localizer}
                                                 dateISOString={item.createdAt}/>
                                    </div>
                                </div>
                            </div>
                            <article className="message mb-2 mt-2">
                                <HtmlRenderer content={item.body}/>
                            </article>
                        </div>
                        <div className="spacer mt-3 mb-3 overflow-hidden border-bottom-1"/>
                    </Fragment>
                );
            })}
        </Fragment>
    );
};