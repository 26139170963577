import {CampaignPerformancesData} from 'models/CampaignPerformancesData';

export class DigestResponseContent {
    static readonly EMPTY = new DigestResponseContent([], false, 0, '', '');

    constructor(
        public campaignPerformances: CampaignPerformancesData[],
        public hasMore: boolean,
        public nextPageNo: number,
        public fromDate: string,
        public toDate: string
    ) {
    }
}