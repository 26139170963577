import {PageParameters} from './types/PageParameters';
import {TimeUtil} from '../utils/TimeUtil';

export class FieldParameter {
    id: number;
    optionIds: number[];
}

export class SearchParameters {
    static builder(): SearchParametersBuilder {
        const EMPTY = new SearchParameters('', '', '', [], [], [], [],
            [], false, false, [], [], {page: 0});
        return new SearchParametersBuilder(EMPTY);
    }

    constructor(
        public term: string,
        public fromDate: string,
        public toDate: string,
        public labelIds: number[],
        public campaignIds: number[],
        public stageIds: number[],
        public tags: string[],
        public moderatorTags: string[],
        public archiveCampaignIncluded: boolean,
        public privateCampaign: boolean,
        public ideaSelectionFields: FieldParameter[],
        public authorSelectionFields: FieldParameter[],
        public pageParameters: PageParameters
    ) {
    }
}

export class SearchParametersBuilder {
    constructor(private searchParameters: SearchParameters) {
    }

    term(term: string): this {
        this.searchParameters.term = term;
        return this;
    }

    fromDate(fromDate: Date | null, timeZone: string): this {
        this.searchParameters.fromDate = fromDate ? TimeUtil.formatToISODate(fromDate, timeZone) : '';
        return this;
    }

    toDate(toDate: Date | null, timezone: string): this {
        this.searchParameters.toDate = toDate ? TimeUtil.formatToISODate(toDate, timezone, true) : '';
        return this;
    }

    labelIds(labelIds: number[]): this {
        this.searchParameters.labelIds = labelIds;
        return this;
    }

    campaignIds(campaignIds: number[]): this {
        this.searchParameters.campaignIds = campaignIds;
        return this;
    }

    stageIds(stageIds: number[]): this {
        this.searchParameters.stageIds = stageIds;
        return this;
    }

    tags(tags: string[]): this {
        this.searchParameters.tags = tags;
        return this;
    }

    moderatorTags(moderatorTags: string[]): this {
        this.searchParameters.moderatorTags = moderatorTags;
        return this;
    }

    archiveCampaignIncluded(archiveCampaignIncluded: boolean): this {
        this.searchParameters.archiveCampaignIncluded = archiveCampaignIncluded;
        return this;
    }

    privateCampaign(privateCampaign: boolean): this {
        this.searchParameters.privateCampaign = privateCampaign;
        return this;
    }

    ideaSelectionFields(ideaSelectionFields: FieldParameter[]): this {
        this.searchParameters.ideaSelectionFields = ideaSelectionFields;
        return this;
    }

    authorSelectionFields(authorSelectionFields: FieldParameter[]): this {
        this.searchParameters.authorSelectionFields = authorSelectionFields;
        return this;
    }

    pageParameters(pageParameters: PageParameters): this {
        this.searchParameters.pageParameters = pageParameters;
        return this;
    }

    build(): SearchParameters {
        return this.searchParameters;
    }
}