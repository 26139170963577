import React from 'react';
import {Avatar} from '@ideascale/ui';
import {MemberLink, StringUtil} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';

type MemberSummaryProps = {
    id: number;
    avatarUrl: string;
    name: string;
    username?: string;
    identityHidden?: boolean
}

export const MemberSummary = (props: MemberSummaryProps) => {
    const {id, avatarUrl, name, username, identityHidden} = props;
    const localizer = useLocalizer();

    return (
        <div className="d-inline-flex align-items-center ms-1">
            <MemberLink id={id} identityHidden={identityHidden}>
                <Avatar className="me-2" size="sm" alt={name ? localizer.msg('common.user-avatar', {username: name}) : ""}
                        src={avatarUrl}/>
            </MemberLink>
            <div>
                <MemberLink id={id} identityHidden={identityHidden}>
                    <strong title={name}>{StringUtil.textTruncate(name, 25)}</strong>
                </MemberLink>
                {
                    username &&
                    <span title={username} className="text-muted d-block" role="presentation">
                        {StringUtil.textTruncate(username, 25)}
                    </span>
                }
            </div>
        </div>
    );
};