import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {NavItem} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import {
    BodyTagAttributes,
    Campaign,
    getTypedKeys,
    PageTitle,
    StringUtil,
    useRoutesMatch,
    useUrlQuery
} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useCommunityService} from 'hooks/useService';
import {useUpdateCurrentCampaign} from 'hooks/useUpdateCurrentCampaign';
import {IdeaSubmissionPreviewContainer} from 'containers/IdeaSubmissionPreviewContainer';
import {PageLayoutContainer} from 'containers/PageLayoutContainer';
import {LeaderboardContainer} from 'containers/LeaderboardContainer';
import {PageNavbar} from 'components/PageNavbar';
import {PageHeader} from 'components/PageHeader';
import {LeaderboardType} from 'models/enums/LeaderboardType';
import {appLinks} from 'services/AppLinks';
import {ROUTES} from 'shared/Routes';
import {PAGE_IDS} from 'constants/AppConstants';

export const LeaderboardPage = () => {
    const localizer = useLocalizer();
    const {communityConfig} = useAppContext();
    const communityService = useCommunityService();
    const [paramsFetched, setParamsFetched] = useState(false);
    const [campaignContext, setCampaignContext] = useState<Campaign>();
    const isLeaderboardBase = useRoutesMatch(ROUTES.LEADERBOARD.BASE);
    const urlQuery = useUrlQuery();
    useUpdateCurrentCampaign();

    const campaignId = useMemo(() => {
        let parsedCampaignId = Number(urlQuery.get(ROUTES.QUERY_PARAMS.CAMPAIGN));
        if (Number.isNaN(parsedCampaignId)) {
            return null;
        } else {
            return parsedCampaignId;
        }
    }, [urlQuery]);

    useEffect(() => {
        if (campaignId && communityService) {
            communityService.fetchCampaign(campaignId)
                .then((response) => {
                    setCampaignContext(response);
                    setParamsFetched(true);
                });
        } else {
            setParamsFetched(true);
        }
    }, [campaignId, communityService]);

    return (
        <Fragment>
            <BodyTagAttributes data-test-element-id={PAGE_IDS.LEADERBOARD}/>
            <PageTitle
                title={`${communityConfig.name} - ${localizer.msg('sidebar.leaderboard.leaderboard')}`}/>
            <PageHeader logo={communityConfig.logo} logoAltText={communityConfig.altText}
                        hideLogo={communityConfig.hideCommunityLogo}
                        bannerImage={communityConfig.bannerImageUrl}
                        logoBgTransparent={!communityConfig.logoFrameEnabled}
                        bannerAltText={communityConfig.bannerImageAltText}
                        bannerGradientEnabled={communityConfig.bannerGradientEnabled}
                        textColor={communityConfig.bannerTitleInBlack ? 'dark' : 'light'}
                        heading={communityConfig.name}
                        headingMeta=""/>
            <IdeaSubmissionPreviewContainer displayAsModal/>
            <PageNavbar navbarAriaLabel={localizer.msg('leaderboard.nav-label')}>
                <NavItem className="nav-item px-2 px-md-3">
                    <NavLink className={() => `${isLeaderboardBase ? 'active' : ''} nav-link px-0 nav-link`}
                             to={ROUTES.LEADERBOARD.BASE}>
                        {localizer.msg('leaderboard.all-leaderboard')}
                    </NavLink>
                </NavItem>
                {
                    getTypedKeys(LeaderboardType).map((key, index) =>
                        <NavItem key={index} className="nav-item px-2 px-md-3">
                            <NavLink className={({isActive}) => `${isActive ? 'active' : ''} nav-link px-0 nav-link`}
                                     to={{
                                         pathname: appLinks.leaderboard(LeaderboardType[key]),
                                         search: urlQuery.toString()
                                     }}>
                                {localizer.msg(`leaderboard.type.${StringUtil.snakeCaseToKebabCase(LeaderboardType[key])}.label`)}
                            </NavLink>
                        </NavItem>
                    )
                }
            </PageNavbar>

            <PageLayoutContainer
                className="leaderboard-container"
                mainContent={paramsFetched ? <LeaderboardContainer campaignContext={campaignContext}/> : null}
            />
        </Fragment>
    );
};