import React, {useEffect, useMemo} from 'react';
import {Controller, useFormState} from 'react-hook-form';
import {OptionType} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {
    Campaign,
    CampaignDropdownLabel,
    CampaignSelect,
    CampaignsHolder,
    IdeaFormFieldKeys,
    Localizer,
    PageParameters,
    useHookFormContext
} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {IdeaFormFields} from 'models/IdeaFormFields';
import {DEFAULT_CAMPAIGN_ID} from './IdeaFormModal';

type CampaignDropdownProps = {
    localizer: Localizer;
    defaultCampaign?: Campaign | null;
    fetchCampaigns: (params: PageParameters) => Promise<PagedResponseContent<CampaignsHolder<Campaign>>>;
    onSelectCampaign: (campaignId: number) => Promise<void>;
    isDisabled: boolean;
    ideaFormFields: IdeaFormFields;
    autoFocus?: boolean;
}

const CAMPAIGN_DROPDOWN_ID = 'campaign-dropdown-field';

export const CampaignDropdown = (props: CampaignDropdownProps) => {
    const {
        localizer,
        defaultCampaign,
        fetchCampaigns,
        onSelectCampaign,
        isDisabled,
        autoFocus,
        ideaFormFields,
    } = props;
    const {control, setFocus} = useHookFormContext();
    const {communityConfig} = useAppContext();
    const {errors} = useFormState({name: IdeaFormFieldKeys.CAMPAIGN, control});

    const defaultValue = useMemo(() => {
        return {label: `-- ${localizer.msg('idea.fields.campaign.please-select')} --`, value: DEFAULT_CAMPAIGN_ID};
    }, [localizer]);

    const defaultOption = useMemo(() => {
        return defaultCampaign
            ? {
                value: defaultCampaign.id,
                label: defaultCampaign.name,
                labelAsJsx: <CampaignDropdownLabel campaign={defaultCampaign}
                                                   classificationEnabled={communityConfig.classificationEnabled}/>,
            } : defaultValue;
    }, [communityConfig.classificationEnabled, defaultCampaign, defaultValue]);

    useEffect(() => {
        if (autoFocus) {
            setTimeout(() => setFocus(IdeaFormFieldKeys.CAMPAIGN));
        }
    }, [setFocus, ideaFormFields, autoFocus]);

    return (
        <div className={`form-group ${IdeaFormFieldKeys.CAMPAIGN in errors ? 'has-error' : ''}`}>
            <label className="fw-bold" htmlFor={CAMPAIGN_DROPDOWN_ID}>
                {localizer.msg('idea.fields.campaign.label')}
                <span className="font-size-lg" aria-hidden>*</span>
                <span className="sr-only">{localizer.msg('common.form.required')}</span>
            </label>
            <Controller
                name={IdeaFormFieldKeys.CAMPAIGN}
                control={control}
                rules={{
                    validate: {
                        campaignRequired: (option: OptionType) => {
                            return isDisabled || option?.value > DEFAULT_CAMPAIGN_ID;
                        }
                    }
                }}
                defaultValue={defaultOption}
                render={({field: {value, onChange, name, ref}}) => {
                    return <CampaignSelect
                        localizer={localizer}
                        svgIconsPath={svgIconsPath}
                        classificationEnabled={communityConfig.classificationEnabled}
                        className={`campaign-search-dropdown ${communityConfig.classificationEnabled ? 'classification' : ''}`}
                        isMulti={false}
                        value={value || defaultOption}
                        name={name}
                        ref={ref}
                        isDisabled={isDisabled}
                        inputId={CAMPAIGN_DROPDOWN_ID}
                        fetchCampaigns={fetchCampaigns}
                        onSelect={async (selectedOption) => {
                            onChange(selectedOption);
                            await onSelectCampaign((selectedOption as OptionType).value);
                        }}/>;
                }}/>
            {IdeaFormFieldKeys.CAMPAIGN in errors &&
                <div className="invalid-feedback d-block">
                    {localizer.msg('idea.form.field.errors.campaign-required')}
                </div>
            }
        </div>
    );
};