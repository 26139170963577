import {useCallback} from 'react';
import {useIdeaUpdater} from './useIdeaUpdater';

export const useIdeaSubscription = (ideaListQueryKey: any = '') => {
    const {updateIdea, updateIdeaDetails} = useIdeaUpdater();

    const ideaListIdeaSubscriptionMutation = useCallback(async (ideaId: number, subscribed: boolean) => {
        await updateIdea(ideaListQueryKey, ideaId, 'subscribed', subscribed);
    }, [ideaListQueryKey, updateIdea]);

    const ideaDetailsIdeaSubscriptionMutation = useCallback(async (ideaId: number, subscribed: boolean, followersCount: number) => {
        await updateIdeaDetails(ideaId, 'subscribed', subscribed);
        await updateIdeaDetails(ideaId, 'followersCount', followersCount);
    }, [updateIdeaDetails]);

    return {ideaListIdeaSubscriptionMutation, ideaDetailsIdeaSubscriptionMutation};
};