import React, {Fragment, useCallback, useMemo} from 'react';
import {Button} from '@ideascale/ui';
import {eventDispatcher, Localizer, useToggle} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {useIdeaSubmissionActions} from 'hooks/useIdeaSubmissionActions';
import {ConfigWrapper} from 'components/landing-page/ConfigWrapper';
import {ButtonsConfigModal} from 'components/landing-page/ButtonsConfigModal';
import {ROUTES} from 'shared/Routes';
import {TranslationModal} from 'shared/TranslationModal';
import {SHOW_LOGIN_PROMPT} from 'constants/AppConstants';
import {TranslationConfig} from 'models/TranslationConfig';
import {Button as ButtonData} from 'models/landing-page/Button';
import {TranslationFieldType} from 'models/enums/TranslationFieldType';
import {TranslationContentType} from 'models/enums/landing-page/TranslationContentType';
import {TranslationContentRequest} from 'models/landing-page/TranslationContentRequest';
import {TranslationContentResponse} from 'models/landing-page/TranslationContentResponse';

type ButtonComponentProps = {
    buttonConfig: ButtonData;
    showConfig: boolean;
    toggleVisibility: (button: ButtonData) => Promise<void>;
    localizer: Localizer;
    updateButtonConfig: (button: ButtonData) => Promise<void>;
    onTextTranslated: (response: TranslationContentResponse) => void;
    getTranslatedButtonText: (parameters: TranslationContentRequest) => Promise<TranslationContentResponse[]>
    saveTranslatedButtonText: (parameters: TranslationContentRequest) => Promise<TranslationContentResponse[]>;
    onComponentEdited: () => void;
    showTranslationConfig?: boolean,
    translationLanguageId?: number,
}

export const ButtonComponent = (props: ButtonComponentProps) => {
    const {
        buttonConfig,
        getTranslatedButtonText,
        saveTranslatedButtonText,
        updateButtonConfig,
        onTextTranslated,
        showConfig,
        showTranslationConfig,
        toggleVisibility,
        localizer,
        translationLanguageId,
        onComponentEdited
    } = props;
    const {communityConfig, authentication} = useAppContext();
    const {onSubmitIdeaButtonClick} = useIdeaSubmissionActions();
    const [showEditModal, toggleEditModal] = useToggle(false);
    const [showTranslationModal, toggleTranslationModal] = useToggle(false);

    const onToggleVisibility = useCallback(async () => {
        toggleVisibility(buttonConfig).then(() => {
            onComponentEdited();
        });
    }, [buttonConfig, onComponentEdited, toggleVisibility]);

    const onEditClicked = useCallback(() => {
        toggleEditModal(true);
    }, [toggleEditModal]);

    const isIdeaSubmissionAllowed = () => {
        if (authentication.actor.hasPendingMembership()) {
            return false;
        } else if (authentication.isJoinCommunityAvailable()) {
            return true;
        } else {
            return communityConfig.submissionAllowed;
        }
    };

    const isSubmissionButton = (actionUrl: string) => {
        return actionUrl?.includes(ROUTES.QUERY_PARAMS.SUBMISSION_FORM);
    };

    const onClick = (actionUrl: string) => {
        if (authentication.community.isNotPublicCommunity() && authentication.actor.isAnonymous()) {
            eventDispatcher.dispatch(SHOW_LOGIN_PROMPT);
        } else {
            if (isSubmissionButton(actionUrl)) {
                if (authentication.actor.isAnonymous() && !communityConfig.ssoEnabled) {
                    eventDispatcher.dispatch(SHOW_LOGIN_PROMPT);
                } else {
                    onSubmitIdeaButtonClick();
                }
            } else if (actionUrl?.startsWith(communityConfig.url) || actionUrl?.includes(`${process.env.PUBLIC_URL}/`)) {
                window.open(actionUrl, '_self');
            } else {
                window.open(actionUrl, '_blank');
            }
        }
    };

    const translationConfigs = useMemo(() => {
        return [
            new TranslationConfig(
                TranslationContentType.COMPONENT_BUTTON_TEXT,
                buttonConfig.id,
                TranslationFieldType.TEXT,
            )
        ];
    }, [buttonConfig.id]);

    return (
        <Fragment>
            <ConfigWrapper className="p-2" showTranslationConfig={showTranslationConfig} visible={buttonConfig.visible}
                           showConfig={showConfig} onEditClicked={onEditClicked} localizer={localizer}
                           onTranslationClicked={toggleTranslationModal}
                           toggleVisibility={onToggleVisibility}
                           accessibleName={localizer.msg('landing-page.components.common.button')}>
                <Button className={`text-break ${showConfig || showTranslationConfig ? 'pointer-events-none' : ''}`}
                        {...(showConfig || showTranslationConfig ? {tabIndex: -1} : {})}
                        {...(!isSubmissionButton(buttonConfig.url) ? {role: 'link'} : {})}
                        key={buttonConfig.url} size={buttonConfig.size}
                        color={buttonConfig.type.toLowerCase()}
                        disabled={isSubmissionButton(buttonConfig.url) && !isIdeaSubmissionAllowed()}
                        onClick={() => onClick(buttonConfig.url)} type="button">
                    {buttonConfig.text}
                </Button>
            </ConfigWrapper>
            {
                showEditModal &&
                <ButtonsConfigModal open={showEditModal}
                                    toggle={toggleEditModal}
                                    onComponentEdited={onComponentEdited}
                                    buttonConfig={buttonConfig}
                                    updateConfig={updateButtonConfig}
                                    localizer={localizer}/>
            }
            {
                showTranslationModal && translationLanguageId !== undefined &&
                <TranslationModal translationLanguageId={translationLanguageId} localizer={localizer}
                                  open={showTranslationModal}
                                  toggle={toggleTranslationModal}
                                  onTextTranslated={onTextTranslated}
                                  onComponentEdited={onComponentEdited}
                                  translationConfigs={translationConfigs}
                                  getTranslatedContent={getTranslatedButtonText}
                                  saveTranslatedContent={saveTranslatedButtonText}/>
            }
        </Fragment>
    );
};