import React, {useState} from 'react';
import {clsx} from 'clsx';
import {useQuery} from 'react-query';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Icon,
    InfoTip,
    Modal,
    Pagination,
    ParagraphSkeleton,
    Scrollbar,
    UncontrolledButtonDropdown,
} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {AvatarInfo, InputSearch, Localizer, PagedResponseContent, PageParameters} from '@ideascale/commons';
import {FollowerInfo} from 'models/types/FollowerInfo';
import styles from 'containers/SearchFilterContainer.module.scss';
import './CampaignFollowersModal.scss';

enum FOLLOWING_ORDER {
    NEWEST = 'Newest',
    OLDEST = 'Oldest'
}

type CampaignFollowersModalProps = {
    open: boolean;
    toggle: () => void;
    localizer: Localizer;
    campaignId: number;
    totalFollowers: number;
    fetchFollowers: (campaignId: number, sort: string, pageParams: PageParameters) => Promise<PagedResponseContent<FollowerInfo>>;
};

export const CampaignFollowersModal = (props: CampaignFollowersModalProps) => {
    const {open, toggle, localizer, campaignId, totalFollowers, fetchFollowers} = props;

    const [pageParams, setPageParams] = useState<PageParameters>({
        term: '',
        page: 0,
        limit: 20,
    });
    const [sortOrder, setSortOrder] = useState<FOLLOWING_ORDER>(FOLLOWING_ORDER.NEWEST);

    const {data, isLoading} = useQuery({
        queryKey: ['campaign-followers', campaignId, sortOrder, pageParams.page, pageParams.limit, pageParams.term],
        queryFn: () => fetchFollowers(campaignId, sortOrder, pageParams),
    });

    return (
        <Modal isOpen={open} className="followers-modal" toggle={toggle} title={
            <div className="d-flex align-items-center">
                <span>{localizer.msg('campaign.followers.modal-title', {count: totalFollowers})} </span>
                <InfoTip className="mt-n2" id="followers-infotip" placement="bottom"
                         content={localizer.msg('campaign.followers.modal-info')}/>
            </div>
        }>
            <InputSearch localizer={localizer} inputTestId="followers-search-input"
                         onSearch={term => setPageParams({...pageParams, term})}
                         onClear={() => setPageParams({...pageParams, term: ''})}/>

            <UncontrolledButtonDropdown className="mt-3">
                <DropdownToggle className={`btn btn-default text-truncate bg-hover-gray px-1`}
                                data-test-element-id="followers-sort-toggle"
                                tag="button" caret type="button">
                    {localizer.msg(`campaign.followers.order-${sortOrder.toLowerCase()}`)}
                </DropdownToggle>
                <DropdownMenu className={styles.searchCategoryDropdown} data-test-element-id="followers-sort-menu">
                    {
                        Object.values(FOLLOWING_ORDER).map((order) =>
                            <DropdownItem key={order} onClick={() => setSortOrder(order)} data-test-element-id="followers-sort-option">
                                {localizer.msg(`campaign.followers.order-${order.toLowerCase()}`)}
                            </DropdownItem>
                        )
                    }
                </DropdownMenu>
            </UncontrolledButtonDropdown>
            <Scrollbar autoHeight={true} autoHeightMin={400} autoHide={true} autoHideTimeout={500}>
                {
                    isLoading
                        ? <div className="mt-3">
                            <ParagraphSkeleton rows={10}/>
                        </div>
                        :
                        data && data.content.length > 0
                            ? (data.content.map((follower, index) => (
                                <AvatarInfo key={index}
                                            testId="follower-item"
                                            localizer={localizer}
                                            className="my-3"
                                            alt={localizer.msg('frontend-shared.common.user-avatar', {username: follower.name})}
                                            title={follower.name}
                                            avatarUrl={follower.avatarUrl}
                                            subTitle={follower.email}
                                            timestamp={follower.followedAt}/>
                            )))
                            : (
                                <div className="d-flex flex-column align-items-center justify-content-center pt-5" data-test-element-id="empty-followers-list">
                                    <Icon iconSpritePath={svgIconPath} name="people-outlined-two" height={48} width={48}/>
                                    <span>{localizer.msg('campaign.followers.no-followers')}</span>
                                </div>
                            )
                }
            </Scrollbar>
            {
                data && data.totalElements > 0 &&
                <Pagination className={clsx('pagination', isLoading && 'hidden')} labels={{
                    results: localizer.msg('campaign.followers.members'),
                    of: localizer.msg('frontend-shared.pagination.of'),
                    pageSize: localizer.msg('frontend-shared.pagination.page-size'),
                    displaying: localizer.msg('frontend-shared.pagination.displaying'),
                    next: localizer.msg('frontend-shared.pagination.next'),
                    previous: localizer.msg('frontend-shared.pagination.previous')
                }} pageOperations={{
                    setPage: (page) => setPageParams({...pageParams, page}),
                    nextPage: () => setPageParams({...pageParams, page: pageParams.page + 1}),
                    previousPage: () => setPageParams({...pageParams, page: pageParams.page - 1}),
                    setPageSize: (limit) => setPageParams({...pageParams, page: 0, limit})
                }} pageStates={{
                    pageSize: data?.pageSize || 20,
                    pageNumber: data?.pageNo || 0,
                    total: data?.totalElements || 0,
                    hasNextPage: data?.hasMore || false,
                    hasPreviousPage: (data && data.pageNo > 0) || false
                }} svgIconPath={svgIconPath}/>
            }
        </Modal>
    );
};