export class CommentActionsPermission {
    static EMPTY = new CommentActionsPermission(false, false, false, false, false, false, false, false, false, false);

    constructor(
        public approvalAllowed: boolean,
        public rejectionAllowed: boolean,
        public replyAllowed: boolean,
        public attachmentAllowed: boolean,
        public votingAllowed: boolean,
        public modificationAllowed: boolean,
        public deletionAllowed: boolean,
        public annotationAllowed: boolean,
        public pinnedAllowed: boolean,
        public abuseReportingAllowed: boolean
    ) {
    }
}
