import React from 'react';
import {Link} from 'react-router-dom';
import {appLinks} from 'services/AppLinks';
import {useAppContext} from 'contexts/AppContext';
import {CampaignDetailsData} from 'models/CampaignDetailsData';
import {StageGroup} from 'models/enums/StageGroup';
import 'components/CampaignFunnel.scss';

type StageFlowProps = {
    campaignData: CampaignDetailsData;
}

export const CampaignFunnel = (props: StageFlowProps) => {
    const {campaignData} = props;
    const {currentCampaign} = useAppContext();

    return (
        <section className="stage-flow">
            {campaignData.stages?.filter((stageDetails) => stageDetails?.stageGroup === StageGroup.ACTIVE).map(stageDetails =>
                <div
                    className="row" key={stageDetails.id}>
                    <div className="col-auto">
                        <Link
                            className="stage-item"
                            to={appLinks.ideas(String(currentCampaign?.id), stageDetails?.key)}>
                            {stageDetails?.label}
                        </Link>
                    </div>
                    <div className="col mb-3">
                        {stageDetails?.description}
                    </div>
                </div>)}
            {campaignData.stages?.filter((stageDetails) => stageDetails?.stageGroup === StageGroup.COMPLETED).map(stageDetails =>
                <div
                    className="row" key={stageDetails.id}>
                    <div className="col-auto">
                        <Link
                            className={`stage-item archive-stage`}
                            to={appLinks.ideas(String(currentCampaign?.id), stageDetails?.key)}>
                            {stageDetails?.label}
                        </Link>
                    </div>
                    <div className="col mb-3">
                        {stageDetails?.description}
                    </div>
                </div>)}
        </section>
    );
};