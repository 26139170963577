import {Command} from './Command';
import {CommunityHomeEditor} from './Editors/CommunityHomeEditor';
import {CommunityOperationType} from 'models/edit-mode/CommunityOperationType';
import {CommunityEditableFieldType} from 'models/edit-mode/CommunityEditableFieldType';
import {IdeaListViewMode} from 'models/enums/IdeaListViewMode';
import {ChangeIdeaViewModeOperation} from 'models/edit-mode/ChangeIdeaViewModeOperation';
import {Operation} from 'models/edit-mode/Operation';

export class UpdateIdeaListViewModeCommand implements Command {
    private readonly communityHomeEditor: CommunityHomeEditor;
    private readonly oldIdeaListViewMode: IdeaListViewMode;
    private readonly newIdeaListViewMode: IdeaListViewMode;
    private readonly name: CommunityOperationType;

    constructor(communityHomeEditor: CommunityHomeEditor, ideaListViewMode: IdeaListViewMode) {
        this.communityHomeEditor = communityHomeEditor;
        this.oldIdeaListViewMode = this.communityHomeEditor.ideaListViewModeConfig.value.toUpperCase() as IdeaListViewMode;
        this.newIdeaListViewMode = ideaListViewMode;
        this.name = CommunityOperationType.CHANGE_IDEA_VIEW_MODE;
    }

    execute(): void {
        this.communityHomeEditor.ideaListViewModeConfig = {
            name: CommunityEditableFieldType.IDEA_LIST_VIEW_MODE,
            value: this.newIdeaListViewMode.toUpperCase(),
            editPermission: this.communityHomeEditor.ideaListViewModeConfig.editPermission
        };
    }

    undo(): void {
        this.communityHomeEditor.ideaListViewModeConfig = {
            name: CommunityEditableFieldType.IDEA_LIST_VIEW_MODE,
            value: this.oldIdeaListViewMode.toUpperCase(),
            editPermission: this.communityHomeEditor.ideaListViewModeConfig.editPermission
        };
    }

    getType(): CommunityOperationType {
        return this.name;
    }

    getOperation(): Operation {
        return new ChangeIdeaViewModeOperation(this.name, this.communityHomeEditor.ideaListViewModeConfig.value.toUpperCase());
    }

}