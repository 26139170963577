import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Localizer, LocalizerImpl} from '@ideascale/commons';
import {useAppContext} from 'contexts/AppContext';
import {LOCALIZATION_KEY_PREFIX} from 'constants/AppConstants';

export const useLocalizer = (): Localizer => {
    const {t} = useTranslation();
    const {communityConfig, localizationMode} = useAppContext();

    return useMemo(() => new LocalizerImpl(t, communityConfig.dateTimeFormat, localizationMode, LOCALIZATION_KEY_PREFIX),
        [t, communityConfig.dateTimeFormat, localizationMode]);
};
