import React, {Fragment} from 'react';
import {Localizer} from '@ideascale/commons';
import {ActivityLink} from '../ActivityLink';
import {ActivityStreamData} from 'models/ActivityStreamData';
import {ActionType} from 'models/enums/ActionType';

type CommentActivityProps = {
    localizer: Localizer;
    activity: ActivityStreamData;
    action: ActionType.COMMENT | ActionType.COMMENT_REMOVE;
}

export const CommentActivity = (props: CommentActivityProps) => {
    const {activity, action, localizer} = props;

    const renderActivityLabel = () => {
        if (action === ActionType.COMMENT_REMOVE) {
            return localizer.msg('profile.activity-stream.comment.removed');
        } else if (action === ActionType.COMMENT) {
            return localizer.msg('profile.activity-stream.comment.posted');
        }
    };

    return (
        <Fragment>
            {renderActivityLabel()}
            {activity.commentReply && <>{' '}({localizer.msg('profile.activity-stream.comment.reply')})</>}
            {' - '}
            <ActivityLink title={activity.idea.title} ideaId={activity.idea.id}/>
        </Fragment>
    );
};