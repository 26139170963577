export class ActivityIcon {
    private static readonly IS_SVG_PROTOCOL = 'ideascale-ui-svg:';

    constructor(
        public url: string,
        public alt: string
    ) {
    }

    isSvg() {
        return this.url.startsWith(ActivityIcon.IS_SVG_PROTOCOL);
    }

    getSvgName() {
        return this.url.replace(ActivityIcon.IS_SVG_PROTOCOL, '');
    }

    getUrl() {
        return this.isSvg() ? this.getSvgName() : this.url;
    }
}