import {BaseIdeaStageSummary} from './BaseIdeaStageSummary';
import {StageSummaryData} from './types/StageSummaryData';

export class IdeateStageSummary extends BaseIdeaStageSummary {
    constructor(
        public stage: StageSummaryData,
        public viewAllowed: boolean,
        public participationAllowed: boolean,
        public percentage: number,
        public callToActionLabel: string,
        public voteValue: number,
        public actorVotingAllowed: boolean,
        public ideaVotingAllowed: boolean,
        public showVotingButton: boolean,
        public downVoteCount: number,
        public upVoteCount: number,
        public netVoteCount: number,
    ) {
        super(stage, viewAllowed, participationAllowed, percentage, callToActionLabel);
    }

    getIconName(): string {
        return '';
    }
}