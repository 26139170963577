import React from 'react';
import {Button, Modal} from '@ideascale/ui';
import {Localizer} from '@ideascale/commons';

type ConfirmCancellationModalProps = {
    localizer: Localizer;
    open: boolean
    onCancel: () => void;
    onConfirmation: () => void;
}
export const ConfirmCancellationModal = (props: ConfirmCancellationModalProps) => {
    const {localizer, open, onCancel, onConfirmation} = props;

    return (
        <Modal isOpen={open} title={localizer.msg('edit-mode.unsaved-confirmation-modal.title')} toggle={onCancel} showHeaderCloseButton={false}>
            <div>{localizer.msg('edit-mode.unsaved-confirmation-modal.message')}</div>
            <div className="mt-5 mb-3 text-end">
                <Button className="me-3" color="cancel" type="button" onClick={onCancel} data-test-element-id="cancel">
                    {localizer.msg('edit-mode.unsaved-confirmation-modal.cancel-label')}
                </Button>
                <Button color="danger" onClick={onConfirmation} data-test-element-id="confirm">
                    {localizer.msg('edit-mode.unsaved-confirmation-modal.confirm-label')}
                </Button>
            </div>
        </Modal>
    );
};