import React, {useCallback} from 'react';
import {Button, Icon} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useToggle} from '@ideascale/commons';
import {useLocalizer} from 'hooks/useLocalizer';
import {useAppContext} from 'contexts/AppContext';
import {useEditModeContext} from 'contexts/EditModeContext';
import {TopbarCommonEditModeActions} from 'components/topbar/TopbarCommonEditModeActions';
import {AdvancedSettingsModal} from 'components/edit-mode/advanced-settings/AdvancedSettingsModal';
import {CommunityOperationType} from 'models/edit-mode/CommunityOperationType';
import {CommunityEditableFieldType} from 'models/edit-mode/CommunityEditableFieldType';

type HomePageTopbarEditModeContainerProps = {
    onCancel: () => void;
    onPublish: () => void;
    publishDisabled: boolean;
}
export const HomePageTopbarEditModeContainer = (props: HomePageTopbarEditModeContainerProps) => {
    const {onCancel, onPublish, publishDisabled} = props;

    const {communityConfig: {maxFileSizeLimit}} = useAppContext();
    const {validationErrors, homeConfig} = useEditModeContext();
    const localizer = useLocalizer();

    const [advancedSettingsModalIsOpen, toggleAdvancedSettingsModal] = useToggle(false);

    const onCancelEditMode = useCallback(() => {
        onCancel();
    }, [onCancel]);

    const onPublishEditMode = useCallback(() => {
        onPublish();
    }, [onPublish]);

    const hasError = () => {
        return (validationErrors.hasError(CommunityOperationType.CHANGE_TOPBAR_LOGO) ||
            validationErrors.hasError(CommunityOperationType.CHANGE_COMMUNITY_BANNER) ||
            validationErrors.hasError(CommunityOperationType.CHANGE_COMMUNITY_LOGO) ||
            validationErrors.hasError(CommunityOperationType.CHANGE_CUSTOM_FAVICON));
    };

    const hasAdvancedSettingsItems = () => {
        const advancedSettingsEditableItems = [
            CommunityEditableFieldType.TOPBAR_LOGO,
            CommunityEditableFieldType.BANNER_IMAGE,
            CommunityEditableFieldType.COMMUNITY_LOGO,
            CommunityEditableFieldType.CUSTOM_FAVICON
        ] as string[];
        return (homeConfig.fields || []).some(field => advancedSettingsEditableItems.includes(field.name));
    };

    return (
        <div className={`d-flex w-100 justify-content-${hasAdvancedSettingsItems() ? 'between' : 'end'} `}>
            {
                hasAdvancedSettingsItems() && (
                    <Button color="link" className={`edit-mode-element px-0 ${hasError() ? 'has-error' : ''}`}
                            onClick={toggleAdvancedSettingsModal} data-test-element-id="btn-advanced-settings">
                        <Icon className={`active position-relative pos-top-n1 ${hasError() ? 'icon-danger' : ''}`}
                              width={24} iconSpritePath={svgIconsPath}
                              name="gear"/>
                        {localizer.msg('edit-mode.advance-settings')}
                        {hasError() && <Icon className="is-icon icon-danger ms-2" name="exclamation-outlined-circle" iconSpritePath={svgIconsPath}/>}
                    </Button>
                )
            }
            <TopbarCommonEditModeActions publishDisabled={publishDisabled} onPublish={onPublishEditMode}
                                         localizer={localizer}
                                         onCancel={onCancelEditMode}/>
            {
                advancedSettingsModalIsOpen && (
                    <AdvancedSettingsModal
                        localizer={localizer}
                        isOpen={advancedSettingsModalIsOpen}
                        toggle={toggleAdvancedSettingsModal}
                        maxFileSizeLimit={maxFileSizeLimit}
                    />
                )
            }
        </div>
    );
};

