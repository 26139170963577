export enum CampaignOperationType {
    CHANGE_CAMPAIGN_NAME = 'CHANGE_CAMPAIGN_NAME',
    CHANGE_CAMPAIGN_SUBTITLE = 'CHANGE_CAMPAIGN_SUBTITLE',
    CHANGE_CAMPAIGN_LOGO = 'CHANGE_CAMPAIGN_LOGO',
    CHANGE_CAMPAIGN_BANNER = 'CHANGE_CAMPAIGN_BANNER',
    CHANGE_CAMPAIGN_FEATURED_IMAGE = 'CHANGE_CAMPAIGN_FEATURED_IMAGE',
    CHANGE_CAMPAIGN_SIDEBAR_ORDER = 'CHANGE_CAMPAIGN_SIDEBAR_ORDER',
    CHANGE_CAMPAIGN_DESCRIPTION = 'CHANGE_CAMPAIGN_DESCRIPTION',
    CHANGE_CAMPAIGN_BRIEF = 'CHANGE_CAMPAIGN_BRIEF',
    CHANGE_CAMPAIGN_TOS = 'CHANGE_CAMPAIGN_TOS',
    CHANGE_CAMPAIGN_WIDGET_ONE = 'CHANGE_CAMPAIGN_WIDGET_ONE',
    CHANGE_CAMPAIGN_WIDGET_TWO = 'CHANGE_CAMPAIGN_WIDGET_TWO',
    CHANGE_CAMPAIGN_WIDGET_THREE = 'CHANGE_CAMPAIGN_WIDGET_THREE',
    PUBLISH_CAMPAIGN_HOME_CONFIG = 'PUBLISH_CAMPAIGN_HOME_CONFIG',
    SHOW_CAMPAIGN_MODERATORS_TEAM = 'SHOW_CAMPAIGN_MODERATORS_TEAM',
    SHOW_GLOBAL_MODERATORS_TEAM = 'SHOW_GLOBAL_MODERATORS_TEAM',
    UNKNOWN_OPERATION = 'UNKNOWN_OPERATION'
}