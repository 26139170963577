import React, {useState} from 'react';
import {useForm, useWatch} from 'react-hook-form';
import {ActionButton, CommonUtil} from '@ideascale/ui';
import {
    EXTERNAL_FILE_BASE_PATH,
    HookFormProvider,
    isImageLink,
    Localizer,
    UploadedResponse,
    UploadProgressCallback,
    useApiErrorResponseHandler,
    useHandleFormSubmit
} from '@ideascale/commons';
import {ImageOrLink} from 'components/landing-page/ImageOrLink';
import {Media} from 'models/landing-page/Media';
import {MediaType} from 'models/enums/landing-page/MediaType';
import {ImageConfigFields} from 'models/landing-page/ImageConfigFields';

type LandingPageImageUploadProps = {
    imageCropWidth: number;
    imageCropHeight: number;
    localizer: Localizer;
    config: Media;
    onConfigUpdated: () => void;
    configUpdated: boolean;
    maxFileSizeLimit: number;
    toggleModal: () => void;
    onComponentEdited: () => void;
    updateConfig: (config: Media) => Promise<void>;
    uploadImage: (data: FormData, onUploadProgress: UploadProgressCallback) => Promise<UploadedResponse>;
    defaultImage?: string;
    note?: JSX.Element;
    previewMode?: 'full' | 'small' | 'medium';
}

export const MediaConfigImageTab = (props: LandingPageImageUploadProps) => {
    const {
        localizer,
        imageCropWidth,
        imageCropHeight,
        defaultImage,
        note,
        config,
        configUpdated,
        onConfigUpdated,
        toggleModal,
        previewMode,
        maxFileSizeLimit,
        updateConfig,
        onComponentEdited,
        uploadImage
    } = props;

    const methods = useForm<ImageConfigFields>({
        defaultValues: {
            altText: config.altText,
            imageLink: isImageLink(config.url) ? atob(decodeURIComponent(config.url.split('?url=')[1])) : '',
            uploadedImage: config.type === MediaType.VIDEO ? '' : isImageLink(config.url) ? '' : defaultImage ? defaultImage : config.url,
            type: MediaType.IMAGE
        }
    });
    const {handleSubmit, formState: {isSubmitting}, control} = methods;
    const onFormSubmit = useHandleFormSubmit(handleSubmit);
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const {imageLink, uploadedImage} = useWatch({control});
    const [imageUploading, setImageUploading] = useState(false);

    const submitConfig = async (data: ImageConfigFields) => {
        const newConfig = {...config};
        newConfig.type = MediaType.IMAGE;
        newConfig.altText = data.altText?.trim() || 'Media';
        if (data.imageLink) {
            const newUrl = data.imageLink;
            new URL(newUrl);
            if (CommonUtil.isSameOrigin(window.location.href, newUrl)) {
                newConfig.url = newUrl;
            } else {
                const base64Url = btoa(newUrl);
                const urlEncode = encodeURIComponent(base64Url);
                newConfig.url = `${EXTERNAL_FILE_BASE_PATH}?url=${urlEncode}`;
            }
        } else if (data.uploadedImage) {
            newConfig.url = data.uploadedImage;
        }

        try {
            await updateConfig(newConfig);
            onComponentEdited();
            toggleModal();
        } catch (e) {
            handleErrorResponse(e);
        }
    };

    return (
        <HookFormProvider {...methods}>
            <form className="form-group media-config-image-tab"
                  onSubmit={onFormSubmit(submitConfig, !configUpdated || (!uploadedImage && !imageLink) || imageUploading || isSubmitting)}>
                {
                    note &&
                    <div className="alert alert-info" role="alert">
                        {note}
                    </div>
                }

                <ImageOrLink localizer={localizer}
                             previewMode={previewMode}
                             setImageUploading={setImageUploading}
                             uploadImage={uploadImage}
                             cropWidth={imageCropWidth}
                             configUpdated={configUpdated}
                             cropHeight={imageCropHeight}
                             onConfigUpdated={onConfigUpdated}
                             maxFileSizeLimit={maxFileSizeLimit}/>

                <div className="form-group mt-5 text-end">
                    <button className="btn btn-cancel me-3" type="button" onClick={toggleModal}
                            data-test-element-id="btn-cancel">
                        {localizer.msg('common.actions.cancel')}
                    </button>
                    <ActionButton type="submit" color="primary"
                                  disabled={!configUpdated || (!uploadedImage && !imageLink)}
                                  loading={imageUploading || isSubmitting} data-test-element-id="btn-submit">
                        {localizer.msg('common.actions.submit')}
                    </ActionButton>
                </div>
            </form>
        </HookFormProvider>
    );
};